import type { LabsGqlNotificationItemsQuery, LabsGqlNotificationOrderItemFragment } from '@orthly/graphql-operations';

export enum NotificationPeriod {
    MORE_THAN_MONTH,
    FOUR_WEEKS_AGO,
    THREE_WEEKS_AGO,
    TWO_WEEKS_AGO,
    WEEK_AGO,
    SIX_DAYS_AGO,
    FOUR_DAYS_AGO,
    TWO_DAYS_AGO,
    TODAY,
}

export enum NotificationPeriodNames {
    MORE_THAN_MONTH = 'More than month old',
    FOUR_WEEKS_AGO = '4 weeks old',
    THREE_WEEKS_AGO = '3 weeks old',
    TWO_WEEKS_AGO = '2 weeks old',
    WEEK_AGO = 'Week old',
    SIX_DAYS_AGO = '6 days old',
    FOUR_DAYS_AGO = '4 days old',
    TWO_DAYS_AGO = '2 days old',
    TODAY = 'Today',
}

export enum NotificationType {
    PENDING_WAXUP_APPROVAL = 'pendingWaxupApprovals',
    OVERDUE_INVOICES = 'overdueInvoices',
    UNSUBMITTED_SCANS = 'unsubmittedScans',
    VISIBLE_HOLD_ORDERS = 'visibleHoldOrders',
    INBOX_ITEMS = 'inboxItems',
}

export enum NotificationActionType {
    REDIRECT = 'redirect',
    FUNCTION = 'function',
    DISMISS = 'dismiss',
    DISMISS_ALL = 'dismissAll',
}

export interface UrgentNotificationDataWrapper {
    periodName: string;
    periodKey: string;
    notifications: UrgentNotification[];
}

export interface UpdateNotificationDataWrapper {
    date: string;
    dismissAll: boolean;
    notifications: DandyNotification[];
}

interface CommonActionProps {
    text: string;
    disabled?: boolean;
}

export interface FunctionAction {
    type: NotificationActionType.FUNCTION;
    onClick: () => void;
}

export interface RedirectAction {
    type: NotificationActionType.REDIRECT;
    url: string;
}

export interface DismissAction {
    type: NotificationActionType.DISMISS;
    eventId: number;
}

export interface DismissAllAction {
    type: NotificationActionType.DISMISS_ALL;
    eventIds: number[];
}

export interface InvoiceUrlArgs {
    invoiceId: string;
}

export interface NotificationActionOptions {
    getOrderUrl: (orderId: string) => string;
    getInvoiceUrl: ({ invoiceId }: InvoiceUrlArgs) => string;
    markAsRead: (eventIds: number) => Promise<void>;
    navigationAction: (path: string) => void;
    openPdf?: (url: string) => void;
}

export type ActionProps = CommonActionProps & (FunctionAction | RedirectAction | DismissAction | DismissAllAction);

export interface DandyNotification {
    id: string;
    type: string;
    eventDate: string;
    dismiss: boolean;
    title: string;
    subtitle: string;
    icon: JSX.Element;
    primaryAction: ActionProps;
    secondaryAction?: ActionProps;
}

// Since these types need to be shared with Chairside, only require fields that are used
export type NotificationInvoice = Pick<
    LabsGqlNotificationItemsQuery['overdueInvoices'][number],
    'id' | 'created_at' | 'period_start' | 'amount_remaining'
>;

export type NotificationOrderInboxItem = LabsGqlNotificationItemsQuery['orderInboxItems'][number];

export type NotificationOrderScanFile = LabsGqlNotificationItemsQuery['scanFiles'][number];

export interface DandyNotificationsData {
    actionableHoldOrders: LabsGqlNotificationOrderItemFragment[];
    orderInboxItems: NotificationOrderInboxItem[];
    waxupReviews: LabsGqlNotificationOrderItemFragment[];
    overdueInvoices: NotificationInvoice[];
    scanFiles: NotificationOrderScanFile[];
}

export interface UrgentNotification extends DandyNotification {
    period: NotificationPeriod;
}
