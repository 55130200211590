import { ToothUtils } from '@orthly/items';
import type { MarginLine } from '@orthly/shared-types';
import _ from 'lodash';
import * as THREE from 'three';
import { Parser as XmlParser } from 'xml2js';

const IDENTITY_MATRIX = new THREE.Matrix4().identity();

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function parseMarginLines(
    xml: string | undefined,
    transform: { upper: THREE.Matrix4; lower: THREE.Matrix4 },
): MarginLine[] {
    if (xml === undefined) {
        return [];
    }
    try {
        const marginLines: MarginLine[] = [];

        new XmlParser().parseString(xml, (err: any, result: any) => {
            if (err) {
                throw new Error(err);
            }

            /**
                This parser is not pretty! But it works for now.
                The format of the 3ml xml file is roughly
                ```
                <NSITree version="1.1">
                    <Features branchtype="information">
                        <Feature name="OrderList" type="NInformations">
                            <ChildFeatures>
                                <Feature name="NAME_OF_PATIENT" type="NInformations">
                                    <ChildFeatures>
                                        ...
                                        <Feature name="Define interfaces container" type="NInformations">
                                            <ChildFeatures>
                                                <Feature name="ModelJobList" type="NInformations">
                                                    <ChildFeatures>
                                                        <Feature name="ModelJob container MJ43D1A246EC4B4FC9A0B15DB6DF6E979B" type="NInformations">
                                                            <ChildFeatures>
                                                                <Feature name="ModelElementList" type="NInformations">
                                                                    <ChildFeatures>
                                                                        <Feature name="ModelElement container ME15BB1DD9C8C9480C9A4391F9F503255A" type="NInformations">
                                                                            <ChildFeatures>
                                                                                <Feature name="ToothElementList" type="NInformations">
                                                                                    <ChildFeatures>
                                                                                        <Feature name="ToothElement container TE32F946BAA0FC4EFB8BD3C6FEF4F270F1" type="NInformations">
                                                                                            <ChildFeatures>
                                                                                                ...
                                                                                                <Feature name="SCopy TE32F946BAA0FC4EFB8BD3C6FEF4F270F1" type="SCopy">
                                                                                                    <ChildFeatures>
                                                                                                        ...
                                                                                                        <Feature name="Margin line TE32F946BAA0FC4EFB8BD3C6FEF4F270F1" type="OPreparationLine">
                                                                                                            <Object name="PrepLine" type="TSysSplineList">
                                                                                                                <Splines>
                                                                                                                    <Object type="TSysSpline">
                                                                                                                        ...
                                                                                                                        <Property name="iMisc1" value="TOOTH_NUMBER"/>
                                                                                                                        <ControlPoints>
                                                                                                                            <Object type="TSysVertex">
                                                                                                                                ...
                                                                                                                                <Vector
                                                                                                                                    name="p"
                                                                                                                                    x="FLOATING_POINT_NUMBER"
                                                                                                                                    y="FLOATING_POINT_NUMBER"
                                                                                                                                    z="FLOATING_POINT_NUMBER"
                                                                                                                                />
                                                                                                                                ...
                                                                                                                            </Object>
                                                                                                                        </ControlPoints>
                                                                                                                        ...
                                                                                                                    </Object>
                                                                                                                </Splines>
                                                                                                            </Object>
                                                                                                        </Feature>
                                                                                                        ...
                                                                                                    </ChildFeatures>
                                                                                                </Feature>
                                                                                                ...
                                                                                            </ChildFeatures>
                                                                                        </Feature>
                                                                                    </ChildFeatures>
                                                                                </Feature>
                                                                            </ChildFeatures>
                                                                        </Feature>
                                                                    </ChildFeatures>
                                                                </Feature>
                                                            </ChildFeatures>
                                                        </Feature>
                                                    </ChildFeatures>
                                                </Feature>
                                            </ChildFeatures>
                                        </Feature>
                                        ...
                                    </ChildFeatures>
                                </Feature>
                            </ChildFeatures>
                        </Feature>
                    </Feature>
                </NSITree>
                ```
                */

            const expectPath = (xml: any, path: string) => {
                const result = _.get(xml, path);

                if (!result) {
                    throw new Error(`Could not get path ${path} of ${xml}`);
                }

                return result;
            };

            // we are here: `<Feature name="OrderList" type="NInformations">`
            const orderList = expectPath(result, 'NSITree.Features.0.Feature');

            // we are here: `<Feature name="NAME_OF_PATIENT" type="NInformations">`
            const patient = expectPath(orderList, '0.ChildFeatures.0.Feature');

            // we are here: `<Feature name="Define interfaces container" type="NInformations">`
            const defineInterfacesContainer = expectPath(patient, '0.ChildFeatures.0.Feature').find(
                (f: any) => f['$'].name === 'Define interfaces container',
            );

            // we are here: `<Feature name="ModelJobList" type="NInformations">`
            const modelJobList = expectPath(defineInterfacesContainer, 'ChildFeatures.0.Feature').find(
                (f: any) => f['$'].name === 'ModelJobList',
            );

            // we are here: `<Feature name="ModelJob container xxx" type="NInformations">`
            for (const job of expectPath(modelJobList, 'ChildFeatures.0.Feature')) {
                try {
                    const toothElementList = expectPath(
                        expectPath(job, 'ChildFeatures.0.Feature').find((f: any) => f['$'].name === 'ModelElementList'),
                        'ChildFeatures.0.Feature.0.ChildFeatures.0.Feature',
                    );

                    const teethContainers = expectPath(toothElementList, '0.ChildFeatures.0.Feature');
                    const containerMargins = teethContainers.map((toothElementContainer: any) => {
                        const toothId = toothElementContainer['$'].name.slice('ToothElement container '.length);
                        const tooth = expectPath(toothElementContainer, 'ChildFeatures.0.Feature').find(
                            (f: any) => f['$'].name === `SCopy ${toothId}`,
                        );
                        const marginLineContainer = expectPath(tooth, 'ChildFeatures.0.Feature').find(
                            (f: any) => f['$'].name === `Margin line ${toothId}`,
                        );
                        const splines = marginLineContainer.Object.find((f: any) => f['$'].name === 'PrepLine').Splines;

                        const toothNumber = parseInt(
                            expectPath(splines, '0.Object.0.Property').find((f: any) => f['$'].name === 'iMisc1')['$']
                                .value,
                        );

                        const controlPoints = expectPath(splines, '0.Object.0.ControlPoints.0.Object');

                        const vertices: number[] = controlPoints
                            .map((obj: any) => expectPath(obj, 'Vector.0.$'))
                            .map((vertex: any) => [parseFloat(vertex.x), parseFloat(vertex.y), parseFloat(vertex.z)]);

                        const upper_mx_array: number[] = [];
                        transform.upper.clone().transpose().toArray(upper_mx_array); // THREE.js column major
                        const lower_mx_array: number[] = [];
                        transform.lower.clone().transpose().toArray(lower_mx_array); // THREE.js column major

                        return {
                            tooth: toothNumber,
                            coords: vertices,
                            // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                            // eslint-disable-next-line no-nested-ternary
                            transformationMatrix: ToothUtils.toothIsUpper(toothNumber)
                                ? upper_mx_array
                                : ToothUtils.toothIsLower(toothNumber)
                                  ? lower_mx_array
                                  : IDENTITY_MATRIX.toArray(), // THREE is column major however IDENTITY does not need transpose
                        };
                    });

                    marginLines.push(...containerMargins);
                } catch (e: any) {
                    console.error('Encountered error while reading tooth model', e);
                    continue;
                }
            }
        });

        return marginLines;
    } catch (e: any) {
        console.error('Encountered error while reading 3ml file', e);
        return [];
    }
}
