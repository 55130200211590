import { usePortalToChairsideBridgeCommand } from '../../../utils/chairside.hooks';
import { useOrderSummaryRowButtonStyles } from '@orthly/dentin';
import { LabsGqlOrderReturnStatus } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet, Button, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    label: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

interface PrintOrderReturnLabelProps {
    labelUrl: string;
    status: LabsGqlOrderReturnStatus;
    title: string;
    style?: React.CSSProperties;
    slim?: boolean;
    analyticsLocation?: string;
}

export const PrintOrderReturnLabel: React.VFC<PrintOrderReturnLabelProps> = ({
    labelUrl,
    status,
    title,
    style,
    slim,
    analyticsLocation,
}) => {
    const buttonClasses = useOrderSummaryRowButtonStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const openPdf = usePortalToChairsideBridgeCommand('openPdf');
    const { label } = useStyles();

    if (isMobile || status !== LabsGqlOrderReturnStatus.NotShipped) {
        return null;
    }

    return (
        <Button
            {...(openPdf ? { onClick: () => openPdf({ url: labelUrl }) } : { href: labelUrl })}
            startIcon={!slim ? 'PrintIcon' : undefined}
            variant={'primary'}
            classes={{ root: label }}
            className={slim ? buttonClasses.button : undefined}
            style={style}
            analytics={{
                AssetName: 'Print Return Label',
                AssetLocation: `Practice - ${analyticsLocation || 'Order List'}`,
            }}
        >
            {title}
        </Button>
    );
};
