import type { ColorMapFn } from './colorMappingFunctions';
import React from 'react';

interface ColorRampComponentProps {
    vMin: number;
    vMax: number;
    colorMap: ColorMapFn;
    width: number;
    height: number;
}

export const ColorRamp: React.FC<ColorRampComponentProps> = props => {
    const { vMin, vMax, colorMap, width, height } = props;

    const canvasRef = React.useRef<HTMLCanvasElement>(null);

    React.useEffect(() => {
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx) {
            return;
        }

        const imageData = ctx.createImageData(width, height);
        const data = imageData.data;
        const step_size = (vMax - vMin) / height;
        const row_length = width * 4;

        // writes color into linear buffer that is row x width x 4 channels long
        // [rgbargbargba.....]
        for (let row = 0; row < height; row++) {
            const color = colorMap(vMin, vMax, vMax - row * step_size) || { r: 255, g: 255, b: 255 };
            const row_start = row * row_length;
            for (let pixel = row_start; pixel < row_start + row_length; pixel += 4) {
                data[pixel] = color.r;
                data[pixel + 1] = color.g;
                data[pixel + 2] = color.b;
                data[pixel + 3] = 255; // Alpha channel
            }
        }
        ctx.putImageData(imageData, 0, 0);
    }, [colorMap, vMin, vMax, width, height]);

    return <canvas style={{ borderRadius: 4 }} width={width} height={height} ref={canvasRef} />;
};
