/* eslint-disable max-lines */
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from './state/GuidedWaxupState';
import type { PresetInfo } from './state/GuidedWaxupTypes';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type {
    LimitedAppearanceFilterBools,
    MainViewCameraControlsRef,
    ModelAppearance,
    PayloadModelAppearance,
    PresetViewControllerFns,
} from '@orthly/dentin';
import {
    GUIDED_WAXUP_APPEARANCE_FILTER,
    INITIAL_APPEARANCE_FILTER,
    RestorativeView,
    applyAppearanceFilter,
    applyVisibility,
    isParentCadVisible,
    useTeethIndices,
    useZoomToVisible,
} from '@orthly/dentin';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlSubmitWaxupReviewMutationVariables } from '@orthly/graphql-operations';
import { useSubmitWaxupReviewMutation } from '@orthly/graphql-react';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import type { SimpleMenuPropsItem } from '@orthly/ui';
import {
    LowerJawIcon,
    ThumbsDownIcon,
    ThumbsUpIcon,
    ToothFilledIcon,
    UpperJawIcon,
    useChangeSubmissionFn,
    useStableCallback,
} from '@orthly/ui';
import { FlossPalette, Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export interface PresetInputs {
    activeTooth: number;
    teethIndices: number[];
    controlRef: MainViewCameraControlsRef;
    presetViewControls: PresetViewControllerFns;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    setZoom: React.Dispatch<React.SetStateAction<number>>;
    zoomToVisible: () => void;
    isImmediateDenture?: boolean;
}

export interface PresetTabs {
    label: string;
    value: LabsGqlGuidedWaxupPresetType;
}

export function isGuidedPreset(selectedTab: LabsGqlGuidedWaxupPresetType) {
    return selectedTab !== LabsGqlGuidedWaxupPresetType.GeneralView;
}

export function makeTitleCase(presetType: string) {
    return _.startCase(presetType.toLowerCase());
}

export function useShouldDisableTabs() {
    const presets = useGuidedWaxupSelector(s => s.presets);
    const { selectedTab, isAnnotatingScreenshot } = useGuidedWaxupContext();
    return (
        (presets[selectedTab]?.status === LabsGqlGuidedWaxupPresetStatus.Rejected && !presets[selectedTab]?.notes) ||
        isAnnotatingScreenshot
    );
}

export function usePresetTabClick() {
    const { setSelectedTab, selectedDesignRevisionAlreadyReviewed, setIsIncompletedWorkReminderModalOpen } =
        useGuidedWaxupContext();
    const disableTabs = useShouldDisableTabs();
    return (tab: PresetTabs) => {
        // to assist doctors in navigating the guided waxup flow, if the tabs are disabled
        // because the doctor rejected the design but hasn't provided a rejection note yet,
        // or if they are currently annotating a markup, then we display a pop-up reminder
        // to complete the actions before navigating away from the current preset.
        if (!disableTabs) {
            BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
                AssetName: `Guided Waxup Preset Tab - ${tab.label}`,
                AssetType: 'button',
                AssetVersion: '',
                AssetCTAText: tab.label,
            });
            setSelectedTab(tab.value);
        } else {
            // if vieweing previous design, this is a no-op
            // otherwise, show modal
            if (!selectedDesignRevisionAlreadyReviewed) {
                setIsIncompletedWorkReminderModalOpen(true);
            }
        }
    };
}

export function useGetSelectedWaxup() {
    const { selectedDesignFragment } = useGuidedWaxupContext();
    return getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment)?.doctor_review;
}

const CONTENT_BY_PRESET_TYPE = {
    [LabsGqlGuidedWaxupPresetType.ToothDesign]: {
        title: makeTitleCase(LabsGqlGuidedWaxupPresetType.ToothDesign),
        mobileTitle: makeTitleCase(LabsGqlGuidedWaxupPresetType.ToothDesign),
        questionTitle: 'Would you like to keep this tooth design?',
    },
    [LabsGqlGuidedWaxupPresetType.MarginView]: {
        title: makeTitleCase(LabsGqlGuidedWaxupPresetType.MarginView),
        mobileTitle: 'Margin',
        questionTitle: 'Please confirm if this margin is accurate',
    },
    [LabsGqlGuidedWaxupPresetType.ContourView]: {
        title: makeTitleCase(LabsGqlGuidedWaxupPresetType.ContourView),
        mobileTitle: 'Contour',
        questionTitle: 'Are the contours correct?',
    },
    [LabsGqlGuidedWaxupPresetType.ContactDesign]: {
        title: makeTitleCase(LabsGqlGuidedWaxupPresetType.ContactDesign),
        mobileTitle: makeTitleCase(LabsGqlGuidedWaxupPresetType.ContactDesign),
        questionTitle: 'Would you like to keep this interproximal contact and embrasure design?',
    },
    [LabsGqlGuidedWaxupPresetType.OcclusalAnatomy]: {
        title: makeTitleCase(LabsGqlGuidedWaxupPresetType.OcclusalAnatomy),
        mobileTitle: 'Occlusal',
        questionTitle: 'Would you like to keep this occlusal design?',
    },
    [LabsGqlGuidedWaxupPresetType.GeneralView]: {
        title: makeTitleCase(LabsGqlGuidedWaxupPresetType.GeneralView),
        mobileTitle: 'General',
        questionTitle: '',
    },
};

export function getPresetTitle(presetType: string, isMobile?: boolean) {
    const enumPresetType = presetType as LabsGqlGuidedWaxupPresetType;
    return isMobile ? CONTENT_BY_PRESET_TYPE[enumPresetType].mobileTitle : CONTENT_BY_PRESET_TYPE[enumPresetType].title;
}

export function getGuidedPresetQuestionTitle(presetType: LabsGqlGuidedWaxupPresetType) {
    return CONTENT_BY_PRESET_TYPE[presetType].questionTitle;
}

export function useGuidedWaxupTabs(presets: Partial<Record<LabsGqlGuidedWaxupPresetType, PresetInfo>>): PresetTabs[] {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const filteredPresets = Object.entries(presets ?? {}).filter(([_, presetInfo]) => presetInfo.status !== 'SKIPPED');
    return filteredPresets.map(([preset, _]) => ({
        label: getPresetTitle(preset, isMobile),
        value: preset as LabsGqlGuidedWaxupPresetType,
    }));
}

export function getAndFormatGuidedPresetAnnotations(
    rejection?: OrderDesignPreviewDesign_FragmentFragment['doctor_review'],
) {
    const rejectionAnnotations = rejection?.annotations?.flatMap(preset => preset.annotated_image_urls);
    return rejectionAnnotations?.map(annotation => ({
        comment: '',
        image_url: annotation,
    }));
}

export const PresetStatusIndicator: React.VFC<{ presetType: LabsGqlGuidedWaxupPresetType }> = ({ presetType }) => {
    const { selectedDesignRevisionAlreadyReviewed } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const selectedWaxup = useGetSelectedWaxup();
    const selectedRejectionPreset =
        selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected
            ? selectedWaxup?.annotations?.find(preset => preset.preset_type === presetType)
            : undefined;
    const status = selectedDesignRevisionAlreadyReviewed
        ? selectedRejectionPreset?.preset_status
        : presets[presetType]?.status;

    // If selected waxup is an approval, then every preset was approved and we just show the thumbs up.
    if (selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved) {
        return <ThumbsUpIcon style={{ color: FlossPalette.GRAY }} />;
    }

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    return status === LabsGqlGuidedWaxupPresetStatus.Rejected ? (
        <ThumbsDownIcon
            style={{
                color: selectedDesignRevisionAlreadyReviewed ? FlossPalette.GRAY : FlossPalette.SECONDARY_FOREGROUND,
            }}
        />
    ) : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    status === LabsGqlGuidedWaxupPresetStatus.Approved ? (
        <ThumbsUpIcon style={{ color: FlossPalette.GRAY }} />
    ) : status === LabsGqlGuidedWaxupPresetStatus.Skipped ? (
        <Text variant={'body2'} medium color={'GRAY'}>
            Incomplete
        </Text>
    ) : null;
};

export function toggleAction(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
    pma: PayloadModelAppearance,
) {
    return function (checked: boolean) {
        setAppearance(app => {
            // CAD visibility is the anchor
            const cadAppearance = applyVisibility(
                app.restoratives.CAD,
                checked,
                candidate => candidate.payloadModel.name === pma.payloadModel.name,
            );
            const preExtractionScansAppearance = applyVisibility(
                app.preExtractionScans,
                checked,
                candidate => candidate.payloadModel.name === pma.payloadModel.name,
            );
            // HeatMap follows CAD
            const cadHeatmapProxy = applyVisibility(
                app.restoratives.HeatMap,
                checked,
                candidate => checked === isParentCadVisible(candidate, cadAppearance),
            );
            return {
                ...app,
                restoratives: {
                    HeatMap: cadHeatmapProxy,
                    CAD: cadAppearance,
                },
                preExtractionScans: preExtractionScansAppearance,
            };
        });
    };
}

export const VisibilityToggleIcon: React.VFC<{ toggleName: string; toggled: boolean }> = ({ toggleName, toggled }) => {
    switch (toggleName) {
        case 'Upper':
            return (
                <UpperJawIcon
                    style={{
                        color: toggled ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                    }}
                />
            );
        case 'Lower':
            return (
                <LowerJawIcon
                    style={{
                        color: toggled ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                    }}
                />
            );
        default:
            return (
                <ToothFilledIcon
                    style={{
                        color: toggled ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                        margin: '4px -6px 0px 3px',
                    }}
                />
            );
    }
};
export const useSubmitGuidedWaxupReview = (onSubmit?: () => Promise<void>) => {
    const [submitMtn] = useSubmitWaxupReviewMutation();
    const mtnSubmitter = (variables: LabsGqlSubmitWaxupReviewMutationVariables) => submitMtn({ variables });
    return useChangeSubmissionFn<any, [LabsGqlSubmitWaxupReviewMutationVariables]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => [`Waxup review submitted!`, {}],
        onSuccess: async () => {
            if (onSubmit) {
                await onSubmit();
            }
        },
    });
};

export const useTimelineAndImagesMenuItems = () => {
    const { setIsMobileTimelineDialogOpen, setIsMobileDoctorImagesDialogOpen, OrderChatWrapper } =
        useGuidedWaxupContext();
    const timelineItem: SimpleMenuPropsItem = {
        onClick: (setClosed: () => void) => {
            setIsMobileTimelineDialogOpen(true);
            setClosed();
        },
        label: 'Timeline',
    };

    const imagesItem: SimpleMenuPropsItem = {
        onClick: (setClosed: () => void) => {
            setIsMobileDoctorImagesDialogOpen(true);
            setClosed();
        },
        label: 'Images',
    };

    // If OrderChatWrapper isn't provided, then there isn't data to render for the timeline, and we exclude
    // it as an option
    return OrderChatWrapper ? [timelineItem, imagesItem] : [imagesItem];
};

export interface OpposingArchControls {
    isUpper: boolean;
    filterValue: boolean;
    toggle: () => void;
}

export function useNextTabNavigation() {
    const { selectedTab, setSelectedTab } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const tabs = Object.keys(presets);
    const currentTabIndex = tabs.findIndex(tab => selectedTab === tab);

    return () => {
        setSelectedTab(
            (tabs[currentTabIndex + 1] as LabsGqlGuidedWaxupPresetType) ?? LabsGqlGuidedWaxupPresetType.GeneralView,
        );
    };
}

export function useIsRejectionNoteRequired() {
    const { selectedTab } = useGuidedWaxupContext();
    const generalViewRejected = useGuidedWaxupSelector(
        s => s.presets[LabsGqlGuidedWaxupPresetType.GeneralView]?.status === LabsGqlGuidedWaxupPresetStatus.Rejected,
    );
    return selectedTab !== LabsGqlGuidedWaxupPresetType.GeneralView || generalViewRejected;
}

export function getMarginColorFilter(showUpperJaw: boolean): LimitedAppearanceFilterBools {
    const appearanceFilterValues: LimitedAppearanceFilterBools = {
        showColor: false,
        showRestos: false,
        showLower: !showUpperJaw,
        showUpper: showUpperJaw,
        showScans: true,
    };
    return appearanceFilterValues;
}

export function getOcclusalArchFilter(showUpperJaw: boolean): LimitedAppearanceFilterBools {
    const appearanceFilterValues: LimitedAppearanceFilterBools = {
        showColor: false,
        showRestos: true,
        showLower: !showUpperJaw,
        showUpper: showUpperJaw,
        showScans: true,
    };
    return appearanceFilterValues;
}

export function setToothDesignPreset(presetInputs: PresetInputs, isMobile?: boolean) {
    const { activeTooth, controlRef, presetViewControls, setAppearance, setZoom } = presetInputs;
    const appearanceFilterValues: LimitedAppearanceFilterBools = INITIAL_APPEARANCE_FILTER;
    controlRef.current?.reset?.();
    presetViewControls.generalToothViewSetter(activeTooth, 'F');
    setZoom(isMobile ? 6 : 12);
    setAppearance(currentAppearance =>
        applyAppearanceFilter(
            {
                ...currentAppearance,
                restorativeView: RestorativeView.CAD,
                showMarginLines: false,
                showDoctorMarginLines: false,
                // return to full opacity for all restorations
                restoratives: {
                    ...currentAppearance.restoratives,
                    CAD: currentAppearance.restoratives['CAD'].map(pma => ({
                        ...pma,
                        appearance: {
                            ...pma.appearance,
                            opacity: 1,
                        },
                    })),
                },
            },
            appearanceFilterValues,
        ),
    );
}

export function setContourViewPreset(presetInputs: PresetInputs) {
    const { activeTooth, controlRef, presetViewControls, setAppearance, zoomToVisible } = presetInputs;
    const showUpperJaw = ToothUtils.toothIsUpper(activeTooth);
    const appearanceFilterValues: LimitedAppearanceFilterBools = {
        showColor: true,
        showRestos: true,
        showLower: !showUpperJaw,
        showUpper: showUpperJaw,
        showScans: true,
    };
    controlRef.current?.reset?.();
    presetViewControls.generalToothViewSetter(activeTooth, 'DF');
    zoomToVisible();
    setAppearance(currentAppearance =>
        applyAppearanceFilter(
            {
                ...currentAppearance,
                restorativeView: RestorativeView.CAD,
                showMarginLines: false,
                showDoctorMarginLines: false,
                // return to full opacity for all restorations
                restoratives: {
                    ...currentAppearance.restoratives,
                    CAD: currentAppearance.restoratives['CAD'].map(pma => ({
                        ...pma,
                        appearance: {
                            ...pma.appearance,
                            opacity: 1,
                        },
                    })),
                },
            },
            appearanceFilterValues,
        ),
    );
}

export function setMarginViewPreset(presetInputs: PresetInputs, isMobile?: boolean) {
    const { activeTooth, controlRef, presetViewControls, setAppearance, setZoom } = presetInputs;
    const showUpperJaw = ToothUtils.toothIsUpper(activeTooth);
    const appearanceFilterValues: LimitedAppearanceFilterBools = {
        showColor: false,
        showRestos: false,
        showLower: !showUpperJaw,
        showUpper: showUpperJaw,
        showScans: true,
    };
    controlRef.current?.reset?.();
    presetViewControls.generalToothViewSetter(activeTooth, 'O');
    setZoom(isMobile ? 25 : 45);
    setAppearance(currentAppearance =>
        applyAppearanceFilter(
            {
                ...currentAppearance,
                restorativeView: RestorativeView.CAD,
                showMarginLines: true,
                showDoctorMarginLines: true,
                // return to full opacity for all restorations
                restoratives: {
                    ...currentAppearance.restoratives,
                    CAD: currentAppearance.restoratives['CAD'].map(pma => ({
                        ...pma,
                        appearance: {
                            ...pma.appearance,
                            opacity: 1,
                        },
                    })),
                },
            },
            appearanceFilterValues,
        ),
    );
}

export function setContactDesignPreset(presetInputs: PresetInputs, isMobile?: boolean) {
    const { activeTooth, controlRef, presetViewControls, setAppearance, setZoom } = presetInputs;
    const showUpperJaw = ToothUtils.toothIsUpper(activeTooth);
    const appearanceFilterValues: LimitedAppearanceFilterBools = {
        showColor: false,
        showRestos: true,
        showLower: !showUpperJaw,
        showUpper: showUpperJaw,
        showScans: true,
    };
    controlRef.current?.reset?.();
    presetViewControls.generalToothViewSetter(activeTooth, 'F');
    setZoom(isMobile ? 6 : 15);
    setAppearance(currentAppearance =>
        applyAppearanceFilter(
            {
                ...currentAppearance,
                restorativeView: RestorativeView.CAD,
                showMarginLines: false,
                showDoctorMarginLines: false,
                // return to full opacity for all restorations
                restoratives: {
                    ...currentAppearance.restoratives,
                    CAD: currentAppearance.restoratives['CAD'].map(pma => ({
                        ...pma,
                        appearance: {
                            ...pma.appearance,
                            opacity: 1,
                        },
                    })),
                },
            },
            appearanceFilterValues,
        ),
    );
}

export function setOcclusalAnatomyPreset(presetInputs: PresetInputs, isMobile?: boolean) {
    const { activeTooth, controlRef, presetViewControls, setAppearance, setZoom } = presetInputs;
    const showUpperJaw = ToothUtils.toothIsUpper(activeTooth);
    const appearanceFilterValues: LimitedAppearanceFilterBools = {
        showColor: false,
        showRestos: true,
        showLower: !showUpperJaw,
        showUpper: showUpperJaw,
        showScans: true,
    };
    controlRef.current?.reset?.();
    presetViewControls.generalToothViewSetter(activeTooth, 'O');
    setZoom(isMobile ? 8 : 15);
    setAppearance(currentAppearance =>
        applyAppearanceFilter(
            {
                ...currentAppearance,
                restorativeView: RestorativeView.CAD,
                showMarginLines: false,
                showDoctorMarginLines: false,
                // return to full opacity for all restorations
                restoratives: {
                    ...currentAppearance.restoratives,
                    CAD: currentAppearance.restoratives['CAD'].map(pma => ({
                        ...pma,
                        appearance: {
                            ...pma.appearance,
                            opacity: 1,
                        },
                    })),
                },
            },
            appearanceFilterValues,
        ),
    );
}

export function setGeneralView(presetInputs: PresetInputs, isMobile?: boolean) {
    const { controlRef, teethIndices, activeTooth, setAppearance, setZoom, presetViewControls, isImmediateDenture } =
        presetInputs;
    const appearanceFilterValues: LimitedAppearanceFilterBools = isImmediateDenture
        ? GUIDED_WAXUP_APPEARANCE_FILTER
        : INITIAL_APPEARANCE_FILTER;
    controlRef.current?.reset?.();
    // if any teeth in the design are anterior, we default to just the general front aligned axis view
    if (ToothUtils.isAnterior(teethIndices)) {
        presetViewControls.axisAlignedViewSetter('FRONT');
    }
    // if the previous check fails, then we know the teeth are posterior, so show a view centered on the active tooth
    // generally from the facial direction
    else {
        presetViewControls.generalToothViewSetter(activeTooth, 'F');
    }
    setZoom(isMobile ? 4 : 6);
    setAppearance(currentAppearance =>
        applyAppearanceFilter(
            { ...currentAppearance, showMarginLines: false, showDoctorMarginLines: false },
            appearanceFilterValues,
        ),
    );
}

export function usePresets(selectedTab: LabsGqlGuidedWaxupPresetType, presetInputs: PresetInputs) {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const unstableSetPreset = () => {
        switch (selectedTab) {
            case LabsGqlGuidedWaxupPresetType.ToothDesign:
                setToothDesignPreset(presetInputs, isMobile);
                break;
            case LabsGqlGuidedWaxupPresetType.MarginView:
                setMarginViewPreset(presetInputs, isMobile);
                break;
            case LabsGqlGuidedWaxupPresetType.ContourView:
                setContourViewPreset(presetInputs);
                break;
            case LabsGqlGuidedWaxupPresetType.ContactDesign:
                setContactDesignPreset(presetInputs, isMobile);
                break;
            case LabsGqlGuidedWaxupPresetType.OcclusalAnatomy:
                setOcclusalAnatomyPreset(presetInputs, isMobile);
                break;
            case LabsGqlGuidedWaxupPresetType.GeneralView:
                setGeneralView(presetInputs, isMobile);
                break;
            default:
                break;
        }
    };
    // using useStableCallback here to stabilize the callback used in the effect to include it in dependency array and avoid re-run on every render
    const setPreset = useStableCallback(unstableSetPreset);
    /**
     * This effect is responsible for updating the appearance of the model and camera view angle of the scene when a new preset is selected.
     * The guided waxup flow introduces pre-determined appearances and views of the model called 'presets', and they are toggled by navigating
     * to the different tabs within the experience. The camera view angle also depends on the active tooth, so our effect depends on
     * the selected tab (the main factor we are considering when determining the preset), the active tooth, and whether the screen is mobile
     * since the camera's zoom is adjusted on smaller screens
     * */
    React.useEffect(() => {
        setPreset();
    }, [selectedTab, presetInputs.activeTooth, setPreset, isMobile]);
}

export function usePresetSetup(inputs: {
    appearance: ModelAppearance;
    controlRef: MainViewCameraControlsRef;
    presetViewControls: PresetViewControllerFns;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    setZoom: React.Dispatch<React.SetStateAction<number>>;
}) {
    const { selectedTab, isImmediateDenture } = useGuidedWaxupContext();
    const { appearance, controlRef, presetViewControls, setAppearance, setZoom } = inputs;
    const teethIndices = useTeethIndices(appearance);
    // -1 is a placeholder value for satisfying typescript type checking
    const activeTooth = teethIndices[0] ?? -1;
    const zoomToVisible = useZoomToVisible(controlRef, appearance);
    const presetInputs: PresetInputs = {
        teethIndices,
        activeTooth,
        controlRef,
        presetViewControls,
        setAppearance,
        setZoom,
        zoomToVisible,
        isImmediateDenture,
    };

    usePresets(selectedTab, presetInputs);
}
