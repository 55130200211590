import { Button, FlossPalette, stylesFactory, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    deleteScreenshotBtn: {
        color: FlossPalette.ATTENTION,
        marginRight: 16,
    },
    markupBtnContainer: {
        position: 'absolute',
        bottom: 16,
        left: 16,
        zIndex: 1,
        [theme.breakpoints.down('lg')]: {
            padding: 16,
            textAlign: 'center',
            position: 'static',
        },
    },
}));

interface GuidedWaxupAnnotationButtonProps {
    closeDrawingCanvas: () => void;
    saveAnnotatedImage: () => Promise<void>;
    trackGuidedWaxupButtonClick: (buttonText: string) => void;
}

export const GuidedWaxupAnnotationButtons: React.VFC<GuidedWaxupAnnotationButtonProps> = ({
    closeDrawingCanvas,
    saveAnnotatedImage,
    trackGuidedWaxupButtonClick,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    return (
        <div className={classes.markupBtnContainer}>
            <Button
                variant={'secondary'}
                onClick={() => {
                    trackGuidedWaxupButtonClick('Delete Screen Capture');
                    closeDrawingCanvas();
                }}
                size={'small'}
                className={classes.deleteScreenshotBtn}
            >
                Delete Screen Capture
            </Button>
            <Button variant={isMobile ? 'primary' : 'secondary'} onClick={saveAnnotatedImage} size={'small'}>
                {isMobile ? 'Save Markup' : 'Save and Markup New Screen Capture'}
            </Button>
        </div>
    );
};
