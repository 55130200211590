import { ThreeOxzUtil } from './ThreeOxz.util';
import type { FormatGeneratorCaseData } from './ThreeOxzGenerator.types';
import { CartItemV2Utils, ItemMaterialFieldV2Utils } from '@orthly/items';
import _ from 'lodash';
import moment from 'moment';
import { create as createXML } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';

type ActorRole = 'dandy' | 'doctor' | 'patient';
type ActorData = { email?: string; communicateKey?: string; actorId: string; roleId: string };
type ActorRoleData = { id: string; name: string; value: string };
type ActorRoleMap = { [Role in RoleType]: ActorRoleData };
type RoleType = 'Clinic' | 'Operator' | 'Patient' | 'Lab';

export class ThreeOxzCaseBuilder {
    private currentIndex: number = 1;
    constructor(private readonly caseData: FormatGeneratorCaseData) {}

    private get nextIndex() {
        return this.currentIndex++;
    }

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    buildCase() {
        const creationDateUTC = this.caseData.creation_date.toISOString();

        const root = createXML({ version: '1.0' });
        const caseContainer = root.ele('CaseContainer', {
            'xmlns:z': 'http://schemas.microsoft.com/2003/10/Serialization/',
            'xmlns:a': 'http://schemas.microsoft.com/2003/10/Serialization/Arrays',
            'xmlns:i': 'http://www.w3.org/2001/XMLSchema-instance',
            xmlns: 'http://schemas.3shape.com/2014/DDT/DataAccess',
        });
        caseContainer.ele('Version').txt('1.0');
        const caseDirectory = caseContainer.ele('CaseDirectory', { 'z:Id': `i${this.nextIndex}` });

        ThreeOxzUtil.addXMLFields(caseDirectory, {
            Id: this.caseData.id,
            CreationDateUtc: creationDateUTC,
            IsDirectory: 'true',
            Name: this.caseData.id,
            Parent: { data: { 'i:nil': 'true' } },
            ServerFilePath: 'Cases',
            ServerFilePathHash: 'BEE0784F2FD5F790',
        });
        const fileSystem = caseDirectory.ele('FileSystemEntries');

        const modelPaths = ThreeOxzUtil.getModelPathRenamings(this.caseData);

        const scanIds = _.compact(
            this.caseData.scans.map<{ scanId: string; threeShapeId: number } | undefined>(scan => {
                // Find the first path which has a translation to a final model path
                const matchingPath = Object.keys(modelPaths).some(
                    candidate => scan.file_name && candidate.includes(scan.file_name),
                )
                    ? scan.file_name
                    : undefined;
                // And now we can grab what our output should be
                const fileName = matchingPath ? modelPaths[matchingPath] : undefined;

                if (!fileName) {
                    return undefined;
                }

                const threeShapeId = this.nextIndex;

                const entry = fileSystem.ele('FileSystemEntry', { 'z:Id': `i${threeShapeId}`, 'i:type': 'FileEntry' });
                ThreeOxzUtil.addXMLFields(entry, {
                    Id: scan.id,
                    CreationDateUtc: creationDateUTC,
                    IsDirectory: 'false',
                    Name: fileName,
                    Parent: { data: { 'z:Ref': 'i1' } },
                    ServerFilePath: `Cases\\${this.caseData.id}`,
                    ServerFilePathHash: 'BEE0784F2FD5F790',
                });

                return { threeShapeId, scanId: scan.id };
            }),
        );

        const caseEntry = caseContainer.ele('Case', { 'z:Id': `i${this.nextIndex}`, 'i:type': 'Case' });
        caseEntry.ele('Id').txt(this.caseData.id);
        const actorRoles = caseEntry.ele('ActorRoles');
        this.generateActor(actorRoles, 'doctor');
        this.generateActor(actorRoles, 'patient');
        this.generateActor(actorRoles, 'dandy');

        // and use correct properties
        const itemName = this.caseData.items
            .flatMap(item => CartItemV2Utils.getUniqueUNNs(item).map(unn => `${item.sku} ${unn}`))
            .join(',');

        ThreeOxzUtil.addXMLFields(caseEntry, {
            ApprovalRequests: {},
            AttachedFiles: {},
            BarCode: { data: { 'i:nil': true } },
            CaseOwners: {},
            ColorNames: { value: this.caseData.items[0]?.shades.find(s => s.name === 'base')?.value },
            Comments: { data: { 'i:nil': true } },
            SourceId: { data: { 'i:nil': true } },
            CommunicateExchangeMessages: {},
            ProductGroups: {},
            Detail: {},
            CreationDateUtc: creationDateUTC,
            DeliveryDateUtc: creationDateUTC,
            ShippingDateUtc: creationDateUTC,
            CustomFields: {},
            DeliveryType: { data: { 'i:nil': true } },
            DirectoryEntry: { data: { 'z:Ref': 'i1' } },
            ExtId: { data: { 'i:nil': true } },
            ItemNames: itemName,
            LockId: '00000000-0000-0000-0000-000000000000',
            MaterialNames: this.caseData.items[0]
                ? ItemMaterialFieldV2Utils.getSingleMaterial(this.caseData.items[0]) ?? ''
                : '',
            Name: this.caseData.id.replace(/-/g, '_'),
            Products: {},
        });
        caseEntry.ele('Importance').ele('Name').txt('Normal').up().ele('Value').txt('0');

        const scans = caseEntry.ele('Scans');
        scanIds.forEach(({ scanId, threeShapeId }) => {
            const scan = this.caseData.scans.find(scan => scan.id === scanId);

            if (!scan) {
                return;
            }

            const scanRoot = scans.ele('Scan', { 'z:Id': `i${this.nextIndex}`, 'i:type': 'SurfaceScan' });
            ThreeOxzUtil.addXMLFields(scanRoot, {
                Id: { value: scan.id },
                CreationDateUtc: { value: creationDateUTC },
                FileSystemEntry: { data: { 'z:Ref': `i${threeShapeId}` } },
                From: { value: scan.definition.type === 'jaw' && scan.definition.variant === 'lower' ? '17' : '1' },
                Internal: { value: 'false' },
                To: {
                    value: scan.definition.type === 'jaw' && scan.definition.variant === 'upper' ? '16' : '32',
                },
                PhysicallyPrepared: { value: 'false' },
                Sectioned: {
                    value: 'false',
                },
            });
            scanRoot.ele('State').ele('Name').txt('ReadyForUse').up().ele('Value').txt('8');
            scanRoot.ele('ProcessState').ele('Name').txt('Raw').up().ele('Value').txt('8');
            scanRoot
                .ele('TypeId')
                .ele('Name')
                .txt(scan.definition.type === 'bite' ? 'Bite' : 'Jaw')
                .up()
                .ele('Value')
                .txt(scan.definition.type === 'bite' ? '3' : '1');
        });
        caseEntry.ele('CustomFields');

        return root;
    }

    private getActorData(whom: ActorRole): ActorData {
        switch (whom) {
            case 'doctor':
                return {
                    roleId: 'aa82f8a1-2967-440f-b0c9-4d27c721e689',
                    actorId: this.caseData.doctor_preferences_id,
                    communicateKey: this.caseData.doctor_preferences_id,
                    email: `automated+${this.caseData.doctor_preferences_id}@meetdandy.com`,
                };
            case 'dandy':
                return {
                    roleId: '75be02cf-dfc5-41e7-9aee-9e1a8b78648c',
                    actorId: 'deadbeef-cafe-4004-abba-facadefacade',
                    communicateKey: '813c1fac-9d5b-44cd-9c64-59c83cf55cec',
                    email: 'automated+dandy@meetdandy.com',
                };
            case 'patient':
                return {
                    roleId: '9b613ccb-9cd3-4310-a081-8b5600da0260',
                    actorId: this.caseData.patient.id,
                    communicateKey: undefined,
                    email: undefined,
                };
        }
    }

    private get ActorRoleData(): ActorRoleMap {
        return {
            Clinic: {
                id: this.caseData.doctor_preferences_id,
                name: 'Clinic',
                value: '2',
            },
            Operator: {
                id: this.caseData.doctor_preferences_id,
                name: 'Operator',
                value: '5',
            },
            Patient: {
                id: this.caseData.patient.id,
                name: 'Patient',
                value: '1',
            },
            Lab: {
                id: 'deadbeef-cafe-4004-abba-facadefacade',
                name: 'Lab',
                value: '3',
            },
        };
    }

    generateActorRole(root: XMLBuilder, type: RoleType) {
        const data = this.ActorRoleData[type];

        const actorRole = root.ele('ActorRole', { 'z:Id': `i${this.nextIndex}` });
        actorRole.ele('Id').txt(data.id);
        actorRole.ele('TypeId').ele('Name').txt(data.name).up().ele('Value').txt(data.value);
    }

    private generateActor(root: XMLBuilder, whom: ActorRole) {
        const actorData = this.getActorData(whom);
        const role = root.ele('CaseActorRole', { 'z:Id': `i${this.nextIndex}` });
        role.ele('Id').txt(actorData.roleId);
        const actor = role.ele('Actor', { 'z:Id': `i${this.nextIndex}`, 'i:type': 'Actor' });
        const accounts = actor.ele('Accounts');
        const account = accounts.ele('Account', { 'z:Id': `i${this.nextIndex}`, 'i:type': `CommunicateAccount` });

        ThreeOxzUtil.addXMLFields(account, {
            Id: { value: actorData.actorId },
            AutoSend: { value: 'false' },
            CommunicateKey: {
                value: actorData.communicateKey,
                data: actorData.communicateKey === undefined ? { 'i:nil': 'true' } : undefined,
            },
            Email: { value: actorData.email, data: actorData.email === undefined ? { 'i:nil': 'true' } : undefined },
            Enabled: { value: 'true' },
            RequiresApproval: { value: 'false' },
            AllowedForOtherUsers: { value: 'false' },
            Password: { data: { 'i:nil': 'true' } },
        });
        account.ele('TypeId').ele('Name').txt('Communicate').up().ele('Value').txt('1');
        ThreeOxzUtil.addXMLFields(
            actor,
            [
                'AddressLine1',
                'AddressLine2',
                'City',
                'Country',
                'DirectoryEntry',
                'Email',
                'ExtId',
                'PhoneNumber',
                'PostCode',
                'State',
            ].reduce(
                (state, name) => ({
                    ...state,
                    [name]: { data: { 'i:nil': 'true' } },
                }),
                {},
            ),
        );
        actor.ele('CustomFields');
        ThreeOxzUtil.addXMLFields(actor, {
            LockId: { value: '00000000-0000-0000-0000-000000000000' },
            Name: {
                value:
                    whom === 'doctor'
                        ? this.caseData.doctor_preferences_id
                        : `${this.caseData.patient.first_name} ${this.caseData.patient.last_name}`,
            },
            Persons: {},
        });
        const roles = actor.ele('Roles');

        if (whom === 'doctor') {
            this.generateActorRole(actor, 'Clinic');
            this.generateActorRole(actor, 'Operator');
        }

        if (whom === 'dandy') {
            this.generateActorRole(actor, 'Lab');
        }

        if (whom === 'patient') {
            const person = actor.ele('Persons').ele('Person', { 'z:Id': `i${this.nextIndex}` });
            ThreeOxzUtil.addXMLFields(person, {
                Id: { value: this.caseData.patient.id },
                DateOfBirth: { value: `${moment(this.caseData.patient.birthday).format('YYYY-MM-DD')}T00:00:00` },
                FirstName: { value: this.caseData.patient.first_name },
                LastName: { value: this.caseData.patient.last_name },
                Gender: { value: '0' },
                SSN: { data: { 'i:nil': 'true' } },
            });
            this.generateActorRole(actor, 'Patient');
            const actorRole = roles.ele('ActorRole', { 'z:Id': `i${this.nextIndex}` });
            actorRole.ele('Id').txt(this.ActorRoleData['Patient'].id);
            actorRole.ele('TypeId').ele('Name').txt('Patient').up().ele('Value').txt('1');
        }
    }
}
