import type { MarginCameraPose } from './MarginMesh';
import { MarginSliderMode, getCameraPoseOnMargin } from './MarginMesh';
import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import type { MarginLine } from '@orthly/shared-types';
import React from 'react';
import * as THREE from 'three';

const cPos = new THREE.Vector3();
const cQuat = new THREE.Quaternion();
const cScale = new THREE.Vector3();

interface MeshRef {
    position: THREE.Vector3;
    rotation: THREE.Euler;
}

export function useMarginSlider(
    controlRef: MainViewCameraControlsRef,
    marginLines: MarginLine[],
    marginLineSlider?: { toothNumber: number; variant: MarginSliderMode; marginLine?: MarginLine },
) {
    const zRef = React.useRef<MeshRef | undefined>();

    const [marginSliderPercentage, setMarginSliderPercentage] = React.useState(0.0);
    const onMarginSliderChange = React.useCallback((newMargin: number) => {
        if (newMargin > 1.0) {
            setMarginSliderPercentage(0.0);
        } else {
            setMarginSliderPercentage(newMargin);
        }
    }, []);

    React.useEffect(() => {
        const camera = controlRef.current?.object;

        if (!camera || (marginLines.length === 0 && !marginLineSlider)) {
            return;
        }

        const margin = marginLineSlider?.marginLine ?? marginLines.find(m => m.tooth === marginLineSlider?.toothNumber);

        if (!margin || !marginLineSlider) {
            return;
        }

        const camPose: MarginCameraPose = getCameraPoseOnMargin(
            margin,
            marginSliderPercentage,
            marginLineSlider.variant,
        );
        camPose.rotMatrix.decompose(cPos, cQuat, cScale);

        const nCoords = margin?.mb_coords?.length ?? 0;
        const alpha = marginSliderPercentage * nCoords - Math.floor(marginSliderPercentage * nCoords);

        const thisIndex = Math.floor(marginSliderPercentage * nCoords) % nCoords;
        const nextIndex = (thisIndex + 1) % nCoords;

        const thisMarginCoord = margin?.mb_coords?.[thisIndex];
        const thisPoint = new THREE.Vector3(thisMarginCoord?.x, thisMarginCoord?.y, thisMarginCoord?.z);

        const nextMarginCoord = margin?.mb_coords?.[nextIndex];
        const nextPoint = new THREE.Vector3(nextMarginCoord?.x, nextMarginCoord?.y, nextMarginCoord?.z);

        const tracerPos = thisPoint.lerp(nextPoint, alpha);

        if (!zRef || !controlRef) {
            return;
        }

        camera.position.copy(camPose.locVector);
        if (marginLineSlider.variant !== MarginSliderMode.Helicopter) {
            camera.up.copy(new THREE.Vector3(0, -1, 0).applyQuaternion(cQuat));
        }

        camera.zoom = 100;

        // We do this in order to make sure the update sequence is correct between camera and controls
        controlRef.current?.target.copy(tracerPos);

        camera.updateProjectionMatrix();
        controlRef.current?.update?.();

        zRef.current?.position?.copy(camPose.locVector);
    }, [marginSliderPercentage, marginLineSlider, marginLines, controlRef]);

    return { onMarginSliderChange, marginSliderPercentage };
}
