import { ActionCard } from '@orthly/ui';
import React from 'react';

export const ModelViewerFallbackComponent: React.FC = () => {
    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2rem',
            }}
        >
            <ActionCard
                variant={'alert'}
                title={'Failed to load model'}
                subtitle={
                    'Something unexpected happened, and we failed to load the model. Please try reloading the page, or contact support if the problem persists.'
                }
            />
        </div>
    );
};
