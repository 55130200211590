import { AnalyticsClient } from '../../analytics/analyticsClient';
import logo from '../../assets/icons/dandy_logo_green.svg';
import person from '../../assets/icons/person_green.svg';
import scanner from '../../assets/icons/scanner_green.svg';
import { useCrmPractice } from '../inbox/state/useCRMPractice';
import { GettingStartedLink } from './components/GettingStartedLink/GettingStartedLink';
import { TrainingDataIntakeCard } from './components/TrainingDataIntake/TrainingDataIntakeCard';
import { TrainingDataIntakeCompletedCards } from './components/TrainingDataIntake/TrainingDataIntakeCompletedCards';
import { EquipmentTracker } from './components/equipment-tracker/EquipmentTracker';
import { useGetPracticeTrainingDateQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { FlossPalette, stylesFactory, useScreenIsMobile, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        background: FlossPalette.TAN,
        height: '100vh',
        padding: '40px 56px 48px 56px',
        [theme.breakpoints.down('sm')]: {
            padding: '40px 24px 48px 24px',
        },
    },
    outerWrapper: {
        maxWidth: '1418px',
        margin: '0 auto 0 auto',
    },
    divider: {
        margin: '40px 0 40px 0',
        display: 'block',
        height: 1,
        border: 0,
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    },
    columns: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        gap: '24px',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'wrap',
        },
    },
    buttonsLabel: {
        marginBottom: '24px',
    },
    buttons: {
        width: '816px',
        display: 'flex',
        gap: '24px',
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },
    },
    tracker: {
        flexGrow: 1,
    },
    modulesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 28,
        minWidth: '336px',
        maxWidth: '696px',
    },
}));

export const GettingStartedRoot: React.FC<{ isByod: boolean }> = ({ isByod }) => {
    const session = useSession();
    const practiceId = session?.organization_id ?? '';
    const classes = useStyles();
    const isMobile = useScreenIsMobile();

    const { value: isEquipmentTrackerEnabled = false } = useFeatureFlag('enableEquipmentTracker');
    // If the forceOnboarding flag is on, we display the equipment
    // tracker with fake tracking numbers for testing purposes
    const { value: forceOnboarding } = useFeatureFlag('forceOnboarding');

    const { data: practiceInfo } = useGetPracticeTrainingDateQuery({
        variables: {
            practiceId,
        },
        skip: !practiceId,
    });

    const { data: rawCRMPracticeData, refetch } = useCrmPractice();

    const trainingUpcoming =
        !practiceInfo?.getPracticeTrainingDate || dayjs() <= dayjs(practiceInfo.getPracticeTrainingDate);

    React.useEffect(() => {
        AnalyticsClient.track('Practice - Onboarding - Onboarding Home Loaded', { screen: 'OnboardingInbox' });
    }, []);

    const showEquipmentTracker = !isByod && trainingUpcoming && isEquipmentTrackerEnabled;

    const showTrainingDataIntake = !!rawCRMPracticeData && !rawCRMPracticeData.getCRMPractice.has_completed_training;
    const showLinkCards = rawCRMPracticeData?.getCRMPractice.has_completed_training;

    return (
        <div className={classes.root}>
            <div className={classes.outerWrapper}>
                <Text variant={isMobile ? 'h4' : 'h3'} medium>
                    Getting started with Dandy
                </Text>
                <Text variant={isMobile ? 'body2' : 'body1'} color={'DARK_GRAY'} style={{ marginTop: 8 }}>
                    While you’re waiting for your training session, you can familiarize yourself with Dandy so you know
                    everything you need to get started.
                </Text>
                <hr className={classes.divider} />
                <div className={classes.columns}>
                    <div>
                        <Text className={classes.buttonsLabel} variant={'h6'} style={{ color: FlossPalette.GRAY }}>
                            Explore our onboarding resources
                        </Text>
                        <div className={classes.buttons}>
                            <GettingStartedLink
                                icon={logo}
                                iconAlt={'Dandy logo'}
                                title={'The Dandy Ecosystem'}
                                body={
                                    'Learn about the parts of the Dandy Ecosystem and how they  work together to drive better outcomes for your practice.'
                                }
                                externalLink={'help-dandy-ecosystem'}
                            />
                            <GettingStartedLink
                                icon={scanner}
                                iconAlt={'Intraoral scanner'}
                                title={'Equipment and Software Setup'}
                                body={"If you've received all your equipment, start here to complete your setup!"}
                                externalLink={'help-setup'}
                            />
                            <GettingStartedLink
                                icon={person}
                                iconAlt={'Person'}
                                title={'Profile Creation and Preferences'}
                                body={
                                    'Ensure your practice gets the most value by customizing your preferences, managing staff profiles, and more.'
                                }
                                externalLink={'help-profile-creation'}
                            />
                        </div>
                    </div>

                    {(showTrainingDataIntake || showLinkCards || showEquipmentTracker || forceOnboarding) && (
                        <div className={classes.modulesContainer}>
                            {(showTrainingDataIntake || (forceOnboarding && !showLinkCards)) && (
                                <TrainingDataIntakeCard refetchTrainingData={refetch} />
                            )}
                            {showLinkCards && <TrainingDataIntakeCompletedCards />}
                            {(showEquipmentTracker || forceOnboarding) && <EquipmentTracker />}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
