/* eslint-disable max-lines */
import {
    IDENTITY_MATRIX,
    ensureMeshIndex,
    extractFacetsToNewGeometry,
    getSomeSamplesFromVector3BufferAttrib,
} from '../../Utils3D';
import { logger } from '../../Utils/Logger';
import type { DcmGeometryInjector, DcmSpline } from '../DcmFiles';
import { parseRestorativeFacetMark } from '../DcmFiles/DcmFacetMark';
import type { InterfacesMarginsMap } from '../DentalDesignerModellingTree';
import { ModellingTree } from '../DentalDesignerModellingTree';
import { extractModellingTree } from '../DesignArchive';
import type { ModelElement, ParsedCaseResult, ToothElement } from '../DesignCaseFile';
import { DENTURE_TOOTH_TYPES, DesignCaseFileParser, DesignCaseFileUtil } from '../DesignCaseFile';
import { CrownAndBridgeMarginToothTypes } from '../DesignCaseFile/DesignCaseFile.util';
import { getInsertionAxes2 } from '../InsertionAxes';
import { cleanXmlNameForComparison } from '../LegacyCaseParsing';
import type {
    AnatomyDcmProcessingIntermediary,
    DcmProcessingIntermediary,
    DesignProjectAsset,
    DesignProjectCadAsset,
    ExistingFiles,
} from './DesignZipReading.types';
import type { ThreeShapeDesignTransformations } from './DesignZipTypes';
import { getCadTransform, getDcmInjector, parseAnatomyDcmsToIntermediary, parseDcmsToIntermediary } from './utils';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import type { ArrayN } from '@orthly/runtime-utils';
import { FileNameUtils, Format } from '@orthly/runtime-utils';
import type { ILogger, InternalDesignMetadata, MarginLine, Number3Array } from '@orthly/shared-types';
import { Jaw } from '@orthly/shared-types';
import type JsZip from 'jszip';
import _ from 'lodash';
import path from 'path';
import percentile from 'percentile';
import { xorshift128plus } from 'pure-rand';
import type { BufferAttribute, InterleavedBufferAttribute } from 'three';
import * as THREE from 'three';
import type { HitPointInfo, MeshBVH } from 'three-mesh-bvh';

// TODO, move to Case Parser

const MAX_INTAGLIO_AND_SCAN_GAP_MM = 2.0;
const MAX_RAW_SCAN_TRANSFORMED_AND_MB_SCAN_GAP_MM = 2.0;
const VERTEX_DISTANCE_SAMPLE_COUNT = 3000;

//init a HitPointInfo object with a Vector3 that we can reuse to save from allocated a bunch of Vector3s
const TEMP_HIT_POINT_INFO: HitPointInfo = {
    point: new THREE.Vector3(),
    distance: 0,
    faceIndex: 0,
};

function fileFolderPatternMatch(files: [string, JsZip.JSZipObject][], pattern: RegExp): [string, JsZip.JSZipObject][] {
    return _.compact(
        files.map<[string, JsZip.JSZipObject] | undefined>((f: [string, JsZip.JSZipObject]) =>
            f[0].match(pattern) ? f : undefined,
        ),
    );
}

// We expect
/*(A case from 3Shape Dental Designer)
 * /designRootFolderName
 *       /Scans
 *           /Upper
 *               MB ${Jaw} Preparation Scan
 *               MB ${Jaw} Antagonist Scan
 *           /Lower
 *               MB ${Jaw} Preparation Scan
 *               MB ${Jaw} Antagonist Scan
 *           /Misc
 *               Raw Bite scan.dcm
 *               ...maybe others
 *       /CAD
 *           ${CadName}.dcm (one or many)
 *       /ExtraData*
 *           $Crown${UNN}.dcm (one or many)
 */

/**
 * A function with reports the existence of files in expected directories
 * based on typical 3Shape folder and naming structure.  Used to explore
 * and validate expectations before converting and processing
 * @param caseZip
 * @param designRootFolderName - expected to be the same as the basename of the original zip folder
 *
 */
export function getExistingFiles(caseZip: JsZip, designRootFolderName: string): ExistingFiles | undefined {
    // Sometimes design archives have a "Backup" directory with a "Scans" subdirectory. We do not want to process the
    // files contained in the "Backup" directory.
    const fileNamesAndFiles = Object.entries(caseZip.files).filter(([fname]) => {
        return fname.startsWith(designRootFolderName) && !fname.startsWith(`${designRootFolderName}/Backup`);
    });

    const mbScanRegex = /(Upper|Lower).*(MB Preparation|MB Antagonist)/;
    const mbScanFiles = fileFolderPatternMatch(fileNamesAndFiles, mbScanRegex);

    // TODO strict enough to === 2
    const hasMBScans = mbScanFiles.length > 0;

    const cadRegex = /(CAD).*\.dcm/i;
    const cadFileNameAndFiles = fileFolderPatternMatch(fileNamesAndFiles, cadRegex);

    const anatomyRegex = /(Anatomy elements).*\.dcm/i;
    const anatomyFileNameAndFiles = fileFolderPatternMatch(fileNamesAndFiles, anatomyRegex);

    const scansRegex = /(Scans).*\.dcm/i;
    const scanFileNameAndFiles = fileFolderPatternMatch(fileNamesAndFiles, scansRegex);

    const extraDataRegex = /(ExtraData).*\.dcm/i;
    const extraDataFileNameAndFiles = fileFolderPatternMatch(fileNamesAndFiles, extraDataRegex);

    function findPrimaryDesignScan(
        files: [string, JsZip.JSZipObject][],
        jaw: Jaw,
        hasMBScans: boolean,
    ): [string, JsZip.JSZipObject] | undefined {
        function findOnePrimaryDesignScan(
            files: [string, JsZip.JSZipObject][],
            jaw: Jaw,
            type: string,
        ): [string, JsZip.JSZipObject] | undefined {
            const strJaw = jaw === Jaw.UPPER ? 'Upper' : 'Lower';
            // First try to find the Preparation scan
            const preparationFile = files.find(f => new RegExp(`${strJaw}.*(${type} Preparation)`).test(f[0]));
            if (preparationFile) {
                return preparationFile;
            }
            // If the preparation scan is not found, then look for Antagonist scan
            return files.find(f => new RegExp(`${strJaw}.*(${type} Antagonist)`).test(f[0]));
        }

        if (hasMBScans) {
            return findOnePrimaryDesignScan(files, jaw, 'MB');
        } else {
            return findOnePrimaryDesignScan(files, jaw, 'Raw');
        }
    }

    const upperMbScanFileNameAndFile = findPrimaryDesignScan(fileNamesAndFiles, Jaw.UPPER, hasMBScans);

    const lowerMbScanFileNameAndFile = findPrimaryDesignScan(fileNamesAndFiles, Jaw.LOWER, hasMBScans);

    const materialsFileNameAndFile = fileNamesAndFiles.find(f => f[0].includes(`Materials.xml`));

    const caseXmlFileNameAndFile = fileNamesAndFiles.find(
        f => f[0] === `${designRootFolderName}/${designRootFolderName}.xml`,
    );
    if (!caseXmlFileNameAndFile) {
        return undefined;
    }

    const ddmtFileNameAndFile = fileNamesAndFiles.find(f =>
        f[0].includes(`${designRootFolderName}/DentalDesignerModellingTree.3ml`),
    );

    return {
        cadFiles: cadFileNameAndFiles,
        extraData: extraDataFileNameAndFiles,
        anatomyFiles: anatomyFileNameAndFiles,
        upperMbScanFile: upperMbScanFileNameAndFile,
        lowerMbScanFile: lowerMbScanFileNameAndFile,
        caseXmlFile: caseXmlFileNameAndFile[1],
        ddmtFile: ddmtFileNameAndFile?.[1],
        materialsFile: materialsFileNameAndFile?.[1],
        scanFiles: scanFileNameAndFiles,
    };
}

export function getDesignRootFolderName(caseZip: JsZip): string {
    // NB: JsZip doesn't seem to detect the root folder of 3Shape design archives as a directory. Instead, we have to
    // parse the root folder name from the absolute paths of the files in the archive.

    const firstFileName = Object.keys(caseZip.files)[0];
    if (!firstFileName) {
        throw new Error('No files in case archive');
    }

    const [designRootFolderName, ...rest] = firstFileName.split('/');
    // `designRootFolderName` will always be a string because `split` returns an array with at least one element, even
    // if the split string is not found. However, TS doesn't realize this, so we check it below.
    if (!rest.length || typeof designRootFolderName !== 'string') {
        throw new Error('No base directory in case archive');
    }

    if (Object.keys(caseZip.files).some(f => !f.startsWith(designRootFolderName))) {
        throw new Error('Multiple root folders in case archive');
    }

    return designRootFolderName;
}

export function getIsDesignRootFolderNameValid(caseZip: JsZip, expectedRootFolderName: string): boolean {
    const filenames = Array.from(Object.keys(caseZip.files));
    if (!filenames.length) {
        return false;
    }

    const expectedDir = `${expectedRootFolderName}/`;

    for (const fname of Object.keys(caseZip.files)) {
        if (fname.startsWith(expectedDir)) {
            return true;
        }
    }

    return false;
}

// TODO, unify with DesignEditor util.ts
export async function getModellingTree(
    ddmtFile: JsZip.JSZipObject,
    parsedCase: ParsedCaseResult,
): Promise<ModellingTree | undefined> {
    const ddmtBuffer = await ddmtFile.async('nodebuffer');
    const ddmtText = await extractModellingTree(ddmtBuffer as Buffer);
    if (!ddmtText) {
        throw new Error('Failed to extract modelling tree from case archive');
    }

    const numImplants = DesignCaseFileUtil.getImplantUnns(parsedCase.toothElements).length;
    const numCrowns = DesignCaseFileUtil.getNonImplantCrownItems(
        parsedCase.toothElements,
        parsedCase.modelElements,
    ).length;

    logger.info(`This case has ${numImplants} Implant items and ${numCrowns} Crown Items`);
    const modellingTree = ModellingTree.tryBuildModellingTree(ddmtText, {
        hasCrowns: numCrowns > 0,
        hasImplants: numImplants > 0,
        allowMultiUnit: true, //numCrowns > 1,
    });

    if (!modellingTree) {
        logger.warn('Failed to parse modelling tree');
    }

    return modellingTree;
}

/**
 * Compares the files found in the CAD directory of the design archive to what was expected based on the case file.
 * @returns The names of missing and extra CAD files
 */
function validateExistingFilesAndCase(
    existingFiles: ExistingFiles,
    parsedCase: ParsedCaseResult,
    designRootFolderName: string,
): { missingCadFileNames: string[]; extraCadFileNames: string[] } {
    // Crown, Bridge, Abutment, DentureTeeth, DentureGums are meIndicationRegular and distinguished by toothElements.
    // Nightguard has its own modelElementType.
    const expectedCADFiles: ModelElement[] = parsedCase.modelElements.filter(
        me => ['meIndicationRegular', 'meSplint'].includes(me.modelType) && me.modelFilePath,
    );

    const missingCadFileNames = _.compact(
        expectedCADFiles.map((mE: ModelElement) => {
            if (
                !existingFiles.cadFiles.some((ele: [string, JsZip.JSZipObject]) => {
                    return (
                        cleanXmlNameForComparison(ele[0]) ===
                        cleanXmlNameForComparison(`${designRootFolderName}/${mE.modelFilePath}`)
                    );
                })
            ) {
                return mE.modelFilePath;
            }
        }),
    );

    // Check for extra files that are along for the ride.
    const extraCadFileNames = _.compact(
        existingFiles.cadFiles.map((ele: [string, JsZip.JSZipObject]) => {
            if (
                !expectedCADFiles.find((mE: ModelElement) => {
                    // make sure any / or \\ or leading / from windows machines is
                    // comparable to our jszip reports
                    return (
                        cleanXmlNameForComparison(ele[0]) ===
                        cleanXmlNameForComparison(`${designRootFolderName}/${mE.modelFilePath}`)
                    );
                })
            ) {
                return ele[0];
            }
        }),
    );

    return { missingCadFileNames, extraCadFileNames };
}

function checkAreMarginsValid(
    parsedCase: ParsedCaseResult,
    rawMarginsDdmt: InterfacesMarginsMap | undefined,
    logger: ILogger | undefined,
): boolean {
    if (!rawMarginsDdmt) {
        logger?.warn('Failed to parse margin lines from DentalDesignerModellingTree');
        return true;
    }

    const expectedMarginToothElementIds = _.compact(
        parsedCase.toothElements.map((tE: ToothElement) => {
            return CrownAndBridgeMarginToothTypes.includes(tE.cacheToothTypeClass) ? tE.toothElementID : undefined;
        }),
    );

    if (expectedMarginToothElementIds.length !== rawMarginsDdmt.size) {
        logger?.warn('Did not get the expected number of margins from DentalDesignerModellingTree');
        return false;
    }

    return true;
}

function processMargins(
    parsedCase: ParsedCaseResult,
    rawMarginsDdmt: InterfacesMarginsMap | undefined,
    designTransforms: ThreeShapeDesignTransformations,
    logger: ILogger | undefined,
): Map<ToothNumber, MarginLine> {
    const processedPtsMap = new Map<ToothNumber, MarginLine>();
    if (!rawMarginsDdmt) {
        return processedPtsMap;
    }

    for (const [teId, points] of rawMarginsDdmt) {
        const rawPointsArray: Number3Array[] = points.map(vec => [vec.x, vec.y, vec.z]);
        const toothElement = parsedCase.toothElements.find((tE: ToothElement) => tE.toothElementID === teId);
        if (!toothElement) {
            logger?.warn('failed to find tooth element');
            continue;
        }

        const unn = toothElement.toothNumber;
        if (!ToothUtils.isToothNumber(unn)) {
            throw new Error('Non tooth number unn');
        }
        if (ToothUtils.toothIsUpper(toothElement.toothNumber)) {
            const appliedMatrix = designTransforms.upperJawToLowerJaw ?? IDENTITY_MATRIX;
            processedPtsMap.set(unn, {
                tooth: unn,
                coords: rawPointsArray,
                transformationMatrix: appliedMatrix.toArray(),
                mb_coords: points.map(v => v.clone().applyMatrix4(appliedMatrix)),
            });
        } else {
            processedPtsMap.set(unn, {
                tooth: unn,
                coords: rawPointsArray,
                transformationMatrix: IDENTITY_MATRIX.toArray(),
                mb_coords: points,
            });
        }
    }

    return processedPtsMap;
}

function getDoctorMarginsFromDcm(dcm: DcmGeometryInjector): DcmSpline[] {
    const allSplines = dcm.parseSplines();
    const doctorMargins = allSplines.filter(spline => spline.name.startsWith('MarginLine_'));
    return doctorMargins;
}
export function getDoctorMarginMap(scanDcms: DcmGeometryInjector[]): Map<ToothNumber, THREE.Vector3[]> {
    const doctorMarginMap = new Map<ToothNumber, THREE.Vector3[]>();

    scanDcms.flatMap(getDoctorMarginsFromDcm).forEach((ml: DcmSpline) => {
        const { points, name } = ml;
        const possibleUNN = _.toNumber(name.split('_')[1]);
        const confirmedUNN = ToothUtils.isToothNumber(possibleUNN) ? possibleUNN : undefined;
        if (confirmedUNN) {
            doctorMarginMap.set(confirmedUNN, points);
        }
    });

    return doctorMarginMap;
}

export interface MinimallyProcessedDesignAssets {
    cadAssets: DesignProjectCadAsset[];
    extraDataAssets: DesignProjectAsset[];
    allScanAssets: DesignProjectAsset[];
    upperMbScan?: DesignProjectAsset;
    lowerMbScan?: DesignProjectAsset;
    parsedCase: ParsedCaseResult;
    modellingTree?: ModellingTree;
    margins: Map<ToothNumber, MarginLine>;
    doctorMargins: Map<ToothNumber, THREE.Vector3[]>;
    insertionAxes: Map<string, THREE.Vector3>;
    implantToothNumbers: Set<ToothNumber>;
    designTransforms: ThreeShapeDesignTransformations;
    missingCadFileNames: string[];
    extraCadFileNames: string[];
    errorMessages: string[];
}

/**
 * Returns the maximum min-distance found between a random sampling of the given vertices and the given BVH
 * @param positions - position vertices (stored like [x1, y1, z1, x2, y2, z2, ...]
 * @param compareBVH
 * @param sampleSize - number of vertices to randomly sample
 * @param sampleSeed - optional seed to use for random sampling
 * @returns The p95 distance
 */
function getP95SampleDistance(
    positions: BufferAttribute | InterleavedBufferAttribute,
    compareBVH: MeshBVH,
    sampleSize: number,
    sampleSeed: number = 1234,
): number {
    const sampleCount = Math.min(sampleSize, positions.count);

    const distances = getSomeSamplesFromVector3BufferAttrib(sampleCount, xorshift128plus(sampleSeed), positions).map(
        pos => {
            compareBVH.closestPointToPoint(pos, TEMP_HIT_POINT_INFO, 0);
            return TEMP_HIT_POINT_INFO.distance ?? -1;
        },
    );

    // TODO EPDCAD-956, consolidate to a specific percentile, likely p95 or p90
    const bins = percentile([5, 10, 25, 50, 75, 90, 95, 100], distances) as ArrayN<number, 8>;

    // Return p95
    return bins[6];
}

/**
 * Returns the calculated distance bins from a random sampling of vertices against a BVH.
 * @param positions - Vertex positions.
 * @param compareBVH - BVH to compare against.
 * @param debugLogger - Optional logger for debug information.
 * @param sampleSize - Number of vertices to sample.
 * @param sampleSeed - Seed for random sampling.
 * @returns Array of distance bins.
 */
export function getBinsSampleDistance(
    positions: BufferAttribute | InterleavedBufferAttribute,
    transformationMatrix: THREE.Matrix4 | undefined,
    compareBVH: MeshBVH,
    sampleSize: number,
    sampleSeed: number = 1234,
): number[] {
    const invertTransformationMatrix = transformationMatrix?.clone().invert();

    const sampleCount = Math.min(sampleSize, positions.count);
    const reusableVec = new THREE.Vector3();
    const distances = getSomeSamplesFromVector3BufferAttrib(sampleCount, xorshift128plus(sampleSeed), positions).map(
        pos => {
            reusableVec.copy(pos);
            if (invertTransformationMatrix) {
                reusableVec.applyMatrix4(invertTransformationMatrix);
            }
            compareBVH.closestPointToPoint(reusableVec, TEMP_HIT_POINT_INFO, 0);
            return TEMP_HIT_POINT_INFO.distance ?? -1;
        },
    );

    const bins = percentile([5, 10, 25, 50, 75, 90, 95, 100], distances) as number[];

    return bins;
}

/**
 * Get the transformation matrix based on the jaw and available transformations.
 * This function checks various conditions to decide the applicable transformation.
 * @param jaw The jaw (UPPER or LOWER) for which to get the transformation.
 * @param transforms Object containing possible transformation matrices.
 * @returns The applicable transformation matrix or undefined if no operation should be performed.
 */
function getRawToMBTransform(jaw: Jaw, transforms: ThreeShapeDesignTransformations): THREE.Matrix4 | undefined {
    switch (jaw) {
        case Jaw.UPPER:
            if (transforms.upperAlignToBite !== undefined) {
                // Both AlignToBite and UpperJaw2LowerJaw are defined
                return transforms.upperAlignToBite;
            } else if (transforms.upperAlignToBite === undefined && transforms.upperJawToLowerJaw !== undefined) {
                // Only UpperJaw2LowerJaw is defined
                return transforms.upperJawToLowerJaw;
            }
            // If none of the conditions met, return undefined
            break;

        case Jaw.LOWER:
            if (transforms.lowerAlignToBite !== undefined) {
                // For the lower jaw, check if AlignToBite is defined
                return transforms.lowerAlignToBite;
            }
            // If AlignToBite is undefined, return undefined
            break;
    }

    // Default case when no applicable transformation is found
    return undefined;
}

/**
 * Helper function to process a single scan asset and retrieve the geometry data.
 * @param assetFile Asset file to process. Commonly found in the `ExistingFiles` interface. EG `ExistingFiles.scanFiles[0][1]`
 * @param partialData Some metadata that will be copied directly into the output structure.
 * @param errorMessages List that error messages should be appended to.
 * @returns The processed project asset.
 */
async function processSingleScan(
    assetFile: JsZip.JSZipObject,
    partialData: Pick<DesignProjectAsset, 'roleType' | 'sourceFile' | 'stub'>,
    errorMessages: string[],
): Promise<DesignProjectAsset | undefined> {
    const dcmGeomInjector = await getDcmInjector(assetFile, errorMessages);
    return dcmGeomInjector
        ? {
              geom: dcmGeomInjector.buildGeometry({ applyTextureCoords: true }),
              imgTexture: dcmGeomInjector.parseTextureImages()[0],
              injector: dcmGeomInjector,
              ...partialData,
          }
        : undefined;
}

/**
 * Processes upper and lower MB scans.
 * @param designFiles Files to process.
 * @returns Set of data representing the processed assets, and error strings.
 */
export async function processMBScans(designFiles: Pick<ExistingFiles, 'upperMbScanFile' | 'lowerMbScanFile'>): Promise<{
    upperMBAsset?: DesignProjectAsset;
    lowerMBAsset?: DesignProjectAsset;
    errorMessages: string[];
}> {
    const { upperMbScanFile, lowerMbScanFile } = designFiles;

    const errorMessages: string[] = [];

    const upperMBAsset = upperMbScanFile
        ? await processSingleScan(
              upperMbScanFile[1],
              {
                  roleType: 'MBScan',
                  sourceFile: 'Upper MB',
                  stub: FileNameUtils.removeFirstDirectory(upperMbScanFile[0]),
              },
              errorMessages,
          )
        : undefined;
    const lowerMBAsset = lowerMbScanFile
        ? await processSingleScan(
              lowerMbScanFile[1],
              {
                  roleType: 'MBScan',
                  sourceFile: 'Lower MB',
                  stub: FileNameUtils.removeFirstDirectory(lowerMbScanFile[0]),
              },
              errorMessages,
          )
        : undefined;

    return {
        upperMBAsset: upperMBAsset,
        lowerMBAsset: lowerMBAsset,
        errorMessages: errorMessages,
    };
}

// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
export async function getMinimallyProcessedAssets(
    designFiles: ExistingFiles,
    designRootFolderName: string,
    onError: (err: string) => void,
    logger: ILogger | undefined,
): Promise<MinimallyProcessedDesignAssets | undefined> {
    const { cadFiles, anatomyFiles, caseXmlFile, ddmtFile, scanFiles } = designFiles;

    const parsedCase = DesignCaseFileParser.parseDesignCase(await caseXmlFile.async('string'));
    const modellingTree = ddmtFile ? await getModellingTree(ddmtFile, parsedCase) : undefined;

    const { missingCadFileNames, extraCadFileNames } = validateExistingFilesAndCase(
        designFiles,
        parsedCase,
        designRootFolderName,
    );

    const errorMessages: string[] = [];

    const { upperMBAsset, lowerMBAsset, errorMessages: mbScanErrors } = await processMBScans(designFiles);
    errorMessages.push(...mbScanErrors);

    const designTransforms: ThreeShapeDesignTransformations = {
        upperJawToLowerJaw: modellingTree?.getUpper2LowerMatrix(),
        upperAlignToBite: upperMBAsset?.injector?.getAlignToBiteTransformation(),
        lowerAlignToBite: lowerMBAsset?.injector?.getAlignToBiteTransformation(),
        upperTransformationFromAlignmentCoordinatesGlobal: upperMBAsset?.injector?.getTransform(
            'TransformationFromAlignmentCoordinatesGlobal',
        ),
        lowerTransformationFromAlignmentCoordinatesGlobal: lowerMBAsset?.injector?.getTransform(
            'TransformationFromAlignmentCoordinatesGlobal',
        ),
    };

    const doctorMarginMap = getDoctorMarginMap(_.compact([upperMBAsset?.injector, lowerMBAsset?.injector]));

    const rawMarginsDdmt = modellingTree?.interfaceMargins;

    if (!checkAreMarginsValid(parsedCase, rawMarginsDdmt, logger)) {
        return undefined;
    }

    const processedMargins = processMargins(parsedCase, rawMarginsDdmt, designTransforms, logger);

    const cadDCMs: DcmProcessingIntermediary[] = await parseDcmsToIntermediary(
        parsedCase,
        designRootFolderName,
        cadFiles,
        errorMessages,
    );

    const anatomyDCMs = await parseAnatomyDcmsToIntermediary(anatomyFiles, errorMessages);
    const insertionAxes = modellingTree
        ? getInsertionAxes2(parsedCase, modellingTree, designTransforms, cadDCMs)
        : new Map<string, THREE.Vector3>();

    const allScanAssets = _.compact(
        await Promise.all(
            scanFiles.map(async ([name, obj]) => {
                const injector = await getDcmInjector(obj, errorMessages);
                return injector
                    ? {
                          geom: injector.buildGeometry({ applyTextureCoords: true }),
                          roleType: 'Scan',
                          imgTexture: injector.parseTextureImages()[0],
                          sourceFile: name,
                          injector: injector,
                          stub: FileNameUtils.removeFirstDirectory(name),
                      }
                    : undefined;
            }),
        ),
    );

    // Diagnosing Raw to MB scan coordinate transform alignment
    [upperMBAsset, lowerMBAsset].forEach(mbscan => {
        const isUpper = mbscan === upperMBAsset;
        const jaw = isUpper ? Jaw.UPPER : Jaw.LOWER;

        if (!mbscan) {
            return;
        }

        const isAntagonistPrepScan = mbscan.stub.includes('Antagonist');

        const strRawScan = `Raw ${isAntagonistPrepScan ? 'Antagonist' : 'Preparation'}` + ' scan.dcm';

        const compareTarget = allScanAssets.find(asset =>
            asset.stub.includes(`${isUpper ? 'Upper' : 'Lower'}/${strRawScan}`),
        );
        if (!compareTarget) {
            return;
        }

        const rawGeometry = compareTarget.geom;

        // Using getRawToMBTransform to determine the correct transformation
        const transformationMatrix = getRawToMBTransform(jaw, designTransforms);

        const compareBVH = rawGeometry ? ensureMeshIndex(rawGeometry) : null;

        const positionAttr = mbscan?.geom.attributes.position;

        if (compareBVH && positionAttr) {
            const bins = getBinsSampleDistance(
                positionAttr,
                transformationMatrix,
                compareBVH,
                VERTEX_DISTANCE_SAMPLE_COUNT,
            );

            // Define a type for valid percentile keys
            type PercentileKeys = 'p50' | 'p75' | 'p95';

            // Define a mapping from keys to their respective indices
            const percentileIndices: Record<PercentileKeys, number> = {
                p50: 3,
                p75: 4,
                p95: 6,
            };

            // Thresholds are still defined with the same keys
            const thresholds: Record<PercentileKeys, number> = {
                p50: MAX_RAW_SCAN_TRANSFORMED_AND_MB_SCAN_GAP_MM,
                p75: MAX_RAW_SCAN_TRANSFORMED_AND_MB_SCAN_GAP_MM,
                p95: MAX_RAW_SCAN_TRANSFORMED_AND_MB_SCAN_GAP_MM,
            };

            const exceedingDistance = Object.keys(thresholds).some(key => {
                const percentileKey = key as PercentileKeys;
                const index = percentileIndices[percentileKey];
                const threshold = thresholds[percentileKey];
                const distance = bins ? bins[index] : undefined;

                return distance && distance > threshold;
            });

            if (exceedingDistance) {
                logger?.info(`Warning: sample distance is larger than threshold`, {
                    jaw: jaw,
                    rawscan: compareTarget.stub,
                    mbscan: mbscan.stub,
                    antagonist: isAntagonistPrepScan,
                    p50: {
                        threshold: thresholds['p50'],
                        distance: bins[3],
                    },
                    p75: {
                        threshold: thresholds['p75'],
                        distance: bins[4],
                    },
                    p95: {
                        threshold: thresholds['p95'],
                        distance: bins[6],
                    },
                });
            }
        }
    });

    const cadAssets: DesignProjectCadAsset[] = cadDCMs.map(
        (cadObj: DcmProcessingIntermediary): DesignProjectCadAsset => {
            const toothElements = cadObj.modelElement
                ? DesignCaseFileUtil.getTeethByModelID(parsedCase.toothElements, cadObj.modelElement.modelElementID)
                : [];
            const toothNumbers = toothElements.map((tE: ToothElement) => tE.toothNumber as ToothNumber);

            const predominantToothType = cadObj.modelElement
                ? DesignCaseFileUtil.getPredominantToothElementType(cadObj.modelElement, parsedCase.toothElements)
                : undefined;

            const isUpper = toothNumbers.some(unn => ToothUtils.toothIsUpper(unn));
            const baseGeometry = cadObj.dcm.buildGeometry({ applyTextureCoords: false });

            const isDenture = predominantToothType && DENTURE_TOOTH_TYPES.includes(predominantToothType);
            const contextAppropriateTransform = getCadTransform(
                designTransforms,
                isDenture ? 'DENTURE' : 'FIXED',
                isUpper ? Jaw.UPPER : Jaw.LOWER,
            );

            if (toothNumbers.length > 0 && contextAppropriateTransform) {
                baseGeometry.applyMatrix4(contextAppropriateTransform);
            }

            // This can be pulled out to a util "test seal zone proximity" which gives some kind of failure
            if (isDenture && predominantToothType?.toLowerCase().includes('gingiva')) {
                const compareTarget = isUpper ? upperMBAsset?.geom : lowerMBAsset?.geom;
                const compareBVH = compareTarget ? ensureMeshIndex(compareTarget) : null;
                // Get just the intaglio if it's a denture?
                const facetMarks = cadObj.dcm.parseFacetMarks();
                if (facetMarks) {
                    // the seal-zone is the area that 3Shape makes the intaglio of the denture
                    const internalGeometry = extractFacetsToNewGeometry(baseGeometry, fIdx => {
                        const { facetType } = parseRestorativeFacetMark(facetMarks[fIdx] as number);
                        return facetType !== undefined && ['seal-zone'].includes(facetType);
                    });
                    const positionAttr = internalGeometry?.attributes?.position;
                    if (compareBVH && positionAttr) {
                        const p95dist = getP95SampleDistance(positionAttr, compareBVH, VERTEX_DISTANCE_SAMPLE_COUNT);
                        if (p95dist > MAX_INTAGLIO_AND_SCAN_GAP_MM) {
                            onError(
                                `p95 separation between seal-zone and scan exceeds ${MAX_INTAGLIO_AND_SCAN_GAP_MM}mm (${p95dist.toFixed(
                                    2,
                                )}mm) for ${isUpper ? 'upper' : 'lower'} denture`,
                            );
                        }
                    }
                }
            }

            return {
                geom: baseGeometry,
                roleType: 'CAD',
                sourceFile: cadObj.fileName,
                injector: cadObj.dcm,
                stub: FileNameUtils.removeFirstDirectory(cadObj.fileName),
                modelElementId: cadObj.modelElement?.modelElementID,
                toothElements: toothElements.map(tE => ({ unn: tE.toothNumber as ToothNumber, id: tE.toothElementID })),
            };
        },
    );

    const anatomyCadAssets: DesignProjectCadAsset[] = anatomyDCMs.map(
        (cadObj: AnatomyDcmProcessingIntermediary): DesignProjectCadAsset => {
            const baseGeometry = cadObj.dcm.buildGeometry({ applyTextureCoords: false });

            const isUpper = cadObj.toothNumber && ToothUtils.toothIsUpper(cadObj.toothNumber);
            const contextAppropriateTransform = getCadTransform(
                designTransforms,
                'FIXED',
                isUpper ? Jaw.UPPER : Jaw.LOWER,
            );
            if (cadObj.toothNumber && contextAppropriateTransform) {
                baseGeometry.applyMatrix4(contextAppropriateTransform);
            }
            return {
                geom: baseGeometry,
                roleType: 'CAD',
                sourceFile: cadObj.fileName,
                injector: cadObj.dcm,
                stub: FileNameUtils.removeFirstDirectory(cadObj.fileName),
                modelElementId: undefined,
                toothElements: [{ unn: cadObj.toothNumber as ToothNumber, id: 'undefined' }],
            };
        },
    );

    const implantToothNumbers = new Set(
        DesignCaseFileUtil.getImplantUnns(parsedCase.toothElements).filter(ToothUtils.isToothNumber),
    );

    missingCadFileNames.forEach(fname => onError(`Missing expected CAD file: ${fname}`));

    return {
        parsedCase,
        modellingTree,
        cadAssets: [...cadAssets, ...anatomyCadAssets],
        insertionAxes,
        margins: processedMargins,
        extraDataAssets: [],
        upperMbScan: upperMBAsset,
        lowerMbScan: lowerMBAsset,
        doctorMargins: doctorMarginMap,
        designTransforms,
        allScanAssets: _.compact(
            await Promise.all(
                scanFiles.map(async ([name, obj]) => {
                    const scan = await processSingleScan(
                        obj,
                        {
                            roleType: 'Scan',
                            sourceFile: name,
                            stub: FileNameUtils.removeFirstDirectory(name),
                        },
                        errorMessages,
                    );

                    const nameLowerCase = name.replaceAll(/\s/g, '').toLowerCase();
                    const isRawPrep = nameLowerCase.includes('rawpreparation');
                    const isPrePrep = nameLowerCase.includes('prepreparationscan');

                    if (
                        scan &&
                        designTransforms.upperAlignToBite &&
                        name.includes('Upper') &&
                        (isPrePrep || isRawPrep)
                    ) {
                        logger?.info('Applying upper align-to-bite matrix to raw prep or prePrep scan', {
                            name,
                        });
                        scan.geom.applyMatrix4(designTransforms.upperAlignToBite);
                    } else if (
                        scan &&
                        designTransforms.lowerAlignToBite &&
                        name.includes('Lower') &&
                        (isPrePrep || isRawPrep)
                    ) {
                        logger?.info('Applying lower align-to-bite matrix to raw prep or prePrep scan', {
                            name,
                        });
                        scan.geom.applyMatrix4(designTransforms.lowerAlignToBite);
                    }

                    return scan;
                }),
            ),
        ),
        implantToothNumbers,
        missingCadFileNames,
        extraCadFileNames,
        errorMessages,
    };
}

/* Converts the file name stub to a key used to index the metadata's `scans` object.
 * Essentially converts `Scans/Lower/MB Antagonist Scan.DCM` -> `Scans.Lower.MB Antagonist Scan`.
 */
function fileNameToScansMetadataKey(fileName: string) {
    const fileNameWithoutExtension = FileNameUtils.removeExtension(fileName);
    return fileNameWithoutExtension.replaceAll('/', '.');
}

/*
 * Computes the contents of the metadata json file that is needed for InternalDesignMetadataSchema.
 * This is a subset of the data that root canal had been procuring, but seems to be all that we actually need in our design viewers.
 * `allAssets` should be a list of all of the various assets that should be considered for `unnForFilePath`, scans and CAD models included.
 */
export async function computeDesignInternalMetadata(
    assets: MinimallyProcessedDesignAssets,
    allAssets: DesignProjectAsset[],
): Promise<InternalDesignMetadata> {
    function optionalToArray(transform: THREE.Matrix4 | undefined) {
        return transform ? { transform: transform.toArray() } : undefined;
    }

    const transforms = {
        UpperJaw2LowerJaw: optionalToArray(assets.designTransforms.upperJawToLowerJaw),
        UpperAlign2Bite: optionalToArray(assets.designTransforms.upperAlignToBite),
        LowerAlign2Bite: optionalToArray(assets.designTransforms.lowerAlignToBite),
        UpperTransformationFromAlignmentCoordinatesGlobal: optionalToArray(
            assets.designTransforms.upperTransformationFromAlignmentCoordinatesGlobal,
        ),
        LowerTransformationFromAlignmentCoordinatesGlobal: optionalToArray(
            assets.designTransforms.lowerTransformationFromAlignmentCoordinatesGlobal,
        ),
    };

    return {
        // this seems unused
        qc_analysis_version: '',
        // this seems unused
        feature_extraction_version: '',
        // this seems unused
        transforms_applied: false,
        // this seems unused
        internal_design_processing: 'NOTUSED',

        // not sure if we really need this or not.
        margin_analysis: undefined,

        // compute these
        metadata_missing: false,
        total_mb_scans: (assets.upperMbScan ? 1 : 0) + (assets.lowerMbScan ? 1 : 0),
        margin_lines: [...assets.margins.values()],
        transforms,
        insertionAxes: [...assets.insertionAxes.entries()].reduce((state, entry) => {
            return {
                ...state,
                [entry[0]]: [entry[1].x, entry[1].y, entry[1].z],
            };
        }, {}),
        unnForFilePath: allAssets.reduce((state, asset) => {
            return {
                ...state,
                [asset.stub]: asset.injector.parseAllTeethAnnotations().map(tooth => tooth.tooth),
            };
        }, {}),
        scans: assets.allScanAssets.reduce((state, scan) => {
            const transforms = Object.entries(scan.injector.parseAllTransforms()).reduce((transformState, entry) => {
                return {
                    ...transformState,
                    [entry[0]]: {
                        transform: entry[1].toArray(),
                    },
                };
            }, {});
            return {
                ...state,
                [fileNameToScansMetadataKey(scan.stub)]: {
                    transforms,
                    dcm_file: scan.stub,
                    teeth: scan.injector.parseAllTeethAnnotations(),
                },
            };
        }, {}),
    };
}

/**
 * Gets a jaw scan from the design archive.
 * @param designArchive The design archive to search for a jaw scan
 * @param rootDirName The root directory name of the design archive
 * @param jaw The jaw to search for
 * @param preprep If true, searches for a prepreparation scan; otherwise, searches for a non-prepreparation scan
 * @returns The first jaw scan found in the archive that matches the given criteria
 */
export function getJawScan(
    designArchive: JsZip,
    rootDirName: string,
    jaw: Jaw,
    preprep: boolean = false,
): JsZip.JSZipObject | undefined {
    const jawScanDirName = path.join(rootDirName, 'Scans', Format.titleCase(jaw));
    const jawScanDirEntries = Object.entries(designArchive.files).filter(([fname, zipObject]) => {
        return fname.startsWith(jawScanDirName) && !zipObject.dir;
    });

    for (const [fname, zipObject] of jawScanDirEntries) {
        if (fname.includes('PrePreparation') === preprep) {
            return zipObject;
        }
    }
}
