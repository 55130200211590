import { AnalyticsClient } from '../../analytics/analyticsClient';
import type { OrdersSortField } from '../../redux/orders/orders.reducer';
import { useOrdersToolbarSelectProps } from './useOrdersToolbarSelectProps';
import { SimpleSelect, SortIcon } from '@orthly/ui';
import { stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useToggleStyles = stylesFactory(() => ({
    sortContainer: { width: 30, marginLeft: 6, flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' },
}));

interface SortDirectionToggleProps {
    isAscending: boolean;
    toggleDirection: () => void;
}

const SortDirectionToggle: React.VFC<SortDirectionToggleProps> = ({ isAscending, toggleDirection }) => {
    const classes = useToggleStyles();
    return (
        <Grid container className={classes.sortContainer} onClick={() => toggleDirection()}>
            <SortIcon highlightedArrow={isAscending ? 'upper' : 'lower'} style={{ cursor: 'pointer' }} />
        </Grid>
    );
};

interface OrdersOverviewToolbarSortProps extends SortDirectionToggleProps {
    sortColumn: OrdersSortField;
    setSortColumn: (sort: OrdersSortField) => void;
}

export const OrdersToolbarSort: React.VFC<OrdersOverviewToolbarSortProps> = props => {
    const selectProps = useOrdersToolbarSelectProps();
    const { toggleDirection, isAscending, sortColumn, setSortColumn } = props;
    return (
        <Grid container wrap={'nowrap'}>
            <SimpleSelect
                {...selectProps}
                options={[
                    { value: 'status', label: 'Relevancy' },
                    { value: 'due_date', label: 'Expected Date' },
                    { value: 'patient_name', label: 'Patient' },
                    { value: 'created_at', label: 'Created' },
                    { value: 'updated_at', label: 'Updated' },
                ]}
                onChange={value => {
                    if (value && value !== sortColumn) {
                        AnalyticsClient.track('Practice - Order List - Sort By Option Selected', {
                            sortBy: value,
                        });
                        setSortColumn(value as any);
                    }
                }}
                label={'Sort by'}
                value={sortColumn}
                SelectProps={{ ...selectProps.SelectProps, displayEmpty: false, variant: 'standard' }}
                variant={'filled'}
                backgroundColor={FlossPalette.WHITE}
                style={{ borderRadius: 0, border: 'none', boxShadow: 'none' }}
            />
            <SortDirectionToggle
                isAscending={isAscending}
                toggleDirection={() => {
                    AnalyticsClient.track('Practice - Order List - Sort Direction Toggled', {
                        sortDirection: isAscending ? 'DESC' : 'ASC',
                    });
                    toggleDirection();
                }}
            />
        </Grid>
    );
};
