/* eslint-disable max-lines */
import { QcHeatmapOptions } from '../ColorRamp';
import { isPreExtractionScanItem } from '../ModelViewer';
import type { ModelPayloadItem } from '../ModelViewer/ModelViewerTypes';
import { Jaw } from '../ModelViewer/ModelViewerTypes';
import {
    getQCUndercutsCurtainId,
    isAnatomyItem,
    isCurrentCadItem,
    isCurrentQcHeatmapItem,
    isLowerMBItem,
    isLowerScanItem,
    isNonMbScanItem,
    isPastCadItem,
    isPastQcHeatmapItem,
    isPrintedModelItem,
    isQCCollisionItem,
    isQCUndercutsCurtainsItem,
    isScanItem,
    isUpperMBItem,
    isUpperScanItem,
} from '../ModelViewer/NewModelViewer.utils';
import { recomputeCollisionsObject } from '../PortalDesignEditor/RecomputeCollisions';
import { getAdjacentModels } from '../PortalDesignEditor/SculptingApp.utils';
import type {
    ItemAppearance,
    ModelAppearance,
    PayloadModelAppearance,
    QCSettings,
    ScanPreviewAppearance,
} from './ModelAppearanceTypes';
import { RestorativeView } from './ModelAppearanceTypes';
import { AttributeName, HeatMapType, ensureDistanceAttributesInitialized } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import _ from 'lodash';
import type * as THREE from 'three';

export const MODEL_VIEWER_INITIAL_APPEARANCE: ModelAppearance = {
    upperJaw: [],
    lowerJaw: [],
    collisions: [],
    anatomy: [],
    solo: [],
    curtains: {},

    restoratives: {
        [RestorativeView.CAD]: [],
        [RestorativeView.HeatMap]: [],
    },
    pastRestoratives: {
        [RestorativeView.CAD]: [],
        [RestorativeView.HeatMap]: [],
    },
    scans: [],
    preExtractionScans: [],
    restorativeView: RestorativeView.CAD,
    showAnatomyLayers: false,
    showCollisions: false,
    showCurtainsCollisions: false,
    showCurtainsHeatmap: false,
    showMarginLines: false,
    showDoctorMarginLines: false,
    showDoctorToothMarkings: false,
    activeHeatMap: HeatMapType.Thickness,
    printedModels: [],
};

// A function which stamps the appearance object and
// payloadModel object together into a single object
const appearanceMapperFor = (appearance: ItemAppearance) => {
    return (model: ModelPayloadItem): PayloadModelAppearance => {
        return {
            appearance: {
                ...appearance,
                visible: model.initiallyHidden ? false : appearance.visible,
            },
            payloadModel: model,
        };
    };
};

function generateCurtainsMap(items: ModelPayloadItem[]): Record<string, ModelPayloadItem> {
    const curtainsMapByID: Record<string, ModelPayloadItem> = {};
    items.filter(isQCUndercutsCurtainsItem).forEach(curtain => {
        const curtainID = getQCUndercutsCurtainId(curtain);
        if (!curtainID) {
            return;
        }
        curtainsMapByID[curtainID] = curtain;
    });
    return curtainsMapByID;
}

export const DEFAULT_SCAN_APPEARANCE: ItemAppearance = {
    colorize: true,
    showInsertionAxis: false,
    showUndercutShadow: false,
    showUndercutCurtains: false,
    opacity: 1,
    visible: true,
};

export const DEFAULT_PRINTED_MODEL_APPEARANCE: ItemAppearance = {
    colorize: false,
    showInsertionAxis: false,
    showUndercutShadow: false,
    showUndercutCurtains: false,
    opacity: 1,
    visible: true,
};

export const DEFAULT_SCAN_PREVIEW_APPEARANCE: ScanPreviewAppearance = {
    visible: true,
    colorize: true,
};

export const DEFAULT_SCAN_EXPORT_APPEARANCE: ItemAppearance = {
    colorize: true,
    showInsertionAxis: false,
    showUndercutShadow: false,
    showUndercutCurtains: false,
    opacity: 1,
    visible: false,
};

export const DEFAULT_RESTORATIVE_APPEARANCE: ItemAppearance = {
    ...DEFAULT_SCAN_APPEARANCE,
    showUndercutShadow: false,
    showUndercutCurtains: false,
    colorize: false,
};

export const DEFAULT_HEATMAP_APPEARANCE: ItemAppearance = {
    ...DEFAULT_RESTORATIVE_APPEARANCE,
};

const DEFAULT_PAST_RESTORATIVE_APPEARANCE: ItemAppearance = {
    ...DEFAULT_RESTORATIVE_APPEARANCE,
    visible: false,
};

const DEFAULT_PAST_HEATMAP_APPEARANCE: ItemAppearance = {
    ...DEFAULT_HEATMAP_APPEARANCE,
    visible: false,
};

interface ModelAppearanceOptions {
    showOrderScans: boolean;
    useProxyModelsForHeatmaps: boolean;
    showPreExtractionScans: boolean;
    enablePreferredScansIfNoDesigns: boolean;
}

const DEFAULT_MODEL_APPEARANCE_OPTIONS: Readonly<ModelAppearanceOptions> = {
    showOrderScans: false,
    useProxyModelsForHeatmaps: true,
    showPreExtractionScans: false,
    enablePreferredScansIfNoDesigns: false,
};

export function createDefaultAppearanceSettings(
    items: ModelPayloadItem[],
    options: Partial<ModelAppearanceOptions> = {},
): ModelAppearance {
    const { showOrderScans, useProxyModelsForHeatmaps, showPreExtractionScans, enablePreferredScansIfNoDesigns } = {
        ...DEFAULT_MODEL_APPEARANCE_OPTIONS,
        ...options,
    };

    const restoratives = {
        [RestorativeView.CAD]: items.filter(isCurrentCadItem).map(appearanceMapperFor(DEFAULT_RESTORATIVE_APPEARANCE)),

        [RestorativeView.HeatMap]: items
            .filter(useProxyModelsForHeatmaps ? isCurrentQcHeatmapItem : isCurrentCadItem)
            .map(appearanceMapperFor(DEFAULT_HEATMAP_APPEARANCE)),
    };
    const printedModels = items.filter(isPrintedModelItem).map(appearanceMapperFor(DEFAULT_PRINTED_MODEL_APPEARANCE));

    const hasDesignModels = restoratives[RestorativeView.CAD].length > 0 || printedModels.length > 0;
    const showGroupedView = hasDesignModels || enablePreferredScansIfNoDesigns;

    // The `upperJaw` and `lowerJaw` scans appear under the "Scans" header, separate from the
    // `scans` that appear under the "Order Scans" header. When there are no CAD items, keep
    // all scans grouped together in `scans` so they all display in the UI under "Order Scans".
    const upperJawItem = showGroupedView ? getPreferredJawScan(items, Jaw.Upper) : undefined;
    const lowerJawItem = showGroupedView ? getPreferredJawScan(items, Jaw.Lower) : undefined;

    const upperJaw = _.compact([upperJawItem]).map(appearanceMapperFor(DEFAULT_SCAN_APPEARANCE));
    const lowerJaw = _.compact([lowerJawItem]).map(appearanceMapperFor(DEFAULT_SCAN_APPEARANCE));

    const curtainsMapByID: Record<string, ModelPayloadItem> = generateCurtainsMap(items);

    const collisions = items.filter(isQCCollisionItem);
    const anatomy = items.filter(isAnatomyItem);

    // If this viewer is rendering design elements, we will treat any scans being rendered as "scan export" items,
    // which are invisible by default.
    // If there are no design elements, we instead will render these scans as the primary items, and thus will be visible by default.
    const shouldUseScanExportAppearance = showGroupedView || upperJaw.length > 0 || lowerJaw.length > 0;

    const scans = showOrderScans
        ? items
              .filter(pl => isScanItem(pl) && ![upperJawItem, lowerJawItem].includes(pl))
              .map(
                  appearanceMapperFor(
                      shouldUseScanExportAppearance ? DEFAULT_SCAN_EXPORT_APPEARANCE : DEFAULT_SCAN_APPEARANCE,
                  ),
              )
        : [];
    const preExtractionScans = showPreExtractionScans
        ? items.filter(pl => isPreExtractionScanItem(pl)).map(appearanceMapperFor(DEFAULT_SCAN_EXPORT_APPEARANCE))
        : [];

    const pastRestoratives = {
        [RestorativeView.CAD]: items
            .filter(isPastCadItem)
            .map(appearanceMapperFor(DEFAULT_PAST_RESTORATIVE_APPEARANCE)),
        [RestorativeView.HeatMap]: items
            .filter(useProxyModelsForHeatmaps ? isPastQcHeatmapItem : isPastCadItem)
            .map(appearanceMapperFor(DEFAULT_PAST_HEATMAP_APPEARANCE)),
    };

    const hasPastRestoratives = !!pastRestoratives.CAD.length;

    const appearanceSettings: ModelAppearance = {
        upperJaw,
        lowerJaw,
        collisions,
        restoratives,
        pastRestoratives,
        anatomy,
        scans,
        preExtractionScans,
        printedModels,
        solo: [],
        curtains: curtainsMapByID,
        restorativeView: hasPastRestoratives ? RestorativeView.HeatMap : RestorativeView.CAD,
        showAnatomyLayers: false,
        showCollisions: false,
        showCurtainsCollisions: false,
        showCurtainsHeatmap: false,
        showMarginLines: false,
        showDoctorMarginLines: false,
        showDoctorToothMarkings: false,
        activeHeatMap: hasPastRestoratives ? HeatMapType.SurfaceDisplacement : HeatMapType.Thickness,
        colorPastRestoratives: false,
    };

    if (!useProxyModelsForHeatmaps) {
        // compute distances attributes for the first time for the CAD geometry
        restoratives[RestorativeView.HeatMap].forEach(payloadModelAppearance => {
            const editGeometry = payloadModelAppearance.payloadModel.model.geometry;
            const proximalModels = getAdjacentModels(payloadModelAppearance, appearanceSettings, true);
            const occlusalModels = getAdjacentModels(payloadModelAppearance, appearanceSettings, false);
            ensureDistanceAttributesInitialized(editGeometry, proximalModels, occlusalModels);
            appearanceSettings.collisions = [recomputeCollisionsObject(editGeometry)];
        });
    }

    return appearanceSettings;
}

export function createFocusContactAppearanceSettings(
    items: ModelPayloadItem[],
    showOrderScans: boolean = false,
    targetUNN: number,
    contactType: HeatMapType,
    useProxyModelsForHeatmaps: boolean = true,
): ModelAppearance {
    const [upperJawItem, ...remainingUpperJawItems] = filterAndSortMBItemsByPreference(items, Jaw.Upper);
    const [lowerJawItem, ...remainingLowerJawItems] = filterAndSortMBItemsByPreference(items, Jaw.Lower);

    // we put the upperJaw or lowerJaw to visible if focussing on occlusal contact
    const upperJaw = _.compact([upperJawItem]).map(
        appearanceMapperFor({
            ...DEFAULT_SCAN_APPEARANCE,
            visible: contactType === HeatMapType.Occlusal && ToothUtils.toothIsUpper(targetUNN),
        }),
    );
    const lowerJaw = _.compact([lowerJawItem]).map(
        appearanceMapperFor({
            ...DEFAULT_SCAN_APPEARANCE,
            visible: contactType === HeatMapType.Occlusal && !ToothUtils.toothIsUpper(targetUNN),
        }),
    );
    const scans = (
        showOrderScans ? [...items.filter(isNonMbScanItem), ...remainingUpperJawItems, ...remainingLowerJawItems] : []
    ).map(
        appearanceMapperFor({
            ...DEFAULT_SCAN_APPEARANCE,
            visible: false,
        }),
    );
    const printedModels = items.filter(isPrintedModelItem).map(
        appearanceMapperFor({
            ...DEFAULT_PRINTED_MODEL_APPEARANCE,
            visible: false,
        }),
    );

    const curtainsMapByID: Record<string, ModelPayloadItem> = generateCurtainsMap(items);
    const collisions = items.filter(isQCCollisionItem);
    const anatomy = items.filter(isAnatomyItem);

    const restoratives = {
        [RestorativeView.CAD]: items.filter(isCurrentCadItem).map(appearanceMapperFor(DEFAULT_RESTORATIVE_APPEARANCE)),

        [RestorativeView.HeatMap]: items
            .filter(useProxyModelsForHeatmaps ? isCurrentQcHeatmapItem : isCurrentCadItem)
            .map(item => {
                const vis = item.unns ? item.unns.includes(targetUNN) : true;
                const am4 = appearanceMapperFor({
                    ...DEFAULT_HEATMAP_APPEARANCE,
                    visible: vis,
                });
                return am4(item);
            }),
    };

    const pastRestoratives = {
        [RestorativeView.CAD]: items.filter(isPastCadItem).map(appearanceMapperFor(DEFAULT_RESTORATIVE_APPEARANCE)),

        [RestorativeView.HeatMap]: items
            .filter(useProxyModelsForHeatmaps ? isPastQcHeatmapItem : isPastCadItem)
            .map(item => {
                const vis = item.unns ? item.unns.includes(targetUNN) : true;
                const am4 = appearanceMapperFor({
                    ...DEFAULT_HEATMAP_APPEARANCE,
                    visible: vis,
                });
                return am4(item);
            }),
    };

    const appearanceSettings: ModelAppearance = {
        upperJaw,
        lowerJaw,
        collisions,
        restoratives,
        pastRestoratives,
        anatomy,
        scans,
        preExtractionScans: [],
        printedModels,
        solo: [],
        curtains: curtainsMapByID,
        restorativeView: RestorativeView.HeatMap,
        showAnatomyLayers: false,
        showCollisions: false,
        showCurtainsCollisions: false,
        showCurtainsHeatmap: false,
        showMarginLines: false,
        showDoctorMarginLines: false,
        showDoctorToothMarkings: false,
        activeHeatMap: contactType,
        heatMapRange: QcHeatmapOptions[contactType].defaultRange,
        colorPastRestoratives: !!pastRestoratives.CAD.length,
    };

    if (!useProxyModelsForHeatmaps) {
        // compute distances attributes for the first time for the CAD geometry
        restoratives[RestorativeView.HeatMap].forEach(payloadModelAppearance => {
            const editGeometry = payloadModelAppearance.payloadModel.model.geometry;
            const proximalModels = getAdjacentModels(payloadModelAppearance, appearanceSettings, true);
            const occlusalModels = getAdjacentModels(payloadModelAppearance, appearanceSettings, false);
            ensureDistanceAttributesInitialized(editGeometry, proximalModels, occlusalModels);
            appearanceSettings.collisions = [recomputeCollisionsObject(editGeometry)];
        });
    }

    return appearanceSettings;
}

export function clearCustomShader(payload: PayloadModelAppearance): PayloadModelAppearance {
    return { ...payload, appearance: { ...payload.appearance, customShader: undefined } };
}

export function applyCustomShader(
    payload: PayloadModelAppearance,
    shader: THREE.ShaderMaterialParameters,
): PayloadModelAppearance {
    return { ...payload, appearance: { ...payload.appearance, customShader: shader } };
}

export function canColorize(modelPayloadAppearance: PayloadModelAppearance) {
    const hasVcolor = !!modelPayloadAppearance.payloadModel.model.geometry.getAttribute(AttributeName.Color);
    const hasImageMap = !!modelPayloadAppearance.payloadModel.colorMap;
    const hasUvMap = !!modelPayloadAppearance.payloadModel.model.geometry.getAttribute(AttributeName.TexCoord);
    return hasVcolor || (hasImageMap && hasUvMap);
}

export function getHeatmapRange(settings: Pick<QCSettings, 'activeHeatMap' | 'heatMapRange'>) {
    return settings.heatMapRange ?? QcHeatmapOptions[settings.activeHeatMap].defaultRange;
}

// "MB" scans are the most final version of scans, so we prefer them over any others.
const RANKED_ARCH_SCAN_PREFIXES: Readonly<string[]> = [
    'mb preparation scan',
    'mb antagonist scan',
    'mb', // take anything named "MB*" over anything else
    'preparation scan',
    'antagonist scan',
    'raw preparation scan',
    'raw antagonist scan',
    'prepreparation scan',
];

/**
 * Gets the preferred arch scan for a given jaw
 * @param items The items to search through
 * @param jaw The jaw to search for
 */
export function getPreferredJawScan<T extends Pick<ModelPayloadItem, 'type' | 'jaw' | 'name'>>(
    items: T[],
    jaw: Jaw,
): T | undefined {
    const filtered = items.filter(jaw === Jaw.Upper ? isUpperScanItem : isLowerScanItem);
    const rank = (payload: T) => {
        const sanitizedName = payload.name.toLowerCase().trim();
        for (const [i, prefix] of RANKED_ARCH_SCAN_PREFIXES.entries()) {
            if (sanitizedName.startsWith(prefix)) {
                return i;
            }
        }
        return RANKED_ARCH_SCAN_PREFIXES.length;
    };
    return _.sortBy(filtered, rank)[0];
}

/**
 * Filters and sorts the given {@link ModelPayloadItem}s in order of preference (first is most preferred). For example
 * having both an MB Antagonist scan and an MB Preparation scan is indeterminate and happens rarely. We take a consistent
 * approach of MB Preparation
 */
export function filterAndSortMBItemsByPreference(payloads: ModelPayloadItem[], jaw: Jaw): ModelPayloadItem[] {
    const filtered = payloads.filter(jaw === Jaw.Upper ? isUpperMBItem : isLowerMBItem);
    function rank(payload: ModelPayloadItem) {
        const nameLower = payload.name.toLowerCase();
        if (nameLower.replace(' ', '').includes('preprep')) {
            return 100;
        } else if (nameLower.includes('preparation')) {
            return 0;
        } else {
            return 1;
        }
    }
    return _.sortBy(filtered, rank);
}
