/* eslint-disable max-lines */
import { useFirebase } from '../../context';
import { GetStoragePathConfig_Query } from '../GetStoragePathConfig.graphql';
import { getFileBlob } from './FileUploader';
import type { FileUploaderBulkProps } from './FileUploaderBulk';
import { buildFBUploadPath } from './FirebaseUpload.utils';
import type { FileUploaderFieldResult } from './file-uploader-types';
import type { DeleteBulkFn, UploadBulkFn } from './useFirebaseUpload';
import { useFirebaseMultiFileUpload } from './useFirebaseUpload';
import { useQuery } from '@apollo/client';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { RefabAttachment } from '@orthly/dentin';
import { FileNameUtils } from '@orthly/runtime-utils';
import type { DeviceLocation, FileUploadDisplayLocation, BucketStoragePathConfig } from '@orthly/shared-types';
import loadImage from 'blueimp-load-image';
import firebase from 'firebase/compat/app';
import _ from 'lodash';
import React from 'react';
import useSWR from 'swr';
import { v4 as uuid } from 'uuid';
import { z } from 'zod';

function isCanvasElement(img: Event | HTMLImageElement | HTMLCanvasElement): img is HTMLCanvasElement {
    return (img as HTMLCanvasElement).toBlob !== undefined;
}

export async function convertImageFileToBase64(file: File): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            try {
                resolve(reader.result as string);
            } catch (error) {
                reject(error);
            }
        };

        reader.onerror = error => reject(error);
        reader.readAsDataURL(file);
    });
}

const processedFileExtensions = ['jpg', 'jpeg'];

async function getProcessedImage(file: File, processImages: boolean = false): Promise<Blob> {
    if (!processedFileExtensions.includes(_.last(file.name.split('.')) ?? '') || !processImages) {
        return await getFileBlob(file);
    }
    const fileBlob = await new Promise<Blob | null>((resolve, _reject) => {
        loadImage(
            file,
            (img, _data) => {
                if (isCanvasElement(img)) {
                    return img.toBlob(blob => resolve(blob));
                }
                return null;
            },
            { maxHeight: 600, meta: true, orientation: true, canvas: true },
        );
    });
    return fileBlob ?? (await getFileBlob(file));
}

interface UploadFilesArgs {
    filesToSubmit: File[];
    uploadFn: UploadBulkFn;
    overrideFileName?: string;
    prependTimestampToFilename?: boolean;
    processImages?: boolean;
}

export async function uploadFilesToGCS(args: UploadFilesArgs): Promise<FileUploaderFieldResult[]> {
    const { filesToSubmit: inputFiles, uploadFn, overrideFileName, processImages, prependTimestampToFilename } = args;
    // ensures we don't overwrite uploads because 2 pending files have same name
    function getDedupedUploadName(file: File, idx: number) {
        if (overrideFileName) {
            // only 1 overrideFileName can be provided (legacy), should never be used for multiple files but just in case
            const postFix = idx > 0 ? `-${idx}` : '';
            return `${overrideFileName}${postFix}.${FileNameUtils.getFileExtension(file.name)}`;
        }
        // how many times have we seen this name earlier in the array of files?
        const priorNameSeenCount = inputFiles.filter((f, prevIdx) => f.name === file.name && prevIdx < idx).length;
        // if there's more than instance of the same name, add a postfix with the file index
        if (priorNameSeenCount > 0) {
            const postFix = `-${priorNameSeenCount}`;
            return `${FileNameUtils.fileNameWithoutExtension(file.name)}${postFix}.${FileNameUtils.getFileExtension(
                file.name,
            )}`;
        }
        return file.name;
    }
    return Promise.all(
        inputFiles.map(async (file, idx) => {
            const uploadName = getDedupedUploadName(file, idx);
            const fileField = { uploadName, fieldKey: file.name };
            const uploadPath = buildFBUploadPath(
                uploadName,
                fileField,
                !overrideFileName && prependTimestampToFilename,
            );
            const fileBlob = await getProcessedImage(file, processImages);
            const result = await uploadFn(fileField.fieldKey, uploadPath, fileBlob);
            return { ...fileField, uploadedPath: result.ref.fullPath };
        }),
    );
}

async function deleteFiles(inputFiles: File[], deleteFn: DeleteBulkFn, overrideFileName?: string): Promise<void> {
    await Promise.all(
        inputFiles.map(async file => {
            const uploadName = overrideFileName
                ? `${overrideFileName}.${FileNameUtils.getFileExtension(file.name)}`
                : file.name;
            const fileField = { uploadName, fieldKey: file.name };
            const deletePath = buildFBUploadPath(uploadName, fileField, !overrideFileName);
            await deleteFn(fileField.fieldKey, deletePath, await getFileBlob(file));
        }),
    );
}

export function useFileUploaderVars(props: FileUploaderBulkProps) {
    const {
        accept,
        initialFiles,
        storagePathConfig,
        overrideFileName,
        prependTimestampToFilename,
        onLoadingStateChange,
        onComplete,
        processImages,
        onSetCanUpload,
        autoSubmit,
        deleteOnReset,
        replaceExisting,
    } = props;
    const [inputFiles, setInputFiles] = React.useState<File[]>(initialFiles ?? []);
    const [loading, setLoadingInternal] = React.useState<boolean>(false);
    const [uploadFn, { deleteFn }] = useFirebaseMultiFileUpload(
        storagePathConfig,
        inputFiles.map(f => ({ fieldKey: f.name, uploadName: overrideFileName ?? f.name })),
    );
    const setLoading = React.useCallback(
        (newLoadingState: boolean) => {
            onLoadingStateChange?.(newLoadingState);
            setLoadingInternal(newLoadingState);
        },
        [onLoadingStateChange],
    );
    const [uploadCompleted, setUploadCompleted] = React.useState<boolean>(!!initialFiles);
    const onSubmit = React.useCallback(
        async (filesToSubmit: File[] = inputFiles, notes?: string) => {
            if (filesToSubmit.length === 0) {
                return;
            }
            setLoading(true);
            try {
                const result = await uploadFilesToGCS({
                    filesToSubmit,
                    uploadFn,
                    overrideFileName,
                    prependTimestampToFilename,
                    processImages,
                });
                setUploadCompleted(true);
                setLoading(false);
                onComplete && onComplete(result, filesToSubmit, notes);
            } catch (e: any) {
                console.error(e);
                setLoading(false);
            }
        },
        [inputFiles, onComplete, setLoading, uploadFn, overrideFileName, processImages, prependTimestampToFilename],
    );

    const onReset = async () => {
        setInputFiles([]);
        setUploadCompleted(false);
        try {
            if (deleteOnReset) {
                await deleteFiles(inputFiles, deleteFn, overrideFileName);
            }
        } catch (e: any) {
            console.error(e);
        }
        props.onReset && props.onReset();
    };

    const onDropAccepted = React.useCallback(
        (newFiles: File[]) => {
            const newFileNames = newFiles.map(f => f.name);
            const keepExisting = !replaceExisting ? inputFiles.filter(e => !newFileNames.includes(e.name)) : [];
            const newInputFiles = [...keepExisting, ...newFiles];
            setInputFiles(newInputFiles);
            onSetCanUpload && onSetCanUpload(newInputFiles.length > 0);
            const uploadAllowed = !uploadCompleted || replaceExisting;
            if (autoSubmit && newInputFiles.length > 0 && !loading && uploadAllowed) {
                console.debug('FileUploaderBulk auto onSubmit called');
                void onSubmit(newInputFiles);
            }
        },
        [inputFiles, onSetCanUpload, autoSubmit, loading, uploadCompleted, onSubmit, replaceExisting],
    );

    return { accept, onReset, onDropAccepted, onSubmit, uploadCompleted, loading, inputFiles, setInputFiles };
}

export function useGetAttachmentsPath(caseId: string, sessionId?: string | null): BucketStoragePathConfig {
    const storagePathConfig = useQuery(GetStoragePathConfig_Query, {
        variables: {
            whichConfig: 'ordering',
            uploadType: 'attachments',
            paths: sessionId ? [caseId, sessionId] : [caseId],
        },
    });
    return {
        path: storagePathConfig.data?.getStoragePathConfig.path ?? '',
        bucketName: storagePathConfig.data?.getStoragePathConfig.bucketName ?? '',
    };
}

export function useGetRefabAttachmentsPath(caseId: string, itemId?: string | undefined): BucketStoragePathConfig {
    const storagePathConfig = useQuery(GetStoragePathConfig_Query, {
        variables: {
            whichConfig: 'ordering',
            uploadType: itemId ? 'refabItemAttachments' : 'refabAttachments',
            paths: itemId ? [caseId, itemId] : [caseId],
        },
    });
    return {
        path: storagePathConfig.data?.getStoragePathConfig.path ?? '',
        bucketName: storagePathConfig.data?.getStoragePathConfig.bucketName ?? '',
    };
}

export enum FileUploadStatus {
    uploading = 'uploading',
    completed = 'completed',
    errored = 'errored',
}

interface UploadedFileBare {
    file: {
        name: string;
        type: string | null | undefined;
    };
    uploadName: string;
    status: FileUploadStatus;
    downloadUrl: string;
    filepath: string;
}

interface UploadedFileFull {
    file: File;
    uploadName: string;
    status: FileUploadStatus;
    percentage?: number;
    error?: string;
    filepath?: string;
}

export type UploadedFile = UploadedFileFull | UploadedFileBare;

interface GenericFileUploadArgs {
    storagePathConfig: BucketStoragePathConfig;
    caseId?: string;
    analyticsEventName?: keyof DandyAnalyticsEventSchemaType;
}

export interface FileUploadAnalyticsMetadata {
    productName: string;
    displayLocation?: FileUploadDisplayLocation;
    device: DeviceLocation;
}

export function useUploadFiles({ caseId, storagePathConfig, analyticsEventName }: GenericFileUploadArgs) {
    const { path: storagePath, bucketName } = storagePathConfig;
    const firebaseService = useFirebase();

    const uploadFiles = React.useCallback(
        async (
            files: File[],
            analyticsMetadata: FileUploadAnalyticsMetadata,
            onUpdate?: (fileUploads: UploadedFile[]) => void,
        ) => {
            if (!bucketName || !storagePath) {
                return;
            }

            const firebaseStorage = firebaseService.storage(bucketName);

            const fileUploads: UploadedFileFull[] = files.map(file => ({
                file,
                uploadName: `${uuid()}.${file.name}`,
                status: FileUploadStatus.uploading,
            }));

            function onChanged() {
                onUpdate?.(fileUploads);
            }
            onChanged();
            for (const fileUpload of fileUploads) {
                const blob = await getProcessedImage(fileUpload.file, true);
                fileUpload.status = FileUploadStatus.uploading;
                const metadata = {
                    contentType: fileUpload.file.type,
                    customMetadata: {
                        displayName: fileUpload.file.name,
                    },
                };
                const uploadTask = firebaseStorage.ref(storagePath).child(fileUpload.uploadName).put(blob, metadata);
                fileUpload.filepath = uploadTask.snapshot.ref.fullPath;
                uploadTask.on(
                    firebase.storage.TaskEvent.STATE_CHANGED,
                    snapshot => {
                        fileUpload.percentage = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        onChanged();
                    },
                    error => {
                        fileUpload.status = FileUploadStatus.errored;
                        fileUpload.error = error.message;
                        onChanged();
                    },
                    () => {
                        fileUpload.status = FileUploadStatus.completed;
                        if (analyticsEventName) {
                            BrowserAnalyticsClientFactory.Instance?.track(analyticsEventName, {
                                ...analyticsMetadata,
                                $groups: {
                                    case: caseId,
                                },
                            });
                        }
                        onChanged();
                    },
                );
            }
        },
        [bucketName, firebaseService, storagePath, caseId, analyticsEventName],
    );

    const deleteFile = React.useCallback(
        (filename: string) => {
            if (!bucketName || !storagePath) {
                return;
            }

            const firebaseStorage = firebaseService.storage(bucketName);

            return firebaseStorage.ref(storagePath).child(filename).delete();
        },
        [bucketName, firebaseService, storagePath],
    );

    function useListFiles() {
        const { data } = useSWR(
            ['firebase-list-files', storagePath],
            async () => {
                if (!bucketName || !storagePath) {
                    return [];
                }

                const firebaseStorage = firebaseService.storage(bucketName);

                const { items } = await firebaseStorage.ref(storagePath).listAll();
                const itemsWithMetadata = await Promise.all(
                    items.map(async item => {
                        const metadata = await item.getMetadata();
                        const downloadUrl = await item.getDownloadURL();
                        return {
                            ...metadata,
                            filepath: item.fullPath,
                            downloadUrl,
                        };
                    }),
                );
                return itemsWithMetadata;
            },
            { refreshInterval: 3000, compare: _.isEqual },
        );

        return React.useMemo(
            () =>
                (data || []).map<UploadedFile>(item => ({
                    file: {
                        name: item.customMetadata?.displayName || item.name,
                        type: item.contentType,
                    },
                    uploadName: item.name,
                    status: FileUploadStatus.completed,
                    downloadUrl: item.downloadUrl,
                    filepath: item.filepath,
                })),
            [data],
        );
    }

    return {
        uploadFiles,
        useListFiles,
        deleteFile,
    };
}

// optional sessionId to link mobile and desktop when uploading files from mobile and linking to the desktop attachment modal component
interface OrderFileUploadArgs {
    orderId: string;
    itemId: string | undefined;
    analyticsMetadata: FileUploadAnalyticsMetadata;
    forRefab: boolean;
    setAttachments?: (attachments: RefabAttachment[]) => void;
    sessionId?: string;
}

export const useOrderFileUpload = (args: OrderFileUploadArgs) => {
    const { orderId, itemId, analyticsMetadata, setAttachments, forRefab, sessionId } = args;
    const [isUploading, setIsUploading] = React.useState(false);
    const [files, setFiles] = React.useState<UploadedFile[]>([]);
    const storagePathConfig = useGetAttachmentsPath(orderId, sessionId);
    const storagePathConfigRefab = useGetRefabAttachmentsPath(orderId, itemId);
    const analyticsEventName = forRefab
        ? 'Practice - Portal - Files Attached In Refabrication Flow'
        : 'Practice - Control Panel - Add Attachment Action Submitted';

    const { uploadFiles, useListFiles, deleteFile } = useUploadFiles({
        caseId: orderId,
        storagePathConfig: forRefab ? storagePathConfigRefab : storagePathConfig,
        analyticsEventName,
    });
    async function onDropAccepted(filesToUpload: File[]) {
        setIsUploading(true);
        await uploadFiles(filesToUpload, analyticsMetadata, newFiles => {
            setFiles(existingFiles =>
                existingFiles.concat(newFiles.filter(nf => !existingFiles.some(f => nf.uploadName === f.uploadName))),
            );
        });
    }
    const uploadedFiles = useListFiles();

    React.useEffect(() => {
        setFiles(files => {
            const deletedFiles = files.filter(f => f.filepath && !uploadedFiles.some(uf => uf.filepath === f.filepath));
            return uploadedFiles
                .concat(files.filter(file => !uploadedFiles.some(f => f.uploadName === file.uploadName)))
                .filter(uf => !deletedFiles.some(df => df.filepath === uf.filepath));
        });
        setAttachments?.(uploadedFiles.map(f => ({ firebaseUrl: f.filepath ?? '' })));
        setIsUploading(false);
    }, [uploadedFiles, setFiles, setAttachments]);

    async function onRemoveFile(filename: string) {
        await deleteFile(filename);
    }

    return { onDropAccepted, onRemoveFile, files, isUploading };
};

const UploadTypeSchema = z.union([z.literal('StumpShade'), z.literal('ReplacementPartial')]);
export type UploadType = z.infer<typeof UploadTypeSchema>;

export function isValidUploadType(uploadType: string): uploadType is UploadType {
    const parseResult = UploadTypeSchema.safeParse(uploadType);
    if (!parseResult.success) {
        return false;
    }

    return !!parseResult.data;
}
