import { FlossPalette } from '@orthly/ui-primitives';
import * as THREE from 'three';

export interface CircleProps {
    pos?: THREE.Vector3;
    rot?: THREE.Euler;
    effectDistance?: number;
}

export function drawCircle(
    opts?: Partial<{
        radius: number; // Default 1
        color: string; // Default LIGHT_GRAY

        // From ThreeJS docs: Due to limitations of the OpenGL Core Profile with the WebGL
        // renderer on most platforms linewidth will always be 1 regardless of the set value.
        lineWidth: number; // Default 1
    }>,
): THREE.Line<THREE.CircleGeometry, THREE.LineBasicMaterial> {
    const { radius = 1, color = FlossPalette.LIGHT_GRAY, lineWidth = 1 } = opts ?? {};

    const geometry = new THREE.CircleGeometry(radius, 32);
    const material = new THREE.LineBasicMaterial({ linewidth: lineWidth, color: color, depthTest: false });
    // The circle geometry includes a center point. Remove the center point with .shift(),
    // then repeat the first vertex into the last position so the line is closed.
    geometry.vertices.shift();
    geometry.vertices[0] && geometry.vertices.push(geometry.vertices[0].clone());
    const line = new THREE.Line(geometry, material);
    line.renderOrder = 1000;
    line.visible = false;
    line.matrixAutoUpdate = false;
    return line;
}

export function updateCircle(circle: THREE.Line, circleProps: CircleProps) {
    const { pos, rot, effectDistance } = circleProps;
    if (!!pos) {
        circle.position.copy(pos);
        circle.updateMatrix();
    }
    if (!!rot) {
        circle.rotation.copy(rot);
        circle.updateMatrix();
    }
    if (!!effectDistance) {
        circle.scale.set(effectDistance, effectDistance, effectDistance);
        circle.updateMatrix();
    }
}
