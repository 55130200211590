import { ItemEditorV2DialogField } from '../../components/ItemEditorV2DialogField';
import type { ItemEditorV2BaseProps } from '../../types/ItemEditorV2BaseProps';
import {
    DandyItemMultiToothSelect,
    getImplantBridgeMultiToothSelectWarning,
    getMultiToothSelectValidationError,
} from '@orthly/dentin';
import { CartItemV2Utils, LabOrderItemSKUType } from '@orthly/items';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

export const isItemEditorV2MultiToothItem = CartItemV2Utils.itemIsType([
    LabOrderItemSKUType.Bridge,
    LabOrderItemSKUType.ImplantBridge,
    LabOrderItemSKUType.Partial,
    LabOrderItemSKUType.SurgicalGuide,
]);

interface ItemEditorV2MultiToothFieldProps
    extends ItemEditorV2BaseProps<
        | LabOrderItemSKUType.Bridge
        | LabOrderItemSKUType.ImplantBridge
        | LabOrderItemSKUType.Partial
        | LabOrderItemSKUType.SurgicalGuide
    > {
    isInternal: boolean;
}

export const ItemEditorV2MultiToothField: React.VFC<ItemEditorV2MultiToothFieldProps> = ({
    item,
    updateItem,
    showError,
    isInternal,
}) => {
    // because implant bridges don't currently allow different sets of metadata, ops will sometimes split an
    // implant bridge into two items, meaning that each item may not individually fulfill the validity requirements we expect from users
    // TODO: enable this check for internal users once implant bridges do allow different sets of metadata
    const validationErrorMessage = !isInternal ? getMultiToothSelectValidationError(item) : undefined;
    // for internal users, we allow partials to have no teeth selected as it appears as "Unknown" in the lab slip and elsewhere
    const isInternalPartial = isInternal && CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Partial);
    const hasTeeth = CartItemV2Utils.getUniqueUNNs(item).length > 0;
    const teethCountErrorMessage = !isInternalPartial && !hasTeeth ? 'Please select at least 1 tooth' : undefined;
    const errorText = showError ? validationErrorMessage ?? teethCountErrorMessage : undefined;
    const warningText = getImplantBridgeMultiToothSelectWarning(item) ?? undefined;

    const valueString = isInternalPartial && !hasTeeth ? 'Unknown' : CartItemV2Utils.getExplicitUnnsDisplay(item);

    return (
        <ItemEditorV2DialogField
            fieldLabel={'Teeth'}
            fieldValue={valueString}
            modalTitle={'Select Teeth'}
            modalContent={
                <Grid container justifyContent={'center'}>
                    <Grid item xs={6}>
                        <DandyItemMultiToothSelect item={item} updateItem={updateItem} archLabels={true} />
                    </Grid>
                </Grid>
            }
            errorText={errorText}
            warningText={warningText}
        />
    );
};
