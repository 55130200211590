import type {
    LabsGqlDeliveryAddressFragment,
    LabsGqlLabOrderFragment,
    LabsGqlLineItemFragment,
    LabsGqlLineToothItemFragment,
    LabsGqlScanExportItemFragment,
} from '@orthly/graphql-operations';
import type { LabsGqlLabOrderItemLinkMetadata, LabsGqlLabOrderItemToothInput, Scalars } from '@orthly/graphql-schema';
import { LabsGqlBaseUnitTypeForTooth, LabsGqlOrderItemLinkType } from '@orthly/graphql-schema';
import { CartItemV2Utils } from '@orthly/items';
import type { IOrderItemV2DTO } from '@orthly/items';
import _ from 'lodash';
import moment from 'moment-timezone';

export class LabsUtilsBase {
    static productsForOrderV2(items: IOrderItemV2DTO[]): string[] {
        const labels = items.map(item => {
            const displayName = CartItemV2Utils.getDisplayName(item);
            const material = CartItemV2Utils.getItemDisplayMaterial(item);
            return material ? `${displayName} - ${material}` : displayName;
        });
        return Object.entries(_.countBy(labels)).map(([label, count]) => {
            return count > 1 ? `${label} (x${count})` : label;
        });
    }

    static unitTypeDisplayForItem(lineItem: LabsGqlLineItemFragment) {
        if (lineItem.link_type) {
            return `${lineItem.link_type}`;
        }
        if (lineItem.teeth.length <= 1) {
            const firstItem = lineItem.teeth[0];
            return firstItem?.unit_type || 'Unknown';
        }
        return _.uniq(lineItem.teeth.map(t => t.unit_type)).join(', ');
    }

    static labelForLineItem(
        lineItem: {
            link_type?: LabsGqlOrderItemLinkType | string | null;
            link_metadata?: LabsGqlLabOrderItemLinkMetadata | null;
            material?: string | null;
            teeth: (LabsGqlLineToothItemFragment | LabsGqlLabOrderItemToothInput)[];
        },
        detailed: boolean = false,
    ) {
        // Implants are displayed differently due to special metadata
        if (
            lineItem.link_type === LabsGqlOrderItemLinkType.Implant ||
            lineItem.link_type === LabsGqlOrderItemLinkType.ImplantBridge
        ) {
            const crownTooth = lineItem.teeth.find(tooth => tooth.unit_type === LabsGqlBaseUnitTypeForTooth.Crown);
            const abutmentTooth = lineItem.teeth.find(
                tooth => tooth.unit_type === LabsGqlBaseUnitTypeForTooth.Abutment,
            );
            const materialType = `\r\n\t(${crownTooth?.material ? `Crown: ${crownTooth?.material}` : ''} ${
                abutmentTooth?.material ? `Abutment: ${abutmentTooth?.material}` : ''
            })`;
            return `${lineItem.link_type} - ${lineItem.link_metadata?.manufacturer} ${lineItem.link_metadata?.system} ${lineItem.link_metadata?.connection} ${lineItem.link_metadata?.relationship} ${materialType}`;
        }
        if (lineItem.link_type) {
            return `${lineItem.link_type} ${!!lineItem.material ? `- ${lineItem.material}` : ''} (${
                lineItem.teeth.length
            } items)`;
        }
        const teethLabels = lineItem.teeth.map(t =>
            LabsUtilsBase.labelForToothElement(t, t.material || lineItem.material || '', detailed),
        );
        return _.uniq(teethLabels)
            .map(label => {
                const count = teethLabels.filter(l => l === label).length;
                return count > 1 ? `${label} (x${count})` : label;
            })
            .join(', ');
    }

    static labelForScanItem(scanItem: LabsGqlScanExportItemFragment, detailed: boolean = false) {
        return LabsUtilsBase.labelForLineItem(
            {
                link_type: scanItem.link_type,
                teeth: scanItem.teeth.map(t => ({ ...t, color: scanItem.Color })),
                material: scanItem.Material,
            },
            detailed,
        );
    }

    static labelForToothElement(
        toothItem: LabsGqlLineToothItemFragment | LabsGqlLabOrderItemToothInput,
        materialType: string,
        detailed: boolean,
    ) {
        const tooth = !detailed || !toothItem.unn ? undefined : `Tooth: ${toothItem.unn}`;
        const details = tooth ? ` (${tooth})` : '';
        return `${toothItem.unit_type} - ${materialType} ${details}`;
    }

    static addressToString(
        address: Pick<LabsGqlDeliveryAddressFragment, 'street_one' | 'street_two' | 'city' | 'state' | 'postal_code'>,
    ): string {
        return [
            address.street_one?.trim(),
            address.street_two?.trim(),
            `${address.city ? `${address.city},` : ''} ${address.state || ''} ${address.postal_code || ''}`,
        ]
            .filter(v => !!v)
            .join(', ')
            .trim()
            .replace(/\s+/g, ' ');
    }

    static amountCentsToDollarInput(amount: number | undefined) {
        if (amount === undefined) {
            return '';
        }
        const formatted = `$${amount / 100}`;
        const withDecimal = formatted.includes('.') ? formatted : `${formatted}.00`;
        const afterDecimal = withDecimal.split('.')[1] ?? '';
        return afterDecimal.length > 1 ? withDecimal : `${withDecimal}0`;
    }

    static dollarInputToAmountCents(input: string | undefined): number | undefined {
        if (input === undefined || input === '') {
            return undefined;
        }
        const amount = parseFloat(input.replace('$', ''));
        return amount * 100;
    }

    static refabricationDate(order: Pick<LabsGqlLabOrderFragment, 'needs_refabrication_date'>): Date | undefined {
        return order.needs_refabrication_date ? new Date(order.needs_refabrication_date) : undefined;
    }

    static metafieldValueDisplay(value: Scalars['MetafieldJSON']['output']) {
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        return Array.isArray(value)
            ? value.join(',')
            : // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
              // eslint-disable-next-line no-nested-ternary
              typeof value === 'boolean'
              ? value
                  ? 'Yes'
                  : 'No'
              : value.toString();
    }

    // these are the materials that can be chosen in
    static default3ShapeMaterials: string[] = [
        'Zirconia - monolithic',
        'Zirconia - veneered',
        'Emax',
        'Gold',
        'PFM - Precious Yellow',
        'PFM - Precious White',
        'PFM - Semi-Precious',
        'PFM - Non-precious',
        'Metal',
        'PMMA',
        'Titanium',
        'CoCr - monolithic',
        'Chrome-Cobalt',
        'Flexible',
    ];

    static default3ShapeUnitTypes: string[] = [
        'Abutment',
        'AbutmentScrewRetainedCrown',
        'Aligner',
        'Aligners',
        'Clasp',
        'Crown',
        'CrownPontic',
        'FullDenture',
        'ImplantPlanning',
        'Inlay',
        'Splints',
        'SurgicalGuide',
        'Unknown',
        'Veneer',
    ];

    // 1-32
    static teethNumbers: string[] = _.range(1, 33).map(String);

    static notificationTimeZones: { tz: string; displayName: string; abbrv: string }[] = [
        { displayName: 'U.S. Eastern', tz: 'America/New_York', abbrv: 'ET' },
        { displayName: 'U.S. Central', tz: 'America/Chicago', abbrv: 'CT' },
        { displayName: 'U.S. Mountain', tz: 'America/Denver', abbrv: 'MT' },
        { displayName: 'U.S. Pacific', tz: 'America/Los_Angeles', abbrv: 'PT' },
        { displayName: 'Alaska', tz: 'America/Anchorage', abbrv: 'AKT' },
        { displayName: 'Hawaii', tz: 'Pacific/Honolulu', abbrv: 'HST' },
    ];

    static notificationTimeZoneOptions: { label: string; value: string }[] = LabsUtilsBase.notificationTimeZones.map(
        timezone => ({
            label: timezone.displayName,
            value: timezone.tz,
        }),
    );

    static notificationTimeZoneAbbreviations: { [key: string]: string } = Object.fromEntries(
        LabsUtilsBase.notificationTimeZones.map(timezone => [timezone.tz, timezone.abbrv]),
    );

    // We only really support the timezones in notificationTimeZones in our dropdowns.
    // If the user's browser gives us a timezone, give us one from that list, or undefined.
    static normalizedTimezoneFromBrowser?: string = (() => {
        try {
            const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
            if (moment.tz.zone(browserTz) === null) {
                return undefined;
            }

            // utc offset by timezone can change with daylight savings
            const browserCurrentUtcOffset = moment().tz(browserTz).utcOffset();

            return LabsUtilsBase.notificationTimeZones.find(
                timezone => moment().tz(timezone.tz).utcOffset() === browserCurrentUtcOffset,
            )?.tz;
        } catch {
            return undefined;
        }
    })();

    static nameForHourRange(hour: number): string {
        return `${moment().hour(hour).minute(0).format('h:00A')} - ${moment()
            .hour(hour + 1)
            .minute(0)
            .format('h:00A')}`;
    }

    static bestCallHourOptions(timezone: string | undefined): { label: string; value: string }[] {
        if (!timezone) {
            return [];
        }

        // outreach/cx work 7am-9pm EST
        const open_hours_est = _.range(7, 12 + 9);
        const open_hours_local_time = open_hours_est
            .map(hour => moment().tz('America/New_York').hour(hour).minute(0).tz(timezone).hour())
            // don't offer to call before 5am, it looks weird and unprofessional
            .filter(hour => hour >= 5);

        return _.map(open_hours_local_time, hour => ({
            value: hour.toString(),
            label: LabsUtilsBase.nameForHourRange(hour),
        }));
    }
}
