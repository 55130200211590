import { formatBytes } from './FirebaseUpload.utils';
import {
    stylesFactory,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Text,
    DeleteIcon,
    FolderIcon,
} from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';
import React from 'react';

const useStyles = stylesFactory(() => ({
    previewImg: {
        display: 'flex',
        flexDirection: 'column',
        width: 'auto',
        maxWidth: '100%',
        maxHeight: 60,
        height: 'auto',
        padding: '5px 10px',
    },
    itemIcon: {
        flexWrap: 'wrap',
        justifyContent: 'center',
        maxWidth: 56,
        // This zooms the image preview on hover
        '&:hover img': {
            position: 'absolute',
            maxHeight: 'unset',
            left: 0,
            top: 10,
            zIndex: 1000,
        },
    },
    fileTypeCaption: {
        width: '100%',
        textAlign: 'center',
    },
}));

interface FirebaseImageListItemPreviewProps {
    alt: string;
    src: string;
    style?: CSSProperties;
}

export const FirebaseImageListItemPreview: React.FC<FirebaseImageListItemPreviewProps> = ({ alt, src, style }) => {
    const classes = useStyles();

    return <img alt={alt} src={src} className={classes.previewImg} style={style} />;
};

interface FirebaseFileListItemPreviewProps {
    file: File;
    style?: CSSProperties;
}

export const FirebaseFileListItemPreview: React.FC<FirebaseFileListItemPreviewProps> = ({ file, style }) => {
    const imgSrc = React.useMemo(() => {
        const isImg = file.type.includes('image');
        if (!isImg) {
            return undefined;
        }
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(file);
    }, [file]);
    if (!imgSrc) {
        return null;
    }
    return <FirebaseImageListItemPreview alt={`${file.name}`} src={imgSrc} style={style} />;
};

interface FirebaseListItemWrapperProps {
    primaryText: string;
    secondaryText?: string;
    itemIcon: React.ReactElement;
    onRemove: () => void;
    completed?: boolean;
}

export const FirebaseListItemWrapper: React.FC<FirebaseListItemWrapperProps> = props => {
    const { primaryText, secondaryText, itemIcon, onRemove, completed } = props;
    const classes = useStyles();
    return (
        <>
            <Divider component={'li'} />
            <ListItem dense={true} button key={primaryText}>
                <ListItemIcon className={classes.itemIcon}>{itemIcon}</ListItemIcon>
                <ListItemText
                    style={{ overflow: 'hidden' }}
                    primary={primaryText}
                    primaryTypographyProps={{ style: { textOverflow: 'ellipsis', wordBreak: 'break-all' } }}
                    secondary={secondaryText}
                />
                <ListItemSecondaryAction>
                    {!completed && (
                        <IconButton edge={'end'} aria-label={'delete'} onClick={() => onRemove()}>
                            <DeleteIcon />
                        </IconButton>
                    )}
                </ListItemSecondaryAction>
            </ListItem>
        </>
    );
};

interface FirebaseFileListItemProps {
    file: File;
    onRemove: () => void;
    disableImgPreview?: boolean;
    completed?: boolean;
}

export const FirebaseFileListItem: React.FC<FirebaseFileListItemProps> = props => {
    const { file, onRemove, disableImgPreview, completed } = props;
    const isImg = file.type.includes('image');
    const classes = useStyles();
    const fileExtension = file.name.split('.').slice(-1)[0];

    return (
        <FirebaseListItemWrapper
            primaryText={file.name}
            secondaryText={formatBytes(file.size)}
            onRemove={onRemove}
            completed={completed}
            itemIcon={
                isImg && !disableImgPreview ? (
                    <FirebaseFileListItemPreview file={file} />
                ) : (
                    <>
                        <FolderIcon />
                        <Text className={classes.fileTypeCaption} variant={'caption'}>
                            .{fileExtension}
                        </Text>
                    </>
                )
            }
        />
    );
};

interface FirebaseUploadFileListProps {
    files: File[];
    onRemove: (name: string, idx: number) => void;
    disableImgPreview?: boolean;
    completed?: boolean;
}

export const FirebaseUploadFileList: React.FC<FirebaseUploadFileListProps> = props => {
    const onItemRemove = (name: string, idx: number) => () => props.onRemove(name, idx);
    return (
        <Grid container style={{ paddingTop: 10 }}>
            <Grid item xs={12}>
                <List dense>
                    {props.files.map((file, idx) => (
                        <FirebaseFileListItem
                            key={`${file.name}-${idx}`}
                            file={file}
                            completed={props.completed}
                            onRemove={onItemRemove(file.name, idx)}
                        />
                    ))}
                    {props.files.length > 0 && <Divider component={'li'} />}
                </List>
            </Grid>
        </Grid>
    );
};
