import { decodeRedirectFromSearchParams, REDIRECT_QUERY_PARAM } from '../utils/loginRedirectUrl';
import { usePrevious } from '../utils/usePrevious';
import { useRetainerToken } from './RetainerTokenProvider';
import type { ApolloError } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type {
    LabsGqlPasswordLoginQueryVariables,
    LabsGqlUserLoginOptionFragment,
    LabsGqlOneTimeCodeLoginV2MutationVariables,
} from '@orthly/graphql-operations';
import { usePasswordLoginLazyQuery, useOneTimeCodeLoginV2Mutation } from '@orthly/graphql-react';
import type { IOrganizationType } from '@orthly/retainer-common';
import { apolloErrorMessage, useChangeSubmissionFn, OrthlyBrowserConfig } from '@orthly/ui';
import React from 'react';
import { useHistory } from 'react-router-dom';

type SubmitLoginProps = LabsGqlPasswordLoginQueryVariables & {
    is_mobile_auth?: boolean;
};
interface IStaffMemberLoginContext {
    onSubmitLogin: ({ email, password, is_mobile_auth }: SubmitLoginProps) => void;
    /**
     * Submits the user login as a post request instead of a GraphQL request.
     */
    onPostSubmitLogin: (props: SubmitLoginProps) => Promise<void>;
    onSubmitOneTimeCodeLogin: ({ mobile_phone_number, code }: LabsGqlOneTimeCodeLoginV2MutationVariables) => void;
    loading: boolean;
    oneTimeCodeLoginLoading: boolean;
    loginOptions?: LabsGqlUserLoginOptionFragment[] | undefined;
    loginError?: string;
    oneTimeCodeLoginError?: string;
    onSelectToken: (token: string, key: string) => void;
    switchRetainerToken: (key: string) => void;
    hasRetainerToken: (key: string) => boolean;
    cleanError: (error: ApolloError | undefined) => string | any;
    loadSessionFromLegacyToken: (legacyNativeIdToken: string, onErrorCb?: (error: any) => void) => Promise<void>;
    setLoginOptions: (loginOptions: LabsGqlUserLoginOptionFragment[]) => void;
}

const StaffMemberLoginContext = React.createContext<IStaffMemberLoginContext>({
    loadSessionFromLegacyToken: async () => {},
    onPostSubmitLogin: () => Promise.resolve(),
    onSubmitLogin: () => {},
    onSubmitOneTimeCodeLogin: () => {},
    onSelectToken: () => {},
    switchRetainerToken: () => {},
    hasRetainerToken: () => false,
    cleanError: () => '',
    loading: false,
    oneTimeCodeLoginLoading: false,
    setLoginOptions: () => undefined,
});

export function useStaffMemberLoginProps() {
    return React.useContext(StaffMemberLoginContext);
}

interface StaffMemberLoginProviderProps {
    children: React.ReactNode;
    loadSessionFromLegacyToken: IStaffMemberLoginContext['loadSessionFromLegacyToken'];
    tokenExchangeLoading: boolean;
    allowedOrgTypes: IOrganizationType[];
}

const loginOptionOrgAllowed =
    (allowedOrgTypes: IOrganizationType[]) =>
    (option: LabsGqlUserLoginOptionFragment): boolean => {
        return allowedOrgTypes.includes(option.organization_type);
    };

function getAutoSelectedLoginOption(
    allowedOrgTypes: IOrganizationType[],
    options: LabsGqlUserLoginOptionFragment[],
): LabsGqlUserLoginOptionFragment | undefined {
    const validOptions = options.filter(loginOptionOrgAllowed(allowedOrgTypes));
    return validOptions.length === 1 ? validOptions[0] : undefined;
}

const updateHistoryOnLogin = (history: ReturnType<typeof useHistory>) => {
    const searchParams = new URLSearchParams(history?.location?.search || '');

    if (searchParams.has(REDIRECT_QUERY_PARAM)) {
        const decodedLocation = decodeRedirectFromSearchParams(searchParams);

        // There are intermittent cases where history.push is in contention with redirects/other sources.
        // This ensures that the redirect happens after the current stack has been cleared.
        if (decodedLocation) {
            setTimeout(() => {
                history.push(decodedLocation);
            }, 200);
        }
    }
};

// eslint-disable-next-line max-lines-per-function
export const StaffMemberLoginProvider: React.FC<StaffMemberLoginProviderProps> = props => {
    const { loadSessionFromLegacyToken, tokenExchangeLoading, allowedOrgTypes } = props;
    const history = useHistory();
    const {
        setRetainerToken: onSelectToken,
        switchRetainerToken,
        hasRetainerToken,
        retainerToken,
        deleteRetainerToken,
    } = useRetainerToken();
    const [loginOptions, setLoginOptions] = React.useState<LabsGqlUserLoginOptionFragment[] | undefined>();
    const [callLogin, { loading: loginLoading, error }] = usePasswordLoginLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            const options = data?.passwordLogin ?? [];
            const selected = getAutoSelectedLoginOption(allowedOrgTypes, options);
            if (selected) {
                // don't bother setting login options if we have auto-selected a token
                onSelectToken(selected.access_token, selected.id);
                updateHistoryOnLogin(history);
                return;
            }
            setLoginOptions(options);
            updateHistoryOnLogin(history);
        },
    });
    const [postLoginError, setPostLoginError] = React.useState<Error>();

    const [oneTimeCodeLoginErrorMessage, setOneTimeCodeLoginErrorMessage] = React.useState<string>('');
    const [callOneTimeCodeLogin, { error: otpError, loading: otpLoading }] = useOneTimeCodeLoginV2Mutation();
    const { submit: submitOneTimeCodeLogin } = useChangeSubmissionFn<any, any>(
        variables => callOneTimeCodeLogin({ variables }),
        {
            closeOnComplete: true,
            disableDefaultErrorMessage: true,
            onSuccess: async data => {
                if (data?.data?.oneTimeCodeLoginV2?.approved) {
                    const options = data?.data?.oneTimeCodeLoginV2.options ?? [];
                    const selected = getAutoSelectedLoginOption(allowedOrgTypes, options);
                    BrowserAnalyticsClientFactory.Instance?.track(
                        'Practice - Mobile Auth - Phone Number Authenticated',
                        {},
                    );

                    if (selected) {
                        // don't bother setting login options if we have auto-selected a token
                        onSelectToken(selected.access_token, selected.id);
                        updateHistoryOnLogin(history);
                        return;
                    }
                    setLoginOptions(options);
                    updateHistoryOnLogin(history);
                } else {
                    setOneTimeCodeLoginErrorMessage(data?.data?.oneTimeCodeLoginV2?.error_message ?? '');
                }
            },
        },
    );

    const previousRetainerToken = usePrevious(retainerToken);
    React.useEffect(() => {
        if (!!previousRetainerToken && !retainerToken && (loginOptions ?? []).length > 0) {
            console.debug('Logged out, clearing login options');
            setLoginOptions(undefined);
        }
    }, [previousRetainerToken, loginOptions, retainerToken]);

    const loading = loginLoading || tokenExchangeLoading;
    const oneTimeCodeLoginLoading = otpLoading || tokenExchangeLoading;

    const onPostSubmitLogin = React.useCallback(
        async ({ email, password, is_mobile_auth }: SubmitLoginProps) => {
            try {
                // clear any existing login information
                deleteRetainerToken();
                setLoginOptions(undefined);
                setPostLoginError(undefined);

                const loginResult = await fetch(`${OrthlyBrowserConfig.practicePortalAuthUrl}/login`, {
                    method: 'POST',
                    body: JSON.stringify({ email, password }),
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    credentials: 'include',
                });

                if (is_mobile_auth) {
                    BrowserAnalyticsClientFactory.Instance?.track('Practice - Mobile Auth - Email Authenticated', {});
                }

                const options = await loginResult.json();
                const selected = getAutoSelectedLoginOption(allowedOrgTypes, options);

                if (selected) {
                    // don't bother setting login options if we have auto-selected a token
                    onSelectToken(selected.access_token, selected.id);
                    updateHistoryOnLogin(history);
                    return;
                }
                setLoginOptions(options);
                updateHistoryOnLogin(history);
            } catch {
                setPostLoginError(new Error('Authentication failed. Please check credentials and retry.'));
            }
        },
        [allowedOrgTypes, history, onSelectToken, deleteRetainerToken, setLoginOptions],
    );

    const onSubmitLogin = React.useCallback(
        (variables: SubmitLoginProps) => {
            if (!loading) {
                // clear any pre-existing retainer tokens since we're logging in
                deleteRetainerToken();
                setLoginOptions(undefined);

                if (variables?.is_mobile_auth) {
                    BrowserAnalyticsClientFactory.Instance?.track('Practice - Mobile Auth - Email Authenticated', {});
                }

                callLogin({ variables });
            }
        },
        [callLogin, loading, deleteRetainerToken],
    );
    const onSubmitOneTimeCodeLogin = React.useCallback(
        (variables: LabsGqlOneTimeCodeLoginV2MutationVariables) => {
            if (!oneTimeCodeLoginLoading) {
                setLoginOptions(undefined);
                void submitOneTimeCodeLogin(variables);
            }
        },
        [oneTimeCodeLoginLoading, submitOneTimeCodeLogin],
    );

    const validLoginOptions = React.useMemo(
        () => loginOptions?.filter(loginOptionOrgAllowed(allowedOrgTypes)),
        [allowedOrgTypes, loginOptions],
    );

    const cleanError = React.useCallback(
        (error: ApolloError | Error | undefined) => {
            if (loginOptions && validLoginOptions && validLoginOptions.length === 0) {
                const article = allowedOrgTypes[0] === 'internal' ? 'an' : 'a';
                return `User not associated with ${article} ${allowedOrgTypes.join(' or ')} organization`;
            }
            return error ? apolloErrorMessage(error, ' ') : undefined;
        },
        [allowedOrgTypes, loginOptions, validLoginOptions],
    );
    const loginError = React.useMemo<string | undefined>(() => {
        return cleanError(error ?? postLoginError);
    }, [cleanError, error, postLoginError]);
    const oneTimeCodeLoginError = React.useMemo<string | undefined>(() => {
        return otpError ? cleanError(otpError) : oneTimeCodeLoginErrorMessage;
    }, [cleanError, otpError, oneTimeCodeLoginErrorMessage]);

    return (
        <StaffMemberLoginContext.Provider
            value={{
                loading,
                oneTimeCodeLoginLoading,
                onPostSubmitLogin,
                onSubmitLogin,
                onSubmitOneTimeCodeLogin,
                onSelectToken,
                switchRetainerToken,
                hasRetainerToken,
                loadSessionFromLegacyToken,
                loginError,
                oneTimeCodeLoginError,
                cleanError,
                loginOptions: validLoginOptions && validLoginOptions.length > 0 ? validLoginOptions : undefined,
                setLoginOptions,
            }}
        >
            {props.children}
        </StaffMemberLoginContext.Provider>
    );
};
