import type { UploadedFile } from '../FirebaseUpload/FileUploader.utils';
import { ValidationUtils } from '@orthly/runtime-utils';
import { Icon, OrderPaperIcon } from '@orthly/ui';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

type FileListPreviewIconProps = {
    file: UploadedFile;
    onClick?: () => void;
} & (
    | {
          variant: 'confirmDeleteView' | 'preview';
          maxThumbnailSize?: never;
      }
    | {
          variant: 'listView';
          maxThumbnailSize: number;
      }
    | {
          variant: 'scanView';
          maxThumbnailSize: number;
      }
);

const useStyles = stylesFactory(() => ({
    listView: (maxThumbnailSize: number) => ({
        minWidth: maxThumbnailSize,
        minHeight: maxThumbnailSize,
        maxWidth: maxThumbnailSize,
        maxHeight: maxThumbnailSize,
        borderRadius: maxThumbnailSize < 50 ? 4 : 8,
        cursor: 'pointer',
        objectFit: 'cover' as const,
    }),
    scanView: (maxThumbnailSize: number) => ({
        minWidth: maxThumbnailSize,
        minHeight: maxThumbnailSize,
        maxWidth: maxThumbnailSize,
        maxHeight: maxThumbnailSize,
        borderRadius: maxThumbnailSize < 50 ? 4 : 8,
        cursor: 'pointer',
        objectFit: 'cover' as const,
    }),
    confirmDeleteView: { maxWidth: 40, maxHeight: 40 },
    preview: { width: '100%', maxHeight: '100%', margin: 'auto', minHeight: 0, objectFit: 'contain' },
}));

export const FileListPreviewImage: React.VFC<FileListPreviewIconProps> = ({
    file,
    variant,
    onClick,
    maxThumbnailSize,
}) => {
    const className = useStyles(maxThumbnailSize || 10)[variant];
    const imgSrc = React.useMemo(() => {
        const isImageFile = file.file.type?.includes('image') || ValidationUtils.isImage(file.file.name);
        if (!isImageFile) {
            return undefined;
        }
        if ('downloadUrl' in file) {
            return file.downloadUrl;
        }
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(file.file);
    }, [file]);

    if (imgSrc) {
        return <img alt={file.file.name} src={imgSrc} className={className} onClick={onClick} />;
    }

    if (variant === 'listView' || variant === 'scanView') {
        return (
            <div className={className} onClick={onClick} style={{ background: FlossPalette.DARK_TAN }}>
                <Icon
                    icon={variant === 'scanView' ? 'ScanIcon' : 'OrderPaperIcon'}
                    titleAccess={file.file.name}
                    style={{
                        color: FlossPalette.LIGHT_GRAY,
                        width: maxThumbnailSize - 4,
                        height: maxThumbnailSize - 4,
                        margin: 2,
                    }}
                />
            </div>
        );
    }

    return <OrderPaperIcon className={className} titleAccess={file.file.name} onClick={onClick} />;
};
