import { isBufferAttribute } from '../Three/TypeHelpers';
import { AttributeName } from './BufferAttributeConstants';
import { getVerticesHash } from './BufferGeometry.util';
import { ensureMeshIndex } from './MeshIndex';
import { getVertexIndicesOfFace } from './Triangle.util';
import type { DcmSpline } from '@orthly/shared-types';
import * as THREE from 'three';
import type { MeshBVH, HitPointInfo } from 'three-mesh-bvh';

interface SplinePoint {
    // Index of the nearest neighbor vertex in the geometry
    nearestNeighborIndex: number;
    // Offset from the nearest neighbor vertex to the spline point
    nearestNeighborOffset: THREE.Vector3;
    // Cached updated spline point position
    updatedPosition: THREE.Vector3;
}

interface Spline {
    name: string;
    // This differentiates splines with the same name
    pointsHash: string;
    points: SplinePoint[];
}

/**
 * Updates the positions of spline points based on the current positions of the geometry vertices.
 *
 * This is accomplished by finding the nearest neighbor vertex in the geometry to each spline point, finding the offset
 * between the spline point and the nearest neighbor, and then applying that offset to the current position of the
 * nearest neighbor.
 */
export class SplineUpdater {
    private positionsHash: string;
    private splines: Spline[];

    private hitPointWorkingVar: HitPointInfo = { distance: 0, faceIndex: 0, point: new THREE.Vector3() };
    private candidateVertexWorkingVar: THREE.Vector3 = new THREE.Vector3();

    /**
     * Constructor
     * @param geometry The geometry that the splines are associated with. It is assumed that the number of vertices and
     * their order will not change.
     * @param splines The splines to keep updated
     */
    constructor(
        private geometry: THREE.BufferGeometry,
        splines: DcmSpline[],
    ) {
        this.positionsHash = getVerticesHash(this.geometry);

        const bvh = ensureMeshIndex(this.geometry);
        const index = this.geometry.getIndex();
        if (!index) {
            throw new Error('Geometry is not indexed');
        }

        this.splines = splines.map(spline => this.getInitializedSpline(spline, bvh, index));
    }

    /**
     * Returns the splines, updated for changes to the geometry vertex positions.
     */
    getUpdatedSplines(): DcmSpline[] {
        const newHash = getVerticesHash(this.geometry);
        if (newHash !== this.positionsHash) {
            this.updateSplines();
            this.positionsHash = newHash;
        }

        return this.getSplinesResult();
    }

    private getInitializedSpline(spline: DcmSpline, bvh: MeshBVH, index: THREE.BufferAttribute): Spline {
        const points = spline.points.map<SplinePoint>(p =>
            this.getInitializedSplinePoint(new THREE.Vector3(p.x, p.y, p.z), bvh, index),
        );

        return {
            name: spline.name,
            pointsHash: spline.points_hash,
            points,
        };
    }

    private getInitializedSplinePoint(
        originalPosition: THREE.Vector3,
        bvh: MeshBVH,
        index: THREE.BufferAttribute,
    ): SplinePoint {
        const hit = bvh.closestPointToPoint(originalPosition, this.hitPointWorkingVar);
        if (!hit) {
            throw new Error('Could not find nearest neighbor for spline point');
        }

        const { nearestNeighborPosition, nearestNeighborIndex } = this.getNearestVertex(
            originalPosition,
            getVertexIndicesOfFace(hit.faceIndex, index),
        );

        const nearestNeighborOffset = originalPosition.clone().sub(nearestNeighborPosition);

        return {
            nearestNeighborIndex,
            nearestNeighborOffset,
            updatedPosition: originalPosition,
        };
    }

    private getNearestVertex(
        target: THREE.Vector3,
        vertexIndices: number[],
    ): { nearestNeighborIndex: number; nearestNeighborPosition: THREE.Vector3 } {
        const result = { nearestNeighborIndex: 0, nearestNeighborPosition: new THREE.Vector3() };
        let minimumDistanceSquared = Infinity;

        const geometryPosition = this.getGeometryPosition();

        for (const i of vertexIndices) {
            this.candidateVertexWorkingVar.fromBufferAttribute(geometryPosition, i);
            const distanceSquared = target.distanceToSquared(this.candidateVertexWorkingVar);

            if (distanceSquared < minimumDistanceSquared) {
                result.nearestNeighborIndex = i;
                result.nearestNeighborPosition.copy(this.candidateVertexWorkingVar);
                minimumDistanceSquared = distanceSquared;
            }
        }

        return result;
    }

    private updateSplines(): void {
        for (const spline of this.splines) {
            this.updateSpline(spline);
        }
    }

    private updateSpline(spline: Spline): void {
        const geometryPosition = this.getGeometryPosition();

        for (const point of spline.points) {
            point.updatedPosition
                .fromBufferAttribute(geometryPosition, point.nearestNeighborIndex)
                .add(point.nearestNeighborOffset);
        }
    }

    private getSplinesResult(): DcmSpline[] {
        return this.splines.map(spline => ({
            name: spline.name,
            points_hash: spline.pointsHash,
            points: spline.points.map(p => ({
                x: p.updatedPosition.x,
                y: p.updatedPosition.y,
                z: p.updatedPosition.z,
            })),
        }));
    }

    private getGeometryPosition(): THREE.BufferAttribute {
        // We cannot cache a reference to the position attribute object because it may change, e.g. if the geometry's
        // `copy` method is called. To be robust, we always get the attribute from the geometry.
        const position = this.geometry.getAttribute(AttributeName.Position);
        if (!isBufferAttribute(position)) {
            throw new Error('Geometry does not have position BufferAttribute');
        }
        return position;
    }
}
