import type { QcHeatmapRange } from '../../ColorRamp';
import { getHeatmapRange } from '../../ModelAppearance/ModelAppearance.utils';
import { HIGHLIGHT_TOOTH_COLOR } from '../defaultModelColors';
import { createDesignMeshShaderMaterial } from './designMeshShaderMaterial.utils';
import { HeatMapType } from '@orthly/forceps';
import { useMemoizedValue } from '@orthly/ui';
import React from 'react';
import * as THREE from 'three';

export type CustomMeshPhongMaterialProps = THREE.MeshPhongMaterialParameters & {
    includeCurtains: boolean;
    activeHeatMap?: HeatMapType;
    heatMapRange?: QcHeatmapRange;
    showSculptMask?: boolean;
};

// We are extending the standard mesh phong material with custom colors heatmap based on distance attributes
export const DesignMeshShaderMaterial: React.FC<CustomMeshPhongMaterialProps> = props => {
    const {
        activeHeatMap = HeatMapType.Thickness,
        includeCurtains,
        heatMapRange: maybeHeatMapRange,
        showSculptMask,
        ...meshPhongMaterialProps
    } = props;
    const heatMapRange = useMemoizedValue(getHeatmapRange({ activeHeatMap, heatMapRange: maybeHeatMapRange }));

    const activeHeatMapUniformRef = React.useRef(new THREE.Uniform(activeHeatMap));
    React.useEffect(() => {
        activeHeatMapUniformRef.current.value = activeHeatMap;
    }, [activeHeatMap]);

    const vMinUniformRef = React.useRef(new THREE.Uniform(heatMapRange.min));
    const vMaxUniformRef = React.useRef(new THREE.Uniform(heatMapRange.max));
    React.useEffect(() => {
        vMinUniformRef.current.value = heatMapRange.min;
        vMaxUniformRef.current.value = heatMapRange.max;
    }, [heatMapRange]);

    const includeCurtainsUniformRef = React.useRef(new THREE.Uniform(includeCurtains));
    React.useEffect(() => {
        includeCurtainsUniformRef.current.value = includeCurtains;
    }, [includeCurtains]);

    const showSculptMaskUniformRef = React.useRef(new THREE.Uniform(showSculptMask));
    React.useEffect(() => {
        showSculptMaskUniformRef.current.value = showSculptMask;
    }, [showSculptMask]);

    const memoizedProps = useMemoizedValue(meshPhongMaterialProps);

    const material = React.useMemo(() => {
        return createDesignMeshShaderMaterial(memoizedProps, {
            activeHeatMap: activeHeatMapUniformRef.current,
            vMin: vMinUniformRef.current,
            vMax: vMaxUniformRef.current,
            defaultColor: new THREE.Uniform(new THREE.Color(HIGHLIGHT_TOOTH_COLOR)),
            includeCurtains: includeCurtainsUniformRef.current,
            showSculptMask: showSculptMaskUniformRef.current,
        });
    }, [memoizedProps]);

    React.useEffect(() => {
        // It would be ideal to for performance to just mark the
        // uniforms for update not the whole material
        // but since we don't create custom material but are patching
        // existing one, we have to mark the whole material for update
        // because THREE doesn't expose methods or flags to do that
        // for standard materials such as MeshPhysicalMaterial
        material.needsUpdate = true;
    }, [material, memoizedProps.map, memoizedProps.vertexColors]);

    React.useEffect(() => {
        return () => material.dispose();
    }, [material]);

    return <primitive object={material} attach={'material'} />;
};
