import { SignUpPageContainer } from '../SignUpPageContainer';
import React from 'react';

export const SignUpShippingRoot: React.FC = () => {
    return (
        <SignUpPageContainer
            header={'Confirm your shipping address'}
            subheader={`Your address is used to ship your equipment and cases.  Your equipment will arrive in 7-10 business days.`}
        >
            <div>
                <p>Address form</p>
            </div>
        </SignUpPageContainer>
    );
};
