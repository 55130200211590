import { findClosestPointToPolyline } from '../../MarginLines/PolylineMargin.util';
import { ensureMeshIndex } from '../../Utils3D/MeshIndex';
import type { DcmGeometryInjector } from '../DcmFiles/DcmGeometryInjector';
import type { ModellingTree } from '../DentalDesignerModellingTree/ModellingTree';
import * as THREE from 'three';

/**
 * Calculates the difference between the requested and received insertion axes
 * @param modelJobId ID of the model job in `modellingTree` that corresponds to the DCM
 * @param dcm The DCM from which the received insertion axis is drawn
 * @param modellingTree The modelling tree from which the requested insertion axis is drawn
 * @param isUpper Whether the crown is on the upper jaw
 * @param dcmBasename The name of the DCM file, for error reporting
 * @returns The difference (angle in radians) between the requested and received insertion axes
 */
export function getInsertionAxesDiff(
    modelJobId: string,
    dcm: DcmGeometryInjector,
    modellingTree: ModellingTree,
    isUpper: boolean,
    dcmBasename?: string,
): { insertionAxesDiffRad: number; dcmInsertionAxis: THREE.Vector3; ddmtInsertionAxis: THREE.Vector3 } {
    const modellingTreeInsertionAxis = modellingTree.insertionAxes?.get(modelJobId);
    if (!modellingTreeInsertionAxis) {
        throw new Error(`Failed to find insertion axis in the modelling tree for ${dcmBasename}`);
    }

    const upper2Lower = modellingTree.getUpper2LowerMatrix();
    if (!upper2Lower) {
        throw new Error(`Failed to get UpperJaw2LowerJaw matrix from ${dcmBasename}`);
    }

    const dcmInsertionAxis = dcm.getAutomateInsertionDirection(isUpper ? upper2Lower : undefined);
    if (!dcmInsertionAxis) {
        throw new Error(`Failed to get insertion axis from ${dcmBasename}`);
    }

    return {
        insertionAxesDiffRad: dcmInsertionAxis.angleTo(modellingTreeInsertionAxis.drillDirection),
        ddmtInsertionAxis: modellingTreeInsertionAxis.drillDirection,
        dcmInsertionAxis,
    };
}

/**
 * Calculates the maximum deviation of the margin line off of the mesh surface
 * @param dcm The DCM containing the margin line and mesh geometry to compare
 * @param dcmBasename The name of the DCM file, for error reporting
 * @returns The maximum distance of the margin line off of the mesh surface
 */
export function getMaxMarginDiffFromMesh(
    dcm: DcmGeometryInjector,
    dcmBasename?: string,
): {
    maxDiff: number;
    prepLineCrvPoints: THREE.Vector3[];
} {
    const marginLine = dcm.parseSplines().find(el => el.name === 'PrepLineCrv');
    if (!marginLine) {
        throw new Error(`Failed to get margin line from ${dcmBasename}`);
    }

    const geometry = dcm.buildGeometry();
    const bvh = ensureMeshIndex(geometry);

    let maxDiff = 0;
    const hitPointInfo = {} as any;
    for (const point of marginLine.points) {
        if (bvh.closestPointToPoint(point, hitPointInfo, maxDiff) && hitPointInfo.distance > maxDiff) {
            maxDiff = hitPointInfo.distance;
        }
    }

    return { maxDiff, prepLineCrvPoints: marginLine.points };
}

/**
 * Calculates the mean and maximum deviation of the received margin line from the requested margin line
 * @param toothElementId ID of the tooth element in `modellingTree` that corresponds to the DCM
 * @param dcm The DCM from which the received margin line is drawn
 * @param modellingTree The modelling tree from which the requested margin line is drawn
 * @param dcmBasename The name of the DCM file, for error reporting
 * @returns The mean and maximum distance of the received margin line points from the requested margin line
 */
export function getMarginDiff(
    toothElementId: string,
    dcm: DcmGeometryInjector,
    modellingTree: ModellingTree,
    dcmBasename?: string,
): { mean: number; max: number } {
    const dcmMarginLine = dcm.parseSplines().find(el => el.name === 'PrepLineCrv');
    if (!dcmMarginLine?.points.length) {
        throw new Error(`Failed to get margin line from ${dcmBasename}`);
    }

    const ddmtMarginLine = modellingTree.interfaceMargins?.get(toothElementId);
    if (!ddmtMarginLine) {
        throw new Error(`Failed to get margin line for ${dcmBasename} from the modelling tree.`);
    }

    const mountDirection = dcm.mountDirection;
    if (!mountDirection) {
        throw new Error(`Failed to get mount direction from ${dcmBasename}`);
    }

    // TODO (EPDCAD-614) Get the cement gap from the DentalDesignerModellingTree file.
    const CEMENT_GAP_MM = 0.04;
    const marginOffset = mountDirection.clone().multiplyScalar(-CEMENT_GAP_MM);

    let maxDiff = 0;
    let sumDiff = 0;
    for (const point of dcmMarginLine.points) {
        const { closestDistance } = findClosestPointToPolyline(
            ddmtMarginLine,
            point.clone().add(marginOffset),
            true,
            true,
            maxDiff,
        );
        if (closestDistance > maxDiff) {
            maxDiff = closestDistance;
        }
        sumDiff += closestDistance;
    }

    return { mean: sumDiff / dcmMarginLine.points.length, max: maxDiff };
}

/**
 * Calculates the ratio of margin line points in undercut over the total number of points
 * @param margin The margin line points
 * @param insertionAxis The insertion axis
 * @param prepScanGeometry The geometry of the prep scan
 * @param dcmBasename The name of the DCM file, for error reporting
 */
export function getMarginUndercutRatio(
    margin: THREE.Vector3[],
    insertionAxis: THREE.Vector3,
    prepScanGeometry: THREE.BufferGeometry,
    dcmBasename?: string,
): number {
    if (margin.length === 0) {
        throw new Error(`Margin line is empty in ${dcmBasename}`);
    }

    const bvh = ensureMeshIndex(prepScanGeometry);
    const ray = new THREE.Ray(new THREE.Vector3(), insertionAxis.clone().negate());

    // Sometimes the margin line is slightly below the scan surface, but we do not want to count these points as
    // undercut.
    const SCAN_SURFACE_TOLERANCE_MM = 0.01;
    const offset = ray.direction.clone().multiplyScalar(SCAN_SURFACE_TOLERANCE_MM);

    const numUndercut = margin.reduce((acc, point) => {
        ray.origin.copy(point).add(offset);
        return bvh.raycastFirst(ray, THREE.DoubleSide) ? acc + 1 : acc;
    }, 0);

    return numUndercut / margin.length;
}
