/* eslint-disable max-lines */
import { logger } from '../../Utils/Logger';
import type { MountingMatrix } from '../MountingMatrixParser';
import { tryParseMountingMatrix } from '../MountingMatrixParser';
import {
    getNodeWithName,
    getNodeWithTag,
    getNodeWithType,
    getNumberValue,
    getUpper2LowerMatrixFromBuilder,
    maybeGetNodeAttribute,
    maybeGetNodeWithName,
    maybeGetNodeWithType,
    parseVector3,
    removeNode,
} from './ModellingTree.util';
import _ from 'lodash';
import type * as THREE from 'three';
import { builder, create, fragment } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';
import * as xpath from 'xpath';

export type InsertionAxisData = {
    drillDirection: THREE.Vector3;
    changedByHand: boolean;
};
export type InsertionAxesMap = Map<string, InsertionAxisData>;
export type InterfacesMarginsMap = Map<string, THREE.Vector3[]>;
export type IntaglioSettingsData = {
    cementGap: number;
    sealZoneGap: number;
    sealZoneWidth: number;
    drillRadius: number;
    transitionWidth: number;
};
export type IntaglioSettingsMap = Map<string, IntaglioSettingsData>;

// This informs parser about expected shape and what
// fields/properties might be optional.  For example there
// are no interfaces with implant cases
interface ModellingTreeParsingOptions {
    hasCrowns?: boolean;
    hasImplants?: boolean;
    allowMultiUnit?: boolean;
}
/**
 * util to check has user control in a Scan container node
 * There are 4 List names that correspond to useage of the
 * bite alignment tool
 */
function hasUserControlPoints(node: Node): boolean {
    const hasModelUserCPs = xpath.select("//List[@name='ModelUserCPs']", node, true) !== undefined;
    const hasRefModelUserCPs = xpath.select("//List[@name='RefModelUserCPs']", node, true) !== undefined;
    const hasUserCPsInLCS = xpath.select("//List[@name='UserCPsInLCS']", node, true) !== undefined;
    const hasUserRefCPsInLCS = xpath.select("//List[@name='UserRefCPsInLCS']", node, true) !== undefined;

    return hasModelUserCPs || hasRefModelUserCPs || hasUserCPsInLCS || hasUserRefCPsInLCS;
}

// 2 preps, 2 Pre-Preps, 2 bites would be max expected scans
// to have bite alignment
const MAX_REPEATS_FOR_CTRL_POINT_REMOVAL: number = 6;

/**
 * Wrapper around 3Shape's Dental Designer Modelling Tree XML document.
 */
export class ModellingTree {
    private static readonly ANATOMY_DESIGN_CONTAINER_NAME = 'Design, step 2 (anatomy) container';
    private static readonly INTERFACES_CONTAINER_NAME = 'Define interfaces container';
    private static readonly DIRECTION_CONTAINER_NAME = 'Direction container';

    private modellingTree: XMLBuilder;
    private teethList: XMLBuilder | undefined;
    private toolsActive: XMLBuilder | undefined;
    private marginLineSpline: XMLBuilder | undefined;
    private marginLineControlPoints: XMLBuilder | undefined;

    private readonly hasCrowns: boolean;
    private readonly hasImplants: boolean;
    private readonly allowMultiUnit: boolean;

    private insertionAxesCache: InsertionAxesMap | null;

    // here, null means we have not tried, undefined means we tried and got none
    private interfaceMarginsCache: Map<string, THREE.Vector3[]> | null | undefined;

    private intaglioSettingsCache: Map<string, IntaglioSettingsData> | undefined;

    private constructor(
        xml: string,
        opts: ModellingTreeParsingOptions = { hasCrowns: true, hasImplants: false, allowMultiUnit: false },
    ) {
        this.hasCrowns = opts.hasCrowns ?? true;
        this.hasImplants = opts?.hasImplants ?? false;
        this.allowMultiUnit = opts?.allowMultiUnit ?? false;

        // This allows for top-level comments in the XML.
        const document = create(xml);
        this.modellingTree = getNodeWithTag(document, 'NSITree');

        const designContainerNode = maybeGetNodeWithName(
            this.modellingTree,
            ModellingTree.ANATOMY_DESIGN_CONTAINER_NAME,
        );

        this.teethList = designContainerNode ? maybeGetNodeWithName(designContainerNode, 'TeethList') : undefined;
        this.toolsActive = designContainerNode ? maybeGetNodeWithName(designContainerNode, 'ToolsActive') : undefined;

        const interfacesNode = maybeGetNodeWithName(this.modellingTree, ModellingTree.INTERFACES_CONTAINER_NAME);

        // only ok not to have interfaces if we have no crowns in case
        if (!interfacesNode) {
            if (this.hasCrowns) {
                throw new Error('An interfaces node is expected if case has crowns');
            } else {
                this.marginLineSpline = undefined;
                this.marginLineControlPoints = undefined;
                this.interfaceMarginsCache = undefined;
                return;
            }
        }

        // once we have setting of margin by ModelJobID we can remove this block
        const modelJobListChildFeatures = getNodeWithTag(
            getNodeWithName(interfacesNode, 'ModelJobList'),
            'ChildFeatures',
        );
        const modelJobContainers = modelJobListChildFeatures.toArray();
        if (!this.allowMultiUnit && modelJobContainers.length !== 1) {
            throw new Error('Modelling tree has more than 1 restorative, which is unsupported.');
        }
        const prepLineNode = maybeGetNodeWithType(modelJobContainers[0] as XMLBuilder, 'OPreparationLine');
        this.marginLineSpline = prepLineNode ? getNodeWithType(prepLineNode, 'TSysSpline') : undefined;
        this.marginLineControlPoints = this.marginLineSpline
            ? getNodeWithTag(this.marginLineSpline, 'ControlPoints')
            : undefined;

        // END REMOVE after implement setting of margin by ModelJobID
        this.interfaceMarginsCache = this.parseInterfaceMargins();
        this.intaglioSettingsCache = this.parseIntaglioSettings();
    }

    /**
     * Attempts to parse the given XML string into a ModellingTree instance.
     * @param xml The Dental Designer Modelling Tree XML string
     * @returns A ModellingTree instance, if parsing was successful
     */
    static tryBuildModellingTree(xml: string, opts?: ModellingTreeParsingOptions): ModellingTree | undefined {
        try {
            return this.buildModellingTree(xml, opts);
        } catch (e) {
            logger.warn('Failed to parse modelling tree', e);
            return undefined;
        }
    }

    static buildModellingTree(xml: string, opts?: ModellingTreeParsingOptions): ModellingTree {
        return new ModellingTree(xml, opts);
    }

    /**
     * Prepares the modelling tree for design injection
     */
    prepareForDesignInjection(): void {
        if (!this.teethList || !this.toolsActive) {
            throw new Error('Cannot inject to Modeling tree with no Teeth List');
        }

        this.teethList.toArray(false, false).forEach(removeNode);
        this.toolsActive.toArray(false, false).forEach(removeNode);
    }

    /**
     * Repeatedly find any nodes with the target Control Point List names
     * remove them.  The getNodeWithName function returns the first one it
     * finds.  Using XPath to actually remove nodes is hard because it does
     * not return the XmlBuilder object, otherwise an XPath fn would be much
     * cleaner
     */
    removeBiteAlignmentUserControlPoints(): void {
        let doItAgain = true;
        let tries = 0;
        const nodeNames = ['ModelUserCPs', 'RefModelUserCPs', 'UserCPsInLCS', 'UserRefCPsInLCS'];
        while (doItAgain && tries < MAX_REPEATS_FOR_CTRL_POINT_REMOVAL) {
            doItAgain = false;
            nodeNames.forEach(nodeName => {
                try {
                    const userPts = getNodeWithName(this.modellingTree, nodeName);
                    if (userPts) {
                        userPts.remove();
                        doItAgain = true;
                    }
                } catch {}
            });
            tries++;
        }
    }
    /**
     * Determines if the modelling tree has user specified bite alingment
     * points (eg, one point or 3Point alignment) in Prep or Antag scans.  This scenario causes
     * reopening cases to fail (see Bite Alignment Training material for designers)
     * and failed alignment of CAD to Scans when injecting design from portal
     * edits.
     *
     * This function only cares if any scan container of significance
     * has user control bite points.  That is a Preparation or Antagonist
     * scan container.  We might do other validation on the Modelling Tree
     * related to the number and type of Scan containers but this fn
     * does not care about that.
     */
    detectBiteUserControlPoints(): undefined | boolean {
        try {
            if (!this.modellingTree.node) {
                return undefined;
            }

            const treeNode = this.modellingTree.node;

            const prepScanNodes = xpath.select(
                "//Feature[contains(@name, 'PreparationScan container')]",
                treeNode as any,
            );

            const antagScanNodes = xpath.select(
                "//Feature[contains(@name, 'AntagonistScan container')]",
                treeNode as any,
            );

            return [...prepScanNodes, ...antagScanNodes].some(scanNode => {
                return hasUserControlPoints(scanNode as Node);
            });
        } catch {
            return undefined;
        }
    }

    getUpper2LowerMatrix(): THREE.Matrix4 | undefined {
        return getUpper2LowerMatrixFromBuilder(this.modellingTree);
    }

    get insertionAxes(): InsertionAxesMap {
        if (this.insertionAxesCache) {
            return this.insertionAxesCache;
        }

        const insertionAxes = this.parseInsertionAxes();
        this.insertionAxesCache = insertionAxes;

        return insertionAxes;
    }

    private parseInsertionAxes(): InsertionAxesMap {
        const insertionAxes: InsertionAxesMap = new Map();

        try {
            this.populateInsertionAxes(insertionAxes);
        } catch (e) {
            logger.warn('Failed to parse insertion axes', e);
        }

        return insertionAxes;
    }

    private populateInsertionAxes(insertionAxes: InsertionAxesMap): void {
        const directionContainerNode = getNodeWithName(this.modellingTree, ModellingTree.DIRECTION_CONTAINER_NAME);
        const modelJobs = getNodeWithName(directionContainerNode, 'ModelJobList').first();
        modelJobs.each(
            (modelJobNode: XMLBuilder) => {
                const nodeName = maybeGetNodeAttribute(modelJobNode, 'name');
                if (!nodeName?.startsWith('ModelJob container')) {
                    return;
                }

                const modelJobId: string | undefined = _.last(nodeName.split(' '));
                if (!modelJobId) {
                    return;
                }

                const drillDirectionContainerName = `Drill direction ${modelJobId}`;
                const drillDirectionContainerNode = maybeGetNodeWithName(modelJobNode, drillDirectionContainerName);

                if (!drillDirectionContainerNode) {
                    return;
                }

                const drillDirectionNode = getNodeWithName(drillDirectionContainerNode, 'DrillDirection');
                const drillDirection = parseVector3(drillDirectionNode);
                if (!drillDirection) {
                    return;
                }

                const changedByHandNode = getNodeWithName(drillDirectionContainerNode, 'ChangedByHand');
                const changedByHand = maybeGetNodeAttribute(changedByHandNode, 'value');
                if (changedByHand === undefined) {
                    return;
                }

                insertionAxes.set(modelJobId, {
                    drillDirection,
                    changedByHand: changedByHand.toLowerCase() === 'true',
                });
            },
            /*self*/ false,
            /*recursive*/ false,
        );
    }

    setInsertionAxis(axis: THREE.Vector3) {
        const insertionAxis = maybeGetNodeWithName(this.modellingTree, 'DrillDirection');
        if (!insertionAxis) {
            return false;
        }
        const element = insertionAxis.node as unknown as Element;
        element.setAttribute('x', axis.x.toString());
        element.setAttribute('y', axis.y.toString());
        element.setAttribute('z', axis.z.toString());
        this.insertionAxesCache = this.insertionAxes;
        return true;
    }

    extractInterfacesMargins(): InterfacesMarginsMap | undefined {
        const interfacesNode = maybeGetNodeWithName(this.modellingTree, ModellingTree.INTERFACES_CONTAINER_NAME);
        if (!interfacesNode) {
            return undefined;
        }

        // What you are about to see is scary looking...but, it lets us use xpath
        // which recursively checks across all modelJobs within the interfaces node
        // and will return an array if there are multiple margins per modelJob (eg bridges)

        // XMLBuilder2 relies on import { Node } from '@oozcitak/dom/lib/dom/interfaces'
        //   defined as Node extends EventTarget.  Let's call this xNode for the moment

        // xpath.select(string, node: Node) is in typescript/lib/lib.dom Node
        //   also defined as Node extends EventTarget.  I'll call it tNode

        // unfortunately we cannot cast back and forth from xNode to tNode because there are some differences in
        // implementation.  So you will see as any, when I wish we could be using as (x or t)Node

        // Find margin lines in the Defined Interfaces node
        // xpath here does not support regex, only contains and starts-with
        const allMargins = xpath.select("//Feature[contains(@name, 'Margin line ')]", interfacesNode.node as any);

        const marginMap = new Map<string, THREE.Vector3[]>();
        allMargins.forEach((mlNode: any) => {
            // get back to nice XMLBuilder object so we can use our existing machinery
            const marginLineNode = builder(mlNode as any);

            const nodeName = maybeGetNodeAttribute(marginLineNode, 'name');
            if (!nodeName) {
                throw new Error('No margin line node name');
            }
            // A real toothElementID is 34 characters long `TE${uuidNoDashes}`
            // We have some artificial toothElementIDs in testing so we just make sure
            // we have some string value after 'Margin line'
            const toothElementId = _.last(nodeName.split(' '));
            if (!toothElementId || toothElementId.length < 1) {
                logger.error('No margin line for tooth element ID', { nodeName, toothElementId });
                throw new Error('No margin line tooth element ID');
            }

            const marginLineSpline = getNodeWithType(marginLineNode, 'TSysSpline');
            const marginLineControlPoints = getNodeWithTag(marginLineSpline, 'ControlPoints');
            const points = _.compact(marginLineControlPoints.toArray().map(parseControlPoint));

            marginMap.set(toothElementId, points);
        });
        return marginMap;
    }

    private parseInterfaceMargins(): InterfacesMarginsMap | undefined {
        try {
            return this.extractInterfacesMargins();
        } catch (e) {
            logger.warn('Failed to extract margins', e);
        }
        return undefined;
    }

    get interfaceMargins(): InterfacesMarginsMap | undefined {
        if (this.interfaceMarginsCache === null) {
            const interfaceMargins = this.parseInterfaceMargins();
            this.interfaceMarginsCache = interfaceMargins;
            return interfaceMargins;
        }
        return this.interfaceMarginsCache;
    }

    public getMountingMatrix(isDenture: boolean): MountingMatrix | undefined {
        return tryParseMountingMatrix(this.modellingTree, isDenture);
    }

    extractIntaglioSettings(): IntaglioSettingsMap | undefined {
        const interfacesNode = maybeGetNodeWithName(this.modellingTree, ModellingTree.INTERFACES_CONTAINER_NAME);
        if (!interfacesNode) {
            return undefined;
        }

        const allSettings = xpath.select("//Feature[contains(@name, 'Die interface ')]", interfacesNode.node as any);

        const settingsMap = new Map<string, IntaglioSettingsData>();
        allSettings.forEach((mlNode: any) => {
            const settingsNode = builder(mlNode as any);

            const nodeName = maybeGetNodeAttribute(settingsNode, 'name');
            if (!nodeName) {
                throw new Error('No die interface node name');
            }
            const toothElementId = _.last(nodeName.split(' '));
            if (!toothElementId || toothElementId.length < 1) {
                throw new Error(`No die interface tooth element ID @ ${nodeName}`);
            }

            const settings: IntaglioSettingsData = {
                sealZoneWidth: getNumberValue(settingsNode, 'SpacerAbovePLDist'),
                transitionWidth: getNumberValue(settingsNode, 'SpacingLineSmoothDist'),
                sealZoneGap: getNumberValue(settingsNode, 'CementGapOffset'),
                cementGap: getNumberValue(settingsNode, 'SpacerOffset'),
                drillRadius: getNumberValue(settingsNode, 'DrillRadius'),
            };

            settingsMap.set(toothElementId, settings);
        });
        return settingsMap;
    }

    private parseIntaglioSettings(): IntaglioSettingsMap | undefined {
        try {
            return this.extractIntaglioSettings();
        } catch (e) {
            logger.warn('Failed to extract intaglio settings', e);
        }
        return undefined;
    }

    get intaglioSettings(): IntaglioSettingsMap | undefined {
        return this.intaglioSettingsCache;
    }

    /**
     * Returns the margin line points stored in the modelling tree
     * If there are multiple margins, and no toothID is given, will return the first margin found
     * to keep single units simple
     */
    getMarginLinePoints(toothElementID?: string): THREE.Vector3[] | undefined {
        if (!this.interfaceMargins || this.interfaceMargins.size === 0) {
            return undefined;
        }

        if (!toothElementID) {
            const marginPoints = this.interfaceMargins.values().next().value;
            return marginPoints;
        }

        return this.interfaceMargins.get(toothElementID);
    }

    /**
     * Sets the margin line points in the modelling tree
     * TODO, generalize to take toothElementID for _allowMultiUnit
     */
    setMarginLinePoints(points: THREE.Vector3[]): void {
        if (this.allowMultiUnit) {
            throw new Error('Not implemented for multiunit cases');
        }
        if (!this.marginLineSpline || !this.marginLineControlPoints) {
            throw new Error('Cannot set margin line points on tree with no interfaces container (implant case?)');
        }
        try {
            getNodeWithName(this.marginLineSpline, 'iMisc1').att('value', '0');
        } catch (e) {
            logger.warn('Failed to set iMisc1 of margin line spline to 0', e);
        }

        try {
            getNodeWithName(this.marginLineSpline, 'Name').att('value', '');
        } catch (e) {
            logger.warn('Failed to set Name of margin line spline to empty string', e);
        }

        // Clear the existing control points.
        this.marginLineControlPoints.toArray(false, false).forEach(removeNode);

        // Insert the new control points.
        points.forEach(p => {
            this.marginLineControlPoints?.import(makeControlPointNode(p));
        });

        //Update the margin line map
        if (!this.interfaceMarginsCache) {
            throw new Error('Interface margins failed after setting margin');
        }
        const teId = this.interfaceMarginsCache.keys().next().value;
        this.interfaceMarginsCache.set(teId, points);
    }

    /**
     * Returns the serialized modelling tree XML string, if serialization was successful
     */
    serialize(): string | undefined {
        try {
            return this.modellingTree.end({ prettyPrint: true, headless: true });
        } catch (e) {
            logger.warn('Failed to serialize modelling tree', e);
            return undefined;
        }
    }
}

function parseControlPoint(node: XMLBuilder): THREE.Vector3 | undefined {
    const element = node.node as unknown as Element;

    if (element.getAttribute('type') !== 'TSysVertex') {
        return undefined;
    }

    try {
        const vectorNode = getNodeWithTag(node, 'Vector');
        return parseVector3(vectorNode);
    } catch (e) {
        logger.warn('Failed to parse control point', e);
    }

    return undefined;
}

function makeControlPointNode(vector: THREE.Vector3): XMLBuilder {
    return fragment()
        .ele('Object', { type: 'TSysVertex' })
        .ele('Property', { name: 'VertexColor', value: '10493984' })
        .up()
        .ele('Vector', {
            name: 'p',
            x: vector.x,
            y: vector.y,
            z: vector.z,
        })
        .up();
}
