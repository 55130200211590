import { MultiProductImageWrapper } from '../../ProductImageWrapper';
import { CartItemV2Utils } from '@orthly/items';
import type { IOrderItemV2DTO } from '@orthly/items';
import type { Theme } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Grid, Tooltip } from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        countContainer: {
            paddingRight: 24,
            [theme.breakpoints.down('lg')]: {
                paddingRight: 0,
            },
        },
        forceMobile: {
            paddingRight: 0,
        },
        iconContainer: {
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            width: 'auto',
        },
        tooltip: {
            whiteSpace: 'pre-line',
        },
    }),
);

interface OrderSummaryRowItemIconsProps {
    items: IOrderItemV2DTO[];
    forceMobileDisplay?: boolean;
}

export const OrderSummaryRowItemIcons: React.VFC<OrderSummaryRowItemIconsProps> = ({ items, forceMobileDisplay }) => {
    const classes = useStyles();
    const summary = items.map(i => CartItemV2Utils.getFullDisplayName(i)).join('\n');
    const products = items.map(i => CartItemV2Utils.getProductUnitType(i));

    return (
        <Grid
            container
            wrap={'nowrap'}
            justifyContent={'flex-end'}
            className={cx(classes.countContainer, forceMobileDisplay && classes.forceMobile)}
        >
            <Tooltip title={summary} arrow classes={{ tooltip: classes.tooltip }}>
                <div role={'tooltip-anchor'}>
                    <MultiProductImageWrapper products={products} size={24} />
                </div>
            </Tooltip>
        </Grid>
    );
};
