import type { QcHeatmapOption } from './ColorRamp.types';
import {
    alignmentDistanceColorMap,
    jetColorMapSqrt,
    modelClearanceColorMap,
    orPuColorMap,
    rgbOcclusionColorMap2,
    rgbThicknessColorMap,
} from './colorMappingFunctions';
import { AttributeName, HeatMapType } from '@orthly/forceps';

/**
 * This is used by the legacy ModelViewer to place color legends in a fixed position.  The ColorRamp component is designed to
 * float: left inside it's container so it can otherwise be laid out next to other components.
 */
export const QC_COLOR_LEGEND_FIXED_CONTAINER_STYLE = {
    position: 'absolute' as 'absolute',
    zIndex: 21,

    // the Figma calls for `bottom: 16`, to be equally inset from left and bottom.
    // However until we re-do the buttons, on tablet window sizes the buttons move to the lower-left,
    // and this would be covering them. Temporarily have this above where those buttons would go.
    // When we re-do the buttons, lower this to `bottom: 16`
    bottom: 86,
    left: 16,
};

export const QcHeatmapOptions: { [key in HeatMapType]: QcHeatmapOption } = {
    [HeatMapType.Proximal]: {
        fn: jetColorMapSqrt,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.ProximalDistance),
        qcStaticHeatmapColor: geometry => geometry.attributes.ProxContactColor_,
        minimumMinValue: 0.0,
        maximumMaxValue: 0.12,
        defaultStep: 0.01,
        numSteps: 5,
        defaultRange: {
            min: 0.02,
            max: 0.06,
        },
    },
    [HeatMapType.Occlusal]: {
        fn: rgbOcclusionColorMap2,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.OcclusalDistance),
        qcStaticHeatmapColor: geometry => geometry.attributes.OcclusionColor_,
        minimumMinValue: -0.4,
        maximumMaxValue: 0.8,
        defaultStep: 0.1,
        numSteps: 6,
        defaultRange: {
            min: 0.0,
            max: 0.4,
        },
    },
    [HeatMapType.Thickness]: {
        fn: rgbThicknessColorMap,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.ThicknessDistance),
        qcStaticHeatmapColor: geometry => geometry.attributes.ThicknessColor_,
        minimumMinValue: 0.0,
        maximumMaxValue: 2.0,
        defaultStep: 0.1,
        numSteps: 5,
        defaultRange: {
            min: 0.4,
            max: 1.2,
        },
    },
    [HeatMapType.Clearance]: {
        fn: modelClearanceColorMap,
        // clearance heatmaps do not apply to qc models, so we consider them a
        // nop here
        qcDynamicHeatmapLayer: () => null,
        qcStaticHeatmapColor: () => null,
        minimumMinValue: 0.0,
        maximumMaxValue: 3.0,
        defaultStep: 0.1,
        numSteps: 5,
        defaultRange: {
            min: 0.5,
            max: 1.5,
        },
    },
    [HeatMapType.VertexDisplacement]: {
        fn: rgbThicknessColorMap,

        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.VertexDisplacement),
        qcStaticHeatmapColor: () => null,
        minimumMinValue: 0.0,
        maximumMaxValue: 1.0,
        defaultStep: 0.1,
        numSteps: 6,
        defaultRange: {
            min: 0.0,
            max: 0.5,
        },
    },
    [HeatMapType.SurfaceDisplacement]: {
        fn: orPuColorMap,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.SurfaceDisplacement),
        qcStaticHeatmapColor: geometry => geometry.getAttribute(AttributeName.DisplacementColor),
        minimumMinValue: -1.0,
        maximumMaxValue: 1.0,
        defaultStep: 0.1,
        numSteps: 5,
        defaultRange: {
            min: -0.2,
            max: 0.2,
        },
    },
    [HeatMapType.AlignmentDistance]: {
        fn: alignmentDistanceColorMap,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.AlignmentDistance),
        qcStaticHeatmapColor: geometry => geometry.getAttribute(AttributeName.DisplacementColor),
        minimumMinValue: -1.0,
        maximumMaxValue: 1.0,
        defaultStep: 0.1,
        numSteps: 5,
        defaultRange: {
            min: -0.25,
            max: 0.25,
        },
    },
    [HeatMapType.TissuePressure]: {
        fn: jetColorMapSqrt,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.ProximalDistance),
        qcStaticHeatmapColor: geometry => geometry.attributes.ProxContactColor_,
        minimumMinValue: 0.02,
        maximumMaxValue: 0.25,
        defaultStep: 0.01,
        numSteps: 5,
        defaultRange: {
            min: 0.02,
            max: 0.25,
        },
    },
    [HeatMapType.Undercut]: {
        fn: modelClearanceColorMap,
        qcDynamicHeatmapLayer: () => null,
        qcStaticHeatmapColor: () => null,
        minimumMinValue: 0.0,
        maximumMaxValue: 1.0,
        defaultStep: 0.01,
        numSteps: 6,
        defaultRange: {
            min: 0.0,
            max: 0.1,
        },
        reverse: true,
    },
    [HeatMapType.CementGap]: {
        fn: rgbOcclusionColorMap2,
        qcDynamicHeatmapLayer: geometry => geometry.getAttribute(AttributeName.CementGapDistance),
        qcStaticHeatmapColor: () => null,
        minimumMinValue: 0.0,
        maximumMaxValue: 0.4,
        defaultStep: 0.01,
        numSteps: 5,
        defaultRange: {
            min: 0.0,
            max: 0.1,
        },
    },
};
