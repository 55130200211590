import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { useFetchAllPracticeEmployeesQuery } from '@orthly/graphql-react';
import type { StaffMemberSession } from '@orthly/session-client';
import { useVerifyOneTimeCode } from '@orthly/veneer';

export function usePreferenceProfiles(session: StaffMemberSession | undefined) {
    const { data } = useFetchAllPracticeEmployeesQuery({
        variables: { practice_id: session?.organization_id ?? '' },
    });
    return data?.fetchAllPracticeEmployees ?? [];
}

export function useVerifyCode(
    migratePref: (pref: LabsGqlDoctorPreferencesFragment) => Promise<unknown>,
    profiles: LabsGqlDoctorPreferencesFragment[],
    onError: () => void,
) {
    return useVerifyOneTimeCode(
        () => {
            if (profiles[0]) {
                void migratePref(profiles[0]);
            }
        },
        () => onError(),
    );
}
