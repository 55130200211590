import RecommendedIcon from '../../../../assets/Recommended.png';
import { getPartnerId } from '../../../../utils/authorization';
import { useStripe } from '../../../../utils/useStripe.hook';
import { AddCreditCardModal } from './AddCreditCardModal';
import { LinkBankAccount } from './LinkBankAccount';
import { usePaymentMethodControls } from './usePaymentMethodControls';
import { DEFAULT_TOAST_TIMEOUT, Toast } from '@orthly/dentin';
import { useSession } from '@orthly/session-client';
import { useQueryParam } from '@orthly/ui';
import { stylesFactory, Grid, Menu, MenuItem, AddIcon, Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory<{ enableCreditCardPaymentFeeOptIn?: boolean }>(() => ({
    addPaymentOptionsContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    menuDropdownBtn: {
        padding: 0,
        height: 'unset',
        marginTop: 8,
        justifyContent: 'flex-start',
    },
    linkBankBtn: ({ enableCreditCardPaymentFeeOptIn }) => ({
        marginTop: enableCreditCardPaymentFeeOptIn ? 8 : 4,
        padding: enableCreditCardPaymentFeeOptIn ? 'none' : 0,
    }),
    addIcon: {
        marginRight: 8,
    },
}));

const LinkBankAccountButton = ({ setOpen }: { setOpen?: (open: boolean) => void }) => (
    <MenuItem onClick={setOpen ? () => setOpen(true) : undefined}>
        <Grid container>
            Link bank account
            <Grid item style={{ marginLeft: 8, display: 'flex', alignItems: 'center' }}>
                <img src={RecommendedIcon} alt={''} width={16} style={{ marginRight: 4 }} />
                <Text medium variant={'caption'} color={'PRIMARY_FOREGROUND'}>
                    Recommended
                </Text>
            </Grid>
        </Grid>
    </MenuItem>
);

enum PaymentType {
    CreditCard = 'creditCard',
    BankAccount = 'bankAccount',
}

export const PaymentMethodsTitleAction: React.FC = () => {
    const { value: enableCreditCardPaymentFeeOptIn } = useFeatureFlag('enableCreditCardPaymentFeeOptIn');
    const classes = useStyles({ enableCreditCardPaymentFeeOptIn });
    const [paymentType, setPaymentType] = useQueryParam('addPaymentType');
    const session = useSession();
    const partnerId = getPartnerId(session);
    const stripe = useStripe();

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const { createPaymentMethod } = usePaymentMethodControls();

    const [toastOpen, setToastOpen] = React.useState(false);
    const [toastErrorMessage, setToastErrorMessage] = React.useState<string>();

    const openCreditCardModal = paymentType === PaymentType.CreditCard;
    const openLinkBankAccountModal = paymentType === PaymentType.BankAccount;

    const setOpenCreditCardModal = (open: boolean) => {
        setPaymentType(open ? PaymentType.CreditCard : undefined);
    };
    const setOpenLinkBankAccountModal = (open: boolean) => {
        setPaymentType(open ? PaymentType.BankAccount : undefined);
    };

    const linkAccount = async (routingNumber: string, accountNumber: string) => {
        if (!session || !partnerId) {
            return;
        }

        /**
         * We use `as` here because the `verification_method` is required to skip verification
         * for ACH accounts, but the typings for the Stripe.js wrapper don't have this property in their type
         */
        const createTokenOptions: stripe.BankAccountTokenOptions = {
            account_holder_name: session.organization_name,
            account_holder_type: 'company',
            country: 'US',
            currency: 'usd',
            account_number: accountNumber,
            routing_number: routingNumber,
            verification_method: 'skip',
        } as stripe.BankAccountTokenOptions;

        const response = await stripe.createToken('bank_account', createTokenOptions);
        if (response.error || !response.token) {
            setToastErrorMessage(response.error?.message);
        } else {
            // ACH methods are set as default when added
            await createPaymentMethod({ cardToken: response.token.id, partnerId, setAsDefault: true });
            await setToastOpen(true);
        }
    };

    return (
        <Grid container direction={'column'}>
            <Button
                variant={enableCreditCardPaymentFeeOptIn ? 'primary' : 'ghost'}
                className={classes.linkBankBtn}
                onClick={e =>
                    enableCreditCardPaymentFeeOptIn ? setOpenLinkBankAccountModal(true) : setAnchorEl(e.currentTarget)
                }
            >
                {!enableCreditCardPaymentFeeOptIn && <AddIcon style={{ marginRight: 8 }} />}
                {enableCreditCardPaymentFeeOptIn ? 'Link bank account' : 'Add payment method'}
            </Button>
            {enableCreditCardPaymentFeeOptIn && (
                <Button
                    variant={'ghost'}
                    className={classes.menuDropdownBtn}
                    onClick={e => setAnchorEl(e.currentTarget)}
                >
                    <AddIcon className={classes.addIcon} /> Other methods
                </Button>
            )}
            <Menu
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={() => setAnchorEl(null)}
                disableEnforceFocus
            >
                <LinkBankAccountButton setOpen={setOpenLinkBankAccountModal} />
                <MenuItem
                    onClick={() => {
                        setOpenCreditCardModal(true);
                        setAnchorEl(null);
                    }}
                >
                    Add credit card
                </MenuItem>
            </Menu>
            <AddCreditCardModal
                open={openCreditCardModal}
                setOpen={setOpenCreditCardModal}
                createCard={({ partnerId, cardToken }) =>
                    createPaymentMethod({ partnerId, cardToken, setAsDefault: false })
                }
                setOpenLinkBankAccountModal={setOpenLinkBankAccountModal}
            />
            <LinkBankAccount
                open={openLinkBankAccountModal}
                setOpen={setOpenLinkBankAccountModal}
                linkAccount={linkAccount}
            />
            <Toast
                headline={'Unable to add payment method'}
                description={`We ran into trouble trying to add a new payment method. ${toastErrorMessage}`}
                open={!!toastErrorMessage}
                onDismiss={() => setToastErrorMessage(undefined)}
                variant={'critical'}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
            <Toast
                headline={'Bank account successfully linked'}
                description={'Your bank account was linked, and was set as your primary payment method.'}
                open={toastOpen}
                onDismiss={() => setToastOpen(false)}
                variant={'success'}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </Grid>
    );
};
