import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlLabSalesOrderServiceSpeed } from '@orthly/graphql-schema';
import { Divider, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const PracticeOrderDetailTrackingNoRushBanner_Query = graphql(`
    query PracticeOrderDetailTrackingNoRushBanner_Query($order_id: String!) {
        getLabSalesOrderById(id: $order_id) {
            service_level {
                speed
            }
        }
    }
`);

const BannerWrapper = styled('div')({
    width: '100%',
    borderRadius: '8px',
    padding: '8px 16px',
    backgroundColor: FlossPalette.GRAY,
});

interface OrderDetailTrackingNoRushBannerProps {
    orderId: string;
}

export const OrderDetailTrackingNoRushBanner: React.VFC<OrderDetailTrackingNoRushBannerProps> = ({ orderId }) => {
    const { data } = useQuery(PracticeOrderDetailTrackingNoRushBanner_Query, {
        variables: {
            order_id: orderId,
        },
    });
    const speed = data?.getLabSalesOrderById?.service_level.speed;
    // TODO: update this credit amount with a real value that is stored in the backend.
    // We don't presently store this, but will once the value stops being static.
    const creditAmount = 5;

    if (speed !== LabsGqlLabSalesOrderServiceSpeed.Slow) {
        return null;
    }

    return (
        <>
            <Divider style={{ marginBottom: 16 }} />
            <BannerWrapper>
                <Text variant={'body2'} color={'WHITE'}>
                    You've opted into <strong>no-rush shipping</strong> (longer turnaround time) and earned a $
                    {creditAmount} credit
                </Text>
            </BannerWrapper>
        </>
    );
};
