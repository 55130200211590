import * as THREE from 'three';

/**
 * Gets the pointer position in normalized device coordinates with respect to the canvas
 * @param clientX The X coordinate of the mouse pointer in local (DOM content) coordinates
 * @param clientY The Y coordinate of the mouse pointer in local (DOM content) coordinates
 * @param canvasContainerRect The size of the canvas and its position relative to the viewport
 * @param pointer The resultant pointer position is set here
 */
export function getPointerPositionFromXY(
    clientX: number,
    clientY: number,
    canvasContainerRect: DOMRect,
    pointer: THREE.Vector2,
): void {
    const normalizedX = ((clientX - canvasContainerRect.x) / canvasContainerRect.width) * 2 - 1;
    const normalizedY = -((clientY - canvasContainerRect.y) / canvasContainerRect.height) * 2 + 1;
    pointer.set(normalizedX, normalizedY);
}

/**
 * Gets the pointer position in normalized device coordinates with respect to the canvas
 * @param mouseEvent Contains the pointer position
 * @param canvas The canvas element
 * @param pointer The resultant pointer position is set here
 */
export function getPointerPosition(evt: MouseEvent, canvas: HTMLCanvasElement, pointer: THREE.Vector2): void {
    getPointerPositionFromXY(evt.clientX, evt.clientY, canvas.getBoundingClientRect(), pointer);
}

/**
 * This function must be called to let the raycaster know what the current canvas, camera, and mouse state are to
 * correctly cast rays from the screen onto the 3D scene.  Although not expensive op, in more complex situations you may
 * want to be judicious about when to call this to not waste computation.  Eg if you dont need raycasting on mousemove,
 * only update on click.
 * @param raycaster The raycaster to update
 * @param canvas The canvas element
 * @param camera The camera to cast rays from
 * @param evt The mouse event
 * @param targetTWorld The transformation to the target frame from the world frame. If not supplied, it is assumed that
 *   the target frame is the world frame.
 */

const pointerPosition = new THREE.Vector2();

export function updateRaycaster(
    raycaster: THREE.Raycaster,
    canvas: HTMLCanvasElement,
    camera: THREE.Camera,
    evt: MouseEvent,
    targetTWorld?: THREE.Matrix4,
): void {
    // Note that pointerPosition follows the pass by reference of
    // the c++/3D world. pointerPosition is mutated
    // for that reason we reuse a single Vector object and reuse it
    getPointerPosition(evt, canvas, pointerPosition);
    raycaster.setFromCamera(pointerPosition, camera);
    if (targetTWorld) {
        raycaster.ray.origin.applyMatrix4(targetTWorld);
        raycaster.ray.direction.transformDirection(targetTWorld);
    }
}

/**
 * Updates the raycaster to cast rays from the mouse position onto a target in the 3D scene
 * @param raycaster The raycaster to update
 * @param canvas The canvas element
 * @param camera The camera to cast rays from
 * @param mousePosition The position of the mouse in local (DOM content) coordinates
 * @param targetTWorld The transformation to the target frame from the world frame. If not supplied, it is assumed that
 *   the target frame is the world frame.
 */
export function updateRaycasterFromMousePosition(
    raycaster: THREE.Raycaster,
    canvas: HTMLCanvasElement,
    camera: THREE.Camera,
    mousePosition: THREE.Vector2,
    targetTWorld?: THREE.Matrix4,
): void {
    getPointerPositionFromXY(mousePosition.x, mousePosition.y, canvas.getBoundingClientRect(), pointerPosition);
    raycaster.setFromCamera(pointerPosition, camera);
    if (targetTWorld) {
        raycaster.ray.origin.applyMatrix4(targetTWorld);
        raycaster.ray.direction.transformDirection(targetTWorld);
    }
}
