import { z } from 'zod';

export const PropertySchema = z.object({
    '@name': z.string(),
    '@value': z.string(),
});

export const BinarySplineSchema = z.object({
    '@name': z.literal('Spline'),
    '@type': z.string(),
    Property: z.union([z.array(PropertySchema), PropertySchema]),
    ControlPointsPacked: z.string(),
});

export const SplineObjectSchema = z.object({
    Object: z.union([z.array(BinarySplineSchema), BinarySplineSchema]),
});

export type BinarySplineType = z.infer<typeof BinarySplineSchema>;
