import type * as THREE from 'three';

/**
 * Generates the eight vertices of the specified box using the provided Vector3 as storage.
 * @param box The box whose vertices will be generated.
 * @param target The target vector that will be populated and yielded for each vertex.
 */
export function* boxPoints(box: THREE.Box3, target: THREE.Vector3) {
    target.copy(box.min);
    yield target;
    target.setX(box.max.x);
    yield target;
    target.setY(box.max.y);
    yield target;
    target.setX(box.min.x);
    yield target;
    target.setZ(box.max.z);
    yield target;
    target.setY(box.min.y);
    yield target;
    target.setX(box.max.x);
    yield target;
    target.setY(box.max.y);
    yield target;
}

/**
 * Generates the twelve edges of the specified box using the provided Line3 as storage.
 * @param box The box whose edges will be generated.
 * @param target The target line that will be populated and yielded for each edge.
 */
export function* boxEdges(box: THREE.Box3, target: THREE.Line3) {
    // x-axis parallel
    target.start.copy(box.min);
    target.end.copy(box.min);
    target.end.setX(box.max.x);
    yield target;
    target.start.setY(box.max.y);
    target.end.setY(box.max.y);
    yield target;
    target.start.setZ(box.max.z);
    target.end.setZ(box.max.z);
    yield target;
    target.start.setY(box.min.y);
    target.end.setY(box.min.y);
    yield target;
    // y-axis parallel
    target.start.copy(box.min);
    target.end.copy(box.min);
    target.end.setY(box.max.y);
    yield target;
    target.start.setZ(box.max.z);
    target.end.setZ(box.max.z);
    yield target;
    target.start.setX(box.max.x);
    target.end.setX(box.max.x);
    yield target;
    target.start.setZ(box.min.z);
    target.end.setZ(box.min.z);
    yield target;
    // z-axis parallel
    target.start.copy(box.min);
    target.end.copy(box.min);
    target.end.setZ(box.max.z);
    yield target;
    target.start.setX(box.max.x);
    target.end.setX(box.max.x);
    yield target;
    target.start.setY(box.max.y);
    target.end.setY(box.max.y);
    yield target;
    target.start.setX(box.min.x);
    target.end.setX(box.min.x);
    yield target;
}
