import { useAskDoctorResponseDialog } from '../../../../../../../../order-detail-v2/components/AskDoctorResponse';
import { getAskDoctorV2Configuration } from '../../../../../../../../order-detail-v2/components/AskDoctorResponse/utils';
import { useGenericTimelineActionStyles } from './sharedStyles';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { Button } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

export const AnswerDoctorQuestionAction: React.VFC<{
    className?: string;
    order: LabsGqlOrder;
}> = ({ className, order }) => {
    const classes = useGenericTimelineActionStyles();
    const { doctorRequestId, shouldShowResolveButton } = getAskDoctorV2Configuration(order);

    const [setAskDoctorResponseOpen, askDoctorResponseDialog] = useAskDoctorResponseDialog({
        doctorRequestId,
        order,
    });

    if (shouldShowResolveButton) {
        return (
            <div className={clsx(classes.actionWrapper, className)}>
                <Button variant={'primary'} onClick={() => setAskDoctorResponseOpen(true)}>
                    Resolve issue
                </Button>
                {askDoctorResponseDialog}
            </div>
        );
    }

    return null;
};
