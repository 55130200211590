import type { OrderTimelineV2Item } from '../../../../../PracticeOrderChatV2';
import { AnswerDoctorQuestionAction } from './OrderTimelineActions/AnswerDoctorQuestionAction';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { LabsGqlOrderTimelineV2EventEnum } from '@orthly/graphql-schema';
import React from 'react';

export const OrderTimelineAction: React.VFC<{
    className?: string;
    event: OrderTimelineV2Item;
    order: LabsGqlOrder;
    allSortedEvents: OrderTimelineV2Item[];
}> = ({ className, event, order, allSortedEvents }) => {
    // We only want to show the CTA to resolve the issue on the last scan rejected / ask dr event if it hasn't been resolved already.
    // Ideally we would have data on the event itself to associate the current event with the data on the task, but short of that we have to look at it chronologically.
    const lastRelevantAskDoctorEvent = allSortedEvents.findLast(
        event =>
            event.name === LabsGqlOrderTimelineV2EventEnum.ScanRejectionSharedWithPractice ||
            event.name === LabsGqlOrderTimelineV2EventEnum.AskedDrAboutOrderHold,
    );

    if (lastRelevantAskDoctorEvent === event) {
        return <AnswerDoctorQuestionAction className={className} order={order} />;
    }

    return null;
};
