import { Matrix4Schema, UnlabelledVector3Schema } from './ThreeShapeGeometrySchemas';
import { PropertySchema, SplineObjectSchema } from './ThreeShapeSplineSchemas';
import { create as createXML } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';
import { z } from 'zod';

/**
 * Notes
 * 1. Optional and empty objects
 *      XMLBuilder will convert an empty element (eg <Text/> or <Text></Text>) to {}
 *      You might see z.optional(x.string().or(z.object({})) becuase 3Shape is a little
 *      loose on whether it omits the object, or puts an empty object there
 *          Example
 *              CommentSchema.text which seems to always be there but sometimes empty
 *              CommentSchame.normal which is sometimes there, sometimes absent
 *
 *
 */
const VerticesSchema = z.object({
    '@vertex_count': z.string().transform(Number).or(z.number()),
    '@base64_encoded_bytes': z.string().transform(Number).or(z.number()),
    '@check_value': z.optional(z.string()),
    '#': z.string(),
});

const FacetsSchema = z.object({
    '@facet_count': z.string().transform(Number).or(z.number()),
    '@base64_encoded_bytes': z.string().transform(Number).or(z.number()),
    '@color': z.optional(z.string()),
    '#': z.string(),
});

const PerVertexTextureCoordSchema = z.object({
    '@Base64EncodedBytes': z.string().transform(Number).or(z.number()),
    '@Key': z.optional(z.string()),
    '#': z.string(),
});

const TextureImageSchema = z.object({
    '@Width': z.string().transform(Number).or(z.number()),
    '@Height': z.string().transform(Number).or(z.number()),
    '@TextureName': z.string(),
    '@TextureCoordSet': z.string(),
    '@BytesPerPixel': z.string().transform(Number).or(z.number()),
    '@Base64EncodedBytes': z.string().transform(Number).or(z.number()),
    '#': z.string(),
});

const CeOrCaSchema = z.object({
    '@version': z.string(),
    Facets: FacetsSchema,
    Vertices: VerticesSchema,
});

const PackedGeometrySchema = z.object({
    Schema: z.union([z.literal('CE'), z.literal('CA')]),
    Binary_data: z
        .object({
            CE: z.optional(CeOrCaSchema),
            CA: z.optional(CeOrCaSchema),
        })
        .refine(binaryData => !!binaryData.CE || !!binaryData.CA, 'Must have CE or CA schema object'),
});

// Annotations: (AnnotationSchema | CommentSchema)[]
// The order which they are stored in the array in the xml
// causes trouble for xmlbuilder2 in that it can create weird nesting
// if encounters Comment, Annotation, Annotation, Comment
// for that reason we have to iterate the list and
// check for types in a post processing
export const AnnotationSchema = z.object({
    '@type': z.string(),
    String: PropertySchema,
    Matrix4x4: Matrix4Schema,
    Property: z.union([z.array(PropertySchema), PropertySchema]),
});

export const CommentSchema = z.object({
    Origin: UnlabelledVector3Schema,
    Normal: z.optional(UnlabelledVector3Schema),
    Text: z.string().or(z.object({})),
    Sectioned: z.string(),
    Details: z.optional(z.string()),
});

export const CleanedAnnotationsSchema = z.object({
    Annotation: AnnotationSchema.array(),
    Comment: CommentSchema.array(),
});

export const PropertiesSchema = z.object({ Property: z.union([PropertySchema, PropertySchema.array()]) });
export type CommentType = z.infer<typeof CommentSchema>;
export type AnnotationType = z.infer<typeof AnnotationSchema>;

export const DcmSchema = z.object({
    HPS: z.object({
        '@version': z.string(),
        Packed_geometry: PackedGeometrySchema,
        SignatureHash: z.optional(z.string()),
        FacetMarks: z.optional(z.string()),
        Annotations: z.optional(CleanedAnnotationsSchema),
        Objects: z.optional(z.any()),
        Splines: z.optional(SplineObjectSchema.or(z.object({}))),
        Properties: z.optional(PropertiesSchema),
        TextureData2: z.optional(
            z.object({
                PerVertexTextureCoord: z.optional(PerVertexTextureCoordSchema),
                TextureSetIDs: z.optional(
                    z.object({ '@IndexCount': z.string(), '@Base64EncodedBytes': z.string(), '#': z.string() }),
                ),
                TextureImages: z.optional(
                    z.object({ TextureImage: z.optional(TextureImageSchema.or(z.array(TextureImageSchema))) }),
                ),
            }),
        ),
    }),
});

function loadDCMtoXML(dcmFile: Buffer): XMLBuilder {
    const dcmString = dcmFile.toString();
    return createXML(dcmString);
}

export function parseDcmBuffer(dcmBuffer: Buffer): z.infer<typeof DcmSchema> | undefined {
    const dcmRoot = loadDCMtoXML(dcmBuffer);
    const dcmObject = dcmRoot.toObject();

    const parseResults = DcmSchema.safeParse(dcmObject);
    if (!parseResults.success) {
        return undefined;
    }
    return parseResults.data;
}
