import { PracticeUtils } from '../../utils/utils';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { LabsGqlAlignerDesignSoftware } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { Grid, FlossPalette, Text } from '@orthly/ui-primitives';
import {
    AlignerTreatmentPlanPatientStatusBox,
    AlignerTreatmentPlanViewer,
    OrderAlignerTreatmentPlanSidebar,
} from '@orthly/veneer';
import { SoftsmileViewer } from '@orthly/veneer';
import React from 'react';
import { useLocation } from 'react-router-dom';

const ErrorPage: React.VFC<{ children: React.ReactNode }> = ({ children }) => (
    <Grid
        container
        direction={`column`}
        alignItems={`center`}
        style={{ backgroundColor: FlossPalette.TAN, height: `100vh`, padding: 16 }}
    >
        <Grid item style={{ width: 250, backgroundColor: FlossPalette.WHITE, borderRadius: 16, padding: 16 }}>
            {children}
        </Grid>
    </Grid>
);

const PracticePatientAppRootAlignerCase_Query = graphql(`
    query PracticePatientAppRootAlignerCase_Query($token: String!, $treatmentPlanVersion: Float) {
        patient_portal_lab_order(token: $token, treatmentPlanVersion: $treatmentPlanVersion) {
            id
            patient_first_name
            patient_last_name
            doctor_name

            aligner_case {
                design_software
            }

            aligner_treatment_plan {
                source
                version_number_internal
                ...VeneerAlignerTreatmentPlanViewer_Fragment
                ...VeneerAlignerTreatmentPlanSidebar_Fragment
            }
        }
    }
`);

const PatientAppAuthenticated: React.VFC<{ token: string; versionId?: number }> = ({ token, versionId }) => {
    const [selectedStepIndex, setSelectedStepIndex] = React.useState(0);

    const {
        data,
        loading: labQueryLoading,
        error,
    } = useQuery(PracticePatientAppRootAlignerCase_Query, {
        variables: {
            token,
            treatmentPlanVersion: versionId,
        },
    });

    if (labQueryLoading) {
        return (
            <LoadBlocker blocking={true}>
                <ErrorPage>Loading...</ErrorPage>
            </LoadBlocker>
        );
    }

    if (error || !data?.patient_portal_lab_order) {
        return <ErrorPage>Error loading data</ErrorPage>;
    }

    const {
        patient_first_name,
        patient_last_name,
        doctor_name,
        aligner_case,
        aligner_treatment_plan: treatmentPlan,
        id: labOrderId,
    } = data.patient_portal_lab_order;

    const isSoftsmile = aligner_case?.design_software === LabsGqlAlignerDesignSoftware.SoftSmile;

    return (
        <Grid container style={{ backgroundColor: FlossPalette.TAN, height: `100vh`, padding: 16 }}>
            <Grid container direction={`column`}>
                <Grid item container>
                    <OrderAlignerTreatmentPlanSidebar
                        treatmentPlanFragment={treatmentPlan ?? null}
                        userRole={`none`}
                        statusBox={
                            <AlignerTreatmentPlanPatientStatusBox
                                patient_name={`${patient_first_name} ${patient_last_name}`}
                                doctor_name={doctor_name}
                            />
                        }
                    />
                </Grid>
                <Grid item container style={{ flexGrow: 1 }}>
                    {/* eslint-disable no-nested-ternary */}
                    {isSoftsmile ? (
                        <SoftsmileViewer
                            labOrderId={labOrderId}
                            versionId={
                                versionId !== undefined ? versionId : treatmentPlan?.version_number_internal ?? 0
                            }
                            isFullscreen={false}
                            close={() => {}}
                            accessToken={token}
                            style={{ width: '100%', height: '100%', padding: 8 }}
                        />
                    ) : treatmentPlan ? (
                        <div style={{ height: 528, width: '100%' }}>
                            <AlignerTreatmentPlanViewer
                                fullScreen={true}
                                hideToolbar={true}
                                style={{ height: 480 }}
                                setSelectedStepIndex={setSelectedStepIndex}
                                selectedStepIndex={selectedStepIndex}
                                treatmentPlanFragment={treatmentPlan}
                            />
                        </div>
                    ) : (
                        <Text>Something has gone wrong</Text>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
};

export const PatientAppRoot: React.VFC = () => {
    React.useEffect(() => void (document.title = `Dandy`), []);
    const location = useLocation();
    const token = PracticeUtils.getParsedQueryProperty(`orderToken`, location.search);
    const versionIdParam = PracticeUtils.getParsedQueryProperty(`versionId`, location.search);
    const versionId = versionIdParam && !Number.isNaN(parseInt(versionIdParam)) ? parseInt(versionIdParam) : undefined;

    if (!token) {
        return <ErrorPage>Invalid link</ErrorPage>;
    }

    // Manually pulling this JWT apart is a little bit messy, but we
    // only really need to check the expiration date here and doing a
    // full verification would require syncing the public key config with
    // labs server.  This will prevent us from making invalid requests in
    // the common case of a user clicking an expired link, but we still
    // have the full verification on the backend to catch any less common
    // failure modes
    const payloadBase64 = token.split('.')[1];
    if (!payloadBase64) {
        return <ErrorPage>Invalid link</ErrorPage>;
    }

    try {
        const payload = JSON.parse(atob(payloadBase64));
        if (Date.now() > payload.exp * 1000) {
            return <ErrorPage>Expired link</ErrorPage>;
        }
    } catch (_e) {
        return <ErrorPage>Invalid link</ErrorPage>;
    }

    return <PatientAppAuthenticated {...{ token, versionId }} />;
};

export const PatientAppLoading: React.VFC = () => <ErrorPage>Loading</ErrorPage>;
