import { isPaymentOrCreditRefundCategory, useSummaryRows } from '../components/InvoiceDetailBody.utils';
import type { PrintableInvoiceBodyProps } from './PrintableInvoiceBody';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { Text, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '320px',
    marginBottom: '24px',
});

const Row = styled('div')({
    display: 'grid',
    gridGap: '0px 16px',
    flexDirection: 'row',
    width: '100%',
    gridTemplateColumns: '3fr 28px 1fr',
    gridTemplateRows: 'max-content',
});

const Cell = styled(Text)({
    height: '22px',
    display: 'inline-block',
    flexDirection: 'column',
    fontWeight: 600,
});

export const PrintableInvoiceSummary: React.FC<PrintableInvoiceBodyProps> = ({ invoice, rows, rowsLoading }) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const summaryRows = useSummaryRows(invoice, rows);

    return (
        <Container>
            <LoadBlocker blocking={rowsLoading}>
                {summaryRows.map(row => {
                    const rowTextColor =
                        enableCreditAndRefundOverhaul && isPaymentOrCreditRefundCategory(row) ? 'GREEN' : 'GRAY';
                    return (
                        <Row key={row.id}>
                            <Cell variant={'caption'} color={rowTextColor}>
                                {row.id === 'Balance' ? 'Total balance' : row.id}
                            </Cell>
                            <Cell variant={'caption'} color={rowTextColor}>
                                {row.quantity ?? ' '}
                            </Cell>
                            <Cell variant={'caption'} color={rowTextColor}>
                                {Format.currency(row.amountCents)}
                            </Cell>
                        </Row>
                    );
                })}
            </LoadBlocker>
        </Container>
    );
};
