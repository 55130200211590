import { MobilePageLayout } from '../../layouts/mobile/MobilePageLayout';
import { useShowMobileLayout } from '../../utils/LayoutUtils';
import { InvoicesTable } from './InvoicesTable';
import { GetInvoiceById_Query } from './components/providers/InvoiceTableProviderQueries.graphql';
import { InvoicesTableCtxProvider } from './components/providers/InvoicesTableProvider.graphql';
import { InvoiceDetailRoot } from './invoice-detail/InvoiceDetailRoot';
import { convertToPracticeInvoiceDetail } from './invoicing.utils';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlInvoiceDto } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client/dist';
import { LoadBlocker } from '@orthly/ui';
import { Grid, styled } from '@orthly/ui-primitives';
import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';

interface InvoiceDetailRouteProps {
    invoiceDetailId: string;
    path: string;
}

const GetPartnerBillingAutochargeEnabled_Query = graphql(`
    query GetPartnerBillingAutochargeEnabled($organizationId: String!) {
        getPartnerBillingAccount(partner_id: $organizationId) {
            autocharge_enabled
        }
    }
`);

const InvoiceDetailRoute: React.FC<InvoiceDetailRouteProps> = props => {
    const organizationId = useSession()?.organization_id ?? '';
    const { data: { getPartnerBillingAccount: partnerBillingAccount } = {} } = useQuery(
        GetPartnerBillingAutochargeEnabled_Query,
        {
            variables: { organizationId },
            skip: !organizationId,
        },
    );
    const invoiceDetailId = props.invoiceDetailId;
    const {
        data: { getInvoiceById } = {},
        refetch: refetchInvoice,
        loading: invoiceLoading,
    } = useQuery<{ getInvoiceById: LabsGqlInvoiceDto }>(GetInvoiceById_Query, {
        variables: { invoiceId: invoiceDetailId },
    });

    const invoice = convertToPracticeInvoiceDetail(getInvoiceById);

    if (invoice) {
        return (
            <InvoiceDetailRoot
                invoice={invoice}
                autochargeEnabled={partnerBillingAccount?.autocharge_enabled ?? false}
                refetchInvoice={refetchInvoice}
            />
        );
    }
    // show loader don't redirect until we know the invoice isn't just loading
    if (invoiceLoading) {
        return (
            <LoadBlocker blocking={true} loader={'dandy'}>
                <Grid container style={{ height: '100vh' }} />
            </LoadBlocker>
        );
    }
    return <Redirect to={`${props.path}`} />;
};

const InvoicesRootBase: React.FC = () => {
    const { path } = useRouteMatch();

    return (
        <InvoicesTableCtxProvider>
            <Switch>
                <Route
                    path={`${path}/:invoiceDetailId`}
                    render={routeProps => {
                        return (
                            <InvoiceDetailRoute path={path} invoiceDetailId={routeProps.match.params.invoiceDetailId} />
                        );
                    }}
                />
                <Route path={`${path}`}>
                    <InvoicesTable />
                </Route>
            </Switch>
        </InvoicesTableCtxProvider>
    );
};

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',

    position: 'relative',
    width: '100%',
    height: '100%',
    maxHeight: '100vh',

    [theme.breakpoints.down('md')]: {
        overflowY: 'scroll',
        flexDirection: 'row',
        flexWrap: 'wrap',
        alignContent: 'baseline',
    },
    '@media print': {
        overflow: 'visible',
        maxHeight: 'unset',
        height: 'auto',
    },
}));

export const InvoicesRoot: React.FC = () => {
    const isMobileLayout = useShowMobileLayout();

    if (isMobileLayout) {
        return (
            <MobilePageLayout title={'Billing'} titleHasBackButton>
                <InvoicesRootBase />
            </MobilePageLayout>
        );
    }

    return (
        <Grid container direction={'row'} style={{ height: '100%', position: 'relative' }}>
            <Container>
                <InvoicesRootBase />
            </Container>
        </Grid>
    );
};
