import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlLabOrderSinglePhotoInput } from '@orthly/graphql-schema';
import { LabsGqlLabOrderPhotoType } from '@orthly/graphql-schema';
import _ from 'lodash';
import React from 'react';

export type OrderDetailImageExists = { title: string; source: string; isMissing?: void; isDeleted?: boolean };
export type OrderDetailImageMissing = { title: string; isMissing: true; sources?: undefined; isDeleted?: boolean };
export type OrderDetailImage = OrderDetailImageExists | OrderDetailImageMissing;

export const ImplantPhotoTypes: LabsGqlLabOrderPhotoType[] = [
    LabsGqlLabOrderPhotoType.SurgicalReport,
    LabsGqlLabOrderPhotoType.XrayOfScanbody,
];

export const LabOrderImageTypeTitleMap: { [k in LabsGqlLabOrderPhotoType]: string } = {
    [LabsGqlLabOrderPhotoType.PatientPhoto]: 'Patient Photo/Shade',
    [LabsGqlLabOrderPhotoType.ScanFile]: 'Scan File',
    [LabsGqlLabOrderPhotoType.Xray]: 'X-Ray',
    [LabsGqlLabOrderPhotoType.FullFace]: 'Natural Smile (Full Face)',
    [LabsGqlLabOrderPhotoType.CloseUp]: 'Natural Smile (Close-up)',
    [LabsGqlLabOrderPhotoType.Side]: 'Side',
    [LabsGqlLabOrderPhotoType.RetractedTeethSeparated]: 'Retracted Teeth (Separated)',
    [LabsGqlLabOrderPhotoType.RetractedTeethTogether]: 'Retracted Teeth (Together)',
    [LabsGqlLabOrderPhotoType.SurgicalReport]: 'Surgical Report',
    [LabsGqlLabOrderPhotoType.XrayOfScanbody]: 'X-Ray of Scan body',
    [LabsGqlLabOrderPhotoType.Annotation]: 'Annotation',
};

export const isImplantOrder = (order: Pick<LabsGqlLabOrderFragment, 'items_v2'>) =>
    order.items_v2.some(item => item.sku === 'Implant' || item.sku === 'ImplantBridge');

export const useOrderDetailImages = (order: LabsGqlLabOrderFragment, isPSR: boolean) => {
    return React.useMemo<OrderDetailImage[]>(() => {
        const [implantPhotos, nonImplantPhotos] = _.partition(order.photos, photo =>
            ImplantPhotoTypes.some(type => type === photo.type),
        );

        const makeOrderDetailPhoto = (file: LabsGqlLabOrderSinglePhotoInput): OrderDetailImage => {
            return { title: LabOrderImageTypeTitleMap[file.type], source: file.fullPath, isDeleted: false };
        };

        const validImplantPhotos =
            isImplantOrder(order) && (isPSR || order.photos.length > 0)
                ? ImplantPhotoTypes.map<OrderDetailImage>(type => {
                      const photo = order.photos.find(photo => photo.type === type);

                      if (photo) {
                          return {
                              title: LabOrderImageTypeTitleMap[type],
                              source: photo.fullPath,
                              isDeleted: false,
                          };
                      }

                      return {
                          title: type,
                          isMissing: true,
                          isDeleted: false,
                      };
                  })
                : implantPhotos.map(makeOrderDetailPhoto);

        const validNonImplantPhotos = nonImplantPhotos.map(makeOrderDetailPhoto);
        return [
            ...validImplantPhotos,
            ...validNonImplantPhotos,
            ...order.doctor_photos.map<OrderDetailImage>((photo, idx) => ({
                source: photo.url,
                title: photo.deleted_at ? `(Deleted) Doctor photo #${idx + 1}` : `Doctor photo #${idx + 1}`,
                isDeleted: !!photo.deleted_at,
            })),
        ];
    }, [order, isPSR]);
};
