import type { AddSaveCommandArgs } from './DandySearchPresenter.hooks';
import type { LabsGqlOrderItemSkuType, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import { stylesFactory, FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const useStyles = stylesFactory(() => ({
    searchItem: {
        padding: '8px 16px',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        },
    },
}));

interface HighlightedLabelProps {
    label: string;
    search: string;
    active: boolean;
}

const HighlightedLabel: React.VFC<HighlightedLabelProps> = props => {
    const { label, search, active } = props;

    const highlightStartIndex = label.toLowerCase().indexOf(search.toLowerCase());
    const highlightEndIndex = highlightStartIndex + search.length;
    const lowerCaseLabel = label.toLowerCase();

    return (
        <Text
            variant={'body2'}
            color={active ? 'PRIMARY_FOREGROUND' : 'BLACK'}
            data-test={'search-option-highlighted-label'}
        >
            {lowerCaseLabel.substring(0, highlightStartIndex)}
            <span style={{ fontWeight: 600 }}>{lowerCaseLabel.substring(highlightStartIndex, highlightEndIndex)}</span>
            {lowerCaseLabel.substring(highlightEndIndex)}
        </Text>
    );
};

export interface DandySearchOptionProps extends SimpleAutocompleteOption {
    label: string;
    status?: LabsGqlLabOrderStatus;
    sku?: LabsGqlOrderItemSkuType;
    patient_gender: string | null;
    patient_birthday: string;
    search_term?: string;
    onNavigationAction: (orderId: string | number) => void;
    saveSearchAction: (saveCommand: AddSaveCommandArgs) => Promise<void>;
}

export type DandyRecentSearchOptionProps = Omit<
    DandySearchOptionProps,
    'search_term' | 'onNavigationAction' | 'saveSearchAction'
>;

export const DandySearchOption: React.VFC<DandySearchOptionProps> = props => {
    const {
        label,
        value,
        status,
        sku,
        patient_gender,
        patient_birthday,
        search_term,
        onNavigationAction,
        saveSearchAction,
    } = props;
    const classes = useStyles();
    const [active, setActive] = React.useState(false);

    const saveSearchOption = () => {
        saveSearchAction({
            patientName: label,
            orderId: `${value}`,
            patientBirthday: patient_birthday,
            patientGender: patient_gender,
        }).catch(console.error);
    };

    const onClickItem = (orderId: string | number) => {
        saveSearchOption();
        onNavigationAction(orderId);
    };

    return (
        <Grid
            container
            direction={'column'}
            className={classes.searchItem}
            onClick={() => onClickItem(value)}
            onMouseDown={() => setActive(true)}
            onMouseUp={() => setActive(false)}
            data-test={'search-option'}
        >
            <Grid item container direction={'row'}>
                <Text
                    variant={'body2'}
                    style={{ textTransform: 'capitalize' }}
                    color={active ? 'PRIMARY_FOREGROUND' : 'BLACK'}
                    data-test={'search-option-label'}
                >
                    {search_term ? <HighlightedLabel label={label} search={search_term} active={active} /> : label}
                </Text>
                {patient_gender && (
                    <Text
                        variant={'body2'}
                        color={active ? 'PRIMARY_FOREGROUND' : 'GRAY'}
                        style={{ marginLeft: 8 }}
                        data-test={'search-option-sublabel'}
                    >
                        {patient_gender}
                        {', '}
                        {moment().diff(moment(patient_birthday), 'years')}
                    </Text>
                )}
            </Grid>
            {sku && status && (
                <Grid item>
                    <Text
                        variant={'caption'}
                        color={active ? 'PRIMARY_FOREGROUND' : 'BLACK'}
                        data-test={'search-option-sku'}
                    >
                        {sku}
                    </Text>
                    <Text
                        variant={'caption'}
                        color={active ? 'PRIMARY_FOREGROUND' : 'GRAY'}
                        style={{ marginLeft: 8 }}
                        data-test={'search-option-status'}
                    >
                        {_.startCase(status)}
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};
