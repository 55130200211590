import { mergeMuiClasses } from '@orthly/ui';
import type { GridProps, GridSize, TextProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    useScreenIsMobile,
    Grid,
    Text,
    Typography,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import moment from 'moment/moment';
import React from 'react';

const useRowStyles = stylesFactory(() => ({
    detailFactRow: {
        paddingTop: 0,
        minHeight: '24px',
    },
    detailFactRowWithSeparator: {
        '&:not(:first-child)': {
            borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        },
        paddingTop: 4,
        paddingBottom: 4,
    },
}));

interface OrderDetailFactRowProps {
    title: string;
    titleContainerStyle?: React.CSSProperties;
    value: React.ReactNode;
    valueStyle?: React.CSSProperties;
    action?: React.ReactNode;
    layoutLeft?: GridSize;
    layoutRight?: GridSize;
    wrapperProps?: GridProps;
    typographyVariant?: TextProps['variant'];
    flexBasisLeft?: number;
    separator?: boolean;
}

export const OrderDetailFactRow: React.FC<OrderDetailFactRowProps> = props => {
    // we use smaller text by default in the ops portal because we are jamming a lot of info in there
    const variant = props.typographyVariant ?? (process.env.REACT_APP_NAME === 'admin' ? 'caption' : 'body2');
    const classes = useRowStyles();
    return (
        <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            {...props.wrapperProps}
            className={clsx(
                classes.detailFactRow,
                props.separator && classes.detailFactRowWithSeparator,
                props.wrapperProps?.className,
            )}
        >
            <Grid
                item
                xs={props.layoutLeft}
                style={{
                    alignSelf: 'flex-start',
                    paddingRight: 5,
                    flexGrow: 1,
                    flexBasis: props.layoutLeft ? undefined : props.flexBasisLeft ?? 100,
                }}
            >
                <Typography variant={variant} color={'textSecondary'} style={{ width: '100%' }}>
                    {props.title}
                </Typography>
            </Grid>
            <Grid
                container
                item
                xs={props.layoutRight}
                alignItems={'center'}
                wrap={'nowrap'}
                justifyContent={'space-between'}
                style={props.layoutRight ? undefined : { flexBasis: 150, flexGrow: 9999 }}
            >
                <Typography
                    component={'div'}
                    variant={variant}
                    color={'textPrimary'}
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', ...props.valueStyle }}
                >
                    {props.value}
                </Typography>
                {props.action ?? null}
            </Grid>
        </Grid>
    );
};

type OrderDetailBlockClassKey = 'root' | 'innerRoot' | 'contentRoot' | 'titleRow' | 'title' | 'updatedAt' | 'activeTab';

const useStyles = stylesFactory<{ attention?: boolean }, OrderDetailBlockClassKey>(() => ({
    root: {
        padding: '12px',
    },
    innerRoot: props => ({
        borderRadius: 16,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        background: props.attention ? FlossPalette.ATTENTION_BACKGROUND : FlossPalette.TAN,
        padding: '16px 24px',
        overflow: 'hidden',
        alignItems: 'center',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        '@media print': {
            background: FlossPalette.WHITE,
        },
    }),
    titleRow: { justifyContent: 'space-between' },
    title: {
        color: FlossPalette.GRAY,
        textTransform: 'capitalize',
    },
    updatedAt: {
        fontFamily: 'Inter Semibold, Verdana, sans-serif',
        color: FlossPalette.GRAY,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        padding: '2px 8px',
        margin: '0 0 0 8px',
        borderRadius: 8,
        background: FlossPalette.TAN,
    },
    activeTab: {
        color: FlossPalette.STAR_GRASS,
        marginTop: '-5px',
        '&::before': {
            content: `""`,
            position: 'relative',
            top: '-17px',
            left: '-15%',
            width: '130%',
            height: '5px',
            display: 'block',
            backgroundColor: 'currentColor',
        },
    },
    contentRoot: {},
}));

interface OrderDetailBlockTabsProps<Enum extends string> {
    titles: Enum[];
    active: Enum;
    onClick: (active: Enum) => void;
}

export const OrderDetailBlockTabs = <Enum extends string>(props: OrderDetailBlockTabsProps<Enum>) => {
    const { titles, active, onClick } = props;
    const classes = useStyles({});
    return (
        <Grid container alignItems={'center'} justifyContent={'space-between'}>
            {titles.map(title => {
                const className = `${classes.title} ${title === active ? classes.activeTab : ''}`;

                return (
                    <Typography
                        key={title}
                        component={'div'}
                        className={className}
                        style={{ fontWeight: 500, flexShrink: 0, marginRight: '20px', cursor: 'pointer' }}
                        onClick={() => onClick(title)}
                    >
                        {title}
                    </Typography>
                );
            })}
        </Grid>
    );
};

export interface OrderDetailBlockProps {
    title?: React.ReactNode;
    actions?: React.ReactNode;
    children?: React.ReactNode;
    variant: 'left' | 'right' | 'full';
    fitHeightToContent?: boolean;
    classes?: Partial<Record<OrderDetailBlockClassKey, string>>;
    rootStyle?: React.CSSProperties;
    innerRootStyle?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    titleRowProps?: Partial<GridProps>;
    buttonsBelowOnMobile?: boolean;
    noPadding?: boolean;
    updatedAt?: string;
    attention?: boolean;
    testId?: string;
}

export const OrderDetailBlock: React.FC<OrderDetailBlockProps> = props => {
    const baseClasses = useStyles({ attention: props.attention });
    const { title, actions, variant, fitHeightToContent, buttonsBelowOnMobile, updatedAt } = props;
    const classes = mergeMuiClasses<OrderDetailBlockClassKey>(baseClasses, props.classes);
    const isMobile = useScreenIsMobile();
    const buttonsBelow = useScreenIsMobileOrVerticalTablet() && buttonsBelowOnMobile;

    const updatedAtText = React.useMemo(() => {
        if (!updatedAt) {
            return undefined;
        }
        if (moment(updatedAt).isSame(moment(), 'd')) {
            return `at ${moment(updatedAt).format('h:mmA')}`;
        }
        if (moment(updatedAt).isSame(moment(), 'y')) {
            return `on ${moment(updatedAt).format(isMobile ? 'MMMM D' : 'MMMM D [at] h:mmA')}`;
        }
        return `on ${moment(updatedAt).format(isMobile ? 'MMMM D, YYYY' : 'MMMM D, YYYY [at] h:mmA')}`;
    }, [updatedAt, isMobile]);

    let md: GridSize = 12;
    if (variant === 'left') {
        md = 4;
    } else if (variant === 'right') {
        md = 8;
    }

    return (
        <Grid
            container
            className={classes.root}
            item
            data-testid={props.testId ?? 'order-detail-block'}
            direction={fitHeightToContent ? 'column' : 'row'}
            xs={12}
            md={md}
            style={props.rootStyle}
        >
            <Grid
                container
                className={classes.innerRoot}
                style={{
                    ...(props.noPadding ? { padding: '0px' } : {}),
                    ...(props.innerRootStyle ?? {}),
                }}
            >
                {title && (
                    <Grid
                        container
                        className={classes.titleRow}
                        style={{
                            height: isMobile ? 'unset' : 32,
                            alignItems: isMobile ? 'baseline' : 'center',
                        }}
                        wrap={buttonsBelow ? undefined : 'nowrap'}
                    >
                        <Grid
                            item
                            style={{ display: 'flex', alignItems: 'center', marginBottom: 12 }}
                            xs={isMobile ? 12 : 9}
                            {...props.titleRowProps}
                        >
                            <Typography
                                component={'div'}
                                className={classes.title}
                                variant={'body1'}
                                style={{ flexShrink: isMobile ? 'unset' : 0 }}
                            >
                                {title}
                            </Typography>
                            {updatedAtText && (
                                <Text
                                    variant={'caption'}
                                    color={'GRAY'}
                                    className={classes.updatedAt}
                                    data-test={'updated-at-text'}
                                >
                                    Last updated {updatedAtText}
                                </Text>
                            )}
                        </Grid>
                        {actions !== undefined && !buttonsBelow && (
                            <Grid
                                container
                                justifyContent={'flex-end'}
                                wrap={`nowrap`}
                                style={{ width: 'auto', marginLeft: 8 }}
                            >
                                {actions}
                            </Grid>
                        )}
                    </Grid>
                )}
                {props.children && (
                    <Grid
                        container
                        className={classes.contentRoot}
                        style={{ flexDirection: 'column', flexGrow: 1, ...props.contentStyle }}
                    >
                        {props.children}
                    </Grid>
                )}
                {actions !== undefined && buttonsBelow && (
                    <Grid container justifyContent={'flex-start'} style={{ width: '100%' }}>
                        {actions}
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
