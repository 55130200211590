import { useFirebaseStorage } from '../context/firebase.context';
import { downloadBlob, getFileNameWithExtension } from '../util/file-utils';
import { OrthlyEnvironment } from '@orthly/shared-types';
import { OrthlyBrowserConfig, OrthlyFrontendApps } from '@orthly/ui';
import type Firebase from 'firebase/compat/app';
import isEqual from 'lodash/isEqual';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

// Calls to firebase.getDownloadURL return a URL that never expires, so we can safely use a local storage cache
// @see https://github.com/firebase/firebase-js-sdk/issues/76#issuecomment-360311881
class FirebaseDownloadUrlCache {
    static clearDownloadCache() {
        try {
            Object.keys(window.localStorage)
                .filter(k => k.includes('firebaseDownload'))
                .forEach(key => localStorage.removeItem(key));
        } catch (e: any) {
            console.error(e);
        }
    }
}

function cleanPath(path: string) {
    const config = new OrthlyBrowserConfig(
        OrthlyFrontendApps.admin,
        process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : undefined,
    );
    const firebaseConfig = config.firebase.config;
    return path
        .replace('gs:/', '')
        .replace(`gs://${firebaseConfig.storageBucket}`, '')
        .replace(firebaseConfig.storageBucket, '');
}

export async function getFirebaseDownloadUrl(firebase: Firebase.storage.Storage, recordPath: string): Promise<string> {
    try {
        FirebaseDownloadUrlCache.clearDownloadCache();
        return await firebase.ref(cleanPath(recordPath)).getDownloadURL();
    } catch (e: any) {
        console.error(e);
        return recordPath;
    }
}

export const firebaseDownloadFile = async (
    firebase: Firebase.storage.Storage,
    recordPath: string,
    fileName?: string,
) => {
    const downloadUrl = await getFirebaseDownloadUrl(firebase, recordPath);
    const res = await fetch(downloadUrl);
    if (!res.ok) {
        throw new Error(`Download failed with status ${res.status}`);
    }

    const defaultDownloadName = fileName ? fileName : downloadUrl.split('/').slice(-1)[0] ?? '';
    downloadBlob(await res.blob(), getFileNameWithExtension(defaultDownloadName, downloadUrl));
};

export function useFirebaseDownload(recordPath?: string, fileName?: string) {
    // TODO: GCS Cleanup: accept bucket name as input for downloads
    const firebase = useFirebaseStorage();

    const downloadFn = React.useCallback(async () => {
        if (recordPath) {
            return firebaseDownloadFile(firebase, recordPath, fileName);
        }
        throw new Error(`Cannot download file from undefined path`);
    }, [firebase, recordPath, fileName]);
    return useAsyncCallback(downloadFn);
}

export function useFirebasePreview(recordPath?: string) {
    // TODO: GCS Cleanup: accept bucket name as input for downloads
    const firebase = useFirebaseStorage();
    const cb = useAsyncCallback(async (path: string) => await getFirebaseDownloadUrl(firebase, path));
    React.useEffect(() => {
        if (cb.status === 'not-requested' && recordPath) {
            void cb.execute(recordPath);
        }
    }, [cb, recordPath]);
    return cb;
}

export function useFirebaseFilesInBucket(bucketName?: string) {
    const firebase = useFirebaseStorage(bucketName);
    return useAsyncCallback(async (storagePath: string) => await firebase.ref(storagePath).listAll());
}

export type FirebasePreviewFileMulti = { source: string; name: string };

export function useFirebasePreviewMulti(recordPaths: FirebasePreviewFileMulti[], refresh: boolean = false) {
    const [previous, setPrevious] = React.useState<FirebasePreviewFileMulti[]>([]);
    const firebase = useFirebaseStorage();
    const cb = useAsyncCallback(async (paths: FirebasePreviewFileMulti[]) =>
        Promise.all(
            paths.map(async path => ({
                name: path.name,
                source: await getFirebaseDownloadUrl(firebase, path.source),
            })),
        ),
    );
    React.useEffect(() => {
        if ((refresh || cb.status === 'not-requested') && !isEqual(recordPaths, previous)) {
            setPrevious(recordPaths);
            void cb.execute(recordPaths);
        }
    }, [cb, recordPaths, previous, refresh]);
    return cb;
}
