import type { ModelAppearance, PayloadModelAppearance } from '../ModelAppearance';
import { DEFAULT_CAMERA_POSITION } from './ModelViewer';
import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import type { CameraPose, ToothViewDirections } from './utils3d';
import {
    generateGenericCameraRotationForTooth,
    poseFrontOnView,
    poseMandibularOcclusalView,
    poseMaxillaryOcclusalView,
    poseSideView,
    updateCameraFromPose,
} from './utils3d';
import type { MountingMatrix } from '@orthly/forceps';
import React from 'react';
import * as THREE from 'three';

type PatientOrientedViewFunction = (viewType: PatientViewDirection) => void;
type ToothOrientedViewFunction = (unn: number, viewType: ToothViewDirections) => void;

export interface PresetViewControllerFns {
    axisAlignedViewSetter: PatientOrientedViewFunction;
    generalToothViewSetter: ToothOrientedViewFunction;
}

export type PatientViewDirection = 'FRONT' | 'RIGHT' | 'LEFT' | 'BACK' | 'TOP' | 'BOTTOM';

export function usePresetViewSetter(
    mountingMatrix: MountingMatrix | null,
    cameraControlsRef: MainViewCameraControlsRef,
    modelAppearance: ModelAppearance,
): PresetViewControllerFns {
    const toothView = React.useCallback(
        (unn: number, viewType: ToothViewDirections) => {
            if (!mountingMatrix) {
                return;
            }

            // find the target item
            const targetPayloadItem = modelAppearance.restoratives['CAD'].find((mpi: PayloadModelAppearance) =>
                mpi.payloadModel.unns?.includes(unn),
            );

            if (!targetPayloadItem?.payloadModel.model.geometry) {
                // Warn? Error? couldn't find the tooth you wanted!
                return;
            }

            // Get the camera rotation
            const insAxis = targetPayloadItem.payloadModel.insertionAxis
                ? new THREE.Vector3(
                      targetPayloadItem.payloadModel.insertionAxis[0],
                      targetPayloadItem.payloadModel.insertionAxis[1],
                      targetPayloadItem.payloadModel.insertionAxis[2],
                  )
                : undefined;
            const rotationMatrix = generateGenericCameraRotationForTooth(unn, mountingMatrix, insAxis, viewType);

            // Dead assignment to improve readability.  Take or leave?
            const modelGeometry = targetPayloadItem.payloadModel.model.geometry;

            if (!modelGeometry.boundingBox) {
                modelGeometry.computeBoundingBox();
            }
            const restorationCenter = new THREE.Vector3();
            modelGeometry.boundingBox?.getCenter(restorationCenter);

            // If, for some unknown reason, we do not have a bounding box
            // aim generally at the case
            const camTarget = modelGeometry.boundingBox ? restorationCenter : mountingMatrix.origin.clone();

            const qRotation = new THREE.Quaternion().setFromRotationMatrix(rotationMatrix);

            const camLocation = camTarget.clone().add(new THREE.Vector3(0, 0, 100).applyQuaternion(qRotation));

            const camPose: CameraPose = { targetVector: camTarget, locVector: camLocation, rotMatrix: rotationMatrix };

            updateCameraFromPose(cameraControlsRef, camPose);
        },
        [mountingMatrix, modelAppearance, cameraControlsRef],
    );

    const axisAlignedViewSetter = React.useCallback(
        (viewType: PatientViewDirection) => {
            if (!mountingMatrix) {
                return;
            }

            if (!cameraControlsRef.current) {
                return;
            }
            let newPose: CameraPose = {
                targetVector: new THREE.Vector3(),
                locVector: DEFAULT_CAMERA_POSITION,
                rotMatrix: new THREE.Matrix4(),
            };

            if (viewType === 'FRONT') {
                newPose = poseFrontOnView(mountingMatrix);
            } else if (viewType === 'RIGHT') {
                newPose = poseSideView(mountingMatrix, 'RIGHT');
            } else if (viewType === 'LEFT') {
                newPose = poseSideView(mountingMatrix, 'LEFT');
            } else if (viewType === 'TOP') {
                newPose = poseMandibularOcclusalView(mountingMatrix);
            } else if (viewType === 'BOTTOM') {
                newPose = poseMaxillaryOcclusalView(mountingMatrix);
            }
            updateCameraFromPose(cameraControlsRef, newPose);
        },
        [mountingMatrix, cameraControlsRef],
    );
    return { axisAlignedViewSetter, generalToothViewSetter: toothView };
}
