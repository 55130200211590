import type { CurrentWaxup, WaxupOrder } from '../Waxups.types';
import { getPresetTitle, makeTitleCase } from './GuidedWaxup.util';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlDesignViewPreset } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { Button, Text, useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

const formatGuidedWaxupDetails = (rejectionPreset: LabsGqlDesignViewPreset) => {
    const showNotes = rejectionPreset.preset_status === LabsGqlGuidedWaxupPresetStatus.Rejected;
    return {
        title: getPresetTitle(rejectionPreset.preset_type),
        status: showNotes ? 'Redesign needed' : makeTitleCase(rejectionPreset.preset_status),
        notes: rejectionPreset.notes,
        showNotes,
    };
};

const GuidedWaxupSubmittedDetailsInfo: React.VFC<{ preset: LabsGqlDesignViewPreset }> = ({ preset }) => {
    if (preset.preset_type === LabsGqlGuidedWaxupPresetType.GeneralView) {
        return null;
    }
    const formattedDetails = formatGuidedWaxupDetails(preset);
    return (
        <ul style={{ paddingLeft: '15px' }}>
            <li>
                <Grid container direction={'row'}>
                    <Grid item>
                        <Text variant={'body2'} medium>
                            {formattedDetails?.title}:&nbsp;
                        </Text>
                    </Grid>
                    <Grid item>
                        <Text variant={'body2'}>{formattedDetails?.status}</Text>
                    </Grid>
                </Grid>
            </li>
            {formattedDetails?.showNotes && (
                <ul>
                    <li>
                        <Text variant={'body2'}>{formattedDetails?.notes}</Text>
                    </li>
                </ul>
            )}
        </ul>
    );
};

const PresetDesignDecisions: React.VFC<{
    lastRejection?: OrderDesignPreviewDesign_FragmentFragment['doctor_review'];
}> = ({ lastRejection }) => {
    return (
        <>
            <Grid item>
                <Text variant={'body2'} medium color={'GRAY'}>
                    Design Decisions
                </Text>
            </Grid>
            <Grid item>
                {lastRejection?.annotations?.map(preset => (
                    <GuidedWaxupSubmittedDetailsInfo key={preset.preset_type} preset={preset} />
                ))}
            </Grid>
        </>
    );
};

const OverallRejectionNotes: React.VFC<{
    lastRejection?: OrderDesignPreviewDesign_FragmentFragment['doctor_review'];
}> = ({ lastRejection }) => {
    return (
        <Grid item>
            <Text variant={'body2'} medium color={'GRAY'}>
                Rejection Note
            </Text>
            {lastRejection?.notes && <Text variant={'body2'}>"{lastRejection.notes}"</Text>}
        </Grid>
    );
};

export const GuidedWaxupSubmittedDetailDesktop: React.VFC<{
    lastRejection?: OrderDesignPreviewDesign_FragmentFragment['doctor_review'];
}> = ({ lastRejection }) => {
    // presets will not be present if an ops user submits the waxup review for the doctor, so we will hide
    // that section of the details if that is the case
    const showDesignPresetInfo = !!lastRejection?.annotations;
    return (
        <Grid container direction={'row'}>
            {showDesignPresetInfo && (
                <Grid item container direction={'column'} md={6}>
                    <PresetDesignDecisions lastRejection={lastRejection} />
                </Grid>
            )}
            <Grid item container md={6} style={{ paddingLeft: showDesignPresetInfo ? 30 : 0 }}>
                <OverallRejectionNotes lastRejection={lastRejection} />
            </Grid>
        </Grid>
    );
};

export const ClickToLoadGuidedWaxup: React.FC<GuidedWaxupBlockProps> = ({ order, currentWaxup }) => {
    const isMobile = useScreenIsMobile();
    const history = useHistory();

    const selectedDesignFragment = getFragmentData(
        OrderDesignPreviewDesign_FragmentFragmentDoc,
        currentWaxup?.currentDesignFragment,
    );

    const isActionRequired = !selectedDesignFragment?.doctor_review;
    const openDesignLabel = isActionRequired
        ? `Open Design Preview`
        : `Open ${_.startCase(selectedDesignFragment?.doctor_review?.status.toLowerCase())} Design Preview`;

    return (
        <Grid
            container
            style={{
                flexDirection: 'column',
                justifyContent: isMobile ? 'end' : 'center',
                alignItems: 'center',
                position: 'relative',
            }}
        >
            {isActionRequired && (
                <Text
                    style={{
                        maxWidth: isMobile ? '80%' : 250,
                        textAlign: 'center',
                    }}
                    variant={'h6'}
                    bold
                    color={'ATTENTION_FOREGROUND'}
                >
                    Action required
                </Text>
            )}
            <Text variant={'h6'} bold style={{ marginBottom: '1em', textAlign: 'center' }}>
                {selectedDesignFragment?.doctor_review
                    ? `You ${selectedDesignFragment.doctor_review.status.toLowerCase()} this design on ${dayjs(selectedDesignFragment.doctor_review.created_at).format('MM/DD')}`
                    : 'Awaiting doctor submission for design preview'}
            </Text>
            <Button
                onClick={() => {
                    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
                        AssetName: 'Guided Waxup Button - Open Design Preview',
                        AssetType: 'button',
                        AssetVersion: '',
                        AssetCTAText: openDesignLabel,
                    });
                    history.replace(`/${PracticeScreen.guided_waxup}/${order.id}`);
                }}
                variant={'primary'}
                style={{ minWidth: isMobile ? '80%' : 250 }}
            >
                <Text variant={'body2'} medium color={'WHITE'}>
                    {openDesignLabel}
                </Text>
            </Button>
        </Grid>
    );
};

interface GuidedWaxupBlockProps {
    order: WaxupOrder;
    currentWaxup?: CurrentWaxup;
}

export const GuidedWaxupBlock: React.VFC<GuidedWaxupBlockProps> = ({ order, currentWaxup }) => {
    return <ClickToLoadGuidedWaxup order={order} currentWaxup={currentWaxup} />;
};
