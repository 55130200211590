import { AttributeName } from '../BufferAttributeConstants';
import type { IndexedBufferGeometry } from '../BufferGeometry.types';
import { triangleFromBufferAttributes, safeTriangleClosestPointToPoint } from '../Triangle.util';
import type { MillInterference, MillInterferenceStatistics } from './types';
import * as THREE from 'three';

export const SIGNIFICANT_FACE_THRESHOLD = 0.05;
export const SIGNIFICANT_FACES_NUMBER = 30;
export const SIGNIFICANT_FACES_NUMBER_WITH_JIGGLE = 10;
export const TOTAL_DIFF_THRESHOLD = 60;
export const TOTAL_DIFF_THRESHOLD_WITH_JIGGLE = 1;

export function getMillStatistics(
    geom: IndexedBufferGeometry,
    millInterferences: MillInterference[],
    millRadiusMm: number,
): MillInterferenceStatistics {
    const index = geom.getIndex();
    const posAttr = geom.getAttribute(AttributeName.Position);

    let totalDiff = 0;
    let maxDiff = 0;
    let totalFacesWithSignificantDiff = 0;
    if (millInterferences.length > 0) {
        const tri = new THREE.Triangle();
        const vec = new THREE.Vector3();

        millInterferences.forEach(mi => {
            const collisionIndices = mi.collisionIndices;
            let averageDistance = 0;
            for (const fIdx of collisionIndices) {
                triangleFromBufferAttributes(tri, index, posAttr, fIdx);
                safeTriangleClosestPointToPoint(tri, mi.sphere.center, vec);
                const distance = vec.distanceTo(mi.sphere.center);
                const diff = Math.abs(millRadiusMm - distance);
                if (diff > maxDiff) {
                    maxDiff = diff;
                }
                averageDistance += distance;
            }
            averageDistance /= collisionIndices.length;
            const diff = Math.abs(millRadiusMm - averageDistance);
            if (diff > SIGNIFICANT_FACE_THRESHOLD) {
                totalFacesWithSignificantDiff++;
            }
            totalDiff += diff;
        });
    }

    return { totalDiff, totalFacesWithSignificantDiff, maxDiff };
}

export function isItMillableFromStatistics(stats: MillInterferenceStatistics, useJiggling: boolean = false): boolean {
    const { totalDiff, totalFacesWithSignificantDiff } = stats;
    if (useJiggling) {
        return (
            totalDiff < TOTAL_DIFF_THRESHOLD_WITH_JIGGLE &&
            totalFacesWithSignificantDiff < SIGNIFICANT_FACES_NUMBER_WITH_JIGGLE
        );
    } else {
        return totalDiff < TOTAL_DIFF_THRESHOLD && totalFacesWithSignificantDiff < SIGNIFICANT_FACES_NUMBER;
    }
}

export function isItMillable(
    geom: IndexedBufferGeometry,
    millInterferences: MillInterference[],
    millRadiusMm: number,
    useJiggling: boolean = false,
): boolean {
    return isItMillableFromStatistics(getMillStatistics(geom, millInterferences, millRadiusMm), useJiggling);
}
