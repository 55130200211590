import { usePartnerUiSelector } from '../../redux/ui';
import { MobileCheckoutPhotoUpload } from '../checkout-v2/AlignerCheckout/MobileCheckoutPhotoUpload';
import { CheckoutV2Root } from '../checkout-v2/CheckoutV2Root';
import { CheckoutOrderConfirmationOverlay } from '../checkout-v2/components/CheckoutConfirmationOverlay/CheckoutConfirmationOverlay';
import { AestheticsMobilePhotoUpload } from '../checkout-v2/screens/AestheticsMobilePhotoUpload';
import { CheckoutOrderNotesV2MobilePhotoUpload } from '../checkout-v2/screens/CheckoutSummaryStep/CheckoutOrderNotesV2';
import { DentureMobilePhotoUpload } from '../checkout-v2/screens/DentureCheckout/DentureMobilePhotoUpload';
import { ImplantMobileXrayUpload } from '../checkout-v2/screens/ImplantCheckout/ImplantMobileXrayUpload';
import { MobilePhotoUpload } from '../checkout-v2/screens/MobilePhotoUpload';
import { WellnessScanMobilePhotoUpload } from '../checkout-v2/screens/WellnessScanMobilePhotoUpload';
import { PRACTICE_CHECKOUT_PATHNAME, PRACTICE_CHECKOUT_SUBMITTED_PATHNAME } from '../checkout-v2/state/CheckoutPaths';
import {
    ATTACH_CHECKOUT_FILES_PATH,
    ATTACH_ALIGNERS_PHOTO_PATH,
    ATTACH_IMPLANT_XRAY_PHOTO_PATH,
    ATTACH_PHOTOS_PATH,
    ATTACH_DENTURES_PHOTO_PATH,
    ATTACH_WELLNESS_SCAN_PHOTO_PATH,
    ATTACH_AESTHETICS_PHOTO_PATH,
} from './LabsPaths';
import { OrderDetailRoot } from './order-detail-v2/OrderDetailRoot';
import { OrdersOverviewRoot } from './orders-v2/OrdersOverviewRoot';
import { PracticeRouteWithMetadata } from '@orthly/analytics/dist/browser';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

export const LabsRoot: React.FC = () => {
    const { path } = useRouteMatch();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);

    return (
        <Switch>
            <Route path={`${path}/${ATTACH_CHECKOUT_FILES_PATH}/:scanID`}>
                <CheckoutOrderNotesV2MobilePhotoUpload />
            </Route>
            <Route path={`${path}/${ATTACH_ALIGNERS_PHOTO_PATH}/:scanId`}>
                <MobileCheckoutPhotoUpload />
            </Route>
            <Route path={`${path}/${ATTACH_DENTURES_PHOTO_PATH}/:scanID`}>
                <DentureMobilePhotoUpload />
            </Route>
            <Route path={`${path}/${ATTACH_IMPLANT_XRAY_PHOTO_PATH}/:scanID`}>
                <ImplantMobileXrayUpload />
            </Route>
            <Route path={`${path}/${ATTACH_WELLNESS_SCAN_PHOTO_PATH}/:scanID`}>
                <WellnessScanMobilePhotoUpload />
            </Route>
            <Route path={`${path}/${ATTACH_AESTHETICS_PHOTO_PATH}/:scanID`}>
                <AestheticsMobilePhotoUpload />
            </Route>
            <Route path={`${path}/${ATTACH_PHOTOS_PATH}/:caseId`}>
                <MobilePhotoUpload />
            </Route>
            <Route path={`${path}/${PRACTICE_CHECKOUT_SUBMITTED_PATHNAME}/:orderId`}>
                <CheckoutOrderConfirmationOverlay />
            </Route>
            <Route path={`${path}/${PRACTICE_CHECKOUT_PATHNAME}`}>
                <CheckoutV2Root />
            </Route>
            <Route
                path={[`${path}/:orderId`, path]}
                render={({ location }) => (
                    <Switch location={location}>
                        <PracticeRouteWithMetadata
                            path={`${path}/:orderId`}
                            title={isNativeApp ? 'Mobile Order Page' : 'Order Page'}
                            metadataProps={params => ({
                                $groups: {
                                    order: params.orderId,
                                },
                            })}
                        >
                            <OrderDetailRoot />
                        </PracticeRouteWithMetadata>
                        <PracticeRouteWithMetadata path={path} title={isNativeApp ? 'Mobile Orders' : 'Orders'}>
                            <OrdersOverviewRoot />
                        </PracticeRouteWithMetadata>
                    </Switch>
                )}
            />
        </Switch>
    );
};
