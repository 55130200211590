import type { DcmGeometryInjector } from '../DcmFiles/DcmGeometryInjector';
import type { ModellingTree } from '../DentalDesignerModellingTree/ModellingTree';
import { ToothUtils } from '@orthly/items';
import type { ToothNumber } from '@orthly/items';
import * as THREE from 'three';

/**
 * Calculates the height of the crown, i.e. the extent of the crown along the insertion axis
 * @param dcm DCM of the crown
 * @param modellingTree Modelling Tree of the design to which the crown belongs
 * @param toothNumber The tooth number of the crown
 * @returns The height of the crown, if it could be calculated
 */
export function calculateCrownHeight(
    dcm: DcmGeometryInjector,
    modellingTree: ModellingTree,
    toothNumber: ToothNumber,
): number | undefined {
    const baseGeometry = dcm.buildGeometry({ applyTextureCoords: false });

    const isUpper = ToothUtils.toothIsUpper(toothNumber);
    const transform = isUpper ? modellingTree.getUpper2LowerMatrix() : undefined;

    if (isUpper && !transform) {
        return undefined;
    }

    const insertionAxis = dcm.getAutomateInsertionDirection(transform);
    if (!insertionAxis) {
        return undefined;
    }

    // Rotate the crown so that its insertion axis is aligned with the y-axis.
    const q = new THREE.Quaternion().setFromUnitVectors(insertionAxis, new THREE.Vector3(0, 1, 0));
    baseGeometry.applyMatrix4(new THREE.Matrix4().makeRotationFromQuaternion(q));

    baseGeometry.computeBoundingBox();
    return baseGeometry.boundingBox?.getSize(new THREE.Vector3()).y;
}
