import * as THREE from 'three';

export function getCentroid(points: Iterable<THREE.Vector3>): THREE.Vector3 {
    const positionSum = new THREE.Vector3();
    let numPoints = 0;

    for (const p of points) {
        positionSum.add(p);
        ++numPoints;
    }

    return positionSum.divideScalar(Math.max(numPoints, 1));
}

export function getMaxDistance(target: THREE.Vector3, samples: Iterable<THREE.Vector3>): number {
    let maxDistanceSq = 0;

    for (const p of samples) {
        maxDistanceSq = Math.max(maxDistanceSq, target.distanceToSquared(p));
    }

    return Math.sqrt(maxDistanceSq);
}

export function getCentroidAndMaxDistance(points: Iterable<THREE.Vector3>): {
    centroid: THREE.Vector3;
    maxDistance: number;
} {
    const centroid = getCentroid(points);
    const maxDistance = getMaxDistance(centroid, points);

    return { centroid, maxDistance };
}
