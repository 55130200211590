import * as THREE from 'three';

// The position and orientation of an object in 3D space
export interface Pose {
    position: THREE.Vector3;
    orientation: THREE.Quaternion;
}

const UNUSED_SCALE = new THREE.Vector3();

export function setPoseFromMatrix(matrix: THREE.Matrix4, pose: Pose): Pose {
    matrix.decompose(pose.position, pose.orientation, UNUSED_SCALE);
    return pose;
}

const UNIT_SCALE = new THREE.Vector3().setScalar(1);

export function setMatrixFromPose(pose: Pose, matrix: THREE.Matrix4): THREE.Matrix4 {
    return matrix.compose(pose.position, pose.orientation, UNIT_SCALE);
}
