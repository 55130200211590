import { AnalyticsClient } from '../../../../../analytics/analyticsClient';
import { ChatWithDandyButton } from './ChatWithDandyButton';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlDoctorRequestFragment } from '@orthly/graphql-operations';
import { useGetDoctorRequestQuery } from '@orthly/graphql-react';
import { LabsGqlDoctorRequestOptionType } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { LoadBlocker, apolloErrorMessage } from '@orthly/ui';
import { Text } from '@orthly/ui-primitives';
import type { OrderForOrderTracker } from '@orthly/veneer';
import { type AskDoctorV2ViewableOption, AskDoctorPracticeDialogContent } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React from 'react';

type AskDoctorResponseDialogContentProps = {
    doctorRequestId: string;
    order: OrderForOrderTracker;
    closeDialog: () => void;
    onSuccess: () => Promise<void>;
};

const PracticeRespondToDoctorRequest_Mutation = graphql(`
    mutation PracticeRespondToDoctorRequest($data: PracticeRespondToDoctorRequestCommand!) {
        practiceRespondToDoctorRequest(data: $data) {
            id
        }
    }
`);

const fromGqlOptionToAskDrV2ViewableOption = (
    option: LabsGqlDoctorRequestFragment['options'][number],
): AskDoctorV2ViewableOption => {
    return {
        id: option.id,
        label: option.label,
        type: option.type,
        modify_order_config:
            option.type === LabsGqlDoctorRequestOptionType.ModifyOrder
                ? {
                      items_v2: OrderItemV2Utils.parseItems(option.config?.items_v2 ?? []),
                      mode: option.config?.mode,
                  }
                : undefined,
    };
};

export const AskDoctorResponseDialogContent: React.VFC<AskDoctorResponseDialogContentProps> = ({
    closeDialog,
    order,
    onSuccess,
    doctorRequestId,
}) => {
    const { enqueueSnackbar } = useSnackbar();
    const [practiceRespondToDoctorRequest, { loading: isSubmitting }] = useMutation(
        PracticeRespondToDoctorRequest_Mutation,
        {
            onError: error => {
                enqueueSnackbar(apolloErrorMessage(error), { variant: 'error' });
            },
            onCompleted: async () => {
                await onSuccess();
            },
        },
    );

    const { data, loading } = useGetDoctorRequestQuery({
        variables: { doctorRequestId },
    });

    const doctorRequestFragment = data?.getDoctorRequest;

    if (!doctorRequestFragment) {
        if (loading) {
            return <LoadBlocker blocking={loading} ContainerProps={{ style: { minHeight: 100 } }} />;
        }
        return <Text variant={'body2'}>Error loading order hold, please contact support</Text>;
    }

    return (
        <LoadBlocker blocking={loading}>
            <AskDoctorPracticeDialogContent
                options={doctorRequestFragment.options.map(fromGqlOptionToAskDrV2ViewableOption)}
                questionForDoctor={doctorRequestFragment.question_text}
                attachmentUrls={doctorRequestFragment.photo_attachment_urls}
                order={order}
                ChatButton={ChatWithDandyButton}
                onCancel={closeDialog}
                onSubmit={async ({ selectedOptionId, freeformText }) => {
                    await practiceRespondToDoctorRequest({
                        variables: {
                            data: {
                                doctor_request_id: doctorRequestId,
                                freeform_notes: freeformText ?? '',
                                selected_doctor_request_option_id: selectedOptionId,
                            },
                        },
                    });

                    const fullOption = doctorRequestFragment.options.find(o => o.id === selectedOptionId);

                    AnalyticsClient.track('Practice - Portal - Practice Response To Order Hold (v2)', {
                        optionType: fullOption?.type ?? '',
                        optionLabel: fullOption?.label ?? '',
                        freeformText: freeformText ?? '',
                        doctorRequestId: doctorRequestFragment.id,
                        $groups: { order: order.id },
                    });
                }}
                isSubmitting={isSubmitting}
            />
        </LoadBlocker>
    );
};
