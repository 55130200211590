import { findClosestPointToPolyline } from './PolylineMargin.util';
import type { MarginLine } from '@orthly/shared-types';
import { number3toSimpleVector } from '@orthly/shared-types';
import * as THREE from 'three';

const IDENTITY_MATRIX = new THREE.Matrix4().identity();

/**
 * Gets the points of the margin line in the Model Builder frame
 * @param mline The margin line data structure
 * @returns The cached points, if available. Otherwise, it falls back to calculating them
 */
export function getMarginPoints(mline: MarginLine): THREE.Vector3[] {
    const coords = mline.mb_coords ?? mline.coords.map(v => number3toSimpleVector(v));
    const matrix = !!mline.mb_coords ? IDENTITY_MATRIX : new THREE.Matrix4().set(...mline.transformationMatrix);
    return coords.map(c => new THREE.Vector3(c.x, c.y, c.z).applyMatrix4(matrix));
}

/**
 * Returns the centroid of the margin line in the Model Builder frame
 * @param mline The margin line data structure
 */
export function getMarginCentroid(mline: MarginLine): THREE.Vector3 {
    const centroid = new THREE.Vector3();

    if (mline.mb_coords?.length) {
        for (const coord of mline.mb_coords) {
            centroid.x += coord.x;
            centroid.y += coord.y;
            centroid.z += coord.z;
        }

        return centroid.multiplyScalar(1 / mline.mb_coords.length);
    }

    if (mline.coords.length) {
        for (const coord of mline.coords) {
            centroid.x += coord[0];
            centroid.y += coord[1];
            centroid.z += coord[2];
        }

        return centroid
            .multiplyScalar(1 / mline.coords.length)
            .applyMatrix4(new THREE.Matrix4().set(...mline.transformationMatrix));
    }

    return centroid;
}

/**
 * Calculates the maximum distance (i.e. difference) between sets of margin lines
 * @param margins One set of margin lines
 * @param otherMargins The other set of margin lines
 * @returns The maximum distance, if there was at least one matching pair of margin lines from the same tooth
 */
export function getMaxMarginDistance(margins: MarginLine[], otherMargins: MarginLine[]): number | undefined {
    let maxMarginDistance: number | undefined = undefined;

    for (const margin of margins) {
        const otherMargin = otherMargins.find(m => m.tooth === margin.tooth);
        if (!otherMargin) {
            continue;
        }

        const points = getMarginPoints(margin);
        const otherPoints = getMarginPoints(otherMargin);

        for (const p of points) {
            const { closestDistance } = findClosestPointToPolyline(otherPoints, p, true, true, maxMarginDistance);
            maxMarginDistance =
                maxMarginDistance === undefined ? closestDistance : Math.max(maxMarginDistance, closestDistance);
        }

        // Need to check in both directions because the maximum difference could happen at a vertex on either version of
        // the margin line.
        for (const p of otherPoints) {
            const { closestDistance } = findClosestPointToPolyline(points, p, true, true, maxMarginDistance);
            maxMarginDistance =
                maxMarginDistance === undefined ? closestDistance : Math.max(maxMarginDistance, closestDistance);
        }
    }

    return maxMarginDistance;
}
