/* eslint-disable */
import { logger } from '../Utils/Logger';
import { AttributeName } from './BufferAttributeConstants';
import * as THREE from 'three';

/*
 * This file was adopted from the old git versioning to handle loading STLs into threejs
 * it appears to have originated from: https://github.com/mrdoob/three.js/blob/master/examples/jsm/loaders/STLLoader.js
 * I've done my best to make it more typesafe and less hacky.
 */

export interface StlFile {
    geometry: THREE.BufferGeometry;
    modelType: 'stl';
}

export class STLLoader {
    manager: THREE.LoadingManager;

    constructor(manager?: THREE.LoadingManager) {
        this.manager = manager !== undefined ? manager : new THREE.LoadingManager();
    }

    async loadAsync(data: ArrayBuffer): Promise<StlFile> {
        try {
            return this.parse(data);
        } catch (exception: any) {
            if (exception) {
                logger.error(exception);
            }
            throw new Error(`Failed to load scan`);
        }
    }

    setPath(value: any) {
        // @ts-ignore
        this.path = value;
        // @ts-ignore
        return this;
    }

    parse(data: any): StlFile {
        function isBinary(data: any) {
            let expect;
            let face_size;
            let n_faces;
            let reader;
            reader = new DataView(data);
            face_size = (32 / 8) * 3 + (32 / 8) * 3 * 3 + 16 / 8;
            n_faces = reader.getUint32(80, true);
            expect = 80 + 32 / 8 + n_faces * face_size;

            if (expect === reader.byteLength) {
                return true;
            }

            // An ASCII STL data must begin with 'solid ' as the first six bytes.
            // However, ASCII STLs lacking the SPACE after the 'd' are known to be
            // plentiful.  So, check the first 5 bytes for 'solid'.

            // Several encodings, such as UTF-8, precede the text with up to 5 bytes:
            // https:// en.wikipedia.org/wiki/Byte_order_mark#Byte_order_marks_by_encoding
            // Search for "solid" to start anywhere after those prefixes.

            // US-ASCII ordinal values for 's', 'o', 'l', 'i', 'd'

            const solid = [115, 111, 108, 105, 100];

            for (let off = 0; off < 5; off += 1) {
                // If "solid" text is matched to the current offset, declare it to be an ASCII STL.

                if (matchDataViewAt(solid, reader, off)) {
                    return false;
                }
            }

            // Couldn't find "solid" text at the beginning; it is binary STL.

            return true;
        }

        function matchDataViewAt(query: any, reader: any, offset: any) {
            // Check if each byte in query matches the corresponding byte from the current offset

            for (let i = 0, il = query.length; i < il; i += 1) {
                if (query[i] !== reader.getUint8(offset + i, false)) {
                    return false;
                }
            }

            return true;
        }

        function parseBinary(data: any): StlFile {
            const reader = new DataView(data);
            const faces = reader.getUint32(80, true);
            let r;
            let g;
            let b;
            let hasColors = false;
            let colors = [];
            let defaultR;
            let defaultG;
            let defaultB;
            let alpha;

            // process STL header
            // check for default color in header ("COLOR=rgba" sequence).

            for (let index = 0; index < 80 - 10; index += 1) {
                if (
                    reader.getUint32(index, false) === 0x434f4c4f /*COLO*/ &&
                    reader.getUint8(index + 4) === 0x52 /*'R'*/ &&
                    reader.getUint8(index + 5) === 0x3d /*'='*/
                ) {
                    hasColors = true;
                    colors = [];

                    defaultR = reader.getUint8(index + 6) / 255;
                    defaultG = reader.getUint8(index + 7) / 255;
                    defaultB = reader.getUint8(index + 8) / 255;
                    alpha = reader.getUint8(index + 9) / 255;
                }
            }

            const dataOffset = 84;
            const faceLength = 12 * 4 + 2;

            const geometry = new THREE.BufferGeometry();

            const vertices = [];
            const normals = [];
            for (let face = 0; face < faces; face += 1) {
                const start = dataOffset + face * faceLength;
                const normalX = reader.getFloat32(start, true);
                const normalY = reader.getFloat32(start + 4, true);
                const normalZ = reader.getFloat32(start + 8, true);

                if (hasColors) {
                    const packedColor = reader.getUint16(start + 48, true);

                    if ((packedColor & 0x8000) === 0) {
                        // facet has its own unique color

                        r = (packedColor & 0x1f) / 31;
                        g = ((packedColor >> 5) & 0x1f) / 31;
                        b = ((packedColor >> 10) & 0x1f) / 31;
                    } else {
                        r = defaultR;
                        g = defaultG;
                        b = defaultB;
                    }
                }

                for (let i = 1; i <= 3; i += 1) {
                    const vertexstart = start + i * 12;

                    vertices.push(reader.getFloat32(vertexstart, true));
                    vertices.push(reader.getFloat32(vertexstart + 4, true));
                    vertices.push(reader.getFloat32(vertexstart + 8, true));

                    normals.push(normalX, normalY, normalZ);

                    if (hasColors) {
                        colors.push(r, g, b);
                    }
                }
            }

            // we construct an index buffer in order to make the models smoother
            //
            // naive STLs look very low fidelity because WebGL doesn't know to reuse the vertices.
            // by creating an index buffer _alongside_ a deduplicated vertex buffer, we can improve
            // memory usage, performance, and render a smoother model
            //
            // in order to construct the index buffer, we create a list of unique vertices and normals.
            // then, we replace the elements of the original vertex buffer with respective indices into
            // the list of distinct vertices

            const indices: number[] = [];
            const vertexIndexMap = new Map<number, Map<number, Map<number, number>>>();
            const dedupedVertices = [];
            const dedupedNormals = [];

            function vertexToIndex(x: number, y: number, z: number): number | undefined {
                return vertexIndexMap.get(x)?.get(y)?.get(z);
            }
            function storeIndexForVertex(x: number, y: number, z: number, value: number) {
                const xMap = vertexIndexMap.get(x) ?? new Map<number, Map<number, number>>();
                const yMap = xMap.get(x) ?? new Map<number, number>();

                vertexIndexMap.set(x, xMap);
                xMap.set(y, yMap);
                yMap.set(z, value);
            }

            for (let i = 0; i < vertices.length; i += 3) {
                const [x, y, z] = [vertices[i] ?? NaN, vertices[i + 1] ?? NaN, vertices[i + 2] ?? NaN];
                const existingIndex = vertexToIndex(x, y, z);

                if (existingIndex !== undefined) {
                    indices.push(existingIndex);
                } else {
                    const index = dedupedVertices.length / 3;
                    storeIndexForVertex(x, y, z, index);
                    indices.push(index);
                    dedupedVertices.push(x, y, z);
                    dedupedNormals.push(normals[i] ?? NaN, normals[i + 1] ?? NaN, normals[i + 2] ?? NaN);
                }
            }

            geometry.setIndex(indices);
            geometry.setAttribute(
                AttributeName.Position,
                new THREE.BufferAttribute(new Float32Array(dedupedVertices), 3),
            );
            geometry.setAttribute(AttributeName.Normal, new THREE.BufferAttribute(new Float32Array(dedupedNormals), 3));

            if (hasColors) {
                geometry.setAttribute(
                    AttributeName.Color,
                    // @ts-ignore
                    new THREE.BufferAttribute(new Float32Array(colors), 3),
                );
                // @ts-ignore
                geometry.hasColors = true;
                // @ts-ignore
                geometry.alpha = alpha;
            }

            return { geometry, modelType: 'stl' };
        }

        function parseASCII(data: any): StlFile {
            const geometry = new THREE.BufferGeometry();
            const patternFace = /facet([\s\S]*?)endfacet/g;
            let faceCounter = 0;

            const patternFloat = /[\s]+([+-]?(?:\d*)(?:\.\d*)?(?:[eE][+-]?\d+)?)/.source;
            const patternVertex = new RegExp(`vertex ${patternFloat} ${patternFloat} ${patternFloat}`, 'g');
            const patternNormal = new RegExp(`normal ${patternFloat} ${patternFloat} ${patternFloat}`, 'g');

            const vertices = [];
            const normals = [];

            const normal = new THREE.Vector3();

            let result;

            while ((result = patternFace.exec(data)) !== null) {
                let vertexCountPerFace = 0;
                let normalCountPerFace = 0;

                // this is a bunch of WTF, we have to fix this
                const text = result[0] as string;

                while ((result = patternNormal.exec(text)) !== null) {
                    normal.x = parseFloat(result[1] as string);
                    normal.y = parseFloat(result[2] as string);
                    normal.z = parseFloat(result[3] as string);
                    normalCountPerFace += 1;
                }

                while ((result = patternVertex.exec(text)) !== null) {
                    vertices.push(
                        parseFloat(result[1] as string),
                        parseFloat(result[2] as string),
                        parseFloat(result[3] as string),
                    );
                    normals.push(normal.x, normal.y, normal.z);
                    vertexCountPerFace += 1;
                }

                // every face have to own ONE valid normal

                // @ts-ignore
                if (normalCountPerFace !== 1) {
                    logger.warn(`STLLoader: Something isn't right with the normal of face number ${faceCounter}`);
                }

                // each face have to own THREE valid vertices

                if (vertexCountPerFace !== 3) {
                    logger.warn(`STLLoader: Something isn't right with the vertices of face number ${faceCounter}`);
                }

                faceCounter += 1;
            }

            geometry.setAttribute(AttributeName.Position, new THREE.Float32BufferAttribute(vertices, 3));
            geometry.setAttribute(AttributeName.Normal, new THREE.Float32BufferAttribute(normals, 3));

            return { geometry, modelType: 'stl' };
        }

        function ensureString(buffer: any) {
            if (typeof buffer !== 'string') {
                return THREE.LoaderUtils.decodeText(new Uint8Array(buffer));
            }

            return buffer;
        }

        function ensureBinary(buffer: any) {
            if (typeof buffer === 'string') {
                const array_buffer = new Uint8Array(buffer.length);
                for (let i = 0; i < buffer.length; i += 1) {
                    array_buffer[i] = buffer.charCodeAt(i) & 0xff; // implicitly assumes little-endian
                }
                return array_buffer.buffer || array_buffer;
            }
            return buffer;
        }

        // start

        const binData = ensureBinary(data);

        return isBinary(binData) ? parseBinary(binData) : parseASCII(ensureString(data));
    }
}
