/* eslint-disable max-lines, max-lines-per-function */
import {
    lineShaderMaterial,
    getMarginTessellatedPoints,
    drawLine,
    getMarginControlPoints,
    MarginEditingPalette,
    createPreviewLine,
    updateLine,
    updateIndexedSpheres,
    marginsEqual,
    updateCircle,
    findRightIndexForANewPoint,
    interimCleanupMeshesAndObjs,
    drawLineTube,
} from './Margin.util';
import type { IndexedSphere, LineType } from './Margin.util';
import { MarginMeshTubeMaterial } from './MarginMeshTubeMaterial';
import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import { drawCircle } from './utils3d/interaction.util';
import { updateRaycaster } from './utils3d/raycast-bvh.util';
import { getMarginPoints } from '@orthly/forceps';
import { ensureMeshIndex } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import type { MarginLine, Length16Array } from '@orthly/shared-types';
import { Button, FlossPalette, Slider, PauseIcon, PlayArrowIcon, withStyles } from '@orthly/ui-primitives';
import { useThree } from '@react-three/fiber';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';
import type { OrthographicCamera, Vector3 } from 'three';
import type { MeshBVH, HitPointInfo } from 'three-mesh-bvh';

export const IDENTITY_16ARRAY: Length16Array = [1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1];

export interface MarginResult {
    toothNumber: number;
    controlPoints: THREE.Vector3[];
    refinedPoints: THREE.Vector3[];
}

export interface MarginEditingStructure {
    activeTooth?: number;
    marginLines: MarginLine[];
    onMarginUpdate: (marginResult: MarginResult) => void;
}

// more to come here
export interface MarginEditingApp extends MarginEditingStructure {
    setActiveMargin: React.Dispatch<React.SetStateAction<number | undefined>>;
}

interface MarginMeshProps {
    associatedGeometry?: THREE.BufferGeometry;
    marginLine: MarginLine;
    marginXray?: boolean;
    color?: THREE.Color;
    width?: number;
    cameraControlsRef: MainViewCameraControlsRef;
    allowEditing?: boolean;
    onMarginUpdate?: (marginResult: MarginResult) => void;
    // The default behavior is to downsample the passed-in margin line to get the control points. That can be disabled
    // by setting this to true.
    disableControlPointDownsampling?: boolean;
    enableTubeMarginLine?: boolean;
}

export const MarginMesh: React.FC<MarginMeshProps> = ({
    marginLine,
    width,
    color,
    marginXray,
    cameraControlsRef,
    associatedGeometry,
    allowEditing,
    onMarginUpdate,
    disableControlPointDownsampling,
    enableTubeMarginLine,
}) => {
    const marginLinesGroupRef = React.useRef<THREE.Group>();
    const shaderMaterial = React.useMemo(() => {
        return lineShaderMaterial({ color, width, xray: marginXray });
    }, [color, width, marginXray]);

    const tubeMaterial = React.useMemo(() => {
        return new MarginMeshTubeMaterial(color ?? new THREE.Color(0xff0000));
    }, [color]);

    // The Non Editing Effect, simply draw the margin and throw it away when done
    React.useEffect(() => {
        if (allowEditing) {
            return;
        }
        const marginPoints = getMarginPoints(marginLine);
        const tessResult = getMarginTessellatedPoints(marginPoints, true);
        const tessMarginPoints = tessResult.points;
        const line = enableTubeMarginLine
            ? drawLineTube(tessMarginPoints, tubeMaterial, true)
            : drawLine(tessMarginPoints, shaderMaterial);
        const g = marginLinesGroupRef.current;
        if (g) {
            g.add(line);
        }

        return () => {
            if (line) {
                line.geometry.dispose();
                if (g) {
                    g.remove(line);
                }
            }
        };
    }, [marginLinesGroupRef, allowEditing, marginLine, shaderMaterial, enableTubeMarginLine, tubeMaterial]);

    // For Margin Editing, initialize our useStates
    const [controlPointsHistory, setControlPointsHistory] = React.useState<THREE.Vector3[][]>([
        getMarginControlPoints(marginLine, !disableControlPointDownsampling),
    ]);
    const [marginClosedHistory, setMarginClosedHistory] = React.useState<boolean[]>([
        controlPointsHistory[0] ? controlPointsHistory[0].length > 0 : false,
    ]);
    const [needsParentUpdate, setNeedsParentUpdate] = React.useState<boolean>(false);
    const { gl } = useThree();

    // Key Strokes which are single ops.
    // The responsibility to remove any event listeners which
    // may conflict falls on the parent App. Eg NewModelViewer!
    React.useEffect(() => {
        if (!allowEditing) {
            return;
        }
        const keyDown = (evt: KeyboardEvent) => {
            if (evt.key === 'z') {
                if (controlPointsHistory.length > 1) {
                    setControlPointsHistory(controlPointsHistory.slice(0, -1));
                    setMarginClosedHistory(marginClosedHistory.slice(0, -1));
                    // dont have all data needed inside this scope
                    // to update the parent, so remind us to when we do
                    setNeedsParentUpdate(true);
                }
            } else if (evt.key === 'r') {
                if (controlPointsHistory.length > 1) {
                    setControlPointsHistory([...controlPointsHistory.slice(0, 1)]);
                    setMarginClosedHistory([...marginClosedHistory.slice(0, 1)]);
                    setNeedsParentUpdate(true);
                }
            } else if (evt.key === 'c') {
                // clear but add it as intermediate state
                setControlPointsHistory([...controlPointsHistory, []]);
                setMarginClosedHistory([...marginClosedHistory, false]);
                setNeedsParentUpdate(true);
            }
        };
        window.addEventListener('keydown', keyDown);
        return () => {
            window.removeEventListener('keydown', keyDown);
        };
    }, [
        marginLine,
        allowEditing,
        setControlPointsHistory,
        controlPointsHistory,
        setMarginClosedHistory,
        marginClosedHistory,
    ]);

    // Geometry and Materials for reuse for Control Points
    // Geometry and Materials will accumulate if not disposed of
    // and can cause performance degradation as they pile up
    const { sphereGeometry, sphereMaterial, activeMaterial } = React.useMemo(() => {
        return {
            sphereGeometry: new THREE.SphereBufferGeometry(0.125, 32, 32),
            sphereMaterial: new THREE.MeshBasicMaterial({ color: MarginEditingPalette.CONTROL_POINT_COLOR }),
            activeMaterial: new THREE.MeshBasicMaterial({ color: MarginEditingPalette.CONTROL_POINT_COLOR_ACTIVE }),
        };
    }, []);
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        const controls = cameraControlsRef.current;
        const camera = cameraControlsRef.current?.object as OrthographicCamera;
        const g = marginLinesGroupRef.current;

        // if not editing, return early
        if (!allowEditing || !controls) {
            return;
        }

        if (g) {
            g.renderOrder = 1000;
        }
        // only need the index if we are editing
        const indexBVH: MeshBVH | undefined =
            associatedGeometry && allowEditing ? ensureMeshIndex(associatedGeometry) : undefined;

        // pull most recent control points off the stack to interim variables
        // this messy collection is our mutable state structure for p0
        // these are things we need to update fast and in real time in the webGL/THREE renderer
        // not the React renderer.  This will be refactored in p1.
        // It is important not to ReactRe-Render the component while in this interim state
        // and this is currently only "enforceable" with memoization of props that are fed
        // to this component!  What that means if we ReactRerender before a mouseEvent state change
        // has  been persisted to ReactState, we will end up with weirdness or loss of interim state.

        // const Arrays are modified (eg, control_points, tessellated points) or entries modified
        const control_points = (controlPointsHistory[controlPointsHistory.length - 1] ?? []).map(cp => cp.clone());
        const affectNeighbors = true;
        const numberOfNeighbors = 10;
        let effectDistance = 2;
        let hoveredSphereUnderMouse: IndexedSphere | undefined = undefined;
        let downPosition: { x: number; y: number } | undefined = undefined;
        let ctrlDown: boolean = false;
        let shiftDown: boolean = false;
        let mouseHoverOverLineLocation: THREE.Vector3 | undefined = undefined;

        const effectCircle: THREE.Line = drawCircle({ lineWidth: 8 });
        const previewCircle: THREE.Line = drawCircle({ color: MarginEditingPalette.CURSOR_CIRCLE_COLOR });
        const previewLine: LineType = createPreviewLine();
        const spheres: IndexedSphere[] = [];
        const falloff_map: Map<number, number> = new Map<number, number>();
        let line_closed: boolean = (marginClosedHistory[marginClosedHistory.length - 1] ?? false) === true;
        const tessResult = getMarginTessellatedPoints(control_points, line_closed, indexBVH);
        const tessellated_points = tessResult.points;
        // Add in all preview/ui/realtime geometry proxies
        // we put the line in place whether are editing or not
        // as this component is responsible for displaying all margins
        const line = enableTubeMarginLine
            ? drawLineTube(tessellated_points, tubeMaterial, line_closed)
            : drawLine(tessellated_points, shaderMaterial);

        if (line) {
            g?.add(line);
        }
        g?.add(effectCircle);
        g?.add(previewCircle);
        g?.add(previewLine);

        // Send potential update when we remount.  This is a slightly odd pattern
        // we can sometimes change the component level history (with ctrl z, r, c)
        // where we don't have access to tessellated_points, so instead we always
        // report back to onMarginUpdate with our most recent state when we enter
        // this hook.
        // This is a great candidate to remove in p1
        if (needsParentUpdate && onMarginUpdate) {
            onMarginUpdate({
                toothNumber: marginLine.tooth,
                controlPoints: control_points,
                refinedPoints: tessellated_points,
            });
            setNeedsParentUpdate(false);
        }

        /*
         * With initial state and properties, we can now define:
         *    1. Some utils for state guard
         *    2. actions/edits to properties (effects)
         *    3. UI Updates (effects)
         *
         * Unfortunately these 3 concepts are not fully distinct in this FSM.
         * For p1, establishing a cleaner separation of
         *
         * */

        const can_close_line = () => {
            if (line_closed || control_points.length < 4) {
                return false;
            }
            const p0 = control_points[0];
            const pn = control_points[control_points.length - 1];
            if (!pn || !p0) {
                return false;
            }
            if (p0.distanceTo(pn) <= 5) {
                return true;
            }
            return false;
        };
        const can_connect_to_end = (pos: THREE.Vector3, line_closed: boolean): boolean | undefined => {
            // This returns undefined if no connection is avaialable
            // returns true if can connect the the end (last index)
            // returns false if can connect to the beginning (first index)

            if (line_closed) {
                return undefined;
            }
            // we want to always add a second point for usability
            // sometimes an initial point gets added accidentally
            // this gives the user a visual cue by connecting these two
            if (control_points.length === 1) {
                return true;
            }
            const d0 = control_points[0]?.distanceTo(pos);
            const d1 = control_points[control_points.length - 1]?.distanceTo(pos);
            // don't add points if really far away //TODO P1 settings for this magic number
            if (d0 && d0 > 3.5 && d1 && d1 > 3.5) {
                return undefined;
            }
            // addToEnd (if false, add to beginning)
            return !!d0 && !!d1 && d0 > d1;
        };
        const update_live_visual_objects = () => {
            const tessResult = getMarginTessellatedPoints(control_points, line_closed, indexBVH);
            updateLine(line, tessResult.points);
            if (g) {
                updateIndexedSpheres(spheres, control_points, sphereGeometry, sphereMaterial, g);
            }
        };

        const update_preview_line = (mouseLocation: THREE.Vector3, endConnect: boolean) => {
            // get the last 3 or first 3 control points
            const previewControlPoints = endConnect ? control_points.slice(-3) : control_points.slice(0, 3);

            if (endConnect === true) {
                // add to end
                previewControlPoints.push(mouseLocation);
            } else {
                // add to beginning
                previewControlPoints.unshift(mouseLocation);
            }
            // now that we have 4 nodes, update the catmulRom curve
            const tessResult = getMarginTessellatedPoints(previewControlPoints, line_closed, indexBVH, 30);
            updateLine(previewLine, tessResult.points, true);
        };

        const update_falloff_map = (selectedSphere: IndexedSphere) => {
            falloff_map.clear();
            const directions: number[] = [1, -1];
            // go forward and backward
            directions.forEach(direction => {
                // iterate to a maximum of numberOfNeighbors away from the selectedSpehre
                for (let i = 1; i < numberOfNeighbors; i++) {
                    const rawIndex = selectedSphere.index + direction * i;

                    if ((rawIndex >= control_points.length || rawIndex < 0) && !line_closed) {
                        // don't wrap around if the loop is not closed
                        break;
                    }
                    // annoyed that -1 % 90 does not give 89
                    const neighborIndex =
                        rawIndex > 0 ? rawIndex % control_points.length : control_points.length + rawIndex;

                    const neighborPoint = control_points[neighborIndex];
                    if (neighborPoint) {
                        const d: number = neighborPoint.distanceTo(selectedSphere.position);
                        if (d > effectDistance) {
                            break;
                        }
                        // gives values from 0 to 1
                        falloff_map.set(neighborIndex, 1 - d / effectDistance);
                    }
                }
            });
        };
        const update_current_history = () => {
            // update our component state
            setControlPointsHistory([...controlPointsHistory, control_points.map(cp => cp.clone())]);
            setMarginClosedHistory([...marginClosedHistory, line_closed]);
            // remindourselves next useEffect to callback provided by the parent
            setNeedsParentUpdate(true);
        };
        const update_history_if_changed = () => {
            const marginsChanged = !marginsEqual(
                controlPointsHistory[controlPointsHistory.length - 1] ?? [],
                control_points,
            );
            const closedChanged = line_closed !== (marginClosedHistory[marginClosedHistory.length - 1] ?? false);
            if (marginsChanged || closedChanged) {
                update_current_history();
            }
        };
        const hover_mouse_on_scene = (raycaster: THREE.Raycaster) => {
            // is mouse over control spheres?
            const sphereIntersects = raycaster.intersectObjects(spheres, false);
            if (sphereIntersects.length > 0) {
                hoveredSphereUnderMouse = sphereIntersects[0]?.object as IndexedSphere;
                // highlight the hovered sphere
                if (hoveredSphereUnderMouse) {
                    hoveredSphereUnderMouse.material = activeMaterial;
                    previewCircle.visible = true;
                    previewLine.visible = false;
                    updateCircle(previewCircle, {
                        pos: hoveredSphereUnderMouse.position,
                        effectDistance: 0.5,
                        rot: camera.rotation,
                    });

                    // Show the line closure preview
                    if (
                        (hoveredSphereUnderMouse.index === 0 ||
                            hoveredSphereUnderMouse.index === control_points.length - 1) &&
                        can_close_line()
                    ) {
                        const N = control_points.length;
                        // get the last 2 and first 2 control points
                        const previewControlPoints = _.compact([
                            control_points[N - 2],
                            control_points[N - 1],
                            control_points[0],
                            control_points[1],
                        ]);
                        const tessResult = getMarginTessellatedPoints(previewControlPoints, line_closed, indexBVH, 30);
                        updateLine(previewLine, tessResult.points, true);
                        previewLine.visible = true;
                    }
                } else {
                    previewCircle.visible = false;
                    previewLine.visible = false;
                }

                return;
            }

            // mouse over margin line?
            mouseHoverOverLineLocation = undefined;
            if (raycaster && raycaster.params && raycaster.params.Line) {
                raycaster.params.Line.threshold = 0.1;
            }
            const lineIntersects = raycaster.intersectObject(line, false);
            if (lineIntersects.length > 0) {
                const point = lineIntersects[0]?.point;
                mouseHoverOverLineLocation = point;
                previewCircle.visible = true;
                previewLine.visible = false;
                updateCircle(previewCircle, {
                    pos: mouseHoverOverLineLocation,
                    rot: camera.rotation,
                    effectDistance: 0.4,
                });
                return;
            }
            mouseHoverOverLineLocation = undefined;

            if (!indexBVH) {
                previewCircle.visible = false;
                previewLine.visible = false;
                return;
            }
            const intersects = indexBVH.raycast(raycaster.ray, THREE.FrontSide);
            const intersection = _.minBy(intersects, intersect => intersect.distance);
            // we did not click on the mesh
            if (!intersection || !intersection.point) {
                previewCircle.visible = false;
                previewLine.visible = false;
                return;
            }

            const addToEnd = can_connect_to_end(intersection.point, line_closed);
            if (addToEnd === undefined) {
                previewCircle.visible = false;
                previewLine.visible = false;
                return;
            }
            previewCircle.visible = true;
            updateCircle(previewCircle, {
                pos: intersection.point,
                rot: camera.rotation,
                effectDistance: 0.75,
            });
            update_preview_line(intersection.point, addToEnd);
            previewLine.visible = true;
        };
        const transforming_control_point = (raycaster: THREE.Raycaster, selectedSphere: IndexedSphere): boolean => {
            // we cannot continue without our ray-casting and snapping index
            if (!indexBVH) {
                return false;
            }
            // where does our mouse hit the objects?
            const intersects = indexBVH.raycast(raycaster.ray, THREE.FrontSide);
            const intersection = _.minBy(intersects, intersect => intersect.distance);
            // we did not click on the mesh
            if (!intersection || !intersection.point) {
                return false;
            }

            // Store where we started on this frame
            // Could consider to store where we started onMouseDown
            // and compute difference from that static reference TODO: p1
            const marginPoint = control_points[selectedSphere.index];
            if (!marginPoint) {
                return false;
            }
            // remember how far we travelled
            const diffVec = intersection.point.clone().sub(marginPoint);

            // actually go there
            selectedSphere.position.copy(intersection.point);
            marginPoint.copy(intersection.point); // mutates the vector in control_points TODO: p1
            if (shiftDown && affectNeighbors) {
                // draw effect circle around the selected sphere
                effectCircle.visible = true;
                updateCircle(effectCircle, {
                    effectDistance,
                    pos: intersection.point,
                    rot: camera.rotation,
                });

                // update the control points nearby
                falloff_map.forEach((falloffValue, neighborIndex) => {
                    const currentPosition = control_points[neighborIndex];
                    if (!indexBVH || !currentPosition) {
                        return;
                    }
                    const effectOnPreviousPoint = diffVec.clone().multiplyScalar(falloffValue);
                    currentPosition?.add(effectOnPreviousPoint);
                    const target = {} as HitPointInfo;
                    // snap to Scan Mesh
                    indexBVH.closestPointToPoint(currentPosition, target, 0, 3);
                    // currentPosition is inside control_points, so thus mutates the vector in control_points:
                    // peformant but not very readable
                    if (target) {
                        currentPosition.copy(target.point);
                        spheres[neighborIndex]?.position.copy(target.point);
                    }
                });
            }
            return true;
        };

        // put spheres where they belong and rebuild line from control points
        update_live_visual_objects();

        const raycaster = new THREE.Raycaster();
        const canvas = gl.domElement;

        /*
         * Since we are constrained by the domEvent listener structure, we  have
         * domEvents that mean different things in different states.  That is one reason this
         * code is a bit tough to read.  for p1, we may define the domEvents for each state
         * and add/remove them on state transition
         * */
        const pointerMove = (evt: MouseEvent) => {
            // communicate mouse position to scene via camera etc
            updateRaycaster(raycaster, gl.domElement, camera, evt);
            // STATE_TRANSFORMING: moving the hoveredSphereUnderMouse
            if (downPosition && hoveredSphereUnderMouse) {
                const didTransform = transforming_control_point(raycaster, hoveredSphereUnderMouse);
                const tessResult = getMarginTessellatedPoints(control_points, line_closed, indexBVH);
                didTransform && updateLine(line, tessResult.points);
                return;
            }
            // STATE_NAVIGATING. no state change until mouseUp
            if (downPosition && !hoveredSphereUnderMouse) {
                return;
            }
            // STATE_HOVER: moving mouse to hover for selection
            // clear previous visual cue on  previous hoveredSphereUnderMouse if any
            if (hoveredSphereUnderMouse) {
                hoveredSphereUnderMouse.material = sphereMaterial;
                hoveredSphereUnderMouse = undefined;
            }
            hover_mouse_on_scene(raycaster);
        };

        const pointerDown = (evt: MouseEvent) => {
            if (evt.button !== 0) {
                return;
            }
            downPosition = { x: evt.clientX, y: evt.clientY };
            if (hoveredSphereUnderMouse) {
                // STATE_WAITING -> DELETE -> WAITING
                if (ctrlDown) {
                    // remove point from control points and regenerate the line
                    control_points.splice(hoveredSphereUnderMouse.index, 1);
                    update_live_visual_objects();
                    hoveredSphereUnderMouse = undefined;
                    return;
                }
                // STATE_WAITING -> CLOSE_LOOP -> WAITING: if clicking on first or last points, close the loop if not already closed
                if (
                    hoveredSphereUnderMouse.index === 0 ||
                    hoveredSphereUnderMouse.index === control_points.length - 1
                ) {
                    line_closed = !line_closed ? can_close_line() : line_closed;
                }
                // STATE_WAITING -> TRANSFORMING
                // build falloff map at pointerDown so we are ready to deform onPointerMove
                update_falloff_map(hoveredSphereUnderMouse);
                controls.enabled = false;
                return;
            }

            if (mouseHoverOverLineLocation) {
                // create a new control point at this position and add it to the other control points
                // first we need to find the right index for the new control point, then add it to that index
                // WAITING -(click)-> INSERT_POINT
                const correctIndex = findRightIndexForANewPoint(control_points, mouseHoverOverLineLocation);
                control_points.splice(correctIndex, 0, mouseHoverOverLineLocation);
                update_live_visual_objects();
                hoveredSphereUnderMouse = spheres[correctIndex];
                mouseHoverOverLineLocation = undefined;
                controls.enabled = false;
                return;
            }
            // If we are here, we have pointed down and not a closed loop,
            // check if we need to put a point at beginning or end
            // WAITING -> ADD_POINT -> TRANSFORMING
            if (!line_closed) {
                if (!indexBVH) {
                    return;
                }

                const intersects = indexBVH.raycast(raycaster.ray, THREE.FrontSide);
                const intersection = _.minBy(intersects, intersect => intersect.distance);
                // we did not click on the mesh
                if (!intersection || !intersection.point) {
                    return;
                }

                const addToEnd = can_connect_to_end(intersection.point, line_closed);
                if (addToEnd === undefined) {
                    return;
                }

                // add the new control point to beginning or end of the list
                addToEnd ? control_points.push(intersection.point) : control_points.unshift(intersection.point);
                // create or update the spheres and their indexes
                update_live_visual_objects();
                // We know we have a new sphere under the mouse because we just created it
                hoveredSphereUnderMouse = addToEnd ? spheres[spheres.length - 1] : spheres[0];
                hoveredSphereUnderMouse && update_falloff_map(hoveredSphereUnderMouse);
                controls.enabled = false;
            }
        };

        const pointerUp = (evt: MouseEvent) => {
            if (evt.button !== 0) {
                return;
            }
            effectCircle.visible = false;
            downPosition = undefined;
            controls.enabled = true;

            if (controlPointsHistory.length === 0) {
                update_current_history();
                return;
            }
            update_history_if_changed();
        };

        const keyDown = (evt: KeyboardEvent) => {
            if (evt.ctrlKey) {
                ctrlDown = true;
            }
            if (evt.shiftKey) {
                shiftDown = true;
            }
        };

        const keyUp = (evt: KeyboardEvent) => {
            ctrlDown = false;
            shiftDown = false;
            effectCircle.visible = false;
            if (evt.key === '+' || evt.key === '=') {
                effectDistance += 0.2;
                effectCircle.visible = true;
                updateCircle(effectCircle, { effectDistance });
                if (hoveredSphereUnderMouse) {
                    update_falloff_map(hoveredSphereUnderMouse);
                }
            } else if (evt.key === '-') {
                effectDistance -= 0.2;
                effectCircle.visible = true;
                updateCircle(effectCircle, { effectDistance });
                if (hoveredSphereUnderMouse) {
                    update_falloff_map(hoveredSphereUnderMouse);
                }
            }
        };

        canvas?.addEventListener('pointerdown', pointerDown);
        canvas?.addEventListener('pointermove', pointerMove);
        canvas?.addEventListener('pointerup', pointerUp);
        canvas?.addEventListener('pointerleave', pointerUp);
        window.addEventListener('keydown', keyDown);
        window.addEventListener('keyup', keyUp);

        return () => {
            if (g && line && spheres) {
                interimCleanupMeshesAndObjs(g, spheres, [line, effectCircle, previewCircle, previewLine]);
            }

            canvas?.removeEventListener('pointerdown', pointerDown);
            canvas?.removeEventListener('pointermove', pointerMove);
            canvas?.removeEventListener('pointerup', pointerUp);
            canvas?.removeEventListener('pointerleave', pointerUp);
            window.removeEventListener('keydown', keyDown);
            window.removeEventListener('keyup', keyUp);
        };
    }, [
        marginLine,
        cameraControlsRef,
        shaderMaterial,
        allowEditing,
        associatedGeometry,
        onMarginUpdate,
        setControlPointsHistory,
        controlPointsHistory,
        needsParentUpdate,
        setMarginClosedHistory,
        marginClosedHistory,
        activeMaterial,
        sphereMaterial,
        sphereGeometry,
        gl,
        enableTubeMarginLine,
        tubeMaterial,
    ]);

    return <group ref={marginLinesGroupRef} />;
};

interface MarginLineLocTangent {
    location: THREE.Vector3;
    marginTangent: THREE.Vector3;
}

/**
 * Get the line tangent to the margin line
 */
function getMarginTangent(margin: MarginLine, s: number): MarginLineLocTangent {
    if (margin.mb_coords === undefined) {
        return { location: new THREE.Vector3(), marginTangent: new THREE.Vector3(1, 0, 0) };
    }

    const sPrime = Math.min(Math.max(s, 0), 1);
    const numberOfPoints = margin.mb_coords.length;

    const indexClosest = Math.floor(sPrime * numberOfPoints) % numberOfPoints;
    const indexPlus1 = (indexClosest + 1) % numberOfPoints;
    const indexPlus2 = (indexClosest + 2) % numberOfPoints;
    const indexMinus1 = (indexClosest - 1 + numberOfPoints) % numberOfPoints;

    // allow for forward interpolation, special case for wrapping around
    let alpha = sPrime * numberOfPoints - indexClosest;
    if (alpha > 1) {
        alpha = sPrime * numberOfPoints - numberOfPoints;
    }

    const thisCoord = margin.mb_coords[indexClosest];
    const previousCoord = margin.mb_coords[indexMinus1];
    const nextCoord = margin.mb_coords[indexPlus1];
    const twoCoordsFromNow = margin.mb_coords[indexPlus2];

    const previousPoint = new THREE.Vector3(previousCoord?.x, previousCoord?.y, previousCoord?.z);
    const thisPoint = new THREE.Vector3(thisCoord?.x, thisCoord?.y, thisCoord?.z);
    const nextPoint = new THREE.Vector3(nextCoord?.x, nextCoord?.y, nextCoord?.z);
    const twoPointsFromNow = new THREE.Vector3(twoCoordsFromNow?.x, twoCoordsFromNow?.y, twoCoordsFromNow?.z);

    const vm1 = thisPoint.clone().addScaledVector(previousPoint, -1).normalize();
    const vp1 = nextPoint.clone().addScaledVector(thisPoint, -1).normalize();
    const vpm1 = nextPoint.clone().addScaledVector(previousPoint, -1).normalize();

    const v2m1 = nextPoint.clone().addScaledVector(thisPoint, -1).normalize();
    const v2p1 = twoPointsFromNow.clone().addScaledVector(nextPoint, -1).normalize();
    const v2pm1 = twoPointsFromNow.clone().addScaledVector(thisPoint, -1).normalize();

    const marginTangent1 = new THREE.Vector3()
        .addScaledVector(vpm1, 0.5)
        .addScaledVector(vp1, 0.25)
        .addScaledVector(vm1, 0.25);
    marginTangent1.normalize();

    const marginTangent2 = new THREE.Vector3()
        .addScaledVector(v2pm1, 0.5)
        .addScaledVector(v2p1, 0.25)
        .addScaledVector(v2m1, 0.25);
    marginTangent2.normalize();

    const marginTangent = marginTangent1.lerpVectors(marginTangent1, marginTangent2, alpha);

    const location = thisPoint.lerp(nextPoint, alpha);

    return { location, marginTangent };
}

export interface MarginCameraPose {
    locVector: THREE.Vector3;
    rotMatrix: THREE.Matrix4;
}

export enum MarginSliderMode {
    Helicopter = 'Helicopter',
    FollowTrack = 'FollowTrack',
    LateralSlide = 'LateralSlide',
}

/**
 * We position the camera such that the camera left/right axis is aligned with
 * the tangent to the margin and the camera -Z is looking down the Y axis. We
 * assume that the preparation is more or less aligned with the world Y axis.
 * We then place the camera 200 units "above" the margin with respect to whether
 * it is an Upper or LowerJaw preparation
 */

export function getCameraPoseOnMargin(
    margin: MarginLine | undefined,
    s: number,
    variant: MarginSliderMode,
): MarginCameraPose {
    if (!margin) {
        return { locVector: new THREE.Vector3(), rotMatrix: new THREE.Matrix4() };
    }

    const { location, marginTangent } = getMarginTangent(margin, s);
    const camZ = ToothUtils.toothIsUpper(margin.tooth) ? new THREE.Vector3(0, 1, 0) : new THREE.Vector3(0, -1, 0);

    let camX: Vector3;
    let camY: Vector3;

    switch (variant) {
        case MarginSliderMode.FollowTrack:
            camX = marginTangent.clone().cross(camZ);
            camY = camZ.clone().cross(camX);
            break;
        case MarginSliderMode.Helicopter:
            camY = new THREE.Vector3(0, 0, 1).cross(camZ);
            camX = camY.clone().cross(camZ);
            break;

        case MarginSliderMode.LateralSlide:
            camY = marginTangent.clone().cross(camZ);
            camX = camY.clone().cross(camZ);
            break;
    }

    const matrixPose = new THREE.Matrix4().makeBasis(camX, camY, camZ);
    const camLocation = location.clone().add(camZ.multiplyScalar(-200));

    return { locVector: camLocation, rotMatrix: matrixPose };
}

interface MarginLineSliderProps {
    onChange: (n: number) => void;
    value: number;
}

const FlossSlider = withStyles(() => ({
    track: {
        height: 4,
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
    rail: {
        height: 4,
        opacity: 1,
        color: FlossPalette.DARK_TAN,
    },
    thumb: {
        width: 32,
        height: 32,
        marginTop: -16,
        marginLeft: -16,
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
}))(Slider);

export const MarginLineSlider: React.FC<MarginLineSliderProps> = ({ onChange, value }) => {
    const [isAutoPlaying, setIsAutoPlaying] = React.useState(false);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (isAutoPlaying) {
                onChange(value + 0.005);
            }
        }, 50);

        return () => clearTimeout(timeoutId);
    });

    return (
        <div
            style={{
                width: '100%',
                height: 64,
                padding: `10px 8px`,
                position: 'absolute',
                left: '0px',
                bottom: '0px',
                right: '0px',
                backgroundColor: FlossPalette.WHITE,
                borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
            }}
        >
            <div
                style={{
                    width: `calc(100% - (164px + 16px))`,
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Button
                    variant={'primary'}
                    onClick={() => setIsAutoPlaying(a => !a)}
                    style={{ backgroundColor: FlossPalette.STAR_GRASS, marginRight: 24 }}
                >
                    {isAutoPlaying ? (
                        <PauseIcon style={{ color: FlossPalette.WHITE }} />
                    ) : (
                        <PlayArrowIcon style={{ color: FlossPalette.WHITE }} />
                    )}
                </Button>
                <FlossSlider
                    onChange={(_event, newValue) => {
                        if (Array.isArray(newValue)) {
                            return;
                        }

                        onChange(newValue);
                    }}
                    value={value}
                    min={0}
                    max={1}
                    step={0.01}
                    size={'small'}
                />
            </div>
        </div>
    );
};
