import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import { NavigationLogoDandy, ToolbarContainer } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Button,
    Text,
} from '@orthly/ui-primitives';
import { DoctorProfilePhotoContent } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory<{ isMobile?: boolean }>(() => ({
    logo: {
        color: FlossPalette.BLACK,
        height: 32,
    },
    profilePhoto: {
        width: 48,
        height: 48,
        borderRadius: 4,
    },
    toolbar: ({ isMobile }) => ({
        backgroundColor: FlossPalette.WHITE,
        height: 72,
        marginBottom: isMobile ? 0 : 50,
        justifyContent: isMobile ? 'center' : 'space-between',
        alignContent: 'center',
    }),
    doctorPhotoAndSignInContainer: {
        display: 'inline-flex',
    },
    doctorPhotoContainer: {
        marginRight: 15,
    },
    loginButton: {
        padding: '12px 16px',
        height: 40,
    },
}));

interface ToolbarProps {
    profile: LabsGqlDoctorPreferencesFragment;
}

const DesktopToolbar: React.VFC<ToolbarProps> = ({ profile }) => {
    const classes = useStyles({});
    const history = useHistory();
    return (
        <>
            <NavigationLogoDandy className={classes.logo} />
            <Grid item>
                <Text variant={'h5'} medium>
                    {`${profile.formatted_name}'s `}
                    <Text variant={'h5'} medium color={'GRAY'} style={{ display: 'inline' }}>
                        preferences
                    </Text>
                </Text>
            </Grid>
            <Grid item className={classes.doctorPhotoAndSignInContainer}>
                <Grid item className={classes.doctorPhotoContainer}>
                    <DoctorProfilePhotoContent
                        type={profile.profile_photo.type}
                        source={profile.profile_photo.source ?? profile.name}
                        color={profile.profile_photo.color}
                        className={classes.profilePhoto}
                    />
                </Grid>
                <Grid item>
                    <Button
                        variant={'secondary-gray'}
                        onClick={() => history.push(`${PracticeScreen.sign_up_account}`)}
                        startIcon={'HomeIcon'}
                    >
                        Sign into portal
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

const MobileToolbar: React.VFC<ToolbarProps> = ({ profile }) => {
    const history = useHistory();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });
    return (
        <Grid container direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Grid item>
                <Text variant={'body2'} medium>
                    {`${profile.formatted_name}'s `}
                    <Text variant={'body2'} medium color={'GRAY'} style={{ display: 'inline' }}>
                        preferences
                    </Text>
                </Text>
            </Grid>
            <Grid item>
                <Button
                    className={classes.loginButton}
                    endIcon={'ChevronRight'}
                    variant={'primary'}
                    onClick={() => history.push(`${PracticeScreen.sign_up_account}`)}
                >
                    Log in
                </Button>
            </Grid>
        </Grid>
    );
};

export const PaymentMethodAndAddressToolbar: React.VFC<ToolbarProps> = ({ profile }) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });

    return (
        <ToolbarContainer sticky className={classes.toolbar}>
            {isMobile ? <MobileToolbar profile={profile} /> : <DesktopToolbar profile={profile} />}
        </ToolbarContainer>
    );
};
