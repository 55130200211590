import {
    STUDIO_MODEL_GRAY_COLOR,
    MODEL_GRAY_COLOR,
    STUDIO_MESH_SHININESS,
    STANDARD_MESH_SHININESS,
} from '../defaultModelColors';
import { createStudioRestorativeMaterial } from './StudioRestorativeMaterial';
import type { CommonMaterialProps } from './Types';
import React from 'react';
import * as THREE from 'three';

interface LightRigProps {
    intensity: number;
    intraLightWidth?: number;
    verticalShift?: number;
    forwardShift?: number;
    bottomLightShift?: number;
}

// Set up a 3 point dental photography light setup
export const StudioLightRig: React.FC<LightRigProps> = props => {
    const { intensity, intraLightWidth = 0.0, verticalShift = 0.0, forwardShift = 0.0, bottomLightShift = 0.0 } = props;

    return (
        <>
            <pointLight
                position={[intraLightWidth / 2, verticalShift, -forwardShift]}
                intensity={0.33 * intensity}
                color={0xffffff}
            />
            ;
            <pointLight
                position={[-intraLightWidth / 2, verticalShift, -forwardShift]}
                intensity={0.33 * intensity}
                color={0xffffff}
            />
            ;
            <pointLight position={[0, -bottomLightShift, 0]} intensity={0.33 * intensity} color={0xffffff} />;
        </>
    );
};

export const StudioRestorativeMaterial: React.VFC<CommonMaterialProps> = props => {
    const material = createStudioRestorativeMaterial(props);

    React.useEffect(() => {
        return () => {
            material.dispose();
        };
    }, [material]);

    return <primitive object={material} attach={'material'} />;
};

export const StudioScanStoneMaterial: React.VFC<CommonMaterialProps> = props => {
    return (
        <meshPhongMaterial
            color={props.color ?? STUDIO_MODEL_GRAY_COLOR}
            // to combat the effects of additional ambient light
            // we put a negative emissive intensity value
            emissive={new THREE.Color(1, 1, 1)}
            emissiveIntensity={-0.15}
            vertexColors={false}
            transparent={props.show_transparent ?? false}
            opacity={props.opacity ?? 1.0}
            wireframe={props.wireframe ?? false}
            depthWrite={props.depthWrite ?? true}
            wireframeLinewidth={props.wireframeLinewidth ?? 3.0}
            specular={new THREE.Color(0x111111)}
            polygonOffset={false}
            polygonOffsetFactor={0.5}
            polygonOffsetUnits={0}
            shininess={STUDIO_MESH_SHININESS}
            side={THREE.DoubleSide}
        />
    );
};

export const StandardScanStoneMaterial: React.VFC<CommonMaterialProps> = props => {
    return (
        <meshPhongMaterial
            color={props.color ?? MODEL_GRAY_COLOR}
            vertexColors={false}
            transparent={props.show_transparent ?? false}
            opacity={props.opacity ?? 1.0}
            wireframe={props.wireframe ?? false}
            depthWrite={props.depthWrite ?? true}
            wireframeLinewidth={props.wireframeLinewidth ?? 3.0}
            specular={new THREE.Color(0x111111)}
            polygonOffset={false}
            polygonOffsetFactor={0.5}
            polygonOffsetUnits={0}
            shininess={STANDARD_MESH_SHININESS}
            side={THREE.DoubleSide}
        />
    );
};
