/* eslint-disable max-lines */
import type { QcHeatmapRange } from '../ColorRamp';
import { QcHeatmapOptions, qcDynamicHeatMapColorForModel } from '../ColorRamp';
import type { Point3DArray, ThreeBufferAttribute } from './ModelViewerTHREETypes';
import type { Model, ModelPayloadItem } from './ModelViewerTypes';
import type { CtmFile } from './ctm/CTMLoader';
import { DEFAULT_MODEL_COLOR } from './defaultModelColors';
import type { HeatMapType, PlyFile } from '@orthly/forceps';
import type { ArrayN } from '@orthly/runtime-utils';
import type { MeshPhysicalMaterialProps } from '@react-three/fiber';
import React from 'react';
import type { Mesh, Texture } from 'three';
import * as THREE from 'three';
import { Euler, Matrix4, Quaternion, Vector3 } from 'three';

// TODO refactor ModelMesh DND-1594
export type Length16Array = ArrayN<number, 16>;

const ORIGINAL_COLOR_ATTRIBUTE_NAME: string = 'originalColor';
const COLOR_ATTRIBUTE_NAME: string = 'color';
export const QC_MESHES_SHININESS = 90;

export const MESH_DEFAULT_ROTATION = new Euler(0, 0, 0);

export const MESH_DEFAULT_QUATERNION = new Quaternion().setFromEuler(MESH_DEFAULT_ROTATION);

export const MESH_DEFAULT_POSITION = new Vector3(0, 0, 0);

export const MESH_DEFAULT_SCALE = new Vector3(1, 1, 1);

export const MESH_DEFAULT_TRANSFORM = new Matrix4().compose(
    MESH_DEFAULT_POSITION,
    MESH_DEFAULT_QUATERNION,
    MESH_DEFAULT_SCALE,
);

export const DEFAULT_SCAN_MESH_COLOR_MNT_PROPS = {
    sRGBToLinear: false,
    saturation: 1.0,
    lightness: 1.0,
};

export const _3SHAPE_SCAN_MESH_COLOR_MNT_PROPS = {
    sRGBToLinear: true,
    saturation: 1.0,
    lightness: 1.0,
};

export const SCAN_MATERIAL_BASE_COLOR = new THREE.Color(0xffffff);
export const SCAN_MATERIAL_ROUGHNESS = 0.25;
export const SCAN_MATERIAL_REFLECTIVITY = 0.2;

export function isLength16Array(arr: any): arr is ArrayN<number, 16> {
    return Array.isArray(arr) && arr.length === 16 && arr.every(el => typeof el === 'number');
}

export function transformFromPlyOrCtm(
    payload?: PlyFile | CtmFile,
): { upper: THREE.Matrix4; lower: THREE.Matrix4 } | null {
    if (!payload) {
        return null;
    }

    const { header } = payload;

    const transform = header?.comments.find(comment => comment.trim().startsWith('transformations'));
    if (!transform) {
        return null;
    }

    try {
        const { upperTransform, lowerTransform } = JSON.parse(transform.slice('transformations '.length));

        if (!isLength16Array(upperTransform) || !isLength16Array(lowerTransform)) {
            return null;
        }

        const upper = new THREE.Matrix4();
        const lower = new THREE.Matrix4();

        upper.set(...upperTransform);
        lower.set(...lowerTransform);

        return { upper, lower };
    } catch {
        return null;
    }
}

const shaderPatchDefinitions = `uniform float saturation;
uniform float lightness;
uniform bool doSRGBToLinear;
vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));

    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}
void main() {
`;

const shaderPatchMain = `
    vec3 hsv = rgb2hsv(outgoingLight);

    hsv.y = clamp(hsv.y * saturation, 0.0, 1.0);
    hsv.z = clamp(hsv.z * lightness, 0.0, 1.0);

    outgoingLight = hsv2rgb(hsv);

    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
    if (doSRGBToLinear) {
        gl_FragColor = sRGBToLinear(gl_FragColor);
    }
`;

export interface MeshPhysicalHSVMaterialProps extends MeshPhysicalMaterialProps {
    saturation?: number;
    lightness?: number;
    sRGBToLinear?: boolean;
    activeHeatMap?: HeatMapType;
    heatMapRange?: QcHeatmapRange;
    showHeatmap?: boolean;
}

/**
 * This material wraps around MeshPhysycalMaterial
 * and ads some color management controls into it
 * by patching material shader.
 */
export const MeshPhysycalHSVMaterial: React.VFC<MeshPhysicalHSVMaterialProps> = props => {
    const { saturation, lightness, sRGBToLinear, ...meshPhysycalMaterialProps } = props;

    const saturationUniformRef = React.useRef(new THREE.Uniform(saturation ?? 1.0));
    React.useEffect(() => {
        saturationUniformRef.current.value = saturation ?? 1.0;
    }, [saturation]);

    const lightnessUniformRef = React.useRef(new THREE.Uniform(lightness ?? 1.0));
    React.useEffect(() => {
        lightnessUniformRef.current.value = lightness ?? 1.0;
    }, [lightness]);

    const sRGBToLinearUniformRef = React.useRef(new THREE.Uniform(sRGBToLinear ?? false));
    React.useEffect(() => {
        sRGBToLinearUniformRef.current.value = sRGBToLinear ?? false;
    }, [sRGBToLinear]);

    const onBeforeCompileHook = React.useCallback(shader => {
        // There is no way to replicate MeshPhysycalMaterial
        // in a CustomShaderMaterial in three.js because
        // some parts of three.js makes optimizations based on
        // material class name.

        // We want to modify original fragment shader code to
        // 1. Add some custom functions definitions see: shaderPatchDefinitions
        //    and some uniforms with parameters for it.
        // 2. Call those functions in main() of the shader see: shaderPatchMain
        //    we want to call those function, when the gl_FragColor
        //    (originl not modified result of the shader) already
        //    available.

        // Imppoprtant parts of the shader are:
        // ...
        // #include <logdepthbuf_pars_fragment>
        // #include <clipping_planes_pars_fragment>
        // // *we want to add our definitions here*
        // void main() {
        //    #include <clipping_planes_fragment>
        //    vec4 diffuseColor = vec4( diffuse, opacity );
        //    ...
        //    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;
        //    ...
        //    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
        //    // *and call for the function here*
        //    ...
        // }

        shader.uniforms['lightness'] = lightnessUniformRef.current;
        shader.uniforms['saturation'] = saturationUniformRef.current;
        shader.uniforms['doSRGBToLinear'] = sRGBToLinearUniformRef.current;

        let fs = shader.fragmentShader;

        // This isn't a very reliable way to patch a shader code
        // if this become a problem in a future, consider to build
        // a simple Abstract Syntax Tree and modify shader via it.
        fs = fs.replace('void main() {', shaderPatchDefinitions);
        fs = fs.replace('\tgl_FragColor = vec4( outgoingLight, diffuseColor.a );', shaderPatchMain);

        shader.fragmentShader = fs;
    }, []);

    const materialRef = React.useRef<THREE.MeshPhysicalMaterial>();

    React.useEffect(() => {
        const material = materialRef.current;
        if (material) {
            // It would be ideal to for performance to just mark the
            // uniforms for update not the whole material
            // but since we don't create custom material but are patching
            // existing one, we have to mark the whole material for update
            // because THREE doesn't expose methods or flags to do that
            // for standard materials such as MeshPhysicalMaterial
            material.needsUpdate = true;
        }
    }, [meshPhysycalMaterialProps.map, meshPhysycalMaterialProps.vertexColors]);

    return (
        <meshPhysicalMaterial ref={materialRef} {...meshPhysycalMaterialProps} onBeforeCompile={onBeforeCompileHook} />
    );
};

// MeshPhysicalMaterialProps
export type DefaultScanMeshPhysicalMaterialProps = {
    opacity?: number;
    transparent?: boolean;
};

/**
 * Legacy scan material.
 *
 * Doesn't modify color space, used in scanner.
 *
 * use ScanMeshPhysicalMaterial for 3Shape sourced scans
 */
export const DefaultScanMeshPhysicalMaterial: React.VFC<MeshPhysicalMaterialProps> = props => {
    const { opacity, transparent, map } = props;
    const base_color = new THREE.Color('white');
    const materialRef = React.useRef<THREE.MeshPhysicalMaterial>();

    React.useEffect(() => {
        const material = materialRef.current;
        if (material) {
            // It would be ideal to for performance to just mark the
            // uniforms for update not the whole material
            // but since we don't create custom material but are patching
            // existing one, we have to mark the whole material for update
            // because THREE doesn't expose methods or flags to do that
            // for standard materials such as MeshPhysicalMaterial
            material.needsUpdate = true;
        }
    }, [map]);

    return (
        <meshPhysicalMaterial
            ref={materialRef}
            map={map}
            vertexColors={!map}
            opacity={opacity}
            transparent={transparent}
            clearcoat={0.07}
            clearcoatRoughness={0.175}
            emissive={base_color}
            emissiveIntensity={0.15}
            side={THREE.DoubleSide}
            flatShading={false}
        />
    );
};

export type ScanMeshPhysicalMaterialProps = {
    opacity?: number;
    transparent?: boolean;
    sRGBToLinear?: boolean;
    lightness?: number;
    saturation?: number;
};

/**
 * Main material for scans comming from 3Shape
 *
 * Does perform color space transformations (Linear / sRGB).
 *
 * Color management props:
 * * @param srgb call sRGB transform in shader code
 * * @param lightness 1.0 leaves lightness unchanged,
 *                    greater than 1.0 values increase lightness
 * * @param saturation 1.0 leaves saturation unchanged,
 *                     greater than 1.0 values increase saturation
 *                     0.0 desaturates model
 *
 * Note: lightness and saturation are applied via HSV transform, and
 * applied after sRGB transformation.
 */
export const ScanMeshPhysicalMaterial: React.VFC<MeshPhysicalHSVMaterialProps> = props => {
    const {
        color,
        map,
        opacity,
        transparent,
        vertexColors,
        sRGBToLinear = _3SHAPE_SCAN_MESH_COLOR_MNT_PROPS.sRGBToLinear,
        lightness = _3SHAPE_SCAN_MESH_COLOR_MNT_PROPS.lightness,
        saturation = _3SHAPE_SCAN_MESH_COLOR_MNT_PROPS.saturation,
    } = props;

    return (
        <MeshPhysycalHSVMaterial
            map={map}
            vertexColors={vertexColors}
            color={color ?? SCAN_MATERIAL_BASE_COLOR}
            opacity={opacity}
            transparent={transparent}
            clearcoat={0.1}
            clearcoatRoughness={0.175}
            roughness={SCAN_MATERIAL_ROUGHNESS}
            reflectivity={SCAN_MATERIAL_REFLECTIVITY}
            side={THREE.DoubleSide}
            flatShading={false}
            saturation={saturation}
            lightness={lightness}
            sRGBToLinear={sRGBToLinear}
        />
    );
};

type CommonMaterialProps = {
    color?: number;
    show_transparent?: boolean;
    opacity?: number;
    wireframe?: boolean;
    wireframeLinewidth?: number;
    depthWrite?: boolean;
};

type CommonProps = {
    visible?: boolean;
    customMaterial?: any;
    centered: boolean;
} & CommonMaterialProps;

type DefaultUntexturedMaterialProps = CommonMaterialProps & { [key: string]: any };
const DefaultUntexturedMaterial: React.VFC<DefaultUntexturedMaterialProps> = props => {
    return (
        <meshPhongMaterial
            color={props.color ?? DEFAULT_MODEL_COLOR}
            vertexColors={false}
            transparent={props.show_transparent ?? false}
            opacity={props.opacity ?? 1.0}
            wireframe={props.wireframe ?? false}
            depthWrite={props.depthWrite ?? true}
            wireframeLinewidth={props.wireframeLinewidth ?? 3.0}
            specular={new THREE.Color(0x111111)}
            polygonOffset={false}
            polygonOffsetFactor={0.5}
            polygonOffsetUnits={0}
            shininess={QC_MESHES_SHININESS}
            side={THREE.DoubleSide}
        />
    );
};

type ModelMeshMaterialProps = {
    model: Model;
    colorize: boolean;
} & CommonMaterialProps;
const ModelMeshMaterial: React.FC<ModelMeshMaterialProps> = props => {
    const { model, colorize, color } = props;

    let textured = colorize;
    if (model.modelType === 'ctm') {
        textured = !!(textured && model.header?.hasTexture);
    }

    const scanMeshProps = {
        transparent: props.show_transparent ?? false,
        opacity: props.opacity ?? 1.0,
    };

    // Important bit to keep in mind: if we ever want to have a non-textured model,
    // we can re-use the PLY model and simply disable its texture in the render cycle!
    // This saves on having to re-compute the model, load it into threejs, etc.

    switch (model.modelType) {
        case 'ply':
        case 'ctm':
        case 'drc':
        case 'dcm':
            return textured ? (
                <DefaultScanMeshPhysicalMaterial {...scanMeshProps} />
            ) : (
                <DefaultUntexturedMaterial {...props} />
            );

        default:
            return (
                <meshPhongMaterial
                    color={color}
                    specular={new THREE.Color(0x111111)}
                    shininess={20}
                    transparent={props.show_transparent ?? false}
                    opacity={props.opacity ?? 1.0}
                    side={THREE.DoubleSide}
                />
            );
    }
};

type ModelMeshProps = {
    model: Model;
    colorize: boolean;
    activeHeatMap?: HeatMapType;
    heatMapRange?: QcHeatmapRange;
    useShaderHeatmaps?: boolean;
    colorMap?: Texture | null;
    insertionAxis?: Point3DArray;
    renderOrder?: number;
    customMesh?: Mesh;
    position?: THREE.Vector3;
    rotation?: THREE.Euler;
    scale?: THREE.Vector3;
    wireframe?: boolean;
} & CommonProps;

export const ModelMesh: React.FC<ModelMeshProps> = props => {
    const {
        visible,
        model,
        centered,
        customMaterial,
        activeHeatMap,
        heatMapRange,
        useShaderHeatmaps,
        renderOrder,
        customMesh,
        wireframe,
        ...materialProps
    } = props;

    React.useEffect(() => {
        // store the original color
        storeOriginalColor(model.geometry);
        model.geometry.computeVertexNormals();
    }, [model.geometry]);

    React.useEffect(() => {
        if (!useShaderHeatmaps) {
            setQCModelHeatMapPerModel(model.geometry, activeHeatMap, heatMapRange);
        }
    }, [model.geometry, activeHeatMap, heatMapRange, useShaderHeatmaps]);

    const ref = React.useRef<Mesh>();

    React.useEffect(() => {
        if (centered && ref.current) {
            ref.current.geometry.center();
        }
    }, [centered]);

    if (!model.geometry) {
        return null;
    }

    const material = <>{customMaterial ?? <ModelMeshMaterial model={model} {...materialProps} />}</>;
    const wireMaterial = <meshStandardMaterial color={0x000000} wireframe={true} />;

    if (customMesh) {
        return (
            <primitive
                ref={ref}
                object={customMesh}
                visible={visible}
                geometry={model.geometry}
                receiveShadow={true}
                castShadow={true}
                renderOrder={renderOrder ?? 1}
            >
                {material}
                <mesh visible={wireframe ?? false} geometry={model.geometry}>
                    {wireMaterial}
                </mesh>
            </primitive>
        );
    }

    return (
        <mesh
            ref={ref}
            visible={visible}
            geometry={model.geometry}
            scale={props.scale ?? MESH_DEFAULT_SCALE.clone()}
            rotation={props.rotation ?? MESH_DEFAULT_ROTATION.clone()}
            position={props.position ?? MESH_DEFAULT_POSITION.clone()}
            receiveShadow={true}
            castShadow={true}
            renderOrder={renderOrder ?? 1}
        >
            {material}
            <mesh visible={wireframe ?? false} geometry={model.geometry}>
                {wireMaterial}
            </mesh>
        </mesh>
    );
};

type QCMeshCustomMaterialProps = {
    wireframe: boolean;
    depthtest: boolean;
    show_transparent?: boolean;
    opacity?: number;
};
export const QCMeshCustomMaterial: React.VFC<QCMeshCustomMaterialProps> = props => {
    const { wireframe, depthtest, show_transparent, opacity } = props;
    return (
        <meshPhongMaterial
            vertexColors={true}
            dithering={true}
            color={'white'}
            side={THREE.DoubleSide}
            wireframe={wireframe}
            wireframeLinewidth={3.0}
            depthTest={depthtest}
            depthWrite={true}
            transparent={show_transparent ?? false}
            opacity={opacity ?? 1.0}
            shininess={QC_MESHES_SHININESS}
        />
    );
};

type QCCollisionsMaterialProps = {
    visible?: boolean;
    color: THREE.Color;
};

const QCCollisionsMaterial: React.VFC<QCCollisionsMaterialProps> = props => {
    const { visible, color } = props;
    return (
        <meshBasicMaterial
            attachArray={'material'}
            color={color}
            wireframe={true}
            wireframeLinewidth={3.0}
            depthTest={false}
            depthWrite={true}
            visible={visible ?? true}
        />
    );
};

type QCCollisionsMeshProps = {
    item: ModelPayloadItem;
    showSecondGroup?: boolean;
    firstGroupColor?: THREE.Color;
    secondGroupColor?: THREE.Color;
    thirdGroupColor?: THREE.Color;
};

export const QCCollisionsMesh: React.VFC<QCCollisionsMeshProps> = props => {
    const { item, showSecondGroup } = props;
    const firstGroupColor = props.firstGroupColor ?? new THREE.Color('black');
    const secondGroupColor = props.secondGroupColor ?? new THREE.Color('purple');
    const thirdGroupColor = props.thirdGroupColor ?? new THREE.Color('black');

    // we are using now three materials for the same collisions geometry, the first material for proxiaml collision lines, the second
    // one for proxiamlCurtains collision lines, the third one for occlusal collision lines.
    // to use multiple materials with the same geometry in three.js we need to split the geometry vertices into different groups
    // and assign each group a material index
    // at least the geometry must have one group to use the first material, otherwise it will not render anything.
    if (item.model.geometry.groups.length === 0) {
        item.model.geometry.addGroup(0, Infinity, 0);
    }

    return item.mesh ? (
        <primitive key={item.name} object={item.mesh} receiveShadow={true} castShadow={true} renderOrder={10000}>
            <QCCollisionsMaterial color={firstGroupColor} />
            {/* Material for the undercut respecting variant */}
            <QCCollisionsMaterial color={secondGroupColor} visible={showSecondGroup} />
            <QCCollisionsMaterial color={thirdGroupColor} />
        </primitive>
    ) : (
        <mesh key={item.name} geometry={item.model.geometry} receiveShadow={true} castShadow={true} renderOrder={10000}>
            <QCCollisionsMaterial color={firstGroupColor} />
            {/* Material for the undercut respecting variant */}
            <QCCollisionsMaterial color={secondGroupColor} visible={showSecondGroup} />
            <QCCollisionsMaterial color={thirdGroupColor} />
        </mesh>
    );
};

export function setMeshColor(
    geometry: THREE.BufferGeometry,
    color: THREE.BufferAttribute | THREE.InterleavedBufferAttribute,
) {
    geometry.setAttribute(COLOR_ATTRIBUTE_NAME, color);
    // typescript isn't sure that .color exists, even though we just set it
    if (geometry.attributes.color) {
        geometry.attributes.color.needsUpdate = true;
    }
}

export function getOriginalColor(geometry: THREE.BufferGeometry | undefined): ThreeBufferAttribute {
    return geometry?.getAttribute(ORIGINAL_COLOR_ATTRIBUTE_NAME);
}

export function storeOriginalColor(geometry: THREE.BufferGeometry) {
    if (!!getOriginalColor(geometry)) {
        // already stored
        return;
    }
    // preserve original color if any
    const origColor = geometry.getAttribute(COLOR_ATTRIBUTE_NAME);
    if (origColor) {
        geometry.setAttribute(ORIGINAL_COLOR_ATTRIBUTE_NAME, origColor);
    }
}

export function setQCModelHeatMapPerModel(
    geometry: THREE.BufferGeometry,
    heatMapType: HeatMapType | undefined,
    heatMapRange?: QcHeatmapRange,
) {
    if (!heatMapType) {
        const originalColor = getOriginalColor(geometry);
        if (originalColor) {
            setMeshColor(geometry, originalColor);
        } else {
            geometry.deleteAttribute(COLOR_ATTRIBUTE_NAME);
        }
        return;
    }

    const heatMap = QcHeatmapOptions[heatMapType];
    const range = heatMapRange ?? heatMap.defaultRange;
    const color = qcDynamicHeatMapColorForModel(geometry, heatMap, range) ?? heatMap.qcStaticHeatmapColor(geometry);

    if (color) {
        setMeshColor(geometry, color);
    }
}
