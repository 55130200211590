import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { SimpleInput, SimplePhoneInput, SimpleSelect } from '@orthly/ui';
import { Grid, Text } from '@orthly/ui-primitives';
import { PersonCardOccupationOptionsDisplay } from '@orthly/veneer';
import React from 'react';

interface SignUpAccountFullNameFieldProps {
    role?: LabsGqlStaffRolePractice;
}

export const SignUpAccountFullNameField: React.VFC<SignUpAccountFullNameFieldProps> = props => {
    const { role } = props;
    const session = useSession();

    return (
        <Grid item>
            <Text variant={'h6'} medium>
                Full name
            </Text>
            <Text variant={'body2'} style={{ marginTop: 8 }}>{`${
                role === LabsGqlStaffRolePractice.PracticeDoctor ? 'Dr. ' : ''
            } ${session?.user.first_name} ${session?.user.last_name}`}</Text>
        </Grid>
    );
};

export const SignUpAccountEmailAddressField: React.VFC = () => {
    const session = useSession();

    return (
        <Grid item>
            <Text variant={'h6'} medium>
                Email Address
            </Text>
            <Text variant={'body2'} style={{ marginTop: 8 }}>
                {session?.user.email}
            </Text>
        </Grid>
    );
};

interface SignUpAccountRoleFieldProps {
    role?: LabsGqlStaffRolePractice;
    setRole: (value?: LabsGqlStaffRolePractice) => void;
}

export const SignUpAccountRoleField: React.VFC<SignUpAccountRoleFieldProps> = props => {
    const { role, setRole } = props;
    return (
        <Grid item>
            <Text variant={'h6'} medium>
                Role
            </Text>
            <SimpleSelect
                label={''}
                placeholder={'Select your role'}
                value={role}
                options={[
                    ...Object.values(LabsGqlStaffRolePractice)
                        .filter(val => val !== LabsGqlStaffRolePractice.Admin)
                        .map(occupation => ({
                            value: occupation,
                            label: PersonCardOccupationOptionsDisplay[occupation],
                        })),
                ]}
                onChange={value => setRole(value as LabsGqlStaffRolePractice)}
            />
        </Grid>
    );
};

interface SignUpAccountPhoneNumberFieldProps {
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    error?: string;
    setError: (value?: string) => void;
}

export const SignUpAccountPhoneNumberField: React.VFC<SignUpAccountPhoneNumberFieldProps> = props => {
    const { error, phoneNumber, setPhoneNumber, setError } = props;

    const onChangeAction = (value?: string) => {
        setError(undefined);
        setPhoneNumber(value ?? '');
    };

    return (
        <Grid item>
            <Text variant={'h6'} medium>
                Mobile Phone Number
            </Text>
            <Text variant={'body2'} color={'GRAY'}>
                You’ll use your mobile phone number to log into the Dandy Portal.
            </Text>
            <SimplePhoneInput
                fullWidth
                onChange={onChangeAction}
                value={phoneNumber}
                label={''}
                placeholder={'(000) 000-0000'}
                TextFieldProps={{
                    helperText: error,
                    error: !!error,
                    InputProps: { style: { marginBottom: !!error ? 4 : 0 } },
                }}
                data-test={'sign-up-account-phone-number'}
            />
        </Grid>
    );
};

interface SignUpAccountPasswordFieldProps {
    password: string;
    setPassword: (value: string) => void;
}

export const SignUpAccountPasswordField: React.VFC<SignUpAccountPasswordFieldProps> = props => {
    const { password, setPassword } = props;

    return (
        <Grid item>
            <Text variant={'h6'} medium>
                Password
            </Text>
            <Text variant={'body2'} color={'GRAY'}>
                Enter a password so you can log in without your mobile phone
            </Text>
            <SimpleInput
                onChange={value => setPassword(value ?? '')}
                value={password}
                label={''}
                placeholder={'Must have at least 7 characters'}
                TextFieldProps={{ type: 'password', style: { width: '100%' } }}
                data-test={'sign-up-account-password'}
            />
        </Grid>
    );
};
