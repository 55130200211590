import type * as THREE from 'three';

export function isMesh(object: any): object is THREE.Mesh {
    return !!object?.isMesh;
}

export function isBufferGeometry(object: any): object is THREE.BufferGeometry {
    return !!object?.isBufferGeometry;
}

export function isVector3(object: any): object is THREE.Vector3 {
    return !!object?.isVector3;
}

export function isMeshWithBufferGeometry(object: any): object is THREE.Mesh<THREE.BufferGeometry> {
    return isMesh(object) && isBufferGeometry(object.geometry);
}

export function isArrowHelper(object: any): object is THREE.ArrowHelper {
    return object?.type === 'ArrowHelper';
}

export function isOrthographicCamera(object: any): object is THREE.OrthographicCamera {
    return !!object?.isOrthographicCamera;
}

export function isPerspectiveCamera(object: any): object is THREE.PerspectiveCamera {
    return !!object?.isPerspectiveCamera;
}

export function hasUniforms(mat: THREE.Material): mat is THREE.ShaderMaterial {
    return mat.type === 'ShaderMaterial';
}

export function isBufferAttribute(object: any): object is THREE.BufferAttribute {
    return !!object?.isBufferAttribute;
}
