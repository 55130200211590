import { useFeatureFlag } from '../../LaunchDarkly';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { OrderEditMode } from '@orthly/shared-types';
import { Icon } from '@orthly/ui';
import { Text, FlossPalette, stylesFactory, Button } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

interface NewOrderBannerProps {
    orderStatus: LabsGqlOrder['status'];
    orderActiveTaskType: string;
    orderEditMode: string;
}

const IN_DESIGN = [
    'ResolveHold',
    'ScanReview',
    'ResolveScanRejection',
    'DesignReview',
    'DesignReview2',
    'DesignPrep',
    'AutomateDesign',
    'ModelDesign',
    'ExternalDesignVerification',
];

const useStyles = stylesFactory(() => ({
    icon: {
        width: 16,
        height: 16,
        placeSelf: 'center',
        marginRight: 8,
    },
    primaryIcon: {
        color: FlossPalette.BLACK,
    },
    attentionIcon: {
        color: FlossPalette.ATTENTION,
    },
    bannerWrapper: {
        padding: '8px 16px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    primaryBannerWrapper: {
        background: FlossPalette.PRIMARY_BACKGROUND,
    },
    attentionBannerWrapper: {
        background: FlossPalette.ATTENTION_BACKGROUND,
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
    },
    bannerElement: {
        display: 'flex',
    },
    button: {
        height: 'unset',
    },
}));

const subTitleText = (orderStatus: string, orderActiveTaskType: string) => {
    if (orderStatus === 'Fabrication') {
        return 'Your order is already in fabrication.';
    }
    if (IN_DESIGN.includes(orderActiveTaskType)) {
        return 'Your order is already in design.';
    }
    return '';
};

export const NewOrderBanner: React.VFC<NewOrderBannerProps> = props => {
    const { orderStatus, orderActiveTaskType, orderEditMode } = props;
    const classes = useStyles();
    const optionalText = subTitleText(orderStatus, orderActiveTaskType);
    const enableNuancedCr = !!useFeatureFlag('enableNuancedCr').value;
    const isCancelResubmit = orderEditMode === OrderEditMode.CancelAndResubmit;

    if (!enableNuancedCr || !isCancelResubmit) {
        return null;
    }

    return (
        <div className={clsx(classes.bannerWrapper, classes.attentionBannerWrapper)}>
            <Icon icon={'ExclamationIcon'} className={clsx(classes.icon, classes.attentionIcon)} />
            <Text variant={'body2'} medium color={'ATTENTION_FOREGROUND'}>
                {`${optionalText} The changes you are submitting above will be processed as a new order and your original order will be canceled.`}
            </Text>
        </div>
    );
};

export const TryAttachScansOrderBanner: React.VFC<{ onClick: () => void }> = ({ onClick }) => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.bannerWrapper, classes.primaryBannerWrapper)}>
            <div className={classes.bannerElement}>
                <Icon icon={'ExclamationIcon'} className={clsx(classes.icon, classes.primaryIcon)} />
                <Text variant={'body2'} medium color={'BLACK'}>
                    Uploading scans? Try out our new ‘Attach existing scans’ feature where you can Preview & select from
                    all your previously captured Chairside scans from one place.
                </Text>
            </div>
            <div className={classes.bannerElement}>
                <Button variant={'ghost'} className={classes.button} endIcon={'ChevronRight'} onClick={onClick}>
                    Select from Existing Chairside Scans
                </Button>
            </div>
        </div>
    );
};
