import { AnalyticsClient } from '../../../../../../analytics/analyticsClient';
import { CalendlyModal } from '../../../../../../components/Calendly';
import type { ConciergeConfigInterface } from './CheckoutSidebarConcierge.util';
import { CheckoutSidebarConciergeCTA } from './CheckoutSidebarConciergeCTA';
import type { CtaAnalyticsEventData } from '@orthly/analytics/dist/browser';
import { useAnalyticsTrackCtaShown } from '@orthly/analytics/dist/browser';
import type { LabsGqlCreatePracticeRequestScheduleTrainingMutationVariables } from '@orthly/graphql-operations';
import { useCreatePracticeRequestScheduleTrainingMutation } from '@orthly/graphql-react';
import { LabsGqlPracticeRequestType } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

type Vars = LabsGqlCreatePracticeRequestScheduleTrainingMutationVariables['data'];

const getAnalyticsTrainingData = (config: ConciergeConfigInterface): CtaAnalyticsEventData => {
    return {
        AssetCTAText: config.training?.ctaButtonText ?? 'Unknown',
        AssetLocation: config.assetLocation,
        AssetVersion: 'v1.0',
        AssetType: 'button',
        AssetName: 'Schedule Training',
    };
};

interface ConciergeScheduleTrainingCTAProps {
    config: ConciergeConfigInterface;
    partnerId: string;
    trainingScheduled: boolean;
    refetch: () => void;
    queryLoading: boolean;
}

export const ConciergeScheduleTrainingCTA: React.FC<ConciergeScheduleTrainingCTAProps> = props => {
    const { config, partnerId, trainingScheduled, refetch, queryLoading } = props;
    const [isCalendlyModalOpen, setisCalendlyModalOpen] = React.useState(false);
    const analyticsTrainingData = React.useMemo(() => getAnalyticsTrainingData(config), [config]);
    useAnalyticsTrackCtaShown(analyticsTrainingData, !trainingScheduled);
    const onScheduleClicked = React.useCallback(() => {
        if (config.training) {
            setisCalendlyModalOpen(true);
            AnalyticsClient.track('Button Clicked', analyticsTrainingData);
        }
    }, [analyticsTrainingData, config]);
    const [submitMtn] = useCreatePracticeRequestScheduleTrainingMutation();
    const mtnSubmitter = (data: Vars) => submitMtn({ variables: { data } });
    const { submit, submitting } = useChangeSubmissionFn<any, [Vars]>(mtnSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Training scheduled', {}],
        onSuccess: () => {
            refetch();
        },
    });

    if (!config.training) {
        return null;
    }

    return (
        <>
            <CheckoutSidebarConciergeCTA
                text={config.training.ctaLineText}
                onClick={onScheduleClicked}
                buttonText={config.training.ctaButtonText}
                disabled={trainingScheduled}
                disabledText={config.training.ctaDisabledText}
                blocking={queryLoading || submitting}
            />
            {isCalendlyModalOpen && (
                <CalendlyModal
                    url={config.training.calendlyUrl}
                    onModalClose={() => {
                        setisCalendlyModalOpen(false);
                    }}
                    onEventTypeViewed={() =>
                        AnalyticsClient.track(`Practice - Checkout - Schedule Training Opened`, {
                            sku: config.sku,
                        })
                    }
                    onEventScheduled={event => {
                        AnalyticsClient.track(`Practice - Checkout - Training Scheduled`, {
                            sku: config.sku,
                        });
                        void submit({
                            sku: config.sku,
                            organization_id: partnerId,
                            data: {
                                training_uri: event.data.payload.event.uri,
                                type: LabsGqlPracticeRequestType.ScheduleTraining,
                            },
                        });
                    }}
                />
            )}
        </>
    );
};
