import { useGetFirebaseTokenQuery } from '@orthly/graphql-react';
import type { OrthlyEnvironment } from '@orthly/shared-types';
import type { FirebaseConfig, OrthlyFrontendApps } from '@orthly/ui';
import { OrthlyBrowserConfig } from '@orthly/ui';
import { Button } from '@orthly/ui-primitives';
import {
    initializeAuth,
    onIdTokenChanged,
    signInWithCustomToken,
    signInAnonymously,
    indexedDBLocalPersistence,
    browserLocalPersistence,
} from 'firebase/auth';
import Firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/messaging';
import 'firebase/compat/storage';
import { useSnackbar } from 'notistack';
import React from 'react';
import invariant from 'tiny-invariant';
import warning from 'tiny-warning';

/**
 * By default, firebase uses indexedDb for persistence.
 * Playwright does not support indexedDb, so we use session storage for local dev instead
 * This prevents us from maxing out our Firebase "Signup" API limit during playwright tests.
 */
const FIREBASE_PERSISTENCE_TYPE = Boolean(process.env.FIREBASE_PREFER_SESSION_STORAGE)
    ? [browserLocalPersistence]
    : [indexedDBLocalPersistence, browserLocalPersistence];

const FirebaseContext = React.createContext<Firebase.app.App | null>(null);

export function getFirebaseContextForTests() {
    // Shouldn't user raw FirebaseContext outside of tests - please use FirebaseProvider
    return FirebaseContext;
}

export type FirebaseProviderProps = {
    appName: OrthlyFrontendApps;
    envOverride?: OrthlyEnvironment;
    firebaseToken?: string;
    firebaseTokenRefreshFunction?: () => void;
};

export function firebaseMessagingEnabled(): boolean {
    return Firebase.messaging.isSupported();
}

const deleteWrongConfigApps = async (projectId: string) => {
    await Promise.all(
        Firebase.apps.map(async a => {
            if ((a.options as FirebaseConfig).projectId !== projectId) {
                await a.delete();
            }
        }),
    );
};

const FAILED_TO_CONNECT =
    "Oops! We're having trouble connecting. Please refresh the page or reach out to our support team for help.";

export const FirebaseProvider: React.FC<FirebaseProviderProps> = ({
    appName,
    envOverride,
    firebaseToken,
    firebaseTokenRefreshFunction,
    children,
}) => {
    // Cypress component tests sometimes throw an error when importing useSnackbar from notistack
    const { enqueueSnackbar } = useSnackbar() ?? { enqueueSnackbar: () => {} };

    // Only update config on appName or envOverride changes
    const config = React.useMemo(() => {
        return new OrthlyBrowserConfig(appName, envOverride);
    }, [appName, envOverride]);
    const firebaseApp = React.useMemo(() => {
        const failedToGetFirebaseToken = () => {
            enqueueSnackbar(FAILED_TO_CONNECT, {
                variant: 'error',
                key: 'firebase-error',
                persist: true,
                preventDuplicate: true,
                action: () => (
                    <Button endIcon={'Refresh'} variant={'secondary-gray'} onClick={() => window.location.reload()}>
                        Refresh Page
                    </Button>
                ),
            });
        };
        const diffConfigApps = Firebase.apps.filter(
            a => (a.options as FirebaseConfig).projectId !== config.firebase.projectId,
        );
        warning(
            diffConfigApps.length === 0,
            'Multiple firebase instances found with differing project IDs, deleting existing apps',
        );
        if (diffConfigApps.length > 0) {
            void deleteWrongConfigApps(config.firebase.projectId);
        }
        const correctApp = Firebase.apps.find(
            a => (a.options as FirebaseConfig).projectId === config.firebase.projectId,
        );
        if (correctApp) {
            return correctApp;
        }
        const firebaseAppLocal = Firebase.initializeApp(config.firebase.config, config.firebase.projectId);
        if (firebaseMessagingEnabled()) {
            firebaseAppLocal.messaging();
        }
        const auth = initializeAuth(firebaseAppLocal, {
            persistence: FIREBASE_PERSISTENCE_TYPE,
        });
        if (firebaseToken) {
            signInWithCustomToken(auth, firebaseToken).catch(error => {
                failedToGetFirebaseToken();
                throw new Error(`Firebase authentication error: ${error}`);
            });
        } else {
            signInAnonymously(auth).catch(error => {
                failedToGetFirebaseToken();
                throw new Error(`Firebase authentication error: ${error}`);
            });
        }

        onIdTokenChanged(auth, user => {
            if (!user) {
                // User is signed out, re-authenticate
                if (firebaseTokenRefreshFunction) {
                    firebaseTokenRefreshFunction();
                } else {
                    signInAnonymously(auth).catch(error => {
                        failedToGetFirebaseToken();
                        throw new Error(`Firebase authentication error: ${error}`);
                    });
                }
            }
        });
        return firebaseAppLocal;
    }, [
        config.firebase.config,
        config.firebase.projectId,
        enqueueSnackbar,
        firebaseToken,
        firebaseTokenRefreshFunction,
    ]);

    return <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>;
};

export type ConnectedFirebaseProviderProps = {
    appName: OrthlyFrontendApps;
    envOverride?: OrthlyEnvironment;
};

export const ConnectedFirebaseProvider: React.FC<ConnectedFirebaseProviderProps> = ({
    appName,
    envOverride,
    children,
}) => {
    const { data: firebaseToken, refetch: refreshFirebaseToken } = useGetFirebaseTokenQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    return (
        <FirebaseProvider
            appName={appName}
            envOverride={envOverride}
            firebaseToken={firebaseToken?.getFirebaseToken}
            firebaseTokenRefreshFunction={() => refreshFirebaseToken()}
        >
            {children}
        </FirebaseProvider>
    );
};

export const useFirebase = (): Firebase.app.App => {
    const context = React.useContext(FirebaseContext);
    // throws if context doesnt exist, so type coercion in return below is fine
    invariant(context, 'useFirebase() called outside of FirebaseProvider');
    return context as Firebase.app.App;
};

export type Firestore = Firebase.firestore.Firestore;

export const useFirestore = (): Firestore => {
    const firebase = useFirebase();
    return firebase.firestore();
};

export const useFirebaseMessaging = () => {
    const firebaseInstance = useFirebase();
    if (firebaseMessagingEnabled()) {
        return firebaseInstance.messaging();
    }
};

export const useFirebaseStorage = (bucketName?: string) => {
    const firebase = useFirebase();
    return firebase.storage(bucketName);
};
