import type { OrderTimelineV2Item } from '../../PracticeOrderChatV2';
import { OrderTimelineLightboxProvider } from './OrderTimelineLightbox';
import { OrderTimelineOrderEvents } from './components/OrderTimelineOrderEvents';
import type { LabsGqlOrder, LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlOrderTimelineV2Attachment } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { ArrowUpIcon, Button, Text, FlossPalette, styled, stylesFactory } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    timelineContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        gap: 32,
        padding: 16,
        backgroundColor: FlossPalette.WHITE,
        height: '100%',
        overflowY: 'auto',
        overflowX: 'hidden',
    },
}));

const ArrowUpIconStyled = styled(ArrowUpIcon)({
    marginLeft: '8px',
});

const useScrollToBottom = (events: unknown) => {
    const scrollContainerRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTop = scrollContainerRef.current.scrollHeight;
        }
    }, [events, scrollContainerRef]);

    return scrollContainerRef;
};

export const OrderTimelineV2: React.FC<{
    events: OrderTimelineV2Item[];
    isLoadingItems: boolean;
    order: LabsGqlSingleLabOrderFragment;
    showScrollToTop?: boolean;
}> = ({ events, isLoadingItems, order, showScrollToTop = false }) => {
    const classes = useStyles();
    const scrollContainerRef = useScrollToBottom(events);
    const [scrollToTopNeeded, setScrollToTopNeeded] = React.useState(false);

    const eventsByOrder = _.groupBy(events, e => e.order_id);
    const ordersByID = Object.fromEntries((order.cancelled_orders || []).map((o: LabsGqlOrder) => [o.id, o]));

    const attachments = React.useMemo(() => {
        const collection: LabsGqlOrderTimelineV2Attachment[] = [];

        for (const event of events) {
            if (Array.isArray(event.attachments)) {
                collection.push(...event.attachments);
            }
        }

        return collection;
    }, [events]);

    React.useEffect(() => {
        if (scrollContainerRef.current) {
            const contentHeight = scrollContainerRef.current.scrollHeight;
            const containerHeight = scrollContainerRef.current.clientHeight;
            setScrollToTopNeeded(contentHeight > containerHeight * 4);
        }
    }, [events, scrollContainerRef]);

    const handleScrollToTop = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    return (
        <OrderTimelineLightboxProvider attachments={attachments} orderId={order.id}>
            <LoadBlocker
                blocking={isLoadingItems}
                ContainerProps={{ className: classes.timelineContainer, ref: scrollContainerRef }}
            >
                {Object.entries(eventsByOrder).map(([orderID, orderEvents], index, arr) => (
                    <OrderTimelineOrderEvents
                        key={orderID}
                        events={orderEvents}
                        order={ordersByID[orderID] || order}
                        isLastOrder={index === arr.length - 1}
                    />
                ))}
                {showScrollToTop && scrollToTopNeeded && (
                    <Button fullWidth variant={'secondary'} onClick={handleScrollToTop}>
                        Back to top <ArrowUpIconStyled />
                    </Button>
                )}
            </LoadBlocker>
        </OrderTimelineLightboxProvider>
    );
};
