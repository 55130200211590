import { PracticeOrderChatV2 } from '../components/PracticeOrderChatV2/PracticeOrderChatV2';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    container: {
        height: 'calc(100vh - 56px)',
        width: '100%',
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        [theme.breakpoints.down('lg')]: {
            height: '100%',
        },
    },
}));

interface OrderDetailChatProps {
    order?: LabsGqlSingleLabOrderFragment;
    mobileView?: boolean;
    refetchMessages: () => Promise<any>;
}

export const OrderDetailChat: React.FC<OrderDetailChatProps> = ({ mobileView, order, refetchMessages }) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <PracticeOrderChatV2 order={order} refetchMessages={refetchMessages} showToolbar={!mobileView} />
        </div>
    );
};
