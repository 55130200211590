import * as THREE from 'three';

/**
 * These utilities were copied from the latest version of three.js. They do not exist as methods of the Sphere class on
 * our version of three.js.
 */

// Working variables
const _v1 = new THREE.Vector3();
const _v2 = new THREE.Vector3();

/**
 * Expands a sphere by a point
 * Adapted from: https://github.com/mrdoob/three.js/blob/r162/src/math/Sphere.js#L163
 * @param sphere The sphere to expand, modified in-place
 * @param point The point by which to expand the sphere
 * @returns A reference to `sphere`
 */
export function expandSphereByPoint(sphere: THREE.Sphere, point: THREE.Vector3): THREE.Sphere {
    if (sphere.isEmpty()) {
        sphere.center.copy(point);
        sphere.radius = 0;
        return sphere;
    }

    _v1.subVectors(point, sphere.center);

    const lengthSq = _v1.lengthSq();
    if (lengthSq > sphere.radius * sphere.radius) {
        // calculate the minimal sphere
        const length = Math.sqrt(lengthSq);
        const delta = (length - sphere.radius) * 0.5;

        sphere.center.addScaledVector(_v1, delta / length);
        sphere.radius += delta;
    }

    return sphere;
}

/**
 * Unions two spheres
 * Adapted from: https://github.com/mrdoob/three.js/blob/r162/src/math/Sphere.js#L197
 * @param expandingSphere The sphere to expand, modified in-place
 * @param addSphere The sphere by which to expand `expandingSphere`
 * @returns A reference to `expandingSphere`
 */
export function unionSpheres(expandingSphere: THREE.Sphere, addSphere: THREE.Sphere | null | undefined): THREE.Sphere {
    if (!addSphere || addSphere.isEmpty()) {
        return expandingSphere;
    }

    if (expandingSphere.isEmpty()) {
        return expandingSphere.copy(addSphere);
    }

    if (expandingSphere.center.equals(addSphere.center)) {
        expandingSphere.radius = Math.max(expandingSphere.radius, addSphere.radius);
        return expandingSphere;
    }

    _v2.subVectors(addSphere.center, expandingSphere.center).setLength(addSphere.radius);

    expandSphereByPoint(expandingSphere, _v1.copy(addSphere.center).add(_v2));
    expandSphereByPoint(expandingSphere, _v1.copy(addSphere.center).sub(_v2));

    return expandingSphere;
}
