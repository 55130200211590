/**
 * This shader extends `DandyMeshPhongShader` and displays the occlusal distance heatmap
 */
import { MODEL_GRAY_COLOR } from './Colors';
import { vertexShader as baseVertexShader, fragmentShader as baseFragmentShader } from './DandyMeshPhongShader';
import _ from 'lodash';
import * as THREE from 'three';

let _vertexShader = baseVertexShader;

const define_distance_attribute = `
attribute float occlusion_layer;
#include <common>
`;
_vertexShader = _vertexShader.replace('#include <common>', define_distance_attribute);

const color_pars_vertex = `
#include <color_pars_vertex>

varying float vOcclusionLayer;
`;
_vertexShader = _vertexShader.replace('#include <color_pars_vertex>', color_pars_vertex);

const color_vertex = `
vOcclusionLayer = 1.0;

#include <color_vertex>

vOcclusionLayer *= occlusion_layer;
`;
_vertexShader = _vertexShader.replace('#include <color_vertex>', color_vertex);

export const vertexShader = _vertexShader;

let _fragmentShader = baseFragmentShader;

// add our color maps functions to the standard colors fragment
const color_pars_fragment = `
uniform float vMin;
uniform float vMax;
uniform bool showHeatmap;

#include <color_pars_fragment>

varying float vOcclusionLayer;

// define our color maps functions
vec3 occlusalColorMap(float v, float vMin, float vMax, vec3 DEFAULT_MODEL_RGB_U8_COLOR)
{
    if(v > vMax || v < -100.0){ //out of range
        return DEFAULT_MODEL_RGB_U8_COLOR;
    }

    float R = 0.0;
    float G = 0.0;
    float B = 0.0;
    if (v < vMin) {
        R = 0.0;
        G = 0.0;
        B = 1.0;
    }else{
        float ratio = min(1.0, (v - vMin) / (vMax - vMin));

        R = min(1.0, pow(2.0 * (1.0 - ratio), 0.4));
        G = min(1.0, pow(2.0 * ratio, 0.4));
        B = 0.0;
    }

    vec3 result = vec3(R, G, B);
    return result;
}
`;
_fragmentShader = _fragmentShader.replace('#include <color_pars_fragment>', color_pars_fragment);

const color_fragment = `
#include <color_fragment>

vec3 DEFAULT_MODEL_RGB_U8_COLOR = vec3(1.0, 1.0, 1.0); 
vec3 multiplyColor = DEFAULT_MODEL_RGB_U8_COLOR;

if(showHeatmap){
    multiplyColor = occlusalColorMap(vOcclusionLayer, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR);
    diffuseColor.rgb *= multiplyColor;
}
`;
_fragmentShader = _fragmentShader.replace('#include <color_fragment>', color_fragment);

export const fragmentShader = _fragmentShader;

export type OcclusalNoColorHeatmapShaderParams = {
    showHeatmap: boolean;
    heatMapRange: { min: number; max: number };
    opacity: number;
    color: THREE.Color;
};

export const DEFAULT_OCCLUSAL_NOCOLOR_SHADER_PARAMS: OcclusalNoColorHeatmapShaderParams = {
    showHeatmap: true,
    heatMapRange: { min: -0.1, max: 0.4 },
    opacity: 1.0,
    color: MODEL_GRAY_COLOR,
};

export interface OcclusalNoColorHeatmapShader {
    vertexShader: string;
    fragmentShader: string;
    uniforms: { [Property in keyof OcclusalNoColorHeatmapShaderParams]: THREE.Uniform };
    lights: true;
    flatShading: false;
}

const STANDARD_MESH_SHININESS = 70;

export function createOcclusalNoColorHeatmapShader(
    params: Partial<OcclusalNoColorHeatmapShaderParams> = {},
): OcclusalNoColorHeatmapShader {
    // Properties with a value of `undefined` are still included when spreading, so we must filter them out or else they
    // would supersede the values of the default parameters values object.
    const { showHeatmap, heatMapRange, opacity, color } = {
        ...DEFAULT_OCCLUSAL_NOCOLOR_SHADER_PARAMS,
        ..._.pickBy(params, _.negate(_.isUndefined)),
    };

    const uniforms = THREE.UniformsUtils.merge([
        THREE.ShaderLib.phong.uniforms,
        {
            diffuse: { value: color },
            vertexColors: { value: false },
            transparent: { value: opacity < 1.0 },
            opacity: { value: opacity },
            wireframe: { value: false },
            specular: { value: new THREE.Color(0x111111) },
            shininess: { value: STANDARD_MESH_SHININESS },
            side: { value: THREE.DoubleSide },
            showHeatmap: { value: showHeatmap },
            vMin: { value: heatMapRange.min },
            vMax: { value: heatMapRange.max },
        },
    ]);

    return {
        vertexShader,
        fragmentShader,
        uniforms,
        lights: true,
        flatShading: false,
    };
}
