import { useOpenOrderDetailPage } from '../../../labs/LabsUtils';
import { useInvoiceDetailContext } from '../../components/providers/InvoiceDetailProvider.graphql';
import type { PracticeInvoiceItem } from '../../invoicing.types';
import { LabsGqlInvoiceItemCategory } from '@orthly/graphql-schema';
import type { Column } from '@orthly/mui-table';
import { useSession } from '@orthly/session-client';
import { Table } from '@orthly/ui';
import type { TextColor } from '@orthly/ui-primitives';
import {
    ArrowSouthIcon,
    Button,
    FlossPalette,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    Text,
    styled,
} from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

export type InvoiceItemRow = PracticeInvoiceItem & {
    doctor_name?: string;
    mailing_address_id?: string;
    effective_date: Date;
    display_amounts: string[];
    descriptions: string[];
};

const StyledTableHeadRow = styled(TableRow)({
    height: '56px',
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
});

interface MultiLineCellProps {
    lines: string[];
    boldFirstLine?: boolean;
    isCredit: boolean;
}

const MultiLineCell: React.FC<MultiLineCellProps> = ({ lines, isCredit, boldFirstLine = false }) => {
    const getTextColor = (idx: number, isCredit: boolean): TextColor => {
        const primaryColor = isCredit ? 'PRIMARY_FOREGROUND' : 'BLACK';
        return idx === 0 ? primaryColor : 'GRAY';
    };
    return (
        <div>
            {lines.map((desc, idx) => (
                <Text
                    variant={'body2'}
                    key={idx}
                    color={getTextColor(idx, isCredit)}
                    medium={boldFirstLine && idx === 0}
                >
                    {desc}
                    <br />
                </Text>
            ))}
        </div>
    );
};

export const InvoiceTransactionsTable: React.FC = () => {
    const { value: enableCreditAndRefundOverhaul = false } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const { rows, invoice } = useInvoiceDetailContext();
    const organizationId = useSession()?.organization_id;
    const openOrder = useOpenOrderDetailPage();

    // for multi-location contracts, the contract primary has access to viewing invoices
    // for other contract locations, but not the orders. don't show the link to the order
    // in that case
    const viewOrderColumn: Column<InvoiceItemRow>[] =
        invoice.organization_id === organizationId
            ? [
                  {
                      name: 'View Order',
                      title: ' ',
                      render: row => {
                          if (!row.order_id || row.recurring_item_id) {
                              return null;
                          }
                          return (
                              <Button
                                  size={'small'}
                                  variant={'ghost'}
                                  style={{ paddingTop: 0, paddingBottom: 0 }}
                                  onClick={() => openOrder(row.order_id ?? '')}
                              >
                                  Open order
                              </Button>
                          );
                      },
                  },
              ]
            : [];

    const columns: Column<InvoiceItemRow>[] = [
        {
            name: 'Date',
            sort: true,
            customSort: (a, b, direction) => {
                const aDate = dayjs(a.effective_date);
                const bDate = dayjs(b.effective_date);
                if (direction === 'asc') {
                    return aDate.valueOf() - bDate.valueOf();
                }
                return bDate.valueOf() - aDate.valueOf();
            },
            defaultSort: 'desc',
            field: 'effective_date',
            type: 'date',
            render: r => dayjs(r.effective_date).format('MM/DD/YYYY'),
        },
        {
            name: 'Order',
            render: row => (
                <MultiLineCell
                    lines={row.descriptions}
                    isCredit={enableCreditAndRefundOverhaul && !!row.used_credit_id}
                    boldFirstLine={true}
                />
            ),
        },
        {
            name: 'Doctor',
            field: 'doctor_name',
            render: row =>
                row.category === LabsGqlInvoiceItemCategory.OtherCharge && !!row.recurring_item_id
                    ? 'Dandy Internal'
                    : row.doctor_name,
        },
        ...viewOrderColumn,
        {
            name: 'Amount',
            field: 'amount_cents',
            render: row => (
                <MultiLineCell
                    lines={row.display_amounts}
                    isCredit={enableCreditAndRefundOverhaul && !!row.used_credit_id}
                    boldFirstLine={true}
                />
            ),
        },
    ];

    const [sortDirection, setSortDirection] = React.useState<'asc' | 'desc'>('desc');

    const toggleSortDirection = () => {
        setSortDirection(curr => (curr === 'asc' ? 'desc' : 'asc'));
    };

    const sortedRows = _.orderBy(rows, ['effective_date'], [sortDirection]);

    return (
        <div>
            <Text variant={'h5'} style={{ marginBottom: '24px', marginLeft: '32px' }}>
                Transactions
            </Text>

            <Table>
                <TableHead style={{ backgroundColor: FlossPalette.TAN }}>
                    <StyledTableHeadRow>
                        {columns.map(column => (
                            <TableCell
                                key={column.name}
                                variant={'head'}
                                style={{
                                    color: FlossPalette.GRAY,
                                    fontWeight: 400,
                                    width: column.width ?? 'auto',
                                    minWidth: column.width ?? '120px',
                                }}
                            >
                                {column.title ?? column.name}
                                {column.sort && (
                                    <TableSortLabel
                                        active={true}
                                        direction={sortDirection}
                                        IconComponent={ArrowSouthIcon}
                                        onClick={toggleSortDirection}
                                    />
                                )}
                            </TableCell>
                        ))}
                    </StyledTableHeadRow>
                </TableHead>
                <TableBody>
                    {sortedRows.map(row => (
                        <TableRow key={row.id}>
                            {columns.map(column => {
                                if (typeof column.render === 'function') {
                                    return <TableCell key={`${column.name}-render`}>{column.render(row)}</TableCell>;
                                }
                            })}
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
