import { FlossPalette, useScreenIsMobile, Button } from '@orthly/ui-primitives';
import React from 'react';

interface FullPageFormBackButtonProps {
    onBackAction: () => void;
}

// TODO: Create a story for this.
export const FullPageFormBackButton: React.VFC<FullPageFormBackButtonProps> = props => {
    const { onBackAction } = props;
    const isMobile = useScreenIsMobile();

    return (
        <Button
            variant={'text'}
            style={{
                color: FlossPalette.STAR_GRASS,
                background: 'none',
                fontWeight: 600,
                marginLeft: isMobile ? -12 : -40,
            }}
            startIcon={'ArrowBackIos'}
            onClick={onBackAction}
        >
            Back
        </Button>
    );
};
