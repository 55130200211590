import { type ToggleActionType } from './QcToolbarTrackToogle.hooks';
import { HeatMapType } from '@orthly/forceps';

export const HeatMapTypeToActionTypeMap: { [K in HeatMapType]: ToggleActionType } = {
    [HeatMapType.Clearance]: 'heatmap_clearance',
    [HeatMapType.Occlusal]: 'heatmap_occlusal',
    [HeatMapType.Thickness]: 'heatmap_thickness',
    [HeatMapType.Proximal]: 'heatmap_proximal',
    [HeatMapType.SurfaceDisplacement]: 'heatmap_surface_displacement',
    [HeatMapType.VertexDisplacement]: 'heatmap_vertex_displacement',
    [HeatMapType.AlignmentDistance]: 'heatmap_alignment_distance',
    [HeatMapType.TissuePressure]: `heatmap_tissue_pressure`,
    [HeatMapType.Undercut]: 'heatmap_undercut',
    [HeatMapType.CementGap]: 'heatmap_cement_gap',
};

export const HeatMapTypeToLabelMap: { [K in HeatMapType]: string } = {
    [HeatMapType.Clearance]: 'Clearance',
    [HeatMapType.Occlusal]: 'Occlusal',
    [HeatMapType.Thickness]: 'Thickness',
    [HeatMapType.Proximal]: 'Proximal',
    [HeatMapType.SurfaceDisplacement]: 'Surface displacement',
    [HeatMapType.VertexDisplacement]: 'Vertex displacement',
    [HeatMapType.AlignmentDistance]: 'Alignment distance',
    [HeatMapType.TissuePressure]: `Tissue pressure`,
    [HeatMapType.Undercut]: 'Undercut',
    [HeatMapType.CementGap]: 'Cement gap',
};
