import * as zip from '../ZipFormat';
import { Vec } from './Vec';
import { HmacSHA1, PBKDF2, algo, lib, mode, pad } from 'crypto-js';

// Converts from BE to LE and vice versa for Uint32Array.
//
function bswapArr(data: Uint32Array) {
    for (let i = 0; i < data.length; i++) {
        const value = data[i] ?? 0;
        data[i] =
            ((value & 0xff) << 24) |
            ((value & 0xff00) << 8) |
            ((value & 0xff0000) >> 8) |
            ((value & 0xff000000) >>> 24);
    }
}

// Converts from SJCL BitArray to Vec.
//
function words2vec(words: lib.WordArray): Vec {
    const result = new Vec(words.sigBytes);
    const source = new Uint32Array(words.words);
    bswapArr(source);
    result.copyFrom(new Uint8Array(source.buffer, source.byteOffset, words.sigBytes));
    return result;
}

// Converts from Vec to SJCL BitArray.
//
function vec2words(vec: Vec): lib.WordArray {
    const result = new Uint32Array((vec.length + 3) / 4);
    vec.copyInto(result);
    bswapArr(result);
    return lib.WordArray.create([...result], vec.length);
}

// WinZip AES implementation.
//
export class WzAES {
    readonly key: string | Vec;
    constructor(
        secret: Uint8Array | string,
        public keyLength: number = 256 / 8,
    ) {
        if (typeof secret === 'string') {
            this.key = secret;
        } else {
            this.key = new Vec(secret);
        }
    }

    // Key derivation utility.
    //
    deriveKey(salt: Vec, keyLength: number, macLength: number = keyLength, verifyLength: number = 2) {
        const key = typeof this.key === 'string' ? this.key : vec2words(this.key);
        const derivedKey = words2vec(
            PBKDF2(key, vec2words(salt), {
                keySize: (macLength + keyLength + verifyLength + 3) / 4,
                iterations: 1000,
                hasher: algo.SHA1,
            }),
        );
        const aesKey = derivedKey.slice(0, keyLength);
        const macKey = derivedKey.slice(keyLength, keyLength + macLength);
        const verifyKey = derivedKey.slice(keyLength + macLength, keyLength + macLength + verifyLength);
        return { aesKey, macKey, verifyKey };
    }

    // Encrypt a block of data
    //
    encrypt(data: Vec, keyLength: number): Vec {
        const saltLength = keyLength / 2;
        const macLength = keyLength;
        const verifyLength = 2;

        const salt = Vec.random(saltLength);
        const { aesKey, macKey, verifyKey } = this.deriveKey(salt, keyLength, macLength, verifyLength);

        const encryptor = algo.AES.createEncryptor(vec2words(aesKey), {
            iv: lib.WordArray.create([0, 0, 0, 0]),
            mode: mode.CTRGladman,
            padding: pad.NoPadding,
        });
        const encryptedValue = words2vec(encryptor.finalize(vec2words(data)));

        const macVerifier = HmacSHA1(vec2words(encryptedValue), vec2words(macKey));
        macVerifier.sigBytes = Math.min(10, macVerifier.sigBytes);

        let output = salt;
        output = output.append(verifyKey);
        output = output.append(encryptedValue);
        output = output.append(words2vec(macVerifier));
        return output;
    }

    // Decrypt a block of data
    //
    decrypt(data: Vec, keyLength: number, ae2: boolean): Vec {
        const saltLength = keyLength / 2;
        const macLength = keyLength;
        const verifyLength = 2;
        const salt = data.slice(0, saltLength);
        const { aesKey, macKey, verifyKey } = this.deriveKey(salt, keyLength, macLength, verifyLength);

        const passVerifyValue = data.slice(saltLength, saltLength + verifyLength);
        if (!verifyKey.equals(passVerifyValue)) {
            throw new Error(`Invalid password: ${passVerifyValue} !== ${verifyKey}`);
        }

        const macPos = data.length - 10;
        const encryptedValue = data.slice(saltLength + verifyLength, macPos);
        const macValue = data.slice(macPos);

        if (ae2) {
            const macVerifier = HmacSHA1(vec2words(encryptedValue), vec2words(macKey));
            macVerifier.sigBytes = Math.min(10, macVerifier.sigBytes);
            const macVerifierVec = words2vec(macVerifier);
            if (!macValue.equals(macVerifierVec)) {
                throw new Error(`Corrupted zip, MAC failed: ${macValue} !== ${macVerifierVec}`);
            }
        }

        const decryptor = algo.AES.createDecryptor(vec2words(aesKey), {
            iv: lib.WordArray.create([0, 0, 0, 0]),
            mode: mode.CTRGladman,
            padding: pad.NoPadding,
        });
        return words2vec(decryptor.finalize(vec2words(encryptedValue)));
    }

    // Handles full decryption workflow.
    //
    decryptRecord(data: Vec, _crc: number, rec: zip.FileRecord) {
        const efDec = new Vec(rec.extraField).shift(zip.ExtraField);
        if (efDec.headerId !== zip.ExtensionId.WzAES) {
            throw new Error('Invalid encryption header.');
        }
        const mode = new Vec(efDec.data).shift(zip.WzAESMode);
        const keyLen = 8 + mode.strength * 8;
        const result = this.decrypt(data, keyLen, mode.version === 2);
        rec.compression = mode.compression;
        return result;
    }

    // Handles full encryption workflow.
    //
    encryptRecord(data: Vec, _crc: number, rec: zip.FileRecord) {
        const result = this.encrypt(data, 256 / 8);
        const mode = new zip.WzAESMode();
        mode.version = 2;
        mode.vendor = 17729; // 'AE'
        mode.strength = 3;
        mode.compression = rec.compression;

        const modeEnc = new Vec();
        modeEnc.push(zip.WzAESMode, mode);

        const extraField = new zip.ExtraField();
        extraField.data = modeEnc.raw;
        extraField.headerId = zip.ExtensionId.WzAES;

        const efEnc = new Vec();
        efEnc.push(zip.ExtraField, extraField);
        rec.extraField = efEnc.raw;
        return result;
    }
}
