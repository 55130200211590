import { useFrame } from '@react-three/fiber';
import React from 'react';
import type { Group } from 'three';
import { Vector3 } from 'three';

type HTMLOverlayAnchorProps = { position?: Vector3; children?: React.ReactNode; [x: string]: any };
export type HTMLOverlayAnchorRef = React.RefObject<Group | undefined>;

/**
 * Component renders empty group, to track it's location.
 * Fires *update* event on it's own ref object
 *
 * Useful together with @see {@link HTMLOverlaySync}
 *
 * @component
 * @example
 * const myRef = React.useRef();
 *
 * React.useEffect(() => {
 *   myRef.current.addEventListener('update', () => {
 *     console.log('Anchor position changed');
 *   });
 * }, [myRef]);
 *
 * return (<HTMLOverlayAnchor ref={myRef}/>);
 *
 */
export const HTMLOverlayAnchor = React.forwardRef<Group, HTMLOverlayAnchorProps>(
    ({ position, children, ...props }, fwGroupRef) => {
        const positionRef = React.useRef(new Vector3());

        React.useEffect(() => {
            const anchor = (fwGroupRef as HTMLOverlayAnchorRef).current;
            if (anchor && position) {
                anchor.position.copy(position);
                anchor.dispatchEvent({ type: 'update' });
            }
        }, [position, fwGroupRef]);

        // It's possible to actually change position
        // properties to add some listeners, but this
        // would work 100% without wrapping all the
        // Vector3 methods
        useFrame(() => {
            const anchor = (fwGroupRef as HTMLOverlayAnchorRef).current;
            if (anchor && positionRef.current && !positionRef.current.equals(anchor.position)) {
                positionRef.current.copy(anchor.position);
                anchor.dispatchEvent({ type: 'update' });
            }
        });

        return (
            <group visible={false} ref={fwGroupRef} position={position} {...props}>
                {children}
            </group>
        );
    },
);
