import type { ModelPayloadItem } from './ModelViewerTypes';
import { Jaw } from './ModelViewerTypes';
import { CadType } from '@orthly/forceps';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';

/**
 * Predicates to filter various groups of ModelPayloadItems we care about
 */

export function isUpperScanItem(pl: Pick<ModelPayloadItem, 'type' | 'jaw'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.jaw === Jaw.Upper;
}

export function isLowerScanItem(pl: Pick<ModelPayloadItem, 'type' | 'jaw'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.jaw === Jaw.Lower;
}

export function isUpperMBItem(pl: ModelPayloadItem): boolean {
    return isUpperScanItem(pl) && isMbScanItem(pl);
}

export function isLowerMBItem(pl: ModelPayloadItem): boolean {
    return isLowerScanItem(pl) && isMbScanItem(pl);
}

export function isScanItem(pl: ModelPayloadItem): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans;
}

export function isMbScanItem(pl: ModelPayloadItem): boolean {
    return isScanItem(pl) && pl.name.startsWith('MB ');
}

export function isNonMbScanItem(pl: ModelPayloadItem): boolean {
    return isScanItem(pl) && !pl.name.startsWith('MB ');
}

export function isDesignBiteScanItem(pl: ModelPayloadItem): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.name.startsWith('Raw Bite');
}

export function isRawPrepScanItem(pl: ModelPayloadItem): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.name.startsWith('Raw Prep');
}

export function isQcHeatmapItem(pl: Pick<ModelPayloadItem, 'type' | 'name'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.QcExtras && pl.name.startsWith('QCThickness');
}

export function isCurrentQcHeatmapItem(pl: ModelPayloadItem): boolean {
    return isQcHeatmapItem(pl) && !isPastDesignItem(pl);
}

export function isPastQcHeatmapItem(pl: ModelPayloadItem): boolean {
    return isQcHeatmapItem(pl) && isPastDesignItem(pl);
}

export function isQCCollisionItem(pl: Pick<ModelPayloadItem, 'type' | 'name'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.QcExtras && pl.name.startsWith('QCCollision');
}

export function isQCUndercutsCurtainsItem(pl: Pick<ModelPayloadItem, 'type' | 'name'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.QcExtras && pl.name.startsWith('QCBlockout');
}

export function getQCUndercutsCurtainId(pl: Pick<ModelPayloadItem, 'path'>): string | undefined {
    // This code gets the mesh ID associated with this curtain item; the ID
    // is stored in the path of the item
    const deSuffixedName = pl.path.split('.')[0];
    const nameParts = deSuffixedName?.split('_');
    if (!nameParts) {
        return undefined;
    }

    //  should be 34 characters?
    return nameParts[nameParts.length - 1];
}

export function isCadItem(pl: Pick<ModelPayloadItem, 'type' | 'isRestorative' | 'cadType'>): boolean {
    return isRemovableItem(pl) || (pl.type === LabsGqlOrderDesignScanType.Cad && pl.isRestorative);
}

export function isCurrentCadItem(
    pl: Pick<ModelPayloadItem, 'type' | 'isPastDesign' | 'isRestorative' | 'cadType'>,
): boolean {
    return isCadItem(pl) && !isPastDesignItem(pl);
}

export function isPastCadItem(pl: ModelPayloadItem): boolean {
    return isCadItem(pl) && isPastDesignItem(pl);
}

export function isPrintedModelItem(pl: Pick<ModelPayloadItem, 'type' | 'isPrintedModel'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Cad && pl.isPrintedModel;
}

export function isAnatomyItem(pl: Pick<ModelPayloadItem, 'type'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.AnatomyElements;
}

export function isPastDesignItem(pl: Pick<ModelPayloadItem, 'isPastDesign'>): boolean {
    return !!pl.isPastDesign;
}

export function isRemovableItem(pl: Pick<ModelPayloadItem, 'type' | 'cadType'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Cad && pl.cadType === CadType.Splint;
}

export function isPreExtractionScanItem(pl: Pick<ModelPayloadItem, 'type' | 'name'>): boolean {
    return pl.type === LabsGqlOrderDesignScanType.Scans && pl.name.startsWith('Pre Extraction Scan');
}
