import { reduce } from 'lodash';

/**
 * Returns the percentile, as a value on the unit interval, of the given threshold in the given values.
 * @param values The values over which to calculate the percentile
 * @param threshold The threshold for which to calculate the percentile
 */
export function getPercentile(values: ArrayLike<number>, threshold: number): number {
    if (!values.length) {
        return 0;
    }

    const numLeq = reduce(values, (acc, val) => acc + (val <= threshold ? 1 : 0), 0);
    return numLeq / values.length;
}
