import { useFileUploaderVars } from './FileUploader.utils';
import type { FileUploaderBulkProps } from './FileUploaderBulk';
import { SimpleDropzone } from './FileUploaderDropzone';
import { FileUploaderLayout } from './FileUploaderLayout';
import type { GridProps } from '@orthly/ui-primitives';
import { Collapse, Grid } from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';
import React from 'react';

export interface FileUploaderCustomPreviewProps extends FileUploaderBulkProps {
    dropzoneTypographyStyle?: React.CSSProperties;
    loadBlockerContainerProps?: GridProps;
    PreviewComponent: React.FC<{ onReset: () => void; file: File; style?: CSSProperties }>;
    previewComponentStyle?: CSSProperties;
}

export const FileUploaderCustomPreview: React.FC<FileUploaderCustomPreviewProps> = props => {
    const { dropzoneOptions, PreviewComponent, previewComponentStyle } = props;

    const { inputFiles, loading, onDropAccepted, onReset, uploadCompleted } = useFileUploaderVars(props);

    return (
        <FileUploaderLayout
            loading={loading}
            title={props.title}
            elevation={props.elevation}
            paperStyle={props.paperStyle}
            loadBlockerContainerProps={props.loadBlockerContainerProps}
        >
            {!loading && (
                <Collapse in={!uploadCompleted && !loading} style={{ width: '100%' }}>
                    <Grid container>
                        <SimpleDropzone
                            wrapperStyle={props.dropzoneWrapperStyle}
                            typographyStyle={props.dropzoneTypographyStyle}
                            options={{ ...dropzoneOptions, onDropAccepted }}
                            dropzoneContent={props.dropzoneContent}
                        />
                    </Grid>
                </Collapse>
            )}
            <Collapse in={uploadCompleted || loading} style={{ width: '100%' }}>
                {inputFiles.map(file => (
                    <PreviewComponent key={file.name} file={file} onReset={onReset} style={previewComponentStyle} />
                ))}
            </Collapse>
        </FileUploaderLayout>
    );
};
