import type { QcHeatmapOption, QcHeatmapRange } from './ColorRamp.types';
import type React from 'react';

export interface ColorLegendToolConfig {
    heatMap: QcHeatmapOption;
    range: QcHeatmapRange;
    step: number;
    onChange: (newValues: QcHeatmapRange) => void;
}

export function makeLegendTools(config: ColorLegendToolConfig) {
    const { range, step, heatMap, onChange } = config;
    const { minimumMinValue, maximumMaxValue } = heatMap;
    const { min, max } = range;

    return {
        moveWindowUp: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            evt.stopPropagation();

            const newMin = min + step;
            const newMax = max + step;

            if (newMax > maximumMaxValue) {
                return;
            }

            onChange({
                min: newMin,
                max: newMax,
            });
        },

        moveWindowDown: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            evt.stopPropagation();

            const newMin = Math.max(min - step, minimumMinValue);
            const newMax = newMin === minimumMinValue ? max : max - step;

            onChange({
                min: newMin,
                max: newMax,
            });
        },

        zoomIntoWindow: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            evt.stopPropagation();

            const newMin = min + step * 0.5;
            const newMax = max - step * 0.5;

            if (newMin >= newMax - step || newMax > maximumMaxValue) {
                return;
            }

            onChange({
                min: newMin,
                max: newMax,
            });
        },

        zoomOutOfWindow: (evt: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            evt.stopPropagation();

            const newMin = min - step * 0.5;
            const newMax = max + step * 0.5;

            if (newMin <= minimumMinValue || newMax > maximumMaxValue) {
                return;
            }

            onChange({
                min: newMin,
                max: newMax,
            });
        },
    };
}
