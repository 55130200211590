import { MaterialsFileParser } from './MaterialsFileParser';
import type JSZip from 'jszip';
import jszip from 'jszip';

// Parses a DME to return the materials/manufacturing information, and any other relevant entries.
export class DmeParser {
    foundMaterialFileContents?: string;

    constructor(private readonly buffer: Buffer) {}

    // Given a zip, finds the materials file and returns its context as a raw xml string.
    private async findMaterialsFileContents(zip: JSZip): Promise<string | undefined> {
        const entries = Object.entries(zip.files);
        for (const entry of entries) {
            if (entry[0]?.endsWith('Materials.xml')) {
                return entry[1].async('string');
            }
        }
    }

    // Finds the cached copy of the Materials.xml file, or reads it from source if it hasn't yet been read.
    async getCachedMaterialsFileContents() {
        if (this.foundMaterialFileContents) {
            return this.foundMaterialFileContents;
        }

        const zipBuffer = await jszip.loadAsync(this.buffer);
        this.foundMaterialFileContents = await this.findMaterialsFileContents(zipBuffer);
        return this.foundMaterialFileContents;
    }

    async parse() {
        const materialsFile = await this.getCachedMaterialsFileContents();

        if (!materialsFile) {
            throw new Error('No materials file found');
        }

        const parser = new MaterialsFileParser(materialsFile);

        return parser.parse();
    }
}
