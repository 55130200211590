import { ConsoleLogger } from './ConsoleLogger';
import type { ILogger, LogLevel } from '@orthly/shared-types';

// A global logger for forceps library code whose implementation can be exchanged at runtime.
export class ForcepsLogger implements ILogger {
    private static impl: ILogger = ConsoleLogger.getInstance();

    static setImplementation(impl: ILogger) {
        ForcepsLogger.impl = impl;
    }

    createChild(namespaceSuffix: string, context: Record<string, any>): ILogger {
        return ForcepsLogger.impl.createChild(namespaceSuffix, context);
    }
    audit(): ILogger {
        return ForcepsLogger.impl.audit();
    }
    debug(message: string, data?: Record<string, any>): void {
        ForcepsLogger.impl.debug(message, data);
    }
    error(obj: string | Error, data?: Record<string, any>): void {
        ForcepsLogger.impl.error(obj, data);
    }
    fatal(message: string, data?: Record<string, any>): void {
        ForcepsLogger.impl.fatal(message, data);
    }
    info(message: string, data?: Record<string, any>): void {
        ForcepsLogger.impl.info(message, data);
    }
    trace(message: string, data?: Record<string, any>): void {
        ForcepsLogger.impl.trace(message, data);
    }
    warn(message: string, data?: Record<string, any>): void {
        ForcepsLogger.impl.warn(message, data);
    }
    time(level: LogLevel, message: string, startData?: Record<string, any>): () => void {
        return ForcepsLogger.impl.time(level, message, startData);
    }
}

export const logger = new ForcepsLogger();
