import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { RootActionDialog } from '@orthly/ui';
import { Button, FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        padding: '8px',
    },
    dialogButtons: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        margin: '15px 5px 5px 5px',
    },
    button: {
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
    },
}));

export const IncompletedWorkReminderModal: React.VFC = () => {
    const classes = useStyles();
    const {
        isIncompletedWorkReminderModalOpen,
        setIsIncompletedWorkReminderModalOpen,
        selectedTab,
        isAnnotatingScreenshot,
    } = useGuidedWaxupContext();
    const formattedTabTitle = _.startCase(selectedTab).toLowerCase();
    return (
        <RootActionDialog
            loading={false}
            open={isIncompletedWorkReminderModalOpen}
            setOpen={setIsIncompletedWorkReminderModalOpen}
            hideButton={true}
            title={
                isAnnotatingScreenshot
                    ? `You have unsaved changes`
                    : `Please supply a rejection note first for ${formattedTabTitle}`
            }
            content={
                <Grid container>
                    <Grid item>
                        <Text variant={'body1'} color={'GRAY'}>
                            {isAnnotatingScreenshot
                                ? `You have an unsaved markup in progress. Please save the markup or delete the markup first.`
                                : `You have stated you want to reject the ${formattedTabTitle}. A rejection note is required to
                            submit your review.`}
                        </Text>
                    </Grid>
                    <Grid item container className={classes.buttonContainer}>
                        <Grid item>
                            <Button
                                variant={'primary'}
                                onClick={() => {
                                    setIsIncompletedWorkReminderModalOpen(false);
                                }}
                                className={classes.dialogButtons}
                            >
                                Done
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            }
        />
    );
};
