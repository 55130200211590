import type { ColorMapFn } from './colorMappingFunctions';
import { styled } from '@mui/material';
import React from 'react';

interface ColorRampComponentProps {
    vMin: number;
    vMax: number;
    colorMap: ColorMapFn;
    width: number;
    height: number;
}

const CanvasStyled = styled('canvas')({
    borderRadius: 4,
});

export const ColorRampHorizontal: React.FC<ColorRampComponentProps> = props => {
    const { vMin, vMax, colorMap, width, height } = props;
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    React.useEffect(() => {
        const ctx = canvasRef.current?.getContext('2d');
        if (!ctx) {
            return;
        }
        const imageData = ctx.createImageData(width, height);
        const data = imageData.data;
        const step_size = (vMax - vMin) / width;
        for (let y = 0; y < height; y++) {
            for (let x = 0; x < width; x++) {
                const color = colorMap(vMin, vMax, vMax - x * step_size) || { r: 255, g: 255, b: 255 };
                const index = (y * width + x) * 4;
                data[index] = color.r;
                data[index + 1] = color.g;
                data[index + 2] = color.b;
                data[index + 3] = 255;
            }
        }
        ctx.putImageData(imageData, 0, 0);
    }, [colorMap, vMin, vMax, width, height]);

    return <CanvasStyled width={width} height={height} ref={canvasRef} />;
};
