/* eslint-disable max-lines, max-lines-per-function */
import { QC_COLOR_LEGEND_FIXED_CONTAINER_STYLE, QCColorLegend } from '../ColorRamp';
import { DesignComparisonContext } from '../DesignComparison';
import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { useClearanceToolSetup } from './ClearanceTools';
import { InsertionAxisMesh } from './InsertionAxisMesh';
import { MarginEditingPalette } from './Margin.util';
import { parseMarginLines } from './MarginLineParser';
import { useMarginSlider } from './MarginLineSlider';
import type { MarginEditingStructure, MarginSliderMode } from './MarginMesh';
import { MarginLineSlider, MarginMesh } from './MarginMesh';
import { MESH_DEFAULT_POSITION, transformFromPlyOrCtm } from './ModelMeshes';
import type { ModelToolbarType, ToolEnableDisableMap } from './ModelToolbar';
import { MODEL_TOOLBAR_HEIGHT, ModelToolbar, ModelToolbarMobile } from './ModelToolbar';
import { ModelViewerCanvas } from './ModelViewerCanvas';
import { ModelViewerFallbackComponent } from './ModelViewerFallbackComponent';
import type { ModelWithTransform } from './ModelViewerPayloads';
import {
    getMarginAssociatedGeometryFromPayload,
    PayloadMesh,
    payloadViewHasLayers,
    plyOrCtmPayload,
} from './ModelViewerPayloads';
import type { MainViewCameraControlsRef, MainViewModelRef } from './ModelViewerTHREETypes';
import type { Jaw, ModelPayloadView } from './ModelViewerTypes';
import type { TakeSnapshotRef } from './ScreenShotCore';
import { ShadeMatch } from './ShadeMatch';
import type { UndercutPropsForModelViewer } from './UndercutViewerApp';
import type { ICameraControls } from './utils3d/TrackballControls';
import { TrackballControls } from './utils3d/TrackballControls';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { HeatMapType } from '@orthly/forceps';
import { LabsGqlDesignOrderAlignerTreatmentPlanSource } from '@orthly/graphql-schema';
import type { InternalDesignMetadata, MarginLine } from '@orthly/shared-types';
import { LoadBlocker, withErrorBoundary } from '@orthly/ui';
import { createStyles, Grid, makeStyles, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import { OrthographicCamera } from '@react-three/drei';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';

const IDENTITY_MATRIX = new THREE.Matrix4().identity();

const DANDY_DEFAULT_KEYMAP = {
    LEFT: THREE.MOUSE.ROTATE,
    MIDDLE: THREE.MOUSE.DOLLY,
    RIGHT: THREE.MOUSE.PAN,
    LEFT_ALT: THREE.MOUSE.PAN,
};

const THREE_SHAPE_KEYMAP = {
    LEFT: THREE.MOUSE.ROTATE,
    MIDDLE: THREE.MOUSE.PAN,
    RIGHT: THREE.MOUSE.ROTATE,
    LEFT_ALT: THREE.MOUSE.PAN,
};

interface TrackballMouseButtons {
    LEFT: THREE.MOUSE;
    MIDDLE: THREE.MOUSE;
    RIGHT: THREE.MOUSE;
    LEFT_ALT: THREE.MOUSE;
}

interface TrackballControlSpeeds {
    rotate?: number;
    zoom?: number;
    pan?: number;
}

interface ModelViewerControlsProps {
    keyMap?: TrackballMouseButtons;
    trackballSpeeds?: TrackballControlSpeeds;
    staticMoving?: boolean;
    min_zoom_distance?: number;
    max_zoom_distance?: number;
    use3ShapeViewerControls?: boolean;
    enableIdenticalPanningBehaviour?: boolean;
}

export const ModelViewerControls = React.forwardRef<ICameraControls, ModelViewerControlsProps>((props, ref) => {
    return (
        <TrackballControls
            ref={ref}
            enableIdenticalPanningBehaviour={props.enableIdenticalPanningBehaviour}
            use3ShapeViewerControls={props.use3ShapeViewerControls ?? false}
            mouseButtons={props.keyMap ?? (props.use3ShapeViewerControls ? THREE_SHAPE_KEYMAP : DANDY_DEFAULT_KEYMAP)}
            rotateSpeed={props.trackballSpeeds?.rotate || 5}
            zoomSpeed={props.trackballSpeeds?.zoom || 3}
            panSpeed={props.trackballSpeeds?.pan || 3}
            minDistance={props.min_zoom_distance}
            maxDistance={props.max_zoom_distance ?? 1.5}
            staticMoving={props.staticMoving ?? false}
        />
    );
});

export const DEFAULT_CAMERA_POSITION = new THREE.Vector3(0, 0, 100);

export interface InitialCameraProps {
    zoom: number;
    position: THREE.Vector3;
    rotation: THREE.Euler;
    up?: THREE.Vector3;
}

export const SceneAmbientLight: React.FC<{ intensity?: number }> = ({ intensity }) => (
    <ambientLight color={0xffffff} intensity={intensity ?? 0.1} />
);

// Sets the camera position around the model using euler angles. xDeg, yDeg, and zDeg are the degree values
// for each rotation. xDeg controls rotation around the X Axis, yDeg the Y Axis, yDeg the Z Axis. To
// prevent gimbal lock when using euler angles, the camera roll angle may also be affected.
// Empirically, a 0 degree rotation resets the camera to behind the model.

export const useRotationCallback = (model: MainViewModelRef, controls: MainViewCameraControlsRef) => {
    return React.useCallback(
        (xDeg: number, yDeg: number, zDeg: number) => {
            // No model/camera rig available
            if (!model.current || !controls.current?.object) {
                return;
            }

            // Reset camera to its starting position, to avoid centering issues if the user has moved the camera out of the plane.
            const camera = controls.current?.object;
            controls.current.reset?.();

            // Calculate new camera position
            const cEuler = new THREE.Euler(
                THREE.MathUtils.degToRad(xDeg),
                THREE.MathUtils.degToRad(yDeg),
                THREE.MathUtils.degToRad(zDeg),
            );

            const newPos = new THREE.Vector3(0, 0, -100).applyEuler(cEuler);

            // This prevents gimbal lock when the camera faces directly upwards or downwards,
            // by changing the roll axis (camera.up) Without this change, the camera will jitter
            // all over the place.
            const up = new THREE.Vector3(0, 1, 0);
            if (Math.abs(up.dot(newPos.clone().normalize())) > 0.9) {
                camera.up?.copy(new THREE.Vector3(0, 0, 1));
            } else {
                camera.up?.copy(new THREE.Vector3(0, 1, 0));
            }

            // Set new state for the camera
            camera.position.copy(newPos);
            camera.updateProjectionMatrix();
            controls.current?.update?.();
        },
        [model, controls],
    );
};

// Rotation vectors for each of the faces we can rotate to
// [x, y, z] indicates an absolute rotation of x degrees, y degrees, z degrees w.r.t. the origin face of the model.
// [0,0,0] implies no rotation
const RotationMeasures: { [K in ModelToolbarType]?: [number, number, number] } = {
    view_front: [0, 180, 0],
    view_back: [0, -180, 0],
    view_left: [0, -90, 0],
    view_right: [0, 90, 0],
    view_bottom: [-90, 0, 0],
    view_top: [90, 0, 0],
};

export interface ModelViewerLayoutProps {
    style?: React.CSSProperties;
    variant?: 'fullscreen' | 'compact'; // Defaults to 'compact'
    full_screen?: boolean;
    hide_toolbar?: boolean;
    hasPastRestoratives?: boolean;
    displayLocation?: DandyAnalyticsEventSchemaType['All - Portal - Design Comparison - Past Design Loaded']['displayLocation'];
}

export type ShadeMatchConfig = {
    onShadePicked: (color: [number, number, number]) => void;
    maxRadiusMm: number;
    jaw: Jaw;
};

export interface ModelViewerProps extends ModelViewerLayoutProps {
    aligner_plan_type?: LabsGqlDesignOrderAlignerTreatmentPlanSource;
    marginLineSlider?: { toothNumber: number; variant: MarginSliderMode; marginLine?: MarginLine };
    marginLineEditing?: MarginEditingStructure;
    // The default behavior is to downsample the passed-in margin line to get the control points. That can be disabled
    // by setting this to true.
    disableMarginControlPointDownsampling?: boolean;
    enableHeatMaps?: boolean;
    trackballSpeeds?: TrackballControlSpeeds;
    shadeMatchConfig?: ShadeMatchConfig;
    undercutApp?: UndercutPropsForModelViewer;
    parentControlRef?: MainViewCameraControlsRef;
    // TODO: DND-2530
    // For right now this flag bleeds into a number of different areas of the
    // model viewer. The current implementation does not leave a lot of flexibility
    // if we wish to use the clearance tool with other features in the future.
    //
    // We intend to resolve these issues when the clearance tool is ported over to
    // model viewer v2. For now, only dandyOS is interested in the clearance tool,
    // so we are allowed to make some assumptions about the usage of this feature
    enableClearanceTool?: boolean;
    staticMoving?: boolean;
    orderMaterialsHaveLayers: boolean;
    model_payload: ModelPayloadView;
    enable_textures?: boolean;
    modelling_tree_buffer?: string;
    design_metadata?: InternalDesignMetadata;
    disable_restoratives?: boolean;
    enable_jaws?: boolean;
    enable_rotation_tools?: boolean;
    use_pretty_buttons?: boolean;
    initialClearanceToolValues?: { min: number; max: number };
    min_zoom_distance?: number;
    max_zoom_distance?: number;
    initialCamSettings?: InitialCameraProps;
    keyMap?: TrackballMouseButtons;
    enableCustomScanShader?: 'design' | 'prep' | 'chairside' | boolean;
    usingSharedControl?: boolean;
    use3ShapeViewerControls?: boolean;
    enableTubeMarginLine?: boolean;
}

// This object interface is meant to allow other parts of the design QC
// portal to edit the model viewer's state
export interface DesignQcViewerProps {
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;

    // These refs are used to handle rotation of the model w.r.t. the camera.
    modelRef: MainViewModelRef;
    controlRef: MainViewCameraControlsRef;
    // Used to take a snapshot of the model viewer's contents.
    takeSnapshotRef?: TakeSnapshotRef;
}

interface UseToolEnableMapArgs {
    enableTextures?: boolean;
    enableMargin?: boolean;
    enableJaws?: boolean;
    enableLayers?: boolean;
    disableRestoratives?: boolean;
    enableRotationTools?: boolean;
    enableQCHeatmaps?: boolean;
}

const useToolEnableMap = (args: UseToolEnableMapArgs) => {
    const {
        enableTextures,
        enableMargin,
        enableJaws,
        enableLayers,
        disableRestoratives,
        enableRotationTools,
        enableQCHeatmaps,
    } = args;

    return React.useMemo<ToolEnableDisableMap>(() => {
        const defaultDisabledOverride = _.compact([
            enableTextures ? 'toggle_texture' : undefined,
            enableMargin ? 'toggle_margin' : undefined,
            enableQCHeatmaps ? 'toggle_qc_heatmaps' : undefined,
            enableLayers ? 'toggle_layers' : undefined,
            enableJaws ? 'toggle_upper' : undefined,
            enableJaws ? 'toggle_lower' : undefined,
            enableRotationTools ? 'view_front' : undefined,
            enableRotationTools ? 'view_left' : undefined,
            enableRotationTools ? 'view_right' : undefined,
        ]).reduce<ToolEnableDisableMap>((acc, tool) => {
            return { ...acc, [tool]: true };
        }, {});

        const defaultEnabledOverride = disableRestoratives
            ? { toggle_restoratives: false, toggle_restorative_transparency: false }
            : null;
        return { ...defaultDisabledOverride, ...defaultEnabledOverride };
    }, [
        enableTextures,
        enableMargin,
        enableJaws,
        enableLayers,
        disableRestoratives,
        enableRotationTools,
        enableQCHeatmaps,
    ]);
};

export const ModelViewerLayoutContainer: React.FC<ModelViewerLayoutProps> = props => {
    const { style, variant, full_screen, hide_toolbar, hasPastRestoratives, displayLocation, children } = props;
    return (
        <DesignComparisonContext.Provider value={{ hasPast: !!hasPastRestoratives, displayLocation }}>
            <div
                id={'model-viewer-layout-container'}
                data-testid={'model-viewer-layout-container'}
                style={{
                    position: full_screen ? undefined : 'relative',
                    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                    // eslint-disable-next-line no-nested-ternary
                    height: full_screen ? '100%' : hide_toolbar ? 520 : MODEL_TOOLBAR_HEIGHT,
                    margin: full_screen ? '0 -16px' : undefined,
                    flexGrow: 1,
                    width: '100%',
                    backgroundColor: variant === 'fullscreen' ? 'white' : undefined,
                    ...style,
                }}
            >
                {children}
            </div>
        </DesignComparisonContext.Provider>
    );
};

export function getMarginLines(
    prepModels: ModelWithTransform[],
    design_metadata?: InternalDesignMetadata,
    modelling_tree_buffer?: string,
) {
    // see if any of the MB models have transforms defined
    const transform = prepModels.map(transformFromPlyOrCtm).find(t => t !== null) || {
        upper: IDENTITY_MATRIX,
        lower: IDENTITY_MATRIX,
    };

    if (design_metadata?.margin_lines !== undefined) {
        return design_metadata.margin_lines;
    }

    return parseMarginLines(modelling_tree_buffer, transform);
}

const useModelViewerStyles = makeStyles(() =>
    createStyles({
        qcColorLegendFixedContainer: QC_COLOR_LEGEND_FIXED_CONTAINER_STYLE,
    }),
);

// This function is used to generate a rotation to actually rotate the models in the scene
// This is used primarly for aligners, however we could concievably use it if 3Shape has a
// different canonical direction convention than (eg) Exocad which we might some day support

export function useDefaultModelRotation(
    aligner_plan_type?: LabsGqlDesignOrderAlignerTreatmentPlanSource,
): THREE.Euler | undefined {
    switch (aligner_plan_type) {
        case LabsGqlDesignOrderAlignerTreatmentPlanSource.ArchForm:
            return new THREE.Euler(-Math.PI / 2, 0, 0);
        case LabsGqlDesignOrderAlignerTreatmentPlanSource.OnyxCeph:
            return new THREE.Euler(0, 0, 0);
        case undefined:
            return undefined;
    }
}

const DEFAULT_PRESSED_BUTTONS: ModelToolbarType[] = [
    'toggle_texture',
    'toggle_restoratives',
    'toggle_restorative_transparency',
    'toggle_layers',
    'toggle_upper',
    'toggle_lower',
];

/**
 * Helper to add key listener to trackball controls.  This allows the shift key to slow down scrolling.
 *
 * @param controls
 * @returns
 */

export function addKeyListener(controls: ICameraControls | null) {
    const shiftKeyListener = (event: KeyboardEvent) => {
        if (controls) {
            controls.rotateSpeed = event.shiftKey ? 0.5 : 5;
        }
    };

    document.addEventListener('keyup', shiftKeyListener);
    document.addEventListener('keydown', shiftKeyListener);
    return () => {
        document.removeEventListener('keyup', shiftKeyListener);
        document.removeEventListener('keydown', shiftKeyListener);
    };
}

/**
 * The legacy model viewer, capable of rendering an entire scene.
 * Handles all of the Threejs context for you
 *
 * @component
 */
const ModelViewerInner: React.FC<ModelViewerProps> = props => {
    const {
        model_payload,
        style,
        enable_textures,
        variant,
        modelling_tree_buffer,
        design_metadata,
        full_screen,
        disable_restoratives,
        enable_jaws,
        enable_rotation_tools,
        hide_toolbar,
        enableClearanceTool = false,
        enableHeatMaps = false,
        aligner_plan_type,
        marginLineSlider,
        marginLineEditing,
        disableMarginControlPointDownsampling,
        use_pretty_buttons = false,
        initialClearanceToolValues = { min: 0.5, max: 1.5 },
        min_zoom_distance,
        max_zoom_distance,
        initialCamSettings,
        keyMap,
        trackballSpeeds,
        shadeMatchConfig,
        enableCustomScanShader,
        undercutApp,
        parentControlRef,
        children,
        usingSharedControl,
        use3ShapeViewerControls,
        enableTubeMarginLine,
    } = props;

    const qcStyles = useModelViewerStyles();

    const [pressedButtons, setPressedButtons] = React.useState<ModelToolbarType[]>(DEFAULT_PRESSED_BUTTONS);
    const [currentColorMap, setActiveColorMap] = React.useState<HeatMapType>(
        enableClearanceTool ? HeatMapType.Clearance : HeatMapType.Thickness,
    );

    React.useEffect(() => {
        setActiveColorMap(enableClearanceTool ? HeatMapType.Clearance : HeatMapType.Thickness);
    }, [setActiveColorMap, enableClearanceTool]);

    const activeColorMap = React.useMemo(
        () =>
            enableClearanceTool || (enableHeatMaps && pressedButtons.includes('toggle_qc_heatmaps'))
                ? currentColorMap
                : undefined,
        [enableHeatMaps, pressedButtons, currentColorMap, enableClearanceTool],
    );

    // These refs are used to handle rotation of the model w.r.t. the camera.
    const modelRef = React.useRef<THREE.Group>();
    const internalControlRef = React.useRef<ICameraControls | null>(null);
    const controlRef = parentControlRef ?? internalControlRef;
    const rotateView = useRotationCallback(modelRef, controlRef);

    const renderTextures: boolean = React.useMemo(() => {
        return !!enable_textures && pressedButtons.includes('toggle_texture');
    }, [pressedButtons, enable_textures]);

    // Handles all of the actions that are fired when a given toolbar button is pressed.
    const onToolbarButtonClicked = React.useCallback(
        (button: ModelToolbarType) => {
            const newRotationMeasure = RotationMeasures[button];
            if (newRotationMeasure) {
                rotateView(...newRotationMeasure);
            }
        },
        [rotateView],
    );

    const isMobile = useScreenIsMobileOrVerticalTablet();

    const toolEnableMap = useToolEnableMap({
        enableTextures: enable_textures,
        enableMargin: !!modelling_tree_buffer,
        enableJaws: enable_jaws,
        enableRotationTools: enable_rotation_tools,
        enableLayers: payloadViewHasLayers(model_payload) && props.orderMaterialsHaveLayers,
        disableRestoratives: disable_restoratives,
        enableQCHeatmaps: enableHeatMaps,
    });

    const marginLines = React.useMemo(() => {
        return getMarginLines(plyOrCtmPayload(model_payload), design_metadata, modelling_tree_buffer);
    }, [modelling_tree_buffer, model_payload, design_metadata]);

    React.useEffect(() => {
        return addKeyListener(controlRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [clearanceToolRange, setClearanceToolRange] = React.useState(initialClearanceToolValues);
    React.useEffect(() => {
        setClearanceToolRange({
            min: initialClearanceToolValues.min,
            max: initialClearanceToolValues.max,
        });
    }, [initialClearanceToolValues.min, initialClearanceToolValues.max]);

    useClearanceToolSetup({
        model_payload,
        activeColorMap,
        enableClearanceTool,
        min: clearanceToolRange.min,
        max: clearanceToolRange.max,
    });

    const { onMarginSliderChange, marginSliderPercentage } = useMarginSlider(controlRef, marginLines, marginLineSlider);

    const colorLegend = React.useMemo(() => {
        switch (activeColorMap) {
            case HeatMapType.Clearance:
                return (
                    <QCColorLegend
                        heatMapType={HeatMapType.Clearance}
                        heatMapRange={clearanceToolRange}
                        setHeatMapRange={setClearanceToolRange}
                        dynamicHeatmaps={true}
                    />
                );

            case undefined:
                return null;

            default:
                return <QCColorLegend heatMapType={activeColorMap} />;
        }
    }, [activeColorMap, clearanceToolRange, setClearanceToolRange]);

    // Rotate the models if they are from certain CAD softwares
    const defaultRotation = useDefaultModelRotation(aligner_plan_type);

    const cameraProps = initialCamSettings ?? {
        zoom: 4,
        position: DEFAULT_CAMERA_POSITION,
    };

    // TODO: We should figure out the best way to do this that doesn't cause re-renders
    // This is a temporary fix that Patrick should own as the same work needs to be done in NewModelViewer
    const marginEditLineColor = React.useMemo(() => {
        return new THREE.Color(MarginEditingPalette.EDIT_LINE_COLOR);
    }, []);

    return (
        <ModelViewerLayoutContainer
            style={style}
            variant={variant}
            full_screen={full_screen}
            hide_toolbar={hide_toolbar || enableClearanceTool}
        >
            {!usingSharedControl ? <div className={qcStyles.qcColorLegendFixedContainer}>{colorLegend}</div> : null}
            {/*
             * Some notes: colorManagement is set to true by default to fix threejs' colors being linear and wrong
             * This, however, causes models that look very off with the textures we have (e.g. not close to 3shape)
             * Concurrent enables support for React's concurrent mode
             */}
            <Grid style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                <ModelViewerCanvas>
                    <OrthographicCamera makeDefault {...cameraProps}>
                        <pointLight intensity={0.7} color={0xffffff} />;
                    </OrthographicCamera>

                    <SceneAmbientLight />

                    {/* Models are grouped in a parent group to give control over every model's rotation, position, etc. */}
                    {/* We add a rotation of 90 degrees for arch form treatment plans because they default facing up */}
                    <group ref={modelRef} rotation={defaultRotation}>
                        <PayloadMesh
                            payload={model_payload}
                            enableTexture={renderTextures}
                            enableCustomScanShader={enableCustomScanShader ?? false}
                            showLayers={
                                !enableClearanceTool &&
                                pressedButtons.includes('toggle_layers') &&
                                pressedButtons.includes('toggle_restoratives')
                            }
                            showRestoratives={!enableClearanceTool && pressedButtons.includes('toggle_restoratives')}
                            activeQCColorMap={activeColorMap}
                            restorativesTransparent={!pressedButtons.includes('toggle_restorative_transparency')}
                            showUpperJaw={pressedButtons.includes('toggle_upper')}
                            showLowerJaw={pressedButtons.includes('toggle_lower')}
                            undercutApp={undercutApp}
                        />

                        {pressedButtons.includes('toggle_margin') &&
                            !marginLineEditing &&
                            marginLines.map((mline: MarginLine, index) => {
                                return (
                                    <MarginMesh
                                        key={`MARGIN_NO_EDIT_${index}_${mline.tooth}`}
                                        marginLine={mline}
                                        cameraControlsRef={controlRef}
                                        marginXray={!pressedButtons.includes('toggle_restorative_transparency')}
                                        disableControlPointDownsampling={disableMarginControlPointDownsampling}
                                        enableTubeMarginLine={enableTubeMarginLine}
                                    />
                                );
                            })}

                        {shadeMatchConfig && (
                            <ShadeMatch
                                modelPayload={model_payload}
                                cameraControlsRef={controlRef}
                                maxRadiusMm={shadeMatchConfig.maxRadiusMm}
                                onShadePicked={shadeMatchConfig.onShadePicked}
                                jaw={shadeMatchConfig.jaw}
                            />
                        )}

                        {marginLineEditing &&
                            marginLineEditing.marginLines.map((mline, index) => {
                                const marginTargetModel = getMarginAssociatedGeometryFromPayload(mline, model_payload);
                                return (
                                    <MarginMesh
                                        key={`MARGIN_EDIT_${index}_${mline.tooth}`}
                                        associatedGeometry={marginTargetModel?.model.geometry}
                                        marginLine={mline}
                                        color={marginEditLineColor}
                                        cameraControlsRef={controlRef}
                                        marginXray={!pressedButtons.includes('toggle_restorative_transparency')}
                                        allowEditing={mline.tooth === marginLineEditing.activeTooth}
                                        onMarginUpdate={marginLineEditing.onMarginUpdate}
                                        disableControlPointDownsampling={disableMarginControlPointDownsampling}
                                        enableTubeMarginLine={enableTubeMarginLine}
                                    />
                                );
                            })}
                    </group>
                    {undercutApp && (
                        <InsertionAxisMesh
                            visible={true}
                            highlighted={true}
                            insertionAxis={undercutApp.insertionAxis}
                            position={MESH_DEFAULT_POSITION}
                        />
                    )}
                    <ModelViewerControls
                        ref={controlRef}
                        min_zoom_distance={min_zoom_distance}
                        max_zoom_distance={max_zoom_distance}
                        staticMoving={props.staticMoving ?? false}
                        keyMap={keyMap}
                        trackballSpeeds={trackballSpeeds}
                        use3ShapeViewerControls={use3ShapeViewerControls}
                    />
                    {children}
                </ModelViewerCanvas>
            </Grid>
            {marginLineSlider && <MarginLineSlider onChange={onMarginSliderChange} value={marginSliderPercentage} />}
            {!hide_toolbar &&
                !enableClearanceTool &&
                (isMobile ? (
                    <ModelToolbarMobile
                        onToolClick={onToolbarButtonClicked}
                        pressedButtons={pressedButtons}
                        setPressedButtons={setPressedButtons}
                        toolEnableDisableMap={toolEnableMap}
                        activeColorMap={activeColorMap}
                        setActiveColorMap={setActiveColorMap}
                        enableClearanceTool={enableClearanceTool}
                    />
                ) : (
                    <ModelToolbar
                        onToolClick={onToolbarButtonClicked}
                        pressedButtons={pressedButtons}
                        setPressedButtons={setPressedButtons}
                        toolEnableDisableMap={toolEnableMap}
                        use_pretty_buttons={use_pretty_buttons}
                        activeColorMap={activeColorMap}
                        setActiveColorMap={setActiveColorMap}
                        enableClearanceTool={enableClearanceTool}
                    />
                ))}
        </ModelViewerLayoutContainer>
    );
};

export const ModelViewer = withErrorBoundary(ModelViewerInner, {
    FallbackComponent: ModelViewerFallbackComponent,
});

export const ModelViewerLoadingPlaceholder: React.FC<ModelViewerLayoutProps> = props => {
    return (
        <ModelViewerLayoutContainer {...props}>
            <LoadBlocker blocking={true} ContainerProps={{ style: { height: '100%' } }} />
        </ModelViewerLayoutContainer>
    );
};
