import * as THREE from 'three';

export const HIGHLIGHT_TOOTH_COLOR: number = 0xfff8db;
export const CURRENT_TOOTH_COLOR: number = 0x008e97;
export const PAST_TOOTH_COLOR: number = 0xfc4c02;
export const DEFAULT_MODEL_COLOR: number = 0xdcc8b2;
export const SCAN_TRIM_COLOR: number = 0x8800ff;
export const GINGIVA_COLOR: number = 0xdc868a;
export const SPLINT_COLOR: number = 0x76a0d0;
export const DEFAULT_PRE_EXTRACTION_SCAN_COLOR: number = 0xaddfff;

export const CURRENT_TOOTH_COLOR_HEX_STRING = toCssHexString(CURRENT_TOOTH_COLOR);
export const PAST_TOOTH_COLOR_HEX_STRING = toCssHexString(PAST_TOOTH_COLOR);

function toCssHexString(color: number): string {
    if (color > 0xffffff || color <= 0) {
        return '#000000';
    }
    return '#'.concat(color.toString(16).padStart(6, '0'));
}

export const STUDIO_MODEL_GRAY_COLOR: THREE.Color = new THREE.Color(170 / 255, 170 / 255, 170 / 255);
export const MODEL_GRAY_COLOR: THREE.Color = new THREE.Color(200 / 255, 200 / 255, 200 / 255);
// This is an attempt at yellow stone/plaster
export const STUDIO_SCAN_YELLOW = new THREE.Color('rgb(245, 225, 135)').getHex();

export const STUDIO_MESH_SHININESS = 100;
export const RESTORATIVE_MESH_SHININESS = 75;
export const STANDARD_MESH_SHININESS = 70;
