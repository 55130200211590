import { useRefabAction } from '../../../../labs/actions/practitioners/useRefabAction';
import { ProductsReviewedTooltip } from './ProductReviewedFeedback';
import { DeliveryDateLayout, FeedbackLayout, Layout } from './Review.styles';
import { formattedDate } from './Reviews.util';
import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { FlossPalette, stylesFactory, useScreenIsMobile, Button, Text, useScreenIsMd } from '@orthly/ui-primitives';
import React from 'react';

type ReviewSubmission = LabsGqlOrderPreviewFragment['review_submission'];

const useStyles = stylesFactory(theme => ({
    root: {
        textAlign: 'end',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-start',
            marginTop: 0,
            textAlign: 'start',
        },
    },
    tooltipTextBox: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-star',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    tooltip: {
        maxWidth: '220px',
        borderRadius: 5,
        backgroundColor: FlossPalette.GRAY,
    },
    tooltipIcon: {
        fontSize: 15,
        width: 16,
        height: 16,
        color: FlossPalette.GRAY,
        cursor: 'help',
        marginBottom: -3,
    },
    ctaButtonContainer: {
        paddingTop: 5,
        '& div': {
            paddingLeft: 10,
        },
        [theme.breakpoints.down('md')]: {
            paddingTop: 0,
            '& div': {
                paddingLeft: 0,
                paddingRight: 5,
            },
        },
    },
    ctaButton: {
        padding: 0,
        height: 'unset',
    },
}));

export const ShowGivenOrderFeedback: React.VFC<{
    order: LabsGqlOrderPreviewFragment;
    review_submission: ReviewSubmission;
    delivery_date?: string | null;
}> = ({ order, review_submission, delivery_date }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const isTablet = useScreenIsMd();

    const reviewCount = review_submission?.items.length ?? 0;
    const itemCount = order.items_v2.length;
    const { triggerRefabAction } = useRefabAction(order.id);

    return (
        <Layout onClick={ev => ev.stopPropagation()}>
            {delivery_date && (
                <DeliveryDateLayout>
                    <Text variant={'caption'} color={'GRAY'}>
                        Delivery
                    </Text>
                    <Text variant={'body2'} medium>
                        {formattedDate(delivery_date)}
                    </Text>
                </DeliveryDateLayout>
            )}

            <FeedbackLayout style={isTablet ? { flex: 1, justifyContent: 'flex-end' } : {}}>
                <Text variant={isMobile ? 'caption' : 'body2'} color={'BLACK'}>
                    {itemCount >= 2 && (
                        <span>
                            {reviewCount}/{itemCount}
                        </span>
                    )}{' '}
                    Feedback Received {itemCount >= 2 && <ProductsReviewedTooltip order={order} />}
                </Text>
                {order.can_refabricate && (
                    <Button
                        variant={'ghost'}
                        className={classes.ctaButton}
                        onClick={ev => {
                            ev.stopPropagation();
                            triggerRefabAction(`order list`);
                        }}
                    >
                        Refabricate
                    </Button>
                )}
            </FeedbackLayout>
        </Layout>
    );
};
