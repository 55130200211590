import _ from 'lodash';
import * as THREE from 'three';
import { create } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';

export function getNodeAttribute(node: XMLBuilder, attrName: string): string {
    const element = node.node as unknown as Element;
    const attrValue = element.getAttribute(attrName);
    if (attrValue === null) {
        throw new Error(`Failed to get attribute ${attrName}`);
    }
    return attrValue;
}

export function maybeGetNodeAttribute(node: XMLBuilder | undefined, attrName: string): string | undefined {
    if (node?.node.nodeType !== /* Node.ELEMENT_NODE */ 1) {
        return undefined;
    }

    const element = node.node as unknown as Element;
    return element.getAttribute(attrName) ?? undefined;
}

/**
 * Searches for a child node with the given attribute value
 * @param node The node whose children to search, recursively. The node itself is not searched.
 * @param attrName The attribute name to search for
 * @param attrValue The attribute value to search for
 * @returns The first found matching node
 * @throws If no matching node is found
 */
function getNodeWithAttribute(node: XMLBuilder, attrName: string, attrValue: string): XMLBuilder {
    const foundNode = maybeGetNodeWithAttribute(node, attrName, attrValue);

    if (!foundNode) {
        throw new Error(`Failed to find node with attribute ${attrName}="${attrValue}"`);
    }

    return foundNode;
}

export function getNodeWithName(node: XMLBuilder, name: string): XMLBuilder {
    return getNodeWithAttribute(node, 'name', name);
}

export function getNodeWithType(node: XMLBuilder, typeValue: string): XMLBuilder {
    return getNodeWithAttribute(node, 'type', typeValue);
}

export function maybeGetNodeWithAttribute(
    node: XMLBuilder,
    attrName: string,
    attrValue: string,
): XMLBuilder | undefined {
    return node.find(
        (node: XMLBuilder) => {
            return maybeGetNodeAttribute(node, attrName) === attrValue;
        },
        /*self*/ false,
        /*recursive*/ true,
    );
}

export function maybeGetNodeWithName(node: XMLBuilder, name: string): XMLBuilder | undefined {
    return maybeGetNodeWithAttribute(node, 'name', name);
}

export function maybeGetNodeWithType(node: XMLBuilder, typeValue: string): XMLBuilder | undefined {
    return maybeGetNodeWithAttribute(node, 'type', typeValue);
}

export function getNodeWithTag(node: XMLBuilder, tag: string): XMLBuilder {
    const foundNode = maybeGetNodeWithTag(node, tag);

    if (!foundNode) {
        throw new Error(`Failed to find node with tag ${tag}`);
    }

    return foundNode;
}

export function maybeGetNodeWithTag(node: XMLBuilder, tag: string): XMLBuilder | undefined {
    return node.find(
        (node: XMLBuilder) => {
            return node.node.nodeName === tag;
        },
        /*self*/ false,
        /*recursive*/ true,
    );
}

export function maybeGetNumberValue(node: XMLBuilder, propertyName: string): number | undefined {
    const propertyNode = maybeGetNodeWithName(node, propertyName);
    if (!propertyNode) {
        return undefined;
    }

    const value = parseFloat(maybeGetNodeAttribute(propertyNode, 'value') ?? '');
    return isNaN(value) ? undefined : value;
}

export function getNumberValue(node: XMLBuilder, propertyName: string): number {
    const value = maybeGetNumberValue(node, propertyName);
    if (!value) {
        throw new Error(`Failed to parse number`);
    }
    return value;
}

export function removeNode(node: XMLBuilder): void {
    node.remove();
}

export function getUpper2LowerMatrixFromBuilder(modellingTreeObject: XMLBuilder) {
    const upper2LowerNode = getNodeWithName(modellingTreeObject, 'UpperJaw2LowerJaw').node as unknown as Element;

    // Here we retrieve the matrix elements in column-major order, as is expected by THREE.Matrix4.fromArray.
    const matrixAttributes = [
        'm00',
        'm10',
        'm20',
        'm30',
        'm01',
        'm11',
        'm21',
        'm31',
        'm02',
        'm12',
        'm22',
        'm32',
        'm03',
        'm13',
        'm23',
        'm33',
    ];
    const matrixValues = matrixAttributes.map(attr => upper2LowerNode.getAttribute(attr));
    if (!_.every(matrixValues)) {
        return undefined;
    }
    const matrixValuesFloat = matrixValues.map(parseFloat);

    const result: THREE.Matrix4 = new THREE.Matrix4();
    result.fromArray(matrixValuesFloat);
    return result;
}

export function createXmlBuilder(modelingTreeString: string) {
    return create(modelingTreeString);
}

export function maybeParseVector3(node: XMLBuilder | undefined): THREE.Vector3 | undefined {
    if (node?.node.nodeName !== 'Vector') {
        return undefined;
    }

    const element = node.node as unknown as Element;

    const values = ([element.getAttribute('x'), element.getAttribute('y'), element.getAttribute('z')] as const).map(
        parseFloat,
    );
    if (values.some(isNaN)) {
        return undefined;
    }

    return new THREE.Vector3(...values);
}

export function parseVector3(node: XMLBuilder): THREE.Vector3 {
    const vec = maybeParseVector3(node);
    if (!vec) {
        throw new Error(`Failed to parse vector`);
    }
    return vec;
}

type EachCallbackType = (node: XMLBuilder, index: number, level: number) => void;

export function forEachDirectChild(node: XMLBuilder, callback: EachCallbackType): void {
    node.each(callback, /*self*/ false, /*recursive*/ false);
}
