import type { ThemeOverrideConfig } from '@orthly/ui';
import { FlossInputThemeProvider } from '@orthly/ui';
import { useScreenIsMobile, Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface SignUpFormContainerProps {
    buttonText?: string;
    isSubmittable?: boolean;
    onSubmitAction?: () => void;
    flossInputConfig?: ThemeOverrideConfig;
}

const SignUpFormContainerBase: React.FC<SignUpFormContainerProps> = props => {
    const { flossInputConfig, buttonText, isSubmittable, onSubmitAction } = props;
    const isMobile = useScreenIsMobile();

    return (
        <FlossInputThemeProvider flossInputConfig={flossInputConfig}>
            <Grid container direction={'column'} spacing={isMobile ? 2 : 3}>
                {props.children}

                {buttonText && onSubmitAction && (
                    <Grid item container={isMobile}>
                        <Button
                            variant={'primary'}
                            type={'submit'}
                            disabled={!isSubmittable}
                            style={{ width: isMobile ? '100%' : undefined }}
                            data-test={'sign-up-button-submit'}
                        >
                            {buttonText}
                        </Button>
                    </Grid>
                )}
            </Grid>
        </FlossInputThemeProvider>
    );
};

export const SignUpFormContainer: React.FC<SignUpFormContainerProps & { formWrapper?: boolean }> = ({
    formWrapper = true,
    ...props
}) => {
    return formWrapper ? (
        <form
            onSubmit={evt => {
                evt.preventDefault();
                props.onSubmitAction?.();
            }}
        >
            <SignUpFormContainerBase {...props} />
        </form>
    ) : (
        <SignUpFormContainerBase {...props} />
    );
};
