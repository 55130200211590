import type { ScanMeshShaderMaterialChairsideProps } from './scanMeshShaderMaterialChairside';
import { ScanMeshShaderMaterialChairside } from './scanMeshShaderMaterialChairside';
import type { ScanMeshShaderMaterialDesignProps } from './scanMeshShaderMaterialDesign';
import { ScanMeshShaderMaterialDesign } from './scanMeshShaderMaterialDesign';
import React from 'react';

type ScanMeshShaderMaterialProps =
    | (ScanMeshShaderMaterialChairsideProps & { mode: 'chairside' })
    | (ScanMeshShaderMaterialDesignProps & { mode: 'design' | 'prep' | true });

export const ScanMeshShaderPhysicalMaterial: React.FC<ScanMeshShaderMaterialProps> = props => {
    if (props.mode === 'chairside') {
        return <ScanMeshShaderMaterialChairside {...props} />;
    } else {
        return <ScanMeshShaderMaterialDesign {...props} />;
    }
};
