import type { Theme } from '@orthly/ui-primitives';
import { FlossPalette, stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory((theme: Theme) => ({
    card: {
        background: FlossPalette.WHITE,
        padding: 40,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: 16,
        flexDirection: 'column',
        [theme.breakpoints.down(process.env.MUI_VERSION_5 === 'true' ? 'sm' : 'xs')]: {
            padding: 24,
        },
    },
    highlightCard: {
        border: `1px solid ${FlossPalette.STAR_GRASS}`,
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
}));

export const CheckYourPhoneCard: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.card} style={{ padding: '40px 40px 0 40px' }}>
            <Text variant={'body1'} medium color={'GRAY'}>
                Check your phone
            </Text>
            <Text variant={'body2'} color={'GRAY'}>
                The code is inside the text message we just sent you
            </Text>
            <Grid container justifyContent={'center'} style={{ marginTop: 32 }}>
                <img
                    src={'/login/DandyVerify.png'}
                    alt={'use your smartphone to verify your login'}
                    style={{ width: '90%' }}
                />
            </Grid>
        </Grid>
    );
};
