import { Jaw } from '../ModelViewer/ModelViewerTypes';
import type { ModelAppearance, PayloadModelAppearance } from './ModelAppearanceTypes';
import { ToothUtils } from '@orthly/items';
import {
    FlossPalette,
    stylesFactory,
    CheckboxPrimitive as Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    formControl: {
        margin: theme.spacing(3),
    },
}));

interface AggregateState {
    checked: boolean;
    indeterminate: boolean;
}

// Returns the Checkbox state for a PMA aggregate based on item
// visibility and the last user-selected state.
function aggregateState(pmas: PayloadModelAppearance[], lastVisibility: boolean): AggregateState {
    let hasVisiblePart = false;
    let hasInvisiblePart = false;
    for (const pma of pmas) {
        hasVisiblePart = hasVisiblePart || pma.appearance.visible;
        hasInvisiblePart = hasInvisiblePart || !pma.appearance.visible;
    }
    const indeterminate = hasVisiblePart && hasInvisiblePart;
    return { checked: indeterminate ? lastVisibility : hasVisiblePart, indeterminate };
}

// Returns `true` iff the PMA is on the upper jaw.

export function pmaIsUpper(pma: PayloadModelAppearance): boolean {
    return pma.payloadModel.jaw === Jaw.Upper || !!pma.payloadModel.unns?.some(unn => ToothUtils.toothIsUpper(unn));
}

// Returns `true` iff the PMA is on the lower jaw.

export function pmaIsLower(pma: PayloadModelAppearance): boolean {
    return pma.payloadModel.jaw === Jaw.Lower || !!pma.payloadModel.unns?.some(unn => ToothUtils.toothIsLower(unn));
}

export function isParentCadVisible(heatMapItem: PayloadModelAppearance, parentCads: PayloadModelAppearance[]): boolean {
    // Find a CAD item with some overlap of UNN's
    // There is not a better way at this time
    // until we put object role on the payloadModel type
    const parentCad = parentCads.find(
        item =>
            item.payloadModel.isRestorative &&
            item.payloadModel.cadType === heatMapItem.payloadModel.cadType &&
            item.payloadModel.unns?.some(toothNumber => heatMapItem.payloadModel.unns?.includes(toothNumber)),
    );
    return parentCad?.appearance?.visible ?? false;
}
// Returns a copy of `pmas` with each PMA's `apperance.visible` prop set to the
// value of `visible`. If `filter` is provided, only sets visibility of PMAs
// that pass the filter.

export function applyVisibility(
    pmas: PayloadModelAppearance[],
    visible: boolean,
    filter?: (pma: PayloadModelAppearance) => boolean,
): PayloadModelAppearance[] {
    return pmas.map(pma => ({
        ...pma,
        appearance: {
            ...pma.appearance,
            visible: !filter || filter(pma) ? visible : pma.appearance.visible,
        },
    }));
}

// State and toggle control for an aggregate.
export interface AggregateToggle {
    label: string;
    state: AggregateState;
    setChecked: (checked: boolean) => void;
}

export const AGGREGATE_CONTROL_LABELS = {
    UPPER: 'Upper',
    LOWER: 'Lower',
    SCANS: 'Scans',
    RESTORATIONS: 'Restorations',
} as const;

export function useAggregateToggles(
    appearance: ModelAppearance,
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
): AggregateToggle[] {
    // Keeps track of the last user-selected state. The actual check state may differ
    // if visibility for items in the aggregate are changed via another toggle.
    const [upperChecked, setUpperChecked] = React.useState(true);
    const [lowerChecked, setLowerChecked] = React.useState(true);
    const [scansChecked, setScansChecked] = React.useState(true);
    const [restorativesChecked, setRestorativesChecked] = React.useState(true);

    // Calculate checkbox button state for each aggregate based on the user-selected
    // state and the visibility of each item in the aggregate.
    const upperState = aggregateState(
        [
            ...appearance.upperJaw,
            ...appearance.scans.filter(pmaIsUpper),
            ...appearance.restoratives.CAD.filter(pmaIsUpper),
        ],
        upperChecked,
    );
    const lowerState = aggregateState(
        [
            ...appearance.lowerJaw,
            ...appearance.scans.filter(pmaIsLower),
            ...appearance.restoratives.CAD.filter(pmaIsLower),
        ],
        lowerChecked,
    );
    const scansState = aggregateState(
        [...appearance.scans, ...appearance.upperJaw, ...appearance.lowerJaw],
        scansChecked,
    );
    const restorativeState = aggregateState(appearance.restoratives.CAD, restorativesChecked);

    return [
        {
            label: AGGREGATE_CONTROL_LABELS.UPPER,
            state: upperState,
            setChecked: checked => {
                setUpperChecked(checked);
                setAppearance(app => ({
                    ...app,
                    upperJaw: applyVisibility(app.upperJaw, checked),
                    restoratives: {
                        ...app.restoratives,
                        CAD: applyVisibility(app.restoratives.CAD, checked, pmaIsUpper),
                    },
                }));
            },
        },
        {
            label: AGGREGATE_CONTROL_LABELS.LOWER,
            state: lowerState,
            setChecked: checked => {
                setLowerChecked(checked);
                setAppearance(app => ({
                    ...app,
                    lowerJaw: applyVisibility(app.lowerJaw, checked),
                    restoratives: {
                        ...app.restoratives,
                        CAD: applyVisibility(app.restoratives.CAD, checked, pmaIsLower),
                    },
                }));
            },
        },
        {
            label: AGGREGATE_CONTROL_LABELS.SCANS,
            state: scansState,
            setChecked: checked => {
                setScansChecked(checked);
                setAppearance(app => ({
                    ...app,
                    upperJaw: applyVisibility(app.upperJaw, checked),
                    lowerJaw: applyVisibility(app.lowerJaw, checked),
                    scans: applyVisibility(app.scans, checked),
                }));
            },
        },
        {
            label: AGGREGATE_CONTROL_LABELS.RESTORATIONS,
            state: restorativeState,
            setChecked: checked => {
                setRestorativesChecked(checked);
                setAppearance(app => ({
                    ...app,
                    restoratives: {
                        ...app.restoratives,
                        CAD: applyVisibility(app.restoratives.CAD, checked),
                    },
                }));
            },
        },
    ];
}

// Toggle switches for the "aggregate" controls that affect multiple parts of the model:
// Upper, Lower, Scans, and Restorations.
const AggregateControls: React.VFC<{
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
}> = ({ appearance, setAppearance }) => {
    const aggregateToggles = useAggregateToggles(appearance, setAppearance);

    return (
        <>
            {aggregateToggles.map(toggle => (
                <FormControlLabel
                    key={toggle.label}
                    control={
                        <Checkbox
                            color={'secondary'}
                            checked={toggle.state.checked}
                            indeterminate={toggle.state.indeterminate}
                            onChange={(_event, checked) => toggle.setChecked(checked)}
                        />
                    }
                    label={toggle.label}
                />
            ))}
        </>
    );
};

/**
 * A FormControl with toggle switches to control visibility for various parts of a design project.
 * By default, includes "aggregate" controls for the entire upper and lower jaw, all scans, and
 * all restoratives, plus controls for individual restoratives. If `hideAggregateControls` is true,
 * only includes controls for individual restoratives.
 **/
export const ModelVisibilityToggler: React.VFC<{
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    hideAggregateControls?: boolean;
}> = ({ appearance, setAppearance, hideAggregateControls }) => {
    const classes = useStyles();

    return (
        <FormControl variant={'standard'} component={'fieldset'} className={classes.formControl}>
            {/* FormLabel must use inline style so that Mui-focused doesn't take over and change the color. */}
            <FormLabel component={'legend'} style={{ marginBottom: 16, color: FlossPalette.DARK_GRAY }}>
                {hideAggregateControls ? `Restoration visibility` : `Model visibility`}
            </FormLabel>
            <FormGroup>
                {!hideAggregateControls && <AggregateControls appearance={appearance} setAppearance={setAppearance} />}
                {_.sortBy(appearance.restoratives.CAD, pma => pma.payloadModel.name).map(pma => (
                    <FormControlLabel
                        key={pma.payloadModel.name}
                        control={
                            <Checkbox
                                checked={pma.appearance.visible}
                                indeterminate={false}
                                color={'secondary'}
                                onChange={(_event, checked) => {
                                    setAppearance(app => {
                                        // CAD visibility is the anchor
                                        const cadAppearance = applyVisibility(
                                            app.restoratives.CAD,
                                            checked,
                                            candidate => candidate.payloadModel.name === pma.payloadModel.name,
                                        );
                                        // HeatMap follows CAD
                                        const cadHeatmapProxy = applyVisibility(
                                            app.restoratives.HeatMap,
                                            checked,
                                            candidate => checked === isParentCadVisible(candidate, cadAppearance),
                                        );
                                        return {
                                            ...app,
                                            restoratives: {
                                                HeatMap: cadHeatmapProxy,
                                                CAD: cadAppearance,
                                            },
                                        };
                                    });
                                }}
                            />
                        }
                        label={pma.payloadModel.name}
                    />
                ))}
            </FormGroup>
        </FormControl>
    );
};
