import type * as THREE from 'three';

// Sets increment such that frame2 = frame1 * increment
export function setIntrinsicIncrement(
    frame1: THREE.Matrix4,
    frame2: THREE.Matrix4,
    increment: THREE.Matrix4,
): THREE.Matrix4 {
    return increment.copy(frame1).invert().multiply(frame2);
}

// Sets increment such that frame2 = increment * frame1
export function setExtrinsicIncrement(
    frame1: THREE.Matrix4,
    frame2: THREE.Matrix4,
    increment: THREE.Matrix4,
): THREE.Matrix4 {
    return increment.copy(frame1).invert().premultiply(frame2);
}
