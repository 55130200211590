import { AttributeName } from './BufferAttributeConstants';
import type { GeometryWithAttributes, IndexedBufferGeometry } from './BufferGeometry.types';
import { remapOcclusalDistance } from './Occlusion.util';
import md5 from 'blueimp-md5';
import * as THREE from 'three';

/**
 * Copies a geometry, preserving the occlusal distance attribute relationships.
 * @param source The geometry to copy from
 * @param target The geometry to copy into
 */
export function copy(source: THREE.BufferGeometry, target: THREE.BufferGeometry): void {
    target.copy(source);
    remapOcclusalDistance(source, target);
}

/**
 * Clones a geometry, preserving the occlusal distance attribute relationships.
 * @param source The geometry to clone
 * @returns The cloned geometry
 */
export function clone(source: THREE.BufferGeometry): THREE.BufferGeometry {
    const clone = source.clone();
    remapOcclusalDistance(source, clone);
    return clone;
}

/**
 * Adds an index to simulate an unindexed geometry.
 */
export function addIndexAsUnindexed(geometry: THREE.BufferGeometry): void {
    const positionAttr = geometry.getAttribute(AttributeName.Position);
    if (!positionAttr) {
        return;
    }

    const index = new THREE.BufferAttribute(new Uint32Array(positionAttr.count), 1);
    for (let i = 0; i < index.count; i++) {
        index.setX(i, i);
    }
    geometry.setIndex(index);
}

/**
 * Calculates a hash of the geometry's vertex positions.
 */
export function getVerticesHash(geometry: THREE.BufferGeometry): string {
    const position = geometry.getAttribute(AttributeName.Position);
    return position ? md5(position.array.toString()) : '';
}

export function isIndexedGeometry(geom: THREE.BufferGeometry): geom is IndexedBufferGeometry {
    return geom.getIndex() !== null;
}

export function getFacetVerts(geom: IndexedBufferGeometry, fIdx: number) {
    const index = geom.getIndex();
    return [index.getX(3 * fIdx), index.getY(3 * fIdx), index.getZ(3 * fIdx)] as const;
}

export function hasBufferAttribute<A extends AttributeName>(
    geom: THREE.BufferGeometry,
    attribute: A,
): geom is GeometryWithAttributes<A> {
    return geom.hasAttribute(attribute);
}
