import { useListAbutmentPartsQuery } from '@orthly/graphql-react';
import type { LabsGqlAbutmentPartDto } from '@orthly/graphql-schema';
import React, { createContext } from 'react';

const ListAbutmentPartsContext = createContext<{
    abutmentPartsMap: Map<string, LabsGqlAbutmentPartDto>;
    abutmentPartsLoading: boolean;
}>({
    abutmentPartsMap: new Map(),
    abutmentPartsLoading: true,
});

export function ListAbutmentPartsProvider({ children }: { children: React.ReactNode }) {
    const { data: abutmentParts, loading: abutmentPartsLoading } = useListAbutmentPartsQuery({
        variables: { withArchived: true },
        fetchPolicy: 'cache-first',
    });
    const listAbutmentParts = abutmentParts?.listAbutmentParts;
    const abutmentPartsMap = React.useMemo(() => {
        return new Map(listAbutmentParts?.map(abutmentPart => [abutmentPart.id, abutmentPart]) ?? []);
    }, [listAbutmentParts]);
    return (
        <ListAbutmentPartsContext.Provider value={{ abutmentPartsMap, abutmentPartsLoading }}>
            {children}
        </ListAbutmentPartsContext.Provider>
    );
}

export function useAbutmentPartsMap(): Map<string, LabsGqlAbutmentPartDto> {
    const context = React.useContext(ListAbutmentPartsContext);
    return context?.abutmentPartsMap ?? new Map();
}

export function useAbutmentPartsLoading(): boolean {
    const context = React.useContext(ListAbutmentPartsContext);
    return context?.abutmentPartsLoading ?? true;
}
