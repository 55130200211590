import { JawAppearanceController } from './JawAppearanceController';
import type { ModelAppearance } from './ModelAppearanceTypes';
import { PastRestorativesAppearanceController } from './PastRestorativesAppearanceController';
import { PrintedModelsAppearanceController } from './PrintedModelsAppearanceController';
import { RestorativesAppearanceController } from './RestorativesAppearanceController';
import { ScansAppearanceController } from './ScansAppearanceController';
import { Card, Collapse, Divider, Icon, IconButton, List, ListItem, ListItemText } from '@orthly/ui-primitives';
import React from 'react';

const controlsContainerStyle = {
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '4px',
    width: 380,
};

type ModelAppearanceToolbarProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    showOrderScans?: boolean;
    onCollapseChange?: () => void;
    isExpanded?: boolean;
    enableSelectForEditing?: boolean;
};

/**
 * This toolbar controls model appearances for the full ModelViewer scene.
 *
 * @component
 */
export const ModelAppearanceToolbar: React.VFC<ModelAppearanceToolbarProps> = ({
    appearanceSettings,
    onAppearanceChange,
    showOrderScans = false,
    onCollapseChange,
    isExpanded,
    enableSelectForEditing,
}) => {
    if (
        appearanceSettings.lowerJaw.length === 0 &&
        appearanceSettings.upperJaw.length === 0 &&
        appearanceSettings.scans.length === 0
    ) {
        return null;
    }

    return (
        <Card
            raised={true}
            style={controlsContainerStyle as React.CSSProperties}
            role={'toolbar'}
            aria-label={'View Tools'}
            data-testid={isExpanded ? 'view-tools-expanded' : 'view-tools-collapsed'}
        >
            <ListItem
                alignItems={'center'}
                style={{
                    justifyContent: 'flex-end',
                    padding: 8,
                }}
            >
                <ListItemText primaryTypographyProps={{ variant: 'h5' }} primary={'Views'} />
                <IconButton
                    size={'small'}
                    onClick={onCollapseChange}
                    role={'button'}
                    aria-label={isExpanded ? 'Collapse' : 'Expand'}
                >
                    <Icon icon={isExpanded ? 'ExpandMoreIcon' : 'ChevronRight'} />
                </IconButton>
            </ListItem>
            <Divider />
            <Collapse in={isExpanded}>
                <List>
                    <JawAppearanceController
                        appearanceSettings={appearanceSettings}
                        onAppearanceChange={onAppearanceChange}
                    />

                    <RestorativesAppearanceController
                        appearanceSettings={appearanceSettings}
                        onAppearanceChange={onAppearanceChange}
                    />

                    <PastRestorativesAppearanceController
                        appearanceSettings={appearanceSettings}
                        onAppearanceChange={onAppearanceChange}
                    />

                    {showOrderScans && (
                        <ScansAppearanceController
                            appearanceSettings={appearanceSettings}
                            onAppearanceChange={onAppearanceChange}
                            enableSelectForEditing={enableSelectForEditing}
                        />
                    )}
                    <PrintedModelsAppearanceController
                        appearanceSettings={appearanceSettings}
                        onAppearanceChange={onAppearanceChange}
                    />
                </List>
            </Collapse>
        </Card>
    );
};
