import { useFeatureFlag } from './LaunchDarkly';
import React from 'react';

export const InfraUpdateTools: React.FC = props => {
    const { value: infraExperiment } = useFeatureFlag('infraExperiment');

    React.useEffect(() => {
        window.sessionStorage.setItem('ffInfraExperiment', infraExperiment ?? '');
    }, [infraExperiment]);

    if (!props.children) {
        return null;
    }

    return <></>;
};
