import { isSection, isSoftTissueModel } from '../ModelViewer/CaseParser.util';
import type { ModelPayloadItem } from '../ModelViewer/ModelViewerTypes';
import { GroupAppearanceController } from './GroupAppearanceController';
import { ModelAppearanceController } from './ModelAppearanceController';
import type { DisabledControls, ItemAppearance, ModelAppearance, PayloadModelAppearance } from './ModelAppearanceTypes';
import { getAllDisabledControls } from './ModelAppearanceTypes';
import { printedModelItemAppearanceSetter } from './appearanceSetters';
import { makeStyles } from '@orthly/ui-primitives';
import React from 'react';

type PrintedModelsAppearanceControllerProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
};

type PrintedItemProps = {
    payloadModelAppearance: PayloadModelAppearance;
    appearanceSettings: ModelAppearance;
    setAppearance: (update: (current: ItemAppearance) => ItemAppearance) => void;
    itemIndex: number;
};

const disabledControls: DisabledControls = getAllDisabledControls();

const PrintedItem: React.VFC<PrintedItemProps> = ({
    appearanceSettings,
    payloadModelAppearance,
    setAppearance,
    itemIndex,
}) => {
    return (
        <ModelAppearanceController
            key={`printed_model_${appearanceSettings.printedModels}_${itemIndex}`}
            text={payloadModelAppearance.payloadModel.name}
            disabledControls={disabledControls}
            setAppearance={setAppearance}
            appearance={payloadModelAppearance.appearance}
        />
    );
};

const getGroupName = (payload: ModelPayloadItem): string | undefined => {
    if (isSection(payload, 'unsectioned')) {
        return `Unsectioned`;
    }
    if (isSection(payload, 'sectioned')) {
        return `Sectioned`;
    }
    if (payload.path.toLowerCase().includes('tooth')) {
        return 'Die Item';
    }

    if (isSoftTissueModel(payload)) {
        return 'Soft Tissue';
    }
    return undefined;
};

const getItemGroups = (printedItems: PayloadModelAppearance[]) => {
    return printedItems.reduce((res: string[], item) => {
        const groupName = getGroupName(item.payloadModel);
        if (!groupName) {
            return res;
        }
        if (res.indexOf(groupName) === -1) {
            res.push(groupName);
        }
        return res;
    }, []);
};

const useStyles = makeStyles(() => ({
    filters: {
        display: 'flex',
        justifyContent: 'flx-start',
        flexWrap: 'wrap',
        '& > *': {
            margin: '4px',
        },
    },
    list: {
        overflowX: 'hidden',
    },
}));

export const PrintedModelsAppearanceController: React.FC<PrintedModelsAppearanceControllerProps> = ({
    appearanceSettings,
    onAppearanceChange,
}) => {
    const [itemGroups, setItemGroups] = React.useState<string[]>([]);
    const printedItems = appearanceSettings.printedModels;

    React.useEffect(() => {
        setItemGroups(getItemGroups(printedItems));
    }, [printedItems]);

    const classes = useStyles();

    const printedModelElements = printedItems.reduce(
        (res: Record<string, JSX.Element[]>, payloadModelAppearance, index) => {
            const groupName = getGroupName(payloadModelAppearance.payloadModel);
            if (!groupName) {
                return res;
            }
            const setAppearance = printedModelItemAppearanceSetter(onAppearanceChange, index);
            const item = (
                <PrintedItem
                    key={`${index}`}
                    itemIndex={index}
                    appearanceSettings={appearanceSettings}
                    setAppearance={setAppearance}
                    payloadModelAppearance={payloadModelAppearance}
                />
            );
            return {
                ...res,
                [groupName]: [...(res[groupName] ?? []), item],
            };
        },
        {},
    );

    const handleGroupAppearanceChange = React.useCallback(
        childrenSettings => {
            onAppearanceChange(current => {
                return {
                    ...current,
                    printedModels: childrenSettings,
                };
            });
        },
        [onAppearanceChange],
    );

    if (printedItems.length === 0) {
        return null;
    }

    return (
        <GroupAppearanceController
            text={'Models'}
            disabledControls={disabledControls}
            appearanceArray={printedItems}
            onAppearanceChange={handleGroupAppearanceChange}
        >
            {itemGroups.map(itemGroup => {
                const handleAppearanceChange = (childrenSettings: PayloadModelAppearance[]) => {
                    const filteredItems = appearanceSettings.printedModels.map(appearanceSetting => {
                        const itemSettings = childrenSettings.find(
                            childrenSetting =>
                                childrenSetting.payloadModel.name === appearanceSetting.payloadModel.name,
                        );
                        if (itemSettings) {
                            return itemSettings;
                        }
                        return appearanceSetting;
                    });
                    handleGroupAppearanceChange(filteredItems);
                };
                const filteredItems = printedItems.filter(item => getGroupName(item.payloadModel) === itemGroup);
                return (
                    <div key={itemGroup} style={{ marginLeft: 12 }}>
                        <GroupAppearanceController
                            text={itemGroup}
                            disabledControls={disabledControls}
                            appearanceArray={filteredItems}
                            onAppearanceChange={handleAppearanceChange}
                        >
                            <div className={classes.list}>{printedModelElements[itemGroup]}</div>
                        </GroupAppearanceController>
                    </div>
                );
            })}
        </GroupAppearanceController>
    );
};
