import { useOrdersToolbarSelectProps } from './useOrdersToolbarSelectProps';
import type { ItemSearchControls, ItemSearchResults } from '@orthly/dentin';
import { useSearchAutocomplete } from '@orthly/dentin';
import { SimpleAutocomplete, useDebouncedState } from '@orthly/ui';
import { FlossPalette, InputAdornment, SearchIcon } from '@orthly/ui-primitives';
import React from 'react';

interface OrdersOverviewToolbarSearchProps {
    onPickOrder: (orderId: string) => void;
    useOrderSearchResults: (controls?: ItemSearchControls) => ItemSearchResults;
    autoFocus?: boolean;
}

export const OrdersToolbarSearch: React.VFC<OrdersOverviewToolbarSearchProps> = props => {
    const { onPickOrder, useOrderSearchResults, autoFocus } = props;
    const selectProps = useOrdersToolbarSelectProps();
    const { search, loading, options, onInputChange, onSelectOption, onBlurOrFocus, noOptionsText } =
        useSearchAutocomplete(onPickOrder, useOrderSearchResults);

    // We debounce to avoid constantly hitting the backend while the user is typing.
    // Locally they will see their changes, and after a few seconds it'll propogate to redux and the backend.
    const [localSearch, setLocalSearch] = useDebouncedState(search ?? null, onInputChange, 500);

    return (
        <SimpleAutocomplete
            label={'Search'}
            options={options}
            variant={'filled'}
            initialInputValue={localSearch ?? null}
            onInputChange={value => setLocalSearch(value)}
            onChange={onSelectOption}
            AutocompleteProps={{ loading, noOptionsText, style: { width: '100%' } }}
            autoFocus={autoFocus}
            TextFieldProps={{
                placeholder: 'Type to search...',
                onBlur: onBlurOrFocus,
                onFocus: onBlurOrFocus,
                InputLabelProps: { classes: selectProps.InputLabelProps?.classes },
                InputProps: {
                    endAdornment: (
                        <InputAdornment position={'end'} style={{ height: 30, paddingBottom: 8 }}>
                            <SearchIcon color={'action'} />
                        </InputAdornment>
                    ),
                    disableUnderline: true,
                    style: {
                        paddingRight: 16,
                        background: FlossPalette.WHITE,
                        border: 'none',
                        borderRadius: 0,
                        boxShadow: 'none',
                    },
                },
            }}
        />
    );
};
