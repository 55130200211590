import { DracoLoader } from '../../utils/draco/DracoLoader';
import type { PayloadModelAppearance } from '../ModelAppearance';
import type { Model, ModelPayloadItem } from './ModelViewerTypes';
import { Jaw } from './ModelViewerTypes';
import { CTMLoader } from './ctm/CTMLoader';
import { AttributeName, CustomPLYLoader, DcmGeometryInjector, PLYLoader, STLLoader } from '@orthly/forceps';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import type { MarginLine } from '@orthly/shared-types';
import _ from 'lodash';

export function stlFileUriToLabel(uri: string): string {
    // https://an/example/filename3.stl -> filename3.stl
    const basename = uri.split('/').slice(-1)[0];
    if (!basename) {
        return uri;
    }

    // filename3.stl -> ["Filename", "3"]
    const [label = uri, numbering = ''] = _.upperFirst(basename.split('.').slice(0, -1).join('.')).split(/(\d+)/);

    // Can return immediately if the numbering is 0, as in filename0.stl -> Filename
    if (!parseInt(numbering)) {
        return label;
    }

    // ["Filename", "3"] -> Filename (3)
    return `${label} (${numbering})`;
}

// model loader from buffer object
// conveniently chooses specific model based on file extension
export async function loadModel({
    buffer,
    filePath,
    useExperimentalPlyLoader,
    shouldCalculateUVs,
}: {
    buffer: ArrayBuffer;
    filePath: string;
    useExperimentalPlyLoader: boolean;
    shouldCalculateUVs: boolean;
}): Promise<Model> {
    const lowerCaseFilePath = filePath.toLowerCase();

    if (lowerCaseFilePath.includes('.drc')) {
        const geometry = await DracoLoader.load(buffer, {
            customAttributes: {
                [AttributeName.ThicknessDistance]: 'float',
                [AttributeName.ProximalDistance]: 'float',
                [AttributeName.OcclusalDistance]: 'float',
            },
        });
        const hasTexture = !!geometry.attributes.uv;
        return { geometry, modelType: 'drc', header: { hasTexture } };
    }

    if (lowerCaseFilePath.includes('.ply')) {
        if (useExperimentalPlyLoader) {
            const loader = new CustomPLYLoader();
            return loader.loadAsync(buffer, shouldCalculateUVs);
        }

        const loader = new PLYLoader();
        return loader.loadAsync(buffer, shouldCalculateUVs);
    }

    if (lowerCaseFilePath.includes('.ctm')) {
        const loader = new CTMLoader();
        return loader.loadAsync(buffer);
    }

    if (lowerCaseFilePath.includes('.dcm')) {
        const dcmString = buffer.toString();
        const geometryInjector = DcmGeometryInjector.tryBuildDCM(dcmString);
        if (!geometryInjector) {
            return Promise.reject('Could not build geometry from DCM');
        }

        const geom = geometryInjector.buildGeometry({ applyTextureCoords: false });

        return Promise.resolve({ geometry: geom, modelType: 'dcm' });
    }

    const loader = new STLLoader();
    return loader.loadAsync(buffer);
}

export function isDynamicHeatmapsDataAvailable(model_payload_items: ModelPayloadItem[]): boolean {
    const attributesDefined = (attributes: any) =>
        attributes[AttributeName.ThicknessDistance] !== undefined ||
        attributes[AttributeName.ProximalDistance] !== undefined ||
        attributes[AttributeName.OcclusalDistance] !== undefined ||
        attributes[AttributeName.VertexDisplacement] !== undefined ||
        attributes[AttributeName.SurfaceDisplacement] !== undefined;

    return model_payload_items
        .filter(
            pi =>
                pi.type === LabsGqlOrderDesignScanType.QcExtras ||
                pi.type === LabsGqlOrderDesignScanType.Cad ||
                pi.type === LabsGqlOrderDesignScanType.Scans,
        )
        .some(pi => attributesDefined(pi.model.geometry.attributes));
}

/*
 * A  more rigid check to ensure all basic heatmaps exist on a
 * provided array of model_payload_items
 * */
export function isPrimaryDynamicHeatmapsDataAvailable(model_payload_items: ModelPayloadItem[]): boolean {
    const attributesDefined = (attributes: any) =>
        attributes[AttributeName.ThicknessDistance] !== undefined &&
        attributes[AttributeName.ProximalDistance] !== undefined &&
        attributes[AttributeName.OcclusalDistance] !== undefined;

    return model_payload_items.some(pi => attributesDefined(pi.model.geometry.attributes));
}

export function getMarginAssociatedGeometryFromNewPayload(
    margin: MarginLine,
    jawPayloads: PayloadModelAppearance[],
): PayloadModelAppearance | undefined {
    if (margin.tooth < 1 || margin.tooth > 32) {
        return undefined;
    }
    const targetJaw = ToothUtils.toothIsUpper(margin.tooth) ? Jaw.Upper : Jaw.Lower;
    const exclusionRegex = /preprep/i;
    return jawPayloads.find(
        j =>
            (j.payloadModel.name.includes(targetJaw) || j.payloadModel.jaw?.includes(targetJaw)) &&
            !exclusionRegex.test(j.payloadModel.name),
    );
}
