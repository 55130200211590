import type { ScanDefinition } from './Scan.types';
import { ThreeOxzGenerator } from './ThreeOxzGenerator';
import type { ScanEntry, ThreeshapeScanName } from './ThreeOxzGenerator.types';
import type { ICartItemV2DTO } from '@orthly/items';
import JsZip from 'jszip';
import _ from 'lodash';
import { v5 as uuidv5 } from 'uuid';

interface ThreeOxzReloaderData {
    order_id: string;
    items: ICartItemV2DTO[];
    scans: ScanEntry[];
    patient: {
        id: string;
        first_name: string;
        last_name: string;
        birthday: string;
    };
    scanner_id: string;
    doctor_preferences_id: string;
}

export const ThreeshapeScanNameToScanDefinition: Record<ThreeshapeScanName, ScanDefinition> = {
    BiteScan: {
        type: 'bite',
        bite_number: 1,
        variant: 'left',
    },
    LowerJawScan: {
        type: 'jaw',
        variant: 'lower',
        is_pre_prep: false,
    },
    UpperJawScan: {
        type: 'jaw',
        variant: 'upper',
        is_pre_prep: false,
    },
    LowerJawPrePreparationScan: {
        type: 'jaw',
        variant: 'lower',
        is_pre_prep: true,
    },
    UpperJawPrePreparationScan: {
        type: 'jaw',
        variant: 'upper',
        is_pre_prep: true,
    },
    LowerJawDentureScan: {
        type: 'denture',
        variant: 'lower',
    },
    UpperJawDentureScan: {
        type: 'denture',
        variant: 'upper',
    },
    LowerImplantScan: {
        type: 'emergence_profile',
        variant: 'lower',
    },
    UpperImplantScan: {
        type: 'emergence_profile',
        variant: 'upper',
    },
};

// Only exported for testing purposes. Not intended for use outside this file.
export function getCombinedScans(originalScans: ScanEntry[], newScans: ScanEntry[]) {
    return originalScans.reduce<ScanEntry[]>((state, scan) => {
        const doesScanAlreadyExist = state.find(candidate => candidate.scan_type === scan.scan_type);

        // If the scan type doesn't exist in the final state yet, we are safe to add ours.
        if (!doesScanAlreadyExist) {
            return [...state, scan];
        }

        // If the scan already exists, check if it's a bite and then we can add them as long as there's not already 2 bites.
        const currentBiteCount = state.filter(candidate => candidate.scan_type === 'BiteScan').length;
        if (scan.scan_type === 'BiteScan' && currentBiteCount < 2) {
            return [...state, scan];
        }

        // Our scan is replaced by the new set of scans, so we'll just drop ours.
        return state;
    }, newScans);
}

export class ThreeOxzReloader {
    constructor(private readonly data: ThreeOxzReloaderData) {}

    static scanNameToScanEntry(orderId: string, scanName: string, buffer: Buffer): ScanEntry | undefined {
        for (const [threeshapeScanName, scanDefinition] of Object.entries(ThreeshapeScanNameToScanDefinition)) {
            if (scanName.startsWith(threeshapeScanName)) {
                return {
                    buffer,
                    scan_type: threeshapeScanName as ThreeshapeScanName,
                    definition: scanDefinition,
                    id: uuidv5(scanName, orderId),
                    file_name: scanName,
                };
            }
        }

        return undefined;
    }

    static async loadThreeoxz(
        data: Omit<ThreeOxzReloaderData, 'scans'>,
        threeoxzBuffer: Buffer | ArrayBuffer | undefined,
    ) {
        if (!threeoxzBuffer) {
            return new ThreeOxzReloader({ ...data, scans: [] });
        }

        const zip = await JsZip.loadAsync(threeoxzBuffer);
        const scanEntries = await Promise.all(
            Object.keys(zip.files).map(async relativePath => {
                if (!relativePath.toLowerCase().endsWith('.dcm')) {
                    return undefined;
                }

                const dcmBuffer = await zip.file(relativePath)?.async('nodebuffer');
                if (!dcmBuffer) {
                    return undefined;
                }

                return ThreeOxzReloader.scanNameToScanEntry(data.order_id, relativePath, dcmBuffer);
            }),
        );

        return new ThreeOxzReloader({
            ...data,
            scans: _.compact(scanEntries),
        });
    }

    get scans() {
        return this.data.scans;
    }

    async regenerate() {
        const generator = new ThreeOxzGenerator({
            ...this.data,
            margin_lines: [],
            id: this.data.order_id,
            creation_date: new Date(),
        });

        return generator.generate();
    }

    async regenerateWithScans(scans: ScanEntry[]) {
        const generator = new ThreeOxzGenerator({
            ...this.data,
            scans: getCombinedScans(this.data.scans, scans),
            margin_lines: [],
            id: this.data.order_id,
            creation_date: new Date(),
        });

        return generator.generate();
    }
}
