import { getMarginColorFilter } from '../GuidedWaxup.util';
import type { LimitedAppearanceFilterBools, ModelAppearance } from '@orthly/dentin';
import { applyAppearanceFilter, useTeethIndices } from '@orthly/dentin';
import { ToothUtils } from '@orthly/items';
import { ModelTextureIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Switch,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    colorToggleContainer: {
        paddingLeft: 20,
        paddingTop: 15,
        flexDirection: 'row',
    },
    iconTextContainer: {
        padding: 5,
        alignItems: 'center',
    },
    activeToggle: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10,
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            '&:hover': {
                backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            },
        },
    },
    inactiveToggle: {
        [theme.breakpoints.down('lg')]: {
            paddingTop: 10,
            backgroundColor: FlossPalette.WHITE,
            '&:hover': {
                backgroundColor: FlossPalette.WHITE,
            },
        },
    },
}));

export const MarginViewControls: React.VFC<{
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
}> = ({ appearance, setAppearance }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const teethIndices = useTeethIndices(appearance);
    // -1 is a placeholder value for satisfying typescript type checking
    const activeTooth = teethIndices[0] ?? -1;
    const showUpperJaw = ToothUtils.toothIsUpper(activeTooth);
    const marginAppearanceFilterValues = getMarginColorFilter(showUpperJaw);
    const [appearanceFilterValues, setAppearanceFilterValues] =
        React.useState<LimitedAppearanceFilterBools>(marginAppearanceFilterValues);
    const toggleStateText = appearanceFilterValues.showColor ? 'on' : 'off';

    const toggleModelColor = () => {
        setAppearanceFilterValues(currentAppearanceFilterValues => {
            setAppearance(currentApperance =>
                applyAppearanceFilter(currentApperance, {
                    ...appearanceFilterValues,
                    showColor: !appearanceFilterValues.showColor,
                }),
            );
            return {
                ...currentAppearanceFilterValues,
                showColor: !currentAppearanceFilterValues.showColor,
            };
        });
    };

    return (
        <Grid item container className={classes.colorToggleContainer}>
            <Button
                variant={'secondary'}
                onClick={toggleModelColor}
                className={appearanceFilterValues.showColor ? classes.activeToggle : classes.inactiveToggle}
            >
                <Grid container className={classes.iconTextContainer} direction={isMobile ? 'column' : 'row'}>
                    <ModelTextureIcon />
                    {!isMobile && <Switch color={'secondary'} checked={appearanceFilterValues.showColor} />}
                    Color {!isMobile && toggleStateText}
                </Grid>
            </Button>
        </Grid>
    );
};
