import type { QCLegendProps } from './ColorRamp.types';
import { QCColorLegendHorizontal } from './QCColorLegendHorizontal';
import { QCColorLegendVertical } from './QCColorLegendVertical';
import React from 'react';

export const QCColorLegend: React.VFC<QCLegendProps> = props => {
    if (props.horizontal) {
        return <QCColorLegendHorizontal {...props} />;
    } else {
        return <QCColorLegendVertical {...props} />;
    }
};
