/* eslint-disable max-lines */
import { OrderDetailFactRow, OrderDetailBlock } from '../OrderDetails/OrderDetailBlock';
import { OrderDetailImagesContent } from '../OrderDetails/OrderDetailImagesBlock';
import { isDenturesFulfillmentType } from './dentures-display.util';
import { getDenturesSingleFulfillmentDisplayName } from './dentures-display.util';
import type {
    LabsGqlLabOrderSingleFulfillmentFragment,
    LabsGqlDenturesSingleFulfillmentFragment,
    LabsGqlDenturesFulfillmentCoreFragment,
    LabsGqlDenturesFulfillmentFixupFragment,
    LabsGqlDenturesFulfillmentCoreArchFragment,
    LabsGqlDenturesFulfillmentSinglePhotoFragment,
    LabsGqlOrderItemShadeFragment,
    LabsGqlDenturesFulfillmentWaxRimFragment,
    LabsGqlOrder,
    LabsGqlDenturesFulfillmentTryInFollowupFragment,
    LabsGqlDenturesFulfillmentFixupIssueFragment,
    LabsGqlDenturesFulfillmentImplantSupportFragment,
} from '@orthly/graphql-operations';
import {
    LabsGqlDenturesSingleFulfillmentType,
    LabsGqlDenturesSingleFulfillmentArchType,
    LabsGqlDenturesHandleDiastemaOption,
} from '@orthly/graphql-schema';
import { Text, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const ItemHeader: React.FC = ({ children }) => (
    <Text variant={'body2'} medium={true} style={{ marginTop: 4 }}>
        {children}
    </Text>
);

interface ItemFactRowProps {
    title: string;
    value: React.ReactNode;
    flexBasisLeft?: number;
}

export const ItemFactRow: React.VFC<ItemFactRowProps> = ({ title, value, flexBasisLeft }) => (
    <OrderDetailFactRow
        typographyVariant={'body1'}
        title={title}
        value={value || '(none)'}
        flexBasisLeft={flexBasisLeft ?? 200}
        wrapperProps={{ alignItems: 'flex-start' }}
        valueStyle={{ whiteSpace: 'normal' }}
    />
);

interface BooleanItemFactRowProps extends Omit<ItemFactRowProps, 'value'> {
    value: boolean;
}

// simple wrapper to display boolean values as 'Yes' or 'No
const BooleanItemFactRow: React.VFC<BooleanItemFactRowProps> = ({ value, ...props }) => (
    <ItemFactRow {...props} value={value ? 'Yes' : 'No'} />
);

const SubItemFactRow: React.VFC<{ title: string; value: React.ReactNode }> = ({ title, value }) => (
    <ItemFactRow title={title} value={value} flexBasisLeft={160} />
);

const ShadeItem: React.VFC<{ shade: LabsGqlOrderItemShadeFragment }> = ({ shade }) => (
    <>
        <Text variant={'body2'} medium={true}>
            {_.capitalize(shade.name)}:
        </Text>
        <Text variant={'body2'} style={{ marginLeft: 4, marginRight: 10 }}>
            {shade.value}
        </Text>
    </>
);

interface CoreArchContentProps {
    arch: LabsGqlDenturesFulfillmentCoreArchFragment;
    location: 'Upper' | 'Lower';
}

const CoreArchContent: React.FC<CoreArchContentProps> = ({ arch, location }) => (
    <>
        <ItemHeader>{location} Arch</ItemHeader>
        <Grid container style={{ borderLeft: '2px solid silver', marginTop: 2, paddingLeft: 10 }}>
            <SubItemFactRow title={'Type'} value={_.startCase(arch.type)} />
            {arch.type === LabsGqlDenturesSingleFulfillmentArchType.Copy && !_.isNil(arch.is_exact_copy) && (
                <SubItemFactRow
                    title={'Exact Copy?'}
                    value={arch.is_exact_copy ? 'Yes' : 'No (See "Copy Change Notes")'}
                />
            )}
            <SubItemFactRow title={'Base Material'} value={arch.fabrication_method.join(', ')} />
            <SubItemFactRow title={'Teeth Type'} value={arch.teeth_quality.join(', ')} />
            <SubItemFactRow
                title={'Shades'}
                value={
                    <Grid container direction={'row'}>
                        {arch.shades.map(shade => (
                            <ShadeItem key={shade.name} shade={shade} />
                        ))}
                    </Grid>
                }
            />
        </Grid>
    </>
);

interface ImagesContentProps {
    photos: LabsGqlDenturesFulfillmentSinglePhotoFragment[];
    namePrefix: string;
    orderId: string;
}

const ImagesContent: React.FC<ImagesContentProps> = ({ orderId, photos, namePrefix }) => {
    const imageSources = React.useMemo(() => {
        return photos.map((photo, i) => ({
            source: photo.path,
            title: `${namePrefix} #${i + 1}`,
        }));
    }, [photos, namePrefix]);

    if (imageSources.length === 0) {
        return null;
    }

    return (
        <OrderDetailImagesContent
            orderId={orderId}
            sources={imageSources}
            wrapperProps={{ style: { paddingTop: 4, marginLeft: -8 } }}
        />
    );
};

const displayFixupIssues =
    (isTryIn: boolean, orderId: string) => (issue: LabsGqlDenturesFulfillmentFixupIssueFragment, idx: number) => {
        const title = `${isTryIn ? 'Try In ' : ''}Followup Concern ${idx + 1}`;
        return (
            <React.Fragment key={idx}>
                <Grid container>
                    <ItemHeader>{title}</ItemHeader>
                </Grid>
                <Grid container style={{ borderLeft: '2px solid silver', marginTop: 4, paddingLeft: 10 }}>
                    <SubItemFactRow title={'Type'} value={_.startCase(issue.type)} />
                    <SubItemFactRow title={'Details'} value={issue.details} />
                    <ImagesContent orderId={orderId} photos={issue.photos} namePrefix={title} />
                </Grid>
            </React.Fragment>
        );
    };

interface FixupFulfillmentContentProps {
    fixupFulfillment: LabsGqlDenturesFulfillmentFixupFragment;
    orderId: string;
}

const FixupFulfillmentContent: React.FC<FixupFulfillmentContentProps> = ({ fixupFulfillment, orderId }) => {
    const { upper_arch, lower_arch } = fixupFulfillment;
    return (
        <>
            {!!upper_arch?.fixup_issues.length && <ItemHeader>Upper Arch</ItemHeader>}
            {upper_arch?.fixup_issues.map((issue, idx) => displayFixupIssues(false, orderId)(issue, idx))}
            {!!lower_arch?.fixup_issues.length && <ItemHeader>Lower Arch</ItemHeader>}
            {lower_arch?.fixup_issues.map((issue, idx) => displayFixupIssues(false, orderId)(issue, idx))}
        </>
    );
};

interface TryInFollowupFulfillmentContentProps {
    tryInFollowFulfillment: LabsGqlDenturesFulfillmentTryInFollowupFragment;
    orderId: string;
}

const TryInFollowupFulfillmentContent: React.FC<TryInFollowupFulfillmentContentProps> = ({
    tryInFollowFulfillment,
    orderId,
}) => {
    const { upper_arch, lower_arch } = tryInFollowFulfillment;

    return (
        <>
            {!!upper_arch?.followup_issues.length && <ItemHeader>Upper Arch</ItemHeader>}
            {upper_arch?.followup_issues.map((issue, idx) => displayFixupIssues(true, orderId)(issue, idx))}
            {!!lower_arch?.followup_issues.length && <ItemHeader>Lower Arch</ItemHeader>}
            {lower_arch?.followup_issues.map((issue, idx) => displayFixupIssues(true, orderId)(issue, idx))}
        </>
    );
};

const ImplantSupportFactRows: React.VFC<{ implantSupport: LabsGqlDenturesFulfillmentImplantSupportFragment }> = ({
    implantSupport,
}) => (
    <>
        <ItemFactRow title={`Housings`} value={`Chairside Pickup (Denture fabricated with recesses)`} />
        <ItemFactRow title={`Collar Heights`} value={implantSupport.collar_heights_mm} />
        {!_.isNil(implantSupport.horseshoe) && (
            <BooleanItemFactRow title={`Horseshoe?`} value={implantSupport.horseshoe} />
        )}
    </>
);

interface CoreFulfillmentContentProps {
    coreFulfillment: LabsGqlDenturesFulfillmentCoreFragment;
    orderId: string;
}

const CoreFulfillmentContent: React.FC<CoreFulfillmentContentProps> = ({ coreFulfillment, orderId }) => {
    const {
        upper_arch,
        lower_arch,
        smile_style,
        festooning_level,
        handle_diastema,
        correct_occlusal_scheme,
        add_stippling,
        add_post_dam,
        bite_adjustment,
        midline_correction,
        copy_change_notes,
        notes,
        photos,
        number_of_spares,
        implant_support,
    } = coreFulfillment;

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const item_type = upper_arch && lower_arch ? 'total sets' : upper_arch ? 'upper arches' : 'lower arches';
    return (
        <>
            <ItemFactRow title={`How many ${item_type}?`} value={1 + (number_of_spares ?? 0)} />
            {implant_support && <ImplantSupportFactRows implantSupport={implant_support} />}
            <ItemFactRow title={'Smile Style'} value={_.startCase(smile_style)} />
            {!_.isNil(festooning_level) && <ItemFactRow title={'Festooning Level'} value={festooning_level} />}
            {!_.isNil(handle_diastema) && handle_diastema !== LabsGqlDenturesHandleDiastemaOption.NoDiastema && (
                <ItemFactRow title={`How to handle diastema?`} value={_.startCase(handle_diastema)} />
            )}
            {!_.isNil(correct_occlusal_scheme) && (
                <BooleanItemFactRow title={`Correct occlusal scheme to Class I?`} value={correct_occlusal_scheme} />
            )}
            {!_.isNil(add_stippling) && <BooleanItemFactRow title={`Add stippling?`} value={add_stippling} />}
            {!_.isNil(add_post_dam) && <BooleanItemFactRow title={`Add a post-dam?`} value={add_post_dam} />}
            {!_.isNil(bite_adjustment) && (
                <ItemFactRow
                    title={`Bite Adjustment`}
                    value={`${_.startCase(bite_adjustment.adjustment)} ${
                        !_.isNil(bite_adjustment.adjustment_distance) ? `${bite_adjustment.adjustment_distance}mm` : ''
                    }`}
                />
            )}
            {!_.isNil(midline_correction) && (
                <ItemFactRow
                    title={`Midline Correction`}
                    value={`${_.startCase(midline_correction.type)} ${
                        !_.isNil(midline_correction.distance) ? `${midline_correction.distance}mm` : ''
                    }`}
                />
            )}
            {/* we intentionally check that these values are false, and not just falsy (undefined or null) */}
            {(upper_arch?.is_exact_copy === false || lower_arch?.is_exact_copy === false) && (
                <ItemFactRow title={'Copy Change Notes'} value={copy_change_notes} />
            )}
            <ItemFactRow title={'Notes'} value={notes} />
            {upper_arch && <CoreArchContent location={'Upper'} arch={upper_arch} />}
            {lower_arch && <CoreArchContent location={'Lower'} arch={lower_arch} />}
            <ImagesContent orderId={orderId} photos={photos} namePrefix={'Core Fulfillment'} />
        </>
    );
};

interface WaxRimFulfillmentContentProps {
    waxRimFulfillment: LabsGqlDenturesFulfillmentWaxRimFragment;
}

const WaxRimFulfillmentContent: React.FC<WaxRimFulfillmentContentProps> = ({ waxRimFulfillment }) => {
    const { implant_support } = waxRimFulfillment;
    return (
        <>
            {implant_support && <ImplantSupportFactRows implantSupport={implant_support} />}
            <ItemFactRow title={'Notes'} value={waxRimFulfillment.notes} />
            <ItemFactRow title={'Wax Rim Arch'} value={waxRimFulfillment.arch} />
        </>
    );
};

interface DenturesSingleFulfillmentContentProps {
    denturesSingleFulfillment: LabsGqlDenturesSingleFulfillmentFragment;
    orderId: string;
}

const DenturesSingleFulfillmentContent: React.FC<DenturesSingleFulfillmentContentProps> = ({
    denturesSingleFulfillment,
    orderId,
}) => {
    if (isDenturesFulfillmentType(denturesSingleFulfillment, LabsGqlDenturesSingleFulfillmentType.WaxRim)) {
        return <WaxRimFulfillmentContent waxRimFulfillment={denturesSingleFulfillment} />;
    }
    if (isDenturesFulfillmentType(denturesSingleFulfillment, LabsGqlDenturesSingleFulfillmentType.Core)) {
        return <CoreFulfillmentContent orderId={orderId} coreFulfillment={denturesSingleFulfillment} />;
    }
    if (isDenturesFulfillmentType(denturesSingleFulfillment, LabsGqlDenturesSingleFulfillmentType.Fixup)) {
        return <FixupFulfillmentContent orderId={orderId} fixupFulfillment={denturesSingleFulfillment} />;
    }
    if (isDenturesFulfillmentType(denturesSingleFulfillment, LabsGqlDenturesSingleFulfillmentType.TryInFollowup)) {
        return <TryInFollowupFulfillmentContent orderId={orderId} tryInFollowFulfillment={denturesSingleFulfillment} />;
    }
    return null;
};

interface DenturesSingleFulfillmentBlockProps {
    denturesSingleFulfillment: LabsGqlDenturesSingleFulfillmentFragment;
    singleFulfillment?: LabsGqlLabOrderSingleFulfillmentFragment;
    orderId: string;
}

const DenturesSingleFulfillmentBlock: React.FC<DenturesSingleFulfillmentBlockProps> = ({
    denturesSingleFulfillment,
    singleFulfillment,
    orderId,
}) => {
    const isComplete =
        denturesSingleFulfillment.__typename === 'DenturesFulfillmentFixupDTO' && denturesSingleFulfillment.is_complete;

    const title = getDenturesSingleFulfillmentDisplayName(denturesSingleFulfillment);

    return (
        <OrderDetailBlock variant={'full'} title={`Dentures ${title} Fulfillment`}>
            <Grid container style={{ marginTop: 4 }}>
                {singleFulfillment && (
                    <ItemFactRow
                        title={'Status'}
                        value={isComplete ? 'Marked as Complete' : singleFulfillment.status}
                    />
                )}
                <DenturesSingleFulfillmentContent
                    orderId={orderId}
                    denturesSingleFulfillment={denturesSingleFulfillment}
                />
            </Grid>
        </OrderDetailBlock>
    );
};

interface OrderDetailDenturesProps {
    order: LabsGqlOrder;
}

export const OrderDetailDentures: React.FC<OrderDetailDenturesProps> = ({ order }) => {
    const { fulfillment, dentures_fulfillment } = order;

    if (!dentures_fulfillment) {
        return null;
    }

    return (
        <>
            <DenturesSingleFulfillmentBlock
                orderId={order.id}
                denturesSingleFulfillment={dentures_fulfillment.current}
                singleFulfillment={fulfillment.current}
            />
            {dentures_fulfillment.history.map((denturesSingleFulfillment, idx) => (
                <DenturesSingleFulfillmentBlock
                    key={idx}
                    orderId={order.id}
                    denturesSingleFulfillment={denturesSingleFulfillment}
                    singleFulfillment={fulfillment.history[idx]}
                />
            ))}
        </>
    );
};
