import type { ModelAppearance } from '../../ModelAppearance';
import { RestorativeView, isTransparent } from '../../ModelAppearance';
import type { CameraPose } from './camera-controls.util';
import type { MountingMatrix } from '@orthly/forceps';
import { ToothUtils } from '@orthly/items';
import { assertNever } from '@orthly/shared-types';
import React from 'react';
import * as THREE from 'three';

/*
 * Parts of this file are meant to be concise and utilitarian
 * Others are meant to be instructive so that someone with less
 * 3D experience can use these as a template to generate their
 * own views if needed.
 * */

// Global Camera Orientations based on MountingMatrix
function makeViewRotationFront(mountMatrix: MountingMatrix): THREE.Matrix4 {
    const Z = mountMatrix.z.clone().normalize();
    const Y = mountMatrix.y.clone().normalize();
    const X = mountMatrix.x.clone().normalize();

    return new THREE.Matrix4().makeBasis(X, Y, Z);
}

export function makeViewRotationSide(mountMatrix: MountingMatrix, side: 'RIGHT' | 'LEFT'): THREE.Matrix4 {
    const Z = side === 'RIGHT' ? mountMatrix.x.clone().negate() : mountMatrix.x.clone();
    const Y = mountMatrix.y.clone();
    const X = Y.clone().cross(Z);

    return new THREE.Matrix4().makeBasis(X, Y, Z);
}

function makeViewRotationOcclusal(
    mountMatrix: MountingMatrix,
    jaw: 'MAXILLARY' | 'MANDIBULAR',
    up: 'ANTERIOR' | 'POSTERIOR' = 'ANTERIOR',
): THREE.Matrix4 {
    // 3Shape convention
    // XYZ = Left, Superior, Anterior and -X -Y -Z = Right, Inferior, Posterior
    // Camera convention
    // XYZ = Right, Up, OutOfScreen and -X, -Y, -Z = Left, Down, LookDirection

    // For this view, we want the camera to be looking up from below if it's Maxillary
    // or down from above if it's Madnibular
    // which means camera -Z (look direction) = mount.Y for maxillary or -mount.Y for mandibular
    const Z = jaw === 'MAXILLARY' ? mountMatrix.y.clone().negate() : mountMatrix.y.clone();

    // We want the up of the camera to be the same as the anterior/posterior
    const Y = up === 'ANTERIOR' ? mountMatrix.z.clone() : mountMatrix.z.clone().negate();

    // knowing we are looking up from the bottom, we could do a bunch of switches
    // based on max, or mand and whether anterior or posterior is up, but by putting
    // our logical switches in Z and Y, I prefer to use cross product and ensure
    // we do so in a right handed sequence to guarantee X is correct and orthogonal
    const X = Y.clone().cross(Z);

    // if we don't trust the orthogonal nature of Z,Y,X we can do a series of cross products
    //const basisAxes = ensureOrthogonal(X, Y, Z)
    //const rotMatrix = new THREE.Matrix4().makeBasis(basisAxes.x ,basisAxes.y, basisAxes.z)
    return new THREE.Matrix4().makeBasis(X, Y, Z);
}

// Global Poses with Camera Positioned 100mm from centers

// This is the prototypical function to create a camera pose from a mountingMatrix
// we have a bunch of these functions and they can be condensed into much more
// succinct or dynamic code. This one serves as the illustration.
export function poseFrontOnView(mountMatrix: MountingMatrix): CameraPose {
    // 3Shape gives us an anatomical Up and an anatomical Forward direction in
    // the design file.  We adopt the convention to call Up=Y, and Z=forward
    // Then we must choose an X that creates a right-handed coordinate system.
    // Point your fingers up, and roll them forward with your right hand...the thumb
    // points Left.   (Y cross Z = X)...in a right handed system, your cross products
    // will always be in order XYZ, YZX, ZXY are valid. ZYX is not!

    // XYZ = Left, Superior, Anterior and -X -Y -Z = Right, Inferior, Posterior

    // THREE.js establishes their own Camera convention such that
    //  -Z is the direction that the viewer is looking so that +X and +Y
    // are Right and Up respectively
    // XYZ = Right, Up, OutOfScreen and -X, -Y, -Z = Left, Down, LookDirection

    // For this view, we want the camera to be looking backwards
    // which means camera -Z (look direction) = mount -Z (posterior direction)
    const Z = mountMatrix.z.clone().normalize();

    // We want the up of the camera to be the same as the occlusal
    const Y = mountMatrix.y.clone().normalize();

    // knowing we are looking at the mouth head on, we expect camera
    // right to be patient left.  which...left is X
    const X = mountMatrix.x.clone().normalize();

    // if we did not trust the orthogonal nature of Z,Y,X
    // we could have done a cross product to generate X

    // It now becomes apparent why we chose ("arbitrarily") to call Z forward and Y up
    // as it conveniently aligns with looking front on at the patient and is a nice
    // mental landmark from which to help construct other views.

    // Generate a rotation matrix from those basis axes
    const rotMatrix = new THREE.Matrix4().makeBasis(X, Y, Z);

    // all that remains is to position the camera away from the origin
    // I like to think of this as start the camera at the target
    // and "back the camera away", eg move it along its own Z axis
    // we could use the bounding sphere to decide the distance but let's just go 100mm away
    const locVector = mountMatrix.origin.clone().add(Z.clone().multiplyScalar(100));

    // The camera target will be the mounting matrix origin
    return { locVector, rotMatrix, targetVector: mountMatrix.origin };
}

/*
 * Looking AT the specified side of the patient from that side
 * eg, looking at the right teeth, from right of the patient
 * */
export function poseSideView(mountMatrix: MountingMatrix, side: 'RIGHT' | 'LEFT'): CameraPose {
    // XYZ = Left, Superior, Anterior
    // -X -Y -Z = Right, Inferior, Posterior

    // For this view, we want the camera to be looking toward the opposite of the
    // side specified.  Eg, looking toward the left from the right or vice versa.
    // which means camera -Z (look direction) = mount.X (looking in left direction) or -mount.X (looking in right)
    // carry the minus sign through
    const Z = side === 'RIGHT' ? mountMatrix.x.clone().negate() : mountMatrix.x.clone();

    // camera up to be the same as the anatomical up in both situations
    const Y = mountMatrix.y.clone();

    // We could be explicit and ternary the X based on the left right condition
    // eg, if looking at right side of patient, we expect the anterior of patient to be
    // pointed to camera right, or Camera.X = Mount.Z
    const X = Y.clone().cross(Z);

    const rotMatrix = new THREE.Matrix4().makeBasis(X, Y, Z);

    // all that is left is to position the camera out to the side of the view
    // using the same "backing away" logic.
    const locVector = mountMatrix.origin.clone().add(Z.clone().multiplyScalar(100));

    // The camera target will be the mounting matrix origin
    return { locVector, rotMatrix, targetVector: mountMatrix.origin.clone() };
}

export function poseMandibularOcclusalView(mountMatrix: MountingMatrix): CameraPose {
    // make the camera look downward, with anterior of mouth up
    const rotMatrix = makeViewRotationOcclusal(mountMatrix, 'MANDIBULAR', 'ANTERIOR');

    // all that is left is to position the camera up above, which is +Y (or camearaZ)
    const locVector = mountMatrix.origin.clone().add(mountMatrix.y.clone().multiplyScalar(100));

    // The camera target will be the mounting matrix origin for now
    return { locVector, rotMatrix, targetVector: mountMatrix.origin.clone() };
}

export function poseMaxillaryOcclusalView(mountMatrix: MountingMatrix): CameraPose {
    // make the camera look upward, with anterior of mouth up
    const rotMatrix = makeViewRotationOcclusal(mountMatrix, 'MAXILLARY', 'ANTERIOR');

    // position the camera to the bottom, which is -Mount.Y (or we could use cameraZ)
    const locVector = mountMatrix.origin.clone().add(mountMatrix.y.clone().multiplyScalar(-100));

    // The camera target will be the mounting matrix origin
    return { locVector, rotMatrix, targetVector: mountMatrix.origin.clone() };
}

// Generally the viewing angle
export type PrimaryArchViewType = 'FRONT' | 'SIDE';
// These conventions, hopefully allow for readability.  Eg, start at the primary view, rotate a little to see it better
export type SecondaryViewAdjust = {
    yCorrection: number; // rotation AWAY from the midline
    zCorrection: number; // rotation toward occlusal
};
export type ToothCameraOrientationSettings = { primaryView: PrimaryArchViewType; adjustment: SecondaryViewAdjust };
export type PresetViewToothSettingRecord = { [tooth: number]: ToothCameraOrientationSettings };

const UPPER_MOLAR_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'SIDE',
    adjustment: { yCorrection: (-20 * Math.PI) / 180, zCorrection: 0 },
};
const LOWER_MOLAR_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'SIDE',
    adjustment: { yCorrection: (-15 * Math.PI) / 180, zCorrection: 0 },
};

const UPPER_PREMOLAR_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'SIDE',
    adjustment: { yCorrection: (-22 * Math.PI) / 180, zCorrection: 0 },
};
const LOWER_PREMOLAR_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'SIDE',
    adjustment: { yCorrection: (-18 * Math.PI) / 180, zCorrection: 0 },
};

const UPPER_CANINE_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'SIDE',
    adjustment: { yCorrection: (-45 * Math.PI) / 180, zCorrection: 0 },
};
const LOWER_CANINE_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'FRONT',
    adjustment: { yCorrection: (35 * Math.PI) / 180, zCorrection: 0 },
};

const UPPER_LATERAL_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'FRONT',
    adjustment: { yCorrection: (10 * Math.PI) / 180, zCorrection: 0 },
};
const LOWER_LATERAL_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'FRONT',
    adjustment: { yCorrection: (5 * Math.PI) / 180, zCorrection: 0 },
};

const UPPER_CENTRAL_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'FRONT',
    adjustment: { yCorrection: 0, zCorrection: 0 },
};
const LOWER_CENTRAL_CAM_VIEW: ToothCameraOrientationSettings = {
    primaryView: 'FRONT',
    adjustment: { yCorrection: 0, zCorrection: 0 },
};

export const TOOTH_PRESET_FACIAL_VIEW_LOOKUP: PresetViewToothSettingRecord = {
    1: UPPER_MOLAR_CAM_VIEW,
    2: UPPER_MOLAR_CAM_VIEW,
    3: UPPER_MOLAR_CAM_VIEW,
    4: UPPER_PREMOLAR_CAM_VIEW,
    5: UPPER_PREMOLAR_CAM_VIEW,
    6: UPPER_CANINE_CAM_VIEW,
    7: UPPER_LATERAL_CAM_VIEW,
    8: UPPER_CENTRAL_CAM_VIEW,
    9: UPPER_CENTRAL_CAM_VIEW,
    10: UPPER_LATERAL_CAM_VIEW,
    11: UPPER_CANINE_CAM_VIEW,
    12: UPPER_PREMOLAR_CAM_VIEW,
    13: UPPER_PREMOLAR_CAM_VIEW,
    14: UPPER_MOLAR_CAM_VIEW,
    15: UPPER_MOLAR_CAM_VIEW,
    16: UPPER_MOLAR_CAM_VIEW,
    17: LOWER_MOLAR_CAM_VIEW,
    18: LOWER_MOLAR_CAM_VIEW,
    19: LOWER_MOLAR_CAM_VIEW,
    20: LOWER_PREMOLAR_CAM_VIEW,
    21: LOWER_PREMOLAR_CAM_VIEW,
    22: LOWER_CANINE_CAM_VIEW,
    23: LOWER_LATERAL_CAM_VIEW,
    24: LOWER_CENTRAL_CAM_VIEW,
    25: LOWER_CENTRAL_CAM_VIEW,
    26: LOWER_LATERAL_CAM_VIEW,
    27: LOWER_CANINE_CAM_VIEW,
    28: LOWER_PREMOLAR_CAM_VIEW,
    29: LOWER_PREMOLAR_CAM_VIEW,
    30: LOWER_MOLAR_CAM_VIEW,
    31: LOWER_MOLAR_CAM_VIEW,
    32: LOWER_MOLAR_CAM_VIEW,
};

export const ToothViewDirectionList = ['M', 'D', 'F', 'L', 'O', 'ML', 'MF', 'DL', 'DF'] as const;
export type ToothViewDirections = (typeof ToothViewDirectionList)[number];

function additionalRotationFromFacial(viewType: ToothViewDirections): number {
    switch (viewType) {
        case 'D':
            return Math.PI / 2;
        case 'M':
            return -Math.PI / 2;
        case 'O':
            return 0;
        case 'F':
            return 0;
        case 'L':
            return Math.PI;
        case 'MF':
            return (-65 * Math.PI) / 180;
        case 'DF':
            return (65 * Math.PI) / 180;
        case 'ML':
            return (-115 * Math.PI) / 180;
        case 'DL':
            return (110 * Math.PI) / 180;
        default:
            assertNever(viewType);
    }
}

function getOpacity(existingValue: number, newValue: number | undefined): number {
    if (newValue && newValue > 0) {
        return newValue;
    }
    if (isTransparent({ opacity: existingValue })) {
        return 1;
    }
    return 0.5;
}

export function generateGenericCameraRotationForTooth(
    unn: number,
    mountMatrix: MountingMatrix,
    insertionAxis: THREE.Vector3 | undefined,
    viewType: ToothViewDirections,
): THREE.Matrix4 {
    const isRight = ToothUtils.toothIsRight(unn);
    const isUpper = ToothUtils.toothIsUpper(unn);
    const rotationPresetSettings = TOOTH_PRESET_FACIAL_VIEW_LOOKUP[unn];

    if (!rotationPresetSettings) {
        return new THREE.Matrix4();
    }
    const basisX = new THREE.Vector3();
    const basisY = new THREE.Vector3();
    const basisZ = new THREE.Vector3();

    const primaryMatrix =
        rotationPresetSettings.primaryView === 'FRONT'
            ? makeViewRotationFront(mountMatrix)
            : makeViewRotationSide(mountMatrix, isRight ? 'RIGHT' : 'LEFT');

    primaryMatrix.extractBasis(basisX, basisY, basisZ);

    // Right-handed coordinate convention yields that a counter-clockwise rotation is positive
    // For dentistry, toward or away from midline is easier to reason about
    // To generate our corrections, we change the sign on the axis to handle this
    const rotationAxis = isRight ? basisY.clone().negate() : basisY.clone();

    const correctionMatrixY = new THREE.Matrix4();

    correctionMatrixY.makeRotationAxis(
        rotationAxis,
        rotationPresetSettings.adjustment.yCorrection + additionalRotationFromFacial(viewType),
    );

    primaryMatrix.premultiply(correctionMatrixY);

    if (viewType !== 'O') {
        return primaryMatrix;
    }

    // If we do not have insertion axis, give back face oriented up or down
    if (!insertionAxis) {
        return makeViewRotationOcclusal(mountMatrix, isUpper ? 'MAXILLARY' : 'MANDIBULAR', 'ANTERIOR');
    }
    const camZ = insertionAxis ? insertionAxis.clone().normalize() : rotationAxis.clone();
    //And we actually want the facial direction (which is currently Z axis) to be camY
    const camY = basisZ.clone().add(camZ.clone().multiplyScalar(-1 * basisZ.dot(camZ)));
    camY.normalize();

    const camX = camY.clone().cross(camZ);

    primaryMatrix.makeBasis(camX, camY, camZ);
    return primaryMatrix;
}

export function useTeethIndices(modelAppearance: ModelAppearance) {
    return React.useMemo(() => {
        return modelAppearance.restoratives['CAD']
            .flatMap(pmi => pmi.payloadModel.unns ?? [])
            .sort((n1, n2) => n1 - n2);
    }, [modelAppearance.restoratives]);
}

export function useToggleMarginLines(setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>) {
    return React.useCallback(() => {
        setAppearance(appearance => ({
            ...appearance,
            showMarginLines: !appearance.showMarginLines,
            showDoctorMarginLines: !appearance.showDoctorMarginLines,
        }));
    }, [setAppearance]);
}

export function useToggleAnatomyLayers(setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>) {
    return React.useCallback(() => {
        setAppearance(appearance => ({
            ...appearance,
            // show CADs
            restorativeView: RestorativeView.CAD,
            // and show anatomy layers by showing translucent Anatomy alongside CADs
            showAnatomyLayers: !appearance.showAnatomyLayers,
        }));
    }, [setAppearance]);
}

export function useToggleTransparencyRestorations(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
) {
    return React.useCallback(
        (newValue?: number) => {
            setAppearance(appearance => ({
                ...appearance,
                restoratives: {
                    ...appearance.restoratives,
                    CAD: appearance.restoratives['CAD'].map(pma => ({
                        ...pma,
                        appearance: {
                            ...pma.appearance,
                            opacity: getOpacity(pma.appearance.opacity, newValue),
                        },
                    })),
                },
            }));
        },
        [setAppearance],
    );
}

export function useToggleTransparencyPreExtractionScans(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
) {
    return React.useCallback(() => {
        setAppearance(appearance => ({
            ...appearance,
            preExtractionScans: appearance.preExtractionScans.map(pma => ({
                ...pma,
                appearance: {
                    ...pma.appearance,
                    opacity: isTransparent({ opacity: pma.appearance.opacity }) ? 1 : 0.5,
                },
            })),
        }));
    }, [setAppearance]);
}
