/**
 * This shader replicates the shader defined by `ScanMeshShaderMaterialDesign`.
 */
import * as THREE from 'three';

export const vertexShader = THREE.ShaderLib.physical.vertexShader;

let _fragmentShader = THREE.ShaderLib.physical.fragmentShader;

const colorConversionFunctions = `
#include <clipping_planes_pars_fragment>

uniform float saturation;
uniform float lightness;
uniform bool doSRGBToLinear;
vec3 rgb2hsv(vec3 c)
{
    vec4 K = vec4(0.0, -1.0 / 3.0, 2.0 / 3.0, -1.0);
    vec4 p = mix(vec4(c.bg, K.wz), vec4(c.gb, K.xy), step(c.b, c.g));
    vec4 q = mix(vec4(p.xyw, c.r), vec4(c.r, p.yzx), step(p.x, c.r));
    float d = q.x - min(q.w, q.y);
    float e = 1.0e-10;
    return vec3(abs(q.z + (q.w - q.y) / (6.0 * d + e)), d / (q.x + e), q.x);
}
vec3 hsv2rgb(vec3 c)
{
    vec4 K = vec4(1.0, 2.0 / 3.0, 1.0 / 3.0, 3.0);
    vec3 p = abs(fract(c.xxx + K.xyz) * 6.0 - K.www);
    return c.z * mix(K.xxx, clamp(p - K.xxx, 0.0, 1.0), c.y);
}
`;
_fragmentShader = _fragmentShader.replace('#include <clipping_planes_pars_fragment>', colorConversionFunctions);

const lightSettings = `
/**
 * This is a template that can be used to light a material, it uses pluggable
 * RenderEquations (RE)for specific lighting scenarios.
 *
 * Instructions for use:
 * - Ensure that both RE_Direct, RE_IndirectDiffuse and RE_IndirectSpecular are defined
 * - If you have defined an RE_IndirectSpecular, you need to also provide a Material_LightProbeLOD. <---- ???
 * - Create a material parameter that is to be passed as the third parameter to your lighting functions.
 *
 * TODO:
 * - Add area light support.
 * - Add sphere light support.
 * - Add diffuse light probe (irradiance cubemap) support.
 */
GeometricContext geometry;
geometry.position = - vViewPosition;
geometry.normal = normal;
geometry.viewDir = ( isOrthographic ) ? vec3( 0, 0, 1 ) : normalize( vViewPosition );
#ifdef CLEARCOAT
    geometry.clearcoatNormal = clearcoatNormal;
#endif
IncidentLight directLight;
const int NUM_NEW_LIGHTS = 3;
PointLight light1 = PointLight(vec3(65.0, 0.0, 0.0), vec3(0.2, 0.2, 0.2), 0.0, 1.0);
PointLight light2 = PointLight(vec3(-65.0, 0.0, 0.0), vec3(0.2, 0.2, 0.2), 0.0, 1.0);
PointLight light3 = PointLight(vec3(0.0, -80.0, 0.0), vec3(0.2, 0.2, 0.2), 0.0, 1.0);
PointLight updatedPointLights[ NUM_NEW_LIGHTS ];
updatedPointLights[0] = light1;
updatedPointLights[1] = light2;
updatedPointLights[2] = light3;
#if defined( RE_Direct )
    PointLight pointLight;
    #if defined( USE_SHADOWMAP ) && NUM_POINT_LIGHT_SHADOWS > 0
    PointLightShadow pointLightShadow;
    #endif
    #pragma unroll_loop_start
    for ( int i = 0; i < NUM_NEW_LIGHTS; i ++ ) {
        pointLight = updatedPointLights[ i ];
        getPointDirectLightIrradiance( pointLight, geometry, directLight );
        #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_POINT_LIGHT_SHADOWS )
        pointLightShadow = pointLightShadows[ i ];
        directLight.color *= all( bvec2( directLight.visible, receiveShadow ) ) ? getPointShadow( pointShadowMap[ i ], pointLightShadow.shadowMapSize, pointLightShadow.shadowBias, pointLightShadow.shadowRadius, vPointShadowCoord[ i ], pointLightShadow.shadowCameraNear, pointLightShadow.shadowCameraFar ) : 1.0;
        #endif
        RE_Direct( directLight, geometry, material, reflectedLight );
    }
    #pragma unroll_loop_end
#endif
#if ( NUM_SPOT_LIGHTS > 0 ) && defined( RE_Direct )
    SpotLight spotLight;
    #if defined( USE_SHADOWMAP ) && NUM_SPOT_LIGHT_SHADOWS > 0
    SpotLightShadow spotLightShadow;
    #endif
    #pragma unroll_loop_start
    for ( int i = 0; i < NUM_SPOT_LIGHTS; i ++ ) {
        spotLight = spotLights[ i ];
        getSpotDirectLightIrradiance( spotLight, geometry, directLight );
        #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_SPOT_LIGHT_SHADOWS )
        spotLightShadow = spotLightShadows[ i ];
        directLight.color *= all( bvec2( directLight.visible, receiveShadow ) ) ? getShadow( spotShadowMap[ i ], spotLightShadow.shadowMapSize, spotLightShadow.shadowBias, spotLightShadow.shadowRadius, vSpotShadowCoord[ i ] ) : 1.0;
        #endif
        RE_Direct( directLight, geometry, material, reflectedLight );
    }
    #pragma unroll_loop_end
#endif
#if ( NUM_DIR_LIGHTS > 0 ) && defined( RE_Direct )
    DirectionalLight directionalLight;
    #if defined( USE_SHADOWMAP ) && NUM_DIR_LIGHT_SHADOWS > 0
    DirectionalLightShadow directionalLightShadow;
    #endif
    #pragma unroll_loop_start
    for ( int i = 0; i < NUM_DIR_LIGHTS; i ++ ) {
        directionalLight = directionalLights[ i ];
        getDirectionalDirectLightIrradiance( directionalLight, geometry, directLight );
        #if defined( USE_SHADOWMAP ) && ( UNROLLED_LOOP_INDEX < NUM_DIR_LIGHT_SHADOWS )
        directionalLightShadow = directionalLightShadows[ i ];
        directLight.color *= all( bvec2( directLight.visible, receiveShadow ) ) ? getShadow( directionalShadowMap[ i ], directionalLightShadow.shadowMapSize, directionalLightShadow.shadowBias, directionalLightShadow.shadowRadius, vDirectionalShadowCoord[ i ] ) : 1.0;
        #endif
        RE_Direct( directLight, geometry, material, reflectedLight );
    }
    #pragma unroll_loop_end
#endif
#if ( NUM_RECT_AREA_LIGHTS > 0 ) && defined( RE_Direct_RectArea )
    RectAreaLight rectAreaLight;
    #pragma unroll_loop_start
    for ( int i = 0; i < NUM_RECT_AREA_LIGHTS; i ++ ) {
        rectAreaLight = rectAreaLights[ i ];
        RE_Direct_RectArea( rectAreaLight, geometry, material, reflectedLight );
    }
    #pragma unroll_loop_end
#endif
#if defined( RE_IndirectDiffuse )
    vec3 iblIrradiance = vec3( 0.0 );
    vec3 updatedAmbientLightColor = vec3( 0.8, 0.8, 0.8 );
    vec3 irradiance = getAmbientLightIrradiance( updatedAmbientLightColor );
    irradiance += getLightProbeIrradiance( lightProbe, geometry );
    #if ( NUM_HEMI_LIGHTS > 0 )
        #pragma unroll_loop_start
        for ( int i = 0; i < NUM_HEMI_LIGHTS; i ++ ) {
            irradiance += getHemisphereLightIrradiance( hemisphereLights[ i ], geometry );
        }
        #pragma unroll_loop_end
    #endif
#endif
#if defined( RE_IndirectSpecular )
    vec3 radiance = vec3( 0.0 );
    vec3 clearcoatRadiance = vec3( 0.0 );
#endif
`;
_fragmentShader = _fragmentShader.replace('#include <lights_fragment_begin>', lightSettings);

const setFragColor = `
vec3 hsv = rgb2hsv(outgoingLight);
hsv.y = clamp(hsv.y * saturation, 0.0, 1.0);
hsv.z = clamp(hsv.z * lightness, 0.0, 1.0);

outgoingLight = hsv2rgb(hsv);

gl_FragColor = vec4( outgoingLight, diffuseColor.a );
if (doSRGBToLinear) {
    gl_FragColor = sRGBToLinear(gl_FragColor);
}
`;
_fragmentShader = _fragmentShader.replace('gl_FragColor = vec4( outgoingLight, diffuseColor.a );', setFragColor);

export const fragmentShader = _fragmentShader;

export type DandyMeshPhysicalShaderParams = {
    sRGBToLinear: false;
    saturation: number;
    lightness: number;
    map?: THREE.Texture;
};

export const DEFAULT_DANDY_MESH_PHYSICAL_SHADER_PARAMS: DandyMeshPhysicalShaderParams = {
    sRGBToLinear: false,
    saturation: 1.0,
    lightness: 1.0,
};

export interface DandyMeshPhysicalShader {
    vertexShader: string;
    fragmentShader: string;
    uniforms: Record<string, THREE.IUniform>;
    lights: true;
    flatShading: false;
    map?: THREE.Texture;
}

export function createDandyMeshPhysicalShader(
    params: Partial<DandyMeshPhysicalShaderParams> = {},
): DandyMeshPhysicalShader {
    const { sRGBToLinear, saturation, lightness, map } = {
        ...DEFAULT_DANDY_MESH_PHYSICAL_SHADER_PARAMS,
        ...params,
    };

    // N.B. UniformUtils.merge() cannot be used with Texture values as cloning
    // a texture breaks it (not sure why).
    const uniforms: Record<string, THREE.IUniform> = {
        ...THREE.UniformsUtils.clone(THREE.ShaderLib.physical.uniforms),
        sRGBToLinear: { value: sRGBToLinear },
        saturation: { value: saturation },
        lightness: { value: lightness },
        roughness: { value: 0.15 },
        reflectivity: { value: 0.2 },
        clearcoat: { value: 0.135 },
        clearcoatRoughness: { value: 0.175 },
        map: { value: map },
    };

    return {
        vertexShader,
        fragmentShader,
        uniforms,
        lights: true,
        flatShading: false,
        map,
    };
}
