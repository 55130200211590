import { useCheckoutPropSelector, useCheckoutSelector } from '../../../redux/selectors';
import { CheckoutContentSection } from '../components/CheckoutLayout/CheckoutContentSection';
import { useCheckoutAction } from '../state/checkout.actions';
import type { CheckoutButtonSelectOption } from '@orthly/dentin';
import { CheckoutButtonSelect, ExtraCartItemV2DisplayUtils } from '@orthly/dentin';
import { useWaxupRecommendationV2Query } from '@orthly/graphql-react';
import { LabsGqlCartRecommendationV2, OrderItemV2InputUtils } from '@orthly/graphql-schema';
import { ExtraCartItemV2Utils } from '@orthly/items';
import type { ExtraCartItemV2UnitType } from '@orthly/items';
import { LoadBlockerLoader } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Grid,
    Text,
    Typography,
    Zoom,
    CheckIcon,
    stylesFactory,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory((theme: Theme) => ({
    button: {
        minHeight: '200px',
        margin: '10px !important',
        borderLeftColor: FlossPalette.DARK_TAN,
        borderRightColor: FlossPalette.DARK_TAN,
        maxWidth: 'calc(33% - 20px)',
        [theme.breakpoints.down('md')]: {
            maxWidth: 'unset',
            margin: '0 !important',
        },
    },
    buttonSelected: {
        '& + $button': {
            borderLeftColor: FlossPalette.DARK_TAN,
        },
    },
    buttonGroup: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    imgWrap: {
        flexGrow: 1,
    },
}));

const useButtonGroupStyles = stylesFactory(() => ({
    groupedOutlinedHorizontal: {
        '&:not(:last-child)': {
            borderRightColor: FlossPalette.DARK_TAN,
        },
    },
}));

const useLabelStyles = makeStyles(() =>
    createStyles<string, {}>({
        textContainer: {
            position: 'absolute',
            alignItems: 'center',
            flexWrap: 'nowrap',
        },
        text: {
            fontWeight: 500,
            textAlign: 'left',
        },
        icon: {
            color: FlossPalette.STAR_GRASS_HIGHLIGHTED,
            marginRight: 4,
        },
    }),
);

const ExtrasButtonSelectLabel: React.FC<{ itemType: string; selected: boolean; fromScan: boolean }> = props => {
    const { selected, itemType, fromScan } = props;
    const classes = useLabelStyles();
    const timeout = 200;

    return (
        <Grid container style={{ paddingBottom: 16, position: 'relative', minHeight: 30 }} alignItems={'center'}>
            <Zoom in={selected} timeout={timeout} exit={true}>
                <Grid container className={classes.textContainer}>
                    <CheckIcon className={classes.icon} />
                    <Typography color={'textPrimary'} variant={'body1'} className={classes.text}>
                        {itemType} {fromScan ? 'configured' : 'added'}
                    </Typography>
                </Grid>
            </Zoom>
            <Zoom in={!selected} timeout={timeout} exit={true}>
                <Grid container className={classes.textContainer}>
                    <Typography color={'textPrimary'} variant={'body1'} className={classes.text}>
                        Add {itemType}
                    </Typography>
                </Grid>
            </Zoom>
        </Grid>
    );
};

function useAvailableExtraOptions(): { extraOptions: CheckoutButtonSelectOption[]; existingOtherUnitTypes: string[] } {
    const { items } = useCheckoutPropSelector(['items']);

    const availableExtras = ExtraCartItemV2Utils.getAddToCartOptions(_.filter(items, i => i.from_scan_export));
    // Get the values of all currently existing item unit types
    const existingOtherUnitTypes = _.compact(
        items.filter(i => !i.from_scan_export).map(ExtraCartItemV2Utils.getExtraItemUnitType),
    );

    // Turn the availableExtras into type CheckoutButtonSelectOption and set the disabled/selected flags depending on
    // whether it is already in the cart
    const extraOptions = React.useMemo<CheckoutButtonSelectOption[]>(() => {
        return availableExtras.map<CheckoutButtonSelectOption>(extraItem => {
            const hasExistingItem = existingOtherUnitTypes.includes(extraItem.unit_type);

            return {
                value: extraItem.unit_type,
                image_url: ExtraCartItemV2DisplayUtils.getImageForUnitType(extraItem.unit_type),
                label: (
                    <ExtrasButtonSelectLabel
                        fromScan={hasExistingItem}
                        itemType={extraItem.label || extraItem.unit_type}
                        selected={hasExistingItem}
                    />
                ),
            };
        });
    }, [existingOtherUnitTypes, availableExtras]);

    return { extraOptions, existingOtherUnitTypes };
}

const WaxupTooltipButton: React.VFC<{ onClick: () => void }> = props => {
    const { onClick } = props;
    return (
        <Grid container item onClick={onClick} style={{ cursor: 'pointer' }}>
            <Text>You'll need to approve the design preview in the portal before we can fabricate this order.</Text>
            <Text style={{ paddingTop: 8 }}>
                We'll notify you when the design preview is ready for review. Keep in mind that a response time over 24
                hours could result in delay to the order delivery.
            </Text>
            <Text style={{ paddingTop: 8, paddingBottom: 8, fontWeight: 'bold' }}>Dismiss »</Text>
        </Grid>
    );
};

const useWaxupExtraOption = (screenEntered: boolean): { loading: boolean; option?: CheckoutButtonSelectOption } => {
    const doctor_id = useCheckoutSelector(s => s.doctor?.id);
    const setSelected = useCheckoutAction('SET_WAXUP_SELECTED');
    const selected = useCheckoutSelector(s => s.waxupState.selected);
    const permitAutoSelect = useCheckoutSelector(s => s.waxupState.permitAutoSelect);
    const setTooltipDismissed = useCheckoutAction('SET_WAXUP_TOOLTIP_DISMISSED');
    const tooltipDismissed = useCheckoutSelector(s => s.waxupState.tooltipDismissed);
    const scanItems = useCheckoutSelector(s => s.items.filter(i => i.from_scan_export));
    const items_v2_by_sku = OrderItemV2InputUtils.getOrderItemV2InputBySKU(scanItems) ?? {};
    const optionEnabled = ExtraCartItemV2Utils.shouldEnableWaxupOption(scanItems);

    const { loading } = useWaxupRecommendationV2Query({
        variables: { items_v2_by_sku, doctor_id: doctor_id ?? 'invalid_doctor_id' },
        // we don't bother making the request if the option isn't enabled, since it can't be recommended
        skip: doctor_id === undefined || !optionEnabled,
        onCompleted: resultData => {
            if (
                !selected &&
                permitAutoSelect &&
                resultData.waxupRecommendationV2 === LabsGqlCartRecommendationV2.Recommended
            ) {
                setSelected(true);
            }
        },
    });

    return React.useMemo<{ loading: boolean; option?: CheckoutButtonSelectOption }>(() => {
        if (loading) {
            return { loading: true };
        }

        if (!optionEnabled) {
            return { loading: false };
        }

        return {
            loading: false,
            option: {
                disabled: false,
                value: 'waxup',
                image_url: '/checkout/digital-waxup.svg',
                TooltipProps: {
                    open: !tooltipDismissed && selected && screenEntered,
                    title: <WaxupTooltipButton onClick={() => setTooltipDismissed(true)} />,
                    interactive: true,
                    arrow: true,
                    placement: 'bottom',
                },
                label: <ExtrasButtonSelectLabel fromScan={false} itemType={'Design Preview'} selected={selected} />,
            },
        };
    }, [loading, optionEnabled, tooltipDismissed, setTooltipDismissed, selected, screenEntered]);
};

export const SelectExtrasSection: React.FC<{ entered: boolean }> = props => {
    const classes = useStyles();
    const btnGroupClasses = useButtonGroupStyles();
    const setSelectedUnits = useCheckoutAction('SET_EXTRA_UNITS');
    const { extraOptions, existingOtherUnitTypes } = useAvailableExtraOptions();
    const setWaxupSelected = useCheckoutAction('SET_WAXUP_SELECTED');
    const waxupSelected = useCheckoutSelector(s => s.waxupState.selected);

    const { loading: waxupOptionLoading, option: waxupOption } = useWaxupExtraOption(props.entered);

    const { loading, allOptions } = React.useMemo<{
        loading: boolean;
        allOptions: CheckoutButtonSelectOption[];
    }>(() => {
        if (waxupOptionLoading) {
            return { loading: true, allOptions: [] };
        }
        if (waxupOption) {
            return { loading: false, allOptions: [waxupOption, ...extraOptions] };
        }
        return { loading: false, allOptions: extraOptions };
    }, [waxupOptionLoading, waxupOption, extraOptions]);

    return (
        <CheckoutContentSection style={{ paddingTop: 0 }}>
            {loading ? (
                <LoadBlockerLoader loader={'linear'} blocking={loading} />
            ) : (
                <CheckoutButtonSelect
                    classes={classes}
                    buttonGroupClasses={btnGroupClasses}
                    mode={'multiple'}
                    options={allOptions}
                    onChange={(newValue: string[]) => {
                        setWaxupSelected(newValue.includes('waxup'));
                        setSelectedUnits(
                            newValue.filter(
                                (v: string): v is ExtraCartItemV2UnitType =>
                                    v !== 'waxup' && ExtraCartItemV2Utils.isExtraUnitType(v),
                            ),
                        );
                    }}
                    value={[...existingOtherUnitTypes, ...(waxupSelected ? ['waxup'] : [])]}
                    label={''}
                />
            )}
        </CheckoutContentSection>
    );
};
