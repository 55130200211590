import { AttributeName } from './BufferAttributeConstants';
import type * as THREE from 'three';

/**
 * Remaps the visualized occlusal distance attribute in `dst` to replicate the mapping in `src`.
 * We do this because we use `AttributeName.OcclusalDistance` as a reference to either of the concrete primary or
 * pre-prep occlusal distance attributes but this relationship is not preserved by `BufferGeometry.copy` or `clone`.
 */
export function remapOcclusalDistance(src: THREE.BufferGeometry, dst: THREE.BufferGeometry): void {
    const visualizedOcclusalDistance = src.getAttribute(AttributeName.OcclusalDistance);
    const primaryOcclusalDistance = src.getAttribute(AttributeName.OcclusalDistanceToPrimary);
    if (
        visualizedOcclusalDistance &&
        primaryOcclusalDistance &&
        visualizedOcclusalDistance === primaryOcclusalDistance
    ) {
        dst.setAttribute(AttributeName.OcclusalDistance, dst.getAttribute(AttributeName.OcclusalDistanceToPrimary));
        return;
    }

    const preprepOcclusalDistance = src.getAttribute(AttributeName.OcclusalDistanceToPrePrep);
    if (
        visualizedOcclusalDistance &&
        preprepOcclusalDistance &&
        visualizedOcclusalDistance === preprepOcclusalDistance
    ) {
        dst.setAttribute(AttributeName.OcclusalDistance, dst.getAttribute(AttributeName.OcclusalDistanceToPrePrep));
    }
}
