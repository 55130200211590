// adapted from https://github.com/gkjohnson/threejs-sandbox/blob/master/shadow-volumes/src/ShadowVolume.js
import { AttributeName } from './BufferAttributeConstants';
import { getFaceNormal } from './Mesh3d.util';
import type { MeshConnectivityGraph } from './MeshConnectivityGraph';
import { ensureMeshIndex } from './MeshIndex';
import { getSubgeometry } from './Subgeometry.util';
import _ from 'lodash';
import * as THREE from 'three';
import type { HitPointInfo } from 'three-mesh-bvh';

/* eslint-disable sonarjs/cognitive-complexity */
// eslint-disable-next-line max-lines-per-function
export function makeCurtainGeometry(
    geometry: THREE.BufferGeometry,
    cadGeometry: THREE.BufferGeometry,
    upAxis: THREE.Vector3,
): THREE.BufferGeometry {
    // Make the assumption there are significantly fewer downFacing faces
    // than upFacing.  For that reason, if we iterate faces, and then only look
    // for a boundary edge on downFaces, we can probably do way fewer of a less
    // efficient computation.  So this is my first hacks
    const cadPositions = cadGeometry.getAttribute(AttributeName.Position);
    if (!cadPositions) {
        throw new Error('Cad geometry must have position attribute');
    }

    const positionVec = new THREE.Vector3();
    const lowestPoint = new THREE.Vector3();
    let lowestH = Infinity;
    for (let i = 0, numVertices = cadPositions.count; i < numVertices; ++i) {
        positionVec.fromBufferAttribute(cadPositions, i);
        const h = positionVec.dot(upAxis);
        if (h < lowestH) {
            lowestH = h;
            lowestPoint.copy(positionVec);
        }
    }

    const positions = geometry.getAttribute(AttributeName.Position);
    if (!positions) {
        throw new Error('Source scan must have position attribute');
    }
    const faces = geometry.index?.array;

    if (!faces) {
        throw new Error('Must be indexed geom');
    }

    const bvh = ensureMeshIndex(geometry);

    const nFaces = faces.length / 3;

    const newVerts: THREE.Vector3[] = [];

    const newFaces: number[][] = [];

    // We are going to extrude edges into quads
    const v0 = new THREE.Vector3();
    const v1 = new THREE.Vector3();
    const v2 = new THREE.Vector3();
    const v3 = new THREE.Vector3();
    const v4 = new THREE.Vector3();
    const v5 = new THREE.Vector3();

    const tempRay = new THREE.Ray();
    const tempOrigin = new THREE.Vector3();
    const tempOffset = new THREE.Vector3().copy(upAxis).multiplyScalar(0.001);
    tempRay.direction.copy(upAxis);

    const displaceVector = new THREE.Vector3().copy(upAxis);
    for (let i = 0; i < nFaces; i++) {
        // compute this normal, and keep it
        const normal = getFaceNormal(geometry, i);

        if (normal.dot(upAxis) > 0.0001) {
            continue;
        }

        const v0i = faces[3 * i];
        const v1i = faces[3 * i + 1];
        const v2i = faces[3 * i + 2];

        if (v0i === undefined || v1i === undefined || v2i === undefined) {
            continue;
        }

        v0.fromBufferAttribute(positions, v0i);
        v1.fromBufferAttribute(positions, v1i);
        v2.fromBufferAttribute(positions, v2i);

        const h0 = v0.dot(upAxis);
        const h1 = v1.dot(upAxis);
        const h2 = v2.dot(upAxis);

        if ([h0, h1, h2].every(h => h < lowestH)) {
            continue;
        }

        // TODO, dont do this if things are occluded
        v3.fromBufferAttribute(positions, v0i).add(displaceVector.copy(upAxis).multiplyScalar(lowestH - h0));
        v4.fromBufferAttribute(positions, v1i).add(displaceVector.copy(upAxis).multiplyScalar(lowestH - h1));
        v5.fromBufferAttribute(positions, v2i).add(displaceVector.copy(upAxis).multiplyScalar(lowestH - h2));

        tempOrigin.copy(v0).add(tempOffset);
        tempRay.origin.copy(tempOrigin);
        const v0_Occluded = bvh.raycastFirst(tempRay, THREE.DoubleSide);

        tempOrigin.copy(v1).add(tempOffset);
        tempRay.origin.copy(tempOrigin);
        const v1_Occluded = bvh.raycastFirst(tempRay, THREE.DoubleSide);

        tempOrigin.copy(v2).add(tempOffset);
        tempRay.origin.copy(tempOrigin);
        const v2_Occluded = bvh.raycastFirst(tempRay, THREE.DoubleSide);

        let numOccluded = 0;
        v0_Occluded && numOccluded++;
        v1_Occluded && numOccluded++;
        v2_Occluded && numOccluded++;

        if (numOccluded === 3 || numOccluded === 0) {
            continue;
        }

        const offset = newVerts.length;

        if (numOccluded === 2) {
            // We will find the non occluded vert and make wings off of it
            // by pushing verts into the stack such that 1 is the non occluded vert
            /*  These are the offsets from pushing into newVerts
             *      0--- 1--- 2
             *      |  / |  / |
             *      | /  | /  |
             *      3----4----5
             * */
            if (!v0_Occluded) {
                newVerts.push(v2.clone());
                newVerts.push(v0.clone());
                newVerts.push(v1.clone());
                newVerts.push(v5.clone());
                newVerts.push(v3.clone());
                newVerts.push(v4.clone());
            } else if (!v1_Occluded) {
                newVerts.push(v0.clone());
                newVerts.push(v1.clone());
                newVerts.push(v2.clone());
                newVerts.push(v3.clone());
                newVerts.push(v4.clone());
                newVerts.push(v5.clone());
            } else {
                newVerts.push(v1.clone());
                newVerts.push(v2.clone());
                newVerts.push(v0.clone());
                newVerts.push(v4.clone());
                newVerts.push(v5.clone());
                newVerts.push(v3.clone());
            }

            // Make the 4 Faces
            newFaces.push([offset, offset + 3, offset + 1]);
            newFaces.push([offset + 1, offset + 3, offset + 4]);

            newFaces.push([offset + 2, offset + 1, offset + 4]);
            newFaces.push([offset + 2, offset + 4, offset + 5]);

            continue;
        }

        if (v0_Occluded) {
            newVerts.push(v1.clone());
            newVerts.push(v2.clone());
            newVerts.push(v4.clone());
            newVerts.push(v5.clone());
        } else if (v1_Occluded) {
            newVerts.push(v2.clone());
            newVerts.push(v0.clone());
            newVerts.push(v5.clone());
            newVerts.push(v3.clone());
        } else {
            newVerts.push(v0.clone());
            newVerts.push(v1.clone());
            newVerts.push(v3.clone());
            newVerts.push(v4.clone());
        }
        /*  These are the offsets from pushing into newVerts
         *      0--- 1
         *      |  / |
         *      | /  |
         *      2----3
         * */

        newFaces.push([offset, offset + 1, offset + 2]);
        newFaces.push([offset + 2, offset + 1, offset + 3]);
    }

    const newGometry = new THREE.BufferGeometry();
    newGometry.setIndex(newFaces.flat());
    newGometry.setAttribute(
        AttributeName.Position,
        new THREE.Float32BufferAttribute(
            newVerts.flatMap((v: THREE.Vector3) => [v.x, v.y, v.z]),
            3,
        ),
    );
    return newGometry;
}

/**
 * brute force method to crop a given geometry within a sphere
 * @param {BufferGeometry} geom - geometry to crop
 * @param {Vector3} center - sphere center
 * @param {number} radius - sphere radius
 * @param {MeshConnectivityGraph} graph - LUT for adjacency info for vertices and faces
 * @returns {BufferGeometry | undefined}
 */
export function cropGeometryNearCenter(
    geom: THREE.BufferGeometry,
    center: THREE.Vector3,
    radius: number,
    graph: MeshConnectivityGraph,
): THREE.BufferGeometry | undefined {
    const vertexIndicesToKeep: number[] = [];
    const verts = geom.getAttribute(AttributeName.Position);

    const vPosition = new THREE.Vector3();
    const rSquared = radius ** 2;
    for (let i = 0, numVertices = verts.count; i < numVertices; ++i) {
        vPosition.fromBufferAttribute(verts, i);
        if (vPosition.distanceToSquared(center) < rSquared) {
            vertexIndicesToKeep.push(i);
        }
    }
    const result = getSubgeometry(geom, vertexIndicesToKeep, graph);
    if (!result) {
        return undefined;
    }
    return result.geometry;
}

export function cropGeometryNearGeometry(
    geom: THREE.BufferGeometry,
    refGeom: THREE.BufferGeometry,
    radius: number,
    graph: MeshConnectivityGraph,
): THREE.BufferGeometry | undefined {
    const posAttr = geom.getAttribute(AttributeName.Position);

    const bvh = ensureMeshIndex(refGeom);
    const pos = new THREE.Vector3();
    const hit: HitPointInfo = { point: new THREE.Vector3(), distance: -1, faceIndex: -1 };
    const vertexIndicesToKeep = _.range(posAttr.count).filter(
        vIdx => bvh.closestPointToPoint(pos.fromBufferAttribute(posAttr, vIdx), hit) && hit.distance <= radius,
    );
    return getSubgeometry(geom, vertexIndicesToKeep, graph)?.geometry;
}
