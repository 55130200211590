import { useHeatMap } from './ColorRamp.hooks';
import type { QCLegendProps } from './ColorRamp.types';
import { ColorRampHorizontal } from './ColorRampHorizontal';
import { Button } from '@mui/material';
import { MagnifierZoomInIcon, MagnifierZoomOutIcon } from '@orthly/ui';
import { FlossPalette, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

const RAMP_WIDTH = 250;

const StyledIconButton = styled(Button)({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0',
    minWidth: '40px',
    height: '40px',
    color: FlossPalette.GRAY,
    border: 'none',
    backgroundColor: FlossPalette.WHITE,
    '&:hover': {
        backgroundColor: FlossPalette.TAN,
    },
    '&:only-child': {
        height: '56px',
    },
});

const StyledLeftIconButton = styled(StyledIconButton)({
    '&:first-of-type': {
        borderRadius: '8px 0px 0px 0px',
    },
    '&:last-of-type:not(:first-child)': {
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: '0px 0px 0px 8px',
    },
});

const StyledRightIconButton = styled(StyledIconButton)({
    '&:first-of-type': {
        borderRadius: '0px 8px 0px 0px',
    },
    '&:last-of-type:not(:first-child)': {
        borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: '0px 0px 8px 0px',
    },
});

const ButtonsGroup = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

const ColorLegendCard = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    float: 'left',
    alignItems: 'center',
    zIndex: 21,
    marginBottom: 16,
    borderRadius: 3,
    background: '#FFFFFF',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
    pointerEvents: 'auto',
});

const ColorLegendContainer = styled('div')({
    pointerEvents: 'none',
    padding: '8px 8px',
});

const ColorLegendBar = styled('div')({
    width: '100%',
});
const ColorLegendPoints = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
});
const ColorLegendPoint = styled('div')({
    color: FlossPalette.GRAY,
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
});

export const QCColorLegendHorizontal: React.VFC<QCLegendProps> = props => {
    const { dynamicHeatmaps, hideZoom, ...otherProps } = props;
    const { points, label, legendTools, vMin, vMax, heatMap } = useHeatMap(otherProps);
    const showZoomButton = !hideZoom;

    return (
        <ColorLegendCard data-test={'color-legend-card'} aria-label={'Heatmap Color Legend'}>
            {dynamicHeatmaps && (
                <ButtonsGroup style={{ borderRight: `1px solid ${FlossPalette.DARK_TAN}` }}>
                    <StyledLeftIconButton onClick={legendTools.moveWindowDown} aria-label={'Move Up'}>
                        <Icon icon={'ArrowWestIcon'} />
                    </StyledLeftIconButton>

                    {showZoomButton && (
                        <StyledLeftIconButton onClick={legendTools.zoomOutOfWindow} aria-label={'Zoom Out'}>
                            <MagnifierZoomOutIcon />
                        </StyledLeftIconButton>
                    )}
                </ButtonsGroup>
            )}
            <ColorLegendContainer>
                <ColorLegendBar>
                    <ColorRampHorizontal
                        vMin={vMin}
                        vMax={vMax}
                        colorMap={heatMap.reverse ? heatMap.fn : (a, b, v) => heatMap.fn(0, b - a, b - v)}
                        width={RAMP_WIDTH}
                        height={16}
                    />
                </ColorLegendBar>
                <ColorLegendPoints>
                    {points.map(value => {
                        return <ColorLegendPoint key={value}>{label(value)}</ColorLegendPoint>;
                    })}
                </ColorLegendPoints>
            </ColorLegendContainer>
            {dynamicHeatmaps && (
                <ButtonsGroup style={{ borderLeft: `1px solid ${FlossPalette.DARK_TAN}` }}>
                    <StyledRightIconButton onClick={legendTools.moveWindowUp} aria-label={'Move Down'}>
                        <Icon icon={'ArrowEastIcon'} />
                    </StyledRightIconButton>
                    {showZoomButton && (
                        <StyledRightIconButton onClick={legendTools.zoomIntoWindow} aria-label={'Zoom In'}>
                            <MagnifierZoomInIcon />
                        </StyledRightIconButton>
                    )}
                </ButtonsGroup>
            )}
        </ColorLegendCard>
    );
};
