import type { ScanDefinition } from './Scan.types';
import type {
    ThreeshapeThreeoxzScanType,
    ScanEntry,
    ThreeshapeScanName,
    FormatGeneratorCaseData,
} from './ThreeOxzGenerator.types';
import type { ICartItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, OrderItemArch, ToothUtils, LabOrderItemSKUType } from '@orthly/items';
import _ from 'lodash';
import type { XMLBuilder, AttributesObject } from 'xmlbuilder2/lib/interfaces';

export class ThreeOxzUtil {
    static addXMLFields(
        root: XMLBuilder,
        data: Record<string, { value?: string; data?: AttributesObject } | string | undefined>,
    ) {
        Object.entries(data).forEach(([key, datum]) => {
            if (!datum) {
                return;
            }

            const value = typeof datum === 'string' ? datum : datum.value;
            const newEle = root.ele(key, typeof datum === 'string' ? {} : datum.data ?? {});
            value && newEle.txt(value);
        });
    }

    // For every entry in data, adds a `Property` field to the root element.
    // The key of the data entry will become the `name` field in the Property,
    // and the value of that key will be the `value` field in the Property.
    static addPropertyFields(root: XMLBuilder, data: Record<string, string>) {
        Object.entries(data).forEach(([name, value]) => {
            root.ele('Property', { name, value });
        });
    }

    // Given an item, determines if it's an "upper" item or not.
    // An item is considered to be an "upper" item if it has at least some teeth on the upper jaw, or is a full-arch item on the upper jaw.
    static isUpperItem(item: ICartItemV2DTO): boolean {
        if (CartItemV2Utils.isArchItem(item)) {
            return item.unit.arch === OrderItemArch.Upper;
        }

        return CartItemV2Utils.getUniqueUNNs(item).some(t => ToothUtils.toothIsUpper(t));
    }

    static isImplantScan(scan: ScanEntry, allScans: ScanEntry[]): boolean {
        const isScanBody = allScans.some(
            s =>
                s.definition.type === 'emergence_profile' &&
                scan.definition.type === 'jaw' &&
                s.definition.variant === scan.definition.variant &&
                !scan.definition.is_pre_prep,
        );

        // For CuP cases - we need the scan with partial to be treated as a fake implant scan
        // because of Trios limitation.
        const isFakeImplantJaw = scan.definition.type === 'jaw' && !!scan.definition.with_partial;

        return isScanBody || isFakeImplantJaw;
    }

    // Given a list of items and scans, returns the scan file type of the provided scan.
    // The Scan File Type is the naming of the scan as used throughout 3Shape's case file system.
    static getScanFileType(
        items: ICartItemV2DTO[],
        allScans: ScanEntry[],
        scan: ScanEntry,
    ): ThreeshapeThreeoxzScanType | undefined {
        switch (scan.definition.type) {
            case 'emergence_profile':
                return 'Preparation';
            case 'denture':
            case 'waxrim':
                // For whatever reason, threeshape expects these to be preparations!
                // Having a ThreeshapeThreeoxzScanType set to Denture implies that the scan names will be set to
                // LowerJawDentureScan etc, but our API does not generate these.
                // Looking through many production orders, some use Denture & LowerJawDentureScan, and
                // about half use Preparation & LowerJawScan.
                // We use Preparation & LowerJawScan here for simplicity.
                return 'Preparation';
            case 'jaw': {
                const filteredItems = items.filter(item => ThreeOxzUtil.shouldIncludeItemInThreeoxz(item));
                const hasUpperPrep = filteredItems.some(item => ThreeOxzUtil.isUpperItem(item));
                const hasLowerPrep = filteredItems.some(item => !ThreeOxzUtil.isUpperItem(item));
                const isPrepJaw =
                    (scan.definition.variant === 'upper' && hasUpperPrep) ||
                    (scan.definition.variant === 'lower' && hasLowerPrep);

                if (isPrepJaw && ThreeOxzUtil.isImplantScan(scan, allScans)) {
                    return 'AbutmentAlignment';
                }

                if (scan.definition.is_pre_prep) {
                    return `GenericPrePrep`;
                }

                return isPrepJaw ? 'Preparation' : 'Antagonist';
            }
            case 'bite':
                return 'OcclusionBite';
            default:
                return undefined;
        }
    }

    // We only allow a subset of items into the threeoxz, namely those that are actually valid in TRIOS.
    // If we allow invalid items into the threeoxz, we will end up with a case that will fail to import into Dental Designer.
    // This is really bad for Designers, as it takes time to create the new case, and may lead to errors.
    static shouldIncludeItemInThreeoxz(
        item: ICartItemV2DTO,
        ignoreSkus: LabOrderItemSKUType[] = [
            LabOrderItemSKUType.Model,
            LabOrderItemSKUType.Waxup,
            LabOrderItemSKUType.Unknown,
            LabOrderItemSKUType.Other,
        ],
    ): boolean {
        if (ignoreSkus.includes(item.sku)) {
            return false;
        }

        return true;
    }

    // Given a 3Oxz scan type and a scan definition, creates the base file name of the scan stream and model file.
    static scanFileTypeToFileName(
        type: ThreeshapeThreeoxzScanType,
        definition: ScanDefinition,
    ): ThreeshapeScanName | undefined {
        switch (type) {
            case 'AbutmentAlignment':
                return 'variant' in definition && definition.variant === 'upper'
                    ? 'UpperImplantScan'
                    : 'LowerImplantScan';
            case 'OcclusionBite':
                return 'BiteScan';
            case 'Denture':
                return 'variant' in definition && definition.variant === 'upper'
                    ? 'UpperJawDentureScan'
                    : 'LowerJawDentureScan';
            case 'GenericPrePrep':
            case 'Preparation':
            case 'Antagonist':
                // Pre-preps
                if (definition.type === 'jaw' && definition.is_pre_prep) {
                    return 'variant' in definition && definition.variant === 'upper'
                        ? 'UpperJawPrePreparationScan'
                        : 'LowerJawPrePreparationScan';
                }

                // Preps or Opposings
                return 'variant' in definition && definition.variant === 'upper' ? 'UpperJawScan' : 'LowerJawScan';
        }

        return undefined;
    }

    // Returns a map of scan ids in a case mapped to what their file name will be.
    // As an example, this may return { 'id1': 'UpperJawScan', 'id2': 'UpperJawScan2' }.
    static getModelFileNames(caseData: Pick<FormatGeneratorCaseData, 'scans' | 'items'>): { [scanId: string]: string } {
        const scansByType = _.groupBy(caseData.scans, scan =>
            ThreeOxzUtil.scanFileTypeToFileName(
                ThreeOxzUtil.getScanFileType(caseData.items, caseData.scans, scan) as ThreeshapeThreeoxzScanType,
                scan.definition,
            ),
        );

        return Object.keys(scansByType).reduce((state, type) => {
            const typeScans = scansByType[type] ?? [];

            if (!type) {
                return state;
            }

            const rewrites = typeScans.map((scan, index) => [scan.id, `${type}${index > 0 ? index + 1 : ''}`]);

            return { ...state, ..._.fromPairs(rewrites) };
        }, {});
    }

    /*
     * Converts paths from the disk storage path to 3shape friendly output paths
     * This includes indexing duplicates to have postfixes for the order they appear in.
     */
    static getModelPathRenamings(caseData: Pick<FormatGeneratorCaseData, 'scans' | 'items'>): {
        [path: string]: string;
    } {
        const scanFileNames = ThreeOxzUtil.getModelFileNames(caseData);

        return caseData.scans.reduce((state, scan) => {
            const outputName = scanFileNames[scan.id];

            if (!outputName || !scan.file_name) {
                return state;
            }

            return {
                ...state,
                [scan.file_name]: `${outputName}.${_.last(scan.file_name?.split('.'))}`,
            };
        }, {});
    }
}
