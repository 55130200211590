import type { GuidedWaxupState, PresetInfo } from './GuidedWaxupTypes';
import { INITIAL_GUIDED_WAXUP_STATE } from './GuidedWaxupTypes';
import { LabsGqlGuidedWaxupPresetStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import type { LocalActionWithPayload } from '@orthly/ui';
import { createLocalReducerContext, createScrollToContext } from '@orthly/ui';

export const {
    Provider: ScrollToProvider,
    useContainer: useScrollToContainer,
    useControl: useScrollToControl,
} = createScrollToContext();

export const {
    Provider: GuidedWaxupProvider,
    useAction: useGuidedWaxupAction,
    useSelector: useGuidedWaxupSelector,
    useListener: useGuidedWaxupListener,
} = createLocalReducerContext(
    {
        PATCH_STATE: (state, action: LocalActionWithPayload<Partial<GuidedWaxupState>>) => ({
            ...state,
            ...action.payload,
        }),
        SET_PRESET_STATUS: (
            state,
            action: LocalActionWithPayload<{
                presetName: LabsGqlGuidedWaxupPresetType;
                status?: LabsGqlGuidedWaxupPresetStatus;
            }>,
        ) => ({
            ...state,
            presets: {
                ...state.presets,
                [action.payload.presetName]: {
                    ...state.presets[action.payload.presetName],
                    status: action.payload.status,
                },
            },
        }),
        SET_PRESET_REJECTION_NOTES: (
            state,
            action: LocalActionWithPayload<{ presetName: LabsGqlGuidedWaxupPresetType; rejectionNotes?: string }>,
        ) => ({
            ...state,
            presets: {
                ...state.presets,
                [action.payload.presetName]: {
                    ...state.presets[action.payload.presetName],
                    notes: action.payload.rejectionNotes,
                },
            },
        }),
        SET_PRESET_ANNOTATION: (
            state,
            action: LocalActionWithPayload<{ presetName: LabsGqlGuidedWaxupPresetType; annotatedImageUrls: string[] }>,
        ) => ({
            ...state,
            presets: {
                ...state.presets,
                [action.payload.presetName]: {
                    ...state.presets[action.payload.presetName],
                    annotatedImageUrls: action.payload.annotatedImageUrls,
                },
            },
        }),
        SKIP_TO_GENERAL_VIEW: state => {
            const presets = Object.keys(state.presets) as LabsGqlGuidedWaxupPresetType[];
            const newPresetStatuses = {} as Record<LabsGqlGuidedWaxupPresetType, PresetInfo>;
            presets.forEach(presetType => {
                if (presetType !== LabsGqlGuidedWaxupPresetType.GeneralView && !state.presets[presetType]?.status) {
                    newPresetStatuses[presetType] = {
                        status: LabsGqlGuidedWaxupPresetStatus.Skipped,
                    };
                } else {
                    newPresetStatuses[presetType] = state.presets[presetType] ?? {};
                }
            });
            return {
                ...state,
                presets: newPresetStatuses,
            };
        },
    },
    INITIAL_GUIDED_WAXUP_STATE,
);

export const useWaxupIsRejected = () =>
    useGuidedWaxupSelector(state =>
        Object.values(state.presets).some(preset => preset?.status === LabsGqlGuidedWaxupPresetStatus.Rejected),
    );
