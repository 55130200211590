import { ArchiveFile, type IArchiveFile } from './File';
import { U16, U64 } from './Utility/Util';
import { Vec } from './Utility/Vec';
import * as zip from './ZipFormat';

// Archive writer options.
//
export interface WriteOptions {
    // Type of compression to be defaulted to.
    //
    compression?: zip.CompressionType;

    // Default timestamp.
    //
    timestamp?: Date;

    // Forces zip64 usage if set.
    //
    zip64?: boolean;
}

// Implement ArchiveFile given the write request.
//
export class ArchiveWritable extends ArchiveFile {
    #body!: Vec;
    #digest?: Vec;

    static async from(file: IArchiveFile, opt?: WriteOptions) {
        const result = new ArchiveWritable();
        switch (typeof file.body) {
            case 'string': {
                result.#body = Vec.fromUtf8(file.body);
                break;
            }
            case 'function': {
                result.#body = new Vec(await file.body());
                break;
            }
            default: {
                result.#body = new Vec(file.body);
                break;
            }
        }
        let compression = file.compression ?? opt?.compression;
        if (compression === undefined) {
            compression = result.#body.length < 1024 ? zip.CompressionType.STORED : zip.CompressionType.DEFLATE;
        }

        result.record.version = 20;
        result.record.compression = compression;
        result.record.fileName = file.name;
        result.record.fileTime = file.timestamp ?? opt?.timestamp ?? new Date();
        result.record.uncompressedSize = result.#body.length;
        result.record.crc = result.calculateChecksum(result.#body);

        if (file.password !== undefined) {
            result.currentPassword = file.password;
            result.record.flags |= zip.Flag.Encrypted;
        }
        return result;
    }

    async body() {
        return this.#body.clone();
    }
    async digest() {
        if (!this.#digest) {
            const compressedBody = await this.compressBody(this.#body);
            this.#digest = this.encryptDigest(compressedBody, this.password ?? '', this.checksum);
            this.record.compressedSize = this.#digest.length;
        }
        return this.#digest.clone();
    }
}

// Given an async/regular iterable of ArchiveFile or write requests, creates the zip and returns the Uint8Array.
//
export async function writeArchive(
    files: Iterable<ArchiveFile | IArchiveFile> | AsyncIterable<ArchiveFile | IArchiveFile>,
    opt?: WriteOptions,
): Promise<Uint8Array> {
    const result = new Vec();

    const directories: zip.DirEntry[] = [];
    for await (const entry of files) {
        let loc: zip.FileRecord;
        let dig: Vec;
        if (!(entry instanceof ArchiveFile)) {
            const file = await ArchiveWritable.from(entry, opt);
            loc = file.fileRecord;
            dig = await file.digest();
        } else {
            loc = new zip.FileRecord(entry.fileRecord);
            dig = await entry.digest();
        }

        let zip64 = null;
        if (opt?.zip64 || loc.uncompressedSize >= 0x7fffffff) {
            zip64 = new Vec();
            zip64.push(U64, BigInt(loc.uncompressedSize));
            zip64.push(U64, BigInt(loc.compressedSize));
            loc.compressedSize = 0xffffffff;
            loc.uncompressedSize = 0xffffffff;
            loc.version = 45;
            // Todo: No support for i64 offsets!
        }

        const dir = new zip.DirEntry({
            versionMadeBy: 31,
            headerOffset: result.length,
            versionToExtract: loc.version,
            crc: loc.crc,
            flags: loc.flags,
            fileName: loc.fileName,
            fileTime: loc.fileTime,
            compression: loc.compression,
            compressedSize: loc.compressedSize,
            uncompressedSize: loc.uncompressedSize,
            extraField: loc.extraField,
        });

        if (zip64) {
            zip64.unshift(U16, zip64.length);
            zip64.unshift(U16, zip.ExtensionId.Zip64);
            loc.extraField = zip64.clone().prepend(loc.extraField).raw;
            dir.extraField = zip64.clone().prepend(dir.extraField).raw;
        }

        result.push(zip.FileRecord, loc);
        result.append(dig);
        directories.push(dir);
    }

    let firstDirOffset: number = 0;
    for (const dir of directories) {
        firstDirOffset ||= result.length;
        result.push(zip.DirEntry, dir);
    }

    const end = new zip.EndLocator();
    end.directoryOffset = firstDirOffset;
    end.directorySize = result.length - end.directoryOffset;
    end.entriesInDirectory = directories.length;
    end.entriesOnDisk = end.entriesInDirectory;
    result.push(zip.EndLocator, end);
    return result.raw;
}
