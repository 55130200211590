import { CheckoutItemV2Manager } from '../CheckoutItemV2Manager';
import { SCANNER_SUBMIT_ROUTE } from '../CheckoutPaths';
import { CheckoutActions } from '../checkout.actions';
import { DoctorNotesCategoryV2, type CheckoutItemV2, type CheckoutState } from '../checkout.state';
import { checkoutBulkStateReducerMap } from './checkout-bulk.reducer';
import { initialState } from './checkout.state';
import { v2ActiveItemReducerMap } from './checkoutV2.reducer';
import { implantCheckoutActions } from './implant-checkout.reducer';
import { surgicalGuideCheckoutReducerMap } from './surgicalGuide-checkout.reducer';
import type { LocationChangePayload } from 'connected-react-router';
import { LOCATION_CHANGE } from 'connected-react-router';
import moment from 'moment';
import { matchPath } from 'react-router-dom';
import type { Reducer } from 'redux';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';

function reduceItemUpdate(
    state: CheckoutState,
    item_index: number,
    applyUpdate: (item: CheckoutItemV2) => CheckoutItemV2,
): CheckoutItemV2[] {
    const currentItem = state.items.find(i => i.item_index === item_index);
    if (!currentItem) {
        return state.items;
    }
    const resultItem = applyUpdate(currentItem);
    const otherItems = state.items.filter(i => i.item_index !== item_index);

    // Check if updated item is currently being bulk edited
    if (!state.bulkEditIds.includes(resultItem.bulk_edit_id)) {
        // updated item is not being bulk edited, bail
        return CheckoutItemV2Manager.sortItems([...otherItems, resultItem]);
    }

    // we need to update all items that have the same bulk edit id
    const itemsToUpdate = otherItems.filter(i => i.bulk_edit_id === resultItem.bulk_edit_id);
    const excludedFromUpdate = otherItems.filter(i => i.bulk_edit_id !== resultItem.bulk_edit_id);
    return CheckoutItemV2Manager.sortItems([
        resultItem,
        ...excludedFromUpdate,
        ...itemsToUpdate.map(existingItem => applyUpdate(existingItem)),
    ]);
}

// we reset everything except for the few properties unaffected by which scan is chosen
const baseStateAfterScanChange = (currentState: CheckoutState): CheckoutState => ({
    ...initialState,
    doctor: currentState.doctor,
    address: currentState.address,
    featureFlags: currentState.featureFlags,
});

const checkoutReducerBase = handleActions<CheckoutState, any>(
    {
        // reset state when the user navigates away from checkout
        [LOCATION_CHANGE]: (state: CheckoutState, action: Action<LocationChangePayload>) =>
            matchPath(action.payload.location.pathname, `/lab/submit`) ||
            matchPath(action.payload.location.pathname, SCANNER_SUBMIT_ROUTE)
                ? state
                : initialState,

        ...CheckoutActions.SET_FEATURE_FLAGS.reducer<CheckoutState>((state, action) => {
            if (state.step !== 0) {
                // We don't want to deal with feature flags changing after checkout has begun.
                return state;
            }
            return {
                ...state,
                featureFlags: action.payload,
            };
        }),

        ...CheckoutActions.SET_SCAN_IMG_SOURCES.reducer<CheckoutState>((state, action) => {
            if (!state.scan) {
                return state;
            }
            return {
                ...state,
                scan: { ...state.scan, image_urls: action.payload },
            };
        }),

        ...CheckoutActions.BACK_TO_EDIT_ORDER.reducer<CheckoutState>(state => {
            return { ...state, step: 1 };
        }),
        ...CheckoutActions.SET_CURRENT_NOTES_V2_CATEGORY.reducer<CheckoutState>((state, action) => {
            return { ...state, doctorNotesV2Category: action.payload };
        }),
        ...CheckoutActions.SET_ATTACHMENTS.reducer<CheckoutState>((state, action) => {
            return { ...state, attachments: action.payload };
        }),

        // We want to keep progress on scan items in state, so they are moved to `removed_scan_items`
        ...CheckoutActions.REMOVE_ITEM.reducer<CheckoutState>((state, action) => {
            const itemToRemove = state.items.find(i => i.item_index === action.payload);
            if (!itemToRemove) {
                return state;
            }
            const newItems = CheckoutItemV2Manager.sortItems(
                state.items.filter(item => item.item_index !== itemToRemove.item_index),
            );
            return {
                ...state,
                items: newItems,
                removed_scan_items: itemToRemove.from_scan_export
                    ? [...state.removed_scan_items, itemToRemove]
                    : state.removed_scan_items,
            };
        }),
        ...CheckoutActions.ADD_SCAN_ITEM_BACK.reducer<CheckoutState>((state, action) => {
            const itemToReAdd = state.removed_scan_items.find(i => i.item_index === action.payload);
            if (!itemToReAdd || !itemToReAdd.from_scan_export) {
                return state;
            }
            return {
                ...state,
                removed_scan_items: state.removed_scan_items.filter(item => item.item_index !== itemToReAdd.item_index),
                items: CheckoutItemV2Manager.sortItems(state.items.concat(itemToReAdd)),
            };
        }),
        ...CheckoutActions.SET_ADDRESS.reducer<CheckoutState>((state, action) => {
            return { ...state, address: action.payload };
        }),
        ...CheckoutActions.SET_DOCTOR.reducer<CheckoutState>((state, action) => {
            // you have to have a doctor to progress pass step 0
            const step = !action.payload && state.step !== 0 ? 0 : state.step;
            return { ...state, step, doctor: action.payload };
        }),

        ...CheckoutActions.SET_DOCTOR_NOTES_OPEN.reducer<CheckoutState>((state, action) => {
            return { ...state, doctorNotesOpen: action.payload };
        }),
        ...CheckoutActions.SET_DOCTOR_NOTES.reducer<CheckoutState>((state, action) => {
            if (action.payload) {
                return { ...state, doctorNotesOpen: true, doctor_notes: action.payload ?? undefined };
            }

            return { ...state, doctor_notes: action.payload ?? undefined };
        }),
        ...CheckoutActions.SET_IS_TRAINING_ORDER.reducer<CheckoutState>((state, action) => {
            return { ...state, is_training_order: !!action.payload };
        }),
        ...CheckoutActions.UPDATE_LINE_ITEM.reducer<CheckoutState>((state, action) => {
            return {
                ...state,
                items: reduceItemUpdate(state, action.payload.item_index, item =>
                    CheckoutItemV2Manager.applyUpdate(item, action.payload.change),
                ),
            };
        }),
        ...CheckoutActions.CHANGE_ITEM_TYPE.reducer<CheckoutState>((state, action) => {
            return {
                ...state,
                items: reduceItemUpdate(state, action.payload.item_index, item =>
                    CheckoutItemV2Manager.changeSKUType(item, action.payload.unit_type),
                ),
            };
        }),
        // Reset everything except for the doctor and address
        ...CheckoutActions.CLEAR_SCAN.reducer<CheckoutState>(state => baseStateAfterScanChange(state)),
        ...CheckoutActions.SET_SCAN.reducer<CheckoutState>((state, { payload: newScan }): CheckoutState => {
            if (state.scan && state.scan.id === newScan.id) {
                return state;
            }

            const items = CheckoutItemV2Manager.cartItemsToCheckoutItems(newScan.cart_items_v2);

            const baseState = baseStateAfterScanChange(state);

            return {
                ...baseState,
                items,
                scan: newScan,
                patient_first_name: newScan.patient_first_name,
                patient_last_name: newScan.patient_last_name,
                // correct date to local timezone
                patient_birthday: newScan.patient_birthday
                    ? moment.utc(newScan.patient_birthday).set({ hour: 12 }).toDate()
                    : undefined,
                bulkEditIds: CheckoutItemV2Manager.getInitialBulkEditIds(items),
                doctor_notes: newScan.case_comments ?? undefined,
                // If the scan provides some notes, we'll open the section by default so that the doctor
                // can see what is being submitted, as otherwise we default to the section being closed.
                doctorNotesOpen: newScan.case_comments ? true : baseState.doctorNotesOpen,
                doctorNotesV2Category: newScan.case_comments
                    ? DoctorNotesCategoryV2.ExtraInstructions
                    : baseState.doctorNotesV2Category,
            };
        }),

        ...CheckoutActions.SET_PATIENT_INFO.reducer<CheckoutState>((state, { payload }) => {
            return {
                ...state,
                patient_first_name: payload.type === 'first' ? payload.value : state.patient_first_name,
                patient_last_name: payload.type === 'last' ? payload.value : state.patient_last_name,
                patient_birthday: payload.type === 'birthday' ? payload.value || undefined : state.patient_birthday,
                patient_gender: payload.type === 'gender' ? payload.value : state.patient_gender,
            };
        }),

        ...CheckoutActions.SET_PATIENT_AND_DOCTOR_INFO.reducer<CheckoutState>((state, { payload }) => {
            return {
                ...state,
                patient_birthday: new Date(payload.birthday),
                patient_gender: payload.gender ?? undefined,
                doctor: payload.doctor,
                address: payload.address,
                existingOrderWarningDismissed: true,
            };
        }),

        ...CheckoutActions.SET_LINE_ITEM_METAFIELD.reducer<CheckoutState>((state, action) => {
            const { item_index, field } = action.payload;
            const currentItem = state.items.find(i => i.item_index === item_index);
            if (!currentItem) {
                return state;
            }
            const existingItems = state.items.filter(i => i.item_index !== item_index);
            const currentFields = currentItem.preference_fields.filter(f => f.field_id !== field.field_id);
            const updatedItem = { ...currentItem, preference_fields: [...currentFields, field] };
            const newState = {
                ...state,
                items: CheckoutItemV2Manager.sortItems([...existingItems, updatedItem]),
            };
            const bulkEditId = currentItem.bulk_edit_id;
            if (newState.bulkEditIds.includes(bulkEditId)) {
                return {
                    ...state,
                    items: newState.items.map(item => {
                        if (item.bulk_edit_id !== bulkEditId) {
                            return item;
                        }
                        return { ...item, preference_fields: updatedItem.preference_fields };
                    }),
                };
            }
            return newState;
        }),

        ...CheckoutActions.DISMISS_EXISTING_ORDER_SCREEN.reducer<CheckoutState>(state => ({
            ...state,
            existingOrderWarningDismissed: true,
        })),
        ...CheckoutActions.START_REFABRICATION.reducer<CheckoutState>((state, { payload: order }) => ({
            ...state,
            refab: { original_order: order, attachments: [], reasons: [] },
            existingOrderWarningDismissed: true,
            patient_birthday: state.patient_birthday ?? new Date(order.patient.birthday),
            patient_gender: state.patient_gender ?? order.patient.gender ?? undefined,
            patient_first_name: state.patient_first_name ?? order.patient.birthday,
            // todo: future - map the original order item selections into the checkout items
        })),
        ...CheckoutActions.SET_REFAB_ATTACHMENTS.reducer<CheckoutState>((state, { payload: attachments }) => {
            return !state.refab ? state : { ...state, refab: { ...state.refab, attachments } };
        }),
        ...CheckoutActions.SET_REFAB_NOTES.reducer<CheckoutState>((state, action) => {
            return !state.refab ? state : { ...state, refab: { ...state.refab, notes: action.payload } };
        }),
        ...CheckoutActions.SET_REFAB_REASONS.reducer<CheckoutState>((state, { payload: reasons }) => {
            return !state.refab ? state : { ...state, refab: { ...state.refab, reasons } };
        }),
        ...CheckoutActions.SET_REFAB_FILES_UPLOADING.reducer<CheckoutState>((state, action) => {
            return !state.refab ? state : { ...state, refab: { ...state.refab, attachmentsUploading: action.payload } };
        }),
        ...CheckoutActions.SET_WAXUP_SELECTED.reducer<CheckoutState>((state, action) => {
            // Disable auto-select after the selection has been made for the first time.
            return {
                ...state,
                waxupState: { ...state.waxupState, permitAutoSelect: false, selected: action.payload },
            };
        }),
        ...CheckoutActions.SET_WAXUP_TOOLTIP_DISMISSED.reducer<CheckoutState>((state, action) => {
            return { ...state, waxupState: { ...state.waxupState, tooltipDismissed: action.payload } };
        }),
        ...CheckoutActions.SET_SMILE_STYLE.reducer<CheckoutState>((state, action) => ({
            ...state,
            smileStyle: action.payload,
        })),
        ...CheckoutActions.SET_SUGGESTED_ITEM_RENDERED.reducer<CheckoutState>(state => ({
            ...state,
            suggestedItemState: 'rendered',
        })),
        ...CheckoutActions.SET_SUGGESTED_ITEM_SELECTED.reducer<CheckoutState>(state => ({
            ...state,
            suggestedItemState: 'selected',
        })),
        ...CheckoutActions.SET_AESTHETIC_CHECKOUT_PHOTOS.reducer<CheckoutState>((state, action) => ({
            ...state,
            aestheticCheckout: {
                ...state.aestheticCheckout,
                photos: action.payload,
            },
        })),
        ...CheckoutActions.START_AESTHETIC_PHOTOS_UPLOAD.reducer<CheckoutState>(state => ({
            ...state,
            aestheticCheckout: {
                ...state.aestheticCheckout,
                screen: 'upload',
                missingUploadWarningShowing: false,
                allowInstructions: false,
            },
        })),
        ...CheckoutActions.AESTHETIC_PHOTOS_CHECK_INSTRUCTIONS.reducer<CheckoutState>(state => ({
            ...state,
            aestheticCheckout: {
                ...state.aestheticCheckout,
                screen: 'instructions',
                allowInstructions: true,
                missingUploadWarningShowing: false,
            },
        })),
        ...CheckoutActions.SET_SLA_MODIFIER.reducer<CheckoutState>((state, action) => ({
            ...state,
            sla_modifier: action.payload,
        })),
    },
    initialState,
);

const bulkReducer = handleActions<CheckoutState, any>(checkoutBulkStateReducerMap, initialState);
const v2ActiveItemReducer = handleActions<CheckoutState, any>(v2ActiveItemReducerMap, initialState);
const implantReducer = handleActions<CheckoutState, any>(implantCheckoutActions, initialState);
const surgialGuideReducer = handleActions<CheckoutState, any>(surgicalGuideCheckoutReducerMap, initialState);

export const checkoutReducer: Reducer<CheckoutState, any> = (state, action) => {
    return [
        checkoutReducerBase,
        bulkReducer,
        v2ActiveItemReducer,
        implantReducer,
        surgialGuideReducer,
    ].reduce<CheckoutState>((currState, reducer) => reducer(currState, action), state ?? initialState);
};
