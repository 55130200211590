import { CoreSummary, FixUpSummary, WaxRimSummary, TryInFollowupSummary } from './DentureSummary';
import type {
    LabsGqlDenturesSingleFulfillmentFragment as Dentures,
    LabsGqlDenturesSingleFulfillment_DenturesFulfillmentCoreDto_Fragment as Core,
    LabsGqlDenturesSingleFulfillment_DenturesFulfillmentWaxRimDto_Fragment as WaxRim,
    LabsGqlDenturesSingleFulfillment_DenturesFulfillmentFixupDto_Fragment as FixUp,
    LabsGqlDenturesSingleFulfillment_DenturesFulfillmentTryInFollowupDto_Fragment as TryInFollowup,
} from '@orthly/graphql-operations';
import { LabsGqlDenturesSingleFulfillmentType as DenturesType } from '@orthly/graphql-schema';
import type { OrderItemArch } from '@orthly/items';
import React from 'react';

const is_wax_rim = (fulfillment: Dentures): fulfillment is WaxRim => {
    return fulfillment.type === DenturesType.WaxRim;
};

const is_core = (fulfillment: Dentures): fulfillment is Core => {
    return fulfillment.type === DenturesType.Core;
};

const is_tryin_followup = (fulfillment: Dentures): fulfillment is TryInFollowup => {
    return fulfillment.type === DenturesType.TryInFollowup;
};

const WaxRimSummaryForOrderDetails: React.VFC<{
    fulfillment: WaxRim;
}> = ({ fulfillment }) => {
    return <WaxRimSummary arch={fulfillment.arch as string as OrderItemArch} />;
};

const CoreSummaryForOrderDetails: React.VFC<{
    fulfillment: Core;
}> = ({ fulfillment }) => {
    return <CoreSummary upper={fulfillment.upper_arch ?? undefined} lower={fulfillment.lower_arch ?? undefined} />;
};

const FixUpSummaryForOrderDetails: React.VFC<{
    fulfillment: FixUp;
}> = ({ fulfillment }) => {
    return <FixUpSummary upper={fulfillment.upper_arch ?? undefined} lower={fulfillment.lower_arch ?? undefined} />;
};

const TryInFollowupSummaryForOrderDetails: React.VFC<{
    fulfillment: TryInFollowup;
}> = ({ fulfillment }) => {
    return (
        <TryInFollowupSummary upper={fulfillment.upper_arch ?? undefined} lower={fulfillment.lower_arch ?? undefined} />
    );
};

export const DentureSummaryForOrderDetails: React.VFC<{ fulfillment: Dentures }> = ({ fulfillment }) => {
    if (is_wax_rim(fulfillment)) {
        return <WaxRimSummaryForOrderDetails fulfillment={fulfillment} />;
    }
    if (is_core(fulfillment)) {
        return <CoreSummaryForOrderDetails fulfillment={fulfillment} />;
    }
    if (is_tryin_followup(fulfillment)) {
        return <TryInFollowupSummaryForOrderDetails fulfillment={fulfillment} />;
    }
    return <FixUpSummaryForOrderDetails fulfillment={fulfillment} />;
};
