// Margin lines drawn by the doctor
import { IDENTITY_16ARRAY } from './MarginMesh';
import type { Length16Array } from './ModelMeshes';
import { isLength16Array } from './ModelMeshes';
import type { ModelViewerOxzMarginInfo } from './NewModelViewer.types';
import type { OrderDesignPreviewDesign_FragmentFragment } from '@orthly/graphql-inline-react';
import { LabsGqlDesignRevisionCustomSplineType } from '@orthly/graphql-schema';
import type { LabsGqlBasicMarginInfo } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import type { MarginLine, InternalDesignMetadata } from '@orthly/shared-types';
import _ from 'lodash';
import React from 'react';

/*
 * A hook to get doctor margins by either
 * 1. Sourcing them from customMargins object, which are parsed form DCM scans in designs
 *
 * 2. Source them from the scan export object, and apply the relevant design transforms to
 *    align them to the design.
 *
 * 3.  Source them from the scan export object, and apply no transforms (eg, for displaying on scans)
 * */
export function useDoctorMargins(
    oxzMarginInfo: ModelViewerOxzMarginInfo | null,
    designMetadata: InternalDesignMetadata | undefined,
    customMargins: OrderDesignPreviewDesign_FragmentFragment['custom_margins'] | undefined,
    transformScanMargins: boolean,
    disableDoctorMargins: boolean,
): MarginLine[] {
    const doctorMarginLines = React.useMemo<MarginLine[]>(() => {
        if (disableDoctorMargins) {
            return [];
        }

        // First, check custom margins, this is the happy path
        // these are parsed right from the MBScan in the design.zip
        // and live in the correct coordinate frame
        const scanDcmMargins = customMargins?.filter(
            m => m.spline_type === LabsGqlDesignRevisionCustomSplineType.DcmDoctorMargin,
        );
        if (scanDcmMargins && scanDcmMargins.length > 0) {
            return scanDcmMargins.map<MarginLine>(datum => ({
                tooth: datum.unn,
                transformationMatrix: IDENTITY_16ARRAY,
                coords: _.chunk(datum.coords, 3).filter(chunk => chunk.length === 3) as [number, number, number][],
                isDoctorMargin: true,
            }));
        }

        // Fall back to the 3oxz extracted doctor margins
        // Get transforms to align the scan source margins to the design if desired
        const transform = designMetadata?.transforms?.UpperJaw2LowerJaw?.transform;
        const isTransformValid = isLength16Array(transform);

        return (oxzMarginInfo?.doctorMargins ?? [])
            .filter((mL: LabsGqlBasicMarginInfo) => !!mL.coords?.length)
            .map<MarginLine>(datum => {
                return {
                    tooth: datum.unn,
                    transformationMatrix:
                        ToothUtils.toothIsUpper(datum.unn) && transformScanMargins && isTransformValid
                            ? (transform as Length16Array)
                            : IDENTITY_16ARRAY,
                    coords: _.chunk(datum.coords, 3).filter(chunk => chunk.length === 3) as [number, number, number][],
                    isDoctorMargin: true,
                };
            });
    }, [customMargins, oxzMarginInfo, disableDoctorMargins, designMetadata, transformScanMargins]);

    return doctorMarginLines;
}
