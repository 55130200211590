import type { FileUploaderField } from './file-uploader-types';
import React from 'react';

export function buildFBUploadPath(ogFileName: string, field: FileUploaderField, prependTimestamps: boolean = false) {
    const extension = ogFileName.includes('.') ? ogFileName.split('.').pop() : undefined;
    let rootName = field.uploadName ? field.uploadName : ogFileName;
    if (extension && !rootName.includes('.')) {
        rootName += `.${extension}`;
    }
    if (prependTimestamps) {
        rootName = `${new Date().toJSON()}-${rootName}`;
    }
    if (field.path) {
        const trailingSlash = field.path[field.path.length - 1] === '/' ? '' : '/';
        return `${field.path}${trailingSlash}${rootName}`;
    }
    return rootName;
}

export function formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0) {
        return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export type FilesStateGen<Value, K extends string = string> = { [key in K]: Value };

export function useFileFieldsState<Value, K extends string = string>(
    fileFields: FileUploaderField<K>[],
    initialValue: Value,
): [FilesStateGen<Value, K>, (f: K, v: Value) => void] {
    const initialState = fileFields.reduce(
        (initState, field) => ({ ...initState, [field.fieldKey]: initialValue }),
        {} as FilesStateGen<Value, K>,
    );
    const [state, setState] = React.useState<FilesStateGen<Value, K>>(initialState);
    const updateState = React.useCallback(
        (fieldKey: K, newValue: Value) => setState(s => ({ ...s, [fieldKey]: newValue })),
        [],
    );
    return [state, updateState];
}
