import type { OrbitControlsProps } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';
import { Vector3 } from 'three';

export type ScaleChangeCallback = (scale: number) => void;
type CanvasScaleSyncType = React.FC<{
    cameraControlsRef: React.MutableRefObject<OrbitControlsProps | null>;
    onScaleChange: ScaleChangeCallback;
}>;

/**
 * Pseudo component tracking changes in THREE Canvas
 * scale changes caused by camera controls
 *
 * @component
 */
export const CanvasScaleSync: CanvasScaleSyncType = ({ cameraControlsRef, onScaleChange }) => {
    const { gl, camera } = useThree();

    React.useEffect(() => {
        const handleCameraChange = () => {
            const rect = gl.domElement.getClientRects()[0];

            // camera's normalized device coordinate (NDC) space
            const v1 = new Vector3(1, 0, 0).project(camera);
            const v0 = new Vector3(0, 0, 0).project(camera);

            const halfWidth = rect ? rect.width / 2 : 1920;
            const scale = 1 / (v0.sub(v1).length() * halfWidth);

            onScaleChange(scale);
        };

        cameraControlsRef?.current?.addEventListener?.('change', handleCameraChange);

        // Initial call
        handleCameraChange();

        // grab the current cameraControls, in case it changes before cleanup runs
        const cameraControls = cameraControlsRef?.current;
        return () => {
            cameraControls?.removeEventListener?.('change', handleCameraChange);
        };
    }, [cameraControlsRef, camera, gl.domElement, onScaleChange]);

    return <></>;
};
