import { PrintOrderAction } from '../../actions/PrintOrderAction';
import { OrderDetailSubtitle } from './OrderDetailSubtitle';
import { TopbarCaptions } from './TopBar/TopbarCaptions';
import { TopbarTitle } from './TopBar/TopbarTitle';
import { SimpleCopyToClipboard } from '@orthly/dentin';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useOrderRefetch } from '@orthly/graphql-react';
import { ToolbarContainer } from '@orthly/ui';
import { Button, styled, useScreenIsSm } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ToolbarContainerRoot = styled(ToolbarContainer)({
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    gap: '24px',
    '&&': {
        height: 'fit-content',
        minHeight: 'fit-content',
        padding: '24px',
    },
});
const LeftSideContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
});
const TitleGroupContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});
const MainTitleContainer = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    gap: '8px',
    flexDirection: 'row',
    flexGrow: 1,
    minWidth: 0,
});

const RightSideContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
});
const OrderDetailsGroup = styled('div')({
    display: 'flex',
    alignItems: 'baseline',
    gap: '32px',
});
const ActionItemsGroup = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

// Mobile styles
const ToolbarContainerMobileRoot = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    minHeight: 'fit-content',
    gap: '16px',
    padding: '12px 24px',
});
const FirstRowContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
});
const MobileTitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: '8px',
});
const SecondRowContainer = styled('div')({
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    overflow: 'hidden',
});
const BackButton = styled(Button)({
    height: 'unset',
    padding: '12px 2px',
});

interface OrderDetailTopBarSummaryProps {
    order: LabsGqlSingleLabOrderFragment;
}

export const OrderDetailTopBarV2: React.FC<OrderDetailTopBarSummaryProps> = ({ order }) => {
    const refetchOrder = useOrderRefetch();
    const history = useHistory();
    const screenIsSm = useScreenIsSm();

    if (screenIsSm) {
        return (
            <ToolbarContainerMobileRoot>
                <FirstRowContainer>
                    <MobileTitleContainer>
                        <TopbarTitle order={order} />
                    </MobileTitleContainer>
                    <OrderDetailSubtitle order={order} />
                </FirstRowContainer>
                <SecondRowContainer>
                    <TopbarCaptions
                        order={order}
                        refetch={async () => {
                            refetchOrder(order.id);
                        }}
                        showCopyToClipboard
                    />
                </SecondRowContainer>
            </ToolbarContainerMobileRoot>
        );
    }

    return (
        <ToolbarContainerRoot dataPendoId={'order-detail-topbar'}>
            <LeftSideContainer>
                <BackButton variant={'secondary-gray'} startIcon={'ChevronLeft'} onClick={() => history.goBack()}>
                    Back
                </BackButton>
                <TitleGroupContainer>
                    <MainTitleContainer>
                        <TopbarTitle order={order} />
                    </MainTitleContainer>
                    <OrderDetailSubtitle order={order} />
                </TitleGroupContainer>
            </LeftSideContainer>
            <RightSideContainer>
                <OrderDetailsGroup>
                    <TopbarCaptions
                        order={order}
                        refetch={async () => {
                            refetchOrder(order.id);
                        }}
                    />
                </OrderDetailsGroup>
                <ActionItemsGroup>
                    <SimpleCopyToClipboard
                        isIconOnly
                        variant={'ghost'}
                        tooltip={'Copy order ID'}
                        value={order?.order_number ?? ''}
                    />
                    <PrintOrderAction order={order} refetch={() => refetchOrder(order.id)} isIconOnly />
                </ActionItemsGroup>
            </RightSideContainer>
        </ToolbarContainerRoot>
    );
};
