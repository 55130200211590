import { useRowsFromInvoiceItems } from '../../invoice-detail/components/InvoiceDetailBody.utils';
import type { InvoiceItemRow } from '../../invoice-detail/components/InvoiceTransactionsTable';
import type {
    ContractSpendTerm,
    InvoicePayment,
    InvoiceTableRow,
    PendingOrgInvoiceItem,
    PracticeInvoice,
    RawPracticeInvoice,
} from '../../invoicing.types';
import {
    convertToPracticeInvoices,
    getTargetPendingInvoiceCreationDate,
    useInvoicesForTableData,
} from '../../invoicing.utils';
import {
    GetActiveContractAndAssociatedOrgsByOrganizationId_Query,
    GetPracticeBillingOverview_Query,
    GetPracticeContractV2ByOrganizationId_Query,
    GetPracticePendingInvoiceItems_Query,
    GetPracticePendingInvoiceItemsForContract_Query,
    ListInvoiceItemsForExport_Query,
    listInvoicePaymentsForExport_Query,
    ListInvoicesWithItemSummaries_Query,
    ListInvoicesWithItemSummariesForActiveContract_Query,
} from './InvoiceTableProviderQueries.graphql';
import type { OperationVariables, QueryResult } from '@apollo/client';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
    type GetPracticePendingInvoiceItemsQuery,
    type ListInvoicesWithItemSummariesForActiveContractQuery,
    type ListInvoicesWithItemSummariesQuery,
} from '@orthly/graphql-inline-react';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrdersByIds } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useFeatureFlag, usePrintableState } from '@orthly/veneer';
import { compact, sumBy } from 'lodash';
import React from 'react';

interface PendingItems {
    pending_total_cents: number;
    pending_orders_count: number;
}

interface InvoicesTableContextType {
    invoices: PracticeInvoice[];
    refetch: () => Promise<unknown>;
    findInvoiceById: (id: string) => PracticeInvoice | undefined;
    tableInvoices: InvoiceTableRow[];
    loading: boolean;
    pendingItems: PendingItems;
    autochargeEnabled: boolean;
    practiceContractSpendTerms: ContractSpendTerm[];
    currentSpendTerm: ContractSpendTerm | undefined;
    partnerId: string;

    /** Multi-Location Contract Info */
    isPracticePrimaryForContract: boolean;
    associatedPractices: { id: string; name: string }[];
    selectedOrganizationId: string | undefined;
    setSelectedOrganizationId: React.Dispatch<React.SetStateAction<string>>;
    selectedInvoiceIds: string[];
    setSelectedInvoiceIds: React.Dispatch<React.SetStateAction<string[]>>;
    payableSelectedInvoices: PracticeInvoice[];

    /** printability */
    isReadyToPrint: boolean;
    setIsReadyToPrint: React.Dispatch<React.SetStateAction<boolean>>;
    fetchPrintData: () => void;
    fetchPrintDataLoading: boolean;
    printableSlipIsOpen: boolean;
    openPrintableSlip: () => void;
    closePrintableSlip: () => void;
    invoicePayments: InvoicePayment[];
    rows: InvoiceItemRow[];
}

export const SELECT_ALL_ORGANIZATIONS = 'All locations';

const InvoicesTableContext = React.createContext<InvoicesTableContextType | null>(null);

// TODO: EPDB-975: refactor helper once the feature flag is removed
const getInvoiceData = (
    enableMultiLocationContracts: boolean,
    multiLocInvoicesQuery: QueryResult<ListInvoicesWithItemSummariesForActiveContractQuery>,
    singleLocInvoicesQuery: QueryResult<ListInvoicesWithItemSummariesQuery>,
) => {
    const rawInvoices = enableMultiLocationContracts
        ? multiLocInvoicesQuery?.data?.listInvoicesWithItemSummariesForActiveContract
        : singleLocInvoicesQuery?.data?.listInvoicesWithItemSummariesForOrganization;
    const practiceInvoices = convertToPracticeInvoices<RawPracticeInvoice>(rawInvoices ?? []);

    const { loading, refetch } = enableMultiLocationContracts ? multiLocInvoicesQuery : singleLocInvoicesQuery;

    return { practiceInvoices, loading, refetch };
};

// TODO: EPDB-975: refactor helper once the feature flag is removed
const getPendingItemData = (
    enableMultiLocationContracts: boolean,
    multiLocPendingItemsQuery: QueryResult<
        {
            previewPracticePendingInvoiceItemsForActiveContract: PendingOrgInvoiceItem[];
        },
        OperationVariables
    >,
    singleLocPendingItemsQuery: QueryResult<GetPracticePendingInvoiceItemsQuery>,
    orgId: string,
): { pendingItemsRaw: PendingOrgInvoiceItem[]; loading: boolean } => {
    const pendingItemsRaw = enableMultiLocationContracts
        ? multiLocPendingItemsQuery?.data?.previewPracticePendingInvoiceItemsForActiveContract
        : singleLocPendingItemsQuery?.data?.previewPracticePendingInvoiceItems?.map<PendingOrgInvoiceItem>(i => ({
              ...i,
              organization_id: orgId,
          }));

    const { loading } = enableMultiLocationContracts ? multiLocPendingItemsQuery : singleLocPendingItemsQuery;
    return { pendingItemsRaw: compact(pendingItemsRaw), loading };
};

// eslint-disable-next-line max-lines-per-function
export const InvoicesTableCtxProvider: React.FC = ({ children }) => {
    const { value: enableMultiLocationContracts = false } = useFeatureFlag('enableMultiLocationContracts');
    const partnerId = useSession()?.organization_id ?? '';
    const [selectedOrganizationId, setSelectedOrganizationId] = React.useState(SELECT_ALL_ORGANIZATIONS);
    const [selectedInvoiceIds, setSelectedInvoiceIds] = React.useState<string[]>([]);

    React.useEffect(() => {
        // when the organization id changes, deselect any selected invoices
        setSelectedInvoiceIds([]);
    }, [selectedOrganizationId]);

    // fetch partner billing data
    const { data: { getPartnerBillingAccount: partnerBillingAccount } = {} } = useQuery(
        GetPracticeBillingOverview_Query,
        {
            variables: { partnerId },
            fetchPolicy: 'no-cache',
            skip: !partnerId,
        },
    );

    // fetch (new) contract data
    const { data: { getActiveContractAndAssociatedOrgsByOrganizationId: contractData } = {} } = useQuery(
        GetActiveContractAndAssociatedOrgsByOrganizationId_Query,
        {
            variables: { organizationId: partnerId },
            fetchPolicy: 'no-cache',
            skip: !enableMultiLocationContracts || !partnerId,
        },
    );

    const { associatedPractices = [], contract, primaryPracticeId } = contractData ?? {};

    /** Pending Items ------------ */
    // TODO: EPDB-975: remove legacy query
    const previewPendingItemsQuery = useQuery(GetPracticePendingInvoiceItems_Query, {
        variables: {
            targetCreationDate: getTargetPendingInvoiceCreationDate().toString(),
        },
        skip: enableMultiLocationContracts,
    });

    const previewPendingItemsForContractQuery = useQuery<{
        previewPracticePendingInvoiceItemsForActiveContract: PendingOrgInvoiceItem[];
    }>(GetPracticePendingInvoiceItemsForContract_Query, {
        variables: {
            targetCreationDate: getTargetPendingInvoiceCreationDate().toString(),
        },
        skip: !enableMultiLocationContracts,
    });

    const { pendingItemsRaw, loading: practicePendingItemsLoading } = getPendingItemData(
        enableMultiLocationContracts,
        previewPendingItemsForContractQuery,
        previewPendingItemsQuery,
        partnerId,
    );

    const pendingItems = pendingItemsRaw?.filter(p => {
        if (!enableMultiLocationContracts) {
            return true;
        }
        return p.organization_id === selectedOrganizationId || selectedOrganizationId === SELECT_ALL_ORGANIZATIONS;
    });

    const orderPendingItems = React.useMemo(() => {
        const onlyPendingOrderItems = pendingItems?.filter(i => !!i.order_id);
        return {
            pending_total_cents: sumBy(onlyPendingOrderItems, 'amount_cents') ?? 0,
            pending_orders_count: onlyPendingOrderItems?.length ?? 0,
        };
    }, [pendingItems]);
    /** End Pending Items ------------ */

    /** Invoices --------------------- */
    // fetch invoices for the current contract
    // if the requesting org id is the primary on the contract, the response will include
    // invoices for *all* associated practices
    // else, the response will only include invoices for the requesting org
    const invoicesWithItemSummariesForActiveContract = useQuery(ListInvoicesWithItemSummariesForActiveContract_Query, {
        skip: !enableMultiLocationContracts || !contract?.id || !partnerId,
    });

    const invoicesWithItemSummaries = useQuery(ListInvoicesWithItemSummaries_Query, {
        skip: enableMultiLocationContracts,
    });

    const {
        practiceInvoices,
        loading: rawInvoicesLoading = true,
        refetch: refetchRawInvoices,
    } = getInvoiceData(
        enableMultiLocationContracts,
        invoicesWithItemSummariesForActiveContract,
        invoicesWithItemSummaries,
    );

    const invoices = practiceInvoices.filter(i => {
        if (!enableMultiLocationContracts) {
            return true;
        }
        return i.organization_id === selectedOrganizationId || selectedOrganizationId === SELECT_ALL_ORGANIZATIONS;
    });

    const findInvoiceById = React.useCallback(
        (id: string) => {
            return invoices?.find(i => i.id === id);
        },
        [invoices],
    );
    /** End Invoices --------------------- */

    /** Printability --------------------- */
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();
    const [isReadyToPrint, setIsReadyToPrint] = React.useState(false);
    const [
        fetchInvoiceItems,
        { data: { invoiceItems = [] } = {}, loading: fetchInvoiceItemsLoading, called: invoiceItemsFetched },
    ] = useLazyQuery(ListInvoiceItemsForExport_Query);

    const [
        fetchInvoicePayments,
        { data: { invoicePayments = [] } = {}, loading: fetchInvoicePaymentsLoading, called: invoicePaymentsFetched },
    ] = useLazyQuery(listInvoicePaymentsForExport_Query);

    // fetch the data necessary to populate the invoice(s)
    const fetchPrintData = React.useCallback(() => {
        fetchInvoiceItems({ variables: { invoiceIds: selectedInvoiceIds } });
        fetchInvoicePayments({ variables: { invoiceIds: selectedInvoiceIds } });
    }, [fetchInvoiceItems, fetchInvoicePayments, selectedInvoiceIds]);

    const orderIds = React.useMemo(() => compact(invoiceItems.map(i => i.order_id)), [invoiceItems]);
    const { orders: ordersRaw, loading: ordersLoading } = useOrdersByIds(orderIds);
    const orders = React.useMemo<LabsGqlOrder[]>(() => compact(ordersRaw), [ordersRaw]);
    // TODO: EPDB-1063: wire up credits and credit categories
    const rows = useRowsFromInvoiceItems(invoiceItems, orders, [], []);

    React.useEffect(() => {
        // once we've retrieved the necessary (additional) data, we can open the printable slip
        if (
            !fetchInvoiceItemsLoading &&
            invoiceItemsFetched &&
            !fetchInvoicePaymentsLoading &&
            invoicePaymentsFetched &&
            !ordersLoading
        ) {
            setIsReadyToPrint(true);
        }
    }, [
        fetchInvoiceItemsLoading,
        invoiceItemsFetched,
        fetchInvoicePaymentsLoading,
        invoicePaymentsFetched,
        ordersLoading,
    ]);
    /** End Printability --------------------- */

    const autochargeEnabled = React.useMemo(
        () => partnerBillingAccount?.autocharge_enabled ?? false,
        [partnerBillingAccount],
    );

    // fetch (old) contract data
    // TODO: EPDB-975: remove legacy code
    const { data: practiceContractData } = useQuery(GetPracticeContractV2ByOrganizationId_Query, {
        variables: { organization_id: partnerId },
        skip: enableMultiLocationContracts,
    });

    // TODO: EPDB-975: simplify ternary
    const spendTerms = enableMultiLocationContracts
        ? contractData?.contract?.spend_terms ?? []
        : practiceContractData?.getPracticeContractV2ByOrganizationId?.spend_terms ?? [];

    const currentSpendTerm = spendTerms.find(t => {
        const today = new Date();
        const startsBeforeToday = new Date(t.effective_start_date ?? '') <= today;
        // if the spend term does not have an end date, it's active (rolling term)
        const endsAfterToday = t.effective_end_date ? new Date(t.effective_end_date) >= today : true;
        return startsBeforeToday && endsAfterToday;
    });

    // the overview table uses additional properties that are not present in the raw invoices
    const tableInvoices = useInvoicesForTableData(
        invoices,
        spendTerms,
        // specifically allowing this to be an array, an empty array, or undefined
        // if undefined, the preview data is not applicable, and if empty, there are no
        // pending items (but we should still represent the current spend)
        pendingItems,
    );

    const isInvoicePayable = (i: PracticeInvoice) => {
        const isOpen = i.status === 'open';
        const amountPaidAndPending = i.amount_paid + i.pending_payment_amount_cents;
        const amountRemaining = i.amount_due - amountPaidAndPending;

        return isOpen && amountRemaining > 0;
    };

    const payableSelectedInvoices = compact(selectedInvoiceIds.map(id => findInvoiceById(id))).filter(isInvoicePayable);

    return (
        <InvoicesTableContext.Provider
            value={{
                invoices,
                refetch: refetchRawInvoices,
                findInvoiceById,
                tableInvoices,
                loading: rawInvoicesLoading || practicePendingItemsLoading,
                pendingItems: orderPendingItems,
                autochargeEnabled,
                practiceContractSpendTerms: spendTerms,
                currentSpendTerm,
                partnerId: partnerId ?? '',

                /** Multi-Location Contract Info */
                isPracticePrimaryForContract: primaryPracticeId === partnerId,
                associatedPractices,
                selectedOrganizationId,

                /** Multi-Location controls */
                setSelectedOrganizationId,
                selectedInvoiceIds,
                setSelectedInvoiceIds,
                payableSelectedInvoices,

                /** printability */
                fetchPrintData,
                fetchPrintDataLoading: fetchInvoiceItemsLoading,
                setIsReadyToPrint,
                isReadyToPrint,
                printableSlipIsOpen: printableIsOpen,
                openPrintableSlip: openPrintable,
                closePrintableSlip: closePrintable,
                invoicePayments,
                rows,
            }}
        >
            {children}
        </InvoicesTableContext.Provider>
    );
};

export function useInvoicesTableContext() {
    const ctx = React.useContext(InvoicesTableContext);
    if (!ctx) {
        throw new Error('useInvoicesTableContext must be used within a InvoicesTableCtxProvider');
    }

    return ctx;
}
