import { ParseModellingTreeUtils } from './ParseModellingTree.util';
import type { ToothNumber } from '@orthly/items';
import { create } from 'xmlbuilder2';
import * as xpath from 'xpath';

/**
 * Gets nodes from the DentalDesignerModellingTree DOM.
 */
export class ModellingTreeQuerier {
    private features: Node;

    constructor(ddmtXml: string) {
        const root = create(ddmtXml).root();
        this.features = xpath.select1(
            `/NSITree/Features/Feature[@name='OrderList']/ChildFeatures/Feature/ChildFeatures`,
            root.node as any,
        ) as Node;
    }

    getCutSplines(prepScanId: string): Node[] {
        return xpath.select(
            createXPathQuery(
                `Feature[@name='NewScanList']`,
                `ChildFeatures`,
                `Feature[@name='PreparationScan container ${prepScanId}']`,
                `ChildFeatures`,
                `Feature[@name='Scans sectioning ${prepScanId}']`,
                `ChildFeatures`,
                `Feature[@name='SCopy sectioning container ${prepScanId}']`,
                `ChildFeatures`,
                `Feature[@name='Scan sectioning ${prepScanId}']`,
                `Object[@name='CutSplines']`,
                `Splines`,
                `Object[@type='TSysSpline']`,
            ),
            this.features,
        ) as Node[];
    }

    getDrillDirection(modelJobId: string): Element {
        return xpath.select1(`Vector[@name='DrillDirection']`, this.getDrillDirectionFeature(modelJobId)) as Element;
    }

    getDirectionPrepLine(modelJobId: string, unn?: ToothNumber): Node {
        const splinesQuery = createXPathQuery(`Object[@name='PrepLines']`, `Splines`, `Object[@type='TSysSpline']`);
        const drillDirectionFeature = this.getDrillDirectionFeature(modelJobId);

        if (!unn) {
            return xpath.select1(splinesQuery, drillDirectionFeature) as Node;
        }

        const splineNodes = xpath.select(splinesQuery, drillDirectionFeature) as Node[];
        for (const splineNode of splineNodes) {
            if (ParseModellingTreeUtils.parseSplineToothNumber(splineNode) === unn) {
                return splineNode;
            }
        }

        throw new Error(`Failed to find spline for tooth ${unn}.`);
    }

    getInterfacesPrepLine(modelJobId: string, modelElementId: string, toothElementId: string): Node {
        return xpath.select1(
            createXPathQuery(
                `Feature[@name='Define interfaces container']`,
                `ChildFeatures`,
                `Feature[@name='ModelJobList']`,
                `ChildFeatures`,
                `Feature[@name='ModelJob container ${modelJobId}']`,
                `ChildFeatures`,
                `Feature[@name='ModelElementList']`,
                `ChildFeatures`,
                `Feature[@name='ModelElement container ${modelElementId}']`,
                `ChildFeatures`,
                `Feature[@name='ToothElementList']`,
                `ChildFeatures`,
                `Feature[@name='ToothElement container ${toothElementId}']`,
                `ChildFeatures`,
                `Feature[@name='SCopy ${toothElementId}']`,
                `ChildFeatures`,
                `Feature[@type='OPreparationLine']`,
                `Object[@name='PrepLine']`,
                `Splines`,
                `Object[@type='TSysSpline']`,
            ),
            this.features,
        ) as Node;
    }

    private getDrillDirectionFeature(modelJobId: string): Node {
        return xpath.select1(
            createXPathQuery(
                `Feature[@name='Direction container']`,
                `ChildFeatures`,
                `Feature[@name='ModelJobList']`,
                `ChildFeatures`,
                `Feature[@name='ModelJob container ${modelJobId}']`,
                `ChildFeatures`,
                `Feature[@name='Drill direction ${modelJobId}']`,
            ),
            this.features,
        ) as Node;
    }
}

function createXPathQuery(...parts: string[]): string {
    return parts.join('/');
}
