import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import type { CrossSectionData, CrossSectionPlane } from './CrossSectionData';
import React from 'react';
import * as THREE from 'three';

export type ToggleWithForceFn = (usedKeyboardShortcut: boolean, forceState?: boolean) => void;
type StateWithToggleFn = { toggle: ToggleWithForceFn; open: boolean };
type CameraViewSnapshot = { position: THREE.Vector3; orientation: THREE.Quaternion; up: THREE.Vector3 };

// copied from NewModelViewerOverlay
// need to reimplement tracking context
function useAppStateToolToggle(currentState: boolean, setState: (val: boolean) => void): ToggleWithForceFn {
    // If forceState is provided, we will use that value instead of toggling
    return React.useCallback(
        (_usedKeyboardShortcut: boolean, forceState?: boolean): void => {
            const newValue = forceState !== undefined ? forceState : !currentState;
            setState(newValue);
        },
        [setState, currentState],
    );
}

export interface CrossSectionAppState {
    appActive: StateWithToggleFn;
    clippingPlane: StateWithToggleFn;
    reversePlane: StateWithToggleFn;
}

export interface CrossSectionHooksApp {
    crossSectionData: CrossSectionData | undefined;
    updateCrossSectionData: React.Dispatch<React.SetStateAction<CrossSectionData | undefined>>;
    csPlane: CrossSectionPlane | undefined;
    setCSPlane: React.Dispatch<React.SetStateAction<CrossSectionPlane | undefined>>;
    clearCrossSectionPlane: () => void;
    crossSectionCameraView: CameraViewSnapshot | undefined;
    restoreCameraView: (view?: CameraViewSnapshot) => void;
    setCrossSectionCameraView: React.Dispatch<React.SetStateAction<CameraViewSnapshot | undefined>>;
    handleNewCrossSectionPlane: () => void;
    crossSectionPlaneActive: boolean;
    crossSectionMinorAxis: THREE.Vector3 | undefined;
}

export function useCrossSectionState(): CrossSectionAppState {
    // Cross section panel open
    const [isCrossSectionOpen, setIsCrossSectionOpen] = React.useState(false);
    const toggleCrossSection = useAppStateToolToggle(isCrossSectionOpen, setIsCrossSectionOpen);

    // Cross section, clipping plane
    const [isClippingPlaneOpen, setIsClippingPlaneOpen] = React.useState(false);
    const toggleClippingPlane = useAppStateToolToggle(isClippingPlaneOpen, setIsClippingPlaneOpen);

    // Cross section, reverse plane
    const [isReversePlaneOpen, setIsReversePlaneOpen] = React.useState(false);
    const toggleReversePlane = useAppStateToolToggle(isReversePlaneOpen, setIsReversePlaneOpen);

    return {
        clippingPlane: {
            open: isClippingPlaneOpen,
            toggle: toggleClippingPlane,
        },
        reversePlane: {
            open: isReversePlaneOpen,
            toggle: toggleReversePlane,
        },
        appActive: {
            open: isCrossSectionOpen,
            toggle: toggleCrossSection,
        },
    };
}
export function useCrossSectionApp(
    controlRef: MainViewCameraControlsRef,
    crossSectionState: CrossSectionAppState,
): CrossSectionHooksApp {
    const [crossSectionData, updateCrossSectionData] = React.useState<CrossSectionData>();
    const [csPlane, setCSPlane] = React.useState<CrossSectionPlane>();
    const [crossSectionCameraView, setCrossSectionCameraView] = React.useState<CameraViewSnapshot>();

    const clearCrossSectionPlane = React.useCallback(() => {
        setCSPlane(undefined);
        setCrossSectionCameraView(undefined);
    }, [setCSPlane, setCrossSectionCameraView]);

    React.useEffect(() => {
        if (!crossSectionState.appActive.open) {
            clearCrossSectionPlane();
        }
    }, [crossSectionState.appActive.open, clearCrossSectionPlane]);

    const handleNewCrossSectionPlane = React.useCallback(() => {
        const camera = controlRef.current?.object;
        setCrossSectionCameraView(
            camera
                ? {
                      position: camera.position.clone(),
                      orientation: camera.quaternion.clone(),
                      up: camera.up.clone(),
                  }
                : undefined,
        );
    }, [controlRef, setCrossSectionCameraView]);

    const restoreCameraView = (view?: CameraViewSnapshot) => {
        const camera = controlRef.current?.object;
        if (view && camera) {
            camera.up.copy(view.up);
            camera.quaternion.copy(view.orientation);
            camera.position.copy(view.position);
        }
    };

    const crossSectionPlaneActive = csPlane !== undefined;
    const crossSectionMinorAxis = crossSectionCameraView?.orientation
        ? new THREE.Vector3(0, 0, -1).applyQuaternion(crossSectionCameraView?.orientation)
        : undefined;

    return {
        crossSectionData,
        updateCrossSectionData,
        csPlane,
        setCSPlane,
        clearCrossSectionPlane,
        crossSectionCameraView,
        restoreCameraView,
        setCrossSectionCameraView,
        handleNewCrossSectionPlane,
        crossSectionPlaneActive,
        crossSectionMinorAxis,
    };
}
