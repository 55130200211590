import { MESH_DEFAULT_ROTATION, MESH_DEFAULT_POSITION } from './ModelMeshes';
import { findClosestPoint, getMarginPoints } from '@orthly/forceps';
import type { MarginLine } from '@orthly/shared-types';
import { FlossPalette } from '@orthly/ui-primitives';
import * as THREE from 'three';
import type { MeshBVH, HitPointInfo } from 'three-mesh-bvh';

export const MarginEditingPalette = {
    CONTROL_POINT_COLOR: '#0062F4',
    CONTROL_POINT_COLOR_ACTIVE: FlossPalette.REVIEW_RED,
    EDIT_LINE_COLOR: '#0062F4',
    PREVIEW_LINE_COLOR: '#47f7ff',
    CURSOR_CIRCLE_COLOR: '#0062F4',
};

export class IndexedSphere extends THREE.Mesh {
    index: number = -1;
}

export function efficientDownsample<T>(sourceArray: T[], sampleSize: number): T[] {
    const k = Math.floor(sourceArray.length / sampleSize);
    // just return the values if we want all of them
    if (k <= 1) {
        return [...sourceArray];
    }
    const newArray: T[] = [];
    for (let i = 0; i < sourceArray.length; i = i + k) {
        const val = sourceArray[i];
        if (val) {
            newArray.push(val);
        }
    }
    return newArray;
}

/*
 * For retrieving controlPoints, we take naive
 * even sample of 20
 * TODO: p1 we will need an epsilon and smoothness based fitting
 * */
export function getMarginControlPoints(mline: MarginLine, downsample: boolean = true): THREE.Vector3[] {
    const coords = getMarginPoints(mline);
    return downsample ? efficientDownsample(coords, 20) : coords;
}

const reUsableCatmulCurveClosed = new THREE.CatmullRomCurve3(
    [new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, 1, 1)],
    true,
);
const reUsableCatmulCurve = new THREE.CatmullRomCurve3([new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, 1, 1)]);

export function getMarginTessellatedPointsNew(
    coords: THREE.Vector3[],
    lineClosed: boolean,
    index: MeshBVH | undefined = undefined,
    tess: number | undefined = 200,
): { points: THREE.Vector3[]; normals: THREE.Vector3[]; binormals: THREE.Vector3[] } {
    if (coords.length < 2) {
        return { points: [], normals: [], binormals: [] };
    }

    const start = coords[0];

    if (!start) {
        return { points: [], normals: [], binormals: [] };
    }

    const points = [...coords];

    const curve = lineClosed ? reUsableCatmulCurveClosed : reUsableCatmulCurve;

    curve.points = points;

    const pathLength = curve.getLength();
    const nPoints = Math.max(Math.floor(pathLength / 0.1), tess);
    const tessPoints = curve.getSpacedPoints(nPoints);

    // @ts-ignore
    const frames = curve.computeFrenetFrames(nPoints, lineClosed);

    if (!index) {
        return { points: tessPoints, normals: frames.normals, binormals: frames.binormals };
    }
    // If we have an index, snap the tessellation to the the mesh
    const mappedTesPoints = tessPoints.map(pt => {
        const target = {} as HitPointInfo;
        index.closestPointToPoint(pt, target, 0, 3);
        return target.point ? target.point.clone() : pt.clone();
    });
    return { points: mappedTesPoints, normals: frames.normals, binormals: frames.binormals };
}

export function getMarginTessellatedPoints(
    coords: THREE.Vector3[],
    lineClosed: boolean,
    index: MeshBVH | undefined = undefined,
    tess: number | undefined = 200,
): { points: THREE.Vector3[]; normals: THREE.Vector3[]; binormals: THREE.Vector3[] } {
    if (coords.length < 2) {
        return { points: [], normals: [], binormals: [] };
    }

    const start = coords[0];

    if (!start) {
        return { points: [], normals: [], binormals: [] };
    }

    const points = [...coords];
    lineClosed && points.push(new THREE.Vector3(start.x, start.y, start.z));

    reUsableCatmulCurve.points = points;
    const tessPoints = reUsableCatmulCurve.getPoints(tess);

    // @ts-ignore
    const frames = reUsableCatmulCurve.computeFrenetFrames(tess, lineClosed);

    if (!index) {
        return { points: tessPoints, normals: frames.normals, binormals: frames.binormals };
    }
    // If we have an index, snap the tessellation to the the mesh
    const mappedTesPoints = tessPoints.map(pt => {
        const target = {} as HitPointInfo;
        index.closestPointToPoint(pt, target, 0, 3);
        return target.point ? target.point.clone() : pt.clone();
    });
    return { points: mappedTesPoints, normals: frames.normals, binormals: frames.binormals };
}

export function drawLine(
    points: THREE.Vector3[],
    shaderMaterial: THREE.ShaderMaterial,
): THREE.Line<THREE.BufferGeometry, THREE.Material> {
    const lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setFromPoints(points);

    const line = new THREE.Line(lineGeometry, shaderMaterial);

    line.position.copy(MESH_DEFAULT_POSITION);
    line.rotation.copy(MESH_DEFAULT_ROTATION);
    return line;
}

export function drawLineTube(
    points: THREE.Vector3[],
    material: THREE.Material,
    lineClosed: boolean,
): THREE.Mesh<THREE.BufferGeometry, THREE.Material> {
    const curve = new THREE.CatmullRomCurve3(points, lineClosed, 'centripetal', 1.0);
    const extruded = new THREE.TubeBufferGeometry(curve, points.length, 0.02, 5, lineClosed);
    const line = new THREE.Mesh(extruded, material);
    line.position.copy(MESH_DEFAULT_POSITION);
    line.rotation.copy(MESH_DEFAULT_ROTATION);
    return line;
}

export type LineType = THREE.Mesh<THREE.BufferGeometry, THREE.Material> | THREE.Line;

interface CircleProps {
    pos?: THREE.Vector3;
    rot?: THREE.Euler;
    effectDistance?: number;
}

export function updateCircle(circle: THREE.Line, circleProps: CircleProps) {
    const { pos, rot, effectDistance } = circleProps;
    if (!!pos) {
        circle.position.copy(pos);
        circle.updateMatrix();
    }
    if (!!rot) {
        circle.rotation.copy(rot);
        circle.updateMatrix();
    }
    if (!!effectDistance) {
        circle.scale.set(effectDistance, effectDistance, effectDistance);
        circle.updateMatrix();
    }
}

export function createPreviewLine(): LineType {
    const points = [];
    points.push(new THREE.Vector3(-10, 0, 0));
    points.push(new THREE.Vector3(0, 10, 0));

    const lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setFromPoints(points);

    const material = new THREE.LineDashedMaterial({
        color: new THREE.Color(MarginEditingPalette.PREVIEW_LINE_COLOR),
        depthTest: false,
        dashSize: 0.3,
        gapSize: 0.15,
    });
    const line = new THREE.Line(lineGeometry, material);
    line.computeLineDistances();
    line.renderOrder = 1000;
    line.visible = false;
    line.matrixAutoUpdate = false;
    return line;
}

export function updateLine(line: LineType, newVerts: THREE.Vector3[], computeDistances: boolean = false) {
    line.geometry.setFromPoints(newVerts);
    // Typescript cant figure out a union THREE.Geometry and THREE.BufferGeometry
    const geometry = line.geometry as THREE.BufferGeometry;
    if (geometry.attributes?.position) {
        geometry.attributes.position.needsUpdate = true;
    }
    if (line instanceof THREE.Line) {
        computeDistances && line.computeLineDistances();
    }
}

// reuses a buffer geometry and material
export function newSharedInstance(
    geometry: THREE.SphereBufferGeometry,
    material: THREE.Material,
    index: number,
): IndexedSphere {
    const sphere = new IndexedSphere(geometry, material);
    sphere.index = index;
    return sphere;
}

export function interimCleanupMeshesAndObjs(group: THREE.Group, spheres: IndexedSphere[], lines: LineType[]): void {
    lines.forEach(line => {
        line.geometry.dispose();
        group?.remove(line);
    });

    // we don't dispose of the geometry as we will use it next mount
    spheres.forEach(sphere => {
        group.remove(sphere);
    });
}

export function findRightIndexForANewPoint(
    control_points: THREE.Vector3[],
    mouseHoverOverLineLocation: THREE.Vector3,
): number {
    const { closestIndex } = findClosestPoint(control_points, mouseHoverOverLineLocation);

    // now should we put the new point after the shortest index or before it
    const prePoint = control_points[closestIndex > 0 ? closestIndex - 1 : control_points.length - 1];
    const p = control_points[closestIndex];
    const nextPoint = control_points[closestIndex < control_points.length - 1 ? closestIndex + 1 : 0];
    if (p && prePoint && nextPoint) {
        const d1 =
            Math.abs(prePoint.distanceTo(mouseHoverOverLineLocation) + p.distanceTo(mouseHoverOverLineLocation)) -
            p.distanceTo(prePoint);
        const d2 =
            Math.abs(nextPoint.distanceTo(mouseHoverOverLineLocation) + p.distanceTo(mouseHoverOverLineLocation)) -
            p.distanceTo(nextPoint);
        if (d1 < d2) {
            // insert before
            return closestIndex;
        }
        // insert after
        return closestIndex < control_points.length - 1 ? closestIndex + 1 : 0;
    }
    return closestIndex;
}

const vShader = `
varying vec4 vcolor;
uniform vec3 stroke;

void main() {
    vcolor = vec4(stroke, 1.0);

    vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
    gl_Position = projectionMatrix * mvPosition;

    // move the vertex fragment a negligible amount toward
    // the camera to reduce z-fighting.
    // at this stage vertex fragment is already projected into
    // Normalized Device Coordinates.
    gl_Position.z = gl_Position.z - 0.0002;
}
`;

const fShader = `
varying vec4 vcolor;

void main() {
    gl_FragColor = vcolor;
}
`;

interface LineShaderMaterialProps {
    color?: THREE.Color;
    width?: number;
    xray?: boolean;
    opacity?: number;
}

export function lineShaderMaterial(props: LineShaderMaterialProps) {
    const { color, width, xray, opacity } = props;
    return new THREE.ShaderMaterial({
        vertexShader: vShader,
        fragmentShader: fShader,
        vertexColors: false,
        transparent: true,
        opacity: opacity ?? 1.0,
        depthTest: !xray,
        depthWrite: true,
        linewidth: width ?? 0.8,
        uniforms: {
            stroke: {
                value: color ?? new THREE.Color(0xff0000),
            },
        },
    });
}

/*
 * Simple comparison of two poly lines with early
 *
 * */
export function marginsEqual(margin1: THREE.Vector3[], margin2: THREE.Vector3[], epsilon: number = 0.0001): boolean {
    if (margin1.length !== margin2.length) {
        return false;
    }
    const arrayLength = margin1.length;
    let i = 0;
    while (i < arrayLength) {
        const m1 = margin1[i];
        const m2 = margin2[i];
        if (m1 === undefined || m2 === undefined) {
            return false;
        }
        if (m1.distanceTo(m2) > epsilon) {
            return false;
        }
        i++;
    }
    return true;
}

export function updateIndexedSpheres(
    spheres: IndexedSphere[],
    control_points: THREE.Vector3[],
    sharedGeoemtry: THREE.SphereBufferGeometry,
    sharedMaterial: THREE.Material,
    group: THREE.Group,
    visible: boolean = true,
) {
    if (spheres.length < control_points.length) {
        for (let i = spheres.length; i < control_points.length; i++) {
            const newSphere = newSharedInstance(sharedGeoemtry, sharedMaterial, i);
            group.add(newSphere);
            spheres.push(newSphere);
        }
    }

    if (spheres.length > control_points.length) {
        for (let i = control_points.length; i < spheres.length; i++) {
            // no need to dispose of geom as we share one buffer geom
            // just remove it from the group
            const extraSphere = spheres[i];
            extraSphere && group.remove(extraSphere);
        }
        spheres.splice(control_points.length, spheres.length - control_points.length);
    }

    control_points.forEach((cp, index) => {
        const existingSphere = spheres[index];
        if (existingSphere) {
            existingSphere.position.copy(cp);
            existingSphere.index = index;
            existingSphere.visible = visible;
        }
    });
}
