import { useSessionMonitor } from '../utils/useSessionMonitor';
import { RetainerSessionManager } from './RetainerSessionManager';
import { useRetainerToken } from './RetainerTokenProvider';
import type { StaffMemberSession } from './StaffMemberSession.types';
import React from 'react';

function useStaffMemberSessionFromToken() {
    const { retainerToken, deleteRetainerToken } = useRetainerToken();
    const [session, setSession] = React.useState<StaffMemberSession | null>(() =>
        RetainerSessionManager.sessionFromToken(retainerToken),
    );
    React.useEffect(() => {
        const sessionFromToken = RetainerSessionManager.sessionFromToken(retainerToken);
        setSession(sessionFromToken);
        // This means we were unable to parse the token, so we should delete it
        if (retainerToken && !sessionFromToken) {
            deleteRetainerToken();
        }
    }, [retainerToken, deleteRetainerToken]);
    useSessionMonitor(session);
    return session;
}

export const StaffMemberSessionContext = React.createContext<StaffMemberSession | undefined>(undefined);

export const StaffMemberSessionProvider: React.FC<{ children: React.ReactNode }> = props => {
    const session = useStaffMemberSessionFromToken();
    return (
        <StaffMemberSessionContext.Provider value={session ?? undefined}>
            {props.children}
        </StaffMemberSessionContext.Provider>
    );
};

export function useSession() {
    return React.useContext(StaffMemberSessionContext);
}
