import { PracticeUtils } from './utils';
import React from 'react';

/**
 * Utility hook that returns an instance of Stripe.js
 */
export function useStripe() {
    const GlobalStripe: typeof window.Stripe = window.Stripe;
    const stripe = React.useMemo(() => GlobalStripe(PracticeUtils.getStripeKey()), [GlobalStripe]);
    return stripe;
}
