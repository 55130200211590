/**
 * Utilities for parsing and writing PTS files. PTS files are written by 3Shape to store the margin lines of teeth.
 */
import { logger } from '../Utils/Logger';
import { ToothUtils } from '@orthly/items';
import type { ToothNumber } from '@orthly/items';
import * as THREE from 'three';

export type PointsMap = Map<ToothNumber, THREE.Vector3[]>;

/**
 * Parses PTS file into a map of tooth number to points
 * @param ptsFile The contents of the PTS file
 * @returns A map of tooth number to points, if parsing was successful
 */
export function parsePts(ptsFile: Buffer): PointsMap | undefined {
    const lines = ptsFile.toString().split('\n');

    const pointsMap: PointsMap = new Map();
    let points: THREE.Vector3[] | undefined = undefined;
    let toothNum: ToothNumber | undefined = undefined;

    try {
        for (const line of lines) {
            if (!line.trim()) {
                continue;
            }

            const beginToothNum = parseBeginLine(line);
            if (beginToothNum) {
                toothNum = beginToothNum;
                points = [];
                continue;
            }

            if (parseEndLine(line, toothNum)) {
                pointsMap.set(toothNum as ToothNumber, points ?? []);
                points = undefined;
                toothNum = undefined;
                continue;
            }

            if (!points) {
                throw new Error('Got points line before BEGIN');
            }

            points.push(parsePointLine(line));
        }
    } catch (e) {
        logger.warn(`Got error while parsing PTS file: ${e}`);
        return undefined;
    }

    return pointsMap;
}

function parseBeginLine(line: string): ToothNumber | undefined {
    const matchBegin = line.match(/^BEGIN_(\d+)/);
    if (!matchBegin) {
        return undefined;
    }

    const maybeToothNumber: number = parseInt(matchBegin?.[1] ?? '');

    if (!ToothUtils.isToothNumber(maybeToothNumber)) {
        throw new Error(`Got invalid tooth number ${maybeToothNumber}`);
    }

    return maybeToothNumber;
}

function parseEndLine(line: string, expectedToothNum: ToothNumber | undefined): boolean {
    const matchBegin = line.match(/^END_(\d+)/);
    if (!matchBegin) {
        return false;
    }

    const maybeToothNumber: number = parseInt(matchBegin?.[1] ?? '');

    if (!ToothUtils.isToothNumber(maybeToothNumber)) {
        throw new Error(`Got invalid tooth number ${maybeToothNumber}`);
    }

    if (expectedToothNum !== maybeToothNumber) {
        throw new Error(`Got unmatched tooth number ${maybeToothNumber}, expected ${expectedToothNum}`);
    }

    return true;
}

function parsePointLine(line: string): THREE.Vector3 {
    const match = line.match(/^(-?\d+\.\d+) (-?\d+\.\d+) (-?\d+\.\d+)/);
    if (!match) {
        throw new Error(`Failed to parse line "${line}"`);
    }

    const x = parseFloat(match[1] ?? '');
    const y = parseFloat(match[2] ?? '');
    const z = parseFloat(match[3] ?? '');
    if (Number.isNaN(x) || Number.isNaN(y) || Number.isNaN(z)) {
        throw new Error(`Failed to parse line "${line}"`);
    }

    return new THREE.Vector3(x, y, z);
}

/**
 * Writes the text contents of a PTS file
 * @param pointsMap A map of tooth number to points
 * @returns A string that can be written to disk as a PTS file
 */
export function writePtsFileText(pointsMap: PointsMap): string {
    const ptsLines: string[] = [];
    for (const [toothNum, points] of pointsMap.entries()) {
        ptsLines.push(`BEGIN_${toothNum}`);
        for (const point of points) {
            ptsLines.push(`${point.x.toFixed(6)} ${point.y.toFixed(6)} ${point.z.toFixed(6)}`);
        }
        ptsLines.push(`END_${toothNum}`);
    }

    // This ensures that the final line of the file has a newline.
    ptsLines.push('');

    // The PTS files produced by 3Shape have CRLF line endings, so we use CRLF here.
    return ptsLines.join('\r\n');
}
