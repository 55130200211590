export type Point3DArray = [number, number, number];

type Properties = { [key: string]: string };

// All the model element types. This list is exhaustive.
const ModelElementTypes = [
    'meIndicationRegular',
    'meDigitalModelDie',
    'meDigitalModelPrepSectioned',
    'meDigitalModelPrepUnsectioned',
    'meDigitalModelAntagonist',
    'meDigitalModelNeighbor',
    'meDigitalModelTissue',
    'meImplantPlanning',
    'meSurgicalGuide',
    'meTransferGuide',
    'meSplint',
] as const;

type ModelElementType = (typeof ModelElementTypes)[number];

// All the ToothElement types that we care about
const CacheToothClassTypes = [
    'teCrown',
    'teVeneer',
    'teInlay',
    'tePontic',
    'teCrownPontic',
    'teTemporaryCrownPontic',
    'teTemporaryVPrepCrown',
    'teTemporaryVPrepCrownPontic',
    'teCoping',
    'tePostAndCoreStandard',
    'teAbutment',
    'teAbutmentAnatomical',
    'teTransferGuide',
    'teAbutmentScrewRetainedCrown',
    'teArtificialTooth',
    'teGingivaFD',
    'teGingiva',
    'teCopyDentureTray',
    'teSplint',
    'teRPD',
];
type CacheToothClassType = (typeof CacheToothClassTypes)[number];

export type LegacyNewModelElement = {
    insertionAxis?: Point3DArray;
    itemsDesc: string;
    modelElementID: string;
    modelFilePath: string;
    modelType: ModelElementType;
    properties: Properties | {};
};

export interface LegacyToothElement {
    toothElementID: string;
    modelElementID: string;
    toothNumber: number;
    cacheToothTypeClass: CacheToothClassType;
    properties: Properties;
}

export interface LegacyModelInfoElement {
    modelElementID: string;
    toothNumber: number;
    digitalModelElementInfoID: string;
}

export type LegacyCaseFileParsedPayload = {
    modelElements: LegacyNewModelElement[];
    toothElements: LegacyToothElement[];
    linkElements?: LinkElement[];
    linkToothElements?: LinkToothElement[];
    modelInfoElements?: LegacyModelInfoElement[];
};

// A type-guard to improve safety of the caseFileParsed coming from RootCanal
// This is mostly retroactive as we are supplanting this with the database
// object in the future. object is CaseFileParsedPayload
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function instanceOfCaseFileParsedPayload(object: any): object is LegacyCaseFileParsedPayload {
    if (object.toothElements === undefined || object.modelElements === undefined) {
        return false;
    }

    if (!Array.isArray(object.toothElements) || !Array.isArray(object.modelElements)) {
        return false;
    }

    const teethValid = object.toothElements.every((te: any) => {
        if (typeof te.toothElementID !== 'string') {
            return false;
        }
        if (typeof te.modelElementID !== 'string') {
            return false;
        }
        if (typeof te.toothNumber !== 'number') {
            return false;
        }
        return true;
    });

    if (!teethValid) {
        return false;
    }

    const modelsValid = object.modelElements.every((me: any) => {
        // Quick and dirty check for what we need
        if (typeof me.modelElementID !== 'string') {
            return false;
        }
        if (typeof me.modelFilePath !== 'string') {
            return false;
        }
        if (typeof me.modelType !== 'string') {
            return false;
        }
        if (me.insertionAxis !== undefined) {
            if (typeof me.insertionAxis[0] !== 'number') {
                return false;
            }
            if (typeof me.insertionAxis[1] !== 'number') {
                return false;
            }
            if (typeof me.insertionAxis[2] !== 'number') {
                return false;
            }
        }
        return true;
    });

    return modelsValid;
}

// occasionally we have some challenging file names written into metadata
// we use these strings to match CAD assets to their role and context
// in the 3Shape design file via the caseFileParsed
// File names with multiple periods, with single of double back or forwardslash file seps

export function deSuffixFilename(fileName: string): string {
    return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
}

export function cleanXmlNameForComparison(fileName: string): string {
    // Take care of backslashes, and double slashes
    const cleanedSlashes = fileName.replaceAll('\\', '/').replaceAll('//', '/');
    const folderParts = cleanedSlashes.split('/');
    if (folderParts.length < 3) {
        return deSuffixFilename(cleanedSlashes);
    }
    const leadingSlashesRemoved = folderParts.slice(-2).join('/');
    return deSuffixFilename(leadingSlashesRemoved);
}

/**
 * ToothElementList
 *   - TDM_Item_ToothElement
 * These represent every item that maps to a tooth unit
 *
 * Each unit in a bridge will have a ToothElement
 * with property modelElementID mapping to the CAD ModelElement
 *
 * Each Abutment,  Implant Crown and PostAndCore will also have a ToothElement
 *
 * Properties
 *   -Anatomical: boolean  if a coping, it means it's a cutback coping vs a thimbly coping, otherwise its False
 *   -PostAndCore:  boolean  whether or not it's a post and core.  Redundant with and sometimes in conflict with
 *   -CacheToothTypeClass:
 */

/**
 * LinkList
 *   - TDM_Item_Link
 * These items specify a connection (relationship) or physical
 * within a CAD object.  Most often the connectors in bridges
 * There wil be 2 LinkToothElements in the LinkToothElement list
 * which key to the linkID of an item in the LinkList
 */

interface LinkElement {
    linkID: number;
    modelElementID: string;
    cacheLinkTypeClass: string;
    linkTypeID: string;
}

/**
 * LinkToothElementList
 *   - TDM_Item_LinkToothElement
 * These items specify a relationshup connection between two tooth elements
 * Eg, in bridge 9x11 we would expect
 * but the relationship connection
 */

interface LinkToothElement {
    linkID: number;
    toothElementID: string;
    linkToothElementID: string;
}
