import type { QcHeatmapRange } from '../ColorRamp/ColorRamp.types';
import type { ModelPayloadItem } from '../ModelViewer/ModelViewerTypes';
import type { HeatMapType } from '@orthly/forceps';
import type React from 'react';
import type * as THREE from 'three';

/**
 * Appearance settings for a single model item.
 */
export type ItemAppearance = ItemAppearanceFlags & {
    opacity: number;
    customShader?: THREE.ShaderMaterialParameters;
    customShaderNoColor?: THREE.ShaderMaterialParameters;
    // NB: This parameter that controls the diffuse color used when not using vertex colors is only implemented for
    // scans that use the "prep" material.
    color?: THREE.Color;
};

/**
 * Boolean appearance settings for a single model item.
 */
export type ItemAppearanceFlags = {
    colorize: boolean;
    showInsertionAxis: boolean;
    showUndercutShadow: boolean;
    showUndercutCurtains: boolean;
    visible: boolean;
};

/**
 * Appearances for all models in the full scene.
 */
export type ModelAppearancePayloadAppearances = {
    upperJaw: PayloadModelAppearance[];
    lowerJaw: PayloadModelAppearance[];
    scans: PayloadModelAppearance[];
    printedModels: PayloadModelAppearance[];
    restoratives: RestorativeAppearance;
    pastRestoratives: RestorativeAppearance;
    preExtractionScans: PayloadModelAppearance[];
};

/**
 * Models and appearances for the full scene, plus tools state.
 */
export type ModelAppearance = {
    collisions: ModelPayloadItem[];
    anatomy: ModelPayloadItem[];
    curtains: Record<string, ModelPayloadItem>;
    solo?: ModelPayloadItem[];
    selectedForEdit?: ModelPayloadItem[];
} & ModelAppearancePayloadAppearances &
    QCSettings;

/**
 * A single model and its appearance settings.
 */
export type PayloadModelAppearance = {
    payloadModel: ModelPayloadItem;
    appearance: ItemAppearance;
};

/**
 * We have multiple views of restoratives, stored in separate models.  This enumerates them.
 */
export enum RestorativeView {
    CAD = 'CAD',
    HeatMap = 'HeatMap',
}

/**
 * Models and appearances for our multiple restorative views.
 */
export type RestorativeAppearance = {
    [key in RestorativeView]: PayloadModelAppearance[];
};
/**
 * Setting flags for appearance (separated from ModelAppearance for cleanliness)
 */
export type QCSettings = {
    showAnatomyLayers: boolean;
    showMarginLines: boolean;
    showDoctorMarginLines: boolean;
    showDoctorToothMarkings: boolean;
    showCollisions: boolean;
    showCurtainsCollisions: boolean;
    restorativeView: RestorativeView;
    activeHeatMap: HeatMapType;
    showCurtainsHeatmap: boolean;
    heatMapRange?: QcHeatmapRange;
    // Give current and past restoratives different colors
    colorPastRestoratives?: boolean;
    showPreviousMarginLines?: boolean;
    showPostEditCheckMarkers?: boolean;
    // The opacity of the porcelain layer of Anatomy elements
    porcelainOpacity?: number;
    applyTransparencyToAnatomyLayers?: boolean;
    applyTransparencyToRestoratives?: boolean;
};

export interface DisabledControls {
    colorize: boolean;
    insertionAxis: boolean;
    transparency: boolean;
    undercutArrowAndShadow: boolean;
    undercutCurtains: boolean;
    solo: boolean;
    selectedForEdit: boolean;
}

export function getAllDisabledControls(): DisabledControls {
    return {
        colorize: true,
        insertionAxis: true,
        transparency: true,
        undercutArrowAndShadow: true,
        undercutCurtains: true,
        solo: true,
        selectedForEdit: true,
    };
}

export type ScanPreviewAppearance = {
    visible: boolean;
    colorize: boolean;
};
/**
 * Model Appearance is often used with state, setState pattern
 * This alias helps keep fn defs from being excessively long
 */
export type SetAppearanceType = React.Dispatch<React.SetStateAction<ModelAppearance>>;
