import { StyledIcon, TrainingCard } from './TrainingDataIntake.styles';
import { TrainingDataIntakeDialog } from './TrainingDataIntakeDialog';
import {
    CreateTrainingIntakeSurveyProvider,
    useCreateTrainingIntakeSurveyResponse,
} from './hooks/useCreateTrainingIntakeSurveyResponse.graphql';
import { DEFAULT_TOAST_TIMEOUT, Toast } from '@orthly/dentin';
import { Button, CheckIcon, ClockAlertIcon, FlossPalette, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const AttentionCard = styled(TrainingCard)({
    background: FlossPalette.ATTENTION_BACKGROUND,
    border: `1px solid ${FlossPalette.ATTENTION_FOREGROUND}`,
});

const TrainingDataIntakeCardInner: React.VFC<{ refetchTrainingData: () => void }> = ({ refetchTrainingData }) => {
    const { successToastOpen, errorToastOpen, setOpen, setSuccessToastOpen, setErrorToastOpen } =
        useCreateTrainingIntakeSurveyResponse();

    return (
        <AttentionCard>
            <StyledIcon icon={'ClockAlertIcon'} />
            <Text variant={'body1'} medium>
                Your practice's training session
            </Text>
            <Text variant={'body2'} color={'GRAY'} style={{ marginTop: 8 }}>
                Complete this 2-minute training intake form for each person who plans on attending your practice's
                Launch Training. This will help us tailor the session based on your staff's scanning experience.
            </Text>
            <Button
                data-test={'training-data-intake-card-button'}
                variant={'secondary'}
                style={{ marginTop: 16 }}
                endIcon={'ChevronRight'}
                onClick={() => setOpen(true)}
                analytics={{
                    AssetLocation: 'Practice - Onboarding - Training Data Intake',
                    AssetName: 'Intake Form',
                }}
            >
                Let's tailor your training
            </Button>
            <TrainingDataIntakeDialog refetchTrainingData={refetchTrainingData} />
            <Toast
                headline={'Training form complete'}
                description={'We got your form submission. Thanks!'}
                open={successToastOpen}
                onDismiss={() => setSuccessToastOpen(false)}
                variant={'success'}
                icon={CheckIcon}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
            <Toast
                headline={'Something went wrong'}
                description={`We can't submit this right now. Please try again later.`}
                open={errorToastOpen}
                onDismiss={() => setErrorToastOpen(false)}
                variant={'warning'}
                icon={ClockAlertIcon}
                timeout={DEFAULT_TOAST_TIMEOUT}
            />
        </AttentionCard>
    );
};

export const TrainingDataIntakeCard: React.VFC<{ refetchTrainingData: () => void }> = ({ refetchTrainingData }) => {
    return (
        <CreateTrainingIntakeSurveyProvider>
            <TrainingDataIntakeCardInner refetchTrainingData={refetchTrainingData} />
        </CreateTrainingIntakeSurveyProvider>
    );
};
