import type { OrbitControlsProps } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';
import type { BufferGeometry, OrthographicCamera } from 'three';
import { Box3, Vector3 } from 'three';

type CrossSectionViewAutoZoomControlType = React.FC<{
    enabled: boolean;
    zoomMultiplier: number;
    targetGeometries: BufferGeometry[][];
    cameraControlsRef: React.MutableRefObject<OrbitControlsProps | null>;
}>;
export const CrossSectionViewAutoZoomControl: CrossSectionViewAutoZoomControlType = ({
    enabled,
    zoomMultiplier,
    targetGeometries,
    cameraControlsRef,
}) => {
    const { gl } = useThree();

    React.useEffect(() => {
        function fitCamera() {
            const camera = cameraControlsRef.current?.object as OrthographicCamera;
            if (!camera) {
                return;
            }

            const bbox = new Box3();

            return targetGeometries.some(geometries => {
                expandBBOX(bbox, geometries);

                // If cross section gets through restoratives,
                // fit camera to restoratives with some extra
                // space
                const size = bbox.getSize(new Vector3());
                const width = size.x;
                const height = size.y;
                const targetDim = Math.max(width, height);
                if (!isValidDim(targetDim)) {
                    return false;
                }

                const canvasHeight = gl.domElement.clientHeight || 500;
                const zoom = canvasHeight / (targetDim * zoomMultiplier);

                // always zoom in and zoom out
                const center = bbox.getCenter(new Vector3());
                center.z = camera.position.z;
                setCameraPosition(camera, center, zoom);
                setControlsPosition(cameraControlsRef.current, center);

                return true;
            });
        }

        if (enabled) {
            targetGeometries?.forEach(geometries =>
                geometries.forEach(g => {
                    g.addEventListener('update', fitCamera);
                }),
            );

            fitCamera();

            return () => {
                targetGeometries?.forEach(geometries =>
                    geometries.forEach(g => {
                        g.removeEventListener('update', fitCamera);
                    }),
                );
            };
        }
    }, [cameraControlsRef, enabled, targetGeometries, gl.domElement, zoomMultiplier]);

    return <></>;
};

function setCameraPosition(camera: OrthographicCamera, position: Vector3, zoom: number) {
    camera.zoom = zoom;
    camera.position.copy(position);
    camera.updateProjectionMatrix();
}

function setControlsPosition(controls: OrbitControlsProps | null, position: Vector3) {
    if (controls) {
        // Downcast target, drei overwrite target property type
        // as Vector3 | [x, y, z] to use with props
        const controlsTarget = controls.target as Vector3;
        controlsTarget?.set(position.x, position.y, controlsTarget.z);

        controls.update?.();
        controls.dispatchEvent?.({ type: 'change', target: null });
    }
}

function expandBBOX(bbox: Box3, geometries: BufferGeometry[] | undefined) {
    geometries?.forEach(g => {
        const positions = g.attributes.position;
        if (!positions) {
            return;
        }

        // g.drawRange.count might be Infinity !
        const length = Math.min(g.drawRange.count, positions.count);

        for (let i = 0; i < length; i++) {
            const x = positions.getX(i);
            const y = positions.getY(i);
            bbox.expandByPoint(new Vector3(x, y, 0.0));
        }
    });
}

function isValidDim(d: number) {
    return Number.isFinite(d) && d > 0.1;
}
