import type {
    FilesState,
    FileUploaderField,
    ProgressState,
    FileUploaderPreviewComponentProps,
} from './file-uploader-types';
import type { SimpleSelectProps } from '@orthly/ui';
import { SimpleSelect, OrthlyErrorBoundary } from '@orthly/ui';
import { createStyles, makeStyles, Grid, LinearProgress } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        progress: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
        selectWrap: {
            marginTop: 10,
            position: 'relative',
            overflow: 'hidden',
            padding: '5px 0',
        },
    }),
);

export const FileSelectLoader: React.FC<{ loading: boolean; progress: number }> = props => {
    const { loading, progress } = props;
    const classes = useStyles();
    return (
        <LinearProgress
            style={{ display: !loading ? 'none' : undefined }}
            value={progress}
            variant={'determinate'}
            className={classes.progress}
        />
    );
};

const FileSelectItem: React.FC<SimpleSelectProps & { loading: boolean; progress: number }> = props => {
    const { loading, progress, ...selectProps } = props;
    const classes = useStyles();
    return (
        <Grid container className={classes.selectWrap}>
            {props.children && <Grid container>{props.children}</Grid>}
            <Grid container>
                <FileSelectLoader loading={loading} progress={progress} />
                <SimpleSelect placeholder={`Clear Selection`} {...selectProps} />
            </Grid>
        </Grid>
    );
};

interface FileSelectListProps<K extends string = string> {
    loading: boolean;
    inputFiles: File[];
    fileFields: FileUploaderField<K>[];
    selectionState: FilesState<K>;
    progressState: ProgressState;
    handleChange: (field: K) => (value?: string) => void;
    disabled?: boolean;
    PreviewComponent?: React.FC<FileUploaderPreviewComponentProps>;
}

export function FileSelectList<K extends string = string>(props: FileSelectListProps<K>) {
    const { selectionState, inputFiles, fileFields, progressState, handleChange, loading, PreviewComponent } = props;
    const selectedFiles = Object.values(selectionState).filter(s => !!s);
    const options: SimpleSelectProps['options'] = inputFiles
        .filter(f => !selectedFiles.includes(f.name))
        .map(f => ({ value: f.name }));
    return (
        <Grid container style={{ paddingTop: 10, position: 'relative' }}>
            {fileFields.map(fileField => {
                const selected = selectionState[fileField.fieldKey];
                const progress = progressState[fileField.fieldKey] || 0;
                const fieldOptions = [...options];
                const selectedFile = selected ? inputFiles.find(f => f.name === selected) : undefined;
                selected &&
                    fieldOptions.push({
                        value: selected as string,
                        label: selectedFile?.name || selected.split('/').slice(-1)?.[0],
                    });
                const fieldName = fileField.displayName || fileField.fieldKey;
                const label = fieldOptions.length === 0 ? `Add ${fieldName}` : `Select ${fieldName}`;
                return (
                    <FileSelectItem
                        key={fileField.fieldKey}
                        progress={progress * 100}
                        loading={loading}
                        options={fieldOptions}
                        onChange={handleChange(fileField.fieldKey)}
                        label={label}
                        value={selected}
                        FormControlProps={{
                            disabled: fieldOptions.length === 0 || props.disabled,
                            variant: 'standard',
                        }}
                    >
                        {PreviewComponent && (
                            <OrthlyErrorBoundary componentName={'PreviewComponent'} FallbackComponent={() => null}>
                                <PreviewComponent field={fileField} selectedFile={selectedFile} />
                            </OrthlyErrorBoundary>
                        )}
                    </FileSelectItem>
                );
            })}
        </Grid>
    );
}
