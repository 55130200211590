import type { DandyNotification, NotificationOrderInboxItem, UrgentNotification } from '../DandyNotifications.types';
import { NotificationPeriod, NotificationPeriodNames } from '../DandyNotifications.types';
import type {
    LabsGqlNotificationOrderItemFragment,
    LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment,
    LabsGqlLabOrderFragment,
    LabsGqlResolveScanRejectionWorkflowTaskFragment,
    LabsGqlOrderPreviewFragment,
} from '@orthly/graphql-operations';
import { LabsGqlEventNames } from '@orthly/graphql-schema';
import _ from 'lodash';
import moment from 'moment/moment';

export const sortUrgentNotifications = (urgents: UrgentNotification[]) => {
    if (!urgents.length) {
        return [];
    }
    const sortedNotifications = _.sortBy(urgents, i => -new Date(i.eventDate).valueOf());
    const groupedNotifications = _.groupBy(sortedNotifications, notification => notification.period);

    return Object.entries(groupedNotifications).map(notification => {
        const periodKey = NotificationPeriod[+notification[0]] ?? '';
        const periodDisplayName = NotificationPeriodNames[periodKey as keyof typeof NotificationPeriodNames];
        return {
            periodKey,
            periodName: periodDisplayName,
            notifications: notification[1],
        };
    });
};

export const sortUpdateNotifications = (updates: DandyNotification[]) => {
    if (!updates.length) {
        return [];
    }
    const sortedNotifications = _.sortBy(updates, i => -new Date(i.eventDate).valueOf());
    const groupedNotifications = _.groupBy(sortedNotifications, notification => notification.eventDate);

    return Object.entries(groupedNotifications).map(notification => {
        const [date, notifications] = notification;
        return {
            date,
            notifications,
            dismissAll: notifications.some(notification => notification.dismiss),
        };
    });
};

export const getPeriod = (date: string) => {
    const today = moment();
    const diffInMs = today.diff(moment(date));
    const differenceInDays = moment.duration(diffInMs).asDays();

    if (differenceInDays <= 1) {
        return NotificationPeriod.TODAY;
    }
    if (differenceInDays <= 3) {
        return NotificationPeriod.TWO_DAYS_AGO;
    }
    if (differenceInDays <= 5) {
        return NotificationPeriod.FOUR_DAYS_AGO;
    }
    if (differenceInDays <= 7) {
        return NotificationPeriod.SIX_DAYS_AGO;
    }
    if (differenceInDays <= 8) {
        return NotificationPeriod.WEEK_AGO;
    }
    if (differenceInDays <= 15) {
        return NotificationPeriod.TWO_WEEKS_AGO;
    }
    if (differenceInDays <= 22) {
        return NotificationPeriod.THREE_WEEKS_AGO;
    }
    if (differenceInDays <= 29) {
        return NotificationPeriod.FOUR_WEEKS_AGO;
    }
    return NotificationPeriod.MORE_THAN_MONTH;
};

export function openCheckoutForScan(scanId: string) {
    const queryString = scanId ? `?scanId=${scanId}` : '';
    return `/lab/submit${queryString}`;
}

export const getTitleForInboxItem = (inboxItem: NotificationOrderInboxItem): string => {
    const { order } = inboxItem;
    const patient = order ? `${order.patient.first_name} ${order.patient.last_name}'s ` : '';

    const title = inboxItem.title;
    switch (inboxItem.event_name) {
        case LabsGqlEventNames.NoteAdded:
            return `${patient}'s order has a new note`;
        case LabsGqlEventNames.OrderHoldCreated:
            return patient ? `${patient}order placed on hold` : 'Order placed on hold';
        case LabsGqlEventNames.DesignFileConversionCompleted:
            return patient ? `${patient}design preview is ready for review` : title;
    }
    return title;
};

export const getSubtitleForInboxItem = (inboxItem: NotificationOrderInboxItem): string => {
    const subtitle = inboxItem.content || '';
    if (inboxItem.event_name === LabsGqlEventNames.DesignFileConversionCompleted) {
        const doctor_name = inboxItem.order.doctor_name ?? '';
        return `${doctor_name ? `By ${doctor_name} · ` : ''}${subtitle}`;
    }

    return subtitle;
};

export const extractDate = (date: string): string => {
    return date.substring(0, 10);
};

export const isActiveTaskScanRejectedAndVisibleToPractice = (
    activeTask:
        | LabsGqlNotificationOrderItemFragment['fulfillment_workflow']['active_task']
        | LabsGqlOrderPreviewFragment['fulfillment_workflow']['active_task'],
) => {
    return Boolean(
        activeTask?.__typename === 'ResolveScanRejectionWorkflowTask' &&
            !activeTask.configuration.practice_response &&
            activeTask.configuration.shared_with_practice,
    );
};

type OrderIsScanRejectedTaskVisibleToPractice<
    T extends LabsGqlLabOrderFragment | LabsGqlNotificationOrderItemFragment,
> = Omit<T, 'fulfillment_workflow'> & {
    fulfillment_workflow: Omit<T['fulfillment_workflow'], 'active_task'> & {
        active_task: Extract<
            T['fulfillment_workflow']['active_task'],
            {
                __typename: 'ResolveScanRejectionWorkflowTask';
            } & T extends LabsGqlLabOrderFragment
                ? LabsGqlResolveScanRejectionWorkflowTaskFragment
                : LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment
        >;
    };
};

export const isOrderScanRejectedAndVisibleToPractice = (
    order: Pick<LabsGqlOrderPreviewFragment, 'fulfillment_workflow'>,
): order is OrderIsScanRejectedTaskVisibleToPractice<LabsGqlLabOrderFragment> => {
    return isActiveTaskScanRejectedAndVisibleToPractice(order.fulfillment_workflow.active_task);
};

export const isNotificationOrderItemScanRejectedAndVisibleToPractice = (
    order: LabsGqlNotificationOrderItemFragment,
): order is OrderIsScanRejectedTaskVisibleToPractice<LabsGqlNotificationOrderItemFragment> => {
    return isActiveTaskScanRejectedAndVisibleToPractice(order.fulfillment_workflow.active_task);
};
