import type { ToothNumber } from '@orthly/items';

export enum ScanName {
    LowerJaw = 'lowerJaw',
    UpperJaw = 'upperJaw',
    PrePrepLowerJaw = 'prePrepLowerJaw',
    PrePrepUpperJaw = 'prePrepUpperJaw',
}

export type RestorativeName = `${ToothNumber}`;

export type GeometryName = ScanName | RestorativeName;

export type NamedGeometry<T extends string> = Omit<THREE.BufferGeometry, 'name' | 'clone'> & { name: T } & {
    clone(): NamedGeometry<T>;
};

export type NamedRestorativeGeometry = NamedGeometry<RestorativeName>;

export type NamedScanGeometry = NamedGeometry<ScanName>;
