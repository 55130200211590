import { getOcclusalArchFilter } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import type { LimitedAppearanceFilterBools, ModelAppearance } from '@orthly/dentin';
import { applyAppearanceFilter } from '@orthly/dentin';
import { ToothUtils } from '@orthly/items';
import { LowerJawIcon, UpperJawIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    Grid,
    Switch,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    archToggleContainer: {
        paddingLeft: 20,
        paddingTop: 15,
        flexDirection: 'row',
    },
    archToggleButton: {
        paddingTop: 10,
    },
    iconTextContainer: {
        padding: 5,
        alignItems: 'center',
    },
    jawIcon: {
        color: FlossPalette.PRIMARY_FOREGROUND,
        marginTop: 5,
    },
    activeToggle: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
    },
    inactiveToggle: {
        backgroundColor: FlossPalette.WHITE,
    },
}));

const JawIcon: React.VFC<{ isUpper: boolean }> = ({ isUpper }) => {
    const classes = useStyles();
    return isUpper ? <LowerJawIcon className={classes.jawIcon} /> : <UpperJawIcon className={classes.jawIcon} />;
};

export const OcclusalAnatomyControls: React.VFC<{
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
}> = ({ setAppearance }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { teeth } = useGuidedWaxupContext();
    const isUpper = teeth.every(tooth => ToothUtils.toothIsUpper(tooth));
    const occlusalAppearanceFilterValues = getOcclusalArchFilter(isUpper);
    const [appearanceFilterValues, setAppearanceFilterValues] =
        React.useState<LimitedAppearanceFilterBools>(occlusalAppearanceFilterValues);

    const checked = isUpper ? appearanceFilterValues.showLower : appearanceFilterValues.showUpper;

    const showLowerText = appearanceFilterValues.showLower ? 'on' : 'off';
    const showUpperText = appearanceFilterValues.showUpper ? 'on' : 'off';
    const toggleStateText = isUpper ? showLowerText : showUpperText;

    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const mobileButtonBackground = isUpper
        ? appearanceFilterValues.showLower
            ? FlossPalette.PRIMARY_BACKGROUND
            : FlossPalette.WHITE
        : appearanceFilterValues.showUpper
          ? FlossPalette.PRIMARY_BACKGROUND
          : FlossPalette.WHITE;

    const toggleOpposingArch = () => {
        setAppearanceFilterValues(currentAppearanceFilterValues => {
            setAppearance(currentApperance =>
                applyAppearanceFilter(currentApperance, {
                    ...appearanceFilterValues,
                    showUpper: isUpper ? appearanceFilterValues.showUpper : !appearanceFilterValues.showUpper,
                    showLower: isUpper ? !appearanceFilterValues.showLower : appearanceFilterValues.showLower,
                }),
            );
            return {
                ...currentAppearanceFilterValues,
                showUpper: isUpper ? currentAppearanceFilterValues.showUpper : !currentAppearanceFilterValues.showUpper,
                showLower: isUpper ? !currentAppearanceFilterValues.showLower : currentAppearanceFilterValues.showLower,
            };
        });
    };
    return (
        <Grid item container className={classes.archToggleContainer}>
            <Button
                variant={'secondary'}
                onClick={toggleOpposingArch}
                className={classes.archToggleButton}
                style={{ backgroundColor: isMobile ? mobileButtonBackground : undefined }}
            >
                <Grid container className={classes.iconTextContainer} direction={isMobile ? 'column' : 'row'}>
                    <JawIcon isUpper={isUpper} />
                    {!isMobile && <Switch color={'secondary'} checked={checked} />}
                    <Text
                        variant={isMobile ? 'caption' : 'body2'}
                        color={'PRIMARY_FOREGROUND'}
                        medium
                        style={{ marginBottom: isMobile ? 10 : 0 }}
                    >
                        Opposing arch {!isMobile && toggleStateText}
                    </Text>
                </Grid>
            </Button>
        </Grid>
    );
};
