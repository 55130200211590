import type { ButtonProps, IconButtonProps } from '@orthly/ui-primitives';
import { FlossPalette, Button, makeStyles, Tooltip, Icon, IconButton } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() => ({
    button: {
        border: 'none',
        height: 'unset',
        padding: 0,
        fontFamily: 'Inter Regular',
        fontSize: 12,
        '&&': {
            color: FlossPalette.GRAY,
        },
        '&& .MuiSvgIcon-root': {
            color: FlossPalette.GRAY,
            fontSize: 14,
        },
        '&:hover': {
            background: 'unset',
            '&&': {
                color: FlossPalette.DARK_GRAY,
            },
            '&& .MuiSvgIcon-root': {
                color: FlossPalette.DARK_GRAY,
            },
        },
    },
    primaryButton: {
        border: 'none',
        height: 'unset',
        padding: 0,
        fontFamily: 'Inter Regular',
        '&&': {
            color: FlossPalette.DARK_GRAY,
        },
        '&& .MuiSvgIcon-root': {
            color: FlossPalette.PRIMARY_FOREGROUND,
            fontSize: 20,
        },
        '&:hover': {
            background: 'unset',
            '&&': {
                color: FlossPalette.BLACK,
            },
            '&& .MuiSvgIcon-root': {
                color: FlossPalette.PRIMARY_FOREGROUND,
            },
        },
    },
}));

interface Props {
    onClick?: React.MouseEventHandler;
    size?: ButtonProps['size'];
    tooltip?: string;
    value: string;
    variant?: 'primary' | 'secondary' | 'ghost';
    isIconOnly?: boolean;
    isIconButton?: boolean;
    iconButtonSize?: IconButtonProps['size'];
}

export const SimpleCopyToClipboard: React.FC<Props> = ({
    children,
    onClick = () => {},
    size,
    tooltip = 'Copy',
    value,
    variant = 'primary',
    isIconOnly = false,
    isIconButton = false,
    iconButtonSize = 'small',
}) => {
    const classes = useStyles();
    const [displayedTooltip, setTooltip] = React.useState(tooltip);
    const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
    const tooltipTimeoutRef = React.useRef<ReturnType<typeof setTimeout>>();

    const openTimedTooltip = () => {
        setIsTooltipOpen(true);
        clearTimeout(tooltipTimeoutRef.current);
        tooltipTimeoutRef.current = setTimeout(() => setIsTooltipOpen(false), 3000);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        onClick(event);
        navigator.clipboard
            .writeText(value)
            .then(() => setTooltip('Copied'))
            .catch(() => setTooltip('Failed to copy'))
            .finally(() => openTimedTooltip());
    };

    return (
        <Tooltip
            arrow
            disableHoverListener
            enterTouchDelay={0}
            onMouseEnter={() => {
                setTooltip(tooltip);
                setIsTooltipOpen(true);
            }}
            onMouseLeave={() => {
                setIsTooltipOpen(false);
            }}
            open={isTooltipOpen}
            title={displayedTooltip}
        >
            <div>
                {isIconButton ? (
                    <IconButton size={iconButtonSize} onClick={handleClick}>
                        <Icon icon={'CopyFileIcon'} fontSize={size} />
                    </IconButton>
                ) : (
                    <Button
                        className={clsx({
                            [classes.primaryButton]: variant === 'primary' && !isIconOnly,
                            [classes.button]: variant !== 'primary' && !isIconOnly,
                        })}
                        endIcon={isIconOnly ? undefined : 'CopyFileIcon'}
                        size={size}
                        variant={isIconOnly ? variant : 'text'}
                        onClick={handleClick}
                    >
                        {isIconOnly ? <Icon icon={'CopyFileIcon'} /> : children ?? value}
                    </Button>
                )}
            </div>
        </Tooltip>
    );
};
