import { useDesignModelPayloadsForScan } from '../DesignViewer/OrderDesignPreview.hooks.graphql';
import { getScanPayloadItems } from '../DesignViewer/OrderDesignPreview.util';
import { useFeatureFlag } from '../LaunchDarkly';
import { OrderDetailBlock } from './OrderDetailBlock';
import { OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import type { MainViewCameraControlsRef, MainViewModelRef, ModelAppearance, ModelPayloadItem } from '@orthly/dentin';
import {
    MODEL_VIEWER_INITIAL_APPEARANCE,
    NewModelViewer,
    orderMaterialCanHaveLayers,
    useDoctorMargins,
} from '@orthly/dentin';
import type {
    FragmentType,
    VeneerOrderDetailScanExportModelViewerBlockScanExport_FragmentFragment,
} from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLabOrderFragment, LabsGqlScanFileForOrderFragment } from '@orthly/graphql-operations';
import { Format } from '@orthly/runtime-utils';
import type { SimpleSelectOption } from '@orthly/ui';
import { LoadBlocker, SimpleSelect } from '@orthly/ui';
import { Grid, useScreenIsMobile } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

const VeneerOrderDetailScanExportModelViewerBlockScanExport_Fragment = graphql(`
    fragment VeneerOrderDetailScanExportModelViewerBlockScanExport_Fragment on scan_export {
        id
        scanned_at
        oxz_margin_info {
            doctorMargins {
                unn
                scan_id
                coords
            }
            prepMarks {
                unn
                details
                sectioned
                text
                dcmName
                origin {
                    x
                    y
                    z
                }
            }
        }
    }
`);

interface OrderDetailScanExportModelViewerBlockProps {
    orderId: string;
    scanExport: VeneerOrderDetailScanExportModelViewerBlockScanExport_FragmentFragment;
    scanExportHistory: readonly VeneerOrderDetailScanExportModelViewerBlockScanExport_FragmentFragment[];
    orderMaterialCanHaveLayers: boolean;
}

const OrderDetailScanExportModelViewerBlock: React.VFC<OrderDetailScanExportModelViewerBlockProps> = ({
    orderId,
    scanExport,
    scanExportHistory,
    orderMaterialCanHaveLayers,
}) => {
    const { value: enableCustomLightingShaderMaterial } = useFeatureFlag('useCustomLightingShaderMaterial');
    const { value: enable3ShapeViewerControls } = useFeatureFlag('enable3ShapeViewerControls');
    const { value: enableMinimum3DSystemRequirements } = useFeatureFlag('enableMinimum3DSystemRequirements');
    const { value: enableTubeMarginLine } = useFeatureFlag('enableTubeMarginLine');

    const scanHistoryOptions = React.useMemo(
        () => _.uniqBy([...scanExportHistory, scanExport].reverse(), scan => scan.id),
        [scanExport, scanExportHistory],
    );
    const scanHistoryOptionsWithLabel = React.useMemo<SimpleSelectOption[]>(
        () =>
            scanHistoryOptions.map(({ id, scanned_at }, index) => {
                const datePart = scanned_at ? `, scanned at ${dayjs(scanned_at).format('MM/DD/YY hh:mm a')})` : '';

                return {
                    value: id,
                    label: `${_.startCase(Format.ordinal(scanHistoryOptions.length - index))} Scan (${
                        index ? 'outdated' : 'most recent'
                    } history${datePart}`,
                };
            }),
        [scanHistoryOptions],
    );
    const [scanHistoryValue, setScanHistoryValue] = React.useState<string | undefined>();
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);
    const [selectedScanId, setSelectedScanId] = React.useState<string | null>(null);
    const [activeScan, setActiveScan] = React.useState(scanExport);

    const { result: designPayloads, loading: designPayloadsLoading } = useDesignModelPayloadsForScan(selectedScanId);
    const model_payload_items = React.useMemo<ModelPayloadItem[]>(() => {
        return getScanPayloadItems(_.compact(designPayloads) ?? []);
    }, [designPayloads]);

    const onScanHistoryChange = React.useCallback(
        (value: string | undefined) => {
            setScanHistoryValue(value);
            const scan = scanHistoryOptions.find(scan => scan.id === value);
            if (scan) {
                setSelectedScanId(scan.id);
                setActiveScan(scan);
            }
        },
        [setScanHistoryValue, scanHistoryOptions],
    );

    const hasDoctorMargins =
        !!activeScan.oxz_margin_info?.doctorMargins && activeScan.oxz_margin_info?.doctorMargins.length > 0;

    const doctorMarginLines = useDoctorMargins(
        activeScan.oxz_margin_info ?? null,
        /*designMetadata*/ undefined,
        /*customMargins*/ undefined,
        /*transform*/ false,
        false,
    );

    const isMobile = useScreenIsMobile();

    return (
        <OrderDetailBlock title={'Order Scans'} variant={'full'}>
            <Grid container>
                <Grid container item alignItems={'center'} xs={12} md={6}>
                    <SimpleSelect
                        options={scanHistoryOptionsWithLabel}
                        label={'Scan History'}
                        value={scanHistoryValue}
                        placeholder={'Select scan'}
                        onChange={onScanHistoryChange}
                    />
                </Grid>
            </Grid>
            <LoadBlocker blocking={designPayloadsLoading} overlayColor={'transparent'}>
                {scanHistoryValue && (
                    <OrderAnalyticsContext.Provider value={{ orderId }}>
                        <NewModelViewer
                            orderMaterialsHaveLayers={orderMaterialCanHaveLayers}
                            full_screen={false}
                            model_payload_items={model_payload_items}
                            hideUi={isMobile}
                            enable_qc_tools={{
                                enableAnatomyLayers: false,
                                enableCollisions: false,
                                enableCrossSections: true,
                                enableDynamicHeatmaps: false,
                                enableHeatmaps: false,
                                enableUndercutHeatmap: false,
                                enableTissuePressureHeatmap: false,
                                enableMarginLines: hasDoctorMargins,
                                enableDoctorMarginLines: hasDoctorMargins,
                                enableDoctorToothMarkings: false,
                                editMarginLines: false,
                                enableUndercutView: false,
                            }}
                            doctorMarginLines={doctorMarginLines}
                            hide_toolbar
                            designQcConfig={{
                                appearance,
                                setAppearance,
                                modelRef,
                                controlRef,
                            }}
                            showOrderScans
                            enableNewScanMeshMaterial={enableCustomLightingShaderMaterial}
                            oxzMarginInfo={activeScan.oxz_margin_info ?? undefined}
                            use3ShapeViewerControls={enable3ShapeViewerControls}
                            checkMinimumSystemRequirements={enableMinimum3DSystemRequirements}
                            enableTubeMarginLine={enableTubeMarginLine}
                        />
                    </OrderAnalyticsContext.Provider>
                )}
            </LoadBlocker>
        </OrderDetailBlock>
    );
};

const VeneerOrderDetailScanExportModelViewerBlockConnectedLabSalesOrder_Fragment = graphql(`
    fragment VeneerOrderDetailScanExportModelViewerBlockConnectedLabSalesOrder_Fragment on LabSalesOrderDTO {
        id
        scan_export {
            ...VeneerOrderDetailScanExportModelViewerBlockScanExport_Fragment
        }
        scan_export_history {
            ...VeneerOrderDetailScanExportModelViewerBlockScanExport_Fragment
        }
        cad_info {
            has_layered_material
        }
    }
`);

interface OrderDetailScanExportModelViewerBlockConnectedProps {
    salesOrder: FragmentType<typeof VeneerOrderDetailScanExportModelViewerBlockConnectedLabSalesOrder_Fragment>;
}

export const OrderDetailScanExportModelViewerBlockConnected: React.VFC<
    OrderDetailScanExportModelViewerBlockConnectedProps
> = ({ salesOrder: salesOrderProp }) => {
    const salesOrder = getFragmentData(
        VeneerOrderDetailScanExportModelViewerBlockConnectedLabSalesOrder_Fragment,
        salesOrderProp,
    );

    return (
        <OrderDetailScanExportModelViewerBlock
            orderId={salesOrder.id}
            scanExport={getFragmentData(
                VeneerOrderDetailScanExportModelViewerBlockScanExport_Fragment,
                salesOrder.scan_export,
            )}
            scanExportHistory={getFragmentData(
                VeneerOrderDetailScanExportModelViewerBlockScanExport_Fragment,
                salesOrder.scan_export_history,
            )}
            orderMaterialCanHaveLayers={salesOrder.cad_info.has_layered_material}
        />
    );
};

interface ScanExportModelViewerBlockProps {
    order: Pick<LabsGqlLabOrderFragment, 'items_v2' | 'id'> & {
        scan_export: LabsGqlScanFileForOrderFragment;
        replaced_scan_exports: LabsGqlScanFileForOrderFragment[];
    };
}

/**
 * @deprecated Use `OrderDetailScanExportModelViewerBlockConnected` instead.
 *
 * TODO: remove this component after last usage is migrated
 */
export const ScanExportModelViewerBlock: React.VFC<ScanExportModelViewerBlockProps> = ({ order }) => {
    return (
        <OrderDetailScanExportModelViewerBlock
            orderId={order.id}
            scanExport={order.scan_export}
            scanExportHistory={order.replaced_scan_exports}
            orderMaterialCanHaveLayers={orderMaterialCanHaveLayers(order)}
        />
    );
};
