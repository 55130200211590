import type { OrderListItemFlagsProps, OrderListItemTitleFlag } from './OrderListItemTitleFlags';
import type { OrderOpsListItemContentProps } from './OrderListItemUtils.types';
import type { OrderListItemTrackerProps } from '@orthly/dentin';
import { LabsUtilsBase, OrderListItemTrackerUtils } from '@orthly/dentin';
import type { AdminUseCompletedInboxDesignListItemDesignData_QueryQuery } from '@orthly/graphql-inline-react';
import type {
    LabsGqlLabOrderFragment,
    LabsGqlOrderFlagsPreviewFragment,
    LabsGqlOrderTrackerPreviewFragment,
} from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus, LabsGqlRushRequestStatus } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { FlossPalette, stylesFactory, Text, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    itemsIndicator: {
        backgroundColor: FlossPalette.DARK_TAN,
        height: 16,
        width: 16,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 2,
        marginRight: 3,
        marginLeft: 6,
    },
    root: { padding: '0 32px', height: 96, backgroundColor: '#fff' },
    // position absolute so the mounted list container doesn't scroll
    noResultsRoot: { background: FlossPalette.TAN, position: 'absolute' },
    noResultsContent: { height: '100%', padding: '8px 16px 8px', alignItems: 'center', justifyContent: 'center' },
}));

export const PatientOrderCount: React.FC<{ patient_order_count: number }> = ({ patient_order_count }) => {
    const classes = useStyles();
    return (
        <Grid container className={classes.itemsIndicator}>
            <Text variant={'caption'} style={{ color: FlossPalette.GRAY, fontWeight: 500, lineHeight: 0 }}>
                {patient_order_count}
            </Text>
        </Grid>
    );
};

interface OrderListNoResultsItemProps {
    message: string;
    variant?: 'normal' | 'error';
    rootStyle?: React.CSSProperties;
}
export const OrderListNoResultsItem: React.FC<OrderListNoResultsItemProps> = ({ message, variant, rootStyle }) => {
    const classes = useStyles();
    return (
        <Grid container className={clsx(classes.root, classes.noResultsRoot)} style={rootStyle}>
            <Grid container className={classes.noResultsContent}>
                <Text variant={'body2'} color={variant === 'error' ? 'ATTENTION' : 'DARK_GRAY'} medium>
                    {message}
                </Text>
            </Grid>
        </Grid>
    );
};

// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getOrderListItemFlagProps(
    order: LabsGqlOrderFlagsPreviewFragment | undefined,
): OrderListItemFlagsProps {
    if (!order) {
        return { flags: [] };
    }
    const flags: OrderListItemTitleFlag[] = [];
    if (order.split_order_count && order.split_order_count > 1) {
        flags.push('Split');
    }
    if (order.original_order_id) {
        flags.push('Refab');
    }
    if (typeof order.partner_order_number === 'number' && order.partner_order_number <= 10) {
        flags.push('NewPractice');
    }

    const missingPrice = order.dentist_amount_due_cents === null;
    if (missingPrice) {
        flags.push('MissingPrice');
    }
    if (order.status === LabsGqlLabOrderStatus.NeedsReview && order.review_flag_reasons) {
        return { flags, review_flag_reasons: order.review_flag_reasons };
    }
    return { flags };
}

export function getOrderTrackerProps(
    order: LabsGqlOrderTrackerPreviewFragment,
): Omit<OrderListItemTrackerProps, 'isManufacturer'> {
    return {
        hasIssue: OrderListItemTrackerUtils.showOrderTrackerIssue(order),
        created_from_refab: !!order.original_order_id,
        due_date: order.manufacturer_sla.due_date,
        estimated_delivery_date: order.practice_dates.estimated_delivery_date,
        created_at: order.created_at,
        ship_date: order.ship_date,
        sla_ship_by_date: order.manufacturer_sla.ship_by_date,
        delivery_date: order.delivery_date,
        state: order.workflow_state.state,
        aligner_props: OrderListItemTrackerUtils.getAlignerProps(order) ?? undefined,
        dentures_props: OrderListItemTrackerUtils.getDenturesProps(order) ?? undefined,
        tracking_status: order.fulfillment?.current?.shipment?.tracking_status ?? '',
        tracking_substatus: order.fulfillment?.current?.shipment?.tracking_substatus ?? '',
        package_accepted_date: order.fulfillment?.current?.shipment?.package_accepted_date ?? '',
        rushAccepted: order.manufacturer_sla.rush_request?.status === LabsGqlRushRequestStatus.Accepted,
    };
}

export function useOrderToOpsListItemContent(
    labNamesById?: Record<string, string | null | undefined>,
    partnerNamesById?: Record<string, string | null | undefined>,
    designFragments?: AdminUseCompletedInboxDesignListItemDesignData_QueryQuery['getDesignOrderDesignRevisionsByTaskIds'],
) {
    return React.useCallback(
        (order: LabsGqlLabOrderFragment): OrderOpsListItemContentProps => {
            const { partner_id, manufacturer_id } = order;
            const manufacturer_name = !!labNamesById ? `${labNamesById[manufacturer_id] ?? 'Unknown lab'}` : undefined;

            return {
                order,
                manufacturer_name,
                ...getOrderListItemFlagProps(order),
                partner_name: partnerNamesById ? partnerNamesById[partner_id] ?? 'Loading partner...' : undefined,
                productsText: LabsUtilsBase.productsForOrderV2(OrderItemV2Utils.parseItems(order.items_v2)).join(', '),
                trackerProps: getOrderTrackerProps(order),
                orderDesignFragments: designFragments?.filter(fragment => fragment.design_order_id === order.id) ?? [],
            };
        },
        [labNamesById, partnerNamesById, designFragments],
    );
}
