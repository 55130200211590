import type { DisabledControls, ItemAppearance } from './ModelAppearanceTypes';
import { isTransparent, getToggledOpacity } from './Transparent.util';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import {
    ModelTextureIcon,
    RestorativesTransparentIcon,
    ToothInFrameIcon,
    VisibilityIcon,
    UndercutShadowIcon,
    CurtainsIcon,
    TargetToothIcon,
} from '@orthly/ui';
import type { SvgIconProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Icon,
    stylesFactory,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    createStyles,
    IconButton,
    Tooltip,
    Grid,
    Text,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const controlListItemTextStyle: React.CSSProperties = {
    whiteSpace: 'nowrap',
};

const useStyles = stylesFactory(() =>
    createStyles({
        iconActive: {
            color: FlossPalette.PRIMARY_FOREGROUND,
        },
        iconInactive: {
            color: FlossPalette.GRAY,
        },
        iconButton: {
            borderRadius: 28,
            padding: 1,
            marginRight: 2,
            '&.active': {
                backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            },
        },
        colorizeIconButton: {
            '& svg': {
                filter: 'saturate(0)',
                opacity: 0.5,
            },

            '&.active svg': {
                filter: 'none',
                opacity: 1,
            },
        },
        titleIcon: {
            height: 24,
            width: 24,
            color: FlossPalette.BLACK,
        },
        titleIconWrapper: {
            height: 24,
            width: 24,
            marginLeft: 4,
        },
    }),
);

export type ModelAppearanceControllerVariant = 'compact' | 'standard';

export interface TitleIconProps {
    IconComponent: React.ComponentType<SvgIconProps>;
    tooltip: string;
}

export type ModelAppearanceControllerProps = {
    text: string;

    appearance: ItemAppearance;
    setAppearance: (update: (current: ItemAppearance) => ItemAppearance) => void;
    disabledControls: Partial<DisabledControls>;

    solo?: boolean;
    onSoloChange?: (solo: boolean) => any;

    selectedForEdit?: boolean;
    onSelectedForEditChange?: (selectedForEdit: boolean) => any;

    header?: boolean;
    dedent?: boolean;
    headerExpanded?: boolean;
    onCollapseChange?: () => void;

    isPast?: boolean; // Whether this controller controls the appearance of models from a past design

    variant?: ModelAppearanceControllerVariant;
    className?: string;

    titleIcons?: TitleIconProps[];
};

type TitleProps = {
    text: string;
    header?: boolean;
    headerExpanded?: boolean;
    dedent?: boolean;
    onCollapseChange?: () => void;
    variant: ModelAppearanceControllerVariant;
    icons?: TitleIconProps[];
};

const Title: React.FC<TitleProps> = ({ header, text, onCollapseChange, headerExpanded, dedent, variant, icons }) => {
    const classes = useStyles();

    const paddingLeftDedent: number = dedent ? 6 : 36;
    const title = (
        <Grid container wrap={'nowrap'} alignItems={'center'}>
            <Grid item>
                <Text
                    style={
                        variant === 'standard'
                            ? {
                                  fontWeight: header ? 500 : 300,
                                  paddingLeft: header ? 0 : paddingLeftDedent,
                                  fontSize: header ? 20 : 14,
                                  ...controlListItemTextStyle,
                              }
                            : undefined
                    }
                    variant={variant === 'standard' ? 'h6' : 'body2'}
                    medium={variant === 'compact'}
                >
                    {text}
                </Text>
            </Grid>
            {icons?.map(({ IconComponent, tooltip }) => {
                return (
                    <Grid key={tooltip} item className={classes.titleIconWrapper}>
                        <Tooltip title={tooltip}>
                            <IconComponent className={classes.titleIcon} />
                        </Tooltip>
                    </Grid>
                );
            })}
        </Grid>
    );

    return header && onCollapseChange ? (
        <ListItem button onClick={onCollapseChange} style={{ padding: `8px 0px` }}>
            <div style={{ marginBottom: -5, marginRight: 12 }}>
                <Icon icon={headerExpanded ? 'ExpandMoreIcon' : 'ChevronRight'} color={'action'} />
            </div>
            <ListItemText primary={title} style={{ flex: 'none', paddingRight: 10 }} />
        </ListItem>
    ) : (
        <ListItemText primary={title} />
    );
};

function useTrackAppearanceChange({
    text,
    header,
    isPast,
}: Pick<ModelAppearanceControllerProps, 'text' | 'header' | 'isPast'>) {
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    return React.useCallback(
        (
            action: DandyAnalyticsEventSchemaType['All - Portal - Scan Appearance Mode Toggled']['action'],
            isActive: boolean,
        ) => {
            if (!orderId) {
                return;
            }

            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Scan Appearance Mode Toggled', {
                action,
                isActive,
                isPast: !!isPast,
                $groups: { order: orderId },
                isGroup: !!header,
                label: text,
            });
        },
        [orderId, text, header, isPast],
    );
}

/***
 * Component to control appearance of a single model item.
 *
 * @component
 */
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
export const ModelAppearanceController: React.FC<ModelAppearanceControllerProps> = props => {
    const {
        appearance,
        setAppearance,
        text,
        header,
        solo,
        onSoloChange,
        selectedForEdit,
        onSelectedForEditChange,
        disabledControls,
        onCollapseChange,
        headerExpanded,
        dedent,
        variant,
        className,
        titleIcons,
    } = props;

    const styles = useStyles();
    const trackAppearanceChange = useTrackAppearanceChange(props);

    const handleTexturesClick = React.useCallback(() => {
        trackAppearanceChange('textures', !appearance.colorize);
        setAppearance(state => ({
            ...state,
            colorize: !appearance.colorize,
        }));
    }, [appearance, setAppearance, trackAppearanceChange]);

    const handleInsertionAxisClick = React.useCallback(
        (orderId?: string) => {
            if (!appearance.showInsertionAxis && orderId) {
                BrowserAnalyticsClientFactory.Instance?.track(`Ops - Portal - QC Insertion Axis Tool Used`, {
                    $groups: {
                        order: orderId,
                    },
                });
            }
            trackAppearanceChange('insertion_axis', !appearance.showInsertionAxis);
            setAppearance(state => ({
                ...state,
                showInsertionAxis: !appearance.showInsertionAxis,
            }));
        },
        [appearance, setAppearance, trackAppearanceChange],
    );

    const handleTransparencyClick = React.useCallback(() => {
        trackAppearanceChange('transparency', !isTransparent(appearance));
        setAppearance(state => ({
            ...state,
            opacity: getToggledOpacity(appearance),
        }));
    }, [appearance, setAppearance, trackAppearanceChange]);

    const handleVisibilityClick = React.useCallback(() => {
        trackAppearanceChange('visibility', !appearance.visible);
        setAppearance(state => ({
            ...state,
            visible: !appearance.visible,
        }));
    }, [appearance, setAppearance, trackAppearanceChange]);

    const handleSoloClick = React.useCallback(() => {
        trackAppearanceChange('solo', !solo);
        onSoloChange?.(!solo);
    }, [onSoloChange, solo, trackAppearanceChange]);

    const handleEditedClick = React.useCallback(() => {
        if (!selectedForEdit) {
            trackAppearanceChange('selectedForEdit', !selectedForEdit);
            onSelectedForEditChange?.(!selectedForEdit);
        }
    }, [onSelectedForEditChange, selectedForEdit, trackAppearanceChange]);

    return (
        <ListItem
            style={{ paddingRight: 0, paddingLeft: 0 }}
            className={className}
            ContainerProps={{ ['aria-label']: text }}
        >
            <Title
                header={header}
                text={text}
                onCollapseChange={onCollapseChange}
                headerExpanded={headerExpanded}
                dedent={dedent}
                variant={variant ?? 'standard'}
                icons={titleIcons}
            />

            <ListItemSecondaryAction style={variant === 'compact' ? undefined : { padding: `0px 4px`, right: '0px' }}>
                {!disabledControls.selectedForEdit && (
                    <Tooltip title={selectedForEdit ? 'Selected for edit' : 'Select for edit'}>
                        <IconButton
                            className={clsx(styles.iconButton, selectedForEdit ? 'active' : undefined)}
                            onClick={handleEditedClick}
                            size={'small'}
                            key={'selectedForEdit'}
                        >
                            <TargetToothIcon className={selectedForEdit ? styles.iconActive : styles.iconInactive} />
                        </IconButton>
                    </Tooltip>
                )}

                {!disabledControls.undercutCurtains && (
                    <Tooltip
                        title={
                            appearance.showUndercutCurtains ? 'Disable Undercut Curtains' : 'Enable Undercut Curtains'
                        }
                    >
                        <IconButton
                            className={clsx(styles.iconButton, appearance.showUndercutCurtains ? 'active' : undefined)}
                            onClick={() => {
                                trackAppearanceChange('undercut_curtains', !appearance.showUndercutCurtains);
                                setAppearance(state => ({
                                    ...state,
                                    showUndercutCurtains: !appearance.showUndercutCurtains,
                                }));
                            }}
                            size={'small'}
                            key={'undercut_curtain'}
                        >
                            <CurtainsIcon
                                className={appearance.showUndercutCurtains ? styles.iconActive : styles.iconInactive}
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {!disabledControls.undercutArrowAndShadow && (
                    <Tooltip
                        title={appearance.showUndercutShadow ? 'Disable Undercut Shadow' : 'Enable Undercut Shadow'}
                    >
                        <IconButton
                            className={clsx(styles.iconButton, appearance.showUndercutShadow ? 'active' : undefined)}
                            onClick={() => {
                                trackAppearanceChange('undercut_shadow', !appearance.showUndercutShadow);
                                setAppearance(state => ({
                                    ...state,
                                    showUndercutShadow: !appearance.showUndercutShadow,
                                }));
                            }}
                            size={'small'}
                            key={'undercut_shadow'}
                        >
                            <UndercutShadowIcon
                                className={appearance.showUndercutShadow ? styles.iconActive : styles.iconInactive}
                            />
                        </IconButton>
                    </Tooltip>
                )}
                <OrderAnalyticsContext.Consumer>
                    {context =>
                        !disabledControls.insertionAxis && (
                            <Tooltip
                                title={
                                    appearance.showInsertionAxis ? 'Disable Insertion Axis' : 'Enable Insertion Axis'
                                }
                            >
                                <IconButton
                                    className={clsx(
                                        styles.iconButton,
                                        appearance.showInsertionAxis ? 'active' : undefined,
                                    )}
                                    onClick={() => handleInsertionAxisClick(context?.orderId)}
                                    size={'small'}
                                    key={'insertionAxis'}
                                >
                                    <Icon
                                        icon={'ArrowDownIcon'}
                                        className={
                                            appearance.showInsertionAxis ? styles.iconActive : styles.iconInactive
                                        }
                                    />
                                </IconButton>
                            </Tooltip>
                        )
                    }
                </OrderAnalyticsContext.Consumer>
                {!disabledControls.colorize && (
                    <Tooltip title={appearance.colorize ? 'Disable Color' : 'Enable Color'}>
                        <IconButton
                            className={clsx(styles.colorizeIconButton, appearance.colorize ? 'active' : undefined)}
                            onClick={handleTexturesClick}
                            size={'small'}
                            key={'colorize'}
                        >
                            <ModelTextureIcon />
                        </IconButton>
                    </Tooltip>
                )}

                {!disabledControls.transparency && (
                    <Tooltip title={isTransparent(appearance) ? 'Disable Transparent' : 'Enable Transparent'}>
                        <IconButton
                            className={clsx(styles.iconButton, isTransparent(appearance) ? 'active' : undefined)}
                            onClick={handleTransparencyClick}
                            size={'small'}
                            key={'transparency'}
                        >
                            <RestorativesTransparentIcon
                                className={isTransparent(appearance) ? styles.iconActive : styles.iconInactive}
                            />
                        </IconButton>
                    </Tooltip>
                )}

                {!disabledControls.solo && (
                    <Tooltip title={solo ? 'Un-Solo' : 'Solo'}>
                        <IconButton
                            className={clsx(styles.iconButton, solo ? 'active' : undefined)}
                            onClick={handleSoloClick}
                            size={'small'}
                            key={'solo'}
                        >
                            <ToothInFrameIcon className={solo ? styles.iconActive : styles.iconInactive} />
                        </IconButton>
                    </Tooltip>
                )}

                <Tooltip title={appearance.visible ? 'Hide' : 'Show'}>
                    <IconButton
                        className={clsx(styles.iconButton, appearance.visible ? 'active' : undefined)}
                        onClick={handleVisibilityClick}
                        size={'small'}
                        key={'visibility'}
                    >
                        <VisibilityIcon className={appearance.visible ? styles.iconActive : styles.iconInactive} />
                    </IconButton>
                </Tooltip>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
