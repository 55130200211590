import type { ModelPayload } from './ModelViewerTypes';
import type { ModelElementType } from '@orthly/forceps';
import type { DesignModelPayloadsDesignRevision_FragmentFragment } from '@orthly/graphql-inline-react';
import type { LabsGqlOrder, LabsGqlPatientFragment } from '@orthly/graphql-operations';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { FileNameUtils } from '@orthly/runtime-utils';

export type DesignCaseParserOrder = Pick<LabsGqlOrder, 'items_v2'> & {
    patient: Pick<LabsGqlPatientFragment, 'first_name'>;
};

type Properties = { [key: string]: string };

export interface ModelElement {
    path: string;
    modelType: ModelElementType;
    properties: Properties;
}

type DesignCase = Pick<DesignModelPayloadsDesignRevision_FragmentFragment, 'case_file_model_elements'>;

/**
 * Order contains any item with a material of
 *   - Emax layered porcelain
 *   - Any type of PFM (non precious, semi precious noble, etc - anything that starts with PFM)
 *   - PFZ (porcelain fused to zirconia)
 */
export function orderMaterialCanHaveLayers(order: Pick<LabsGqlOrder, 'items_v2'>): boolean {
    const orderItemsV2 = OrderItemV2Utils.parseItems(order.items_v2);
    return orderItemsV2
        .flatMap(item => {
            if (!item) {
                return;
            }
            return CartItemV2Utils.getAllMaterials(item);
        })
        .some(material => {
            const lower = material?.toLowerCase();

            if (!lower) {
                return false;
            }

            return lower.includes('porcelain') || lower.startsWith('pfm');
        });
}

/**
 * Use a heuristic to determine which models are restoratives (crowns, etc.)
 *
 * We include the model if it is of the CAD type AND
 *   - If the model name includes the patient's first name
 *   - Or, if the model if of the form `\(\d+\)` (for example, `(86002)`)
 *
 * We also include the restoratives of type "Anatomy Elements" if the order can have layers,
 * as this is where the layers are stored
 *
 * It seems that different labs use different naming schemes for their models.
 * orderID in parens:  regex test ^\(\d+-\d+\-\d+\)
 *     (11-461-141)_First_20220315_1936 :
 * orderID and dates arranged around underscores: regx test /_\d+_\d+/
 *      First_Last _20220314_1629 3
 *      43105_20220315_0140
 *
 */
const cadNonRestorativeStrings: string[] = ['Unsectioned', 'Sectioned', 'UpperJaw', 'LowerJaw', 'Tooth_{'];
const cadRestorativeRegexPatterns: RegExp[] = [/_\d+_\d+/, /^\(\d+-\d+-\d+\)/, /_[a-zA-Z]+\s\d/];

function isNotRestorativeFileStringPattern(cadFilePath: string): boolean {
    const cadFileName = cadFilePath.split('/').slice(-1)[0] ?? cadFilePath;
    // we exclude the known keywords which indicate nonRestorative CAD items
    return cadNonRestorativeStrings.some(item => cadFileName.includes(item));
}

function isRestorativeFileStringPattern(cadFilePath: string): boolean {
    const cadFileName = cadFilePath.split('/').slice(-1)[0] ?? cadFilePath;
    // we exclude the known keywords which indicate nonRestorative CAD items
    if (cadNonRestorativeStrings.some(item => cadFileName.includes(item))) {
        return false;
    }
    // we explicitly match known patterns
    return cadRestorativeRegexPatterns.some(regx => regx.test(cadFileName));
}

const isJaw = (payload: Pick<ModelPayload, 'path'>, side: 'upper' | 'lower') =>
    payload.path.toLowerCase().includes(side);

// Sectioned and Unsectioned are terms used in 3Shape to distinguish between two different types of 3D printed model output style"
export const isSection = (payload: Pick<ModelPayload, 'path'>, side: 'sectioned' | 'unsectioned') =>
    payload.path.toLowerCase().includes(side);

export const isSoftTissueModel = (payload: Pick<ModelPayload, 'path'>) =>
    payload.path.toLowerCase().includes('softtissue');

const getPrintedModelName = (payload: Pick<ModelPayload, 'path'>) => {
    let jawName;
    // Don't use else to be able to save original name if it doesn't fir any criteria
    if (isJaw(payload, 'upper')) {
        jawName = 'Upper';
    }
    if (isJaw(payload, 'lower')) {
        jawName = 'Lower';
    }
    if (jawName) {
        if (isSection(payload, 'unsectioned')) {
            return `${jawName} Unsectioned`;
        }
        if (isSection(payload, 'sectioned')) {
            return `${jawName} Sectioned`;
        }
    }
    return jawName;
};

// Ensures we're always dealing with just raw file paths (no extensions).
function normalizePath(path: string) {
    // Occasionally we see "\" as path separators in case files. Correct them to "/".
    // (https://github.com/orthly/orthlyweb/pull/11450)
    return FileNameUtils.removeExtension(path.toLowerCase().replace('\\', '/'));
}

export class DesignCaseParser {
    private static getRestorativesByOrder<T extends Pick<ModelPayload, 'path' | 'type'>>(
        payloads: T[],
        order: DesignCaseParserOrder,
    ): T[] {
        const canHaveLayers = orderMaterialCanHaveLayers(order);
        return payloads.filter(
            payload =>
                (payload.type === LabsGqlOrderDesignScanType.Cad &&
                    ((payload.path.includes(order.patient.first_name) &&
                        !isNotRestorativeFileStringPattern(payload.path)) ||
                        isRestorativeFileStringPattern(payload.path))) ||
                (canHaveLayers && payload.type === LabsGqlOrderDesignScanType.AnatomyElements),
        );
    }

    static getRestorativesByCaseFile<T extends Pick<ModelPayload, 'path' | 'type'>>(
        payloads: T[],
        parsedCase?: DesignCase,
    ): T[] {
        if (!parsedCase) {
            return [];
        }

        // Array of all of the paths that are a restorative
        // These seem to always be marked as "meIndicationRegular".
        const caseRestoratives = parsedCase.case_file_model_elements
            .filter(element => element.model_element_type === 'meIndicationRegular')
            .map(element => normalizePath(element.model_file_name));

        return payloads.filter(
            payload =>
                payload.type === LabsGqlOrderDesignScanType.Cad &&
                caseRestoratives.find(restorativePath => normalizePath(payload.path).endsWith(restorativePath)),
        );
    }

    static getRestoratives<T extends Pick<ModelPayload, 'path' | 'type'>>(
        order: DesignCaseParserOrder,
        payloads: T[],
        caseFileParsed?: DesignCase,
    ): (T & { visible: boolean })[] {
        const namedRestoratives = DesignCaseParser.getRestorativesByOrder(payloads, order);
        const caseRestoratives = DesignCaseParser.getRestorativesByCaseFile(payloads, caseFileParsed);

        if (caseRestoratives.length === 0) {
            return namedRestoratives.map(r => ({ ...r, visible: true }));
        }

        // Restoratives in the case file should be considered the source of truth.
        // Still, 3Shape makes mistakes, so we assume that there may be some files in existence that it doesn't know about (or our parser failed!)
        // We will grab any restoratives that appear in the case file and make them visible by default. The rest will be invisible by default.
        // This way, we can be _really_ sure about the restoratives we render by default, and not lose sleep over ones we're less sure about.
        const visibleRestoratives = caseRestoratives;
        const hiddenRestoratives = namedRestoratives.filter(
            restorative => !visibleRestoratives.some(other => other.path === restorative.path),
        );

        return [
            ...visibleRestoratives.map(r => ({ ...r, visible: true })),
            ...hiddenRestoratives.map(r => ({ ...r, visible: false })),
        ];
    }

    static getPrintedModels<T extends Pick<ModelPayload, 'path' | 'type' | 'name'>>(
        payloads: T[],
        caseFileParsed?: DesignCase,
    ): T[] {
        if (!caseFileParsed) {
            return [];
        }

        const replaceName = (payload: T) => {
            return {
                ...payload,
                name: getPrintedModelName(payload) ?? payload.name,
            };
        };

        // meDigitalModelPrepSectioned or meDigitalModelPrepUnsectioned or meDigitalModelDie  will give us the 3DPrinted models
        const case3DPrintedModels = caseFileParsed.case_file_model_elements
            .filter(
                element =>
                    element.model_element_type === 'meDigitalModelPrepSectioned' ||
                    element.model_element_type === 'meDigitalModelPrepUnsectioned' ||
                    element.model_element_type === 'meDigitalModelAntagonist' ||
                    element.model_element_type === 'meDigitalModelDie',
            )
            .map(element => normalizePath(element.model_file_name));
        // replaceName will give elements nice names based on path
        return payloads
            .filter(payload => {
                // Only CAD elements can be models
                if (payload.type !== LabsGqlOrderDesignScanType.Cad) {
                    return false;
                }

                // 3D printed models are the physical models
                if (case3DPrintedModels.find(path => normalizePath(payload.path).endsWith(path))) {
                    return true;
                }

                // Soft Tissue models are implant specific, but still are valid models
                if (isSoftTissueModel(payload)) {
                    return true;
                }

                // Otherwise we just say no
                return false;
            })
            .map(item => replaceName(item));
    }
}
