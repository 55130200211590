import { getPartnerId } from '../../../../utils/authorization';
import { SettingsBaseModal } from '../../components/SettingsBaseModal';
import { AddCreditCardForm } from './AddCreditCardForm.graphql';
import { useSession } from '@orthly/session-client';
import React from 'react';

interface AddCreditCardModalProps {
    open: boolean;
    setOpen: (value: boolean) => void;
    createCard: (props: { partnerId: string; cardToken: string }) => Promise<void>;
    setOpenLinkBankAccountModal: (open: boolean) => void;
}

export const AddCreditCardModal: React.FC<AddCreditCardModalProps> = props => {
    const { open, setOpen, createCard, setOpenLinkBankAccountModal } = props;
    const session = useSession();
    const userId = session?.user_id;
    const partnerId = getPartnerId(session);

    if (!userId || !partnerId) {
        return null;
    }

    return (
        <SettingsBaseModal
            content={
                <AddCreditCardForm
                    createCard={createCard}
                    setOpen={setOpen}
                    setOpenLinkBankAccountModal={setOpenLinkBankAccountModal}
                />
            }
            loading={false}
            title={'Add your credit card'}
            open={open}
            setOpen={setOpen}
            CustomButton={() => null}
        />
    );
};
