import type { MeshPhongMaterialProps } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

export const TriosMaterialFragmentShader = `
#include <common>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
#include <map_pars_fragment>
#include <alphamap_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_pars_fragment>
#include <cube_uv_reflection_fragment>
#include <fog_pars_fragment>
#include <specularmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

uniform float vertexColorLerp;
uniform float specularStrength;
uniform float ambientStrength;

vec3 viewDir = +normalize(vec3(+0.0, 0.1, -1.0));
vec3 light0 =  -normalize(vec3(+0.0, 0.0, +1.0));
vec3 light1 =  -normalize(vec3(+0.0, 1.0, +0.0));
vec3 light2 =  -normalize(vec3(+1.0, 0.0, -1.0));
vec3 light3 =  -normalize(vec3(-1.0, 0.0, -1.0));

float calcSpecular(vec3 lightDir, vec3 normal) {
    vec3 H = normalize(((-viewDir) + lightDir));
    return pow(clamp(dot(H, normal), 0.0, 1.0), 512.0);
}
vec4 calcSpecularAndDiffuse(vec3 normal, vec4 vertexColor) {
    vertexColor.rgb = ((vertexColor.rgb - vec3(0.5)) * 1.15) + vec3(0.5);
    vertexColor.rgb = pow(abs(vertexColor.rgb), vec3(0.95));
	
    float specular = calcSpecular(light0, normal);
    specular += calcSpecular(light2, normal);
    specular += calcSpecular(light3, normal);
	
    vec4 diffuse = vec4(clamp(max(dot(light1, normal), dot(light0, normal)), 0.0, 1.0));
    vec4 color = vec4 (mix(vec4 (((vec4(ambientStrength) + diffuse) * vertexColor)), vec4(vertexColor), vec4(vertexColorLerp)));
    color = color + (specular * specularStrength);
    return color;
}

uniform vec3 diffuse;
uniform float opacity;

#ifdef DANDY_COLOR_TRANSFORM
    uniform mat4x4 dandyColorTransform;
#endif

varying vec3 vNormal;

void main() {
	#include <clipping_planes_fragment>
	vec4 diffuseColor = vec4( diffuse, opacity );
	#include <logdepthbuf_fragment>

#ifdef USE_MAP
    #ifdef DANDY_COLOR_TRANSFORM
	    vec4 texelColor = dandyColorTransform*texture2D( map, vUv );
    #else
	    vec4 texelColor = texture2D( map, vUv );
    #endif
	texelColor = mapTexelToLinear( texelColor );
	diffuseColor *= texelColor;

#endif	
    	
	#include <color_fragment>
	#include <alphamap_fragment>
	#include <alphatest_fragment>

	ReflectedLight reflectedLight = ReflectedLight(vec3(0.0),vec3(0.0),vec3(0.0),vec3(0.0));
	reflectedLight.indirectDiffuse += vec3(1.0);
	reflectedLight.indirectDiffuse *= diffuseColor.rgb;
	vec3 outgoingLight = reflectedLight.indirectDiffuse;

	vec3 normal = normalize(vNormal);
    gl_FragColor = calcSpecularAndDiffuse(normal, vec4(outgoingLight, diffuseColor.a));
}
`;

export interface ScanMeshShaderMaterialChairsideProps extends MeshPhongMaterialProps {
    vertexColorLerp?: number;
    specularStrength?: number;
    ambientStrength?: number;
}

export const ScanMeshShaderMaterialChairside: React.FC<ScanMeshShaderMaterialChairsideProps> = props => {
    const onBeforeCompileHook = React.useCallback(
        (shader: THREE.Shader) => {
            shader.uniforms['vertexColorLerp'] = { value: props.vertexColorLerp ?? 1.0 };
            shader.uniforms['ambientStrength'] = { value: props.ambientStrength ?? 0.1 };
            shader.uniforms['specularStrength'] = { value: props.specularStrength ?? 0.352 };
            shader.fragmentShader = TriosMaterialFragmentShader;
        },
        [props.vertexColorLerp, props.ambientStrength, props.specularStrength],
    );

    const materialRef = React.useRef<THREE.MeshPhysicalMaterial>();
    React.useEffect(() => {
        const material = materialRef.current;
        if (material) {
            // It would be ideal to for performance to just mark the
            // uniforms for update not the whole material
            // but since we don't create custom material but are patching
            // existing one, we have to mark the whole material for update
            // because THREE doesn't expose methods or flags to do that
            // for standard materials such as MeshPhysicalMaterial
            material.needsUpdate = true;
        }
    }, [props.map, props.vertexColors]);

    return (
        <meshPhongMaterial ref={materialRef} {...props} side={THREE.DoubleSide} onBeforeCompile={onBeforeCompileHook} />
    );
};
