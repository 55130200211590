import { createStyles, makeStyles } from '@orthly/ui-primitives';

export const useCommonStyles = makeStyles(() =>
    createStyles({
        //non-inherited pointer events disablement
        noPointerEvents: {
            pointerEvents: 'none',
            ':where(&>*)': {
                pointerEvents: 'auto',
            },
        },
    }),
);
