import type { FirebasePreviewFileMultiWithType } from './OrderDesignPreview.types';
import type { DesignModelPayloadsDesignRevision_FragmentFragment } from '@orthly/graphql-inline-react';
import { LabsGqlDesignRevisionAssetFileType, LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { FileNameUtils, type NonNullableUnpackArray } from '@orthly/runtime-utils';
import _ from 'lodash';

type SingleAsset = NonNullableUnpackArray<DesignModelPayloadsDesignRevision_FragmentFragment['assets']>;

enum ModelFileType {
    PlyWithImage = 'PlyWithImage',
    PlyWithoutImage = 'PlyWithoutImage',
    Ctm = 'Ctm',
    Unknown = 'Unknown',
    Draco = 'Draco',
}

function fileTypeForEntry(entry: SingleAsset): ModelFileType {
    if (entry.file_type === LabsGqlDesignRevisionAssetFileType.Ply) {
        if (entry.image_path) {
            return ModelFileType.PlyWithImage;
        } else {
            return ModelFileType.PlyWithoutImage;
        }
    } else if (entry.file_type === LabsGqlDesignRevisionAssetFileType.Ctm) {
        return ModelFileType.Ctm;
    } else if (entry.file_type === LabsGqlDesignRevisionAssetFileType.Drc) {
        return ModelFileType.Draco;
    }
    return ModelFileType.Unknown;
}

const FILE_TYPE_PREFERENCES = [
    ModelFileType.Draco,
    ModelFileType.PlyWithImage,
    ModelFileType.PlyWithoutImage,
    ModelFileType.Ctm,
    ModelFileType.Unknown,
];

/* Returns the preference score for an entry. */
function preferenceForEntry(entry: SingleAsset, preferences: ModelFileType[]): number {
    const fileType = fileTypeForEntry(entry);
    const indexInPreferences = preferences.indexOf(fileType);
    return indexInPreferences >= 0 ? indexInPreferences : 100;
}

function preferredEntry(
    entries: DesignModelPayloadsDesignRevision_FragmentFragment['assets'],
    preferences: ModelFileType[],
): SingleAsset | undefined {
    return _.sortBy(entries, e => preferenceForEntry(e, preferences))[0];
}

export interface DesignModelPathsOpts {
    desiredFiles?: string[];
    filterFiles?: (filePath: string, allPaths: string[]) => boolean;
}

export function getDesignModelPaths(
    assets: DesignModelPayloadsDesignRevision_FragmentFragment['assets'],
    opts: DesignModelPathsOpts = {},
): FirebasePreviewFileMultiWithType[] {
    const { desiredFiles, filterFiles } = opts;
    const allPaths = (assets ?? []).map(a => a.file_path);
    const filtered3dFiles = (assets ?? []).filter(design3dFile => {
        if (design3dFile.file_path.includes('Backup')) {
            return false;
        }

        if (desiredFiles && !_.some(desiredFiles, f => design3dFile.file_path.endsWith(f))) {
            return false;
        }

        if (filterFiles && !filterFiles(design3dFile.file_path, allPaths)) {
            return false;
        }

        // No external models allowed in the file listing, as they're just design library
        if (design3dFile.mesh_type === LabsGqlOrderDesignScanType.ExternalModels) {
            return false;
        }
        // Get anatomy elements for layered cases (eg, layered porcelain on coping)
        if (design3dFile.mesh_type === LabsGqlOrderDesignScanType.AnatomyElements) {
            return true;
        }

        const fileName = design3dFile.file_path.split('/').slice(-1)[0];

        if (fileName?.startsWith('Raw') && design3dFile.mesh_type === LabsGqlOrderDesignScanType.Scans) {
            return true;
        }

        // Ignoring Raw scan and UNN scans
        return !!fileName && !fileName.match(/Raw\s.*\sscan/i) && !fileName.startsWith('UNN');
    });

    // Group all models without their extension
    // This allows us to find those that have the same name, but are just different file types.
    const groups = _.groupBy(filtered3dFiles, f => FileNameUtils.removeExtension(f.file_path));
    const designPaths = _.flatten(
        _.compact(
            Object.values(groups).map(entries => {
                return preferredEntry(entries, FILE_TYPE_PREFERENCES);
            }),
        ),
    ).map(file => ({
        source: file.file_path,
        name: file.file_path,
        type: file.mesh_type,
        ...(file.image_path && { image: file.image_path }),
    }));

    return designPaths;
}
