import { SignUpPageContainer } from '../SignUpPageContainer';
import { StackY } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    Card,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Icon,
    Button,
    Text,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    formContainer: {
        width: '100%',
        marginTop: 24,
        marginBottom: 24,
    },
    trainingCard: {
        padding: '32px 24px',
        borderRadius: 16,
        border: `1px solid ${FlossPalette.DARK_TAN}`,
        boxSizing: 'border-box',
    },
    trainingListItem: {
        display: 'flex',
        marginTop: 12,
    },
    trainingIcon: {
        marginRight: 8,
        color: FlossPalette.SECONDARY_FOREGROUND,
        width: 24,
        height: 24,
    },
}));

const TrainingOverviewCard: React.VFC = () => {
    const classes = useStyles();

    return (
        <Card elevation={0} className={classes.trainingCard}>
            <Text style={{ marginBottom: 8 }} variant={'h6'} medium>
                Training Overview
            </Text>
            <Text variant={'body2'} color={'GRAY'}>
                Your 3 hour virtual training will get you set up for success with Dandy and introduce you to what you
                need to know to scan and place an order
            </Text>
            <StackY>
                <Grid container>
                    <Grid item className={classes.trainingListItem}>
                        <Icon icon={'CheckCircleIcon'} className={classes.trainingIcon} />
                        <Text color={'GRAY'} medium variant={'body2'}>
                            Using Dandy Equipment
                        </Text>
                    </Grid>
                    <Grid item className={classes.trainingListItem}>
                        <Icon icon={'CheckCircleIcon'} className={classes.trainingIcon} />
                        <Text color={'GRAY'} medium variant={'body2'}>
                            Tips and tricks for successful scanning
                        </Text>
                    </Grid>
                    <Grid item className={classes.trainingListItem}>
                        <Icon icon={'CheckCircleIcon'} className={classes.trainingIcon} />
                        <Text color={'GRAY'} medium variant={'body2'}>
                            Submitting crown and bridge orders
                        </Text>
                    </Grid>
                    <Grid item className={classes.trainingListItem}>
                        <Icon icon={'CheckCircleIcon'} className={classes.trainingIcon} />
                        <Text color={'GRAY'} medium variant={'body2'}>
                            Navigating the Dandy Portal
                        </Text>
                    </Grid>
                </Grid>
            </StackY>
        </Card>
    );
};

export const SignUpScheduleTraining: React.VFC = () => {
    const [numOfAttendees, setNumOfAttendees] = React.useState<string | undefined>('');
    const classes = useStyles();
    const handleDropdownChange = (value: string): void => {
        setNumOfAttendees(value);
    };

    const menuOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: 'More than 6' },
    ];

    return (
        <SignUpPageContainer
            header={'Schedule your training'}
            subheader={'Select the date and time you wuld like to have your 3-hour virtual training.'}
            sidebar={<TrainingOverviewCard />}
        >
            <>
                <Text medium variant={'body1'}>
                    How many will attend training?
                </Text>
                <Text variant={'body2'}>
                    We recommend that anyone who will be scanning on a regular basis attend training, however we do
                    recommend limiting training to six attendees for best results
                </Text>
                <FormControl className={classes.formContainer} variant={'standard'}>
                    <InputLabel id={'num_attendees'}># of attendees</InputLabel>
                    <Select
                        labelId={'num_attendees'}
                        data-test={'taining__select-attendees'}
                        value={numOfAttendees}
                        style={{ background: FlossPalette.WHITE, marginBottom: 8 }}
                        onChange={e => handleDropdownChange(e.target.value as string)}
                        variant={'standard'}
                    >
                        {menuOptions.map(option => (
                            <MenuItem key={option.label} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                    {numOfAttendees && Number(numOfAttendees) > 6 && (
                        <Text variant={'body2'}>
                            Each virtual training time is limited to 6 attendees for best results. If you intend to have
                            more than six people, your AE will contact you to schedule the appropriate number of
                            training sessions for your practice.
                        </Text>
                    )}
                </FormControl>
                <Button
                    data-test={'training__submit'}
                    disabled={!numOfAttendees}
                    variant={'primary'}
                    onClick={() => {
                        /* TODO: Wire up to next scheduling screen */
                    }}
                    type={'submit'}
                >
                    Continue
                </Button>
            </>
        </SignUpPageContainer>
    );
};
