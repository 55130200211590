import { DesignComparisonContext } from '../DesignComparison';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import React from 'react';

export type ToggleActionType = DandyAnalyticsEventSchemaType['All - Portal - Design Tool Toggled']['action'];

export function useQcToolbarTrackToggle() {
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const comparisonContext = React.useContext(DesignComparisonContext);
    const hasPast = !!comparisonContext?.hasPast;

    return React.useCallback(
        (action: ToggleActionType, usedKeyboardShortcut: boolean, isActive: boolean) => {
            // No order ID -> we won't track
            if (!orderId) {
                return;
            }

            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Tool Toggled', {
                usedKeyboardShortcut,
                action,
                isActive,
                hasPast,
                $groups: { order: orderId },
            });
        },
        [orderId, hasPast],
    );
}
