import { getOpposingJawDistance, applyColorFromDistance, computeDistanceToOpposingModel } from './ModelDistanceColor';
import { getOriginalColor, setMeshColor, storeOriginalColor } from './ModelMeshes';
import type { ModelPayload, ModelPayloadView } from './ModelViewerTypes';
import { ModelPayloadViewKind } from './ModelViewerTypes';
import { HeatMapType } from '@orthly/forceps';
import React from 'react';

function getModelScansForClearanceTool(model_payload: ModelPayloadView): {
    prep_scan: ModelPayload | null;
    antag_scan: ModelPayload | null;
} {
    switch (model_payload.kind) {
        case ModelPayloadViewKind.AnteriorAndPrepCombined: {
            const prep_scan = model_payload.prepScan ?? null;
            const antag_scan = model_payload.anteriorScan ?? null;

            return { prep_scan, antag_scan };
        }
        case ModelPayloadViewKind.PrepCombined: {
            const prep_scan = model_payload.prepScan ?? null;

            return { prep_scan, antag_scan: null };
        }
        default:
            return { prep_scan: null, antag_scan: null };
    }
}

/**
 * Update the colors of the prep and antagonist scans for the clearance viewer,
 * given a minimum and maximum distance
 */
function updateClearanceColorValues({
    prep_scan,
    antag_scan,
    min,
    max,
}: {
    prep_scan: ModelPayload | null;
    antag_scan: ModelPayload | null;
    min: number;
    max: number;
}) {
    const prepDistance = getOpposingJawDistance(prep_scan?.model.geometry);
    const antagDistance = getOpposingJawDistance(antag_scan?.model.geometry);

    if (prepDistance && prep_scan) {
        applyColorFromDistance(prep_scan.model.geometry, min, max);
    }

    if (antagDistance && antag_scan) {
        applyColorFromDistance(antag_scan.model.geometry, min, max);
    }
}

/**
 * Reset models to their original colors, prior to the toggling of the clearance
 * tool
 */
function revertClearanceColorValues(prep_scan: ModelPayload | null, antag_scan: ModelPayload | null) {
    const originalPrepColor = getOriginalColor(prep_scan?.model.geometry);
    const originalAntagColor = getOriginalColor(antag_scan?.model.geometry);

    if (originalPrepColor && prep_scan) {
        setMeshColor(prep_scan.model.geometry, originalPrepColor);
    }

    if (originalAntagColor && antag_scan) {
        setMeshColor(antag_scan.model.geometry, originalAntagColor);
    }
}

export function useClearanceToolSetup({
    model_payload,
    activeColorMap,
    enableClearanceTool,
    min,
    max,
}: {
    model_payload: ModelPayloadView;
    activeColorMap?: HeatMapType;
    enableClearanceTool: boolean;
    min: number;
    max: number;
}): void {
    React.useEffect(() => {
        if (
            model_payload.kind !== ModelPayloadViewKind.AnteriorAndPrepCombined ||
            activeColorMap !== HeatMapType.Clearance
        ) {
            return;
        }

        const { prep_scan, antag_scan } = getModelScansForClearanceTool(model_payload);

        if (prep_scan && antag_scan) {
            computeDistanceToOpposingModel(antag_scan.model.geometry, prep_scan.model.geometry);
            computeDistanceToOpposingModel(prep_scan.model.geometry, antag_scan.model.geometry);
            storeOriginalColor(prep_scan.model.geometry);
            storeOriginalColor(antag_scan.model.geometry);
        }
    }, [model_payload, activeColorMap]);

    React.useEffect(() => {
        if (
            model_payload.kind !== ModelPayloadViewKind.PrepCombined &&
            model_payload.kind !== ModelPayloadViewKind.AnteriorAndPrepCombined
        ) {
            return;
        }

        const { prep_scan, antag_scan } = getModelScansForClearanceTool(model_payload);

        if (enableClearanceTool && activeColorMap === HeatMapType.Clearance) {
            updateClearanceColorValues({ prep_scan, antag_scan, min, max });
        } else {
            revertClearanceColorValues(prep_scan, antag_scan);
        }
    }, [enableClearanceTool, model_payload, min, max, activeColorMap]);
}
