/* eslint-disable max-lines-per-function */
import type { ImpersonatedQRCodeLinkResult } from '../QRCode';
import { RefabFileUploader } from './RefabFileUploader';
import type { RefabAttachment, RefabFlowLabOrder, TextUploadLinkResult } from '@orthly/dentin';
import { useRefabFlowAction, useRefabFlowSelector } from '@orthly/dentin';
import { SimpleInput, TextWithTooltip } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    banner: {
        background: FlossPalette.PRIMARY_BACKGROUND,
        borderRadius: `16px`,
        marginTop: `8px`,
        padding: `14px`,
    },
    bannerText: {
        display: `flex`,
        marginLeft: `16px`,
        justifyContent: 'center',
    },
    columnsWrapper: {
        margin: `12px 0 0`,
    },
    dandyRecommendsBlock: {
        marginTop: `12px`,
    },
    column: {
        marginBottom: 0,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            '&:first-child': {
                marginLeft: 0,
                paddingLeft: 0,
            },
            '&:last-child': {
                marginRight: 0,
                paddingRight: 0,
            },
        },
        width: `100%`,
    },
    bulletPointsList: {
        display: `flex`,
        flexFlow: `row wrap`,
        columnGap: `50px`,
        paddingLeft: `16px`,
        margin: `4px 0 0`,
    },
}));

const HARDCODED_ADVICE = [
    'Markup the digital waxup with annotated instructions',
    'Upload photos as visual reference',
    'Provide RX instruction with corrective measures',
];

export const RefabRecommendationBanner: React.VFC<{
    additionalInfoRequests: string[];
    fullWidth?: boolean;
}> = ({ additionalInfoRequests, fullWidth }) => {
    const classes = useStyles();
    const bulletPoints = additionalInfoRequests.length > 0 ? additionalInfoRequests : HARDCODED_ADVICE;

    return (
        <Grid container className={classes.banner} xs={12} md={fullWidth ? 12 : 10}>
            <Grid item direction={'column'} className={classes.bannerText}>
                <Text variant={'h6'}>
                    Refabs with more <span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>detail</span> lead to more{' '}
                    <span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>accurate results</span> and create{' '}
                    <span style={{ color: FlossPalette.PRIMARY_FOREGROUND }}>better smiles</span>
                </Text>
                <div>
                    <ul className={classes.bulletPointsList}>
                        {bulletPoints.map(text => (
                            <li key={text}>
                                <Text color={'BLACK'} variant={'body2'}>
                                    {text}
                                </Text>
                            </li>
                        ))}
                    </ul>
                </div>
            </Grid>
        </Grid>
    );
};

const RefabGeneralNotes: React.VFC<{ notes: string | undefined; setNotes: (notes: string | undefined) => void }> = ({
    notes,
    setNotes,
}) => {
    const classes = useStyles();
    return (
        <>
            <SimpleInput
                onChange={setNotes}
                value={notes}
                label={'Add a note'}
                fullWidth
                placeholder={'Comment on what our technicians should keep in mind'}
                TextFieldProps={{
                    multiline: true,
                    rows: 5,
                }}
            />
            <div className={classes.dandyRecommendsBlock}>
                <Text color={'DARK_GRAY'} medium variant={'caption'}>
                    DANDY RECOMMENDS
                </Text>
                <TextWithTooltip
                    text={'Describe issues in detail'}
                    tooltipTitle={'Example'}
                    tooltipSubTitle={'"#6 is bulky and needs to match the contour of #11."'}
                />
                <TextWithTooltip
                    text={'Share corrective measures'}
                    tooltipTitle={'Example'}
                    tooltipSubTitle={
                        '"Contour an ovate pontic to allow for proper tissue healing. Do not fill entire socket."'
                    }
                />
            </div>
        </>
    );
};

export const RefabSupplementalInfoStepOne: React.VFC<{
    order?: RefabFlowLabOrder;
    additionalInfoRequests: string[];
    qrCode?: ImpersonatedQRCodeLinkResult;
    textUploadLink?: TextUploadLinkResult;
    onUploadingChange?: (isUploading: boolean) => void;
}> = ({ order, additionalInfoRequests, qrCode, textUploadLink, onUploadingChange }) => {
    const classes = useStyles();
    const patchState = useRefabFlowAction('PATCH_STATE');
    const notes = useRefabFlowSelector(s => s.supplementaryNotes);

    const setAttachments = React.useCallback(
        (attachments: RefabAttachment[]) => {
            patchState({ attachments });
        },
        [patchState],
    );
    const setNotes = (notes: string | undefined) => {
        patchState({ supplementaryNotes: notes ?? '' });
    };

    if (!order) {
        return null;
    }
    return (
        <>
            <RefabRecommendationBanner additionalInfoRequests={additionalInfoRequests} />
            <Grid container className={classes.columnsWrapper} spacing={4} direction={'row'}>
                <Grid item sm={12} md={4} className={classes.column}>
                    <Text medium variant={'body1'} style={{ marginBottom: 12 }}>
                        Add detailed instructions
                    </Text>
                    <RefabGeneralNotes notes={notes} setNotes={setNotes} />
                </Grid>
                <Grid item md={6} className={classes.column}>
                    <Text medium variant={'body1'} style={{ marginBottom: 12 }}>
                        Attach any files, photos, or x-rays
                    </Text>
                    <RefabFileUploader
                        orderId={order.id}
                        setAttachments={setAttachments}
                        qrCode={qrCode}
                        textUploadLink={textUploadLink}
                        onUploadingChange={onUploadingChange}
                    />
                </Grid>
            </Grid>
        </>
    );
};
