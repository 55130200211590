import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useSession } from '@orthly/session-client';

const PracticeHasBeenSentSignUpEmail_Query = graphql(`
    query PracticeHasBeenSentSignUpEmail($email: String!) {
        practiceHasBeenSentSignUpEmail(email: $email)
    }
`);

export const usePracticeHasBeenSentSignUpEmail = () => {
    const session = useSession();
    const { data, ...rest } = useQuery(PracticeHasBeenSentSignUpEmail_Query, {
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        skip: !session,
        variables: { email: session?.user.email ?? '' },
    });

    return {
        value: data?.practiceHasBeenSentSignUpEmail ?? false,
        ...rest,
    };
};
