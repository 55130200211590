import type { GeometryName, NamedGeometry, NamedRestorativeGeometry, NamedScanGeometry } from './Naming';
import { ScanName } from './Naming';
import { AllToothNumbers } from '@orthly/items';
import { belongsToEnum } from '@orthly/runtime-utils';
import type * as THREE from 'three';

const TOOTH_KEYS = AllToothNumbers.map(n => `${n}`);

export function isNamedRestorativeGeometry(geom: THREE.BufferGeometry): geom is NamedRestorativeGeometry {
    return TOOTH_KEYS.includes(geom.name);
}

export function isNamedScanGeometry(geom: THREE.BufferGeometry): geom is NamedScanGeometry {
    return belongsToEnum(geom.name, ScanName);
}

export function isNamedGeometry<T extends GeometryName>(geom: THREE.BufferGeometry, name: T): geom is NamedGeometry<T> {
    return geom.name === name;
}
