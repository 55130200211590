import { useFeatureFlag } from '../../../LaunchDarkly';
import { PrintableSlipSectionDivider } from '../../../PrintableSlip/components/shared/PrintableSlipSectionDivider';
import { UnboxingSlipHeaderTopHalfText } from './UnboxingSlipHeaderTopHalfText';
import { UnboxingSlipLogo } from './UnboxingSlipLogo';
import { UnboxingSlipOrderInfo } from './UnboxingSlipOrderInfo';
import type { LabsGqlLabOrderForLabSlipFragment } from '@orthly/graphql-operations';
import { Grid, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles({
    slipHeaderSection: {
        alignItems: 'flex-end',
        justifyContent: 'space-between',
    },
});

interface IUnboxingSlipHeaderProps {
    // the order for which the slip is being rendered
    order: LabsGqlLabOrderForLabSlipFragment;
    layout: '4x6' | 'letter';
}

/**
 * Renders the topmost section of the Order Unboxing Slip.
 * Contains Dandy logo, basic order information as the practice and patient names
 * @returns Topmost section of the Order Unboxing Slip
 */
export const UnboxingSlipHeader: React.VFC<IUnboxingSlipHeaderProps> = ({ order, layout }) => {
    const classes = useStyles();
    const { value: hidePracticeDetails = false } = useFeatureFlag('hidePracticeDetailsWherePossible');
    return (
        <>
            <Grid container>
                <Grid container spacing={3} className={classes.slipHeaderSection}>
                    <UnboxingSlipLogo />
                    <UnboxingSlipOrderInfo order={order} />
                </Grid>
                <Grid container>
                    <Grid container spacing={3} className={classes.slipHeaderSection}>
                        {!hidePracticeDetails && (
                            <UnboxingSlipHeaderTopHalfText layout={layout} value={order.doctor_name} label={'Doctor'} />
                        )}
                        <UnboxingSlipHeaderTopHalfText
                            layout={layout}
                            value={`${order.patient.first_name} ${order.patient.last_name}`}
                            label={'Patient'}
                        />
                    </Grid>
                </Grid>
            </Grid>
            <PrintableSlipSectionDivider />
        </>
    );
};
