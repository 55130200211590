import { ToothUtils } from '@orthly/items';
import type { ToothNumber } from '@orthly/items';
import * as THREE from 'three';
import * as xpath from 'xpath';

export class ParseModellingTreeUtils {
    static parseSplineControlPoints(tSysSpline: Node): THREE.Vector3[] {
        const vectorElements = xpath.select('ControlPoints/Object/Vector', tSysSpline) as Element[];
        return vectorElements.map(ParseModellingTreeUtils.parseVector);
    }

    static parseSplineToothNumber(tSysSpline: Node): ToothNumber {
        const iMisc1 = ParseModellingTreeUtils.parseIntegerPropertyValue(
            xpath.select1(`Property[@name='iMisc1']`, tSysSpline) as Element,
        );

        // Unn (range 1-32, inclusive) is stored in the lower 5 bits of iMisc1.
        const unn = ToothUtils.toToothNumber(0x1f & iMisc1);
        if (!unn) {
            throw new Error('Failed to parse tooth number');
        }

        return unn;
    }

    static parseVector(vector: Element): THREE.Vector3 {
        const x = parseFloat(vector.getAttribute('x') || '');
        const y = parseFloat(vector.getAttribute('y') || '');
        const z = parseFloat(vector.getAttribute('z') || '');

        if (isNaN(x) || isNaN(y) || isNaN(z)) {
            throw new Error('Failed to parse vector');
        }

        return new THREE.Vector3(x, y, z);
    }

    static parsePropertyValue(property: Element): string {
        const value = property.getAttribute('value');
        if (value === null) {
            throw new Error('Failed to parse property value');
        }
        return value;
    }

    static parseIntegerPropertyValue(property: Element): number {
        const value = parseInt(ParseModellingTreeUtils.parsePropertyValue(property));
        if (isNaN(value)) {
            throw new Error('Failed to parse integer property value');
        }
        return value;
    }
}
