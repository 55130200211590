import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { CrossSectionMathUtils } from './CrossSectionData';
import type { CrossSectionPlane, CrossSectionPlaneSetter } from './CrossSectionData';
import { CROSS_SECTION_MESH_SIZE, CROSS_SECTION_MESH_ASYMMETRY } from './CrossSectionPlaneMesh';
import React from 'react';
import type { Group } from 'three';
import { Vector3, Quaternion } from 'three';

export enum CrossSectionRotationGizmoPlacement {
    TOP = 'top',
    BOTTOM = 'bottom',
    FRONT = 'front',
    BACK = 'back',
}

const CROSS_SECTION_MINOR_D = CROSS_SECTION_MESH_SIZE * CROSS_SECTION_MESH_ASYMMETRY;

const CS_TOP_GIZMO_POSITION = new Vector3(0, 1, 0).multiplyScalar(CROSS_SECTION_MESH_SIZE);
const CS_BOTTOM_GIZMO_POSITION = new Vector3(0, 1, 0).multiplyScalar(-CROSS_SECTION_MESH_SIZE);
const CS_FRONT_GIZMO_POSITION = new Vector3(1, 0, 0).multiplyScalar(-CROSS_SECTION_MINOR_D);
const CS_BACK_GIZMO_POSITION = new Vector3(1, 0, 0).multiplyScalar(CROSS_SECTION_MINOR_D);

const PLACE_TO_POSITION: { [key in CrossSectionRotationGizmoPlacement]: Vector3 } = {
    [CrossSectionRotationGizmoPlacement.TOP]: CS_TOP_GIZMO_POSITION,
    [CrossSectionRotationGizmoPlacement.BOTTOM]: CS_BOTTOM_GIZMO_POSITION,
    [CrossSectionRotationGizmoPlacement.FRONT]: CS_FRONT_GIZMO_POSITION,
    [CrossSectionRotationGizmoPlacement.BACK]: CS_BACK_GIZMO_POSITION,
};

const BASE_ROTATION_SPEED = 0.1;

export interface CrossSectionRotationControlProps {
    visible: boolean;
    highlighted: boolean;
    onActive?: (active: boolean) => void;
    placement: CrossSectionRotationGizmoPlacement;
    csPlane: CrossSectionPlane;
    setCSPlane: CrossSectionPlaneSetter;
    cameraControlsRef: MainViewCameraControlsRef;
    onHoverChange: (mouseIsHover: boolean) => void;
}

export const CrossSectionRotationControl: React.VFC<CrossSectionRotationControlProps> = props => {
    const { visible, highlighted, csPlane, placement, setCSPlane, cameraControlsRef, onHoverChange, onActive } = props;

    const gizmosRef = React.useRef<Group>();
    const hoverRef = React.useRef<boolean>(false);
    const csPlaneRef = React.useRef<CrossSectionPlane>(csPlane);

    React.useEffect(() => {
        const gizmo = gizmosRef.current;
        if (gizmo) {
            gizmo.visible = visible;
        }
    }, [visible]);

    React.useEffect(() => {
        hoverRef.current = highlighted;
    }, [highlighted]);

    React.useEffect(() => {
        csPlaneRef.current = csPlane;
    }, [csPlane]);

    React.useEffect(() => {
        if (csPlane) {
            const p = PLACE_TO_POSITION[placement].clone().applyQuaternion(csPlane.orientation).add(csPlane.position);

            gizmosRef.current?.position?.copy(p);
        }
    }, [csPlane, placement]);

    // Main effect for this tool
    // registers 3 mouse event handlers
    // This effect should live as long as the component itself.
    // so it's dependencies are passed as refs.
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    const controls = cameraControlsRef.current;
    const canvas = controls?.domElement;

    React.useEffect(() => {
        // Overview of the work:

        // On mouse down if tool is visible and hovered:
        // * save initial position of the mouse
        // * save initial position and orientation of the plane
        // * calculate rotation axis

        // On mouse move:
        // if tool was activated on mousedown
        // (if we have axis and position defined)
        // calculate signed distance traveled by mouse
        // multiply it by rotation speed to have angle
        // apply rotation quaternion to plane

        // On mouse up, reset the tool
        if (controls) {
            let downPosition: { x: number; y: number } | null = null;
            let rotationAxis: Vector3 | null = null;

            let mouseDownPlaneOrientation: Quaternion | null = null;
            let mouseDownPlanePosition: Vector3 | null = null;

            const pointerDown = (evt: MouseEvent) => {
                const mouseIsHover = hoverRef.current;
                const gizmo = gizmosRef.current;
                const currentCSPlane = csPlaneRef.current;

                if (mouseIsHover && gizmo && gizmo.visible && currentCSPlane) {
                    const evtXY = {
                        x: evt.clientX,
                        y: evt.clientY,
                    };

                    mouseDownPlaneOrientation = currentCSPlane.orientation;
                    mouseDownPlanePosition = currentCSPlane.position;

                    const gizmoLocalPosition = gizmo.position.clone().sub(mouseDownPlanePosition);

                    const mouseDownPlaneNormal = CrossSectionMathUtils.getPlaneNormal(currentCSPlane);
                    rotationAxis = gizmoLocalPosition.cross(mouseDownPlaneNormal).normalize();

                    downPosition = hoverRef.current ? evtXY : null;
                    controls.enabled = false;
                    onActive?.(true);
                } else {
                    onActive?.(false);
                }
            };

            const pointerMove = (evt: MouseEvent) => {
                const currentCSPlane = csPlaneRef.current;

                if (downPosition && mouseDownPlaneOrientation && rotationAxis && currentCSPlane) {
                    const dx = evt.clientX - downPosition.x;
                    const dy = evt.clientY - downPosition.y;

                    const dSign = Math.sign(Math.abs(dx) > Math.abs(dy) ? dx : dy);

                    const d = Math.sqrt(dx * dx + dy * dy) * dSign;

                    const angle = (d * BASE_ROTATION_SPEED * Math.PI) / 180.0;

                    const rotQuaternion = new Quaternion().setFromAxisAngle(rotationAxis, angle);
                    const newOrientation = rotQuaternion.multiply(mouseDownPlaneOrientation);

                    setCSPlane({
                        ...currentCSPlane,
                        orientation: newOrientation,
                    });
                }
            };

            const pointerUp = () => {
                if (downPosition) {
                    controls.enabled = true;
                }

                downPosition = null;
                rotationAxis = null;
                mouseDownPlaneOrientation = null;
                mouseDownPlanePosition = null;
                onActive?.(false);
            };

            canvas?.addEventListener('pointerdown', pointerDown);
            canvas?.addEventListener('pointermove', pointerMove);
            canvas?.addEventListener('pointerup', pointerUp);
            canvas?.addEventListener('pointerleave', pointerUp);

            return () => {
                canvas?.removeEventListener('pointerdown', pointerDown);
                canvas?.removeEventListener('pointermove', pointerMove);
                canvas?.removeEventListener('pointerup', pointerUp);
                canvas?.removeEventListener('pointerleave', pointerUp);
            };
        }
    }, [setCSPlane, controls, canvas, csPlaneRef, placement, gizmosRef, onActive]);

    const pointerOver = React.useCallback(() => {
        onHoverChange(visible);
    }, [onHoverChange, visible]);

    const onPointerOut = React.useCallback(() => {
        onHoverChange(false);
    }, [onHoverChange]);

    return (
        <mesh renderOrder={2} visible={visible} ref={gizmosRef} onPointerOver={pointerOver} onPointerOut={onPointerOut}>
            <sphereBufferGeometry attach={'geometry'} args={[1, 16, 16]} />
            <meshBasicMaterial
                depthTest={false}
                depthWrite={false}
                attach={'material'}
                color={highlighted ? '#FF0000' : '#990000'}
            />
        </mesh>
    );
};
