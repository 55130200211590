import { AttributeName } from './BufferAttributeConstants';
import { isIndexedGeometry } from './BufferGeometry.util';
import { generateFacets } from './Mesh3d.util';
import * as THREE from 'three';
import type { MeshBVHOptions } from 'three-mesh-bvh';
import { computeBoundsTree, disposeBoundsTree, acceleratedRaycast, MeshBVH } from 'three-mesh-bvh';

// Apply three-mesh-bvh to THREE
THREE.BufferGeometry.prototype.computeBoundsTree = computeBoundsTree;
THREE.BufferGeometry.prototype.disposeBoundsTree = disposeBoundsTree;
THREE.Mesh.prototype.raycast = acceleratedRaycast;

/*
 * providing an options object will force regeneration of the bounds tree
 * since the options used to create the bounds tree are not accessible
 * on the BVHTree object for comparison. Otherwise this is just used
 * to make sure a BVHTree has been computed for the object
 * */
export function ensureMeshIndex(geometry: THREE.BufferGeometry, options: MeshBVHOptions = {}): MeshBVH {
    let index: MeshBVH;

    if (geometry.boundsTree && Object.keys(options).length === 0) {
        index = geometry.boundsTree;
    } else {
        // create a backup of the original index before MeshBVH overwrites it
        if (!geometry.hasAttribute(AttributeName.BackupIndex)) {
            const backupIndex = geometry.index?.clone();
            if (backupIndex) {
                geometry.setAttribute(AttributeName.BackupIndex, backupIndex);
            }
        }
        geometry.boundsTree = index = new MeshBVH(geometry, { maxLeafTris: options?.maxLeafTris ?? 3, ...options });
    }
    return index;
}

/*
 * A geometry with no vertices or no faces
 * can sometimes be created by crop geometry or
 * other functions.  MeshBVH initializer will fail
 * */
export function isEmptyPositionOrIndex(geometry: THREE.BufferGeometry): boolean {
    const numVertices = geometry.getAttribute(AttributeName.Position).count;
    if (numVertices === 0) {
        return true;
    }
    const index = geometry.getIndex()?.array;
    if (!index) {
        return true;
    }
    return index.length === 0;
}

/**
 * Gets a convenient mapping from new facet indices to pre-BVH facet indices.
 * @param geometry The geometry to get the index map for.
 * @returns An array that, when indexed by a facet index yields the original facet index before the BVH was constructed.
 */
export function getIndexMap(geometry: THREE.BufferGeometry): number[] {
    if (!isIndexedGeometry(geometry)) {
        throw new Error('Index map only valid for indexed geometry.');
    }
    if (geometry.hasAttribute(AttributeName.BackupIndex)) {
        const facetMap = new Map<string, number>();
        const backup = geometry.getAttribute(AttributeName.BackupIndex);
        for (let fIdx = 0; fIdx < backup.count / 3; fIdx += 1) {
            const key = [backup.getX(3 * fIdx), backup.getY(3 * fIdx), backup.getZ(3 * fIdx)].join(',');
            facetMap.set(key, fIdx);
        }

        const result: number[] = [];
        for (const { index, verts } of generateFacets(geometry)) {
            result[index] = facetMap.get(verts.join(',')) ?? -1;
        }

        return result;
    } else {
        return Array.from({ length: geometry.getIndex().count / 3 }).map((_, i) => i);
    }
}
