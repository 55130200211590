import { PracticePortalRouter } from './PracticePortalRouter.graphql';
import { LogoutButton } from './components/LogoutButton';
import { PracticeTopNav } from './components/PracticeTopNav';
import { ZendeskChatUnavailablePanel } from './components/ZendeskChatUnavailablePanel';
import { ChatWindowRoot } from './screens/chat/ChatWindow/ChatWindowRoot';
import { useChatAction } from './screens/chat/chat-state/chat.actions';
import { useChatSelector } from './screens/chat/chat-state/chat.selectors';
import { useZendeskChat } from './screens/chat/useZendeskChat';
import { PracticeInboxProvider } from './screens/inbox/state/PracticeInboxProvider';
import { LabsContextProvider } from './screens/labs/LabsContext';
import { SignUpRoot } from './screens/sign-up/SignUpRoot';
import { CurrentPartnerCtxProvider } from './utils/CurrentPartnerContext';
import { getPartnerId } from './utils/authorization';
import { SessionGuard, SessionGuardUtils, useSession } from '@orthly/session-client';
import { ErrorPage } from '@orthly/ui';
import { stylesFactory, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import { OutageBanner, useFeatureFlag } from '@orthly/veneer';
import { PracticeOutageBanner } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    headerWrapper: {
        position: 'fixed',
        zIndex: theme.zIndex.appBar,
    },
}));

const PartnerAppRootBase: React.VFC = () => {
    const session = useSession();
    const screenIsMedium = useScreenIsMd();
    const outOfOfficeOpen = useChatSelector(({ outOfOfficeOpen }) => outOfOfficeOpen);
    const closeOutOfOffice = useChatAction('CLOSE_OUT_OF_OFFICE');

    useZendeskChat({ autoOpen: false });

    return (
        <SessionGuard
            test={SessionGuardUtils.hasOrgType(['lab', 'practice'])}
            fallback={
                <ErrorPage
                    message={'Unauthorized'}
                    iconName={'PanTool'}
                    text={"You don't have permission to see this."}
                >
                    <LogoutButton />
                </ErrorPage>
            }
        >
            <CurrentPartnerCtxProvider partnerId={getPartnerId(session)}>
                <LabsContextProvider>
                    {/* PracticeInboxProvider is here to ensure sidebar item count is up to date on all screens */}
                    <PracticeInboxProvider>
                        <PracticePortalRouter />
                        <ZendeskChatUnavailablePanel open={outOfOfficeOpen} onClose={closeOutOfOffice} />
                    </PracticeInboxProvider>
                </LabsContextProvider>
                {/* Take out below OutageBanner when we init PracticeOutageBanner on mobile */}
                {screenIsMedium && <OutageBanner audience={'practice'} />}
                <ChatWindowRoot />
            </CurrentPartnerCtxProvider>
        </SessionGuard>
    );
};

const showSignUp = (pathname: string) => {
    return pathname.match(/^\/sign-up\//);
};

export const PartnerAppRoot: React.VFC = () => {
    const classes = useStyles();
    const { value: maintenanceBannerPractice } = useFeatureFlag('maintenanceBannerPractice');

    if (showSignUp(location.pathname)) {
        return <SignUpRoot />;
    }

    return (
        <Grid container>
            <Grid container className={classes.headerWrapper}>
                <PracticeOutageBanner banner={maintenanceBannerPractice?.[0]} />
                <PracticeTopNav />
            </Grid>
            <PartnerAppRootBase />
        </Grid>
    );
};
