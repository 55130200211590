import { useFeatureFlag } from '../LaunchDarkly';
import type { ImplantSystemInputProps } from '@orthly/dentin';
import { ImplantSystemInput } from '@orthly/dentin';
import { useGetImplantTypesLiteQuery, useGetImplantTypesQuery } from '@orthly/graphql-react';
import React from 'react';

interface AutocompleteImplantSystemInputProps
    extends Omit<ImplantSystemInputProps, 'implantSystems' | 'loadingError'> {}

export const AutocompleteImplantSystemInput: React.VFC<AutocompleteImplantSystemInputProps> = props => {
    const useLiteQuery = !!useFeatureFlag('enableGetImplantTypesLite').value;
    const implantTypes = useGetImplantTypesQuery({ variables: { withDeleted: false }, skip: useLiteQuery });
    const implantTypesLite = useGetImplantTypesLiteQuery({
        variables: { withDeleted: false },
        skip: !useLiteQuery,
    });
    const { error } = useLiteQuery ? implantTypesLite : implantTypes;
    const implantTypesData = useLiteQuery
        ? implantTypesLite.data?.getImplantTypesLite
        : implantTypes.data?.getImplantTypes;

    return <ImplantSystemInput {...props} implantSystems={implantTypesData ?? []} loadingError={!!error} />;
};
