export enum SignatureType {
    ZIPFILERECORD = 0x04034b50,
    ZIPDATADESCR = 0x08074b50,
    ZIPDIRENTRY = 0x02014b50,
    ZIPDIGITALSIG = 0x05054b50,
    ZIP64ENDLOCATORRECORD = 0x06064b50,
    ZIP64ENDLOCATOR = 0x07064b50,
    ZIPENDLOCATOR = 0x06054b50,
}
export enum HostOsType {
    FAT = 0,
    AMIGA = 1,
    VMS = 2, // VAX/VMS
    Unix = 3,
    VM_CMS = 4,
    Atari = 5, // what if it's a minix filesystem? [cjh]
    HPFS = 6, // filesystem used by OS/2 (and NT 3.x)
    Mac = 7,
    Z_System = 8,
    CPM = 9,
    TOPS20 = 10, // pkzip 2.50 NTFS
    NTFS = 11, // filesystem used by Windows NT
    QDOS = 12, // SMS/QDOS
    Acorn = 13, // Archimedes Acorn RISC OS
    VFAT = 14, // filesystem used by Windows 95, NT
    MVS = 15,
    BeOS = 16, // hybrid POSIX/database filesystem
    Tandem = 17,
    OS400 = 18,
    OSX = 19,
}
export enum CompressionType {
    STORED = 0,
    SHRUNK = 1,
    REDUCED1 = 2,
    REDUCED2 = 3,
    REDUCED3 = 4,
    REDUCED4 = 5,
    IMPLODED = 6,
    TOKEN = 7,
    DEFLATE = 8, // Deflate - standard ZIP codec, used from the start, also found in regular ZIP files
    DEFLATE64 = 9,
    PKImploding = 10,
    BZip2 = 12, // BZIP2 - Newer than deflate, with better compression and slightly slower speed.
    LZMA = 14, // LZMA - advanced ZIPX codec, taken from open source 7-zip format
    Terse = 18,
    Lz77 = 19,
    Jpeg = 0x60, // Jpeg - Codec added by WinZip for specific support of jpeg images ( http://www.winzip.com/wz_jpg_comp.pdf )
    WavPack = 0x61, // WavPack - Codec for compressing specifically wav files. ( http://www.wavpack.com )
    PPMd = 0x62, // PPMd - context modeling based codec, also featured in new ZIP standard. We use it in our Optimized method for text file compression. ( http://www.compression.ru/ds/ )
    WzAES = 0x63, // WZAES encryption methods
}
export enum Flag {
    Encrypted = 0x0001, // Bit 0: If set, indicates that the file is encrypted.
    CompressionFlagBit1 = 0x0002,
    CompressionFlagBit2 = 0x0004,
    DescriptorUsedMask = 0x0008,
    Reserved1 = 0x0010,
    Reserved2 = 0x0020,
    StrongEncrypted = 0x0040, // Bit 6: Strong encryption
    CurrentlyUnused1 = 0x0080,
    CurrentlyUnused2 = 0x0100,
    CurrentlyUnused3 = 0x0200,
    CurrentlyUnused4 = 0x0400,
    Utf8 = 0x0800, // Bit 11: filename and comment encoded using UTF-8
    ReservedPKWARE1 = 0x1000,
    CDEncrypted = 0x2000, // Bit 13: Used when encrypting the Central Directory to indicate selected data values in the Local Header are masked to hide their actual values.
    ReservedPKWARE2 = 0x4000,
    ReservedPKWARE3 = 0x8000,
}
export enum ExtensionId {
    Zip64 = 0x0001, // Zip64 extended information extra field
    AVInfo = 0x0007, // AV Info
    ExtLanguage = 0x0008, // Reserved for extended language encoding data (PFS)
    OS2 = 0x0009, // OS/2
    NTFS = 0x000a, // NTFS
    OpenVMS = 0x000c, // OpenVMS
    UNIX = 0x000d, // UNIX
    fileStream = 0x000e, // Reserved for file stream and fork descriptors
    PatchDescriptor = 0x000f, // Patch Descriptor
    PKCS7X509 = 0x0014, // PKCS#7 Store for X.509 Certificates
    X509IDSignature = 0x0015, // X.509 Certificate ID and Signature for individual file
    X509IDCD = 0x0016, // X.509 Certificate ID for Central Directory
    StrongEncryption = 0x0017, // Strong Encryption Header
    RecordManagement = 0x0018, // Record Management Controls
    PKCS7List = 0x0019, // PKCS#7 Encryption Recipient Certificate List
    Attributes = 0x0065, // IBM S/390 (Z390), AS/400 (I400) attributes uncompressed
    ReservedAttributes = 0x0066, // Reserved for IBM S/390 (Z390), AS/400 (I400) attributes - compressed
    POSZIP4690 = 0x4690, // POSZIP 4690 (reserved)
    Mac = 0x07c8, // Macintosh
    ZipItMac1 = 0x2605, // ZipIt Macintosh
    ZipItMac2 = 0x2705, // ZipIt Macintosh 1.3.5+
    ZipItMac3 = 0x2805, // ZipIt Macintosh 1.3.5+
    InfoZIPMac = 0x334d, // Info-ZIP Macintosh
    Acorn = 0x4341, // Acorn/SparkFS
    WinNTSecurity = 0x4453, // Windows NT security descriptor (binary ACL)
    VM_CMS = 0x4704, // VM/CMS
    MVS = 0x470f, // MVS
    FWKCS = 0x4b46, // FWKCS MD5 (see below)
    OS2AccessList = 0x4c41, // OS/2 access control list (text ACL)
    InfoZIPOpenVMS = 0x4d49, // Info-ZIP OpenVMS
    Xceed = 0x4f4c, // Xceed original location extra field
    AOSVS = 0x5356, // AOS/VS (ACL)
    extTimestamp = 0x5455, // extended timestamp
    XceedUnicode = 0x554e, // Xceed unicode extra field
    InfoZIPUNIX = 0x5855, // Info-ZIP UNIX (original, also OS/2, NT, etc)
    InfoZIPUnicodeComment = 0x6375, // Info-ZIP Unicode Comment Extra Field
    BeOS = 0x6542, // BeOS/BeBox
    InfoZIPUnicodePath = 0x7075, // Info-ZIP Unicode Path Extra Field
    ASiUNIX = 0x756e, // ASi UNIX
    InfoZIPUNIXNew = 0x7855, // Info-ZIP UNIX (16-bit UID/GID info)
    InfoZIPUNIXNew3rd = 0x7875, // Info-ZIP UNIX 3rd generation (generic UID/GID, ...)
    WinGrowth = 0xa220, // Microsoft Open Packaging Growth Hint
    SMSQDOS = 0xfd4a, // SMS/QDOS
    WzAES = 0x9901, //
}

export enum AlgoId {
    DES = 0x6601, //- DES
    RC2OLD = 0x6602, //- RC2 (version needed to extract < 5.2)
    TDES168 = 0x6603, //- 3DES 168
    TDES112 = 0x6609, //- 3DES 112
    AES128 = 0x660e, //- AES 128
    AES192 = 0x660f, //- AES 192
    AES256 = 0x6610, //- AES 256
    RC2 = 0x6702, //- RC2 (version needed to extract >= 5.2)
    Blowfish = 0x6720, //- Blowfish
    Twofish = 0x6721, //- Twofish
    RC4 = 0x6801, //- RC4
    Unknown = 0xffff, //- Unknown algorithm
}

export enum AesMode {
    AES128 = 0x01, // 128-bit encryption key
    AES192 = 0x02, // 192-bit encryption key
    AES256 = 0x03, // 256-bit encryption key
}

export enum PfKind {
    PfPassword = 0x0001, //- Password is required to decrypt
    PfCertificates = 0x0002, //- Certificates only
    PfPasswordCertificates = 0x0003, //- Password or certificate required to decrypt
}
