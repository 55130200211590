import { AttributeName, ATTRIBUTE_MAP_INVALID_VALUE } from '../Utils3D/BufferAttributeConstants';
import { logger } from '../Utils/Logger';
import type { AdjacencyMatrix, MeshConnectivityGraph } from './MeshConnectivityGraph';
import { findIslands } from './MeshConnectivityGraph.util';
import { remapOcclusalDistance } from './Occlusion.util';
import _ from 'lodash';
import * as THREE from 'three';

type SubgeometryData = MeshConnectivityGraph & {
    // The subgeometry
    geometry: THREE.BufferGeometry;
    // Map from vertex index in the whole geometry (input) to vertex index in the subgeometry (output)
    vertexMap: Map<number, number>;
    // Map from face index in the whole geometry (input) to face index in the subgeometry (output)
    faceMap: Map<number, number>;
};

type SubgeometryOptions = {
    // A list of additional attributes to copy to the subgeometry. If not specified, all attributes will be copied. If
    // specified, the positions and normals will automatically be appended and thus copied.
    attributeNames?: string[];

    // If specified, only the face relationships in this set will be copied to the subgeometry. Careless use of this
    // option can result in the subgeometry containing vertices that do not belong to any faces.
    keepFaces?: Set<number>;
};

/**
 * Extracts a subset of the geometry into a new BufferGeometry object.
 *
 * By default, all attributes on the geometry are copied to the subgeometry. A face is included in the subgeometry only
 * if all three of its vertices are included in the subgeometry. No checks are made on whether the subgeometry is fully
 * connected (i.e. has no islands).
 *
 * @param geometry The object from which to extract the subgeometry
 * @param vertices The indices of the vertices of `geometry` to extract
 * @param graph Specifies the vertex and face connectivity in `geometry`
 * @param opts Additional options
 * @returns The subgeometry and associated metadata
 */
export function getSubgeometry(
    geometry: THREE.BufferGeometry,
    vertices: number[],
    graph: MeshConnectivityGraph,
    opts: SubgeometryOptions = {},
): SubgeometryData | undefined {
    // Lookup from vertex index in the original geometry to vertex index in the new subgeometry. `vertices` is the
    // inverse map (lookup from new vertex index to original vertex index).
    const vertexMap = new Map<number, number>();
    vertices.forEach((oldIndex: number, newIndex: number) => vertexMap.set(oldIndex, newIndex));

    const nVertices = vertices.length;
    if (vertexMap.size !== nVertices) {
        // This indicates that duplicate vertices were specified in `vertices`.
        return;
    }

    const oldGeometryIndex = geometry.getIndex()?.array;
    if (!oldGeometryIndex) {
        return;
    }

    const oldV2V = graph.adjacencyVertexToVertex;
    const oldV2F = graph.adjacencyFacesToVertex;

    const attributeNameToSize = getAttributeNameToSize(geometry, opts.attributeNames);
    const subgeometry = createEmptyClone(geometry, Object.keys(attributeNameToSize), nVertices);
    const subgeometryIndex: number[] = [];

    // Checks if the vertex from the whole geometry is included in the subgeometry.
    const isVertexIncluded = (oldVertex: number): boolean => vertexMap.has(oldVertex);
    // Gets the subgeometry vertex index corresponding to the whole geometry vertex index.
    const oldVertexToNewVertex = (oldVertex: number): number => vertexMap.get(oldVertex) as number;

    // Vertex to vertex connections in the subgeometry
    const v2V: AdjacencyMatrix = [];
    // Vertex to face connections in the subgeometry
    const v2F: AdjacencyMatrix = [];

    // Lookup from face index in the whole geometry to face index in the subgeometry
    const faceMap: Map<number, number> = new Map();
    // Indices of faces in the whole geometry that will not be included in the subgeometry
    const excludedFaces: Set<number> = new Set();

    vertices.forEach((oldVertex: number, newVertex: number) => {
        for (const [name, itemSize] of Object.entries(attributeNameToSize)) {
            copyVertexAttribute(name, itemSize, geometry, oldVertex, subgeometry, newVertex);
        }

        v2V[newVertex] = oldV2V[oldVertex]?.filter(isVertexIncluded).map(oldVertexToNewVertex) ?? [];

        v2F[newVertex] = getNewNeighborFaces(
            oldV2F[oldVertex] ?? [],
            faceMap,
            opts.keepFaces,
            excludedFaces,
            oldGeometryIndex,
            subgeometryIndex,
            isVertexIncluded,
            oldVertexToNewVertex,
        );
    });

    subgeometry.setIndex(subgeometryIndex);

    remapOcclusalDistance(geometry, subgeometry);

    return {
        geometry: subgeometry,
        vertexMap: vertexMap,
        faceMap: faceMap,
        adjacencyVertexToVertex: v2V,
        adjacencyFacesToVertex: v2F,
    };
}

/**
 * Gets the names of the attributes to copy.
 *
 * If no attribute names are supplied, all attributes will be copied. Requested attributes that do not exist on the
 * source geometry will be ignored.
 */
function getAttributeNameToSize(geometry: THREE.BufferGeometry, attributeNames?: string[]): Record<string, number> {
    const attributesToCopy = attributeNames
        ? [...attributeNames, AttributeName.Position, AttributeName.Normal]
        : [...Object.keys(geometry.attributes)];

    const nameToSize: Record<string, number> = {};
    for (const name of attributesToCopy) {
        const attribute = geometry.getAttribute(name);
        if (attribute) {
            nameToSize[name] = attribute.itemSize;
        }
    }

    return nameToSize;
}

/**
 * Creates an empty clone of the geometry, with the requested attributes sized to hold the requested number of vertices.
 */
function createEmptyClone(
    geometry: THREE.BufferGeometry,
    attributeNames: string[],
    numVertices: number,
): THREE.BufferGeometry {
    const newGeometry = new THREE.BufferGeometry();

    for (const name of attributeNames) {
        const attribute = geometry.getAttribute(name);
        const itemSize = attribute.itemSize;
        const bufferView = new (attribute.array.constructor as any)(numVertices * itemSize);
        newGeometry.setAttribute(name, new THREE.BufferAttribute(bufferView, itemSize));
    }

    return newGeometry;
}

/**
 * Copies the value of the requested attribute from the source geometry to the destination geometry, at the specified
 * vertex indices.
 */
function copyVertexAttribute(
    name: string,
    itemSize: number,
    srcGeometry: THREE.BufferGeometry,
    srcIndex: number,
    dstGeometry: THREE.BufferGeometry,
    dstIndex: number,
) {
    const srcAttribute = srcGeometry.getAttribute(name);
    const dstAttribute = dstGeometry.getAttribute(name);
    if (!(srcAttribute && dstAttribute)) {
        return;
    }

    if (itemSize > 3) {
        dstAttribute.setW(dstIndex, srcAttribute.getW(srcIndex));
    }
    if (itemSize > 2) {
        dstAttribute.setZ(dstIndex, srcAttribute.getZ(srcIndex));
    }
    if (itemSize > 1) {
        dstAttribute.setY(dstIndex, srcAttribute.getY(srcIndex));
    }
    if (itemSize > 0) {
        dstAttribute.setX(dstIndex, srcAttribute.getX(srcIndex));
    }
    if (itemSize > 4 || itemSize < 1) {
        logger.warn(`Got attribute with unexpected item size: ${itemSize}`);
    }
}

/**
 * Helper for `getSubgeometry` that copies face relationships that should be included in the subgeometry.
 */
function getNewNeighborFaces(
    neighborFaces: number[],
    faceMap: Map<number, number>,
    keepFaces: Set<number> | undefined,
    excludedFaces: Set<number>,
    geometryIndex: ArrayLike<number>,
    subgeometryIndex: number[],
    isVertexIncluded: (v: number) => boolean,
    oldVertexToNewVertex: (v: number) => number,
): number[] {
    const newNeighborFaces: number[] = [];
    for (const oldFaceIndex of neighborFaces) {
        if (keepFaces && !keepFaces.has(oldFaceIndex)) {
            // This face was not requested to be included in the subgeometry.
            continue;
        }

        if (excludedFaces.has(oldFaceIndex)) {
            // This face has already been processed and is not included in the subgeometry.
            continue;
        }

        let newFaceIndex = faceMap.get(oldFaceIndex);
        if (newFaceIndex !== undefined) {
            // This face has already been processed and is included in the subgeometry.
            newNeighborFaces.push(newFaceIndex);
            continue;
        }

        const oldTriangleVertices = [
            geometryIndex[oldFaceIndex * 3] as number,
            geometryIndex[oldFaceIndex * 3 + 1] as number,
            geometryIndex[oldFaceIndex * 3 + 2] as number,
        ];

        if (!_.every(oldTriangleVertices, isVertexIncluded)) {
            // A face should only be included if all of its vertices are included.
            excludedFaces.add(oldFaceIndex);
            continue;
        }

        // Add the face relationship to the subgeometry.
        newFaceIndex = faceMap.size;
        newNeighborFaces.push(newFaceIndex);
        faceMap.set(oldFaceIndex, newFaceIndex);

        oldTriangleVertices.forEach(i => subgeometryIndex.push(oldVertexToNewVertex(i)));
    }

    return newNeighborFaces;
}

/**
 * Extracts a subset of the geometry into a new BufferGeometry object.
 *
 * By default, all attributes on the geometry are copied to the subgeometry. No checks are made on whether the
 * subgeometry is fully connected (i.e. has no islands).
 *
 * @param geometry The object from which to extract the subgeometry
 * @param faces The indices of the faces of `geometry` to extract
 * @param graph Specifies the vertex and face connectivity in `geometry`
 * @param attributeNames A list of additional attributes to copy to the subgeometry. If not specified, all attributes
 *   will be copied. If specified, the positions and normals will automatically be appended and thus copied.
 * @returns The subgeometry and associated metadata
 */
export function getSubgeometryFromFaces(
    geometry: THREE.BufferGeometry,
    faces: Set<number>,
    graph: MeshConnectivityGraph,
    attributeNames?: string[],
): SubgeometryData | undefined {
    const meshIndex = geometry.getIndex();
    if (!meshIndex) {
        return;
    }

    const numFaces = meshIndex.count / 3;
    const vertexIndices = new Set<number>();
    for (const faceIndex of faces) {
        if (faceIndex >= numFaces) {
            return;
        }

        for (let i = 0; i < 3; ++i) {
            vertexIndices.add(meshIndex.getX(faceIndex * 3 + i));
        }
    }

    return getSubgeometry(geometry, Array.from(vertexIndices), graph, { attributeNames, keepFaces: faces });
}

/**
 * Extracts the intaglio surface into a new BufferGeometry object.
 * @param geometry The geometry from which to extract the intaglio
 * @param graph Specifies the vertex and face connectivity in `geometry`
 * @param attributeNames A list of additional 1-sized attributes to copy to the subgeometry
 * @returns The intaglio surface geometry and associated metadata
 */
export function getIntaglioGeometry(
    geometry: THREE.BufferGeometry,
    graph: MeshConnectivityGraph,
    attributeNames: string[] = [],
): SubgeometryData | undefined {
    const isIntaglio = geometry.getAttribute(AttributeName.IsIntaglio);
    if (!isIntaglio) {
        return;
    }

    const intaglioVertices: number[] = [];
    for (let i = 0; i < isIntaglio.count; ++i) {
        isIntaglio.getX(i) && intaglioVertices.push(i);
    }

    return getSubgeometry(geometry, intaglioVertices, graph, { attributeNames });
}

/**
 * Extracts the part of the surface that has been modified into a new BufferGeometry object.
 * @param geometry The geometry from which to extract the modified part
 * @param graph Specifies the vertex and face connectivity in `geometry`
 * @param attributeNames A list of additional 1-sized attributes to copy to the subgeometry
 * @returns The extracted surface geometry and associated metadata
 */
export function getModifiedGeometry(
    geometry: THREE.BufferGeometry,
    graph: MeshConnectivityGraph,
    attributeNames: string[] = [],
): SubgeometryData | undefined {
    const displacements = geometry.getAttribute(AttributeName.SurfaceDisplacement);
    if (!displacements) {
        return;
    }
    const modifiedVertices: number[] = [];

    const numVertices = displacements.count;
    for (let i = 0; i < numVertices; ++i) {
        const displacement = displacements.getX(i);
        if (displacement === ATTRIBUTE_MAP_INVALID_VALUE) {
            continue;
        }

        modifiedVertices.push(i);
    }

    return getSubgeometry(geometry, modifiedVertices, graph, { attributeNames });
}

/**
 * Splits a Buffer geometry into topological islands in new BufferGeometry objects.
 * @param geometry The geometry to separate
 * @param graph Specifies the vertex and face connectivity in `geometry`
 * @param attributeNames Specifies attributes that we want to preserve across the split if any
 * @returns The extracted array of geometries and metadata or undefined if getSubgeometry fails
 * or if the length of islands does not necessitate splitting
 */
export function splitGeometryIntoIslands(
    geometry: THREE.BufferGeometry,
    graph: MeshConnectivityGraph,
    attributeNames: string[] = [],
): SubgeometryData[] | undefined {
    // Find islands in the geometry
    const islands = findIslands(graph.adjacencyVertexToVertex);

    if (islands.length < 2) {
        return undefined;
    }
    // create subgeometires for each one
    const subGeoms: SubgeometryData[] = [];
    let success = true;
    for (const isl of islands) {
        // get faces of the island
        const faces: Set<number> = new Set();
        const facesArray = isl.flatMap(vertex => graph.adjacencyFacesToVertex[vertex]);
        facesArray.forEach(face => {
            if (face !== undefined) {
                faces.add(face);
            }
        });
        const subGeom = getSubgeometryFromFaces(geometry, faces, graph, attributeNames);
        if (!subGeom) {
            success = false;
            break;
        }
        subGeoms.push(subGeom);
    }

    // this util expects to return all islands or nothing at all
    // things like loose edges or verts are not expected to be handled
    if (!success) {
        return undefined;
    }

    return subGeoms;
}
