import { DandyRecommendedIcon } from '@orthly/ui';
import { Text, stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    recommendedHeader: {
        backgroundColor: FlossPalette.STAR_GRASS,
        color: FlossPalette.WHITE,
        fontSize: 12,
        padding: '4px 0 4px 24px',
        width: '100%',
        borderRadius: '4px 4px 0 0',
        marginLeft: 2,
        alignItems: 'center',
    },
    recommendedText: {
        paddingLeft: 8,
    },
    recommendedIconWrapper: {
        display: 'flex',
        alignItems: 'center',
    },
}));

interface RecommendationHeaderProps {
    styleOverrides?: React.CSSProperties;
    customText?: string;
}

export const RecommendationHeader: React.VFC<RecommendationHeaderProps> = ({ styleOverrides, customText }) => {
    const classes = useStyles();

    return (
        <Grid container direction={'row'} className={classes.recommendedHeader} style={styleOverrides}>
            <Grid item className={classes.recommendedIconWrapper}>
                <DandyRecommendedIcon />
            </Grid>
            <Grid item>
                <Text variant={'caption'} color={'WHITE'} medium className={classes.recommendedText}>
                    {customText ?? 'Recommended'}
                </Text>
            </Grid>
        </Grid>
    );
};
