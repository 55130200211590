import type { Direction } from './Adjustment.types';
import * as THREE from 'three';

const X_AXIS = new THREE.Vector3(1, 0, 0);
const Y_AXIS = new THREE.Vector3(0, 1, 0);

/**
 * In the insertion axis frame, the x-axis is the insertion axis direction. The axes about which to rotate the insertion
 * axis are therefore all in the y-z plane. They are created by rotating the y-axis about the x-axis by varying angles.
 * Although we would typically create a clockwise sequence to match the direction sequence (North, NorthEast, East,
 * etc.) by negatively incrementing the rotation angle, we need to increment by a positive angle here because the user
 * is usually looking along the insertion axis (i.e. the insertion axis is going into the screen, rather than out of
 * it).
 */
const DIRECTION_TO_ROTATION_AXIS: { [key in Direction]: THREE.Vector3 } = {
    North: Y_AXIS.clone(),
    NorthEast: Y_AXIS.clone().applyAxisAngle(X_AXIS, 0.25 * Math.PI),
    East: Y_AXIS.clone().applyAxisAngle(X_AXIS, 0.5 * Math.PI),
    SouthEast: Y_AXIS.clone().applyAxisAngle(X_AXIS, 0.75 * Math.PI),
    South: Y_AXIS.clone().applyAxisAngle(X_AXIS, Math.PI),
    SouthWest: Y_AXIS.clone().applyAxisAngle(X_AXIS, 1.25 * Math.PI),
    West: Y_AXIS.clone().applyAxisAngle(X_AXIS, 1.5 * Math.PI),
    NorthWest: Y_AXIS.clone().applyAxisAngle(X_AXIS, 1.75 * Math.PI),
};

function directionToRelativeAxis(dir: Direction, q: THREE.Quaternion): THREE.Vector3 {
    return DIRECTION_TO_ROTATION_AXIS[dir].clone().applyQuaternion(q);
}

export function directionToCameraRelativeAxis(
    dir: Direction,
    qAxis: THREE.Quaternion,
    qCam: THREE.Quaternion,
    parentFrame?: THREE.Matrix4,
): THREE.Vector3 {
    // The desired behavior of the buttons for this method is...complicated. Things are fairly
    // straight-forward if the camera is looking down the tail of the insertion axis, N moves the
    // tail upward on the screen, E to the right, S down, W to the left. However, when viewing the
    // insertion axis from the side, N should move the tail into or out of the screen, and E should
    // rotate the axis clockwise.

    // To summarize:
    // Looking down tail:
    //   N/S -> rotate around -x/+x in camera frame
    //   E/W -> rotate around +y/-y in camera frame
    // Looking from side:
    //   N/S -> rotate around some axis in camera xy plane
    //   E/W -> rotate around -z/+z in camera frame

    // We want the rotation axes to always be in the frame of reference of the insertion axis, so that
    // the magnitude of each nudge is always the same. However, there's an additional degree of freedom
    // that we can control, which is the rotation of this frame about the insertion axis itself. To
    // achieve more intuitive behavior, we want to try and align our local rotation frame with the view
    // direction of the camera.

    // axisOrientation represents some coordinate frame where +x is aligned with the insertion axis,
    // i.e. it transforms vectors from a PoI-relative frame to parent frame. For tail-on views we
    // want to add another rotation about the +x axis to align our local +y with the camera's -x as
    // much as we can. For side-on views, we want to pick that rotation to align the local +z with
    // the camera's -z.

    // qParent goes from parent local to world
    // qCam goes from camera local to world
    // qAxis goes from axis local to parent, which means qParent * qAxis goes from axis local to world
    // To get from camera local to axis local we can use conj(qParent * qAxis) * qCam

    const qParent = new THREE.Quaternion(0, 0, 0, 1);
    if (parentFrame) {
        qParent.setFromRotationMatrix(parentFrame);
    }
    const q = qParent.multiply(qAxis).conjugate().multiply(qCam);
    const { w, x, y, z } = q;

    const phiTail = Math.atan2(-2 * (x * z - w * y), -2 * (x * y + w * z));
    const phiSide = Math.atan2(2 * (y * z - w * x), 2 * x * x + 2 * y * y - 1);

    const ease = (t: number) => (3 - 2 * t) * t * t;
    // Compute an ease curve based on how "side-on" vs "tail-on" the view is using the x coordinate
    // of the camera z axis in the local insertion frame. 0 is side-on, 1 is tail/head-on.
    const t = ease(Math.abs(2 * (w * y + x * z)));

    const phi = t * phiTail + (1 - t) * phiSide;

    q.set(Math.sin(phi / 2), 0, 0, Math.cos(phi / 2)).premultiply(qAxis);

    return directionToRelativeAxis(dir, q);
}
