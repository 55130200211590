import { useLegacyTokenExchanger } from '../utils/useLegacyToken';
import { RetainerTokenProvider, useRetainerToken } from './RetainerTokenProvider';
import { StaffMemberLoginProvider } from './StaffMemberLoginProvider';
import { StaffMemberSessionProvider } from './StaffMemberSessionProvider';
import type { IOrganizationType } from '@orthly/retainer-common';
import React from 'react';

interface RetainerSessionProviderProps {
    children: React.ReactNode;
    allowImpersonate?: boolean;
    showNotification: (message: string, error: boolean) => void;
    // Fallback for when we are exchanging legacy tokens, either from old sessions stored locally or impersonate tokens
    TokenExchangeLoadingFallback?: React.ComponentType;
    // The organization types the user should be allowed to select when logging in. An error will be shown if the login
    // response includes no organizations with these types
    allowedOrgTypes: IOrganizationType[];
}

const RetainerSessionProviderInternal: React.FC<RetainerSessionProviderProps> = props => {
    const { allowImpersonate, showNotification, TokenExchangeLoadingFallback, allowedOrgTypes } = props;
    const { tokenExchangeLoading, loadSessionFromLegacyToken, legacyNativeIdToken } = useLegacyTokenExchanger(
        !!allowImpersonate,
        showNotification,
    );
    const { retainerToken } = useRetainerToken();
    // Only show the loading indicator if there was a legacy native id token found initially
    if (tokenExchangeLoading && legacyNativeIdToken && !retainerToken) {
        return TokenExchangeLoadingFallback ? <TokenExchangeLoadingFallback /> : null;
    }
    return (
        <StaffMemberSessionProvider>
            <StaffMemberLoginProvider
                allowedOrgTypes={allowedOrgTypes}
                tokenExchangeLoading={tokenExchangeLoading}
                loadSessionFromLegacyToken={loadSessionFromLegacyToken}
            >
                {props.children}
            </StaffMemberLoginProvider>
        </StaffMemberSessionProvider>
    );
};

export const RetainerSessionProvider: React.FC<RetainerSessionProviderProps> = props => {
    return (
        <RetainerTokenProvider>
            <RetainerSessionProviderInternal {...props}>{props.children}</RetainerSessionProviderInternal>
        </RetainerTokenProvider>
    );
};
