import type { ModelPayload } from '@orthly/dentin';
import React from 'react';

// Contains the heavier parts of the model payload: The model and colorMap.
// From this data, the rest of the payload can be filled in synchronously.
export type CacheableModelPayload = Pick<ModelPayload, 'model' | 'path' | 'type' | 'colorMap'>;

interface Caches {
    modelAssetCache: Record<string, CacheableModelPayload>;
}

export const ModelAssetCacheContext = React.createContext<Caches | undefined>(undefined);

/** Convenience wrapper to provide a configured ModelAssetCacheContext.  */
export const ModelAssetCacheProvider: React.VFC<{ children?: React.ReactNode }> = ({ children }) => {
    const [caches] = React.useState({
        modelAssetCache: {},
    });
    return <ModelAssetCacheContext.Provider value={caches}>{children}</ModelAssetCacheContext.Provider>;
};
