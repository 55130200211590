import type { QcHeatmapOption, QcHeatmapRange } from './ColorRamp.types';
import {
    DEFAULT_MODEL_RGB_U8_COLOR,
    SIMPLE_COLOR_MAP_KEYS,
    SimpleColorMaps,
    type SimpleColorMapFn,
} from './colorMappingFunctions';
import type { ThreeBufferAttribute } from '@orthly/forceps';
import * as THREE from 'three';

type Color = { r: number; g: number; b: number };

type RampDirection = 'horizontal' | 'vertical';

const COLOR_RAMP_RESOLUTION = 256 as const;

type RenderColorRampOpts = { direction: RampDirection };

const DEFAULT_RENDER_OPTS: RenderColorRampOpts = {
    direction: 'vertical',
};

const BYTES_PER_PIXEL = 4;

export function renderSimpleColorRamp(
    imageData: Uint8ClampedArray,
    width: number,
    height: number,
    colorMap: (t: number) => Color,
    opts?: RenderColorRampOpts,
) {
    const direction = opts?.direction ?? DEFAULT_RENDER_OPTS.direction;
    const [iMax, jMax] = direction === 'vertical' ? [height, width] : [width, height];
    const getPixelIdx =
        direction === 'vertical' ? (i: number, j: number) => j + width * i : (i: number, j: number) => i + width * j;
    for (let i = 0; i < iMax; i += 1) {
        const t = i / (iMax - 1);
        const { r, g, b } = colorMap(t);
        for (let j = 0; j < jMax; j += 1) {
            const idx = getPixelIdx(i, j) * BYTES_PER_PIXEL;
            imageData.set([r, g, b, 255], idx);
        }
    }
}

export function createSimpleColorRampImage(colorMap: SimpleColorMapFn) {
    const width = COLOR_RAMP_RESOLUTION;
    const height = 1;
    const data = new Uint8ClampedArray(width * height * BYTES_PER_PIXEL);
    renderSimpleColorRamp(data, width, height, colorMap, { direction: 'horizontal' });
    return createImageBitmap(new ImageData(data, width, height));
}

export type ColorRampData = {
    [K in keyof typeof SimpleColorMaps]: {
        image: ImageBitmap;
        texture: THREE.Texture;
    };
};

export async function generateColorRamps(): Promise<ColorRampData> {
    const state = {} as ColorRampData;
    const images = await Promise.all(
        SIMPLE_COLOR_MAP_KEYS.map(async key => [key, await createSimpleColorRampImage(SimpleColorMaps[key])] as const),
    );
    images.forEach(([key, image]) => {
        // Our version of Three has overly restrictive type definitions for Texture and CanvasTexture
        const texture = new THREE.CanvasTexture(
            image as any,
            THREE.UVMapping,
            THREE.ClampToEdgeWrapping,
            THREE.ClampToEdgeWrapping,
        );
        state[key] = { image, texture };
    });
    return state;
}

export function getLabelFormatter(range: QcHeatmapRange, points: number[]) {
    const { min, max } = range;
    const dv = (max - min) / points.length;
    let precision = Math.ceil(Math.abs(Math.log10(dv)));

    const labels = points.map(v => v.toFixed(precision));

    // precision calculation might give tailing zeros for values
    // because of rounding errors.
    // check if all of the labels have tailing zeros, decrease precision
    const tailsAreZero = labels.map(t => t.substring(t.length - 1)).every(s => s === '0');
    const headsAreZero = labels.map(t => t.substring(-1)).every(s => s === '0');

    if (tailsAreZero) {
        precision--;
    }

    // if we have high precision, lot's of digits after decimal point
    // hide leading zero to save space in the UI
    const noLeadingZero = precision >= 3 && headsAreZero;

    return noLeadingZero
        ? (value: number) => `${value.toFixed(precision)}mm`.substring(1)
        : (value: number) => `${value.toFixed(precision)}mm`;
}

export function qcDynamicHeatMapColorForModel(
    geometry: THREE.BufferGeometry,
    heatMap: QcHeatmapOption,
    heatMapRange: QcHeatmapRange,
): ThreeBufferAttribute | null {
    const rawDataAttr = heatMap.qcDynamicHeatmapLayer(geometry);
    if (!rawDataAttr) {
        return null;
    }

    const colors = [];

    for (const v of Array.from(rawDataAttr.array)) {
        const outOfRange = v > 100 || v < -100;
        const col = isNaN(v) || outOfRange ? undefined : heatMap.fn(heatMapRange.min, heatMapRange.max, v);

        if (col && !isNaN(col?.r) && !isNaN(col?.g) && !isNaN(col?.b)) {
            const r = col.r / 255;
            const g = col.g / 255;
            const b = col.b / 255;

            colors.push(r, g, b);
        } else {
            const { r, g, b } = DEFAULT_MODEL_RGB_U8_COLOR;

            colors.push(r, g, b);
        }
    }

    return new THREE.Float32BufferAttribute(colors, 3);
}
