export const DEFAULT_TRANSPARENT_OPACITY = 0.5;

export function isTransparent({ opacity }: { opacity: number }) {
    return opacity < 1;
}

export function getToggledOpacity(
    appearance: { opacity: number },
    transparentOpacity: number = DEFAULT_TRANSPARENT_OPACITY,
) {
    return isTransparent(appearance) ? 1 : transparentOpacity;
}
