import { FileSelectList } from './FileSelectList';
import { convertImageFileToBase64 } from './FileUploader.utils';
import { FileUploaderDropzone } from './FileUploaderDropzone';
import { FileUploaderLayout, FileUploaderSubmitButton } from './FileUploaderLayout';
import { FileUploaderSingleFileField } from './FileUploaderSingleField';
import { buildFBUploadPath } from './FirebaseUpload.utils';
import { FirebaseUploadFileList } from './FirebaseUploadFileList';
import type {
    UploadFirebaseFn,
    FileUploaderProps,
    FileUploaderFieldResult,
    FileUploaderInst,
    FilesState,
} from './file-uploader-types';
import { useFirebaseMultiFileUpload } from './useFirebaseUpload';
import * as Sentry from '@sentry/react';
import React from 'react';

export async function getFileBlob(file: File) {
    const isIphone =
        typeof window !== 'undefined' &&
        (window.navigator.userAgent.match(/iPad/i) || window.navigator.userAgent.match(/iPhone/i));
    if (!file.type.includes('image') || !isIphone) {
        return file.slice();
    }
    const base64String = await convertImageFileToBase64(file);
    // convert back to blob
    return await (await fetch(base64String)).blob();
}

// All da brains behind this guy
function useFileUploaderManager<K extends string = string>(props: FileUploaderProps<K>) {
    const { fileFields, onUploadStart, onComplete, prependTimestampToFilename, storagePathConfig, onSetCanUpload } =
        props;
    const [selectionState, setSelectionState] = React.useState<FilesState<K>>({} as any);
    const [loading, setLoading] = React.useState(false);
    const [inputFiles, setInputFiles] = React.useState<File[]>([]);
    const [upload, { progress }] = useFirebaseMultiFileUpload<K>(storagePathConfig, fileFields);
    const onSubmit: UploadFirebaseFn<K> = React.useCallback(async () => {
        try {
            onUploadStart && onUploadStart();
            setLoading(true);
            const promises = Object.entries(selectionState).map<Promise<FileUploaderFieldResult<K>>>(
                async ([fieldKey, fileName]) => {
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const fileField = fileFields.find(f => f.fieldKey === fieldKey)!;
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    const file = inputFiles.find(f => f.name === fileName)!;
                    const uploadPath = buildFBUploadPath(fileName as string, fileField, prependTimestampToFilename);
                    // convert back to blob
                    const blob = await getFileBlob(file);
                    const result = await upload(fieldKey as K, uploadPath, blob);
                    return { ...fileField, uploadedPath: result.ref.fullPath };
                },
            );
            const result = await Promise.all(promises);
            setLoading(false);
            setSelectionState({} as any);
            setInputFiles([]);
            onComplete && onComplete(result);
            return result;
        } catch (e: any) {
            Sentry.captureException(e);
            throw e;
        }
    }, [onUploadStart, selectionState, onComplete, fileFields, inputFiles, prependTimestampToFilename, upload]);
    const [canSubmit, setCanSubmit] = React.useState<boolean>(false);
    const handleChange = (fieldKey: K) => (selectedFileName?: string) => {
        setSelectionState({ ...selectionState, [fieldKey]: selectedFileName });
    };
    const onRemoveFile = (name: string) => {
        setSelectionState(currentSelections => {
            return Object.entries<string | undefined>(currentSelections).reduce((newState, [fieldKey, fileName]) => {
                if (fileName !== name) {
                    newState[fieldKey as K] = fileName;
                }
                return newState;
            }, {} as FilesState<K>);
        });
        setInputFiles(files => files.filter(f => f.name !== name));
    };
    React.useEffect(() => {
        const missingFields = fileFields.filter(field => !field.optional && !selectionState[field.fieldKey]);
        const newCanSubmit = missingFields.length === 0;
        if (canSubmit !== newCanSubmit) {
            onSetCanUpload && onSetCanUpload(newCanSubmit);
            setCanSubmit(newCanSubmit);
        }
    }, [selectionState, fileFields, loading, onSetCanUpload, canSubmit]);
    return {
        selectionState,
        setSelectionState,
        onSubmit,
        progress,
        loading,
        inputFiles,
        setInputFiles,
        onRemoveFile,
        handleChange,
        canSubmit,
    };
}

const FileUploaderWrapped = <K extends string = string>(
    props: FileUploaderProps<K>,
    parentRef?: React.Ref<FileUploaderInst<K>>,
) => {
    // TODO: Render the single uploader if only 1 file field passed
    const { fileFields } = props;
    const manager = useFileUploaderManager<K>(props);
    const { onSubmit, progress, loading, inputFiles, onRemoveFile, handleChange, canSubmit } = manager;
    React.useImperativeHandle<FileUploaderInst<K>, FileUploaderInst<K>>(
        parentRef,
        () => ({ submit: canSubmit ? onSubmit : undefined }),
        [canSubmit, onSubmit],
    );
    return (
        <FileUploaderLayout loading={loading || props.submitting} title={props.title} elevation={props.elevation}>
            {!props.completedPaths && (
                <FileUploaderDropzone
                    fileFields={fileFields}
                    setInputFiles={manager.setInputFiles}
                    setSelectionState={manager.setSelectionState}
                    dropzoneOptions={props.dropzoneOptions}
                    selectionState={manager.selectionState}
                />
            )}
            {!props.completedPaths && (
                <FirebaseUploadFileList
                    disableImgPreview={props.disableImgFilePreview}
                    files={inputFiles}
                    onRemove={onRemoveFile}
                />
            )}
            <FileSelectList
                loading={loading}
                fileFields={props.fileFields}
                handleChange={handleChange}
                inputFiles={inputFiles}
                progressState={progress}
                selectionState={props.completedPaths || manager.selectionState}
                disabled={!!props.completedPaths}
                PreviewComponent={props.PreviewComponent}
            />
            {!props.hideSubmitButton && !props.completedPaths && (
                <FileUploaderSubmitButton
                    disabled={!canSubmit || props.disabled}
                    onSubmit={canSubmit ? onSubmit : undefined}
                    submitButtonText={props.submitButtonText || 'Upload'}
                />
            )}
        </FileUploaderLayout>
    );
};

const SingleFileUploaderWrapped = <K extends string = string>(
    props: FileUploaderProps<K>,
    parentRef?: React.Ref<FileUploaderInst<K>>,
) => {
    const manager = useFileUploaderManager<K>(props);
    const { onSubmit, progress, loading, inputFiles, canSubmit } = manager;
    React.useImperativeHandle<FileUploaderInst<K>, FileUploaderInst<K>>(
        parentRef,
        () => ({ submit: canSubmit ? onSubmit : undefined }),
        [canSubmit, onSubmit],
    );
    const dropzoneProps = {
        setInputFiles: manager.setInputFiles,
        setSelectionState: manager.setSelectionState,
        dropzoneOptions: { ...props.dropzoneOptions, multiple: false },
        selectionState: manager.selectionState,
    };
    return (
        <FileUploaderLayout
            loading={loading || props.submitting}
            title={props.title}
            elevation={props.elevation}
            paperStyle={props.paperStyle}
        >
            {props.fileFields.map(field => {
                const selectedFileName = manager.selectionState[field.fieldKey];
                const selectedFile = selectedFileName ? inputFiles.find(f => f.name === selectedFileName) : undefined;
                const fieldProgress = progress[field.fieldKey] || 0;
                return (
                    <FileUploaderSingleFileField
                        key={field.fieldKey}
                        loading={loading}
                        progress={fieldProgress * 100}
                        field={field}
                        selectedFile={selectedFile}
                        onRemoveFile={manager.onRemoveFile}
                        PreviewComponent={props.PreviewComponent}
                        dropzoneProps={dropzoneProps}
                        dropzoneContent={props.dropzoneContent}
                        wrapperStyle={props.wrapperStyle}
                        disableImgPreview={props.disableImgFilePreview}
                    />
                );
            })}
            {!props.hideSubmitButton && !props.completedPaths && (
                <FileUploaderSubmitButton
                    disabled={!canSubmit || props.disabled}
                    onSubmit={canSubmit ? onSubmit : undefined}
                    submitButtonText={props.submitButtonText || 'Upload'}
                />
            )}
        </FileUploaderLayout>
    );
};

export const FileUploader = React.forwardRef(FileUploaderWrapped);
export const FileUploaderSingle = React.forwardRef(SingleFileUploaderWrapped);
