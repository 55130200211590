import type { ModelAppearance, PayloadModelAppearance } from '../ModelAppearance';
import { RestorativeView } from '../ModelAppearance';
import type { UndercutMetrics } from './UndercutHighlight.util';
import React from 'react';

type UpdateTextFn = (msg: string | null) => void;
export type UpdateTextFnRef = React.MutableRefObject<UpdateTextFn | null>;

export type SetValueFn = (value: number | UndercutMetrics | null | undefined) => void;

interface HeatmapHighlightState {
    updateTextRef: UpdateTextFnRef;
    setHeatmapHighlight: SetValueFn;
}

export function useHeatmapHighlight(): HeatmapHighlightState {
    const heatmapHighlightRef = React.useRef<number | UndercutMetrics | null | undefined>(null);
    const updateTextRef = React.useRef<UpdateTextFn | null>(null);

    const setHeatmapHighlight = React.useCallback<SetValueFn>(value => {
        heatmapHighlightRef.current = value;
        if (updateTextRef.current) {
            const value = heatmapHighlightRef.current;
            if (typeof value === 'number') {
                const formattedValue =
                    Math.abs(value) < 0.1 ? `${value.toFixed(3)}mm` : `${Math.round(value * 100) / 100}mm`;
                updateTextRef.current(heatmapHighlightRef.current ? formattedValue : null);
            } else {
                const escapeDistance = value?.escapeOffset?.length();
                const depth = value?.intersection?.distance;
                const lines = [];
                if (escapeDistance) {
                    lines.push(`Escape: ${escapeDistance.toFixed(2)}mm`);
                }
                if (depth) {
                    lines.push(`Depth: ${depth.toFixed(2)}mm`);
                }
                updateTextRef.current(lines.length !== 0 ? lines.join('\n') : null);
            }
        }
    }, []);

    return { updateTextRef, setHeatmapHighlight };
}

/**
 * Gets the models that should have the heatmap highlight applied
 * @param appearance Model appearance
 * @param heatmapHighlightEnabled Whether heatmap highlights are enabled
 * @returns Models that should have the heatmap highlight applied
 */
export function useModelsForHeatmapHighlight(
    appearance?: ModelAppearance,
    heatmapHighlightEnabled?: boolean,
    showScansHeatmap?: boolean,
): PayloadModelAppearance[] {
    return React.useMemo(() => {
        if (!appearance || appearance.restorativeView !== RestorativeView.HeatMap || !heatmapHighlightEnabled) {
            return [];
        }

        const visibleCurrentRestoratives = appearance.restoratives.HeatMap.filter(pma => pma.appearance.visible);
        const visiblePastRestoratives = appearance.pastRestoratives.HeatMap.filter(pma => pma.appearance.visible);

        const visibleScans = showScansHeatmap
            ? [...appearance.upperJaw, ...appearance.lowerJaw].filter(pma => pma.appearance.visible)
            : [];

        // Do not render the highlight if both current and past restoratives are visible as it could be confusing which
        // version the highlight pertains to.
        if (visibleCurrentRestoratives.length && visiblePastRestoratives.length) {
            return visibleScans;
        }

        return visibleCurrentRestoratives.length
            ? [...visibleCurrentRestoratives, ...visibleScans]
            : [...visiblePastRestoratives, ...visibleScans];
    }, [appearance, heatmapHighlightEnabled, showScansHeatmap]);
}
