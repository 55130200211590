import type { SettingsContentSwitchComponent } from './SettingsContentSectionRouter';
import { SettingsContentSectionRouter } from './SettingsContentSectionRouter';
import { stylesFactory, FlossPalette, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        height: '100%',
        position: 'relative',
        background: FlossPalette.WHITE,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
}));

interface PracticeSettingsRootProps {
    subNavComponent: React.ReactNode;
    Router: SettingsContentSwitchComponent;
}

export const PracticeSettingsRoot: React.FC<PracticeSettingsRootProps> = props => {
    const classes = useStyles();
    const { subNavComponent, Router } = props;
    return (
        <Grid container alignContent={'flex-start'} wrap={'nowrap'} className={classes.root}>
            {subNavComponent}
            <Grid container direction={'column'} alignContent={'flex-start'}>
                <SettingsContentSectionRouter Switch={Router} />
            </Grid>
        </Grid>
    );
};
