export interface Tetrahedron {
    type: 'tetrahedron';
    base: THREE.Triangle;
    vertex: THREE.Vector3;
}

export interface Prism {
    type: 'prism';
    base: THREE.Triangle;
    direction: THREE.Vector3;
}

export interface Cupola {
    type: 'cupola';
    base: THREE.Triangle;
    aEdges: [THREE.Vector3, THREE.Vector3];
    bEdges: [THREE.Vector3, THREE.Vector3];
    cEdges: [THREE.Vector3, THREE.Vector3];
}

export type CastVolume = Tetrahedron | Prism | Cupola;

export enum PlaneSide {
    Front = 'front',
    Behind = 'behind',
    Crossing = 'crossing',
}
