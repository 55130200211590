import { ResolveFlaggedScanScreen } from './types';
import type {
    LabsGqlLabOrderFragment,
    LabsGqlWorkflowTask_ResolveScanRejectionWorkflowTask_Fragment as ResolveScanRejectionWorkflowTask,
} from '@orthly/graphql-operations';
import { useOrder, usePracticeRespondToRejectedScanMutation } from '@orthly/graphql-react';
import { LabsGqlResolveScanRejectionDecisionEnum } from '@orthly/graphql-schema';
import { LoadBlocker, RootActionDialog, SimpleInput, useChangeSubmissionFn } from '@orthly/ui';
import {
    FlossPalette,
    stylesFactory,
    FormControlLabel,
    Grid,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
    Button,
    Text,
} from '@orthly/ui-primitives';
import { DandyAttachmentZoomIcon, DandyLightbox, isImagePath, useFirebasePreviewMulti } from '@orthly/veneer';
import React from 'react';

interface ResolveFlaggedScanDialogProps {
    order: LabsGqlLabOrderFragment;
    activeTask: ResolveScanRejectionWorkflowTask;
    open: boolean;
    setOpen: (open: boolean) => void;
    screen: ResolveFlaggedScanScreen;
    setScreen: (screen: ResolveFlaggedScanScreen) => void;
    refetch: () => Promise<any>;
}

const DialogTitleGeneratorMap: {
    [K in ResolveFlaggedScanScreen]: (task: ResolveScanRejectionWorkflowTask) => string;
} = {
    [ResolveFlaggedScanScreen.THANK_YOU]: () => 'Thank you!',
    [ResolveFlaggedScanScreen.DOCTOR_CHOICE]: () => 'Review scan issue',
    [ResolveFlaggedScanScreen.DOCTOR_NOTES]: (task: ResolveScanRejectionWorkflowTask) =>
        !!task.configuration.options_for_doctor?.length
            ? 'How do you want to proceed?'
            : 'Do you want to leave any extra instructions?',
    [ResolveFlaggedScanScreen.CONFIRM_STOP]: () => 'Stop this order?',
};

const useStyles = stylesFactory(() => ({
    root: {
        position: 'relative',
        '&:hover': {
            background: FlossPalette.STAR_GRASS,
            cursor: 'pointer',
        },
        '&:hover img': { opacity: 0.4 },
        '&:hover svg': { visibility: 'visible' },
    },
    stopOrderButton: {
        backgroundColor: `${FlossPalette.ATTENTION} !important`,
    },
}));

const ResolveFlaggedScanDialogDoctorNotesScreen: React.FC<{
    orderId: string;
    activeTask: ResolveScanRejectionWorkflowTask;
    setScreen: (screen: ResolveFlaggedScanScreen) => void;
}> = props => {
    const [selectedOption, setSelectedOption] = React.useState<string | undefined>(undefined);
    const [doctorNotes, setDoctorNotes] = React.useState<string>('');
    const [proceedSubmitMtn] = usePracticeRespondToRejectedScanMutation();

    const proceedOrderSubmitter = async () =>
        await proceedSubmitMtn({
            variables: {
                data: {
                    order_id: props.orderId,
                    practice_response: {
                        practice_notes: doctorNotes,
                        practice_selected_option: selectedOption,
                        practice_decision: LabsGqlResolveScanRejectionDecisionEnum.Proceed,
                    },
                },
            },
        });

    const { submit: proceedWithOrder, submitting } = useChangeSubmissionFn<any, []>(proceedOrderSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan issue resolved!', {}],
        onSuccess: async () => {
            props.setScreen(ResolveFlaggedScanScreen.THANK_YOU);
        },
    });
    const options = props.activeTask.configuration.options_for_doctor;
    return (
        <Grid container>
            <Grid container direction={'column'}>
                {!!options?.length && (
                    <Grid item style={{ marginBottom: 32 }}>
                        <RadioGroup
                            name={'options'}
                            onChange={changed => {
                                setSelectedOption(changed.target.value);
                            }}
                        >
                            {options.map((option, idx) => (
                                <FormControlLabel
                                    key={idx}
                                    value={option}
                                    control={<Radio color={'secondary'} />}
                                    label={option}
                                />
                            ))}
                        </RadioGroup>
                    </Grid>
                )}
                <Grid item>
                    <SimpleInput
                        TextFieldProps={{ multiline: true, rows: 3 }}
                        fullWidth={true}
                        onChange={val => setDoctorNotes(val ?? '')}
                        value={doctorNotes}
                        label={'Extra instructions (optional)'}
                    />
                </Grid>
            </Grid>
            <Grid item container style={{ justifyContent: 'flex-end', marginTop: 40 }}>
                <Button
                    variant={'secondary'}
                    onClick={() => {
                        props.setScreen(ResolveFlaggedScanScreen.DOCTOR_CHOICE);
                    }}
                    style={{ marginRight: 8 }}
                >
                    Back
                </Button>
                <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'auto' } }}>
                    <Button
                        disabled={!!options?.length && !selectedOption}
                        onClick={() => proceedWithOrder()}
                        variant={'primary'}
                    >
                        Proceed
                    </Button>
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

interface ConfirmStopScreenProps {
    setOpen: (open: boolean) => void;
    orderId: string;
    setScreen: (screen: ResolveFlaggedScanScreen) => void;
    refetch: () => Promise<any>;
}

const ResolveFlaggedScanDialogConfirmStopScreen: React.FC<ConfirmStopScreenProps> = props => {
    const classes = useStyles();
    const { setOpen, orderId } = props;
    const [stopSubmitMtn] = usePracticeRespondToRejectedScanMutation();

    const stopOrderSubmitter = async () =>
        await stopSubmitMtn({
            variables: {
                data: {
                    order_id: orderId,
                    practice_response: { practice_decision: LabsGqlResolveScanRejectionDecisionEnum.Cancel },
                },
            },
        });

    const { submit: stopOrder, submitting } = useChangeSubmissionFn<any, []>(stopOrderSubmitter, {
        closeOnComplete: true,
        successMessage: () => ['Scan issue resolved!', {}],
        onSuccess: async () => {
            setOpen(false);
            await props.refetch();
        },
    });
    return (
        <Grid container>
            <Text variant={'body2'} style={{ margin: '8px 0' }}>
                You'll need to bring the patient back in, re-scan them and place a new order.
            </Text>
            <Grid item container style={{ justifyContent: 'flex-end', marginTop: 8 }}>
                <Button
                    onClick={() => {
                        props.setScreen(ResolveFlaggedScanScreen.DOCTOR_CHOICE);
                    }}
                    variant={'secondary'}
                    style={{ marginRight: 8 }}
                >
                    Back
                </Button>
                <LoadBlocker blocking={submitting} ContainerProps={{ style: { width: 'auto' } }}>
                    <Button onClick={() => stopOrder()} variant={'primary'} className={classes.stopOrderButton}>
                        Stop order &amp; rescan patient
                    </Button>
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};

export const ResolveFlaggedScanDialogThankYouScreen: React.FC<{
    order: LabsGqlLabOrderFragment;
    onFinish: () => Promise<void>;
}> = props => {
    const { order } = props;
    const { loading } = useOrder(order.id, { fetchPolicy: 'network-only' });
    return (
        <Grid container>
            <LoadBlocker blocking={loading}>
                <Text variant={'body2'} style={{ margin: '8px 0' }}>
                    Because of this extra step, there may be a delay in the delivery. We will update the portal if there
                    are any changes
                </Text>
            </LoadBlocker>
            <Grid container style={{ justifyContent: 'flex-end', marginTop: 8 }}>
                <Button onClick={props.onFinish} variant={'primary'}>
                    Close
                </Button>
            </Grid>
        </Grid>
    );
};

const ResolveFlaggedScanDialogDoctorChoiceScreen: React.FC<{
    order: LabsGqlLabOrderFragment;
    activeTask: ResolveScanRejectionWorkflowTask;
    setScreen: (screen: ResolveFlaggedScanScreen) => void;
}> = props => {
    const classes = useStyles();
    const { order, activeTask } = props;
    const [selectedAttachmentPreview, setSelectedAttachmentPreview] = React.useState<string | undefined>(undefined);

    const attachments = React.useMemo(() => {
        const attachedImageUrls = activeTask.configuration.file_urls ?? [];
        return attachedImageUrls.filter(url => isImagePath(url)).map(url => ({ source: url, name: url }));
    }, [activeTask.configuration.file_urls]);

    // Loads firebase URLs since we currently just have Firebase paths in the attachments list.
    const { result: previews, loading } = useFirebasePreviewMulti(attachments);
    return (
        <Grid container spacing={2} direction={'column'}>
            <Grid item>
                <Text
                    variant={'body2'}
                >{`We found an issue with ${order.patient.first_name} ${order.patient.last_name}'s scan`}</Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>{activeTask.configuration.notes_for_doctor}</Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>Review Issues:</Text>
            </Grid>
            <LoadBlocker blocking={loading} ContainerProps={{ style: { justifyContent: 'space-evenly' } }}>
                {attachments.map(r => {
                    const imgSrc = previews?.find(img => r.name === img.name)?.source;
                    return (
                        <Grid
                            item
                            xs={5}
                            key={r.name}
                            onClick={() => setSelectedAttachmentPreview(r.name)}
                            style={{ padding: 0, margin: '8px 0px' }}
                            className={classes.root}
                        >
                            {imgSrc && (
                                <>
                                    <img
                                        alt={r.name}
                                        key={r.name}
                                        src={imgSrc}
                                        style={{ height: '100%', width: '100%' }}
                                    />
                                    <DandyAttachmentZoomIcon />
                                </>
                            )}
                        </Grid>
                    );
                })}
            </LoadBlocker>
            <Grid item>
                <Text variant={'body2'}>There's a chance this might result in a refabrication.</Text>
            </Grid>
            <Grid item>
                <Text variant={'body2'}>
                    Do you want to proceed with the current scan, or cancel the order and rescan the patient?
                </Text>
            </Grid>
            <Grid item container style={{ justifyContent: 'flex-end', marginTop: 8 }}>
                <Button
                    onClick={() => {
                        props.setScreen(ResolveFlaggedScanScreen.DOCTOR_NOTES);
                    }}
                    variant={'secondary'}
                    style={{ marginRight: 8 }}
                >
                    Proceed as is
                </Button>
                <Button
                    onClick={() => props.setScreen(ResolveFlaggedScanScreen.CONFIRM_STOP)}
                    variant={'primary'}
                    className={classes.stopOrderButton}
                >
                    Stop order & rescan patient
                </Button>
            </Grid>
            {selectedAttachmentPreview && (
                <DandyLightbox
                    previews={previews}
                    loading={loading}
                    setSelectedAttachmentPreview={setSelectedAttachmentPreview}
                    selectedAttachmentPreview={selectedAttachmentPreview}
                    onPhotoViewedAnalytics={(source, name) => {
                        return {
                            name: 'All - Portal - Photo Lightbox Viewed',
                            data: {
                                $groups: { order: order.id },
                                displayLocation: 'annotations_list',
                                photoSource: source,
                                photoName: name,
                            },
                        };
                    }}
                />
            )}
        </Grid>
    );
};

const ResolveFlaggedScanDialogContent: React.FC<ResolveFlaggedScanDialogProps> = props => {
    switch (props.screen) {
        case ResolveFlaggedScanScreen.DOCTOR_CHOICE:
            return <ResolveFlaggedScanDialogDoctorChoiceScreen {...props} />;
        case ResolveFlaggedScanScreen.DOCTOR_NOTES:
            return <ResolveFlaggedScanDialogDoctorNotesScreen {...props} orderId={props.order.id} />;
        case ResolveFlaggedScanScreen.THANK_YOU:
            return (
                <ResolveFlaggedScanDialogThankYouScreen
                    {...props}
                    onFinish={async () => {
                        props.setOpen(false);
                        await props.refetch();
                    }}
                />
            );
        case ResolveFlaggedScanScreen.CONFIRM_STOP:
            return <ResolveFlaggedScanDialogConfirmStopScreen {...props} orderId={props.order.id} />;
    }
};

export const ResolveFlaggedScanDialog: React.FC<ResolveFlaggedScanDialogProps> = props => {
    const { setOpen, open, screen, activeTask } = props;

    return (
        <RootActionDialog
            showCloseButton={true}
            loading={false}
            title={DialogTitleGeneratorMap[screen](activeTask)}
            open={open}
            setOpen={setOpen}
            content={<ResolveFlaggedScanDialogContent {...props} />}
            buttonText={''}
            titleProps={{ style: { borderBottom: 'none' } }}
            CustomButton={() => null}
            contentProps={{ dividers: false }}
            titleTextProps={{ variant: 'h4' }}
            closeIconButtonProps={{
                style: { borderRadius: 0, height: 'fit-content', backgroundColor: FlossPalette.TAN },
            }}
            closeIconProps={{ color: 'primary' }}
        />
    );
};
