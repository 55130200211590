import { logger } from '../../Utils/Logger';
import type { MaterialsFileSection, BaseEntrySchema } from './MaterialsFileParser.zod';
import { MaterialsFileSchema } from './MaterialsFileParser.zod';
import CryptoJS from 'crypto-js';
import xmlParser from 'fast-xml-parser';
import _ from 'lodash';
import type z from 'zod';

export type MaterialsFileEntry = {
    // The section name, eg `AbutmentKitList`.
    section: MaterialsFileSection['@_name'];
    // The ItemID field, eg `CAMLOG 4.3 NE 00.066`.
    id: string;
    // The Name field, which generally is the same as the `id`, but can diverge.
    name?: string;
    // The CreatorSiteID field, eg `05002`.
    creatorSiteId: string;
    // Hash of all of `data`, prepended with the section name.
    // This is the field that should be used for comparisons.
    hash: string;
};

export class MaterialsFileParser {
    constructor(private readonly rawXML: string) {}

    parseIdHashMap(): Record<string, string> {
        const results = this.parse();

        return (results ?? []).reduce((state, entry) => {
            return {
                ...state,
                [entry.id]: entry.hash,
            };
        }, {});
    }

    // Parses the Materials file but only returns entries with one of the provided section names.
    // Especially useful when parsing a design file's materials file, as it contains many more definitions than we care about, and we have many designs.
    // Conversely, one would be discouraged from using this to parse a DME, as we have a fairly small number of DMEs.
    parseAndExtractSections(sectionNames: MaterialsFileSection['@_name'][]): MaterialsFileEntry[] {
        const result = this.parse();

        return (result ?? []).filter(m => sectionNames.includes(m.section));
    }

    parse(): MaterialsFileEntry[] | undefined {
        const xml = xmlParser.parse(this.rawXML, {
            attributeNamePrefix: '@_',
            ignoreAttributes: false,
            arrayMode: (tagName, parentTagName) =>
                (parentTagName === 'List' && tagName === 'Object') || tagName === 'Property' || tagName === 'String',
        });

        const parseResult = MaterialsFileSchema.safeParse(xml);

        if (!parseResult.success) {
            for (const err of parseResult.error.errors) {
                const failed = _.get(xml, err.path);
                logger.warn('Failed to parse', { failed, err });
            }

            return undefined;
        }

        const result = parseResult.data.DentalContainer.Object.Object.map(section => {
            if (!section.List.Object) {
                return [];
            }

            return section.List.Object.map<MaterialsFileEntry>((entry: z.infer<typeof BaseEntrySchema>) => {
                const idRecord = entry.Property.find(prop => prop['@_name'] === 'ItemID');
                const nameRecord = entry.Property.find(prop => prop['@_name'] === 'Name');
                const creatorSiteIdRecord = entry.Property.find(prop => prop['@_name'] === 'CreatorSiteID');

                const data = JSON.stringify(entry);
                const id = idRecord?.['@_value'] ?? 'failed_to_parse_id';
                return {
                    id,
                    section: section['@_name'],
                    hash: `${section['@_name']}-${id}-${CryptoJS.MD5(data).toString(CryptoJS.enc.Hex)}`,
                    name: nameRecord?.['@_value'],
                    creatorSiteId: creatorSiteIdRecord?.['@_value'] ?? 'unknown_creator_id',
                };
            });
        });

        return _.flattenDeep(result);
    }
}
