import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { MESH_DEFAULT_TRANSFORM } from '../ModelViewer/ModelMeshes';
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import type {
    CrossSectionData,
    CrossSectionDataSetter,
    CrossSectionPlane,
    CrossSectionPlaneSetter,
} from './CrossSectionData';
import { CrossSectionMathUtils, listAllPayloadModels } from './CrossSectionData';
import { CrossSectionPlaneControls } from './CrossSectionPlaneControls';
import { CrossSectionPlaneMesh } from './CrossSectionPlaneMesh';
import {
    addPayloadItemToCrossSection,
    addMarginToCrossSection,
    addUpdatedMarginToCrossSection,
} from './CrossSectionUtils';
import { DrawNewCrossSectionPlaneTool } from './DrawNewCrossSectionPlaneTool';
import { ensureMeshIndex, getVertex, createCircleLineGeometry } from '@orthly/forceps';
import type { MarginLine } from '@orthly/shared-types';
import { useThree } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';
import type { BufferGeometry, Vector3 } from 'three';
import { Matrix4, Plane } from 'three';

type CrossSectionPlaneToolProps = {
    payload: ModelAppearance;
    csPlane?: CrossSectionPlane;
    minorAxis?: Vector3;
    setCSPlane: CrossSectionPlaneSetter;
    cameraControlsRef: MainViewCameraControlsRef;
    onUpdateCrossSection: CrossSectionDataSetter;
    newCrossSectionPlaneActive: boolean;
    onNewCrossSectionPlane: () => void;
    clippingPlane: boolean;
    reversePlane: boolean;
    marginLines?: MarginLine[];
    updatedMarginLine?: THREE.Vector3[];
    previousMarginLines?: MarginLine[];
    showMarginLines?: boolean;
    showUpdatedMarginLines?: boolean;
    showPreviousMarginLines?: boolean;
};

/**
 * This component is responsible for drawing Cross Section plane
 * in main 3d view, and controls it's position and creation.
 *
 * It's also responsible for updating CrossSectionData DTO
 * on cross section plane changes
 *
 * @component
 */
export const CrossSectionPlaneTool: React.VFC<CrossSectionPlaneToolProps> = ({
    payload,
    csPlane,
    setCSPlane,
    cameraControlsRef,
    onUpdateCrossSection,
    newCrossSectionPlaneActive,
    onNewCrossSectionPlane,
    clippingPlane,
    reversePlane,
    marginLines,
    updatedMarginLine,
    previousMarginLines,
    showMarginLines,
    showUpdatedMarginLines,
    showPreviousMarginLines,
}) => {
    const crossSectionRef = React.useRef<CrossSectionData>();

    const { gl } = useThree();

    React.useEffect(() => {
        const updated = !!crossSectionRef.current;
        crossSectionRef.current = indexGeometryForCrossSection(
            payload,
            marginLines,
            updatedMarginLine,
            previousMarginLines,
            showMarginLines,
            showUpdatedMarginLines,
            showPreviousMarginLines,
            crossSectionRef.current,
        );
        if (updated) {
            onUpdateCrossSection(crossSectionRef.current);
        }
    }, [
        payload,
        onUpdateCrossSection,
        marginLines,
        updatedMarginLine,
        previousMarginLines,
        showMarginLines,
        showUpdatedMarginLines,
        showPreviousMarginLines,
    ]);

    React.useEffect(() => {
        const crossSection = crossSectionRef.current;

        if (crossSection && csPlane) {
            const plane = CrossSectionMathUtils.getThreePlane(csPlane);
            if (clippingPlane) {
                const planeForClipping = CrossSectionMathUtils.getThreePlane(csPlane);
                if (reversePlane) {
                    planeForClipping.negate();
                }

                // small epsilon to stop z-fighting of the plane visual representation
                //  Displace view clipping slightly so that the 3D object widget
                //  does not get clipped out or Z-fight.
                planeForClipping.constant += 0.01;

                gl.clippingPlanes = [planeForClipping];
            } else {
                gl.clippingPlanes = [];
            }

            crossSection.entries.forEach(entry => {
                intersectAndUpdateCrossSection(
                    entry.geometry,
                    entry.mesh,
                    plane,
                    csPlane.orientation,
                    entry.lineGeometry,
                    csPlane.cameraUp ?? new THREE.Vector3(),
                    ['MarginLine', 'UpdatedMarginLine'].includes(entry.type),
                );
            });

            crossSection.plane.copy(plane);

            onUpdateCrossSection(crossSection);
        }
    }, [
        csPlane,
        payload,
        onUpdateCrossSection,
        clippingPlane,
        reversePlane,
        gl,
        marginLines,
        updatedMarginLine,
        showMarginLines,
    ]);

    return (
        <>
            <DrawNewCrossSectionPlaneTool
                setCSPlane={setCSPlane}
                cameraControlsRef={cameraControlsRef}
                newCrossSectionPlaneActive={newCrossSectionPlaneActive}
                onNewCrossSectionPlane={onNewCrossSectionPlane}
            />

            {csPlane && <CrossSectionPlaneMesh csPlane={csPlane} />}
            {csPlane && (
                <CrossSectionPlaneControls
                    visible={!newCrossSectionPlaneActive}
                    cameraControlsRef={cameraControlsRef}
                    csPlane={csPlane}
                    setCSPlane={setCSPlane}
                />
            )}
        </>
    );
};

/**
 * Intersects the cross-section plane with the payloadGeometry, and returns the intersection contours (cross-section)
 * oriented in the x-y plane (with the plane normal pointing toward z-axis)
 * If the payloadGeometry is a mesh, we use the index MeshBVH to do the intersection
 * If the payloadGeometry is a MarginLine, we intersect each line of the marginline with the plane, and add circles
 * to represent the intersection points clearly in the cross-section view
 */
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
function intersectAndUpdateCrossSection(
    payloadGeometry: THREE.BufferGeometry,
    payloadMesh: THREE.Mesh<BufferGeometry, THREE.Material> | undefined,
    csPlane: Plane,
    csPlaneOrientation: THREE.Quaternion,
    lineGeometry: BufferGeometry,
    cameraUp: THREE.Vector3,
    isMarginLine: boolean,
) {
    let cameraAngle = Math.acos(cameraUp.dot(new THREE.Vector3(0, 1, 0).applyQuaternion(csPlaneOrientation)));
    const dotProduct = cameraUp.dot(new THREE.Vector3(0, 0, 1).applyQuaternion(csPlaneOrientation));
    if (dotProduct < 0) {
        cameraAngle *= -1;
    }
    const tempVector = new THREE.Vector3();
    const tempLine = new THREE.Line3();
    const localPlane = new THREE.Plane();

    // Here, we get the world transform of the item whose cross section we will take. Previously, we could assume that
    // the item vertex positions were all expressed in the world frame, but with the advent of the local transform tool
    // (EPDCAD-226), this is no longer the case. Accounting for the item world transform is only implemented for meshes
    // (i.e. non-margin line items). This is okay for the time being because margin line items will not exist in the
    // scan editor, where the local transform tool is used, as they would not yet have been defined.
    const worldTMesh = payloadMesh?.matrixWorld ?? MESH_DEFAULT_TRANSFORM;
    const meshTWorld = worldTMesh.clone().invert();

    localPlane.copy(csPlane).applyMatrix4(meshTWorld);

    let index = 0;
    const posAttr = lineGeometry.attributes.position;

    if (!posAttr) {
        return;
    }

    if (!isMarginLine) {
        const bvh = ensureMeshIndex(payloadGeometry);
        bvh.shapecast({
            // @ts-ignore
            // ts compliant return localPlane.intersectsBox( box ) : INTERSECTED ? NOT_INTERSECTED breaks the computations
            intersectsBounds: box => {
                return localPlane.intersectsBox(box);
            },

            intersectsTriangle: tri => {
                // check each triangle edge to see if it intersects with the plane. If so then
                // add it to the list of segments.
                let count = 0;
                tempLine.start.copy(tri.a);
                tempLine.end.copy(tri.b);
                if (localPlane.intersectLine(tempLine, tempVector)) {
                    posAttr.setXYZ(index, tempVector.x, tempVector.y, tempVector.z);
                    index++;
                    count++;
                }

                tempLine.start.copy(tri.b);
                tempLine.end.copy(tri.c);
                if (localPlane.intersectLine(tempLine, tempVector)) {
                    posAttr.setXYZ(index, tempVector.x, tempVector.y, tempVector.z);
                    count++;
                    index++;
                }

                tempLine.start.copy(tri.c);
                tempLine.end.copy(tri.a);
                if (localPlane.intersectLine(tempLine, tempVector)) {
                    posAttr.setXYZ(index, tempVector.x, tempVector.y, tempVector.z);
                    count++;
                    index++;
                }

                // If we only intersected with one or three sides then just remove it. This could be handled
                // more gracefully.
                if (count !== 2) {
                    index -= count;
                }

                return false;
            },
        });
    } else {
        // intersecting margin line
        const ptsCount = payloadGeometry.attributes.position?.count ?? 0;
        const v1 = new THREE.Vector3();
        const v2 = new THREE.Vector3();
        const intersectionPoint = new THREE.Vector3();
        for (let i = 0; i < ptsCount; i += 2) {
            getVertex(i, payloadGeometry, v1);
            getVertex(i + 1, payloadGeometry, v2);
            const line = new THREE.Line3(v1, v2);
            if (localPlane.intersectLine(line, intersectionPoint)) {
                posAttr.setXYZ(index, intersectionPoint.x, intersectionPoint.y, intersectionPoint.z);
                index++;
            }
        }
    }

    // Compose a matrix to transform lines to get into XY plane
    const orthPlaneMatrix = new Matrix4().makeRotationFromQuaternion(csPlaneOrientation.clone().invert());

    // Now move plane into XY, z=0 position, matrices are applied left to right.  So this
    // transforms from mesh to world, and then from world to orthoplane)
    const csWorldPlaneToXYOrthMatrix = new THREE.Matrix4().multiplyMatrices(orthPlaneMatrix, worldTMesh);
    lineGeometry.applyMatrix4(csWorldPlaneToXYOrthMatrix);

    // rotate the cross section along the z-axis to match the camera up direction EPDCAD-77
    lineGeometry.rotateZ(cameraAngle);

    if (isMarginLine) {
        let newIndex = 0;
        // To show the marginLine intersection inside the cross-section view, we want to show each intersection point (with the cross-section plane) as a circle
        const intersectionPoints = [];
        for (let i = 0; i < index; i++) {
            const pt = new THREE.Vector3();
            getVertex(i, lineGeometry, pt);
            intersectionPoints.push(pt);
        }

        intersectionPoints.forEach(center => {
            const circlePts = createCircleLineGeometry(0.05);
            circlePts.forEach(pt => {
                pt.add(center);
                posAttr.setXYZ(newIndex, pt.x, pt.y, pt.z);
                newIndex++;
            });
        });

        index = newIndex;
    }

    // Update number of points
    // Note for for non-3D readers: We do this because it's fast to update the elements of the buffer,
    // but not fast to append the buffer, so we preallocate the buffer.
    lineGeometry.setDrawRange(0, index);

    if (index >= posAttr.count) {
        console.warn('Cross Section line geometry position attribute have not enought space');
    }

    posAttr.needsUpdate = true;

    lineGeometry.dispatchEvent({ type: 'update' });
}

/**
 * Parse geometries from payload and
 * index them with MeshBVH
 */

export function indexGeometryForCrossSection(
    payload: ModelAppearance,
    marginLines?: MarginLine[],
    updatedMarginLine?: THREE.Vector3[],
    previousMarginLines?: MarginLine[],
    showMarginLines?: boolean,
    showUpdatedMarginLines?: boolean,
    showPreviousMarginLines?: boolean,
    oldCrossSection?: CrossSectionData,
): CrossSectionData {
    const crossSection: CrossSectionData = {
        plane: new Plane(),
        entries: [],
        errorEntries: oldCrossSection?.errorEntries,
    };

    const payloadModels = listAllPayloadModels(payload);

    payloadModels.forEach(item => {
        const payloadGeometry = item.payloadModel.model?.geometry;

        if (payloadGeometry) {
            const oldEntry = oldCrossSection?.entries?.find(e => e.geometry === payloadGeometry);

            if (oldEntry) {
                // the entry already exists; just update visibility
                oldEntry.visible = item.isVisible;
                crossSection.entries.push(oldEntry);
            } else {
                addPayloadItemToCrossSection(crossSection, item);
            }
        }
    });

    // add entry for margin_lines
    addMarginToCrossSection(crossSection, oldCrossSection, false, marginLines, showMarginLines);

    // Add entry for updated margin line.
    addUpdatedMarginToCrossSection(crossSection, oldCrossSection, updatedMarginLine, showUpdatedMarginLines);

    // Add entry for previous margin lines.
    addMarginToCrossSection(crossSection, oldCrossSection, true, previousMarginLines, showPreviousMarginLines);

    return crossSection;
}
