import FileSaver from 'file-saver';
import React from 'react';

export function useRemoteFileToWindowUrl(downloadUrl: string, contentType: string) {
    const [windowUrl, setWindowUrl] = React.useState<string | undefined>();
    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<Error | undefined>();

    const download = React.useCallback(async () => {
        try {
            setLoading(true);
            const res = await fetch(downloadUrl);
            const data = await res.blob();
            const newData = data.slice(0, data.size, contentType);
            setWindowUrl(window.URL.createObjectURL(newData));
        } catch (e: any) {
            setError(e);
        } finally {
            setLoading(false);
        }
    }, [downloadUrl, contentType, setLoading, setError, setWindowUrl]);

    React.useEffect(() => {
        if (!windowUrl && !loading && !error) {
            void download();
        }
    }, [windowUrl, loading, error, download]);
    return { windowUrl, loading, error };
}

export function downloadBlob(data: Blob, fileName: string) {
    FileSaver.saveAs(data, fileName);
}

export function getFileExtension(fileName: string) {
    const queryRemovedName = fileName.split('?')[0] || fileName;
    return queryRemovedName.substr(queryRemovedName.lastIndexOf('.') + 1) || '';
}

export function isBlobURL(url: string | undefined | null) {
    return !!url?.startsWith('blob:');
}

/**
 * Add extension to a filename
 * @example From download url getFileNameWithExtension('my name', downloadUrl);
 * @param {string} fileName
 * @param {string} extensionSource Source to use to get the extension
 * @return {string}
 */
export function getFileNameWithExtension(fileName: string, extensionSource?: string) {
    const ext = getFileExtension(extensionSource || fileName);
    return `${fileName.replace(`.${ext}`, '')}.${ext}`;
}

/**
 * Triggers the provided callback when files are pasted into an input. Attach it to your input's `onPaste` prop
 * @example ```tsx
 * const [files, setFiles] = React.useState<File[]>([]);
 * const onPaste = useFilesFromOnPasteEvent(setFiles);
 * return <input onPaste={onPaste} />
 * ```
 * @param {(files: File[]) => (void | undefined)} onFilesPastedCb
 * @return {(event: React.ClipboardEvent) => void}
 */
export function useFilesFromOnPasteEvent(onFilesPastedCb: ((files: File[]) => void) | undefined) {
    return React.useCallback(
        (event: React.ClipboardEvent) => {
            if (onFilesPastedCb && event.clipboardData.files.length > 0) {
                event.preventDefault();
                onFilesPastedCb(Array.from(event.clipboardData.files));
            }
        },
        [onFilesPastedCb],
    );
}
