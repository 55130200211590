import { create } from 'xmlbuilder2';
import * as xpath from 'xpath';

/**
 * Gets nodes from the case file DOM.
 */
export class CaseFileQuerier {
    private mainObject: Node;

    constructor(caseFileXml: string) {
        const root = create(caseFileXml).root();
        this.mainObject = xpath.select1(`/DentalContainer/Object[@name='MainObject']`, root.node as any) as Node;
    }

    getModelJobIds(): string[] {
        return this.getListObjectPropertyValues('ModelJob', 'ModelJobID');
    }

    getModelElementIds(): { modelElementId: string; modelJobId: string }[] {
        return xpath
            .select(
                createXPathQuery(
                    `Object[@name='ModelElementList']`,
                    `List[@name='Items']`,
                    `Object[@type='TDM_Item_ModelElement']`,
                ),
                this.mainObject,
            )
            .map(el => ({
                modelElementId: getNamedPropertyValue(el as Node, 'ModelElementID'),
                modelJobId: getNamedPropertyValue(el as Node, 'ModelJobID'),
            }));
    }

    getToothElementIds(): { toothElementId: string; modelElementId: string }[] {
        return xpath
            .select(
                createXPathQuery(
                    `Object[@name='ToothElementList']`,
                    `List[@name='Items']`,
                    `Object[@type='TDM_List_ToothElement']`,
                ),
                this.mainObject,
            )
            .map(el => ({
                toothElementId: getNamedPropertyValue(el as Node, 'ToothElementID'),
                modelElementId: getNamedPropertyValue(el as Node, 'ModelElementID'),
            }));
    }

    getLinkIds(): string[] {
        return this.getListObjectPropertyValues('Link', 'LinkID');
    }

    getLinkToothElementIds(): string[] {
        return this.getListObjectPropertyValues('LinkToothElement', 'LinkToothElementID');
    }

    getScanIds(): string[] {
        return this.getListObjectPropertyValues('Scan', 'ScanID');
    }

    getCustomDataFieldIds(): string[] {
        return this.getListObjectPropertyValues('CustomData', 'FieldID');
    }

    getOrderExchangeElementKinds(): string[] {
        return this.getListObjectPropertyValues('OrderExchangeElement', 'Kind');
    }

    getDigitalModelElementInfoIds(): { digitalModelElementInfoId: string; modelElementId: string }[] {
        return xpath
            .select(
                createXPathQuery(
                    `Object[@name='DigitalModelElementInfoList']`,
                    `List[@name='Items']`,
                    `Object[@type='TDM_Item_DigitalModelElementInfo']`,
                ),
                this.mainObject,
            )
            .map(el => ({
                digitalModelElementInfoId: getNamedPropertyValue(el as Node, 'DigitalModelElementInfoID'),
                modelElementId: getNamedPropertyValue(el as Node, 'ModelElementID'),
            }));
    }

    private getListObjectPropertyValues(objectType: string, propertyName: string): string[] {
        return xpath
            .select(
                createXPathQuery(
                    `Object[@name='${objectType}List']`,
                    `List[@name='Items']`,
                    `Object[@type='TDM_Item_${objectType}']`,
                    `Property[@name='${propertyName}']`,
                ),
                this.mainObject,
            )
            .map(getPropertyValue);
    }
}

function createXPathQuery(...parts: string[]): string {
    return parts.join('/');
}

function getPropertyValue(propertyNode: Node): string {
    return xpath.select1(`string(@value)`, propertyNode) as string;
}

function getNamedPropertyValue(node: Node, propertyName: string): string {
    return xpath.select1(`string(Property[@name='${propertyName}']/@value)`, node) as string;
}
