export const ATTRIBUTE_MAP_INVALID_VALUE = 1000000;

export enum AttributeName {
    Position = 'position',
    Normal = 'normal',
    TexCoord = 'uv',
    Color = 'color',
    ThicknessDistance = 'thickness_layer',
    ProximalDistance = 'contact_layer',
    CurtainsDistance = 'curtain_layer',
    VertexDisplacement = 'vertex_displacement',
    SurfaceDisplacement = 'surface_displacement',
    AlignmentDistance = 'alignment_distance',
    SculptMask = 'sculpt_mask',
    /** Source of occlusal distance for visualization */
    OcclusalDistance = 'occlusion_layer',
    /** Occlusal distance to the primary (either post-op or plain antagonist) opposing arch scan */
    OcclusalDistanceToPrimary = 'occlusion_to_primary',
    /** Occlusal distance to the opposing pre-prep scan */
    OcclusalDistanceToPrePrep = 'occlusion_to_pre_prep',
    /** Attribute indicating vertices that are marked for removal */
    ToBeRemoved = 'to_be_removed',
    IsIntaglio = 'is_intaglio',
    IsSealZone = 'is_seal_zone',
    BackupIndex = 'backupIndex',
    DisplacementColor = 'DisplacementColor_',
    CementGapDistance = 'cement_gap_distance',
}
