// Here we define a custom material (by extending the standard mesh phong material) with custom colors heatmap based on
// distance attributes. The colors heatmaps are computed from the distances in the GPU using a special shader code.
import type { UniformsObject } from './ShaderBuilder';
import { makeShaderBuilder, type ShaderReplacement } from './ShaderBuilder';
import * as THREE from 'three';

const _uniformDecls = [
    'int activeHeatMap',
    'float vMin',
    'float vMax',
    'vec3 defaultColor',
    'bool includeCurtains',
    'bool showSculptMask',
] as const;

// See description of each shader chunk in the comments inside the onCompile method below.
const _vertexReplacements: ShaderReplacement[] = [
    [
        '#include <common>',
        /* glsl */ `
attribute float contact_layer;
attribute float curtain_layer;
attribute float occlusion_layer;
attribute float thickness_layer;
attribute float sculpt_mask;
attribute float vertex_displacement;
attribute float surface_displacement;
attribute float cement_gap_distance;`,
    ],
    [
        '#include <color_pars_vertex>',
        /* glsl */ `
varying float vContactLayer;
varying float vContactCurtainLayer;
varying float vOcclusionLayer;
varying float vThicknessLayer;
varying float vSculptLayer;
varying float vVertexDisplacement;
varying float vSurfaceDisplacement;
varying float vCementGapDistance;`,
    ],
    [
        '#include <color_vertex>',
        /* glsl */ `
vContactLayer = contact_layer;
vContactCurtainLayer = curtain_layer;
vOcclusionLayer = occlusion_layer;
vThicknessLayer = thickness_layer;
vSculptLayer = sculpt_mask;
vVertexDisplacement = vertex_displacement;
vSurfaceDisplacement = surface_displacement;
vCementGapDistance = cement_gap_distance;`,
    ],
];

const _fragmentReplacements: ShaderReplacement[] = [
    [
        '#include <color_pars_fragment>',
        /* glsl */ `


varying float vContactLayer;
varying float vContactCurtainLayer;
varying float vOcclusionLayer;
varying float vThicknessLayer;
varying float vSculptLayer;
varying float vVertexDisplacement;
varying float vSurfaceDisplacement;
varying float vCementGapDistance;

// define our color maps functions
vec3 thicknessColorMap(float v, float vMin, float vMax, vec3 DEFAULT_MODEL_RGB_U8_COLOR)
{
    if(v > 100.0 || v < -100.0){ //out of range
        return DEFAULT_MODEL_RGB_U8_COLOR;
    }

    float vPrime = min(max(v, vMin + 0.000001), vMax - 0.000001);

    float dV = vMax - vMin;
    float ratio = (2.0 * (vPrime - vMin)) / dV;

    float R = 0.0;
    float G = 0.0;
    float B = 0.0;
    if (v < vMin) {
        R = 1.0;
        G = 0.0;
        B = 0.0;
    } else if (v > vMax) {
        return DEFAULT_MODEL_RGB_U8_COLOR;
    } else {
        R = min(max(0.0, 1.0 - ratio), 1.0);
        B = min(max(0.0, ratio - 1.0), 1.0);
        G = 1.0 - B - R;
    }
    R = pow(R, 0.75);
    G = pow(G, 0.75);
    B = pow(B, 0.75);

    vec3 result = vec3(R, G, B);
    return result;
}
vec3 occlusalColorMap(float v, float vMin, float vMax, vec3 DEFAULT_MODEL_RGB_U8_COLOR, bool limitAtVMax)
{
    float upperLimit = limitAtVMax ? vMax : 100.0;
    if(v > upperLimit || v < -100.0){ //out of range
        return DEFAULT_MODEL_RGB_U8_COLOR;
    }

    float R = 0.0;
    float G = 0.0;
    float B = 0.0;
    if (v < vMin) {
        R = 0.0;
        G = 0.0;
        B = 1.0;
    }else{
        float ratio = min(1.0, (v - vMin) / (vMax - vMin));

        R = min(1.0, pow(2.0 * (1.0 - ratio), 0.4));
        G = min(1.0, pow(2.0 * ratio, 0.4));
        B = 0.0;
    }

    vec3 result = vec3(R, G, B);
    return result;
}
vec3 proximalColorMap(float v, float vMin, float vMax, vec3 DEFAULT_MODEL_RGB_U8_COLOR)
{
    if(v > 100.0 || v < 0.0){ //out of range
        return DEFAULT_MODEL_RGB_U8_COLOR;
    }

    float vPrime = min(max(v, vMin + 0.000001), vMax - 0.000001);
    float R = 1.0;
    float G = 1.0;
    float B = 1.0;

    float dV = vMax - vMin;
    float ratio = (vPrime - vMin) / dV;

    if (ratio < 0.25) {
        R = 0.0;
        G = (4.0 * (vPrime - vMin)) / dV;
    } else if (ratio < 0.5) {
        R = 0.0;
        B = 1.0 + (4.0 * (vMin + 0.25 * dV - vPrime)) / dV;
    } else if (ratio < 0.75) {
        R = (4.0 * (vPrime - vMin - 0.5 * dV)) / dV;
        B = 0.0;
    } else {
        G = 1.0 + (4.0 * (vMin + 0.75 * dV - vPrime)) / dV;
        B = 0.0;
    }

    R = pow(R, 0.45);
    G = pow(G, 0.45);
    B = pow(B, 0.45);

    vec3 result = vec3(R, G, B);
    return result;
}
vec3 highlightValid(float v, vec3 DEFAULT_MODEL_RGB_U8_COLOR)
{
    if (v > 100.0 || v < -100.0) {
        return DEFAULT_MODEL_RGB_U8_COLOR;
    }
    return vec3(0.0, 1.0, 0.0);
}
vec3 surfaceDisplacementColorMap(float v, float vMin, float vMax, vec3 defaultColor)
{
    vec3 colorTable[11];
    colorTable[0] = vec3(0.17647058823529413,  0.0                ,  0.29411764705882354);  // purple
    colorTable[1] = vec3(0.32941176470588235,  0.15294117647058825,  0.53333333333333333);
    colorTable[2] = vec3(0.50196078431372548,  0.45098039215686275,  0.67450980392156867);
    colorTable[3] = vec3(0.69803921568627447,  0.6705882352941176 ,  0.82352941176470584);
    colorTable[4] = vec3(0.84705882352941175,  0.85490196078431369,  0.92156862745098034);
    colorTable[5] = vec3(0.96862745098039216,  0.96862745098039216,  0.96862745098039216);
    colorTable[6] = vec3(0.99607843137254903,  0.8784313725490196 ,  0.71372549019607845);
    colorTable[7] = vec3(0.99215686274509807,  0.72156862745098038,  0.38823529411764707);
    colorTable[8] = vec3(0.8784313725490196 ,  0.50980392156862742,  0.07843137254901961);
    colorTable[9] = vec3(0.70196078431372544,  0.34509803921568627,  0.02352941176470588);
    colorTable[10] = vec3(0.49803921568627452,  0.23137254901960785,  0.03137254901960784); // orange

    if (v > 100.0 || v < -100.0) {
        return defaultColor;
    }

    if (vMax <= vMin) {
        return defaultColor;
    }

    float scaledRatio = 10.0 * (v - vMin) / (vMax - vMin);
    if (scaledRatio <= 0.0) {
        return colorTable[0];
    }

    if (scaledRatio >= 10.0) {
        return colorTable[10];
    }

    int breakpoint = int(scaledRatio);
    float alpha = scaledRatio - float(breakpoint);
    return mix(colorTable[breakpoint], colorTable[breakpoint + 1], alpha);
}`,
    ],
    [
        '#include <color_fragment>',
        /* glsl */ `
vec3 DEFAULT_MODEL_RGB_U8_COLOR = defaultColor;
vec3 multiplyColor = defaultColor;
vec3 purple = vec3(0.647, 0.125, 0.94);
vec3 proximalColor = proximalColorMap(vContactLayer, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR);
vec3 proximalCurtainColor = proximalColorMap(vContactCurtainLayer, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR);
if(includeCurtains && vContactCurtainLayer > 0.01 && (vContactCurtainLayer - vContactLayer) > 0.005 && vContactCurtainLayer < 100.0){
    if(vContactLayer > 100.0 || vContactLayer < 0.0){
        proximalColor = purple;
    }else{
        proximalColor = proximalCurtainColor;
    }
}
if (activeHeatMap == 1) {
    multiplyColor = thicknessColorMap(vThicknessLayer, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR);
} else if (activeHeatMap == 2 || activeHeatMap == 7) {
    multiplyColor = proximalColor;
} else if (activeHeatMap == 3) {
    multiplyColor = occlusalColorMap(vOcclusionLayer, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR, false);
} else if (activeHeatMap == 5) {
    multiplyColor = highlightValid(vVertexDisplacement, DEFAULT_MODEL_RGB_U8_COLOR);
} else if (activeHeatMap == 6) {
    multiplyColor = surfaceDisplacementColorMap(vSurfaceDisplacement, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR);
} else if (activeHeatMap == 10) {
    multiplyColor = occlusalColorMap(vCementGapDistance, vMin, vMax, DEFAULT_MODEL_RGB_U8_COLOR, true);
}
diffuseColor.rgb *= multiplyColor;
if(showSculptMask){
    diffuseColor.rgb *= 1.0 - 0.5 * vSculptLayer;
}`,
    ],
];

export type DesignMeshShaderMaterialUniforms = UniformsObject<typeof _uniformDecls>;

const designMeshShaderFactory = makeShaderBuilder(
    'design-mesh-shader-material',
    _uniformDecls,
    _vertexReplacements,
    _fragmentReplacements,
);

/**
 * Creates a custom material, an extension of MeshPhongMaterial, to be used on restorative meshes.
 * @param phongMaterialParams Parameters to apply to the base Phong material
 * @param uniformsIn The uniforms to pass to the material shader. If not supplied, they will be created.
 * @returns A tuple with two elements:
 *   1. The material
 *   2. The custom uniforms of the material shader. If `uniformsIn` was supplied, it is passed through here.
 */
export function createDesignMeshShaderMaterial(
    phongMaterialParams: THREE.MeshPhongMaterialParameters,
    uniforms: DesignMeshShaderMaterialUniforms,
): THREE.MeshPhongMaterial {
    const material = new THREE.MeshPhongMaterial(phongMaterialParams);
    designMeshShaderFactory.applyShader(material, uniforms);

    return material;
}
