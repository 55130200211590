import type { EventSchemaWithChildrenSchema, ProcessedEventOutput } from './DesignPerformanceLogParser.types';
import moment from 'moment';

/*
 * This class handles data manipulation for a Performance Log event.
 * We utilize a class because as we parse the entirety of the Performance Log, we will often modify the times and durations.
 */
export class ProcessedEvent {
    dandyCorrected: boolean;
    name: string;
    kind: string;
    timeZone: string;
    utcTime: Date;
    utcCorrectedEnd: Date | undefined;
    children: ProcessedEvent[];
    durationMs?: number;
    // When set, the output will use the date provided in `utcCorrectedEnd` instead of
    // whatever was supplied in the base schema via the constructor.
    overriddenRawEndTime?: boolean;

    constructor(private readonly baseSchema: EventSchemaWithChildrenSchema) {
        const childrenEvents = baseSchema.Children?.Event;

        const startTime = ProcessedEvent.transformStringToUtcDate(baseSchema.Time, baseSchema.TimeZone);

        if (!startTime) {
            throw new Error('Unable to compute start time');
        }

        this.name = baseSchema.Name;
        this.kind = baseSchema.Kind;
        this.utcTime = startTime;
        this.utcCorrectedEnd = baseSchema.EndTime
            ? ProcessedEvent.transformStringToUtcDate(baseSchema.EndTime, baseSchema.TimeZone) ?? undefined
            : undefined;
        this.dandyCorrected = false;
        this.timeZone = baseSchema.TimeZone;
        this.durationMs = baseSchema.Duration;
        this.children = childrenEvents ? childrenEvents.map(child => new ProcessedEvent(child)) : [];
    }

    /*
     * Converts a 3Shape String date to a UTC formatted date.
     * 3Shape stores dates in two parts: (1) the "date" part, eg `2023-12-28 11.58.04.973`, and (2) the timezone, eg `-06:00`.
     * Exposed here as a static method to make testing easier
     */
    static transformStringToUtcDate(str: string, tz: string): Date | undefined {
        // 3Shape stores date time REALLY strangely.
        // Lifted directly from Patrick's python code.
        const result = moment(`${str} ${tz}`, 'YYYY-MM-DD HH.mm.ss.SSS Z');

        return result.isValid() ? result.toDate() : undefined;
    }

    // Updates the end time and duration to a new "fixed" end time.
    setCorrectEndTime(correctedEndTime: Date) {
        this.dandyCorrected = true;
        this.utcCorrectedEnd = correctedEndTime;
        this.durationMs = correctedEndTime.getTime() - this.utcTime.getTime();
    }

    // Returns a legacy-output compatible format for the object which matches how the python code had previously emitted it.
    getOutputEventSchema(): ProcessedEventOutput {
        const rawEndTime = this.baseSchema.EndTime
            ? ProcessedEvent.transformStringToUtcDate(this.baseSchema.EndTime, this.timeZone)?.getTime()
            : undefined;
        const utcEndTime = this.overriddenRawEndTime ? this.utcCorrectedEnd?.getTime() : rawEndTime;

        return {
            DandyCorrected: this.dandyCorrected,
            Kind: this.kind,
            Name: this.name,
            TimeZone: this.timeZone,
            utcCorrectedEnd: this.dandyCorrected ? this.utcCorrectedEnd?.getTime() ?? null : null,
            utcEndTime: utcEndTime,
            utcTime: this.utcTime.getTime(),
            Duration: this.durationMs,
            EndTime: this.baseSchema.EndTime,
            Time: this.baseSchema.Time,
            Children: this.children.map(child => child.getOutputEventSchema()),
        };
    }

    /*
     * Only use this for user interactive or idle events.
     * Some events can overlap.  However Idle, GUI changes and Sculpt cannot be simultaneous.
     * Changing small details in this function can greatly alter the overall cumulative durations of our events in unexpected ways.
     * Proceed with caution -- it was ported from Python intentionally as it is.
     */
    static validateEventDuration(events: ProcessedEvent[], parentIdx: number, currentEvent: ProcessedEvent) {
        /*
         *Arbitrarily chosen limit to avoid unnecessarily long searches.
         * Readers note: in Partick's original Python code, he did a loop from i = 1, while i < 100.
         * I simplified this a little bit to be more readable, and because of the index math, our limit ends up being 98.
         * Changing this will ultimately affect your durations, because we'd have a different value considered as our "terminal" end event.
         * This is wonky, but I did it to be backwards compatible. We should revisit this one day.
         */
        const searchDistanceLimit = 98;

        if (!currentEvent.durationMs) {
            return;
        }

        // We will find an event which started at or after our current event,
        const maximumIdx = Math.min(parentIdx + searchDistanceLimit + 1, events.length - 1);

        for (let candidateIdx = parentIdx + 1; candidateIdx <= maximumIdx; candidateIdx++) {
            const candidateEvent = events[candidateIdx];

            if (!candidateEvent) {
                continue;
            }

            // Candidate started before current, look for another candidate
            if (candidateEvent.utcTime < currentEvent.utcTime) {
                continue;
            }

            // Candidate started after the child ended -> we are fine.
            if (currentEvent.utcCorrectedEnd && candidateEvent.utcTime > currentEvent.utcCorrectedEnd) {
                return;
            }

            currentEvent.setCorrectEndTime(new Date(candidateEvent.utcTime.getTime() - 1));
            return;
        }
    }

    /*
     * Changing small details in this function can greatly alter the overall cumulative durations of our events in unexpected ways.
     * Proceed with caution -- it was ported from Python intentionally as it is.
     */
    static validateEventWithChildren(events: ProcessedEvent[], idx: number) {
        const currentEvent = events[idx];

        if (!currentEvent) {
            throw new Error('Provided idx for event outside of range');
        }

        currentEvent.children.forEach(child => {
            this.validateEventDuration(events, idx, child);
        });
        const lastChild = currentEvent.children.at(-1);
        const lastChildEndTime = lastChild?.utcCorrectedEnd?.getTime() ?? lastChild?.utcTime?.getTime();
        const estimatedParentDuration = lastChildEndTime
            ? lastChildEndTime - currentEvent.utcTime.getTime()
            : undefined;

        currentEvent.durationMs = currentEvent.durationMs ?? estimatedParentDuration;
    }
}
