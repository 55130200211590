import { AttributeName } from './BufferAttributeConstants';
import type * as THREE from 'three';
import { Vector3 } from 'three';

export function uniqBySetWithArrayFrom<T>(array: T[]): T[] {
    return Array.from(new Set(array));
}

export type AdjacencyMatrix = number[][];

/**
 * Look up table for quickly getting adjacent faces or vertices to a given vertex
 */
export interface MeshConnectivityGraph {
    adjacencyVertexToVertex: AdjacencyMatrix;
    adjacencyFacesToVertex: AdjacencyMatrix;
}

function getPosition(geometry: THREE.BufferGeometry, vert: number): Vector3 {
    const positionObject = geometry.attributes.position;

    return new Vector3(
        positionObject?.array[vert * 3],
        positionObject?.array[vert * 3 + 1],
        positionObject?.array[vert * 3 + 2],
    );
}

// Given a geometry, builds a matrix consisting of every vertex and its neighbors.
// This is meant to be computed once per mesh, the cost of which is amortized
// over each query into the neighbors data structure.
// The efficiency of this function relies on the mesh not having seams
// This is true for our scans but may not be true for some meshes (eg curtains or other scanners?)
export function buildMeshAdjacency(geo: THREE.BufferGeometry, cleanMesh: boolean = true): AdjacencyMatrix {
    const adj: AdjacencyMatrix = [];
    if (geo.attributes.position) {
        const nVertices = geo.attributes.position.count;
        for (let i = 0; i < nVertices; ++i) {
            adj[i] = [];
        }
    }

    if (geo.index) {
        const nFaces = geo.index.count / 3;
        for (let i = 0; i < nFaces; ++i) {
            const a = geo.index.array[3 * i];
            const b = geo.index.array[3 * i + 1];
            const c = geo.index.array[3 * i + 2];
            if (a !== undefined && b !== undefined && c !== undefined) {
                adj[a]?.push(b);
                adj[b]?.push(c);
                adj[c]?.push(a);

                if (!cleanMesh) {
                    adj[b]?.push(a);
                    adj[c]?.push(b);
                    adj[a]?.push(c);
                }
            }
        }
    }
    return cleanMesh ? adj : adj.map(arr => uniqBySetWithArrayFrom(arr));
}

// Similar to buildMeshAdjacency, but also builds vertices-to-faces structure
export function buildMeshAdjacencyAndVerticesFaces(
    geo: THREE.BufferGeometry,
    cleanMesh: boolean = true,
): MeshConnectivityGraph {
    const adj: AdjacencyMatrix = [];
    const verticesFaces: number[][] = [];
    if (geo.attributes.position) {
        const nVertices = geo.attributes.position.count;
        for (let i = 0; i < nVertices; ++i) {
            adj[i] = [];
            verticesFaces[i] = [];
        }
    }

    if (geo.index) {
        const nFaces = geo.index.count / 3;
        for (let i = 0; i < nFaces; ++i) {
            const a = geo.index.array[3 * i];
            const b = geo.index.array[3 * i + 1];
            const c = geo.index.array[3 * i + 2];
            if (a !== undefined && b !== undefined && c !== undefined) {
                adj[a]?.push(b);
                adj[b]?.push(c);
                adj[c]?.push(a);

                verticesFaces[a]?.push(i);
                verticesFaces[b]?.push(i);
                verticesFaces[c]?.push(i);

                if (!cleanMesh) {
                    adj[b]?.push(a);
                    adj[c]?.push(b);
                    adj[a]?.push(c);
                }
            }
        }
    }
    const adjResult = cleanMesh ? adj : adj.map(arr => uniqBySetWithArrayFrom(arr));
    return { adjacencyVertexToVertex: adjResult, adjacencyFacesToVertex: verticesFaces };
}

// given a list of vertex indices
export function getConnectedFaces(vertIndices: number[], adjacenyFacesToVertex: AdjacencyMatrix): number[] {
    const neighborFaces: number[] = [];
    for (let j = 0; j < vertIndices.length; j++) {
        const ni = vertIndices[j];
        if (ni) {
            const ni_faces = adjacenyFacesToVertex[ni];
            ni_faces && ni_faces.forEach(n => neighborFaces.push(n));
        }
    }
    return uniqBySetWithArrayFrom(neighborFaces);
}

/*
 * Slight rework of the expand by radius and iterations
 * but without radius
 * */
export function expandVertexSelection(
    selectedVerts: number[],
    adjacencyVertexToVertex: AdjacencyMatrix,
    iterations: number,
): number[] {
    let currentRing: number[] = [...selectedVerts];
    const neighbors: number[] = [];
    const visited: Set<number> = new Set();

    for (let iter = 0; iter < iterations; ++iter) {
        const nextRing: number[] = [];
        for (let i = 0; i < currentRing.length; ++i) {
            const e = currentRing[i];

            if (e === undefined || visited.has(e)) {
                continue;
            }
            visited.add(e);
            neighbors.push(e);
            const adjs = adjacencyVertexToVertex[e]?.filter(vIndex => !visited.has(vIndex));
            if (adjs) {
                nextRing.push(...adjs);
            }
        }

        currentRing = nextRing;
        if (!nextRing.length) {
            break;
        }
    }
    return uniqBySetWithArrayFrom(neighbors);
}

type GetNeighborsData = {
    // The vertex id of the starting vertex
    mainHandle: number;
    // The number of vertices in the geometry
    numVertices: number;
    // The adjacency matrix, computed via buildMeshAdjacency
    adjacencyMatrix: AdjacencyMatrix;
    geometry: THREE.BufferGeometry;
    // Setting to 0 will just return mainHandle.
    maxRadiusMm: number;
    // If true, the getNeighbor function will exclude vertices marked as mask using the mask attribute
    excludeMask?: boolean;
    // The maximum number of rings to search for neighbors. Make sure its big enough to cover the region of interest (by distance from mainHandle)
    maxNumRings?: number;
};

// Finds all the neighboring vertex IDs of a given vertex, mainHandle.
// This is limited to be at most maxNumRings from the mainHandle.
// This works via a BFS, the cost of which is very cheap due to the adjacency matrix being pre-computed.
// https://en.wikipedia.org/wiki/Breadth-first_search
export function getNeighbors(data: GetNeighborsData): number[] {
    const { mainHandle, numVertices, adjacencyMatrix, geometry, maxRadiusMm, excludeMask, maxNumRings } = data;
    let maskArray: ArrayLike<number> = [];
    if (excludeMask) {
        // check if the sculpt mask attribute exists in the geometry
        const maskAttribute = geometry.attributes[AttributeName.SculptMask];
        maskArray = maskAttribute?.array ?? [];
    }

    let currentRing: number[] = [mainHandle];

    const startingPosition = getPosition(geometry, mainHandle);
    const visited: boolean[] = Array(numVertices).fill(false);

    let maxDistanceSeen: number = 0;
    const maxIterations = maxNumRings ?? 25;
    const neighbors: number[] = [];
    for (let iter = 0; iter < maxIterations; ++iter) {
        const nextRing: number[] = [];

        for (let i = 0; i < currentRing.length; ++i) {
            const e = currentRing[i];

            if (e === undefined || visited[e] || (excludeMask && maskArray[e])) {
                continue;
            }

            const position = getPosition(geometry, e);
            const distance = Math.abs(startingPosition.distanceTo(position));
            maxDistanceSeen = Math.max(distance, maxDistanceSeen);

            visited[e] = true;
            if (distance <= maxRadiusMm) {
                neighbors.push(e);
                const adjs = adjacencyMatrix[e]?.filter(vIndex => !visited[vIndex]);
                if (adjs) {
                    nextRing.push(...adjs);
                }
            }
        }
        currentRing = nextRing;
        if (!nextRing.length) {
            break;
        }
    }

    return neighbors;
}

export class NeighborDistance {
    constructor(vertexIndex: number, distance: number) {
        this.vertexIndex = vertexIndex;
        this.distance = distance;
    }
    vertexIndex: number;
    distance: number;
    originalVertexIndex: number = -1;
}

// Same like getNeighbors, but return the distances too.
export function getNeighborsWithDistance(data: GetNeighborsData): NeighborDistance[] {
    const { mainHandle, numVertices, adjacencyMatrix, geometry, maxRadiusMm, excludeMask, maxNumRings } = data;
    let maskArray: ArrayLike<number> = [];
    if (excludeMask) {
        // check if the sculpt mask attribute exists in the geometry
        const maskAttribute = geometry.attributes[AttributeName.SculptMask];
        maskArray = maskAttribute?.array ?? [];
    }

    let currentRing: number[] = [mainHandle];

    const startingPosition = getPosition(geometry, mainHandle);
    const visited: boolean[] = Array(numVertices).fill(false);

    let maxDistanceSeen: number = 0;
    const maxIterations = maxNumRings ?? 25;
    const neighbors: NeighborDistance[] = [];
    for (let iter = 0; iter < maxIterations; ++iter) {
        const nextRing: number[] = [];

        for (const e of currentRing) {
            if (e === undefined || visited[e] || (excludeMask && maskArray[e])) {
                continue;
            }

            const position = getPosition(geometry, e);
            const distance = Math.abs(startingPosition.distanceTo(position));
            maxDistanceSeen = Math.max(distance, maxDistanceSeen);

            visited[e] = true;
            if (distance <= maxRadiusMm) {
                neighbors.push(new NeighborDistance(e, distance));
                const adjs = adjacencyMatrix[e]?.filter(vIndex => !visited[vIndex]);
                if (adjs) {
                    nextRing.push(...adjs);
                }
            }
        }
        currentRing = nextRing;
        if (!nextRing.length) {
            break;
        }
    }

    return neighbors;
}

// given a list of vertex indices, return the list of edges on the boundary of the selection
export function getBoundaryEdges(vertIndices: number[], graph: MeshConnectivityGraph): [number, number][] {
    const vertIndicesSet = new Set(vertIndices);

    // boundary vertices are those that have at least one neighbor that is not in the selection
    const boundaryVerts = vertIndices.filter(v => {
        const neighbors = graph.adjacencyVertexToVertex[v];
        if (!neighbors) {
            return false;
        }
        return neighbors.some(n => !vertIndicesSet.has(n));
    });

    const edgesCountPerVertex: Map<number, number> = new Map();
    boundaryVerts.forEach(v => {
        const neighbors = graph.adjacencyVertexToVertex[v];
        if (!neighbors) {
            return;
        }
        const count = neighbors.filter(n => boundaryVerts.includes(n)).length;
        edgesCountPerVertex.set(v, count);
    });

    // convert them to edges
    const boundaryEdges: [number, number][] = [];
    boundaryVerts.forEach(v => {
        const neighbors = graph.adjacencyVertexToVertex[v];
        if (!neighbors) {
            return;
        }
        neighbors.forEach(n => {
            if (
                boundaryVerts.includes(n) &&
                !((edgesCountPerVertex.get(v) ?? 0) >= 3 && (edgesCountPerVertex.get(n) ?? 0) >= 3)
            ) {
                boundaryEdges.push([v, n]);
            }
        });
    });
    return boundaryEdges;
}
