import type { Annotation } from '../Annotations';
import { AnnotationListModal } from '../Annotations';
import { OrderDesignAnnotator } from '../Annotations/OrderDesignAnnotator';
import { DesignViewerLiteWrapper } from '../Waxups/DesignViewerLiteWrapper';
import type { RefabFlowLabOrder } from '@orthly/dentin';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { RootActionDialog } from '@orthly/ui';
import { FlossPalette, Text, stylesFactory, Chip, Button } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    column: {
        marginBottom: 0,
        paddingBottom: 0,
        [theme.breakpoints.up('md')]: {
            '&:first-child': {
                marginLeft: 0,
                paddingLeft: 0,
            },
            '&:last-child': {
                marginRight: 0,
                paddingRight: 0,
            },
        },
    },
    modelContainer: {
        position: `relative`,
        marginTop: `12px`,
    },
    markupButton: {
        position: `absolute`,
        right: `0`,
        top: `0`,
        width: `auto`,
    },
}));

interface RefabAnnotationsModalProps {
    order: RefabFlowLabOrder;
    refetchDesign?: () => Promise<unknown> | void;
    annotations: Annotation[];
    setAnnotations: (annotations: Annotation[]) => void;
    annotatorNotes: React.MutableRefObject<string>;
    isAnnotatorOpen: boolean;
    setIsAnnotatorOpen: (isAnnotatorOpen: boolean) => void;
    isAnnotationListModalOpen: boolean;
    setIsAnnotationListModalOpen: (isAnnotationListModalOpen: boolean) => void;
    latestDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    viewerHeight?: number;
}

export const RefabAnnotationsModal: React.VFC<RefabAnnotationsModalProps> = ({
    order,
    refetchDesign,
    annotations,
    setAnnotations,
    annotatorNotes,
    isAnnotatorOpen,
    setIsAnnotatorOpen,
    isAnnotationListModalOpen,
    setIsAnnotationListModalOpen,
    latestDesignFragment,
    viewerHeight = 450,
}) => {
    const classes = useStyles();
    return (
        <>
            <div>
                <div className={classes.modelContainer}>
                    <DesignViewerLiteWrapper
                        order={order}
                        designFragment={latestDesignFragment}
                        refetchDesign={refetchDesign}
                        style={{ height: viewerHeight }}
                    />
                    <Button
                        variant={'ghost'}
                        onClick={() => setIsAnnotatorOpen(true)}
                        endIcon={'PencilOutlinedIcon'}
                        className={classes.markupButton}
                    >
                        Markup this design preview
                    </Button>
                </div>
                <RootActionDialog
                    open={isAnnotatorOpen}
                    setOpen={setIsAnnotatorOpen}
                    showCloseButton
                    loading={false}
                    title={<Text variant={`h4`}>{'What would you like to markup?'}</Text>}
                    titleProps={{ style: { alignItems: 'center' } }}
                    fullHeight
                    content={
                        <OrderDesignAnnotator
                            order={order}
                            designFragment={latestDesignFragment}
                            onSave={async data => {
                                setAnnotations([...annotations, ...data.annotations]);
                                setIsAnnotatorOpen(false);
                            }}
                            onCancel={() => setIsAnnotatorOpen(false)}
                            trackingSource={'refab_annotation'}
                            refetchDesign={refetchDesign}
                            notesRef={annotatorNotes}
                            hideTextInput={true}
                        />
                    }
                    hideButton
                    dialogProps={{
                        maxWidth: 'lg',
                    }}
                    contentProps={{ style: { padding: 0 } }}
                    PaperProps={{ style: { height: 800, minHeight: 600 } }}
                />
            </div>
            {annotations.length > 0 && (
                <div style={{ marginLeft: `4px`, marginTop: `8px` }}>
                    <Chip
                        label={`${Format.pluralize('Screenshot', annotations.length)} saved`}
                        clickable={true}
                        onClick={() => setIsAnnotationListModalOpen(true)}
                        size={'small'}
                        style={{
                            backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
                            color: FlossPalette.SECONDARY_FOREGROUND,
                            marginRight: 16,
                        }}
                    />
                    <Button variant={'ghost'} onClick={() => setIsAnnotationListModalOpen(true)}>
                        View markups
                    </Button>
                </div>
            )}
            <AnnotationListModal
                annotations={annotations}
                open={isAnnotationListModalOpen}
                setOpen={setIsAnnotationListModalOpen}
                canRemove={true}
                onRemove={indexToRemove => {
                    const newAnnotationsList = annotations.filter((_, i) => i !== indexToRemove);

                    if (newAnnotationsList.length === 0) {
                        setIsAnnotationListModalOpen(false);
                    }
                    setAnnotations(newAnnotationsList);
                }}
            />
        </>
    );
};
