import type { FilesState, FileUploaderField } from './file-uploader-types';
import {
    Button,
    FlossPalette,
    FlossPaletteUtils,
    Text,
    createStyles,
    makeStyles,
    Grid,
    AttachFileIcon,
} from '@orthly/ui-primitives';
import React from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

const useStyles = makeStyles(() =>
    createStyles({
        dropzoneWrap: {
            padding: 20,
            minHeight: 100,
            border: `1px dashed ${FlossPalette.STAR_GRASS}`,
            borderRadius: 8,
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            cursor: 'pointer',
            background: FlossPaletteUtils.toRgba('PRIMARY_BACKGROUND', 0.5),
        },
    }),
);

interface SimpleDropzoneProps {
    options: DropzoneOptions;
    selectedFileName?: string;
    dropzoneContent?: React.ReactNode;
    preUploadText?: string;
    wrapperStyle?: React.CSSProperties;
    typographyStyle?: React.CSSProperties;
}

export function SimpleDropzone(props: SimpleDropzoneProps) {
    const classes = useStyles();
    const { getRootProps, getInputProps } = useDropzone(props.options);
    const { ref, ...rootProps } = getRootProps();
    return (
        <Grid {...rootProps} className={classes.dropzoneWrap} style={props.wrapperStyle} ref={ref}>
            <input id={'dandy-file-uploader'} data-testid={'dandy-file-uploader'} {...getInputProps()} />
            {!!props.dropzoneContent && props.dropzoneContent}
            {!props.dropzoneContent && (
                <>
                    {props.selectedFileName || props.preUploadText ? (
                        <Text variant={'body1'} color={'BLACK'} style={props.typographyStyle}>
                            {props.selectedFileName || props.preUploadText}
                        </Text>
                    ) : (
                        <Grid container direction={'row'} alignItems={'center'} justifyContent={'center'}>
                            <Text variant={'body2'}>Drag &amp; drop files here</Text>
                            <Text variant={'body2'} color={'GRAY'} style={{ margin: '0 8px' }}>
                                or
                            </Text>
                            <Button variant={'secondary'}>
                                Select files
                                <AttachFileIcon style={{ color: FlossPalette.PRIMARY_FOREGROUND }} />
                            </Button>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
}

export interface FileUploaderDropzoneProps<K extends string = string> {
    dropzoneOptions?: DropzoneOptions;
    dropzoneContent?: React.ReactNode;
    fileFields: FileUploaderField<K>[];
    setInputFiles: React.Dispatch<React.SetStateAction<File[]>>;
    setSelectionState: React.Dispatch<React.SetStateAction<FilesState<K>>>;
    selectionState: FilesState<K>;
    key?: string;
    wrapperStyle?: React.CSSProperties;
}

export function FileUploaderDropzone(props: FileUploaderDropzoneProps) {
    const classes = useStyles();
    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        ...props.dropzoneOptions,
        onDropAccepted: files => {
            props.setInputFiles(currentFiles => [...currentFiles, ...files]);
            const firstFileField = props.fileFields[0];
            const firstFile = files[0];
            const onlyOneFile = props.fileFields.length === 1 && files.length === 1;
            if (onlyOneFile && firstFile && firstFileField && !props.selectionState[firstFileField.fieldKey]) {
                props.setSelectionState({
                    ...props.selectionState,
                    [firstFileField.fieldKey]: firstFile.name,
                });
            }
        },
    });
    const { ref, ...rootProps } = getRootProps();
    return (
        <Grid {...rootProps} className={classes.dropzoneWrap} style={props.wrapperStyle} ref={ref}>
            <input {...getInputProps()} />
            <Text variant={'body2'} color={'BLACK'}>
                {props.dropzoneContent ?? `Drop files here, or click to select files`}
            </Text>
        </Grid>
    );
}
