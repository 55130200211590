import { useOrderDetailPreferences } from '../../hooks/useOrderDetailPreferences';
import { OrderDetailBlock } from './OrderDetailBlock';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import type { GridProps } from '@orthly/ui-primitives';
import { FlossPalette, Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

interface OrderDetailPreferencesProps {
    order: Pick<LabsGqlOrder, 'items_v2'>;
    action?: React.ReactNode;
}

export const OrderDetailRow: React.FC<{
    name: string;
    value: string;
    variant: 'sidebar' | 'standard';
    wrapperProps?: GridProps;
    tooltip?: React.ReactNode;
}> = props => {
    const isSidebarElement = props.variant === 'sidebar';
    return (
        <Grid container item xs={12} style={{ paddingTop: 4, paddingRight: 8 }} {...props.wrapperProps}>
            <Text variant={isSidebarElement ? 'caption' : 'body2'} color={'BLACK'}>
                <span style={{ color: FlossPalette.GRAY }}>{props.name}:&nbsp;</span>
                {props.value}
            </Text>
            {props.tooltip}
        </Grid>
    );
};

export const OrderPreferencesSidebarBlock: React.FC<OrderDetailPreferencesProps> = props => {
    const preferenceEntries = useOrderDetailPreferences(props.order.items_v2);
    return (
        <>
            {preferenceEntries.map(e => (
                <OrderDetailRow key={e.name} value={e.value} name={e.name} variant={'sidebar'} />
            ))}
        </>
    );
};

interface OrderDetailPreferencesBlockProps {
    order: Pick<LabsGqlOrder, 'items_v2'>;
    action?: React.ReactNode;
    showNumericValues?: boolean;
    isOps?: boolean;
    noPadding?: boolean;
}

export const OrderDetailPreferencesBlock: React.FC<OrderDetailPreferencesBlockProps> = props => {
    const preferenceEntries = useOrderDetailPreferences(props.order.items_v2, {
        showNumericValues: !!props.showNumericValues,
        isOps: !!props.isOps,
    });
    if (preferenceEntries.length === 0) {
        return null;
    }
    return (
        <OrderDetailBlock
            noPadding={props.noPadding}
            title={'Preferences'}
            variant={'full'}
            actions={props.action}
            rootStyle={{ paddingTop: 8, display: 'block' }}
        >
            {preferenceEntries.map(e => (
                <OrderDetailRow key={e.name} value={e.value} name={e.name} variant={'standard'} />
            ))}
        </OrderDetailBlock>
    );
};
