import type { FlossColor, TextColor } from '@orthly/ui-primitives';
import { stylesFactory, FlossPalette, Grid, Text, Button } from '@orthly/ui-primitives';
import React from 'react';

type OrderAlertBannerVariant = 'primary' | 'alert';

const VARIANT_TO_BACKGROUND_COLOR_MAP: { [K in OrderAlertBannerVariant]: FlossColor } = {
    primary: 'PRIMARY_BACKGROUND',
    alert: 'ATTENTION_BACKGROUND',
};

const VARIANT_TO_TEXT_COLOR_MAP: { [K in OrderAlertBannerVariant]: TextColor } = {
    primary: 'PRIMARY_FOREGROUND',
    alert: 'ATTENTION_FOREGROUND',
};

const useStyles = stylesFactory(() => ({
    root: ({ variant }: { variant: OrderAlertBannerVariant }) => ({
        backgroundColor: FlossPalette[VARIANT_TO_BACKGROUND_COLOR_MAP[variant]],
        height: 40,
        width: '100%',
        padding: '8px 16px',
    }),
    button: ({ variant }: { variant: OrderAlertBannerVariant }) => ({
        height: 24,
        textDecoration: variant === 'primary' ? 'underline' : 'none',
        '&:hover': {
            textDecoration: variant === 'primary' ? 'underline' : 'none',
        },
    }),
}));

interface OrderAlertBannerActionProps {
    label: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface OrderAlertBannerProps {
    variant: OrderAlertBannerVariant;
    title: string;
    actions: OrderAlertBannerActionProps[];
}

export const OrderAlertBanner: React.VFC<OrderAlertBannerProps> = ({ variant, title, actions }) => {
    const classes = useStyles({ variant });

    return (
        <Grid container direction={'row'} className={classes.root}>
            <Grid item xs>
                <Text variant={'body2'} medium color={VARIANT_TO_TEXT_COLOR_MAP[variant]}>
                    {title}
                </Text>
            </Grid>
            <Grid item>
                {actions.map(({ label, onClick }) => (
                    <Button
                        key={label}
                        variant={'ghost'}
                        className={classes.button}
                        onClick={onClick}
                        onAuxClick={onClick}
                    >
                        {label}
                    </Button>
                ))}
            </Grid>
        </Grid>
    );
};
