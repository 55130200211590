import { useShowMobileLayout } from '../../utils/LayoutUtils';
import { InvoicesTableFilters } from './components/InvoicesTableFilters';
import {
    SELECT_ALL_ORGANIZATIONS,
    useInvoicesTableContext,
} from './components/providers/InvoicesTableProvider.graphql';
import type { PracticeInvoice } from './invoicing.types';
import { useNextInvoicingDateDetails } from './invoicing.utils';
import { PaymentAction, paymentActionId } from './payments/components/PaymentAction.graphql';
import { useInvoiceStyles } from './styles';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import { Button, FlossPalette, Grid, Text, styled, useScreenIsMobile } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import dayjs from 'dayjs';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';

const FlexContainer = styled('div')({
    display: 'flex',
});

interface TopSummaryItemProps {
    title: React.ReactNode;
    amount: React.ReactNode;
    detail?: React.ReactNode;
    Action?: React.FC;
    dataTest?: string;
}

const TopSummaryItemInfo: React.FC<TopSummaryItemProps> = ({ Action, title, amount, detail }) => (
    <>
        <FlexContainer>
            <Text variant={'body2'} style={{ fontWeight: 600 }}>
                {title}
            </Text>
        </FlexContainer>
        <Text variant={'h5'} style={{ paddingTop: 5 }}>
            {amount}
        </Text>
        {Action && <Action />}
        {detail && (
            <Text variant={'caption'} color={'GRAY'}>
                {detail}
            </Text>
        )}
    </>
);

const StyledSummaryCard = styled('div')({
    display: 'flex',
    flexShrink: 0,
    width: '288px',
    padding: '16px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
    borderRadius: '16px',
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    background: FlossPalette.TAN,
    '& + &': {
        marginLeft: '20px',
    },
});

const TopSummaryItem: React.FC<TopSummaryItemProps> = ({ dataTest, ...props }) => (
    <StyledSummaryCard data-test={dataTest}>
        <TopSummaryItemInfo {...props} />
    </StyledSummaryCard>
);

const MinimumCommitmentContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
});

interface MinimumCommitmentProps {
    usageAmountCents: number;
    activeMinimumCommitmentCents: number;
    isMultiMonthTerm: boolean;
}

const MinimumCommitmentMessage: React.FC<MinimumCommitmentProps> = ({
    activeMinimumCommitmentCents,
    usageAmountCents,
    isMultiMonthTerm,
}) => {
    const term = isMultiMonthTerm ? 'cycle' : 'month';
    const message = React.useMemo(() => {
        if (usageAmountCents > activeMinimumCommitmentCents) {
            const amountToBeInvoiced = Format.currency(Math.max(usageAmountCents));
            return `You'll be invoiced ${amountToBeInvoiced} this month`;
        }
        const commitmentText = isMultiMonthTerm ? 'Aggregate minimum spend' : 'Minimum commitment';
        return `${commitmentText}: ${Format.currency(activeMinimumCommitmentCents)}`;
    }, [usageAmountCents, activeMinimumCommitmentCents, isMultiMonthTerm]);

    if (activeMinimumCommitmentCents <= 0) {
        return null;
    }

    const remainingSpend = activeMinimumCommitmentCents - usageAmountCents;

    const remainingSpendMessage =
        remainingSpend > 0
            ? `You can still spend ${Format.currency(remainingSpend)} with no extra charge.`
            : `You've hit your minimum spend this ${term}.`;
    return (
        <MinimumCommitmentContainer>
            {message}
            <br />
            {remainingSpendMessage}
        </MinimumCommitmentContainer>
    );
};

const CalculatePracticeAggregateSpendOverPeriod_Query = graphql(`
    query CalculatePracticeAggregateSpendOverPeriod(
        $partnerId: String!
        $periodStart: DateTime!
        $periodEnd: DateTime!
    ) {
        calculatePracticeAggregateSpendOverPeriod(
            partnerId: $partnerId
            periodStart: $periodStart
            periodEnd: $periodEnd
        )
    }
`);

// wrapping the button text since we don't know how long practice names might be
const StyledButton = styled(Button)({
    textWrap: 'wrap',
});

const SummaryPaymentItem: React.FC<{
    totalBalanceDue: number;
    unpaidInvoices: PracticeInvoice[];
}> = ({ totalBalanceDue, unpaidInvoices }) => {
    const { isPracticePrimaryForContract, associatedPractices, selectedOrganizationId } = useInvoicesTableContext();

    let paymentText = 'Pay invoice';
    let dataTestText = 'invoices-table-summary-payment-button';
    // if there *are* multiple locations on the contract, the primary can filter by location
    // if a location is selected, indicate that they're only paying off invoices for the selected practice
    if (isPracticePrimaryForContract && associatedPractices.length > 1) {
        const selectedPracticeName = associatedPractices.find(p => p.id === selectedOrganizationId)?.name;
        const isNotFilteredByLocation = selectedOrganizationId === SELECT_ALL_ORGANIZATIONS || !selectedPracticeName;
        const locationText = isNotFilteredByLocation ? 'across all locations' : `for ${selectedPracticeName}`;
        const invoiceText = unpaidInvoices.length > 1 ? 'invoices' : 'invoice';
        paymentText = `Pay ${invoiceText} ${locationText}`;
        dataTestText += isNotFilteredByLocation ? '-all-locations' : '-single-location';
    } else {
        // if the current user is not the primary practice, OR there aren't multiple locations on the contract
        // then they can only see/pay their own invoices. they'll still be able to pay multiple invoices at once,
        // we just don't need to differentiate between location names
        if (unpaidInvoices.length > 1) {
            paymentText = 'Pay all open invoices';
        }
    }

    return (
        <TopSummaryItem
            dataTest={'amount-due-summary'}
            title={'Your amount due'}
            amount={Format.currency(totalBalanceDue)}
            Action={() =>
                !!unpaidInvoices.length ? (
                    <PaymentAction
                        invoiceIds={unpaidInvoices.map(i => i.id)}
                        CustomButton={({ onClick }) => (
                            <StyledButton
                                variant={'primary'}
                                onClick={onClick}
                                id={paymentActionId}
                                data-test={dataTestText}
                            >
                                {paymentText}
                            </StyledButton>
                        )}
                    />
                ) : null
            }
        />
    );
};

const SummarySpendItem: React.FC<{ isMultiMonthTerm: boolean }> = ({ isMultiMonthTerm }) => {
    const {
        pendingItems,
        currentSpendTerm,
        isPracticePrimaryForContract,
        associatedPractices,
        selectedOrganizationId,
    } = useInvoicesTableContext();

    let spendTitle = `Spend this ${isMultiMonthTerm ? 'cycle' : 'month'}`;
    // associatedPractices includes the primary practice, so we need to check if there are more than one
    const hasAssociatedPractices = isPracticePrimaryForContract && associatedPractices.length > 1;
    if (hasAssociatedPractices) {
        if (selectedOrganizationId !== SELECT_ALL_ORGANIZATIONS) {
            const selectedPracticeName = associatedPractices.find(p => p.id === selectedOrganizationId)?.name;
            spendTitle += ` (for ${selectedPracticeName})`;
        } else {
            spendTitle += ' (all locations)';
        }
    }

    return (
        <TopSummaryItem
            dataTest={'minimum-commitment-summary'}
            title={spendTitle}
            amount={Format.currency(pendingItems.pending_total_cents)}
            detail={
                <MinimumCommitmentMessage
                    usageAmountCents={pendingItems.pending_total_cents}
                    activeMinimumCommitmentCents={currentSpendTerm?.spend_minimum_cents ?? 0}
                    isMultiMonthTerm={isMultiMonthTerm}
                />
            }
        />
    );
};

// TODO: EPDB-975: delete legacy minimum spend components
const MultiMonthSpendSummary: React.FC<{
    daysTilNextInvoiceDue: number;
    startDate?: string | null;
    endDate?: string | null;
    minimumSpendCents: number;
}> = ({ daysTilNextInvoiceDue, startDate, endDate, minimumSpendCents }) => {
    const { partnerId } = useInvoicesTableContext();
    const { data: aggregateSpendData, loading } = useQuery(CalculatePracticeAggregateSpendOverPeriod_Query, {
        // a multi-month spend term must have both start and end dates, so we should never
        // fall back to an empty string (or skip). adding to satisfy ts/eslint since
        // tehcnically null dates are valid for inactive spend terms (or terms in perpetuity)
        variables: {
            partnerId,
            periodStart: startDate ?? '',
            periodEnd: endDate ?? '',
        },
        skip: !startDate || !endDate,
    });

    const currentAggregateSpend = aggregateSpendData?.calculatePracticeAggregateSpendOverPeriod ?? 0;

    return (
        <LoadBlocker
            blocking={loading}
            ContainerProps={{ style: { display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' } }}
        >
            <TopSummaryItem
                title={'Remaining contract duration'}
                amount={endDate ? moment(new Date(endDate)).fromNow(true) : 'n/a'}
                detail={endDate ? `Ends ${moment(new Date(endDate)).format('MMMM Do yyyy')}` : ''}
            />
            <TopSummaryItem
                title={'Remaining contracted total'}
                amount={Format.currency(minimumSpendCents - currentAggregateSpend)}
                detail={`${Format.currency(currentAggregateSpend)} est. spend so far`}
            />
            <TopSummaryItem
                title={'Your next invoice will be sent in'}
                amount={`${daysTilNextInvoiceDue} days`}
                detail={'(automatically charged on the 8th of every month)'}
            />
        </LoadBlocker>
    );
};

interface ContainerProps {
    isMobile: boolean;
    isMultiLocEnabled: boolean;
}

const Container = styled('div', {
    shouldForwardProp: p => p !== 'isMobile' && p !== 'isMultiLocEnabled',
})<ContainerProps>(({ isMobile, isMultiLocEnabled }) => {
    let padding;
    if (isMobile || isMultiLocEnabled) {
        padding = '0px';
    } else {
        padding = `20px 50px`;
    }

    return { padding };
});

const TitleContainer = styled('div')({
    display: 'flex',
    flexDirection: 'row',
    height: '64px',
    padding: '20px 24px',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
});

const SummaryContainer = styled('div', {
    shouldForwardProp: p => p !== 'isMobile' && p !== 'isMultiLocEnabled',
})<ContainerProps>(({ isMobile, isMultiLocEnabled }) => {
    let padding;
    if (isMobile) {
        padding = '12px';
    } else {
        padding = isMultiLocEnabled ? '20px 24px' : undefined;
    }

    return {
        flexWrap: 'nowrap',
        width: isMobile ? 'fit-content' : undefined,
        padding,
        display: 'flex',
        flexDirection: 'row',
    };
});

interface InvoicesTableHeaderProps {
    lastInvoiceDate?: Date;
}

export const InvoicesTableHeader: React.FC<InvoicesTableHeaderProps> = ({ lastInvoiceDate }) => {
    const { value: enableMultiLocationContracts = false } = useFeatureFlag('enableMultiLocationContracts');
    const { invoices, loading, pendingItems, autochargeEnabled, currentSpendTerm } = useInvoicesTableContext();
    const isMobileLayout = useShowMobileLayout();
    const classes = useInvoiceStyles();
    const isMobile = useScreenIsMobile();

    const { nextInvoiceDue, invoiceDueDayOfMonth } = useNextInvoicingDateDetails();
    const ordersSince = lastInvoiceDate ? dayjs(lastInvoiceDate).format('MM/DD/YYYY') : undefined;
    const daysTillNextInvoiceDue = React.useMemo(() => {
        return nextInvoiceDue.endOf('day').diff(dayjs().utc().endOf('day'), 'days');
    }, [nextInvoiceDue]);

    const unpaidInvoices = invoices.filter(i => i.status === 'open' && i.pending_payment_amount_cents === 0);
    const totalBalanceDue = _.sumBy(unpaidInvoices, i => i.amount_due);

    const isMultiMonthTerm = !!currentSpendTerm && currentSpendTerm.spend_cycle_length > 1;
    const headerMessage = isMultiMonthTerm ? 'Your contract overview' : 'Your Dandy contract';

    return (
        <Container isMobile={isMobile} isMultiLocEnabled={enableMultiLocationContracts}>
            {enableMultiLocationContracts ? (
                <TitleContainer>
                    <Text variant={'h5'} medium data-test={'billing-table-title'}>
                        Billing
                    </Text>
                    <InvoicesTableFilters />
                </TitleContainer>
            ) : (
                <Grid
                    container
                    style={{ padding: isMobile ? '24px' : '12px 0' }}
                    className={isMobileLayout ? '' : classes.tableHeader}
                >
                    <Text variant={'h4'} data-test={'billing-table-title'}>
                        {headerMessage}
                    </Text>
                </Grid>
            )}

            <LoadBlocker
                blocking={loading}
                ContainerProps={{
                    style: {
                        paddingBottom: '20px',
                        overflowX: 'scroll',
                    },
                }}
                overlayColor={FlossPalette.TAN}
            >
                <SummaryContainer isMobile={isMobile} isMultiLocEnabled={enableMultiLocationContracts}>
                    {!enableMultiLocationContracts && isMultiMonthTerm ? (
                        <MultiMonthSpendSummary
                            daysTilNextInvoiceDue={daysTillNextInvoiceDue}
                            startDate={currentSpendTerm.effective_start_date}
                            endDate={currentSpendTerm.effective_end_date}
                            minimumSpendCents={currentSpendTerm.spend_minimum_cents}
                        />
                    ) : (
                        <>
                            {enableMultiLocationContracts && (
                                <SummaryPaymentItem totalBalanceDue={totalBalanceDue} unpaidInvoices={unpaidInvoices} />
                            )}
                            <TopSummaryItem
                                title={
                                    autochargeEnabled
                                        ? `You'll be charged in`
                                        : `Next invoice ${totalBalanceDue ? 'will be charged' : 'due'} in`
                                }
                                amount={
                                    daysTillNextInvoiceDue === 0
                                        ? 'Today'
                                        : `${Format.pluralize('day', daysTillNextInvoiceDue)}`
                                }
                                detail={`Automatically charged on the ${invoiceDueDayOfMonth}th of every month`}
                            />
                            <SummarySpendItem isMultiMonthTerm={isMultiMonthTerm} />
                            <TopSummaryItem
                                title={ordersSince ? 'Orders since last invoice' : 'Orders on next invoice'}
                                amount={pendingItems.pending_orders_count}
                                detail={ordersSince ? <>Last Invoice was sent on {ordersSince}</> : undefined}
                            />
                        </>
                    )}
                </SummaryContainer>
            </LoadBlocker>
        </Container>
    );
};
