import { CustomPLYLoader } from './DandyPLYLoader';
import type { PlyFile } from './PLYLoader';
import { STLExporter } from './STLExporter';
import { STLLoader } from './STLLoader';
import type { StlFile } from './STLLoader';
import * as THREE from 'three';

export async function loadPlyFile(plyBuffer: ArrayBuffer): Promise<PlyFile | null> {
    const scanLoader = new CustomPLYLoader();
    const plyObject = await scanLoader.loadAsync(plyBuffer, false);
    if (!plyObject.geometry.attributes.position) {
        return null;
    }
    return plyObject;
}

export async function loadStlFile(stlBuffer: ArrayBuffer): Promise<StlFile | null> {
    const loader = new STLLoader();
    const stlObject = await loader.loadAsync(stlBuffer);

    if (!stlObject.geometry.attributes.position) {
        return null;
    }
    return stlObject;
}

export function meshToSTLBinary(geometry: THREE.BufferGeometry): DataView | undefined {
    const dummyMaterial = new THREE.MeshBasicMaterial();
    const exporter = new STLExporter();

    // exporter requires a Mesh, not just geometry
    const tmpMesh = new THREE.Mesh(geometry, dummyMaterial);
    const stlBinary = exporter.parse(tmpMesh, { binary: true });

    dummyMaterial.dispose();
    if (stlBinary instanceof DataView) {
        return stlBinary;
    }
    return undefined;
}
