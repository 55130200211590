import { FlossPalette, createStyles, makeStyles } from '@orthly/ui-primitives';

export const QC_TOOLBAR_BUTTON_STYLE = {
    backgroundColor: FlossPalette.TAN,
    borderRadius: 0,
    '&.active': {
        backgroundColor: FlossPalette.DARK_TAN,
    },
    '@media (hover: none)': {
        '&:hover': {
            // Match the non-hover border.
            border: '1px solid transparent',
        },
    },
};

export const useQcToolbarStyles = makeStyles(() =>
    createStyles({
        panel: {
            borderRadius: '4px',
            width: '40px',
        },
        qcToolsContainer: {},
        mainMenu: {
            float: 'left',
            marginRight: 8,
        },
        flexColumn: {
            display: 'flex',
            flexDirection: 'column',
        },
        flexRow: {
            display: 'flex',
            flexDirection: 'row',
        },
        iconButton: QC_TOOLBAR_BUTTON_STYLE,
    }),
);
