import { usePracticeAppSelector } from '../../../../redux';
import { useOrderAction } from '../../../../redux/orders/orders.actions';
import { useOpenOrderDetailPage } from '../../../labs/LabsUtils';
import { useAskDoctorResponseDialog } from '../../../labs/order-detail-v2/components/AskDoctorResponse';
import { getAskDoctorV2Configuration } from '../../../labs/order-detail-v2/components/AskDoctorResponse/utils';
import { PracticeSidebarStatusCount_Query } from '../../../labs/orders-v2/useOrderStatusCounts.graphql';
import { ReviewThisOrderEntrypoint } from './Reviews/ReviewThisOrder';
import { ShowGivenOrderFeedback } from './Reviews/ShowGivenOrderFeedback';
import { useApolloClient } from '@apollo/client';
import { OrderSummaryRow, getOrderSummaryRowIssue, OrderSummaryRowButtons } from '@orthly/dentin';
import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import {
    PracticeOrderIdsByStatusDocument,
    PracticeInboxActiveOrdersDocument,
    OrderPracticePreviewsByIdsDocument,
} from '@orthly/graphql-react';
import { LabsGqlWorkflowStateEnum, LabsGqlLabOrderStatus, LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { OrderDetailTrackerV2, useFeatureFlag } from '@orthly/veneer';
import React from 'react';

interface PracticeOrderSummaryRowProps {
    order: LabsGqlOrderPreviewFragment;
    fromInbox?: boolean;
    isLast?: boolean;
    onOpen?: () => void;
}

const PracticeStepperSectionV2: React.VFC<{ order: LabsGqlOrderPreviewFragment }> = ({ order }) => {
    const session = useSession();
    const { value: enableDesignPreviewETA } = useFeatureFlag('enableDesignPreviewETA');

    if (order.workflow_state.state === LabsGqlWorkflowStateEnum.Delivered) {
        if (order.review_submission) {
            return (
                <ShowGivenOrderFeedback
                    order={order}
                    review_submission={order.review_submission}
                    delivery_date={order.delivery_date}
                />
            );
        }

        return (
            <ReviewThisOrderEntrypoint
                orderId={order.id}
                canRefabricate={order.can_refabricate}
                canSubmitFeedback={order.can_submit_feedback}
                canSubmitTryInFeedback={order.can_submit_tryin_feedback}
                delivery_date={order.delivery_date}
            />
        );
    }

    return (
        <OrderDetailTrackerV2
            order={order}
            orderTrackerEntries={order.order_tracker_entries}
            isPractice={session?.organization_type === 'practice'}
            isLab={session?.organization_type === 'lab'}
            showDesignPreviewETA={enableDesignPreviewETA}
        />
    );
};

const orderStatusToPracticeOrderStatus: Record<LabsGqlLabOrderStatus, LabsGqlPracticeOrderStatus> = {
    [LabsGqlLabOrderStatus.Cancelled]: LabsGqlPracticeOrderStatus.Cancelled,
    [LabsGqlLabOrderStatus.OnHold]: LabsGqlPracticeOrderStatus.OnHold,
    [LabsGqlLabOrderStatus.Fabrication]: LabsGqlPracticeOrderStatus.Fabrication,
    [LabsGqlLabOrderStatus.Delivered]: LabsGqlPracticeOrderStatus.Delivered,
    [LabsGqlLabOrderStatus.NeedsRefabrication]: LabsGqlPracticeOrderStatus.All,
    [LabsGqlLabOrderStatus.NeedsReview]: LabsGqlPracticeOrderStatus.New,
    [LabsGqlLabOrderStatus.Shipped]: LabsGqlPracticeOrderStatus.Shipped,
    [LabsGqlLabOrderStatus.New]: LabsGqlPracticeOrderStatus.New,
};

function useOnOrderSummaryRowEdited(isInbox: boolean) {
    const client = useApolloClient();

    const setListPageStatus = useOrderAction('CHANGE_STATUS');
    const currentOrderListStatus = usePracticeAppSelector(s => s.orders.status);
    // if we're on the order list page and the edited order is now in a different status than the page is on, we want
    // to change the screen so it shows up
    const shouldUpdateOrderListStatus = !isInbox && currentOrderListStatus !== LabsGqlPracticeOrderStatus.All;
    return React.useCallback(
        (newOrderStatus?: LabsGqlLabOrderStatus) => {
            const newScreenStatus = newOrderStatus
                ? orderStatusToPracticeOrderStatus[newOrderStatus]
                : currentOrderListStatus;
            // if we're not in the inbox, and the new order status is different from the current screen, we want to change the screen
            if (shouldUpdateOrderListStatus && newScreenStatus !== currentOrderListStatus) {
                setListPageStatus(newScreenStatus);
                // we can exit here because changing the screen will refetch the list view and status counts
                return;
            }
            // If we're in the inbox, we refetch the InboxActiveOrders query
            // For the list view, we want to refetch the sidebar counts and the IDs in the list view (to trigger a refetch of order details)
            const queriesToRefetch = isInbox
                ? [PracticeInboxActiveOrdersDocument]
                : [
                      PracticeOrderIdsByStatusDocument,
                      PracticeSidebarStatusCount_Query,
                      OrderPracticePreviewsByIdsDocument,
                  ];
            void client.refetchQueries({ include: queriesToRefetch });
        },
        [client, currentOrderListStatus, isInbox, setListPageStatus, shouldUpdateOrderListStatus],
    );
}

export const PracticeOrderSummaryRow: React.VFC<PracticeOrderSummaryRowProps> = ({
    order,
    fromInbox,
    isLast,
    onOpen,
}) => {
    const isInbox = fromInbox ?? true;
    const openOrder = useOpenOrderDetailPage();
    const warningMessage = getOrderSummaryRowIssue(order);

    const { doctorRequestId, shouldShowResolveButton } = getAskDoctorV2Configuration(order);
    const onDoctorResponseSuccess = useOnOrderSummaryRowEdited(isInbox);
    const [setAskDoctorResponseOpen, askDoctorResponseDialog] = useAskDoctorResponseDialog({
        doctorRequestId,
        order,
        onSuccess: onDoctorResponseSuccess,
    });

    const handleClick = React.useCallback(() => {
        return onOpen ? onOpen() : openOrder(order.id);
    }, [onOpen, openOrder, order.id]);

    return (
        <>
            <OrderSummaryRow
                order={order}
                onClick={handleClick}
                openOrder={openOrder}
                stepperSection={<PracticeStepperSectionV2 order={order} />}
                warningMessage={warningMessage}
                isLast={isLast}
                isInbox={isInbox}
                Buttons={
                    <OrderSummaryRowButtons
                        setAskDoctorResponseOpen={setAskDoctorResponseOpen}
                        order={order}
                        shouldShowResolveButton={shouldShowResolveButton}
                    />
                }
            />
            {askDoctorResponseDialog}
        </>
    );
};
