import type { StaffMemberSession } from '../context';
import { useSession } from '../context/StaffMemberSessionProvider';
import type { IOrganizationType, StaffRole, RetainerScope, RetainerActionsForScope } from '@orthly/retainer-common';
import { RetainerAuthorizationUtils, doesStaffHaveCapability } from '@orthly/retainer-common';
import React from 'react';

export type SessionTestFn = (session: StaffMemberSession | undefined) => boolean;

interface RetainerSessionGuardProps {
    children: React.ReactNode;
    test: (session: StaffMemberSession | undefined) => boolean;
    fallback?: React.ReactNode;
}

export const SessionGuard: React.FC<RetainerSessionGuardProps> = props => {
    const { test } = props;
    const session = useSession();
    const testPassed = React.useMemo(() => test(session), [session, test]);
    return <>{testPassed ? props.children : props.fallback ?? null}</>;
};

export function useHasCapability<Scope extends RetainerScope>(
    scope: Scope,
    action: RetainerActionsForScope<Scope>,
): boolean {
    const session = useSession();

    return React.useMemo(() => {
        return !!session && doesStaffHaveCapability(session, scope, action);
    }, [session, scope, action]);
}

type RetainerCapabilityGuardProps<Scope extends RetainerScope = RetainerScope> = {
    scope: Scope;
    action: RetainerActionsForScope<Scope>;
    children: React.ReactNode;
    fallback?: React.ReactNode;
};

export const RetainerCapabilityGuard: React.FC<RetainerCapabilityGuardProps> = ({
    scope,
    action,
    children,
    fallback,
}) => {
    const hasCapability = useHasCapability(scope, action);

    return <>{hasCapability ? children : fallback ?? null}</>;
};

export class SessionGuardUtils {
    static hasCapability<Scope extends RetainerScope>(
        scope: Scope,
        action: RetainerActionsForScope<Scope>,
    ): SessionTestFn {
        return (session: StaffMemberSession | undefined): boolean => doesStaffHaveCapability(session, scope, action);
    }

    static hasRole(allowedRoles: StaffRole[]): SessionTestFn {
        return (session: StaffMemberSession | undefined): boolean =>
            RetainerAuthorizationUtils.staffHasRole(session, allowedRoles);
    }

    static doesNotHaveRole(bannedRoles: StaffRole[]): SessionTestFn {
        return (session: StaffMemberSession | undefined): boolean =>
            !RetainerAuthorizationUtils.staffHasRole(session, bannedRoles);
    }

    // Returns true if the user has none of `bannedRoles` OR at least one of `exceptionRoles`.
    // For example, you can ban all users who have a `Designer` role, unless they also have an `Admin` role.
    static doesNotHaveBannedRoleOrHasExceptionRole(
        bannedRoles: StaffRole[],
        exceptionRoles: StaffRole[],
    ): SessionTestFn {
        return (session: StaffMemberSession | undefined): boolean =>
            SessionGuardUtils.doesNotHaveRole(bannedRoles)(session) ||
            SessionGuardUtils.hasRole(exceptionRoles)(session);
    }

    static hasOrgType(allowedOrgTypes: IOrganizationType[]): SessionTestFn {
        return (session: StaffMemberSession | undefined): boolean =>
            RetainerAuthorizationUtils.staffHasOrgType(session, allowedOrgTypes);
    }

    static sessionExists: SessionTestFn = (session: StaffMemberSession | undefined): boolean => {
        return !!session;
    };

    static compose(...sessionTests: SessionTestFn[]): SessionTestFn {
        return session => {
            const failingTest = sessionTests.find(sessionTest => !sessionTest(session));
            return failingTest === undefined;
        };
    }
}
