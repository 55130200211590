export enum Direction {
    North = 'North',
    NorthEast = 'NorthEast',
    East = 'East',
    SouthEast = 'SouthEast',
    South = 'South',
    SouthWest = 'SouthWest',
    West = 'West',
    NorthWest = 'NorthWest',
}
