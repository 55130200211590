import type { IndexedBufferGeometry } from './BufferGeometry.types';
import type { AdjacencyMatrix, MeshConnectivityGraph } from './MeshConnectivityGraph';
import { isArrayN } from '@orthly/runtime-utils';
import _ from 'lodash';

/**
 * Finds islands (disconnected subgraphs) in a graph.
 * @param adjacency Lists the neighbors of each vertex, i.e. the edges of the graph
 * @param sort If true, the islands will be sorted by size in descending order
 * @returns An array of islands in the graph. If the graph is fully connected, this will be an array of length 1.
 */
export function findIslands(adjacency: AdjacencyMatrix, sort: boolean = true): number[][] {
    const n = adjacency.length;
    // What follows is a basic implementation of a disjoint sets data structure, this array
    // represents a forest of trees where each index is a node and the value at that index is the
    // parent of that node. A root node of a tree is one that is its own parent. We initialize the
    // forest where each tree is a single node.
    const ds = Array.from({ length: n })
        .fill(0)
        .map((_, i) => i);
    // The root operation, given a node index, finds the index of the root node of the tree the
    // index belongs to.
    const root = (i: number) => {
        let j = i;
        while (j !== ds[j]) {
            j = ds[j] as number;
        }
        return j as number;
    };
    // The union operation joins the two trees the given node indices belong to.
    const union = (i: number, j: number) => {
        let fi = root(i);
        let fj = root(j);
        // If both i and j are in the same tree, nothing to do
        if (fi === fj) {
            return;
        }
        if (fi > fj) {
            [fi, fj] = [fj, fi];
        }
        // Make fi the parent of fj
        ds[fj] = fi;
    };

    // Doing a pairwise union of every edge in the graph results in our forest containing one tree
    // for each connected component (island).
    adjacency.forEach((neighbors, i) => {
        neighbors.forEach(j => {
            union(i, j);
        });
    });

    // We now need to extract each tree that remains in our forest. This is accomplished by mapping
    // root indices to arrays of nodes that appear in that tree.
    const islandSets = new Map<number, number[]>();

    // Because the indices are examined in ascending order this has the bonus effect of each island
    // having its indices in ascending order with no duplicates.
    for (let i = 0; i < n; i += 1) {
        const k = root(i);
        const island = islandSets.get(k);
        if (island === undefined) {
            islandSets.set(k, [i]);
        } else {
            island.push(i);
        }
    }

    const islands = Array.from(islandSets.values());

    if (sort) {
        islands.sort((a, b) => b.length - a.length);
    }

    return islands;
}

/**
 * gets a connected path in the graph using sparse margin points as initial points
 * @param graph The graph to search
 * @param points The initial margin points
 * @returns The connected path of vertices in the graph
 */
export function getConnectedMarginPath(graph: MeshConnectivityGraph, points: number[]): number[] {
    const path: number[] = [];
    for (let i = 0; i < points.length; i++) {
        const point = points[i];
        const nextPoint = points[(i + 1) % points.length];
        if (point === undefined || nextPoint === undefined) {
            continue;
        }
        const connectedPath = getShortestPath(graph, point, nextPoint);
        path.push(...connectedPath);
    }
    return path;
}

/**
 * Gets the shortest path between two vertices in a graph.
 * @param graph The graph to search
 * @param start The index of the starting vertex
 * @param end The index of the ending vertex
 * @returns The shortest path between the two vertices, or an empty array if no path exists
 */
export function getShortestPath(graph: MeshConnectivityGraph, start: number, end: number): number[] {
    const visited = new Set<number>();
    const queue: [number, number[]][] = [[start, [start]]];
    while (queue.length > 0) {
        const first = queue.shift();
        if (!first) {
            continue;
        }
        const [current, path] = first;
        visited.add(current);
        if (current === end) {
            return path;
        }
        const neighbors = graph.adjacencyVertexToVertex[current];
        if (!neighbors) {
            continue;
        }
        for (const neighbor of neighbors) {
            if (!visited.has(neighbor)) {
                queue.push([neighbor, [...path, neighbor]]);
            }
        }
    }
    return [];
}

/**
 * Disconnects a loop from a graph by removing the vertices and edges that are part of the loop.
 * @param adjacencyVertexToVertex The vertexToVertex adjacency matrix to modify
 * @param vertices The vertices that are part of the loop
 * */
export function disconnectLoopFromGraph(adjacencyVertexToVertex: AdjacencyMatrix, vertices: number[]) {
    vertices.forEach((v, i) => {
        const neighbors = adjacencyVertexToVertex[v];
        if (!neighbors) {
            return;
        }
        // remove v from the neighbors
        adjacencyVertexToVertex[v] = [];
        for (const neighbor of neighbors) {
            const preVertices = adjacencyVertexToVertex[neighbor];
            if (!preVertices) {
                continue;
            }
            adjacencyVertexToVertex[neighbor] = preVertices.filter(v => v !== vertices[i]);
        }
    });
}

export type TriangleEdgeIndex = 0 | 1 | 2;
const EDGE_PAIRS = [
    [0, 1],
    [1, 2],
    [2, 0],
] as const;

export function getEdgeVerts(
    geom: IndexedBufferGeometry,
    fIdx: number,
    edge: TriangleEdgeIndex,
): readonly [number, number] {
    const [e0, e1] = EDGE_PAIRS[edge];
    return [geom.index.getX(3 * fIdx + e0), geom.index.getX(3 * fIdx + e1)];
}

export function findOppositeTriangle(
    geom: IndexedBufferGeometry,
    graph: MeshConnectivityGraph,
    fIdx: number,
    edge: TriangleEdgeIndex,
): number | undefined {
    const [iv1, iv2] = getEdgeVerts(geom, fIdx, edge);
    const faces1 = graph.adjacencyFacesToVertex[iv1];
    const faces2 = graph.adjacencyFacesToVertex[iv2];
    if (!faces1 || !faces2) {
        return undefined;
    }

    // get shared faces
    const sharedFaces = _.intersection(faces1, faces2).filter(f => f !== fIdx);
    return isArrayN(sharedFaces, 1) ? sharedFaces[0] : undefined;
}
