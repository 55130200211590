import logo from '../../../../assets/logo/LogoDark.svg';
import { Logo, Page, PageMessage, ReturnLink } from './PageLayout.styles';
import { PanelContent } from './components/PanelContent';
import { Text } from '@orthly/ui-primitives';
import React from 'react';

export const PageLayout: React.FC<
    Parameters<typeof PanelContent>[0] & {
        pageMessage: string;
    }
> = ({ pageMessage, ...panelContentProps }) => {
    return (
        <Page>
            <Logo alt={'Dandy logo'} src={logo} />
            <ReturnLink href={'https://www.meetdandy.com/'}>
                <Text bold color={'PRIMARY_FOREGROUND'} variant={'body2'}>
                    Return to meetdandy.com
                </Text>
            </ReturnLink>
            <PageMessage variant={'h1'} color={'DARK_TAN'}>
                {pageMessage}
            </PageMessage>
            <PanelContent {...panelContentProps} />
        </Page>
    );
};
