import type { IOrderItemV2DTO, IPartialDentureItem } from '@orthly/items';
import _ from 'lodash';

/**
 * Retrieves a preference value from the order item
 * @param item The order item to retrieve a preference from
 * @param pref The ID of the preference to extract from the item
 * @returns the value of the preference we wanted to retrieve, or null if it does not exist on the item
 */
function getPreferenceWithId(item: IOrderItemV2DTO, pref: string): string | undefined {
    const stainingPref = item.preference_fields.find(field => field.field_id === pref);
    return stainingPref?.value ? `${stainingPref?.value}` : undefined;
}

const PreferenceIds = {
    mfgStandard: 'partial-manufacturing-preference-order-item-meta',
    mfgAcrylic: 'partial-manufacturing-preference-flexible-acrylic-order-item-meta',
    metalClasp: 'metal-clasp-options-order-item-meta',
    acrylicMaterial: 'partial-flexible-options-order-item-meta',
    metalMaterial: 'partial-metal-options-order-item-meta',
    metalTissueShade: 'partial-tissue-shade-non-flexible-order-item-meta',
    acrylicTissueShade: 'partial-tissue-shade-order-item-meta',
    coverage: 'partial-coverage-type-order-item-meta',
    partialIsNew: 'new-vs-existing-partial-order-item-meta',
    digitalExtraction: 'digital-extraction-for-partial-order-item-meta',
    acetalClaspShade: 'partial-acetal-clasp-shade-order-item-meta',
    teethSelection: 'partial-teeth-selection-order-item-meta',
} as const;
/** Partials preference fields to exclude from the "Nonstandard Preferences" section
 *   Note that there's a number of them that are duplicated, because Metal and Acrylic
 *   partials seem to use different preference fields.
 */

export const PARTIALS_PREFERENCE_FIELDS = new Set<string>([
    ...Object.values(PreferenceIds),

    'partial-metal-clasp-preference-order-item-meta', // Handled in the notes section
]);

export function getPartialsPrefValues(item: IPartialDentureItem) {
    return _.mapValues(PreferenceIds, id => getPreferenceWithId(item, id));
}
