import { useCurrentSessionIsAdmin } from '../../../../redux/selectors';
import { RemoveItemModal } from '../../components/RemoveItemModal';
import { SendInviteEmailModal } from '../../components/SendInviteEmailModal.graphql';
import { useDeleteEmployeePreferences, useDeleteStaffMember } from '../../state/usePracticeStaffControls';
import { EditStaffRolesModal } from './EditStaffRolesModal';
import type { StaffUser } from './useFetchPracticeStaffUsers.graphql';
import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { PhoneNumberUtils, Format } from '@orthly/runtime-utils';
import { useSession } from '@orthly/session-client';
import { LoadBlocker, PencilOutlinedIcon } from '@orthly/ui';
import {
    FlossPalette,
    useScreenIsMobile,
    stylesFactory,
    Grid,
    CheckboxPrimitive as Checkbox,
    IconButton,
    TablePrimitive as Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableSortLabel,
    Icon,
    Text,
    styled,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    prefRow: {
        '&:hover': { background: FlossPalette.TAN },
    },
    sortArrow: {
        verticalAlign: 'sub',
        '& .MuiTableSortLabel-icon': {
            width: 12,
        },
    },
    entriesSelected: {
        padding: '0 24px',
        borderRight: `1px solid ${FlossPalette.DARK_TAN}`,
    },
}));

const formatFullName = (staffUser: StaffUser) => {
    return [staffUser.first_name, staffUser.last_name].filter(Boolean).join(' ');
};

const getStaffUserStatus = (staffUser: StaffUser) => {
    if (staffUser.is_active) {
        return 'Active';
    }
    if (staffUser.invite_sent) {
        return 'Invited';
    }

    return 'Pending';
};

type StaffUserWithStatus = StaffUser & { status: ReturnType<typeof getStaffUserStatus> };

interface EmployeePreferencesTableProps {
    staffUsers: StaffUser[] | undefined;
    loading: boolean;
    refetch?: () => Promise<void>;
    selectStaffMember: (staffUser: StaffUser) => void;
    onInviteAction: (firstName: string) => void;
}

interface EmployeePreferencesTableCellProps {
    field: EmployeePreferencesTableFields;
    staffUser: StaffUserWithStatus;
}

interface EmployeePreferencesRowProps {
    staffUser: StaffUserWithStatus;
    refetch?: () => Promise<any>;
    selectStaffMember: (staffUser: StaffUserWithStatus) => void;
    isStaffMemberSelected: boolean;
    onClickStaffMember: (event: React.ChangeEvent<HTMLInputElement>, staffUser: StaffUserWithStatus) => void;
    onInviteAction: (firstName: string) => void;
}

enum EmployeePreferencesTableFields {
    name = 'name',
    status = 'status',
    login_phone = 'login_phone',
    login_email = 'login_email',
}
const EmployeePreferencesTableLabels: { [K in EmployeePreferencesTableFields]: string } = {
    name: 'Name',
    status: 'Status',
    login_phone: 'Login mobile phone',
    login_email: 'Login email address',
};

const EmployeePreferencesTableCellContent: React.FC<EmployeePreferencesTableCellProps> = ({ field, staffUser }) => {
    switch (field) {
        case EmployeePreferencesTableFields.name:
            return (
                <Grid item data-test={'staff-name'}>
                    <Text variant={'body2'}>
                        {staffUser.roles.includes(LabsGqlStaffRolePractice.PracticeDoctor) && (
                            <span style={{ color: FlossPalette.GRAY }}>Dr. </span>
                        )}
                        {formatFullName(staffUser)}
                    </Text>
                </Grid>
            );
        case EmployeePreferencesTableFields.status:
            return (
                <Grid item data-test={'staff-status'}>
                    {staffUser.is_active ? (
                        <Text variant={'body2'} color={'STAR_GRASS'} medium>
                            Active
                        </Text>
                    ) : (
                        <Text variant={'body2'} color={'GRAY'} medium>
                            {staffUser.invite_sent ? 'Invited' : 'Not yet logged in'}
                        </Text>
                    )}
                </Grid>
            );
        case EmployeePreferencesTableFields.login_phone:
            return (
                <>
                    {staffUser.login_phone ? (
                        PhoneNumberUtils.prettyPhoneNumber(staffUser.login_phone)
                    ) : (
                        <Text variant={'body2'} color={'GRAY'} medium>
                            Not set
                        </Text>
                    )}
                </>
            );
        case EmployeePreferencesTableFields.login_email:
            // Don't display the placeholder email if the user has not set their own email address
            return (
                <>
                    {/@hasnotsetemail.meetdandy.com$/.test(staffUser.login_email) ? (
                        <Text variant={'body2'} color={'GRAY'} medium>
                            Not set
                        </Text>
                    ) : (
                        staffUser.login_email
                    )}
                </>
            );
        default:
            field satisfies never;
            return null;
    }
};

const EmployeePreferencesTableCell: React.FC<EmployeePreferencesTableCellProps> = props => {
    return (
        <TableCell style={{ whiteSpace: 'nowrap' }}>
            <EmployeePreferencesTableCellContent {...props} />
        </TableCell>
    );
};

const IconsCell = styled(TableCell)({
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
});

const EmployeePreferencesRow: React.FC<EmployeePreferencesRowProps> = props => {
    const { staffUser, refetch, selectStaffMember, isStaffMemberSelected, onClickStaffMember, onInviteAction } = props;
    const classes = useStyles();
    const { submit: submitDeletePrefs } = useDeleteEmployeePreferences(refetch);
    const session = useSession();
    const isAdmin = useCurrentSessionIsAdmin();
    const showInviteButton = isAdmin && !staffUser.is_active;

    return (
        <TableRow className={classes.prefRow} data-test={'staff-row'}>
            {isAdmin && (
                <TableCell padding={'checkbox'} data-test={'staff-select-checbox'}>
                    <Checkbox
                        color={'secondary'}
                        checked={isStaffMemberSelected}
                        onChange={event => onClickStaffMember(event, staffUser)}
                    />
                </TableCell>
            )}
            {Object.values(EmployeePreferencesTableFields).map((field, valIdx) => (
                <EmployeePreferencesTableCell key={valIdx} field={field} staffUser={staffUser} />
            ))}
            <IconsCell>
                {isAdmin && (
                    <IconButton
                        onClick={() => selectStaffMember(staffUser)}
                        style={{ color: FlossPalette.GRAY }}
                        data-test={'staff-edit-button'}
                    >
                        <PencilOutlinedIcon />
                    </IconButton>
                )}
                {isAdmin && session?.id !== staffUser.staff_member_id && (
                    <RemoveItemModal
                        title={`Are you sure you want to remove ${formatFullName(staffUser)} from your practice?`}
                        subtitle={'This action is permanent and cannot be undone.'}
                        confirmRemoveText={'Remove from practice'}
                        removeItem={async () => {
                            await submitDeletePrefs({ preferences_id: staffUser.doctor_preference_id });
                        }}
                        buttonProps={{ 'data-test': 'staff-remove-button' }}
                    />
                )}
                {showInviteButton && <SendInviteEmailModal staffUser={staffUser} onInviteAction={onInviteAction} />}
            </IconsCell>
        </TableRow>
    );
};

export const EmployeePreferencesTable: React.FC<EmployeePreferencesTableProps> = ({
    staffUsers,
    refetch,
    loading,
    selectStaffMember,
    onInviteAction,
}) => {
    const classes = useStyles();
    const session = useSession();
    const isAdmin = useCurrentSessionIsAdmin();
    const isMobile = useScreenIsMobile();
    const { submit: submitDeletePrefs, submitting: submittingDeletePrefs } = useDeleteEmployeePreferences();
    const { submit: submitDeleteRole, submitting: submittingDeleteRole } = useDeleteStaffMember();
    const staffUsersWithStatus =
        staffUsers?.map<StaffUserWithStatus>(staffUser => ({
            ...staffUser,
            status: getStaffUserStatus(staffUser),
        })) ?? [];
    const [selectedStaffUsers, setSelectedStaffUsers] = React.useState<StaffUserWithStatus[]>([]);
    const [sortColumn, setSortColumn] = React.useState<{
        columnName: EmployeePreferencesTableFields;
        sortDirection: 'asc' | 'desc';
    }>({
        columnName: EmployeePreferencesTableFields.name,
        sortDirection: 'asc',
    });
    const toggleSortDirection = () => {
        setSortColumn({ ...sortColumn, sortDirection: sortColumn.sortDirection === 'asc' ? 'desc' : 'asc' });
    };
    const handleSortClick = (columnName: EmployeePreferencesTableFields) => {
        if (columnName === sortColumn.columnName) {
            toggleSortDirection();
        } else {
            setSortColumn({ columnName, sortDirection: 'asc' });
        }
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setSelectedStaffUsers(staffUsersWithStatus);
            return;
        }
        setSelectedStaffUsers([]);
    };
    const handleClickStaffMember = (event: React.ChangeEvent<HTMLInputElement>, staffUser: StaffUserWithStatus) => {
        if (event.target.checked) {
            setSelectedStaffUsers([...selectedStaffUsers, staffUser]);
            return;
        }
        const newSelectedStaffMembers = selectedStaffUsers.filter(
            selectedStaffUser => selectedStaffUser.doctor_preference_id !== staffUser.doctor_preference_id,
        );
        setSelectedStaffUsers(newSelectedStaffMembers);
    };
    const sortedPreferences = _.orderBy(staffUsersWithStatus, [sortColumn.columnName], [sortColumn.sortDirection]);
    const rowCount = sortedPreferences.length;
    const numSelected = selectedStaffUsers.length;
    const numSelectedText = Format.pluralize('staff member', numSelected);

    /* don't show bulk delete if one of the selected prefs is the session user's pref */
    const isRemovable = !selectedStaffUsers.some(staffUser => staffUser.staff_member_id === session?.id);

    return (
        <Grid container>
            <LoadBlocker blocking={loading}>
                {isAdmin && numSelected > 0 && (
                    <Grid
                        container
                        alignItems={'center'}
                        style={{ borderBottom: `1px solid ${FlossPalette.DARK_TAN}` }}
                    >
                        <Grid item className={classes.entriesSelected}>
                            <Text variant={'body2'} color={'GRAY'}>
                                {Format.pluralize('entry', numSelected, 'entries')} selected
                            </Text>
                        </Grid>
                        <EditStaffRolesModal
                            staffUsers={selectedStaffUsers}
                            openModalButtonText={'Edit positions'}
                            title={'Edit positions'}
                            subtitle={`You're about to edit the positions assigned to ${numSelectedText}`}
                            confirmButtonText={isMobile ? 'Update' : 'Update positions'}
                        />
                        {isRemovable && (
                            <RemoveItemModal
                                title={`Are you sure you want to remove ${numSelectedText}?`}
                                subtitle={`You're about to delete ${Format.joinWithAnd(
                                    selectedStaffUsers.map(formatFullName),
                                )}. This action is permanent and cannot be undone.`}
                                confirmRemoveText={isMobile ? 'Remove' : 'Remove from practice'}
                                removeItem={async () => {
                                    await Promise.all(
                                        selectedStaffUsers.map(async staffUser => {
                                            await submitDeletePrefs({ preferences_id: staffUser.doctor_preference_id });
                                            const { partner_id, staff_member_id } = staffUser;
                                            if (partner_id && staff_member_id) {
                                                await submitDeleteRole({
                                                    organization_id: partner_id,
                                                    member_id: staff_member_id,
                                                });
                                            }
                                        }),
                                    );
                                    void refetch?.();
                                    setSelectedStaffUsers([]);
                                }}
                                openModalButtonText={'Delete'}
                                loading={submittingDeleteRole || submittingDeletePrefs}
                            />
                        )}
                    </Grid>
                )}
                <TableContainer>
                    <Table>
                        <TableHead style={{ backgroundColor: FlossPalette.TAN }}>
                            <TableRow>
                                {isAdmin && (
                                    <TableCell padding={'checkbox'}>
                                        <Checkbox
                                            color={'secondary'}
                                            indeterminate={numSelected > 0 && numSelected < rowCount}
                                            checked={rowCount > 0 && numSelected === rowCount}
                                            onChange={handleSelectAllClick}
                                            inputProps={{
                                                'aria-label': 'select all staff',
                                            }}
                                        />
                                    </TableCell>
                                )}
                                {Object.values(EmployeePreferencesTableFields).map(field => (
                                    <TableCell
                                        key={field}
                                        variant={'head'}
                                        style={{ color: FlossPalette.GRAY, cursor: 'pointer' }}
                                        onClick={() => handleSortClick(field)}
                                    >
                                        {EmployeePreferencesTableLabels[field]}
                                        <TableSortLabel
                                            className={clsx('sort-label', classes.sortArrow)}
                                            active={field === sortColumn.columnName}
                                            direction={
                                                field === sortColumn.columnName ? sortColumn.sortDirection : undefined
                                            }
                                            IconComponent={props => <Icon icon={'ArrowDownIcon'} {...props} />}
                                        />
                                    </TableCell>
                                ))}
                                {/* add an extra cell for the delete icon */}
                                <TableCell variant={'head'} />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedPreferences.map(staffUser => {
                                const staffMemberSelected = selectedStaffUsers.some(
                                    selectedPref =>
                                        selectedPref.doctor_preference_id === staffUser.doctor_preference_id,
                                );
                                return (
                                    <EmployeePreferencesRow
                                        key={staffUser.staff_member_id}
                                        staffUser={staffUser}
                                        refetch={refetch}
                                        selectStaffMember={selectStaffMember}
                                        isStaffMemberSelected={staffMemberSelected}
                                        onClickStaffMember={handleClickStaffMember}
                                        onInviteAction={onInviteAction}
                                    />
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </LoadBlocker>
        </Grid>
    );
};
