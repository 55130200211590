import { graphql } from '@orthly/graphql-inline-react';

export const GetStoragePathConfig_Query = graphql(`
    query GetStoragePathConfig_Query($whichConfig: String!, $uploadType: String!, $paths: [String!]!) {
        getStoragePathConfig(whichConfig: $whichConfig, uploadType: $uploadType, paths: $paths) {
            bucketName
            path
        }
    }
`);
