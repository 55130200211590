import * as THREE from 'three';

/**
 * These utility functions convert between the vector and quaternion representations of the insertion direction. In the
 * quaternion representation, the insertion axis is aligned with the positive x-axis of the frame defined by the
 * quaternion.
 */

const X_AXIS = new THREE.Vector3(1, 0, 0);

export function getInsertionAxisFromOrientation(orientation: THREE.Quaternion): THREE.Vector3 {
    return X_AXIS.clone().applyQuaternion(orientation);
}

export function getInsertionOrientationFromAxis(axis: THREE.Vector3): THREE.Quaternion {
    return new THREE.Quaternion().setFromUnitVectors(X_AXIS, axis);
}
