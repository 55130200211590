import { useOpenOrderDetailPage } from '../../../LabsUtils';
import { AskDoctorResponseDialogContent } from './AskDoctorResponseDialogContent.graphql';
import { GetDoctorRequestDocument, useApolloRefetch, useOrder, useOrderRefetch } from '@orthly/graphql-react';
import { type LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { makeLazyDialog, RootActionDialog } from '@orthly/ui';
import type { OrderForOrderTracker } from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React from 'react';

export interface AskDoctorResponseDialogProps {
    doctorRequestId: string | null;
    order: OrderForOrderTracker;
    onSuccess?: (newOrderStatus?: LabsGqlLabOrderStatus) => void;
}

export const useAskDoctorResponseDialog = makeLazyDialog<AskDoctorResponseDialogProps>(
    ({ open, setOpen, doctorRequestId, order, onSuccess }) => {
        const { enqueueSnackbar } = useSnackbar();

        const openOrder = useOpenOrderDetailPage();

        const refetch = useApolloRefetch();
        // This is used to refresh the data displayed on the order page without requiring each consumer of this hook to provide the refetchOrder function
        const refreshPageData = useOrderRefetch();

        // Only using this hook to access a refetchOrder that returns the order, hence why we always skip.
        const { refetch: refetchOrder } = useOrder(order.id, { skip: true });

        // When we open this modal, doctorRequestId will always be defined, but I still check on it to clean up the type to be string only downstream.
        if (!doctorRequestId) {
            return null;
        }

        return (
            <RootActionDialog
                title={`Review order issue`}
                open={open}
                setOpen={setOpen}
                loading={false}
                hideButton
                showCloseButton
                PaperProps={{ style: { width: 700, maxWidth: 700, maxHeight: '95vh', scrollbarWidth: 'thin' } }}
                content={
                    <AskDoctorResponseDialogContent
                        order={order}
                        closeDialog={() => setOpen(false)}
                        doctorRequestId={doctorRequestId}
                        onSuccess={async () => {
                            refetch.query(GetDoctorRequestDocument);

                            const updatedOrder = await refetchOrder();
                            // We check if the order ended up being resubmitted; if it has we want to direct the user to the new order for a better UX
                            const resubmittedOrderId = updatedOrder?.data?.lab_order?.replaced_by_ids?.[0];

                            enqueueSnackbar(
                                `Successfully responded to the order issue!${
                                    resubmittedOrderId ? 'Opening the new order' : ''
                                }`,
                                { variant: 'success' },
                            );
                            setOpen(false);

                            if (resubmittedOrderId) {
                                openOrder(resubmittedOrderId);
                            } else {
                                refreshPageData(order.id);
                            }
                            onSuccess?.(updatedOrder?.data.lab_order.status);
                        }}
                    />
                }
            />
        );
    },
);
