import type { MarginEditingApp } from '../ModelViewer/MarginMesh';
import { useQcToolbarStyles } from './QCToolbar.hooks';
import { IconToggleButton } from '@orthly/ui';
import { Text, Card, IconButton, EditIcon } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

/**
 * This toolbar selects between major view elements and mode.
 *
 * @component
 */
type MarginEditToolbarProps = {
    marginEditingApp: MarginEditingApp;
};

export const MarginEditToolbar: React.FC<MarginEditToolbarProps> = props => {
    const { marginEditingApp } = props;

    const styles = useQcToolbarStyles();

    const { marginLines, activeTooth, setActiveMargin } = marginEditingApp;

    // cycle active margin
    const cycleActiveMargin = React.useCallback(() => {
        if (activeTooth === undefined) {
            setActiveMargin(marginLines[0]?.tooth);
            return;
        }

        const currentIndex = marginLines.map(mL => mL.tooth).indexOf(activeTooth ?? 0);
        if (currentIndex === -1) {
            setActiveMargin(marginLines[0]?.tooth);
            return;
        }
        const nMargins = marginLines.length;
        const nextIndex = currentIndex === nMargins - 1 ? 0 : currentIndex + 1;
        setActiveMargin(marginLines[nextIndex]?.tooth);
    }, [marginLines, activeTooth, setActiveMargin]);

    return (
        <div className={styles.qcToolsContainer}>
            <Card raised={true} className={clsx(styles.panel, styles.mainMenu)}>
                <div className={styles.flexColumn}>
                    <IconToggleButton
                        tooltip={'Cycle active margin'}
                        active={true}
                        className={styles.iconButton}
                        onClick={cycleActiveMargin}
                    >
                        <EditIcon />
                    </IconToggleButton>

                    {marginEditingApp.activeTooth && (
                        <IconButton onClick={() => {}}>
                            <Text variant={'body1'}>{marginEditingApp.activeTooth}</Text>
                        </IconButton>
                    )}
                </div>
            </Card>
        </div>
    );
};
