/* eslint-disable max-lines */
import { AttributeName } from '../../Utils3D';
import { logger } from '../../Utils/Logger';
import {
    maybeGetNodeWithName,
    maybeGetNodeWithTag,
    maybeGetNodeWithType,
    maybeParseVector3,
} from '../DentalDesignerModellingTree';
import type { DecryptOptions } from './Dcm.utils';
import {
    BytesPerVert,
    base64ToFacetMarks,
    base64ToFacets,
    base64ToPaddedVertices,
    base64ToSplinePoints,
    base64ToUint8Array,
    base64ToVertexTextureCoords,
    base64ToVertices,
    facetMarksToBase64,
    facetsArrayToBase64,
    normalizePackageLockList,
    splinePointsToBase64,
    texCoordsToBase64,
    transformVectorObj,
    transformVectorOrientation,
    unnFromFacetMark,
    vertCoordsToBase64,
} from './Dcm.utils';
import { concatKeys, getDefaultKey, getReversedKey, getSalt } from './DcmEncryption';
import type { AnnotationType, CommentType } from './DcmParsing';
import { AnnotationSchema, CommentSchema, DcmSchema } from './DcmParsing';
import type { BinarySplineType } from './ThreeShapeSplineSchemas';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import type { DesignMetadataScanTooth } from '@orthly/shared-types';
import { isLength16Array } from '@orthly/shared-types';
import md5 from 'blueimp-md5';
import CryptoJS from 'crypto-js';
import _ from 'lodash';
import * as THREE from 'three';
import { create as createXML } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';
import type { z } from 'zod';

// These are curves used for margin display and parametric generation
// of the margin region.  There are other curves, eg MorphPoints but
// for now we type our expected inputs.
const dcmCadCurveNames = ['InterfacePrepLineCrv', 'PrepLineCrv', 'OverlayCrv_0', 'OverlayCrv_1'];
type DcmCadCurveNames = (typeof dcmCadCurveNames)[number];

const FACET_MARKS_ATTRIBUTE_NAME = 'facetMarks';

type RESTORATIVE_TRANSFORM_NAMES = 'TransformationFromLibraryCoordinates';
type PRINTED_MODEL_TRANSFORM_NAMES =
    | 'AlignToBiteTransformation'
    | 'OcclusalPlaneTransformation'
    | 'MBOCSTransformationID';
type SCANNER_SCAN_TRANSFORM_NAMES = 'Focus2FinalTrans';
type DENTAL_DESIGNER_SCAN_TRANSFORM_NAMES =
    | 'Focus2FinalTrans'
    | 'AlignToBiteTransformation'
    | 'OcclusalPlaneTransformation'
    | 'TransformationFromAlignmentCoordinatesGlobal';

type TransformName =
    | RESTORATIVE_TRANSFORM_NAMES
    | PRINTED_MODEL_TRANSFORM_NAMES
    | SCANNER_SCAN_TRANSFORM_NAMES
    | DENTAL_DESIGNER_SCAN_TRANSFORM_NAMES;

export interface DcmSpline {
    name: string;
    points: THREE.Vector3[];
    iMisc1: string;
    pointsHash: string;
}

interface MarginLineSpline {
    unn: ToothNumber;
    points: THREE.Vector3[];
}

export interface PrepMark {
    origin: THREE.Vector3;
    unn: number;
    text: string | number;
    details: string;
    sectioned?: boolean;
}

export interface TextureImage {
    b64Data: string;
    width: number;
    height: number;
    name: string;
}

export interface BufferGeometryOptions {
    /** Whether to attempt to parse texture coordinates. */
    applyTextureCoords?: boolean;
    /**
     * A palette function that maps facet marks to a color or 'default', which will generate a
     * new color for each unique mark it encounters.
     */
    applyFacetPalette?: ((mark: number) => THREE.Color) | 'default';
}
export interface BufferGeometryResult {
    buffer: THREE.BufferGeometry;
    /**
     * Vertices may be split in some circumstances to support per facet
     * properties. If so, each set in this array will contain the new indices
     * of the split vertices.
     */
    vertexSets?: Set<number>[];
    /**
     * Maps a vertex to the index of the set it belongs to in `vertexSets`.
     */
    vertexSetIndex?: number[];
}

const DETAILS_PREPMARK_REGEX = /Preparation_\d+$/;

export interface DcmXmlPreprocessingOpts {
    removeShadeMatchEntries?: boolean;
    // When set to true, will set the ScanSource of the DCM to `ssTRIOS`, and will compute a SignatureHash.
    // When set to false or emitted, the current value of ScanSource will be left as is, and SignatureHash will be deleted.
    setScanSource?: boolean;
}

export interface DcmXmlPreprocessingResults {
    removedShadeMatchEntries: boolean;
}

export interface MorphPointsData {
    name: string;
    points: THREE.Vector3[];
}

type DCMObject = z.infer<typeof DcmSchema>;

export class DcmGeometryInjector {
    dcmObject: DCMObject;

    preprocessingResults: DcmXmlPreprocessingResults = { removedShadeMatchEntries: false };

    // This is a copy of the XMLBuilder after all modifications from the constructor have been done.
    // This should not be modified further, as `dcmObject` will not be kept in sync with it.
    readonly processedXML: XMLBuilder;

    // The mount direction, read from the `vnMountDir` Vector node. This will not exist in all DCM files, e.g. a scan.
    // A value of `null` indicates that we have not yet attempted to parse the mount direction. A value of `undefined`
    // indcates that we have attempted and failed to parse the mount direction.
    private mountDirection_: THREE.Vector3 | null | undefined = null;

    private mountPoint_: THREE.Vector3 | null | undefined = null;

    private constructor(donorXML: string, xmlPreProcessingOpts?: DcmXmlPreprocessingOpts) {
        // modifies rawXML in destructive way
        const rawXML = createXML(donorXML);
        this.preProcessXml(rawXML, xmlPreProcessingOpts);

        // We create an initial version of the DCM so that we can recompute the signature hash.
        // We may end up deleting the signature hash outright, but `updateSignatureHash` will ultimately mutate rawXML.
        // Hence, we still need to recompute the final dcmObject later.
        // This is kind of messy (though not really expensive yet), and should probably be better handled in the future.
        this.dcmObject = this.computeDcmObject(rawXML);
        this.updateSignatureHash(rawXML, !!xmlPreProcessingOpts?.setScanSource);
        this.dcmObject = this.computeDcmObject(rawXML);

        this.processedXML = rawXML;
    }

    // Given a raw XMLBuilder object, performs necessary modifications to produce a valid object representation of the XML.
    private computeDcmObject(rawXML: XMLBuilder): DCMObject {
        // preprocess the Annotations from XML into the Object because
        // xml builder creates strange nesting when mixed arrays
        // arrive in odd order, eg [Comment, Annotation, Annotation, Comment]
        const initialObject = rawXML.toObject();
        const annotation = this.preprocessAnnotationsAndComments(rawXML);
        _.set(initialObject, 'HPS.Annotations', annotation);
        return DcmSchema.parse(initialObject);
    }

    preProcessXml(rawXML: XMLBuilder, opts: DcmXmlPreprocessingOpts | undefined) {
        if (!opts) {
            return;
        }

        if (opts.removeShadeMatchEntries) {
            let numRemoved = 0;
            let shadeMeasurement = maybeGetNodeWithType(rawXML, 'TSysObjectShadeMeasurement');
            while (shadeMeasurement) {
                logger.info('Removing shade measurement');
                shadeMeasurement.remove();
                numRemoved++;
                shadeMeasurement = maybeGetNodeWithType(rawXML, 'TSysObjectShadeMeasurement');
                if (numRemoved > 100) {
                    logger.warn('Too many shade measurements to remove');
                    break;
                }
            }

            if (numRemoved > 0) {
                this.preprocessingResults = { ...this.preprocessingResults, removedShadeMatchEntries: true };
            }
        }

        if (opts?.setScanSource) {
            this.updateScanSourceProperty(rawXML);
        }
    }

    private updateScanSourceProperty(rawXML: XMLBuilder) {
        const properties = maybeGetNodeWithTag(rawXML, 'Properties');

        // This is a malformed DCM, let's just assume the person knows what they're doing
        if (!properties) {
            return;
        }

        const existingScanSourceProperty = maybeGetNodeWithName(properties, 'ScanSource');
        if (existingScanSourceProperty) {
            existingScanSourceProperty.remove();
        }

        properties.ele('Property', {
            name: 'ScanSource',
            value: 'ssTRIOS',
        });
    }

    /*
     * Computes the signature hash of the current XML state.
     * This is imperative to be set if we want shiny models.
     */
    computeSignatureHash(): string {
        const decryptedVertices = this.getVerticesDecryptedBuffer();
        const propsFormatted = Object.entries(this.parseAllProperties())
            .map(([key, value]) => `${key}=${value}\r\n`)
            .join('');

        // We encode this as a base64 string because CryptoJS only takes a string or WordArray as an argument.
        // Unfortunately, strings poorly represents binary data, which causes this to break.
        // Therefore, we encode it as base64, and then allow CryptoJS to convert it to a WordArray.
        const buffer = Buffer.concat([decryptedVertices, Buffer.from(propsFormatted, 'utf8')]).toString('base64');
        return CryptoJS.SHA1(CryptoJS.enc.Base64.parse(buffer)).toString(CryptoJS.enc.Hex).toUpperCase();
    }

    /*
     * If `shouldWriteNewHash` is set to true, recomputes and rewrites the `SignatureHash` field of the XML.
     * Otherwise, will remove it from the XML entirely.
     */
    private updateSignatureHash(rawXML: XMLBuilder, shouldWriteNewHash: boolean) {
        const hps = maybeGetNodeWithTag(rawXML, 'HPS');

        if (!hps) {
            return;
        }

        const signatureHash = maybeGetNodeWithTag(rawXML, 'SignatureHash');
        signatureHash?.remove();

        if (shouldWriteNewHash) {
            const hash = this.computeSignatureHash();
            hps?.ele('SignatureHash').txt(hash);
        }
    }

    static tryBuildDCM(xml: string, xmlPreProcessingOps?: DcmXmlPreprocessingOpts): DcmGeometryInjector | undefined {
        try {
            return new DcmGeometryInjector(xml, xmlPreProcessingOps);
        } catch (e) {
            logger.warn('Failed to parse the DCM xml', e);
            return undefined;
        }
    }

    prepareForInjection() {
        // set the schema to CA
        this.dcmObject.HPS.Packed_geometry.Schema = 'CA';
        // if the schema was CE, change it CA
        if (this.dcmObject.HPS.Packed_geometry.Binary_data.CE) {
            const CE = this.dcmObject.HPS.Packed_geometry.Binary_data.CE;
            const CA = { '@version': '1.0', Facets: CE.Facets, Vertices: CE.Vertices };

            // create/assign the CA Structure
            this.dcmObject.HPS.Packed_geometry.Binary_data.CA = CA;

            // remove the CE, as we are switching to CA
            delete this.dcmObject.HPS.Packed_geometry.Binary_data.CE;
        }
    }

    injectGeometryToDcm(geometry: THREE.BufferGeometry, writeFacesAndTexture: boolean = false): void {
        const CA = this.dcmObject.HPS.Packed_geometry.Binary_data.CA;
        if (!CA) {
            throw new Error('Must be CA Schema DCM, use prepareForInjection method first');
        }
        const { vertsBase64, vertexCount, encodedBytes: vertBytes } = vertCoordsToBase64(geometry);

        if (vertexCount !== CA.Vertices['@vertex_count'] && !writeFacesAndTexture) {
            throw new Error(`Non Equal Vertex Array: ${CA.Vertices['@vertex_count']} ${vertexCount}`);
        }
        CA.Vertices = { '@vertex_count': vertexCount, '@base64_encoded_bytes': vertBytes, '#': vertsBase64 };

        if (!writeFacesAndTexture) {
            return;
        }

        const { facetsBase64, facetCount, encodedBytes: facetBytes } = facetsArrayToBase64(geometry);
        CA.Facets = {
            '@facet_count': facetCount,
            '@base64_encoded_bytes': facetBytes,
            '#': facetsBase64,
        };

        const texData = this.dcmObject.HPS.TextureData2;
        if (!texData) {
            return;
        }

        if (!geometry.getAttribute(AttributeName.TexCoord)) {
            // If we cannot find the uv attribute from which to update the texture data, we should delete it rather than
            // leave it in an invalid state.
            delete this.dcmObject.HPS.TextureData2;
            return;
        }

        // The @Base64EncodedBytes field is the number of packed bytes *before* base64 encoding is applied.
        const { uvBase64, encodedBytes: texCoordBytes } = texCoordsToBase64(geometry);
        texData.PerVertexTextureCoord = { '#': uvBase64, '@Base64EncodedBytes': texCoordBytes };
    }

    // helper util to get this as array
    getSplinesArray(): BinarySplineType[] {
        const splinesObject = _.get(this.dcmObject, 'HPS.Splines.Object');
        if (!splinesObject) {
            return [];
        }
        return Array.isArray(splinesObject) ? splinesObject : [splinesObject];
    }

    getSplineWithName(curveName: string) {
        const splinesObject = _.get(this.dcmObject, 'HPS.Splines.Object');
        if (!splinesObject) {
            return undefined;
        }
        const splinesArray = Array.isArray(splinesObject) ? splinesObject : [splinesObject];

        const targetSpline = splinesArray.find(spline => {
            const propertyObject = spline?.Property;
            const propertyArray = Array.isArray(propertyObject) ? propertyObject : [propertyObject];

            const nameProp = propertyArray.find(p => {
                return p && p['@name'] === 'Name';
            });
            if (nameProp && nameProp['@value'] === curveName) {
                return true;
            }
            return false;
        });

        if (!targetSpline) {
            return undefined;
        }
        return base64ToPaddedVertices(targetSpline[`ControlPointsPacked`]);
    }

    // helper util to get the morph points from the spline array and return them
    getMorphPoints() {
        const splinesArray = this.getSplinesArray();
        const result: MorphPointsData[] = [];
        splinesArray.forEach(spline => {
            if (spline.Property) {
                const props = spline.Property;
                if (Array.isArray(props)) {
                    const nameProperty = props.find(prop => prop['@name'] === 'Name');
                    const valueProperty = nameProperty ? nameProperty['@value'] : '';
                    if (nameProperty && valueProperty.startsWith('MorphPoints')) {
                        const pts = base64ToPaddedVertices(spline.ControlPointsPacked);
                        result.push({ name: valueProperty, points: pts });
                    }
                }
            }
        });
        return result;
    }

    removeSplines(curveNames: DcmCadCurveNames[]) {
        const splinesArray = this.getSplinesArray();

        const cleanedSplines = splinesArray.filter(spline => {
            const propertyObject = spline?.Property;
            const propertyArray = Array.isArray(propertyObject) ? propertyObject : [propertyObject];

            const nameProp = propertyArray.find(p => {
                return p && p['@name'] === 'Name';
            });
            if (nameProp && curveNames.includes(nameProp['@value'])) {
                return false;
            }
            return true;
        });

        _.set(this.dcmObject, 'HPS.Splines.Object', cleanedSplines);
    }

    parseSplines(): DcmSpline[] {
        const splinesArray = this.getSplinesArray();

        return splinesArray.flatMap(targetSpline => {
            const propertyObject = targetSpline?.Property;
            if (!propertyObject) {
                return [];
            }
            const propertyArray = Array.isArray(propertyObject) ? propertyObject : [propertyObject];

            const name = propertyArray.find(p => p?.[`@name`] === `Name`)?.[`@value`] ?? '';
            const iMisc1 = propertyArray.find(p => p[`@name`] === `iMisc1`)?.[`@value`] ?? '';

            const base64Points = targetSpline[`ControlPointsPacked`];
            if (typeof base64Points !== `string` || base64Points.length < 1) {
                return [];
            }
            const pointsHash = md5(base64Points);
            const points = base64ToSplinePoints(base64Points);
            return [{ points, iMisc1, name, pointsHash }];
        });
    }

    parseMarginLineSplines(): MarginLineSpline[] {
        return _.compact(
            this.parseSplines().map(spline => {
                const match = spline.name.match(/MarginLine_(\d+)/);
                if (!match) {
                    return undefined;
                }

                // TODO:  EPDCAD-665 (unify UNN from DCM)
                const unn = ToothUtils.toToothNumber(match[1]);
                if (unn === undefined) {
                    return undefined;
                }

                return { unn, points: spline.points };
            }),
        );
    }

    transformSplines(transform: THREE.Matrix4): THREE.Vector3[] {
        const transformedSplines: THREE.Vector3[] = [];
        const splinesArray = this.getSplinesArray();

        // mutate the splines
        splinesArray.forEach(targetSpline => {
            const base64Points = targetSpline[`ControlPointsPacked`];
            if (typeof base64Points !== `string` || base64Points.length < 1) {
                return;
            }
            const points = base64ToSplinePoints(base64Points);
            const transformedPoints = points.map(p => p.applyMatrix4(transform));
            transformedSplines.push(...transformedPoints);
            const packedPointsTransformed = splinePointsToBase64(transformedPoints);
            _.set(targetSpline, 'ControlPointsPacked', packedPointsTransformed);
        });
        return transformedSplines;
    }

    getPrepMarks(): PrepMark[] {
        const commentsArray = this.dcmObject.HPS.Annotations?.Comment;

        return _.compact(
            commentsArray?.map<PrepMark | undefined>(comment => {
                const details: string | undefined = comment.Details;
                if (!details) {
                    return undefined;
                }
                const commentText = typeof comment.Text === 'string' ? comment.Text : '';
                if (details.match(DETAILS_PREPMARK_REGEX)) {
                    // TODO:  EPDCAD-665 (unify UNN from DCM)
                    const possibleUNN = _.toNumber(details.split('_')[1]);
                    const detailsUNN = !_.isNaN(possibleUNN) ? possibleUNN : undefined;
                    const origin: { x: number; y: number; z: number } = comment.Origin;
                    const originVec = new THREE.Vector3().set(origin.x, origin.y, origin.z);
                    return {
                        details,
                        origin: originVec,
                        unn: detailsUNN ?? -1,
                        text: commentText,
                        sectioned: comment.Sectioned === 'True' ? true : false,
                    };
                }
            }),
        );
    }

    transformComments(netTransform: THREE.Matrix4, onlyPrepMarks: boolean = false) {
        const commentsArray = this.dcmObject.HPS.Annotations?.Comment;

        commentsArray?.forEach(comment => {
            const details: string | undefined = comment.Details;
            if (!details) {
                return;
            }

            if (!onlyPrepMarks || (onlyPrepMarks && details.match(DETAILS_PREPMARK_REGEX))) {
                comment.Origin = transformVectorObj(comment.Origin, netTransform);
                if (comment.Normal) {
                    comment.Normal = transformVectorOrientation(comment.Normal, netTransform);
                }
            }
        });
    }

    injectInsertionAxis(axis: THREE.Vector3, toothNumber: number, upper2Lower: THREE.Matrix4): boolean {
        if (!this.mountPoint) {
            return false;
        }
        const isUpper = ToothUtils.toothIsUpper(toothNumber);
        const transformedAxis = isUpper ? axis.clone().transformDirection(upper2Lower.clone().invert()) : axis;
        const dcmInsertionAxis = this.mountPoint.clone().sub(transformedAxis);

        const object = _.get(this.dcmObject, 'HPS.Objects.Object');
        const objects = Array.isArray(object) ? object : [object];

        const dentalInfoObject = objects.find(el => _.get(el, '@.type') === 'TSysObjectDentalInformation');
        const vectors = _.get(dentalInfoObject, '#.3.Vector');
        const vectorsArray = Array.isArray(vectors) ? vectors : [vectors];
        const insertionAxis = vectorsArray.find(vector => {
            return vector['@name'] === 'vnMountDir';
        });
        if (!insertionAxis) {
            return false;
        }
        insertionAxis['@x'] = dcmInsertionAxis.x.toString();
        insertionAxis['@y'] = dcmInsertionAxis.y.toString();
        insertionAxis['@z'] = dcmInsertionAxis.z.toString();

        this.mountDirection_ = dcmInsertionAxis;
        return true;
    }

    /**
     *
     * @param curveName, string name of target curve
     * @param pts, array of vectors representing the new curve
     *
     * returns a boolean success flag
     */
    injectSpline(curveName: DcmCadCurveNames, pts: THREE.Vector3[]): boolean {
        const splinesObject = _.get(this.dcmObject, 'HPS.Splines.Object');
        if (!splinesObject) {
            return false;
        }
        const splinesArray = Array.isArray(splinesObject) ? splinesObject : [splinesObject];

        const targetSpline = splinesArray.find(spline => {
            const propertyObject = spline?.Property;
            const propertyArray = Array.isArray(propertyObject) ? propertyObject : [propertyObject];

            const nameProp = propertyArray.find(p => {
                return p && p['@name'] === 'Name';
            });
            if (nameProp && nameProp['@value'] === curveName) {
                return true;
            }
            return false;
        });

        if (!targetSpline) {
            return false;
        }

        const base64Points = splinePointsToBase64(pts);
        _.set(targetSpline, 'ControlPointsPacked', base64Points);
        return true;
    }

    injectNonMorphSpline(curveName: DcmCadCurveNames, pts: THREE.Vector3[], pointsHash: string): boolean {
        const splinesObject = _.get(this.dcmObject, 'HPS.Splines.Object');
        if (!splinesObject) {
            return false;
        }
        const splinesArray = Array.isArray(splinesObject) ? splinesObject : [splinesObject];

        const targetSplines = splinesArray.filter(spline => {
            const propertyObject = spline?.Property;
            const propertyArray = Array.isArray(propertyObject) ? propertyObject : [propertyObject];

            const nameProp = propertyArray.find(p => {
                return p && p['@name'] === 'Name';
            });
            const base64Points = spline[`ControlPointsPacked`];
            const hash = md5(base64Points);
            return nameProp && nameProp['@value'] === curveName && hash === pointsHash;
        });

        if (targetSplines.length === 0) {
            return false;
        }

        const base64Points = splinePointsToBase64(pts);
        targetSplines.map(spline => {
            _.set(spline, 'ControlPointsPacked', base64Points);
        });
        return true;
    }

    preprocessAnnotationsAndComments(rawXML: XMLBuilder): { Annotation: AnnotationType[]; Comment: CommentType[] } {
        const AnnotationsXML = rawXML.find(ele => ele.node.nodeName === 'Annotations', true, true);

        const annotationObjectsArray: AnnotationType[] = [];
        const commentsObjectsArray: CommentType[] = [];

        AnnotationsXML?.each(ele => {
            const packedObj = ele.toObject();
            const comment = _.get(packedObj, 'Comment');
            const annotation = _.get(packedObj, 'Annotation');
            if (annotation && AnnotationSchema.safeParse(annotation).success) {
                annotationObjectsArray.push(annotation);
            } else if (comment && CommentSchema.safeParse(comment).success) {
                commentsObjectsArray.push(comment);
            } else {
                throw new Error('Unhandled Annotation in DCM');
            }
        });

        return { Annotation: annotationObjectsArray, Comment: commentsObjectsArray };
    }

    findTransformByName(name: TransformName): AnnotationType | undefined {
        const targetTransform = this.dcmObject.HPS.Annotations?.Annotation.find(
            c => c['@type'] === 'CoordinateTransform' && c['String']['@value'] === name,
        );
        return targetTransform;
    }

    // Follows decoding logic similar to the old python code. Don't @ me.
    parseAllTeethAnnotations(): DesignMetadataScanTooth[] {
        const isNumeric = (str?: string) => str && /^-?\d+$/.test(str);

        const convertedTeeth = (this.dcmObject.HPS.Annotations?.Comment ?? []).map<DesignMetadataScanTooth | undefined>(
            comment => {
                const text = `${comment.Text}`;
                if (comment.Details && isNumeric(comment.Details) && / \d+$/.test(text)) {
                    return {
                        text,
                        annotation_type: text.split(' ').at(-1) ?? '',
                        tooth: parseInt(comment.Details),
                        origin: [comment.Origin.x, comment.Origin.y, comment.Origin.z],
                    };
                }

                if (text && isNumeric(text)) {
                    return {
                        text,
                        annotation_type:
                            !comment.Details || comment.Details?.startsWith('Preparation_')
                                ? 'Preparation'
                                : comment.Details,
                        tooth: parseInt(text),
                        origin: [comment.Origin.x, comment.Origin.y, comment.Origin.z],
                    };
                }

                return undefined;
            },
        );

        return _.compact(convertedTeeth);
    }

    // Returns a map of <TransformName, Matrix4> for all Transforms defined in the DCM.
    parseAllTransforms(): Record<string, THREE.Matrix4> {
        const transformNames = this.dcmObject.HPS.Annotations?.Annotation.filter(
            annotation => annotation['@type'] === 'CoordinateTransform',
        ).map(annotation => annotation['String']['@value']);

        return (transformNames ?? []).reduce((state, name) => {
            const transform = this.getTransform(name as TransformName);

            if (!transform) {
                return state;
            }

            return {
                ...state,
                [name]: transform,
            };
        }, {});
    }

    /**
     *
     * @param targetName:  the name of the transform to set
     * @param transform:  THREE.Matrix4, to set the value to
     */
    setTransform(targetName: TransformName, transform: THREE.Matrix4): boolean {
        const targetTransform: AnnotationType | undefined = this.findTransformByName(targetName);
        if (!targetTransform) {
            return false;
        }

        const matrixArray = [...transform.elements];
        if (!isLength16Array(matrixArray)) {
            return false;
        }
        // Note, transform.elements is column major form THREE.js
        // we could either transform.clone().transpose().elements
        // here we just use indexing and make this comment
        targetTransform.Matrix4x4['@m00'] = matrixArray[0];
        targetTransform.Matrix4x4['@m01'] = matrixArray[4];
        targetTransform.Matrix4x4['@m02'] = matrixArray[8];
        targetTransform.Matrix4x4['@m03'] = matrixArray[12];
        targetTransform.Matrix4x4['@m10'] = matrixArray[1];
        targetTransform.Matrix4x4['@m11'] = matrixArray[5];
        targetTransform.Matrix4x4['@m12'] = matrixArray[9];
        targetTransform.Matrix4x4['@m13'] = matrixArray[13];
        targetTransform.Matrix4x4['@m20'] = matrixArray[2];
        targetTransform.Matrix4x4['@m21'] = matrixArray[6];
        targetTransform.Matrix4x4['@m22'] = matrixArray[10];
        targetTransform.Matrix4x4['@m23'] = matrixArray[14];
        targetTransform.Matrix4x4['@m30'] = matrixArray[3];
        targetTransform.Matrix4x4['@m31'] = matrixArray[7];
        targetTransform.Matrix4x4['@m32'] = matrixArray[11];
        targetTransform.Matrix4x4['@m33'] = matrixArray[15];

        return true;
    }

    getTransform(targetName: TransformName): THREE.Matrix4 | undefined {
        const targetTransform: AnnotationType | undefined = this.findTransformByName(targetName);
        if (!targetTransform) {
            return undefined;
        }
        // Here we retrieve the matrix elements in row-major order, as is expected by THREE.Matrix4.set.
        const elementArray = [
            targetTransform.Matrix4x4['@m00'],
            targetTransform.Matrix4x4['@m01'],
            targetTransform.Matrix4x4['@m02'],
            targetTransform.Matrix4x4['@m03'],
            targetTransform.Matrix4x4['@m10'],
            targetTransform.Matrix4x4['@m11'],
            targetTransform.Matrix4x4['@m12'],
            targetTransform.Matrix4x4['@m13'],
            targetTransform.Matrix4x4['@m20'],
            targetTransform.Matrix4x4['@m21'],
            targetTransform.Matrix4x4['@m22'],
            targetTransform.Matrix4x4['@m23'],
            targetTransform.Matrix4x4['@m30'],
            targetTransform.Matrix4x4['@m31'],
            targetTransform.Matrix4x4['@m32'],
            targetTransform.Matrix4x4['@m33'],
        ] as const;

        const parsedTransform = new THREE.Matrix4().set(...elementArray);

        const t = new THREE.Vector3();
        const q = new THREE.Quaternion();
        const s = new THREE.Vector3();
        parsedTransform.decompose(t, q, s);

        return parsedTransform;
    }

    getAlignToBiteTransformation(): THREE.Matrix4 | undefined {
        const alignToBite = this.dcmObject.HPS.Annotations?.Annotation.find(
            c => c['@type'] === 'CoordinateTransform' && c['String']['@value'] === 'AlignToBiteTransformation',
        );
        if (!alignToBite) {
            return undefined;
        }

        // Here we retrieve the matrix elements in row-major order, as is expected by THREE.Matrix4.set.
        const elementArray = [
            alignToBite.Matrix4x4['@m00'],
            alignToBite.Matrix4x4['@m01'],
            alignToBite.Matrix4x4['@m02'],
            alignToBite.Matrix4x4['@m03'],
            alignToBite.Matrix4x4['@m10'],
            alignToBite.Matrix4x4['@m11'],
            alignToBite.Matrix4x4['@m12'],
            alignToBite.Matrix4x4['@m13'],
            alignToBite.Matrix4x4['@m20'],
            alignToBite.Matrix4x4['@m21'],
            alignToBite.Matrix4x4['@m22'],
            alignToBite.Matrix4x4['@m23'],
            alignToBite.Matrix4x4['@m30'],
            alignToBite.Matrix4x4['@m31'],
            alignToBite.Matrix4x4['@m32'],
            alignToBite.Matrix4x4['@m33'],
        ] as const;

        return new THREE.Matrix4().set(...elementArray);
    }

    validate(): boolean {
        const result = DcmSchema.safeParse(this.dcmObject);
        if (!result.success) {
            logger.info('DcmGeometryInjector.validate(): Error parsing schema', result);
        }
        return result.success;
    }

    serialize(): string | undefined {
        try {
            if (!this.validate()) {
                throw new Error('Internally malformed XML Output Document');
            }
            const doc = createXML({ version: '1.0' }, this.dcmObject);

            return doc.end({ prettyPrint: true });
        } catch (e) {
            logger.warn('Failed to serialize DCM File tree', e);
            return undefined;
        }
    }

    clearFacetMarks(): void {
        delete this.dcmObject.HPS.FacetMarks;
    }

    parseFacetMarks(): Uint32Array | undefined {
        const facetMarks = this.dcmObject.HPS.FacetMarks;
        return facetMarks ? base64ToFacetMarks(facetMarks) : undefined;
    }

    getFacetMarksString(): string | undefined {
        return this.dcmObject.HPS.FacetMarks;
    }

    /**
     * Rewrite the facet marks of the DCM based on the attributes of the specified BufferGeometry.
     * @param geometry A Three.js BufferGeometry with a facetMarks attribute.
     * @returns true if the facet marks were successfully rewritten; otherwise, false.
     */
    rewriteFacetMarksFromGeometry(geometry: THREE.BufferGeometry): boolean {
        const facetAttr = geometry.getAttribute(FACET_MARKS_ATTRIBUTE_NAME);

        if (!facetAttr) {
            return false;
        }

        this.rewriteFacetMarksFromArray(facetAttr.array);
        return true;
    }

    rewriteFacetMarksFromArray(marksArray: ArrayLike<number>): void {
        this.dcmObject.HPS.FacetMarks = facetMarksToBase64(marksArray);
    }

    /**
     * Rewrite the facet marks based on a mapping from old index (key) to new index (value).
     * E.g. if facet 1 was removed, the map would look like { 0 -> 0, 2 -> 1, 3 -> 2, ...}.
     * @param facetIndexMap The index map from old index (key) to new index (value).
     */
    rewriteFacetMarksFromIndexMap(facetIndexMap: Map<number, number>): void {
        const oldFacetMarks = this.parseFacetMarks();
        if (!oldFacetMarks) {
            return;
        }
        const newFacetMarks: number[] = [];
        for (const [oldIdx, newIdx] of facetIndexMap) {
            const oldMark = oldFacetMarks[oldIdx];
            if (oldMark === undefined) {
                throw new Error(`Invalid index '${oldIdx}' when remapping facet marks`);
            }
            newFacetMarks[newIdx] = oldMark;
        }
        this.rewriteFacetMarksFromArray(newFacetMarks);
    }

    // TODO:  EPDCAD-665 (unify UNN from DCM)
    facetMarksToUnn(): number[] | undefined {
        const facetMarks = this.parseFacetMarks();
        if (!facetMarks) {
            return undefined;
        }

        return Array.from(facetMarks, unnFromFacetMark);
    }

    validateFacetMarks(): boolean {
        const numFacetMarks = this.parseFacetMarks()?.length;
        if (numFacetMarks === undefined) {
            return true;
        }
        const binData = this.dcmObject.HPS.Packed_geometry.Binary_data;
        const caOrCe = binData.CA ?? binData.CE;
        return numFacetMarks === caOrCe?.Facets['@facet_count'];
    }

    parseTextureImage(texImage: {
        '#': string;
        '@Base64EncodedBytes': number;
        '@Width': number;
        '@Height': number;
        '@TextureName': string;
        '@TextureCoordSet': string;
        '@BytesPerPixel': number;
    }): TextureImage {
        const width = texImage['@Width'];
        const height = texImage['@Height'];
        const name = texImage['@TextureName'];

        return {
            b64Data: texImage['#'],
            width,
            height,
            name,
        };
    }

    parseTextureImages(): TextureImage[] {
        const texImages = this.dcmObject.HPS.TextureData2?.TextureImages?.TextureImage;
        if (!texImages) {
            return [];
        }
        const parsedImages: TextureImage[] = [];
        if (Array.isArray(texImages)) {
            for (let i = 0; i < texImages.length; i++) {
                const texImage = texImages[i];
                if (texImage) {
                    const parsedImage = this.parseTextureImage(texImage);
                    parsedImages.push(parsedImage);
                }
            }
        } else {
            const parsedImage = this.parseTextureImage(texImages);
            parsedImages.push(parsedImage);
        }
        return parsedImages;
    }

    parseProperty(name: string): string | undefined {
        const props = this.dcmObject.HPS.Properties?.Property;
        if (!props) {
            return undefined;
        }
        for (const prop of Array.isArray(props) ? props : [props]) {
            if (prop['@name'] === name) {
                return prop['@value'];
            }
        }
        return undefined;
    }

    parseAllProperties(): Record<string, string> {
        const props = this.dcmObject.HPS.Properties?.Property;
        if (!props) {
            return {};
        }

        const propsArray = Array.isArray(props) ? props : [props];
        return propsArray.reduce((state, prop) => {
            return {
                ...state,
                [prop['@name']]: prop['@value'],
            };
        }, {});
    }

    getVertexEncryptionKeys(): Uint8Array[] {
        const ekid = this.parseProperty('EKID');
        const packageLockList = normalizePackageLockList(this.parseProperty('PackageLockList'));
        const salt = packageLockList ? getSalt(packageLockList) : undefined;
        const defaultKey = getDefaultKey();
        if (ekid) {
            switch (ekid) {
                case '0':
                    throw new Error(`Unsupported encryption key ID: ${ekid}`);
                case '1':
                    if (salt) {
                        return [concatKeys(defaultKey, salt), defaultKey];
                    }
                    return [defaultKey];
                case '2':
                    throw new Error(`Unsupported encryption key ID: ${ekid}`);
                default:
                    throw new Error(`Unknown encryption key ID: ${ekid}`);
            }
        } else if (salt) {
            return [salt];
        }
        return [];
    }

    parseVertices(): THREE.Vector3[] {
        const keys = this.getVertexEncryptionKeys();
        const binData = this.dcmObject.HPS.Packed_geometry.Binary_data;
        const verticesNode = (binData.CA ?? binData.CE)?.Vertices;
        let decryptOpts: DecryptOptions | undefined;

        const bytes = verticesNode?.['@base64_encoded_bytes'] as number;
        const checkValue = verticesNode?.['@check_value'];

        decryptOpts = { bytes, checkValue, keys };

        // For the CA schema the absence of the @check_value attribute indicates no encryption
        if (this.dcmObject.HPS.Packed_geometry.Schema === 'CA' && !checkValue) {
            // Since there's no encryption in this case we'll just clear the keys array
            decryptOpts = undefined;
        }

        return base64ToVertices(verticesNode?.['#'] as string, decryptOpts);
    }

    /*
     * Returns a *TRUNCATED* buffer representing the vertices of the DCM.
     * This is utilized by the Signature computation and shouldn't be used by much else.
     * The result of this function is verbatim prepended to the buffer used to compute the Signature Hash.
     * Change at your own peril.
     */
    private getVerticesDecryptedBuffer(): Buffer {
        const keys = this.getVertexEncryptionKeys();
        const binData = this.dcmObject.HPS.Packed_geometry.Binary_data;
        const verticesNode = (binData.CA ?? binData.CE)?.Vertices;
        let decryptOpts: DecryptOptions | undefined;

        const bytes = verticesNode?.['@base64_encoded_bytes'] as number;
        const checkValue = verticesNode?.['@check_value'];

        decryptOpts = { bytes, checkValue, keys };

        // For the CA schema the absence of the @check_value attribute indicates no encryption
        if (this.dcmObject.HPS.Packed_geometry.Schema === 'CA' && !checkValue) {
            // Since there's no encryption in this case we'll just clear the keys array
            decryptOpts = undefined;
        }

        const arr = base64ToUint8Array(verticesNode?.['#'] as string, decryptOpts);
        const baseBuffer = Buffer.from(arr.buffer);

        // Sometimes, base64 decoding will yield some extra bytes of "padding".
        // 3Shape, however, expects that we are using a truncated buffer of exactly how many bytes were given as an input.
        // Fortunately, they tell us via the `base64_encoded_bytes` attribute exactly how big the final buffer should be.
        return bytes ? baseBuffer.subarray(0, bytes) : baseBuffer;
    }

    parseFacets(): [number, number, number][] {
        const binData = this.dcmObject.HPS.Packed_geometry.Binary_data;
        const facetsNode = (binData.CA ?? binData.CE)?.Facets;

        return base64ToFacets(facetsNode?.['#'] as string);
    }

    parseTextureCoords(): THREE.Vec2[][] | undefined {
        const perVertTex = this.dcmObject.HPS.TextureData2?.PerVertexTextureCoord;
        const binData = this.dcmObject.HPS.Packed_geometry.Binary_data;
        // We can't rely on the number of vertices reported by '@vertex_count' as, apparently,
        // it is just wrong sometimes. Always go by the byte count instead.
        const vertByteCount = (binData.CA ?? binData.CE)?.Vertices['@base64_encoded_bytes'];
        if (!vertByteCount || !perVertTex) {
            return undefined;
        }

        // Chairside produces a `CA` schema, which does not encrypt these TextureCoordinates.
        const shouldDecrypt = this.dcmObject.HPS.Packed_geometry.Schema === 'CE';

        return base64ToVertexTextureCoords(
            perVertTex['#'],
            Math.trunc(vertByteCount / BytesPerVert),
            shouldDecrypt
                ? {
                      bytes: perVertTex['@Base64EncodedBytes'] ?? 0,
                      keys: [getReversedKey(getDefaultKey())],
                  }
                : undefined,
        );
    }

    buildGeometry(opts?: BufferGeometryOptions): THREE.BufferGeometry {
        const rawVerts = this.parseVertices();
        const rawFacets = this.parseFacets();
        const rawTexCoords = opts?.applyTextureCoords ?? true ? this.parseTextureCoords() : undefined;

        const geometry = new THREE.BufferGeometry();

        const perFacetTexCoords = rawTexCoords?.filter(uvs => uvs.length > 1);

        if (perFacetTexCoords && perFacetTexCoords.length > 0) {
            // Check that all the per-facet coords are collapsible
            const collapsible = (uvs: THREE.Vec2[]) => uvs.every(uv => uv.x === uvs[0]?.x && uv.y === uvs[0]?.y);
            if (perFacetTexCoords.every(collapsible)) {
                logger.info('Unnecessary per-facet texture coordinates were encountered.');
            } else {
                logger.info(
                    'Per-facet texture coordinates are not fully supported. Extra coordinates will be ignored.',
                );
            }
        }
        const facets = rawFacets.flat();
        const verts = rawVerts.flatMap(v => [v.x, v.y, v.z]);
        const texCoords = rawTexCoords?.flatMap(uvs => [uvs[0]?.x ?? 0, uvs[0]?.y ?? 0]);

        geometry.setIndex(facets);
        geometry.setAttribute(AttributeName.Position, new THREE.Float32BufferAttribute(verts, 3));
        if (texCoords) {
            geometry.setAttribute(AttributeName.TexCoord, new THREE.Float32BufferAttribute(texCoords, 2));
        }

        return geometry;
    }

    get mountDirection(): THREE.Vector3 | undefined {
        if (this.mountDirection_ === null) {
            this.mountDirection_ = maybeParseVector3(maybeGetNodeWithName(this.processedXML, 'vnMountDir'));
        }
        return this.mountDirection_?.clone();
    }

    get mountPoint(): THREE.Vector3 | undefined {
        if (this.mountPoint_ === null) {
            this.mountPoint_ = maybeParseVector3(maybeGetNodeWithName(this.processedXML, 'ptMountPointEst'));
        }
        return this.mountPoint_?.clone();
    }

    /**
     * Returns the insertion direction in the global frame, if it could be calculated
     * @param transform The transformation matrix to apply to the insertion direction. Only the rotation component is
     *   applied. If the DCM is of an upper jaw crown, this should be the UpperJaw2LowerJaw transformation extracted
     *   from the DDMT. If the DCM is of a lower jaw crown, no transformation is needed.
     * NB: This formula for getting the insertion direction seems only to hold for DCMs generated by 3Shape Automate and
     * not for DCMs generated by 3Shape Dental Designer.
     */
    getAutomateInsertionDirection(transform?: THREE.Matrix4): THREE.Vector3 | undefined {
        const mountDir = this.mountDirection;
        const mountPt = this.mountPoint;
        if (!(mountDir && mountPt)) {
            return undefined;
        }

        const insertionDir = mountPt.clone().sub(mountDir).normalize();
        if (transform) {
            insertionDir.transformDirection(transform);
        }

        return insertionDir;
    }
}
