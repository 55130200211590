import { useFirebaseStorage } from '../../context';
import type { FilesStateGen } from './FirebaseUpload.utils';
import { useFileFieldsState } from './FirebaseUpload.utils';
import type { FileUploaderField } from './file-uploader-types';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/compat/app';
import React from 'react';

type FbOnUpdate =
    | firebase.Observer<firebase.storage.UploadTaskSnapshot>
    | ((a: firebase.storage.UploadTaskSnapshot) => any);
type FbErrorCb = ((a: Error) => any) | null;
type FbOnComplete = firebase.Unsubscribe | null;
type FBCallbackArgs = [FbOnUpdate, FbErrorCb, FbOnComplete];

/**
 * Google sucks at typing their shit so I had to do this. See the docs for firebase.storage.UploadTask.on(...)
 * Basically, it accepts the event you want to listen for, and then optional listener callbacks (onUpdate, onError,
 * onComplete) If you pass any callbacks, it returns a function to unregister them, but if you do not pass any, it
 * returns a fn that allows you to register callbacks. Again, wtf google
 */
interface FBUploadTaskOverride extends Omit<firebase.storage.UploadTask, 'on'> {
    /**
     * override A - at least on callback is passed, and the returned function cancels
     * @param {firebase.storage.TaskEvent} event
     * @param onStateChange
     * @param onError
     * @param onComplete
     * @return {() => void} 'firebase.Unsubscribe' in their typings
     */
    on(
        event: firebase.storage.TaskEvent,
        onStateChange: FbOnUpdate,
        onError?: FbErrorCb,
        onComplete?: FbOnComplete,
    ): () => void;
    /**
     * override B - no callbacks passed, returns a function to register the callback
     * @param {firebase.storage.TaskEvent} event
     * @return {(...args: FBRegCallbackArgs) => () => void} Fn to register callbacks aka event listeners
     */
    on(event: firebase.storage.TaskEvent): (...args: FBCallbackArgs) => () => void;
}

type UseFbBulkUploadDetails<K extends string = string> = {
    errors: FilesStateGen<Error | undefined, K>;
    snapshots: FilesStateGen<firebase.storage.UploadTaskSnapshot | undefined, K>;
    called: FilesStateGen<boolean, K>;
    progress: FilesStateGen<number, K>;
    deleteFn: DeleteBulkFn<K>;
};

export type UploadBulkFn<K extends string = string> = (
    fieldKey: K,
    fileName: string,
    blob: Blob,
) => Promise<firebase.storage.UploadTaskSnapshot>;
export type DeleteBulkFn<K extends string = string> = (fieldKey: K, fileName: string, blob?: Blob) => Promise<void>;
type UseFirebaseUploadBulk<K extends string = string> = [UploadBulkFn<K>, UseFbBulkUploadDetails<K>];

export function useFirebaseMultiFileUpload<K extends string = any>(
    storagePathConfig: BucketStoragePathConfig,
    fileFields: FileUploaderField<K>[],
): UseFirebaseUploadBulk<K> {
    const { path: storagePath, bucketName } = storagePathConfig;
    const firebaseStorage = useFirebaseStorage(bucketName);
    const [progress, setProgress] = useFileFieldsState<number, K>(fileFields, 0);
    const [snapshots, setSnapshots] = useFileFieldsState<firebase.storage.UploadTaskSnapshot | undefined, K>(
        fileFields,
        undefined,
    );
    const [called, setCalled] = useFileFieldsState<boolean, K>(fileFields, false);
    const [errors, setErrors] = useFileFieldsState<Error | undefined, K>(fileFields, undefined);
    const [streamClosers, updateStreamClosers] = useFileFieldsState<(() => void) | undefined, K>(fileFields, undefined);

    // this has to use the callback setState approach because im not sure it will be updated each time streamCloser changes
    const closeStreamIfPossible = React.useCallback(
        (fieldKey: K) => {
            const closer = streamClosers[fieldKey];
            !!closer && closer();
            !!closer && updateStreamClosers(fieldKey, undefined);
        },
        [streamClosers, updateStreamClosers],
    );
    const onProgress = (fieldKey: K) => (snapshot: firebase.storage.UploadTaskSnapshot) => {
        setSnapshots(fieldKey, snapshot);
        const newProgress = snapshot.bytesTransferred / snapshot.totalBytes;
        setProgress(fieldKey, newProgress);
        newProgress === 1 && closeStreamIfPossible(fieldKey);
    };
    const onError = (fieldKey: K) => (error: Error) => {
        setErrors(fieldKey, error);
        Sentry.captureException(error);
    };
    const onComplete = (fieldKey: K) => () => closeStreamIfPossible(fieldKey);

    const upload: UploadBulkFn<K> = async (
        fieldKey: K,
        fileName: string,
        blob: Blob,
    ): Promise<firebase.storage.UploadTaskSnapshot> => {
        setCalled(fieldKey, true);
        // its important to know that the returned task technically extends Promise and so can can be awaited
        const task = firebaseStorage.ref(storagePath).child(fileName).put(blob) as FBUploadTaskOverride;
        const closeStream = task.on(
            firebase.storage.TaskEvent.STATE_CHANGED,
            onProgress(fieldKey),
            onError(fieldKey),
            onComplete(fieldKey),
        );
        updateStreamClosers(fieldKey, closeStream);
        return new Promise((res, rej) => {
            task.then(res).catch(rej);
        });
    };

    const deleteFn: DeleteBulkFn<K> = async (_fieldKey: K, fileName: string, _blob?: Blob): Promise<void> => {
        // its important to know that the returned task technically extends Promise and so can can be awaited
        const task = firebaseStorage.ref(storagePath).child(fileName).delete();
        return new Promise((res, rej) => {
            task.then(res).catch(rej);
        });
    };
    return [upload, { errors, progress, snapshots, called, deleteFn }];
}
