import type { IArchiveFile } from '../../Zippy/File';
import { readArchive } from '../../Zippy/Reader';
import { writeArchive } from '../../Zippy/Writer';

const DDMT_ZIP_PASSPHRASE: string = 'antonnsi';
const PERFORMANCE_LOG_PASSPHRASE: string = 'PerformanceLog';

async function unlockFileWithPassphrase(contents: Buffer, passphrase: string): Promise<string | undefined> {
    try {
        // The rawContents of the buffer of the file, which is itself a zipped file.
        const archive = readArchive(contents);
        // There should always be exactly one file here, since it's a single-file archive.
        const { done, value } = archive.next();
        const candidateFile = !done ? value : undefined;
        const ddmt = candidateFile ? await candidateFile.body(passphrase) : undefined;

        if (ddmt) {
            return ddmt.toUtf8();
        }
    } catch (e) {
        this.logger.error('Failed to extract modelling tree', e);
    }

    return undefined;
}

export async function extractModellingTree(contents3ml: Buffer): Promise<string | undefined> {
    return unlockFileWithPassphrase(contents3ml, DDMT_ZIP_PASSPHRASE);
}

export async function extractPerformanceLog(contents3pl: Buffer): Promise<string | undefined> {
    return unlockFileWithPassphrase(contents3pl, PERFORMANCE_LOG_PASSPHRASE);
}

export async function compressModellingTree(modellingTree: string): Promise<Buffer | undefined> {
    try {
        const file: IArchiveFile = {
            name: 'DentalDesignerModellingTree.3ml',
            password: DDMT_ZIP_PASSPHRASE,
            body: modellingTree,
        };

        return Buffer.from(await writeArchive([file]));
    } catch (e) {
        this.logger.error(`Failed to compress modelling tree`, e);
    }

    return undefined;
}
