/*
 *  1.  This util provides helpers to set and control the camera in a THREE.js/ReactThreeFiber scene (NewModelViewer etc).
 *  2.  The secret sauces are as follows
 *           -knowing that the camera and the controls for the scene are linked, which is a huge PITA sometimes.  Most functions are looking for a camerRef and controlsRef
 *           -construction of rotation and transform matrices, quaternions and zoom etc.  This module attempts to help with some of that pain
 *           -knowing some weirdness for how the camera and controls update cycles happen in THREE.js
 *           -know the three.js system camera convention.
 *              -Y is up in screen space
 *              -X is right in screen space
 *              -You are looking down the -Z direction / +Z is out of the screen
 * */
import { listVisiblePayloadModels } from '../../CrossSection';
import type { ModelAppearance } from '../../ModelAppearance';
import type { MainViewCameraControlsRef } from '../ModelViewerTHREETypes';
import type { ModelPayloadItem } from '../ModelViewerTypes';
import { isOrthographicCamera } from '@orthly/forceps';
import * as _ from 'lodash';
import React from 'react';
import * as THREE from 'three';

const IDENTITY = new THREE.Matrix4().identity();

const DEFAULT_MAX_ORTHOGRAPHIC_ZOOM = 200;

export interface CameraPose {
    // The location that the camera should be looking
    targetVector: THREE.Vector3;
    // the location of the camera eyeVector = (targetVector - locVector)
    locVector: THREE.Vector3;

    // orientation matrix of the camera object
    // Beware, it is possible to  create an invalid rotation
    // that does not respect the eye generated from loc and target!
    rotMatrix: THREE.Matrix4;
}

export function updateCameraFromPose(controlRef: MainViewCameraControlsRef, cameraPose: CameraPose, zoom?: number) {
    const controls = controlRef.current;
    const camera = controlRef.current?.object;
    // We need things to exist on their refs first
    if (!(camera && controls)) {
        return;
    }
    const cPos = new THREE.Vector3();
    const cQuat = new THREE.Quaternion();
    const cScale = new THREE.Vector3();
    cameraPose.rotMatrix.decompose(cPos, cQuat, cScale);

    camera.position.copy(cameraPose.locVector);
    camera.quaternion.copy(cQuat);
    camera.up.copy(new THREE.Vector3(0, 1, 0).applyQuaternion(cQuat));
    if (zoom) {
        camera.zoom = zoom;
    }

    camera.updateMatrixWorld(true);
    camera.updateProjectionMatrix();

    // We do this in order to make sure the update sequence is correct between camera and controls
    controls.target.copy(cameraPose.targetVector);
    controls.update();

    controls.enabled = true;
}

interface ZoomToVisibleInfo {
    camTarget: THREE.Vector3;
    zoom: number;
}

interface ModelInfo {
    geom: Pick<THREE.BufferGeometry, 'boundingBox' | 'computeBoundingBox'>;
    worldTGeom: THREE.Matrix4;
}

/**
 * This function usually used with listVisiblePayloadModels
 * @param {ModelPayloadItem[]} models
 */
export function computeZoomToVisible(models: ModelInfo[], camera: THREE.OrthographicCamera): ZoomToVisibleInfo {
    // initiate a box or sphere in camera space
    const bbox = new THREE.Box3();
    const bboxWorld = new THREE.Box3();
    // TODO EPDCAD-21 after update THREE.js, use sphere.union method

    const reuseVector = new THREE.Vector3();
    // grow the box by the objs in it
    models.forEach(modelAndMatrix => {
        const { geom, worldTGeom } = modelAndMatrix;
        //force compute bounding box because they can be stale
        geom.computeBoundingBox();
        if (geom.boundingBox) {
            // world space box for center/target
            const low = geom.boundingBox.min;
            const high = geom.boundingBox.max;

            const corners: [number, number, number][] = [
                [low.x, low.y, low.z],
                [high.x, low.y, low.z],
                [low.x, high.y, low.z],
                [low.x, low.y, high.z],
                [high.x, high.y, low.z],
                [high.x, low.y, high.z],
                [low.x, high.y, high.z],
                [high.x, high.y, high.z],
            ];

            // Add all corners of the bbox to worldBBo
            corners.forEach(ele => {
                reuseVector.set(ele[0], ele[1], ele[2]).applyMatrix4(worldTGeom);
                bboxWorld.expandByPoint(reuseVector);
                reuseVector.project(camera);
                bbox.expandByPoint(reuseVector);
            });
        }
    });

    const boxWidth = bbox.max.x - bbox.min.x;
    const boxHeight = bbox.max.y - bbox.min.y;

    // cam width is 2 in normalized coordinates and we put our widths in those coords
    // just need zoom to match biggest dimension no aspect needed
    // edge case box width and height === 0 creating infinity, so
    const zoomFactor = Math.min(1 / (boxHeight / 2), 1 / (boxWidth / 2), DEFAULT_MAX_ORTHOGRAPHIC_ZOOM);

    const finalZoom = camera.zoom * zoomFactor;

    const camTarget = new THREE.Vector3();
    bboxWorld.getCenter(camTarget);

    return { camTarget, zoom: finalZoom };
}

export function applyZoomToVisible(
    zoomInfo: ZoomToVisibleInfo,
    controlsRef: MainViewCameraControlsRef,
    camera: THREE.OrthographicCamera,
): void {
    const { camTarget, zoom } = zoomInfo;

    const existingTarget = controlsRef.current?.target;
    if (!existingTarget) {
        return;
    }

    const deltaTarget = camTarget.clone().sub(existingTarget);

    // Slide the camera over the same amount we slide the target over
    const camLocation = camera.position.clone();
    camLocation.add(deltaTarget);

    const camPose: CameraPose = {
        targetVector: camTarget,
        locVector: camLocation,
        rotMatrix: camera.matrixWorld.clone(),
    };
    updateCameraFromPose(controlsRef, camPose, zoom);
}

/*
 * Creates a Callback to snap the camera target and zoom to the
 * visible itmes in teh modelAppearance object
 * */
export function useZoomToVisible(controlRef: MainViewCameraControlsRef, appearance: ModelAppearance) {
    return React.useCallback(() => {
        const controls = controlRef.current;
        const camera = controlRef.current?.object;

        // make sure the refs have been set and we are type/undefined safe
        // TS has a hard time with the union of types OrthographicCamera and PerspectiveCamera
        if (!(controls && isOrthographicCamera(camera))) {
            return;
        }

        const visibleItems: ModelPayloadItem[] = listVisiblePayloadModels(appearance);

        if (visibleItems.length === 0) {
            return;
        }
        const zoomInfo = computeZoomToVisible(
            _.compact(
                visibleItems.map((mpi: ModelPayloadItem) => {
                    return {
                        geom: mpi.mesh?.geometry ?? mpi.model.geometry,
                        worldTGeom: mpi.mesh?.matrixWorld ?? IDENTITY,
                    };
                }),
            ),
            camera,
        );

        applyZoomToVisible(zoomInfo, controlRef, camera);
    }, [appearance, controlRef]);
}

export function useResetCameraView(controlRef: MainViewCameraControlsRef): () => void {
    return React.useCallback(() => {
        controlRef.current?.reset?.();
    }, [controlRef]);
}

export function zoomToModels(controlRef: MainViewCameraControlsRef, models: ModelPayloadItem[]): void {
    const controls = controlRef.current;
    const camera = controls?.object;
    if (!(controls && isOrthographicCamera(camera) && models.length)) {
        return;
    }

    const zoomInfo = computeZoomToVisible(
        models.map(el => {
            return {
                geom: el.mesh?.geometry ?? el.model.geometry,
                worldTGeom: el.mesh?.matrixWorld ?? IDENTITY,
            };
        }),
        camera,
    );

    applyZoomToVisible(zoomInfo, controlRef, camera);
}

interface BoxInfo {
    box: THREE.Box3;
    worldTBox?: THREE.Matrix4;
}

class GeometryStub {
    constructor(public boundingBox: THREE.Box3) {}

    // This is a no-op to satisfy the THREE.BufferGeometry interface required by computeZoomToVisible
    computeBoundingBox(): void {}
}

export function zoomToBoxes(controlRef: MainViewCameraControlsRef, boxInfos: BoxInfo[]): void {
    const controls = controlRef.current;
    const camera = controls?.object;
    if (!(controls && isOrthographicCamera(camera) && boxInfos.length)) {
        return;
    }

    const zoomInfo = computeZoomToVisible(
        boxInfos.map(el => {
            return {
                geom: new GeometryStub(el.box),
                worldTGeom: el.worldTBox ?? IDENTITY,
            };
        }),
        camera,
    );

    applyZoomToVisible(zoomInfo, controlRef, camera);
}

export function zoomToSphere(
    controlRef: MainViewCameraControlsRef,
    sphere: THREE.Sphere,
    bufferRatio: number = 0,
): void {
    const controls = controlRef.current;
    const camera = controls?.object;
    if (!(controls && isOrthographicCamera(camera))) {
        return;
    }

    // Apply the same translation to the camera position and the camera target.
    const translation = sphere.center.clone().sub(controls.target);
    controls.target.copy(sphere.center);
    camera.position.add(translation);

    // Zoom to fit the sphere in the viewport.
    const radius = sphere.radius * (1 + bufferRatio);
    camera.zoom = Math.min(camera.right - camera.left, camera.top - camera.bottom) / (2 * radius);

    camera.updateMatrixWorld(true);
    camera.updateProjectionMatrix();
    controls.update();
}
