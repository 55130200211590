import type { MeshData } from './DracoDecodeWorker';
import { DracoDecodeWorker } from './DracoDecodeWorker';
import type { DracoLoaderOptions } from './DracoLoaderTypes';
import { BufferAttribute, BufferGeometry } from 'three';

function makeBufferGeometry(meshData: MeshData): BufferGeometry {
    const geometry = new BufferGeometry();
    geometry.setIndex(new BufferAttribute(meshData.index, 1));
    for (const [name, attr] of Object.entries(meshData.attributes)) {
        geometry.setAttribute(name, new BufferAttribute(attr.data, attr.numComponents));
    }
    return geometry;
}

// Public interface for loading Draco-encoded data to three.js BufferGeometry.
// Most of this class is concerned with managing a pool of DracoDecodeWorkers,
// and feeding load requests into idle workers for processing.
export class DracoLoader {
    private static readonly MAX_WORKERS = 2;
    // Tracks the number of workers created or pending creation.
    private static workerCount = 0;
    private static workers: DracoDecodeWorker[] = [];
    private static queue: {
        data: ArrayBuffer;
        options: DracoLoaderOptions;
        onSuccess: (geometry: BufferGeometry) => void;
        onError: (error: unknown) => void;
    }[] = [];

    /** Loads Draco-encoded mesh data into a three.js BufferGeometry object. */
    static async load(data: ArrayBuffer, options: DracoLoaderOptions = {}): Promise<BufferGeometry> {
        const promise = new Promise<BufferGeometry>((res, rej) => {
            DracoLoader.queue.push({
                data,
                options: options,
                onSuccess: geometry => res(geometry),
                onError: error => rej(error),
            });
        });

        const worker = await DracoLoader.getAvailableWorker();
        if (worker) {
            DracoLoader.processNext(worker);
        }
        return promise;
    }

    private static async getAvailableWorker(): Promise<DracoDecodeWorker | undefined> {
        let idleWorker = DracoLoader.workers.find(w => w instanceof DracoDecodeWorker && !w.isBusy);
        if (!idleWorker && DracoLoader.workerCount < DracoLoader.MAX_WORKERS) {
            DracoLoader.workerCount += 1;
            idleWorker = await DracoDecodeWorker.create();
            DracoLoader.workers.push(idleWorker);
        }
        return idleWorker;
    }

    // Precondition: `worker` is not busy.
    private static processNext(worker: DracoDecodeWorker) {
        const next = DracoLoader.queue.shift();
        if (next) {
            worker
                .decode(next.data, next.options)
                .then(res => next.onSuccess(makeBufferGeometry(res)))
                .catch(next.onError)
                .finally(() => {
                    DracoLoader.processNext(worker);
                });
        }
    }
}
