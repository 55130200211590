import { extractPerformanceLog } from '../../DesignArchive';
import type {
    ParsedEventData,
    SessionData,
    SessionDataUsageSummaryDatum,
    TypedEventName,
} from './DesignPerformanceLogParser.types';
import { DesignPerformanceLogSchema, isEventOfName } from './DesignPerformanceLogParser.types';
import { ProcessedEvent } from './ProcessedEvent';
import _ from 'lodash';
import { create as createXML } from 'xmlbuilder2';

// A list of event names that are used to signify new sections.
const eventSectionNames: TypedEventName[] = [
    'Order',
    'Prepare',
    'Segmentation',
    'Interfaces',
    'Directions',
    'Anatomy pre-design',
    'Frame design',
    'Abutments/Post and Cores',
    'Anatomy design',
    'FD Initial Setup',
    'Export',
];

export class DesignPerformanceLogParser {
    private readonly performanceLog: DesignPerformanceLogSchema;
    // We arbitrarily chose 10 minutes as a significant enough gap between two events to identify a new session beginning.
    private static readonly TEN_MINUTES_IN_MS = 600_000;

    constructor(xmlString: string) {
        const xml = createXML(xmlString);
        const object = xml.toObject({});

        this.performanceLog = DesignPerformanceLogSchema.parse(object);
    }

    static async parseFile(lockedFileBuffer: Buffer): Promise<DesignPerformanceLogParser> {
        const unlockedFile = await extractPerformanceLog(lockedFileBuffer);

        if (!unlockedFile) {
            throw new Error('Failed to unlock Performance Log');
        }

        return new DesignPerformanceLogParser(unlockedFile);
    }

    // Given an index and the list of events, finds the first event before the provided index that isn't an "Idle" event.
    private static findPreviousNonIdleEvent(events: ProcessedEvent[], currentIdx: number): number | undefined {
        const previousIdx = _.findLastIndex(events, (candidate, idx) => {
            // This is not before the current index
            if (idx >= currentIdx) {
                return false;
            }

            // This is an idle event
            if (isEventOfName(candidate, 'Idle')) {
                return false;
            }

            return true;
        });

        return previousIdx === -1 ? undefined : previousIdx;
    }

    // Given an index and the list of events, finds the first event after the provided index that isn't an "Idle" event.
    static findNextNonIdleEvent(events: ProcessedEvent[], currentIdx: number): number | undefined {
        const nextIdx = events.findIndex((candidate, idx) => {
            // This is not after the current index
            if (idx <= currentIdx) {
                return false;
            }

            // This is an idle event
            if (isEventOfName(candidate, 'Idle')) {
                return false;
            }

            return true;
        });

        return nextIdx === -1 ? undefined : nextIdx;
    }

    private static get emptySession(): SessionData {
        return {
            gap_to_last_session: 0,
            session_start: 0,
            session_finish: undefined,
            session_duration: undefined,
            usage_summary: {},
            gaps: [],
        };
    }

    // Stores the event in the session's usage aggregation.
    private updateUsageSummary(sessionData: SessionData, event: ProcessedEvent): SessionData {
        const currentUsageSummary = sessionData.usage_summary[event.name];

        return {
            ...sessionData,
            usage_summary: {
                ...sessionData.usage_summary,
                [event.name]: {
                    count: (currentUsageSummary?.count ?? 0) + 1,
                    cumulative_duration: (currentUsageSummary?.cumulative_duration ?? 0) + (event.durationMs ?? 0),
                },
            },
        };
    }

    // If the event has an end time, returns it, otherwise returns the start time.
    // Sounds weird, but it's how it behaved in Python.
    private static getEndTime(event: ProcessedEvent): number {
        return event.utcCorrectedEnd?.getTime() ?? event.utcTime.getTime();
    }

    // This function does the heavy lifting of "processing" all of the events in the event log.
    // It is quite complex and painful, but is mostly translated directly from Python. I abstracted quite a bit of pain away to the ProcessedEvent class.
    // TODO: revisit and simplify this function further.
    // eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
    processEvents(): ParsedEventData {
        if (!this.performanceLog.PerformanceLog.Journal.Event) {
            throw new Error('No Events found');
        }

        const processedEvents = this.performanceLog.PerformanceLog.Journal.Event.flatMap(
            event => new ProcessedEvent(event),
        );

        const firstEvent = processedEvents[0];
        const lastEvent = processedEvents.at(-1);

        if (!firstEvent || !lastEvent) {
            throw new Error('No first or last event found');
        }

        const badEventIndices: number[] = [];

        const overallEndTime = lastEvent.utcCorrectedEnd ?? lastEvent.utcTime;

        const allSessions: SessionData[] = [];
        let currentSession: SessionData = DesignPerformanceLogParser.emptySession;
        let lastSectionEvent: ProcessedEvent | undefined;

        for (let idx = 0; idx < processedEvents.length; idx++) {
            const currentEvent = processedEvents.at(idx);
            const previousEvent = processedEvents.at(idx - 1);

            // Should never happen, for safety only.
            if (!currentEvent) {
                continue;
            }

            // Start the first session
            if (idx === 0) {
                currentSession.session_start = currentEvent.utcTime.getTime();
            }

            // are we starting a new section or a new Session?
            if (isEventOfName(currentEvent, eventSectionNames)) {
                if (lastSectionEvent) {
                    if (!lastSectionEvent.durationMs) {
                        let sectionEnd: number | undefined = 0;
                        // find most recent non idle event (walk backwards)
                        if (previousEvent && isEventOfName(previousEvent, 'Idle')) {
                            const previousNonIdleIdx = DesignPerformanceLogParser.findPreviousNonIdleEvent(
                                processedEvents,
                                idx,
                            );
                            const previousNonIdleEvent = processedEvents[previousNonIdleIdx ?? 0];
                            if (previousNonIdleEvent) {
                                sectionEnd = DesignPerformanceLogParser.getEndTime(previousNonIdleEvent);
                            } else {
                                sectionEnd = previousEvent.utcTime.getTime();
                            }
                        } else {
                            // previous section ended at end of the previous event!
                            // open question whether there is dead time and how significant it might be between previous_event and event
                            sectionEnd = previousEvent?.utcCorrectedEnd?.getTime() ?? previousEvent?.utcTime.getTime();
                        }

                        const session_duration = sectionEnd
                            ? sectionEnd - lastSectionEvent.utcTime.getTime()
                            : undefined;
                        lastSectionEvent.durationMs = session_duration;
                        lastSectionEvent.utcCorrectedEnd = sectionEnd ? new Date(sectionEnd) : undefined;
                        lastSectionEvent.overriddenRawEndTime = true;
                    }

                    //  We skipped this previously when we continue and we need to update
                    //  now that we are sure we found its duration we can add the usage
                    currentSession = this.updateUsageSummary(currentSession, lastSectionEvent);

                    // How long has it been since the last section event to the current one?
                    // A ten minute gap or more signifies a new Session
                    const lastSectionEnd = DesignPerformanceLogParser.getEndTime(lastSectionEvent);
                    const sessionGap = currentEvent.utcTime.getTime() - lastSectionEnd;

                    // If session gap is more than 10 minutes
                    if (sessionGap > DesignPerformanceLogParser.TEN_MINUTES_IN_MS) {
                        currentSession.session_finish = lastSectionEnd;
                        currentSession.session_duration = lastSectionEnd - currentSession.session_start;

                        allSessions.push(currentSession);
                        currentSession = {
                            ...DesignPerformanceLogParser.emptySession,
                            session_start: currentEvent.utcTime.getTime(),
                            gap_to_last_session: sessionGap,
                        };
                    }
                }

                // Want to keep track of last section
                lastSectionEvent = currentEvent;
                continue;
            }

            // do some correction on our children events
            if (currentEvent.children.length) {
                ProcessedEvent.validateEventWithChildren(processedEvents, idx);
                for (const child of currentEvent.children) {
                    currentSession = this.updateUsageSummary(currentSession, child);
                }
            }

            // Do some correction on userinteractive events
            if (isEventOfName(currentEvent, ['TSculpt_AddRemoveSmooth', 'TSculpt_DentalAddRemoveSmoothRefModel'])) {
                ProcessedEvent.validateEventDuration(processedEvents, idx, currentEvent);
            }

            // do some checking for Idle events which can be out of sync
            if (previousEvent && isEventOfName(currentEvent, 'Idle')) {
                //  check if this Idle event starts before the session start or before previous event starts
                if (
                    currentEvent.utcTime.getTime() < currentSession.session_start ||
                    currentEvent.utcTime.getTime() < previousEvent.utcTime.getTime()
                ) {
                    badEventIndices.push(idx);
                    // continue to not reduce into the usage summary!
                    continue;
                }

                //  check if next non Idle event starts before Idle event ends
                if (idx <= processedEvents.length - 1 && currentEvent.utcCorrectedEnd) {
                    const nonIdleIdx = DesignPerformanceLogParser.findNextNonIdleEvent(processedEvents, idx);
                    const nonIdleEvent = processedEvents[nonIdleIdx ?? -1];

                    // this should indicate the end of the event stream!
                    if (!nonIdleEvent) {
                        continue;
                    }

                    //  should we adjust the end time? for now just ommit it!
                    if (nonIdleEvent.utcTime.getTime() < currentEvent.utcCorrectedEnd?.getTime()) {
                        badEventIndices.push(idx);
                        continue;
                    }
                }
            }

            // check for long gaps
            if (previousEvent) {
                const lastEventTime = DesignPerformanceLogParser.getEndTime(previousEvent);
                const gap = currentEvent.utcTime.getTime() - lastEventTime;
                if (gap > DesignPerformanceLogParser.TEN_MINUTES_IN_MS) {
                    currentSession.gaps.push({ gap, currentEvent, previousEvent });
                }
            }

            // Actually put the event into the session usage summary
            currentSession = this.updateUsageSummary(currentSession, currentEvent);
        }

        // Use final event to record the last session_duration
        if (!currentSession.session_duration && lastSectionEvent) {
            currentSession = this.updateUsageSummary(currentSession, lastSectionEvent);
            const lastEvent = processedEvents.at(-1);
            const lastNonIdleEventIdx = DesignPerformanceLogParser.findPreviousNonIdleEvent(
                processedEvents,
                processedEvents.length - 1,
            );
            const resolvedLastNonIdleEvent =
                lastEvent && isEventOfName(lastEvent, 'Idle') && lastNonIdleEventIdx !== undefined
                    ? processedEvents[lastNonIdleEventIdx]
                    : lastEvent;

            if (resolvedLastNonIdleEvent) {
                const estimatedEnd = DesignPerformanceLogParser.getEndTime(resolvedLastNonIdleEvent);
                currentSession.session_finish = estimatedEnd;
                currentSession.session_duration = estimatedEnd - currentSession.session_start;
            }
        }

        // Actually push that last session to the list
        allSessions.push(currentSession);

        const aggregateSessionUsage = allSessions.reduce<Record<string, SessionDataUsageSummaryDatum>>(
            (state, session) => {
                return Object.entries(session.usage_summary).reduce<Record<string, SessionDataUsageSummaryDatum>>(
                    (innerState, summary) => {
                        return {
                            ...innerState,
                            [summary[0]]: {
                                count: (innerState[summary[0]]?.count ?? 0) + summary[1].count,
                                cumulative_duration:
                                    (innerState[summary[0]]?.cumulative_duration ?? 0) + summary[1].cumulative_duration,
                            },
                        };
                    },
                    state,
                );
            },
            {},
        );

        return {
            event_analysis_version: '001_2022_06_30',
            FEATURE_n_events: DesignPerformanceLogParser.getTotalEventCount(processedEvents),
            FEATURE_n_sessions: allSessions.length,
            FEATURE_raw_event_total_timespan_ms:
                overallEndTime.getTime() - (processedEvents[0]?.utcTime.getTime() ?? 0),
            FEATURE_raw_idle_time_ms: _.sumBy(processedEvents, event =>
                isEventOfName(event, 'Idle') ? event.durationMs ?? 0 : 0,
            ),
            FEATURE_cumulative_session_time: _.sumBy(allSessions, session => session.session_duration ?? 0),
            FEATURE_total_gap_between_sessions: _.sumBy(allSessions, session => session.gap_to_last_session),
            bad_events: badEventIndices,
            sessions: allSessions,
            parsed_events: processedEvents.map(event => event.getOutputEventSchema()),
            first_event_timestamp: processedEvents[0]?.utcTime.getTime() ?? 0,
            aggregate_session_usage: aggregateSessionUsage,
            FEATURE_Prepare_duration: DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                aggregateSessionUsage,
                'Prepare',
            ),
            FEATURE_Segmentation_duration: DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                aggregateSessionUsage,
                'Segmentation',
            ),
            FEATURE_Interfaces_duration: DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                aggregateSessionUsage,
                'Interfaces',
            ),
            FEATURE_Directions_duration: DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                aggregateSessionUsage,
                'Directions',
            ),
            'FEATURE_Anatomy_pre-design_duration':
                DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                    aggregateSessionUsage,
                    'Anatomy pre-design',
                ),
            FEATURE_Frame_design_duration: DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                aggregateSessionUsage,
                'Frame design',
            ),
            FEATURE_Abutments_Post_and_Cores_duration:
                DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                    aggregateSessionUsage,
                    'Abutments/Post and Cores',
                ),
            FEATURE_Anatomy_design_duration:
                DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                    aggregateSessionUsage,
                    'Anatomy design',
                ),
            FEATURE_Idle_duration: DesignPerformanceLogParser.getAggregateSessionCumulativeUsageDurationOfType(
                aggregateSessionUsage,
                'Idle',
            ),
        };
    }

    private static getAggregateSessionCumulativeUsageDurationOfType(
        aggregateSessionUsage: Record<string, SessionDataUsageSummaryDatum>,
        key: TypedEventName,
    ): number {
        return aggregateSessionUsage[key]?.cumulative_duration ?? 0;
    }

    private static getTotalEventCount(events: ProcessedEvent[]): number {
        return events.reduce((state, event) => {
            if (event.children.length) {
                return state + 1 + DesignPerformanceLogParser.getTotalEventCount(event.children);
            }

            return state + 1;
        }, 0);
    }
}
