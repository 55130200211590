import * as THREE from 'three';

/**
 * To add new types, just add a key and constructor here, nothing below needs to be modified.
 */
const KEYS_AND_CTORS = [
    ['vector3', THREE.Vector3],
    ['vector4', THREE.Vector4],
    ['matrix3', THREE.Matrix3],
    ['matrix4', THREE.Matrix4],
    ['line3', THREE.Line3],
    ['ray', THREE.Ray],
    ['plane', THREE.Plane],
    ['triangle', THREE.Triangle],
    ['quaternion', THREE.Quaternion],
] as const;

// A bunch of utility types to help automate turning the above into something useful
type KeysAndCtors = typeof KEYS_AND_CTORS;
// Need a helper type here to extract the key element from each inner tuple
type GetKeyOfEach<Tuple extends readonly KeysAndCtors[number][] = KeysAndCtors> = Tuple extends readonly [
    infer F extends KeysAndCtors[number],
    ...infer Rest extends readonly KeysAndCtors[number][],
]
    ? [F[0], ...GetKeyOfEach<Rest>]
    : [];
type AllKeys = GetKeyOfEach;
type ObjectKey = AllKeys[number];
// Need a way to find the associated constructor type from a given key
type FindConstructor<
    Key extends ObjectKey,
    Acc extends readonly unknown[] = [],
> = Key extends KeysAndCtors[Acc['length']][0] ? KeysAndCtors[Acc['length']][1] : FindConstructor<Key, [0, ...Acc]>;
type ConstructorMap = { [K in ObjectKey]: FindConstructor<K> };
type ObjectTypeMap = { [K in ObjectKey]: InstanceType<ConstructorMap[K]> };
type ObjectType = ObjectTypeMap[ObjectKey];
// Used by the autoAcquire method to map a tuple of object keys to a tuple of matching types
type AutoAcquire<Keys extends readonly [...ObjectKey[]]> = { [Index in keyof Keys]: ObjectTypeMap[Keys[Index]] } & {
    length: Keys['length'];
};
type Storage = { [K in ObjectKey]: ObjectTypeMap[K][] };

// Now we have some utility constants, the first is an object mapping keys to constructors
const CTOR_MAP = ((): ConstructorMap => {
    const obj = {} as Record<string, unknown>;
    for (const [key, ctor] of KEYS_AND_CTORS) {
        obj[key] = ctor;
    }
    return obj as ConstructorMap;
})();

// All the keys as a tuple
const KEYS = KEYS_AND_CTORS.map(([key]) => key) as AllKeys;

function makeStorage(): Storage {
    const o = {} as Storage;
    for (const key of KEYS) {
        o[key] = [];
    }
    return o;
}

class ThreeObjectCache {
    private storage: Storage = makeStorage();

    // Add an overload so the return type works correctly. The actual implementation can't use this
    // return type since TypeScript will interpret it to be an intersection of all object types
    // rather than a union. An alternative approach would be to add an implicit generic parameter
    // for the return type, but this requires type assertions for each return statement.
    /**
     * Acquires objects from the cache or creates new ones if the cache is empty.
     * @param key The type of object to acquire.
     */
    acquire<Key extends ObjectKey>(key: Key): ObjectTypeMap[Key];
    acquire<Key extends ObjectKey>(key: Key): ObjectType {
        return this.storage[key].pop() ?? new CTOR_MAP[key]();
    }

    /**
     * Releases objects back to the cache for reuse.
     * @param objs The objects to release.
     */
    release(...objs: ObjectType[]) {
        for (const obj of objs) {
            for (const [key, ctor] of KEYS_AND_CTORS) {
                if (obj instanceof ctor) {
                    // Need the any type assertion here to allow us to push to the vector; there's
                    // not really any good way to associate the instanceof check with the actual
                    // type of this.storage[key].
                    this.storage[key].push(obj as any);
                    break;
                }
            }
        }
    }

    /**
     * Provides a function that can acquire and automatically release objects.
     * @example
     * THREE_CACHE.autoAcquire('vector3', 'vector3')(
     *     (vec1, vec2) => {
     *         // Use vec1 and vec2 here
     *     }
     * );
     * @example
     * // autoAcquire will also pass through return values
     * const distance = THREE_CACHE.autoAcquire('vector3', 'vector3')(
     *     (vec1, vec2) => {
     *         // Use vec1 and vec2 here
     *         return vec1.distanceTo(vec2);
     *     }
     * );
     * @param keys The key types to acquire.
     * @returns A callable function that accepts a callback that will receive the acquired objects
     *  as parameters.
     */
    autoAcquire<Keys extends readonly [...ObjectKey[]]>(
        ...keys: Keys
    ): <R>(body: (...args: AutoAcquire<Keys>) => R) => R {
        return body => {
            const objs = keys.map(k => this.acquire(k)) as AutoAcquire<Keys>;
            try {
                return body.apply(null, objs);
            } finally {
                this.release(...objs);
            }
        };
    }
}

export const THREE_CACHE = new ThreeObjectCache();
