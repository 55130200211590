import type { CrossSectionEntry } from './CrossSectionData';
import { getLineStyle, useStyles } from './CrossSectionStyles';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { HelpIcon, IconToggleButton } from '@orthly/ui';
import type { TooltipProps } from '@orthly/ui-primitives';
import { FlossPalette, Text, Tooltip, Grid, withStyles } from '@orthly/ui-primitives';
import React from 'react';

type LegendIconShape = 'line' | 'circle';

interface LegendIconProps {
    color: string;
    shape: LegendIconShape;
}

const LegendIcon: React.FC<LegendIconProps> = ({ color, shape }) => {
    const draw = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d');
        if (!ctx) {
            return;
        }

        ctx.strokeStyle = color;

        ctx.beginPath();
        if (shape === 'line') {
            // Although the cross section entries ostensibly have varying widths, they're not obviously different in
            // the cross section view (at least not 1 and 2 pixels), and it's difficult to see the color of a 1
            // pixel wide line in the legend, so we use a width of 2 pixels for all line entity legend icons.
            // NB: https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Applying_styles_and_colors#a_linewidth_example
            ctx.lineWidth = 2;

            ctx.moveTo(0, 8);
            ctx.lineTo(16, 8);
        } else {
            ctx.lineWidth = 1;
            ctx.arc(8, 8, 4, 0, 2 * Math.PI);
        }
        ctx.stroke();
    };

    return <canvas ref={draw} height={16} width={16} />;
};

type LegendEntryProps = LegendIconProps & {
    label: string;
};

const LegendEntry: React.FC<LegendEntryProps> = ({ label, ...iconProps }) => {
    return (
        <Grid item container direction={'row'} justifyContent={'flex-start'} alignItems={'center'}>
            <Grid item>
                <LegendIcon {...iconProps} />
            </Grid>
            <Grid item>
                <Text variant={'body2'} color={'GRAY'} style={{ lineHeight: '1.2em', paddingLeft: 4 }}>
                    {label}
                </Text>
            </Grid>
        </Grid>
    );
};

type LegendProps = {
    entries: CrossSectionEntry[] | undefined;
};

type LegendPopperProps = Omit<TooltipProps, 'title'> & LegendProps;

// MUI Popover would have been a more natural choice, but it messed up the layout of the page somehow, so we use a
// Tooltip.
const LegendPopperUnstyled: React.FC<LegendPopperProps> = ({ children, entries, ...tooltipProps }) => {
    const legendContents = React.useMemo(() => {
        const hasUpdatedMargin = entries?.some(e => e.type === 'UpdatedMarginLine');
        const hasPastRestoratives = entries?.some(e => e.type === LabsGqlOrderDesignScanType.Cad && e.isPast);

        const currentRestorativesLabel = hasPastRestoratives ? 'Current Restorative' : 'Restorative';
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        const currentMarginLineLabel = hasUpdatedMargin
            ? 'Original Margin Line'
            : hasPastRestoratives
              ? 'Current Margin Line'
              : 'Margin Line';

        const legendEntries: React.ReactElement[] = [
            ['Scan', getLineStyle({ type: LabsGqlOrderDesignScanType.Scans }), 'line'],
            [currentRestorativesLabel, getLineStyle({ type: LabsGqlOrderDesignScanType.Cad }), 'line'],
            [currentMarginLineLabel, getLineStyle({ type: 'MarginLine' }), 'circle'],
            ['Undercut Curtain', getLineStyle({ type: LabsGqlOrderDesignScanType.QcExtras }), 'line'],
        ].map(([label, lineSpec, shape]) => {
            return <LegendEntry key={label} label={label} color={lineSpec.color} shape={shape} />;
        });

        if (hasPastRestoratives) {
            [
                ['Past Restorative', getLineStyle({ type: LabsGqlOrderDesignScanType.Cad, isPast: true }), 'line'],
                ['Past Margin Line', getLineStyle({ type: 'MarginLine', isPast: true }), 'circle'],
            ].forEach(([label, lineSpec, shape]) => {
                legendEntries.push(<LegendEntry label={label} color={lineSpec.color} shape={shape} />);
            });
        }

        if (hasUpdatedMargin) {
            const lineSpec = getLineStyle({ type: 'UpdatedMarginLine' });
            legendEntries.push(<LegendEntry label={'Edited Margin Line'} color={lineSpec.color} shape={'circle'} />);
        }

        return (
            <Grid container direction={'column'}>
                {legendEntries}
            </Grid>
        );
    }, [entries]);

    return (
        <Tooltip {...tooltipProps} title={legendContents}>
            {children}
        </Tooltip>
    );
};

const LegendPopper = withStyles({
    tooltip: {
        maxWidth: 'none',
        padding: 4,
        margin: 0,
        background: FlossPalette.WHITE,
        color: FlossPalette.GRAY,
        border: `1px solid ${FlossPalette.STROKE_DARK}`,
        borderRadius: 3,
        boxShadow: 'none',
    },
})(LegendPopperUnstyled);

export const Legend: React.FC<LegendProps> = ({ entries }) => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const toggleIsOpen = React.useCallback(() => {
        setIsOpen(current => !current);
    }, [setIsOpen]);

    return (
        <IconToggleButton
            className={classes.headerButton}
            tooltip={isOpen ? 'Hide Legend' : 'Show Legend'}
            tooltipPlacement={'left'}
            active={isOpen}
            onClick={toggleIsOpen}
        >
            <LegendPopper open={isOpen} entries={entries}>
                <HelpIcon />
            </LegendPopper>
        </IconToggleButton>
    );
};
