import { SelectStaffActions } from '../../screens/select-staff/state/select-staff.actions';
import { OrdersActions } from './orders.actions';
import type { LabsGqlProductLine } from '@orthly/graphql-schema';
import { LabsGqlOrdersSortField, LabsGqlPracticeOrderStatus, LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { handleActions } from 'redux-actions';

export type OrdersSortField = LabsGqlOrdersSortField;

export interface OrdersState {
    status: LabsGqlPracticeOrderStatus;
    readEventIds: number[];
    doctor_id_filter: string[];
    product_line_filter: LabsGqlProductLine[];
    sort_column: LabsGqlOrdersSortField;
    sort_asc: boolean;
}

const initialState: OrdersState = {
    status: LabsGqlPracticeOrderStatus.All,
    readEventIds: [],
    doctor_id_filter: [],
    product_line_filter: [],
    sort_column: LabsGqlOrdersSortField.Status,
    sort_asc: true,
};

export const ordersReducer = handleActions<OrdersState, any>(
    {
        ...SelectStaffActions.SELECT_PRACTICE_EMPLOYEE.reducer<OrdersState>((state, action) => ({
            ...state,
            doctor_id_filter: action.payload.roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)
                ? [action.payload.id]
                : initialState.doctor_id_filter,
        })),
        ...OrdersActions.CHANGE_STATUS.reducer<OrdersState>((state, action) => ({
            ...state,
            status: action.payload,
        })),
        ...OrdersActions.LOAD_READ_EVENTS.reducer<OrdersState>((state, action) => ({
            ...state,
            readEventIds: action.payload,
        })),
        ...OrdersActions.MARK_EVENTS_READ.reducer<OrdersState>((state, action) => ({
            ...state,
            readEventIds: action.payload,
        })),
        ...OrdersActions.SET_DOCTOR_FILTER.reducer<OrdersState>((state, action) => ({
            ...state,
            doctor_id_filter: action.payload,
        })),
        ...OrdersActions.SET_PRODUCT_FILTER.reducer<OrdersState>((state, action) => ({
            ...state,
            product_line_filter: action.payload,
        })),
        ...OrdersActions.SET_SORT_COL.reducer<OrdersState>((state, action) => ({
            ...state,
            sort_column: action.payload,
            // if we're switching to a new sort col, switch to DESC for dates, ASC for names (A-Z).
            sort_asc: action.payload !== state.sort_column ? action.payload === 'patient_name' : state.sort_asc,
        })),
        ...OrdersActions.TOGGLE_SORT_DIR.reducer<OrdersState>(state => ({ ...state, sort_asc: !state.sort_asc })),
    },
    initialState,
);
