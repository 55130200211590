import { useInvoicesTableContext } from '../../components/providers/InvoicesTableProvider.graphql';
import { gqlFragmentsToPaymentMethods } from '../../invoicing.utils';
import { PaymentInvoiceDetails } from './PaymentDialogDetails.graphql';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlPaymentMethodFragment } from '@orthly/graphql-operations';
import { useLabPaymentMethodsQuery } from '@orthly/graphql-react';
import { LoadBlocker, useRootActionCommand } from '@orthly/ui';
import { Button, FormControl, MenuItem, Select, Text, styled } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const DetailsContainer = styled('div')({
    paddingBottom: '12px',
});

const StyledSelect = styled(Select)({
    padding: '0px 0px 8px 0px !important',
    '&& div': {
        padding: '16px 16px 8px 16px !important',
    },
});

const PaymentMethodMenuItem: React.FC<{ paymentMethod: LabsGqlPaymentMethodFragment }> = ({ paymentMethod: pm }) => {
    let content = pm.method === 'card' ? `${pm.brand} ending in ${pm.last4}` : `ACH (${pm.name}) ending in ${pm.last4}`;
    if (pm.is_default) {
        content += ' (default)';
    }

    return <Text variant={'body2'}>{content}</Text>;
};

const PaymentMethodContainer: React.FC<{
    paymentMethods: LabsGqlPaymentMethodFragment[];
    selectedId: string | undefined;
    onChange: React.Dispatch<React.SetStateAction<string>>;
}> = ({ paymentMethods, selectedId, onChange }) => (
    <>
        <Text variant={'h6'} style={{ marginTop: '24px', marginBottom: '16px' }}>
            Pay with method
        </Text>
        <FormControl fullWidth variant={'standard'}>
            <StyledSelect
                fullWidth
                defaultValue={selectedId ?? ''}
                displayEmpty={true}
                onChange={event => {
                    const pmId = event.target.value as string;
                    onChange(pmId);
                }}
                variant={'standard'}
            >
                <MenuItem disabled value={''}>
                    <em>Select a payment method</em>
                </MenuItem>
                {paymentMethods.map(pm => (
                    <MenuItem style={{ width: '100%' }} value={pm.id} key={pm.id}>
                        <PaymentMethodMenuItem paymentMethod={pm} />
                    </MenuItem>
                ))}
            </StyledSelect>
        </FormControl>
    </>
);

interface PaymentContainerProps {
    paymentMethods: LabsGqlPaymentMethodFragment[];
    selectedPaymentMethodId: string;
    setSelectedPaymentMethodId: React.Dispatch<React.SetStateAction<string>>;
    onClose: () => void;
    onSubmit: () => void;
}

const PaymentContainer: React.FC<PaymentContainerProps> = ({
    paymentMethods,
    onClose,
    selectedPaymentMethodId,
    setSelectedPaymentMethodId,
    onSubmit,
}) => {
    const { payableSelectedInvoices } = useInvoicesTableContext();
    const buttonText = payableSelectedInvoices.length > 1 ? 'Pay Invoices' : 'Pay Invoice';

    return (
        <div style={{ paddingTop: '24px', width: '100%' }}>
            <PaymentMethodContainer
                paymentMethods={paymentMethods}
                selectedId={selectedPaymentMethodId}
                onChange={setSelectedPaymentMethodId}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
                <Button variant={'secondary'} onClick={onClose}>
                    Cancel
                </Button>
                <Button variant={'primary'} style={{ marginLeft: '8px' }} onClick={onSubmit}>
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

const PayInvoiceWithSource_Mutation = graphql(`
    mutation PayInvoiceWithSource($data: PayInvoiceWithSourceInput!) {
        payInvoiceWithPaymentSource(data: $data) {
            id
        }
    }
`);

const PayContractInvoicesWithSource_Mutation = graphql(`
    mutation PayContractInvoicesWithPaymentSource($data: PayContractInvoicesWithSourceInput!) {
        payContractInvoicesWithPaymentSource(data: $data) {
            id
        }
    }
`);

interface PaymentDialogProps {
    invoiceDetailId?: string; // manually provided invoice id which we're paying
    invoiceIds?: string[]; // manually provided list of *all* payable invoice ids
    refetchInvoice?: () => Promise<unknown>;
    onClose: () => void;
    willBeChargedCCFee?: boolean;
}

/**
 * The payment dialog can be accessed in a few ways:
 * // TODO: EPDB-975: clean up legacy comment
 * - from the invoices overview table (inline) - soon to be deprecated for multilocation payment options
 *   - in this case, we're only paying one invoice, so we provide an `invoiceDetailId`
 *
 * - from the invoices overview table (payment tile) - when enableMultiLocationContracts is enabled
 *   - here, we're paying *all* of the selected org's open/payable invoices. if no org is selected,
 *     we're paying all of the contracted practice's open/payable invoices, passed in as `invoiceIds`
 *
 * - from the invoices table controls - when enableMultiLocationContracts is enabled
 *   - here, we're *only* paying the selected "payable" invoices. since selection is handled statefully
 *     in the invoices table context provider, so we don't pass in any variables (use useInvoicesTableContext instead)
 *
 * - from the invoice details screen
 *   - in this case, we're only paying one invoice, so we provide an `invoiceDetailId`
 */
export const PaymentDialog: React.FC<PaymentDialogProps> = ({
    invoiceDetailId,
    invoiceIds,
    refetchInvoice: refetchInvoiceDetail,
    onClose,
    willBeChargedCCFee,
}) => {
    const { value: enableMultiLocationContracts } = useFeatureFlag('enableMultiLocationContracts');
    const { refetch: refetchInvoicesList, payableSelectedInvoices, setSelectedInvoiceIds } = useInvoicesTableContext();

    const { data: paymentMethodsData, loading: paymentMethodsLoading } = useLabPaymentMethodsQuery();
    const paymentMethods = gqlFragmentsToPaymentMethods(paymentMethodsData) ?? [];
    const defaultPaymentMethod = paymentMethods.find(pm => pm.is_default);
    const [selectedPaymentMethodId, setSelectedPaymentMethodId] = React.useState(defaultPaymentMethod?.id ?? '');

    const paymentMethodIsCreditCard = () => {
        const selectedPaymentMethod = paymentMethods.find(pm => pm.id === selectedPaymentMethodId);
        return selectedPaymentMethod?.method === 'card' && selectedPaymentMethod?.funding === 'credit';
    };

    const payInvoiceMtn = useMutation(PayInvoiceWithSource_Mutation);
    const payContractInvoicesMtn = useMutation(PayContractInvoicesWithSource_Mutation);

    const handleSuccess = async () => {
        // When paying from the invoice detail page, we need to refetch the specific invoice that was being paid so that
        // the payment button disappears
        if (refetchInvoiceDetail) {
            await refetchInvoiceDetail();
        }
        // And to ensure that the list of invoices being rendered on the invoices overview screen also updates after
        // payment is made from the invoice detail screen, we refetch the total list of invoices here
        await refetchInvoicesList();
        // unset any selected invoice ids
        if (!invoiceDetailId && !invoiceIds) {
            setSelectedInvoiceIds([]);
        }
        onClose();
    };

    const { submit: payInvoice, submitting: payingInvoice } = useRootActionCommand(payInvoiceMtn, {
        successMessage: () => [
            'Thank you! Your payment has been created.',
            { anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
        ],
        onSuccess: handleSuccess,
    });

    const { submit: payContractInvoices, submitting: payingContractInvoices } = useRootActionCommand(
        payContractInvoicesMtn,
        {
            successMessage: () => [
                'Thank you! Your payment has been created.',
                { anchorOrigin: { vertical: 'bottom', horizontal: 'right' } },
            ],
            onSuccess: handleSuccess,
        },
    );

    const onSubmit = () => {
        // when multilocation contracts are enabled, the contract primary has access to
        // paying other locations' invoices. In this case, we need to use the payContractInvoicesWithPaymentSource mutation
        // (even if they're only paying one invoice) to ensure the paying org has permissions
        if (enableMultiLocationContracts) {
            if (invoiceDetailId) {
                void payContractInvoices({
                    data: { invoiceIds: [invoiceDetailId], sourceId: selectedPaymentMethodId },
                });
            } else {
                // contract secondary locations will only have access to their own invoices
                // which they are able to pay simulataneously (if they have multiple selected)
                const ids = invoiceIds ?? payableSelectedInvoices.map(i => i.id);
                void payContractInvoices({
                    data: { invoiceIds: ids, sourceId: selectedPaymentMethodId },
                });
            }
        } else {
            // if multilocation contracts are not enabled, or if the practice is not the contract primary
            // they won't have access to pay multiple invoices at once, and the only (singular) invoices
            // they'll have access to pay will be their own. we can use the payInvoiceWithPaymentSource mutation
            if (!invoiceDetailId) {
                return;
            }
            void payInvoice({
                data: { invoiceId: invoiceDetailId, sourceId: selectedPaymentMethodId },
            });
        }
    };

    return (
        <>
            <DetailsContainer>
                <PaymentInvoiceDetails
                    invoiceDetailId={invoiceDetailId}
                    invoiceIds={invoiceIds}
                    paymentMethodIsCreditCard={paymentMethodIsCreditCard()}
                    willBeChargedCCFee={willBeChargedCCFee}
                />
            </DetailsContainer>
            <LoadBlocker blocking={paymentMethodsLoading || payingInvoice || payingContractInvoices}>
                <PaymentContainer
                    paymentMethods={paymentMethods}
                    selectedPaymentMethodId={selectedPaymentMethodId}
                    setSelectedPaymentMethodId={setSelectedPaymentMethodId}
                    onSubmit={onSubmit}
                    onClose={onClose}
                />
            </LoadBlocker>
        </>
    );
};
