import type { ArrayN } from '@orthly/runtime-utils';
import type * as THREE from 'three';

export type ShaderReplacement = readonly [string | RegExp, string];

export type TypedUniform<T> = Omit<THREE.Uniform, 'value'> & { value: T };

/**
 * A type mapping from GLSL types to compatible Typescript types
 */
export type WebGLTypeMap = {
    int: number;
    uint: number;
    float: number;
    bool: boolean | number;
    vec2: THREE.Vector2 | Float32Array | ArrayN<number, 2>;
    vec3: THREE.Vector3 | THREE.Color | Float32Array | ArrayN<number, 3>;
    vec4: THREE.Vector4 | THREE.Quaternion | Float32Array | ArrayN<number, 4>;
    mat2: Float32Array | ArrayN<number, 4>;
    mat3: THREE.Matrix3 | Float32Array | ArrayN<number, 9>;
    mat4: THREE.Matrix4 | Float32Array | ArrayN<number, 16>;
    sampler2D: THREE.Texture;
};

type StripUniformKeyword<Decl extends string> = Decl extends `uniform ${infer S}` ? S : Decl;
type StripSemicolon<Decl extends string> = Decl extends `${infer S};` ? S : Decl;
type StripDecl<Decl extends string> = StripUniformKeyword<StripSemicolon<Decl>>;
type UniformIdent<Decl extends string> =
    StripDecl<Decl> extends `${keyof WebGLTypeMap} ${infer S}`
        ? S extends `${infer Name}[${number}]`
            ? Name
            : S
        : never;
type ParseUniform<Decl extends string> =
    StripDecl<Decl> extends `${infer Type extends keyof WebGLTypeMap} ${infer S}`
        ? S extends `${infer Name}[${infer N extends number}]`
            ? { [K in Name]: TypedUniform<ArrayN<WebGLTypeMap[Type], N>> }
            : { [K in S]: TypedUniform<WebGLTypeMap[Type]> }
        : never;
/**
 * Provides a strong type definition for shader uniforms parsed from constant declarations.
 */
export type UniformsObject<Decls extends readonly string[]> = number extends Decls['length']
    ? never
    : Decls extends readonly [infer First extends string, ...infer Rest extends readonly string[]]
      ? ParseUniform<First> & UniformsObject<Rest>
      : {};

export type UniformNames<Decls extends readonly string[]> = Decls extends readonly [
    infer First extends string,
    ...infer Rest extends readonly string[],
]
    ? [UniformIdent<First>, ...UniformNames<Rest>]
    : [];

function applyReplacements(base: string, replacements: readonly ShaderReplacement[]) {
    return replacements.reduce(
        (shader, [replace, replacement]) => shader.replace(replace, s => `${replacement}\n${s}`),
        base,
    );
}

function fixDecl(decl: string) {
    const hasUniform = /^uniform\s/.test(decl);
    const hasSemicolon = /;\s*$/.test(decl);
    if (hasUniform) {
        return hasSemicolon ? decl : `${decl};`;
    } else {
        return hasSemicolon ? `uniform ${decl}` : `uniform ${decl};`;
    }
}

function getUniformName<Decl extends string>(decl: Decl): UniformIdent<Decl> {
    const result = /^(?:uniform)?\s*(?:u?int|float|bool|(?:vec|mat)[234]|sampler2D) ([_A-Za-z][_A-Za-z0-9]*)/.exec(
        decl,
    );
    const ident = result?.[1];
    // Just assert the type; for the most part the ParseUniform will result in a compile error if
    // the declaration is incorrectly formatted, save for some pathological cases you'd have to go
    // out of your way to cause.
    // If this becomes a problem we can revisit the type definition to make it more robust.
    return ident as UniformIdent<Decl>;
}

class ShaderBuilder<UniformDecls extends readonly string[]> {
    private _cacheKey: string;
    private _uniformNames: UniformNames<UniformDecls>;
    private _vertRepl: ShaderReplacement[];
    private _fragRepl: ShaderReplacement[];

    constructor(
        cacheKey: string,
        uniformDecls: UniformDecls,
        vertexRepl: readonly ShaderReplacement[],
        fragmentRepl: readonly ShaderReplacement[],
    ) {
        this._cacheKey = cacheKey;
        const uniformRepl: ShaderReplacement = ['#include <common>', uniformDecls.map(fixDecl).join('\n')];
        this._uniformNames = uniformDecls.map(getUniformName) as UniformNames<UniformDecls>;
        this._vertRepl = [uniformRepl, ...vertexRepl];
        this._fragRepl = [uniformRepl, ...fragmentRepl];
    }

    applyShader(baseMaterial: THREE.Material, uniforms: UniformsObject<UniformDecls>) {
        baseMaterial.onBeforeCompile = shader => {
            for (const name of this._uniformNames) {
                shader.uniforms[name] = uniforms[name];
            }
            shader.vertexShader = applyReplacements(shader.vertexShader, this._vertRepl);
            shader.fragmentShader = applyReplacements(shader.fragmentShader, this._fragRepl);
        };

        // NB: Without setting this callback, three.js may not be able to distinguish between different materials. See
        // EPDCAD-743 for an example.
        baseMaterial.customProgramCacheKey = () => this._cacheKey;
    }
}

// The signature for the below function is a little peculiar. Ideally we could constrain UniformDecls
// to be a tuple type, but there is currently no mechanism to do this. Instead we modify the type of
// the first argument to check that the length of `UniformDecls` is compile-time constant by checking
// if `number` extends it. If `number` extends `UniformDecls['length']` then it's *not* constant and
// we set the first argument type to `never`, which will cause a compile error. Otherwise we just set
// it to `UniformDecls` and everything works. Surprisingly TypeScript can still do the generic deduction
// in spite of all this, so the call-site doesn't need to explicitly specify the generic type.

/**
 * Creates a new shader factory that can be used to apply a shader to a `THREE.Material`.
 * @param cacheKey A unique identifier for this material type.
 * @param uniformDecls A tuple of GLSL uniform declarations.
 * @param vertexRepl The vertex shader replacement rules.
 * @param fragmentRepl The fragment shader replacement rules.
 * @returns A new shader factory instance that can apply the shader to a `THREE.Material`.
 */
export function makeShaderBuilder<UniformDecls extends readonly string[]>(
    cacheKey: string,
    uniformDecls: number extends UniformDecls['length'] ? never : UniformDecls,
    vertexRepl: readonly ShaderReplacement[],
    fragmentRepl: readonly ShaderReplacement[],
) {
    return new ShaderBuilder<UniformDecls>(cacheKey, uniformDecls, vertexRepl, fragmentRepl);
}
