import { useFileUploaderVars } from './FileUploader.utils';
import { SimpleDropzone } from './FileUploaderDropzone';
import { FileUploaderLayout, FileUploaderSubmitButton } from './FileUploaderLayout';
import { FirebaseUploadFileList } from './FirebaseUploadFileList';
import type { FileUploaderFieldResult } from './file-uploader-types';
import type { BucketStoragePathConfig } from '@orthly/shared-types';
import { LoadBlocker, SmallTrashCanIcon } from '@orthly/ui';
import {
    FlossPalette,
    FlossPaletteUtils,
    stylesFactory,
    Collapse,
    Grid,
    IconButton,
    ChevronRight,
    Button,
} from '@orthly/ui-primitives';
import type { ChangeEvent } from 'react';
import React from 'react';
import type { Accept, DropzoneOptions } from 'react-dropzone';

export interface FileUploaderBulkProps {
    title?: string;
    storagePathConfig: BucketStoragePathConfig;
    disabled?: boolean;
    onSetCanUpload?: (canUpload: boolean) => void;
    dropzoneOptions?: Omit<DropzoneOptions, 'onDropAccepted'>;
    preUploadText?: string;
    submitButtonText?: string;
    elevation?: number;
    onLoadingStateChange?: (loading: boolean) => void;
    onComplete?: (result: FileUploaderFieldResult[], files: File[], notes?: string) => void;
    onReset?: () => void;
    // If true, upload will start as soon as files are selected
    autoSubmit?: boolean;
    dropzoneWrapperStyle?: React.CSSProperties;
    paperStyle?: React.CSSProperties;
    dropzoneContent?: React.ReactNode;
    // used for displaying existing files - these WILL NOT be uploaded again
    initialFiles?: File[];
    overrideFileName?: string;
    prependTimestampToFilename?: boolean;
    deleteOnReset?: boolean;
    processImages?: boolean;
    replaceExisting?: boolean;
    onGetInputFiles?: (files: File[]) => void;
    accept?: Accept;
}
type ImageUploaderProps = FileUploaderBulkProps & {
    style?: React.CSSProperties;
    examplePhoto?: string;
    ImageTag?: React.ReactNode;
    exampleStyle?: React.CSSProperties;
    initialSrc?: string;
    showExampleDisabled: boolean;
    onSkipImage?: () => void;
};

export function useImageUploaderData(props: ImageUploaderProps) {
    const { initialSrc } = props;
    const { inputFiles, loading, onDropAccepted, onReset, onSubmit } = useFileUploaderVars(props);
    const imgSource = React.useMemo(() => {
        const uploadedImage = inputFiles.find(file => file.type.includes('image'));
        if (!uploadedImage) {
            return initialSrc;
        }
        const urlCreator = window.URL || window.webkitURL;
        return urlCreator.createObjectURL(uploadedImage);
    }, [inputFiles, initialSrc]);

    const handleChange = React.useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const file = event.target.files?.[0];
            if (!file) {
                return;
            }
            if (inputFiles.length > 0) {
                void onReset().then(() => onDropAccepted([file]));
            }
            onDropAccepted([file]);
        },
        [onDropAccepted, onReset, inputFiles.length],
    );
    return { imgSource, handleChange, loading, onSubmit, onReset };
}

export const ImageUploader: React.FC<ImageUploaderProps> = props => {
    const { loading, imgSource, handleChange } = useImageUploaderData(props);
    const { examplePhoto, ImageTag, exampleStyle, showExampleDisabled, onSkipImage } = props;
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [showExample, setShowExample] = React.useState(false);

    const imageStyle: React.CSSProperties = { width: '100%', height: '100%', objectFit: `cover` };
    /* eslint-disable no-nested-ternary */
    return (
        <>
            <LoadBlocker blocking={loading} ContainerProps={{ style: exampleStyle }}>
                {imgSource && !showExample ? (
                    <img src={imgSource} style={imageStyle} alt={`Uploaded`} />
                ) : examplePhoto ? (
                    <>
                        {ImageTag}
                        <img src={examplePhoto} style={imageStyle} alt={`Example`} />
                    </>
                ) : null}
            </LoadBlocker>
            <input onChange={handleChange} multiple={false} ref={fileInputRef ?? null} type={'file'} hidden />
            <Button
                variant={'primary'}
                onClick={() => !!fileInputRef?.current && fileInputRef.current.click()}
                endIcon={'CameraIcon'}
                style={props.style}
                disabled={loading}
            >
                {!!imgSource ? 'Re-take Photo' : 'Take Photo'}
            </Button>
            {onSkipImage && (
                <Button
                    variant={'secondary'}
                    onClick={() => onSkipImage()}
                    style={{ ...props.style, marginTop: 12, backgroundColor: FlossPalette.WHITE }}
                    disabled={loading}
                >
                    Skip optional photo
                </Button>
            )}
            {imgSource && (
                <Button
                    variant={'secondary'}
                    onTouchStart={() => setShowExample(true)}
                    onTouchEnd={() => setShowExample(false)}
                    endIcon={'VisibilityIcon'}
                    style={{ ...props.style, marginTop: 12, backgroundColor: FlossPalette.WHITE }}
                    disabled={loading || showExampleDisabled}
                >
                    Press and hold for example
                </Button>
            )}
        </>
    );
};

const useImageUploaderWithFloatingResetButtonStyles = stylesFactory(() => ({
    label: { width: '100%' },
}));
export const ImageUploaderWithFloatingResetButton: React.FC<ImageUploaderProps> = props => {
    const classes = useImageUploaderWithFloatingResetButtonStyles();
    const { loading, imgSource, handleChange, onSubmit, onReset } = useImageUploaderData(props);
    const { examplePhoto, ImageTag, exampleStyle, showExampleDisabled } = props;
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [showExample, setShowExample] = React.useState(false);

    return (
        <>
            <LoadBlocker blocking={loading}>
                {showExample && examplePhoto && (
                    <>
                        {ImageTag}
                        <img src={examplePhoto} style={exampleStyle} alt={`Example`} />
                    </>
                )}
                {!showExample && imgSource && (
                    <>
                        <img src={imgSource} style={exampleStyle} alt={`Uploaded`} />
                        <Grid container justifyContent={'center'} style={{ position: 'relative' }}>
                            <IconButton onClick={onReset} style={{ padding: 0 }}>
                                <SmallTrashCanIcon
                                    preserveAspectRatio={'xMidYMin'}
                                    color={'primary'}
                                    style={{
                                        color: FlossPalette.STAR_GRASS,
                                        background: FlossPalette.WHITE,
                                        borderRadius: 16,
                                        padding: 4,
                                        height: 32,
                                        width: 32,
                                        position: 'absolute',
                                        bottom: 8,
                                        boxShadow: `0 0 0 3px ${FlossPaletteUtils.toRgba('BLACK', 0.16)}`,
                                    }}
                                />
                            </IconButton>
                        </Grid>
                    </>
                )}
                <input onChange={handleChange} multiple={false} ref={fileInputRef} type={'file'} hidden />
                {examplePhoto && (
                    <Button
                        variant={'secondary'}
                        onTouchStart={() => setShowExample(true)}
                        onTouchEnd={() => setShowExample(false)}
                        endIcon={'VisibilityIcon'}
                        style={{ ...props.style }}
                        disabled={loading || showExampleDisabled}
                    >
                        Press and hold for example
                    </Button>
                )}
                {!imgSource && (
                    <Button
                        variant={'primary'}
                        onClick={() => fileInputRef.current?.click()}
                        style={props.style}
                        disabled={loading}
                    >
                        Take a photo
                    </Button>
                )}
                {imgSource && !props.autoSubmit && (
                    <Button
                        variant={'primary'}
                        onClick={() => onSubmit()}
                        style={props.style}
                        classes={{
                            root: classes.label,
                        }}
                    >
                        <Grid container justifyContent={'space-between'} alignItems={'center'}>
                            Submit photos
                            <ChevronRight />
                        </Grid>
                    </Button>
                )}
            </LoadBlocker>
        </>
    );
};

export const useImageUploaderWithNotesStyles = stylesFactory(() => ({
    issueDetailInput: { backgroundColor: FlossPalette.WHITE, marginTop: 8 },
    uploadedImg: { height: 80, borderRadius: 4, width: 80 },
    trashIcon: {
        color: FlossPalette.STAR_GRASS,
        background: FlossPalette.WHITE,
        borderRadius: 16,
        padding: 4,
        height: 32,
        width: 32,
        boxShadow: `0 0 0 3px ${FlossPaletteUtils.toRgba('BLACK', 0.16)}`,
    },
    iconButton: { padding: 0, position: 'absolute', bottom: 8, left: 24 },
}));

export const FileUploaderBulk: React.FC<FileUploaderBulkProps> = props => {
    const { dropzoneOptions, disabled, autoSubmit, onGetInputFiles } = props;
    const { accept, inputFiles, loading, onDropAccepted, onReset, onSubmit, uploadCompleted, setInputFiles } =
        useFileUploaderVars(props);
    const submitDisabled = inputFiles.length === 0 || loading;
    onGetInputFiles && onGetInputFiles(inputFiles);

    return (
        <FileUploaderLayout
            loading={loading}
            title={props.title}
            elevation={props.elevation}
            paperStyle={props.paperStyle}
        >
            <Collapse in={!uploadCompleted} style={{ width: '100%' }}>
                <Grid container>
                    <SimpleDropzone
                        wrapperStyle={props.dropzoneWrapperStyle}
                        options={{ ...dropzoneOptions, onDropAccepted, accept }}
                        dropzoneContent={props.dropzoneContent}
                    />
                </Grid>
            </Collapse>
            <FirebaseUploadFileList
                completed={uploadCompleted}
                disableImgPreview={false}
                files={inputFiles}
                onRemove={name => {
                    setInputFiles(files => files.filter(f => f.name !== name));
                }}
            />
            <Collapse in={!uploadCompleted && !autoSubmit} style={{ width: '100%' }}>
                <Grid container>
                    <FileUploaderSubmitButton
                        disabled={submitDisabled || disabled}
                        onSubmit={!submitDisabled ? onSubmit : undefined}
                        submitButtonText={props.submitButtonText || 'Upload'}
                    />
                </Grid>
            </Collapse>
            <Collapse in={uploadCompleted} style={{ width: '100%' }}>
                <Grid container justifyContent={'flex-end'}>
                    <Button variant={'secondary'} onClick={onReset}>
                        Reset
                    </Button>
                </Grid>
            </Collapse>
        </FileUploaderLayout>
    );
};
