import * as THREE from 'three';

export class MarginMeshTubeMaterial extends THREE.MeshLambertMaterial {
    constructor(
        color?: string | number | THREE.Color | undefined,
        colors?: (THREE.Color | [number, number, number])[] | undefined,
    ) {
        super({
            color: color ?? 0x000000,
            side: THREE.DoubleSide,
            flatShading: true,
            emissive: new THREE.Color(0, 0, 0),
            vertexColors: colors ? true : undefined,
        });
    }
    // customize MeshLambertMaterial to apply a small z-offset to make the margin lines stand out more
    onBeforeCompile(shader: THREE.Shader, renderer: THREE.WebGLRenderer): void {
        super.onBeforeCompile(shader, renderer);
        shader.vertexShader = shader.vertexShader.replace(
            '#include <project_vertex>',
            `#include <project_vertex>
             gl_Position.z -= 0.00008;`,
        );
    }
}
