import { OrderDetailBody } from './OrderDetailBody';
import { OrderDetailChat } from './OrderDetailChat';
import { OrderDetailTopBarV2 } from './components/OrderDetailTopBarV2';
import type {
    LabsGqlChatMessageDtoFragment,
    LabsGqlOrder,
    LabsGqlSingleLabOrderFragment,
} from '@orthly/graphql-operations';
import type { ListChatMessagesQueryHookResult, SingleLabOrderQueryHookResult } from '@orthly/graphql-react';
import { TabWithBadge } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import type { FirebasePreviewFileMulti } from '@orthly/veneer';
import { useFirebasePreviewMulti } from '@orthly/veneer';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    mobileRoot: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        height: '100%',
    },
    mobileRootSticky: {
        height: 'auto',
    },
    tabsRoot: {
        width: 'auto',
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        backgroundColor: FlossPalette.TAN,
        zIndex: 2,
    },
    tabsRootSticky: {
        position: 'sticky',
    },
    main: { overflow: 'auto' },
}));

function useOrderImageSources(chatMessages: LabsGqlChatMessageDtoFragment[], order?: LabsGqlOrder) {
    const shadeMatchSources = (order?.scan_export.image_urls ?? [])
        .filter(s => s.includes('measure'))
        .map<FirebasePreviewFileMulti>((source, idx) => ({ source, name: `Shade match #${idx + 1}` }));
    const noteSources = React.useMemo<FirebasePreviewFileMulti[]>(() => {
        if (!order) {
            return [];
        }
        return chatMessages.flatMap(message => {
            return message.attachments
                .map(({ url }) => url)
                .filter(a => a.includes('png') || a.includes('jpg') || a.includes('jpeg'))
                .map(source => ({ source, name: source }));
        });
    }, [order, chatMessages]);
    return [...shadeMatchSources, ...noteSources];
}

const MobileImagesSection: React.FC<{ sources: FirebasePreviewFileMulti[] }> = props => {
    const { sources } = props;
    const { result: firebasePreviewSrcs } = useFirebasePreviewMulti(sources);
    if (!firebasePreviewSrcs) {
        return null;
    }
    return (
        <Grid container wrap={'wrap'} style={{ padding: '12px 8px', height: '100%', overflow: 'scroll' }}>
            {firebasePreviewSrcs.map(src => (
                <Grid container key={src.name} style={{ padding: 12 }} direction={'column'}>
                    <Grid
                        container
                        direction={'column'}
                        style={{ overflow: 'hidden', borderRadius: 16, background: FlossPalette.TAN }}
                    >
                        <img
                            src={src.source}
                            alt={src.name}
                            style={{ objectFit: 'contain', display: 'block', maxHeight: '100%', maxWidth: '100%' }}
                        />
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
};

type MobileRootView = 'order' | 'timeline' | 'images' | 'qc';

interface OrderDetailMobileBottomNavProps {
    imageCount: number;
    view: MobileRootView;
    setView: React.Dispatch<React.SetStateAction<MobileRootView>>;
}

const OrderDetailMobileTopNav: React.FC<OrderDetailMobileBottomNavProps> = props => {
    const classes = useStyles();
    const { imageCount, view, setView } = props;

    return (
        <Grid item className={clsx(classes.tabsRoot, classes.tabsRootSticky)} style={{ top: 0 }}>
            <Tabs
                indicatorColor={'secondary'}
                textColor={'inherit'}
                variant={'fullWidth'}
                value={view}
                onChange={(_, val) => setView(val)}
            >
                <Tab value={'order'} label={'Order'} />
                {imageCount > 0 && <Tab value={'images'} label={'Images'} />}
                <Tab value={'timeline'} label={<TabWithBadge label={'Timeline'} />} />
            </Tabs>
        </Grid>
    );
};

interface OrderDetailMobileRootProps {
    chatMessages: LabsGqlChatMessageDtoFragment[];
    order?: LabsGqlSingleLabOrderFragment;
    refetchChatMessages: ListChatMessagesQueryHookResult['refetch'];
    refetchOrder: SingleLabOrderQueryHookResult['refetch'];
    loading: boolean;
}

export const OrderDetailMobileRoot: React.FC<OrderDetailMobileRootProps> = props => {
    const { chatMessages, order, refetchChatMessages, refetchOrder, loading } = props;
    const classes = useStyles();
    const imageSources = useOrderImageSources(chatMessages, order);
    const [view, setView] = React.useState<MobileRootView>('order');

    return (
        <Grid
            container
            className={clsx(classes.mobileRoot, classes.mobileRootSticky)}
            style={{ background: view === 'timeline' ? FlossPalette.TAN : undefined }}
        >
            {order && <OrderDetailTopBarV2 order={order} />}
            <OrderDetailMobileTopNav view={view} setView={setView} imageCount={imageSources.length} />
            <Grid container className={classes.main}>
                {/* eslint-disable no-nested-ternary */}
                {view === 'timeline' ? (
                    <OrderDetailChat order={order} refetchMessages={refetchChatMessages} mobileView />
                ) : view === 'images' ? (
                    <MobileImagesSection sources={imageSources} />
                ) : (
                    <OrderDetailBody order={order} loading={loading} refetch={refetchOrder} />
                )}
                {/* eslint-enable no-nested-ternary */}
            </Grid>
        </Grid>
    );
};
