import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';

const ORDER_SUMMARY_ROW_DESKTOP_HEIGHT = 108;
const ORDER_SUMMARY_ROW_TABLET_HEIGHT = 200;

export const getOrderListItemHeight = (isTablet: boolean) => {
    if (isTablet) {
        return ORDER_SUMMARY_ROW_TABLET_HEIGHT;
    }
    return ORDER_SUMMARY_ROW_DESKTOP_HEIGHT;
};

export function getOrderSummaryRowIssue(
    order: Pick<
        LabsGqlOrderPreviewFragment,
        'delivery_date' | 'fulfillment_workflow' | 'hold_in_review' | 'ship_status' | 'status'
    >,
): string | undefined {
    const skipStatuses = [LabsGqlLabOrderStatus.NeedsRefabrication, LabsGqlLabOrderStatus.Cancelled];
    if (order.delivery_date || skipStatuses.includes(order.status)) {
        return undefined;
    }
    if (
        order.fulfillment_workflow.active_task?.type === 'WaxupReview' ||
        order.fulfillment_workflow.active_task?.type === 'ApproveAlignerFabrication'
    ) {
        return 'Needs approval';
    }
    if (order.status === LabsGqlLabOrderStatus.OnHold && !order.hold_in_review) {
        return 'On Hold';
    }
    return undefined;
}
