import type { ModelViewerOxzMarginInfo, ToothMarkingWithParent } from './NewModelViewer.types';
import type { InternalDesignMetadata } from '@orthly/shared-types';
import _ from 'lodash';
import React from 'react';

/**
 * Idempotently gets the tooth markings from the highest priority data source available
 * @param precomputedToothMarkings Tooth markings that were already computed. These are most preferred.
 * @param designMetadata Design metadata. These are second most preferred.
 * @param oxzMarginInfo OZX margin information. These are third most preferred.
 * @returns Tooth markings
 */
export function useGetToothMarkings(
    precomputedToothMarkings: ToothMarkingWithParent[] | undefined,
    designMetadata: InternalDesignMetadata | undefined,
    oxzMarginInfo: ModelViewerOxzMarginInfo | undefined,
): ToothMarkingWithParent[] {
    return React.useMemo<ToothMarkingWithParent[]>(() => {
        if (precomputedToothMarkings) {
            return precomputedToothMarkings;
        }

        // this is the Design viewer situation, we want to use these if they exist
        if (designMetadata?.scans) {
            const data = Object.values(designMetadata.scans).flatMap<ToothMarkingWithParent | undefined>(scan =>
                (scan.teeth ?? []).map(tooth => {
                    if (
                        tooth.annotation_type !== 'Preparation' ||
                        !scan.dcm_file.includes('MB') ||
                        tooth.origin.length !== 3
                    ) {
                        return undefined;
                    }

                    return {
                        unn: tooth.tooth,
                        scanPath: scan.dcm_file,
                        origin: tooth.origin as [number, number, number],
                    };
                }),
            );
            return _.compact(data);
        }

        // we fall back to the scan export prepMarks
        if (oxzMarginInfo?.prepMarks) {
            return _.compact(
                oxzMarginInfo.prepMarks.map(pM => {
                    // avoid double tooth marks on Pre-Preps
                    if (pM.dcmName.toLowerCase().includes('prepreparation')) {
                        return undefined;
                    }
                    return {
                        unn: pM.unn,
                        scanPath: pM.dcmName,
                        origin: [pM.origin.x, pM.origin.y, pM.origin.z],
                    };
                }),
            );
        }

        return [];
    }, [precomputedToothMarkings, designMetadata, oxzMarginInfo]);
}
