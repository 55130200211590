import { logger } from '../../Utils/Logger';
import {
    forEachDirectChild,
    getNodeAttribute,
    getNodeWithName,
    maybeGetNodeWithTag,
    maybeGetNumberValue,
    parseVector3,
} from '../DentalDesignerModellingTree/ModellingTree.util';
import _ from 'lodash';
import * as THREE from 'three';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';

// Relevant fields from the "TSysObjectDrillCompensationInfo" DCM node
interface DrillCompensationInfo {
    direction: THREE.Vector3;
    toothIndex?: number;
    toolRadius?: number;
    cornerToolRadius?: number;
}

// Relevant fields from the "TSysObjectDentalInformation" DCM node
interface DentalInformation {
    mountDir: THREE.Vector3;
    topCapDir: THREE.Vector3;
    toothElementType: string;
    primaryKey: string;
}

/**
 * Gets the insertion axis from a DCM file
 * The insertion axis is drawn from the DentalInformation object if present, falling back on the DrillCompensationInfo.
 * @param dcmXml XML document from a DCM file
 * @returns The insertion axis, if it could be parsed
 */
export function getInsertionAxis(dcmXml: XMLBuilder): THREE.Vector3 | undefined {
    const objectsNode = maybeGetNodeWithTag(dcmXml, 'Objects');
    if (!objectsNode) {
        return undefined;
    }

    const dentalInformations: DentalInformation[] = [];
    forEachDirectChild(objectsNode, (objectNode: XMLBuilder) => {
        if (getNodeAttribute(objectNode, 'type') === 'TSysObjectDentalInformation') {
            appendParsedDentalInformation(objectNode, dentalInformations);
        }
    });

    const insertionAxis = getInsertionAxisFromDentalInformations(dentalInformations);
    if (insertionAxis) {
        return insertionAxis;
    }

    const drillCompensationInfos: DrillCompensationInfo[] = [];
    forEachDirectChild(objectsNode, (objectNode: XMLBuilder) => {
        if (getNodeAttribute(objectNode, 'type') === 'TSysObjectDrillCompensationInfo') {
            appendParsedDrillCompensationInfo(objectNode, drillCompensationInfos);
        }
    });

    return getInsertionAxisFromDrillCompensationInfos(drillCompensationInfos);
}

function appendParsedDentalInformation(node: XMLBuilder, infos: DentalInformation[]): void {
    try {
        // We do not consider a DentalInformation object unless we are able to parse all of the relevant fields.
        infos.push({
            mountDir: parseVector3(getNodeWithName(node, 'vnMountDir')),
            topCapDir: parseVector3(getNodeWithName(node, 'vnTopCapDir')),
            toothElementType: getNodeAttribute(getNodeWithName(node, 'ToothElementType'), 'value'),
            primaryKey: getNodeAttribute(getNodeWithName(node, 'OrderXMLItem_PrimaryKey'), 'value'),
        });
    } catch (e) {
        logger.warn(e.message);
    }
}

// Ranking of tooth element types, in order of decreasing precedence when determining the insertion axis.
const TOOTH_ELEMENT_TYPE_RANKING: Record<string, number> = {
    teCrown: 0,
    teCoping: 1,
    teInlay: 2,
    tePostAndCoreStandard: 3,
    teCrownPontic: 4,
    teAbutmentScrewRetainedCrown: 5,
    teAbutment: 6,
    teTransferGuide: 7,
};

// Gets the insertion axis from the most preferable DentalInformation object, if any
function getInsertionAxisFromDentalInformations(infos: DentalInformation[]): THREE.Vector3 | undefined {
    if (infos.length === 0) {
        return undefined;
    }

    let bestInfo: DentalInformation | undefined;
    let bestInfoRank: number = Infinity;

    for (const info of infos) {
        const rank = TOOTH_ELEMENT_TYPE_RANKING[info.toothElementType];
        if (rank === undefined) {
            continue;
        }

        if (
            rank < bestInfoRank ||
            (rank <= bestInfoRank &&
                isValidDirection(info.mountDir) &&
                !isValidDirection((bestInfo as DentalInformation).mountDir))
        ) {
            bestInfo = info;
            bestInfoRank = rank;
        }
    }

    if (!bestInfo) {
        return undefined;
    }

    if (isValidDirection(bestInfo.mountDir)) {
        return bestInfo.mountDir;
    }

    if (isValidDirection(bestInfo.topCapDir)) {
        return bestInfo.topCapDir;
    }

    return undefined;
}

const ZERO_VECTOR = new THREE.Vector3(0, 0, 0);
const THREESHAPE_DEFAULT_VECTOR = new THREE.Vector3(0, 1, 0);

function isValidDirection(dir: THREE.Vector3): boolean {
    return !(dir.equals(ZERO_VECTOR) || dir.equals(THREESHAPE_DEFAULT_VECTOR));
}

function appendParsedDrillCompensationInfo(node: XMLBuilder, infos: DrillCompensationInfo[]): void {
    try {
        // We will consider a DrillCompensationInfo object as long as it has a direction.
        infos.push({
            direction: parseVector3(getNodeWithName(node, 'Direction')),
            toolRadius: maybeGetNumberValue(node, 'ToolRadius'),
            cornerToolRadius: maybeGetNumberValue(node, 'CornerToolRadius'),
            toothIndex: maybeGetNumberValue(node, 'ToothIndex'),
        });
    } catch (e) {
        logger.warn(e.message);
    }
}

// Gets the insertion axis from the most preferable DrillCompensationInfo object, if any
function getInsertionAxisFromDrillCompensationInfos(infos: DrillCompensationInfo[]): THREE.Vector3 | undefined {
    if (infos.length === 0) {
        return undefined;
    }

    let bestInfo = infos[0] as DrillCompensationInfo;

    for (const info of infos.slice(1)) {
        const infoData = [info.toolRadius, info.cornerToolRadius, info.toothIndex];
        const bestInfoData = [bestInfo.toolRadius, bestInfo.cornerToolRadius, bestInfo.toothIndex];

        if (compareMaybeNumberArrays(bestInfoData, infoData) > 0) {
            bestInfo = info;
        }
    }

    return bestInfo.direction;
}

function compareMaybeNumbers(lhs: number | undefined, rhs: number | undefined): number {
    if (lhs === undefined && rhs === undefined) {
        return 0;
    }

    if (lhs === undefined) {
        return 1;
    }

    if (rhs === undefined) {
        return -1;
    }

    return rhs - lhs;
}

function compareMaybeNumberArrays(lhs: (number | undefined)[], rhs: (number | undefined)[]): number {
    for (const [lhsVal, rhsVal] of _.zip(lhs, rhs)) {
        const cmp = compareMaybeNumbers(lhsVal, rhsVal);
        if (cmp !== 0) {
            return cmp;
        }
    }

    return 0;
}
