import { useOpenOrderDetailPage } from '../LabsUtils';
import { CancelOrderReturnAction } from '../actions/practitioners/CancelOrderReturn';
import { PrintOrderReturnLabel } from './PrintOrderReturnLabel';
import { OrderSummaryRowBase, OrderSummaryRowItemIcons } from '@orthly/dentin';
import { useOrderSummaryRowButtonStyles } from '@orthly/dentin';
import type { LabsGqlOrderReturnFragment } from '@orthly/graphql-operations';
import type { OrderReturn } from '@orthly/graphql-react';
import { LabsGqlOrderReturnStatus } from '@orthly/graphql-schema';
import { OrderItemV2Utils } from '@orthly/items';
import { ShippingUtils } from '@orthly/shared-types';
import { useScreenIsMobileOrVerticalTablet, Grid, Button, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';

export const OrderReturnActionsCell: React.FC<{ row: LabsGqlOrderReturnFragment }> = props => {
    const { row } = props;
    const { status } = row;
    const buttonClasses = useOrderSummaryRowButtonStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    if (status === LabsGqlOrderReturnStatus.Cancelled || status === LabsGqlOrderReturnStatus.Received) {
        return null;
    }

    return (
        <Grid
            container
            style={{ width: 'auto' }}
            wrap={isMobile ? 'wrap' : 'nowrap'}
            onClick={e => e.stopPropagation()}
        >
            {status === LabsGqlOrderReturnStatus.Transit && !isMobile ? (
                <Button
                    variant={'ghost'}
                    endIcon={'TrackIcon'}
                    href={ShippingUtils.getTrackingLinkForCarrier(row.shipment.carrier, row.shipment.tracking_number)}
                    className={buttonClasses.button}
                    analytics={{
                        AssetName: 'Track Return',
                        AssetLocation: 'Practice - Order List',
                    }}
                >
                    Track Return
                </Button>
            ) : (
                <div style={{ display: 'flex', gap: 16 }}>
                    <CancelOrderReturnAction orderReturn={props.row} />
                    <PrintOrderReturnLabel
                        slim
                        labelUrl={props.row.label_url}
                        status={props.row.status}
                        title={'Print label'}
                    />
                </div>
            )}
        </Grid>
    );
};

interface ReturnSummaryRowProps {
    orderReturn: OrderReturn;
}

export const ReturnSummaryRow: React.FC<ReturnSummaryRowProps> = props => {
    const { orderReturn } = props;
    const isMobile = useScreenIsMobile();
    const isUnshipped = orderReturn.status === LabsGqlOrderReturnStatus.NotShipped;
    const openOrder = useOpenOrderDetailPage();
    return (
        <OrderSummaryRowBase
            forceAlert
            key={orderReturn.id}
            openOrder={openOrder}
            subtitle={'Pending Return'}
            patientName={`${orderReturn.order.patient.first_name} ${orderReturn.order.patient.last_name}`}
            iconsSection={<OrderSummaryRowItemIcons items={OrderItemV2Utils.parseItems(orderReturn.order.items_v2)} />}
            warningMessage={isUnshipped ? 'Needs Shipment' : undefined}
            stepperSection={
                <Grid
                    container
                    justifyContent={'flex-end'}
                    alignItems={isMobile ? 'flex-end' : 'center'}
                    style={{ height: '100%' }}
                >
                    <OrderReturnActionsCell row={orderReturn} />
                </Grid>
            }
            onClick={() => {
                openOrder(orderReturn.order.id);
            }}
        />
    );
};
