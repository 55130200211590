import { OrderSummaryRowBase } from './OrderSummaryRowBase';
import { OrderSummaryRowItemIcons } from './OrderSummaryRowItemIcons/OrderSummaryRowItemIcons';
import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { OrderFormatUtils, HoldUtils } from '@orthly/shared-types';
import React from 'react';

interface OrderSummaryRowProps {
    order: LabsGqlOrderPreviewFragment;
    onClick: () => void;
    openOrder: (orderId: string) => void;
    stepperSection?: React.ReactNode;
    warningMessage?: string;
    isInbox?: boolean;
    isLast?: boolean;
    // When true, the row will be rendered as if it were on mobile
    forceMobileDisplay?: boolean;
    Buttons?: React.ReactNode;
}

export const OrderSummaryRow: React.VFC<OrderSummaryRowProps> = ({
    order,
    onClick,
    openOrder,
    stepperSection,
    warningMessage,
    isInbox,
    isLast,
    forceMobileDisplay,
    Buttons,
}) => {
    const items = OrderItemV2Utils.parseItems(order.items_v2);
    return (
        <OrderSummaryRowBase
            patientName={Format.possessiveNoun(`${order.patient.first_name} ${order.patient.last_name}`)}
            patientSKUs={CartItemV2Utils.getItemsDisplayNames(items)}
            subtitle={OrderFormatUtils.getDisplayedStaffMemberName(order.doctor_name)}
            onClick={onClick}
            openOrder={openOrder}
            iconsSection={<OrderSummaryRowItemIcons items={items} forceMobileDisplay={forceMobileDisplay} />}
            order={order}
            stepperSection={stepperSection}
            warningMessage={warningMessage}
            warningTooltipContent={
                // eslint-disable-next-line no-nested-ternary
                order.hold_details && order.status === LabsGqlLabOrderStatus.OnHold
                    ? HoldUtils.composeHoldReason(order.hold_details)
                    : undefined
            }
            isLast={isLast}
            isInbox={isInbox}
            forceMobileDisplay={forceMobileDisplay}
            Buttons={Buttons}
        />
    );
};
