import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderWaxupReviewStatus, LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';

export enum OrderSummaryButton {
    ModifyRx = 'modify rx',
    ReviewDesign = 'review design',
    ResolveHold = 'resolve hold',
    RefabricateOrder = 'refabricate order',
    ProvideFeedback = 'provide feedback',
}

export const getOrderButtonsToRender = (
    order: Pick<
        LabsGqlOrderPreviewFragment,
        | 'id'
        | 'waxup_status'
        | 'can_edit'
        | 'workflow_state'
        | 'review_submission'
        | 'fulfillment_workflow'
        | 'can_refabricate'
        | 'can_submit_feedback'
    >,
    shouldShowResolveButton: boolean,
): OrderSummaryButton[] => {
    if (order.workflow_state.state === LabsGqlWorkflowStateEnum.Delivered) {
        const buttonsToRender = [];
        if (order.can_refabricate) {
            buttonsToRender.push(OrderSummaryButton.RefabricateOrder);
        }
        if (order.can_submit_feedback) {
            buttonsToRender.push(OrderSummaryButton.ProvideFeedback);
        }
        return buttonsToRender;
    }

    if (shouldShowResolveButton) {
        return [OrderSummaryButton.ResolveHold];
    }

    if (order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview) {
        return [OrderSummaryButton.ReviewDesign];
    }

    if (order.can_edit) {
        return [OrderSummaryButton.ModifyRx];
    }

    return [];
};
