import type { UploadedFile } from '../FirebaseUpload/FileUploader.utils';
import { FileUploadStatus } from '../FirebaseUpload/FileUploader.utils';
import { FileListPreviewImage } from './FileListPreviewImage';
import { LoadBlocker, TrashIcon, WarningIcon } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    stylesFactory,
    Text,
    Dialog,
    DialogContent,
    Grid,
    IconButton,
    Icon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    filePreview: {
        padding: 8,
        flexWrap: 'nowrap',
    },
    fileName: {
        paddingLeft: 16,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));

export interface FileListPreviewProps {
    fileUpload: UploadedFile;
    onRemove?: (name: string) => void;
    maxThumbnailSize: number;
    showFileName?: boolean;
}

export const FileListPreview: React.VFC<FileListPreviewProps> = ({
    fileUpload,
    onRemove,
    maxThumbnailSize,
    showFileName,
}) => {
    const classes = useStyles();
    const [photoPreviewOpen, setPhotoPreviewOpen] = React.useState(false);
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [isDeleting, setIsDeleting] = React.useState(false);
    const { file } = fileUpload;
    const isUploading = fileUpload.status === FileUploadStatus.uploading;
    const uploadFailed = fileUpload.status === FileUploadStatus.errored;
    const keepFile = () => {
        setConfirmOpen(false);
        setPhotoPreviewOpen(false);
    };
    const deleteFile = () => {
        setIsDeleting(true);
        onRemove?.(fileUpload.uploadName);
    };

    return (
        <>
            <Dialog
                open={!!photoPreviewOpen}
                style={{ borderRadius: 8 }}
                PaperProps={{
                    style: { borderRadius: 8 },
                }}
            >
                <DialogContent style={{ padding: 0, display: 'flex', flexDirection: 'column' }}>
                    <IconButton
                        onClick={() => setPhotoPreviewOpen(false)}
                        style={{ position: 'absolute', top: 4, right: 4 }}
                    >
                        <Icon
                            icon={'CloseIcon'}
                            style={{ color: FlossPalette.BLACK, outline: FlossPalette.WHITE }}
                            stroke={'white'}
                            stroke-width={1}
                        />
                    </IconButton>
                    <FileListPreviewImage file={fileUpload} variant={'preview'} />
                    <Grid container style={{ padding: 10 }}>
                        <Grid item style={{ flexGrow: 1 }}>
                            <Text variant={'body2'} bold>
                                {file.name}
                            </Text>
                        </Grid>
                        {onRemove && (
                            <>
                                <Dialog
                                    open={confirmOpen}
                                    maxWidth={'md'}
                                    onClose={() => setConfirmOpen(false)}
                                    style={{ borderRadius: 8 }}
                                    PaperProps={{
                                        style: {
                                            paddingBottom: 8,
                                            borderRadius: 8,
                                            border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
                                        },
                                    }}
                                >
                                    <DialogContent>
                                        <Grid
                                            container
                                            direction={'column'}
                                            spacing={1}
                                            style={{
                                                height: '100%',
                                                maxHeight: '100%',
                                                width: '100%',
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                gap: 20,
                                            }}
                                        >
                                            <Grid item container direction={'row'} wrap={'nowrap'} style={{ gap: 20 }}>
                                                <Text variant={'h4'} noWrap>
                                                    Delete {file.name}?
                                                </Text>
                                                <FileListPreviewImage file={fileUpload} variant={'confirmDeleteView'} />
                                            </Grid>
                                            <LoadBlocker blocking={isDeleting}>
                                                <Text variant={'body2'}>You can upload this file again if needed.</Text>
                                                <Grid item container direction={'row'} spacing={1}>
                                                    <Grid item xs />
                                                    <Grid item>
                                                        <Button variant={'secondary'} onClick={keepFile} fullWidth>
                                                            No, keep it
                                                        </Button>
                                                    </Grid>
                                                    <Grid item>
                                                        <Button
                                                            fullWidth
                                                            variant={'alert-secondary'}
                                                            onClick={deleteFile}
                                                        >
                                                            Yes, delete
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </LoadBlocker>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                                <Grid item>
                                    <TrashIcon
                                        style={{ cursor: 'pointer', color: FlossPalette.ATTENTION_FOREGROUND }}
                                        onClick={() => setConfirmOpen(true)}
                                    />
                                </Grid>
                                <Grid item>
                                    <Text
                                        style={{ cursor: 'pointer' }}
                                        variant={'body2'}
                                        bold
                                        color={'ATTENTION_FOREGROUND'}
                                        onClick={() => setConfirmOpen(true)}
                                    >
                                        Delete
                                    </Text>
                                </Grid>
                            </>
                        )}
                    </Grid>
                </DialogContent>
            </Dialog>
            {isUploading && (
                <Grid container alignItems={'center'}>
                    <Icon icon={'InsertPhotoIcon'} style={{ color: FlossPalette.LIGHT_GRAY }} />
                    <Text
                        style={{ overflow: 'hidden', textOverflow: 'ellipsis', wordBreak: 'break-all' }}
                        variant={'body2'}
                    >
                        Uploading {file.name}...
                    </Text>
                </Grid>
            )}
            {uploadFailed && (
                <Grid container alignItems={'center'}>
                    <WarningIcon style={{ color: FlossPalette.ATTENTION }} />
                    <Grid item style={{ width: 'calc(100% - 24px)', paddingLeft: 16 }}>
                        <Text variant={'body2'} medium className={classes.fileName} style={{ paddingLeft: 0 }}>
                            {file.name}
                        </Text>
                        <Text variant={'caption'}>
                            Something went wrong uploading this photo. Try again or create a new photo.
                        </Text>
                    </Grid>
                </Grid>
            )}
            {!isUploading && !uploadFailed && (
                <Grid
                    container={!!showFileName}
                    item={!showFileName}
                    className={showFileName ? classes.filePreview : undefined}
                >
                    <FileListPreviewImage
                        file={fileUpload}
                        variant={'listView'}
                        maxThumbnailSize={maxThumbnailSize}
                        onClick={() => setPhotoPreviewOpen(true)}
                    />
                    {showFileName && (
                        <Text variant={'body2'} medium className={classes.fileName}>
                            {file.name}
                        </Text>
                    )}
                </Grid>
            )}
        </>
    );
};

interface FileListProps {
    files: UploadedFile[];
    onRemove: (name: string) => void;
    maxHeight?: number;
    maxThumbnailSize?: number;
    showFileName?: boolean;
}

export const FileList: React.VFC<FileListProps> = ({
    files,
    onRemove,
    maxHeight,
    maxThumbnailSize = 96,
    showFileName,
}) => {
    return (
        <Grid container style={{ gap: 8, maxHeight: maxHeight || 'auto', overflow: 'auto' }} data-test={'file-list'}>
            {files.map(file => (
                <FileListPreview
                    key={file.uploadName}
                    fileUpload={file}
                    onRemove={onRemove}
                    maxThumbnailSize={maxThumbnailSize}
                    showFileName={showFileName}
                />
            ))}
        </Grid>
    );
};
