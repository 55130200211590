import type { OrderDetailTrackerProps, OrderForOrderTracker } from '../../OrderDetailTracker/OrderDetailTracker.types';
import type { AlignerTrackerProps } from '@orthly/dentin';
import { OrderListItemTrackerUtils } from '@orthly/dentin';
import { LabsGqlLabOrderStatus } from '@orthly/graphql-schema';
import moment from 'moment/moment';

const getTotalFabricationDays = (order: OrderForOrderTracker): number => {
    // TODO: rework this component to no longer need to utilize the raw fab/design days.
    return order.manufacturer_sla.fabrication_days + (order.manufacturer_sla.design_days ?? 0);
};

const calculatePadding = (stepCount: number, widthRatio: number, isMobile: boolean) => {
    return (1 / (stepCount - 1)) * (widthRatio * 100) * (isMobile ? 0.7 : 0.8);
};

const getStepPercentagePadding = (
    order: OrderForOrderTracker,
    stepCount: number,
    daysSinceStepStarted: number,
    totalStepDays: number,
    status: LabsGqlLabOrderStatus,
    isMobile: boolean,
): number => {
    if (order.status !== status) {
        return 0;
    }

    // if the step has just started, we want the bar to stay where it is
    if (daysSinceStepStarted === 0) {
        return 0;
    }

    // if we've exceeded the estimate, we want the bar to be somewhere near the end
    if (daysSinceStepStarted / totalStepDays > 1) {
        return calculatePadding(stepCount, 1, isMobile);
    }

    // otherwise, we'll increment the bar by the percentage of the way through the step we are
    return calculatePadding(stepCount, daysSinceStepStarted / totalStepDays, isMobile);
};

const getInFabricationPercentagePadding = (
    order: OrderForOrderTracker,
    stepCount: number,
    isMobile: boolean,
): number => {
    if (!order.fabrication_start_date) {
        return 0;
    }

    const daysSinceFabricationStart = moment().diff(moment(order.fabrication_start_date), 'days');
    const totalFabricationDays = getTotalFabricationDays(order);

    return getStepPercentagePadding(
        order,
        stepCount,
        daysSinceFabricationStart,
        totalFabricationDays,
        LabsGqlLabOrderStatus.Fabrication,
        isMobile,
    );
};

const getShippedPercentagePadding = (order: OrderForOrderTracker, stepCount: number, isMobile: boolean): number => {
    const daysSinceShipment = moment().diff(moment(order.ship_date), 'days');
    const estimatedDeliveryDays = moment(order.practice_dates.estimated_delivery_date).diff(
        moment(order.ship_date),
        'days',
    );

    return getStepPercentagePadding(
        order,
        stepCount,
        daysSinceShipment,
        estimatedDeliveryDays,
        LabsGqlLabOrderStatus.Shipped,
        isMobile,
    );
};

const getAlignersPercentagePadding = (
    alignerProps: AlignerTrackerProps,
    stepCount: number,
    isMobile: boolean,
): number => {
    if (alignerProps.treatment_plan_ready_for_approval) {
        return calculatePadding(stepCount, 1, isMobile);
    }
    if (alignerProps.treatment_plan_active) {
        return calculatePadding(stepCount, 0.85, isMobile);
    }
    return 0;
};

const getPartialsPercentagePadding = (props: OrderDetailTrackerProps, stepCount: number, isMobile: boolean): number => {
    if (
        props.isPractice &&
        props.order.status === LabsGqlLabOrderStatus.New &&
        (props.order.partials_fulfillment?.history ?? []).length > 0
    ) {
        return calculatePadding(stepCount, 0.5, isMobile);
    }
    return 0;
};

export const getPercentagePadding = (props: OrderDetailTrackerProps, stepCount: number, isMobile: boolean): number => {
    const { order } = props;

    if (order.status === LabsGqlLabOrderStatus.Fabrication) {
        return getInFabricationPercentagePadding(order, stepCount, isMobile);
    }
    if (order.status === LabsGqlLabOrderStatus.Shipped) {
        return getShippedPercentagePadding(order, stepCount, isMobile);
    }
    const alignerProps = OrderListItemTrackerUtils.getAlignerProps(order);
    if (alignerProps) {
        return getAlignersPercentagePadding(alignerProps, stepCount, isMobile);
    }
    const partialsProps = OrderListItemTrackerUtils.getPartialsProps(order);
    if (partialsProps) {
        return getPartialsPercentagePadding(props, stepCount, isMobile);
    }
    return 0;
};
