import { getHeaderForLabSlipItem } from '../../utils/shared.utils';
import { BaseItem } from './BaseItem';
import type { IDentureItem, IOrderItemDentureProductionMetadata } from '@orthly/items';
import { DenturesProductionType } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

const getDentureAddonFields = (dentureProductionMetadata: IOrderItemDentureProductionMetadata | null | undefined) => {
    const { add_metal_framework, add_metal_mesh, add_softliner, cusil_gaskets_teeth } = dentureProductionMetadata ?? {};

    return [
        !_.isNil(add_metal_framework) && { key: 'Metal Framework', value: add_metal_framework },
        !_.isNil(add_metal_mesh) && { key: 'Metal Mesh', value: add_metal_mesh },
        !_.isNil(add_softliner) && { key: 'Softliner', value: add_softliner },
        (cusil_gaskets_teeth ?? []).length > 0 && {
            key: 'Cu-sil Gaskets Teeth',
            value: cusil_gaskets_teeth?.join(', '),
        },
    ];
};

interface IDentureItemProps {
    item: IDentureItem;
}

/**
 * Renders a "Denture" order item to display on
 * the Printable Slip
 * @param props The order item to render
 * @returns Rendered order item details for the order "Denture" item
 */
export const DentureItem: React.VFC<IDentureItemProps> = ({ item }) => {
    const { shades, denture_fabrication_method, denture_production_metadata } = item;
    const { quantity, festooning_level, add_stippling } = denture_production_metadata ?? {};
    const tissueShade = shades.find(s => s.name === 'tissue')?.value;
    const baseShade = shades.find(s => s.name === 'base')?.value;
    const headerLeft = getHeaderForLabSlipItem(item);
    const itemFields = _.compact([
        { key: 'Arch', value: item.unit.arch },
        denture_fabrication_method && {
            key: 'Fabrication Method',
            value: _.startCase(denture_fabrication_method),
        },
        (tissueShade || item.denture_production_type !== DenturesProductionType.WaxRim) && {
            key: 'Tissue Shade',
            value: tissueShade,
        },
        (baseShade || item.denture_production_type !== DenturesProductionType.WaxRim) && {
            key: 'Tooth Shade',
            value: baseShade,
        },
        { key: 'Quantity', value: quantity ?? 1 },
        festooning_level && { key: 'Festooning', value: festooning_level },
        !_.isNil(add_stippling) && { key: 'Stippling', value: add_stippling },
        ...getDentureAddonFields(item.denture_production_metadata),
    ]);

    return <BaseItem itemData={{ headerLeft, itemFields, allowPageBreaks: true }} />;
};
