import { usePortalToChairsideBridgeCommand } from '../../../utils/chairside.hooks';
import { usePartialsFeedbackPrintLabel } from '@orthly/dentin';
import type { LabsGqlPartialsFulfillmentFragment } from '@orthly/graphql-operations';
import { LabsGqlLabOrderFulfillmentStatus } from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet, Button, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    label: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
    },
}));

export const PrintPartialsReturnLabel: React.FC<{
    fulfillment: LabsGqlPartialsFulfillmentFragment;
    status: LabsGqlLabOrderFulfillmentStatus;
    title: string;
}> = props => {
    const { fulfillment, status, title } = props;
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const openPdf = usePortalToChairsideBridgeCommand('openPdf');
    const { label } = useStyles();

    const printLabel = usePartialsFeedbackPrintLabel(fulfillment, openPdf ? url => openPdf({ url }) : undefined);

    const { current } = fulfillment;
    if (
        isMobile ||
        status !== LabsGqlLabOrderFulfillmentStatus.Delivered ||
        (current.__typename !== 'PartialsFulfillmentTryInDTO' &&
            current.__typename !== 'PartialsFulfillmentWaxRimDTO') ||
        !current.feedback ||
        !current.return
    ) {
        return null;
    }

    return (
        <Button onClick={printLabel} startIcon={'PrintIcon'} variant={'primary'} classes={{ root: label }}>
            {title}
        </Button>
    );
};
