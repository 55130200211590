import { useInterval } from '../utils/useInterval';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import React from 'react';

interface IRetainerTokenContext {
    retainerToken: string | null;
    setRetainerToken: (token: string, key: string) => void;
    switchRetainerToken: (key: string) => void;
    hasRetainerToken: (key: string) => boolean;
    deleteRetainerToken: () => void;
}

const RetainerTokenContext = React.createContext<IRetainerTokenContext>({
    deleteRetainerToken: () => {},
    setRetainerToken: (_token, _key) => {},
    switchRetainerToken: _key => {},
    hasRetainerToken: _key => false,
    retainerToken: null,
});

export function useRetainerToken(): IRetainerTokenContext {
    return React.useContext(RetainerTokenContext);
}

// This is its own context to prevent constantly polling local storage
export const RetainerTokenProvider: React.FC<{ children: React.ReactNode }> = props => {
    const [retainerToken, setRetainerTokenState] = React.useState<string | null>(RetainerTokenStorage.getActiveToken());
    useInterval(() => {
        const localToken = RetainerTokenStorage.getActiveToken();
        if (localToken !== retainerToken) {
            setRetainerTokenState(localToken);
        }
    }, 250);
    const setRetainerToken = React.useCallback((token: string, key: string = '') => {
        RetainerTokenStorage.setToken(token, key);
        setRetainerTokenState(token);
    }, []);
    const switchRetainerToken = React.useCallback(
        (key: string) => {
            RetainerTokenStorage.switchToken(key);
            const localToken = RetainerTokenStorage.getActiveToken();
            if (localToken !== retainerToken) {
                setRetainerTokenState(localToken);
            }
        },
        [retainerToken],
    );
    const hasRetainerToken = React.useCallback((key: string) => {
        return RetainerTokenStorage.hasToken(key);
    }, []);
    const deleteRetainerToken = React.useCallback(() => {
        RetainerTokenStorage.removeToken();
        setRetainerTokenState(null);
    }, []);
    return (
        <RetainerTokenContext.Provider
            value={{ retainerToken, setRetainerToken, switchRetainerToken, hasRetainerToken, deleteRetainerToken }}
        >
            {props.children}
        </RetainerTokenContext.Provider>
    );
};
