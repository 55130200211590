import type { ModelAppearance, PayloadModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { RestorativeView } from '../ModelAppearance/ModelAppearanceTypes';
import { DEFAULT_TRANSPARENT_OPACITY } from '../ModelAppearance/Transparent.util';
import type { ModelPayloadItem } from '../ModelViewer/ModelViewerTypes';
import { Jaw } from '../ModelViewer/ModelViewerTypes';
import { recomputeCollisionsObject } from './RecomputeCollisions';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { AttributeName, HeatMapType, ensureDistanceAttributesInitialized } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import _ from 'lodash';
import type React from 'react';
import type * as THREE from 'three';

export enum ActiveCollisionType {
    None,
    Proximal,
    Occlusal,
}

// In the design editing app, we assume there is only ever one margin being edited at a time. By convention, we store it
// in the map of margin lines at `MARGIN_KEY`.
export const MARGIN_KEY: ToothNumber = 1;

export function getAdjacentModels(
    activeModel: PayloadModelAppearance,
    appearance: ModelAppearance,
    sameJaw: boolean,
): THREE.BufferGeometry[] {
    // We want same jaw for proximal heatmaps and opposite jaw for occlusal
    // pulled isUpper out of ternary for readability
    const isUpper = activeModel.payloadModel.jaw === Jaw.Upper;
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const desiredJaw = sameJaw ? activeModel.payloadModel.jaw : isUpper ? Jaw.Lower : Jaw.Upper;

    // TODO EPDCAD-38, for now we use heatmap proxy objects
    const restoratives = appearance.restoratives['HeatMap'];
    const adjacentGeometry = restoratives
        .filter(model => {
            // do not include the active model
            if (model === activeModel) {
                return false;
            }
            // Just get all restorations of the same jaw.
            // Future optimization: filter to +/- 2 unn and closestPointToGeometry < 2.0mm
            if (model.payloadModel.jaw === desiredJaw) {
                return false;
            }
        })
        .map(model => {
            return model.payloadModel.model.geometry;
        });

    const lowerJaw = appearance.lowerJaw[0];
    const upperJaw = appearance.upperJaw[0];

    // Put the scan in there
    upperJaw && desiredJaw === Jaw.Upper && adjacentGeometry.push(upperJaw.payloadModel.model.geometry);
    lowerJaw && desiredJaw === Jaw.Lower && adjacentGeometry.push(lowerJaw.payloadModel.model.geometry);

    return adjacentGeometry;
}

export function getModelAnatomy(
    activeModel: PayloadModelAppearance,
    appearance: ModelAppearance,
): THREE.BufferGeometry[] | undefined {
    // Do we have our activeModel yet
    if (!activeModel.payloadModel || appearance.anatomy.length === 0) {
        return undefined;
    }

    const filteredAnatomies = appearance.anatomy.filter((mpi: ModelPayloadItem) => {
        if (!mpi.unns) {
            return false;
        }
        // TODO: EPDCAD58/59: Bridges
        return mpi.unns.some(toothNumber => activeModel.payloadModel?.unns?.includes(toothNumber));
    });

    return filteredAnatomies?.map(mpi => mpi.model.geometry);
}

/**
 * Get the prep model, i.e. the model of the jaw that the restorative is on
 * @param activeModel The model of the restorative
 * @param appearance The appearance
 * @returns The prep model, if it could be found
 */
export function getPrepModel(
    activeModel?: PayloadModelAppearance,
    appearance?: ModelAppearance,
): PayloadModelAppearance | undefined {
    if (!(activeModel && appearance)) {
        return;
    }

    const isUpper = activeModel.payloadModel.jaw === Jaw.Upper;
    return isUpper ? appearance.upperJaw[0] : appearance.lowerJaw[0];
}

/**
 * Makes the CAD and HeatMap object on modelAppearance the same
 * arrays. It does this as a pre-requisite to using shaders which rely
 * on the existence of float attributes on the buffer geometry and in
 * lieu of the previous proxy objects we were using.
 * */
export function setAppearanceForCadHeatmaps(setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>) {
    setAppearance(current => {
        const cadRestoratives = current.restoratives[RestorativeView.CAD];
        const pastCadRestoratives = current.pastRestoratives.CAD;

        const cad0 = cadRestoratives[0]?.payloadModel.model.geometry;
        const heatMap0 = current.restoratives[RestorativeView.HeatMap][0]?.payloadModel.model.geometry;

        const pastCad0 = pastCadRestoratives[0]?.payloadModel.model.geometry;
        const pastHeatMap0 = current.pastRestoratives.HeatMap[0]?.payloadModel.model.geometry;

        // already unified, do not cause infinite re-render
        if (cad0 === heatMap0 && pastCad0 === pastHeatMap0) {
            return current;
        }

        return {
            ...current,
            restoratives: {
                ...current.restoratives,
                [RestorativeView.HeatMap]: cadRestoratives,
            },
            pastRestoratives: {
                ...current.pastRestoratives,
                [RestorativeView.HeatMap]: pastCadRestoratives,
            },
        };
    });
}
/**
 * Idempotently unifies the two restoratives views
 * @param orderId The order ID, used for tracking performance
 * @param appearance Current appearance settings
 * @param setAppearance Setter for the appearance settings
 */
export function unifyRestorativesView(
    orderId: string | undefined,
    appearance: ModelAppearance,
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
): void {
    setAppearanceForCadHeatmaps(setAppearance);

    const cadRestoratives = appearance.restoratives[RestorativeView.CAD];
    const pastCadRestoratives = appearance.pastRestoratives[RestorativeView.CAD];

    const initializeDistanceAttributes = (pma: PayloadModelAppearance): void => {
        const geometry = pma.payloadModel.model.geometry;
        if (!geometry.hasAttribute(AttributeName.Normal)) {
            geometry.computeVertexNormals();
        }

        const curtainsGeometry = appearance.curtains[pma.payloadModel.modelElementID ?? '']?.model.geometry;

        const proximalModels = getAdjacentModels(pma, appearance, true);
        const occlusalModels = getAdjacentModels(pma, appearance, false);
        ensureDistanceAttributesInitialized(geometry, proximalModels, occlusalModels, curtainsGeometry);
    };

    const currentRestorativesStartMs = performance.now();
    cadRestoratives.forEach(initializeDistanceAttributes);
    const totalTime = performance.now() - currentRestorativesStartMs;

    const pastRestorativesStartMs = performance.now();
    pastCadRestoratives.forEach(initializeDistanceAttributes);
    const totalTimeForPast = performance.now() - pastRestorativesStartMs;

    const collisionComputationStartMs = performance.now();
    const restorativeCollisionsItems = cadRestoratives.map((pma: PayloadModelAppearance) =>
        recomputeCollisionsObject(pma.payloadModel.model.geometry),
    );
    setAppearance(current => ({
        ...current,
        collisions: [...current.collisions.filter(el => el.name.includes('SCAN')), ...restorativeCollisionsItems],
    }));
    const collisionComputationTime = performance.now() - collisionComputationStartMs;

    BrowserAnalyticsClientFactory.Instance?.track('Ops - Portal - DesignEditor - Load Computation Performance', {
        $groups: { order: orderId ?? '' },
        numRestoratives: cadRestoratives.length,
        timePerRestorative: totalTime / cadRestoratives.length,
        collisionComputationTime,
        numPastRestoratives: pastCadRestoratives.length,
        timePerPastRestorative: pastCadRestoratives.length ? totalTimeForPast / pastCadRestoratives.length : undefined,
    });
}

/**
 * Sets the desired appearance for sculpting mode
 * @param setAppearance Model appearance setter
 * @param restorationIndex Index of the active (i.e. being edited) model in the list of restoratives
 */
export function setSculptingAppearance(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>> | undefined,
    restorationIndex: number,
) {
    if (!setAppearance) {
        return;
    }

    setAppearance(current => {
        const activeModel = current.restoratives['CAD'][restorationIndex];
        if (!activeModel) {
            // No update to be made, so return the same object.
            return current;
        }

        // Make the geometry visible and opaque.
        activeModel.appearance.visible = true;
        activeModel.appearance.opacity = 1;

        // Hide printed models.
        current.printedModels.forEach(mpa => {
            mpa.appearance.visible = false;
        });

        // Return a new object so that the state update is not ignored.
        return { ...current };
    });
}

/**
 * Sets the desired appearance for margin editing mode
 * @param setAppearance Model appearance setter
 * @param restorationIndex Index of the active (i.e. being edited) model in the list of restoratives
 */
export function setMarginEditingAppearance(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>> | undefined,
    restorationIndex: number,
) {
    if (!setAppearance) {
        return;
    }

    setAppearance(current => {
        const activeModel = current.restoratives['CAD'][restorationIndex];
        if (!activeModel) {
            // No update to be made, so return the same object.
            return current;
        }

        // Make the geometry invisible, and transparent if it is later made visible.
        // NB: The restorative view must be `CAD` in order to make the geometry transparent.
        activeModel.appearance.visible = false;
        activeModel.appearance.opacity = DEFAULT_TRANSPARENT_OPACITY;
        current.restorativeView = RestorativeView.CAD;

        // Show the same jaw and hide the antagonist jaw.
        const isUpper = activeModel.payloadModel.jaw === Jaw.Upper;

        current.lowerJaw.forEach(mpa => {
            mpa.appearance.visible = !isUpper;
            mpa.appearance.opacity = 1;
        });

        current.upperJaw.forEach(mpa => {
            mpa.appearance.visible = isUpper;
            mpa.appearance.opacity = 1;
        });

        // Hide printed models.
        current.printedModels.forEach(mpa => {
            mpa.appearance.visible = false;
        });

        // Return a new object so that the state update is not ignored.
        return { ...current };
    });
}

/**
 * Sets the desired appearance for review mode, or when exiting review mode
 * @param setAppearance Model appearance setter
 * @param checkChangesEnabled True if we are entering review mode, false otherwise
 * @param showContours Whether or not the surface displacement heatmap should be shown
 * @param restorationIndex Index of the active (i.e. being edited) model in the list of restoratives
 */
export function setCheckChangesAppearance(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>> | undefined,
    checkChangesEnabled: boolean,
    showContours: boolean,
    restorationIndex: number,
) {
    if (!setAppearance) {
        return;
    }

    setAppearance(current => {
        const activeModel = current.restoratives['CAD'][restorationIndex];
        if (!activeModel) {
            return { ...current };
        }

        if (checkChangesEnabled) {
            // Make the geometry visible and opaque.
            activeModel.appearance.visible = true;
            activeModel.appearance.opacity = 1;

            // Set the desired heatmap.
            const restorativeView = RestorativeView.HeatMap;
            const activeHeatMap = showContours ? HeatMapType.SurfaceDisplacement : HeatMapType.VertexDisplacement;

            // Hide all other entities.
            const lowerJaw = [...current.lowerJaw];
            const upperJaw = [...current.upperJaw];
            const printedModels = [...current.printedModels];

            _.concat(lowerJaw, upperJaw, printedModels).forEach(mpa => (mpa.appearance.visible = false));

            const showAnatomyLayers = false;

            return { ...current, lowerJaw, upperJaw, printedModels, restorativeView, activeHeatMap, showAnatomyLayers };
        }

        // Turn off the heatmap.
        return { ...current, restorativeView: RestorativeView.CAD };
    });
}
