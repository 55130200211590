import { InboxBackgroundImg } from './components/InboxBackgroundImg';
import { InboxEmptySection } from './components/InboxEmptySection';
import { InboxLoyaltyBanner } from './components/tracker/InboxLoyaltyBanner';
import { InboxTrackingRoot } from './components/tracker/InboxTrackingRoot';
import { useCrmPractice } from './state/useCRMPractice';
import { useInboxActiveOrders } from './state/useInboxActiveOrders';
import { useInboxTitleMessage } from './useInboxTitleMessage';
import { Format } from '@orthly/runtime-utils';
import { TOOLBAR_TABLET_DOWN_HEIGHT, ToolbarContainer } from '@orthly/ui';
import { stylesFactory, Grid, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    root: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            marginTop: TOOLBAR_TABLET_DOWN_HEIGHT,
        },
    },
    toolbar: {
        height: 'auto',
        [theme.breakpoints.down('md')]: {
            top: TOOLBAR_TABLET_DOWN_HEIGHT,
        },
    },
}));

const useLoyaltyDetails = (): { optedIn: boolean; points: number } | null => {
    const { data: rawCRMPracticeData } = useCrmPractice();

    if (!rawCRMPracticeData?.getCRMPractice?.nullable_loyalty_details) {
        return null;
    }

    const details = rawCRMPracticeData.getCRMPractice.nullable_loyalty_details;
    return { optedIn: details.has_opted_into_loyalty, points: details.loyalty_points_balance };
};

const InboxContentToolbar: React.VFC = () => {
    const title = useInboxTitleMessage();
    const classes = useStyles();
    const { value: enableInboxLoyaltyBanner = false } = useFeatureFlag('enableInboxLoyaltyBanner');
    const loyaltyDetails = useLoyaltyDetails();

    return (
        <ToolbarContainer sticky disableMobileSticky className={classes.toolbar}>
            {enableInboxLoyaltyBanner && loyaltyDetails ? (
                <InboxLoyaltyBanner isOptedIn={loyaltyDetails.optedIn} points={loyaltyDetails.points} />
            ) : (
                <Text variant={'h5'} data-test={'inbox-title'}>
                    {title}
                </Text>
            )}
        </ToolbarContainer>
    );
};

export const InboxContentRoot: React.FC = () => {
    const { priority, orders } = useInboxActiveOrders();
    const classes = useStyles();
    const totalOrders = priority.length + orders.length;

    return (
        <Grid container className={classes.root}>
            <Grid style={{ width: '100%' }}>
                <InboxContentToolbar />
                <InboxEmptySection activeOrderCount={totalOrders} />
                <InboxTrackingRoot
                    orders={priority}
                    title={`You have ${Format.pluralize('order', priority.length)} that ${Format.pluralizeNoun(
                        'requires',
                        priority.length,
                        'require',
                    )} your attention`}
                />
                <InboxTrackingRoot orders={orders} title={'Track your orders'} />
            </Grid>
            <InboxBackgroundImg activeOrderCount={totalOrders} />
        </Grid>
    );
};
