import type { CrossSectionData, CrossSectionItem } from './CrossSectionData';
import { AttributeName, getMarginPoints } from '@orthly/forceps';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import type { MarginLine } from '@orthly/shared-types';
import * as THREE from 'three';
import { mergeBufferGeometries } from 'three-stdlib';

/**
 * Create line buffer geometry with enough data to hold @param length segments.
 * @param {number} [length = 6000]
 */
export function createLineGeometry(length?: number) {
    const size = length ?? 6000;
    const lineGeometry = new THREE.BufferGeometry();
    const linePosAttr = new THREE.BufferAttribute(new Float32Array(3 * size), 3, false);
    linePosAttr.setUsage(THREE.DynamicDrawUsage);
    lineGeometry.setAttribute(AttributeName.Position, linePosAttr);

    return lineGeometry;
}

/**
 * Build one bufferGeometry from all marginLines
 */
export function buildGeometryFromMarginLines(
    margin_lines?: MarginLine[],
    showMarginLines?: boolean,
): THREE.BufferGeometry {
    let result: THREE.BufferGeometry = new THREE.BufferGeometry();
    if (showMarginLines && margin_lines) {
        margin_lines.forEach(mline => {
            const points = getMarginPoints(mline);
            const lineGeometry = buildGeometryFromMarginLine(points);
            if (!result.attributes.position) {
                result = lineGeometry;
            } else {
                result = mergeBufferGeometries([result, lineGeometry]) ?? result;
            }
        });
    }
    return result ?? new THREE.BufferGeometry();
}

/**
 * Build one geometry from one marginLine, storing each line segment two vertices in the buffer array
 */
export function buildGeometryFromMarginLine(points: THREE.Vector3[]): THREE.BufferGeometry {
    const pts_pairs: THREE.Vector3[] = [];
    for (let i = 0; i < points.length; i++) {
        const pt = points[i];
        const pt_next = points[(i + 1) % points.length];
        if (pt && pt_next) {
            pts_pairs.push(pt);
            pts_pairs.push(pt_next);
        }
    }
    const lineGeometry = new THREE.BufferGeometry();
    lineGeometry.setFromPoints(pts_pairs);
    return lineGeometry;
}

/**
 * Build BVH Index for `payloadModel` and add CrossSection entry to `crossSection`
 *
 * Check if BVH index already built for geometry
 * Skip geometry index if payloadModel is in the crossSection errorEntries
 * If BVH index fails to build, add entry to crossSection errorEntries
 */
export function addPayloadItemToCrossSection(crossSection: CrossSectionData, item: CrossSectionItem) {
    try {
        const payloadIsValid = !crossSection.errorEntries?.some(err => err.modelPayload === item.payloadModel);

        // Skip invalid payload entries, dont try to rebuild
        // geometry index for them
        if (payloadIsValid) {
            crossSection.entries.push({
                visible: item.isVisible,
                type: item.payloadModel.type ?? LabsGqlOrderDesignScanType.Other,
                jaw: item.payloadModel.jaw,
                isPast: item.isPast,
                lineGeometry: createLineGeometry(),
                geometry: item.payloadModel.model?.geometry,
                mesh: item.payloadModel.mesh,
            });
        }
    } catch (meshIndexError: any) {
        console.error(`Failed to index geometry for ${item.payloadModel.name}`, meshIndexError);
        const errorMsg = (meshIndexError as any).message ?? String(meshIndexError);

        if (!crossSection.errorEntries) {
            crossSection.errorEntries = [];
        }

        crossSection.errorEntries.push({
            errorMsg,
            modelPayload: item.payloadModel,
        });
    }
}

/**
 * Adds a cross section entry for a margin line
 * @param crossSection The object to which to add the entry
 * @param oldCrossSection The prior cross section data
 * @param isPast If true, mark the cross section entry as having come from a previous design
 * @param marginLines The margin lines
 * @param showMarginLines Whether margin lines should be shown
 */
export function addMarginToCrossSection(
    crossSection: CrossSectionData,
    oldCrossSection: CrossSectionData | undefined,
    isPast: boolean,
    marginLines?: MarginLine[],
    showMarginLines?: boolean,
) {
    if (marginLines && marginLines.length > 0) {
        const oldEntry = oldCrossSection?.entries?.find(e => e.type === 'MarginLine' && e.isPast === isPast);
        const visible = showMarginLines ?? false;
        const marginLinesGeometry = buildGeometryFromMarginLines(marginLines, showMarginLines);

        if (oldEntry) {
            // the entry already exists
            oldEntry.visible = visible;
            oldEntry.geometry = marginLinesGeometry;
            crossSection.entries.push(oldEntry);
        } else {
            crossSection.entries.push({
                visible,
                type: 'MarginLine',
                jaw: undefined,
                lineGeometry: createLineGeometry(),
                geometry: marginLinesGeometry,
                isPast: isPast,
            });
        }
    }
}

/**
 * Adds a cross section entry for an updated margin line
 * @param crossSection The object to which to add the entry
 * @param oldCrossSection The prior cross section data
 * @param updatedMarginLine The updated margin line
 * @param showUpdatedMarginLines Whether the updated margin line should be shown
 */
export function addUpdatedMarginToCrossSection(
    crossSection: CrossSectionData,
    oldCrossSection: CrossSectionData | undefined,
    updatedMarginLine: THREE.Vector3[] | undefined,
    showUpdatedMarginLines: boolean | undefined,
) {
    if (updatedMarginLine && updatedMarginLine.length > 0) {
        const oldEntry = oldCrossSection?.entries?.find(e => e.type === 'UpdatedMarginLine');
        const visible = !!showUpdatedMarginLines;
        const geometry = visible ? buildGeometryFromMarginLine(updatedMarginLine) : new THREE.BufferGeometry();

        // Always update the old entry if it exists, but do not create a new entry unless the updated margin line should
        // be shown.
        if (oldEntry) {
            // the entry already exists
            oldEntry.visible = visible;
            oldEntry.geometry = geometry;
            crossSection.entries.push(oldEntry);
        } else if (showUpdatedMarginLines) {
            crossSection.entries.push({
                visible,
                type: 'UpdatedMarginLine',
                jaw: undefined,
                lineGeometry: createLineGeometry(),
                geometry: geometry,
            });
        }
    }
}
