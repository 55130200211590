import { useFileUploaderVars } from './FileUploader.utils';
import type { FileUploaderBulkProps } from './FileUploaderBulk';
import { SimpleDropzone } from './FileUploaderDropzone';
import { FileUploaderLayout } from './FileUploaderLayout';
import type { GridProps } from '@orthly/ui-primitives';
import { Collapse, Grid } from '@orthly/ui-primitives';
import type { CSSProperties } from 'react';
import React from 'react';

export interface FileUploaderControlledPreviewProps extends FileUploaderBulkProps {
    dropzoneTypographyStyle?: React.CSSProperties;
    loadBlockerContainerProps?: GridProps;
    PreviewComponent: React.FC<{ onReset: () => void; file: File; style?: CSSProperties }>;
    previewComponentStyle?: CSSProperties;
    files: File[];
    loading?: boolean;
}

/**
 * This component is a controlled file uploader
 *
 * The displayed preview will always be `prop.files`, makig the React state the source of truth
 *
 * Changes will be propagated with the onSetCanUpload or onComplete handlers
 */
export const FileUploaderControlledPreview: React.FC<FileUploaderControlledPreviewProps> = props => {
    const { dropzoneOptions, PreviewComponent, previewComponentStyle, files } = props;

    const { loading, onDropAccepted, onReset } = useFileUploaderVars(props);

    return (
        <FileUploaderLayout
            loading={loading || props.loading}
            title={props.title}
            elevation={props.elevation}
            paperStyle={props.paperStyle}
            loadBlockerContainerProps={props.loadBlockerContainerProps}
        >
            <Collapse in={!files.length} style={{ width: '100%', maxHeight: '100%' }}>
                <Grid container>
                    <SimpleDropzone
                        wrapperStyle={props.dropzoneWrapperStyle}
                        typographyStyle={props.dropzoneTypographyStyle}
                        options={{ ...dropzoneOptions, onDropAccepted }}
                        dropzoneContent={props.dropzoneContent}
                    />
                </Grid>
            </Collapse>
            <Collapse in={files.length > 0 || loading} style={{ width: '100%', maxHeight: '100%' }}>
                {files?.map(file => (
                    <PreviewComponent key={file.name} file={file} onReset={onReset} style={previewComponentStyle} />
                ))}
            </Collapse>
        </FileUploaderLayout>
    );
};
