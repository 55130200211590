import { HeatMapType } from '@orthly/forceps';
import type { UseStateObject } from '@orthly/ui';
import {
    AlignerModelFaceFrontIcon,
    AlignerModelFaceLeftIcon,
    AlignerModelFaceRightIcon,
    LayersIcon,
    LowerJawIcon,
    ModelFaceBackIcon,
    ModelFaceBottomIcon,
    ModelFaceTopIcon,
    ModelMarginIcon,
    ModelTextureIcon,
    QCHeatmapIcon,
    RestorativesIcon,
    RestorativesTransparentIcon,
    UpperJawIcon,
} from '@orthly/ui';
import type { IconButtonProps } from '@orthly/ui-primitives';
import { Button, FlossPalette, Grid, Tooltip, ButtonGroup, IconButton } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

// for me this is ModelToolbarButtonType since these are the available buttons
export type ModelToolbarType =
    | 'view_front'
    | 'view_back'
    | 'view_left'
    | 'view_right'
    | 'view_bottom'
    | 'view_top'
    | 'toggle_texture'
    | 'toggle_restoratives'
    | 'toggle_restorative_transparency'
    | 'toggle_qc_heatmaps'
    | 'toggle_margin'
    | 'toggle_layers'
    | 'toggle_upper'
    | 'toggle_lower';

export type ToolEnableDisableMap = Partial<Record<ModelToolbarType, boolean>>;

const defaultToolsMap: ToolEnableDisableMap = {
    toggle_restoratives: true,
    toggle_restorative_transparency: true,
    toggle_qc_heatmaps: false,
    toggle_texture: false,
    toggle_margin: false,
    toggle_layers: false,
    toggle_upper: false,
    toggle_lower: false,
};

const alwaysDisabledToolMap: ToolEnableDisableMap = {
    view_back: false,
    view_bottom: false,
    view_top: false,
};

const mobileOverrideToolMap: ToolEnableDisableMap = {
    toggle_layers: false,
    toggle_upper: false,
    toggle_lower: false,
};

export interface ModelToolbarTool {
    type: ModelToolbarType;
    title: string;
    pretty_title?: string; // used for the post facelift version of the model viewer (see use_pretty_buttons)
    canBeActivated?: boolean;
    icon: React.FC;
}

// All of the tools that should render in the sidebar.
const allTools: ModelToolbarTool[] = [
    {
        type: 'toggle_upper',
        title: 'Toggle Maxillary',
        pretty_title: 'Upper',
        canBeActivated: true,
        icon: UpperJawIcon,
    },
    {
        type: 'toggle_lower',
        title: 'Toggle Mandibular',
        pretty_title: 'Lower',
        canBeActivated: true,
        icon: LowerJawIcon,
    },
    {
        type: 'view_front',
        title: 'Front view',
        pretty_title: 'Front',
        icon: () => <AlignerModelFaceFrontIcon />,
    },
    {
        type: 'view_back',
        title: 'Back view',
        icon: ModelFaceBackIcon,
    },
    {
        type: 'view_left',
        title: 'Left view',
        pretty_title: 'Left',
        icon: () => <AlignerModelFaceLeftIcon />,
    },
    {
        type: 'view_right',
        title: 'Right view',
        pretty_title: 'Right',
        icon: () => <AlignerModelFaceRightIcon />,
    },
    {
        type: 'view_top',
        title: 'Top view',
        icon: ModelFaceTopIcon,
    },
    {
        type: 'view_bottom',
        title: 'Bottom view',
        icon: ModelFaceBottomIcon,
    },
    {
        type: 'toggle_texture',
        title: 'Show/hide color',
        canBeActivated: true,
        icon: ModelTextureIcon,
    },
    {
        type: 'toggle_restoratives',
        title: 'Show/hide restorative',
        canBeActivated: true,
        icon: RestorativesIcon,
    },
    {
        type: 'toggle_restorative_transparency',
        title: 'Show restorations opaque/transparent',
        canBeActivated: true,
        icon: RestorativesTransparentIcon,
    },
    {
        type: 'toggle_qc_heatmaps',
        title: 'Show/Hide Heatmaps',
        canBeActivated: true,
        icon: QCHeatmapIcon,
    },
    {
        type: 'toggle_layers',
        title: 'Show/hide layers',
        canBeActivated: true,
        icon: LayersIcon,
    },
    {
        type: 'toggle_margin',
        title: 'Show/hide margin line',
        canBeActivated: true,
        icon: ModelMarginIcon,
    },
];

export const MODEL_TOOLBAR_HEIGHT: number = (allTools.length + 1) * 40;

interface ModelToolbarProps extends UseStateObject<'pressedButtons', ModelToolbarType[]> {
    onToolClick: (button: ModelToolbarType) => void;
    toolEnableDisableMap: ToolEnableDisableMap;
    activeColorMap?: HeatMapType;
    setActiveColorMap: (next: HeatMapType) => void;
    enableClearanceTool: boolean;
}

interface ModelToolbarButtonProps extends IconButtonProps, UseStateObject<'pressedButtons', ModelToolbarType[]> {
    tool: ModelToolbarTool;
    onToolClick: (tool: ModelToolbarType) => void;
    additionalStyle?: React.CSSProperties;
    deselectedColor: FlossPalette['LIGHT_GRAY'] | FlossPalette['WHITE'];
    use_pretty_buttons?: boolean;
}

const ModelToolbarButton: React.VFC<ModelToolbarButtonProps> = props => {
    const {
        tool,
        additionalStyle,
        pressedButtons,
        setPressedButtons,
        onToolClick,
        size,
        deselectedColor,
        use_pretty_buttons = false,
        ...IconButtonProps
    } = props;
    const onClick = React.useCallback(() => {
        if (tool.canBeActivated) {
            setPressedButtons(state => {
                return state.includes(tool.type) ? state.filter(t => t !== tool.type) : [...state, tool.type];
            });
        }
        onToolClick(tool.type);
    }, [onToolClick, tool, setPressedButtons]);

    if (use_pretty_buttons) {
        const button_active = tool.canBeActivated && pressedButtons.includes(tool.type);
        return (
            <Button
                variant={button_active ? `primary` : `secondary`}
                onClick={onClick}
                style={{ backgroundColor: `white`, margin: 4 }}
            >
                <span style={{ display: `flex`, width: 96 }}>
                    {tool.pretty_title ?? tool.title}
                    <span style={{ flexGrow: 1 }} />
                    <span className={`MuiButton-endIcon`}>
                        <tool.icon />
                    </span>
                </span>
            </Button>
        );
    }

    return (
        <IconButton
            {...IconButtonProps}
            onClick={onClick}
            style={{
                minHeight: 40,
                backgroundColor:
                    tool.canBeActivated && pressedButtons.includes(tool.type)
                        ? FlossPalette.STAR_GRASS
                        : deselectedColor,
                color: FlossPalette.BLACK,
                ...additionalStyle,
            }}
            size={size}
        >
            <Tooltip title={tool.title}>
                <tool.icon />
            </Tooltip>
        </IconButton>
    );
};

const useTools = (toolEnableDisableMap: ToolEnableDisableMap): ModelToolbarTool[] => {
    return React.useMemo(() => {
        const disabledTools = {
            ...defaultToolsMap,
            ...toolEnableDisableMap,
            ...alwaysDisabledToolMap,
        };

        return allTools.filter(t => disabledTools[t.type]);
    }, [toolEnableDisableMap]);
};

export const ModelToolbarMobile: React.FC<ModelToolbarProps> = props => {
    const { onToolClick, pressedButtons, setPressedButtons } = props;

    const toolEnableDisableMap = React.useMemo(() => {
        return {
            ...props.toolEnableDisableMap,
            ...mobileOverrideToolMap,
        };
    }, [props.toolEnableDisableMap]);
    const tools = useTools(toolEnableDisableMap);

    return (
        <Grid
            container
            wrap={'nowrap'}
            justifyContent={'space-evenly'}
            style={{ width: 'auto', paddingBottom: 16, position: 'absolute', left: 155, bottom: 0 }}
        >
            {tools.map(tool => (
                <ModelToolbarButton
                    key={tool.title}
                    tool={tool}
                    additionalStyle={{ borderRadius: 2, margin: '0 4px 0 4px' }}
                    pressedButtons={pressedButtons}
                    setPressedButtons={setPressedButtons}
                    onToolClick={onToolClick}
                    deselectedColor={FlossPalette.WHITE}
                />
            ))}
        </Grid>
    );
};

export const ModelToolbar: React.FC<ModelToolbarProps & { use_pretty_buttons: boolean }> = props => {
    const {
        onToolClick,
        pressedButtons,
        setPressedButtons,
        use_pretty_buttons,
        activeColorMap,
        setActiveColorMap,
        enableClearanceTool: clearanceToolEnabled,
    } = props;

    const toolEnableDisableMap = React.useMemo(() => {
        return {
            ...props.toolEnableDisableMap,
            toggle_layers: props.toolEnableDisableMap?.toggle_layers && pressedButtons.includes('toggle_restoratives'),
        };
    }, [props.toolEnableDisableMap, pressedButtons]);

    const tools = useTools(toolEnableDisableMap);

    const box_size = use_pretty_buttons ? { bottom: 8, right: -16, width: `auto` } : { top: 0, right: 0, width: 64 };
    // don't forget to feature flag this!!
    const colorMaps: { label: React.ReactNode; tooltip: string; value: HeatMapType }[] = _.compact([
        { label: 'T', tooltip: 'Thickness', value: HeatMapType.Thickness },
        { label: 'P', tooltip: 'Proximal', value: HeatMapType.Proximal },
        { label: 'O', tooltip: 'Occlusal', value: HeatMapType.Occlusal },
        clearanceToolEnabled ? { label: 'C', tooltip: 'Clearance', value: HeatMapType.Clearance } : null,
    ]);

    return (
        <Grid
            container
            style={{ padding: 16, position: 'absolute', ...box_size }}
            direction={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <ButtonGroup style={use_pretty_buttons ? {} : { width: 40 }} orientation={'vertical'}>
                {tools.map(tool => (
                    <ModelToolbarButton
                        key={tool.title}
                        tool={tool}
                        onToolClick={onToolClick}
                        pressedButtons={pressedButtons}
                        setPressedButtons={setPressedButtons}
                        size={'small'}
                        deselectedColor={FlossPalette.TAN}
                        use_pretty_buttons={use_pretty_buttons}
                    />
                ))}
            </ButtonGroup>

            {activeColorMap && (
                <ButtonGroup style={{ width: 40, marginTop: 25 }} orientation={'vertical'}>
                    {colorMaps.map(colorMap => {
                        const selected = colorMap.value === activeColorMap;
                        return (
                            <Tooltip title={colorMap.tooltip} key={colorMap.tooltip /* hey, it's a string */}>
                                <IconButton
                                    onClick={() => {
                                        setActiveColorMap(colorMap.value);
                                    }}
                                    style={{
                                        minHeight: 40,
                                        backgroundColor: selected ? FlossPalette.STAR_GRASS : FlossPalette.TAN,
                                        color: selected ? FlossPalette.TAN : undefined,
                                    }}
                                    size={'small'}
                                >
                                    <>{colorMap.label}</>
                                </IconButton>
                            </Tooltip>
                        );
                    })}
                </ButtonGroup>
            )}
        </Grid>
    );
};
