import { useRootStyles } from '../../components/Containers';
import { useFullPageFormStyles } from '../../layouts/FullPageForm.styles';
import { FullPageFormBackButton } from '@orthly/dentin';
import { useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import React from 'react';

interface SignUpPageContainerProps {
    header: string;
    subheader?: string | React.ReactNode;
    sidebar?: JSX.Element;
    onBackAction?: () => void;
}

export const SignUpPageContainer: React.FC<SignUpPageContainerProps> = props => {
    const { children, header, sidebar, subheader, onBackAction } = props;
    const classes = useFullPageFormStyles();
    const rootClasses = useRootStyles();
    const isMobile = useScreenIsMobile();

    return (
        <>
            <Grid item container={isMobile} lg={7} md={7} sm={12}>
                <Grid className={classes.leftContentWrapper}>
                    {onBackAction && (
                        <Grid item>
                            <FullPageFormBackButton onBackAction={onBackAction} />
                        </Grid>
                    )}

                    <Grid item>
                        <Text variant={isMobile ? 'h5' : 'h4'} style={{ marginBottom: !!subheader ? 8 : 0 }}>
                            {header}
                        </Text>
                    </Grid>
                    {subheader && (
                        <Text variant={isMobile ? 'body2' : 'body1'} color={'GRAY'}>
                            {subheader}
                        </Text>
                    )}
                    <Grid item style={{ marginTop: 24 }}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
            {sidebar && (
                <Grid item lg={4} md={5} className={rootClasses.noSmallScreenDisplay}>
                    <Grid className={classes.rightContentWrapper}>{sidebar}</Grid>
                </Grid>
            )}
        </>
    );
};
