import { isGuidedPreset, useNextTabNavigation } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_MOBILE_NAV_HEIGHT, HIDE_POPUP_MODAL_KEY, NAV_BUTTON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector, useWaxupIsRejected } from '../state/GuidedWaxupState';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { LabsGqlGuidedWaxupPresetStatus, LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import {
    Button,
    FlossPalette,
    Grid,
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
} from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

const useStyles = stylesFactory(() => ({
    navAreaDesktop: {
        marginBottom: 20,
        paddingLeft: 24,
    },
    requiredFieldText: {
        paddingLeft: 10,
    },
    navAreaMobile: {
        width: '100%',
        height: GUIDED_WAXUP_MOBILE_NAV_HEIGHT,
        borderTop: `2px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.WHITE,
        // setting position and zIndex so elements are hidden behind nav area when scrolling
        position: 'fixed',
        zIndex: 1,
        alignItems: 'center',
        justifyContent: 'space-evenly',
        // using dynamic viewport units to account for address bar on mobile browsers
        top: `calc(100dvh - ${GUIDED_WAXUP_MOBILE_NAV_HEIGHT}px)`,
    },
    mobileButtons: {
        width: '50%',
        padding: '5px 5px 5px 10px',
        '&:last-child': {
            padding: '5px 10px 5px 5px',
        },
    },
    soloButton: {
        width: '100%',
        padding: '15px',
    },
}));

const trackNavButtonClick = (buttonText: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
        AssetName: `Guided Waxup Navigation Button - ${buttonText}`,
        AssetType: 'button',
        AssetVersion: '',
        AssetCTAText: buttonText,
    });
};

interface NavAreaProps {
    submit?: () => Promise<unknown>;
}

export const GuidedWaxupNavArea: React.VFC<NavAreaProps> = ({ submit }) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const {
        selectedTab,
        setSelectedTab,
        isAnnotatingScreenshot,
        selectedDesignRevisionAlreadyReviewed,
        setIsSkipToGeneralViewModalOpen,
        setIsMobileRejectionNoteOpen,
        revisionId,
        internalEvaluation,
        showOnlyGeneralView,
    } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const skipToGeneralView = useGuidedWaxupAction('SKIP_TO_GENERAL_VIEW');
    const waxupRejected = useWaxupIsRejected();
    const navigateToNextTab = useNextTabNavigation();
    const guidedPreset = isGuidedPreset(selectedTab);
    const disableNav =
        (presets[selectedTab]?.status === LabsGqlGuidedWaxupPresetStatus.Rejected && !presets[selectedTab]?.notes) ||
        isAnnotatingScreenshot;
    const buttonIsSkipToSubmit = guidedPreset && !selectedDesignRevisionAlreadyReviewed;
    const showPrimaryButton = !selectedDesignRevisionAlreadyReviewed || guidedPreset;
    const showSecondaryButton = buttonIsSkipToSubmit || !guidedPreset;
    const generalRejectionNote = useGuidedWaxupSelector(s => s.presets[selectedTab]?.notes) ?? '';
    const viewExistingRejectionNote =
        selectedDesignRevisionAlreadyReviewed || revisionId !== undefined || generalRejectionNote?.length > 0;

    let primaryBtnText = guidedPreset ? 'Continue' : `${waxupRejected ? 'Reject' : 'Approve'} Design`;
    if (internalEvaluation) {
        primaryBtnText += ' (Not recommended. Internal Evaluation)';
    }
    // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
    // eslint-disable-next-line no-nested-ternary
    const secondaryBtnText = buttonIsSkipToSubmit
        ? 'Skip to Submit Review'
        : viewExistingRejectionNote
          ? 'View Rejection Note'
          : 'Add Rejection Note';

    React.useEffect(() => {
        if (internalEvaluation || showOnlyGeneralView) {
            skipToGeneralView({});
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        }
    }, [internalEvaluation, setSelectedTab, skipToGeneralView, showOnlyGeneralView]);

    const navigateOrSubmitClick = () => {
        trackNavButtonClick(primaryBtnText);
        if (!guidedPreset) {
            void submit?.();
        } else {
            navigateToNextTab();
        }
    };

    const skipToGeneralViewClick = () => {
        trackNavButtonClick('Skip to Submit Review');
        if (localStorage.getItem(HIDE_POPUP_MODAL_KEY) === 'true') {
            skipToGeneralView({});
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        } else {
            setIsSkipToGeneralViewModalOpen(true);
        }
    };

    if (isMobile) {
        return (
            <Grid item container className={classes.navAreaMobile}>
                {showSecondaryButton && (
                    <Grid item className={showPrimaryButton ? classes.mobileButtons : classes.soloButton}>
                        <Button
                            variant={buttonIsSkipToSubmit ? 'secondary-gray' : 'alert-secondary'}
                            style={NAV_BUTTON_STYLES}
                            disabled={disableNav}
                            onClick={() => {
                                if (buttonIsSkipToSubmit) {
                                    skipToGeneralViewClick();
                                } else {
                                    setIsMobileRejectionNoteOpen(true);
                                }
                            }}
                        >
                            {secondaryBtnText}
                        </Button>
                    </Grid>
                )}
                {showPrimaryButton && (
                    <Grid item className={showSecondaryButton ? classes.mobileButtons : classes.soloButton}>
                        <Button
                            variant={'primary'}
                            style={NAV_BUTTON_STYLES}
                            disabled={disableNav}
                            onClick={navigateOrSubmitClick}
                        >
                            {primaryBtnText}
                        </Button>
                    </Grid>
                )}
            </Grid>
        );
    }

    return (
        <Grid item container direction={'column'}>
            {disableNav && (
                <Grid item className={clsx(classes.navAreaDesktop, classes.requiredFieldText)}>
                    <Text variant={'body2'}>One or more required field(s) are missing</Text>
                </Grid>
            )}
            <Grid item container direction={'row'} className={classes.navAreaDesktop} spacing={2}>
                {buttonIsSkipToSubmit && (
                    <Grid item>
                        <Button variant={'secondary-gray'} disabled={disableNav} onClick={skipToGeneralViewClick}>
                            {secondaryBtnText}
                        </Button>
                    </Grid>
                )}
                <Grid item>
                    <Button variant={'primary'} disabled={disableNav} onClick={navigateOrSubmitClick}>
                        {primaryBtnText}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
