import type { ICameraControls } from './CameraControls.types';
import { TrackballControls as TrackballControlsImplNew } from './CustomTrackballControls';
import type { ReactThreeFiber } from '@react-three/fiber';
import { useFrame, useThree } from '@react-three/fiber';
import React from 'react';
import type * as THREE from 'three';
import { TrackballControls as TrackballControlsImplOriginal } from 'three-stdlib';

export type { ICameraControls } from './CameraControls.types';

// originally taken from:
// https://github.com/pmndrs/drei/blob/master/src/core/TrackballControls.tsx
// added use3ShapeViewerControls flag to switch implementation between the original trackball-controls,
// and the new implementation that has differences in mouse buttons, rotate, zoom and pan.
export type TrackballControlsProps = ReactThreeFiber.Overwrite<
    ReactThreeFiber.Object3DNode<
        TrackballControlsImplNew | TrackballControlsImplOriginal,
        typeof TrackballControlsImplNew | typeof TrackballControlsImplOriginal
    >,
    {
        use3ShapeViewerControls?: boolean;
        enableIdenticalPanningBehaviour?: boolean;
        target?: ReactThreeFiber.Vector3;
        camera?: THREE.Camera;
        domElement?: HTMLElement;
        regress?: boolean;
        makeDefault?: boolean;
        onChange?: (e?: THREE.Event) => void;
        onStart?: (e?: THREE.Event) => void;
        onEnd?: (e?: THREE.Event) => void;
    }
>;

export const TrackballControls = React.forwardRef<ICameraControls, TrackballControlsProps>(
    (
        { use3ShapeViewerControls, makeDefault, camera, domElement, regress, onChange, onStart, onEnd, ...restProps },
        ref,
    ) => {
        const { invalidate, camera: defaultCamera, gl, events, set, get, performance, viewport } = useThree();
        const explCamera = camera || defaultCamera;
        const explDomElement = (domElement || events.connected || gl.domElement) as HTMLElement;
        const controls = React.useMemo(
            () =>
                // the main place where we switch implementation
                use3ShapeViewerControls
                    ? new TrackballControlsImplNew(
                          explCamera as THREE.PerspectiveCamera,
                          restProps.enableIdenticalPanningBehaviour,
                      )
                    : new TrackballControlsImplOriginal(explCamera as THREE.PerspectiveCamera),
            [explCamera, use3ShapeViewerControls, restProps.enableIdenticalPanningBehaviour],
        );

        useFrame(() => {
            if (controls.enabled) {
                controls.update();
            }
        }, -1);

        React.useEffect(() => {
            controls.connect(explDomElement);
            return () => void controls.dispose();
        }, [explDomElement, regress, controls, invalidate]);

        React.useEffect(() => {
            const callback = (e: THREE.Event) => {
                invalidate();
                if (regress) {
                    performance.regress();
                }
                if (onChange) {
                    onChange(e);
                }
            };
            controls.addEventListener('change', callback);
            if (onStart) {
                controls.addEventListener('start', onStart);
            }
            if (onEnd) {
                controls.addEventListener('end', onEnd);
            }
            return () => {
                if (onStart) {
                    controls.removeEventListener('start', onStart);
                }
                if (onEnd) {
                    controls.removeEventListener('end', onEnd);
                }
                controls.removeEventListener('change', callback);
            };
        }, [onChange, onStart, onEnd, controls, invalidate, performance, regress]);

        React.useEffect(() => {
            controls.handleResize();
        }, [viewport, controls]);

        React.useEffect(() => {
            if (makeDefault) {
                const old = get().controls;
                set({ controls });
                return () => set({ controls: old });
            }
        }, [makeDefault, controls, get, set]);

        return <primitive ref={ref} object={controls} {...restProps} />;
    },
);
