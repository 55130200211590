import { logger } from '../Utils/Logger';
import * as THREE from 'three';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';
import * as xpath from 'xpath';

export type MountingMatrix = {
    // Unit vector in the direction of the left side of the mouth
    x: THREE.Vector3;
    // Unit vector in the direction opposite of occlusion (i.e. up in the mouth)
    y: THREE.Vector3;
    // Unit vector in the direction of the front mouth
    z: THREE.Vector3;
    // Origin of the coordinate frame
    origin: THREE.Vector3;
};

/**
 * Extracts the mounting matrix info from the given XML
 * @param {XMLBuilder} modelingTree - NSITree node (root of DentalDesignerModellingTree.3ml)
 * @param {boolean} isDenture - is it a denture
 * @returns {MountingMatrix}
 */
export function parseMountingMatrix(modelingTree: XMLBuilder, isDenture: boolean): MountingMatrix {
    let mountingMatrix: MountingMatrix | undefined = undefined;

    const guiVersionValue = xpath.select(
        '//Property[@name="GUIVersion"]/@value',
        modelingTree.node as any,
        true,
    ) as any;
    const is2021 = /Dental System 2021/.test(guiVersionValue?.textContent ?? '');

    const occAlignment: Node = xpath.select(
        '//Feature[contains(@name,"NewScanList")]' +
            '//Feature[starts-with(@name,"PreparationScan")]' +
            '//Feature[starts-with(@name,"Scans alignment")]' +
            '//Feature[starts-with(@name,"Occlusal alignment")]',
        modelingTree.node as any,
        true,
    ) as any;

    if (occAlignment) {
        const occlusalDirection = getVector(occAlignment, 'OcclusalDirection');
        const frontDirection = getVector(occAlignment, 'FrontDirection');
        const center = getVector(occAlignment, 'Center');

        mountingMatrix = createMountingMatrix(is2021, isDenture, occlusalDirection, frontDirection, center);
    }

    if (!mountingMatrix) {
        throw new Error('Encountered unknown error while reading 3ml file');
    }

    return mountingMatrix;
}

function createMountingMatrix(
    is2021: boolean,
    isDenture: boolean,
    occlusalDirection: THREE.Vector3,
    frontDirection: THREE.Vector3,
    center: THREE.Vector3,
): MountingMatrix {
    // Compute the basis vectors of a right-handed coordinate system
    // applied to the mouth as follows
    //
    // 1.  From the patient's point of view: Y = occlusal direction = up
    // 2.  If we choose +Z to be forward, the X = Y.Cross(Z) or left

    // x = left
    // y = superior
    // z = anterior
    const y = is2021 ? occlusalDirection.clone().normalize() : occlusalDirection.clone().normalize().negate();

    if (isDenture) {
        // if it is a denture the defining y vector is negated before constructing the x-axis vector and the mounting matrix
        y.negate();
    }

    let z = frontDirection.clone().normalize();
    const x = new THREE.Vector3().crossVectors(y, z);

    // Let's not just trust that these vectors are orthogonal!
    if (Math.abs(x.angleTo(y) - Math.PI / 2) > 0.000175) {
        // we know x is perpendicular to y and z because we created x
        // so let's correct Z, as the forward axis is slightly less important
        // than the occlusal
        z = x.clone().cross(y);
    }
    return { x, y, z, origin: center };
}

export function tryParseMountingMatrix(
    modelingTree: XMLBuilder,
    isDenture: boolean = false,
): MountingMatrix | undefined {
    try {
        return parseMountingMatrix(modelingTree, isDenture);
    } catch (e: any) {
        logger.warn('Encountered error while reading 3ml file', e);
        return undefined;
    }
}

function getVector(parent: Node, name: string): THREE.Vector3 {
    const xmlVector: any = xpath.select(`Vector[contains(@name,"${name}")]`, parent, true) as any;
    if (!xmlVector) {
        throw new Error(`Could not find vector ${name}`);
    }

    try {
        const x = parseFloat(xmlVector.attributes.find((a: any) => a.name === 'x').value);
        const y = parseFloat(xmlVector.attributes.find((a: any) => a.name === 'y').value);
        const z = parseFloat(xmlVector.attributes.find((a: any) => a.name === 'z').value);
        return new THREE.Vector3(x, y, z);
    } catch {
        throw new Error(`Failed to parse vector name=${name}`);
    }
}
