import type { ItemAppearance, ModelAppearance } from './ModelAppearanceTypes';
import type React from 'react';

function updateItemAtIndex<T>(arr: T[], index: number, updateFn: (s: T) => T) {
    return arr.map((item, i) => (i === index ? updateFn(item) : item));
}

export function scanItemAppearanceSetter(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
    index: number,
) {
    return (update: (current: ItemAppearance) => ItemAppearance) => {
        setAppearance(state => ({
            ...state,
            scans: updateItemAtIndex(state.scans, index, scan => ({
                ...scan,
                appearance: update(scan.appearance),
            })),
        }));
    };
}

export function printedModelItemAppearanceSetter(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
    index: number,
) {
    return (update: (current: ItemAppearance) => ItemAppearance) => {
        setAppearance(state => ({
            ...state,
            printedModels: updateItemAtIndex(state.printedModels, index, model => ({
                ...model,
                appearance: update(model.appearance),
            })),
        }));
    };
}

export function pastRestorativeAppearanceSetter(
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
    index: number,
) {
    return (update: (current: ItemAppearance) => ItemAppearance) => {
        setAppearance(state => {
            const items = state.pastRestoratives[state.restorativeView];
            return {
                ...state,
                pastRestoratives: {
                    ...state.pastRestoratives,
                    [state.restorativeView]: updateItemAtIndex(items, index, item => ({
                        ...item,
                        appearance: update(item.appearance),
                    })),
                },
            };
        });
    };
}
