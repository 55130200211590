import { boxPoints } from '../Box3.generators';
import { THREE_CACHE } from '../ThreeObjectCache';
import { intersectsSphere } from '../Triangle.util';
import { CastMode, type ShapeCaster } from './types';
import * as THREE from 'three';
import { CONTAINED, INTERSECTED, NOT_INTERSECTED, type MeshBVH, type ShapecastIntersection } from 'three-mesh-bvh';

function boxContainedIn(box: THREE.Box3, sphere: THREE.Sphere) {
    return THREE_CACHE.autoAcquire('vector3')(storage => {
        for (const pt of boxPoints(box, storage)) {
            if (!sphere.containsPoint(pt)) {
                return false;
            }
        }
        return true;
    });
}

/**
 * Performs a BVH shapecast to find all facets that intersect a sphere.
 */
export class SphereCaster implements ShapeCaster {
    public readonly sphere: THREE.Sphere = new THREE.Sphere();

    /** Public facing interface methods */
    public readonly intersectsBounds: (
        box: THREE.Box3,
        isLeaf: boolean,
        score: number | undefined,
        depth: number,
        nodeIndex: number,
    ) => ShapecastIntersection;

    public readonly intersectsTriangle: (
        triangle: THREE.Triangle,
        triangleIndex: number,
        contained: boolean,
        depth: number,
    ) => boolean;

    private facetIndices: Set<number> = new Set();
    private mode: CastMode;

    constructor(point: THREE.Vector3, radius: number) {
        this.sphere.set(point, radius);
        // Bind these to the private implementations so we can just pass `this` to MeshBVH.shapecast
        this.intersectsBounds = this.intersectsBoundsImpl.bind(this);
        this.intersectsTriangle = this.intersectsTriangleImpl.bind(this);
    }

    get intersectingFacets() {
        return Array.from(this.facetIndices).sort((a, b) => a - b);
    }

    clear() {
        this.facetIndices.clear();
    }

    castAny(bvh: MeshBVH): boolean {
        this.mode = CastMode.FindAny;
        this.clear();
        return bvh.shapecast(this);
    }

    castAll(bvh: MeshBVH): number[] {
        this.mode = CastMode.FindAll;
        this.clear();
        bvh.shapecast(this);
        return this.intersectingFacets;
    }

    private intersectsBoundsImpl(box: THREE.Box3): ShapecastIntersection {
        if (!box.intersectsSphere(this.sphere)) {
            return NOT_INTERSECTED;
        }

        if (boxContainedIn(box, this.sphere)) {
            return CONTAINED;
        }

        return INTERSECTED;
    }

    private intersectsTriangleImpl(triangle: THREE.Triangle, triangleIndex: number, contained: boolean): boolean {
        if (contained || intersectsSphere(triangle, this.sphere)) {
            if (this.mode === CastMode.FindAny) {
                return true;
            } else {
                this.facetIndices.add(triangleIndex);
            }
        }

        return false;
    }
}
