import { HIGHLIGHT_TOOTH_COLOR, RESTORATIVE_MESH_SHININESS } from '../defaultModelColors';
import type { CommonMaterialProps } from './Types';
import * as THREE from 'three';

// Creates a material suitable for use on models of restoratives
export function createStudioRestorativeMaterial(props: CommonMaterialProps = {}): THREE.MeshPhongMaterial {
    return new THREE.MeshPhongMaterial({
        color: props.color ?? HIGHLIGHT_TOOTH_COLOR,
        emissive: new THREE.Color(HIGHLIGHT_TOOTH_COLOR),
        emissiveIntensity: 0.1,
        vertexColors: false,
        transparent: props.show_transparent ?? false,
        opacity: props.opacity ?? 1.0,
        wireframe: props.wireframe ?? false,
        depthWrite: props.depthWrite ?? true,
        wireframeLinewidth: props.wireframeLinewidth ?? 3.0,
        specular: new THREE.Color(0x111111),
        polygonOffset: false,
        shininess: RESTORATIVE_MESH_SHININESS,
        side: THREE.DoubleSide,
    });
}
