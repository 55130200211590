import { LoaderIcon } from './LoaderIcon';
import type { LabsGqlOneTimeCodeLoginV2MutationVariables } from '@orthly/graphql-operations';
import { SimpleCodeInput } from '@orthly/ui';
import type { Theme } from '@orthly/ui-primitives';
import { Button, FlossPalette, stylesFactory, Text, useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory((theme: Theme) => ({
    sendNewCodeButton: {
        backgroundColor: 'none',
        marginTop: 8,
        [theme.breakpoints.down('sm')]: {
            backgroundColor: FlossPalette.WHITE,
            marginTop: 24,
        },
    },
}));

interface VerifyCodeFormProps {
    onSendNewCodeAction: () => void;
    onLoginAction: ({ mobile_phone_number, code }: LabsGqlOneTimeCodeLoginV2MutationVariables) => void;
    setCode: (value: string) => void;
    loading: boolean;
    phoneNumber: string;
    code: string;
    error?: string;
    style?: React.CSSProperties;
}

export const VerifyCodeForm: React.FC<VerifyCodeFormProps> = props => {
    const { onSendNewCodeAction, onLoginAction, setCode, loading, phoneNumber, code, error, style } = props;
    const classes = useStyles();
    const isMobile = useScreenIsMobile();
    const [hideError, setHideError] = React.useState(true);
    const [newCodeSent, setNewCodeSent] = React.useState(false);

    const onSendNewCodeClick = () => {
        onSendNewCodeAction();
        setNewCodeSent(true);
        setHideError(true);
    };

    const onChange = (code: string) => {
        setCode(code);
        setNewCodeSent(false);
    };

    const onCompletion = (code: string) => {
        onLoginAction({ code, mobile_phone_number: phoneNumber });
        setHideError(false);
    };

    return (
        <Grid item container style={{ alignItems: 'baseline', ...style }} data-test={'verify-code'}>
            <SimpleCodeInput
                value={code}
                length={6}
                error={!!error && code.length === 0 && !hideError}
                flossInputConfig={{ backgroundColor: 'white', bordered: true }}
                onChange={onChange}
                onCompletion={onCompletion}
            />
            {loading ? (
                <LoaderIcon style={{ height: 16, margin: isMobile ? '16px auto 0 auto' : '16px 0 0 0' }} />
            ) : (
                <Button
                    variant={'ghost'}
                    className={classes.sendNewCodeButton}
                    fullWidth={isMobile}
                    onClick={onSendNewCodeClick}
                    disabled={newCodeSent}
                >
                    {newCodeSent ? 'New code sent!' : 'Send a new code'}
                </Button>
            )}
            {error && (
                <Grid container>
                    <Text variant={'body1'} color={'ATTENTION'} style={{ marginTop: 16 }}>
                        {error}
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};
