import type { AlignerTrackerProps, DenturesTrackerProps, PartialsTrackerProps } from './OrderListItemTracker.types';
import type {
    LabsGqlOrderTrackerPreviewFragment,
    LabsGqlWorkflowTaskAssigneeFragment,
    LabsGqlWorkflowTaskCloseoutFragment,
    LabsGqlWorkflowTaskPreview_WorkflowTaskGeneric_Fragment,
} from '@orthly/graphql-operations';
import {
    LabsGqlLabOrderStatus,
    LabsGqlWorkflowTaskType,
    LabsGqlDenturesSingleFulfillmentType,
} from '@orthly/graphql-schema';
import type { LabsGqlWorkflowTaskGeneric } from '@orthly/graphql-schema';
import { ShippingUtils } from '@orthly/shared-types';
import moment from 'moment';

const TreatmentPlanSteps: LabsGqlWorkflowTaskType[] = [
    LabsGqlWorkflowTaskType.SetTreatmentPlanRequestUrl,
    LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest,
    LabsGqlWorkflowTaskType.FinalizeTreatmentPlanRequest,
    LabsGqlWorkflowTaskType.ApproveAlignerFabrication,
    LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment,
];

export class OrderListItemTrackerUtils {
    static formatCloseout(closeout: LabsGqlWorkflowTaskCloseoutFragment): string {
        const date = OrderListItemTrackerUtils.formatDate(closeout.closed_at);
        if (closeout.actor_id === 'TimedOutPrepTasksWorker' || closeout.actor_id === 'TimedOutAutomateTasksWorker') {
            return `Timed out, ${date}`;
        } else if (!closeout.actor_staff) {
            return date;
        }
        return `${closeout.actor_staff.user.first_name} ${closeout.actor_staff.user.last_name}, ${date}`;
    }

    static formatAssignee(assignee: LabsGqlWorkflowTaskAssigneeFragment | null): string {
        if (!assignee) {
            return `Unassigned`;
        }
        return `${assignee.assigned_user.first_name} ${assignee.assigned_user.last_name}`;
    }

    static formatDate(date: Date | string): string {
        const placed_on = moment(date);
        const now = moment();
        const day_difference = now.diff(placed_on, `days`);
        const hour_difference = now.diff(placed_on, `hours`);
        if (day_difference === 0) {
            if (hour_difference === 0) {
                return `Just now`;
            }
            return `${hour_difference}h ago`;
        }
        if (day_difference === 1) {
            return `1 day ago`;
        }
        return `${day_difference} days ago`;
    }

    static isDesignTaskForInternal(task: LabsGqlWorkflowTaskType | null | undefined): boolean {
        if (!task) {
            return false;
        }

        switch (task) {
            case LabsGqlWorkflowTaskType.ScanReview:
            case LabsGqlWorkflowTaskType.DesignPrep:
            case LabsGqlWorkflowTaskType.InternalDesign:
            case LabsGqlWorkflowTaskType.DesignReview:
            case LabsGqlWorkflowTaskType.WaxupReview:
            case LabsGqlWorkflowTaskType.ExternalDesignVerification:
            case LabsGqlWorkflowTaskType.AutomateDesign:
            case LabsGqlWorkflowTaskType.AutomateReview:
            case LabsGqlWorkflowTaskType.ModelDesign:
                return true;
            default:
                return false;
        }
    }

    static showOrderTrackerIssue(
        order: Pick<
            LabsGqlOrderTrackerPreviewFragment,
            'status' | 'delivery_date' | 'fulfillment_workflow' | 'ship_status'
        >,
    ): boolean {
        const skipStatuses = [LabsGqlLabOrderStatus.NeedsRefabrication, LabsGqlLabOrderStatus.Cancelled];
        if (order.delivery_date || skipStatuses.includes(order.status) || ShippingUtils.isReturned(order.ship_status)) {
            return false;
        }
        if (
            (order.status === LabsGqlLabOrderStatus.OnHold || order.status === LabsGqlLabOrderStatus.NeedsReview) &&
            !OrderListItemTrackerUtils.isDesignTaskForInternal(order.fulfillment_workflow.active_task?.type)
        ) {
            return true;
        }
        return false;
    }

    static getAlignerProps(
        order: Pick<LabsGqlOrderTrackerPreviewFragment, 'aligner_case' | 'fulfillment_workflow'>,
    ): AlignerTrackerProps | null {
        if (!order.aligner_case) {
            return null;
        }
        const activeTask = order.fulfillment_workflow.active_task;
        const completedTasks = order.fulfillment_workflow.closed_tasks.map(t => t.type);
        const completedTask = order.fulfillment_workflow.closed_tasks.find(
            (t): t is LabsGqlWorkflowTaskPreview_WorkflowTaskGeneric_Fragment =>
                t.type === LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment,
        ) as LabsGqlWorkflowTaskGeneric | undefined;
        const isInTreatmentPlan = activeTask && TreatmentPlanSteps.includes(activeTask.type);
        return {
            new_aligner_plan:
                order.fulfillment_workflow.active_task?.type === LabsGqlWorkflowTaskType.CreateTreatmentPlanRequest ||
                order.fulfillment_workflow.active_task?.type === LabsGqlWorkflowTaskType.ConvertTreatmentPlanRequest,
            treatment_plan_active: isInTreatmentPlan ?? false,
            treatment_plan_complete: completedTasks.includes(LabsGqlWorkflowTaskType.CreateExternalAlignerFulfillment),
            treatment_plan_ready_for_approval: activeTask?.type === LabsGqlWorkflowTaskType.ApproveAlignerFabrication,
            treatment_plan_completed_date: completedTask?.created_at,
        };
    }

    static getDenturesProps(
        order: Pick<LabsGqlOrderTrackerPreviewFragment, 'dentures_fulfillment'>,
    ): DenturesTrackerProps | null {
        if (!order.dentures_fulfillment) {
            return null;
        }
        const { current, history } = order.dentures_fulfillment;
        const completedFulfillments = history.map(h => h.type);
        return {
            current_fulfillment_type: current.type,
            wax_rim_completed: completedFulfillments.includes(LabsGqlDenturesSingleFulfillmentType.WaxRim),
        };
    }

    static getPartialsProps(
        order: Pick<LabsGqlOrderTrackerPreviewFragment, 'partials_fulfillment'>,
    ): PartialsTrackerProps | null {
        if (!order.partials_fulfillment) {
            return null;
        }
        const { current } = order.partials_fulfillment;
        return {
            current_fulfillment_type: current.type,
        };
    }
}
