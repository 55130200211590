import React from 'react';

type Number2 = [number, number] | null;

type DragHeaderType = React.FC<{
    dragTargetRef: React.MutableRefObject<HTMLDivElement | null>;
    children?: React.ReactNode;
    [x: string]: any;
}>;

const MARGINS: [number, number, number, number] = [10, 40, 65, 10];

/**
 * Allows to move html block element @param dragTargetRef by
 * this header.
 */
export const DragHeader: DragHeaderType = ({ dragTargetRef, children, ...props }) => {
    const dragHeaderRef = React.useRef<HTMLDivElement | null>(null);

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        const anchor = dragHeaderRef.current;
        const [marginTop, marginRight, marginBottom, marginLeft] = MARGINS;

        // Register three pointer events
        // * down - save x,y pointer down position (pointerPosition)
        //          save top, left position of drag target element (targetPosition)

        // * move - if tool is active ( offsets are not null )
        //          calculates pointer dx, dy offset and apply it to
        //          initial position of target element

        // * up   - reset (set offsets to null)

        let pointerPosition: Number2 = null;
        let targetPosition: Number2 = null;

        const down = (evt: MouseEvent) => {
            if (dragTargetRef.current) {
                pointerPosition = [evt.clientX, evt.clientY];

                const dtx = dragTargetRef.current?.getClientRects()?.[0]?.x;
                const dty = dragTargetRef.current?.getClientRects()?.[0]?.y;

                targetPosition = dtx !== undefined && dty !== undefined ? [dtx, dty] : null;
            }
        };

        const move = (evt: MouseEvent) => {
            if (targetPosition && pointerPosition && dragTargetRef.current) {
                targetPosition = [pointerPosition[0] - evt.clientX, pointerPosition[1] - evt.clientY];
                pointerPosition = [evt.clientX, evt.clientY];
                let x = dragTargetRef.current.offsetLeft - targetPosition[0];
                let y = dragTargetRef.current.offsetTop - targetPosition[1];

                const width = dragTargetRef.current.getClientRects()?.[0]?.width || 500;
                const height = dragTargetRef.current.getClientRects()?.[0]?.height || 500;

                // keep target element within screen borders
                const parentWidth = dragTargetRef.current.parentElement?.getBoundingClientRect().width || 1920;
                const parentHeight = dragTargetRef.current.parentElement?.getBoundingClientRect().height || 1080;

                const maxX = parentWidth - marginRight;
                const maxY = parentHeight - marginBottom;

                if (x < marginLeft) {
                    x = marginLeft;
                }
                if (y < marginTop) {
                    y = marginTop;
                }

                if (x + width > maxX) {
                    x = maxX - width;
                }
                if (y + height > maxY) {
                    y = maxY - height;
                }

                dragTargetRef.current.style.left = `${x}px`;
                dragTargetRef.current.style.top = `${y}px`;
            }
        };

        const up = () => {
            targetPosition = null;
            pointerPosition = null;
        };

        anchor?.addEventListener('pointerdown', down);
        document.addEventListener('pointermove', move);
        document.addEventListener('pointerup', up);

        return () => {
            anchor?.removeEventListener('pointerdown', down);
            document.removeEventListener('pointermove', move);
            document.removeEventListener('pointerup', up);
        };
    }, [dragHeaderRef, dragTargetRef]);

    return (
        <div ref={dragHeaderRef} {...props}>
            {children}
        </div>
    );
};
