import { MESH_DEFAULT_POSITION, MESH_DEFAULT_ROTATION, MESH_DEFAULT_SCALE } from './ModelMeshes';
import type { Point3DArray } from './ModelViewerTHREETypes';
import React from 'react';
import * as THREE from 'three';
import type { LineMaterial } from 'three-stdlib';

export type InsertionAxisMeshProps = {
    visible: boolean;
    highlighted: boolean;
    position: THREE.Vector3;
    insertionAxis: Point3DArray;
};

export const InsertionAxisMesh: React.VFC<InsertionAxisMeshProps> = props => {
    const { visible, highlighted, insertionAxis, position } = props;

    const arrowHelperRef = React.useRef<THREE.ArrowHelper>();
    React.useEffect(() => {
        const arrowHelper = arrowHelperRef.current;
        if (arrowHelper) {
            const material = arrowHelper.line.material as LineMaterial;
            material.linewidth = highlighted ? 2.5 : 0.5;
        }
    }, [highlighted]);

    const insVec = new THREE.Vector3(insertionAxis[0], insertionAxis[1], insertionAxis[2]);
    insVec.normalize();

    return (
        <mesh
            visible={visible}
            scale={MESH_DEFAULT_SCALE.clone()}
            rotation={MESH_DEFAULT_ROTATION.clone()}
            position={MESH_DEFAULT_POSITION.clone()}
            renderOrder={1}
        >
            <arrowHelper args={[insVec, position, 30, '#6dadc3', 5]} ref={arrowHelperRef} />
        </mesh>
    );
};
