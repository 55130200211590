import { CameraIcon, IconToggleButton } from '@orthly/ui';
import {
    FlossPalette,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ChevronLeft,
    ChevronRight,
} from '@orthly/ui-primitives';
import type { RootState as ThreeJSRootState } from '@react-three/fiber';
import { useThree } from '@react-three/fiber';
import React from 'react';

/**
 * Used to access the @react-three/fiber state from a parent component of the Canvas. This is required, because
 * `useThree` is only usable from children of the Canvas (it uses context).
 *
 * !IMPORTANT! This must be rendered as a child of the @react-three/fiber <Canvas> component
 *
 * @example ```tsx
 * const MyCanvas: React.VFC = () => {
 *     const threeRef = React.useRef<ThreeJSRootState["gl"] | null>(null);
 *     return (
 *         <div>
 *             <Canvas>
 *                 <UseThreeRef stateRef ={threeRef} />
 *             </Canvas>
 *             <Button onClick={() => {
 *                 threeJS?.gl.domElement.toDataURL()
 *             }}>Click me</Button>
 *         </div>
 *     )
 * }
 * ```
 * @param {UseThreeRefProps<T>} props
 * @return {null}
 */

export interface UseThreeRefProps<T = ThreeJSRootState> {
    selector?: (state: ThreeJSRootState) => T;
    stateRef: React.MutableRefObject<T | null>;
}

export function UseThreeRef<T = ThreeJSRootState>(props: UseThreeRefProps<T>): null {
    const { stateRef, selector } = props;
    const state = useThree<T>(selector);
    React.useEffect(() => {
        stateRef.current = state;
    }, [state, stateRef]);
    return null;
}
// Use this component for testing and debugging only.
// as it will cause re-renders on state change
// useful if trying to detect changes in ThreeJSRootState
export interface UseThreeStateProps<T = ThreeJSRootState> {
    selector?: (state: ThreeJSRootState) => T;
    setState: React.Dispatch<React.SetStateAction<T | null>>;
}
export function UseThreeState<T = ThreeJSRootState>(props: UseThreeStateProps<T>): null {
    const { setState, selector } = props;
    const state = useThree<T>(selector);
    React.useEffect(() => {
        setState(state);
    }, [state, setState]);
    return null;
}

export interface CropInsets {
    top: number;
    left: number;
    bottom: number;
    right: number;
}

async function snapshot(canvas: HTMLCanvasElement): Promise<Blob | null> {
    return new Promise(resolve => {
        canvas.toBlob(resolve);
    });
}

/** Returns the contents of the `source` canvas as an image blob, cropped to the given frame. */
async function croppedSnapshot(source: HTMLCanvasElement, insets: CropInsets): Promise<Blob | null> {
    const { top, left, bottom, right } = insets;

    const canvas = document.createElement('canvas');
    canvas.width = source.width - left - right;
    canvas.height = source.height - top - bottom;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return null;
    } else {
        ctx.drawImage(source, -left, -top);
        return snapshot(canvas);
    }
}

/**
 * A purpose built component to render inside of <Canvas/> that
 * gives reference to toDataUrl()
 */
export type TakeSnapshotRef = React.MutableRefObject<((cropInsets?: CropInsets) => Promise<Blob | null>) | undefined>;

export const Snapshotter: React.VFC<{ takeSnapshotRef: TakeSnapshotRef }> = ({ takeSnapshotRef }) => {
    const glElement = useThree(s => s.gl.domElement);
    React.useEffect(() => {
        takeSnapshotRef.current = async cropInsets =>
            cropInsets ? croppedSnapshot(glElement, cropInsets) : snapshot(glElement);
    }, [takeSnapshotRef, glElement]);
    return null;
};

/**
 * A minimal UI for putting a screenshot  button into ModelViewer UI
 */
export const ScreenShotAndViewButton: React.FC<{ takeScreenShot: () => void }> = props => {
    const { takeScreenShot } = props;
    const [isScreenShotMenuVisible, setScreenShotMenuVisible] = React.useState(false);
    const toggleVisible = React.useCallback(
        () => setScreenShotMenuVisible(visible => !visible),
        [setScreenShotMenuVisible],
    );
    return (
        <Grid
            item
            style={{
                position: 'absolute',
                zIndex: 100,
                top: 'auto',
                bottom: '16px',
                alignSelf: 'center',
            }}
        >
            {isScreenShotMenuVisible ? (
                <Card raised={true}>
                    <IconToggleButton active={false} onClick={toggleVisible} tooltip={'Hide Screen Shot Tools'}>
                        <ChevronLeft />
                    </IconToggleButton>
                    <IconToggleButton tooltip={'Screen Shot'} active={false} onClick={takeScreenShot}>
                        <CameraIcon htmlColor={FlossPalette.GRAY} />
                    </IconToggleButton>
                </Card>
            ) : (
                <IconToggleButton active={false} onClick={toggleVisible} tooltip={'Show Screen Shot Tools'}>
                    <ChevronRight />
                </IconToggleButton>
            )}
        </Grid>
    );
};

export const SimpleScreenShotCard: React.FC<{ imgData: string; handleClose: () => void }> = props => {
    const { imgData, handleClose } = props;

    return (
        <>
            <Dialog fullWidth={true} maxWidth={false} open={true} onClose={() => {}}>
                <DialogTitle>Simple Screen Shot Viewer</DialogTitle>
                <DialogContent>
                    <img src={imgData} alt={'No ScreenShot'} />
                </DialogContent>
                <DialogActions>
                    <Button variant={'contained'} onClick={handleClose}>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
