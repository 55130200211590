import { getGuidedPresetQuestionTitle, makeTitleCase, useGetSelectedWaxup } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { LabsGqlDesignOrderDoctorReviewStatus, LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { Text, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    presetInfoContainer: {
        paddingTop: 15,
        marginBottom: 10,
    },
    presetControlsContainer: {
        marginBottom: 20,
        paddingLeft: 24,
        paddingRight: 20,
    },
    rejectionNoteDisplay: {
        paddingRight: 20,
        justifyContent: 'flex-end',
        paddingLeft: 24,
    },
    rejectionNoteContainer: {
        flexDirection: 'column',
        alignContent: 'flex-start',
    },
}));

export const HistoricalWaxupPresetInfo: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { selectedTab } = useGuidedWaxupContext();
    const presetQuestionTitle = getGuidedPresetQuestionTitle(selectedTab);
    const selectedWaxup = useGetSelectedWaxup();
    const selectedWaxupIsRejection = selectedWaxup?.status === LabsGqlDesignOrderDoctorReviewStatus.Rejected;
    const selectedRejectionPreset = selectedWaxupIsRejection
        ? selectedWaxup?.annotations?.find(preset => preset.preset_type === selectedTab)
        : undefined;
    const presetStatus = selectedWaxupIsRejection
        ? selectedRejectionPreset?.preset_status ?? LabsGqlGuidedWaxupPresetStatus.Skipped
        : LabsGqlGuidedWaxupPresetStatus.Approved;

    return (
        <Grid item container direction={isMobile ? 'column' : 'row'} className={classes.presetInfoContainer}>
            <Grid
                item
                container
                direction={'column'}
                justifyContent={'flex-start'}
                className={classes.presetControlsContainer}
                xs={12}
                md={9}
                key={selectedTab}
            >
                <Text variant={isMobile ? 'body2' : 'body1'} medium>
                    {presetQuestionTitle}
                </Text>
                <Text variant={isMobile ? 'body1' : 'h5'} color={'GRAY'}>
                    {makeTitleCase(presetStatus)}
                </Text>
            </Grid>
            {selectedRejectionPreset?.notes && (
                <Grid item xs={12} md={3} className={classes.rejectionNoteDisplay}>
                    <Grid item container className={classes.rejectionNoteContainer}>
                        <Grid item>
                            <Text variant={isMobile ? 'body2' : 'body1'} medium>
                                Rejection Note
                            </Text>
                        </Grid>
                        <Grid item>
                            <Text variant={isMobile ? 'body2' : 'body1'}>{selectedRejectionPreset?.notes}</Text>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};
