import type { MaterialNode } from '@react-three/fiber';
import _ from 'lodash';
import type { Texture, ShaderMaterialParameters } from 'three';
import { ShaderMaterial } from 'three';

export interface DandyShaderMaterialParameters extends ShaderMaterialParameters {
    map?: Texture;
}

// extend the default threejs ShaderMaterial to include the map property
// we need to set this property explicitly in the JSX, so that three.js can use it!
// otherwise it will be read as null on rendering time
export class DandyShaderMaterial extends ShaderMaterial {
    map?: Texture;

    constructor(parameters: DandyShaderMaterialParameters) {
        super(_.omit(parameters, 'map'));
        this.map = parameters.map;
    }
}

declare global {
    namespace JSX {
        interface IntrinsicElements {
            dandyShaderMaterial: MaterialNode<DandyShaderMaterial, [DandyShaderMaterialParameters]>;
        }
    }
}
