/* eslint-disable max-lines */
import z from 'zod';

// So what the hell is this?
// The easiest way to handle the 3shape xml -> json madness was with a parser generator.
// Every Materials file is basically the same -- it's an array of Objects, which have a section, and then a list of Objects within them.
// Those innermost Objects are all `BaseEntrySchema` plus maybe some other properties.
// Most every innermost Object will have a `Property` attribute with `name="id"`.
// these are the primary keys we extract.

export const BaseEntrySchema = z.object({
    Property: z.array(
        z.object({
            '@_name': z.string(),
            '@_value': z.string(),
        }),
    ),
    String: z
        .array(
            z.object({
                '@_name': z.string(),
                '@_value': z.string(),
            }),
        )
        .optional(),
});

const ListObjectDefinition = (nested?: z.ZodObject<any>) => {
    return {
        Object: z.array(nested ? z.union([BaseEntrySchema, nested]) : BaseEntrySchema).optional(),
    };
};

const OrderListSchema = z.object({
    '@_name': z.literal('OrderList'),
    List: z.object(ListObjectDefinition()),
});

const ModelJobListSchema = z.object({
    '@_name': z.literal('ModelJobList'),
    List: z.object(ListObjectDefinition()),
});

const ModelElementListSchema = z.object({
    '@_name': z.literal('ModelElementList'),
    List: z.object(ListObjectDefinition()),
});

const ToothElementListSchema = z.object({
    '@_name': z.literal('ToothElementList'),
    List: z.object(ListObjectDefinition()),
});

const LinkListSchema = z.object({
    '@_name': z.literal('LinkList'),
    List: z.object(ListObjectDefinition()),
});

const LinkToothElementListSchema = z.object({
    '@_name': z.literal('LinkToothElementList'),
    List: z.object(ListObjectDefinition()),
});

const CountryListSchema = z.object({
    '@_name': z.literal('CountryList'),
    List: z.object(ListObjectDefinition()),
});

const MaterialListSchema = z.object({
    '@_name': z.literal('MaterialList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                    String: z
                        .array(
                            z.object({
                                '@_name': z.string(),
                                '@_value': z.string(),
                            }),
                        )
                        .optional(),
                }),
            )
            .optional(),
    }),
});

const SiteListSchema = z.object({
    '@_name': z.literal('SiteList'),
    List: z.object(ListObjectDefinition()),
});

const SitePreferencesListSchema = z.object({
    '@_name': z.literal('SitePreferencesList'),
    List: z.object(ListObjectDefinition()),
});

const SplitBridgeLinkListSchema = z.object({
    '@_name': z.literal('SplitBridgeLinkList'),
    List: z.object(ListObjectDefinition()),
});

const ManufacturingProcessListSchema = z.object({
    '@_name': z.literal('ManufacturingProcessList'),
    List: z.object(ListObjectDefinition()),
});

const ManufacturerManProcessListSchema = z.object({
    '@_name': z.literal('Manufacturer_ManProcessList'),
    List: z.object(ListObjectDefinition()),
});

const MaterialManProcessListSchema = z.object({
    '@_name': z.literal('Material_ManProcessList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const ToothElementTypeListSchema = z.object({
    '@_name': z.literal('ToothElementTypeList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const LinkTypeListSchema = z.object({
    '@_name': z.literal('LinkTypeList'),
    List: z.object(ListObjectDefinition()),
});

const OptionalComponentListSchema = z.object({
    '@_name': z.literal('OptionalComponentList'),
    List: z.object(ListObjectDefinition()),
});

const ValidationListSchema = z.object({
    '@_name': z.literal('ValidationList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                    Object: z
                        .object({
                            '@_name': z.literal('AdvancedConnectorValidationData'),
                            ConnectorValidationData: z.any(),
                        })
                        .optional(),
                }),
            )
            .optional(),
    }),
});

const CementSpaceListSchema = z.object({
    '@_name': z.literal('CementSpaceList'),
    List: z.object(ListObjectDefinition()),
});

const OverlayListSchema = z.object({
    '@_name': z.literal('OverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const CopingOverlayListSchema = z.object({
    '@_name': z.literal('CopingOverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const CrownOverlayListSchema = z.object({
    '@_name': z.literal('CrownOverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const InlayOverlayListSchema = z.object({
    '@_name': z.literal('InlayOverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const TabletopOverlayListSchema = z.object({
    '@_name': z.literal('TabletopOverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const TelescopeOverlayListSchema = z.object({
    '@_name': z.literal('TelescopeOverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const WaxupOverlayListSchema = z.object({
    '@_name': z.literal('WaxupOverlayList'),
    List: z.object({
        Object: z
            .array(
                z.object({
                    Property: z.array(
                        z.object({
                            '@_name': z.string(),
                            '@_value': z.string(),
                        }),
                    ),
                }),
            )
            .optional(),
    }),
});

const ScanListSchema = z.object({
    '@_name': z.literal('ScanList'),
    List: z.object(ListObjectDefinition()),
});

const PathListSchema = z.object({
    '@_name': z.literal('PathList'),
    List: z.object(ListObjectDefinition()),
});

const InboxDataListSchema = z.object({
    '@_name': z.literal('InboxDataList'),
    List: z.object(ListObjectDefinition()),
});

const TrackAndTraceListSchema = z.object({
    '@_name': z.literal('TrackAndTraceList'),
    List: z.object(ListObjectDefinition()),
});

const CustomDataListSchema = z.object({
    '@_name': z.literal('CustomDataList'),
    List: z.object(ListObjectDefinition()),
});

const OrderExchangeElementListSchema = z.object({
    '@_name': z.literal('OrderExchangeElementList'),
    List: z.object(ListObjectDefinition()),
});

const OrderExchangeAttachmentListSchema = z.object({
    '@_name': z.literal('OrderExchangeAttachmentList'),
    List: z.object(ListObjectDefinition()),
});

const DigitalModelElementInfoListSchema = z.object({
    '@_name': z.literal('DigitalModelElementInfoList'),
    List: z.object(ListObjectDefinition()),
});

const MarginLineListSchema = z.object({
    '@_name': z.literal('MarginLineList'),
    List: z.object(ListObjectDefinition()),
});

const OperatorListSchema = z.object({
    '@_name': z.literal('OperatorList'),
    List: z.object(ListObjectDefinition()),
});

const ColorListSchema = z.object({
    '@_name': z.literal('ColorList'),
    List: z.object(ListObjectDefinition()),
});

const ColorGroupListSchema = z.object({
    '@_name': z.literal('ColorGroupList'),
    List: z.object(ListObjectDefinition()),
});

const ColorGroupLinkListSchema = z.object({
    '@_name': z.literal('ColorGroupLinkList'),
    List: z.object(ListObjectDefinition()),
});

const SmileLibraryListSchema = z.object({
    '@_name': z.literal('SmileLibraryList'),
    List: z.object(ListObjectDefinition()),
});

const SmileLibraryGroupListSchema = z.object({
    '@_name': z.literal('SmileLibraryGroupList'),
    List: z.object(ListObjectDefinition()),
});

const SmileLibraryGroupLinkListSchema = z.object({
    '@_name': z.literal('SmileLibraryGroupLinkList'),
    List: z.object(ListObjectDefinition()),
});

const SmileLibraryProviderListSchema = z.object({
    '@_name': z.literal('SmileLibraryProviderList'),
    List: z.object(ListObjectDefinition()),
});

const SmileLibraryProviderLinkListSchema = z.object({
    '@_name': z.literal('SmileLibraryProviderLinkList'),
    List: z.object(ListObjectDefinition()),
});

const FDSmileLibraryLinkListSchema = z.object({
    '@_name': z.literal('FDSmileLibraryLinkList'),
    List: z.object(ListObjectDefinition()),
});

const AttachmentListSchema = z.object({
    '@_name': z.literal('AttachmentList'),
    List: z.object(ListObjectDefinition()),
});

const AttachmentGroupListSchema = z.object({
    '@_name': z.literal('AttachmentGroupList'),
    List: z.object(ListObjectDefinition()),
});

const AttachmentAttachmentGroupListSchema = z.object({
    '@_name': z.literal('Attachment_AttachmentGroupList'),
    List: z.object(ListObjectDefinition()),
});

const AttachmentMaterialListSchema = z.object({
    '@_name': z.literal('Attachment_MaterialList'),
    List: z.object(ListObjectDefinition()),
});

const ManufacturingBlankListSchema = z.object({
    '@_name': z.literal('ManufacturingBlankList'),
    List: z.object(ListObjectDefinition()),
});

const ManufacturingBlankMaterialLinkListSchema = z.object({
    '@_name': z.literal('ManufacturingBlank_Material_LinkList'),
    List: z.object(ListObjectDefinition()),
});

const PressMultiSprueListSchema = z.object({
    '@_name': z.literal('PressMultiSprueList'),
    List: z.object(ListObjectDefinition()),
});

const PressMultiSprueMaterialLinkListSchema = z.object({
    '@_name': z.literal('PressMultiSprue_Material_LinkList'),
    List: z.object(ListObjectDefinition()),
});

const PnCSystemListSchema = z.object({
    '@_name': z.literal('PnCSystemList'),
    List: z.object(ListObjectDefinition()),
});

const PnCTypeListSchema = z.object({
    '@_name': z.literal('PnCTypeList'),
    List: z.object(ListObjectDefinition()),
});

const BarGroupListSchema = z.object({
    '@_name': z.literal('BarGroupList'),
    List: z.object(ListObjectDefinition()),
});

const BarBarGroupListSchema = z.object({
    '@_name': z.literal('Bar_BarGroupList'),
    List: z.object(ListObjectDefinition()),
});

const Order2DImageOverlayListSchema = z.object({
    '@_name': z.literal('Order2DImageOverlayList'),
    List: z.object(ListObjectDefinition()),
});

const Material2DImageOverlayListSchema = z.object({
    '@_name': z.literal('Material2DImageOverlayList'),
    List: z.object(ListObjectDefinition()),
});

const JobTypeERPInformationListSchema = z.object({
    '@_name': z.literal('JobTypeERPInformationList'),
    List: z.object(ListObjectDefinition()),
});

const ExternalApplicationListSchema = z.object({
    '@_name': z.literal('ExternalApplicationList'),
    List: z.object(ListObjectDefinition()),
});

const MillingMachineListSchema = z.object({
    '@_name': z.literal('MillingMachineList'),
    List: z.object(ListObjectDefinition()),
});

const ImplantSystemListSchema = z.object({
    '@_name': z.literal('ImplantSystemList'),
    List: z.object(ListObjectDefinition()),
});

const ImplantSystemPartListSchema = z.object({
    '@_name': z.literal('ImplantSystemPartList'),
    List: z.object(ListObjectDefinition()),
});

const AbutmentKitListSchema = z.object({
    '@_name': z.literal('AbutmentKitList'),
    List: z.object(ListObjectDefinition()),
});

const ImplantSystemGroupListSchema = z.object({
    '@_name': z.literal('ImplantSystemGroupList'),
    List: z.object(ListObjectDefinition()),
});

const ImplantSystemImplantSystemGroupListSchema = z.object({
    '@_name': z.literal('ImplantSystem_ImplantSystemGroupList'),
    List: z.object(ListObjectDefinition()),
});

const AbutmentKitMaterialRelationListSchema = z.object({
    '@_name': z.literal('AbutmentKitMaterialRelationList'),
    List: z.object(ListObjectDefinition()),
});

const AbutmentKitImplantPartExtraRelationListSchema = z.object({
    '@_name': z.literal('AbutmentKitImplantPartExtraRelationList'),
    List: z.object(ListObjectDefinition()),
});

const IPGSSurgicalTrayListSchema = z.object({
    '@_name': z.literal('IPGSSurgicalTrayList'),
    List: z.object(ListObjectDefinition()),
});

const IPGSSleeveListSchema = z.object({
    '@_name': z.literal('IPGSSleeveList'),
    List: z.object(ListObjectDefinition()),
});

const IPGSSleeveGroupOffsetListSchema = z.object({
    '@_name': z.literal('IPGSSleeveGroupOffsetList'),
    List: z.object(ListObjectDefinition()),
});

const IPGSGroupOffsetListSchema = z.object({
    '@_name': z.literal('IPGSGroupOffsetList'),
    List: z.object(ListObjectDefinition()),
});

const IPGSImplantTraySleeveListSchema = z.object({
    '@_name': z.literal('IPGSImplantTraySleeveList'),
    List: z.object(ListObjectDefinition()),
});

const DentureWaxTemplateListSchema = z.object({
    '@_name': z.literal('DentureWaxTemplateList'),
    List: z.object(ListObjectDefinition()),
});

const RPDConnectorListSchema = z.object({
    '@_name': z.literal('RPDConnectorList'),
    List: z.object(ListObjectDefinition()),
});

const WaxProfileStripListSchema = z.object({
    '@_name': z.literal('WaxProfileStripList'),
    List: z.object(ListObjectDefinition()),
});

const RetentionsListSchema = z.object({
    '@_name': z.literal('RetentionsList'),
    List: z.object(ListObjectDefinition()),
});

const StippledWaxListSchema = z.object({
    '@_name': z.literal('StippledWaxList'),
    List: z.object(ListObjectDefinition()),
});

const ModelManufacturingListSchema = z.object({
    '@_name': z.literal('ModelManufacturingList'),
    List: z.object(ListObjectDefinition()),
});

const ArticulatorInterfaceListSchema = z.object({
    '@_name': z.literal('ArticulatorInterfaceList'),
    List: z.object(ListObjectDefinition()),
});

const ArticulatorListSchema = z.object({
    '@_name': z.literal('ArticulatorList'),
    List: z.object(ListObjectDefinition()),
});

const ArticulatorToolListSchema = z.object({
    '@_name': z.literal('ArticulatorToolList'),
    List: z.object(ListObjectDefinition()),
});

const CommunicateOrderListSchema = z.object({
    '@_name': z.literal('CommunicateOrderList'),
    List: z.object(ListObjectDefinition()),
});

const ArticulatorAdapterModelsListSchema = z.object({
    '@_name': z.literal('ArticulatorAdapterModelsList'),
    List: z.object(ListObjectDefinition()),
});

const ModelBaseListSchema = z.object({
    '@_name': z.literal('ModelBaseList'),
    List: z.object(ListObjectDefinition()),
});

export const MaterialsFileSchema = z.object({
    // Root
    DentalContainer: z.object({
        Object: z.object({
            '@_name': z.literal('MainObject'),
            // Array of all the items that we're actually interested in.
            Object: z.array(
                z.discriminatedUnion('@_name', [
                    OrderListSchema,
                    ModelJobListSchema,
                    ModelElementListSchema,
                    ToothElementListSchema,
                    LinkListSchema,
                    LinkToothElementListSchema,
                    SplitBridgeLinkListSchema,
                    CountryListSchema,
                    MaterialListSchema,
                    SiteListSchema,
                    SitePreferencesListSchema,
                    ManufacturingProcessListSchema,
                    ManufacturerManProcessListSchema,
                    MaterialManProcessListSchema,
                    ToothElementTypeListSchema,
                    LinkTypeListSchema,
                    OptionalComponentListSchema,
                    ValidationListSchema,
                    CementSpaceListSchema,
                    OverlayListSchema,
                    CopingOverlayListSchema,
                    CrownOverlayListSchema,
                    InlayOverlayListSchema,
                    TabletopOverlayListSchema,
                    TelescopeOverlayListSchema,
                    WaxupOverlayListSchema,
                    ScanListSchema,
                    PathListSchema,
                    InboxDataListSchema,
                    TrackAndTraceListSchema,
                    CustomDataListSchema,
                    OrderExchangeElementListSchema,
                    OrderExchangeAttachmentListSchema,
                    DigitalModelElementInfoListSchema,
                    MarginLineListSchema,
                    OperatorListSchema,
                    ColorListSchema,
                    ColorGroupListSchema,
                    ColorGroupLinkListSchema,
                    SmileLibraryListSchema,
                    SmileLibraryGroupListSchema,
                    SmileLibraryGroupLinkListSchema,
                    SmileLibraryProviderListSchema,
                    SmileLibraryProviderLinkListSchema,
                    FDSmileLibraryLinkListSchema,
                    AttachmentListSchema,
                    AttachmentGroupListSchema,
                    AttachmentAttachmentGroupListSchema,
                    AttachmentMaterialListSchema,
                    ManufacturingBlankListSchema,
                    ManufacturingBlankMaterialLinkListSchema,
                    PressMultiSprueListSchema,
                    PressMultiSprueMaterialLinkListSchema,
                    PnCSystemListSchema,
                    PnCTypeListSchema,
                    CommunicateOrderListSchema,
                    ArticulatorToolListSchema,
                    ArticulatorListSchema,
                    ArticulatorInterfaceListSchema,
                    ModelManufacturingListSchema,
                    StippledWaxListSchema,
                    RetentionsListSchema,
                    WaxProfileStripListSchema,
                    RPDConnectorListSchema,
                    DentureWaxTemplateListSchema,
                    IPGSImplantTraySleeveListSchema,
                    IPGSGroupOffsetListSchema,
                    IPGSSleeveGroupOffsetListSchema,
                    IPGSSleeveListSchema,
                    IPGSSurgicalTrayListSchema,
                    AbutmentKitImplantPartExtraRelationListSchema,
                    AbutmentKitMaterialRelationListSchema,
                    ImplantSystemImplantSystemGroupListSchema,
                    ImplantSystemGroupListSchema,
                    AbutmentKitListSchema,
                    BarGroupListSchema,
                    BarBarGroupListSchema,
                    Order2DImageOverlayListSchema,
                    Material2DImageOverlayListSchema,
                    JobTypeERPInformationListSchema,
                    ExternalApplicationListSchema,
                    MillingMachineListSchema,
                    ImplantSystemListSchema,
                    ImplantSystemPartListSchema,
                    ArticulatorAdapterModelsListSchema,
                    ModelBaseListSchema,
                ]),
            ),
        }),
    }),
});

// This is a doozy of a type
export type MaterialsFileSection = z.infer<typeof MaterialsFileSchema>['DentalContainer']['Object']['Object'][number];
