import { U32 } from './Util';

// Declare generic crc32 creation.
//
function create(poly: number, initialSeed: number = 0) {
    const lookupTable = U32.array(256);
    for (let i = 0; i < 256; i++) {
        let r = i;
        for (let j = 0; j !== 8; j++) {
            if (r & 1) {
                r = (r >>> 1) ^ poly;
            } else {
                r >>>= 1;
            }
        }
        lookupTable[i] = r;
    }

    return (dataIn: number | Buffer | Iterable<number>, chkIn: number = initialSeed) => {
        let chk = ~chkIn;
        const data = typeof dataIn === 'number' ? [dataIn] : dataIn;

        for (const byte of data) {
            chk = (chk >>> 8) ^ (lookupTable[(chk ^ byte) & 0xff] as number);
        }
        return U32(~chk);
    };
}

export const crc32 = Object.assign(create(0xedb88320), { create });
