import { LogoutButton } from '../../components/LogoutButton';
import { usePartnerUiSelector } from '../../redux/ui';
import { SignUpPageLayout } from './SignUpPageLayout';
import { SignUpAccountRoot } from './account/SignUpAccountRoot';
import { SignUpBillingRoot } from './billing/SignUpBillingRoot';
import { SignUpShippingRoot } from './shipping/SignUpShippingRoot';
import { SignUpScheduleTraining } from './training/SignUpScheduleTraining';
import { PracticeRouteWithMetadata } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import { usePracticeEmailHasNotStartedOrCompletedSignUpQuery } from '@orthly/graphql-react';
import { SessionGuard, SessionGuardUtils, useSession } from '@orthly/session-client';
import { ErrorPage } from '@orthly/ui';
import React from 'react';
import { Switch, useHistory } from 'react-router-dom';

const SignUpRoutes: React.FC = () => {
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    return (
        <SignUpPageLayout>
            <Switch>
                <PracticeRouteWithMetadata
                    path={`/${PracticeScreen.sign_up_account}`}
                    title={isNativeApp ? 'Mobile Sign Up Account' : 'Sign Up Account'}
                >
                    <SignUpAccountRoot />
                </PracticeRouteWithMetadata>
                <PracticeRouteWithMetadata
                    path={`/${PracticeScreen.sign_up_billing}`}
                    title={isNativeApp ? 'Mobile Sign Up Billing' : 'Sign Up Billing'}
                >
                    <SignUpBillingRoot />
                </PracticeRouteWithMetadata>
                <PracticeRouteWithMetadata
                    path={`/${PracticeScreen.sign_up_shipping}`}
                    title={isNativeApp ? 'Mobile Sign Up Shipping' : 'Sign Up Shipping'}
                >
                    <SignUpShippingRoot />
                </PracticeRouteWithMetadata>
                <PracticeRouteWithMetadata
                    path={`/${PracticeScreen.sign_up_training}`}
                    title={isNativeApp ? 'Mobile Sign Up Training' : 'Sign Up Training'}
                >
                    <SignUpScheduleTraining />
                </PracticeRouteWithMetadata>
            </Switch>
        </SignUpPageLayout>
    );
};

export const SignUpRoot: React.VFC = () => {
    const session = useSession();
    const history = useHistory();

    const { loading } = usePracticeEmailHasNotStartedOrCompletedSignUpQuery({
        variables: { email: session?.user.email ?? '' },
        onCompleted: data => {
            if (data.practiceEmailHasNotStartedOrCompletedSignUp) {
                history.push(`/${PracticeScreen.inbox}`);
            }
        },
    });

    if (loading) {
        return null;
    }

    return (
        <SessionGuard
            test={SessionGuardUtils.hasOrgType(['lab', 'practice'])}
            fallback={
                <ErrorPage
                    message={'Unauthorized'}
                    iconName={'PanTool'}
                    text={"You don't have permission to see this."}
                >
                    <LogoutButton />
                </ErrorPage>
            }
        >
            <SignUpRoutes />
        </SessionGuard>
    );
};
