/* eslint-disable max-lines */
// Defines different payloads for NewModelViewer
import { getHeatmapRange } from '../ModelAppearance/ModelAppearance.utils';
import type { ItemAppearance, ModelAppearance, PayloadModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { RestorativeView } from '../ModelAppearance/ModelAppearanceTypes';
import { isTransparent } from '../ModelAppearance/Transparent.util';
import type { CheckResult } from '../PortalDesignEditor/PostEditCheck.util';
import type { DandyShaderMaterialParameters } from '../PrepMaterials/dandyShaderMaterial';
import { DandyShaderMaterial } from '../PrepMaterials/dandyShaderMaterial';
import { ScanMeshShaderMaterialPrep } from '../PrepMaterials/scanMeshShaderMaterialPrep';
import { InsertionAxisMesh } from './InsertionAxisMesh';
import { MarginEditingPalette } from './Margin.util';
import type { MarginEditingStructure } from './MarginMesh';
import { MarginMesh } from './MarginMesh';
import {
    DefaultScanMeshPhysicalMaterial,
    ModelMesh,
    QCCollisionsMesh,
    QCMeshCustomMaterial,
    QC_MESHES_SHININESS,
} from './ModelMeshes';
import { getMarginAssociatedGeometryFromNewPayload } from './ModelViewer.util';
import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import type { ModelPayloadItem } from './ModelViewerTypes';
import { Jaw } from './ModelViewerTypes';
import type { ToothMarkingWithParent } from './NewModelViewer.types';
import { isPreExtractionScanItem } from './NewModelViewer.utils';
import type { ToothPrepDataMap } from './PrepDesign.hooks';
import { ToothMarkingMesh } from './ToothMarkingMesh';
import {
    CURRENT_TOOTH_COLOR,
    DEFAULT_PRE_EXTRACTION_SCAN_COLOR,
    GINGIVA_COLOR,
    HIGHLIGHT_TOOTH_COLOR,
    MODEL_GRAY_COLOR,
    PAST_TOOTH_COLOR,
    SCAN_TRIM_COLOR,
    SPLINT_COLOR,
} from './defaultModelColors';
import { StandardScanStoneMaterial, StudioRestorativeMaterial } from './materials/StudioLightSetup';
import { DesignMeshShaderMaterial } from './materials/designMeshShaderMaterial';
import { ScanMeshShaderPhysicalMaterial } from './materials/scanMeshShaderMaterial';
import { AttributeName, CadType, HeatMapType } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import type { MarginLine } from '@orthly/shared-types';
import { extend } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

extend({ DandyShaderMaterial });

interface AppearancePayloadMeshProps {
    appearanceSettings: ModelAppearance;
    marginLines?: MarginLine[];
    doctorMarginLines?: MarginLine[];
    previousMarginLines?: MarginLine[];
    toothMarkings?: ToothMarkingWithParent[];
    cameraControlsRef: MainViewCameraControlsRef;
    enableNewScanMeshMaterial?: 'design' | 'prep' | 'chairside' | boolean;
    marginLineEditing?: MarginEditingStructure;
    useShaderHeatmaps?: boolean;
    postEditCheckResult?: CheckResult;
    scanTrimmedSections?: THREE.Mesh[];
    showScansHeatmap?: boolean;
    prepData: ToothPrepDataMap;
    selectedInsertionAxis?: ToothNumber;
    enableTubeMarginLine?: boolean;
    showWireframe?: boolean;
}

/**
 * This mesh shows all the models and lines for NewModelViewer, applying appropriate appearance options.
 *
 * @component
 */
/*eslint-disable-next-line max-lines-per-function*/
export const AppearancePayloadMesh: React.FC<AppearancePayloadMeshProps> = props => {
    const {
        appearanceSettings,
        marginLines,
        doctorMarginLines,
        previousMarginLines,
        cameraControlsRef,
        enableNewScanMeshMaterial,
        marginLineEditing,
        useShaderHeatmaps,
        toothMarkings,
        postEditCheckResult,
        scanTrimmedSections,
        showScansHeatmap,
        selectedInsertionAxis,
        enableTubeMarginLine,
        prepData,
        showWireframe,
    } = props;

    const qcHeatmapMaterial = React.useMemo(
        () =>
            useShaderHeatmaps ? (
                <DesignMeshShaderMaterial
                    vertexColors={false}
                    dithering={true}
                    color={'white'}
                    side={THREE.DoubleSide}
                    wireframe={false}
                    wireframeLinewidth={3.0}
                    depthTest={true}
                    depthWrite={true}
                    transparent={false}
                    opacity={1.0}
                    shininess={QC_MESHES_SHININESS}
                    activeHeatMap={appearanceSettings.activeHeatMap}
                    heatMapRange={appearanceSettings.heatMapRange}
                    includeCurtains={appearanceSettings.showCurtainsHeatmap}
                />
            ) : (
                <QCMeshCustomMaterial wireframe={false} depthtest={true} />
            ),
        [appearanceSettings, useShaderHeatmaps],
    );

    const anatomyMaterial = React.useMemo(
        () => (
            <StudioRestorativeMaterial
                show_transparent={appearanceSettings.porcelainOpacity ? appearanceSettings.porcelainOpacity < 1 : true}
                opacity={appearanceSettings.porcelainOpacity ?? 0.5}
            />
        ),
        [appearanceSettings],
    );

    const jawsPayload = [...appearanceSettings.upperJaw, ...appearanceSettings.lowerJaw] as PayloadModelAppearance[];
    const jawMeshes = jawsPayload.map(modelWithAppearance =>
        renderPayloadItemScanMesh(
            appearanceSettings,
            modelWithAppearance.payloadModel,
            modelWithAppearance.appearance,
            useShaderHeatmaps ?? false,
            !!showScansHeatmap,
            appearanceSettings.solo,
            enableNewScanMeshMaterial,
            showWireframe ?? false,
        ),
    );

    // restoratives
    const restorativeView = appearanceSettings.restorativeView;
    const heatmapIsActive = restorativeView === RestorativeView.HeatMap;

    const restoratives = appearanceSettings.restoratives[restorativeView].map(modelWithAppearance => {
        const { payloadModel, appearance } = modelWithAppearance;
        const visible = isVisible(payloadModel, appearance, appearanceSettings.solo);

        const insertionAxis = appearance.showInsertionAxis ? payloadModel.insertionAxis : undefined;
        const insertionAxisPosition = payloadModel.model.geometry.boundingSphere?.center;
        let color = appearanceSettings.colorPastRestoratives ? CURRENT_TOOTH_COLOR : undefined;
        color = getCadItemColor(payloadModel) ?? color;

        const customMaterial =
            heatmapIsActive && canApplyHeatmap(payloadModel) ? (
                qcHeatmapMaterial
            ) : (
                <StudioRestorativeMaterial
                    show_transparent={isTransparent(appearance)}
                    opacity={appearance.opacity}
                    color={color}
                />
            );
        const matchedCurtain = appearanceSettings.curtains[modelWithAppearance.payloadModel.modelElementID ?? ''];

        return (
            <React.Fragment key={payloadModel.path}>
                <ModelMesh
                    visible={visible}
                    color={HIGHLIGHT_TOOTH_COLOR}
                    model={payloadModel.model}
                    show_transparent={isTransparent(appearance)}
                    opacity={appearance.opacity}
                    colorize={false}
                    centered={false}
                    activeHeatMap={appearanceSettings.activeHeatMap}
                    heatMapRange={appearanceSettings.heatMapRange}
                    customMaterial={customMaterial}
                    useShaderHeatmaps={heatmapIsActive && useShaderHeatmaps}
                    wireframe={showWireframe}
                />
                {insertionAxisPosition && insertionAxis && (
                    <InsertionAxisMesh
                        visible={true}
                        highlighted={appearance.showUndercutShadow}
                        insertionAxis={insertionAxis}
                        position={insertionAxisPosition}
                    />
                )}
                {appearance.showUndercutCurtains && matchedCurtain && (
                    <ModelMesh
                        color={0xff0000}
                        model={matchedCurtain.model} // curtain model
                        show_transparent={false}
                        opacity={1}
                        colorize={false}
                        centered={false}
                    />
                )}
            </React.Fragment>
        );
    });

    const pastRestoratives = appearanceSettings.pastRestoratives[restorativeView].map(modelWithAppearance => {
        const { payloadModel, appearance } = modelWithAppearance;
        const visible = isVisible(payloadModel, appearance, appearanceSettings.solo);

        const customMaterial = heatmapIsActive ? (
            qcHeatmapMaterial
        ) : (
            <StudioRestorativeMaterial
                show_transparent={isTransparent(appearance)}
                opacity={appearance.opacity}
                color={appearanceSettings.colorPastRestoratives ? PAST_TOOTH_COLOR : undefined}
            />
        );

        return (
            <ModelMesh
                key={payloadModel.path}
                visible={visible}
                model={payloadModel.model}
                colorize={false}
                centered={false}
                activeHeatMap={appearanceSettings.activeHeatMap}
                heatMapRange={appearanceSettings.heatMapRange}
                useShaderHeatmaps={heatmapIsActive && useShaderHeatmaps}
                customMaterial={customMaterial}
                wireframe={showWireframe}
            />
        );
    });

    const scans = [...appearanceSettings.scans, ...appearanceSettings.preExtractionScans].map(modelWithAppearance => {
        return renderPayloadItemScanMesh(
            appearanceSettings,
            modelWithAppearance.payloadModel,
            modelWithAppearance.appearance,
            useShaderHeatmaps ?? false,
            !!showScansHeatmap,
            appearanceSettings.solo,
            enableNewScanMeshMaterial,
            showWireframe ?? false,
        );
    });

    const printedModels = appearanceSettings.printedModels.map(modelWithAppearance => {
        const { payloadModel, appearance } = modelWithAppearance;
        const visible = appearance.visible;

        return (
            <ModelMesh
                key={`printed_model_${payloadModel.name}`}
                visible={visible}
                model={payloadModel.model}
                show_transparent={isTransparent(appearance)}
                opacity={appearance.opacity}
                colorize={appearance.colorize}
                centered={false}
            />
        );
    });

    // margin lines
    const anyRestorativesTransparent = React.useMemo(() => {
        return appearanceSettings.restoratives[appearanceSettings.restorativeView].some(
            i => isTransparent(i.appearance) && i.appearance.visible,
        );
    }, [appearanceSettings]);

    const marginEligiblePayloads = [...jawsPayload, ...appearanceSettings.scans].filter(
        pl => pl.appearance.visible === true,
    );

    const anyUpperMBJawVisible = appearanceSettings.upperJaw.some(s => s.appearance.visible);
    const anyLowerMBJawVisible = appearanceSettings.lowerJaw.some(s => s.appearance.visible);

    const anyUpperScanVisible = appearanceSettings.scans.some(
        s =>
            (s.payloadModel.jaw === Jaw.Upper || s.payloadModel.name.toLowerCase().includes('upper')) &&
            s.appearance.visible,
    );
    const anyLowerScanVisible = appearanceSettings.scans.some(
        s =>
            (s.payloadModel.jaw === Jaw.Lower || s.payloadModel.name.toLowerCase().includes('lower')) &&
            s.appearance.visible,
    );

    const isUpperJawVisible = anyUpperScanVisible || anyUpperMBJawVisible;
    const isLowerJawVisible = anyLowerScanVisible || anyLowerMBJawVisible;

    const margins =
        marginLines !== undefined
            ? marginLines
                  .filter(mLine => (ToothUtils.toothIsUpper(mLine.tooth) ? isUpperJawVisible : isLowerJawVisible))
                  .map((mLine, index) => (
                      <MarginMesh
                          key={`MARGIN_${index}_${mLine.tooth}`}
                          marginLine={mLine}
                          marginXray={anyRestorativesTransparent}
                          cameraControlsRef={cameraControlsRef}
                          allowEditing={false}
                          enableTubeMarginLine={enableTubeMarginLine}
                      />
                  ))
            : undefined;

    const doctorMargins = doctorMarginLines
        ?.filter(mLine => (ToothUtils.toothIsUpper(mLine.tooth) ? isUpperJawVisible : isLowerJawVisible))
        .map((mLine, index) => (
            <MarginMesh
                key={`DOCTOR_MARGIN_${index}_${mLine.tooth}`}
                marginLine={mLine}
                marginXray={anyRestorativesTransparent}
                cameraControlsRef={cameraControlsRef}
                allowEditing={false}
                color={new THREE.Color('#0000ff')}
                enableTubeMarginLine={enableTubeMarginLine}
            />
        ));

    const previousMargins = previousMarginLines?.map((mLine, index) => (
        <MarginMesh
            key={`PREVIOUS_MARGIN_${index}_${mLine.tooth}`}
            marginLine={mLine}
            marginXray={anyRestorativesTransparent}
            cameraControlsRef={cameraControlsRef}
            allowEditing={false}
            color={new THREE.Color('#0000ff')}
            enableTubeMarginLine={enableTubeMarginLine}
        />
    ));

    // Originally, I excluded or included the tooth marking objects from the JSX based on `showDoctorToothMarkings`, but
    // I was getting a TypeError when the objects were unmounted. Instead, we toggle the visibility of the objects,
    // which prevents the error.
    const toothMarkingsPayload = toothMarkings?.map((payload, idx) => (
        <ToothMarkingMesh
            key={`TOOTH_MARKING_${idx}`}
            marking={payload}
            visible={appearanceSettings.showDoctorToothMarkings}
        />
    ));

    const postEditChecksPayload = React.useMemo(() => {
        if (!appearanceSettings.showPostEditCheckMarkers) {
            return undefined;
        }

        const selfIntersectionMarkerRadiusMm = 0.2;
        return postEditCheckResult?.intaglioSelfIntersection.intersections?.map(({ position }, idx) => (
            <mesh key={`SELF_INTERSECTION_${idx}`} position={position.clone()}>
                <sphereBufferGeometry attach={'geometry'} args={[selfIntersectionMarkerRadiusMm]} />
                <meshBasicMaterial attach={'material'} color={'cyan'} />
            </mesh>
        ));
    }, [postEditCheckResult, appearanceSettings.showPostEditCheckMarkers]);

    const editMargins =
        marginLineEditing !== undefined
            ? marginLineEditing.marginLines.map((mline, index) => {
                  const marginTargetModel = getMarginAssociatedGeometryFromNewPayload(mline, marginEligiblePayloads);
                  return (
                      <MarginMesh
                          key={`MARGIN_EDIT_${index}_${mline.tooth}`}
                          associatedGeometry={marginTargetModel?.payloadModel.model.geometry}
                          marginLine={mline}
                          color={new THREE.Color(MarginEditingPalette.EDIT_LINE_COLOR)}
                          cameraControlsRef={cameraControlsRef}
                          marginXray={anyRestorativesTransparent}
                          allowEditing={mline.tooth === marginLineEditing.activeTooth && !!marginTargetModel}
                          onMarginUpdate={marginLineEditing.onMarginUpdate}
                          enableTubeMarginLine={enableTubeMarginLine}
                      />
                  );
              })
            : [];

    // collisions
    const collisions = appearanceSettings.collisions.map((qcmesh, i) => {
        return (
            <QCCollisionsMesh
                key={`${qcmesh.name}_${i}`}
                item={qcmesh}
                showSecondGroup={appearanceSettings.showCurtainsCollisions}
            />
        );
    });

    // Anatomy layers are shown transparently and by a single flag
    // which filters the payload
    const anatomyLayersPayload = appearanceSettings.anatomy;
    const anatomyLayers = anatomyLayersPayload.map(anatItem => {
        return (
            <ModelMesh
                key={anatItem.name}
                model={anatItem.model}
                centered={false}
                colorize={false}
                customMaterial={anatomyMaterial}
            />
        );
    });

    const removedGeometryMaterial = React.useMemo(
        () => <StudioRestorativeMaterial show_transparent={true} opacity={0.5} color={SCAN_TRIM_COLOR} />,
        [],
    );

    const removedGeometryMeshes = React.useMemo(() => {
        return (
            scanTrimmedSections?.map((mesh, idx) => {
                return (
                    <primitive key={`REMOVED_GEOMETRY_${idx}`} object={mesh} receiveShadow={true} castShadow={true}>
                        {removedGeometryMaterial}
                    </primitive>
                );
            }) ?? null
        );
    }, [scanTrimmedSections, removedGeometryMaterial]);

    const insertionAxisMesh = useInsertionAxisMesh(selectedInsertionAxis, prepData);

    return (
        <>
            {scans}
            {jawMeshes}
            {restoratives}
            {pastRestoratives}
            {printedModels}
            {appearanceSettings.showCollisions && collisions}
            {appearanceSettings.showMarginLines && margins}
            {appearanceSettings.showDoctorMarginLines && doctorMargins}
            {appearanceSettings.showPreviousMarginLines && previousMargins}
            {editMargins}
            {appearanceSettings.showAnatomyLayers && anatomyLayers}
            {toothMarkingsPayload}
            {postEditChecksPayload}
            {removedGeometryMeshes}
            {insertionAxisMesh}
        </>
    );
};

/**
 * Helper to determine if a model is visible.
 * @param modelPayload Model in question.
 * @param appearance Model's appearance settings.
 * @param soloModelsArray Overall solo state (affecs visibility of non-solo'd models)
 * @returns
 */
function isVisible(
    modelPayload: ModelPayloadItem,
    appearance: ItemAppearance,
    soloModelsArray?: ModelPayloadItem[],
): boolean {
    const soloIsEmpty = soloModelsArray === undefined || soloModelsArray.length === 0;
    const visibleForSolo = soloIsEmpty ? true : soloModelsArray?.includes(modelPayload);
    return appearance.visible && !!visibleForSolo;
}
function getShaderCustomMaterial(appearance: ItemAppearance, colorMap?: THREE.Texture) {
    const shader = appearance.customShader;
    if (shader === undefined) {
        return undefined;
    }
    const uniforms = { ...shader.uniforms, map: { value: colorMap } };
    // NB: We use the syntax for passing arguments to the THREE.ShaderMaterial constructor (i.e. `args` prop) instead of
    // setting individual properties on the object because the latter approach was not properly setting the value of
    // some uniforms of the shader.
    const shaderParams: DandyShaderMaterialParameters = {
        vertexColors: !colorMap,
        side: THREE.DoubleSide,
        ...shader,
        uniforms,
        map: colorMap,
    };
    return <dandyShaderMaterial args={[shaderParams]} />;
}

function getPrepShaderCustomMaterial(
    appearanceSettings: ModelAppearance,
    shader: THREE.ShaderMaterialParameters,
    appearance: ItemAppearance,
    modelPayload: ModelPayloadItem,
) {
    const colorize = appearance.colorize;

    // basic visibility from Appearance manager
    const visProps = {
        transparent: isTransparent(appearance),
        opacity: appearance.opacity,
    };

    const colorMap = colorize ? modelPayload.colorMap : undefined;

    if (shader.uniforms?.vMin) {
        shader.uniforms.vMin.value = appearanceSettings.heatMapRange?.min;
    }
    if (shader.uniforms?.vMax) {
        shader.uniforms.vMax.value = appearanceSettings.heatMapRange?.max;
    }
    if (shader.uniforms?.showHeatmap) {
        shader.uniforms.showHeatmap.value = appearanceSettings.restorativeView === RestorativeView.HeatMap;
    }
    if (shader.uniforms?.opacity) {
        shader.uniforms.opacity.value = appearance.opacity;
    }
    if (shader.uniforms?.map) {
        shader.uniforms.map.value = colorMap;
    }

    // NB: We use the syntax for passing arguments to the THREE.ShaderMaterial constructor (i.e. `args` prop) instead of
    // setting individual properties on the object because the latter approach was not properly setting the value of
    // some uniforms of the shader.
    const shaderParams: DandyShaderMaterialParameters = {
        side: THREE.DoubleSide,
        ...shader,
        ...visProps,
        vertexColors: colorize && !modelPayload.colorMap,
        map: colorMap,
    };

    return <dandyShaderMaterial args={[shaderParams]} />;
}

/**
 * Helper to render standard Scan meshes.
 *
 * @param modelPayload Model to render.
 * @param appearance Model's appearance settings.
 * @param soloModelsArray Overall solo state (affects visibility of non-solo'd models)
 * @returns
 */

function renderPayloadItemScanMesh(
    appearanceSettings: ModelAppearance,
    modelPayload: ModelPayloadItem,
    appearance: ItemAppearance,
    useShaderHeatmaps: boolean,
    showHeatmap: boolean,
    soloModelsArray?: ModelPayloadItem[],
    enableNewScanMaterial: 'design' | 'prep' | 'chairside' | boolean = false,
    showWireframe: boolean = false,
) {
    const visible = isVisible(modelPayload, appearance, soloModelsArray);
    let customMaterial: any = undefined;
    if (visible) {
        customMaterial = getItemScanMeshMaterial(
            appearanceSettings,
            appearance,
            modelPayload,
            showHeatmap,
            enableNewScanMaterial,
        );
    }

    return (
        <ModelMesh
            visible={visible}
            centered={false}
            key={modelPayload.name}
            model={modelPayload.model}
            show_transparent={isTransparent(appearance)}
            opacity={appearance.opacity}
            colorize={appearance.colorize}
            colorMap={modelPayload.colorMap}
            customMaterial={customMaterial}
            activeHeatMap={appearanceSettings.activeHeatMap}
            heatMapRange={appearanceSettings.heatMapRange}
            useShaderHeatmaps={useShaderHeatmaps}
            customMesh={modelPayload.mesh}
            wireframe={showWireframe}
        />
    );
}

function getScanMeshOcclusalMaterial(
    appearanceSettings: ModelAppearance,
    appearance: ItemAppearance,
    modelPayload: ModelPayloadItem,
    showHeatmap: boolean,
) {
    const colorize = appearance.colorize;
    const hasVcolor = !!modelPayload.model.geometry.getAttribute(AttributeName.Color);
    const hasImageMap = !!modelPayload.colorMap;
    const hasUvMap = !!modelPayload.model.geometry.getAttribute(AttributeName.TexCoord);
    const enableColoring = appearance.colorize && (hasVcolor || (hasImageMap && hasUvMap));
    const isOcclusalHeatmapActive =
        appearanceSettings.restorativeView === RestorativeView.HeatMap &&
        appearanceSettings.activeHeatMap === HeatMapType.Occlusal;
    return (
        <ScanMeshShaderMaterialPrep
            enableVertexColors={colorize && !modelPayload.colorMap}
            enableColoring={enableColoring}
            heatMapRange={getHeatmapRange(appearanceSettings)}
            showHeatmap={showHeatmap && isOcclusalHeatmapActive}
            colorMap={colorize ? modelPayload.colorMap : undefined}
            opacity={appearance.opacity}
            color={appearance.color}
        />
    );
}

/**
 * Choose and create correct material.
 *
 * 1. Use custom shader material if it exists (eg for undercuts)
 * 2. Use MeshPhysycalHSVMaterial with color management
 *    if colorization is enabled (appearance.colorize).
 * 3. Use StudioScanStoneMaterial for uncolored model without
 *    custom shader
 */
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
function getItemScanMeshMaterial(
    appearanceSettings: ModelAppearance,
    appearance: ItemAppearance,
    modelPayload: ModelPayloadItem,
    showHeatmap: boolean,
    enableNewScanMaterial: 'design' | 'prep' | 'chairside' | boolean = false,
) {
    const colorize = appearance.colorize;
    const hasVcolor = !!modelPayload.model.geometry.getAttribute(AttributeName.Color);
    const hasImageMap = !!modelPayload.colorMap;
    const hasUvMap = !!modelPayload.model.geometry.getAttribute(AttributeName.TexCoord);
    const enableColoring = appearance.colorize && (hasVcolor || (hasImageMap && hasUvMap));

    // any custom shader is assigned here (like the undercut, trim, and alignment shaders)
    let customMaterial: any = undefined;

    // here we totally separate materials for prep from 'design' and 'chairside'
    if (enableNewScanMaterial === 'prep') {
        if (appearance.customShader) {
            customMaterial = getPrepShaderCustomMaterial(
                appearanceSettings,
                appearance.customShader,
                appearance,
                modelPayload,
            );
            // if no-vertex-color mode is selected, use the no-color custom shader
            if (!enableColoring && appearance.customShaderNoColor) {
                customMaterial = getPrepShaderCustomMaterial(
                    appearanceSettings,
                    appearance.customShaderNoColor,
                    appearance,
                    modelPayload,
                );
            }
        }
        if (!customMaterial) {
            customMaterial = getScanMeshOcclusalMaterial(appearanceSettings, appearance, modelPayload, showHeatmap);
        }
    } else {
        customMaterial = getShaderCustomMaterial(appearance, modelPayload.colorMap);

        if (showHeatmap && enableNewScanMaterial !== 'chairside') {
            customMaterial = getScanMeshOcclusalMaterial(appearanceSettings, appearance, modelPayload, showHeatmap);
        }

        if (!customMaterial && enableColoring) {
            // basic visibility from Appearance manager
            const visProps = {
                transparent: isTransparent(appearance),
                opacity: appearance.opacity,
            };
            // Choose imageTexture vs vertexColor
            const colorProps = {
                map: colorize ? modelPayload.colorMap : undefined,
                vertexColors: colorize && !modelPayload.colorMap,
            };

            customMaterial = enableNewScanMaterial ? (
                <ScanMeshShaderPhysicalMaterial
                    mode={enableNewScanMaterial}
                    {...visProps}
                    {...colorProps}
                    activeHeatMap={appearanceSettings.activeHeatMap}
                    heatMapRange={appearanceSettings.heatMapRange}
                    showHeatmap={showHeatmap && appearanceSettings.restorativeView === RestorativeView.HeatMap}
                />
            ) : (
                <DefaultScanMeshPhysicalMaterial {...visProps} {...colorProps} />
            );
        }
    }

    const color = getScanColor(modelPayload);
    customMaterial = customMaterial ?? (
        <StandardScanStoneMaterial
            color={color}
            show_transparent={isTransparent(appearance)}
            opacity={appearance.opacity}
        />
    );

    return customMaterial;
}

function getCadItemColor(modelPayload: ModelPayloadItem) {
    if (modelPayload.cadType === CadType.Gingiva) {
        return GINGIVA_COLOR;
    }
    if (modelPayload.cadType === CadType.Splint) {
        return SPLINT_COLOR;
    }
    return undefined;
}

function canApplyHeatmap(modelPayload: ModelPayloadItem) {
    return modelPayload.cadType !== CadType.Gingiva && modelPayload.cadType !== CadType.Splint;
}

function getScanColor(modelPayload: ModelPayloadItem) {
    if (isPreExtractionScanItem(modelPayload)) {
        return DEFAULT_PRE_EXTRACTION_SCAN_COLOR;
    }

    return MODEL_GRAY_COLOR;
}

function useInsertionAxisMesh(
    selectedInsertionAxis: ToothNumber | undefined,
    prepData: ToothPrepDataMap,
): React.ReactElement | null {
    return React.useMemo(() => {
        const prep = selectedInsertionAxis ? prepData?.get(selectedInsertionAxis) : undefined;
        if (!prep) {
            return null;
        }
        const { insertionAxis, marginCentroid } = prep;

        const position = marginCentroid.clone().addScaledVector(insertionAxis, 2);

        return (
            <InsertionAxisMesh
                visible={true}
                highlighted={true}
                insertionAxis={insertionAxis.toArray()}
                position={position}
            />
        );
    }, [selectedInsertionAxis, prepData]);
}
