import type { MillInterferenceAdjustmentParams, MillInterferenceParams } from './types';

export function getMillInterferenceParams(params?: Partial<MillInterferenceParams>): MillInterferenceParams {
    return {
        relativeEpsilon: params?.relativeEpsilon ?? 1e-3,
        jiggleIterations: params?.jiggleIterations ?? 0,
        maxLateralJiggleFactor: params?.maxLateralJiggleFactor ?? 0,
        maxNormalJiggleFactor: params?.maxNormalJiggleFactor ?? 0,
        facetSelector: params?.facetSelector ?? (() => true),
    };
}

export function getMillAdjustmentParams(
    inParams?: Partial<MillInterferenceAdjustmentParams>,
): MillInterferenceAdjustmentParams {
    return {
        maxIterations: inParams?.maxIterations ?? 30,
        surfaceTension: inParams?.surfaceTension ?? 0.5,
        surfaceRelaxation: inParams?.surfaceRelaxation ?? 1.0,
        pushbackTension: inParams?.pushbackTension ?? 0.175,
        pushbackRelaxation: inParams?.pushbackRelaxation ?? 0.75,
        tiltTension: inParams?.tiltTension ?? 2,
        tiltRelaxation: inParams?.tiltRelaxation ?? 1.0,
        preventBoundaryAdjustment: inParams?.preventBoundaryAdjustment ?? true,
        ...getMillInterferenceParams(inParams),
    };
}
