/*
 * Non optimized linear brute force search for closest point
 * For small lists performance is fine
 * Depending on desired use, if you do, not care about
 * pure accuracy, an earlyEpsilon can be specified
 * */
import { logger } from '../Utils/Logger';
import * as THREE from 'three';

export function findClosestPoint(
    points: THREE.Vector3[],
    targetPoint: THREE.Vector3,
    earlyEpsilon: number | undefined = undefined,
): { closestIndex: number; closestDistance: number } {
    let closestDistance = Number.MAX_VALUE;
    let closestIndex = -1;
    const earlyDistSquared = earlyEpsilon ? Math.pow(earlyEpsilon, 2) : 0;
    for (let i = 0; i < points.length; i++) {
        const d = points[i]?.distanceToSquared(targetPoint);
        if (d !== undefined && d < closestDistance) {
            closestDistance = d;
            closestIndex = i;
            // if we don't care below a certain distance, then we can stop here!
            if (!!earlyEpsilon && d < earlyDistSquared) {
                return { closestIndex: i, closestDistance: d };
            }
        }
    }
    return { closestIndex, closestDistance: Math.sqrt(closestDistance) };
}

/*
 * This function is not truly exact and assumes that the polyline
 * does not have any loops over on itself. It makes the assumption
 * that the closes point along the line segments, is on one of the
 * segments connected to the closest vertex in the polyline.  For
 * dental margins this is a safe assumption
 *
 * earlyEpsilon signifies than any closer than that value
 * is insignificant and to return early
 * */
export function findClosestPointToPolyline(
    points: THREE.Vector3[],
    targetPoint: THREE.Vector3,
    lineClosed: boolean,
    coarseFirst: boolean = true,
    earlyEpsilon: number | undefined = undefined,
): { closestIndex: number; closestDistance: number; closestPoint: THREE.Vector3; percentage: number } {
    const reusableLineSegment = new THREE.Line3();

    let startInd = 0;
    let endInd = points.length;
    let closestDistance = Number.MAX_VALUE;
    let closestIndex = -1;
    let closestPercentage = 0;
    let closestPoint = new THREE.Vector3();

    if (coarseFirst) {
        const coarseResult = findClosestPoint(points, targetPoint, earlyEpsilon);
        startInd =
            coarseResult.closestIndex < 2
                ? points.length + (coarseResult.closestIndex - 2)
                : coarseResult.closestIndex - 2;
        endInd = startInd + 4;
        closestDistance = coarseResult.closestDistance;
        closestIndex = coarseResult.closestIndex;
        closestPoint = points[coarseResult.closestIndex] ?? new THREE.Vector3();
    }

    for (let i = startInd; i < endInd; i++) {
        if (i === points.length - 1 && !lineClosed) {
            continue;
        }
        const j = i % points.length;
        const n = (i + 1) % points.length;
        const p0 = points[j];
        const p1 = points[n];

        // our modulo operator safety should prevent this from ever
        // happening.  But if we mess up indices somehow then
        // reusableLineSegment.set() will throw
        if (p0 === undefined || p1 === undefined) {
            logger.warn('Indexing error in find closest point');
            continue;
        }
        reusableLineSegment.set(p0, p1);
        const percentage = reusableLineSegment.closestPointToPointParameter(targetPoint, true);
        const pointOnLine = new THREE.Vector3().lerpVectors(p0, p1, percentage);
        const d = targetPoint.distanceTo(pointOnLine);

        // if we know what close is, then we can stop here!
        if (!!earlyEpsilon && d < earlyEpsilon) {
            return { closestIndex: j, closestDistance: d, closestPoint: closestPoint, percentage: percentage };
        }
        if (d !== undefined && d < closestDistance) {
            closestDistance = d;
            closestIndex = j;
            closestPercentage = percentage;
            closestPoint = pointOnLine;
        }
    }
    return { closestIndex, closestDistance, closestPoint, percentage: closestPercentage };
}
