import type { SecondarySidebarItem } from '../../../components/PracticeSecondarySidebar.types';
import { usePracticeAppSelector } from '../../../redux';
import { useOrdersSelector } from '../../../redux/orders/orders.selectors';
import type { LabOrderRootStatus } from '../LabsUtils';
import { useOrderStatusCounts } from './useOrderStatusCounts.graphql';
import type { LabsGqlOrderPreviewFragment, LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import type { OrderReturn } from '@orthly/graphql-react';
import { useReturns, useScans } from '@orthly/graphql-react';
import { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import _ from 'lodash';
import React from 'react';

export type OrdersOverviewItem =
    | { id: string; type: 'order'; order: LabsGqlOrderPreviewFragment }
    | { id: string; type: 'return'; orderReturn: OrderReturn }
    | { id: string; type: 'scan'; scan: LabsGqlScanFileFragment };

const orderPassesFilters =
    (doctor_id_filter: string[], unit_type_filter: string[]) =>
    (order: Pick<LabsGqlOrderPreviewFragment, 'items_v2' | 'doctor_preferences_id'>): boolean => {
        if (doctor_id_filter.length > 0 && !doctor_id_filter.includes(order.doctor_preferences_id)) {
            return false;
        }
        if (unit_type_filter.length > 0) {
            const unitTypes = OrderItemV2Utils.parseItems(order.items_v2).flatMap(i =>
                CartItemV2Utils.getAllUnitTypes(i),
            );
            return !!unitTypes.find(u => unit_type_filter.includes(u));
        }
        return true;
    };

function useReturnsTableRows() {
    const { returns, loading } = useReturns({ fetchPolicy: 'no-cache' });
    return { loading, returns: returns.filter(ret => !ret.cancellation_date) };
}

export function useUnsubmittedScanOverviewItems(isAscending: boolean): {
    scanRows: OrdersOverviewItem[];
    loading: boolean;
} {
    const status = useOrdersSelector(s => s.status);
    const scansVisible = status === 'All' || status === 'Draft';
    const { scans, loading } = useScans({ skip: !scansVisible });
    return React.useMemo(() => {
        if (!scansVisible) {
            return { loading, scanRows: [] };
        }
        const filteredSorted = _.sortBy(scans, s => {
            const dateValue = new Date(s.created_at).valueOf();
            return isAscending ? dateValue : -dateValue;
        });
        return {
            loading,
            scanRows: filteredSorted.map<OrdersOverviewItem>(scan => ({ scan, id: scan.id, type: 'scan' })),
        };
    }, [scans, scansVisible, loading, isAscending]);
}

export function useReturnOverviewItems(): { returnRows: OrdersOverviewItem[]; loading: boolean } {
    const { returns, loading } = useReturnsTableRows();
    const { status, doctor_id_filter, product_line_filter } = usePracticeAppSelector(s => s.orders);
    const returnsVisible = status === 'Return' || status === 'All';
    return React.useMemo(() => {
        if (!returnsVisible) {
            return { loading, returnRows: [] };
        }
        const passesFilter = orderPassesFilters(doctor_id_filter, product_line_filter);
        return {
            loading,
            returnRows: _.compact(
                returns.map(r => (passesFilter(r.order) ? { type: 'return', id: r.id, orderReturn: r } : undefined)),
            ),
        };
    }, [doctor_id_filter, returnsVisible, returns, product_line_filter, loading]);
}

export function useOrdersOverviewSidebarItems() {
    const statusOrderCount = useOrderStatusCounts();
    return React.useMemo<SecondarySidebarItem<LabOrderRootStatus>[][]>(() => {
        // The `special` statuses (scans, returns, on hold) we only show if they have active items
        const top: SecondarySidebarItem<LabOrderRootStatus>[] = [
            // we show the "special" items in the All section, so include them in the count
            {
                value: LabsGqlPracticeOrderStatus.All,
                count:
                    statusOrderCount.All +
                    statusOrderCount.UnsubmittedScans +
                    statusOrderCount.InTransitReturns +
                    statusOrderCount.UnshippedReturns,
            },
            {
                value: LabsGqlPracticeOrderStatus.Draft,
                label: 'Incomplete orders',
                count: statusOrderCount.UnsubmittedScans,
                variant: 'error',
            },
            {
                value: LabsGqlPracticeOrderStatus.OnHold,
                label: 'On hold',
                count: statusOrderCount.OnHold,
                variant: 'error',
            },
            {
                value: LabsGqlPracticeOrderStatus.Waxup,
                label: 'Awaiting Approval',
                count: statusOrderCount.WaxupReview,
                variant: 'error',
            },
            {
                value: LabsGqlPracticeOrderStatus.Return,
                label: 'Returns',
                count: statusOrderCount.UnshippedReturns + statusOrderCount.InTransitReturns,
                variant: statusOrderCount.UnshippedReturns > 0 ? 'error' : undefined,
            },
            {
                value: LabsGqlPracticeOrderStatus.NeedsOrderFeedback,
                label: 'Needs order feedback',
                count: statusOrderCount.NeedsOrderFeedback,
            },
        ];
        const middle: SecondarySidebarItem<LabOrderRootStatus>[] = [
            { value: LabsGqlPracticeOrderStatus.New, count: statusOrderCount.New },
            { value: LabsGqlPracticeOrderStatus.Fabrication, count: statusOrderCount.Fabrication },
            { value: LabsGqlPracticeOrderStatus.Shipped, count: statusOrderCount.Shipped, label: 'In Transit' },
        ];
        const bottom: SecondarySidebarItem<LabOrderRootStatus>[] = [
            { value: LabsGqlPracticeOrderStatus.Delivered, count: statusOrderCount.Delivered },
            { value: LabsGqlPracticeOrderStatus.Cancelled, count: statusOrderCount.Cancelled },
        ];
        return [
            top.filter(s => s.count > 0 || s.value === LabsGqlPracticeOrderStatus.NeedsOrderFeedback),
            middle,
            bottom,
        ];
    }, [statusOrderCount]);
}
