import type { ProcessedEvent } from './ProcessedEvent';
import z from 'zod';

// Basic zod schemas to actually parse the XML object
const BaseEventSchema = z.object({
    // If the name is empty, `xmlbuilder2` parses it as an empty object rather than a string.
    Name: z.union([z.string(), z.object({}).strict()]).transform(datum => {
        return typeof datum === 'string' ? datum : '';
    }),
    Kind: z.string(),
    Time: z.string(),
    EndTime: z.string().optional(),
    TimeZone: z.string(),
    Duration: z.coerce.number().optional(),
});
type BaseEventSchema = z.infer<typeof BaseEventSchema>;

const EventSchemaWithChildrenSchema = BaseEventSchema.extend({
    Children: z
        .object({
            Event: z.union([BaseEventSchema, z.array(BaseEventSchema)]).transform(datum => {
                return Array.isArray(datum) ? datum : [datum];
            }),
        })
        .optional(),
});
export type EventSchemaWithChildrenSchema = z.infer<typeof EventSchemaWithChildrenSchema>;

export const DesignPerformanceLogSchema = z.object({
    PerformanceLog: z.object({
        Journal: z.object({
            Event: z
                .union([EventSchemaWithChildrenSchema, z.array(EventSchemaWithChildrenSchema)])
                .transform(datum => {
                    return Array.isArray(datum) ? datum : [datum];
                })
                .optional(),
        }),
    }),
});
export type DesignPerformanceLogSchema = z.infer<typeof DesignPerformanceLogSchema>;

// This is not an exhaustive list of all possible typed names,
// but rather the hardcoded names _we_ actually reference in code.
// This is used to ensure simple typos don't break everything as code changes happen.
export type TypedEventName =
    | 'Idle'
    | 'Save'
    | 'ISaveScan'
    | 'SaveTree'
    | 'Order'
    | 'Prepare'
    | 'Segmentation'
    | 'Interfaces'
    | 'Directions'
    | 'Anatomy pre-design'
    | 'Frame design'
    | 'Abutments/Post and Cores'
    | 'Anatomy design'
    | 'FD Initial Setup'
    | 'Export'
    | 'TSculpt_AddRemoveSmooth'
    | 'TSculpt_DentalAddRemoveSmoothRefModel';

export function isEventOfName(event: ProcessedEvent, names: TypedEventName[] | TypedEventName): boolean {
    if (Array.isArray(names)) {
        // Cast is safe because we're searching the array.... Typescript is funny sometimes.
        return names.includes(event.name as TypedEventName);
    }

    return event.name === names;
}

// These data structures are used to compute the actual report that we were previously generating in python.

export type ProcessedEventOutput = {
    DandyCorrected: boolean;
    utcCorrectedEnd: number | null;
    Name: string;
    Kind: string;
    Time: string;
    EndTime?: string;
    TimeZone: string;
    Duration?: number;
    utcTime: number;
    utcEndTime?: number;
    Children?: ProcessedEventOutput[];
};

export type ParsedEventData = {
    event_analysis_version: '001_2022_06_30';
    FEATURE_n_events: number;
    FEATURE_raw_event_total_timespan_ms: number;
    FEATURE_raw_idle_time_ms: number;
    FEATURE_n_sessions: number;
    FEATURE_cumulative_session_time: number;
    FEATURE_total_gap_between_sessions: number;
    FEATURE_Prepare_duration: number;
    FEATURE_Segmentation_duration: number;
    FEATURE_Interfaces_duration: number;
    FEATURE_Directions_duration: number;
    'FEATURE_Anatomy_pre-design_duration': number;
    FEATURE_Frame_design_duration: number;
    FEATURE_Abutments_Post_and_Cores_duration: number;
    FEATURE_Anatomy_design_duration: number;
    FEATURE_Idle_duration: number;
    first_event_timestamp: number;
    sessions: Omit<SessionData, 'processedEvents'>[];
    bad_events: number[];
    parsed_events: ProcessedEventOutput[];
    aggregate_session_usage: Record<string, SessionDataUsageSummaryDatum>;
};

export type SessionDataUsageSummaryDatum = {
    count: number;
    cumulative_duration: number;
};

export type SessionDataGap = {
    gap: number;
    currentEvent: ProcessedEvent;
    previousEvent: ProcessedEvent;
};

export type SessionData = {
    gap_to_last_session: number;
    session_start: number;
    session_finish?: number;
    session_duration?: number;
    usage_summary: Record<string, SessionDataUsageSummaryDatum>;
    gaps: SessionDataGap[];
};
