import { QcHeatmapOptions } from '../ColorRamp';
import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import { drawCircle } from './utils3d/interaction.util';
import { updateRaycaster } from './utils3d/raycast-bvh.util';
import { AttributeName, HeatMapType, ensureMeshIndex, getTriangle } from '@orthly/forceps';
import { useThree } from '@react-three/fiber';
import _ from 'lodash';
import React from 'react';
import type { OrthographicCamera, Vector3 } from 'three';
import * as THREE from 'three';

function interimCleanupMeshesAndObjs(group: THREE.Group, lines: THREE.Line[]): void {
    lines.forEach(line => {
        line.geometry.dispose();
        group?.remove(line);
    });
}

function updateCircle(circle: THREE.Line, circleProps: any) {
    const { pos, rot, effectDistance } = circleProps;
    if (!!pos) {
        circle.position.copy(pos);
        circle.updateMatrix();
    }
    if (!!rot) {
        circle.rotation.copy(rot);
        circle.updateMatrix();
    }
    if (!!effectDistance) {
        circle.scale.set(effectDistance, effectDistance, effectDistance);
        circle.updateMatrix();
    }
}

export const HeatmapHighlight: React.FC<{
    geometries: THREE.BufferGeometry[];
    cameraControlsRef: MainViewCameraControlsRef;
    activeHeatMap: HeatMapType;
    showCurtainsHeatmap: boolean;
    onHeatmapHighlighted: (thickness: number | null) => void;
    maxRadiusMm: number;
}> = ({ cameraControlsRef, geometries, maxRadiusMm, onHeatmapHighlighted, activeHeatMap, showCurtainsHeatmap }) => {
    const groupRef = React.useRef<THREE.Group>();
    const { gl } = useThree();

    // Geometry and Materials for reuse for Control Points
    // Geometry and Materials will accumulate if not disposed of
    // and can cause performance degradation as they pile up
    const { sphereGeometry, sphereMaterial } = React.useMemo(() => {
        return {
            sphereGeometry: new THREE.SphereBufferGeometry(maxRadiusMm, 32, 32),
            sphereMaterial: new THREE.MeshBasicMaterial({ color: 'rgb(255, 255, 255)' }),
        };
    }, [maxRadiusMm]);

    React.useEffect(
        // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
        // eslint-disable-next-line sonarjs/cognitive-complexity
        () => {
            const controls = cameraControlsRef.current;
            const camera = cameraControlsRef.current?.object as OrthographicCamera;
            const g = groupRef.current;

            if (!controls) {
                return;
            }

            if (g) {
                g.renderOrder = 1000;
            }

            let previewCircle: THREE.Line | undefined = undefined;
            let circlePos: Vector3 | undefined = undefined;
            let isOffMesh: boolean = true;
            const update_live_visual_objects = (showCircle: boolean) => {
                if (!showCircle && previewCircle) {
                    previewCircle.visible = false;
                    return;
                }
                if (g && circlePos) {
                    if (!previewCircle) {
                        previewCircle = drawCircle({ radius: maxRadiusMm, color: `rgb(0, 0, 0)` });
                        g.add(previewCircle);
                    }
                    previewCircle.visible = true;
                    updateCircle(previewCircle, {
                        pos: circlePos,
                        effectDistance: 0.5,
                        rot: camera.rotation,
                    });
                }
            };

            // put circle where it belongs
            update_live_visual_objects(true);

            const rayCaster = new THREE.Raycaster();
            const canvas = gl.domElement;

            const recomputeSelectedColor = () => {
                // needed for raycasting
                const indexBVHSequence = geometries.map(g => ensureMeshIndex(g));
                const intersectionRecords = indexBVHSequence
                    .map(bvh => {
                        const intersection = bvh.raycastFirst(rayCaster.ray, THREE.DoubleSide);
                        return {
                            geometry: bvh.geometry,
                            intersection: intersection,
                        };
                    })
                    .filter(intersectionRecord => !!intersectionRecord.intersection);
                const intersectionRecord = _.minBy(intersectionRecords, record => record.intersection.distance);

                // the mouse is not over the mesh
                if (!intersectionRecord?.intersection.face) {
                    // Don't spam the text updater which sets state on a React component
                    if (!isOffMesh) {
                        onHeatmapHighlighted(null);
                        update_live_visual_objects(false);
                        isOffMesh = true;
                    }
                    return;
                }
                isOffMesh = false;
                circlePos = intersectionRecord.intersection.point;

                const { geometry, intersection } = intersectionRecord;
                const heatMap = QcHeatmapOptions[activeHeatMap];
                const rawData = heatMap.qcDynamicHeatmapLayer(geometry);
                const curtainsAttribute = geometry.attributes[AttributeName.CurtainsDistance];
                const includeCurtains = activeHeatMap === HeatMapType.Proximal && showCurtainsHeatmap;
                if (rawData && intersection.face) {
                    const triangle = getTriangle(geometry, intersection.face);
                    const barycentricCoordinates: THREE.Vector3 = new THREE.Vector3();
                    triangle.getBarycoord(intersection.point, barycentricCoordinates);
                    const vertices: number[] = [intersection.face.a, intersection.face.b, intersection.face.c];
                    const distances = vertices.map<number>(vert => {
                        let r = rawData.array[vert] ?? 0;
                        if (includeCurtains) {
                            const curtain_distance = curtainsAttribute?.array[vert] ?? 0;
                            if (curtain_distance > r && curtain_distance < 100) {
                                r = curtain_distance;
                            }
                        }
                        return r;
                    });
                    const distancesVector = new THREE.Vector3(distances[0], distances[1], distances[2]);
                    // interpolate the distance using barycentric coordinates
                    const distance = distancesVector.dot(barycentricCoordinates);
                    if (distance > 100) {
                        update_live_visual_objects(false);
                        onHeatmapHighlighted(null);
                    } else {
                        update_live_visual_objects(true);
                        onHeatmapHighlighted(distance);
                    }
                } else {
                    update_live_visual_objects(false);
                    onHeatmapHighlighted(null);
                }
            };

            const pointerMove = (evt: MouseEvent) => {
                // communicate mouse position to scene via camera etc
                updateRaycaster(rayCaster, gl.domElement, camera, evt);
                recomputeSelectedColor();
            };

            const pointerLeave = () => {
                update_live_visual_objects(false);
                onHeatmapHighlighted(null);
            };

            canvas?.addEventListener('pointerleave', pointerLeave);
            canvas?.addEventListener('pointermove', pointerMove);

            return () => {
                if (g && previewCircle) {
                    interimCleanupMeshesAndObjs(g, [previewCircle]);
                }
                canvas?.removeEventListener('pointerleave', pointerLeave);
                canvas?.removeEventListener('pointermove', pointerMove);
            };
        },
        [
            cameraControlsRef,
            geometries,
            sphereMaterial,
            sphereGeometry,
            activeHeatMap,
            showCurtainsHeatmap,
            gl.domElement,
            maxRadiusMm,
            onHeatmapHighlighted,
        ],
    );

    return <group ref={groupRef} />;
};
