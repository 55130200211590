import { ModelMesh, QCMeshCustomMaterial } from './ModelMeshes';
import type { ModelPayload } from './ModelViewerTypes';
import type { UndercutPropsForModelViewer } from './UndercutViewerApp';
import { DEFAULT_MODEL_COLOR, HIGHLIGHT_TOOTH_COLOR } from './defaultModelColors';
import { ScanMeshShaderPhysicalMaterial } from './materials/scanMeshShaderMaterial';
import { AttributeName, type HeatMapType } from '@orthly/forceps';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';

// Given a list of scans, returns a list such that any duplicates are removed.
// When a duplicate is encountered, will return the PLY if it exists, else the STL,
// else the first scan it finds.
function uniqueScans(scans: ModelPayload[]): ModelPayload[] {
    const groups = _.values(_.groupBy<ModelPayload>(scans, r => r.path.replace('.ply', '').replace('.stl', '')));
    const uniques = groups.map<ModelPayload | undefined>(
        grouping =>
            grouping.find(t => t.model.modelType === 'stl') ??
            grouping.find(t => t.model.modelType === 'ply') ??
            grouping[0],
    );
    return _.compact<ModelPayload>(uniques);
}

type PrepAndRestorativeModelMeshProps = {
    restoratives: ModelPayload[];
    qcExtras?: ModelPayload[];
    restorativesTransparent: boolean;
    enableTexture: boolean;
    prep: ModelPayload;
    activeColormap?: HeatMapType;
    enableCustomScanShader?: 'design' | 'prep' | 'chairside' | boolean;
    undercutApp?: UndercutPropsForModelViewer;
};

export const PrepAndRestorativeModelMesh: React.FC<PrepAndRestorativeModelMeshProps> = props => {
    const {
        prep,
        restoratives,
        enableTexture,
        restorativesTransparent,
        qcExtras,
        activeColormap,
        enableCustomScanShader,
        undercutApp,
    } = props;

    const uniqueRestoratives = React.useMemo(() => uniqueScans(restoratives), [restoratives]);
    const uniqueQCMeshes = React.useMemo(() => uniqueScans(qcExtras ?? []), [qcExtras]);
    const qcMatWire = React.useMemo(() => <QCMeshCustomMaterial wireframe={true} depthtest={false} />, []);
    const qcMatColor = React.useMemo(() => <QCMeshCustomMaterial wireframe={false} depthtest={true} />, []);

    const modelViewerScanMeshmaterial = React.useMemo(
        () =>
            enableCustomScanShader ? (
                <ScanMeshShaderPhysicalMaterial mode={enableCustomScanShader} vertexColors={true} />
            ) : undefined,
        [enableCustomScanShader],
    );

    const customPrepScanMaterial =
        undercutApp?.undercutMaterial ??
        (enableTexture && enableCustomScanShader ? modelViewerScanMeshmaterial : undefined);
    return (
        <group>
            <ModelMesh
                color={DEFAULT_MODEL_COLOR}
                model={prep.model}
                colorize={enableTexture}
                customMaterial={
                    prep.model.geometry.hasAttribute(AttributeName.Color) || undercutApp?.undercutMaterial
                        ? customPrepScanMaterial
                        : undefined
                }
                centered={false}
                activeHeatMap={activeColormap}
            />

            {uniqueRestoratives.map(restorative => (
                <ModelMesh
                    key={restorative.name}
                    color={HIGHLIGHT_TOOTH_COLOR}
                    model={restorative.model}
                    show_transparent={restorativesTransparent}
                    opacity={restorativesTransparent ? 0.35 : 1.0}
                    colorize={false}
                    centered={false}
                    activeHeatMap={activeColormap}
                />
            ))}

            {uniqueQCMeshes.map(qcmesh => (
                <ModelMesh
                    key={qcmesh.name}
                    color={HIGHLIGHT_TOOTH_COLOR}
                    model={qcmesh.model}
                    wireframe={qcmesh.name.includes('Collision')}
                    depthWrite={!qcmesh.name.includes('Collision')}
                    show_transparent={props.restorativesTransparent}
                    opacity={props.restorativesTransparent ? 0.35 : 1.0}
                    colorize={qcmesh.name.includes('Thickness')}
                    centered={false}
                    customMaterial={qcmesh.name.includes('Collision') ? qcMatWire : qcMatColor}
                    activeHeatMap={activeColormap}
                />
            ))}
        </group>
    );
};

type FullJawAndRestorativeModelMeshProps = {
    restoratives: ModelPayload[];
    enableTexture: boolean;
    restorativesTransparent: boolean;
    prep: ModelPayload;
    anterior: ModelPayload;
    qcExtras?: ModelPayload[];
    activeColormap?: HeatMapType;
    enableCustomScanShader?: 'design' | 'prep' | 'chairside' | boolean;
    undercutApp?: UndercutPropsForModelViewer;
};

export const FullJawAndRestorativeModelMesh: React.FC<FullJawAndRestorativeModelMeshProps> = props => {
    const {
        prep,
        anterior,
        restoratives,
        enableTexture,
        qcExtras,
        activeColormap,
        enableCustomScanShader,
        undercutApp,
    } = props;
    const uniqueRestoratives = React.useMemo(() => uniqueScans(restoratives), [restoratives]);
    const uniqueQCMeshes = React.useMemo(() => uniqueScans(qcExtras ?? []), [qcExtras]);
    const qcMatWire = React.useMemo(() => <QCMeshCustomMaterial wireframe={true} depthtest={false} />, []);
    const qcMatColor = React.useMemo(() => <QCMeshCustomMaterial wireframe={false} depthtest={true} />, []);
    const modelViewerScanMeshMaterial = React.useMemo(
        () =>
            enableCustomScanShader ? (
                <ScanMeshShaderPhysicalMaterial mode={enableCustomScanShader} vertexColors={true} />
            ) : undefined,
        [enableCustomScanShader],
    );

    const customPrepScanMaterial =
        undercutApp?.undercutMaterial ??
        (enableTexture && enableCustomScanShader ? modelViewerScanMeshMaterial : undefined);

    return (
        <group>
            <ModelMesh
                color={DEFAULT_MODEL_COLOR}
                model={prep.model}
                colorize={enableTexture}
                customMaterial={
                    prep.model.geometry.hasAttribute(AttributeName.Color) || undercutApp?.undercutMaterial
                        ? customPrepScanMaterial
                        : undefined
                }
                centered={false}
                activeHeatMap={activeColormap}
            />

            <ModelMesh
                color={DEFAULT_MODEL_COLOR}
                model={anterior.model}
                colorize={enableTexture}
                customMaterial={
                    prep.model.geometry.hasAttribute(AttributeName.Color) || undercutApp?.undercutMaterial
                        ? customPrepScanMaterial
                        : undefined
                }
                centered={false}
                activeHeatMap={activeColormap}
            />

            {uniqueRestoratives.map(restorative => (
                <ModelMesh
                    key={restorative.name}
                    color={HIGHLIGHT_TOOTH_COLOR}
                    model={restorative.model}
                    show_transparent={props.restorativesTransparent}
                    opacity={props.restorativesTransparent ? 0.35 : 1.0}
                    colorize={false}
                    centered={false}
                    activeHeatMap={activeColormap}
                />
            ))}
            {uniqueQCMeshes.map(qcmesh => (
                <ModelMesh
                    key={qcmesh.name}
                    color={HIGHLIGHT_TOOTH_COLOR}
                    model={qcmesh.model}
                    wireframe={qcmesh.name.includes('Collision')}
                    depthWrite={!qcmesh.name.includes('Collision')}
                    show_transparent={props.restorativesTransparent}
                    opacity={props.restorativesTransparent ? 0.35 : 1.0}
                    colorize={qcmesh.name.includes('Thickness')}
                    centered={false}
                    customMaterial={qcmesh.name.includes('Collision') ? qcMatWire : qcMatColor}
                    activeHeatMap={activeColormap}
                />
            ))}
        </group>
    );
};

type FullJawModelMeshProps = {
    showUpper: boolean;
    showLower: boolean;
    upper: ModelPayload;
    lower: ModelPayload;
    enableCustomScanShader?: 'design' | 'prep' | 'chairside' | boolean;
};

export const FullJawModelMesh: React.FC<FullJawModelMeshProps> = props => {
    const { upper, lower, showUpper, showLower, enableCustomScanShader } = props;

    // If we are using a CTM or PLY, we use this custom material as the model won't be textured enough otherwise with a basic material
    const isTextured = (payload: ModelPayload) => ['ply', 'ctm'].includes(payload.model.modelType);
    const useTexture = isTextured(upper) || isTextured(lower);

    // we use this payload for ortho models, which do have vertex colors but we don't want
    // the new ScanMeshShaderPhysicalMaterial unless explicitly declared
    const customMaterial =
        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
        // eslint-disable-next-line no-nested-ternary
        useTexture && !enableCustomScanShader ? (
            <meshLambertMaterial
                vertexColors={true}
                side={THREE.DoubleSide}
                flatShading={true}
                blending={THREE.NormalBlending}
            />
        ) : useTexture && enableCustomScanShader ? (
            <ScanMeshShaderPhysicalMaterial mode={enableCustomScanShader} vertexColors={true} />
        ) : undefined;

    return (
        <group>
            {useTexture && <ambientLight intensity={0.3} />}

            {showUpper && (
                <group>
                    <ModelMesh
                        color={DEFAULT_MODEL_COLOR}
                        model={upper.model}
                        colorize={useTexture}
                        centered={false}
                        customMaterial={customMaterial}
                    />
                </group>
            )}

            {showLower && (
                <group>
                    <ModelMesh
                        color={DEFAULT_MODEL_COLOR}
                        model={lower.model}
                        colorize={useTexture}
                        centered={false}
                        customMaterial={customMaterial}
                    />
                </group>
            )}
        </group>
    );
};
