import { Text } from '@orthly/ui-primitives';
import React from 'react';

interface MouseLabelProps {
    forceUpdateRef: React.MutableRefObject<((msg: string | null) => void) | null>;
    // If true, uses offsetX and offsetY of the mouse event to position the label. By default, uses pageX and pageY.
    offsetMouse?: boolean;
}

// This component is meant to be updated fast
// as much outside of react render cycle as possible
//
// in future, we will replace Text with something less
// React as well and perhaps set a ref on an html object
export const MouseLabel: React.VFC<MouseLabelProps> = ({ forceUpdateRef, offsetMouse }) => {
    const [position, setPosition] = React.useState({ left: 0, top: 0 });
    const [msg, setMessage] = React.useState<string | null>();

    // mount our force update and event listener
    React.useEffect(() => {
        forceUpdateRef.current = setMessage;
        const mousemove = (e: any) => {
            const x = offsetMouse ? e.offsetX : e.pageX;
            const y = offsetMouse ? e.offsetY : e.pageY;
            setPosition({ left: x + 10, top: y + 10 });
        };
        document.addEventListener('mousemove', mousemove);
        return () => {
            forceUpdateRef.current = null;
            document.removeEventListener('mousemove', mousemove);
        };
    }, [forceUpdateRef, offsetMouse]);

    return (
        <Text
            variant={'caption'}
            style={{
                position: 'fixed' as 'fixed',
                zIndex: 10000,
                left: position.left,
                top: position.top,
                display: !!msg ? '' : 'none',
                color: 'white',
                textShadow: '0.07em 0 black, 0 0.07em black, -0.07em 0 black, 0 -0.07em black',
            }}
        >
            {msg}
        </Text>
    );
};
