import type { StaffMemberSession } from '../context/StaffMemberSession.types';
import { usePrevious } from './usePrevious';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import * as Sentry from '@sentry/react';
import React from 'react';

function handleSessionStart(session: StaffMemberSession) {
    const { user, id: staff_id, ...staffMember } = session;
    // Super important!!! This ensures we can separate impersonation events from real ones
    BrowserAnalyticsClientFactory.Instance?.register({
        isImpersonation: staffMember.isImpersonation || false,
    });
    const staffTraits = {
        staff_id,
        staff_roles: staffMember.roles,
        organization_id: staffMember.organization_id,
        organization_name: staffMember.organization_name,
    };
    try {
        Sentry.setUser({
            ...user,
            ...staffTraits,
            // username is one of the few searchable sentry user fields. co-opting it for org type or org name search
            // !! before changing any traits below, you also need to change user-request.middleware.ts:150 !!
            username: `${staffMember.organization_type}:${staffMember.organization_name}`,
            member_roles: staffMember.roles,
            member_id: staff_id,
        });
        BrowserAnalyticsClientFactory.Instance?.setGroup('organization', staffMember.organization_id);
        void BrowserAnalyticsClientFactory.Instance?.setGroupProp('organization', staffMember.organization_id, {
            name: staffMember.organization_name,
            type: staffMember.organization_type,
        });
        BrowserAnalyticsClientFactory.Instance?.track('Global - Auth Session Started', {
            staffRoles: staffTraits.staff_roles,
            organizationType: staffMember.organization_type,
            isImpersonation: session.isImpersonation || false,
            organizationId: staffTraits.organization_id,
            organizationName: staffTraits.organization_name,
        });
    } catch (err: any) {
        console.info(`Failed to track session start!`);
        console.error(err);
        Sentry.captureException(err, { extra: { ...user, ...staffTraits } });
    }
}

function handleSessionEnd() {
    Sentry.setUser(null);
    BrowserAnalyticsClientFactory.Instance?.reset();
}

export function useSessionMonitor(session: StaffMemberSession | null) {
    const previousSession = usePrevious(session);
    // Session monitor
    React.useEffect(() => {
        if (!session && !!previousSession) {
            // CASE: session has terminated
            console.info(`::::: END SESSION`, previousSession);
            return handleSessionEnd();
        }
        if (!!session && !previousSession) {
            // CASE: session has started (from none)
            return handleSessionStart(session);
        }
        if (!!session && !!previousSession && previousSession.id !== session.id) {
            // CASE: session has changed (new login without logging out first)
            console.info(`:::::/2 END SESSION`, previousSession);
            handleSessionEnd();
            console.info(`:::::/2 START SESSION`, session);
            return handleSessionStart(session);
        }
    }, [session, previousSession]);
}
