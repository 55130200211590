import { PracticeScreen } from '../PracticeScreen';
import type {
    HorizontalNavAccountSettingsLinks,
    HorizontalNavAccountSettingsProps,
    HorizontalNavChatProps,
} from './components';
import {
    HorizontalNavAccountSettings,
    HorizontalNavChat,
    HorizontalNavOnlineBooking,
    HorizontalNavSupplies,
} from './components';
import type { HorizontalNavCSTProps } from './components/HorizontalNavCST';
import { HorizontalNavCST } from './components/HorizontalNavCST';
import { useButtonStyles } from './hooks';
import type { LabsGqlUserLoginOptionFragment } from '@orthly/graphql-operations';
import { HomeIcon, NavigationLogoDandy } from '@orthly/ui';
import type { SvgIconProps, Theme } from '@orthly/ui-primitives';
import {
    Button,
    FlossPalette,
    stylesFactory,
    useScreenIsMobile,
    AppBar,
    Divider,
    Grid,
    Toolbar,
} from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';
import { Link, useHistory } from 'react-router-dom';

const useStyles = stylesFactory<{ navbarHeight: number }>((theme: Theme) => ({
    toolbar: {
        minHeight: 'unset',
        height: ({ navbarHeight }) => navbarHeight,
        margin: '0 8px',
        justifyContent: 'space-between',
    },
    appBar: {
        zIndex: 1200,
        background: FlossPalette.TAN,
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        '@media print': {
            display: 'none',
        },
    },
    logo: {
        minHeight: 28,
        maxHeight: 28,
        color: FlossPalette.BLACK,
    },
    horizontalNavMiddle: {
        width: '40%',
        [theme.breakpoints.down('md')]: {
            width: '50%',
        },
    },
}));

interface NavButtonProps {
    title?: string;
    Icon?: React.ComponentType<SvgIconProps>;
    screen?: string;
    externalLink?: string;
    openExternalLinkAction?: (externalLink: string) => void;
}

const NavButton: React.VFC<NavButtonProps> = props => {
    const { Icon, title, screen, externalLink, openExternalLinkAction } = props;
    const buttonClasses = useButtonStyles();
    const history = useHistory();
    const [isHovering, setIsHovering] = React.useState(false);
    const active = history.location.pathname.replaceAll('/', '') === screen;
    const onClick = React.useCallback(() => {
        if (screen) {
            history.push(`/${screen}`);
        } else if (externalLink && openExternalLinkAction) {
            openExternalLinkAction(externalLink);
        }
    }, [screen, externalLink, openExternalLinkAction, history]);

    return (
        <Button
            onMouseOver={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            onClick={onClick}
            variant={'nav'}
            className={cx(!!Icon && buttonClasses.iconButton, active && buttonClasses.active)}
            data-test={`horizontal-nav-button-${title?.toLowerCase().replaceAll(' ', '-')}`}
        >
            {Icon ? (
                <Icon
                    style={{
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        color: active
                            ? FlossPalette.PRIMARY_FOREGROUND
                            : isHovering
                              ? FlossPalette.DARK_GRAY
                              : FlossPalette.GRAY,
                    }}
                />
            ) : (
                title
            )}
        </Button>
    );
};
export interface HorizontalNavLeftSideButtonLinks {
    Home: string;
    Orders: string;
}

interface HorizontalNavLeftSideProps {
    enablePMS: boolean;
    enableDandyShopLinks: boolean;
    leftNavButtonLinks: HorizontalNavLeftSideButtonLinks;
    additionalLeftNavButtons?: NavButtonProps[];
    openExternalLinkAction?: (externalLink: string) => void;
}

export const HorizontalNavLeftSide: React.VFC<HorizontalNavLeftSideProps> = props => {
    const { enablePMS, enableDandyShopLinks, leftNavButtonLinks, additionalLeftNavButtons, openExternalLinkAction } =
        props;

    return (
        <Grid data-test={'horizontal-nav-left-side'} container style={{ alignItems: 'center' }}>
            <NavButton screen={leftNavButtonLinks.Home} Icon={HomeIcon} />
            <NavButton screen={leftNavButtonLinks.Orders} title={'Orders'} />
            {additionalLeftNavButtons?.map(button => <NavButton key={button.screen} {...button} />)}
            <Divider orientation={'vertical'} style={{ height: 16, margin: '0 8px' }} />
            <NavButton
                externalLink={'help-center'}
                openExternalLinkAction={openExternalLinkAction}
                title={'Help Center'}
            />
            {enablePMS && <HorizontalNavOnlineBooking />}
            <HorizontalNavSupplies
                enableDandyShopLinks={enableDandyShopLinks}
                openExternalLinkAction={openExternalLinkAction}
            />
        </Grid>
    );
};

const HorizontalNavMiddle: React.VFC<{ searchComponent: React.ReactNode; navbarHeight: number }> = props => {
    const { searchComponent, navbarHeight } = props;
    const classes = useStyles({ navbarHeight });

    return (
        <Grid
            container
            data-test={'horizontal-nav-middle'}
            alignItems={'center'}
            justifyContent={'center'}
            className={classes.horizontalNavMiddle}
        >
            {searchComponent}
        </Grid>
    );
};

export interface HorizontalNavRightSideButtonLinks {
    horizontalNavAccountSettingsLinks: HorizontalNavAccountSettingsLinks;
}

interface HorizontalNavRightSideProps extends Omit<HorizontalNavAccountSettingsProps, 'horizontalNavLinks'> {
    searchComponent?: React.ReactNode;
    notificationsComponent?: React.ReactNode;
    chatProps?: Omit<HorizontalNavChatProps, 'navbarHeight'>;
    cstProps?: Omit<HorizontalNavCSTProps, 'navbarHeight'>;
    rightNavButtonLinks: HorizontalNavRightSideButtonLinks;
    screenIsMedium: boolean;
    volumeControl?: React.ReactNode; // Only used in Chairside
    disableChatBackdrop?: boolean;
    loginOptions: LabsGqlUserLoginOptionFragment[];
}

export const HorizontalNavRightSide: React.VFC<HorizontalNavRightSideProps> = props => {
    const {
        profile,
        smallProfilePhoto,
        largeProfilePhoto,
        searchComponent,
        chatProps,
        cstProps,
        notificationsComponent,
        switchProfileAction,
        logoutAction,
        logoutText,
        additionalMenuSection,
        settingsPlacement,
        rightNavButtonLinks,
        volumeControl,
        screenIsMedium,
        navbarHeight,
        disableChatBackdrop,
        loginOptions,
    } = props;

    return (
        <Grid
            container
            data-test={'horizontal-nav-right-side'}
            style={{ alignItems: 'center', justifyContent: 'end', flexWrap: 'nowrap', width: 'auto' }}
        >
            {cstProps && <HorizontalNavCST {...cstProps} navbarHeight={navbarHeight} />}
            {cstProps && searchComponent && !screenIsMedium && (
                <Divider
                    orientation={'vertical'}
                    style={{
                        height: 0,
                        margin: '0px 4px',
                    }}
                />
            )}
            {!screenIsMedium && searchComponent}
            {chatProps && (
                <HorizontalNavChat {...chatProps} navbarHeight={navbarHeight} disableBackdrop={disableChatBackdrop} />
            )}
            {volumeControl}
            {notificationsComponent}
            {!screenIsMedium && (
                <>
                    <Divider orientation={'vertical'} style={{ height: 16, margin: '0 8px' }} />
                    <HorizontalNavAccountSettings
                        profile={profile}
                        smallProfilePhoto={smallProfilePhoto}
                        largeProfilePhoto={largeProfilePhoto}
                        switchProfileAction={switchProfileAction}
                        logoutAction={logoutAction}
                        logoutText={logoutText}
                        settingsPlacement={settingsPlacement}
                        horizontalNavLinks={rightNavButtonLinks.horizontalNavAccountSettingsLinks}
                        additionalMenuSection={additionalMenuSection}
                        navbarHeight={navbarHeight}
                        loginOptions={loginOptions}
                    />
                </>
            )}
        </Grid>
    );
};

export interface HorizontalNavProps extends HorizontalNavLeftSideProps, HorizontalNavRightSideProps {
    chairside: boolean;
}

export const HorizontalNav: React.FC<HorizontalNavProps> = props => {
    const {
        chairside,
        searchComponent,
        notificationsComponent,
        chatProps,
        cstProps,
        enablePMS,
        enableDandyShopLinks,
        profile,
        smallProfilePhoto,
        largeProfilePhoto,
        switchProfileAction,
        logoutAction,
        screenIsMedium,
        logoutText,
        leftNavButtonLinks,
        rightNavButtonLinks,
        settingsPlacement,
        additionalMenuSection,
        navbarHeight,
        disableChatBackdrop,
        openExternalLinkAction,
        loginOptions,
    } = props;
    const classes = useStyles({ navbarHeight });
    const isMobile = useScreenIsMobile();

    if (chairside || isMobile) {
        return null;
    }

    return (
        <AppBar data-test={'horizontal-nav-app-bar'} position={'static'} elevation={0} className={classes.appBar}>
            <Toolbar disableGutters className={classes.toolbar}>
                {screenIsMedium ? (
                    <Link
                        data-test={'horizontal-nav-logo-link'}
                        to={`/${PracticeScreen.inbox}`}
                        style={{ marginLeft: 8, marginBottom: -8 }}
                    >
                        <NavigationLogoDandy className={classes.logo} />
                    </Link>
                ) : (
                    <HorizontalNavLeftSide
                        enablePMS={enablePMS}
                        enableDandyShopLinks={enableDandyShopLinks}
                        leftNavButtonLinks={leftNavButtonLinks}
                        openExternalLinkAction={openExternalLinkAction}
                    />
                )}
                {screenIsMedium && (
                    <HorizontalNavMiddle searchComponent={searchComponent} navbarHeight={navbarHeight} />
                )}
                <HorizontalNavRightSide
                    profile={profile}
                    smallProfilePhoto={smallProfilePhoto}
                    largeProfilePhoto={largeProfilePhoto}
                    searchComponent={searchComponent}
                    notificationsComponent={notificationsComponent}
                    chatProps={chatProps}
                    cstProps={cstProps}
                    switchProfileAction={switchProfileAction}
                    logoutAction={logoutAction}
                    logoutText={logoutText}
                    settingsPlacement={settingsPlacement}
                    rightNavButtonLinks={rightNavButtonLinks}
                    screenIsMedium={screenIsMedium}
                    additionalMenuSection={additionalMenuSection}
                    navbarHeight={navbarHeight}
                    disableChatBackdrop={disableChatBackdrop}
                    loginOptions={loginOptions}
                />
            </Toolbar>
        </AppBar>
    );
};
