import { GUIDED_WAXUP_COMMON_STYLES } from '../Waxups/GuidedWaxups/VisualConstants.util';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { ToolState } from '@orthly/dentin';
import { RedoIcon, UndoIcon } from '@orthly/ui';
import type { IconName } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Text,
    stylesFactory,
    useScreenIsMobile,
    Icon,
    Button,
    IconButton,
    Popover,
    Tooltip,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

const trackIconButtonClick = (iconName: string) => {
    BrowserAnalyticsClientFactory.Instance?.track('Button Clicked', {
        AssetName: `Markup Annotation Button - ${_.startCase(iconName)}`,
        AssetType: 'button',
        AssetVersion: '',
        AssetCTAText: `${_.startCase(iconName)} (Icon)`,
    });
};

export type ToolStateSetter = (action: ToolState | ((prevState: ToolState) => ToolState)) => void;

export const PossibleToolTypes: { type: ToolState['toolType']; icon: IconName; tooltip?: string }[] = [
    { type: 'brush', icon: 'DrawIcon', tooltip: 'Pencil' },
    { type: 'arrow', icon: 'DrawArrowIcon', tooltip: 'Arrow' },
    { type: 'rect', icon: 'DrawRectangleIcon', tooltip: 'Rectangle' },
    { type: 'oval', icon: 'DrawCircleIcon', tooltip: 'Ellipse' },
];
export const PossibleBrushSizes = [5, 9, 15, 21];
export const PossibleColors = ['#2196F3', '#9C27B0', '#4CAF50', '#F44336', '#000000'];

export const INITIAL_TOOL_STATE: ToolState = {
    toolType: 'pointer',
    brushSize: PossibleBrushSizes[0] ?? 5,
    color: PossibleColors[PossibleColors.length - 1] ?? 'black',
};

const BORDER_STYLE = `1px solid ${FlossPalette.STROKE_LIGHT}`;
const SELECTED_BG_COLOR = '#0002';

interface DrawingControlsStyleProps {
    size?: number;
    color?: string;
}

const useStyles = stylesFactory<DrawingControlsStyleProps>(theme => ({
    toolRow: {
        display: 'flex',
        padding: '0px 24px',
        height: '48px',
        borderBottom: BORDER_STYLE,
        overflow: 'hidden', // Overflow is hidden to clip the `selectedToolPointer`.
        alignItems: 'center',
        '@media screen and (max-width: 500px)': {
            justifyContent: 'center',
        },
    },
    brushesAndColorsRow: {
        display: 'flex',
        padding: '0px 24px',
        alignItems: 'center',
        height: '48px',
        borderBottom: BORDER_STYLE,
        [theme.breakpoints.down('lg')]: {
            padding: 0,
        },
    },
    iconButton: {
        cursor: 'pointer',
        '&:hover': { background: FlossPalette.DARK_GRAY },
    },
    selectedBrushStyle: {
        backgroundColor: `${SELECTED_BG_COLOR} !important`,
        '&:hover': { background: `${SELECTED_BG_COLOR} !important` },
    },
    verticalDivider: {
        width: 0,
        alignSelf: 'stretch',
        margin: '8px 8px',
        borderRight: BORDER_STYLE,
    },
    controlTopbar: {
        ...GUIDED_WAXUP_COMMON_STYLES,
        borderRadius: '8px 8px 0px 0px',
        marginBottom: 0,
        marginLeft: 0.5,
        width: '100%',
    },
    buttonSize: {
        height: 36,
        width: 36,
    },
    colorAndSizeOuterConatiner: ({ color }) => ({
        height: 23,
        width: 23,
        borderRadius: 23 / 2,
        position: 'absolute' as const,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: `dashed 1px ${color}`,
    }),
    // size is defaulted to zero since it can be undefined to satisfy type safety,
    // but it should always be passed in where this class and the one below are used
    colorAndSizeInnerContainer: ({ size = 0, color }) => ({
        top: 'auto',
        left: 'auto',
        bottom: 'auto',
        right: 'auto',
        height: size,
        width: size,
        backgroundColor: color,
        borderRadius: size / 2,
    }),
    brushSizeContent: ({ size = 0 }) => ({
        height: size,
        width: size,
        backgroundColor: FlossPalette.DARK_GRAY,
        borderRadius: size / 2,
    }),
    colorButtonContent: ({ color }) => ({
        height: 20,
        width: 20,
        backgroundColor: color,
        borderRadius: 10,
    }),
}));

export const DrawingToolButton: React.VFC<{
    iconName: IconName;
    toolType: ToolState['toolType'];
    isSelected: boolean;
    setToolState: ToolStateSetter;
    tooltip?: string;
}> = ({ iconName, toolType, isSelected, setToolState, tooltip }) => {
    const classes = useStyles({});

    return (
        <Tooltip title={tooltip || iconName}>
            <IconButton
                className={classes.buttonSize}
                style={{
                    position: 'relative',
                    color: isSelected ? FlossPalette.GREEN : undefined,
                }}
                onClick={() => {
                    trackIconButtonClick(toolType);
                    setToolState(s => ({ ...s, toolType: isSelected ? 'pointer' : toolType }));
                }}
            >
                <Icon icon={iconName} />
            </IconButton>
        </Tooltip>
    );
};

export const ColorAndSizeButton: React.VFC<{
    size: number;
    color: string;
    isSelected: boolean;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
}> = ({ size, color, isSelected, onClick }) => {
    const classes = useStyles({ size, color });

    return (
        <Tooltip title={'Thickness/Color'}>
            <IconButton
                size={'small'}
                className={cx(classes.buttonSize, isSelected ? classes.selectedBrushStyle : undefined)}
                onClick={event => {
                    trackIconButtonClick('Color/Size Options');
                    onClick(event);
                }}
            >
                <div className={classes.colorAndSizeOuterConatiner}>
                    <div className={classes.colorAndSizeInnerContainer} />
                </div>
            </IconButton>
        </Tooltip>
    );
};

export const BrushSizeButton: React.VFC<{
    size: number;
    isSelected: boolean;
    onSelect: () => void;
}> = ({ size, isSelected, onSelect }) => {
    const classes = useStyles({ size });

    return (
        <IconButton
            size={'small'}
            className={cx(classes.buttonSize, isSelected ? classes.selectedBrushStyle : undefined)}
            onClick={() => {
                trackIconButtonClick('Brush Size Selector');
                onSelect();
            }}
        >
            <div className={classes.brushSizeContent} />
        </IconButton>
    );
};

export const ColorButton: React.VFC<{
    color: string;
    isSelected: boolean;
    onSelect: () => void;
}> = ({ color, isSelected, onSelect }) => {
    const classes = useStyles({ color });
    return (
        <IconButton
            size={'small'}
            className={cx(classes.buttonSize, isSelected ? classes.selectedBrushStyle : undefined)}
            onClick={() => {
                trackIconButtonClick('Brush Color Selector');
                onSelect();
            }}
        >
            <div className={classes.colorButtonContent} />
        </IconButton>
    );
};

export const DrawingControlsColorAndSizePopover: React.FC<{
    brushSelectAnchor: HTMLButtonElement | null;
    setBrushSelectAnchor: (x: HTMLButtonElement | null) => void;
    toolState: ToolState;
    setToolState: ToolStateSetter;
}> = ({ brushSelectAnchor, setBrushSelectAnchor, toolState, setToolState }) => {
    const classes = useStyles({});
    return (
        <Popover
            open={!!brushSelectAnchor}
            anchorEl={brushSelectAnchor}
            onClose={() => setBrushSelectAnchor(null)}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <div className={classes.brushesAndColorsRow}>
                {PossibleBrushSizes.map(size => (
                    <BrushSizeButton
                        key={size}
                        size={size}
                        isSelected={size === toolState.brushSize}
                        onSelect={() => {
                            setBrushSelectAnchor(null);
                            setToolState(s => ({ ...s, brushSize: size }));
                        }}
                    />
                ))}
                <div className={classes.verticalDivider} />
                {PossibleColors.map(color => (
                    <ColorButton
                        key={color}
                        color={color}
                        isSelected={color === toolState.color}
                        onSelect={() => {
                            setBrushSelectAnchor(null);
                            setToolState(s => ({ ...s, color }));
                        }}
                    />
                ))}
            </div>
        </Popover>
    );
};

export const DrawingControlsUndoAndRedoButtons: React.FC<{
    canUndo: boolean;
    undo: () => void;
    canRedo: boolean;
    redo: () => void;
}> = props => {
    const { canUndo, undo, canRedo, redo } = props;
    return (
        <>
            <Tooltip title={'Undo'}>
                <IconButton
                    disabled={!canUndo}
                    onClick={() => {
                        trackIconButtonClick('Undo');
                        undo();
                    }}
                >
                    <UndoIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Redo'}>
                <IconButton
                    disabled={!canRedo}
                    onClick={() => {
                        trackIconButtonClick('Redo');
                        redo();
                    }}
                >
                    <RedoIcon />
                </IconButton>
            </Tooltip>
        </>
    );
};

export const DrawingControls: React.VFC<{
    toolState: ToolState;
    setToolState: ToolStateSetter;
    canUndo: boolean;
    canRedo: boolean;
    undo: () => void;
    redo: () => void;
    deleteButtonVisible: boolean;
    deleteSnapshot?: () => void;
    trackingSource: DandyAnalyticsEventSchemaType['Order Annotation - Capture Screenshot Clicked']['source'];
}> = ({
    toolState,
    setToolState,
    canUndo,
    canRedo,
    undo,
    redo,
    deleteButtonVisible,
    deleteSnapshot,
    trackingSource,
}) => {
    const classes = useStyles({});
    const isMobile = useScreenIsMobile();

    // Controls whether the brush selector popover is visible and which element it is anchored to.
    const [brushSelectAnchor, setBrushSelectAnchor] = React.useState<HTMLButtonElement | null>(null);

    return (
        <div>
            <div
                className={classes.toolRow}
                data-pendo-id={trackingSource === 'refab_annotation' ? 'refab-design-annotations' : undefined}
            >
                {!isMobile && (
                    <Text variant={'body2'} medium color={'GRAY'} style={{ marginRight: 16 }}>
                        Markup tools:
                    </Text>
                )}
                {PossibleToolTypes.map(({ type, icon }) => (
                    <DrawingToolButton
                        key={type}
                        iconName={icon}
                        toolType={type}
                        isSelected={type === toolState.toolType}
                        setToolState={setToolState}
                    />
                ))}
                <div className={classes.verticalDivider} />
                <ColorAndSizeButton
                    color={toolState.color}
                    size={toolState.brushSize}
                    isSelected={!!brushSelectAnchor}
                    onClick={e => {
                        setBrushSelectAnchor(e.currentTarget);
                    }}
                />
                <DrawingControlsColorAndSizePopover
                    brushSelectAnchor={brushSelectAnchor}
                    setBrushSelectAnchor={setBrushSelectAnchor}
                    toolState={toolState}
                    setToolState={setToolState}
                />

                <div className={classes.verticalDivider} />
                <DrawingControlsUndoAndRedoButtons canUndo={canUndo} undo={undo} canRedo={canRedo} redo={redo} />
                {deleteButtonVisible && (
                    <>
                        <div style={{ flexGrow: 1 }} />
                        <Button variant={'ghost-warning'} onClick={() => deleteSnapshot?.()} endIcon={'TrashCanIcon'}>
                            Delete screen capture
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};
