import { TAB_BAR_HEIGHT } from '../layouts/mobile/MobileTabs';
import { usePracticeAppSelector } from '../redux';
import { HORIZONTAL_NAV_HEIGHT } from '@orthly/dentin';
import { useScreenIsMd, useScreenIsMobile, useScreenIsSm } from '@orthly/ui-primitives';
import { PRACTICE_OUTAGE_BANNER_HEIGHT } from '@orthly/veneer';

type ContentHeightParams = {
    chairside: boolean;
    maintenanceBannerPractice?: boolean;
};

export const useShowMobileLayout = (): boolean => {
    const screenIsMedium = useScreenIsMd();
    const isNativeApp = usePracticeAppSelector(s => s.ui.isNativeApp);
    return isNativeApp || screenIsMedium;
};

export const useCalculateContentHeight = (params: ContentHeightParams): string => {
    const { chairside, maintenanceBannerPractice } = params;
    const screenIsMedium = useScreenIsMd();
    const isMobile = useScreenIsMobile();
    const isMobileLayout = useShowMobileLayout();

    let offsetHeight = 0;

    if (!isMobile && !chairside) {
        offsetHeight += HORIZONTAL_NAV_HEIGHT;
    }

    if (isMobileLayout) {
        offsetHeight += TAB_BAR_HEIGHT;
    }

    if (maintenanceBannerPractice && !screenIsMedium) {
        offsetHeight += PRACTICE_OUTAGE_BANNER_HEIGHT;
    }

    return `calc(100vh - ${offsetHeight}px)`;
};

export const useCalculatePaddingTop = (params: ContentHeightParams): number => {
    const { chairside, maintenanceBannerPractice } = params;
    const isMobile = useScreenIsMobile();
    const isScreenSm = useScreenIsSm();
    const screenIsMedium = useScreenIsMd();

    let paddingTop = 0;

    if (!isMobile && !isScreenSm && !chairside) {
        paddingTop += HORIZONTAL_NAV_HEIGHT;
    }

    if (maintenanceBannerPractice && !screenIsMedium) {
        paddingTop += PRACTICE_OUTAGE_BANNER_HEIGHT;
    }

    return paddingTop;
};
