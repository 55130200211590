import { useCheckoutPropSelector } from '../../../../redux';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useInputItemsV2 } from '../../state/useInputItemsV2';
import { DeliveryDateSelect, EstimatedDeliveryDateDisplay } from '@orthly/dentin';
import { useCheckoutEstimatedDeliveryQuery, useGetCartDeliveryOptionsQuery } from '@orthly/graphql-react';
import type { LabsGqlCartDeliveryOption } from '@orthly/graphql-schema';
import { LabsGqlCartSlaModifier } from '@orthly/graphql-schema';
import { LoadBlocker } from '@orthly/ui';
import { FlossPalette, FlossPaletteUtils, styled, Text } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

/**
 * Returns a list of delivery dates applicable to the current checkout selection.
 * This is a direct fork of Chairside's behavior, without the IPC fetches.
 *
 * @notes
 * super complicated hook that basically exists to fall back to the getEstimatedDelivery call if our
 * delivery options don't inlcude a standard delivery option for some reason if there's only one option in options,
 * we know that it must be the standard
 *
 * @todo Add a `standard_delivery_estimate` to the `GetCartDeliveryOptions` query to vastly simplify this hook.
 */
function useDeliveryDateOptions(): { options: LabsGqlCartDeliveryOption[]; loading: boolean } {
    const { doctor, waxupState } = useCheckoutPropSelector(['doctor', 'waxupState']);

    const isWaxupSelected = waxupState.selected;
    const items_v2_by_sku = useInputItemsV2();

    const { data: deliveryOptionsData, loading: deliveryOptionsLoading } = useGetCartDeliveryOptionsQuery({
        variables: {
            items_v2_by_sku,
            doctor_id: doctor?.id ?? null,
            waxup: isWaxupSelected,
            enable_delivery_options: true,
        },
    });

    const deliveryOptions = deliveryOptionsData?.getCartDeliveryOptions.options;
    const shouldUseEstimatedDeliveryFallback =
        !deliveryOptionsLoading && !deliveryOptions?.some(o => o.sla_modifier === LabsGqlCartSlaModifier.Standard);

    // we only fall back to making this request when we don't get a standard response from the above, which we should always get
    const { loading: estimatedDeliveryLoading, data: estimatedDeliveryData } = useCheckoutEstimatedDeliveryQuery({
        variables: {
            waxup: isWaxupSelected,
            items_v2_by_sku,
            doctor_id: doctor?.id ?? null,
        },
        skip: !shouldUseEstimatedDeliveryFallback,
    });
    const estimatedStandardDeliveryDate = estimatedDeliveryData?.estimatedDeliveryDate.standard_delivery_date;

    // if we have a dentures or aligners case we never want to surface anything other than standard
    // this should be enforced server-side anyways but we also filter here jic
    const isDenturesOrAligners = items_v2_by_sku?.denture_items?.length || items_v2_by_sku?.aligner_items?.length;

    return React.useMemo(() => {
        if (shouldUseEstimatedDeliveryFallback) {
            return {
                options: estimatedStandardDeliveryDate
                    ? [
                          {
                              sla_modifier: LabsGqlCartSlaModifier.Standard,
                              delivery_date: estimatedStandardDeliveryDate,
                              info_text: null,
                              design_preview_eta: estimatedStandardDeliveryDate,
                          },
                      ]
                    : [],
                loading: estimatedDeliveryLoading,
            };
        }

        const options = isDenturesOrAligners
            ? (deliveryOptions ?? []).filter(o => o.sla_modifier === LabsGqlCartSlaModifier.Standard)
            : deliveryOptions;

        return {
            options: _.sortBy(options, o => new Date(o.delivery_date).getTime() * -1),
            loading: deliveryOptionsLoading,
        };
    }, [
        shouldUseEstimatedDeliveryFallback,
        deliveryOptions,
        deliveryOptionsLoading,
        estimatedStandardDeliveryDate,
        estimatedDeliveryLoading,
        isDenturesOrAligners,
    ]);
}

const Wrapper = styled('div')({
    color: '#4CCEAD',
    padding: 0,
    margin: 0,
    background: `${FlossPalette.TAN}`,
    border: `1px solid ${FlossPaletteUtils.toRgba('BLACK', 0.08)}`,
    borderRadius: 8,
    minHeight: 58,
    width: '100%',
});

export const CheckoutExpectedDelivery: React.FC = () => {
    const { scan, sla_modifier } = useCheckoutPropSelector(['scan', 'sla_modifier']);
    const setSlaModifierAction = useCheckoutAction('SET_SLA_MODIFIER');

    const { loading, options } = useDeliveryDateOptions();

    React.useEffect(() => {
        if (loading) {
            return;
        }
        // If the selected option is no longer available, we need to reset the selection
        // If they've selected Standard and there's only one option, we reset as well since they wouldn't have needed to make a selection
        // we compare both the sla_modifier and the info_text because the info_text is used
        // for pricing(since this feature is experimental) and included as part of the submission
        if (sla_modifier && (options.length < 2 || !options.some(o => _.isEqual(o.sla_modifier, sla_modifier)))) {
            setSlaModifierAction(undefined);
        }
        // if they haven't made a selection and we have more than one option available, we select that by default
        if (!sla_modifier && options.length > 1) {
            const standardOption = options.find(o => o.sla_modifier === LabsGqlCartSlaModifier.Standard);
            if (standardOption) {
                setSlaModifierAction(standardOption.sla_modifier);
            }
        }
    }, [sla_modifier, setSlaModifierAction, options, loading]);

    if (!scan || (!options.length && !loading)) {
        return null;
    }

    return (
        <LoadBlocker
            blocking={loading}
            ContainerProps={{
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Wrapper>
                {options.length > 1 ? (
                    <DeliveryDateSelect options={options} value={sla_modifier} onSelect={setSlaModifierAction} />
                ) : (
                    options.length === 1 && options[0] && <EstimatedDeliveryDateDisplay option={options[0]} />
                )}
            </Wrapper>
        </LoadBlocker>
    );
};
