// *** IMPORTANT: IMPORT TYPES ONLY ***
// This file provides code for a Web Worker that is loaded without context from imports.
// Import type definitions only.
import type { DracoDataType, DracoLoaderOptions } from './DracoLoaderTypes';
// eslint-disable-next-line import/no-extraneous-dependencies -- We only need @types/draco3d.
import type draco3d from 'draco3d';

// Add more types as needed.
type TypedArray = Uint8Array | Float32Array;

/* ---- Request message types ---- */

interface InitMessage {
    type: 'init';
    wasmBinary?: ArrayBuffer;
}

interface DecodeMessage {
    type: 'decode';
    data: ArrayBuffer;
    options?: Partial<DracoLoaderOptions>;
}

type WorkerRequestMessage = InitMessage | DecodeMessage;

/* ---- Response message types ---- */

interface MeshAttribute {
    data: TypedArray;
    numComponents: number;
}

export interface MeshData {
    index: Uint32Array;
    attributes: Record<string, MeshAttribute>;
}

interface SuccessMessage {
    type: 'success';
    meshData: MeshData;
}

interface ErrorMessage {
    type: 'error';
    error: unknown;
}

type WorkerResponseMessage = SuccessMessage | ErrorMessage;

// Provided by code prepended to worker script.
declare function DracoDecoderModule(props?: draco3d.DracoDecoderModuleProps): Promise<draco3d.DecoderModule>;
// In the context of the script, `self` refers to the worker.
declare const self: Worker;

/**
 * This function provides code for a Web Worker. It may take no dependencies
 * on outside code other than the `DracoDecoderModule` function declared above.
 */
function WorkerScript() {
    /* ---- Globals ---- */

    // Promise for initialization. Must be fulfilled before decoding begins.
    let initPromise: Promise<draco3d.DecoderModule>;
    // Loaded decoder module instance. Set when initPromise is fulfilled.
    let draco: draco3d.DecoderModule;

    function sRgbToLinear(c: number) {
        return c < 0.04045 ? c * 0.0773993808 : Math.pow(c * 0.9478672986 + 0.0521327014, 2.4);
    }

    /* ---- Decoder ---- */

    class Decoder {
        private readonly decoder: draco3d.Decoder;
        private readonly mesh: draco3d.Mesh;

        private static defaultAttributeTypes: Record<string, draco3d.GeometryAttributeType>;

        /** Initialize static lookups that depend on the dynamic decoder module. */
        static staticInit() {
            Decoder.defaultAttributeTypes = {
                position: draco.POSITION,
                normal: draco.NORMAL,
                color: draco.COLOR,
                uv: draco.TEX_COORD,
            };
        }

        /**
         * The public interface for the Decoder. Pass encoded data and options.
         * Returns decoded triangle index and vertex attribute data.
         */
        static decode(data: ArrayBuffer, options: DracoLoaderOptions = {}): MeshData {
            const instance = new Decoder(data, options);
            const meshData = instance.getMeshData();
            instance.destroy();
            return meshData;
        }

        private constructor(
            data: ArrayBuffer,
            private readonly options: DracoLoaderOptions = {},
        ) {
            this.decoder = new draco.Decoder();
            this.mesh = Decoder.decodeBufferToMesh(this.decoder, data);
        }

        private destroy() {
            draco.destroy(this.decoder);
            draco.destroy(this.mesh);
        }

        private static decodeBufferToMesh(decoder: draco3d.Decoder, buffer: ArrayBuffer): draco3d.Mesh {
            const decBuffer = new draco.DecoderBuffer();
            decBuffer.Init(new Int8Array(buffer), buffer.byteLength);

            const mesh = new draco.Mesh();
            decoder.DecodeBufferToMesh(decBuffer, mesh);

            draco.destroy(decBuffer);
            return mesh;
        }

        private getMeshData(): MeshData {
            const attributes: Record<string, MeshAttribute> = {};
            for (const [name, attType] of Object.entries(Decoder.defaultAttributeTypes)) {
                const attId = this.decoder.GetAttributeId(this.mesh, attType);
                if (attId === -1) {
                    continue;
                }
                const attribute = this.getAttributeData(attId, 'float');
                if (name === 'color') {
                    // Color is encoded as sRGB Uint8, but we want linear floats for rending.
                    // `getAttributeData` automatically normalizes the uint8 values to [0, 1].
                    // Here we convert them to the linear color space.
                    const values = attribute.data;
                    for (let i = 0; i < values.length; i++) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Checked index.
                        values[i] = sRgbToLinear(values[i]!);
                    }
                }
                attributes[name] = attribute;
            }
            if (this.options.customAttributes) {
                for (const [name, dataType] of Object.entries(this.options.customAttributes)) {
                    const attId = this.decoder.GetAttributeIdByName(this.mesh, name);
                    if (attId === -1) {
                        continue;
                    }
                    attributes[name] = this.getAttributeData(attId, dataType);
                }
            }

            return { index: this.getTriangles(), attributes };
        }

        private getTriangles(): Uint32Array {
            const numFaces = this.mesh.num_faces();
            const numValues = numFaces * 3;
            const byteLength = numValues * Uint32Array.BYTES_PER_ELEMENT;

            const ptr = draco._malloc(byteLength);
            this.decoder.GetTrianglesUInt32Array(this.mesh, byteLength, ptr);
            const index = new Uint32Array(draco.HEAP32.buffer, ptr, numValues).slice();
            draco._free(ptr);
            return index;
        }

        private getAttributeData(attId: number, dataType: DracoDataType): MeshAttribute {
            // It seems that initializing this locally is important for constructing
            // a TypedArray from the draco heap pointer. Otherwise you can get an
            // error, "Cannot perform Construct on a detached ArrayBuffer".
            // Not totally sure what's going on, but this works fine.
            const dataTypeMap = {
                uint8: [Uint8Array, draco.DT_UINT8, draco.HEAPU8],
                float: [Float32Array, draco.DT_FLOAT32, draco.HEAPF32],
            } as const;
            const [ArrayType, dracoDataType, heap] = dataTypeMap[dataType];

            const attribute = this.decoder.GetAttribute(this.mesh, attId);
            const numValues = this.mesh.num_points() * attribute.num_components();
            const byteLength = numValues * ArrayType.BYTES_PER_ELEMENT;

            const ptr = draco._malloc(byteLength);
            this.decoder.GetAttributeDataArrayForAllPoints(this.mesh, attribute, dracoDataType, byteLength, ptr);
            const array = new ArrayType(heap.buffer, ptr, numValues).slice();
            draco._free(ptr);
            return { data: array, numComponents: attribute.num_components() };
        }
    }

    /* ---- Communication ---- */

    // Typed alias for self.postMessage.
    const postMessage: (msg: WorkerResponseMessage, transfer: Transferable[]) => void = self.postMessage;

    async function onInit(message: InitMessage) {
        // eslint-disable-next-line sonarjs/no-use-of-empty-return-value -- False error.
        initPromise = DracoDecoderModule({ wasmBinary: message.wasmBinary });
        draco = await initPromise;
        Decoder.staticInit();
    }

    async function onDecode(message: DecodeMessage) {
        if (!initPromise) {
            throw new Error('Must post "init" message before "decode".');
        }
        // If initialization isn't done yet, wait for it.
        await initPromise;

        const meshData = Decoder.decode(message.data, message.options);
        const transfer: Transferable[] = [
            meshData.index.buffer,
            ...Object.values(meshData.attributes).map(({ data }) => data.buffer),
        ];
        postMessage({ type: 'success', meshData }, transfer);
    }

    onmessage = async (evt: MessageEvent<WorkerRequestMessage>) => {
        const message = evt.data;
        try {
            if (message.type === 'init') {
                await onInit(message);
            } else if (message.type === 'decode') {
                await onDecode(message);
            }
        } catch (error) {
            postMessage({ type: 'error', error }, []);
        }
    };
}

/** Wrapper for convenient use of the worker. */
export class DracoDecodeWorker {
    private busy = false;
    get isBusy() {
        return this.busy;
    }

    private constructor(private readonly worker: Worker) {}

    static async create(): Promise<DracoDecodeWorker> {
        // The browser caches these, so they should load from disk most of the time.
        const [wasmWrapperJs, wasmBinary] = await Promise.all([
            fetch('https://www.gstatic.com/draco/versioned/decoders/1.5.7/draco_wasm_wrapper.js').then(res =>
                res.text(),
            ),
            fetch('https://www.gstatic.com/draco/versioned/decoders/1.5.7/draco_decoder.wasm').then(res =>
                res.arrayBuffer(),
            ),
        ]);
        const workerScript = `${wasmWrapperJs} (${WorkerScript.toString()})()`;
        const scriptUrl = URL.createObjectURL(new Blob([workerScript]));
        const worker = new DracoDecodeWorker(new Worker(scriptUrl));
        worker.postMessage({ type: 'init', wasmBinary }, [wasmBinary]);
        return worker;
    }

    // Typed wrapper for underlying `postMessage()` function.
    private postMessage(msg: WorkerRequestMessage, transfer: Transferable[]) {
        this.worker.postMessage(msg, transfer);
    }

    async decode(data: ArrayBuffer, options: DracoLoaderOptions): Promise<MeshData> {
        if (this.busy) {
            throw new Error(`Cannot call decode() on a busy worker.`);
        }
        this.busy = true;
        return new Promise<MeshData>((res, rej) => {
            this.worker.onmessage = (evt: MessageEvent<WorkerResponseMessage>) => {
                const message = evt.data;
                if (message.type === 'success') {
                    this.busy = false;
                    res(message.meshData);
                } else if (message.type === 'error') {
                    this.busy = false;
                    rej(message.error);
                }
            };
            this.postMessage({ type: 'decode', data, options }, [data]);
        });
    }
}
