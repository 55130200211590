import { useCheckoutPropSelector, useCheckoutSelector } from '../../../../redux';
import { EditDeliveryAddressModal } from '../../../account/practice-settings/addresses/EditDeliveryAddressModal';
import { LabsUtils, useGoToPracticeScreen } from '../../../labs/LabsUtils';
import { useSelectStaffPropSelector } from '../../../select-staff/state/select-staff.selectors';
import { useCheckoutAction } from '../../state/checkout.actions';
import { CheckoutContentSection } from '../CheckoutLayout/CheckoutContentSection';
import { CheckoutErrorSection } from '../CheckoutLayout/CheckoutErrorSection';
import { CheckoutSelectField, PracticeScreen } from '@orthly/dentin';
import { useFetchDoctorsQuery, useLabDeliveryAddressesQuery } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { LoadBlocker } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import React from 'react';

const CheckoutSelectDeliveryAddress: React.FC = () => {
    const { address, doctor } = useCheckoutPropSelector(['address', 'doctor']);
    const changeAddress = useCheckoutAction('SET_ADDRESS');
    const [open, setOpen] = React.useState(false);
    const partnerId = useSession()?.organization_id;
    const {
        data: addressesRaw,
        loading,
        refetch,
    } = useLabDeliveryAddressesQuery({
        onCompleted: data => {
            const addresses = data.addresses.filter(a => !a.deleted_at);
            if (addresses.length === 1 && (!address || address.id !== addresses[0]?.id)) {
                return changeAddress(addresses[0]);
            }
            if (address && !addresses.find(f => f.id === address.id)) {
                changeAddress(undefined);
            }
        },
    });
    const addresses = addressesRaw?.addresses.filter(a => !a.deleted_at) || [];
    if (addresses.length === 0 && !loading) {
        return (
            <CheckoutContentSection
                title={'Error: No Addresses Found 😔'}
                subtitle={`Uh oh, it looks like you do not have a delivery address on your account.
                Please click the button to add one in your settings`}
            >
                {partnerId && (
                    <EditDeliveryAddressModal
                        // eslint-disable-next-line @typescript-eslint/no-misused-promises
                        onSuccess={() => refetch()}
                        open={open}
                        setOpen={setOpen}
                        partnerId={partnerId}
                    />
                )}
            </CheckoutContentSection>
        );
    }

    return (
        <LoadBlocker blocking={loading}>
            <CheckoutSelectField
                required={false}
                options={addresses.map(d => ({ label: LabsUtils.addressToString(d), value: d.id }))}
                onChange={value => {
                    changeAddress(value ? addresses.find(d => d.id === value) : undefined);
                }}
                label={'Delivery Address'}
                value={address?.id}
                placeholder={'Select Delivery Address'}
                errorText={!address && !!doctor && !loading ? 'Please select an address' : undefined}
                FormHelperTextProps={{ style: { margin: 0, position: 'absolute', bottom: -16 } }}
            />
        </LoadBlocker>
    );
};

export const CheckoutSelectDoctor: React.FC = () => {
    const { profile: staffProfile } = useSelectStaffPropSelector(['profile']);
    const doctor = useCheckoutSelector(s => s.doctor);
    const setDoctor = useCheckoutAction('SET_DOCTOR');
    const goToProfilePage = useGoToPracticeScreen(PracticeScreen.staff);
    const { data: doctorsData, loading, refetch } = useFetchDoctorsQuery();
    const doctors = React.useMemo(() => doctorsData?.fetchDoctors ?? [], [doctorsData?.fetchDoctors]);

    React.useEffect(() => {
        if (doctors.length === 1 && (!doctor || doctor.id !== doctors[0]?.id)) {
            setDoctor(doctors[0]);
            return;
        }
        if (!doctor && staffProfile) {
            const newDoctor = doctors.find(d => d.id === staffProfile?.id);
            if (newDoctor) {
                setDoctor(newDoctor);
            }
            return;
        }
        if (doctor && !doctors.find(f => f.id === doctor.id)) {
            setDoctor(undefined);
        }
    }, [doctor, doctors, setDoctor, staffProfile]);

    if (doctors.length === 0 && !loading) {
        return (
            <CheckoutErrorSection
                title={'Error: No Doctors Registered 😔'}
                subtitle={`Uh oh, it looks like you do not have any doctors on your account. Please try refreshing, or click the button to add one`}
                firstButton={{ text: 'Add a Doctor', onClick: () => goToProfilePage() }}
                secondButton={{
                    text: 'Refresh Doctors List',
                    onClick: () => {
                        if (!loading) {
                            void refetch();
                        }
                    },
                }}
            />
        );
    }
    return (
        <CheckoutContentSection title={'What doctor is ordering?'}>
            <Grid container spacing={2}>
                <Grid container item xs={12} md={6}>
                    <LoadBlocker blocking={loading}>
                        <CheckoutSelectField
                            required={false}
                            options={doctors.map(d => ({ label: d.formatted_name, value: d.id }))}
                            onChange={value => {
                                setDoctor(value ? doctors.find(d => d.id === value) : undefined);
                            }}
                            label={'Doctor'}
                            value={doctor?.id}
                            InputLabelProps={{ shrink: true, focused: true }}
                        />
                    </LoadBlocker>
                </Grid>
                <Grid container item xs={12} md={6}>
                    <CheckoutSelectDeliveryAddress />
                </Grid>
            </Grid>
        </CheckoutContentSection>
    );
};
