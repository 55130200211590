import { AttributeName } from '../../Utils3D/BufferAttributeConstants';
import { getFaceVertexIndices } from '../../Utils3D/Mesh3d.util';
import { base64ToFacetMarks } from './Dcm.utils';
import * as THREE from 'three';

export function bakeFacetMarksIntoGeometry(geometry: THREE.BufferGeometry, facetMarksString: string | undefined) {
    if (!facetMarksString) {
        return;
    }

    const facetMarks = base64ToFacetMarks(facetMarksString);
    if (!facetMarks) {
        return;
    }

    const isCameoArray = Array.from(facetMarks, isCameoFromFacetMark);

    const maskAttribute = geometry.getAttribute(AttributeName.SculptMask);
    if (maskAttribute === undefined) {
        initializeSculptMask(geometry, 1);
        // Unmask the cameo surface.
        unmaskCameo(geometry, isCameoArray);
    }

    const positionAttribute = geometry.getAttribute(AttributeName.Position);

    const nVertices = positionAttribute.count;
    const intaglioAttribute = new THREE.BufferAttribute(new Float32Array(nVertices), 1);
    geometry.setAttribute(AttributeName.IsIntaglio, intaglioAttribute);

    const sealZoneAttribute = new THREE.BufferAttribute(new Float32Array(nVertices), 1);
    geometry.setAttribute(AttributeName.IsSealZone, sealZoneAttribute);

    let index = geometry.getIndex()?.array;
    if (index === undefined) {
        return;
    }
    if (geometry.hasAttribute(AttributeName.BackupIndex)) {
        // if the geometry has a backup index, use it to unmask the cameo surface
        index = geometry.getAttribute(AttributeName.BackupIndex).array;
    }

    const isIntaglioArray = Array.from(facetMarks, isIntaglioFromFacetMark);
    const isSealZoneArray = Array.from(facetMarks, isSealZoneFromFacetMark);

    for (let fi = 0; fi < isIntaglioArray.length; fi++) {
        const isIntaglio = isIntaglioArray[fi];
        const isSealZone = isSealZoneArray[fi];

        const vertexIndices = getFaceVertexIndices(index, fi);

        if (vertexIndices) {
            for (let i = 0; i < vertexIndices.length; ++i) {
                const vi = vertexIndices[i];
                if (vi === undefined) {
                    continue;
                }
                intaglioAttribute.setX(vi, isIntaglio ? 1 : 0);
                sealZoneAttribute.setX(vi, isSealZone ? 1 : 0);
            }
        }
    }
    intaglioAttribute.needsUpdate = true;
    sealZoneAttribute.needsUpdate = true;
}

/**
 * Unmask the cameo surface (i.e. everything that is not the intaglio)
 * @param geometry The geometry to operate on
 * @param isCameoArray The input isCameo flag extracted from facetMarks
 */
function unmaskCameo(geometry: THREE.BufferGeometry, isCameoArray: number[]) {
    const sculptMask = geometry.getAttribute(AttributeName.SculptMask);
    let index = geometry.getIndex()?.array;
    if (index === undefined) {
        return;
    }
    if (geometry.hasAttribute(AttributeName.BackupIndex)) {
        // if the geometry has a backup index, use it to unmask the cameo surface
        index = geometry.getAttribute(AttributeName.BackupIndex).array;
    }
    for (let fi = 0; fi < isCameoArray.length; fi++) {
        const isCameo = isCameoArray[fi];
        const vertexIndices = getFaceVertexIndices(index, fi);
        if (vertexIndices && isCameo) {
            for (let i = 0; i < vertexIndices.length; ++i) {
                const vi = vertexIndices[i];
                if (vi === undefined) {
                    continue;
                }
                sculptMask.setX(vi, 0);
            }
        }
    }

    sculptMask.needsUpdate = true;
}

function initializeSculptMask(geometry: THREE.BufferGeometry, seedValue: number = 0) {
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    if (positionAttribute === undefined) {
        return;
    }
    let maskAttribute = geometry.getAttribute(AttributeName.SculptMask);

    const nVertices = positionAttribute.count;
    if (maskAttribute === undefined) {
        maskAttribute = new THREE.BufferAttribute(new Float32Array(nVertices), 1);
        geometry.setAttribute(AttributeName.SculptMask, maskAttribute);
    }

    // reset mask layer to the seed value
    for (let i = 0; i < nVertices; i++) {
        maskAttribute.setX(i, seedValue);
    }

    maskAttribute.needsUpdate = true;
}

// More info about facetMarks here: https://www.notion.so/orthly/Near-Margin-Region-Facet-Marks-7cb01930372349bd8a82ab498d0b971f
function isCameoFromFacetMark(facetMark: number): number {
    // Extract bit 20 of the facetMark to indicate if the facet is a cameo facet
    return (facetMark >> 20) & 1;
}

function isIntaglioFromFacetMark(facetMark: number): boolean {
    // Extract bits 17-18 of the facetMark to indicate if the facet is an intaglio facet
    return (facetMark & 0x00060000) !== 0;
}

function isSealZoneFromFacetMark(facetMark: number): number {
    // Extract bit 17 of the facetMark to indicate if the facet is a seal zone facet
    return (facetMark >> 17) & 1;
}
