import type { LabsGqlDoctorRequestFragment, LabsGqlOrder } from '@orthly/graphql-operations';
import { useGetDoctorRequestQuery } from '@orthly/graphql-react';
import { LabsGqlDoctorRequestOptionType, LabsGqlLabOrderStatus } from '@orthly/graphql-schema';

const getActiveDoctorRequestId = (order: Pick<LabsGqlOrder, 'fulfillment_workflow'>) => {
    let doctorRequestId: string | null = null;

    if (
        order.fulfillment_workflow.active_task?.__typename === 'ResolveScanRejectionWorkflowTask' ||
        order.fulfillment_workflow.active_task?.__typename === 'ResolveHoldWorkflowTask'
    ) {
        doctorRequestId = order.fulfillment_workflow.active_task.configuration.doctor_request_id;
    }

    return doctorRequestId;
};

const getHoldInformationFromDoctorRequestResponse = (
    doctorRequest: LabsGqlDoctorRequestFragment | null | undefined,
) => {
    if (doctorRequest) {
        const selectedResponseOptionId = doctorRequest.doctor_request_response?.selected_doctor_request_option_id;
        const selectedOption = doctorRequest.options.find(o => o.id === selectedResponseOptionId);

        if (selectedOption?.type === LabsGqlDoctorRequestOptionType.NeedsAdditionalScans) {
            return {
                needsAdditionalScans: true,
            };
        }
    }

    return {
        needsAdditionalScans: false,
    };
};

export type DoctorRequestHoldInfo = {
    needsAdditionalScans: boolean;
    isDoctorRequestLoading: boolean;
};

export const useDoctorRequestHoldInfo = (
    order: Pick<LabsGqlOrder, 'fulfillment_workflow' | 'status'>,
): DoctorRequestHoldInfo => {
    const isOrderOnHold = order.status === LabsGqlLabOrderStatus.OnHold;
    const doctorRequestId = getActiveDoctorRequestId(order);
    const isQualifyingOrder = isOrderOnHold && doctorRequestId;

    const { data, loading } = useGetDoctorRequestQuery({
        variables: { doctorRequestId: doctorRequestId ?? '' },
        skip: !isQualifyingOrder,
        fetchPolicy: 'no-cache',
    });

    if (!isQualifyingOrder) {
        return {
            isDoctorRequestLoading: false,
            needsAdditionalScans: false,
        };
    }

    const { needsAdditionalScans } = getHoldInformationFromDoctorRequestResponse(data?.getDoctorRequest);

    return {
        isDoctorRequestLoading: loading,
        needsAdditionalScans,
    };
};
