import type { Point3DArray } from '../ModelViewer/ModelViewerTHREETypes';
import type { CrossSectionPlane } from './CrossSectionData';
import { Line } from '@react-three/drei';
import React from 'react';
import * as THREE from 'three';
import type { Group } from 'three';
import { EllipseCurve, Vector3 } from 'three';

type CrossSectionPlaneMeshType = React.FC<{
    csPlane: CrossSectionPlane;
    size?: number;
}>;

export const CROSS_SECTION_MESH_SIZE = 40;
export const CROSS_SECTION_MESH_ASYMMETRY = 0.6;

/**
 * Draws an ellipse which position and orientation
 * match Cross Section plane
 *
 * @component
 */
export const CrossSectionPlaneMesh: CrossSectionPlaneMeshType = ({ csPlane, size }) => {
    const _size = size || CROSS_SECTION_MESH_SIZE;
    const segments = 50;

    const planeMeshRef = React.useRef<Group>();

    const planeScale = new Vector3(_size * 0.6, _size, _size * 0.6);

    React.useEffect(() => {
        if (!csPlane) {
            return;
        }

        planeMeshRef.current?.position.copy(csPlane.position);
        planeMeshRef.current?.quaternion.copy(csPlane.orientation);
    }, [csPlane]);

    const outlineCurve = new EllipseCurve(0, 0, 1, 1, 0, 2 * Math.PI, false, 0);
    const points = outlineCurve.getPoints(segments).map(p => [p.x, p.y, 0.0] as Point3DArray);

    return (
        <group ref={planeMeshRef} scale={planeScale} visible={csPlane !== undefined}>
            <mesh>
                <circleBufferGeometry attach={'geometry'} args={[1, segments]} />
                <meshBasicMaterial
                    attach={'material'}
                    side={THREE.DoubleSide}
                    opacity={0.5}
                    transparent={true}
                    color={'#80deea'}
                />
            </mesh>
            <Line isShaderMaterial={false} points={points} color={'#127094'} linewidth={0.5} />
        </group>
    );
};
