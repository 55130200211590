import { IDENTITY_MATRIX } from '../../Utils3D';
import { DcmGeometryInjector } from '../DcmFiles';
import type { ParsedCaseResult } from '../DesignCaseFile';
import { cleanXmlNameForComparison } from '../LegacyCaseParsing';
import type { AnatomyDcmProcessingIntermediary, DcmProcessingIntermediary } from './DesignZipReading.types';
import type { ThreeShapeDesignTransformations } from './DesignZipTypes';
import type { ToothNumber } from '@orthly/items';
import { Jaw } from '@orthly/shared-types';
import type JsZip from 'jszip';
import _ from 'lodash';
import path from 'path';
import type * as THREE from 'three';

export async function getDcmInjector(
    dcmFile: JsZip.JSZipObject,
    errorMessages: string[],
): Promise<DcmGeometryInjector | undefined> {
    const contents = await dcmFile.async('string');
    const injector = DcmGeometryInjector.tryBuildDCM(contents);

    if (injector) {
        return injector;
    }

    errorMessages.push(`Could not parse file as DCM: ${dcmFile.name}`);
}

export async function parseDcmsToIntermediary(
    parsedCase: ParsedCaseResult,
    designRootFolderName: string,
    cadFiles: [string, JsZip.JSZipObject][],
    errorMessages: string[],
): Promise<DcmProcessingIntermediary[]> {
    return _.compact(
        await Promise.all(
            cadFiles.map(async entry => {
                const matchedModelElement = parsedCase.modelElements.find(
                    modelElement =>
                        cleanXmlNameForComparison(entry[0]) ===
                        cleanXmlNameForComparison(`${designRootFolderName}/${modelElement.modelFilePath}`),
                );

                const dcm = await getDcmInjector(entry[1], errorMessages);
                return dcm ? { fileName: entry[0], dcm, modelElement: matchedModelElement } : undefined;
            }),
        ),
    );
}

export async function parseAnatomyDcmsToIntermediary(
    cadFiles: [string, JsZip.JSZipObject][],
    errorMessages: string[],
): Promise<AnatomyDcmProcessingIntermediary[]> {
    return _.compact(
        await Promise.all(
            cadFiles.map(async entry => {
                const dcm = await getDcmInjector(entry[1], errorMessages);
                const dcmBasename = path.basename(entry[0]);
                const toothNumber = parseInt(dcmBasename.replace(/^\D+/g, '') ?? '0') as ToothNumber;
                return dcm ? { fileName: entry[0], dcm, toothNumber } : undefined;
            }),
        ),
    );
}

// The design and jaw context sensitive transform logic
export function getCadTransform(
    designTransforms: ThreeShapeDesignTransformations,
    variant: 'FIXED' | 'DENTURE',
    jaw: Jaw,
): THREE.Matrix4 | undefined {
    if (variant === 'FIXED') {
        if (jaw === Jaw.UPPER) {
            return designTransforms.upperAlignToBite ?? designTransforms.upperJawToLowerJaw ?? IDENTITY_MATRIX;
        }
        return undefined;
    }

    // The denture logic here is empirically derived. It seems to hinge on whether
    // the TransformFromAlignmentCoordinatesGlobal exists in the MB scans.
    switch (jaw) {
        case Jaw.UPPER:
            return (
                (designTransforms.upperTransformationFromAlignmentCoordinatesGlobal
                    ? designTransforms.upperJawToLowerJaw
                    : designTransforms.upperAlignToBite) ?? IDENTITY_MATRIX
            );
        case Jaw.LOWER:
            return designTransforms.lowerTransformationFromAlignmentCoordinatesGlobal
                ? IDENTITY_MATRIX
                : designTransforms.lowerAlignToBite ?? IDENTITY_MATRIX;
        default:
            return undefined;
    }
}
