// This file is meant for internal utilites used by PrismCaster
import { PlaneSide } from './PrismCaster.types';

export enum PlaneID {
    Base = 0,
    A = 1,
    AB = 2,
    B = 3,
    BC = 4,
    C = 5,
    CA = 6,
}

export type EdgePlane = PlaneID.AB | PlaneID.BC | PlaneID.CA;
export type VertPlane = PlaneID.A | PlaneID.B | PlaneID.C;

export function boxPlaneSide(box: THREE.Box3, plane: THREE.Plane): PlaneSide {
    // Compute the dot products of the vertex nearest and furthest along the plane normal
    let min;
    let max;

    if (plane.normal.x > 0) {
        min = plane.normal.x * box.min.x;
        max = plane.normal.x * box.max.x;
    } else {
        min = plane.normal.x * box.max.x;
        max = plane.normal.x * box.min.x;
    }

    if (plane.normal.y > 0) {
        min += plane.normal.y * box.min.y;
        max += plane.normal.y * box.max.y;
    } else {
        min += plane.normal.y * box.max.y;
        max += plane.normal.y * box.min.y;
    }

    if (plane.normal.z > 0) {
        min += plane.normal.z * box.min.z;
        max += plane.normal.z * box.max.z;
    } else {
        min += plane.normal.z * box.max.z;
        max += plane.normal.z * box.min.z;
    }

    // The plane is defined to be the set of points, `p`, s.t. `p·n + C == 0`
    // A point is "in front" of the plane if `p·n + C > 0`

    if (min <= -plane.constant) {
        return -plane.constant <= max ? PlaneSide.Crossing : PlaneSide.Behind;
    } else {
        return PlaneSide.Front;
    }
}

export function trianglePlaneSide(triangle: THREE.Triangle, plane: THREE.Plane): PlaneSide {
    let min = Infinity;
    let max = -Infinity;
    for (const pt of [triangle.a, triangle.b, triangle.c]) {
        const d = pt.dot(plane.normal) + plane.constant;
        min = Math.min(min, d);
        max = Math.max(max, d);
    }

    if (min < 0) {
        return 0 <= max ? PlaneSide.Crossing : PlaneSide.Behind;
    }
    return PlaneSide.Front;
}
