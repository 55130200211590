/*
 *  This module provides helpers to give a simplified control of
 *  NewModelViewer Appearance.  Scene state management at the QC
 *  level requires granular control over per object appearance and
 *  visibility that is often not required in other viewer use cases
 *
 * */
import { isPreExtractionScanItem } from '../ModelViewer';
import { Jaw } from '../ModelViewer/ModelViewerTypes';
import type { ModelAppearance, PayloadModelAppearance, SetAppearanceType } from './ModelAppearanceTypes';
import { isParentCadVisible } from './ModelVisibilityToggler';
import { ToothUtils } from '@orthly/items';
import React from 'react';

export interface LimitedAppearanceFilterBools {
    showColor: boolean;
    showUpper: boolean;
    showLower: boolean;
    showScans: boolean;
    showRestos: boolean;
    showPreExtractionScans?: boolean;
}

export const INITIAL_APPEARANCE_FILTER: LimitedAppearanceFilterBools = {
    showColor: true,
    showUpper: true,
    showLower: true,
    showScans: true,
    showRestos: true,
};

export const GUIDED_WAXUP_APPEARANCE_FILTER: LimitedAppearanceFilterBools = {
    ...INITIAL_APPEARANCE_FILTER,
    showPreExtractionScans: false,
};
function itemVisibleForJawFilter(pma: PayloadModelAppearance, filterValue: LimitedAppearanceFilterBools): boolean {
    const isUpper =
        pma.payloadModel.jaw === Jaw.Upper ||
        (pma.payloadModel.unns && pma.payloadModel.unns.some(unn => ToothUtils.toothIsUpper(unn)));
    const isLower =
        pma.payloadModel.jaw === Jaw.Lower ||
        (pma.payloadModel.unns && pma.payloadModel.unns.some(unn => !ToothUtils.toothIsUpper(unn)));
    return ((filterValue.showUpper && isUpper) || (filterValue.showLower && isLower)) ?? false;
}

function applyAppearanceFilterToItem(
    pma: PayloadModelAppearance,
    filterValue: LimitedAppearanceFilterBools,
): PayloadModelAppearance {
    const itemJawVisible = itemVisibleForJawFilter(pma, filterValue);
    const itemTypeVisible =
        (filterValue.showRestos && pma.payloadModel.isRestorative) ||
        (filterValue.showScans && !pma.payloadModel.isRestorative && !isPreExtractionScanItem(pma.payloadModel)) ||
        (!!filterValue.showPreExtractionScans && isPreExtractionScanItem(pma.payloadModel));

    return {
        ...pma,
        appearance: {
            ...pma.appearance,
            visible: itemTypeVisible && itemJawVisible,
            colorize: filterValue.showColor,
        },
    };
}

export function applyAppearanceFilter(
    currentAppearance: ModelAppearance,
    filterValue: LimitedAppearanceFilterBools,
): ModelAppearance {
    const upperJaw = currentAppearance.upperJaw.map((pma: PayloadModelAppearance) =>
        applyAppearanceFilterToItem(pma, filterValue),
    );
    const lowerJaw = currentAppearance.lowerJaw.map((pma: PayloadModelAppearance) =>
        applyAppearanceFilterToItem(pma, filterValue),
    );
    const scans = currentAppearance.scans.map((pma: PayloadModelAppearance) =>
        applyAppearanceFilterToItem(pma, filterValue),
    );
    const preExtractionScans = currentAppearance.scans.map((pma: PayloadModelAppearance) =>
        applyAppearanceFilterToItem(pma, filterValue),
    );

    const cadRestoratives = currentAppearance.restoratives['CAD'].map((pma: PayloadModelAppearance) =>
        applyAppearanceFilterToItem(pma, filterValue),
    );
    const restoratives = {
        CAD: cadRestoratives,
        HeatMap: currentAppearance.restoratives['HeatMap'].map((pma: PayloadModelAppearance) => {
            return {
                ...pma,
                appearance: {
                    ...pma.appearance,
                    visible: isParentCadVisible(pma, cadRestoratives) && itemVisibleForJawFilter(pma, filterValue),
                },
            };
        }),
    };

    // Things we don't show in this limited simple view
    // however we don't want to obliterate them in case
    // we go back to the more complex view states
    const printedModels = currentAppearance.printedModels.map((pma: PayloadModelAppearance) => {
        return { ...pma, appearance: { ...pma.appearance, visible: false } };
    });

    return {
        ...currentAppearance,
        upperJaw,
        lowerJaw,
        scans,
        restoratives,
        printedModels,
        solo: [],
        preExtractionScans,
    };
}

export interface ExternalAppearanceApp {
    appearanceFilterValues: LimitedAppearanceFilterBools;
    toggleColor: () => void;
}

export function useExternalAppearanceFilters(setAppearance: SetAppearanceType): ExternalAppearanceApp {
    const [appearanceFilterValues, setAppearanceFilterValues] =
        React.useState<LimitedAppearanceFilterBools>(INITIAL_APPEARANCE_FILTER);

    const toggleColor = React.useCallback(() => {
        setAppearanceFilterValues(currentFilter => {
            return { ...currentFilter, showColor: !currentFilter.showColor };
        });
    }, [setAppearanceFilterValues]);

    React.useEffect(() => {
        setAppearance(currentAppearance => applyAppearanceFilter(currentAppearance, appearanceFilterValues));
    }, [appearanceFilterValues, setAppearance]);

    return { appearanceFilterValues, toggleColor };
}
