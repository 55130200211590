/* eslint-disable max-lines */
import type { ICameraControls } from './CameraControls.types';
import type { PerspectiveCamera, OrthographicCamera } from 'three';
import { EventDispatcher, MOUSE, Quaternion, Vector2, Vector3 } from 'three';

/**** DANDY CUSTOMIZATION ****/
// Computes the 3D point corresponding to a certain mouse position on the screen
export function get3DPointFromMousePosition(vector: Vector2, camera: PerspectiveCamera | OrthographicCamera): Vector3 {
    const vector3D = new Vector3(
        vector.x * 2 - 1,
        -vector.y * 2 + 1,
        camera.near !== camera.far ? (camera.near + camera.far) / (camera.near - camera.far) : 0,
    );
    vector3D.unproject(camera);
    return vector3D;
}

// originally taken from:
// https://github.com/pmndrs/three-stdlib/blob/v2.5.9/src/controls/TrackballControls.ts
// Dandy customizations marked with /**** DANDY CUSTOMIZATION ****/ comments
class TrackballControls extends EventDispatcher implements ICameraControls {
    public enabled = true;

    public screen = { left: 0, top: 0, width: 0, height: 0 };

    public rotateSpeed = 1.0;
    public zoomSpeed = 1.2;
    public panSpeed = 0.3;

    public noRotate = false;
    public noZoom = false;
    public noPan = false;

    public staticMoving = false;
    public dynamicDampingFactor = 0.2;

    public minDistance = 0;
    public maxDistance = Infinity;

    /**** DANDY CUSTOMIZATION ****/
    // maximum zoom for orthographic camera
    public maxZoom = 500;

    public keys: [string, string, string] = ['KeyA' /*A*/, 'KeyS' /*S*/, 'KeyD' /*D*/];

    public mouseButtons = {
        LEFT: MOUSE.ROTATE,
        MIDDLE: MOUSE.DOLLY,
        RIGHT: MOUSE.PAN,
        LEFT_ALT: MOUSE.PAN,
    };

    public object: PerspectiveCamera | OrthographicCamera;
    public domElement: HTMLElement | undefined;

    public target = new Vector3();

    // internals
    private STATE = {
        NONE: -1,
        ROTATE: 0,
        ZOOM: 1,
        PAN: 2,
        TOUCH_ROTATE: 3,
        TOUCH_ZOOM_PAN: 4,
    };

    private EPS = 0.000001;

    private lastPosition = new Vector3();
    private lastZoom = 1;

    private _state = this.STATE.NONE;
    private _keyState = this.STATE.NONE;
    private _eye = new Vector3();
    private _movePrev = new Vector2();
    private _moveCurr = new Vector2();
    private _lastAxis = new Vector3();
    private _lastAngle = 0;
    private _zoomStart = new Vector2();
    private _zoomEnd = new Vector2();
    private _touchZoomDistanceStart = 0;
    private _touchZoomDistanceEnd = 0;
    private _panStart = new Vector2();
    private _panEnd = new Vector2();

    /**** DANDY CUSTOMIZATION ****/
    private _zoomCursorPosition = new Vector3();

    private target0: Vector3;
    private position0: Vector3;
    private up0: Vector3;
    private zoom0: number;
    private enableIdenticalPanningBehaviour?: boolean;

    // events

    private readonly changeEvent = { type: 'change' };
    private readonly startEvent = { type: 'start' };
    private readonly endEvent = { type: 'end' };
    private readonly zoomChangeEvent = { type: 'zoomChange' };

    private readonly ZOOM_CHANGE_EMIT_THRESHOLD = 1e-3;

    constructor(
        object: PerspectiveCamera | OrthographicCamera,
        enableIdenticalPanningBehaviour?: boolean,
        domElement?: HTMLElement,
    ) {
        super();
        this.object = object;
        this.enableIdenticalPanningBehaviour = enableIdenticalPanningBehaviour;
        // for reset

        this.target0 = this.target.clone();
        this.position0 = this.object.position.clone();
        this.up0 = this.object.up.clone();
        this.zoom0 = this.object.zoom;

        // connect events
        if (domElement !== undefined) {
            this.connect(domElement);
        }

        // force an update at start
        this.update();
    }

    private onScreenVector = new Vector2();

    private getMouseOnScreen = (pageX: number, pageY: number): Vector2 => {
        this.onScreenVector.set(
            (pageX - this.screen.left) / this.screen.width,
            (pageY - this.screen.top) / this.screen.height,
        );

        return this.onScreenVector;
    };

    private onCircleVector = new Vector2();

    private getMouseOnCircle = (pageX: number, pageY: number): Vector2 => {
        this.onCircleVector.set(
            (pageX - this.screen.width * 0.5 - this.screen.left) / (this.screen.width * 0.5),
            (this.screen.height + 2 * (this.screen.top - pageY)) / this.screen.width, // screen.width intentional
        );

        return this.onCircleVector;
    };

    private axis = new Vector3();
    private eyeDirection = new Vector3();
    private objectUpDirection = new Vector3();
    private objectSidewaysDirection = new Vector3();
    private moveDirection = new Vector3();
    private angle: number = 0;
    private quaternion = new Quaternion();

    private rotateCamera = (): void => {
        this.moveDirection.set(this._moveCurr.x - this._movePrev.x, this._moveCurr.y - this._movePrev.y, 0);
        this.angle = this.moveDirection.length();

        if (this.angle) {
            this._eye.copy(this.object.position).sub(this.target);

            this.eyeDirection.copy(this._eye).normalize();
            this.objectUpDirection.copy(this.object.up).normalize();
            this.objectSidewaysDirection.crossVectors(this.objectUpDirection, this.eyeDirection).normalize();

            this.objectUpDirection.setLength(this._moveCurr.y - this._movePrev.y);
            this.objectSidewaysDirection.setLength(this._moveCurr.x - this._movePrev.x);

            this.moveDirection.copy(this.objectUpDirection.add(this.objectSidewaysDirection));

            this.axis.crossVectors(this.moveDirection, this._eye).normalize();

            this.angle *= this.rotateSpeed;
            this.quaternion.setFromAxisAngle(this.axis, this.angle);

            this.object.up.applyQuaternion(this.quaternion);

            /**** DANDY CUSTOMIZATION ****/
            // HotFix:  we previously apply the rotation to both target and position to
            // match 3Shape rotation behavior.  However,hotfix to revert it because
            // of specific use case in QC flow for zooming in on isolated objects (crown, margin)
            // and rotating around that camera target. Because we do not specify a rotation center
            // configuraiton for controls or the scene, the only way to satisfy this requirement
            // is to not rotate the controls target for the time being.
            // this.target.applyQuaternion(this.quaternion);
            this.object.position.applyQuaternion(this.quaternion);

            this._eye.copy(this.object.position).sub(this.target);

            this._lastAxis.copy(this.axis);
            this._lastAngle = this.angle;
        } else if (!this.staticMoving && this._lastAngle) {
            this._lastAngle *= Math.sqrt(1.0 - this.dynamicDampingFactor);
            this.quaternion.setFromAxisAngle(this._lastAxis, this._lastAngle);

            /**** DANDY CUSTOMIZATION ****/
            // HotFix see comment above about rotation of target
            this.object.position.applyQuaternion(this.quaternion);
            this._eye.copy(this.object.position).sub(this.target);
            this.object.up.applyQuaternion(this.quaternion);
        }

        this._movePrev.copy(this._moveCurr);
    };

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    private zoomCamera = (): void => {
        if (!this.domElement) {
            return;
        }
        let factor = 1.0;

        if (this._state === this.STATE.TOUCH_ZOOM_PAN) {
            factor = this._touchZoomDistanceStart / this._touchZoomDistanceEnd;
            this._touchZoomDistanceStart = this._touchZoomDistanceEnd;

            if ((this.object as PerspectiveCamera).isPerspectiveCamera) {
                this._eye.multiplyScalar(factor);
            } else if ((this.object as OrthographicCamera).isOrthographicCamera) {
                this.object.zoom /= factor;
                this.object.updateProjectionMatrix();
            } else {
                console.warn('THREE.TrackballControls: Unsupported camera type');
            }
        } else {
            factor = 1.0 + (this._zoomEnd.y - this._zoomStart.y) * this.zoomSpeed;

            if (factor !== 1.0 && factor > 0.0) {
                if ((this.object as PerspectiveCamera).isPerspectiveCamera) {
                    this._eye.multiplyScalar(factor);
                } else if ((this.object as OrthographicCamera).isOrthographicCamera) {
                    if (factor > 1.0 && this.object.zoom < this.maxDistance * this.maxDistance) {
                        factor = 1.0;
                    }
                    if (factor < 1.0 && this.object.zoom > this.maxZoom) {
                        factor = 1.0;
                    }
                    /**** DANDY CUSTOMIZATION ****/
                    // We want to zoom on the current mouse position. Previously, by default it only changes the zoom here
                    // but we want to pan the camera in the opposite direction (difference between mouse position before and after)
                    // TODO: Apply similar computation for perspective camera
                    const mouse3D_before = this._zoomCursorPosition.clone().unproject(this.object);
                    this.object.zoom /= factor;
                    this.object.updateProjectionMatrix();
                    const mouse3D = this._zoomCursorPosition.clone().unproject(this.object).sub(mouse3D_before);
                    this.pan.copy(mouse3D.negate());
                    this.object.position.add(this.pan);
                    this.target.add(this.pan);
                } else {
                    console.warn('THREE.TrackballControls: Unsupported camera type');
                }
            }

            if (this.staticMoving) {
                this._zoomStart.copy(this._zoomEnd);
            } else {
                this._zoomStart.y += (this._zoomEnd.y - this._zoomStart.y) * this.dynamicDampingFactor;
            }
        }

        if (Math.abs(factor - 1.0) > this.ZOOM_CHANGE_EMIT_THRESHOLD) {
            this.dispatchEvent(this.zoomChangeEvent);
        }
    };

    private mouseChange = new Vector2();
    private pan = new Vector3();

    private panCamera = (): void => {
        if (!this.domElement) {
            return;
        }
        this.mouseChange.copy(this._panEnd).sub(this._panStart);

        if (this.mouseChange.lengthSq()) {
            /**** DANDY CUSTOMIZATION ****/
            // 1/1 panning behavior without damping effect
            const panStart3D = get3DPointFromMousePosition(this._panStart, this.object);
            const panEnd3D = get3DPointFromMousePosition(this._panEnd, this.object);
            const change3D = panStart3D.sub(panEnd3D);
            this.pan.copy(change3D);

            this.object.position.add(this.pan);
            this.target.add(this.pan);

            // use static moving on pan
            this._panStart.copy(this._panEnd);
        }
    };

    private checkDistances = (): void => {
        if (!this.noZoom || !this.noPan) {
            if (this._eye.lengthSq() > this.maxDistance * this.maxDistance) {
                this.object.position.addVectors(this.target, this._eye.setLength(this.maxDistance));
                this._zoomStart.copy(this._zoomEnd);
            }

            if (this._eye.lengthSq() < this.minDistance * this.minDistance) {
                this.object.position.addVectors(this.target, this._eye.setLength(this.minDistance));
                this._zoomStart.copy(this._zoomEnd);
            }
        }
    };

    /**** DANDY CUSTOMIZATION ****/
    // public function to test rotating the camera by mouse move
    public doRotateCamera(fromMousePosition: Vector2, toMousePosition: Vector2) {
        this._movePrev.copy(this.getMouseOnCircle(fromMousePosition.x, fromMousePosition.y));
        this._moveCurr.copy(this.getMouseOnCircle(toMousePosition.x, toMousePosition.y));
        this.update();
    }

    public handleResize = (): void => {
        if (!this.domElement) {
            return;
        }
        const box = this.domElement.getBoundingClientRect();
        // adjustments come from similar code in the jquery offset() function
        const d = this.domElement.ownerDocument.documentElement;
        this.screen.left = box.left + window.pageXOffset - d.clientLeft;
        this.screen.top = box.top + window.pageYOffset - d.clientTop;
        this.screen.width = box.width;
        this.screen.height = box.height;
    };

    public update = (): void => {
        this._eye.subVectors(this.object.position, this.target);

        if (!this.noRotate) {
            this.rotateCamera();
        }

        if (!this.noZoom) {
            this.zoomCamera();
        }

        if (!this.noPan) {
            this.panCamera();
        }

        this.object.position.addVectors(this.target, this._eye);

        if ((this.object as PerspectiveCamera).isPerspectiveCamera) {
            this.checkDistances();

            this.object.lookAt(this.target);

            if (this.lastPosition.distanceToSquared(this.object.position) > this.EPS) {
                this.dispatchEvent(this.changeEvent);

                this.lastPosition.copy(this.object.position);
            }
        } else if ((this.object as OrthographicCamera).isOrthographicCamera) {
            this.object.lookAt(this.target);

            if (
                this.lastPosition.distanceToSquared(this.object.position) > this.EPS ||
                this.lastZoom !== this.object.zoom
            ) {
                this.dispatchEvent(this.changeEvent);

                this.lastPosition.copy(this.object.position);
                this.lastZoom = this.object.zoom;
            }
        } else {
            console.warn('THREE.TrackballControls: Unsupported camera type');
        }
    };

    public reset = (): void => {
        this._state = this.STATE.NONE;
        this._keyState = this.STATE.NONE;

        this.target.copy(this.target0);
        this.object.position.copy(this.position0);
        this.object.up.copy(this.up0);
        this.object.zoom = this.zoom0;

        this.object.updateProjectionMatrix();

        this._eye.subVectors(this.object.position, this.target);

        this.object.lookAt(this.target);

        this.dispatchEvent(this.changeEvent);

        this.lastPosition.copy(this.object.position);
        this.lastZoom = this.object.zoom;
    };

    private keydown = (event: KeyboardEvent): void => {
        if (this.enabled === false) {
            return;
        }

        window.removeEventListener('keydown', this.keydown);

        if (this._keyState !== this.STATE.NONE) {
            return;
        } else if (event.code === this.keys[this.STATE.ROTATE] && !this.noRotate) {
            this._keyState = this.STATE.ROTATE;
        } else if (event.code === this.keys[this.STATE.ZOOM] && !this.noZoom) {
            this._keyState = this.STATE.ZOOM;
        } else if (event.code === this.keys[this.STATE.PAN] && !this.noPan) {
            this._keyState = this.STATE.PAN;
        }
    };

    private onPointerDown = (event: PointerEvent): void => {
        if (this.enabled === false) {
            return;
        }

        switch (event.pointerType) {
            case 'mouse':
            case 'pen':
                this.onMouseDown(event);
                break;

            // TODO touch
        }
    };

    private onPointerMove = (event: PointerEvent): void => {
        if (this.enabled === false) {
            return;
        }

        switch (event.pointerType) {
            case 'mouse':
            case 'pen':
                this.onMouseMove(event);
                break;

            // TODO touch
        }
    };

    private onPointerUp = (event: PointerEvent): void => {
        if (this.enabled === false) {
            return;
        }

        switch (event.pointerType) {
            case 'mouse':
            case 'pen':
                this.onMouseUp();
                break;

            // TODO touch
        }
    };

    private keyup = (): void => {
        if (this.enabled === false) {
            return;
        }

        this._keyState = this.STATE.NONE;

        window.addEventListener('keydown', this.keydown);
    };

    private onMouseDown = (event: MouseEvent): void => {
        if (!this.domElement) {
            return;
        }
        if (this._state === this.STATE.NONE) {
            /**** DANDY CUSTOMIZATION ****/
            // fix the way the mouseButtons are handled to make it more readable and allow two buttons to do the same action
            let nextMouseAction: THREE.MOUSE = -1;
            switch (event.button) {
                case 0: // left mouse key clicked
                    if (this.enableIdenticalPanningBehaviour ? !event.shiftKey : !event.altKey) {
                        nextMouseAction = this.mouseButtons.LEFT;
                    } else {
                        nextMouseAction = this.mouseButtons.LEFT_ALT;
                    }
                    break;
                case 1: // middle mouse key clicked
                    nextMouseAction = this.mouseButtons.MIDDLE;
                    break;
                case 2: // right mouse key clicked
                    nextMouseAction = this.mouseButtons.RIGHT;
                    break;
            }
            switch (nextMouseAction) {
                case MOUSE.ROTATE:
                    this._state = this.STATE.ROTATE;
                    break;
                case MOUSE.DOLLY:
                    this._state = this.STATE.ZOOM;
                    break;
                case MOUSE.PAN:
                    this._state = this.STATE.PAN;
                    break;
                default:
                    this._state = this.STATE.NONE;
            }
        }

        const state = this._keyState !== this.STATE.NONE ? this._keyState : this._state;

        if (state === this.STATE.ROTATE && !this.noRotate) {
            this._moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
            this._movePrev.copy(this._moveCurr);
        } else if (state === this.STATE.ZOOM && !this.noZoom) {
            this._zoomStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
            this._zoomEnd.copy(this._zoomStart);
        } else if (state === this.STATE.PAN && !this.noPan) {
            this._panStart.copy(this.getMouseOnScreen(event.pageX, event.pageY));
            this._panEnd.copy(this._panStart);
        }

        this.domElement.ownerDocument.addEventListener('pointermove', this.onPointerMove);
        this.domElement.ownerDocument.addEventListener('pointerup', this.onPointerUp);

        this.dispatchEvent(this.startEvent);
        event.preventDefault();
    };

    private onMouseMove = (event: MouseEvent): void => {
        if (this.enabled === false) {
            return;
        }

        const state = this._keyState !== this.STATE.NONE ? this._keyState : this._state;

        if (state === this.STATE.ROTATE && !this.noRotate) {
            this._movePrev.copy(this._moveCurr);
            this._moveCurr.copy(this.getMouseOnCircle(event.pageX, event.pageY));
        } else if (state === this.STATE.ZOOM && !this.noZoom) {
            this._zoomEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        } else if (state === this.STATE.PAN && !this.noPan) {
            this._panEnd.copy(this.getMouseOnScreen(event.pageX, event.pageY));
        }
        event.preventDefault();
    };

    private onMouseUp = (): void => {
        if (!this.domElement) {
            return;
        }
        if (this.enabled === false) {
            return;
        }

        this._state = this.STATE.NONE;

        this.domElement.ownerDocument.removeEventListener('pointermove', this.onPointerMove);
        this.domElement.ownerDocument.removeEventListener('pointerup', this.onPointerUp);

        this.dispatchEvent(this.endEvent);
    };

    private mousewheel = (event: WheelEvent): void => {
        if (this.enabled === false) {
            return;
        }

        if (this.noZoom === true) {
            return;
        }

        if (!this.domElement) {
            return;
        }

        event.preventDefault();

        switch (event.deltaMode) {
            case 2:
                // Zoom in pages
                this._zoomStart.y -= event.deltaY * 0.025;
                break;

            case 1:
                // Zoom in lines
                this._zoomStart.y -= event.deltaY * 0.01;
                break;

            default:
                // undefined, 0, assume pixels
                this._zoomStart.y -= event.deltaY * 0.00025;
                break;
        }

        /**** DANDY CUSTOMIZATION ****/
        // on mouse wheel, record the mouse position in screen in NDC coordinates
        const mouse = this.getMouseOnScreen(event.pageX, event.pageY);
        this._zoomCursorPosition = new Vector3(
            mouse.x * 2 - 1,
            -mouse.y * 2 + 1,
            this.object.near !== this.object.far
                ? (this.object.near + this.object.far) / (this.object.near - this.object.far)
                : 0,
        );

        this.dispatchEvent(this.startEvent);
        this.dispatchEvent(this.endEvent);
    };

    private touchstart = (event: TouchEvent): void => {
        if (this.enabled === false) {
            return;
        }

        event.preventDefault();

        const touches0PageX = event.touches[0]?.pageX ?? 0;
        const touches0PageY = event.touches[0]?.pageY ?? 0;
        const touches1PageX = event.touches[1]?.pageX ?? 0;
        const touches1PageY = event.touches[1]?.pageY ?? 0;

        if (event.touches.length === 1) {
            this._state = this.STATE.TOUCH_ROTATE;
            this._moveCurr.copy(this.getMouseOnCircle(touches0PageX, touches0PageY));
            this._movePrev.copy(this._moveCurr);
        } else if (event.touches.length >= 2) {
            this._state = this.STATE.TOUCH_ZOOM_PAN;
            const dx = touches0PageX - touches1PageX;
            const dy = touches0PageY - touches1PageY;
            this._touchZoomDistanceEnd = this._touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy);

            const x = (touches0PageX + touches1PageX) / 2;
            const y = (touches0PageY + touches1PageY) / 2;
            this._panStart.copy(this.getMouseOnScreen(x, y));
            this._panEnd.copy(this._panStart);
        }

        this.dispatchEvent(this.startEvent);
    };

    private touchmove = (event: TouchEvent): void => {
        if (this.enabled === false) {
            return;
        }

        event.preventDefault();

        const touches0PageX = event.touches[0]?.pageX ?? 0;
        const touches0PageY = event.touches[0]?.pageY ?? 0;
        const touches1PageX = event.touches[1]?.pageX ?? 0;
        const touches1PageY = event.touches[1]?.pageY ?? 0;

        if (event.touches.length === 1) {
            this._movePrev.copy(this._moveCurr);
            this._moveCurr.copy(this.getMouseOnCircle(touches0PageX, touches0PageY));
        } else if (event.touches.length >= 2) {
            const dx = touches0PageX - touches1PageX;
            const dy = touches0PageY - touches1PageY;
            this._touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy);

            const x = (touches0PageX + touches1PageX) / 2;
            const y = (touches0PageY + touches1PageY) / 2;
            this._panEnd.copy(this.getMouseOnScreen(x, y));
        }
    };

    private touchend = (event: TouchEvent): void => {
        if (this.enabled === false) {
            return;
        }

        const touches0PageX = event.touches[0]?.pageX ?? 0;
        const touches0PageY = event.touches[0]?.pageY ?? 0;

        switch (event.touches.length) {
            case 0:
                this._state = this.STATE.NONE;
                break;

            case 1:
                this._state = this.STATE.TOUCH_ROTATE;
                this._moveCurr.copy(this.getMouseOnCircle(touches0PageX, touches0PageY));
                this._movePrev.copy(this._moveCurr);
                break;
        }

        this.dispatchEvent(this.endEvent);
    };

    private contextmenu = (event: MouseEvent): void => {
        if (this.enabled === false) {
            return;
        }

        event.preventDefault();
    };

    // https://github.com/mrdoob/three.js/issues/20575
    public connect = (domElement: HTMLElement): void => {
        if ((domElement as any) === document) {
            console.error(
                'THREE.OrbitControls: "document" should not be used as the target "domElement". Please use "renderer.domElement" instead.',
            );
        }
        this.domElement = domElement;
        this.domElement.addEventListener('contextmenu', this.contextmenu);

        this.domElement.addEventListener('pointerdown', this.onPointerDown);
        this.domElement.addEventListener('wheel', this.mousewheel);

        this.domElement.addEventListener('touchstart', this.touchstart);
        this.domElement.addEventListener('touchend', this.touchend);
        this.domElement.addEventListener('touchmove', this.touchmove);

        this.domElement.ownerDocument.addEventListener('pointermove', this.onPointerMove);
        this.domElement.ownerDocument.addEventListener('pointerup', this.onPointerUp);

        window.addEventListener('keydown', this.keydown);
        window.addEventListener('keyup', this.keyup);

        this.handleResize();
    };

    public dispose = (): void => {
        if (!this.domElement) {
            return;
        }
        this.domElement.removeEventListener('contextmenu', this.contextmenu);

        this.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.domElement.removeEventListener('wheel', this.mousewheel);

        this.domElement.removeEventListener('touchstart', this.touchstart);
        this.domElement.removeEventListener('touchend', this.touchend);
        this.domElement.removeEventListener('touchmove', this.touchmove);

        this.domElement.ownerDocument.removeEventListener('pointermove', this.onPointerMove);
        this.domElement.ownerDocument.removeEventListener('pointerup', this.onPointerUp);

        window.removeEventListener('keydown', this.keydown);
        window.removeEventListener('keyup', this.keyup);
    };
}

export { TrackballControls };
