import { useRegisterHotKeys } from '../HotKeyCheatSheet';
import { GroupAppearanceController, getCurrentGroupAppearance } from './GroupAppearanceController';
import { canColorize } from './ModelAppearance.utils';
import { ModelAppearanceController } from './ModelAppearanceController';
import type { DisabledControls, ItemAppearance, ModelAppearance, PayloadModelAppearance } from './ModelAppearanceTypes';
import _ from 'lodash';
import React from 'react';

// the subset of ModelAppearance keys which pertain to jaw scans
type JawAppearanceKeys = keyof Pick<ModelAppearance, 'upperJaw' | 'lowerJaw'>;

type JawAppearanceControllerProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
};

/**
 * Controls the appearance of jaw scan models, including the individual jaw scans and the group as a whole.
 *
 * @component
 */
// eslint-disable-next-line max-lines-per-function
export const JawAppearanceController: React.FC<JawAppearanceControllerProps> = props => {
    const { appearanceSettings, onAppearanceChange } = props;
    // we expect at most a single scan for each jaw
    const upperJawAppearance = appearanceSettings.upperJaw[0];
    const lowerJawAppearance = appearanceSettings.lowerJaw[0];
    // make an array of all defined jaw scans
    const groupAppearances: PayloadModelAppearance[] = _.compact([upperJawAppearance, lowerJawAppearance]);
    // we also need a parallel array of the settings fields each came from, so we can map update group appearances correctly
    const groupKeys: JawAppearanceKeys[] = _.compact([
        upperJawAppearance && 'upperJaw',
        lowerJawAppearance && 'lowerJaw',
    ]);

    const handleGroupSoloChange = React.useCallback(
        (isSolo: boolean) => {
            onAppearanceChange(current => ({
                ...current,
                solo: isSolo ? groupAppearances.map(entry => entry.payloadModel) : [],
            }));
        },
        [groupAppearances, onAppearanceChange],
    );
    const handleScansGroupAppearanceChange = React.useCallback(
        (newAppearance: PayloadModelAppearance[]) => {
            onAppearanceChange(current => calcSetting(current, newAppearance, groupKeys));
        },
        [groupKeys, onAppearanceChange],
    );
    const handleUpperJawSettingChange = React.useCallback(
        (update: (current: ItemAppearance) => ItemAppearance) => {
            onAppearanceChange(current => {
                // update the appearance for all models on the upper jaw
                return {
                    ...current,
                    upperJaw: updateJawAppearance(current.upperJaw, update),
                };
            });
        },
        [onAppearanceChange],
    );

    const handleLowerJawSettingChange = React.useCallback(
        (update: (current: ItemAppearance) => ItemAppearance) => {
            onAppearanceChange(current => {
                // update the appearance for all models on the lower jaw
                return {
                    ...current,
                    lowerJaw: updateJawAppearance(current.lowerJaw, update),
                };
            });
        },
        [onAppearanceChange],
    );

    const isSoloUpperArch = upperJawAppearance && appearanceSettings.solo?.includes(upperJawAppearance.payloadModel);
    const isSoloLowerArch = lowerJawAppearance && appearanceSettings.solo?.includes(lowerJawAppearance.payloadModel);

    const handleUpperArchSoloChange = React.useCallback(
        (isSolo: boolean) => {
            upperJawAppearance &&
                onAppearanceChange(current => ({
                    ...current,
                    solo: isSolo ? [upperJawAppearance.payloadModel] : [],
                }));
        },
        [onAppearanceChange, upperJawAppearance],
    );
    const handleLowerArchSoloChange = React.useCallback(
        (isSolo: boolean) => {
            lowerJawAppearance &&
                onAppearanceChange(current => ({
                    ...current,
                    solo: isSolo ? [lowerJawAppearance.payloadModel] : [],
                }));
        },
        [onAppearanceChange, lowerJawAppearance],
    );

    const disabledControls = {
        insertionAxis: true,
        undercutArrowAndShadow: true,
        undercutCurtains: true,
        selectedForEdit: true,
    };

    const groupDisabledControls: DisabledControls = {
        ...disabledControls,
        colorize: !_.compact([upperJawAppearance, lowerJawAppearance]).some(canColorize),
        transparency: false,
        solo: false,
        selectedForEdit: true,
    };
    const currentGroupAppearance = getCurrentGroupAppearance(groupAppearances, groupDisabledControls);

    const toggleGroupTexture = React.useCallback(() => {
        const newSettings = groupAppearances.map((oldPayloadModel): PayloadModelAppearance => {
            return {
                payloadModel: oldPayloadModel.payloadModel,
                appearance: {
                    ...oldPayloadModel.appearance,
                    colorize: !currentGroupAppearance.colorize,
                },
            };
        });
        handleScansGroupAppearanceChange(newSettings);
    }, [handleScansGroupAppearanceChange, groupAppearances, currentGroupAppearance]);
    const toggleUpperJawVisible = React.useCallback(
        () => handleUpperJawSettingChange(state => ({ ...state, visible: !upperJawAppearance?.appearance?.visible })),
        [handleUpperJawSettingChange, upperJawAppearance],
    );
    const toggleUpperJawSolo = React.useCallback(
        () => handleUpperArchSoloChange(!isSoloUpperArch),
        [handleUpperArchSoloChange, isSoloUpperArch],
    );
    const toggleLowerJawVisible = React.useCallback(
        () => handleLowerJawSettingChange(state => ({ ...state, visible: !lowerJawAppearance?.appearance.visible })),
        [handleLowerJawSettingChange, lowerJawAppearance],
    );
    const toggleLowerJawSolo = React.useCallback(
        () => handleLowerArchSoloChange(!isSoloLowerArch),
        [handleLowerArchSoloChange, isSoloLowerArch],
    );

    useRegisterHotKeys({ key: 'T', description: 'All Textures', category: 'Scans', action: toggleGroupTexture });
    useRegisterHotKeys({
        key: 'U',
        description: 'Show/Hide Upper Arch',
        category: 'Scans',
        action: toggleUpperJawVisible,
    });
    useRegisterHotKeys({
        key: 'L',
        description: 'Show/Hide Lower Arch',
        category: 'Scans',
        action: toggleLowerJawVisible,
    });
    useRegisterHotKeys({
        key: 'Shift+U',
        description: 'Solo Upper Arch',
        category: 'Scans',
        action: toggleUpperJawSolo,
    });
    useRegisterHotKeys({
        key: 'Shift+L',
        description: 'Solo Lower Arch',
        category: 'Scans',
        action: toggleLowerJawSolo,
    });

    // if there are not scans, don't display anything
    if (appearanceSettings.upperJaw.length === 0 && appearanceSettings.lowerJaw.length === 0) {
        return null;
    }

    return (
        <GroupAppearanceController
            text={'Scans'}
            solo={isGroupSolo(groupAppearances, appearanceSettings)}
            onSoloChange={handleGroupSoloChange}
            appearanceArray={groupAppearances}
            onAppearanceChange={handleScansGroupAppearanceChange}
            disabledControls={groupDisabledControls}
        >
            {upperJawAppearance && (
                <ModelAppearanceController
                    key={'upper_arch'}
                    text={'Upper Arch'}
                    solo={isSoloUpperArch}
                    onSoloChange={handleUpperArchSoloChange}
                    setAppearance={handleUpperJawSettingChange}
                    appearance={upperJawAppearance.appearance}
                    disabledControls={{
                        ...disabledControls,
                        colorize: !canColorize(upperJawAppearance),
                    }}
                />
            )}
            {lowerJawAppearance && (
                <ModelAppearanceController
                    key={'lower_arch'}
                    text={'Lower Arch'}
                    solo={isSoloLowerArch}
                    onSoloChange={handleLowerArchSoloChange}
                    setAppearance={handleLowerJawSettingChange}
                    appearance={lowerJawAppearance.appearance}
                    disabledControls={{
                        ...disabledControls,
                        colorize: !canColorize(lowerJawAppearance),
                    }}
                />
            )}
        </GroupAppearanceController>
    );
};

/**
 * Helper to copy a group of models with a new appearance applied.
 *
 * @param modelsWithAppearance The models to process.
 * @param newAppearance New appearance to bind to models.
 * @returns A copy of modelsWithAppearance with newAppearance.
 */
function updateJawAppearance(
    modelsWithAppearance: PayloadModelAppearance[],
    update: (current: ItemAppearance) => ItemAppearance,
): PayloadModelAppearance[] {
    return modelsWithAppearance.map(modelWithAppearance => ({
        payloadModel: modelWithAppearance.payloadModel,
        appearance: update(modelWithAppearance.appearance),
    }));
}

// the group is solo'd if everything in the group is solo'd
function isGroupSolo(groupAppearances: PayloadModelAppearance[], appearanceSettings: ModelAppearance) {
    return (
        groupAppearances.length > 0 &&
        groupAppearances.every(entry => appearanceSettings.solo?.includes(entry.payloadModel))
    );
}

function calcSetting(
    currentSettings: ModelAppearance,
    newAppearance: PayloadModelAppearance[],
    groupKeys: JawAppearanceKeys[],
) {
    const newSettings = {
        ...currentSettings,
    };
    /**
     * newAppearance is returned in the same order as groupAppearances, so we iterate through them and use groupKeys to
     * figure out which jaw is being updated.
     */
    newAppearance.forEach((newAppearance, i) => {
        // figure out which jaw this update is for
        const jawKey = groupKeys[i];
        if (jawKey) {
            // update the appearance for all models on this jaw
            newSettings[jawKey] = updateJawAppearance(currentSettings[jawKey], () => newAppearance.appearance);
        }
    });
    return newSettings;
}
