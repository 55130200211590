import { DragHeader } from '../misc/DragHeader';
import { CrossSectionViewAutoZoomControl } from './CrossSectionAutoZoom';
import type { CrossSectionData } from './CrossSectionData';
import { CrossSectionMeasureTool } from './CrossSectionMeasureTool';
import { getLineStyle, useStyles } from './CrossSectionStyles';
import { Legend } from './CrossSectionViewLegend';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { CloseCrossSectionIcon, DisableZoomCrossSectionIcon } from '@orthly/ui';
import { FlossPalette, Card, IconButton, Tooltip } from '@orthly/ui-primitives';
import type { OrbitControlsProps } from '@react-three/drei';
import { OrbitControls, OrthographicCamera } from '@react-three/drei';
import { Canvas } from '@react-three/fiber';
import React from 'react';
import type { BufferGeometry, Group } from 'three';
import { MOUSE } from 'three';

type CrossSectionViewType = React.FC<{
    enableIdenticalPanningBehaviour?: boolean;
    active?: boolean;
    crossSectionUpToDate: boolean;
    crossSection?: CrossSectionData;
    onClose?: () => void;
    children: React.ReactNode;
}>;

/**
 * Floating panel to display 2D cross section on a separate canvas
 *
 * This component:
 * * creates separate canvas for cross section lines
 * * draws additional instruments specific for cross 2D cross section
 *   such as measurements tool
 * * handles panel dragging with mouse
 *
 * @component
 */
export const CrossSectionView: CrossSectionViewType = ({
    enableIdenticalPanningBehaviour,
    crossSection,
    crossSectionUpToDate,
    onClose,
    children,
}) => {
    const cssClasses = useStyles();

    const [autoZoom, setAutoZoom] = React.useState(true);
    const styleActive = { color: FlossPalette.PRIMARY_FOREGROUND };
    const styleInactive = { color: FlossPalette.GRAY };

    const cameraControlsRef = React.useRef<OrbitControlsProps>(null);
    const csGeometriesRef = React.useRef<Group>();
    const dragTargetRef = React.useRef<HTMLDivElement | null>(null);

    const toggleAutoZoom = React.useCallback(() => {
        setAutoZoom(!autoZoom);
    }, [autoZoom]);

    React.useEffect(() => {
        // stop default action when the middle mouse button is clicked
        // on windows when we are clicking middle mouse button and try to pan the view
        // it is shaking the whole window as the default middle button action
        // is not prevented
        // we are adding event listener to this component so that
        // we can stop the default action
        const handleMouseDown = (mouseEvent: MouseEvent) => {
            if (mouseEvent.button !== 1) {
                return;
            }
            mouseEvent.preventDefault();
            mouseEvent.stopPropagation();
        };

        const dragTargetElement = dragTargetRef.current;

        dragTargetElement?.addEventListener('mousedown', handleMouseDown, true);

        return () => {
            dragTargetElement?.removeEventListener('mousedown', handleMouseDown, true);
        };
    }, []);

    // memo-ize line geometries so that we don't create a new one every cycle
    const zoomGeometries = React.useMemo(() => {
        // only include currently visible lines
        const visibleEntries = crossSection?.entries?.filter(e => e.visible);

        const geometries: BufferGeometry[][] = [];

        // we only show CAD restoratives (vs AnatomyElements, QCExtras, etc)
        const restoratives = visibleEntries?.filter(entry => entry.type === LabsGqlOrderDesignScanType.Cad);
        // fit to restoratives only first
        if (restoratives) {
            geometries.push(restoratives.map(e => e.lineGeometry));
        }
        // and fall back to all lines
        if (visibleEntries) {
            geometries.push(visibleEntries.map(e => e.lineGeometry));
        }
        return geometries;
    }, [crossSection]);

    const allLineGeometries = React.useMemo(
        () => crossSection?.entries?.filter(e => e.visible).map(e => e.lineGeometry) || [],
        [crossSection],
    );

    const linePrimitives = crossSection?.entries.map((entry, index) => {
        const geometry = entry.lineGeometry;
        if (!geometry) {
            return undefined;
        }

        const style = getLineStyle(entry);
        return (
            <lineSegments key={index} geometry={geometry} visible={entry.visible}>
                <lineBasicMaterial attach={'material'} {...style} />
            </lineSegments>
        );
    });

    // kinda dangerous, assumes all children are buttons?
    const buttons = React.Children.toArray(children) as React.ReactElement[];
    buttons.push(
        <Tooltip title={autoZoom ? 'Disable Auto Zoom' : 'Enable Auto Zoom'} tabIndex={2}>
            <IconButton style={autoZoom ? styleInactive : styleActive} onClick={toggleAutoZoom}>
                <DisableZoomCrossSectionIcon />
            </IconButton>
        </Tooltip>,
    );
    buttons.sort((b1, b2) => (b1.props.tabIndex || 0) - (b2.props.tabIndex || 0));

    const anyErrors = (crossSection?.errorEntries?.length ?? 0) > 0;
    const errorMsgs = anyErrors && (
        <div className={cssClasses.error}>
            <span>Failed to index geometry for:</span>
            {crossSection?.errorEntries?.map(err => (
                <span key={err.modelPayload.name}>{`${err.modelPayload.name}`}</span>
            ))}
        </div>
    );

    return (
        <Card ref={dragTargetRef} className={cssClasses.crossSectionPane} aria-label={'Cross Section Window'}>
            <DragHeader
                dragTargetRef={dragTargetRef}
                className={cssClasses.header}
                role={'toolbar'}
                aria-label={'Cross Section Tools'}
            >
                <div className={cssClasses.toolbar}>{buttons}</div>
                <div className={cssClasses.headerSpacer} />
                <Legend entries={crossSection?.entries} />
                <IconButton aria-label={'Close'} className={cssClasses.headerButton} onClick={onClose}>
                    <CloseCrossSectionIcon />
                </IconButton>
            </DragHeader>
            <Canvas className={cssClasses.csCanvas}>
                <OrthographicCamera makeDefault zoom={4} position={[0, 0, 100]} />

                <OrbitControls
                    mouseButtons={{
                        LEFT: MOUSE.ROTATE,
                        MIDDLE: enableIdenticalPanningBehaviour ? MOUSE.PAN : -1,
                        RIGHT: MOUSE.PAN,
                    }} // TODO pass keyMap as props as part of configurable keyMaps
                    listenToKeyEvents={undefined}
                    ref={cameraControlsRef as any}
                    enableRotate={false}
                    zoomSpeed={3}
                    enableDamping={false}
                />

                <CrossSectionViewAutoZoomControl
                    zoomMultiplier={1.4}
                    enabled={autoZoom && crossSectionUpToDate}
                    targetGeometries={zoomGeometries}
                    cameraControlsRef={cameraControlsRef}
                />

                <CrossSectionMeasureTool
                    active={crossSectionUpToDate}
                    lineGeometries={allLineGeometries}
                    cameraControlsRef={cameraControlsRef}
                />

                <ambientLight />

                <group ref={csGeometriesRef} visible={crossSectionUpToDate}>
                    {linePrimitives}
                </group>
            </Canvas>
            {errorMsgs}
        </Card>
    );
};
