import { InboxBodySection } from '../InboxBodySection';
import { PracticeOrderSummaryRow } from './PracticeOrderSummaryRow';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { Button, FlossPalette, Grid, stylesFactory } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface InboxTrackingRootProps {
    orders: LabsGqlOrderPreviewFragment[];
    title: string;
}

export const useStyles = stylesFactory(theme => ({
    listItemWrap: {
        cursor: 'pointer',
        padding: '0 40px',
        [theme.breakpoints.down('md')]: {
            padding: '0 16px',
        },
        '&:hover': {
            background: FlossPalette.PRIMARY_BACKGROUND,
        },
    },
}));

export const InboxTrackingRoot: React.FC<InboxTrackingRootProps> = props => {
    const { orders, title } = props;
    const history = useHistory();
    const classes = useStyles();

    if (orders.length === 0) {
        return null;
    }

    return (
        <InboxBodySection
            title={title}
            titleSecondary={
                <Button
                    variant={'ghost'}
                    onClick={() => history.push(`/${PracticeScreen.orders}`)}
                    style={{ paddingRight: 0 }}
                >
                    All orders
                </Button>
            }
        >
            {orders.map((o, idx) => (
                <Grid key={o.id} container className={classes.listItemWrap}>
                    <PracticeOrderSummaryRow order={o} isLast={idx === orders.length - 1} />
                </Grid>
            ))}
        </InboxBodySection>
    );
};
