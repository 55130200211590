import { useChatFeatures } from '../../../../chat/utils';
import { addBusinessDays } from '@orthly/date-fns-business-days';
import { PracticeScreen, TextBubble } from '@orthly/dentin';
import type { LabsGqlOrder } from '@orthly/graphql-operations';
import {
    LabsGqlLabOrderStatus,
    LabsGqlLabOrderWaxupReviewStatus,
    LabsGqlOrderItemSkuType,
    LabsGqlOrderReturnStatus,
} from '@orthly/graphql-schema';
import { Format } from '@orthly/runtime-utils';
import { ShippingUtils } from '@orthly/shared-types';
import { Grid, Link, Button, CheckIcon, Text, FlossPalette, Icon } from '@orthly/ui-primitives';
import type { DoctorRequestHoldInfo } from '@orthly/veneer';
import { useFeatureFlag, useShouldShowGuidedWaxupFlow } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

type TrackingDetails = {
    needsAttention?: boolean;
    subtitle?: React.ReactNode;
    title: React.ReactNode;
    titleDate?: React.ReactNode;
    titleSuffix?: React.ReactNode;
    titleButton?: React.ReactNode;
};

type RouterHistory = ReturnType<typeof useHistory>;

const formatDate = (date: Date | string) => dayjs(date).format('MMM D');
const formatDateTime = (date: Date | string) => dayjs(date).format('MMM D, h:mmA');

const getDeliveredDetails = (order: LabsGqlOrder, dueDate: string): TrackingDetails => {
    return {
        title: 'Delivery date: ',
        titleDate: formatDate(order.delivery_date ?? dueDate),
    };
};

const getNeedsRefabDetails = (
    order: LabsGqlOrder,
    dueDate: string,
    pendingReturnStatus: LabsGqlOrderReturnStatus | undefined,
): TrackingDetails => {
    if (pendingReturnStatus) {
        return {
            title: `Return requested: `,
            titleDate: pendingReturnStatus === LabsGqlOrderReturnStatus.Transit ? 'In transit' : 'Needs shipment',
        };
    }

    return {
        title: 'Sent for refabrication: ',
        titleDate: formatDate(order.needs_refabrication_date ?? order.delivery_date ?? dueDate),
    };
};

const getCancelledDetails = (order: LabsGqlOrder): TrackingDetails => {
    if (order.replaced_by_ids && order.replaced_by_ids.length > 0) {
        return { title: `Canceled and resubmitted with changes` };
    }

    return { title: `Order was canceled` };
};

const getOnHoldDetails = (
    order: LabsGqlOrder,
    holdTitle: string | null | undefined,
    doctorRequestInfo: DoctorRequestHoldInfo,
): TrackingDetails => {
    if (doctorRequestInfo.needsAdditionalScans) {
        return {
            title: 'Resolution of hold pending new scans',
            subtitle: 'You have opted to capture new scans.',
            needsAttention: true,
        };
    }

    if (order.hold_in_review) {
        return {
            title: (
                <Grid container alignItems={'center'} style={{ gap: '0.5ch' }}>
                    <CheckIcon style={{ color: 'green' }} />
                    Hold resolved
                </Grid>
            ),
        };
    }

    return {
        needsAttention: true,
        title: holdTitle ? `On hold: ${holdTitle}` : 'On hold',
    };
};

const getExpectedDeliveryDetails = (
    order: LabsGqlOrder,
    dueDate: string,
    carrier: string,
    isDelayed: boolean,
    isPendingPartnerOrderDesign: boolean,
): TrackingDetails => {
    const originalEta = order.fulfillment.current.shipment?.original_eta;
    const eta = order.fulfillment.current.shipment?.eta;
    const latestDeliveryAttempt = order.fulfillment.current.shipment?.latest_delivery_attempt_date;

    const isDueDateToday = dayjs(dueDate).isSame(dayjs(), 'day');

    if (carrier && originalEta && eta && new Date(originalEta) < new Date(eta)) {
        const formattedEta = formatDate(eta);
        const unableToDeliver = latestDeliveryAttempt && new Date(latestDeliveryAttempt) < new Date(eta);

        const deliveryString = latestDeliveryAttempt
            ? `${carrier} will try to deliver again on `
            : `${carrier} will try to deliver on `;

        const deliverySuffixString = unableToDeliver
            ? `${carrier} was unable to deliver this order on ${formatDateTime(
                  latestDeliveryAttempt,
              )}, and will try again on ${formattedEta}.`
            : `${carrier} has delayed this order, and will attempt delivery on ${formattedEta}.`;

        return {
            title: deliveryString,
            titleDate: formattedEta,
            titleSuffix: (
                <Text variant={'body2'} color={'GRAY'}>
                    {deliverySuffixString}
                </Text>
            ),
        };
    }

    return {
        title: 'Expected delivery: ',
        titleDate: isDueDateToday ? 'Today' : formatDate(dueDate),
        titleSuffix: isDelayed && (
            <Text color={'GRAY'} component={'span'} variant={'h5'}>
                {` (Originally ${formatDate(order.practice_dates.promised_delivery_date)})`}
            </Text>
        ),
        subtitle: isPendingPartnerOrderDesign ? 'Pending approval of fixed restoration design' : undefined,
    };
};

const getReturnDetails = (openChat: () => unknown, carrier: string): TrackingDetails => {
    return {
        title: `${carrier} was unable to deliver this. Returning to Dandy`,
        titleSuffix: (
            <Text variant={'body2'} color={'GRAY'}>
                {'To reschedule delivery, '}
                <Link
                    onClick={openChat}
                    style={{ color: FlossPalette.STAR_GRASS, fontWeight: 'bold', cursor: 'pointer' }}
                >
                    chat with us
                </Link>
                {' or call us at 267-310-3332'}
            </Text>
        ),
    };
};

const getDesignPreviewPendingDetails = (order: LabsGqlOrder): TrackingDetails | undefined => {
    if (order.practice_dates.digital_design_preview_estimated_completion_date) {
        const designPreviewETA = new Date(order.practice_dates.digital_design_preview_estimated_completion_date);
        const originalDesignPreviewETA = order.practice_dates.promised_digital_design_preview_completion_date
            ? new Date(order.practice_dates.promised_digital_design_preview_completion_date)
            : null;

        return {
            title: 'A digital design preview will be ready on ',
            titleDate: formatDate(designPreviewETA),
            titleSuffix:
                order.practice_dates.is_digital_design_preview_delayed && originalDesignPreviewETA ? (
                    <Text
                        color={'GRAY'}
                        component={'span'}
                        variant={'h5'}
                        style={{ whiteSpace: 'nowrap' }}
                    >{` (Previously: ${formatDate(originalDesignPreviewETA)})`}</Text>
                ) : null,
            subtitle: <Text variant={'body2'}>Your design preview is in progress</Text>,
        };
    }
};

const getDesignPreviewReadyForReviewDetails = (
    order: LabsGqlOrder,
    shouldShowGuidedWaxup: boolean,
    history: RouterHistory,
): TrackingDetails | undefined => {
    if (order.practice_dates.digital_design_preview_estimated_completion_date) {
        const designPreviewETA = new Date(order.practice_dates.digital_design_preview_estimated_completion_date);
        const reviewDue = addBusinessDays(designPreviewETA, 2);
        const differenceInDays = dayjs(reviewDue).diff(dayjs(), 'day');

        return {
            needsAttention: true,
            title: 'Review digital design by ',
            titleDate: differenceInDays > 0 ? formatDate(reviewDue) : 'Today',
            titleButton: shouldShowGuidedWaxup && (
                <Button
                    analytics={{
                        AssetName: 'Order Details - Open Design Preview',
                        AssetCTAText: 'Open Design Preview',
                    }}
                    onClick={() => {
                        history.replace(`/${PracticeScreen.guided_waxup}/${order.id}`);
                    }}
                    variant={'primary'}
                >
                    Open design preview
                </Button>
            ),
            subtitle: (
                <TextBubble noMargin>
                    <div style={{ display: 'flex', gap: 8 }}>
                        <Icon icon={'InfoOutlinedIcon'} style={{ color: FlossPalette.WARNING }} />
                        {differenceInDays > 0 ? (
                            <div>
                                {'You have '}
                                <Text bold component={'span'} variant={'body2'}>
                                    {Format.pluralize('day', differenceInDays)}
                                </Text>
                                {
                                    ' to reject or approve the design, after which your order will proceed to fabrication.'
                                }
                            </div>
                        ) : (
                            <div>
                                {'You must reject or approve the design '}
                                <Text bold component={'span'} variant={'body2'}>
                                    {'today'}
                                </Text>
                                {', after which your order will proceed to fabrication.'}
                            </div>
                        )}
                    </div>
                </TextBubble>
            ),
        };
    }
};

const getDesignPreviewDetails = (
    order: LabsGqlOrder,
    shouldShowGuidedWaxup: boolean,
    history: RouterHistory,
): TrackingDetails | undefined => {
    if (order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.AwaitingDesign) {
        return getDesignPreviewPendingDetails(order);
    }

    if (order.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview) {
        return getDesignPreviewReadyForReviewDetails(order, shouldShowGuidedWaxup, history);
    }
};

const isPendingOtherItemsDesign = (
    order: LabsGqlOrder,
    partnerOrders: Pick<LabsGqlOrder, 'waxup_status'>[],
): boolean => {
    if (order.status !== LabsGqlLabOrderStatus.New) {
        return false;
    }

    // For certain skus in a split order, i.e. removables, delivery is contingent on a design preview for the fixed item in the split order.
    const skusDependentOnSplitOrdersDesignPreview: LabsGqlOrderItemSkuType[] = [
        LabsGqlOrderItemSkuType.Removeable,
        LabsGqlOrderItemSkuType.Partial,
        LabsGqlOrderItemSkuType.Tmj,
    ];

    const orderDependentOnDesignPreview = order.items_v2.some(item =>
        skusDependentOnSplitOrdersDesignPreview.includes(item.sku),
    );

    const otherOrdersHaveFixedDesignPreview = partnerOrders.some(otherOrder => {
        return (
            otherOrder.waxup_status === LabsGqlLabOrderWaxupReviewStatus.AwaitingDesign ||
            otherOrder.waxup_status === LabsGqlLabOrderWaxupReviewStatus.ReadyForReview
        );
    });

    return orderDependentOnDesignPreview && otherOrdersHaveFixedDesignPreview;
};

export const useTrackingDetails = (
    order: LabsGqlOrder,
    partnerOrders: Pick<LabsGqlOrder, 'waxup_status'>[],
    holdTitle: string | null | undefined,
    pendingReturnStatus: LabsGqlOrderReturnStatus | undefined,
    doctorRequestHoldInfo: DoctorRequestHoldInfo,
): TrackingDetails | undefined => {
    const { value: enableDesignPreviewETA = false } = useFeatureFlag('enableDesignPreviewETA');
    const { value: enablePendingDesignDisclaimer = false } = useFeatureFlag(
        'scanneros_pending_fixed_design_approval_enabled',
    );
    const shouldShowGuidedWaxup = useShouldShowGuidedWaxupFlow(order);
    const { openChat } = useChatFeatures();
    const history = useHistory();

    const carrier = ShippingUtils.getCarrierDisplayText(order.carrier ?? 'Carrier');
    const isDelayed = order.practice_dates.is_delivery_delayed;
    const dueDate = order.practice_dates.estimated_delivery_date;

    if (ShippingUtils.isReturned(order.ship_status)) {
        return getReturnDetails(openChat, carrier);
    }

    switch (order.status) {
        case LabsGqlLabOrderStatus.Delivered:
            return getDeliveredDetails(order, dueDate);
        case LabsGqlLabOrderStatus.NeedsRefabrication:
            return getNeedsRefabDetails(order, dueDate, pendingReturnStatus);
        case LabsGqlLabOrderStatus.Cancelled:
            return getCancelledDetails(order);
        case LabsGqlLabOrderStatus.OnHold:
            return getOnHoldDetails(order, holdTitle, doctorRequestHoldInfo);
        case LabsGqlLabOrderStatus.New:
        case LabsGqlLabOrderStatus.Fabrication:
        case LabsGqlLabOrderStatus.Shipped:
        case LabsGqlLabOrderStatus.NeedsReview:
            if (enableDesignPreviewETA && order.fulfillment_workflow.configuration.waxup_required) {
                const designPreviewDetails = getDesignPreviewDetails(order, shouldShowGuidedWaxup, history);

                if (designPreviewDetails) {
                    return designPreviewDetails;
                }
            }

            const isPendingPartnerOrderDesign =
                enablePendingDesignDisclaimer && isPendingOtherItemsDesign(order, partnerOrders);

            return getExpectedDeliveryDetails(order, dueDate, carrier, isDelayed, isPendingPartnerOrderDesign);
        default:
            // We should have handled every possible case for this switch statement.
            // If this line is triggering an error, then a new status has been added which must be accounted for.
            order.status satisfies never;
    }
};
