import type { ModelPayloadItem } from '../ModelViewer/ModelViewerTypes';
import { GroupAppearanceController } from './GroupAppearanceController';
import { canColorize } from './ModelAppearance.utils';
import type { ModelAppearanceControllerVariant, TitleIconProps } from './ModelAppearanceController';
import { ModelAppearanceController } from './ModelAppearanceController';
import type { DisabledControls, ModelAppearance, PayloadModelAppearance } from './ModelAppearanceTypes';
import { getAllDisabledControls } from './ModelAppearanceTypes';
import { getCleanedModelPayloadLabel } from './ScansAppearanceController.utils';
import { scanItemAppearanceSetter } from './appearanceSetters';
import React from 'react';

type ScansAppearanceControllerProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    enableSelectForEditing?: boolean;
    noGroup?: boolean;
};

type ScanItemProps = {
    payloadModelAppearance: PayloadModelAppearance;
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    scanItemIndex: number;
    enableSelectForEditing?: boolean;
    dedent?: boolean;
    variant?: ModelAppearanceControllerVariant;
    className?: string;
    handleSelectedForEdit?: (isSelectedForEdit: boolean, modelPayload: ModelPayloadItem) => void;
    text?: string;
    titleIcons?: TitleIconProps[];
};

const disabledControls: DisabledControls = {
    ...getAllDisabledControls(),
    colorize: false,
};

export const ScanItem: React.FC<ScanItemProps> = ({
    appearanceSettings,
    onAppearanceChange,
    payloadModelAppearance,
    scanItemIndex,
    enableSelectForEditing,
    dedent,
    variant,
    className,
    handleSelectedForEdit,
    text,
    titleIcons,
}) => {
    const labelText = text ?? getCleanedModelPayloadLabel(payloadModelAppearance.payloadModel.name);

    const isSelectedForEdit = appearanceSettings.selectedForEdit?.includes(payloadModelAppearance.payloadModel);

    const onSelectedForEditChange = React.useCallback(
        (selectedForEdit: boolean) => {
            if (handleSelectedForEdit) {
                handleSelectedForEdit(selectedForEdit, payloadModelAppearance.payloadModel);
                return;
            }

            onAppearanceChange(current => ({
                ...current,
                selectedForEdit: selectedForEdit ? [payloadModelAppearance.payloadModel] : [],
            }));
        },
        [onAppearanceChange, payloadModelAppearance, handleSelectedForEdit],
    );

    const setAppearance = scanItemAppearanceSetter(onAppearanceChange, scanItemIndex);

    return (
        <ModelAppearanceController
            key={`${appearanceSettings.scans}_${scanItemIndex}`}
            text={labelText}
            disabledControls={{
                ...disabledControls,
                colorize: !canColorize(payloadModelAppearance),
                transparency: false,
                selectedForEdit: !enableSelectForEditing,
            }}
            setAppearance={setAppearance}
            appearance={payloadModelAppearance.appearance}
            selectedForEdit={isSelectedForEdit}
            onSelectedForEditChange={onSelectedForEditChange}
            dedent={dedent ?? false}
            variant={variant}
            className={className}
            titleIcons={titleIcons}
        />
    );
};

export const ScansAppearanceController: React.FC<ScansAppearanceControllerProps> = ({
    appearanceSettings,
    onAppearanceChange,
    enableSelectForEditing,
    noGroup,
}) => {
    const scanItems: PayloadModelAppearance[] = appearanceSettings.scans;

    // Select the first scan for edit by default, if one is not already selected.
    React.useEffect(() => {
        const firstScan = scanItems[0];
        if (firstScan) {
            onAppearanceChange(current => {
                if (current.selectedForEdit?.[0]) {
                    // Return the same object so as not to cause a re-render.
                    return current;
                }

                return {
                    ...current,
                    selectedForEdit: [firstScan.payloadModel],
                };
            });
        }
    }, [onAppearanceChange, scanItems]);

    const scansElements = scanItems.map((payloadModelAppearance, index) => {
        return (
            <ScanItem
                key={`${index}`}
                scanItemIndex={index}
                appearanceSettings={appearanceSettings}
                onAppearanceChange={onAppearanceChange}
                payloadModelAppearance={payloadModelAppearance}
                enableSelectForEditing={enableSelectForEditing}
                dedent={noGroup}
            />
        );
    });

    const handleScansGroupAppearanceChange = React.useCallback(
        childrenSettings => {
            onAppearanceChange(current => {
                return {
                    ...current,
                    scans: childrenSettings,
                };
            });
        },
        [onAppearanceChange],
    );

    if (scanItems.length === 0) {
        return null;
    }

    if (noGroup) {
        return <>{scansElements}</>;
    }

    return (
        <GroupAppearanceController
            text={'Order Scans'}
            disabledControls={disabledControls}
            appearanceArray={scanItems}
            onAppearanceChange={handleScansGroupAppearanceChange}
        >
            {scansElements}
        </GroupAppearanceController>
    );
};
