import type { ModelAppearance, PayloadModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import type { Jaw, ModelPayloadItem } from '../ModelViewer/ModelViewerTypes';
import type { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import _ from 'lodash';
import type React from 'react';
import type { BufferGeometry, Quaternion, Mesh, Material } from 'three';
import { Plane, Vector3 } from 'three';

/**
 * Consider to move this file and ModelViewerTypes
 * out of components into separate module.
 *
 * I would suggest dto (data transport objects)
 * module for it.
 */
export type CrossSectionEntry = {
    type: LabsGqlOrderDesignScanType | 'MarginLine' | 'UpdatedMarginLine';
    jaw?: Jaw;
    visible: boolean;
    // Whether this entry (either a model or a margin line) is from a past design
    isPast?: boolean;

    geometry: BufferGeometry;
    lineGeometry: BufferGeometry;
    mesh?: Mesh<BufferGeometry, Material>;
};

export type CrossSectionErrorEntry = {
    modelPayload: ModelPayloadItem;
    errorMsg: string;
};

export type CrossSectionData = {
    plane: Plane;
    entries: CrossSectionEntry[];
    errorEntries?: CrossSectionErrorEntry[];
};

export type CrossSectionDataSetter = React.Dispatch<React.SetStateAction<CrossSectionData | undefined>>;

export type CrossSectionPlane = {
    position: Vector3;
    orientation: Quaternion;
    cameraUp?: Vector3;
};
export type CrossSectionPlaneSetter = React.Dispatch<React.SetStateAction<CrossSectionPlane | undefined>>;

export type CrossSectionItem = {
    payloadModel: ModelPayloadItem;
    isVisible: boolean;
    // Whether this item (either a model or a margin line) is from a past design
    isPast: boolean;
};

export const CrossSectionMathUtils = {
    getThreePlane({ position, orientation }: CrossSectionPlane): Plane {
        const normal = new Vector3(0, 0, 1).applyQuaternion(orientation);
        return new Plane().setFromNormalAndCoplanarPoint(normal, position);
    },

    getPlaneNormal({ orientation }: CrossSectionPlane): Vector3 {
        return new Vector3(0, 0, 1).applyQuaternion(orientation);
    },
};

function mapNominalPayload(pma: PayloadModelAppearance): CrossSectionItem {
    return { payloadModel: pma.payloadModel, isVisible: pma.appearance.visible, isPast: false };
}

function mapPastPayload(pma: PayloadModelAppearance): CrossSectionItem {
    return { payloadModel: pma.payloadModel, isVisible: pma.appearance.visible, isPast: true };
}

/**
 * List all models available for Cross Section
 */
export function listAllPayloadModels(payload: ModelAppearance): CrossSectionItem[] {
    const cadWithCurtainsIDs = payload.restoratives.CAD.filter(({ appearance }) => appearance.showUndercutCurtains).map(
        cad => cad.payloadModel.modelElementID,
    );

    const visibleCurtains = _.compact(cadWithCurtainsIDs.map(id => id && payload.curtains[id])).map(item => ({
        payloadModel: item,
        isVisible: true,
        isPast: false,
    }));

    const hiddenCurtains = Object.entries(payload.curtains)
        .filter(([key]) => !cadWithCurtainsIDs.includes(key))
        .map(([_key, value]) => ({ payloadModel: value, isVisible: false, isPast: false }));

    const restoratives = payload.restoratives.CAD.map(mapNominalPayload);
    const pastRestoratives = payload.pastRestoratives.CAD.map(mapPastPayload);
    const lowerJaw = payload.lowerJaw.map(mapNominalPayload);
    const upperJaw = payload.upperJaw.map(mapNominalPayload);
    const scans = payload.scans.map(mapNominalPayload);
    return [
        ...visibleCurtains,
        ...hiddenCurtains,
        ...restoratives,
        ...pastRestoratives,
        ...lowerJaw,
        ...upperJaw,
        ...scans,
    ];
}

/**
 * List models available for Cross Section,
 * filter only visible
 * TODO Patrick, Ammar this belongs in a util!
 */
export function listVisiblePayloadModels(payload: ModelAppearance): ModelPayloadItem[] {
    const restoratives = payload.restoratives.CAD.filter(cad => cad.appearance.visible).map(cad => cad.payloadModel);
    const pastRestoratives = payload.pastRestoratives.CAD.filter(cad => cad.appearance.visible).map(
        cad => cad.payloadModel,
    );

    const cadWithCurtains = payload.restoratives.CAD.filter(({ appearance }) => appearance.showUndercutCurtains);

    const curtains: ModelPayloadItem[] = _.compact(
        cadWithCurtains.map(cad => {
            const elemId = cad.payloadModel.modelElementID;
            return elemId && payload.curtains[elemId];
        }),
    );

    const lowerJaw = payload.lowerJaw.filter(jaw => jaw.appearance.visible).map(jaw => jaw.payloadModel);

    const upperJaw = payload.upperJaw.filter(jaw => jaw.appearance.visible).map(jaw => jaw.payloadModel);

    const scans = payload.scans.filter(scan => scan.appearance.visible).map(scan => scan.payloadModel);
    return [...curtains, ...restoratives, ...pastRestoratives, ...lowerJaw, ...upperJaw, ...scans];
}
