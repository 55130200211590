import { LoadBlocker } from '@orthly/ui';
import type { GridProps } from '@orthly/ui-primitives';
import { createStyles, makeStyles, Button, Grid, Paper, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        paperRoot: {
            padding: 20,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            width: '100%',
        },
    }),
);

interface FileUploaderLayoutProps {
    children: React.ReactNode;
    loading?: boolean;
    title?: string;
    elevation?: number;
    paperStyle?: React.CSSProperties;
    loadBlockerContainerProps?: GridProps;
}

export const FileUploaderLayout: React.FC<FileUploaderLayoutProps> = props => {
    const classes = useStyles();
    return (
        <Grid container style={{ width: '100%' }} justifyContent={'center'}>
            <Paper className={classes.paperRoot} elevation={props.elevation} style={props.paperStyle}>
                <LoadBlocker blocking={!!props.loading} ContainerProps={props.loadBlockerContainerProps}>
                    {props.title && (
                        <Grid container style={{ padding: '0 0 10px' }}>
                            <Text variant={'h6'}>{props.title}</Text>
                        </Grid>
                    )}
                    {props.children}
                </LoadBlocker>
            </Paper>
        </Grid>
    );
};

interface FileUploaderSubmitButtonProps {
    disabled?: boolean;
    onSubmit?: () => void;
    submitButtonText: string;
}

export const FileUploaderSubmitButton: React.FC<FileUploaderSubmitButtonProps> = props => {
    return (
        <Grid container style={{ paddingTop: 10 }}>
            <Button
                onClick={() => {
                    props.onSubmit?.();
                }}
                disabled={props.disabled}
                fullWidth
                variant={'contained'}
            >
                {props.submitButtonText}
            </Button>
        </Grid>
    );
};
