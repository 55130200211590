import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { TAB_BAR_HEIGHT } from '../../../layouts/mobile/MobileTabs';
import { usePracticeAppSelector } from '../../../redux';
import { useShowMobileLayout } from '../../../utils/LayoutUtils';
import { useIsChairside } from '../../../utils/chairside.hooks';
import { PracticeOrderSummaryRow } from '../../inbox/components/tracker/PracticeOrderSummaryRow';
import { UnsubmittedScanSummaryRow } from '../../inbox/components/tracker/UnsubmittedScanSummaryRow';
import { ReturnSummaryRow } from '../components/ReturnSummaryRows';
import { OrdersOverviewToolbar } from './OrdersOverviewToolbar';
import { OrdersSidebar } from './OrdersSidebar';
import type { OrdersOverviewItem } from './useOrdersOverviewItems';
import { useReturnOverviewItems, useUnsubmittedScanOverviewItems } from './useOrdersOverviewItems';
import { getOrderListItemHeight, HORIZONTAL_NAV_HEIGHT } from '@orthly/dentin';
import {
    useOrderPracticePreviewsByIdsQuery,
    usePracticeOrderIdsByStatusQuery,
    useOrderPreviewsById,
} from '@orthly/graphql-react';
import { LabsGqlPracticeOrderStatus } from '@orthly/graphql-schema';
import { useToolbarContainerHeight } from '@orthly/ui';
import { FlossPalette, stylesFactory, useScreenIsMd, createStyles, Grid, Typography } from '@orthly/ui-primitives';
import type { VirtualListHookResult } from '@orthly/veneer';
import { OrderListNoResultsItem, VIRTUAL_LIST_LOADING, VirtualList } from '@orthly/veneer';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme =>
    createStyles({
        root: {
            height: '100%',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            alignContent: 'flex-start',
            flexWrap: 'nowrap',
            position: 'relative',
            background: '#fff',
        },
        contentRoot: {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'stretch',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            display: 'flex',
            flex: '0 1 100%',
        },
        rootLoading: {
            position: 'relative',
            alignItems: 'center',
            cursor: 'pointer',
            borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
            [theme.breakpoints.down('sm')]: {
                padding: '16px',
            },
        },
        gridItem: {
            zIndex: 1,
            position: 'relative',
        },
        warningItem: {
            alignItems: 'center',
            width: '100%',
        },
        orderScanButton: {
            marginLeft: 12,
        },
        tooltip: {
            whiteSpace: 'pre-line',
        },
        textSecondary: {
            color: theme.palette.text.secondary,
        },
    }),
);

interface OrdersOverviewItemRenderProps {
    item?: OrdersOverviewItem;
}

function keyForItem(item?: OrdersOverviewItem): { id: string; updated_at: string } | undefined {
    if (!item) {
        return undefined;
    }
    switch (item.type) {
        case 'order':
            return { id: item.order.id, updated_at: item.order.updated_at };
        case 'return':
            return {
                id: item.orderReturn.id,
                updated_at: `${item.orderReturn.updated_at}${item.orderReturn.order.updated_at}`,
            };
        case 'scan':
            return { id: item.scan.id, updated_at: item.scan.created_at };
    }
}

function overviewItemIsEqual(prev: OrdersOverviewItemRenderProps, next: OrdersOverviewItemRenderProps): boolean {
    const prevKey = keyForItem(prev.item);
    const nextKey = keyForItem(next.item);
    return prevKey?.id === nextKey?.id && prevKey?.updated_at === nextKey?.updated_at;
}

const OrdersOverviewItemRender = React.memo<OrdersOverviewItemRenderProps>(props => {
    const { item } = props;

    switch (item?.type) {
        case 'order':
            return <PracticeOrderSummaryRow order={item.order} fromInbox={false} />;
        case 'return':
            return <ReturnSummaryRow orderReturn={item.orderReturn} />;
        case 'scan':
            return <UnsubmittedScanSummaryRow scan={item.scan} />;
        default:
            return null;
    }
}, overviewItemIsEqual);

export const useListStyles = stylesFactory(theme => ({
    listItemWrap: {
        height: '100%',
        cursor: 'pointer',
        padding: '0 40px',
        '&:hover': {
            background: FlossPalette.PRIMARY_BACKGROUND,
        },
        [theme.breakpoints.down('sm')]: {
            padding: '0 16px',
        },
    },
}));

type ListItemProps = { listItem?: OrdersOverviewItem };

function orderListItemPropsAreEqual(prev: ListItemProps, next: ListItemProps): boolean {
    const prevItem = prev.listItem;
    const nextItem = next.listItem;

    if (prevItem?.id !== nextItem?.id || prevItem?.type !== nextItem?.type) {
        return false;
    }

    return overviewItemIsEqual({ item: prevItem }, { item: nextItem });
}

const OrderListItemCellLoading: React.FC = () => (
    <div style={{ width: '100%', background: FlossPalette.DARK_TAN, height: 24, paddingRight: 8 }} />
);

const OrderListItemLoading: React.FC = () => {
    const classes = useStyles();

    return (
        <Grid container className={classes.rootLoading} style={{ borderBottom: undefined, height: '100%' }}>
            <Grid container item xs={10} lg={4} className={classes.gridItem}>
                <OrderListItemCellLoading />
                <Typography
                    component={'div'}
                    className={classes.textSecondary}
                    style={{ display: 'flex', alignItems: 'center', width: '50%', lineHeight: '24px', marginTop: 4 }}
                >
                    <OrderListItemCellLoading />
                </Typography>
            </Grid>
            <Grid container item xs={2} lg={2} style={{ width: 'auto' }} className={classes.gridItem} />
            <Grid container item xs={12} lg={6} wrap={'nowrap'} alignItems={'center'} className={classes.gridItem}>
                <OrderListItemCellLoading />
            </Grid>
        </Grid>
    );
};

const OrdersVirtualizedListItem = React.memo<ListItemProps>(props => {
    const item = props.listItem;
    const classes = useListStyles();

    const testId = item ? `order-list-item-${item.id}` : 'order-list-item-loading';

    return (
        <Grid container className={classes.listItemWrap} data-test={testId}>
            {item ? <OrdersOverviewItemRender item={item} /> : <OrderListItemLoading />}
        </Grid>
    );
}, orderListItemPropsAreEqual);

const OrdersListView: React.FC = () => {
    const { status } = usePracticeAppSelector(s => s.orders);
    const isChairside = useIsChairside();
    const isTablet = useScreenIsMd();
    const topBarHeight =
        useToolbarContainerHeight() + (!isChairside ? HORIZONTAL_NAV_HEIGHT : 0) + (isTablet ? TAB_BAR_HEIGHT : 0);
    const listItemHeight = getOrderListItemHeight(isTablet);

    return (
        <VirtualList
            listItemHeight={listItemHeight}
            toolbarHeight={topBarHeight}
            useItemData={useListViewData}
            ListItem={OrdersVirtualizedListItem}
            noResultsMessage={
                <OrderListNoResultsItem
                    message={`No orders with status ${_.startCase(status)}`}
                    rootStyle={{ position: 'unset' }}
                />
            }
        />
    );
};

export const OrdersOverviewRoot: React.FC = () => {
    const classes = useStyles();
    const chairside = useIsChairside();
    const isMobileLayout = useShowMobileLayout();

    if (isMobileLayout && !chairside) {
        return (
            <MobilePageLayout title={'Orders'}>
                <Grid container className={classes.root}>
                    <OrdersSidebar />
                    <Grid container className={classes.contentRoot}>
                        <OrdersOverviewToolbar />
                        <OrdersListView />
                    </Grid>
                </Grid>
            </MobilePageLayout>
        );
    }

    return (
        <Grid container className={classes.root}>
            <OrdersSidebar />
            <Grid container className={classes.contentRoot}>
                <OrdersOverviewToolbar />
                <OrdersListView />
            </Grid>
        </Grid>
    );
};

function useListViewData(startIndex: number, stopIndex: number): VirtualListHookResult<OrdersOverviewItem> {
    const ordersState = usePracticeAppSelector(s => s.orders);
    const { product_line_filter, doctor_id_filter, status, sort_column, sort_asc } = ordersState;
    const [lastIdFetchAt, setLastIdFetchAt] = React.useState(new Date().valueOf());
    const {
        data: idData,
        loading: idsLoading,
        error: idQueryError,
    } = usePracticeOrderIdsByStatusQuery({
        // we dont want to mess around with cache here, we want to always fetch the latest set of IDs for the filter
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'network-only',
        onCompleted: () => {
            // reset the cache for orders, we have a new set displaying
            setLastIdFetchAt(new Date().valueOf());
        },
        variables: {
            filter: {
                status,
                product_line_filter,
                doctor_id_filter,
                sort_asc,
                sort_field: sort_column,
            },
        },
    });

    const orderIds = idData?.practiceOrderIdsByStatus.map(({ id }) => id) ?? [];

    const { scanRows = [], loading: scanRowsLoading } = useUnsubmittedScanOverviewItems(sort_asc);
    const { returnRows = [], loading: returnRowsLoading } = useReturnOverviewItems();

    const showScans = status === LabsGqlPracticeOrderStatus.All || status === LabsGqlPracticeOrderStatus.Draft;
    const showReturns = status === LabsGqlPracticeOrderStatus.All || status === LabsGqlPracticeOrderStatus.Return;
    const scans = showScans ? scanRows : [];
    const returns = showReturns ? returnRows : [];

    const orderStart = Math.max(0, startIndex - returns.length);
    const orderEnd = Math.max(0, stopIndex - returns.length);

    const visibleIds = orderIds.slice(orderStart, orderEnd);

    const {
        orders,
        loading: ordersLoading,
        error: orderQueryError,
    } = useOrderPreviewsById(useOrderPracticePreviewsByIdsQuery, visibleIds, lastIdFetchAt);

    const allOrders = Array.from(new Array(orderIds.length), (_, index): OrdersOverviewItem | undefined => {
        const order = orders[index - orderStart];

        return (
            order && {
                order,
                id: order.id,
                type: 'order',
            }
        );
    });

    const allItems = [...returns, ...allOrders, ...scans];

    return {
        // The output items are intentionally not memoized. VirtualList doesn't care about
        // the stability of the array or even about the stability of the input objects.
        listItems: allItems
            .slice(startIndex, stopIndex)
            .map(item => item && { ...item, key: `${item.type}${item.id}` }),
        itemsLoading: ordersLoading || scanRowsLoading || returnRowsLoading,
        error: idQueryError || orderQueryError,
        listItemCount: idsLoading ? VIRTUAL_LIST_LOADING : orderIds.length + scans.length + returns.length,
    };
}
