import { ItemFactRow } from '../Dentures/OrderDetailDentures';
import { OrderDetailBlock } from '../OrderDetails/OrderDetailBlock';
import type {
    LabsGqlLabOrderSingleFulfillmentFragment,
    LabsGqlPartialsSingleFulfillmentFragment,
    LabsGqlPartialsFulfillmentTryInFragment,
    LabsGqlPartialsFulfillmentWaxRimFragment,
    LabsGqlLabOrderFragment,
} from '@orthly/graphql-operations';
import { LabsGqlPartialsSingleFulfillmentType, LabsGqlPartialsTryInFeedbackIssueType } from '@orthly/graphql-schema';
import { Text, FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

export const TRY_IN_ISSUE_NAMES: Record<LabsGqlPartialsTryInFeedbackIssueType, string> = {
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticFitLoose]: 'Fit is loose',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticFitRocking]: 'Fit is rocking',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticTeethLength]: 'Teeth length needs changes',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticTeethShape]: 'Teeth shape needs changes',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticTeethSpacing]: 'Teeth spacing needs changes',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticMaterialsDenture]: 'Incorrect denture material',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticMaterialsClasp]: 'Incorrect clasp material',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticShadeIncorrect]: 'Incorrect tooth shade',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticShadeTooLight]: 'Tissue shade too light',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticShadeTooDark]: 'Tissue shade too dark',
    [LabsGqlPartialsTryInFeedbackIssueType.AestheticOther]: 'Other (Aesthetic)',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralFitTooLoose]: 'The fit is too loose',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralFitTooTight]: 'The fit is too tight',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralClaspPlacement]: 'Incorrect placement',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralClaspTooTight]: 'Clasps are too tight',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralClaspTooLoose]: 'Clasps are too loose',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralOcclusionOpen]: 'Open occlusal contact',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralOcclusionTight]: 'Tight occlusal contact',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralTeethIncorrect]: 'Incorrect teeth',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralTeethMissing]: 'Missing teeth',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralFramework]: 'The framework is incorrect',
    [LabsGqlPartialsTryInFeedbackIssueType.StructuralOther]: 'Other comments',
};

export const PARTIALS_MANUFACTURING_STEP_TO_TEXT: Record<LabsGqlPartialsSingleFulfillmentType, string> = {
    [LabsGqlPartialsSingleFulfillmentType.TryIn]: 'Try-In',
    [LabsGqlPartialsSingleFulfillmentType.WaxRim]: 'Wax Rim',
    [LabsGqlPartialsSingleFulfillmentType.Finish]: 'Final Processing',
};

interface PartialsWaxRimFeedbackProps {
    fulfillment: LabsGqlPartialsFulfillmentWaxRimFragment;
    nextStep: LabsGqlPartialsSingleFulfillmentFragment | null;
}

const PartialsWaxRimFeedback: React.VFC<PartialsWaxRimFeedbackProps> = props => {
    const { fulfillment, nextStep } = props;
    const feedback = fulfillment.feedback;
    if (!feedback) {
        return <Text variant={'body2'}>No Feedback yet</Text>;
    }

    return (
        <>
            {nextStep && (
                <ItemFactRow
                    title={'Next Manufacturing Step'}
                    value={PARTIALS_MANUFACTURING_STEP_TO_TEXT[nextStep.type]}
                />
            )}
            <ItemFactRow title={'Notes'} value={feedback.notes} />
        </>
    );
};

interface PartialsTryInFeedbackProps {
    fulfillment: LabsGqlPartialsFulfillmentTryInFragment;
    nextStep: LabsGqlPartialsSingleFulfillmentFragment | null;
}

const PartialsTryInFeedback: React.VFC<PartialsTryInFeedbackProps> = props => {
    const { fulfillment, nextStep } = props;
    const issues = fulfillment.feedback?.issues;

    if (!issues) {
        return <Text variant={'body2'}>No Feedback yet</Text>;
    }

    return (
        <>
            {nextStep && (
                <ItemFactRow
                    flexBasisLeft={250}
                    title={'Next Manufacturing Step'}
                    value={PARTIALS_MANUFACTURING_STEP_TO_TEXT[nextStep.type]}
                />
            )}
            {issues.map(issue => (
                <ItemFactRow
                    key={issue.type}
                    flexBasisLeft={250}
                    title={TRY_IN_ISSUE_NAMES[issue.type]}
                    value={issue.details}
                />
            ))}
        </>
    );
};

interface PartialsSingleFulfillmentBlockProps {
    partialsFulfillment: LabsGqlPartialsSingleFulfillmentFragment;
    fulfillment?: LabsGqlLabOrderSingleFulfillmentFragment;
    nextStep: LabsGqlPartialsSingleFulfillmentFragment | null;
}

const PartialsSingleFulfillmentBlock: React.VFC<PartialsSingleFulfillmentBlockProps> = props => {
    const { nextStep, partialsFulfillment, fulfillment } = props;

    const fulfillmentReturn =
        partialsFulfillment.__typename === 'PartialsFulfillmentWaxRimDTO' ||
        partialsFulfillment.__typename === 'PartialsFulfillmentTryInDTO'
            ? partialsFulfillment.return
            : null;

    const formatDate = (dateString: string | undefined | null): string | null => {
        if (!dateString) {
            return null;
        }

        return new Date(dateString).toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        });
    };

    return (
        <OrderDetailBlock
            variant={'full'}
            title={`Partials Fulfillment for ${PARTIALS_MANUFACTURING_STEP_TO_TEXT[partialsFulfillment.type]}`}
        >
            <ItemFactRow flexBasisLeft={180} title={'Start Date'} value={formatDate(fulfillment?.created_at)} />
            <ItemFactRow
                flexBasisLeft={180}
                title={'Fabrication Date'}
                value={formatDate(fulfillment?.manufacturer_sla?.start_date)}
            />
            <ItemFactRow
                flexBasisLeft={180}
                title={'Shipped Date'}
                value={formatDate(fulfillment?.shipment?.ship_date) ?? 'Not shipped'}
            />
            <ItemFactRow
                flexBasisLeft={180}
                title={'Delivered To Practice'}
                value={formatDate(fulfillment?.shipment?.delivery_date) ?? 'Not delivered'}
            />
            <ItemFactRow
                flexBasisLeft={180}
                title={'Shipped to Lab'}
                value={formatDate(fulfillmentReturn?.in_transit_at) ?? 'Not shipped'}
            />
            <ItemFactRow
                flexBasisLeft={180}
                title={'Received at Lab'}
                value={formatDate(fulfillmentReturn?.received_at) ?? 'Not received'}
            />
            {(partialsFulfillment.__typename === 'PartialsFulfillmentWaxRimDTO' ||
                partialsFulfillment.__typename === 'PartialsFulfillmentTryInDTO') && (
                <Text variant={'body2'} medium={true} style={{ marginTop: 4, color: FlossPalette.GRAY }}>
                    Feedback
                </Text>
            )}
            {partialsFulfillment.__typename === 'PartialsFulfillmentTryInDTO' && (
                <PartialsTryInFeedback nextStep={nextStep} fulfillment={partialsFulfillment} />
            )}
            {partialsFulfillment.__typename === 'PartialsFulfillmentWaxRimDTO' && (
                <PartialsWaxRimFeedback nextStep={nextStep} fulfillment={partialsFulfillment} />
            )}
        </OrderDetailBlock>
    );
};

interface OrderDetailPartialsProps {
    order: LabsGqlLabOrderFragment;
}

export const OrderDetailPartials: React.FC<OrderDetailPartialsProps> = ({ order }) => {
    const { fulfillment, partials_fulfillment } = order;

    if (!partials_fulfillment) {
        return null;
    }

    return (
        <>
            <PartialsSingleFulfillmentBlock
                partialsFulfillment={partials_fulfillment.current}
                fulfillment={fulfillment.current}
                nextStep={partials_fulfillment.upcoming}
            />
            {partials_fulfillment.history.map((partialsFulfillment, idx) => (
                <PartialsSingleFulfillmentBlock
                    key={idx}
                    partialsFulfillment={partialsFulfillment}
                    fulfillment={fulfillment.history[idx]}
                    nextStep={partials_fulfillment.history[idx - 1] ?? partials_fulfillment.current}
                />
            ))}
        </>
    );
};
