import type {
    DesignCaseParserOrder,
    Model,
    ModelPayload,
    ModelPayloadItem,
    ModelPayloadView,
    SingletonModelPayloadView,
    TexturedModelPayload,
    UntexturedModelPayload,
} from '@orthly/dentin';
import { DesignCaseParser, Jaw, ModelPayloadViewKind, loadModel } from '@orthly/dentin';
import type { DesignModelPayloadsDesignRevision_FragmentFragment } from '@orthly/graphql-inline-react';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import { FileNameUtils } from '@orthly/runtime-utils';
import type { DesignMetadata } from '@orthly/shared-types';
import { getUnnByFileName } from '@orthly/shared-types';
import { OrthlyBrowserConfig } from '@orthly/ui';
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import * as THREE from 'three';

type DesignCase = Pick<DesignModelPayloadsDesignRevision_FragmentFragment, 'case_file_model_elements'>;

// Given a list of scans, returns the MB scans if they exist, or else the regular prep/antag scans
function reduceJawScans(scans: ModelPayload[]) {
    const modelBuilderScans = scans.filter(scan => scan.name.includes('MB '));
    return modelBuilderScans.length ? modelBuilderScans : scans;
}

// Groups by the same path rather than name so that files in different folders w/ same name
// (e.g. upper and lower prep) don't get merged into just one prep scan being selected.
function groupPayloadsByName(designPayloads: ModelPayload[]): Record<string, ModelPayload[]> {
    return _.groupBy(designPayloads, payload => FileNameUtils.removeExtension(payload.path));
}

// Returns the groups of design payloads, tagged as what they actually represent in terms of the full design.
// As an example, we return every restorative in one array, every lower jaw scan in one array, and so on.
// Split out for readability, and testing!
export function getTaggedDesignPayloads(
    order: DesignCaseParserOrder,
    designPayloads: ModelPayload[],
    design?: DesignCase,
) {
    const validPayloads: ModelPayload[] = _.compact(designPayloads ?? []);
    const groups = groupPayloadsByName(validPayloads);
    const restoratives = DesignCaseParser.getRestoratives(order, validPayloads, design);
    const printedModels = DesignCaseParser.getPrintedModels(validPayloads, design);
    const qcExtras = validPayloads.filter(pl => pl.type === LabsGqlOrderDesignScanType.QcExtras);

    const validPrepScanNames = ['PreparationScan', 'MB Preparation scan'];
    const validJawScanNames = ['AntagonistScan', 'MB Antagonist scan', ...validPrepScanNames];

    // Some terminology:
    // Prep scan: the prepared jaw and teeth that the restorative will attach to.
    // Antagonist scan: the other jaw
    // If there are restoratives on the top and bottom, both the upper and lower will have a Prep scan, but neither will have an Antagonist scan!
    const isJawScan = (payload: ModelPayload, side: 'upper' | 'lower') =>
        payload.path.toLowerCase().includes(`/${side}/`) && validJawScanNames.includes(payload.name);

    // We store the lower jaw, regardless of if it has a prep or antagonist, and the upper jaw, respectively.
    const lowerJaw = reduceJawScans(validPayloads.filter(payload => isJawScan(payload, 'lower')));
    const upperJaw = reduceJawScans(validPayloads.filter(payload => isJawScan(payload, 'upper')));
    const prepScan = reduceJawScans(validPayloads.filter(payload => validPrepScanNames.includes(payload.name)));

    return { groups, restoratives, lowerJaw, upperJaw, prepScan, qcExtras, printedModels };
}

function getPreparationScan(scans: ModelPayload[]): ModelPayload | undefined {
    return scans.find(scan => scan.name.includes('Preparation'));
}

/**
 * Helper to get UNNs from Anatomy filenames.
 *
 * @param pl
 * @returns
 */
function getUnnsFromAnatomyPath(pl: Pick<ModelPayload, 'name'>): number[] | undefined {
    const smatch = pl.name.match(/\((\d+)\)/);
    if (smatch?.[1] === undefined) {
        return undefined;
    }
    const toothNumber = Number(smatch[1]);
    return [toothNumber];
}

/**
 * Helper to get jaw name from a file name
 *
 * @param pl
 * @returns
 */
function getJawFromScanPath(pl: Pick<ModelPayload, 'path'>): Jaw | undefined {
    const parts = pl.path.toLowerCase().split('/');

    const scansIdx = parts.findIndex(s => s === 'scans');

    if (scansIdx < 0 || scansIdx !== parts.length - 3) {
        return undefined;
    }

    let jaw: Jaw | undefined = undefined;
    [Jaw.Lower, Jaw.Upper].forEach(j => {
        const jawIdx = parts.findIndex(s => s === j.toLowerCase());
        if (jawIdx === scansIdx + 1) {
            jaw = j;
        }
    });
    return jaw;
}

/**
 * Helper to choose the preferred mesh format for mulitple versions of the *same* model.
 *
 * @param group Multiple payload options for the same model with different modelTypes.
 * @returns
 */
function getPreferredScan(group: ModelPayload[]) {
    return (
        group.find((scan): scan is TexturedModelPayload => scan.model.modelType === 'drc') ??
        group.find((scan): scan is TexturedModelPayload => scan.model.modelType === 'ctm') ??
        group.find((scan): scan is TexturedModelPayload => scan.model.modelType === 'ply') ??
        group.find((scan): scan is UntexturedModelPayload => scan.model.modelType === 'stl')
    );
}

// The props that `getDesignPayloadItems()` adds to ModelPayload to make it a ModelPayloadItem
interface Extras {
    isRestorative: boolean;
    isPrintedModel: boolean;
    initiallyHidden: boolean;
}

/**
 * Build ModelPayloadItems (for use with NewModelViewer; replaces ModelPayloadView)
 *
 * @param order Order information.
 * @param designPayloads Model payload meshes.
 * @param designMetadata Design metadata.
 * @param caseFilePayload Case file.
 * @returns
 */
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getDesignPayloadItems<T extends Omit<ModelPayload, 'model'>>(
    order: DesignCaseParserOrder,
    designPayloads: T[],
    design?: DesignCase,
    isImmediateDenture?: boolean,
): (T & Extras)[] {
    const restoratives = DesignCaseParser.getRestoratives(order, designPayloads, design);
    const printedModels = DesignCaseParser.getPrintedModels(designPayloads, design);

    let haveLowerPrep = false;
    let haveUpperPrep = false;
    designPayloads.forEach(pl => {
        if (pl.type === LabsGqlOrderDesignScanType.Scans) {
            const jaw = getJawFromScanPath(pl);
            if (jaw === Jaw.Upper) {
                haveUpperPrep = true;
            } else if (jaw === Jaw.Lower) {
                haveLowerPrep = true;
            }
        }
    });
    let singlePrepJaw: Jaw | null;
    if (haveLowerPrep && !haveUpperPrep) {
        singlePrepJaw = Jaw.Lower;
    } else if (haveUpperPrep && !haveLowerPrep) {
        singlePrepJaw = Jaw.Upper;
    } else {
        singlePrepJaw = null;
    }

    const getJawFromUnns = (unns: number[] | undefined) => {
        if (unns === undefined) {
            if (singlePrepJaw) {
                return singlePrepJaw;
            }
            return undefined;
        }

        let jaw: Jaw | undefined = undefined;
        [Jaw.Lower, Jaw.Upper].forEach(j => {
            if (ToothUtils.checkGroupMembership(j, unns)) {
                jaw = j;
            }
        });
        return jaw;
    };
    const results = designPayloads.map(pl => {
        const restorativeItem = restoratives.find(item => item.path === pl.path);
        const isPrintedModel = printedModels.some(item => item.path === pl.path);
        const cadAnatomyQcExtra =
            pl.type === LabsGqlOrderDesignScanType.QcExtras ||
            pl.type === LabsGqlOrderDesignScanType.AnatomyElements ||
            pl.type === LabsGqlOrderDesignScanType.Cad;

        let jaw: Jaw | undefined;

        let unns = pl.unns;
        if (cadAnatomyQcExtra) {
            if (!unns?.length) {
                unns = getUnnsFromAnatomyPath(pl);
            }
            jaw = getJawFromUnns(unns);
        } else if (pl.type === LabsGqlOrderDesignScanType.Scans) {
            jaw = getJawFromScanPath(pl);
        } else {
            jaw = getJawFromUnns(unns);
        }

        if (
            // For immediate dentures we show only Preparation scans. Pre Extraction Scan - it's renamed Raw Preparation scan
            isImmediateDenture &&
            pl.type === LabsGqlOrderDesignScanType.Scans &&
            !pl.name.includes('Preparation scan') &&
            !pl.name.includes('Pre Extraction Scan') &&
            !pl.name.includes('Antagonist scan')
        ) {
            return null;
        }

        if (isImmediateDenture && pl.name.includes('Raw Preparation scan') && !pl.name.includes(`${jaw}`)) {
            pl.name = `Pre Extraction Scan ${jaw}`;
        }
        if (pl.name.includes('MB Preparation scan') && !pl.name.includes(`${jaw}`)) {
            pl.name = `${pl.name} ${jaw}`;
        }

        return {
            ...pl,
            jaw,
            isRestorative: !!restorativeItem,
            isPrintedModel,
            unns,
            initiallyHidden: restorativeItem ? !restorativeItem.visible : false,
        };
    });

    return _.compact(results);
}

/**
 * Build ModelPayloadItems from Scans (not design) for use with NewModelViewer; replaces ModelPayloadView)
 *
 * @param order Order information.
 * @param designPayloads Model payload meshes.
 * @returns ModelPayloadItem
 */
export function getScanPayloadItems(designPayloads: ModelPayload[]): ModelPayloadItem[] {
    // Filter out undefined payloads
    const validPayloads: ModelPayload[] = _.compact(designPayloads ?? []);
    const groups = groupPayloadsByName(validPayloads);

    const results = Object.values(groups).map(group => {
        const pl = getPreferredScan(group);
        if (pl === undefined) {
            return null;
        }

        let jaw: Jaw | undefined = undefined;
        if (pl.type === LabsGqlOrderDesignScanType.Scans && !pl.path.includes('Bite')) {
            jaw = getJawFromScanPath(pl);
        }

        return {
            ...pl,
            jaw,
            unns: [],
            isRestorative: false,
            isPrintedModel: false,
        };
    });

    return _.compact(results);
}

// Returns an array of predefined views of the various models in the payload list.
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function getGroupedDesignPreviews(
    order: DesignCaseParserOrder,
    designPayloads: ModelPayload[],
    designMetadata: DesignMetadata,
    design?: DesignCase,
): ModelPayloadView[] {
    const { groups, restoratives, lowerJaw, upperJaw, prepScan, qcExtras, printedModels } = getTaggedDesignPayloads(
        order,
        designPayloads,
        design,
    );

    const unnByFileName = getUnnByFileName(designMetadata);

    const normalPayloads: SingletonModelPayloadView[] = _.compact(
        Object.values(groups).map(payload => {
            const selectedScan = getPreferredScan(payload);
            return selectedScan
                ? {
                      payload: selectedScan,
                      kind: ModelPayloadViewKind.SingletonPayloadView,
                  }
                : null;
        }),
    );

    if (lowerJaw.length === 0 && upperJaw.length === 0) {
        return normalPayloads;
    }

    const hasMetadata = Object.keys(unnByFileName).length > 0;

    const singleArchRestoratives = (jaw: Jaw) =>
        restoratives.filter(r =>
            ToothUtils.checkGroupMembership(jaw, unnByFileName[FileNameUtils.fileNameWithoutExtension(r.path)] ?? []),
        );

    const singleArchQCs = (jaw: Jaw) =>
        qcExtras.filter(m => {
            const smatch = m.name.match(/\((\d+)\)/);
            const toothNumber = smatch?.[1] === undefined ? -1 : Number(smatch[1]);
            return ToothUtils.checkGroupMembership(jaw, [toothNumber]);
        });

    const lowerJawScan = getPreparationScan(lowerJaw) ?? _.first(lowerJaw);
    const upperJawScan = getPreparationScan(upperJaw) ?? _.first(upperJaw);

    return _.compact([
        lowerJaw.length && upperJaw.length
            ? {
                  restoratives,
                  qcExtras,
                  printedModels,
                  // The prop names for AnteriorAndPrepCombined are misnomers. By convention
                  // `prepScan` is the lower jaw scan and `anteriorScan` is the upper jaw scan.
                  prepScan: lowerJawScan,
                  anteriorScan: upperJawScan,
                  kind: ModelPayloadViewKind.AnteriorAndPrepCombined,
              }
            : undefined,
        upperJaw.length && hasMetadata
            ? {
                  printedModels,
                  restoratives: singleArchRestoratives(Jaw.Upper),
                  qcExtras: singleArchQCs(Jaw.Upper),
                  prepScan: upperJawScan,
                  jaw: Jaw.Upper,
                  kind: ModelPayloadViewKind.PrepCombined,
              }
            : undefined,
        lowerJaw.length && hasMetadata
            ? {
                  printedModels,
                  restoratives: singleArchRestoratives(Jaw.Lower),
                  qcExtras: singleArchQCs(Jaw.Lower),
                  prepScan: lowerJawScan,
                  jaw: Jaw.Lower,
                  kind: ModelPayloadViewKind.PrepCombined,
              }
            : undefined,
        // fallback for orders without tooth metadata
        hasMetadata
            ? undefined
            : {
                  printedModels,
                  restoratives,
                  prepScan: getPreferredScan(prepScan),
                  jaw: null,
                  kind: ModelPayloadViewKind.PrepCombined,
              },
        ...normalPayloads,
    ]);
}

// model loader from buffer object retrieved from Firebase Url
// conveniently chooses specific model based on file extension
export async function loadModelFromBuffer({
    buffer,
    filePath,
    useExperimentalPlyLoader = false,
    shouldCalculateUVs = false,
}: {
    buffer: ArrayBuffer;
    filePath: string;
    useExperimentalPlyLoader?: boolean;
    shouldCalculateUVs?: boolean;
}): Promise<Model> {
    try {
        return await loadModel({ buffer, filePath, useExperimentalPlyLoader, shouldCalculateUVs });
    } catch (error: any) {
        console.error(`Error loading model for ${filePath}:`, error);
        // This error gets thrown in QA when the model is not found, which happens a lot.
        // Since this is expected, and the resulting errors reported to Sentry
        // are not actionable, we only want to log this error in production.
        if (OrthlyBrowserConfig.isProduction) {
            Sentry.captureException(error, { extra: { filePath, useExperimentalPlyLoader }, level: 'fatal' });
        }
        throw error;
    }
}

export async function loadColorMapFromImage(filePath: string | null | undefined): Promise<THREE.Texture | undefined> {
    if (!filePath) {
        return undefined;
    }
    try {
        const loader = new THREE.TextureLoader();
        return await loader.loadAsync(filePath);
    } catch (error: any) {
        // This error gets thrown in QA when the image is not found, which happens a lot.
        // Since this is expected, and the resulting errors reported to Sentry
        // are not actionable, we only want to log this error in production.
        if (OrthlyBrowserConfig.isProduction) {
            Sentry.captureException(error, { extra: { filePath }, level: 'fatal' });
        }
        throw error;
    }
}
