import { useOpenCheckout } from '../../../../utils/useSetScreen';
import { useOpenOrderDetailPage } from '../../../labs/LabsUtils';
import { DeleteUnusedScan } from '../../../labs/actions/DeleteUnusedScan';
import { calculateSKU, MultiProductImageWrapper, OrderSummaryRowBase } from '@orthly/dentin';
import type { LabsGqlScanFileFragment } from '@orthly/graphql-operations';
import { isValidProductUnitType } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { useScreenIsMobile, Tooltip, Button, Box, styled, Text, useScreenIsMd } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';

interface UnsubmittedScanSummaryRowProps {
    scan: LabsGqlScanFileFragment;
}

const trackingData = {
    AssetName: 'UnsubmittedScanSummaryRow',
    AssetCTAText: 'Order',
    AssetLocation: 'Order summary row',
};

const ActionsLayout = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    alignItems: 'end',
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('lg')]: {
        justifyContent: 'start',
    },
}));

const StyledButton = styled(Button)({
    padding: '0 12px',
    height: 'unset',
});

const UnsubmittedScanSummaryRowAction: React.VFC<{ scan: LabsGqlScanFileFragment }> = props => {
    const { scan } = props;
    const isMobile = useScreenIsMobile();
    const isTablet = useScreenIsMd();
    const openCheckout = useOpenCheckout();

    return (
        <ActionsLayout style={isTablet ? { flex: 1, justifyContent: 'flex-end' } : {}}>
            <Text variant={isMobile ? 'caption' : 'body2'} color={'BLACK'}>
                Scanned on {dayjs(scan.created_at).format('MM/DD')}
            </Text>

            <div style={{ display: 'flex' }}>
                <DeleteUnusedScan
                    scan={scan}
                    CustomRender={renderProps => (
                        <StyledButton
                            variant={'ghost'}
                            onClick={() => renderProps.openModal()}
                            analytics={{
                                AssetName: 'Delete Saved Order',
                                AssetLocation: 'Practice - Order List',
                            }}
                        >
                            Delete
                        </StyledButton>
                    )}
                />
                <Tooltip title={isMobile ? `Mobile orders aren't quite ready yet...` : ''}>
                    <div>
                        <StyledButton
                            variant={'ghost'}
                            onClick={() => {
                                openCheckout({ trackingData, scanId: scan.id });
                            }}
                            style={{ marginRight: -12 }}
                            disabled={isMobile}
                        >
                            Place order
                        </StyledButton>
                    </div>
                </Tooltip>
            </div>
        </ActionsLayout>
    );
};

export const UnsubmittedScanSummaryRow: React.FC<UnsubmittedScanSummaryRowProps> = props => {
    const { scan } = props;
    const openOrder = useOpenOrderDetailPage();

    const products = _.compact(
        scan.restorations.map(r => {
            const sku = calculateSKU(r);
            return isValidProductUnitType(sku) ? sku : undefined;
        }),
    );

    return (
        <OrderSummaryRowBase
            patientName={Format.possessiveNoun(`${scan.patient_first_name} ${scan.patient_last_name}`)}
            patientSKUs={'saved order'}
            subtitle={''}
            onClick={() => {}}
            iconsSection={
                <Box sx={{ alignItems: 'flex-start', flexWrap: 'nowrap', width: 'auto' }}>
                    <MultiProductImageWrapper products={products} size={24} />
                </Box>
            }
            stepperSection={<UnsubmittedScanSummaryRowAction scan={scan} />}
            openOrder={openOrder}
        />
    );
};
