import { usePricingGuide } from '../PricingGuide.context';
import { ClearAlignerPricing } from './ClearAligner/ClearAlignerPricing';
import { CrownBridgePricing } from './CrownBridge/CrownBridgePricing';
import { ImplantPricing } from './Implant/ImplantPricing';
import { RemovablePricing } from './Removable/RemovablePricing';
import { LoadBlocker, QueryString } from '@orthly/ui';
import { FlossPalette, stylesFactory, Grid, Tab, Tabs } from '@orthly/ui-primitives';
import classNames from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';

const pricingGuideTabs = {
    CrownEtc: { label: 'Crown & Bridge, Veneers, Inlays & Onlays', Component: CrownBridgePricing, url: 'crown-etc' },
    Implant: { label: 'Implant solutions', Component: ImplantPricing, url: 'implant' },
    Removable: { label: 'Removables', Component: RemovablePricing, url: 'removable' },
    ClearAligner: { label: 'Clear aligners', Component: ClearAlignerPricing, url: 'clear-aligner' },
} as const;

type PricingTab = keyof typeof pricingGuideTabs;

const useStyles = stylesFactory(() => ({
    root: {
        paddingBottom: '12px',
        maxHeight: '100%',
        position: 'relative',
    },
    grid: {
        gap: '24px',
    },
    tabs: {
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: '0 12px',
    },
    tab: {
        whiteSpace: 'nowrap',
        maxWidth: 'unset',
    },
    contentContainer: {
        minHeight: 0,
        overflow: 'auto',
        paddingTop: '24px',
        paddingBottom: '24px',
        margin: '0 24px',
        '@media print': {
            width: '95%', // table is too wide to print full width
            padding: '10px 0 0 0',
            margin: '0 auto',
        },
    },
}));

const isValidTab = (tab: string): tab is PricingTab => tab in pricingGuideTabs;

export interface PricingGuideTabsProps {
    isModal?: boolean;
}

export const PricingGuideTabs: React.FC<PricingGuideTabsProps> = ({ isModal }) => {
    const classes = useStyles();
    const history = useHistory();
    const { loading } = usePricingGuide();
    const [activeTab, setActiveTab] = React.useState<PricingTab>('CrownEtc');
    const { Component: ActiveContent } = pricingGuideTabs[activeTab];

    React.useEffect(() => {
        const activeTab = QueryString.parse(history.location.search).type;
        if (activeTab) {
            for (const tab in pricingGuideTabs) {
                if (isValidTab(tab) && pricingGuideTabs[tab].url === activeTab) {
                    setActiveTab(tab);
                }
            }
        }
    }, [history.location.search]);

    return (
        <LoadBlocker
            blocking={loading}
            loader={'dandy'}
            ContainerProps={{
                direction: 'column',
                wrap: 'nowrap',
                className: classes.root,
            }}
        >
            <Grid item>
                <Tabs
                    indicatorColor={'secondary'}
                    textColor={'inherit'}
                    className={classes.tabs}
                    onChange={(_, selectedTab) => {
                        if (!isValidTab(selectedTab)) {
                            return;
                        }

                        setActiveTab(selectedTab);

                        if (!isModal) {
                            history.push({
                                pathname: '/my_practice/pricing',
                                search: `?type=${pricingGuideTabs[selectedTab].url}`,
                            });
                        }
                    }}
                    value={activeTab}
                >
                    {Object.entries(pricingGuideTabs).map(([tabName, { label }]) => (
                        <Tab
                            className={classes.tab}
                            data-test={`pricing-tab-${tabName}`}
                            key={tabName}
                            label={label}
                            value={tabName}
                        />
                    ))}
                </Tabs>
            </Grid>
            <Grid item className={classNames(classes.grid, classes.contentContainer)}>
                <ActiveContent />
            </Grid>
        </LoadBlocker>
    );
};
