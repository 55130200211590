import type * as THREE from 'three';

export interface ExpandedMorphPoints {
    name: string;
    points: THREE.Vector3[];
}

export interface ExpandedUpdatedSpline extends ExpandedMorphPoints {
    pointsHash: string;
}

export interface ExpandedItem {
    modelElementId: string;
    vertexPositions: THREE.Vector3[];
    morphPoints: ExpandedMorphPoints[];
    updatedSplines: ExpandedUpdatedSpline[];
    insertionAxis: THREE.Vector3;
}
