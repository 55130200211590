import type { ModelPayloadItem } from './ModelViewerTypes';
import type { ToothNumber } from '@orthly/items';
import { IconToggleButton } from '@orthly/ui';
import { InsertionDirectionIcon } from '@orthly/ui-primitives';
import React from 'react';

type InsertionAxesMap = Map<ToothNumber, THREE.Vector3>;

interface ShowPoiButtonsProps {
    selectedInsertionAxis?: ToothNumber;
    setSelectedInsertionAxis: React.Dispatch<React.SetStateAction<ToothNumber | undefined>>;
    insertionAxes?: InsertionAxesMap;
    modelPayloadItems: ModelPayloadItem[];
    className?: string;
}

/**
 * A series of buttons for toggling the visibility of the insertion axis arrow for each tooth.
 */
export const ShowPoiButtons: React.VFC<ShowPoiButtonsProps> = ({
    selectedInsertionAxis,
    setSelectedInsertionAxis,
    insertionAxes,
    modelPayloadItems,
    className,
}) => {
    const toggleInsertionAxis = React.useCallback(() => {
        setSelectedInsertionAxis(current => {
            return current ? undefined : insertionAxes?.keys().next().value;
        });
    }, [setSelectedInsertionAxis, insertionAxes]);

    const hasInsertionAxes = !!insertionAxes?.size;

    // We do not want to show this button if the design has restoratives models. In that case, the restoratives
    // appearance controller can be used to toggle the insertion axis.
    if (modelPayloadItems.some(i => i.isRestorative)) {
        return null;
    }

    const tooltip = getTooltip(hasInsertionAxes, selectedInsertionAxis);

    return (
        <>
            <IconToggleButton
                tooltip={tooltip}
                active={!!selectedInsertionAxis}
                disabled={!hasInsertionAxes}
                className={className}
                onClick={toggleInsertionAxis}
            >
                <InsertionDirectionIcon />
            </IconToggleButton>

            {selectedInsertionAxis
                ? Array.from(insertionAxes?.keys() ?? []).map(toothNumber => {
                      return (
                          <IconToggleButton
                              key={toothNumber}
                              tooltip={`Show tooth ${selectedInsertionAxis} insertion axis`}
                              active={selectedInsertionAxis === toothNumber}
                              className={className}
                              onClick={() => setSelectedInsertionAxis?.(toothNumber)}
                          >
                              {toothNumber}
                          </IconToggleButton>
                      );
                  })
                : null}
        </>
    );
};

function getTooltip(hasInsertionAxes: boolean, selectedInsertionAxis?: ToothNumber): string {
    if (!hasInsertionAxes) {
        return 'No insertion axes available';
    }

    return selectedInsertionAxis ? 'Hide insertion axis' : 'Show insertion axis';
}
