import type { VeneerUseOrderRevisionItemsDesign_Fragment } from '../../OrderDesignActions.graphql';
import type { FragmentType, OrderDesignPreviewDesign_FragmentFragmentDoc } from '@orthly/graphql-inline-react';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import constate from 'constate';

export interface GuidedWaxupContextShape {
    order: LabsGqlSingleLabOrderFragment;
    patientName: string;
    refetch: () => Promise<unknown>;
    viewingPreviousDesign: boolean;
    setSelectedDesignRevisionId: (id?: string) => void;
    selectedTab: LabsGqlGuidedWaxupPresetType;
    setSelectedTab: (tab: LabsGqlGuidedWaxupPresetType) => void;
    onBack: () => void;
    userRole: string;
    selectedDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    selectedDesignRevisionAlreadyReviewed: boolean;
    previousDesignFragment?: FragmentType<typeof OrderDesignPreviewDesign_FragmentFragmentDoc>;
    designRevisionFragments: FragmentType<typeof VeneerUseOrderRevisionItemsDesign_Fragment>[];
    selectedDesignRevisionIdx?: number;
    selectedDesignRevisionId?: string;
    isWarningDialogModalOpen: boolean;
    setIsWarningDialogModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isSkipToGeneralViewModalOpen: boolean;
    setIsSkipToGeneralViewModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isIncompletedWorkReminderModalOpen: boolean;
    setIsIncompletedWorkReminderModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileRejectionNoteOpen: boolean;
    setIsMobileRejectionNoteOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileTimelineDialogOpen: boolean;
    setIsMobileTimelineDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isMobileDoctorImagesDialogOpen: boolean;
    setIsMobileDoctorImagesDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
    isAnnotatingScreenshot: boolean;
    setIsAnnotatingScreenshot: React.Dispatch<React.SetStateAction<boolean>>;
    teeth: number[];
    firstPreset: LabsGqlGuidedWaxupPresetType;
    revisionId?: number;
    enableGuidedWaxupComparison?: boolean;
    isSidebarExpanded: boolean;
    setIsSidebarExpanded: React.Dispatch<React.SetStateAction<boolean>>;
    OrderChatWrapper?: React.FC<{
        order: LabsGqlSingleLabOrderFragment;
        hideFooter?: boolean;
    }>;
    internalEvaluation?: boolean;
    showOnlyGeneralView?: boolean;
    isImmediateDenture?: boolean;
    isRemoveable?: boolean;
}

export const [GuidedWaxupContextProvider, useGuidedWaxupContext] = constate(
    (context: GuidedWaxupContextShape) => context,
);
