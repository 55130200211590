import { FlossPalette, stylesFactory } from '@orthly/ui-primitives';

export const GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP = 72;
export const GUIDED_WAXUP_TOOLBAR_HEIGHT_MOBILE = 100;
export const PRESET_SIDEBAR_WIDTH = 220;
export const TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED = 300;
export const TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED = 40;

export const NAV_BUTTON_STYLES = { width: '100%', textAlign: 'center' } as const;
export const HIDE_POPUP_MODAL_KEY = 'hidePopupModal';

export const GUIDED_WAXUP_MOBILE_NAV_HEIGHT = 90;
export const MOBILE_HEIGHT = `calc(100dvh - ${GUIDED_WAXUP_TOOLBAR_HEIGHT_MOBILE}px - ${GUIDED_WAXUP_MOBILE_NAV_HEIGHT}px)`;
export const MOBILE_DIALOG_HEIGHT = `calc(100dvh - ${GUIDED_WAXUP_MOBILE_NAV_HEIGHT}px)`;
export const GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT = `calc(100dvh - ${GUIDED_WAXUP_TOOLBAR_HEIGHT_DESKTOP}px)`;

export const useDesignViewerButtonStyles = stylesFactory(theme => ({
    buttonBorder: {
        borderRadius: 0,
    },
    buttonGroupContainer: {
        position: 'relative',
        display: 'inline-block',
    },
    selectedMobileDesignButton: {
        backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        '&:hover': {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
        },
    },
    buttonGroup: {
        position: 'relative',
    },
    buttonsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'nowrap',
        [theme.breakpoints.down('lg')]: {
            flexDirection: 'column',
        },
    },
    inactiveHeatmap: {
        backgroundColor: FlossPalette.WHITE,
    },
    desktopHeatmapMenu: {
        position: 'absolute',
        top: 50,
        right: 0,
    },
    mobileAnatomyAndTransparencyMenu: {
        marginLeft: 65,
        marginBottom: 10,
    },
    mobileHeatmapMenu: {
        marginLeft: 65,
        marginBottom: 10,
    },
    desktopAnatomyAndTransparencyMenu: {
        position: 'absolute',
        top: 50,
        right: 140,
    },
    hidden: {
        display: 'none',
    },
}));

export const useMobileDialogStyles = stylesFactory(() => ({
    dialog: {
        height: MOBILE_DIALOG_HEIGHT,
    },
    dialogContentContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        height: 70,
        backgroundColor: FlossPalette.TAN,
        borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        marginBottom: 10,
    },
    goBackContainer: {
        width: '40%',
    },
    goBackButton: {
        padding: 8,
        minWidth: 0,
        background: FlossPalette.TAN,
        border: 'none',
    },
    title: {
        alignSelf: 'center',
        width: '60%',
    },
}));

export const GUIDED_WAXUP_COMMON_STYLES = {
    backgroundColor: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
};

export const DESKTOP_SCREEN_CONTAINER_STYLES = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    overflow: 'scroll',
    scrollBehavior: 'smooth',
} as const;
