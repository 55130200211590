// Defines different payloads for ModelViewer
import { FullJawAndRestorativeModelMesh, FullJawModelMesh, PrepAndRestorativeModelMesh } from './ComplexModelMeshes';
import { ModelMesh, QCMeshCustomMaterial } from './ModelMeshes';
import type { ModelPayloadView, ModelPayload, Model } from './ModelViewerTypes';
import { ModelPayloadViewKind, Jaw } from './ModelViewerTypes';
import type { UndercutPropsForModelViewer } from './UndercutViewerApp';
import type { CtmFile } from './ctm/CTMLoader';
import { MODEL_GRAY_COLOR, HIGHLIGHT_TOOTH_COLOR } from './defaultModelColors';
import { StandardScanStoneMaterial } from './materials/StudioLightSetup';
import { ScanMeshShaderPhysicalMaterial } from './materials/scanMeshShaderMaterial';
import type { PlyFile } from '@orthly/forceps';
import { HeatMapType } from '@orthly/forceps';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import type { MarginLine } from '@orthly/shared-types';
import React from 'react';

interface PayloadMeshProps {
    payload: ModelPayloadView;
    enableTexture: boolean;
    showRestoratives: boolean;
    restorativesTransparent: boolean;
    showLayers: boolean;
    showUpperJaw: boolean;
    showLowerJaw: boolean;
    activeQCColorMap?: HeatMapType;
    enableCustomScanShader?: 'design' | 'prep' | 'chairside' | boolean;
    undercutApp?: UndercutPropsForModelViewer;
}

function filterRestoratives(
    restoratives: ModelPayload[],
    config: Pick<PayloadMeshProps, 'showRestoratives' | 'showLayers'>,
): ModelPayload[] {
    if (!config.showRestoratives) {
        return [];
    }

    if (!config.showLayers) {
        return restoratives.filter(r => r.type !== LabsGqlOrderDesignScanType.AnatomyElements);
    }

    return restoratives;
}

function filterHeatmaps(payloads: ModelPayload[], config: Pick<PayloadMeshProps, 'activeQCColorMap'>): ModelPayload[] {
    if (!config.activeQCColorMap || config.activeQCColorMap === HeatMapType.Clearance) {
        return [];
    }
    return payloads.filter(r => r.type === LabsGqlOrderDesignScanType.QcExtras && r.name.includes('Thickness'));
}

export const PayloadMesh: React.FC<PayloadMeshProps> = props => {
    const { payload, enableTexture, activeQCColorMap, enableCustomScanShader, undercutApp } = props;

    const singletonQCMaterial = React.useMemo(() => <QCMeshCustomMaterial wireframe={false} depthtest={true} />, []);
    const customScanMaterial = enableCustomScanShader ? (
        <ScanMeshShaderPhysicalMaterial mode={enableCustomScanShader} vertexColors={true} />
    ) : (
        <StandardScanStoneMaterial color={MODEL_GRAY_COLOR} show_transparent={false} />
    );

    switch (payload.kind) {
        case ModelPayloadViewKind.SingletonPayloadView: {
            const scanRegex = /(Antagonist|Preparation|Prepreparation|scan)/i;
            const shaderEligible = scanRegex.test(payload.payload.name);
            let singletonCustomScanMaterial =
                enableTexture && enableCustomScanShader && shaderEligible ? customScanMaterial : undefined;
            if (undercutApp !== undefined) {
                singletonCustomScanMaterial = undercutApp.undercutMaterial;
            }
            if (
                payload.payload.type === LabsGqlOrderDesignScanType.QcExtras &&
                payload.payload.name.includes('Thickness')
            ) {
                return (
                    <ModelMesh
                        key={payload.payload.name}
                        color={HIGHLIGHT_TOOTH_COLOR}
                        model={payload.payload.model}
                        colorize={true}
                        centered={!payload.disableCentering}
                        customMaterial={singletonQCMaterial}
                        activeHeatMap={activeQCColorMap}
                    />
                );
            }

            return (
                <ModelMesh
                    model={payload.payload.model}
                    colorize={enableTexture}
                    centered={!payload.disableCentering}
                    activeHeatMap={activeQCColorMap}
                    customMaterial={singletonCustomScanMaterial}
                />
            );
        }
        case ModelPayloadViewKind.PrepCombined: {
            // Due to TS limitations, we have to extract these into a separate variable
            const combinedPrep = payload.prepScan;
            const qc_extras = payload.qcExtras ?? [];
            return combinedPrep ? (
                <PrepAndRestorativeModelMesh
                    prep={combinedPrep}
                    restoratives={filterRestoratives(payload.restoratives, props)}
                    qcExtras={filterHeatmaps(qc_extras, props)}
                    enableTexture={enableTexture}
                    enableCustomScanShader={enableCustomScanShader}
                    restorativesTransparent={props.restorativesTransparent}
                    activeColormap={activeQCColorMap}
                    undercutApp={undercutApp}
                />
            ) : null;
        }
        case ModelPayloadViewKind.AnteriorAndPrepCombined: {
            // Due to TS limitations, we have to extract these into a separate variable
            const fullPrep = payload.prepScan;
            const anterior = payload.anteriorScan;
            const qc_extras = payload.qcExtras ?? [];
            return fullPrep && anterior ? (
                <FullJawAndRestorativeModelMesh
                    prep={fullPrep}
                    anterior={anterior}
                    enableCustomScanShader={enableCustomScanShader}
                    restoratives={filterRestoratives(payload.restoratives, props)}
                    qcExtras={filterHeatmaps(qc_extras, props)}
                    enableTexture={enableTexture}
                    restorativesTransparent={props.restorativesTransparent}
                    activeColormap={activeQCColorMap}
                    undercutApp={undercutApp}
                />
            ) : null;
        }
        case ModelPayloadViewKind.FullJaw:
            return (
                <FullJawModelMesh
                    upper={payload.upper}
                    lower={payload.lower}
                    showUpper={props.showUpperJaw}
                    showLower={props.showLowerJaw}
                    enableCustomScanShader={enableCustomScanShader}
                />
            );
    }
};

/*
 * matches a margin to it's associated jaw Scan mesh for a given
 * payload in Old Model Viewer
 * */

export function getMarginAssociatedGeometryFromPayload(margin: MarginLine, payloadView: ModelPayloadView) {
    // validate, some margins have unknown UNN
    if (margin.tooth < 1 || margin.tooth > 32) {
        return undefined;
    }

    const targetJaw = ToothUtils.toothIsUpper(margin.tooth) ? Jaw.Upper : Jaw.Lower;

    switch (payloadView.kind) {
        case ModelPayloadViewKind.SingletonPayloadView: {
            return payloadView.payload;
        }
        case ModelPayloadViewKind.PrepCombined: {
            const prepModel = payloadView.prepScan;
            if (payloadView.jaw === targetJaw) {
                return prepModel;
            }

            return undefined;
        }

        case ModelPayloadViewKind.AnteriorAndPrepCombined: {
            // see ReviewScans.tsx in Scanner repo
            // see getGroupedDesignPreviews() method in OrderDesignPreview.util.ts

            // For AnteriorAndPrepCombined, `prepScan` and `anteriorScan` are misnomers.
            // They hold the lower and upper jaw scans, respectively.
            const lowerJaw = payloadView.prepScan;
            const upperJaw = payloadView.anteriorScan;

            return targetJaw === Jaw.Upper ? upperJaw : lowerJaw;
        }
        case ModelPayloadViewKind.FullJaw: {
            const upperJaw = payloadView.upper;
            const lowerJaw = payloadView.lower;

            return targetJaw === Jaw.Upper ? upperJaw : lowerJaw;
        }
    }
}

export type ModelWithTransform = PlyFile | CtmFile;

/**
 * Helper to determine if a model is a ply or ctm model
 * @param model Model in question.
 * @returns Model if it is, otherwise undefined.
 */

export function isPlyOrCtmModel(model: Model): ModelWithTransform | undefined {
    return model.modelType === 'ply' || model.modelType === 'ctm' ? model : undefined;
}

export function plyOrCtmPayload(payload: ModelPayloadView): ModelWithTransform[] {
    let model: Model | undefined;
    switch (payload.kind) {
        case ModelPayloadViewKind.SingletonPayloadView:
            model = payload.payload.model;
            break;
        case ModelPayloadViewKind.PrepCombined:
            model = payload.prepScan?.model;
            break;
        case ModelPayloadViewKind.AnteriorAndPrepCombined:
            model = payload.prepScan?.model ?? payload.anteriorScan?.model;
            break;
    }
    const result = model && isPlyOrCtmModel(model);
    return result ? [result] : [];
}

/**
 * Helper to determine if a ModelPayloadView has anatomy layers.
 * @param payload
 * @returns
 */

export function payloadViewHasLayers(payload: ModelPayloadView): boolean {
    switch (payload.kind) {
        case ModelPayloadViewKind.SingletonPayloadView:
        case ModelPayloadViewKind.FullJaw:
            return false;
        case ModelPayloadViewKind.PrepCombined:
        case ModelPayloadViewKind.AnteriorAndPrepCombined:
            return payload.restoratives.some(r => r.type === LabsGqlOrderDesignScanType.AnatomyElements);
    }
}
