import { findClosestPointToPolyline } from '../MarginLines';
import { logger } from '../Utils/Logger';
import { AttributeName } from './BufferAttributeConstants';
import { getFaceNearestVertex, getVertex, getVertexNormal, setVertex } from './Mesh3d.util';
import type { MeshConnectivityGraph } from './MeshConnectivityGraph';
import { disconnectLoopFromGraph, findIslands, getConnectedMarginPath } from './MeshConnectivityGraph.util';
import { getSubgeometryFromFaces } from './Subgeometry.util';
import type { IntaglioSettings } from '@orthly/shared-types';
import _ from 'lodash';
import * as THREE from 'three';
import type { MeshBVH } from 'three-mesh-bvh';

/**
 * Extracts a prep site from a scan geometry using a group of points representing the margin line.
 * @param geometry The scan geometry
 * @param margin The margin of points to use to extract the prep site
 * @returns The prep site geometry, or undefined if the prep site could not be extracted
 */
export function extractPrepSiteFromScan(
    geometry: THREE.BufferGeometry,
    margin: THREE.Vector3[],
    bvh: MeshBVH,
    originalGraph: MeshConnectivityGraph,
): THREE.BufferGeometry | undefined {
    const closestPoints = _.compact(
        margin.map(point => {
            const closestResult = bvh.closestPointToPoint(point);
            if (!closestResult) {
                return undefined;
            }
            return getFaceNearestVertex(geometry, closestResult.faceIndex, closestResult.point);
        }),
    );
    const marginPath = _.uniq(getConnectedMarginPath(originalGraph, closestPoints));

    // **** disconnect the two sides inside the graph **********/
    const updatedGraph = _.cloneDeep(originalGraph);
    disconnectLoopFromGraph(updatedGraph.adjacencyVertexToVertex, marginPath);

    // Find islands in the remaining geometry
    const islands = findIslands(updatedGraph.adjacencyVertexToVertex).filter(island => island.length > 100);
    if (islands.length !== 2) {
        logger.info(`Found wrong number of Islands: ${islands.length}`);
        return;
    }

    // get the smaller island
    const smallerIsland = islands[1];
    if (!smallerIsland) {
        return;
    }

    // get faces of the island
    const faces: Set<number> = new Set();
    const facesArray = smallerIsland.flatMap(vertex => updatedGraph.adjacencyFacesToVertex[vertex]);
    facesArray.forEach(face => {
        if (face !== undefined) {
            faces.add(face);
        }
    });

    const result = getSubgeometryFromFaces(geometry, faces, updatedGraph);
    if (!result) {
        logger.info('Failed to get subgeometry');
        return;
    }
    return result.geometry;
}

// Parameters to control the ideal gap between the intaglio and the scan surface
export type CementGapSettings = Required<
    Pick<IntaglioSettings, 'cementGap' | 'sealZoneGap' | 'sealZoneWidth' | 'transitionWidth'>
>;

// Nominal values from tech spec document: https://docs.google.com/document/d/1ZMHEcG0kO4agmNXxYZC_BCm4LqMsEuuAG6tDzYTmP2E
export const DEFAULT_CEMENT_GAP_SETTINGS: CementGapSettings = {
    sealZoneGap: 0.04,
    cementGap: 0.07,
    sealZoneWidth: 0.5,
    transitionWidth: 0.2,
};

// A function representing the ideal gap distance between the intaglio and the scan surface based on the distance to the margin line
export function idealGapDistanceFunction(
    x: number,
    { sealZoneGap, cementGap, sealZoneWidth, transitionWidth }: CementGapSettings,
): number {
    const x1 = sealZoneWidth - transitionWidth / 2;
    const x2 = sealZoneWidth + transitionWidth / 2;
    const t = Math.max(0, Math.min((x - x1) / (x2 - x1), 1));
    return sealZoneGap + t * (cementGap - sealZoneGap);
}

/**
 * move all the vertices of the prep site by the ideal distance.
 * @param prepSite The prep site geometry
 * @param margin The margin of points used to extract the prep site
 * @returns The idealized prep surface geometry
 */
export function extrudeIdealizedPrepSite(
    prepSite: THREE.BufferGeometry,
    margin: THREE.Vector3[],
    idealGapParameters: CementGapSettings = DEFAULT_CEMENT_GAP_SETTINGS,
): THREE.BufferGeometry | undefined {
    const result = prepSite.clone();
    const positions = prepSite.getAttribute(AttributeName.Position);
    if (!positions) {
        logger.info('Failed to get positions');
        return undefined;
    }
    if (!prepSite.hasAttribute(AttributeName.Normal)) {
        logger.info('Failed to get normals');
        return undefined;
    }
    const vertex = new THREE.Vector3();
    const normal = new THREE.Vector3();
    const numberOfVertices = positions.count;
    for (let i = 0; i < numberOfVertices; i++) {
        getVertex(i, prepSite, vertex);
        getVertexNormal(i, prepSite, normal);
        const { closestDistance } = findClosestPointToPolyline(margin, vertex, true, false);
        const idealDistance = idealGapDistanceFunction(closestDistance, idealGapParameters);
        vertex.addScaledVector(normal, idealDistance);
        setVertex(i, result, vertex.x, vertex.y, vertex.z);
    }
    return result;
}
