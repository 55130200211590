import type { BufferView, FixedTrivialType, TrivialType, TrivialTypeof } from './Util';
import { U8, isFixedType, getRandomValues } from './Util';

// Vector type.
//
export class Vec implements BufferView {
    // Underlying resource.
    //
    raw: Uint8Array = U8.Empty;

    // Constructed from either another buffer or length.
    //
    constructor(n?: number);
    constructor(buffer: ArrayBufferLike | BufferView, byteOffset?: number, byteLength?: number);
    constructor(n: number | ArrayBufferLike | BufferView = 0, byteOffset?: number, byteLength?: number) {
        if (n) {
            if (typeof n === 'number') {
                this.raw = U8.array(n);
            } else {
                this.raw = U8.view(n, byteOffset, byteLength);
            }
        }
    }

    // Comparsion.
    //
    compare(other: Vec): number {
        const lenMax = Math.max(this.length, other.length);
        for (let n = 0; n < lenMax; n++) {
            const a = this.raw[n] ?? 0;
            const b = other.raw[n] ?? 0;
            if (a !== b) {
                return a - b;
            }
        }
        return 0;
    }
    equals(other: Vec) {
        return this.compare(other) === 0;
    }

    // Random fill.
    //
    fillRandom(begin: number = 0, end: number = this.length) {
        getRandomValues(this.raw.subarray(begin, end));
        return this;
    }
    static random(n: number) {
        return new Vec(n).fillRandom();
    }

    // Streaming API.
    //
    static async consume(stream: Blob | AsyncIterable<BufferView>) {
        if (stream instanceof Blob) {
            return new Vec(await stream.arrayBuffer());
        } else {
            const result = new Vec();
            for await (const piece of stream) {
                result.append(piece);
            }
            return result;
        }
    }

    // Concat.
    //
    static concat(buffers: Iterable<BufferView>) {
        let totalLength = 0;
        for (const { byteLength } of buffers) {
            totalLength += byteLength;
        }
        const result = U8.array(totalLength);
        let pos = 0;
        for (const view of buffers) {
            result.set(U8.view(view), pos);
            pos += view.byteLength;
        }
        return new Vec(result);
    }

    // Clone.
    //
    clone() {
        const res = new Vec(this);
        this.raw = new Uint8Array(new ArrayBuffer(this.capacity), 0, this.length);
        this.raw.set(res.raw);
        return res;
    }

    // Implement BufferLike interface.
    //
    readonly BYTES_PER_ELEMENT = 1;
    get buffer() {
        return this.raw.buffer;
    }
    get byteLength() {
        return this.raw.byteLength;
    }
    get byteOffset() {
        return this.raw.byteOffset;
    }
    fill(value: number, start?: number, end?: number) {
        this.raw.fill(value, start, end);
        return this;
    }

    // Copy operations.
    //
    copyWithin(target: number, start: number, end?: number) {
        this.raw.copyWithin(target, start, end);
        return this;
    }
    copyFrom(buffer: BufferView, offset: number = 0) {
        this.raw.set(new Uint8Array(buffer.buffer, buffer.byteOffset, buffer.byteLength), offset);
        return this;
    }
    copyInto(buffer: BufferView, offset: number = 0) {
        new Uint8Array(buffer.buffer, buffer.byteOffset, buffer.byteLength).set(this.raw, offset);
        return this;
    }

    // Implement the vector interface.
    //
    get capacity() {
        return this.raw.buffer.byteLength - this.byteOffset;
    }
    get length() {
        return this.raw.byteLength;
    }
    get empty() {
        return !this.length;
    }

    // Internal resizing logic with allocation amortizing.
    //
    #resize(capacityIn: number, length: number, amortize: boolean = true) {
        let capacity = capacityIn;
        if (!capacity) {
            this.raw = U8.Empty;
        } else {
            let copyFrom: Uint8Array | undefined;
            let buffer = this.raw.buffer;
            let offset = this.raw.byteOffset;
            if (capacity !== this.capacity) {
                if (amortize && capacity > this.capacity) {
                    capacity += capacity >> 1;
                }
                offset = 0;
                buffer = new ArrayBuffer(capacity);
                copyFrom = this.raw.subarray(0, length);
            } else if (this.length === length) {
                return;
            }

            const newView = new Uint8Array(buffer, offset, length);
            if (copyFrom) {
                newView.set(copyFrom);
            }
            this.raw = newView;
        }
    }
    #allocate(off: number) {
        const newPos = off < 0 ? -off : 0;
        const newLength = this.length + Math.abs(off);
        if (this.buffer.byteLength >= newLength) {
            // adding at the beginning?:
            if (off < 0) {
                if (this.byteOffset >= -off) {
                    this.raw = new Uint8Array(this.buffer, this.byteOffset + off, this.byteLength - off);
                    return;
                }
            } else if (off === 0) {
                return;
            }
            const full = new Uint8Array(this.buffer, 0, this.buffer.byteLength);
            full.copyWithin(newPos, this.byteOffset, this.byteLength);
            this.raw = full.subarray(0, newLength);
        } else {
            const n = new Uint8Array(newLength + (newLength >> 1));
            n.set(this.raw, newPos);
            this.raw = n.subarray(0, newLength);
        }
    }

    // Capacity control.
    //
    reserve(n: number) {
        if (this.capacity < n) {
            this.#resize(n, this.length);
        }
        return this;
    }
    shrinkToFit() {
        if (this.capacity !== this.length) {
            this.#resize(this.length, this.length);
        }
        return this;
    }

    // Size control.
    //
    resize(newLength: number) {
        if (newLength > this.capacity) {
            this.#resize(newLength, newLength);
        } else {
            this.#resize(this.capacity, newLength);
        }
        return this;
    }

    // Inserts/appends/prepends other byte-arrays.
    //
    insert(at: number, { byteLength, byteOffset, buffer }: BufferView) {
        if (at === 0) {
            this.#allocate(-byteLength);
        } else if (at === this.byteLength) {
            this.#allocate(byteLength);
        } else {
            this.resize(this.length + byteLength);
            this.raw.copyWithin(byteLength + at, at);
        }
        this.raw.set(new Uint8Array(buffer, byteOffset, byteLength), at);
        return this;
    }
    append(view: BufferView) {
        return this.insert(this.length, view);
    }
    prepend(view: BufferView) {
        return this.insert(0, view);
    }

    // Erases a specific part of buffer.
    //
    erase(begin: number = 0, end: number = this.length) {
        if (!begin) {
            this.raw = new Uint8Array(this.buffer, this.byteOffset + end, this.byteLength - end);
        } else if (end === this.length) {
            this.raw = new Uint8Array(this.buffer, this.byteOffset, begin);
        } else {
            this.raw.copyWithin(begin, end);
            this.resize(this.length - (end - begin));
        }
        return this;
    }

    // Trivial type utilities.
    //
    get<Ty extends TrivialType>(t: Ty, offset: number): TrivialTypeof<Ty> {
        if (isFixedType(t)) {
            return t.from(this.raw.subarray(offset));
        } else {
            return t.shift(this.subarray(offset));
        }
    }
    set<Ty extends TrivialType>(t: Ty, offset: number, value: TrivialTypeof<Ty>) {
        if (isFixedType(t)) {
            this.raw.set(t.bytes(value), offset);
        } else {
            const tmp = new Vec();
            t.unshift(tmp, value);
            tmp.copyInto(this, offset);
        }
        return this;
    }
    push<Ty extends TrivialType>(t: Ty, value: TrivialTypeof<Ty>) {
        if (isFixedType(t)) {
            return this.append(t.bytes(value));
        } else {
            const tmp = new Vec();
            t.unshift(tmp, value);
            this.append(tmp);
        }
        return this;
    }
    pop<Ty extends FixedTrivialType>(t: Ty): TrivialTypeof<Ty> {
        const newLen = this.length - t.Length;
        const v = this.get(t, newLen);
        this.erase(newLen);
        return v;
    }
    shift<Ty extends TrivialType>(t: Ty): TrivialTypeof<Ty> {
        return t.shift(this);
    }
    unshift<Ty extends TrivialType>(t: Ty, value: TrivialTypeof<Ty>) {
        t.unshift(this, value);
        return this;
    }

    // Iteration.
    //
    forEach<Ty extends FixedTrivialType>(t: Ty, callback: (value: TrivialTypeof<Ty>, offset: number) => void) {
        const maxOffset = this.length - t.Length;
        for (let n = 0; n <= maxOffset; n++) {
            callback(t.from(this.raw.subarray(n)), n);
        }
    }
    findIndex<Ty extends FixedTrivialType>(t: Ty, callback: (value: TrivialTypeof<Ty>, offset: number) => boolean) {
        const maxOffset = this.length - t.Length;
        for (let n = 0; n <= maxOffset; n++) {
            if (callback(t.from(this.raw.subarray(n)), n)) {
                return n;
            }
        }
        return -1;
    }
    findLastIndex<Ty extends FixedTrivialType>(t: Ty, callback: (value: TrivialTypeof<Ty>, offset: number) => boolean) {
        const maxOffset = this.length - t.Length;
        for (let n = maxOffset; n >= 0; n--) {
            if (callback(t.from(this.raw.subarray(n)), n)) {
                return n;
            }
        }
        return -1;
    }
    indexOf<Ty extends FixedTrivialType>(t: Ty, value: TrivialTypeof<Ty>) {
        const maxOffset = this.length - t.Length;
        for (let n = 0; n <= maxOffset; n++) {
            if (t.from(this.raw.subarray(n)) === value) {
                return n;
            }
        }
        return -1;
    }
    lastIndexOf<Ty extends FixedTrivialType>(t: Ty, value: TrivialTypeof<Ty>) {
        const maxOffset = this.length - t.Length;
        for (let n = maxOffset; n >= 0; n--) {
            if (t.from(this.raw.subarray(n)) === value) {
                return n;
            }
        }
        return -1;
    }
    *[Symbol.iterator]() {
        yield* this.raw;
    }

    // UTF-8 utilities.
    //
    decodeUtf8(begin: number, endIn?: number) {
        const end = endIn ?? this.raw.indexOf(0, begin) ?? this.length;
        return new TextDecoder('utf8').decode(this.raw.subarray(begin, end));
    }
    encodeUtf8(begin: number, end: number, src: string) {
        new TextEncoder().encodeInto(src, this.raw.subarray(begin, end));
        return this;
    }
    appendUtf8(src: string) {
        return this.append(new TextEncoder().encode(src));
    }
    prependUtf8(src: string) {
        return this.prepend(new TextEncoder().encode(src));
    }
    popUtf8(byteLength: number) {
        const result = this.decodeUtf8(this.length - byteLength, this.length);
        this.resize(this.length - byteLength);
        return result;
    }
    shiftUtf8(byteLength: number) {
        const result = this.decodeUtf8(0, byteLength);
        this.erase(0, byteLength);
        return result;
    }
    toUtf8() {
        return this.decodeUtf8(0, this.length);
    }

    static fromUtf8(text: string) {
        return new Vec(new TextEncoder().encode(text));
    }

    // Slicing.
    //
    slice(begin: number, end: number = this.length) {
        const r = new Vec(end - begin);
        r.raw.set(this.raw.subarray(begin, end));
        return r;
    }
    subarray(begin: number, end: number = this.length) {
        return new Vec(this.buffer, this.byteOffset + begin, end - begin);
    }

    // Inspection.
    //
    toString() {
        let hex: string;
        if (this.raw.length > 16) {
            hex = `${[...this.raw.slice(0, 16)].map(k => k.toString(16).padStart(2, '0')).join(' ')}...`;
        } else {
            hex = [...this.raw.slice(0, 16)].map(k => k.toString(16).padStart(2, '0')).join(' ');
        }
        return `Vec(${this.length},${this.capacity})[${hex}]`;
    }
}
