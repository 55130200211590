import { useDesignViewerButtonStyles } from '../VisualConstants.util';
import { Button, CloseIcon, ExpandMoreIcon, Grid, styled, Text, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

interface DesignViewLiteButtonProps {
    icon: React.ReactNode;
    id?: string;
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onClose: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
    label: string;
    extraLabel?: string;
    isActive: boolean;
}

const LabelIconContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
});

const LabelWrapper = styled('div')({
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginRight: 4,
});

const IconWrapper = styled('div')({
    display: 'flex',
});

export const DesignViewLiteButton: React.VFC<DesignViewLiteButtonProps> = ({
    icon,
    id,
    label,
    extraLabel,
    onClick,
    onClose,
    isActive,
}) => {
    const classes = useDesignViewerButtonStyles();
    const isScreenLgOrUp = useScreenIsLgOrUp();
    const IconEl = extraLabel ? <CloseIcon onClick={onClose} /> : <ExpandMoreIcon />;
    const isSelected = isActive || extraLabel;

    return (
        <Button
            variant={'secondary'}
            size={'small'}
            id={id}
            className={cx(classes.buttonBorder, isSelected && classes.selectedMobileDesignButton)}
            onClick={onClick}
            style={isScreenLgOrUp ? {} : { flex: 1, height: '100%' }}
        >
            <Grid container className={classes.buttonsContainer}>
                {icon}
                {!isScreenLgOrUp ? (
                    <LabelIconContainer>
                        <LabelWrapper>
                            <Text
                                variant={'caption'}
                                medium
                                color={'PRIMARY_FOREGROUND'}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                {`${label}${extraLabel ? `:` : ''}`}
                            </Text>
                            {extraLabel && extraLabel.length > 0 && (
                                <Text
                                    variant={'caption'}
                                    color={'PRIMARY_FOREGROUND'}
                                    style={{ display: 'flex', alignItems: 'center' }}
                                >
                                    {extraLabel}
                                </Text>
                            )}
                        </LabelWrapper>
                        <IconWrapper>{IconEl}</IconWrapper>
                    </LabelIconContainer>
                ) : (
                    <>
                        {label}
                        {extraLabel && extraLabel.length > 0 ? `:` : ''} {extraLabel} {IconEl}
                    </>
                )}
            </Grid>
        </Button>
    );
};
