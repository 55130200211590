import { useFullPageFormStyles } from '../../layouts/FullPageForm.styles';
import { NavigationLogoDandy } from '@orthly/ui';
import { useScreenIsMobile, Grid } from '@orthly/ui-primitives';
import React from 'react';

export const SignUpPageLayout: React.FC = ({ children }) => {
    const classes = useFullPageFormStyles();
    const isMobile = useScreenIsMobile();

    return (
        <Grid container className={classes.root} style={{ overflowY: 'scroll' }}>
            <Grid item container={isMobile} lg={1} md={12}>
                <NavigationLogoDandy className={classes.logo} />
            </Grid>
            {children}
        </Grid>
    );
};
