import { SettingsFormCustomSubmit } from '../../components/SettingsFormCustomSubmit';
import { AddCardForm } from './AddCardForm';
import { FlossPalette, Text, WarningIcon, styled } from '@orthly/ui-primitives';
import React from 'react';

type AddCreditCardFormProps = {
    createCard: (props: { partnerId: string; cardToken: string }) => Promise<void>;
    setOpen: (value: boolean) => void;
    setOpenLinkBankAccountModal: (open: boolean) => void;
};

const ContentContainer = styled('div')({
    marginTop: 32,
});

const WarningBanner = styled('div')({
    display: 'flex',
    backgroundColor: FlossPalette.ATTENTION_BACKGROUND,
    borderRadius: '8px',
    gap: '10px',
    padding: '16px',
    marginBottom: '32px',
});

const StyledText = styled(Text)({
    cursor: 'pointer',
});

export const AddCreditCardForm = ({ createCard, setOpen, setOpenLinkBankAccountModal }: AddCreditCardFormProps) => {
    const [triggerSubmit, setTriggerSubmit] = React.useState<() => void>();
    const setSubmit = React.useCallback((target: () => void) => setTriggerSubmit(() => target), []);
    const [formDisabled, setFormDisabled] = React.useState<boolean>(true);

    return (
        <ContentContainer>
            <WarningBanner>
                <WarningIcon style={{ color: FlossPalette.ATTENTION }} />
                <Text variant={'body2'}>
                    If you choose to pay by credit card, a 2.9% processing fee will be added to your monthly invoice.
                </Text>
            </WarningBanner>
            <AddCardForm
                createCard={createCard}
                onComplete={() => {
                    setOpen(false);
                }}
                onRender={({ submit, disabled }) => {
                    if (triggerSubmit === undefined) {
                        setSubmit(submit);
                    }
                    setFormDisabled(disabled);
                }}
            />
            <Text variant={'body2'}>Avoid the processing fee by paying via ACH (bank account).</Text>
            <StyledText
                variant={'body2'}
                color={'PRIMARY_FOREGROUND'}
                bold
                onClick={() => setOpenLinkBankAccountModal(true)}
            >
                View instructions here
            </StyledText>
            {triggerSubmit && (
                <SettingsFormCustomSubmit
                    setOpen={setOpen}
                    disabled={formDisabled}
                    triggerSubmit={triggerSubmit}
                    confirmText={'Add Credit Card'}
                    style={{ marginTop: 20 }}
                />
            )}
        </ContentContainer>
    );
};
