/**
 * This shader replicates the shader defined by `ScanMeshShaderMaterialDesign`.
 */
import { MODEL_GRAY_COLOR } from './Colors';
import * as THREE from 'three';

export const vertexShader = THREE.ShaderLib.phong.vertexShader;

export const fragmentShader = THREE.ShaderLib.phong.fragmentShader;

export interface DandyMeshPhongShader {
    vertexShader: string;
    fragmentShader: string;
    uniforms: { [uniform: string]: THREE.IUniform };
    lights: true;
}

const STANDARD_MESH_SHININESS = 70;

export function createDandyMeshPhongShader(): DandyMeshPhongShader {
    const uniforms = THREE.UniformsUtils.merge([
        THREE.ShaderLib.phong.uniforms,
        {
            diffuse: { value: MODEL_GRAY_COLOR },
            vertexColors: { value: false },
            transparent: { value: false },
            opacity: { value: 1.0 },
            wireframe: { value: false },
            specular: { value: new THREE.Color(0x111111) },
            shininess: { value: STANDARD_MESH_SHININESS },
            side: { value: THREE.DoubleSide },
        },
    ]);

    return {
        vertexShader,
        fragmentShader,
        uniforms,
        lights: true,
    };
}
