import { AttributeName } from './BufferAttributeConstants';
import type { TypedArray } from 'three';

export interface DuplicateFacets {
    type: 'duplicate-facets';
    facets: number[];
}

export interface DegenerateFacets {
    type: 'degenerate-facets';
    facets: number[];
}

export interface NonManifoldEdge {
    type: 'non-manifold-edge';
    edge: [number, number];
    facets: number[];
}

export interface UnreferencedVertices {
    type: 'unreferenced-vertices';
    vertices: number[];
}

function computeTopologyProps(geometry: THREE.BufferGeometry) {
    const vertCount = geometry.getAttribute(AttributeName.Position).count;
    const unreferencedVerts = new Set<number>(new Array(vertCount).fill(0).map((_, i) => i));
    const edgeIncidence = new Map<string, number[]>();
    const facetsByVerts = new Map<string, number[]>();
    const degenerateFacets: number[] = [];
    const indexAttr = geometry.getIndex();
    if (indexAttr === null) {
        return { unreferencedVerts, edgeIncidence, facetsByVerts, degenerateFacets };
    }

    const indices = indexAttr.array as TypedArray;
    for (let i = 0; i < indexAttr.count / 3; i += 1) {
        const verts = Array.from(indices.slice(3 * i, 3 * i + 3));
        const uniqueVerts = Array.from(new Set(verts));
        uniqueVerts.forEach(v => unreferencedVerts.delete(v));

        if (uniqueVerts.length !== verts.length) {
            degenerateFacets.push(i);
        }

        for (let j = 1; j <= verts.length; j += 1) {
            const v0 = verts[j - 1];
            const v1 = verts[j % verts.length];
            const key = `${v0},${v1}`;
            let incidence = edgeIncidence.get(key);
            if (incidence === undefined) {
                incidence = [];
                edgeIncidence.set(key, incidence);
            }
            incidence.push(i);
        }
        const facetKey = verts
            .sort((a, b) => a - b)
            .map(v => `${v}`)
            .join(',');
        let facets = facetsByVerts.get(facetKey);
        if (facets === undefined) {
            facets = [];
            facetsByVerts.set(facetKey, facets);
        }
        facets.push(i);
    }
    return { unreferencedVerts, edgeIncidence, facetsByVerts, degenerateFacets };
}

export type ToplogyError = DuplicateFacets | DegenerateFacets | NonManifoldEdge | UnreferencedVertices;

export function validateTopology(geometry: THREE.BufferGeometry): ToplogyError[] {
    const { unreferencedVerts, degenerateFacets, edgeIncidence, facetsByVerts } = computeTopologyProps(geometry);

    const errors: ToplogyError[] = [];
    if (unreferencedVerts.size > 0) {
        errors.push({ type: 'unreferenced-vertices' as const, vertices: Array.from(unreferencedVerts) });
    }
    if (degenerateFacets.length > 0) {
        errors.push({ type: 'degenerate-facets' as const, facets: degenerateFacets });
    }

    Array.from(edgeIncidence)
        .filter(([, facets]) => facets.length > 1)
        .map(([key, facets]) => ({
            type: 'non-manifold-edge' as const,
            edge: key.split(',').map(Number) as [number, number],
            facets,
        }))
        .forEach(e => errors.push(e));

    Array.from(facetsByVerts.values())
        .filter(facets => facets.length > 1)
        .map(facets => ({
            type: 'duplicate-facets' as const,
            facets,
        }))
        .forEach(e => errors.push(e));

    return errors;
}
