import type { ModelAppearance } from '../ModelAppearance';
import { useQcToolbarStyles } from './QCToolbar.hooks';
import { Card, IconToggleButton, Text, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import React from 'react';

interface OpacityOptionProps {
    active: boolean;
    mobileGuidedWaxups: boolean;
    onClick: (value: number) => void;
    value: number;
}

const OpacityOption: React.VFC<OpacityOptionProps> = ({ active, mobileGuidedWaxups, onClick, value }) => {
    const styles = useQcToolbarStyles();
    const handleClick = () => {
        onClick(value);
    };
    const opacityPercent = `${value * 100}%`;
    return (
        <div className={mobileGuidedWaxups ? styles.flexRow : styles.flexColumn} aria-label={'Anatomy Layer Opacity'}>
            <IconToggleButton
                active={active}
                tooltip={`Set opacity to ${opacityPercent}`}
                className={styles.iconButton}
                onClick={handleClick}
            >
                <Text variant={'body1'}>{opacityPercent}</Text>
            </IconToggleButton>
        </div>
    );
};

interface AnatomyLayerSelectionBarProps {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
}

const opacityOptions = [0.25, 0.5, 0.75, 1];

export const AnatomyLayerSelectionBar: React.VFC<AnatomyLayerSelectionBarProps> = ({
    appearanceSettings,
    onAppearanceChange,
}) => {
    const styles = useQcToolbarStyles();
    const isScreenLgOrUp = useScreenIsLgOrUp();
    const mobileGuidedWaxups = !isScreenLgOrUp;

    const onChange = React.useCallback(
        (newValue: number) => {
            onAppearanceChange(current => ({
                ...current,
                porcelainOpacity: newValue,
            }));
        },
        [onAppearanceChange],
    );

    const currentValue = appearanceSettings.porcelainOpacity ?? 0.5;

    return (
        <Card raised={true} className={styles.panel} style={{ width: 'auto' }}>
            {opacityOptions.map(value => (
                <OpacityOption
                    key={value}
                    active={currentValue === value}
                    mobileGuidedWaxups={mobileGuidedWaxups}
                    onClick={onChange}
                    value={value}
                />
            ))}
        </Card>
    );
};
