import { useFeatureFlag } from './LaunchDarkly';
import type { LaunchDarklyFlags } from '@orthly/shared-types';
import { FlossPalette, stylesFactory, Button } from '@orthly/ui-primitives';
import { useSnackbar } from 'notistack';
import React from 'react';

const useStyles = stylesFactory(() => ({
    root: {
        '&.notistack-MuiContent': {
            borderLeft: `4px solid ${FlossPalette.ATTENTION_FOREGROUND} !important`,
            backgroundColor: FlossPalette.TAN,
            borderRadius: 5,
            '& svg': {
                color: `${FlossPalette.ATTENTION} !important`,
                fill: `${FlossPalette.ATTENTION} !important`,
            },
        },
    },
}));

const snackbarKey = `outage banner`;

type Audience = 'practice' | 'ops' | 'lab' | 'design';

interface MaintenanceBannerProps {
    audience: Audience;
}

const AudienceFeatureFlagMap: Record<Audience, keyof LaunchDarklyFlags> = {
    practice: 'outage',
    ops: 'maintenanceBannerOps',
    lab: 'maintenanceBannerLab',
    design: 'maintenanceBannerDesign',
};

export const OutageBanner: React.VFC<MaintenanceBannerProps> = ({ audience }) => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const { value: audienceMessage } = useFeatureFlag(AudienceFeatureFlagMap[audience]);
    const { value: generalMessage } = useFeatureFlag('maintenanceBannerGeneral');

    React.useEffect(() => {
        const message = audienceMessage || generalMessage;
        if (message) {
            enqueueSnackbar(message, {
                key: snackbarKey,
                variant: `warning`,
                preventDuplicate: true,
                persist: true,
                anchorOrigin: { horizontal: `center`, vertical: `bottom` },
                className: classes.root,
                action: () => (
                    <Button variant={`primary`} size={`small`} onClick={() => closeSnackbar(snackbarKey)}>
                        Dismiss
                    </Button>
                ),
            });
            return () => {
                closeSnackbar(`outage banner`);
            };
        }
    }, [audienceMessage, generalMessage, enqueueSnackbar, closeSnackbar, classes]);

    return null;
};
