/**
 * NOTE: this file was originally created in the `Scanner` repo.
 * The extended history can be found here:
 * https://github.com/orthly/Scanner/commits/477bb65b8709136bbcfed64770ef66eb52307d8a/packages/fluoride/src/components/common/Gallery
 * Moved for https://meetdandy.atlassian.net/browse/EPDCHAIR-3938
 */
import { GalleryCard, GalleryCardSizeVariant } from './GalleryCard';
import type { GalleryCardOptions, GalleryCardStyleFields } from './GalleryCard';
import { Grid, useScreenIsMobile, useScreenIsSm } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

export interface GalleryProps {
    cards: GalleryCardOptions[];
    cardVariantOverride?: GalleryCardSizeVariant;
    cardStylesOverride?: React.CSSProperties;
    cardVariantStyleOverrides?: GalleryCardStyleFields;
    directionOverride?: 'row' | 'column';
}

/**
 * This helper function isn't designed to handle cases where the card count is 0,
 * because it does not matter what variant we return in that case
 */
const useGetCardSizeVariant = function (cardCount: number): GalleryCardSizeVariant {
    const isMobile = useScreenIsMobile();
    const isSmScreen = useScreenIsSm();
    if (isMobile) {
        return GalleryCardSizeVariant.MobileCard;
    }
    switch (cardCount) {
        case 1:
        case 2:
            return GalleryCardSizeVariant.XLargeCard;
        case 3:
            return GalleryCardSizeVariant.LargeCard;
        case 4:
            return GalleryCardSizeVariant.MediumCard;
        case 5:
        case 6:
            return isSmScreen ? GalleryCardSizeVariant.MediumCard : GalleryCardSizeVariant.SmallCard;
        case 7:
        case 8:
            return isSmScreen ? GalleryCardSizeVariant.MediumCard : GalleryCardSizeVariant.XSmallCard;
        default:
            return isSmScreen ? GalleryCardSizeVariant.MediumCard : GalleryCardSizeVariant.XXSmallCard;
    }
};

/**
 * This Component isn't designed to support more than 12 options,
 * and will break visually if you put in more than that.
 */
export const Gallery: React.VFC<GalleryProps> = ({
    cards,
    cardVariantOverride,
    cardStylesOverride,
    cardVariantStyleOverrides,
    directionOverride = 'row',
}) => {
    const variantDefault = useGetCardSizeVariant(cards.length);
    const variant = cardVariantOverride ? cardVariantOverride : variantDefault;

    return (
        <Grid container direction={directionOverride} spacing={3} data-test={'gallery'}>
            {cards.map((card, idx) => {
                return (
                    <GalleryCard
                        key={idx}
                        sizeVariant={variant}
                        styleOverrides={cardStylesOverride}
                        variantStyleOverrides={cardVariantStyleOverrides}
                        {...card}
                    />
                );
            })}
        </Grid>
    );
};

export interface GallerySelectOption<T> extends Omit<GalleryCardOptions, 'onClick' | 'selected' | 'title'> {
    value: T;
    // Title is an optional key, because there are many cases where the title and value are identical,
    // and if no title is provided, then the value will be used instead.
    title?: string | React.ReactNode;
}

interface GallerySelectProps<T>
    extends Pick<
        GalleryProps,
        'cardVariantOverride' | 'cardStylesOverride' | 'cardVariantStyleOverrides' | 'directionOverride'
    > {
    options: GallerySelectOption<T>[];
    updateSelectedValue: (option: T) => void;
    selectedValue?: T | null;
    recommendedValue?: T;
}

export const GallerySelect: <T extends {}>(props: GallerySelectProps<T>) => React.ReactElement = ({
    options,
    updateSelectedValue,
    selectedValue,
    recommendedValue,
    cardVariantOverride,
    cardStylesOverride,
    cardVariantStyleOverrides,
}) => {
    React.useEffect(() => {
        if (selectedValue === undefined && recommendedValue !== undefined) {
            updateSelectedValue(recommendedValue);
        }
    }, [selectedValue, recommendedValue, updateSelectedValue]);

    const cards = options.map<GalleryCardOptions>(({ value, title, ...card }) => ({
        ...card,
        title: title || value,
        onClick: () => updateSelectedValue(value),
        selected: _.isEqual(selectedValue, value),
        recommended: _.isEqual(recommendedValue, value),
    }));

    return (
        <Gallery
            cards={cards}
            cardVariantOverride={cardVariantOverride}
            cardStylesOverride={cardStylesOverride}
            cardVariantStyleOverrides={cardVariantStyleOverrides}
        />
    );
};

interface GalleryMultiSelectProps<T>
    extends Omit<GallerySelectProps<T>, 'updateSelectedValue' | 'selectedValue' | 'recommendedValue'> {
    updateSelectedValues: (options: T[]) => void;
    selectedValues: T[];
    recommendedValues?: T[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const GalleryMultiSelect: <T extends {}>(props: GalleryMultiSelectProps<T>) => React.ReactElement = ({
    options,
    updateSelectedValues,
    selectedValues,
    recommendedValues,
    cardVariantOverride,
    cardStylesOverride,
    cardVariantStyleOverrides,
    directionOverride,
}) => {
    React.useEffect(() => {
        if (selectedValues.length === 0 && recommendedValues?.length) {
            updateSelectedValues(recommendedValues);
        }
    }, [selectedValues, recommendedValues, updateSelectedValues]);

    const cards = options.map<GalleryCardOptions>(({ value, title, ...card }) => {
        const selected = selectedValues.includes(value);
        const onClick = () => {
            if (selected) {
                updateSelectedValues(selectedValues.filter(opt => opt !== value));
            } else {
                updateSelectedValues([...selectedValues, value]);
            }
        };

        return {
            ...card,
            title: title || value,
            onClick,
            selected,
            recommended: recommendedValues && recommendedValues.includes(value),
            selectVariant: 'multi',
        };
    });

    return (
        <Gallery
            cards={cards}
            cardVariantStyleOverrides={cardVariantStyleOverrides}
            cardVariantOverride={cardVariantOverride}
            cardStylesOverride={cardStylesOverride}
            directionOverride={directionOverride}
        />
    );
};
