import type { Vec } from './Vec';

// Import or implement crypto.getRandomValues.
//
export const getRandomValues: (view: Uint8Array) => Uint8Array = (() => {
    const __crypto = (globalThis as any).crypto as { getRandomValues<T extends ArrayBufferView | null>(array: T): T };
    if (__crypto?.getRandomValues) {
        return buffer => __crypto.getRandomValues(buffer);
    }

    const LCE_SEED = new Float64Array([Date.now() + Math.random()]);
    const lceBuffer = new BigUint64Array(LCE_SEED.buffer, LCE_SEED.byteOffset, 1);
    function lceNext() {
        let lceSeed = lceBuffer[0] as bigint;
        lceSeed *= BigInt('6364136223846793005');
        lceSeed &= BigInt('0xffffffffffffffff');
        lceSeed += BigInt('1442695040888963407');
        lceSeed &= BigInt('0xffffffffffffffff');
        lceBuffer[0] = lceSeed;
        return lceBuffer[0];
    }

    return buffer => {
        for (let n = 0; n < buffer.length; n++) {
            buffer[n] = Number(lceNext() & BigInt(0xff));
        }
        return buffer;
    };
})();

// ArrayBufferView but immutable.
//
export interface BufferView {
    readonly buffer: ArrayBufferLike;
    readonly byteLength: number;
    readonly byteOffset: number;
}

// Trivial types.
//
export type TypedArrayCtor =
    | Int8ArrayConstructor
    | Uint8ArrayConstructor
    | Int16ArrayConstructor
    | Uint16ArrayConstructor
    | Int32ArrayConstructor
    | Uint32ArrayConstructor
    | BigInt64ArrayConstructor
    | BigUint64ArrayConstructor
    | Float32ArrayConstructor
    | Float64ArrayConstructor;

export abstract class TrivialType<T = any> {
    abstract unshift(dst: Vec, src: T): void;
    abstract shift(src: Vec): T;
}
export type TrivialTypeof<T> = T extends TrivialType<infer R> ? R : never;

const kFixed: unique symbol = Symbol('Trivial tag');

export abstract class FixedTrivialType<T = any> extends TrivialType<T> {
    readonly [kFixed] = kFixed;

    abstract readonly Length: number;
    abstract bytes(v: T): Uint8Array;
    abstract from(b: Uint8Array): T;

    unshift(dst: Vec, src: T) {
        dst.prepend(this.bytes(src));
    }
    shift(src: Vec) {
        const val = this.from(src.raw);
        src.erase(0, this.Length);
        return val;
    }
    random() {
        return this.from(getRandomValues(new Uint8Array(this.Length)));
    }
}

export function isFixedType<T>(ty: TrivialType<T>): ty is FixedTrivialType<T> {
    return kFixed in ty;
}

export interface BuiltinType<Ctor extends TypedArrayCtor = TypedArrayCtor>
    extends FixedTrivialType<InstanceType<Ctor>[number]> {
    readonly Zero: InstanceType<Ctor>[number];
    readonly Empty: InstanceType<Ctor>;
    readonly Signed: boolean;
    readonly ArrayType: Ctor;

    random(): InstanceType<Ctor>[number];
    array(n?: number | Iterable<InstanceType<Ctor>[number]>): InstanceType<Ctor>;
    view(buffer: ArrayBufferLike | BufferView, byteOffset?: number, length?: number): InstanceType<Ctor>;
    copy(dst: ArrayBufferLike | BufferView, src: ArrayBufferLike | BufferView, len: number): void;
    (v: number | bigint | string | boolean): InstanceType<Ctor>[number];
}
function createBuiltin<Ctor extends TypedArrayCtor>(
    arrayType: Ctor,
    cast: (b: number | bigint | string | boolean) => InstanceType<Ctor>[0],
) {
    type T = InstanceType<Ctor>[0];

    // Allocate the temporary space.
    //
    const tempSpace = new arrayType(1);
    const tempSpaceBytes = new Uint8Array(tempSpace.buffer, tempSpace.byteOffset, tempSpace.byteLength);

    // Determine signedness.
    //
    tempSpace[0] = cast(-1);
    const signed = (tempSpace[0] as number) < 0;

    // Create the interface.
    //
    return Object.freeze(
        Object.assign(cast, {
            Zero: cast(0),
            Empty: new arrayType(),
            Signed: signed,
            Length: arrayType.BYTES_PER_ELEMENT,
            ArrayType: arrayType,
            [kFixed]: kFixed,
            from(b: Uint8Array) {
                tempSpaceBytes.set(b.subarray(0, arrayType.BYTES_PER_ELEMENT));
                return tempSpace[0];
            },
            bytes(v: T) {
                tempSpace[0] = v;
                return tempSpaceBytes;
            },
            unshift(dst: Vec, src: T) {
                dst.prepend(this.bytes(src));
            },
            shift(src: Vec) {
                const val = this.from(src.raw);
                src.erase(0, arrayType.BYTES_PER_ELEMENT);
                return val;
            },
            random() {
                getRandomValues(tempSpaceBytes);
                return tempSpace[0];
            },
            copy(dst: ArrayBufferLike | BufferView, src: ArrayBufferLike | BufferView, len: number) {
                this.view(dst, 0, len).set(this.view(src, 0, len) as any);
            },
            view(arg: ArrayBufferLike | BufferView, boff: number = 0, len?: number) {
                if (!('byteOffset' in arg)) {
                    return new arrayType(arg, boff, len);
                }

                const buffer = arg.buffer;
                const byteLength = arg.byteLength;
                const byteOffset = arg.byteOffset + boff;
                return new arrayType(buffer, byteOffset, len ?? byteLength / arrayType.BYTES_PER_ELEMENT);
            },
            array: (n: any) => new arrayType(n),
        }),
    ) as BuiltinType<Ctor>;
}

export const I8 = createBuiltin(Int8Array, x => ((Number(x) & 0xff) << 24) >> 24);
export const U8 = createBuiltin(Uint8Array, x => Number(x) & 0xff);
export const I16 = createBuiltin(Int16Array, x => ((Number(x) & 0xffff) << 16) >> 16);
export const U16 = createBuiltin(Uint16Array, x => Number(x) & 0xffff);
export const I32 = createBuiltin(Int32Array, x => Number(x) >> 0);
export const U32 = createBuiltin(Uint32Array, x => Number(x) >>> 0);
export const I64 = createBuiltin(BigInt64Array, x => BigInt(x));
export const U64 = createBuiltin(BigUint64Array, x => BigInt(x));
export const F32 = createBuiltin(Float32Array, x => Number(x));
export const F64 = createBuiltin(Float64Array, x => Number(x));

// Arrays.
//
export const FixedTypedArray = <T extends TypedArrayCtor>(ctor: T, count: number) => {
    return {
        unshift(dst: Vec, src: InstanceType<T>) {
            dst.prepend(src.subarray(0, count));
        },
        shift(src: Vec) {
            const length = count * ctor.BYTES_PER_ELEMENT;
            const res = new ctor(src.buffer, src.byteOffset, length);
            src.erase(0, length);
            return res;
        },
    } as TrivialType<InstanceType<T>>;
};
export const FixedString = (count: number) => {
    return {
        unshift(dst: Vec, src: string) {
            dst.prepend(new TextEncoder().encode(src).subarray(0, count));
        },
        shift(src: Vec) {
            return src.shiftUtf8(count);
        },
    } as TrivialType<string>;
};

// Structures.
//
export interface StructField<T> {
    name?: string;
    unshift(dst: Vec, ctx: any, src?: T): void;
    shift(src: Vec, ctx: any): T;
}
export class Struct {
    static define<Ty>(fields: ReadonlyArray<StructField<any>>) {
        const rfields = [...fields].reverse();
        return class {
            static Fields = fields;

            static unshift(dst: Vec, src: Ty) {
                for (const field of rfields) {
                    field.unshift(dst, src);
                }
            }
            static shift(src: Vec) {
                const res = new this();
                for (const field of fields) {
                    field.shift(src, res);
                }
                return res as Ty;
            }
        };
    }
    static field<T>(name: string, type: TrivialType<T>): StructField<T> {
        return {
            name,
            unshift(dst: Vec, ctx: any, src: T = ctx[name]) {
                type.unshift(dst, src);
            },
            shift(src: Vec, ctx: any) {
                return (ctx[name] = type.shift(src));
            },
        };
    }
    static array<T extends TypedArrayCtor>(
        name: string,
        type: BuiltinType<T>,
        tag: string,
    ): StructField<InstanceType<T>> {
        return {
            name,
            unshift(dst: Vec, ctx: any, src: InstanceType<T> = ctx[name]) {
                dst.prepend(src);
                ctx[tag] = src.length;
            },
            shift(src: Vec, ctx: any): InstanceType<T> {
                const byteLength = ctx[tag] * type.Length;
                const res = new type.ArrayType(src.buffer, src.byteOffset, byteLength);
                src.erase(0, byteLength);
                return (ctx[name] = res) as any;
            },
        };
    }
    static string(name: string, tag: string): StructField<string> {
        return {
            name,
            unshift(dst: Vec, ctx: any, src: string = ctx[name]) {
                const data = new TextEncoder().encode(src);
                dst.prepend(data);
                ctx[tag] = data.length;
            },
            shift(src: Vec, ctx: any) {
                return (ctx[name] = src.shiftUtf8(ctx[tag]));
            },
        };
    }
    static magic32(value: number): StructField<void> {
        return {
            unshift(dst: Vec) {
                dst.unshift(U32, value);
            },
            shift(src: Vec) {
                if (src.shift(U32) !== U32(value)) {
                    throw Error('Magic mismatch');
                }
            },
        };
    }
}
