import { useSetScreen } from '../../../../utils/useSetScreen';
import { CheckoutNewScanConfirmationOverlay } from '../../components/CheckoutConfirmationOverlay/CheckoutNewScanConfirmationOverlay';
import { useCheckoutAction } from '../../state/checkout.actions';
import { useDenturesCheckoutAction } from '../../state/dentures-checkout.actions';
import type { DentureImplantSupport } from '../../state/reducers/dentures-checkout.types';
import { DentureSummaryForOrderDetails } from '../DentureCheckout/DentureSummaryForOrderDetails';
import type { ScanWithExistingOrder } from './ExistingOrder';
import { CheckoutExistingOrderTitle, ItemsTableForExistingOrder } from './ExistingOrder';
import { PracticeScreen } from '@orthly/dentin';
import type {
    LabsGqlDenturesFulfillmentWaxRimFragment,
    LabsGqlExistingPatientOrderMatchFragment,
    LabsGqlDenturesFulfillmentImplantSupportFragment,
    LabsGqlDenturesSingleFulfillmentFragment,
} from '@orthly/graphql-operations';
import {
    useUpdateDenturesFulfillmentFixupNewScanMutation,
    useSingleMailingAddressQuery,
    useGetDoctorPreferencesByIdQuery,
    useUpdateDenturesFulfillmentTryInNewScanMutation,
    useReplaceThreeoxzMutation,
} from '@orthly/graphql-react';
import type {
    LabsGqlUpdateDenturesFulfillmentFixupNewScanCommand,
    LabsGqlUpdateDenturesFulfillmentTryInNewScanCommand,
} from '@orthly/graphql-schema';
import { LabsGqlLabOrderStatus, LabsGqlDenturesArchType } from '@orthly/graphql-schema';
import type { IDentureItem, IOrderItemDentureImplantSupport } from '@orthly/items';
import { DenturesProductionType, LabOrderItemSKUType, OrderItemArch, OrderItemV2Utils } from '@orthly/items';
import { useChangeSubmissionFn, LoadBlocker } from '@orthly/ui';
import { Grid, Button } from '@orthly/ui-primitives';
import React from 'react';

interface DentureContinuationSectionWrapperProps {
    existingOrderMatch: LabsGqlExistingPatientOrderMatchFragment;
}

const DentureContinuationSectionWrapper: React.FC<DentureContinuationSectionWrapperProps> = props => {
    const { existingOrderMatch } = props;
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);

    return (
        <Grid container spacing={2}>
            <CheckoutExistingOrderTitle existingOrderMatch={existingOrderMatch} />
            <Grid container item xs={12} sm={6}>
                <ItemsTableForExistingOrder items={OrderItemV2Utils.parseItems(existingOrderMatch.order.items_v2)} />
            </Grid>
            <Grid container item spacing={2}>
                {props.children}
                <Grid item>
                    <Button variant={`secondary`} onClick={() => dismissScreen()}>
                        Place new order
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const GQL_DENTURE_ARCH_TO_ITEM_ARCH: { [K in LabsGqlDenturesArchType]: OrderItemArch } = {
    [LabsGqlDenturesArchType.Dual]: OrderItemArch.Dual,
    [LabsGqlDenturesArchType.Upper]: OrderItemArch.Upper,
    [LabsGqlDenturesArchType.Lower]: OrderItemArch.Lower,
};

function convertImplantSupportFromFulfillmentGql(
    implantSupport: LabsGqlDenturesFulfillmentImplantSupportFragment | null | undefined,
): DentureImplantSupport | undefined {
    return implantSupport ? { isImplantSupported: true, collarHeightsMm: implantSupport.collar_heights_mm } : undefined;
}

function convertImplantSupportFromItem(
    implantSupport?: IOrderItemDentureImplantSupport | null,
): DentureImplantSupport | undefined {
    return implantSupport?.is_implant_supported
        ? { isImplantSupported: true, collarHeightsMm: implantSupport.collar_heights_mm ?? undefined }
        : undefined;
}

interface DentureWaxRimContinuationSectionContentProps {
    existingOrderMatch: LabsGqlExistingPatientOrderMatchFragment;
    waxRimFulfillment?: LabsGqlDenturesFulfillmentWaxRimFragment;
    waxRimItems: IDentureItem[];
}

const DentureWaxRimContinuationSectionContent: React.VFC<DentureWaxRimContinuationSectionContentProps> = ({
    existingOrderMatch,
    waxRimFulfillment,
    waxRimItems,
}) => {
    const setWaxRimContinuation = useDenturesCheckoutAction('SET_WAX_RIM_CONTINUATION');
    const setPatientDoctorInfo = useCheckoutAction(`SET_PATIENT_AND_DOCTOR_INFO`);
    const addressQuery = useSingleMailingAddressQuery({
        variables: { id: existingOrderMatch.order.mailing_address_id },
    });
    const prefQuery = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: existingOrderMatch.order.doctor_preferences_id },
    });
    const waxRimItem = waxRimItems[0];

    return (
        <DentureContinuationSectionWrapper existingOrderMatch={existingOrderMatch}>
            <Grid item>
                <Button
                    variant={`primary`}
                    onClick={() => {
                        setWaxRimContinuation({
                            arch: waxRimFulfillment
                                ? GQL_DENTURE_ARCH_TO_ITEM_ARCH[waxRimFulfillment.arch]
                                : waxRimItem?.unit.arch ?? OrderItemArch.Dual,
                            isWaxRimContinuation: true,
                            orderId: existingOrderMatch.order.id,
                            implantSupport: waxRimFulfillment
                                ? convertImplantSupportFromFulfillmentGql(waxRimFulfillment.implant_support)
                                : convertImplantSupportFromItem(
                                      waxRimItem?.denture_production_metadata?.implant_support,
                                  ),
                        });
                        setPatientDoctorInfo({
                            gender: existingOrderMatch.order.patient.gender,
                            birthday: existingOrderMatch.order.patient.birthday,
                            doctor: prefQuery.data?.preferences,
                            address: addressQuery.data?.singleAddress,
                        });
                    }}
                >
                    Create final denture from wax rim
                </Button>
            </Grid>
        </DentureContinuationSectionWrapper>
    );
};

interface DentureFulfillmentContinuationSectionContentProps {
    currentFulfillment: LabsGqlDenturesSingleFulfillmentFragment;
    scanWithExisting: ScanWithExistingOrder;
}

const DentureFulfillmentContinuationSectionContent: React.VFC<DentureFulfillmentContinuationSectionContentProps> = ({
    currentFulfillment,
    scanWithExisting,
}) => {
    const { existingOrderMatch } = scanWithExisting;
    const setScreen = useSetScreen();
    const [updateDenturesFixupNewScan] = useUpdateDenturesFulfillmentFixupNewScanMutation();
    const dismissScreen = useCheckoutAction(`DISMISS_EXISTING_ORDER_SCREEN`);

    const { submit: uploadFixupScan, submitting: submittingFixupScan } = useChangeSubmissionFn<any, any>(
        (data: LabsGqlUpdateDenturesFulfillmentFixupNewScanCommand) =>
            updateDenturesFixupNewScan({ variables: { data } }),
        {
            successMessage: res => {
                const result = res.data;
                const firstOrder = result?.updateDenturesFulfillmentFixupNewScan;
                const patientName = firstOrder
                    ? `for ${firstOrder.patient.first_name} ${firstOrder.patient.last_name} `
                    : '';
                return [`Scan uploaded for ${patientName}!`, {}];
            },
            onSuccess: () =>
                setScreen(
                    PracticeScreen.dentures_feedback,
                    `/${existingOrderMatch.order.id}?complete=true&scanId=${scanWithExisting.id}`,
                )(),
        },
    );

    const [updateDenturesTryInNewScan] = useUpdateDenturesFulfillmentTryInNewScanMutation();

    const { submit: uploadTryInScan, submitting: submittingTryInScan } = useChangeSubmissionFn<any, any>(
        (data: LabsGqlUpdateDenturesFulfillmentTryInNewScanCommand) =>
            updateDenturesTryInNewScan({ variables: { data } }),
        {
            successMessage: res => {
                const result = res.data;
                const firstOrder = result?.updateDenturesFulfillmentTryInNewScan;
                const patientName = firstOrder
                    ? `for ${firstOrder.patient.first_name} ${firstOrder.patient.last_name} `
                    : '';
                return [`Scan uploaded for ${patientName}!`, {}];
            },
            onSuccess: () =>
                setScreen(
                    PracticeScreen.dentures_feedback,
                    `/${existingOrderMatch.order.id}?complete=true&scanId=${scanWithExisting.id}`,
                )(),
        },
    );

    // previous fixup is missing a scan if there were required structural changes without a scan uploaded
    const previousFixupMissingScan =
        currentFulfillment?.__typename === 'DenturesFulfillmentFixupDTO'
            ? currentFulfillment.fixup_issues_metadata?.__typename ===
                  'DenturesFulfillmentFixupStructuralIssuesMetadata' &&
              !currentFulfillment.fixup_issues_metadata.scan_export_id
            : false;

    const previousTryInMissingScan =
        currentFulfillment?.__typename === 'DenturesFulfillmentTryInFollowupDTO'
            ? currentFulfillment.followup_issues_metadata?.__typename ===
                  'DenturesFulfillmentFollowupStructuralIssuesMetadata' &&
              !currentFulfillment.followup_issues_metadata.scan_export_id
            : false;

    return (
        <Grid container spacing={2}>
            <CheckoutExistingOrderTitle existingOrderMatch={existingOrderMatch} />
            <Grid container item xs={12} sm={6}>
                {currentFulfillment && <DentureSummaryForOrderDetails fulfillment={currentFulfillment} />}
            </Grid>
            <Grid container item spacing={2}>
                {existingOrderMatch.order.status === LabsGqlLabOrderStatus.Delivered && (
                    <Grid item>
                        <Button
                            variant={`primary`}
                            onClick={() =>
                                setScreen(
                                    PracticeScreen.dentures_feedback,
                                    `/${existingOrderMatch.order.id}?scanId=${scanWithExisting.id}`,
                                )()
                            }
                        >
                            Submit review/fixup for previous denture order
                        </Button>
                    </Grid>
                )}
                {previousTryInMissingScan && (
                    <Grid item>
                        <LoadBlocker blocking={submittingTryInScan}>
                            <Button
                                variant={`primary`}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={() =>
                                    uploadTryInScan({
                                        scan_export_id: scanWithExisting.id,
                                        lab_order_id: existingOrderMatch.order.id,
                                    })
                                }
                            >
                                Upload scan for Try In followup
                            </Button>
                        </LoadBlocker>
                    </Grid>
                )}
                {previousFixupMissingScan && (
                    <Grid item>
                        <LoadBlocker blocking={submittingFixupScan}>
                            <Button
                                variant={`primary`}
                                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                                onClick={() =>
                                    uploadFixupScan({
                                        scan_export_id: scanWithExisting.id,
                                        lab_order_id: existingOrderMatch.order.id,
                                    })
                                }
                            >
                                Upload scan for previous fixup request
                            </Button>
                        </LoadBlocker>
                    </Grid>
                )}
                <Grid item>
                    <Button variant={`secondary`} onClick={() => dismissScreen()}>
                        Place new order
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface DentureItemContinuationSectionContentProps {
    scanWithExisting: ScanWithExistingOrder;
}

const DentureItemContinuationSectionContent: React.VFC<DentureItemContinuationSectionContentProps> = ({
    scanWithExisting,
}) => {
    const { existingOrderMatch } = scanWithExisting;
    const setScreen = useSetScreen();

    const [newScanSubmitted, setNewScanSubmitted] = React.useState(false);

    const [rawSubmitReplaceScan] = useReplaceThreeoxzMutation();
    const { submit: submitReplaceScan, submitting: submittingReplaceScan } = useChangeSubmissionFn<any, any>(
        rawSubmitReplaceScan,
        {
            successMessage: () => ['New scan submitted!', {}],
            onSuccess: () => setNewScanSubmitted(true),
        },
    );

    const isDelivered = existingOrderMatch.order.status === LabsGqlLabOrderStatus.Delivered;
    const canUploadScan = !isDelivered;

    return (
        <DentureContinuationSectionWrapper existingOrderMatch={existingOrderMatch}>
            <CheckoutNewScanConfirmationOverlay
                open={newScanSubmitted}
                orderId={existingOrderMatch.order.id}
                title={'New scan submitted'}
            />
            <Grid item>
                {canUploadScan && (
                    <Button
                        variant={'primary'}
                        disabled={submittingReplaceScan}
                        onClick={() =>
                            !submittingReplaceScan &&
                            submitReplaceScan({
                                variables: {
                                    data: {
                                        order_id: existingOrderMatch.order.id,
                                        new_3oxz_gcs_path: scanWithExisting.original_upload_url,
                                    },
                                },
                            })
                        }
                    >
                        Upload scan for in-progress order
                    </Button>
                )}
                {existingOrderMatch.order.can_submit_tryin_feedback && (
                    <Button
                        variant={'primary'}
                        onClick={() => setScreen(PracticeScreen.try_in_feedback, `/${existingOrderMatch.order.id}`)()}
                    >
                        Submit try in feedback for previous denture order
                    </Button>
                )}
                {existingOrderMatch.order.can_refabricate && (
                    <Button
                        variant={'primary'}
                        onClick={() => setScreen(PracticeScreen.refab_flow, `/${existingOrderMatch.order.id}`)()}
                    >
                        Refabricate last order
                    </Button>
                )}
            </Grid>
        </DentureContinuationSectionWrapper>
    );
};

export const DentureContinuationSectionContent: React.VFC<{ scanWithExisting: ScanWithExistingOrder }> = ({
    scanWithExisting,
}) => {
    const { existingOrderMatch } = scanWithExisting;

    const dentureItems = OrderItemV2Utils.parseItems(existingOrderMatch.order.items_v2).filter(
        OrderItemV2Utils.itemIsType(LabOrderItemSKUType.Denture),
    );
    const waxRimItems = dentureItems.filter(item => item.denture_production_type === DenturesProductionType.WaxRim);
    const currentFulfillment = existingOrderMatch.order.dentures_fulfillment?.current;

    if (currentFulfillment?.__typename === 'DenturesFulfillmentWaxRimDTO' || waxRimItems.length > 0) {
        return (
            <DentureWaxRimContinuationSectionContent
                existingOrderMatch={existingOrderMatch}
                waxRimFulfillment={
                    currentFulfillment?.__typename === 'DenturesFulfillmentWaxRimDTO' ? currentFulfillment : undefined
                }
                waxRimItems={waxRimItems}
            />
        );
    }

    if (currentFulfillment) {
        return (
            <DentureFulfillmentContinuationSectionContent
                currentFulfillment={currentFulfillment}
                scanWithExisting={scanWithExisting}
            />
        );
    }

    return <DentureItemContinuationSectionContent scanWithExisting={scanWithExisting} />;
};
