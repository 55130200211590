import { useSendOneTimeCode } from '../../../graphql/useSendOneTimeCode.graphql';
import { useUserExistsWithMobileNumber } from '../../../graphql/useUserExistsWithMobileNumber.graphql';
import { CheckYourPhoneCard } from '../../mobile-auth/screens/CheckYourPhoneCard';
import { MobileAuthLoginType, useMobileAuthAction } from '../../mobile-auth/state/MobileAuth.actions';
import { SignUpFormContainer } from '../SignUpFormContainer';
import { SignUpPageContainer } from '../SignUpPageContainer';
import {
    SignUpAccountEmailAddressField,
    SignUpAccountFullNameField,
    SignUpAccountPasswordField,
    SignUpAccountPhoneNumberField,
    SignUpAccountRoleField,
} from './components/SignUpAccountFormComponents';
import { usePreferenceProfiles, useVerifyCode } from './utils/SignUpAccountVerifyPhoneNumberUtils';
import { PracticeScreen } from '@orthly/dentin';
import { useSendMobileAppLinksMutation, useUpdatePracticeSignUpStatusMutation } from '@orthly/graphql-react';
import type { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { LabsGqlPracticeSignUpStatus } from '@orthly/graphql-schema';
import { PhoneNumberUtils } from '@orthly/runtime-utils';
import {
    useSession,
    useStaffMemberLoginProps,
    REDIRECT_QUERY_PARAM,
    decodeRedirectFromSearchParams,
} from '@orthly/session-client';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useMigratePref, VerifyCodeForm } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface SignUpAccountCompleteInformationPageProps {
    role?: LabsGqlStaffRolePractice;
    setRole: (value?: LabsGqlStaffRolePractice) => void;
    phoneNumber: string;
    setPhoneNumber: (value: string) => void;
    password: string;
    setPassword: (value: string) => void;
    onContinueAction: () => void;
}

export const SignUpAccountCompleteInformationPage: React.VFC<SignUpAccountCompleteInformationPageProps> = props => {
    const { role, phoneNumber, password, setRole, setPhoneNumber, setPassword, onContinueAction } = props;
    const session = useSession();
    const [error, setError] = React.useState<string | undefined>(undefined);

    const isSubmittable = password.length > 6;

    const { submit: triggerPhoneQuery } = useUserExistsWithMobileNumber({
        onSuccess: userExists => {
            if (userExists) {
                setError('Phone number already in use!');
            } else {
                onContinueAction();
            }
        },
    });

    const onSubmitAction = () => {
        triggerPhoneQuery(phoneNumber, session?.id);
    };

    return (
        <SignUpPageContainer
            header={'Provide your account information'}
            subheader={'Complete the form below so you can log back into your Dandy Portal'}
        >
            <SignUpFormContainer
                buttonText={'Continue'}
                isSubmittable={isSubmittable}
                onSubmitAction={onSubmitAction}
                flossInputConfig={{ backgroundColor: 'white', bordered: true }}
            >
                <SignUpAccountFullNameField role={role} />
                <SignUpAccountEmailAddressField />
                <SignUpAccountRoleField role={role} setRole={setRole} />
                <SignUpAccountPhoneNumberField
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                    error={error}
                    setError={setError}
                />
                <SignUpAccountPasswordField password={password} setPassword={setPassword} />
            </SignUpFormContainer>
        </SignUpPageContainer>
    );
};

interface SignUpAccountCompletionPageProps {
    role?: LabsGqlStaffRolePractice;
    phoneNumber: string;
    code: string;
    setCode: (value: string) => void;
    onSendNewCodeAction: () => void;
    onBackAction: () => void;
}

const SignUpAccountCompletionPage: React.VFC<SignUpAccountCompletionPageProps> = props => {
    const { role, phoneNumber, code, setCode, onSendNewCodeAction, onBackAction } = props;
    const { onSelectToken, cleanError } = useStaffMemberLoginProps();
    const history = useHistory();
    const session = useSession();

    // There will always be one admin profile since this is the first time the user is logging in.
    const profiles = usePreferenceProfiles(session);
    const [submitMtn] = useSendMobileAppLinksMutation();
    const { submit } = useChangeSubmissionFn<any, any>(variables => submitMtn({ variables }), {
        closeOnComplete: true,
    });
    const sendMobileAppLinks = () => {
        void submit({
            data: {
                is_sign_up: true,
                preference_id: profiles[0]?.id ?? '',
                phone_number: phoneNumber,
                ios_link: 'https://apps.apple.com/us/app/dandy/id1620743110',
                android_link: 'https://play.google.com/store/apps/details?id=com.meetdandy.practice.twa&hl=nl&gl=US',
            },
        });
    };

    const [submitPracticeSignInStatusMtn] = useUpdatePracticeSignUpStatusMutation();
    const { submit: submitPracticeSignInStatus } = useChangeSubmissionFn<any, any>(
        variables => submitPracticeSignInStatusMtn({ variables }),
        {
            closeOnComplete: true,
        },
    );
    const updatePracticeSignInStatus = () => {
        void submitPracticeSignInStatus({
            data: { email: session?.user.email ?? '', status: LabsGqlPracticeSignUpStatus.SignUpComplete },
        });
    };

    const completeMigration = () => {
        updatePracticeSignInStatus();
        sendMobileAppLinks();

        const searchParams = new URLSearchParams(history?.location?.search || '');

        if (searchParams.has(REDIRECT_QUERY_PARAM)) {
            const decodedLocation = decodeRedirectFromSearchParams(searchParams);

            history.push(decodedLocation);
        } else {
            history.push(`/${PracticeScreen.inbox}`);
        }
    };

    const { submit: migratePref } = useMigratePref(
        completeMigration,
        onSelectToken,
        phoneNumber,
        session?.user.email ?? '',
        `${session?.user.first_name} ${session?.user.last_name}`,
        role ? [role] : [],
        undefined,
    );

    const [verifyCodeErrorMessage, setVerifyCodeErrorMessage] = React.useState<string>('');
    const onVerifyCodeError = () => {
        setCode('');
        setVerifyCodeErrorMessage(`That code doesn't look right. Please try again.`);
    };
    const {
        error: verificationError,
        submit: verifyCode,
        submitting: verifyingCode,
    } = useVerifyCode(migratePref, profiles, onVerifyCodeError);

    const oneTimeCodeVerificationError = React.useMemo<string | undefined>(() => {
        return cleanError(verificationError) ?? verifyCodeErrorMessage;
    }, [cleanError, verificationError, verifyCodeErrorMessage]);

    return (
        <SignUpPageContainer
            header={'Enter the 6-digit code we’ve texted you'}
            subheader={
                <>
                    A text message was sent to your phone
                    <span style={{ fontWeight: 600 }}>{` ${PhoneNumberUtils.prettyPhoneNumber(phoneNumber)}`}</span>
                    .
                    <br />
                    You should receive this within 30 seconds.
                </>
            }
            sidebar={<CheckYourPhoneCard />}
            onBackAction={onBackAction}
        >
            <SignUpFormContainer formWrapper={false}>
                <VerifyCodeForm
                    onSendNewCodeAction={onSendNewCodeAction}
                    onLoginAction={args => {
                        void verifyCode(args);
                    }}
                    setCode={setCode}
                    loading={verifyingCode}
                    phoneNumber={phoneNumber}
                    code={code}
                    style={{ marginTop: -12 }}
                    error={oneTimeCodeVerificationError}
                />
            </SignUpFormContainer>
        </SignUpPageContainer>
    );
};

export const SignUpAccountRoot: React.VFC = () => {
    const [role, setRole] = React.useState<LabsGqlStaffRolePractice | undefined>(undefined);
    const [phoneNumber, setPhoneNumber] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [showSignUpAccountCompletionPage, setShowSignUpAccountCompletionPage] = React.useState<boolean>(false);
    const [code, setCode] = React.useState('');
    const setLoginType = useMobileAuthAction('SET_LOGIN_TYPE');

    const { submit: submitSend } = useSendOneTimeCode({
        onSuccess: () => {
            // Set the login type to skip the select staff picker
            setLoginType(MobileAuthLoginType.select_staff);
            setCode('');
        },
    });

    const sendCodeAndGoToNextStepAction = () => {
        void submitSend(phoneNumber);
        setShowSignUpAccountCompletionPage(true);
    };

    return showSignUpAccountCompletionPage ? (
        <SignUpAccountCompletionPage
            role={role}
            phoneNumber={phoneNumber}
            code={code}
            setCode={setCode}
            onSendNewCodeAction={() => void submitSend(phoneNumber)}
            onBackAction={() => setShowSignUpAccountCompletionPage(false)}
        />
    ) : (
        <SignUpAccountCompleteInformationPage
            role={role}
            setRole={setRole}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            password={password}
            setPassword={setPassword}
            onContinueAction={sendCodeAndGoToNextStepAction}
        />
    );
};
