import { getInsertionOrientationFromAxis } from './Conversion.utils';
import * as THREE from 'three';

interface CameraControlsRef {
    current: {
        object: THREE.Camera;
    } | null;
}

/**
 * Gets the insertion direction aligned with the camera view
 * @param controlsRef Reference to camera controls
 * @param targetMesh Mesh in whose local frame the insertion direction should be expressed
 * @returns The insertion direction orientation
 */
export function getInsertionDirectionFromCameraView(
    controlsRef: CameraControlsRef | undefined,
    targetMesh?: THREE.Mesh,
): THREE.Quaternion {
    const camera = controlsRef?.current?.object;
    if (!camera) {
        throw new Error('Failed to get camera.');
    }

    // Get the view direction in the world frame.
    const viewDirection = new THREE.Vector3(0, 0, -1).applyQuaternion(camera.quaternion);
    if (targetMesh) {
        // Transform the view direction into the local frame, if requested.
        viewDirection.transformDirection(targetMesh.matrixWorld.clone().invert());
    }

    return getInsertionOrientationFromAxis(viewDirection);
}
