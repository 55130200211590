/* eslint-disable max-lines, max-lines-per-function */
import { CanvasScaleSync } from '../misc/CanvasScaleSync';
import { HTMLOverlayAnchor } from '../misc/HTMLOverlayAnchor';
import { HTMLOverlaySync } from '../misc/HTMLOverlaySync';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import { AttributeName } from '@orthly/forceps';
import { createStyles, makeStyles } from '@orthly/ui-primitives';
import type { OrbitControlsProps } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';
import type { Line, LineBasicMaterial, Mesh } from 'three';
import { BufferGeometry, Group, Vector2, Vector3 } from 'three';

const DISTANCE_MARKER_CLICK_TOLERANCE = 8;
const PROJECT_POINT_DISTANCE = 0.25;
const MARKERS_SIZE = 4;

const useStyles = makeStyles(() =>
    createStyles({
        dimLabel: {
            position: 'absolute',
            fontSize: '8pt',
            zIndex: 20,
            pointerEvents: 'none',
            userSelect: 'none',
            padding: '2px',
            backgroundColor: 'rgba(255,255,230, 0.7)',
            margin: '0 0 0 0',
        },
    }),
);

type PointGeometryTuple = [Vector2 | null, BufferGeometry | null];
/**
 * Projects point onto LineString
 *
 * More strictly project given point onto all LineString segments
 * and return one closest to provided point p or null if there is
 * no projected point within searchDistance
 *
 * @param p - point to project
 * @param geometries - array of LineSegments geometries to which we project point
 * @param searchDistance - maximum distance between projection and point p
 */
const projectPoint = function (p: Vector2, geometries: BufferGeometry[], searchDistance: number): PointGeometryTuple {
    let minDistanceSq = searchDistance * searchDistance;

    let result = null;
    let geometry = null;

    geometries.forEach(g => {
        const positions = g.getAttribute(AttributeName.Position);

        // In LineSegments positions encode both points
        // of each line segments, even if end and beginning of next segment
        // match each other.

        // Use (positions.count - 1) so even contract is broken and
        // buffer contains odd number of points, we don't have exception
        for (let i = 0; i < positions.count - 1; i += 2) {
            const oa = new Vector2(positions.getX(i), positions.getY(i));
            const ob = new Vector2(positions.getX(i + 1), positions.getY(i + 1));

            const ab = ob.sub(oa);
            const tangent = ab.clone().normalize();

            const ap = p.clone().sub(oa);

            // Check that projected point lays
            // inside ab line segment
            const l = ap.dot(tangent);
            if (l > 0 && l * l < ab.lengthSq()) {
                // mouse pointer projected onto ab line segment
                const pp = oa.add(tangent.multiplyScalar(l));

                const dSq = pp.distanceToSquared(p);
                if (dSq < minDistanceSq) {
                    minDistanceSq = dSq;
                    result = pp;
                    geometry = g;
                }
            }
        }
    });

    return [result, geometry];
};

type TwoBufferGeometries = [BufferGeometry | null, BufferGeometry | null];

type CrossSectionMeasureToolType = React.FC<{
    active: boolean;
    cameraControlsRef: React.MutableRefObject<OrbitControlsProps | null>;
    lineGeometries: BufferGeometry[];
}>;

/**
 * Measures distance between cross section lines in cross section view
 *
 * Displays HTMLOverlay with measured distance,
 * Points (which you can drag) projected onto cross section lines
 * Line representing measure
 *
 * @component
 */
export const CrossSectionMeasureTool: CrossSectionMeasureToolType = ({ active, lineGeometries, cameraControlsRef }) => {
    const { gl, raycaster } = useThree();
    const cssClasses = useStyles();
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const markARef = React.useRef<Mesh>();
    const markBRef = React.useRef<Mesh>();
    const lineRef = React.useRef<Line<BufferGeometry, LineBasicMaterial>>();

    const matchedGeometriesRef = React.useRef<TwoBufferGeometries>([null, null]);

    const textOverlayAnchorRef = React.useRef(new Group());
    const textOverlayRef = React.useRef<HTMLDivElement>();

    const scaleRef = React.useRef(1.0);

    const measureLineGeometry = React.useRef(
        new BufferGeometry().setFromPoints([new Vector3(0, 0, 0), new Vector3(0, 0, 0)]),
    );

    // This effect registers canvas listeners for this tool
    // Main effect for this tool
    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        if (!active) {
            return;
        }

        const canvas = gl.domElement;
        const geometries = lineGeometries;

        // We register three main mouse event handlers here
        // * down - initialize tool (places first point) or detects that
        //          user want to drag existing point
        // * move - checks if we can place second point if tool is initialized
        //          (we have one of the point or moving earlier created point)
        //          updates distances
        // * up -   just marks tool that we are not dragging with mouse any more
        //
        // On mouse down, we check
        // if pointer is close to one of the lines
        //    we place first point, and make it visible,
        //    and the rest is done in move handler
        // if pointer is close to one of the previously created point
        //    save which point is stays still, and which moves as
        //    `staticEnd` and `dragTarget`
        // if pointer isn't close to anything above
        //    reset tool, hide dimension primitives

        // flag that user clicked down (we check it in move handler)
        let dragging = false;

        // If we are dragging one of the points of previously created dimension
        // we want to be able to mark which end is following mouse and which one
        // stays in place, by default markA stays in place markB is moving
        let staticEnd: Mesh | null;
        let dragTarget: Mesh | null;

        // we use this index, to save LineString geometries
        // on which pointA and pointB was projected
        // Saved as matchedGeometriesRef.current [pointA geometry, pointB geometry]
        // If we are dragging point a, and drag it from one cross section line to another
        // we want to update `pointA geometry` in matchedGeometriesRef
        // This reference to geometries is used to reproject points
        // onto correct lines later when user moves cross section plane
        let dragPrjGeomI = 1;

        // start with all primitives hidden
        hideAllPrimitives();

        const down = (evt: PointerEvent) => {
            // only run callbacks once all the required primitive refs are bound.
            if (
                !markARef.current ||
                !markBRef.current ||
                !textOverlayAnchorRef.current ||
                !textOverlayRef.current ||
                !lineRef.current
            ) {
                return;
            }

            // check for left mouse button
            if (evt.button !== 0) {
                return;
            }

            const p = raycaster.ray.origin;

            // If we already have dimension created earlier
            // both end marks are visible
            // Check if pointer down is close to one of the existing marks
            // and if so, set dragTargetRef to point which we are about to move
            if (markARef.current.visible && markBRef.current.visible) {
                const clickScreenPosition = new Vector3(p.x, p.y, 0.0);

                // Line String and end point marks are in 3d space,
                // reset z coordinate to ensure to have correct distances
                // We did the same for pointer position one line earlier
                const markAPosition = markARef.current.position.clone();
                markAPosition.z = 0.0;
                const markBPosition = markBRef.current.position.clone();
                markBPosition.z = 0.0;

                // We use current screen space scale to have distance in pixels
                const markADistance = markAPosition.distanceTo(clickScreenPosition) / scaleRef.current;
                const markBDistance = markBPosition.distanceTo(clickScreenPosition) / scaleRef.current;

                if (markADistance < DISTANCE_MARKER_CLICK_TOLERANCE) {
                    dragging = true;
                    dragTarget = markARef.current;
                    staticEnd = markBRef.current;
                    dragPrjGeomI = 0;

                    return;
                }
                if (markBDistance < DISTANCE_MARKER_CLICK_TOLERANCE) {
                    dragging = true;
                    dragTarget = markBRef.current;
                    staticEnd = markARef.current;
                    dragPrjGeomI = 1;

                    return;
                }
            }

            // At this point we have checked that user doesn't modify
            // existing dimension.

            // If user clicked close enough to cross section line
            // reset dimension and set markARef location

            const planeZ = geometries[0]?.attributes?.position?.getZ(0) || 0.0;

            const [pp, geometry] = projectPoint(new Vector2(p.x, p.y), geometries, PROJECT_POINT_DISTANCE);
            matchedGeometriesRef.current[0] = geometry || null;

            if (pp) {
                // Reset end point
                markARef.current.position.set(pp.x, pp.y, planeZ);
                markARef.current.visible = true;
                markBRef.current.visible = false;

                // Reset text overlay
                textOverlayAnchorRef.current.position.copy(markARef.current.position);
                textOverlayAnchorRef.current.visible = true;

                textOverlayRef.current.innerText = '';

                // Reset dimension line
                // We are starting a line, so both ends of the line match markA
                lineRef.current.geometry.setFromPoints([
                    markARef.current.position.clone(),
                    markARef.current.position.clone(),
                ]);
                lineRef.current.geometry.attributes.position &&
                    (lineRef.current.geometry.attributes.position.needsUpdate = true);

                dragging = true;
                dragTarget = markBRef.current;
                staticEnd = markARef.current;
                dragPrjGeomI = 1;
                BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Cross Section Measure Tool Used', {
                    $groups: { order: orderId ?? '' },
                });
            }
        };

        const move = () => {
            // only run callbacks once all the required primitive refs are bound.
            if (
                !markARef.current ||
                !markBRef.current ||
                !textOverlayAnchorRef.current ||
                !textOverlayRef.current ||
                !lineRef.current
            ) {
                return;
            }

            // Check that first marker is set and button still pressed
            if (!dragTarget || !staticEnd?.visible || !dragging) {
                return;
            }
            const p = raycaster.ray.origin;

            const planeZ = geometries[0]?.attributes?.position?.getZ(0) || 0.0;

            // draw a line between static point (pointA if we draw a new dimension)
            // and current pointer position
            lineRef.current.visible = true;
            lineRef.current.geometry.setFromPoints([staticEnd.position.clone(), new Vector3(p.x, p.y, planeZ)]);
            lineRef.current.geometry.attributes.position &&
                (lineRef.current.geometry.attributes.position.needsUpdate = true);

            const [pp, geometry] = projectPoint(new Vector2(p.x, p.y), geometries, PROJECT_POINT_DISTANCE);
            matchedGeometriesRef.current[dragPrjGeomI] = geometry || null;

            if (pp) {
                const markerA = markARef.current;
                const markerB = markBRef.current;

                // If pointer position is close to cross section line, snap dragging end to line
                dragTarget.position.set(pp.x, pp.y, planeZ);

                // Update distance label text
                const distance = markerA.position.distanceTo(markerB.position);
                if (distance > 0.01) {
                    dragTarget.visible = true;
                    updateMeasurePrimitives(markerA, markerB, distance);
                }
            } else {
                // If pointer isn't close enough to cross section line
                // hide text and dragging point
                dragTarget.visible = false;
                textOverlayRef.current.style.display = 'none';
            }
        };

        const up = () => {
            if (!dragging) {
                return;
            }

            // reset dragging state
            dragging = false;
            dragTarget = null;
            staticEnd = null;

            // If either end isn't visible, hide all
            if (!markARef.current?.visible || !markBRef.current?.visible) {
                hideAllPrimitives();
            }
        };

        // Register pointerup on document,
        // so it would work correctly if user press pointer
        // inside canvas and release pointer outside of the canvas
        document.addEventListener('pointerup', up);
        canvas.addEventListener('pointerdown', down);
        canvas.addEventListener('pointermove', move);

        return () => {
            document.removeEventListener('pointerup', up);
            canvas.removeEventListener('pointerdown', down);
            canvas.removeEventListener('pointermove', move);

            hideAllPrimitives();
        };
    }, [active, lineGeometries, gl.domElement, raycaster, orderId]);

    // This effect handles measurement reprojection
    // on cross section plane position change
    React.useEffect(() => {
        const geometries = lineGeometries;

        const handleGeometryChange = () => {
            // these are geometries on which pointA and pointB being originaly projected
            // so we snap points to correct lines
            const [lineGeomA, lineGeomB] = matchedGeometriesRef.current;
            const markerA = markARef.current;
            const markerB = markBRef.current;

            // Try to reproject measurement points onto corresponding lines
            // on cross section plane position change
            // (and cross section lines geometries respective change)
            if (lineGeomA && lineGeomB && markerA?.visible && markerB?.visible) {
                const pointA2D = new Vector2(markerA.position.x, markerA.position.y);
                const [newPointA] = projectPoint(pointA2D, [lineGeomA], PROJECT_POINT_DISTANCE);

                const pointB2D = new Vector2(markerB.position.x, markerB.position.y);
                // Do not project pointB if pointA failed, to save unnecessary projectPoint call
                const [newPointB] = newPointA ? projectPoint(pointB2D, [lineGeomB], PROJECT_POINT_DISTANCE) : [null];

                if (newPointA && newPointB) {
                    // Successfully reproject, update position and value

                    markerA.position.set(newPointA.x, newPointA.y, markerA.position.z);
                    markerB.position.set(newPointB.x, newPointB.y, markerB.position.z);

                    const distance = newPointA.distanceTo(newPointB);
                    updateMeasurePrimitives(markerA, markerB, distance);

                    return;
                }

                // Failed to reproject, disable further tries to reproject
                matchedGeometriesRef.current[0] = null;
                matchedGeometriesRef.current[1] = null;
            }

            // We either failed to reproject one of the point in block above
            // or one of the markers isn't visible.
            // That means that the whole measure isn't valid.
            // Hide all the primitives
            hideAllPrimitives();
        };

        geometries.forEach(geometry => {
            geometry.addEventListener('update', handleGeometryChange);
        });

        return () => {
            geometries.forEach(geometry => {
                geometry.removeEventListener('update', handleGeometryChange);
            });
        };
    }, [lineGeometries]);

    // This effect creates HTML div element to display measured distance
    //
    // Create div manually, to keep measure tool self contained
    // otherwise we'd have to provide textOverlayRef for div
    // created outside CrossSectionMeasureTool at CrossSectionView
    // level.
    // Which would make it imposable to create multiple instances
    // of CrossSectionMeasureTool dynamically.
    React.useEffect(() => {
        const div = document.createElement('div');
        div.className = cssClasses.dimLabel;

        gl.domElement.parentNode?.appendChild(div);

        textOverlayRef.current = div;

        return () => {
            gl.domElement.parentNode?.removeChild(div);
        };
    }, [textOverlayAnchorRef, cssClasses, gl.domElement]);

    // Change scale of measure line end markers
    // so they always have approximately
    // the same amount of pixels in diameter
    const handleScaleChange = React.useCallback(scale => {
        if (markARef.current && markBRef.current) {
            markARef.current.scale.setScalar(scale * MARKERS_SIZE);
            markBRef.current.scale.setScalar(scale * MARKERS_SIZE);

            scaleRef.current = scale;
        }
    }, []);

    /**
     * * Updates measure line geometry (make ends match markerA and markerB)
     * * Updates measure text with formatted distance value
     * * Set text overlay anchor position to the middle between markerA and markerB
     */
    function updateMeasurePrimitives(markerA: Mesh, markerB: Mesh, distance: number) {
        const measureLineGeometry = lineRef.current?.geometry;
        const textOverlay = textOverlayRef.current;
        const textOverlayAnchor = textOverlayAnchorRef.current;

        if (measureLineGeometry && textOverlay && textOverlayAnchor) {
            measureLineGeometry.setFromPoints([markerA.position.clone(), markerB.position.clone()]);
            measureLineGeometry.attributes.position && (measureLineGeometry.attributes.position.needsUpdate = true);

            textOverlay.style.display = 'block';
            const formattedDistance = `${distance.toFixed(Math.abs(distance) < 0.1 ? 3 : 2)}mm`;
            textOverlay.innerText = formattedDistance;

            const middlePoint = markerA.position.clone().add(markerB.position).divideScalar(2);
            textOverlayAnchor.position.copy(middlePoint);
            textOverlayAnchor.visible = true;
        }
    }

    function hideAllPrimitives() {
        textOverlayRef.current && (textOverlayRef.current.style.display = 'none');
        textOverlayAnchorRef.current && (textOverlayAnchorRef.current.visible = false);

        lineRef.current && (lineRef.current.visible = false);
        markARef.current && (markARef.current.visible = false);
        markBRef.current && (markBRef.current.visible = false);
    }

    return (
        <group>
            <CanvasScaleSync cameraControlsRef={cameraControlsRef} onScaleChange={handleScaleChange} />
            <HTMLOverlaySync
                cameraControlsRef={cameraControlsRef}
                targetElementRef={textOverlayRef}
                anchorRef={textOverlayAnchorRef}
            />
            <HTMLOverlayAnchor ref={textOverlayAnchorRef} />

            {/* Turn off frustum culling to skip bonding volume recalculation on every geometry change */}
            <lineSegments ref={lineRef} geometry={measureLineGeometry.current} frustumCulled={false}>
                <lineBasicMaterial
                    depthTest={false}
                    depthWrite={false}
                    attach={'material'}
                    color={'#000'}
                    linewidth={1}
                />
            </lineSegments>

            <mesh ref={markARef} visible={false}>
                <sphereBufferGeometry attach={'geometry'} args={[1, 8, 8]} />
                <meshBasicMaterial depthTest={false} depthWrite={false} attach={'material'} color={'#000'} />
            </mesh>

            <mesh ref={markBRef} visible={false}>
                <sphereBufferGeometry attach={'geometry'} args={[1, 8, 8]} />
                <meshBasicMaterial depthTest={false} depthWrite={false} attach={'material'} color={'#000'} />
            </mesh>
        </group>
    );
};
