import { BufferGeometry, Float32BufferAttribute, Vector2, Vector3 } from 'three';

// adopted from
// https://github.com/mrdoob/three.js/blob/r123/src/geometries/TubeBufferGeometry.js
// but modified to accept points, normals, binormals directly
export class DandyTubeBufferGeometry extends BufferGeometry {
    constructor(
        points: Vector3[],
        inputNormals: Vector3[],
        binormals: Vector3[],
        radius: number = 1,
        radialSegments: number = 8,
        closed: boolean = false,
    ) {
        super();
        this.type = 'TubeBufferGeometry';

        this.parameters = {
            points: points,
            radius: radius,
            radialSegments: radialSegments,
            closed: closed,
        };

        const frames = { normals: inputNormals, binormals: binormals };

        // helper variables
        const vertex = new Vector3();
        const normal = new Vector3();
        const uv = new Vector2();
        let P = new Vector3();

        // buffer
        const vertices: number[] = [];
        const normals: number[] = [];
        const uvs: number[] = [];
        const indices: number[] = [];

        // create buffer data

        generateBufferData();

        // build geometry

        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));

        // functions

        function generateBufferData() {
            for (let i = 0; i < points.length; i++) {
                generateSegment(i);
            }

            // if the geometry is not closed, generate the last row of vertices and normals
            // at the regular position on the given path
            //
            // if the geometry is closed, duplicate the first row of vertices and normals (uvs will differ)

            generateSegment(closed === false ? points.length - 1 : 0);

            // uvs are generated in a separate function.
            // this makes it easy compute correct values for closed geometries

            generateUVs();

            // finally create faces

            generateIndices();
        }

        function generateSegment(i: number) {
            // we use getPointAt to sample evenly distributed points from the given path
            const pp = points[i];

            // retrieve corresponding normal and binormal
            const N = frames.normals[i];
            const B = frames.binormals[i];
            if (pp === undefined || N === undefined || B === undefined) {
                return;
            }
            P = pp;

            // generate normals and vertices for the current segment

            for (let j = 0; j <= radialSegments; j++) {
                const v = (j / radialSegments) * Math.PI * 2;

                const sin = Math.sin(v);
                const cos = -Math.cos(v);

                // normal

                normal.x = cos * N.x + sin * B.x;
                normal.y = cos * N.y + sin * B.y;
                normal.z = cos * N.z + sin * B.z;
                normal.normalize();

                normals.push(normal.x, normal.y, normal.z);

                // vertex

                vertex.x = P.x + radius * normal.x;
                vertex.y = P.y + radius * normal.y;
                vertex.z = P.z + radius * normal.z;

                vertices.push(vertex.x, vertex.y, vertex.z);
            }
        }

        function generateIndices() {
            for (let j = 1; j <= points.length; j++) {
                for (let i = 1; i <= radialSegments; i++) {
                    const a = (radialSegments + 1) * (j - 1) + (i - 1);
                    const b = (radialSegments + 1) * j + (i - 1);
                    const c = (radialSegments + 1) * j + i;
                    const d = (radialSegments + 1) * (j - 1) + i;

                    // faces

                    indices.push(a, b, d);
                    indices.push(b, c, d);
                }
            }
        }

        function generateUVs() {
            for (let i = 0; i <= points.length; i++) {
                for (let j = 0; j <= radialSegments; j++) {
                    uv.x = i / points.length;
                    uv.y = j / radialSegments;

                    uvs.push(uv.x, uv.y);
                }
            }
        }
    }

    parameters: {
        points: Vector3[];
        radius: number;
        radialSegments: number;
        closed: boolean;
    };
}
