import { ClearAlignersUpsellConfig } from './InboxUpsellClearAligners';
import type { InboxUpsellItemConfigProps, InboxUpsellSkuType } from './InboxUpsellComponents';
import { DDPWebinarConfig } from './InboxUpsellDDPWebinar';
import { DandyUtah2023Config } from './InboxUpsellDandyUtah2023';
import { HolidayRushUpsellConfig } from './InboxUpsellHolidayRush';
import { ImplantUpsellConfigA, ImplantUpsellConfigB } from './InboxUpsellImplantConfigs';
import {
    DandyUpsellPointsProgramEnrolled,
    DandyUpsellPointsProgramInvited,
    DandyUpsellTiersProgramEnrolled,
    DandyUpsellTiersProgramInvited,
} from './InboxUpsellLoyaltyProgram';
import { NightGuardUpsellConfig } from './InboxUpsellNightGuard';
import { useFeatureFlag } from '@orthly/veneer';
import _ from 'lodash';

interface UpsellElement {
    SKU_TYPE: InboxUpsellSkuType;
    CONFIG_LIST: readonly InboxUpsellItemConfigProps[];
}

// This list includes all the possible options to display upsells on the inbox
// Its order is important as if multiple configurations are allowed (if multiple flags are enabled for the same organization for instance)
// the first one in the list will be displayed.
export const UPSELL_ARRAY: readonly UpsellElement[] = [
    { SKU_TYPE: 'ddp-webinar', CONFIG_LIST: [DDPWebinarConfig] },
    // Created a new SKU Type as we want to "force" users to see the new banner the first time they see it, even if they dismissed it in the past.
    {
        SKU_TYPE: 'loyalty',
        CONFIG_LIST: [
            DandyUpsellTiersProgramInvited,
            DandyUpsellTiersProgramEnrolled,
            DandyUpsellPointsProgramInvited,
            DandyUpsellPointsProgramEnrolled,
        ],
    },
    { SKU_TYPE: 'clear-aligners', CONFIG_LIST: [ClearAlignersUpsellConfig] },
    { SKU_TYPE: 'night-guards', CONFIG_LIST: [NightGuardUpsellConfig] },
    { SKU_TYPE: 'dentures', CONFIG_LIST: [DandyUtah2023Config] },
    { SKU_TYPE: 'implants-v2', CONFIG_LIST: [ImplantUpsellConfigA, ImplantUpsellConfigB] },
    { SKU_TYPE: 'holiday-rush', CONFIG_LIST: [HolidayRushUpsellConfig] },
] as const;

// This helper checks if the feature flag for a specific configuration is enabled
// A feature flag can return a boolean, in which case our manual configuration above does not need a checkFeatureEnabled function.
// If the feature flag returns a string (for instance for a multivariant flag, show_a, show_b, etc.) then we need to use
// the checkFeatureEnabled function to verify which version is currently enabled (eg: checkFeatureEnabled: getFlagVariantValueChecker('show_a'))
export function useIsFeatureEnabled(
    config: Pick<InboxUpsellItemConfigProps, 'checkFeatureEnabled' | 'featureFlagKey'>,
) {
    const checkFeatureEnabled = config.checkFeatureEnabled ?? Boolean;
    const { value: flagValue } = useFeatureFlag(config.featureFlagKey);

    return !_.isUndefined(flagValue) && checkFeatureEnabled(flagValue);
}

// Function used to return the upsell configuration to show on the inbox
export function useGetUpsellConfigToShow(inboxUpsellDismissedSKUs: InboxUpsellSkuType[]) {
    // There are multiple configurations defined in the upsell array but we want to
    // remove all the ones that are gated by a feature flag that is not enabled,
    // and also remove all the ones that the user has already "dismissed", so that in the
    // end we only return 1 upsell config which will be the one to display to the user
    const enabledConfigs = UPSELL_ARRAY.flatMap(UPSELL => {
        return (
            UPSELL.CONFIG_LIST
                // We usually refrain from using hooks in loops/callbacks, however here
                // the UPSELL_ARRAY is a frozen constant and will never change so we're ok with it
                .filter(useIsFeatureEnabled)
                .filter(() => !inboxUpsellDismissedSKUs.includes(UPSELL.SKU_TYPE))
        );
    });

    return enabledConfigs?.[0] ?? null;
}
