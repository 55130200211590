import { extractFacetsToNewGeometry } from '../../Utils3D';
import type { DcmGeometryInjector } from '../DcmFiles';
import { parseRestorativeFacetMark } from '../DcmFiles/DcmFacetMark';
import { getNodeWithTag, getUpper2LowerMatrixFromBuilder } from '../DentalDesignerModellingTree';
import { extractModellingTree } from '../DesignArchive';
import type { ToothElement } from '../DesignCaseFile';
import { DENTURE_TOOTH_TYPES, DesignCaseFileParser, DesignCaseFileUtil } from '../DesignCaseFile';
import type { DcmProcessingIntermediary, DesignProjectAsset, ExistingFiles } from './DesignZipReading.types';
import type { ThreeShapeDesignTransformations } from './DesignZipTypes';
import { getCadTransform, parseDcmsToIntermediary } from './utils';
import { ToothUtils } from '@orthly/items';
import { FileNameUtils } from '@orthly/runtime-utils';
import { Jaw } from '@orthly/shared-types';
import { create } from 'xmlbuilder2';

/**
 * If you do not have the whole modeling tree file extracted and parsed already (or to not want to do so) this is a function to quickly
 * find the upper to lower matrix transform. Orders of magnitude faster than setting up the whole tree.
 * @param ddmtFile The design modeling tree file.
 * @param useRegexMethod If `true` uses a regex method to find the transform instead of XML builder. Much faster, but slightly more brittle.
 * @returns The upper to lower matrix transform.
 */
async function getUpper2LowerMatrixFast(
    ddmtFile: Pick<ExistingFiles, 'ddmtFile'>,
    useRegexMethod: boolean = true,
): Promise<undefined | THREE.Matrix4> {
    if (ddmtFile.ddmtFile === undefined) {
        return undefined;
    }

    const ddmtBuffer = await ddmtFile.ddmtFile.async('nodebuffer');
    const ddmtText = await extractModellingTree(ddmtBuffer as Buffer);
    if (!ddmtText) {
        throw new Error('Failed to extract modelling tree from case archive');
    }

    if (useRegexMethod) {
        // Makes a couple brittle assumptions about what the node name for this XML element is, and
        // that it will have a `name` attribute, and that the name will be `UpperJaw2LowerJaw`, and that
        // it is a node with no body, but it is very fast.
        // I believe these assumptions are already being made (either explicitly or implicitly) already.
        if (ddmtText.includes('UpperJaw2LowerJaw')) {
            const regex = new RegExp(`<Matrix4x4[^>]*name="UpperJaw2LowerJaw"[^>]*>`);
            const match = regex.exec(ddmtText);

            if (match) {
                const fastTree = create(match[0]);
                const matrixFast = getUpper2LowerMatrixFromBuilder(fastTree);
                return matrixFast;
            } else {
                return undefined;
            }
        }
        return undefined;
    }

    // Still a decent bit faster than having to create the whole modeling tree structure, and is less brittle than the above since it parses the whole document,
    // but substantially slower than the above regex solution.
    const document = create(ddmtText);
    const modelingTree = getNodeWithTag(document, 'NSITree');
    const matrix = getUpper2LowerMatrixFromBuilder(modelingTree);

    return matrix;
}

/**
 * Gets tooth cameo assets from the design files. These are the tooth assets with the internals removed.
 * @param designFiles Information required to generate the tooth cameos
 * @param designRootFolderName ? Not Sure, consumed by `parseDcmsToIntermediary` ?
 * @param errorMessages Any error messages encountered will be appended to this list
 * @param upperMBdcm Upper MB Scan DCM, used to potentially access its getAlignToBiteTransformation
 * @param lowerMBdcm Lower MB Scan DCM, used to potentially access its getAlignToBiteTransformation
 * @param useFastUpper2LowerMatrixParsing If `true`, uses a faster regex parsing to get the upper to lower matrix information from the ddmt.
 * @returns The tooth cameo CAD assets, split into upper and lower.
 */
export async function getToothCameos(
    designFiles: Pick<ExistingFiles, 'cadFiles' | 'caseXmlFile' | 'ddmtFile'>,
    designRootFolderName: string,
    errorMessages: string[],
    upperMBdcm?: DcmGeometryInjector,
    lowerMBdcm?: DcmGeometryInjector,
    useFastUpper2LowerMatrixParsing: boolean = true,
): Promise<{ upperCADAssets: DesignProjectAsset[]; lowerCADAssets: DesignProjectAsset[] }> {
    const { cadFiles, caseXmlFile } = designFiles;

    const parsedCase = DesignCaseFileParser.parseDesignCase(await caseXmlFile.async('string'));

    // This is how we would do it if we load the whole modeling tree.
    // const modellingTree = ddmtFile ? await getModellingTree(ddmtFile, parsedCase) : undefined;
    // const upperToLowerMatrix = modellingTree?.getUpper2LowerMatrix();

    // This is quite a bit faster than parsing the whole modeling tree then calling `getUpper2LowerMatrix` on it.
    const upperToLowerMatrix = await getUpper2LowerMatrixFast(designFiles, useFastUpper2LowerMatrixParsing);

    const cadDCMs: DcmProcessingIntermediary[] = await parseDcmsToIntermediary(
        parsedCase,
        designRootFolderName,
        cadFiles,
        errorMessages,
    );

    const designTransforms: ThreeShapeDesignTransformations = {
        upperJawToLowerJaw: upperToLowerMatrix,
        upperAlignToBite: upperMBdcm?.getAlignToBiteTransformation(),
        lowerAlignToBite: lowerMBdcm?.getAlignToBiteTransformation(),
        upperTransformationFromAlignmentCoordinatesGlobal: upperMBdcm?.getTransform(
            'TransformationFromAlignmentCoordinatesGlobal',
        ),
        lowerTransformationFromAlignmentCoordinatesGlobal: lowerMBdcm?.getTransform(
            'TransformationFromAlignmentCoordinatesGlobal',
        ),
    };

    // ** DONE ** Use the cameo
    const upperCADAssets: DesignProjectAsset[] = [];
    const lowerCADAssets: DesignProjectAsset[] = [];

    cadDCMs.forEach((cadObj: DcmProcessingIntermediary) => {
        const toothNumbers = cadObj.modelElement
            ? DesignCaseFileUtil.getTeethByModelID(parsedCase.toothElements, cadObj.modelElement.modelElementID).map(
                  (tE: ToothElement) => tE.toothNumber,
              )
            : [];

        const predominantToothType = cadObj.modelElement
            ? DesignCaseFileUtil.getPredominantToothElementType(cadObj.modelElement, parsedCase.toothElements)
            : undefined;

        const isUpper = toothNumbers.some(unn => ToothUtils.toothIsUpper(unn));

        const baseGeometry = cadObj.dcm.buildGeometry({ applyTextureCoords: false });
        const facetMarks = cadObj.dcm.parseFacetMarks();
        if (!facetMarks) {
            throw new Error('No facet marks present in DCM');
        }
        const cameoBufferGeom = extractFacetsToNewGeometry(baseGeometry, fIdx => {
            const { facetType } = parseRestorativeFacetMark(facetMarks[fIdx] as number);
            return facetType !== undefined && !['intaglio', 'seal-zone'].includes(facetType);
        });

        const isDenture = predominantToothType && DENTURE_TOOTH_TYPES.includes(predominantToothType);
        const contextAppropriateTransform = getCadTransform(
            designTransforms,
            isDenture ? 'DENTURE' : 'FIXED',
            isUpper ? Jaw.UPPER : Jaw.LOWER,
        );

        if (toothNumbers.length > 0 && contextAppropriateTransform) {
            cameoBufferGeom.applyMatrix4(contextAppropriateTransform);
        }

        // TODO: Here I could change the `stub` to give it a `Cameo` subfolder within the CAD subfolder so it doesn't clash with the existing structures.
        //  Potentially also give it a CAD/Cameo `roleType`, but I do not know what that really is.
        const asset = {
            geom: cameoBufferGeom,
            roleType: 'CAD',
            sourceFile: cadObj.fileName,
            injector: cadObj.dcm,
            stub: FileNameUtils.removeFirstDirectory(cadObj.fileName),
        };

        if (isUpper) {
            upperCADAssets.push(asset);
        } else {
            lowerCADAssets.push(asset);
        }
    });

    return { upperCADAssets, lowerCADAssets };
}
