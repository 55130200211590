import { getMarginCentroid } from '@orthly/forceps';
import type { ToothNumber } from '@orthly/items';
import type { MarginLine } from '@orthly/shared-types';
import React from 'react';
import type * as THREE from 'three';

interface ToothPrepData {
    insertionAxis: THREE.Vector3;
    marginCentroid: THREE.Vector3;
}

export type ToothPrepDataMap = Map<ToothNumber, ToothPrepData>;

/**
 * Parses data from the design prep, used for visualization of prep design revisions. For this reason, we do not process
 * the data if there are restoratives present.
 */
export function usePrepDesignData(
    hasRestoratives: boolean,
    insertionAxes: Map<ToothNumber, THREE.Vector3> | undefined,
    margins: MarginLine[],
): ToothPrepDataMap {
    return React.useMemo(() => {
        const prepData: ToothPrepDataMap = new Map();
        if (hasRestoratives || !insertionAxes) {
            return prepData;
        }

        insertionAxes.forEach((insertionAxis, toothNumber) => {
            const margin = margins.find(m => m.tooth === toothNumber);
            if (!margin) {
                return;
            }

            prepData.set(toothNumber, {
                insertionAxis: insertionAxis.clone(),
                marginCentroid: getMarginCentroid(margin),
            });
        });

        return prepData;
    }, [hasRestoratives, insertionAxes, margins]);
}
