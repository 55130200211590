/* eslint-disable */
import { logger } from '../Utils/Logger';
import { AttributeName } from './BufferAttributeConstants';
import type { PlyFile, PlyHeader } from './PLYLoader';
import * as THREE from 'three';

/*
 * This file was copy pasted from packages/dentin/src/components/ModelViewer/PLYLoader.ts
 * TODO: refactor to not reuse, and not duplicate, PLYLoader.ts
 */

const ATTRIBUTE_TYPE_MAP = {
    int8: THREE.Int8BufferAttribute,
    char: THREE.Int8BufferAttribute,
    uint8: THREE.Uint8BufferAttribute,
    uchar: THREE.Uint8BufferAttribute,
    int16: THREE.Int16BufferAttribute,
    short: THREE.Int16BufferAttribute,
    uint16: THREE.Uint16BufferAttribute,
    ushort: THREE.Uint16BufferAttribute,
    int: THREE.Int32BufferAttribute,
    int32: THREE.Int32BufferAttribute,
    uint: THREE.Uint32BufferAttribute,
    uint32: THREE.Uint32BufferAttribute,
    float: THREE.Float32BufferAttribute,
    float32: THREE.Float32BufferAttribute,
    double: THREE.Float64BufferAttribute,
    float64: THREE.Float64BufferAttribute,
};

type StringDatatypes = keyof typeof ATTRIBUTE_TYPE_MAP;

// Types to parse custom attributes out of
type CustomAttribute = {
    name: string;
    type: StringDatatypes;
    colorComponents: boolean;
    components: string[];
    values: number[];
};

type CustomPlyBuffer = {
    indices: number[];
    vertices: number[];
    normals: number[];
    uvs: number[];
    faceVertexUvs: number[];
    colors: number[];
    customAttributes?: { [key: string]: CustomAttribute };
};

const STANDARD_VERTEX_ATTRIBUTE_NAMES = [
    'x',
    'y',
    'z',
    'px',
    'py',
    'pz',
    'posx',
    'posy',
    'posz',
    'nx',
    'ny',
    'nz',
    'normalx',
    'normaly',
    'normalz',
    's',
    'u',
    'texture_u',
    'tx',
    't',
    'v',
    'texture_v',
    'ty',
    'red',
    'diffuse_red',
    'r',
    'diffuse_r',
    'green',
    'diffuse_green',
    'g',
    'diffuse_g',
    'blue',
    'diffuse_blue',
    'b',
    'diffuse_b',
];

// TODO change name
export class CustomPLYLoader {
    manager: THREE.LoadingManager;
    propertyNameMapping: any;

    constructor(manager?: THREE.LoadingManager) {
        this.manager = manager !== undefined ? manager : new THREE.LoadingManager();
    }

    async loadAsync(data: ArrayBuffer, shouldCalculateUVs: boolean): Promise<PlyFile> {
        try {
            return this.parse(data, shouldCalculateUVs);
        } catch (exception: any) {
            if (exception) {
                logger.error(exception);
            }
            throw new Error(`Failed to load scan`);
        }
    }

    setPropertyNameMapping(mapping: any) {
        this.propertyNameMapping = mapping;
    }

    parse(data: ArrayBuffer, shouldCalculateUVs: boolean): PlyFile {
        function parseHeader(data: any): PlyHeader {
            const patternHeader = /ply([\s\S]*)end_header\r?\n/;
            let headerText: string | undefined = '';
            let headerLength = 0;
            const result = patternHeader.exec(data);

            if (result !== null) {
                headerText = result[1];
                headerLength = new Blob([result[0]!]).size;
            }

            const header: PlyHeader = {
                comments: [],
                elements: [],
                headerLength,
                objInfo: '',
            };

            const lines = headerText?.split('\n') ?? [];
            let currentElement: { properties: any[]; name?: string; count?: number } = { properties: [] };
            let lineType, lineValues;

            function make_ply_element_property(propertValues: any[], propertyNameMapping?: any) {
                const property: { name?: string; type?: string; countType?: string; itemType?: string } = {
                    type: propertValues[0],
                };

                if (property.type === 'list') {
                    property.name = propertValues[3];
                    property.countType = propertValues[1];
                    property.itemType = propertValues[2];
                } else {
                    property.name = propertValues[1];
                }

                if (propertyNameMapping && property.name && property.name in propertyNameMapping) {
                    property.name = propertyNameMapping[property.name];
                }

                return property;
            }

            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                line = line?.trim();

                if (!line || line === '') {
                    continue;
                }

                lineValues = line.split(/\s+/);
                lineType = lineValues.shift();
                line = lineValues.join(' ');

                switch (lineType) {
                    case 'format':
                        header.format = lineValues[0];
                        header.version = lineValues[1];
                        break;
                    case 'comment':
                        header.comments.push(line);
                        break;
                    case 'element':
                        if (currentElement !== undefined) {
                            header.elements.push(currentElement);
                        }

                        currentElement = {
                            name: lineValues[0],
                            count: parseInt(lineValues[1] ?? '0'),
                            properties: [] as any[],
                        };
                        break;
                    case 'property':
                        try {
                            currentElement = {
                                ...(currentElement ?? {}),
                                properties: [
                                    ...(currentElement?.properties ?? []),
                                    make_ply_element_property(lineValues, scope.propertyNameMapping),
                                ],
                            };
                        } catch (err: any) {
                            logger.error(err);
                        }
                        break;
                    case 'obj_info':
                        header.objInfo = line;
                        break;
                    default:
                        logger.info('unhandled', { lineType, lineValues });
                }
            }

            if (currentElement !== undefined) {
                header.elements.push(currentElement);
            }

            return header;
        }

        function parseASCIINumber(n: string | undefined, type: string) {
            switch (type) {
                case 'char':
                case 'uchar':
                case 'short':
                case 'ushort':
                case 'int':
                case 'uint':
                case 'int8':
                case 'uint8':
                case 'int16':
                case 'uint16':
                case 'int32':
                case 'uint32':
                    return n ? parseInt(n) : 0;
                case 'float':
                case 'double':
                case 'float32':
                case 'float64':
                    return n ? parseFloat(n) : 0.0;
            }
        }

        function parseASCIIElement(properties: any[], line: string) {
            const values = line.split(/\s+/);
            const element: any = {};

            for (let i = 0; i < properties.length; i++) {
                if (properties[i].type === 'list') {
                    const list = [];
                    const n = parseASCIINumber(values.shift(), properties[i].countType) ?? 0;

                    for (let j = 0; j < n; j++) {
                        list.push(parseASCIINumber(values.shift(), properties[i].itemType));
                    }

                    element[properties[i].name] = list;
                } else {
                    element[properties[i].name] = parseASCIINumber(values.shift(), properties[i].type);
                }
            }

            return element;
        }

        function parseASCII(data: any, header: PlyHeader) {
            // PLY ascii format specification, as per http://en.wikipedia.org/wiki/PLY_(file_format)
            // can add ? optional properties here
            const buffer: CustomPlyBuffer = {
                indices: [],
                vertices: [],
                normals: [],
                uvs: [],
                faceVertexUvs: [],
                colors: [],
            };
            parseCustomAttributes(header, buffer);
            let result;
            const patternBody = /end_header\s([\s\S]*)$/;
            let body: string | undefined = '';
            if ((result = patternBody.exec(data)) !== null) {
                body = result[1];
            }

            const lines = body?.split('\n') ?? [];
            let currentElement = 0;
            let currentElementCount = 0;

            for (let i = 0; i < lines.length; i++) {
                let line = lines[i];
                line = line?.trim();
                if (!line || line === '') {
                    continue;
                }

                if (currentElementCount >= header.elements[currentElement].count) {
                    currentElement++;
                    currentElementCount = 0;
                }

                const element = parseASCIIElement(header.elements[currentElement].properties, line);
                handleElement(buffer, header.elements[currentElement].name, element);
                currentElementCount++;
            }

            return postProcess(buffer);
        }

        function parseCustomAttributes(header: PlyHeader, buffer: CustomPlyBuffer) {
            // Get or create new map for custom attributes in buffer
            const customAttributes: { [x: string]: CustomAttribute } = buffer.customAttributes ?? {};
            buffer.customAttributes = customAttributes;

            const vertexElement = header.elements.find(e => 'vertex' === e.name);
            if (vertexElement) {
                const colorComponentsRgx = /(_r$|red)|(_g$|green)|(_b$|blue)/gi;

                vertexElement.properties.forEach((prop: { name: any; type: any }) => {
                    const pName = prop.name;

                    // All except standard
                    if (!STANDARD_VERTEX_ATTRIBUTE_NAMES.includes(pName)) {
                        // will replace all occurrences because the //g flag is set on colorComponentsRgx
                        const propGroupName = pName.replace(colorComponentsRgx, '');

                        const newAttrib = {
                            name: pName,
                            type: prop.type,
                            values: [],
                            colorComponents: false,
                            components: [],
                        };
                        // put it in if it hasn't been recorded yet
                        if (!customAttributes[propGroupName]) {
                            customAttributes[propGroupName] = newAttrib;
                        }
                        // if it is a colorComponent, add to existing
                        if (colorComponentsRgx.test(pName)) {
                            (customAttributes[propGroupName] as CustomAttribute).colorComponents = true;
                            (customAttributes[propGroupName] as CustomAttribute).components.push(pName);
                        }
                    }
                });

                Object.values(customAttributes)
                    .filter(attr => attr.colorComponents)
                    .forEach(attr => {
                        // const sortedComponents = [null , null, null];
                        const sortedComponents = new Array(3);
                        attr.components.forEach(c => {
                            const match = colorComponentsRgx.exec(c);
                            for (let i = 0; i < 3; i++) {
                                // In regexp groups are numbered starting with 1, group 0 is the whole match
                                if (match && match[i + 1]) {
                                    sortedComponents[i] = c;
                                }
                            }
                        });

                        if (sortedComponents[0] && sortedComponents[1] && sortedComponents[2]) {
                            attr.components = sortedComponents;
                        }
                    });
            }
        }

        function postProcess(buffer: CustomPlyBuffer) {
            const geometry = new THREE.BufferGeometry();
            // mandatory buffer data

            if (buffer.indices.length > 0) {
                geometry.setIndex(buffer.indices);
            }

            geometry.setAttribute(AttributeName.Position, new THREE.Float32BufferAttribute(buffer.vertices, 3));
            // optional buffer data
            if (buffer.normals.length > 0) {
                geometry.setAttribute(AttributeName.Normal, new THREE.Float32BufferAttribute(buffer.normals, 3));
            }

            if (buffer.uvs.length > 0) {
                geometry.setAttribute(AttributeName.TexCoord, new THREE.Float32BufferAttribute(buffer.uvs, 2));
            }

            if (buffer.colors.length > 0) {
                geometry.setAttribute(AttributeName.Color, new THREE.Float32BufferAttribute(buffer.colors, 3));
            }
            // unclear on object.keys.
            if (buffer.customAttributes) {
                // TODO, iterate key,value over map to improve readability if possible
                Object.keys(buffer.customAttributes).forEach(attrName => {
                    const attr = buffer.customAttributes?.[attrName];
                    if (!attr) {
                        return;
                    }
                    // we previously did regex filtering to determine if the attrib was a color
                    if (attr.colorComponents) {
                        geometry.setAttribute(attrName, new THREE.Float32BufferAttribute(attr.values, 3));
                    } else {
                        const bufferClass = ATTRIBUTE_TYPE_MAP[attr.type];
                        if (bufferClass) {
                            geometry.setAttribute(attrName, new bufferClass(attr.values, 1));
                        } else {
                            logger.error('Unsupported vertex attribute type', { type: attr.type });
                        }
                    }
                });
            }

            geometry.computeBoundingSphere();
            if (shouldCalculateUVs) {
                const geometryIndexCount = geometry.getIndex()!.count;
                if (buffer.faceVertexUvs.length >= geometryIndexCount * 2) {
                    const uvCoords = Array(geometry.getAttribute(AttributeName.Position).count * 2);
                    for (let i = 0; i < geometryIndexCount; i += 1) {
                        const textureV = buffer.faceVertexUvs[i * 2];
                        const textureU = buffer.faceVertexUvs[i * 2 + 1];
                        const index = geometry.getIndex()?.array[i] || 0;
                        uvCoords[index * 2] = textureU;
                        uvCoords[index * 2 + 1] = textureV;
                    }
                    geometry.setAttribute(AttributeName.TexCoord, new THREE.Float32BufferAttribute(uvCoords, 2));
                }
            }
            return geometry;
        }

        function handleElement(buffer: any, elementName: string, element: any) {
            if (elementName === 'vertex') {
                buffer.vertices.push(element.x, element.y, element.z);
                if ('nx' in element && 'ny' in element && 'nz' in element) {
                    buffer.normals.push(element.nx, element.ny, element.nz);
                }

                if ('s' in element && 't' in element) {
                    buffer.uvs.push(element.s, element.t);
                }

                if ('red' in element && 'green' in element && 'blue' in element) {
                    buffer.colors.push(element.red / 255.0, element.green / 255.0, element.blue / 255.0);
                }

                Object.keys(buffer.customAttributes).forEach(attrName => {
                    const attr = buffer.customAttributes[attrName];
                    if (attr.colorComponents && attr.components !== undefined) {
                        attr.components.forEach((component: string | number) => {
                            // TODO used IDE infer type from usage
                            attr.values.push(element[component] / 255.0);
                        });
                    } else if (element[attrName] !== undefined) {
                        attr.values.push(element[attrName]);
                    }
                });
            } else if (elementName === 'face') {
                const vertex_indices = element.vertex_indices || element.vertex_index; // issue #9338
                const texcoord = element.texcoord;

                if (vertex_indices.length === 3) {
                    buffer.indices.push(vertex_indices[0], vertex_indices[1], vertex_indices[2]);
                    if (texcoord && texcoord.length === 6) {
                        buffer.faceVertexUvs.push(texcoord[1], texcoord[0]);
                        buffer.faceVertexUvs.push(texcoord[3], texcoord[2]);
                        buffer.faceVertexUvs.push(texcoord[5], texcoord[4]);
                    }
                } else if (vertex_indices.length === 4) {
                    buffer.indices.push(vertex_indices[0], vertex_indices[1], vertex_indices[3]);
                    buffer.indices.push(vertex_indices[1], vertex_indices[2], vertex_indices[3]);
                }
            }
        }

        function binaryRead(dataview: any, at: any, type: string, little_endian: boolean) {
            switch (type) {
                // corespondences for non-specific length types here match rply:
                case 'int8':
                case 'char':
                    return [dataview.getInt8(at), 1];
                case 'uint8':
                case 'uchar':
                    return [dataview.getUint8(at), 1];
                case 'int16':
                case 'short':
                    return [dataview.getInt16(at, little_endian), 2];
                case 'uint16':
                case 'ushort':
                    return [dataview.getUint16(at, little_endian), 2];
                case 'int32':
                case 'int':
                    return [dataview.getInt32(at, little_endian), 4];
                case 'uint32':
                case 'uint':
                    return [dataview.getUint32(at, little_endian), 4];
                case 'float32':
                case 'float':
                    return [dataview.getFloat32(at, little_endian), 4];
                case 'float64':
                case 'double':
                    return [dataview.getFloat64(at, little_endian), 8];
            }
        }

        function binaryReadElement(dataview: any, at: any, properties: any[], little_endian: boolean) {
            const element: any = {};
            let result,
                read = 0;

            for (let i = 0; i < properties.length; i++) {
                if (properties[i].type === 'list') {
                    const list = [];
                    result = binaryRead(dataview, at + read, properties[i].countType, little_endian);
                    if (!result) {
                        continue;
                    }

                    const n = result[0];
                    read += result[1];
                    for (let j = 0; j < n; j++) {
                        result = binaryRead(dataview, at + read, properties[i].itemType, little_endian);

                        if (!result) {
                            continue;
                        }
                        list.push(result[0]);
                        read += result[1];
                    }
                    element[properties[i].name] = list;
                } else {
                    result = binaryRead(dataview, at + read, properties[i].type, little_endian);
                    if (!result) {
                        continue;
                    }

                    element[properties[i].name] = result[0];
                    read += result[1];
                }
            }

            return [element, read];
        }

        function parseBinary(data: ArrayBuffer, header: any) {
            const buffer: CustomPlyBuffer = {
                indices: [],
                vertices: [],
                normals: [],
                uvs: [],
                faceVertexUvs: [],
                colors: [],
            };

            parseCustomAttributes(header, buffer);

            const little_endian = header.format === 'binary_little_endian';
            const body = new DataView(data, header.headerLength);
            let result,
                loc = 0;

            for (let currentElement = 0; currentElement < header.elements.length; currentElement++) {
                for (
                    let currentElementCount = 0;
                    currentElementCount < header.elements[currentElement].count;
                    currentElementCount++
                ) {
                    result = binaryReadElement(body, loc, header.elements[currentElement].properties, little_endian);
                    loc += result[1];
                    const element = result[0];

                    handleElement(buffer, header.elements[currentElement].name, element);
                }
            }

            return postProcess(buffer);
        }

        let geometry;
        let header;
        const scope = this;

        const text = THREE.LoaderUtils.decodeText(new Uint8Array(data));
        header = parseHeader(text);
        geometry = header.format === 'ascii' ? parseASCII(text, header) : parseBinary(data, header);

        return { geometry, header, modelType: 'ply' };
    }
}
