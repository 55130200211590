import * as THREE from 'three';
import { mergeBufferGeometries } from 'three-stdlib';

interface ArrowGeometryParams {
    length: number;
    offset: number;
    width: number;
    headRatio: number;
}

const DEFAULT_ARROW_GEOMETRY_PARAMS: ArrowGeometryParams = {
    length: 5,
    offset: 3,
    width: 1,
    headRatio: 0.4,
};

export class ArrowGeometry extends THREE.BufferGeometry {
    constructor(params: Partial<ArrowGeometryParams> = {}) {
        super();

        const length = params.length ?? DEFAULT_ARROW_GEOMETRY_PARAMS.length;
        const width = params.width ?? DEFAULT_ARROW_GEOMETRY_PARAMS.width;
        const offset = params.offset ?? DEFAULT_ARROW_GEOMETRY_PARAMS.offset;
        const headRatio = params.headRatio ?? DEFAULT_ARROW_GEOMETRY_PARAMS.headRatio;

        const headGeometry = new THREE.ConeBufferGeometry(width, length * headRatio, 12);
        headGeometry.translate(0, offset + length, 0);

        const tailLength = (1 - headRatio) * length;
        const tailWidth = width * 0.5;
        const tailGeometry = new THREE.CylinderBufferGeometry(tailWidth, tailWidth, tailLength, 12);
        tailGeometry.translate(0, offset + tailLength, 0);

        const mergedGeometry = mergeBufferGeometries([headGeometry, tailGeometry]);
        if (mergedGeometry) {
            this.copy(mergedGeometry);
        }

        headGeometry.dispose();
        tailGeometry.dispose();
    }
}
