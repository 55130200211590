import { qcDynamicHeatMapColorForModel, QcHeatmapOptions } from '../ColorRamp';
import type { PayloadModelAppearance } from '../ModelAppearance';
import { QC_TOOLBAR_BUTTON_STYLE } from '../QCToolbar/QCToolbar.hooks';
import {
    AttributeName,
    computeOcclusalDistanceToOpposalModel,
    computeSurfaceDisplacement,
    ComputeVertexNormalsByAngle,
    HeatMapType,
    resetDistanceField,
} from '@orthly/forceps';
import { createStyles, makeStyles } from '@orthly/ui-primitives';
import React from 'react';

export const useNewModelViewerStyles = makeStyles(() =>
    createStyles({
        uiPanelsContainer: {
            position: 'absolute',
            top: '10px',
            left: '15px',
            zIndex: 5,
            '&>div': {
                marginBottom: '20px',
                marginRight: '20px',
            },
        },
        qcToolbarButton: QC_TOOLBAR_BUTTON_STYLE,
        qcToolbarWhite: {
            ...QC_TOOLBAR_BUTTON_STYLE,
            backgroundColor: 'inherit !important',
        },
    }),
);

/**
 * Calculates and stores the surface displacement with respect to another design revision
 * @param restoratives Restorative models in which to store the surface displacement
 * @param referenceRestoratives Restoratives against which to calculate the surface displacement
 */
export function useInitializeSurfaceDisplacement(
    restoratives: PayloadModelAppearance[],
    referenceRestoratives: PayloadModelAppearance[],
) {
    React.useEffect(() => {
        for (const refItem of referenceRestoratives) {
            const item = restoratives.find(item =>
                item.payloadModel.unns?.some(toothNumber => refItem.payloadModel.unns?.includes(toothNumber)),
            );

            if (!item) {
                continue;
            }

            const geometry = item.payloadModel.model.geometry;
            if (geometry.hasAttribute(AttributeName.DisplacementColor)) {
                // This model has already had its surface displacement calculated.
                continue;
            }

            if (!geometry.hasAttribute(AttributeName.Normal)) {
                geometry.computeVertexNormals();
            }

            resetDistanceField(geometry, AttributeName.SurfaceDisplacement);
            computeSurfaceDisplacement(geometry, refItem.payloadModel.model.geometry);

            // Convert the surface displacement statistic to a color for each vertex.
            const heatmapOptions = QcHeatmapOptions[HeatMapType.SurfaceDisplacement];
            const colors = qcDynamicHeatMapColorForModel(geometry, heatmapOptions, heatmapOptions.defaultRange);

            if (colors) {
                geometry.setAttribute(AttributeName.DisplacementColor, colors);
            }
        }
    }, [restoratives, referenceRestoratives]);
}

export function useInitializeOcclusalDistance(
    scan: PayloadModelAppearance | undefined,
    refScans: PayloadModelAppearance[],
) {
    React.useEffect(() => {
        const geometry = scan?.payloadModel.model.geometry;
        if (!geometry || geometry.hasAttribute(AttributeName.OcclusalDistance) || refScans.length === 0) {
            return;
        }

        if (!geometry.hasAttribute(AttributeName.Normal)) {
            ComputeVertexNormalsByAngle(geometry);
        }

        resetDistanceField(geometry, AttributeName.OcclusalDistance);
        refScans.forEach(ref => computeOcclusalDistanceToOpposalModel(geometry, ref.payloadModel.model.geometry));
    }, [scan, refScans]);
}

export function useResetUninitializedDistanceField(scans: PayloadModelAppearance[], attrName: AttributeName) {
    React.useEffect(() => {
        for (const scan of scans) {
            const geometry = scan.payloadModel.model.geometry;
            if (geometry.hasAttribute(attrName)) {
                continue;
            }

            resetDistanceField(geometry, attrName);
        }
    }, [scans, attrName]);
}
