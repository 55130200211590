import { NATIVE_TOKEN, IMPERSONATE_TOKEN } from './constants';
import type { LocationDescriptorObject } from 'history';

export const REDIRECT_QUERY_PARAM = 'redirectUrl';

export function encodeRedirectToSearchParamString(location: LocationDescriptorObject, defaultLandingPage: string) {
    const { pathname = '', search = '' } = location;

    const searchParams = new URLSearchParams(search);

    searchParams.delete(NATIVE_TOKEN);
    searchParams.delete(IMPERSONATE_TOKEN);

    if (search.includes(defaultLandingPage) || pathname.includes(defaultLandingPage)) {
        searchParams.delete(REDIRECT_QUERY_PARAM);
        const searchParamsString = searchParams.toString();

        if (searchParamsString === '') {
            return '';
        }

        return `?${searchParamsString}`;
    }

    const rawURI = `${pathname}${search}`;
    if (rawURI.length < 4) {
        return '';
    }

    searchParams.set(REDIRECT_QUERY_PARAM, rawURI);

    return `?${searchParams.toString()}`;
}

export function decodeRedirectFromSearchParams(searchParams: URLSearchParams) {
    const redirectLocation = searchParams.get(REDIRECT_QUERY_PARAM) || '';

    const [decodedPath, decodedSearchString] = redirectLocation.split('?');

    const decodedSearchParams = new URLSearchParams(decodedSearchString);

    searchParams.forEach((value, key) => {
        if (![REDIRECT_QUERY_PARAM, NATIVE_TOKEN, IMPERSONATE_TOKEN].includes(key)) {
            decodedSearchParams.set(key, value);
        }
    });

    if (decodedSearchParams.toString() === '') {
        return decodedPath || '';
    }

    return `${decodedPath}?${decodedSearchParams.toString()}`;
}
