import { usePracticeInboxData } from '../state/PracticeInboxProvider';
import { createStyles, makeStyles, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        bgRoot: {
            justifyContent: 'flex-end',
            position: 'relative',
            alignContent: 'center',
            flexDirection: 'column',
            paddingTop: 8,
        },
        handsImg: { width: '25%', paddingBottom: 24, maxWidth: 175 },
        celebrationImg: { maxWidth: '50%' },
    }),
);

export const InboxBackgroundImg: React.FC<{ activeOrderCount: number }> = props => {
    const classes = useStyles();
    const { activeOrderCount } = props;
    const { onboardingComplete, totalItemsCount } = usePracticeInboxData();
    const showCelebrationGraphic = !onboardingComplete || activeOrderCount + totalItemsCount === 0;
    const src = showCelebrationGraphic ? `/inbox/onboarding-bg.png` : '/inbox/shred-it-hands.png';
    return (
        <Grid container className={classes.bgRoot}>
            <img
                src={src}
                alt={'Inbox Background'}
                className={showCelebrationGraphic ? classes.celebrationImg : classes.handsImg}
            />
        </Grid>
    );
};
