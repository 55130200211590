import { useCheckoutSelector } from '../../../redux';
import { denturesCheckoutCurrentStep, useDentureCheckoutSelector } from '../state/dentures-checkout.selectors';
import { DentureCheckoutStep } from '../state/reducers/dentures-checkout.types';
import { CheckoutMainScreensContainer } from './CheckoutMainScreensContainer/CheckoutMainScreensContainer';
import { DentureAddSpareDenturesScreen } from './DentureCheckout/DentureAddSpareDenturesScreen';
import { DentureAestheticScreen } from './DentureCheckout/DentureAestheticScreen';
import { DentureCheckoutPatientDoctorStep } from './DentureCheckout/DentureCheckoutPatientDoctorStep';
import { DentureFestooningScreen } from './DentureCheckout/DentureFestooningScreen';
import { DentureNotesScreen } from './DentureCheckout/DentureNotesScreen';
import { InitialDenturePhotoUploadChoiceScreen } from './DentureCheckout/DenturePhotoUploadChoiceScreen';
import { InitialDenturePhotoUploadReviewScreen } from './DentureCheckout/DenturePhotoUploadReviewScreen';
import { InitialDenturePreferencesScreen } from './DentureCheckout/DenturePreferencesScreen';
import { DentureSelectTypeScreen } from './DentureCheckout/DentureSelectTypeScreen';
import { InitialDentureStyleScreen } from './DentureCheckout/DentureStyleScreen';
import type { ScanFilesCheckoutQueryHookResult } from '@orthly/graphql-react';
import React from 'react';

interface DentureCheckoutMainScreensProps {
    scansQuery: ScanFilesCheckoutQueryHookResult;
}

function useDentureCheckoutTitle(): string {
    const dentureStep = useDentureCheckoutSelector(denturesCheckoutCurrentStep);
    const patient_first_name = useCheckoutSelector(state => state.patient_first_name);

    switch (dentureStep) {
        case DentureCheckoutStep.PatientInfoStep:
            return `Let's get your order started`;
        case DentureCheckoutStep.InitialNonWaxRimOrderDentureOptionsStep:
            return 'Choose your preferences for this denture';
        case DentureCheckoutStep.InitialNonWaxRimOrderDentureStyleStep:
            return 'What smile style do you want for this denture?';
        case DentureCheckoutStep.DentureFestooningStep:
            return 'What festooning level are you looking for?';
        case DentureCheckoutStep.DentureAestheticStep:
            return 'Choose your aesthetic & functional preferences for this denture';
        case DentureCheckoutStep.InitialOrderChooseTypeStep:
            return 'Select a denture type';
        case DentureCheckoutStep.OrderNotesStep:
            return 'Additional Notes and Clinical Preferences';
        case DentureCheckoutStep.InitialNonWaxRimOrderPhotosChoiceStep:
        case DentureCheckoutStep.InitialNonWaxRimOrderPhotosReviewStep:
            return `Please upload a few photos of ${patient_first_name}`;
        case DentureCheckoutStep.AddSpareDenturesStep:
            return 'Just one more question!';
        case DentureCheckoutStep.Summary:
            return '';
    }
}

export const DentureCheckoutMainScreens: React.VFC<DentureCheckoutMainScreensProps> = ({ scansQuery }) => {
    const title = useDentureCheckoutTitle();
    return (
        <CheckoutMainScreensContainer
            titleProps={{ title, titleAction: null }}
            screens={
                <>
                    <DentureCheckoutPatientDoctorStep scansQuery={scansQuery} />
                    <DentureSelectTypeScreen />
                    <InitialDenturePreferencesScreen />
                    <InitialDentureStyleScreen />
                    <DentureFestooningScreen />
                    <DentureAestheticScreen />
                    <InitialDenturePhotoUploadChoiceScreen />
                    <InitialDenturePhotoUploadReviewScreen />
                    <DentureNotesScreen />
                    <DentureAddSpareDenturesScreen />
                </>
            }
        />
    );
};
