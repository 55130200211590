import { trackGuidedWaxupButtonClick } from '../../DesignViewerLiteDetachedContainer';
import { useDesignViewerButtonStyles } from '../VisualConstants.util';
import type { ModelAppearance } from '@orthly/dentin';
import { ModelMarginIcon } from '@orthly/ui';
import {
    Button,
    Grid,
    Switch,
    Text,
    useScreenIsLgOrUp,
    useScreenIsMobileOrVerticalTablet,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

interface MarginToggleButtonProps {
    appearance: ModelAppearance;
    onToggleMarginLines: () => void;
}

export const MarginToggleButton: React.VFC<MarginToggleButtonProps> = ({ appearance, onToggleMarginLines }) => {
    const classes = useDesignViewerButtonStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const isScreenLgOrUp = useScreenIsLgOrUp();
    return (
        <Button
            variant={'secondary'}
            size={'small'}
            onClick={() => {
                trackGuidedWaxupButtonClick('Toggle Margin Lines');
                onToggleMarginLines();
            }}
            className={cx(
                classes.buttonBorder,
                isMobile && appearance.showMarginLines && classes.selectedMobileDesignButton,
            )}
            style={isScreenLgOrUp ? {} : { height: '100%' }}
        >
            <Grid container className={classes.buttonsContainer}>
                <ModelMarginIcon />
                {!isScreenLgOrUp ? (
                    <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                        Margin
                    </Text>
                ) : (
                    <>
                        <Switch
                            color={'secondary'}
                            checked={appearance.showMarginLines}
                            key={+appearance.showMarginLines}
                        />
                        Margin
                    </>
                )}
            </Grid>
        </Button>
    );
};
