import { logger } from '../Utils/Logger';
import * as THREE from 'three';

export function fromBarycoord(
    tri: THREE.Triangle,
    a: number,
    b: number,
    c: number,
    target?: THREE.Vector3,
): THREE.Vector3 {
    const tgt = target ?? new THREE.Vector3();
    const s = a + b + c;
    return tgt
        .set(0, 0, 0)
        .addScaledVector(tri.a, a / s)
        .addScaledVector(tri.b, b / s)
        .addScaledVector(tri.c, c / s);
}

export function getIncenter(tri: THREE.Triangle, target?: THREE.Vector3): THREE.Vector3 {
    const a = tri.b.distanceTo(tri.c);
    const b = tri.c.distanceTo(tri.a);
    const c = tri.a.distanceTo(tri.b);
    return fromBarycoord(tri, a, b, c, target);
}

export function getCircumcenter(tri: THREE.Triangle, target?: THREE.Vector3): THREE.Vector3 {
    const a2 = tri.b.distanceToSquared(tri.c);
    const b2 = tri.c.distanceToSquared(tri.a);
    const c2 = tri.a.distanceToSquared(tri.b);
    return fromBarycoord(tri, a2 * (b2 + c2 - a2), b2 * (c2 + a2 - b2), c2 * (a2 + b2 - c2), target);
}

export function intersectsSphere(tri: THREE.Triangle, sphere: THREE.Sphere): boolean {
    const point = new THREE.Vector3();
    tri.closestPointToPoint(sphere.center, point);

    return sphere.containsPoint(point);
}

export function triangleFromBufferAttributes(
    tri: THREE.Triangle,
    index: THREE.BufferAttribute,
    posAttr: THREE.BufferAttribute | THREE.InterleavedBufferAttribute,
    sourceIndex: number,
) {
    tri.a.fromBufferAttribute(posAttr, index.getX(3 * sourceIndex));
    tri.b.fromBufferAttribute(posAttr, index.getY(3 * sourceIndex));
    tri.c.fromBufferAttribute(posAttr, index.getZ(3 * sourceIndex));
}

export function safeTriangleClosestPointToPoint(triangle: THREE.Triangle, point: THREE.Vector3, target: THREE.Vector3) {
    if (triangle.getArea() > 0) {
        triangle.closestPointToPoint(point, target);
        if (isNaN(target.x) || isNaN(target.y) || isNaN(target.z)) {
            logger.info(`NaN in safeTriangleClosestPointToPoint`, { area: triangle.getArea().toExponential() });
        }
        return target;
    }

    const ab2 = triangle.a.distanceToSquared(triangle.b);
    const bc2 = triangle.b.distanceToSquared(triangle.c);
    const ca2 = triangle.c.distanceToSquared(triangle.a);

    let origin: THREE.Vector3 | undefined;
    if (ab2 > bc2) {
        if (ab2 > ca2) {
            target.subVectors(triangle.a, triangle.b);
            origin = triangle.b;
        } else {
            target.subVectors(triangle.c, triangle.a);
            origin = triangle.a;
        }
    } else if (bc2 > ca2) {
        target.subVectors(triangle.b, triangle.c);
        origin = triangle.c;
    } else {
        target.subVectors(triangle.c, triangle.a);
        origin = triangle.a;
    }

    const l2 = target.lengthSq();

    const t = l2 > 0 ? Math.max(0, Math.min((target.dot(point) - target.dot(origin)) / l2, 1)) : 0;

    target.multiplyScalar(t).add(origin);

    return target;
}

export function getVertexIndicesOfFace(faceIndex: number, index: THREE.BufferAttribute): [number, number, number] {
    const offset = 3 * faceIndex;
    return [index.getX(offset), index.getY(offset), index.getZ(offset)];
}
