import * as zip from '../ZipFormat';
import { crc32 } from './Crc32';
import { U32, U8 } from './Util';
import { Vec } from './Vec';

// ZipCrypto implementation.
//
export class ZipCrypto {
    // Initialilzes the keys given the secret.
    //
    readonly key: ReadonlyArray<number>;
    constructor(secretIn: Uint8Array | string) {
        const secret = typeof secretIn === 'string' ? Vec.fromUtf8(secretIn).raw : secretIn;

        const keys = [0x12345678, 0x23456789, 0x34567890];
        secret.forEach(k => ZipCrypto.step(k, keys, true));
        this.key = keys;
    }

    // Applies a single step of encryption or decryption.
    //
    static step(byteIn: number, key: number[], encrypt: boolean) {
        let byte = byteIn;
        const tmp = (key[2] as number) | 2;
        const k = U8(Math.imul(tmp, tmp ^ 1) >>> 8);
        if (!encrypt) {
            byte = U8(k ^ byte);
        }
        key[0] = ~crc32(byte, ~(key[0] as number));
        key[1] = U32((key[1] as number) + (key[0] & 0xff));
        key[1] = U32(Math.imul(key[1], 134775813) + 1);
        key[2] = ~crc32(key[1] >>> 24, ~(key[2] as number));
        if (encrypt) {
            byte = U8(k ^ byte);
        }
        return byte;
    }

    // Creates a seperate context with mutable key state.
    //
    createContext() {
        const ctx = [...this.key];
        return {
            encrypt({ raw: src }: Vec, { raw: dst }: Vec) {
                for (let n = 0; n < src.length; n++) {
                    dst[n] = ZipCrypto.step(src[n] as number, ctx, true);
                }
                return this;
            },
            decrypt({ raw: src }: Vec, { raw: dst }: Vec) {
                for (let n = 0; n < src.length; n++) {
                    dst[n] = ZipCrypto.step(src[n] as number, ctx, false);
                }
                return this;
            },
        };
    }

    // Encrypts the given body with the two validation bytes.
    //
    encrypt(body: Vec, chk1: number, chk2: number = 0) {
        const result = new Vec(12 + body.length);
        const hdr = result.subarray(0, 12);
        hdr.fillRandom();
        hdr.raw[11] = chk1;
        hdr.raw[10] = chk2;
        this.createContext().encrypt(hdr, hdr).encrypt(body, result.subarray(12));
        return result;
    }

    // Decrypts the given digest, returns the resulting body and the two validation bytes computed.
    //
    decrypt(digest: Vec) {
        const result = new Vec(digest.length);
        this.createContext().decrypt(digest, result);
        const hdr = result.subarray(0, 12);
        return {
            body: result.subarray(12),
            chk1: hdr.raw[11] ?? 0,
            chk2: hdr.raw[10] ?? 0,
        };
    }

    // Handles full decryption workflow.
    //
    decryptRecord(data: Vec, crc: number, rec: zip.FileRecord) {
        const { chk1, chk2, body } = this.decrypt(data);
        if (chk1 !== U8(crc >>> 24)) {
            if (!(rec.flags & 8)) {
                throw Error(`Invalid password: ${U8(crc >>> 24)} !== ${chk1}`);
            }

            const [t2, t1] = zip.DosTime.bytes(rec.fileTime);
            if (chk1 !== t1 || chk2 !== t2) {
                throw Error(
                    `Invalid password (#2): ${t1?.toString(16)}/${t2?.toString(16)} !== ${chk1.toString(
                        16,
                    )}/${chk2.toString(16)}`,
                );
            }
        }
        return body;
    }

    // Handles full encryption workflow.
    //
    encryptRecord(data: Vec, crc: number) {
        return this.encrypt(data, U8(crc >>> 24), U8(crc >>> 16));
    }
}
