import { ATTRIBUTE_MAP_INVALID_VALUE, AttributeName } from './BufferAttributeConstants';
import * as THREE from 'three';

// Recompute collisions of a subset of faces of the geometry, for each face in the subset, a new collision line will be computed (if any)
// the face-to-lines maps entry for that face will be updated, and the visualization geometry will be updated from the maps
export function recomputeCollisionsForASubset(
    geometry: THREE.BufferGeometry,
    collisionGeometry: THREE.BufferGeometry,
    subsetFaces: number[],
) {
    const index = geometry.index;
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    const collisionsPositionAttribute = collisionGeometry.getAttribute(AttributeName.Position);

    if (positionAttribute === undefined || !index || collisionsPositionAttribute === undefined) {
        return;
    }
    const faceToLineMapProximal: Map<number, THREE.Vector3[]> = collisionGeometry.userData['faceToLineMapProximal'];
    const faceToLineMapProximalWithCurtains: Map<number, THREE.Vector3[]> =
        collisionGeometry.userData['faceToLineMapProximalWithCurtains'];
    const faceToLineMapOcclusal: Map<number, THREE.Vector3[]> = collisionGeometry.userData['faceToLineMapOcclusal'];

    const proximalAttribute = geometry.getAttribute(AttributeName.ProximalDistance);
    const proximalDistances: ArrayLike<number> = proximalAttribute?.array ?? [];
    const proximalAttributeWithCurtains = geometry.getAttribute(AttributeName.CurtainsDistance);
    const proximalDistancesWithCurtains: ArrayLike<number> = proximalAttributeWithCurtains?.array ?? [];
    const occlusalAttribute = geometry.getAttribute(AttributeName.OcclusalDistance);
    const occlusalDistances: ArrayLike<number> = occlusalAttribute?.array ?? [];

    for (let i = 0, il = subsetFaces.length; i < il; i++) {
        const faceIndex = subsetFaces[i] ?? 0;
        const vA = index.getX(faceIndex * 3 + 0);
        const vB = index.getX(faceIndex * 3 + 1);
        const vC = index.getX(faceIndex * 3 + 2);
        addIsoCurvesFromATriangle(faceIndex, vA, vB, vC, positionAttribute, proximalDistances, faceToLineMapProximal);
        addIsoCurvesFromATriangle(
            faceIndex,
            vA,
            vB,
            vC,
            positionAttribute,
            proximalDistancesWithCurtains,
            faceToLineMapProximalWithCurtains,
        );
        addIsoCurvesFromATriangle(faceIndex, vA, vB, vC, positionAttribute, occlusalDistances, faceToLineMapOcclusal);
    }

    let pointIndex = 0;
    const addLinesFromMapToPositionAttribute = (faceToLine: Map<number, THREE.Vector3[]>) => {
        faceToLine.forEach(iso_line => {
            if (iso_line[0] && iso_line[1]) {
                const v1 = iso_line[0];
                const v2 = iso_line[1];
                collisionsPositionAttribute.setXYZ(pointIndex, v1.x, v1.y, v1.z);
                pointIndex++;
                collisionsPositionAttribute.setXYZ(pointIndex, v2.x, v2.y, v2.z);
                pointIndex++;
                collisionsPositionAttribute.setXYZ(pointIndex, v2.x, v2.y, v2.z);
                pointIndex++;
            }
        });
    };

    addLinesFromMapToPositionAttribute(faceToLineMapProximal);
    const proximalGroupCount = pointIndex;
    addLinesFromMapToPositionAttribute(faceToLineMapProximalWithCurtains);
    const proximalWithCurtainsGroupCount = pointIndex - proximalGroupCount;
    addLinesFromMapToPositionAttribute(faceToLineMapOcclusal);
    const occlusalGroupCount = pointIndex - proximalWithCurtainsGroupCount - proximalGroupCount;

    collisionGeometry.clearGroups();
    collisionGeometry.addGroup(0, proximalGroupCount, 0);
    collisionGeometry.addGroup(proximalGroupCount - 1, proximalWithCurtainsGroupCount, 1);
    collisionGeometry.addGroup(proximalGroupCount + proximalWithCurtainsGroupCount - 1, occlusalGroupCount, 2);

    collisionGeometry.setDrawRange(0, pointIndex);
    collisionsPositionAttribute.needsUpdate = true;
}

// Given an edge of a mesh, this function will extract the iso-curve intersection point
// by interpolating the iso function values along the edge, and get the point where this function is zero.
// http://mesh.brown.edu/3DPGP-2008/notes/3DPGP-20080201.pdf
function getEdgeIsoVertexPoint(
    fA: number,
    fB: number,
    pA: THREE.Vector3,
    pB: THREE.Vector3,
): THREE.Vector3 | undefined {
    if (fA * fB < 0 && fA !== ATTRIBUTE_MAP_INVALID_VALUE && fB !== ATTRIBUTE_MAP_INVALID_VALUE) {
        //there's an intersection
        const lambda = fA / (fA - fB);
        const intersectionPoint = new THREE.Vector3().lerpVectors(pA, pB, lambda);
        return intersectionPoint;
    }
    return undefined;
}

// extract the iso line from a mesh face (triangular face).
function getIsoCurvesFromATriangle(
    vA: number,
    vB: number,
    vC: number,
    positionAttribute: THREE.BufferAttribute | THREE.InterleavedBufferAttribute,
    isoFunctionArray: ArrayLike<number>,
): THREE.Vector3[] {
    const isoLine: THREE.Vector3[] = [];
    const pA = new THREE.Vector3().fromBufferAttribute(positionAttribute, vA);
    const pB = new THREE.Vector3().fromBufferAttribute(positionAttribute, vB);
    const pC = new THREE.Vector3().fromBufferAttribute(positionAttribute, vC);

    const dA = isoFunctionArray[vA] || 0;
    const dB = isoFunctionArray[vB] || 0;
    const dC = isoFunctionArray[vC] || 0;

    const vAB = getEdgeIsoVertexPoint(dA, dB, pA, pB);
    const vAC = getEdgeIsoVertexPoint(dA, dC, pA, pC);
    const vBC = getEdgeIsoVertexPoint(dB, dC, pB, pC);
    if (vAB !== undefined && vAC !== undefined) {
        isoLine.push(vAB, vAC);
    } else if (vAB !== undefined && vBC !== undefined) {
        isoLine.push(vAB, vBC);
    } else if (vAC !== undefined && vBC !== undefined) {
        isoLine.push(vAC, vBC);
    }
    return isoLine;
}

// calls getIsoCurvesFromATriangle to extract iso line from a mesh face, then add this iso-line to a map data-structure
// this data structure stores one iso-line per face
function addIsoCurvesFromATriangle(
    faceIndex: number,
    vA: number,
    vB: number,
    vC: number,
    positionAttribute: THREE.BufferAttribute | THREE.InterleavedBufferAttribute,
    isoFunctionArray: ArrayLike<number>,
    faceToLineMap: Map<number, THREE.Vector3[]>,
) {
    const isoLine = getIsoCurvesFromATriangle(vA, vB, vC, positionAttribute, isoFunctionArray);
    if (isoLine.length > 0) {
        faceToLineMap.set(faceIndex, isoLine);
    } else if (faceToLineMap.has(faceIndex)) {
        // remove this face index, since its no longer correct
        faceToLineMap.delete(faceIndex);
    }
}

// This function will compute all collisions lines of a geometry based on a distance attribute stored in attributes of this geometry
// it will return a map data-structure that stores one collision-line per face (if any)
export function computeAllCollisionLines(
    geometry: THREE.BufferGeometry,
    distance_attribute_name: string,
): Map<number, THREE.Vector3[]> {
    const faceToLineMap: Map<number, THREE.Vector3[]> = new Map<number, THREE.Vector3[]>();

    const index = geometry.index;
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    const distanceAttribute = geometry.getAttribute(distance_attribute_name);
    if (!(index && positionAttribute && distanceAttribute)) {
        return faceToLineMap;
    }

    const distanceArray = distanceAttribute.array;
    const nFaces = index.count / 3;

    for (let i = 0; i < nFaces; ++i) {
        const offset = i * 3;
        const vA = index.getX(offset);
        const vB = index.getX(offset + 1);
        const vC = index.getX(offset + 2);
        addIsoCurvesFromATriangle(i, vA, vB, vC, positionAttribute, distanceArray, faceToLineMap);
    }
    return faceToLineMap;
}

export type FaceToLineMap = Map<number, THREE.Vector3[]>;

export interface CollisionGroup {
    name: string;
    data: FaceToLineMap;
}

// TODO, must validate with restoratio sculpting app
export function collissionGroupsToGeometry(collisionGroups: CollisionGroup[], maxNumPoints?: number) {
    // extrude the extracted iso lines to appear thick in the viewer (follow the same way of the offline collisions)
    const verticesArray: number[] = [];
    const addLinesFromMapToArray = (faceToLine: Map<number, THREE.Vector3[]>) => {
        faceToLine.forEach(iso_line => {
            if (iso_line[0] && iso_line[1]) {
                const v1 = iso_line[0];
                const v2 = iso_line[1];
                // each face / line contributes by 9 numbers (3 vertices) to the position attribute
                verticesArray.push(v1.x, v1.y, v1.z, v2.x, v2.y, v2.z, v2.x, v2.y, v2.z);
            }
        });
    };

    const resultGeometry = new THREE.BufferGeometry();

    let start = 0;
    collisionGroups.forEach((colGroup, idx) => {
        addLinesFromMapToArray(colGroup.data);
        const numVertices = verticesArray.length / 3;
        resultGeometry.addGroup(start, numVertices - start, idx);
        start = numVertices;
        resultGeometry.userData[colGroup.name] = colGroup.data;
    });

    // If user specified to fill the geom for every face, use the value specifie
    // otherwise, use the len of vertices array
    const numPoints = maxNumPoints ? maxNumPoints * 3 : verticesArray.length / 3;
    // this seems expensive for the non real time case, but we only use multiple maps for crowns which are small
    const verticesFloatArray = new Float32Array(numPoints * 3);
    verticesFloatArray.set(verticesArray);
    const newPositionAttribute = new THREE.BufferAttribute(verticesFloatArray, 3);
    resultGeometry.setAttribute(AttributeName.Position, newPositionAttribute);
    resultGeometry.setDrawRange(0, numPoints);

    return resultGeometry;
}
