import { SwitchWithSlider } from './SwitchWithSlider';
import { useTransparency } from './TransparencyControls.hooks';
import { TransparencyFormMobile } from './TransparencyFormMobile';
import type { ModelAppearance } from '@orthly/dentin';
import { Card, Divider, styled } from '@orthly/ui-primitives';
import React from 'react';

const StyleCard = styled(Card)({
    marginTop: 16,
    paddingTop: 8,
    paddingBottom: 8,
});
export const TransparencyToggleMobile: React.VFC<{
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    onFormToggle: () => void;
    canHaveLayers: boolean;
}> = ({ appearanceSettings, onAppearanceChange, onFormToggle, canHaveLayers }) => {
    const {
        onToggleRestorative,
        onToggleAnatomyLayers,
        restorativeOpacity,
        porcelainOpacity,
        applyToAnatomyLayers,
        applyToRestorative,
    } = useTransparency(appearanceSettings, onAppearanceChange);
    const [formOpen, setFormOpen] = React.useState<boolean>(false);

    const handleFormShown = () => {
        setFormOpen(true);
        onFormToggle();
    };

    const handleApplyToRestorative = () => {
        onToggleRestorative();
        handleFormShown();
    };

    React.useEffect(() => {
        if (!canHaveLayers) {
            handleApplyToRestorative();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleApplyToAnatomyLayers = () => {
        onToggleAnatomyLayers();
        handleFormShown();
    };

    const onClose = () => {
        setFormOpen(false);
        onFormToggle();
    };
    return (
        <>
            {formOpen ? (
                <TransparencyFormMobile
                    appearanceSettings={appearanceSettings}
                    onAppearanceChange={onAppearanceChange}
                    onClose={onClose}
                />
            ) : (
                <StyleCard variant={'outlined'}>
                    <SwitchWithSlider
                        checked={applyToRestorative}
                        onClick={handleApplyToRestorative}
                        label={'Apply to all'}
                        value={100 - restorativeOpacity * 100}
                        showSlider={false}
                    />
                    {canHaveLayers && (
                        <>
                            <Divider style={{ marginBottom: '8px' }} />
                            <SwitchWithSlider
                                checked={applyToAnatomyLayers}
                                onClick={handleApplyToAnatomyLayers}
                                label={'Apply to layered porcelain'}
                                hint={'This is the target tooth shape and the porcelain will be laid by hand'}
                                value={100 - porcelainOpacity * 100}
                                showSlider={false}
                            />
                        </>
                    )}
                </StyleCard>
            )}
        </>
    );
};
