import { ArrowGeometry } from './ArrowGeometry';
import type { GroupProps } from '@react-three/fiber';
import React from 'react';

type ArrowMeshType = React.FC<
    {
        length?: number;
        offset?: number;
        width?: number;
        children?: React.ReactNode;
    } & GroupProps
>;

export const ArrowMesh: ArrowMeshType = ({ length, offset, width, children, ...props }) => {
    const arrowGeometry = React.useMemo(() => new ArrowGeometry({ length, offset, width }), [length, offset, width]);

    React.useEffect(() => {
        return () => {
            arrowGeometry.dispose();
        };
    }, [arrowGeometry]);

    return (
        <group {...props}>
            <mesh>
                <primitive object={arrowGeometry} attach={'geometry'} />
                {children}
            </mesh>
        </group>
    );
};
