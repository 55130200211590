import type { MeshBVH } from 'three-mesh-bvh';

export type BvhShapeCaster = Parameters<MeshBVH['shapecast']>[0];
export interface ShapeCaster extends BvhShapeCaster {
    castAny(bvh: MeshBVH): boolean;
    castAll(bvh: MeshBVH): number[];
}

// Leaving room here for a possible FindNearest if needed
export enum CastMode {
    FindAll,
    FindAny,
}
