import ModelViewerBackground from '../../../assets/images/ModelViewerBackground.svg';
import { DesignViewerLite } from '../DesignViewer/WaxupDesignViewerLite';
import type { DesignViewerLiteWrapperProps } from './DesignViewerLiteWrapper';
import type {
    ExternalAppearanceApp,
    MainViewCameraControlsRef,
    MainViewModelRef,
    ModelAppearance,
} from '@orthly/dentin';
import { DesignComparisonMenu } from '@orthly/dentin';
import { stylesFactory, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    modelViewerContainer: {
        display: 'flex',
        background: `url(${ModelViewerBackground}), linear-gradient(#fbfbfb, #ffffff)`,
        overflow: 'hidden',
        position: 'relative',
        minHeight: 300,
        borderRadius: 16,
    },
    designComparisonMenu: {
        position: 'absolute',
        right: 20,
        top: 20,
    },
}));

export interface GuidedWaxupViewerWrapperProps extends DesignViewerLiteWrapperProps {
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    externalAppearanceApp: ExternalAppearanceApp;
    modelRef: MainViewModelRef;
    controlRef: MainViewCameraControlsRef;
    isImmediateDenture?: boolean;
    setPresetAnnotation?: (annotatedImageUrls: string[]) => void;
    camConfig?: {
        zoom?: number;
        position?: THREE.Vector3;
    };
}

export const DesignViewerLiteDetachedWrapper: React.VFC<GuidedWaxupViewerWrapperProps> = ({
    order,
    refetchDesign,
    designFragment,
    previousDesignFragment,
    takeSnapshotRef,
    style,
    appearance,
    setAppearance,
    modelRef,
    controlRef,
    camConfig,
    isImmediateDenture,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const [desiredPreviousDesignFiles, setDesiredPreviousDesignFiles] = React.useState<string[]>([]);

    const hasPastRestoratives = !!appearance.pastRestoratives.CAD.length;

    const [maxMarginDistance, setMaxMarginDistance] = React.useState<number | undefined>(undefined);

    return (
        <div className={classes.modelViewerContainer} style={{ ...style }}>
            <DesignViewerLite
                selectedDesignFragment={designFragment}
                order={order}
                refetch={refetchDesign}
                userRole={'practitioner'}
                fullScreen={true}
                isMobile={isMobile}
                enableNewViewerWithProps={{ appearance, setAppearance, modelRef, controlRef, takeSnapshotRef }}
                previousDesignFragment={previousDesignFragment}
                desiredPreviousDesignFiles={desiredPreviousDesignFiles}
                setDesiredPreviousDesignFiles={setDesiredPreviousDesignFiles}
                setMaxMarginDistance={setMaxMarginDistance}
                hideOverlayTools={true}
                camConfig={camConfig}
                isImmediateDenture={isImmediateDenture}
            />
            {hasPastRestoratives && (
                <div className={classes.designComparisonMenu}>
                    <DesignComparisonMenu
                        appearance={appearance}
                        setAppearance={setAppearance}
                        maxMarginDistanceMm={maxMarginDistance}
                        displayLocation={undefined}
                        setDesiredPreviousDesignFiles={setDesiredPreviousDesignFiles}
                    />
                </div>
            )}
        </div>
    );
};
