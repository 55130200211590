/* eslint-disable max-lines */
import { getPercentile } from '../Math/Percentile';
import { logger } from '../Utils/Logger';
import { AttributeName, ATTRIBUTE_MAP_INVALID_VALUE } from './BufferAttributeConstants';
import { Matrix3 } from './IterativeClosestPoint.utils';
import type { AdjacencyMatrix } from './MeshConnectivityGraph';
import { triangleFromBufferAttributes } from './Triangle.util';
import numeric from 'numeric';
import * as THREE from 'three';
import { BufferAttribute, Vector3 } from 'three';

export function prettyPrintMatrix(mx: THREE.Matrix4, mxName: string | undefined = undefined) {
    mxName && logger.info(`Matrix ${mxName}`);
    const eles = mx.elements.map(ele => ele.toFixed(3));
    logger.info(`${[0, 4, 8, 12].map(ind => eles[ind])}`);
    logger.info(`${[1, 5, 9, 13].map(ind => eles[ind])}`);
    logger.info(`${[2, 6, 10, 14].map(ind => eles[ind])}`);
    logger.info(`${[3, 7, 11, 15].map(ind => eles[ind])}`);
}

export function getFace(faceIndex: number, geo: THREE.BufferGeometry): THREE.Face3 | undefined {
    if (geo.index && geo.index.array) {
        const a = geo.index.array[faceIndex * 3];
        const b = geo.index.array[faceIndex * 3 + 1];
        const c = geo.index.array[faceIndex * 3 + 2];
        if (a !== undefined && b !== undefined && c !== undefined) {
            const face = new THREE.Face3(a, b, c);
            return face;
        }
    }
    return undefined;
}

/**
 * Gets the indices of a face's component vertices
 * @param index The geometry index, which defines the faces
 * @param faceIndex The index of the desired face
 * @returns The vertex indices, if they could be retrieved
 */
export function getFaceVertexIndices(
    index: ArrayLike<number>,
    faceIndex: number,
): [number, number, number] | undefined {
    const offset = faceIndex * 3;
    if (faceIndex < 0 || offset + 2 >= index.length) {
        return undefined;
    }
    return [index[offset] as number, index[offset + 1] as number, index[offset + 2] as number];
}

export function getVertex(vIndex: number, geo: THREE.BufferGeometry, target: THREE.Vector3) {
    const x = geo.attributes.position?.array[vIndex * 3];
    const y = geo.attributes.position?.array[vIndex * 3 + 1];
    const z = geo.attributes.position?.array[vIndex * 3 + 2];
    if (x !== undefined && y !== undefined && z !== undefined) {
        target.set(x, y, z);
    }
}

export function getVertexNormal(vIndex: number, geo: THREE.BufferGeometry, target: THREE.Vector3) {
    const x = geo.attributes.normal?.array[vIndex * 3];
    const y = geo.attributes.normal?.array[vIndex * 3 + 1];
    const z = geo.attributes.normal?.array[vIndex * 3 + 2];
    if (x !== undefined && y !== undefined && z !== undefined) {
        target.set(x, y, z);
    }
}

export function getVertices(indices: number[], geo: THREE.BufferGeometry): THREE.Vector3[] {
    const result: THREE.Vector3[] = [];
    indices.forEach(ind => {
        if (ind && geo) {
            const v = new THREE.Vector3();
            getVertex(ind, geo, v);
            result.push(v);
        }
    });
    return result;
}

export function getVerticesNormals(indices: number[], geo: THREE.BufferGeometry): THREE.Vector3[] {
    const result: THREE.Vector3[] = [];
    indices.forEach(ind => {
        if (ind && geo) {
            const v = new THREE.Vector3();
            getVertexNormal(ind, geo, v);
            result.push(v);
        }
    });
    return result;
}

export function getFaceNormal(geo: THREE.BufferGeometry, faceIndex: number) {
    let result = new THREE.Vector3(0, 0, 0);
    const a = geo.index?.array?.[faceIndex * 3] ?? faceIndex * 3;
    const b = geo.index?.array?.[faceIndex * 3 + 1] ?? faceIndex * 3 + 1;
    const c = geo.index?.array?.[faceIndex * 3 + 2] ?? faceIndex * 3 + 2;
    if (a && b && c) {
        const vA = new THREE.Vector3();
        getVertex(a, geo, vA);
        const vB = new THREE.Vector3();
        getVertex(b, geo, vB);
        const vC = new THREE.Vector3();
        getVertex(c, geo, vC);

        const face = new THREE.Triangle(vA, vB, vC);
        result = face.getNormal(result);
    }
    return result;
}

export function getFaceNearestVertex(geo: THREE.BufferGeometry, faceIndex: number, point: THREE.Vector3): number {
    const face = getFace(faceIndex, geo);
    if (face) {
        const vA = new THREE.Vector3();
        getVertex(face.a, geo, vA);
        const vB = new THREE.Vector3();
        getVertex(face.b, geo, vB);
        const vC = new THREE.Vector3();
        getVertex(face.c, geo, vC);
        const da = vA.distanceToSquared(point);
        const db = vB.distanceToSquared(point);
        const dc = vC.distanceToSquared(point);
        if (da < db && da < dc) {
            return face.a;
        } else if (db < da && db < dc) {
            return face.b;
        } else {
            return face.c;
        }
    }
    return -1;
}

export function getTriangle(geo: THREE.BufferGeometry, face: THREE.Face3): THREE.Triangle {
    const vA = new THREE.Vector3();
    getVertex(face.a, geo, vA);
    const vB = new THREE.Vector3();
    getVertex(face.b, geo, vB);
    const vC = new THREE.Vector3();
    getVertex(face.c, geo, vC);
    return new THREE.Triangle(vA, vB, vC);
}

export function getTriangleByIndex(geom: THREE.BufferGeometry, idx: number, triangle: THREE.Triangle): THREE.Triangle {
    const index = geom.getIndex();
    const posAttr = geom.getAttribute(AttributeName.Position);
    if (index) {
        triangleFromBufferAttributes(triangle, index, posAttr, idx);
    } else {
        triangle.a.fromBufferAttribute(posAttr, 3 * idx);
        triangle.b.fromBufferAttribute(posAttr, 3 * idx + 1);
        triangle.c.fromBufferAttribute(posAttr, 3 * idx + 2);
    }
    return triangle;
}

// Customize the THREE.js compute vertex normals by using angle method
// https://www.bytehazard.com/articles/vertnorm.html
// NB: This matches what 3Shape does better than the BufferGeometry.computeVertexNormals method.
export function ComputeVertexNormalsByAngle(geometry: THREE.BufferGeometry) {
    const index = geometry.index;
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    if (positionAttribute === undefined) {
        return;
    }

    let normalAttribute = geometry.getAttribute(AttributeName.Normal);

    if (normalAttribute === undefined) {
        normalAttribute = new BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);
        geometry.setAttribute(AttributeName.Normal, normalAttribute);
    } else {
        // reset existing normals to zero
        for (let i = 0, il = normalAttribute.count; i < il; i++) {
            normalAttribute.setXYZ(i, 0, 0, 0);
        }
    }

    const pA = new Vector3();
    const pB = new Vector3();
    const pC = new Vector3();
    const nA = new Vector3();
    const nB = new Vector3();
    const nC = new Vector3();
    const cb = new Vector3();
    const ab = new Vector3();
    const ac = new Vector3();
    const vertexNormalA = new Vector3();
    const vertexNormalB = new Vector3();
    const vertexNormalC = new Vector3();

    // indexed elements

    if (index) {
        for (let i = 0, il = index.count; i < il; i += 3) {
            const vA = index.getX(i + 0);
            const vB = index.getX(i + 1);
            const vC = index.getX(i + 2);

            pA.fromBufferAttribute(positionAttribute, vA);
            pB.fromBufferAttribute(positionAttribute, vB);
            pC.fromBufferAttribute(positionAttribute, vC);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            ac.subVectors(pA, pC);

            const faceNormal = cb.clone().cross(ab).normalize();
            cb.normalize();
            ab.normalize();
            ac.normalize();

            nA.fromBufferAttribute(normalAttribute, vA);
            nB.fromBufferAttribute(normalAttribute, vB);
            nC.fromBufferAttribute(normalAttribute, vC);

            vertexNormalA.copy(faceNormal);
            vertexNormalB.copy(faceNormal);
            vertexNormalC.copy(faceNormal);

            // computation similar to Blender API function calculate_normals_poly_and_vert in here:
            // https://developer.blender.org/diffusion/B/browse/master/source/blender/blenkernel/intern/mesh_normals.cc

            const aA = Math.acos(ab.dot(ac));
            vertexNormalA.multiplyScalar(aA);
            const aB = Math.acos(ab.dot(cb));
            vertexNormalB.multiplyScalar(aB);
            const aC = Math.acos(-ac.dot(cb));
            vertexNormalC.multiplyScalar(aC);

            nA.add(vertexNormalA);
            nB.add(vertexNormalB);
            nC.add(vertexNormalC);

            normalAttribute.setXYZ(vA, nA.x, nA.y, nA.z);
            normalAttribute.setXYZ(vB, nB.x, nB.y, nB.z);
            normalAttribute.setXYZ(vC, nC.x, nC.y, nC.z);
        }
    } else {
        // non-indexed elements (unconnected triangle soup)

        for (let i = 0, il = positionAttribute.count; i < il; i += 3) {
            pA.fromBufferAttribute(positionAttribute, i + 0);
            pB.fromBufferAttribute(positionAttribute, i + 1);
            pC.fromBufferAttribute(positionAttribute, i + 2);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            cb.cross(ab);

            normalAttribute.setXYZ(i + 0, cb.x, cb.y, cb.z);
            normalAttribute.setXYZ(i + 1, cb.x, cb.y, cb.z);
            normalAttribute.setXYZ(i + 2, cb.x, cb.y, cb.z);
        }
    }

    geometry.normalizeNormals();

    normalAttribute.needsUpdate = true;
}

// similar to ComputeVertexNormalsByAngle but for a subset of vertices
// EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
// eslint-disable-next-line sonarjs/cognitive-complexity
export function ComputeVertexNormalsByAngleForSubset(
    geometry: THREE.BufferGeometry,
    subsetVertices: number[],
    subsetFaces: number[],
) {
    const index = geometry.index;
    const positionAttribute = geometry.getAttribute(AttributeName.Position);
    if (positionAttribute === undefined) {
        return;
    }

    let normalAttribute = geometry.getAttribute(AttributeName.Normal);

    if (normalAttribute === undefined) {
        normalAttribute = new BufferAttribute(new Float32Array(positionAttribute.count * 3), 3);
        geometry.setAttribute(AttributeName.Normal, normalAttribute);
    } else {
        // reset normals for this region
        for (let i = 0; i < subsetVertices.length; i++) {
            const vi = subsetVertices[i] ?? 0;
            normalAttribute.setXYZ(vi, 0, 0, 0);
        }
    }

    const pA = new Vector3();
    const pB = new Vector3();
    const pC = new Vector3();
    const nA = new Vector3();
    const nB = new Vector3();
    const nC = new Vector3();
    const cb = new Vector3();
    const ab = new Vector3();
    const ac = new Vector3();
    const vertexNormalA = new Vector3();
    const vertexNormalB = new Vector3();
    const vertexNormalC = new Vector3();

    // indexed elements
    if (index) {
        for (let i = 0, il = subsetFaces.length; i < il; i++) {
            const faceIndex = subsetFaces[i] ?? 0;
            const vA = index.getX(faceIndex * 3 + 0);
            const includeA: boolean = subsetVertices.indexOf(vA) !== -1;
            const vB = index.getX(faceIndex * 3 + 1);
            const includeB: boolean = subsetVertices.indexOf(vB) !== -1;
            const vC = index.getX(faceIndex * 3 + 2);
            const includeC: boolean = subsetVertices.indexOf(vC) !== -1;

            pA.fromBufferAttribute(positionAttribute, vA);
            pB.fromBufferAttribute(positionAttribute, vB);
            pC.fromBufferAttribute(positionAttribute, vC);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            ac.subVectors(pA, pC);

            const faceNormal = cb.clone().cross(ab).normalize();
            cb.normalize();
            ab.normalize();
            ac.normalize();

            if (includeA) {
                nA.fromBufferAttribute(normalAttribute, vA);
                vertexNormalA.copy(faceNormal);
                const aA = Math.acos(ab.dot(ac));
                vertexNormalA.multiplyScalar(aA);
                nA.add(vertexNormalA);
                normalAttribute.setXYZ(vA, nA.x, nA.y, nA.z);
            }

            if (includeB) {
                nB.fromBufferAttribute(normalAttribute, vB);
                vertexNormalB.copy(faceNormal);
                const aB = Math.acos(ab.dot(cb));
                vertexNormalB.multiplyScalar(aB);
                nB.add(vertexNormalB);
                normalAttribute.setXYZ(vB, nB.x, nB.y, nB.z);
            }

            if (includeC) {
                nC.fromBufferAttribute(normalAttribute, vC);
                vertexNormalC.copy(faceNormal);
                const aC = Math.acos(-ac.dot(cb));
                vertexNormalC.multiplyScalar(aC);
                nC.add(vertexNormalC);
                normalAttribute.setXYZ(vC, nC.x, nC.y, nC.z);
            }
        }
    } else {
        // non-indexed elements (unconnected triangle soup)

        for (let i = 0, il = positionAttribute.count; i < il; i += 3) {
            pA.fromBufferAttribute(positionAttribute, i + 0);
            pB.fromBufferAttribute(positionAttribute, i + 1);
            pC.fromBufferAttribute(positionAttribute, i + 2);

            cb.subVectors(pC, pB);
            ab.subVectors(pA, pB);
            cb.cross(ab);

            normalAttribute.setXYZ(i + 0, cb.x, cb.y, cb.z);
            normalAttribute.setXYZ(i + 1, cb.x, cb.y, cb.z);
            normalAttribute.setXYZ(i + 2, cb.x, cb.y, cb.z);
        }
    }

    // normalize normals for this region
    const _vector = new THREE.Vector3();
    for (let i = 0; i < subsetVertices.length; i++) {
        const vi = subsetVertices[i] ?? 0;
        _vector.fromBufferAttribute(normalAttribute, vi);
        _vector.normalize();
        normalAttribute.setXYZ(vi, _vector.x, _vector.y, _vector.z);
    }
}

export function getAverageVector(vectors: THREE.Vector3[]) {
    const result = new THREE.Vector3(0, 0, 0);
    for (const v of vectors) {
        result.add(v);
    }
    if (vectors.length > 0) {
        result.multiplyScalar(1 / vectors.length);
    }
    return result;
}

export function getAverageVector2D(vectors: THREE.Vector2[]) {
    const result = new THREE.Vector2(0, 0);
    for (const v of vectors) {
        result.add(v);
    }
    if (vectors.length > 0) {
        result.multiplyScalar(1 / vectors.length);
    }
    return result;
}

export function setVertex(vIndex: number, geo: THREE.BufferGeometry, x: number, y: number, z: number) {
    if (geo.attributes.position) {
        geo.attributes.position?.setXYZ(vIndex, x, y, z);
    }
}
export interface RotationResult {
    updateMatrix: THREE.Matrix4;
    updatedGeometry: THREE.BufferGeometry;
}

/*
 * Matrix4() has a method getMaxScaleOnAxis() but it does not
 * detect scale < 1 as problem.  We could invert the matrix
 * and run it again but instead we have rolled our own method
 * */
export function isMatrixAngleAndScalePreserving(matrix: THREE.Matrix4, tolerance: number = 1e-4): boolean {
    // ensure that matrix is distance and orientation preserving
    const S = new THREE.Vector3().setFromMatrixScale(matrix);
    const c1 = [S.x, S.y, S.z].some(component => Math.abs(component - 1) > tolerance);
    const c2 = Math.abs(matrix.determinant() - 1) > (1 + tolerance) ** 3 - 1;
    return !(c1 || c2);
}

/*
 *
 * tolerance is in radians.  0.0001745329 is .01 degrees
 * */
type NonOrthogonalReasons = 'XY' | 'YZ' | 'ZX';
type NonOrthogonalError = { reason: NonOrthogonalReasons; message: string };
export function testBasesAreOrthogonal(
    X: THREE.Vector3,
    Y: THREE.Vector3,
    Z: THREE.Vector3,
    tolerance: number = 0.0001745,
): { result: boolean; errors: NonOrthogonalError[] } {
    const Xp = X.clone().normalize();
    const Yp = Y.clone().normalize();
    const Zp = Z.clone().normalize();

    // square to save a few calcs on abs value
    const testXY = Xp.dot(Yp) ** 2;
    const testYZ = Yp.dot(Zp) ** 2;
    const testZX = Zp.dot(Xp) ** 2;

    // This makes the units of the tolerance a little hard to reason about
    // X.dot(Y)**2 = cos(angleXY)**2
    // if tolerance is in radians
    // we actually want abs(angleXY - pi/2) < tolerance for perpendicular
    // you cna use some trig relationships and whatnot and
    // maybe convince ourselves that....
    // we want X.dot(y)**2 < cos(pi/2 - tolerance)**2

    const epsilon = Math.cos(Math.PI / 2 - tolerance) ** 2;

    let result: boolean = true;
    const errors: NonOrthogonalError[] = [];
    if (testXY > epsilon) {
        const angleXY = X.angleTo(Y);
        errors.push({ reason: 'XY', message: `Angle between XY is ${(angleXY * 180) / Math.PI} deg` });
        result = false;
    }
    if (testYZ > epsilon) {
        const angleYZ = Y.angleTo(Z);
        errors.push({ reason: 'YZ', message: `Angle between YZ is ${(angleYZ * 180) / Math.PI} deg` });
        result = false;
    }
    if (testZX > epsilon) {
        const angleZX = Z.angleTo(X);
        errors.push({ reason: 'ZX', message: `Angle between ZX is ${(angleZX * 180) / Math.PI} deg` });
        result = false;
    }

    return { result, errors };
}

export function numberArrayApproxEqual(a: number[], b: number[], tolerance: number = 10e-6): boolean {
    if (a.length !== b.length) {
        return false;
    }

    for (let i = 0; i < a.length; i++) {
        const A = a[i];
        const B = b[i];

        if (A === undefined || B === undefined) {
            return false;
        }
        if (Math.abs(A - B) > tolerance) {
            return false;
        }
    }
    return true;
}
export function areVectors3Equal(v1: THREE.Vector3, v2: THREE.Vector3, tolerance: number = 10e-6): boolean {
    if (Math.abs(v1.x - v2.x) > tolerance) {
        return false;
    }
    if (Math.abs(v1.y - v2.y) > tolerance) {
        return false;
    }
    if (Math.abs(v1.z - v2.z) > tolerance) {
        return false;
    }
    return true;
}

export function areMatrix4Equal(m1: THREE.Matrix4, m2: THREE.Matrix4, tolerance: number = 10e-6): boolean {
    return numberArrayApproxEqual(m1.toArray(), m2.toArray(), tolerance);
}

export function areBasisAxesRightHanded(
    X: THREE.Vector3,
    Y: THREE.Vector3,
    Z: THREE.Vector3,
    tolerance: number = 0.0001,
): boolean {
    const Xp = Y.clone().normalize().cross(Z);
    const Yp = Z.clone().normalize().cross(X);
    const Zp = X.clone().normalize().cross(Y);

    return (
        areVectors3Equal(X.clone().normalize(), Xp, tolerance) &&
        areVectors3Equal(Y.clone().normalize(), Yp, tolerance) &&
        areVectors3Equal(Z.clone().normalize(), Zp, tolerance)
    );
}

export function generateRotationMatrixWithNoScale(insertionAxis: THREE.Vector3) {
    const Z = insertionAxis.clone().normalize();
    let finalMx = new THREE.Matrix4().identity();

    let Xprime = new THREE.Vector3(1, 0, 0);
    let Yprime = new THREE.Vector3(0, 1, 0);

    let iteration = 0;
    let noScale = true;
    do {
        noScale = true;
        let X = new THREE.Vector3();
        let Y = new THREE.Vector3();

        if (Math.abs(Z.dot(Xprime)) < 0.9) {
            X = Xprime.clone().sub(Z.clone().multiplyScalar(Xprime.dot(Z)));
            X.normalize();
            Y = Z.clone().cross(X);
            Y.normalize();
        } else {
            Y = Yprime.clone().sub(Z.clone().multiplyScalar(Yprime.dot(Z)));
            Y.normalize();

            X = Y.clone().cross(Z);
            X.normalize();
        }

        const rmx = new THREE.Matrix4().makeBasis(X, Y, Z);

        // center it, and rotate it
        finalMx = rmx.invert();

        if (!isMatrixAngleAndScalePreserving(finalMx)) {
            logger.warn('WARNING: Scale not preserved within tolerance', {
                maxScale: finalMx.getMaxScaleOnAxis(),
                iteration,
            });
            finalMx = new THREE.Matrix4().identity();
            noScale = false;
            // choose another random X' Y'
            Xprime = new THREE.Vector3(Math.random(), Math.random(), Math.random());
            Yprime = new THREE.Vector3(Math.random(), Math.random(), Math.random());
        }
        iteration++;
    } while (iteration < 5 && !noScale);

    if (!noScale) {
        // here we tried 5 times, but still got scale in the rotation matrix
        throw new Error('Rotation matrix still has scale!');
    }
    return finalMx;
}

export function rotateCrownToAlignWithAxis(
    crownGeometry: THREE.BufferGeometry,
    insertionAxis: THREE.Vector3,
    mode: 'duplicate' | 'modify' = 'duplicate',
): RotationResult {
    // if this geometry is still used in the 3DView, we don't want
    // to modify it.  If we are doing a transform server side, no need
    // to waste the memory
    const transform_me = mode === 'duplicate' ? crownGeometry.clone() : crownGeometry;

    let finalMx = new THREE.Matrix4().identity();

    // rotate it
    try {
        finalMx = generateRotationMatrixWithNoScale(insertionAxis);
    } catch (e) {
        logger.warn('WARNING: Skipping rotation, could not generate matrix without scaling', e);
    }

    // center it
    transform_me.computeBoundingBox();
    const bbox = transform_me.boundingBox;
    const bbox_center = new THREE.Vector3();
    bbox?.getCenter(bbox_center);
    const tmx = new THREE.Matrix4().makeTranslation(-bbox_center.x, -bbox_center.y, -bbox_center.z);
    finalMx = finalMx.multiply(tmx);

    // apply it
    transform_me.applyMatrix4(finalMx);
    return { updateMatrix: finalMx, updatedGeometry: transform_me };
}

export const IDENTITY_MATRIX = new THREE.Matrix4();

/**
 * Checks whether the geometry has an edge longer than the specified threshold
 * @param geometry The object to check
 * @param v2V The vertex to vertex adjacency matrix of `geometry`
 * @param maxLength The edge length threshold
 * @returns True if `geometry` has an edge longer than `maxLength`; false otherwise
 */
export function hasEdgeLongerThan(
    geometry: THREE.BufferGeometry,
    v2V: AdjacencyMatrix,
    maxLength: number,
): boolean | undefined {
    const positions = geometry.getAttribute(AttributeName.Position);
    if (!positions) {
        return;
    }

    const maxLenSquared = maxLength * maxLength;
    const nVertices = positions.count;

    const p = new THREE.Vector3();
    const q = new THREE.Vector3();

    for (let i = 0; i < nVertices; ++i) {
        const neighbors = v2V[i];
        if (!neighbors) {
            return;
        }

        for (const ni of neighbors) {
            if (i >= ni) {
                // Only need to check each edge once (i.e. should only check 1 -> 2 and not 2 -> 1).
                continue;
            }

            p.fromBufferAttribute(positions, i);
            q.fromBufferAttribute(positions, ni);

            if (p.sub(q).lengthSq() > maxLenSquared) {
                return true;
            }
        }
    }

    return false;
}

export function fitAPlaneToPoints(points: THREE.Vector3[]): THREE.Plane {
    if (points.length < 3) {
        logger.warn(`Can't fit a plane to less than 3 points, but have ${points.length}`);
        return new THREE.Plane(new THREE.Vector3(0, 0, 0), 0);
    }
    const avg = getAverageVector(points);

    const H = new Matrix3();
    for (let i = 0; i < points.length; i++) {
        const pc = points[i]?.clone().sub(avg);
        if (!pc) {
            continue;
        }
        const product = Matrix3.fromVectorTransposeProduct(pc, pc);
        H.add(product);
    }

    try {
        const ev = numeric.eig(H.data);

        //eigen values
        const EV = ev.lambda.x as number[];
        const minEV = Math.min.apply(null, EV);
        const minEV_index = EV.indexOf(minEV);

        //eigen vectors
        const E = ev.E.x as number[][];
        const e0 = E[0];
        const e1 = E[1];
        const e2 = E[2];
        if (e0 === undefined || e1 === undefined || e2 === undefined) {
            throw new Error("Couldn't fit a plane to points, eigen vectors are undefined");
        }
        const n = new THREE.Vector3(e0[minEV_index], e1[minEV_index], e2[minEV_index]);
        const plane = new THREE.Plane();
        plane.setFromNormalAndCoplanarPoint(n.normalize(), avg);
        return plane;
    } catch (error) {
        logger.error("Couldn't fit a plane to points", { error, matrixData: H.data });
        // as an alternative, we can use the first 3 points to fit a plane
        const pt0 = points[0];
        const pt1 = points[1];
        const pt2 = points[2];
        const plane = new THREE.Plane();
        if (pt0 && pt1 && pt2) {
            plane.setFromCoplanarPoints(pt0, pt1, pt2);
        }
        return plane;
    }
}

export function computeLocalMaximaOfVertices(points: THREE.Vector3[], plane: THREE.Plane): THREE.Vector3 {
    let maxima = -Infinity;
    const maximaVertex = new THREE.Vector3();
    for (const point of points) {
        const distance = plane.distanceToPoint(point);
        if (distance > maxima) {
            maximaVertex.copy(point);
            maxima = distance;
        }
    }
    return maximaVertex;
}

type FacetPredicate = (fIdx: number) => boolean;

export function extractFacetsToNewGeometry(geom: THREE.BufferGeometry, pred: FacetPredicate) {
    const posAttr = geom.getAttribute(AttributeName.Position);
    const index = geom.getIndex();
    const outGeom = new THREE.BufferGeometry();
    const verts = [];
    if (index === null) {
        for (let fIdx = 0; fIdx < posAttr.count / 3; fIdx += 1) {
            if (pred(fIdx)) {
                const vIdx = fIdx * 3;
                verts.push(
                    posAttr.getX(vIdx),
                    posAttr.getY(vIdx),
                    posAttr.getZ(vIdx),
                    posAttr.getX(vIdx + 1),
                    posAttr.getY(vIdx + 1),
                    posAttr.getZ(vIdx + 1),
                    posAttr.getX(vIdx + 2),
                    posAttr.getY(vIdx + 2),
                    posAttr.getZ(vIdx + 2),
                );
            }
        }
    } else {
        const facets = [];
        const vertMap = new Map<number, number>();
        const getMappedVert = (vIdx: number) => {
            let j = vertMap.get(vIdx);
            if (j === undefined) {
                j = Math.trunc(verts.length / 3);
                vertMap.set(vIdx, j);
                verts.push(posAttr.getX(vIdx), posAttr.getY(vIdx), posAttr.getZ(vIdx));
            }
            return j;
        };
        for (let fIdx = 0; fIdx < index.count / 3; fIdx += 1) {
            if (!pred(fIdx)) {
                continue;
            }

            facets.push(
                getMappedVert(index.getX(3 * fIdx)),
                getMappedVert(index.getY(3 * fIdx)),
                getMappedVert(index.getZ(3 * fIdx)),
            );
        }
        outGeom.setIndex(facets);
    }

    outGeom.setAttribute(AttributeName.Position, new THREE.Float32BufferAttribute(verts, 3));
    return outGeom;
}

export function* generateFacets(geom: THREE.BufferGeometry) {
    const index = geom.getIndex();
    if (index) {
        for (let i = 0; i < index.count; i += 3) {
            yield { index: i / 3, verts: [index.getX(i), index.getY(i), index.getZ(i)] as const };
        }
    } else {
        const posAttr = geom.getAttribute(AttributeName.Position);
        for (let i = 0; i < posAttr.count; i += 3) {
            yield { index: i / 3, verts: [i, i + 1, i + 2] as const };
        }
    }
}

/**
 * Checks whether the geometry has a vertex with surface displacement whose magnitude exceeds a threshold
 * @param geometry The object to check
 * @param maxDisplacement The surface displacement magnitude threshold
 * @returns True if such a vertex was found; false otherwise
 */
export function hasSurfaceDisplacementGreaterThan(
    geometry: THREE.BufferGeometry,
    maxDisplacement: number,
): boolean | undefined {
    const displacements = geometry.getAttribute(AttributeName.SurfaceDisplacement);
    if (!displacements) {
        return;
    }

    const numVertices = displacements.count;
    for (let i = 0; i < numVertices; ++i) {
        const displacement = displacements.getX(i);
        if (displacement === ATTRIBUTE_MAP_INVALID_VALUE) {
            continue;
        }

        if (Math.abs(displacement) > maxDisplacement) {
            return true;
        }
    }

    return false;
}

export function hasThicknessLessThan(sourceModel: THREE.BufferGeometry, minThickness: number): boolean | undefined {
    const thicknessAttribute = sourceModel.getAttribute(AttributeName.ThicknessDistance);
    if (!thicknessAttribute) {
        return;
    }

    const numVertices = thicknessAttribute.count;
    for (let i = 0; i < numVertices; ++i) {
        const thickness = thicknessAttribute.getX(i);
        if (thickness === undefined || thickness === ATTRIBUTE_MAP_INVALID_VALUE) {
            continue;
        }

        if (thickness < minThickness) {
            return true;
        }
    }

    return false;
}

/**
 * Returns the percentile of `threshold` in the cement gap distance attribute of `geometry`
 * @param geometry The geometry over which to calculate the percentile
 * @param threshold The threshold for which to calculate the percentile
 * @returns The percentile as a value on the unit interval
 */
export function getCementGapPercentile(geometry: THREE.BufferGeometry, threshold: number): number {
    const cementGapAttr = geometry.getAttribute(AttributeName.CementGapDistance);
    if (!cementGapAttr) {
        throw new Error('Missing cement gap distance attribute.');
    }

    return getPercentile(cementGapAttr.array, threshold);
}

/**
 * Returns the percentile of `threshold` in the cement gap distance attribute of `geometry`, for vertices in the seal zone
 * @param geometry The geometry over which to calculate the percentile
 * @param threshold The threshold for which to calculate the percentile
 * @returns The percentile as a value on the unit interval
 */
export function getSealZoneGapPercentile(geometry: THREE.BufferGeometry, threshold: number): number {
    const cementGapAttr = geometry.getAttribute(AttributeName.CementGapDistance);
    if (!cementGapAttr) {
        throw new Error('Missing cement gap distance attribute.');
    }

    const isSealZoneAttr = geometry.getAttribute(AttributeName.IsSealZone);
    if (!isSealZoneAttr) {
        throw new Error('Missing seal zone attribute.');
    }

    const sealZoneGaps: number[] = [];
    for (let i = 0; i < isSealZoneAttr.count; ++i) {
        if (isSealZoneAttr.getX(i)) {
            sealZoneGaps.push(cementGapAttr.getX(i));
        }
    }

    return getPercentile(sealZoneGaps, threshold);
}

export function ensureBoundingBox(geometry: THREE.BufferGeometry): THREE.Box3 {
    if (!geometry.boundingBox) {
        geometry.computeBoundingBox();
    }
    return geometry.boundingBox as THREE.Box3;
}
