import { useRowsFromInvoiceItems } from '../../invoice-detail/components/InvoiceDetailBody.utils';
import type { InvoiceItemRow } from '../../invoice-detail/components/InvoiceTransactionsTable';
import type { AddressOption } from '../../invoice-detail/invoiceItemFilter';
import { useInvoiceItemFilter } from '../../invoice-detail/invoiceItemFilter';
import type {
    BillingCreditCategories,
    ContractSpendTerm,
    Credit,
    PracticeInvoiceDetail,
    PracticeInvoiceItem,
} from '../../invoicing.types';
import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlDoctorDeliveryAddressFragment as Address, LabsGqlOrder } from '@orthly/graphql-operations';
import { useOrdersByIds } from '@orthly/graphql-react';
import { useSession } from '@orthly/session-client';
import { useFeatureFlag, usePrintableState } from '@orthly/veneer';
import { compact } from 'lodash';
import React from 'react';

export interface InvoiceDetailContextType {
    invoice: PracticeInvoiceDetail;
    refetchInvoice: () => Promise<unknown>;
    rows: InvoiceItemRow[];
    // we already handle the invoice loading state in `InvoicesRoot`, so we don't need to expose it here
    // but we do load the orders in order to populate the row data, so ensure that loading state is handled
    rowsLoading: boolean;
    autochargeEnabled: boolean;
    contractSpendTerms: ContractSpendTerm[];

    /** doctors filters */
    doctorNameFilter?: string;
    setDoctorNameFilter: (f?: string) => void;
    doctorOptions: string[];

    /** address filters */
    addressIdFilter?: string;
    setAddressIdFilter: (f?: string) => void;
    addresses: AddressOption[];
    addressFilterName?: string;

    /** printability */
    printableSlipIsOpen: boolean;
    openPrintableSlip: () => void;
    closePrintableSlip: () => void;
    billingContactInfoLoading: boolean;
    organizationName?: string;
    billingEmail?: string;
}

const InvoiceDetailContext = React.createContext<InvoiceDetailContextType | null>(null);

const GetBillingContactData_Query = graphql(`
    query GetBillingContactInfo($organizationId: String!) {
        billingContact: getPartnerBillingAccount(partner_id: $organizationId) {
            primary_billing_contact_user_id
        }
        organization: getOrganization(id: $organizationId) {
            name
        }
    }
`);

const GetBillingContactEmail_Query = graphql(`
    query GetBillingContactEmail($userId: String!) {
        billingContactEmail: getUser(id: $userId) {
            email
        }
    }
`);

const GetPracticeContractV2ByOrganizationId_Query = graphql(`
    query GetPracticeContractV2ByOrganizationId($organization_id: String!) {
        getPracticeContractV2ByOrganizationId(organization_id: $organization_id) {
            id
            spend_terms {
                id
                effective_start_date
                effective_end_date
                spend_minimum_cents
                spend_cycle_length
            }
        }
    }
`);

const ListAddresses_Query = graphql(`
    query ListAddresses {
        listAddresses {
            id
            partner_id
            street_one
            street_two
            city
            state
            postal_code
            country
            deleted_at
            longitude
            latitude
        }
    }
`);

const ListItemsForInvoice_Query = graphql(`
    query ListItemsForInvoice($invoiceId: String!) {
        listInvoiceItemsForInvoice(invoiceId: $invoiceId) {
            id
            category
            subcategory
            item_type
            invoice_id
            order_id
            amount_cents
            description
            recurring_item_id
            used_credit_id
            created_at
            updated_at

            order_created_at
            doctor_name
            mailing_address_id
        }
    }
`);

const ListAllInvoiceCreditsForOrganization_Query = graphql(`
    query ListAllInvoiceCreditsForOrganization($organizationId: String!) {
        credits: listInvoiceCreditsForOrganization(organizationId: $organizationId) {
            id
            credit_category_id
        }
    }
`);

const ListAllBillingCreditCategories_Query = graphql(`
    query ListAllBillingCreditCategories($include_archived: Boolean) {
        getAllBillingCreditCategories(include_archived: $include_archived) {
            id
            updated_at
            created_at
            name
            archived
        }
    }
`);

export const InvoiceDetailCtxProvider: React.FC<{
    invoice: PracticeInvoiceDetail;
    refetchInvoice: () => Promise<unknown>;
    autochargeEnabled: boolean;
}> = ({ invoice, refetchInvoice, autochargeEnabled, children }) => {
    const { value: enableCreditAndRefundOverhaul } = useFeatureFlag('enableCreditAndRefundOverhaul');
    const practiceId = useSession()?.organization_id ?? '';
    const { printableIsOpen, openPrintable, closePrintable } = usePrintableState();

    const { data: { billingContact, organization } = {}, loading: billingContactDataLoading } = useQuery(
        GetBillingContactData_Query,
        {
            variables: { organizationId: practiceId },
        },
    );

    const { data: { billingContactEmail } = {}, loading: billingContactEmailLoading } = useQuery(
        GetBillingContactEmail_Query,
        {
            variables: { userId: billingContact?.primary_billing_contact_user_id ?? '' },
            skip: !billingContact?.primary_billing_contact_user_id,
        },
    );

    const { data: practiceContractData } = useQuery(GetPracticeContractV2ByOrganizationId_Query, {
        variables: { organization_id: practiceId ?? '' },
    });

    const { data: { listInvoiceItemsForInvoice: items = [] } = {} } = useQuery<{
        listInvoiceItemsForInvoice: PracticeInvoiceItem[];
    }>(ListItemsForInvoice_Query, {
        variables: { invoiceId: invoice.id },
    });

    const { data: { credits = [] } = {}, loading: creditsLoading } = useQuery<{ credits: Credit[] }>(
        ListAllInvoiceCreditsForOrganization_Query,
        {
            variables: { organizationId: practiceId },
            skip: !enableCreditAndRefundOverhaul,
        },
    );

    const { data: { getAllBillingCreditCategories: creditCategories = [] } = {}, loading: creditCategoriesLoading } =
        useQuery<{
            getAllBillingCreditCategories: BillingCreditCategories;
        }>(ListAllBillingCreditCategories_Query, {
            variables: { include_archived: true },
            skip: !enableCreditAndRefundOverhaul,
        });

    const spendTerms = React.useMemo(
        () => practiceContractData?.getPracticeContractV2ByOrganizationId?.spend_terms ?? [],
        [practiceContractData],
    );

    const orderIds = React.useMemo(() => compact(items.map(i => i.order_id)), [items]);
    const { orders: ordersRaw, loading } = useOrdersByIds(orderIds);
    const orders = React.useMemo<LabsGqlOrder[]>(() => compact(ordersRaw), [ordersRaw]);

    /** filter block */
    const { data: { listAddresses } = {}, loading: addressesLoading } = useQuery<{ listAddresses: Address[] }>(
        ListAddresses_Query,
    );
    const [doctorName, setDoctorName] = React.useState<string | undefined>();
    const [addressId, setAddressId] = React.useState<string | undefined>();
    const { doctorNameOptions, addressIdOptions, rowFilter } = useInvoiceItemFilter(
        orders,
        items,
        compact(listAddresses),
    );
    const addressFilterName = addressIdOptions.find(a => a.id === addressId)?.label ?? '';
    const doctorFiltersLoading = loading && doctorNameOptions.length === 0;
    const addressFiltersLoading = addressesLoading && addressIdOptions.length === 0;
    /** end filter block */

    const rows = useRowsFromInvoiceItems(items, orders, credits, creditCategories);
    const visibleItemRows = React.useMemo(
        () => rows.filter(rowFilter({ doctorName, addressId })),
        [rows, doctorName, addressId, rowFilter],
    );

    return (
        <InvoiceDetailContext.Provider
            value={{
                invoice,
                refetchInvoice,
                rows: visibleItemRows,
                rowsLoading:
                    loading ||
                    doctorFiltersLoading ||
                    addressFiltersLoading ||
                    creditsLoading ||
                    creditCategoriesLoading,
                autochargeEnabled,
                contractSpendTerms: spendTerms,
                /** doctor filters */
                doctorOptions: doctorNameOptions,
                doctorNameFilter: doctorName,
                setDoctorNameFilter: setDoctorName,
                /** address filters */
                addresses: addressIdOptions,
                setAddressIdFilter: setAddressId,
                addressIdFilter: addressId,
                addressFilterName,
                /** printability */
                printableSlipIsOpen: printableIsOpen,
                openPrintableSlip: openPrintable,
                closePrintableSlip: closePrintable,
                billingContactInfoLoading: billingContactDataLoading || billingContactEmailLoading,
                organizationName: organization?.name,
                billingEmail: billingContactEmail?.email,
            }}
        >
            {children}
        </InvoiceDetailContext.Provider>
    );
};

export function useInvoiceDetailContext() {
    const ctx = React.useContext(InvoiceDetailContext);
    if (!ctx) {
        throw new Error('useInvoiceDetailContext must be used within a InvoiceDetailCtxProvider');
    }

    return ctx;
}
