import { SuggestedValueWarning } from '../LegacyCheckoutField/SuggestedValueWarning';
import type { SimpleSelectProps } from '@orthly/ui';
import { SimpleSelect } from '@orthly/ui';
import React from 'react';

interface CheckoutSelectFieldProps extends SimpleSelectProps {
    required: boolean;
    small?: boolean;
    suggestedValue?: string;
}

export const CheckoutSelectField: React.FC<CheckoutSelectFieldProps> = props => {
    const { required, small, suggestedValue, ...selectProps } = props;
    return (
        <>
            <SimpleSelect
                {...selectProps}
                FormControlProps={{
                    ...selectProps.FormControlProps,
                    variant: 'standard',
                    required,
                    style: { backgroundColor: 'transparent', fontWeight: 500 },
                    error: required && !selectProps.value,
                    size: small ? 'small' : undefined,
                }}
                FormHelperTextProps={{
                    ...selectProps.FormHelperTextProps,
                    style: {
                        margin: '4px 14px 10px',
                        textTransform: 'none',
                        ...selectProps.FormHelperTextProps?.style,
                    },
                }}
                SelectProps={{
                    ...selectProps?.SelectProps,
                    MenuProps: {
                        PaperProps: { style: { maxHeight: '40vh' } },
                        anchorOrigin: { horizontal: 'left', vertical: 'bottom' },
                        ...selectProps?.SelectProps?.MenuProps,
                    },
                    variant: 'standard',
                }}
            />
            <SuggestedValueWarning
                currentValue={selectProps.value}
                suggestedValue={suggestedValue}
                customStyles={{ marginBottom: '24px' }}
                onAccept={() => selectProps.onChange(suggestedValue)}
            />
        </>
    );
};
