import { CompressionType } from '../ZipFormat';
import type { BufferView } from './Util';
import { Vec } from './Vec';
import zlib from 'zlib';

// Comperssion algorithm interface.
//
export interface ICompression {
    id: CompressionType;
    compress(buf: Uint8Array): Uint8Array | PromiseLike<Uint8Array>;
    decompress(buf: Uint8Array): Uint8Array | PromiseLike<Uint8Array>;
}

// Compression algorithm registry.
//
const registry: ICompression[] = [];

// Registers a compressor.
//
export function register(
    id: CompressionType,
    cb: (buf: Uint8Array, op: 'compress' | 'decompress') => Uint8Array | PromiseLike<Uint8Array>,
) {
    registry[id] = {
        id,
        compress: buf => cb(buf, 'compress'),
        decompress: buf => cb(buf, 'decompress'),
    };
}

// Finds a compression algoritm by its Zip identifiers or throws an error.
//
export function find(identifier: CompressionType) {
    const result = registry[identifier];
    if (!result) {
        throw Error(`Unknown compression algorithm: ${CompressionType[identifier] || identifier}`);
    }
    return result;
}

// Register the no-op algorithm.
//
register(CompressionType.STORED, k => k);

// Determine if we're in NodeJS.
//
const __global = globalThis as any;
let inNodeJs = false;
try {
    inNodeJs = !!__global.process.versions.node;
} catch {}

// If we're in the context of a node process:
//
if (inNodeJs) {
    interface ZlibModule {
        deflateRaw(buf: Uint8Array, cb: (err: Error | null, res: Uint8Array) => void): void;
        inflateRaw(buf: Uint8Array, cb: (err: Error | null, res: Uint8Array) => void): void;
    }

    // Registers a named compressor using node-zlib.
    //
    type CompressCallback = (
        lib: ZlibModule,
        input: Uint8Array,
        cb: (err: Error | null, out: Uint8Array) => void,
    ) => void;
    const registerZlib = (id: CompressionType, compress: CompressCallback, decompress: CompressCallback) => {
        const ops = { compress, decompress } as const;
        register(id, async (buf, op) => {
            return new Promise<Uint8Array>((res, rej) => {
                ops[op](zlib, buf, (err, data) => {
                    err ? rej(err) : res(data);
                });
            });
        });
    };

    // Register deflate raw.
    //
    registerZlib(
        CompressionType.DEFLATE,
        (lib, buf, cb) => lib.deflateRaw(buf, cb),
        (lib, buf, cb) => lib.inflateRaw(buf, cb),
    );
}
// Otherwise:
//
else {
    // Registers a named compressor using web-streams.
    //
    const ops = { compress: __global.CompressionStream, decompress: __global.DecompressionStream } as const;
    const registerWeb = (id: CompressionType, name: string) => {
        register(id, async (buf, op) => {
            const { readable, writable } = new ops[op](name);
            const writer = writable.getWriter();
            writer.write(buf);
            writer.close();

            const reader = readable.getReader() as {
                read(): Promise<{ done: boolean; value: BufferView }>;
            };

            const result = new Vec();
            while (true) {
                const { done, value } = await reader.read();
                if (done) {
                    break;
                }
                result.append(value);
            }
            return result.raw;
        });
    };

    // Register deflate-raw using Web streams.
    //
    registerWeb(CompressionType.DEFLATE, 'deflate-raw');
}
