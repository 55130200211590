/* eslint-disable max-lines */
import { ensureMeshIndex } from '../../Utils3D';
import { AttributeName } from '../../Utils3D/BufferAttributeConstants';
import { logger } from '../../Utils/Logger';
import { flipBlockEndianness, getChecksum } from './DcmEncryption';
import { parseFacets } from './DcmFacetParser';
import type { DcmSpline } from './DcmGeometryInjector';
import { DcmSchema } from './DcmParsing';
import type { ToothNumber } from '@orthly/items';
import { ToothUtils } from '@orthly/items';
import * as b64 from 'base64-arraybuffer';
import Blowfish from 'egoroof-blowfish';
import * as _ from 'lodash';
import type { Duplex } from 'stream';
import * as THREE from 'three';

import TypedArray = NodeJS.TypedArray;

/*
 * This file contains the methods to take geometry attributes to and
 * from the base64 or other structures in dcm files
 * */
export function vertCoordsToBase64(geometry: THREE.BufferGeometry): {
    vertsBase64: string;
    vertexCount: number;
    encodedBytes: number;
} {
    const positionAttrib = geometry.attributes[AttributeName.Position];
    if (positionAttrib === undefined) {
        throw new Error('No Vertex Positions to Encode');
    }

    const typeArray = positionAttrib.array as TypedArray;
    return {
        vertsBase64: b64.encode(typeArray.buffer),
        vertexCount: positionAttrib.count,
        encodedBytes: typeArray.byteLength,
    };
}

const PADDING_VALUE: number = 16711680;

export enum MinimalDcmBufferType {
    DCM,
    DICOM,
    UNKNOWN,
}

/**
 * This checks if the buffer is a minimal DCM file, or if not, whether it is a DICOM medical image file.
 * @param buffer
 * @returns DcmBufferType
 */
export function minimalDcmBufferType(buffer: ArrayBuffer | Buffer): MinimalDcmBufferType {
    const bufferString = new TextDecoder().decode(buffer.slice(0, 132));

    if (!bufferString.includes('<HPS')) {
        // If the buffer doesn't contain the string '<HPS', it's probably not a 3Shape scan file.

        if (bufferString.includes('DICM')) {
            // If the buffer contains 'DICM', it's a DICOM medical image file.
            return MinimalDcmBufferType.DICOM;
        }

        return MinimalDcmBufferType.UNKNOWN;
    }

    // If the buffer contains '<HPS', we can assume it is a 3Shape scan file.
    return MinimalDcmBufferType.DCM;
}

/**
 * This packs an array of vectors as a Float32 with a padded
 * Float32 every 4th element. This is a 3Shape specific structure
 * and presumably the padded number is used as a bitfield or color
 * etc.  EG, the padding value above is red.  In testing, it seems Dental
 * Designer ignores this color and always shows Blue for doctor margins in scan
 * and green for margins stored in CAD/*.dcm files.
 */
function pointsToPaddedArray(points: THREE.Vector3[]): Float32Array {
    const paddedValues = points.map<[number, number, number, number]>(point => [
        point.x,
        point.y,
        point.z,
        PADDING_VALUE,
    ]);
    return new Float32Array(_.flatten(paddedValues));
}

/*
 * A 3Shape convention specific util
 * the x,y,z float32 binary coords in 3Shape binary spline objects
 * are padded every 4th element (see above).
 * */
export function splinePointsToBase64(points: THREE.Vector3[]): string {
    const arr = pointsToPaddedArray(points);
    return b64.encode(arr.buffer);
}

/**
 * Packs the facets of a specified geometry as a one byte command (always 6)
 * followed by 3 Int32 indices representing each vertex of that facet.
 * @param geometry A three.js buffer geometry object to encode the facets of.
 * @returns An object containing the base64 encoded buffer as a string and the
 *          number of facets that were written to it.
 */
export function facetsArrayToBase64(geometry: THREE.BufferGeometry): {
    facetsBase64: string;
    facetCount: number;
    encodedBytes: number;
} {
    const facetsAttrib = geometry.getIndex()?.array;
    if (!facetsAttrib) {
        throw new Error(`Cannot encode facets of non-indexed buffer geometry`);
    }
    const nFaces = facetsAttrib.length / 3;
    const bytesPerFacet = 13;
    const packedFacetArray = new Int8Array(bytesPerFacet * nFaces);

    const indexesArray = new Int32Array(3);
    const indexesOutArray = new Int8Array(indexesArray.buffer);

    for (let i = 0; i < nFaces; i++) {
        // In the trivial case, every 3-tuple of int32 vertex indices is preceded by a
        // one byte instruction 6
        packedFacetArray[bytesPerFacet * i] = 6;
        indexesArray[0] = facetsAttrib[3 * i] as number;
        indexesArray[1] = facetsAttrib[3 * i + 1] as number;
        indexesArray[2] = facetsAttrib[3 * i + 2] as number;
        for (let j = 0; j < indexesOutArray.length; ++j) {
            packedFacetArray[j + bytesPerFacet * i + 1] = indexesOutArray[j] as number;
        }
    }

    return {
        facetsBase64: b64.encode(packedFacetArray.buffer),
        facetCount: nFaces,
        encodedBytes: packedFacetArray.byteLength,
    };
}

// Texture coordinates are packed using 16 bit fixed point representation into
// a 32 bit unsigned integer. There are two fixed point range modes used for
// values in 3Shape's DCM, one for values that are in the range [0, 1] and
// another for those that aren't. The mode is indicated by the MSB of each
// 16-bit half of the 32 bit integer, a bit value of '0' indicates the [0, 1]
// mode. A bit value of '1' indicates a [-256, 256] 'wide' mode.
const PACKED_FP16_SHIFT = 1 << 16;
const FP16_RANGE_BIT = 1 << 15;
const FP16_LARGE_SCALE = 64;
const FP16_LARGE_BIAS = 256;
const FP16_SMALL_SCALE = FP16_RANGE_BIT - 1;

function unscaleFp16(uint16: number): number {
    if (uint16 >= FP16_RANGE_BIT) {
        return (uint16 - FP16_RANGE_BIT) / FP16_LARGE_SCALE - FP16_LARGE_BIAS;
    }
    return uint16 / FP16_SMALL_SCALE;
}

const FP16_MIN_VALUE = unscaleFp16(0);
const FP16_MAX_VALUE = unscaleFp16(FP16_SMALL_SCALE);

function scaleFp16(value: number): number {
    if (value > FP16_MAX_VALUE || value < FP16_MIN_VALUE) {
        throw new Error(`Cannot pack ${value} into FP16, must be between ${FP16_MIN_VALUE} and ${FP16_MAX_VALUE}.`);
    }
    if (value > 1 || value < 0) {
        // We're in the 'wide' range mode, set the range bit to indicate this.
        return FP16_RANGE_BIT | Math.round((value + FP16_MIN_VALUE) * FP16_LARGE_SCALE + FP16_LARGE_BIAS);
    }
    // The 'narrow' range mode.
    return Math.round(value * FP16_SMALL_SCALE);
}

function unpackVec2(packed: number): [number, number] {
    const lower = packed % PACKED_FP16_SHIFT;
    const upper = Math.trunc(packed / PACKED_FP16_SHIFT);
    return [unscaleFp16(lower), unscaleFp16(upper)];
}

function packVec2(x: number, y: number): number {
    const lower = scaleFp16(x);
    const upper = scaleFp16(y);
    return (PACKED_FP16_SHIFT * upper) | lower;
}

/**
 * Packs the UV texture coordinates of the specified geometry as aa 1 byte number
 * (always 1) followed by the UV coordinates represented as 15 bit fixed point
 * packed into the upper and lower 16 bits of a 32 bit Uint.
 * @param geometry A three.js buffer geometry object to encode the texture
 *                 coordinates of.
 * @returns An object containing the base64 encoded buffer as a string and the
 *          number of texture coordinates that were written to it.
 */
export function texCoordsToBase64(geometry: THREE.BufferGeometry): {
    uvBase64: string;
    uvCount: number;
    encodedBytes: number;
} {
    const uvAttrib = geometry.attributes.uv;
    if (!uvAttrib) {
        throw new Error(`Cannot pack texture coordinates: geometry is missing 'uv' attribute`);
    }
    const bytesPerUv = 5;
    const packedUvArray = new Int8Array(bytesPerUv * uvAttrib.count);

    // The most straight forward way to unpack the 32 bit uint into bytes is to
    // use these array types to alias a single UInt32 with a byte array.
    const indexesArray = new Uint32Array(1);
    const indexesOutArray = new Int8Array(indexesArray.buffer);

    for (let i = 0; i < uvAttrib.count; i++) {
        // In the trivial case each vertex has a single texture coordinate, which
        // we record by writing a byte with the number 1. Alternatively there could
        // be n texture coordinates recorded for a single vertex (where n is the
        // number of incident facets).
        packedUvArray[bytesPerUv * i] = 1;
        const u = uvAttrib.getX(i);
        const v = uvAttrib.getY(i);
        // The u coordinate is expected in the least significant 16 bits while the
        // v coordinate is expected in the most significant 16 bits. To shift the
        // v coordinate into the MS 16 bits, we can multiply by 2^16 and add it to
        // the packed u coordinate.
        indexesArray[0] = packVec2(u, v);
        for (let j = 0; j < indexesOutArray.length; j++) {
            packedUvArray[bytesPerUv * i + 1 + j] = indexesOutArray[j] as number;
        }
    }

    return {
        uvBase64: b64.encode(packedUvArray.buffer),
        uvCount: uvAttrib.count,
        encodedBytes: packedUvArray.byteLength,
    };
}

function bufferToFloat32Array(data: DataView): Float32Array {
    const myFloatArr = new Float32Array(data.byteLength / 4);
    for (let i = 0; i < myFloatArr.length; i++) {
        myFloatArr[i] = data.getFloat32(i * 4, true);
    }
    return myFloatArr;
}

export function base64ToSplineCoords(data: string): { x: number; y: number; z: number }[] {
    const binaryData = b64.decode(data);
    const dataView = new DataView(binaryData);
    const floatArray = bufferToFloat32Array(dataView);
    const nVerts = floatArray.length / 4;
    const coords: { x: number; y: number; z: number }[] = [];
    for (let i = 0; i <= nVerts; i++) {
        const x = floatArray[4 * i];
        const y = floatArray[4 * i + 1];
        const z = floatArray[4 * i + 2];
        // 4th float is a packed dummy number

        // Safety Checks, maybe we got encrypted data we are unaware of
        if (x === undefined || y === undefined || z === undefined) {
            continue;
        }

        if ([x, y, z].some(value => Math.abs(value) > 10000 || _.isNaN(value))) {
            throw new Error('UNUSUAL NUMBER PARSING FOR MARGIN');
        }
        coords.push({ x, y, z });
    }
    return coords;
}

export function base64ToSplinePoints(data: string): THREE.Vector3[] {
    const coords = base64ToSplineCoords(data);
    return coords.map(({ x, y, z }) => new THREE.Vector3(x, y, z));
}

export function base64ToPaddedVertices(b64Data: string, decryptOpts?: DecryptOptions): THREE.Vector3[] {
    const data = base64ToUint8Array(b64Data, decryptOpts);

    const view = new DataView(data.buffer);
    const bytesPerVec = 4 * Float32Array.BYTES_PER_ELEMENT;
    const length = Math.trunc(data.byteLength / bytesPerVec);
    const vecs = [];
    for (let i = 0; i < length; i += 1) {
        const offset = bytesPerVec * i;
        vecs.push(
            new THREE.Vector3(
                view.getFloat32(offset, true),
                view.getFloat32(offset + Float32Array.BYTES_PER_ELEMENT, true),
                view.getFloat32(offset + 2 * Float32Array.BYTES_PER_ELEMENT, true),
            ),
        );
    }
    return vecs;
}

export function facetMarksToBase64(data: ArrayLike<number>): string {
    const marksArray = new Uint32Array(data);
    return b64.encode(marksArray.buffer);
}

export function base64ToFacetMarks(data: string): Uint32Array {
    const binaryData = b64.decode(data);
    return new Uint32Array(binaryData);
}

// TODO:  EPDCAD-665 (unify UNN from DCM)
/**
 * Extracts the UNN index from a 3Shape FacetMark mask.
 * @param mark The facet mark bitmask
 * @returns The UNN index of the tooth
 */
export function unnFromFacetMark(mark: number): number {
    // Extract the upper 5 bits of the 32 bit mask by shifting off the lower
    // 27 and then masking those 5 bits (prevent any sign extension from creeping in).
    return ((mark >> 27) & 0x1f) + 1;
}

const reuseVector = new THREE.Vector3();
export function transformVectorObj(vectorObj: { x: number; y: number; z: number }, transform: THREE.Matrix4) {
    reuseVector.set(vectorObj.x, vectorObj.y, vectorObj.z);
    reuseVector.applyMatrix4(transform);
    return { x: reuseVector.x, y: reuseVector.y, z: reuseVector.z };
}

export function transformVectorOrientation(vectorObj: { x: number; y: number; z: number }, transform: THREE.Matrix4) {
    reuseVector.set(vectorObj.x, vectorObj.y, vectorObj.z);
    reuseVector.transformDirection(transform);
    return { x: reuseVector.x, y: reuseVector.y, z: reuseVector.z };
}

export function normalizePackageLockList(value: string | undefined): string | undefined {
    if (!value) {
        return undefined;
    }

    const split = _.uniq(
        value
            .split(';')
            .filter(x => x.length > 0)
            .sort(),
    );
    return `${split.join(';')};`;
}

export interface DecryptOptions {
    bytes: number;
    checkValue?: string;
    keys: Uint8Array[];
}

function decrypt(data: Uint8Array, decryptOpts: DecryptOptions) {
    const { bytes, checkValue, keys } = decryptOpts;
    // A peculiar quirk of the 3shape blowfish encryption is that it gets the endianness wrong.
    // In order to correctly decrypt these, we have to flip the endianness of 32-bit chunks and
    // then, at the end, flip them back.
    flipBlockEndianness(data);
    for (const key of keys) {
        // Because of the endian issues we have to disable the default padding and use null padding
        // instead. Unfortunately this blowfish library will always attempt to remove padding and
        // this may still cause things to be inappropriately truncated. So we pad back out to the
        // length of data to ensure we don't lose anything.
        const bf = new Blowfish(key, Blowfish.MODE.ECB, Blowfish.PADDING.NULL);
        const decrypted = (() => {
            const dec = bf.decode(data, Blowfish.TYPE.UINT8_ARRAY);
            if (dec.byteLength === data.byteLength) {
                return dec;
            } else {
                const ret = new Uint8Array(data.byteLength);
                ret.set(dec);
                return ret;
            }
        })();
        // Undo our endian flip.
        flipBlockEndianness(decrypted);
        const checkSum = getChecksum(decrypted, bytes);
        if (!checkValue || checkSum === parseInt(checkValue)) {
            data.set(decrypted);
            return true;
        }
    }

    return false;
}

export function base64ToUint8Array(b64Data: string, decryptOpts?: DecryptOptions): Uint8Array {
    const data = new Uint8Array(b64.decode(b64Data));
    if (decryptOpts && decryptOpts.keys.length > 0 && !decrypt(data, decryptOpts)) {
        throw new Error('Failed to decrypt vertices.');
    }

    return data;
}

export const BytesPerVert = 3 * Float32Array.BYTES_PER_ELEMENT;

export function base64ToVertices(b64Data: string, decryptOpts?: DecryptOptions): THREE.Vector3[] {
    const data = base64ToUint8Array(b64Data, decryptOpts);

    const view = new DataView(data.buffer);
    const length = Math.trunc(data.byteLength / BytesPerVert);
    const vecs = [];
    for (let i = 0; i < length; i += 1) {
        const offset = BytesPerVert * i;
        vecs.push(
            new THREE.Vector3(
                view.getFloat32(offset, true),
                view.getFloat32(offset + Float32Array.BYTES_PER_ELEMENT, true),
                view.getFloat32(offset + 2 * Float32Array.BYTES_PER_ELEMENT, true),
            ),
        );
    }
    return vecs;
}

export function base64ToFacets(b64Data: string) {
    return parseFacets(b64.decode(b64Data));
}

export function base64ToVertexTextureCoords(
    b64Data: string | undefined,
    vertCount: number,
    decryptOpts?: DecryptOptions,
): THREE.Vec2[][] | undefined {
    if (!b64Data) {
        return undefined;
    }
    const data = new Uint8Array(b64.decode(b64Data));
    if (decryptOpts && decryptOpts.keys.length > 0 && !decrypt(data, decryptOpts)) {
        throw new Error('Failed to decrypt texture coordinates.');
    }

    const bytesPerVertex = Math.floor(data.byteLength / vertCount);
    if (bytesPerVertex === 9) {
        // Texture coordinates are typically packed as two 16-bit floating point numbers, but we have seen DCMs where
        // they are packed as 32-bit floating point numbers. We do not currently support the latter format.
        // TODO (EPDCAD-755): Handle double-wide texture coordinates.
        logger.info('Encountered double-wide texture coordinates. Not decrypting.');
        return undefined;
    }

    const allTexCoords = [];
    const view = new DataView(data.buffer);
    let offset = 0;
    let badMemoryAccesses = 0;
    for (let i = 0; i < vertCount; i += 1) {
        if (offset >= view.byteLength) {
            badMemoryAccesses++;
            break;
        }

        const coords = [];
        const count = view.getUint8(offset);
        offset += Uint8Array.BYTES_PER_ELEMENT;
        for (let j = 0; j < count; j += 1) {
            // There was a bug with reading beyond the end of the buffer caused by incorrect
            // handling of padding. This code was added to observe those errors and remains just in
            // case any further issues arise.
            if (offset + 4 > view.byteLength) {
                badMemoryAccesses++;
                continue;
            }
            const packedVec2 = view.getUint32(offset, true);
            // 0xffffffff is a special marker for an invalid value. Treat as [0, 0].
            const uv: [number, number] = packedVec2 !== 0xffffffff ? unpackVec2(view.getUint32(offset, true)) : [0, 0];
            offset += Uint32Array.BYTES_PER_ELEMENT;
            coords.push(new THREE.Vector2(...uv));
        }
        allTexCoords.push(coords);
    }

    if (badMemoryAccesses > 0) {
        logger.info('Attempted to read vertex texture coords beyond DataView size', {
            badMemoryAccesses,
            vertCount,
            dataViewSize: view.byteLength,
        });
    }

    return allTexCoords;
}

// TODO:  EPDCAD-665 (unify UNN from DCM)
export function dcmMargintoUnn(dcmSpline: DcmSpline): ToothNumber {
    if (dcmSpline.name === 'PrepLineCrv') {
        const suspectedUnn = parseInt(dcmSpline.iMisc1);
        if (ToothUtils.isToothNumber(suspectedUnn)) {
            return suspectedUnn;
        }
    } else if (dcmSpline.name.match(/MarginLine_/)) {
        const suspectedUnn = parseInt(dcmSpline.name.split('_')[1] as string);
        if (ToothUtils.isToothNumber(suspectedUnn)) {
            return suspectedUnn;
        }
    }
    return 1;
}

// a function to check if the margin splines are floating
export function checkMarginSplinesFloating(
    transformedPoints: THREE.Vector3[],
    geometry: THREE.BufferGeometry,
): boolean {
    let marginFloating: boolean = false;
    let totalDistance = 0;
    let totalPoints = 0;
    const meshIndex = ensureMeshIndex(geometry);
    // check if any transformed points are not on the scan surface
    transformedPoints.forEach(p => {
        const closestPointInfo = meshIndex.closestPointToPoint(p);
        totalDistance += closestPointInfo?.distance ?? 0;
        totalPoints++;
    });

    if (totalPoints === 0) {
        return false;
    }

    const mean = totalDistance / totalPoints;
    if (mean > 1) {
        marginFloating = true;
    }

    // TODO EPDCAD-879: remove once we are confident in the margin floating check
    logger.info(`margin mean distance to scan surface: ${mean} mm`);

    return marginFloating;
}

export async function isValidDcm(stream: Duplex): Promise<boolean> {
    const root = Object.keys(DcmSchema.shape)[0];
    if (!root) {
        return false;
    }
    // Create a comparison buffer from the expected XML root and compare it to the file's initial contents.
    const rootBuffer = Buffer.from(`<${root}`, 'utf-8');
    const bytes = [];
    for await (const chunk of stream) {
        bytes.push(...chunk.slice(0, rootBuffer.length - bytes.length));
        if (bytes.length >= rootBuffer.byteLength) {
            bytes.splice(rootBuffer.byteLength);
            break;
        }
    }
    const header = Buffer.from(bytes);
    return rootBuffer.compare(header) === 0;
}
