import type { ToothMarkingWithParent } from './NewModelViewer.types';
import React from 'react';
import * as THREE from 'three';

interface ToothMarkingMeshProps {
    marking: ToothMarkingWithParent;
    visible: boolean;
}

export const ToothMarkingMesh: React.VFC<ToothMarkingMeshProps> = ({ marking, visible }) => {
    const { origin, parentObject } = marking;

    const meshRef = React.useRef<THREE.Mesh>();

    React.useEffect(() => {
        const mesh = meshRef.current;
        if (!(mesh && parentObject)) {
            return;
        }

        parentObject.add(mesh);
        mesh.visible = visible;
        return () => {
            parentObject.remove(mesh);
        };
    }, [parentObject, visible]);

    // We hide the object until it has been added to the parent object. Otherwise, we would see the object jump from its
    // initial world-centric position to its final parent-centric position.
    const isInitiallyVisible = !parentObject && visible;

    return (
        <mesh ref={meshRef} position={new THREE.Vector3().fromArray(origin)} visible={isInitiallyVisible}>
            <sphereBufferGeometry attach={'geometry'} />
            <meshBasicMaterial attach={'material'} color={'#00ff00'} />
        </mesh>
    );
};
