/* eslint-disable max-lines */
import { useButtonStyles } from '../hooks';
import { DandyBackdrop } from './DandyBackdrop';
import { TabWithCount, trackButtonClick, formatDataTestTitle } from '@orthly/ui';
import type { ButtonProps, PopperPlacementType, SvgIconProps, TooltipProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    Text,
    Button,
    ListItemText,
    Box,
    ClickAwayListener,
    Grid,
    IconButton,
    MenuItem,
    MenuList,
    Tooltip,
    withStyles,
    CallMadeIcon,
    CloseIcon,
} from '@orthly/ui-primitives';
import cx from 'clsx';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const DandyNavMenuTooltip = withStyles({
    tooltip: {
        borderRadius: 8,
        padding: 8,
    },
})(({ title, ...props }: TooltipProps & { title: string }) => (
    <Tooltip
        arrow
        placement={'bottom'}
        title={
            <Text variant={'body2'} color={'WHITE'}>
                {title}
            </Text>
        }
        PopperProps={{
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 8],
                    },
                },
            ],
        }}
        {...props}
    >
        <div className={'NavMenuTooltipRoot'}>{props.children}</div>
    </Tooltip>
));

const StyledMenuItem = withStyles(() => ({
    root: {
        color: FlossPalette.GRAY,
        height: 40,
        fontFamily: 'Inter Semibold',
        borderRadius: 2,
        transition: 'none',
        '&:hover': {
            background: FlossPalette.DARK_TAN,
            color: FlossPalette.DARK_GRAY,
        },
    },
    selected: {
        color: FlossPalette.PRIMARY_FOREGROUND,
        background: `${FlossPalette.PRIMARY_BACKGROUND} !important`,
        '&:hover': {
            color: FlossPalette.PRIMARY_FOREGROUND,
        },
    },
}))(MenuItem);

export const TooltipMenu = withStyles({
    tooltip: {
        maxWidth: 'none',
        padding: 0,
        margin: 0,
        background: FlossPalette.WHITE,
        color: FlossPalette.GRAY,
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        borderRadius: '0 0 8px 8px',
        boxShadow: 'none',
    },
})((props: TooltipProps) => (
    <Tooltip {...props} placement={props.placement ?? 'bottom-start'}>
        <MenuList>{props.children}</MenuList>
    </Tooltip>
));

export const buttonLinksToNavButtonProps = (
    links: Record<string, string>,
): Omit<NavMenuButtonProps, 'navigationAction'>[] => {
    return Object.entries(links).map(([title, screen]) => ({ title, screen }));
};

export interface NavMenuButtonProps {
    title: string;
    externalLink?: string;
    subtitle?: string;
    screen?: string;
    openExternalLinkAction?: (externalLink: string) => void;
    navigationAction?: () => void;
    analytics?: ButtonProps['analytics'];
}

export const NavMenuButton: React.VFC<NavMenuButtonProps> = props => {
    const { title, subtitle, externalLink, screen, navigationAction, openExternalLinkAction, analytics } = props;
    const history = useHistory();
    const active = history.location.pathname.replaceAll('/', '') === screen?.replaceAll('/', '');

    const onClickAction = () => {
        screen && history.push(`/${screen}`);
        externalLink && openExternalLinkAction?.(externalLink);
        navigationAction?.();
        analytics && trackButtonClick(title, analytics);
    };

    return (
        <StyledMenuItem
            onClick={onClickAction}
            selected={active}
            style={{ height: subtitle ? 'unset' : undefined, alignItems: 'center' }}
            data-test={`nav-menu-button-title-${formatDataTestTitle(title)}`}
        >
            <ListItemText
                disableTypography
                primary={title}
                secondary={
                    subtitle ? (
                        <Text
                            paragraph
                            variant={'caption'}
                            color={'GRAY'}
                            style={{ margin: 0, whiteSpace: 'break-spaces' }}
                        >
                            {subtitle}
                        </Text>
                    ) : undefined
                }
                data-test={'nav-menu-button'}
            />
            {externalLink && <CallMadeIcon style={{ fontSize: 16, marginTop: 4 }} />}
        </StyledMenuItem>
    );
};

export interface NavMenuSectionProps {
    title: string;
    titleLabel?: string;
    Icon: React.ComponentType<SvgIconProps>;
    menuButtons: NavMenuButtonProps[];
    footerText?: string;
    navigationAction: () => void;
    openExternalLinkAction?: (externalLink: string) => void;
}

export const NavMenuSection: React.VFC<NavMenuSectionProps> = props => {
    const { title, titleLabel, Icon, menuButtons, footerText, navigationAction, openExternalLinkAction } = props;

    return (
        <Grid data-test={'nav-section'} item xs style={{ padding: 8 }}>
            <Grid container direction={'row'} alignItems={'center'} style={{ margin: '-8px 0 8px 16px' }}>
                <Icon data-test={'nav-section-icon'} style={{ color: FlossPalette.BLACK, marginRight: 8 }} />
                <Text variant={'body2'} medium>
                    {title}
                </Text>
                {titleLabel && (
                    <div
                        style={{
                            background: FlossPalette.DARK_TAN,
                            borderRadius: 2,
                            padding: '0 4px',
                            marginLeft: 8,
                        }}
                    >
                        <Text
                            data-test={`nav-menu-section-title-label-${formatDataTestTitle(titleLabel)}`}
                            medium
                            variant={'caption'}
                            color={'GRAY'}
                            style={{ textTransform: 'uppercase' }}
                        >
                            {' '}
                            {titleLabel}{' '}
                        </Text>
                    </div>
                )}
            </Grid>
            {menuButtons.map(menuButton => (
                <NavMenuButton
                    key={menuButton.title}
                    {...menuButton}
                    openExternalLinkAction={openExternalLinkAction}
                    navigationAction={() => {
                        menuButton.navigationAction?.();
                        navigationAction();
                    }}
                />
            ))}
            {footerText && (
                <Grid container style={{ padding: '8px 16px' }}>
                    <Text variant={'body2'} color={'GRAY'}>
                        {footerText}
                    </Text>
                </Grid>
            )}
        </Grid>
    );
};

interface NavMenuProps {
    title: string;
    description: string;
    open: boolean;
    active?: boolean;
    menuButtons?: Omit<NavMenuButtonProps, 'navigationAction'>[];
    setOpen: (value: boolean) => void;
}

export const NavMenu: React.FC<NavMenuProps> = props => {
    const { title, description, open, active, menuButtons, setOpen } = props;
    const history = useHistory();

    const isActive =
        active ??
        !!menuButtons?.find(c => history.location.pathname.replaceAll('/', '') === c.screen?.replaceAll('/', ''));

    const buttonDataTest = () => {
        const isNavOpen = open ? 'nav-open' : 'nav-closed';
        return `nav-menu-top-level-button-${formatDataTestTitle(title)} ${isNavOpen}`;
    };

    const buttonStyle = () => {
        if (isActive) {
            return {
                backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
                color: FlossPalette.PRIMARY_FOREGROUND,
            };
        }
        if (open) {
            return {
                backgroundColor: FlossPalette.DARK_TAN,
                color: FlossPalette.DARK_GRAY,
            };
        }
        return undefined;
    };

    return (
        <TooltipMenu
            // This was added to solve a bug raised in ES-3746, where clicks in the menu wouldn't trigger as expected.
            // It turns out it's because the clicks inside the content of a tooltip trigger a "blur" event which then
            // triggers the onClose callback tooltip; unless disableFocusListener is set to `true`
            disableFocusListener
            disableTouchListener
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            title={
                <Grid container direction={'column'}>
                    <Grid item style={{ padding: '16px 24px', borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}` }}>
                        <Text variant={'caption'} color={'DARK_GRAY'}>
                            {description}
                        </Text>
                    </Grid>
                    <Grid item style={{ padding: !!menuButtons ? 8 : 0 }}>
                        {menuButtons?.map(menuButton => (
                            <NavMenuButton
                                key={menuButton.screen}
                                navigationAction={() => {
                                    setOpen(false);
                                }}
                                {...menuButton}
                            />
                        ))}
                        {props.children}
                    </Grid>
                </Grid>
            }
        >
            <Button
                variant={'nav'}
                data-test={buttonDataTest()}
                style={buttonStyle()}
                endIcon={open ? 'ChevronUp' : 'ChevronDown'}
                onClick={() => setOpen(!open)}
            >
                {title}
            </Button>
        </TooltipMenu>
    );
};

interface IconButtonNavMenuAction {
    icon: React.ReactNode;
    action: () => void;
}

interface IconButtonNavMenuProps {
    title: string;
    icon: React.ReactNode | string;
    navbarHeight: number;
    badgeCount?: number;
    iconAttachment?: React.ReactNode;
    customHeader?: boolean;
    customOnClickAction?: () => void;
    navigationAction?: IconButtonNavMenuAction;
    active?: boolean;
    open?: boolean;
    setOpen?: (value: boolean) => void;
    onOpenAction?: () => void;
    headerBorder?: boolean;
    critical?: boolean;
    clickAway?: boolean;
    disableBackdrop?: boolean;
    menuPlacement?: PopperPlacementType;
    analytics?: ButtonProps['analytics'];
}

interface IconButtonNavMenuBaseProps extends IconButtonNavMenuProps {
    showTooltip: boolean;
    setShowTooltip: (value: boolean) => void;
    handleTooltipClose: () => void;
}

const IconButtonNavMenuBase: React.FC<IconButtonNavMenuBaseProps> = props => {
    const {
        title,
        icon,
        badgeCount,
        iconAttachment,
        customHeader,
        active,
        showTooltip,
        setShowTooltip,
        handleTooltipClose,
        open,
        setOpen,
        navigationAction,
        onOpenAction,
        customOnClickAction,
        menuPlacement,
        analytics,
        headerBorder = true,
        critical = false,
    } = props;
    const buttonClasses = useButtonStyles();
    const controlled = open !== undefined && !!setOpen;
    const iconIsText = typeof icon === 'string';
    const iconAttachmentIsText = typeof iconAttachment === 'string';

    const handleTooltipOpen = () => {
        controlled ? setOpen(true) : setShowTooltip(true);
        customOnClickAction?.();
        onOpenAction?.();
    };

    return (
        <TooltipMenu
            disableHoverListener
            disableFocusListener
            disableTouchListener
            PopperProps={{
                disablePortal: true,
            }}
            open={controlled ? open : showTooltip}
            onClose={handleTooltipClose}
            placement={menuPlacement as unknown as TooltipProps['placement']}
            title={
                <Grid container direction={'column'}>
                    {!customHeader && (
                        <Grid
                            item
                            container
                            direction={'row'}
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '8px 8px 8px 16px',
                                borderBottom: headerBorder ? `1px solid ${FlossPalette.STROKE_LIGHT}` : 0,
                            }}
                        >
                            <Grid item>
                                <Text variant={'h5'}>{title}</Text>
                            </Grid>
                            <Grid item>
                                {navigationAction && (
                                    <IconButton
                                        onClick={() => {
                                            handleTooltipClose();
                                            navigationAction.action();
                                        }}
                                    >
                                        {navigationAction.icon}
                                    </IconButton>
                                )}
                                <IconButton onClick={handleTooltipClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>{props.children}</Grid>
                </Grid>
            }
        >
            <DandyNavMenuTooltip title={title}>
                <div>
                    <Button
                        onClick={showTooltip || open ? handleTooltipClose : handleTooltipOpen}
                        className={cx(
                            !iconAttachment ? buttonClasses.iconButton : buttonClasses.iconAttachmentButton,
                            (showTooltip || open || active) && buttonClasses.active,
                        )}
                        variant={'nav'}
                        analytics={analytics}
                        data-test={`nav-icon-button-base-${formatDataTestTitle(title)}`}
                    >
                        <Grid
                            container
                            direction={'row'}
                            alignItems={'center'}
                            justifyContent={'space-between'}
                            style={
                                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                                // eslint-disable-next-line no-nested-ternary
                                !!iconAttachment && !iconIsText && !iconAttachmentIsText
                                    ? { width: 76, padding: '0 2px 0 8px' }
                                    : iconIsText || iconAttachmentIsText
                                      ? {
                                            flexWrap: 'nowrap',
                                            cursor: 'pointer',
                                            paddingLeft: iconAttachmentIsText ? 8 : 0,
                                        }
                                      : undefined
                            }
                        >
                            {!!badgeCount && badgeCount > 0 && (
                                <Box
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        right: 0,
                                        zIndex: 4,
                                    }}
                                    data-test={'nav-icon-button-badge-count'}
                                >
                                    <TabWithCount count={badgeCount} active critical={critical} />
                                </Box>
                            )}
                            {iconIsText ? (
                                <Grid
                                    data-test={`nav-icon-is-text-${formatDataTestTitle(icon)}`}
                                    item
                                    style={{ paddingLeft: 12 }}
                                >
                                    {icon}
                                </Grid>
                            ) : (
                                icon
                            )}
                            {iconAttachmentIsText ? (
                                <Grid
                                    data-test={`nav-icon-is-text-${formatDataTestTitle(iconAttachment)}`}
                                    item
                                    style={{ padding: '0 8px' }}
                                >
                                    {iconAttachment}
                                </Grid>
                            ) : (
                                iconAttachment
                            )}
                        </Grid>
                    </Button>
                </div>
            </DandyNavMenuTooltip>
        </TooltipMenu>
    );
};

export const IconButtonNavMenu: React.FC<IconButtonNavMenuProps> = props => {
    const { open, setOpen, navbarHeight, disableBackdrop, clickAway = true } = props;
    const [showTooltip, setShowTooltip] = React.useState(false);
    const controlled = open !== undefined && !!setOpen;

    const handleTooltipClose = () => {
        controlled ? setOpen(false) : setShowTooltip(false);
    };

    return (
        <>
            {clickAway ? (
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <div>
                        <IconButtonNavMenuBase
                            {...props}
                            showTooltip={showTooltip}
                            setShowTooltip={setShowTooltip}
                            handleTooltipClose={handleTooltipClose}
                        />
                    </div>
                </ClickAwayListener>
            ) : (
                <IconButtonNavMenuBase
                    {...props}
                    showTooltip={showTooltip}
                    setShowTooltip={setShowTooltip}
                    handleTooltipClose={handleTooltipClose}
                />
            )}
            {!disableBackdrop && <DandyBackdrop open={controlled ? open : showTooltip} navbarHeight={navbarHeight} />}
        </>
    );
};
