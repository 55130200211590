import { Canvas } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

const DETAIL_KEY = -1 as const;

interface ModelViewerCanvasProps {
    // Enables SRGB color space transform. Set useSRGB to false for legacy scans. Set useSRGB to true for NewModelViewer to better match 3Shape scans colors
    useSRGB?: boolean;
    checkCanvasSize?: boolean;
    canvasRef?: React.MutableRefObject<HTMLCanvasElement | null>;
}

export const ModelViewerCanvas: React.FC<ModelViewerCanvasProps> = ({
    useSRGB = false,
    checkCanvasSize,
    canvasRef: canvasRefUpper,
    children,
}) => {
    const canvasRef = React.useRef<HTMLCanvasElement>(null);
    const containerRef = React.useRef<HTMLDivElement>(null);

    const currentCanvas = canvasRef.current;

    // This effect is a hacky solution to fix the bug described in EPDCAD-363, where sometimes the canvas element does
    // not fill up the entire container element.
    React.useEffect(() => {
        if (!(checkCanvasSize && currentCanvas && containerRef.current)) {
            return;
        }

        const listener = (event: UIEvent) => {
            // 'resize' events usually have an undefined 'detail' property, so we set the detail property to a sentinel
            // value to identify resize events that are dispatched from this effect, and ignore all other resize events.
            if (event.detail !== DETAIL_KEY) {
                return;
            }

            if (!hasSameSize(currentCanvas, containerRef.current)) {
                // We set a timeout to avoid dispatching too many resize events.
                setTimeout(() => {
                    dispatchEvent(new UIEvent('resize', { detail: DETAIL_KEY }));
                }, 50);
            }
        };
        addEventListener('resize', listener);

        // Kick off the loop of resize events. We need to add the above listener because it seems that sometimes the
        // first dispatched resize event does not correct the size of the canvas element.
        if (!hasSameSize(currentCanvas, containerRef.current)) {
            dispatchEvent(new UIEvent('resize', { detail: DETAIL_KEY }));
        }

        return () => removeEventListener('resize', listener);
    }, [checkCanvasSize, currentCanvas]);

    React.useEffect(() => {
        // Pass the ref to the canvas element up to the parent, if requested.
        if (canvasRefUpper) {
            canvasRefUpper.current = currentCanvas;
        }
        if (currentCanvas) {
            currentCanvas.setAttribute('data-testid', 'model-viewer-canvas-element');
        }
    }, [canvasRefUpper, currentCanvas]);

    return (
        <div
            ref={containerRef}
            aria-label={'Model Viewer Canvas Container'}
            style={{ height: '100%', width: '100%', overflow: 'hidden' }}
            data-test={'model-viewer-canvas'}
        >
            <Canvas
                ref={canvasRef}
                orthographic={true}
                linear={true}
                mode={'concurrent'}
                gl={{ preserveDrawingBuffer: true }}
                onCreated={({ gl }) => {
                    gl.toneMapping = THREE.NoToneMapping;
                    gl.outputEncoding = useSRGB ? THREE.sRGBEncoding : THREE.LinearEncoding;
                }}
            >
                <fog color={new THREE.Color(0x001e45)} near={100} far={1000} />
                {children}
            </Canvas>
        </div>
    );
};

function hasSameSize(lhs: Element | null, rhs: Element | null): boolean {
    return !!lhs && !!rhs && lhs.clientWidth === rhs.clientWidth && lhs.clientHeight === rhs.clientHeight;
}
