import { FixedTrivialType, U16, U32 } from '../Utility/Util';

// Reads DOSTIME from [u] into [into].
//
function readTime(uIn: number, into: Date = new Date()) {
    let u = uIn;
    into.setMilliseconds(0);
    u &= 0xffff;
    into.setSeconds(2 * (u & 31));
    u >>>= 5;
    into.setMinutes(u & 63);
    u >>>= 6;
    into.setHours(u & 63);
    return into;
}

// Reads DOSDATE from [u] into [into].
//
function readDate(uIn: number, into: Date = new Date()) {
    let u = uIn;
    u &= 0xffff;
    into.setDate(u & 31);
    u >>>= 5;
    into.setMonth((u & 15) - 1);
    u >>>= 4;
    into.setFullYear(1980 + u);
    return into;
}

// Writes the DOSTIME extracted from [from] into the resulting date object derived from current timestamp.
//
function writeTime(from: Date) {
    let u = from.getHours();
    u <<= 6;
    u |= from.getMinutes();
    u <<= 5;
    u |= Math.min(29, (from.getSeconds() / 2) | 0);
    return U16(u);
}

// Writes the DOSDATE extracted from [from] into the resulting date object derived from current timestamp.
//
function writeDate(from: Date) {
    let u = from.getFullYear() - 1980;
    u <<= 4;
    u |= 1 + from.getMonth();
    u <<= 5;
    u |= from.getDate();
    return U16(u);
}

// Trivial descriptors for DOSDATE, DOSTIME and [DOSTIME, DOSDATE].
//
class DosDateImpl extends FixedTrivialType<Date> {
    readonly Length = U16.Length;
    bytes(from: Date) {
        return U16.bytes(writeDate(from));
    }
    from(buf: Uint8Array) {
        return readDate(U16.from(buf));
    }
}
export const DosDate = new DosDateImpl();

class DosTimeImpl extends FixedTrivialType<Date> {
    readonly Length = U16.Length;
    bytes(from: Date) {
        return U16.bytes(writeTime(from));
    }
    from(buf: Uint8Array) {
        return readTime(U16.from(buf));
    }
}

export const DosTime = new DosTimeImpl();
class DosDateTimeImpl extends FixedTrivialType<Date> {
    readonly Length = U32.Length;
    bytes(from: Date) {
        return U32.bytes((writeDate(from) << 16) | writeTime(from));
    }
    from(buf: Uint8Array) {
        const u = U32.from(buf);
        return readDate(u >>> 16, readTime(u));
    }
}
export const DosDateTime = new DosDateTimeImpl();
