import { useGetOwnImpersonatedShortenedLinkQuery } from '@orthly/graphql-react';
import { ActionCard, LoadBlocker } from '@orthly/ui';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import * as qrCode from 'qrcode';
import React from 'react';
import { useAsyncCallback } from 'react-async-hook';

const useStyles = stylesFactory(() => ({
    qrCode: {
        height: 168,
        width: 168,
        boxShadow: '0 0 3px rgba(0,0,0,0.16)',
        borderRadius: '16px',
    },
}));

export interface ImpersonatedQRCodeLinkResult {
    qrData?: string;
    error?: Error;
    link?: string;
}

export function useImpersonatedQrCodeLink(baseUrl: string | null): ImpersonatedQRCodeLinkResult {
    const { data, error: queryError } = useGetOwnImpersonatedShortenedLinkQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        variables: {
            initial_url: baseUrl ?? '',
        },
        skip: !baseUrl,
    });

    const {
        execute: createQrCode,
        error: qrCodeError,
        result: qrData,
    } = useAsyncCallback<string, [string]>(qrCode.toDataURL);

    React.useEffect(
        () => {
            const shortenedLink = data?.getOwnImpersonatedShortenedLink?.shortUrl;
            if (shortenedLink) {
                console.log(`QR for URL: ${shortenedLink}`);

                void createQrCode(shortenedLink);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data?.getOwnImpersonatedShortenedLink],
    );

    return {
        qrData,
        link: data?.getOwnImpersonatedShortenedLink.fullUrl,
        error: queryError || qrCodeError,
    };
}

export const QRCode: React.VFC<{ qrData?: string; error?: Error; errorText: string }> = ({
    qrData,
    error,
    errorText,
}) => {
    const classes = useStyles();

    return (
        <>
            {error ? (
                <div style={{ paddingTop: 12 }}>
                    <ActionCard variant={'alert'} title={'Failed to load the QR code.'} subtitle={errorText} />
                </div>
            ) : (
                <LoadBlocker
                    blocking={!qrData}
                    ContainerProps={{
                        style: {
                            width: 168,
                            height: 168,
                            marginTop: 16,
                            borderRadius: 16,
                        },
                    }}
                    OverlayProps={{ style: { borderRadius: 16 } }}
                >
                    {qrData ? (
                        <img src={qrData} alt={'QR Code'} className={classes.qrCode} />
                    ) : (
                        <Grid className={classes.qrCode} />
                    )}
                </LoadBlocker>
            )}
        </>
    );
};
