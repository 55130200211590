import { getGuidedPresetQuestionTitle } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { RejectionNoteInput } from './RejectionNoteInput';
import { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import {
    Text,
    stylesFactory,
    useScreenIsMobileOrVerticalTablet,
    FormControlLabel,
    Grid,
    RadioPrimitive as Radio,
    RadioGroupPrimitive as RadioGroup,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    container: {
        padding: '0px 24px',
        marginBottom: 10,
    },
    radioGroup: {
        flexWrap: 'wrap',
        [theme.breakpoints.down('lg')]: {
            flexWrap: 'nowrap',
        },
    },
    radioButton: {
        borderRadius: '16px',
        paddingRight: '20px',
        marginLeft: 0,
        ...GUIDED_WAXUP_COMMON_STYLES,
    },
    presetRejectionInput: {
        marginTop: 24,
        [theme.breakpoints.down('lg')]: {
            padding: '0px 24px',
            width: '100%',
            marginBottom: 24,
        },
    },
    questionTitle: {
        marginTop: 24,
        marginBottom: 16,
        [theme.breakpoints.down('lg')]: {
            marginBottom: 8,
        },
    },
}));

export const GuidedPresetControls: React.VFC = () => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const { selectedTab } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const setPresetStatus = useGuidedWaxupAction('SET_PRESET_STATUS');
    const presetRejectionNotes = presets[selectedTab]?.notes;
    const presetQuestionTitle = getGuidedPresetQuestionTitle(selectedTab);

    return (
        <>
            <Grid item md={6} className={classes.container}>
                <Text variant={isMobile ? 'body2' : 'body1'} medium className={classes.questionTitle}>
                    {presetQuestionTitle}
                </Text>
                <RadioGroup
                    value={presets[selectedTab]?.status}
                    onChange={e => {
                        setPresetStatus({
                            presetName: selectedTab,
                            status: e.target.value as LabsGqlGuidedWaxupPresetStatus,
                        });
                    }}
                    row={true}
                    className={classes.radioGroup}
                >
                    <FormControlLabel
                        className={classes.radioButton}
                        value={LabsGqlGuidedWaxupPresetStatus.Approved}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} medium>
                                Accept
                            </Text>
                        }
                    />
                    <FormControlLabel
                        className={classes.radioButton}
                        value={LabsGqlGuidedWaxupPresetStatus.Rejected}
                        control={<Radio color={'secondary'} />}
                        label={
                            <Text variant={'body2'} medium>
                                Redesign
                            </Text>
                        }
                    />
                </RadioGroup>
            </Grid>
            {presets[selectedTab]?.status === LabsGqlGuidedWaxupPresetStatus.Rejected && (
                <Grid item md={6} className={classes.presetRejectionInput}>
                    <RejectionNoteInput rejectionNote={presetRejectionNotes} />
                </Grid>
            )}
        </>
    );
};
