import { XIcon, IconToggleButton, HelpIcon } from '@orthly/ui';
import { Text, FlossPalette, Card, Grid } from '@orthly/ui-primitives';
import constate from 'constate';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import React from 'react';
import { useHotkeys, HotkeysProvider } from 'react-hotkeys-hook';

type AnchorPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

const [HotKeyRegistryProvider, useHotKeyState] = constate(() => {
    const [registeredHotKeys, setRegisteredHotKeys] = React.useState<Record<string, Record<string, string>>>({});

    const registerHotKey = React.useCallback(
        ({ key, description, category }: { key: string; description: string; category: string }) => {
            setRegisteredHotKeys(registeredHotKeys => {
                const existingBinding = registeredHotKeys[category]?.[key];
                // report to sentry, but don't take down the entire page
                if (existingBinding) {
                    console.error(`HotKey already registered for: '${key}' (${existingBinding})`);
                }
                return {
                    ...registeredHotKeys,
                    [category]: {
                        ...registeredHotKeys[category],
                        [key]: description,
                    },
                };
            });
        },
        [setRegisteredHotKeys],
    );

    const deregisterHotKey = React.useCallback(
        ({ key, category }: { key: string; category: string }) => {
            setRegisteredHotKeys(registeredHotKeys => ({
                ...registeredHotKeys,
                [category]: omit(registeredHotKeys[category], [key]),
            }));
        },
        [setRegisteredHotKeys],
    );

    return { registerHotKey, deregisterHotKey, registeredHotKeys };
});

// If there are no scopes enabled, then the wildcard scope is enabled and thus all hotkeys are enabled. To avoid this
// behavior, we assign a hotkey the default scope, if a scope was not provided. The default scope should always be
// enabled, so we avoid the wildcard scope being enabled.
const DEFAULT_HOT_KEY_SCOPE = 'default' as const;
const HOT_KEY_SCOPES = [DEFAULT_HOT_KEY_SCOPE, 'tabs'] as const;
type HotKeyScope = (typeof HOT_KEY_SCOPES)[number];

export const HotKeyProvider: React.FC = ({ children }) => {
    return (
        <HotkeysProvider initiallyActiveScopes={[...HOT_KEY_SCOPES]}>
            <HotKeyRegistryProvider>{children}</HotKeyRegistryProvider>
        </HotkeysProvider>
    );
};

export function useRegisterHotKeys({
    key,
    description,
    category,
    action,
    disabled,
    scope,
}: {
    key: string;
    description: string;
    category: string;
    action: () => void;
    disabled?: boolean;
    scope?: HotKeyScope;
}) {
    const { registerHotKey, deregisterHotKey } = useHotKeyState();

    React.useEffect(() => {
        !disabled && registerHotKey?.({ key, description, category });
        return () => {
            !disabled && deregisterHotKey?.({ key, category });
        };
    }, [category, deregisterHotKey, description, key, registerHotKey, disabled]);

    useHotkeys(key, action, { enabled: !disabled, scopes: scope ?? DEFAULT_HOT_KEY_SCOPE }, [action]);
}

export const HotKeyCheatSheet: React.FC<{
    toggleSheetVisible(): void;
}> = ({ toggleSheetVisible }) => {
    const { registeredHotKeys } = useHotKeyState();
    return (
        <Card raised={true}>
            <Grid
                style={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '16px',
                    minWidth: '320px',
                }}
            >
                <Grid
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingBottom: '8px',
                    }}
                >
                    <Text variant={'body2'} style={{ fontWeight: 500 }}>
                        Keyboard Shortcuts
                    </Text>
                    <XIcon onClick={() => toggleSheetVisible()} style={{ color: 'gray', cursor: 'pointer' }} />
                </Grid>
                {Object.entries(registeredHotKeys).map(
                    ([group, keys]) =>
                        !isEmpty(keys) && (
                            <Grid key={group} style={{ padding: '2px 0' }}>
                                <Text variant={'body1'}>{group}</Text>
                                {Object.entries(keys).map(([key, description]) => (
                                    <Grid
                                        key={key}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            paddingTop: '4px',
                                        }}
                                    >
                                        {description}
                                        <Grid style={{ display: 'flex', flexDirection: 'row' }}>
                                            {key.split('+').map(keyPart => (
                                                <Grid
                                                    key={key + keyPart}
                                                    style={{
                                                        backgroundColor: FlossPalette.TAN,
                                                        padding: '0 4px',
                                                        margin: '0 4px',
                                                    }}
                                                >
                                                    <Text variant={'caption'} style={{ fontWeight: 400 }}>
                                                        {keyPart}
                                                    </Text>
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </Grid>
                                ))}
                                {group !== Object.keys(registeredHotKeys).pop() && (
                                    <Grid
                                        style={{
                                            backgroundColor: FlossPalette.GRAY,
                                            width: '100%',
                                            height: '1px',
                                            margin: '8px 0',
                                        }}
                                    />
                                )}
                            </Grid>
                        ),
                )}
            </Grid>
        </Card>
    );
};

export const HotKeyCheatSheetButton: React.FC<{ sheetAnchor: AnchorPosition; disableHotKeys?: boolean }> = ({
    sheetAnchor,
    disableHotKeys,
}) => {
    const [isCheatSheetVisible, setCheatSheetVisible] = React.useState(false);
    const toggleSheetVisible = React.useCallback(() => setCheatSheetVisible(visible => !visible), []);

    useRegisterHotKeys({
        key: 'S',
        description: 'Show/Hide Shortcuts',
        category: 'Shortcuts Panel',
        action: toggleSheetVisible,
        disabled: disableHotKeys ?? false,
    });

    return (
        <>
            <Grid
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    top: sheetAnchor.startsWith('top-') ? '16px' : 'auto',
                    bottom: sheetAnchor.startsWith('bottom-') ? '16px' : 'auto',
                    right: sheetAnchor.endsWith('-right') ? '16px' : 'auto',
                    left: sheetAnchor.endsWith('-left') ? '16px' : 'auto',
                }}
            >
                {isCheatSheetVisible ? (
                    <HotKeyCheatSheet toggleSheetVisible={toggleSheetVisible} />
                ) : (
                    <IconToggleButton active={false} onClick={toggleSheetVisible} tooltip={'Show Hot Keys'}>
                        <HelpIcon />
                    </IconToggleButton>
                )}
            </Grid>
        </>
    );
};
