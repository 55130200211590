import { ReasonCodeFaultOverrideButton } from '../components/ReasonCodeFaultOverrideButton';
import { useRefabFlowContext } from '../screens/refab-flow-context';
import { useRefabFlowSelector } from '../state/refab-flow-state';
import { ReasonCodeCategoryCells, ReasonCodeIndividualCheckbox } from './RefabItemReasonCodesComponents';
import type { LabsGqlReasonCodeOptionFragment } from '@orthly/graphql-operations';
import { LabsGqlReasonCodeGroup } from '@orthly/graphql-schema';
import { FlossPalette, Text, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    refabItemsContainer: {
        display: `flex`,
        flexDirection: `column`,
        marginLeft: 8,
        width: '100%',
        [theme.breakpoints.down('lg')]: {
            width: '95%',
        },
    },
    refabItems: {
        display: `flex`,
        flexFlow: `row`,
        alignItems: `center`,
        justifyContent: `flex-start`,
        gap: 4,
        background: FlossPalette.TAN,
        borderRadius: 4,
    },
}));

export const RefabReasonCodesContainer: React.VFC<{ reasonCodes: LabsGqlReasonCodeOptionFragment[] }> = ({
    reasonCodes,
}) => {
    const classes = useStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const fitGroupCodes = reasonCodes.filter(reasonCode => reasonCode.group === LabsGqlReasonCodeGroup.Fit);
    const fitGroupCategories = _.uniq(fitGroupCodes.map(reasonCode => reasonCode.category));
    const aestheticGroupCodes = reasonCodes.filter(rc => rc.group === LabsGqlReasonCodeGroup.Aesthetic);
    const aestheticGroupCategories = _.uniq(aestheticGroupCodes.map(reasonCode => reasonCode.category));
    const otherGroupCodes = reasonCodes.filter(reasonCode => reasonCode.group === LabsGqlReasonCodeGroup.Other);
    const { isOps } = useRefabFlowContext();
    const itemId = useRefabFlowSelector(s => s.screenItemId);

    return (
        <Grid container className={classes.refabItemsContainer}>
            <Grid container spacing={isMobile ? 1 : 4} justifyContent={'flex-start'}>
                {!!aestheticGroupCodes?.length && (
                    <Grid item xs={12} lg={3}>
                        <Text variant={isMobile ? 'body2' : `h6`} medium>
                            Product Aesthetic
                        </Text>
                        {aestheticGroupCategories.map(category => (
                            <ReasonCodeCategoryCells
                                key={category}
                                reasonCodes={aestheticGroupCodes}
                                category={category}
                            />
                        ))}
                    </Grid>
                )}
                {!!fitGroupCodes?.length && (
                    <Grid item xs={12} lg={3}>
                        <Text variant={isMobile ? 'body2' : `h6`} medium>
                            Product Fit
                        </Text>
                        {fitGroupCategories.map(category => (
                            <ReasonCodeCategoryCells key={category} reasonCodes={fitGroupCodes} category={category} />
                        ))}
                    </Grid>
                )}
                {!!otherGroupCodes?.length && (
                    <Grid item xs={12} lg={3}>
                        <Text variant={isMobile ? 'body2' : `h6`} medium>
                            Other
                        </Text>
                        {otherGroupCodes?.map(code => (
                            <ReasonCodeIndividualCheckbox key={`${itemId}-${code.id}`} reasonCode={code} />
                        ))}
                    </Grid>
                )}
                {isOps && (
                    <Grid item xs={12} lg={3}>
                        <ReasonCodeFaultOverrideButton />
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
};
