import { DesignComparisonContext } from '../DesignComparison';
import { useRegisterHotKeys } from '../HotKeyCheatSheet';
import { ModelAppearanceToolbar, createDefaultAppearanceSettings } from '../ModelAppearance';
import { clearCustomShader, applyCustomShader } from '../ModelAppearance/ModelAppearance.utils';
import type { ModelAppearance, PayloadModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { QCToolbar, QC_TOOLBAR_BUTTON_STYLE } from '../QCToolbar';
import { MarginEditToolbar } from '../QCToolbar/MarginEditToolbar';
import { Jaw } from './ModelViewerTypes';
import type { NewModelViewerProps } from './NewModelViewer.types';
import type { ToothPrepDataMap } from './PrepDesign.hooks';
import { useProximalContactsViewUi } from './ProximalContactsUI';
import { ShowPoiButtons } from './ShowPoiButtons';
import { SnapToPoiButton } from './SnapToPoiButton';
import type { UndercutShader } from './UndercutShader';
import { useUndercutShader } from './UndercutShader';
import { useZoomToVisible } from './utils3d';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { OrderAnalyticsContext, BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { ToothUtils } from '@orthly/items';
import type { ToothNumber } from '@orthly/items';
import { IconToggleButton, XYZArrowsIcon, ShowCrossSectionIcon } from '@orthly/ui';
import { Text, FlossPalette, makeStyles, createStyles, Icon } from '@orthly/ui-primitives';
import React from 'react';

export const useOverlayStyles = makeStyles(() =>
    createStyles({
        uiPanelsContainer: {
            position: 'absolute',
            top: '10px',
            left: '15px',
            zIndex: 5,
            '&>div': {
                marginBottom: '20px',
                marginRight: '20px',
            },
        },
        qcToolbarButton: QC_TOOLBAR_BUTTON_STYLE,
        qcToolbarWhite: {
            ...QC_TOOLBAR_BUTTON_STYLE,
            backgroundColor: 'inherit !important',
        },
    }),
);

// Utility hook that will track the `All - Portal - Design Tool Toggled` event,
// if the order id is tracked in the current context
function useDesignToolToggle(
    action: DandyAnalyticsEventSchemaType['All - Portal - Design Tool Toggled']['action'],
    currentState: boolean,
    setState: (val: boolean) => void,
) {
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const comparisonContext = React.useContext(DesignComparisonContext);
    const hasPast = !!comparisonContext?.hasPast;

    // If forceState is provided, we will use that value instead of toggling
    return React.useCallback(
        (usedKeyboardShortcut: boolean, forceState?: boolean): void => {
            const newValue = forceState !== undefined ? forceState : !currentState;
            setState(newValue);
            // No order ID -> we won't track
            if (!orderId) {
                return;
            }

            BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Tool Toggled', {
                usedKeyboardShortcut,
                action,
                isActive: newValue,
                hasPast,
                $groups: { order: orderId },
            });
        },
        [action, orderId, setState, currentState, hasPast],
    );
}

// Records the state of some of the design tools
function useDesignTools() {
    const [isScanAppearanceMenuOpen, setIsScanAppearanceMenuOpen] = React.useState(true);

    // Cross section
    const [isCrossSectionOpen, setIsCrossSectionOpen] = React.useState(false);
    const toggleCrossSection = useDesignToolToggle('cross_section', isCrossSectionOpen, setIsCrossSectionOpen);

    // Cross section, clipping plane
    const [isClippingPlaneOpen, setIsClippingPlaneOpen] = React.useState(false);
    const toggleClippingPlane = useDesignToolToggle('clipping_plane', isClippingPlaneOpen, setIsClippingPlaneOpen);

    // Cross section, reverse plane
    const [isReversePlaneOpen, setIsReversePlaneOpen] = React.useState(false);
    const toggleReversePlane = useDesignToolToggle('reverse_plane', isReversePlaneOpen, setIsReversePlaneOpen);

    return {
        clippingPlane: {
            open: isClippingPlaneOpen,
            toggle: toggleClippingPlane,
        },
        reversePlane: {
            open: isReversePlaneOpen,
            toggle: toggleReversePlane,
        },
        crossSection: {
            open: isCrossSectionOpen,
            toggle: toggleCrossSection,
        },
        scanAppearance: {
            open: isScanAppearanceMenuOpen,
            toggle: () => setIsScanAppearanceMenuOpen(state => !state),
        },
    };
}

function useSelectedInsertionAxis(
    insertionAxes: Map<ToothNumber, THREE.Vector3> | undefined,
    setAppearance: NewModelViewerProps['designQcConfig']['setAppearance'],
) {
    const [selectedInsertionAxis, setSelectedInsertionAxis] = React.useState<ToothNumber | undefined>(undefined);

    const undercutShader = useUndercutShader();

    React.useEffect(() => {
        undercutShader.uniforms.bboxMin.value.set(-100, -100, -100);
        undercutShader.uniforms.bboxMax.value.set(100, 100, 100);
    }, [undercutShader]);

    React.useEffect(() => {
        if (!insertionAxes?.size) {
            return;
        }

        setAppearance(current => {
            const cleared = {
                ...current,
                lowerJaw: current.lowerJaw.map(clearCustomShader),
                upperJaw: current.upperJaw.map(clearCustomShader),
                scans: current.scans.map(clearCustomShader),
            };
            if (!selectedInsertionAxis) {
                return cleared;
            }

            const jaw = ToothUtils.toothIsUpper(selectedInsertionAxis) ? Jaw.Upper : Jaw.Lower;
            return applyUndercutShader(cleared, jaw, undercutShader);
        });

        const direction = selectedInsertionAxis ? insertionAxes.get(selectedInsertionAxis) : undefined;
        if (direction) {
            undercutShader.uniforms.insertionAxis.value.copy(direction).negate();
        }
    }, [insertionAxes, selectedInsertionAxis, setAppearance, undercutShader]);

    return { selectedInsertionAxis, setSelectedInsertionAxis };
}

function applyUndercutShader(current: ModelAppearance, jaw: Jaw, shader: UndercutShader): ModelAppearance {
    const jawKey = jaw === 'Upper' ? 'upperJaw' : 'lowerJaw';

    // We prefer to apply the shader to either the `upperJaw` or `lowerJaw` models, if they exist, but fall back to
    // applying the shader to the prep scan if they don't.
    return current[jawKey].length
        ? {
              ...current,
              [jawKey]: current[jawKey].map(el => applyCustomShader(el, shader)),
          }
        : {
              ...current,
              scans: current.scans.map(el => {
                  return applyUndercutShaderIfPrepScan(el, jaw, shader);
              }),
          };
}

function applyUndercutShaderIfPrepScan(
    scan: PayloadModelAppearance,
    jaw: Jaw,
    shader: UndercutShader,
): PayloadModelAppearance {
    if (scan.payloadModel.jaw === jaw && scan.payloadModel.name === 'Raw Preparation scan') {
        return applyCustomShader(scan, shader);
    }
    return scan;
}

// This hook handles managing all the state and components needed for the overlay.
// eslint-disable-next-line max-lines-per-function
export function useNewModelViewerToolsOverlay(
    props: NewModelViewerProps,
    dynamicHeatmaps: boolean,
    prepData: ToothPrepDataMap,
) {
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const comparisonContext = React.useContext(DesignComparisonContext);
    const hasPast = !!comparisonContext?.hasPast;

    const {
        hideUi,
        designQcConfig: { appearance, setAppearance, controlRef },
        disableHotKeys,
        enable_qc_tools,
        undercutApp,
        showOrderScans,
        useProxyModelsForHeatmaps = true,
        model_payload_items,
        marginLineEditing,
        setDesiredPreviousDesignFiles,
        showBasicHeatmaps = true,
        insertionAxes,
        enablePreferredScansIfNoDesigns,
        showWireframes,
        setShowWireframes,
        enableWireframes,
    } = props;

    const styles = useOverlayStyles();
    const { ProximalContactsSubmenuToggleButton, ProximalContactsSubmenu } = useProximalContactsViewUi(props);

    const state = useDesignTools();
    const { crossSection, scanAppearance } = state;
    const { selectedInsertionAxis, setSelectedInsertionAxis } = useSelectedInsertionAxis(insertionAxes, setAppearance);

    const resetCameraView = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            controlRef.current?.reset?.();

            if (orderId) {
                BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Tool Toggled', {
                    usedKeyboardShortcut,
                    action: 'center_and_reset_zoom',
                    isActive: true,
                    hasPast,
                    $groups: { order: orderId },
                });
            }
        },
        [controlRef, orderId, hasPast],
    );

    const zoomToVisible = useZoomToVisible(controlRef, appearance);
    const toggleCrossSection = crossSection.toggle;
    const resetDefaults = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            setAppearance(
                createDefaultAppearanceSettings(model_payload_items, {
                    showOrderScans,
                    useProxyModelsForHeatmaps,
                    enablePreferredScansIfNoDesigns,
                }),
            );
            resetCameraView(usedKeyboardShortcut);
            toggleCrossSection(usedKeyboardShortcut, false);

            if (orderId) {
                BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Tool Toggled', {
                    usedKeyboardShortcut,
                    action: 'restore_default_view',
                    isActive: true,
                    hasPast,
                    $groups: { order: orderId },
                });
            }
        },
        [
            setAppearance,
            model_payload_items,
            resetCameraView,
            toggleCrossSection,
            showOrderScans,
            useProxyModelsForHeatmaps,
            orderId,
            hasPast,
            enablePreferredScansIfNoDesigns,
        ],
    );

    // Hot keys!

    useRegisterHotKeys({
        key: 'X',
        description: 'Cross Section',
        category: 'Tools',
        action: () => crossSection.toggle(true),
        disabled: props.disableHotKeys || !props.enable_qc_tools.enableCrossSections,
    });

    useRegisterHotKeys({
        key: 'Z',
        description: 'Center and Reset Zoom',
        category: 'View',
        action: () => resetCameraView(true),
        disabled: disableHotKeys,
    });

    useRegisterHotKeys({
        key: 'D',
        description: 'Restore Default View',
        category: 'View',
        action: () => resetDefaults(true),
        disabled: disableHotKeys,
    });

    return {
        state,
        OverlayTools: (
            <>
                {!hideUi && (
                    <ModelAppearanceToolbar
                        appearanceSettings={appearance}
                        onAppearanceChange={setAppearance}
                        showOrderScans={showOrderScans}
                        onCollapseChange={() => scanAppearance.toggle()}
                        isExpanded={scanAppearance.open}
                        enableSelectForEditing={enable_qc_tools.enableSelectForEditing ?? false}
                    />
                )}
                <QCToolbar
                    appearanceSettings={appearance}
                    onAppearanceChange={setAppearance}
                    enableQcTools={enable_qc_tools}
                    dynamicHeatmaps={dynamicHeatmaps}
                    topChildren={enable_qc_tools.externalTools}
                    disableHotkeys={disableHotKeys}
                    ProximalContactsSubmenu={ProximalContactsSubmenu ?? undefined}
                    setDesiredPreviousDesignFiles={setDesiredPreviousDesignFiles}
                    showBasicHeatmaps={showBasicHeatmaps}
                >
                    {enable_qc_tools.enableCrossSections && (
                        <IconToggleButton
                            tooltip={crossSection.open ? 'Hide Cross Section' : 'Show Cross Section'}
                            active={crossSection.open}
                            onClick={() => crossSection.toggle(false)}
                            className={styles.qcToolbarButton}
                        >
                            <ShowCrossSectionIcon />
                        </IconToggleButton>
                    )}

                    <IconToggleButton
                        tooltip={'Restore Camera View'}
                        active={false}
                        onClick={() => resetCameraView(false)}
                        className={styles.qcToolbarWhite}
                    >
                        <XYZArrowsIcon htmlColor={FlossPalette.GRAY} />
                    </IconToggleButton>

                    <IconToggleButton
                        tooltip={'Zoom Visible'}
                        active={false}
                        onClick={zoomToVisible}
                        className={styles.qcToolbarWhite}
                        key={'1_ZoomButton'}
                        data-test={'zoom-visible-button'}
                    >
                        <Icon icon={'CenterFocusStrong'} />
                    </IconToggleButton>

                    {undercutApp && (
                        <>
                            <IconToggleButton
                                tooltip={'Activate Insertion Tool'}
                                active={undercutApp.undercutActive}
                                onClick={undercutApp.toggleUndercutActive}
                                className={styles.qcToolbarWhite}
                            >
                                <Text variant={'body1'}> U </Text>
                            </IconToggleButton>
                            {undercutApp.undercutActive && (
                                <IconToggleButton
                                    tooltip={'Capture View as Insertion Direction'}
                                    active={false}
                                    onClick={undercutApp.captureViewDirection}
                                    className={styles.qcToolbarWhite}
                                >
                                    <Icon icon={'ArrowDownIcon'} htmlColor={FlossPalette.GRAY} />
                                </IconToggleButton>
                            )}
                        </>
                    )}

                    {!hideUi && ProximalContactsSubmenuToggleButton}
                    <SnapToPoiButton
                        controlRef={controlRef}
                        payloadItems={model_payload_items}
                        prepData={prepData}
                        className={styles.qcToolbarWhite}
                    />
                    <ShowPoiButtons
                        selectedInsertionAxis={selectedInsertionAxis}
                        setSelectedInsertionAxis={setSelectedInsertionAxis}
                        insertionAxes={insertionAxes}
                        modelPayloadItems={model_payload_items}
                        className={styles.qcToolbarButton}
                    />
                    {enableWireframes && (
                        <IconToggleButton
                            tooltip={'Show Wireframe'}
                            active={showWireframes ?? false}
                            onClick={() => setShowWireframes?.(x => !x)}
                            className={styles.qcToolbarWhite}
                        >
                            <Text variant={'body1'}> W </Text>
                        </IconToggleButton>
                    )}
                    {enable_qc_tools.externalToolsLower}
                </QCToolbar>
                {marginLineEditing && <MarginEditToolbar marginEditingApp={marginLineEditing} />}
            </>
        ),
        selectedInsertionAxis,
    };
}
