import { useCurrentProfileIsDoctor, useCurrentSessionIsAdmin } from '../../../../redux/selectors';
import { PracticeSettingsBase } from '../../components/PracticeSettingsBase';
import { CreateNewEmployee } from '../doctors/CreateNewEmployee';
import { EmployeePreferencesTable } from './EmployeePreferencesTable';
import { AddEditStaff as EditStaff } from './add-edit-staff/useStaffForm';
import { StaffRoutes } from './staff-paths';
import type { StaffUser } from './useFetchPracticeStaffUsers.graphql';
import { useFetchPracticeStaffUsers } from './useFetchPracticeStaffUsers.graphql';
import { DEFAULT_TOAST_TIMEOUT, Toast } from '@orthly/dentin';
import { CheckIcon, Button } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const PracticeEmployees: React.FC = () => {
    const history = useHistory();
    const { staffUsers, refetch: refetchStaffUsers, loading: staffUsersLoading } = useFetchPracticeStaffUsers();
    const isAdmin = useCurrentSessionIsAdmin();
    const isDoctor = useCurrentProfileIsDoctor();

    const [showStaffMemberDetail, setShowStaffMemberDetail] = React.useState(false);
    const [selectedStaffUser, setSelectedStaffUser] = React.useState<StaffUser | undefined>();
    const [firstName, setFirstName] = React.useState('');
    const [toastOpen, setToastOpen] = React.useState(false);

    const toggleShowStaffMemberWithStaffMember = async (staffUser: StaffUser | undefined) => {
        setSelectedStaffUser(staffUser);
        setShowStaffMemberDetail(!showStaffMemberDetail);
        void refetchStaffUsers();
    };

    const refetch = async () => {
        void refetchStaffUsers();
    };

    const onInviteAction = (firstName: string) => {
        setFirstName(firstName);
        setToastOpen(true);
    };

    const onBackAction = () => toggleShowStaffMemberWithStaffMember(undefined);

    return (
        <PracticeSettingsBase
            title={
                showStaffMemberDetail ? (
                    <Button
                        variant={'ghost'}
                        onClick={onBackAction}
                        startIcon={'ChevronLeft'}
                        style={{ marginLeft: -16 }}
                    >
                        Back to staff
                    </Button>
                ) : (
                    'Staff'
                )
            }
            titleHasBackButton={!showStaffMemberDetail}
            titleAction={
                showStaffMemberDetail || (!isAdmin && !isDoctor) ? undefined : (
                    <CreateNewEmployee
                        text={'Add new staff member'}
                        createAction={() => {
                            history.push(StaffRoutes.add_staff);
                        }}
                    />
                )
            }
            Content={
                showStaffMemberDetail ? (
                    <EditStaff staffUser={selectedStaffUser} onBackAction={onBackAction} />
                ) : (
                    <>
                        <EmployeePreferencesTable
                            staffUsers={staffUsers}
                            loading={staffUsersLoading}
                            refetch={refetch}
                            selectStaffMember={staff => toggleShowStaffMemberWithStaffMember(staff)}
                            onInviteAction={onInviteAction}
                        />
                        <Toast
                            headline={`${firstName} has been invited to your practice`}
                            description={`${firstName} will have to confirm the invitation to get access to your practice portal.`}
                            open={toastOpen}
                            onDismiss={() => setToastOpen(false)}
                            icon={CheckIcon}
                            variant={'info'}
                            timeout={DEFAULT_TOAST_TIMEOUT}
                        />
                    </>
                )
            }
        />
    );
};
