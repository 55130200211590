import { GroupAppearanceController } from './GroupAppearanceController';
import { ModelAppearanceController } from './ModelAppearanceController';
import type { DisabledControls, ModelAppearance, PayloadModelAppearance } from './ModelAppearanceTypes';
import { getAllDisabledControls, RestorativeView } from './ModelAppearanceTypes';
import { pastRestorativeAppearanceSetter } from './appearanceSetters';
import React from 'react';

type PastRestorativesAppearanceControllerProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
};

/**
 * Controls the appearance of past restorative models (i.e. previous designs).
 *
 * @component
 */
export const PastRestorativesAppearanceController: React.FC<PastRestorativesAppearanceControllerProps> = ({
    appearanceSettings,
    onAppearanceChange,
}) => {
    const restorativeView = appearanceSettings.restorativeView;
    const items = appearanceSettings.pastRestoratives[restorativeView];

    const disabledControls: DisabledControls = {
        ...getAllDisabledControls(),
        transparency: restorativeView === RestorativeView.HeatMap,
        solo: false,
    };

    const itemElements = items.map<JSX.Element>((payloadModelAppearance, index) => {
        const setAppearance = pastRestorativeAppearanceSetter(onAppearanceChange, index);
        const handleSoloChange = (isSolo: boolean) => {
            onAppearanceChange(current => ({
                ...current,
                solo: isSolo ? [payloadModelAppearance.payloadModel] : [],
            }));
        };

        const isSolo = appearanceSettings.solo?.includes(payloadModelAppearance.payloadModel);

        return (
            <ModelAppearanceController
                key={`${index}`}
                solo={isSolo}
                onSoloChange={handleSoloChange}
                text={payloadModelAppearance.payloadModel.name}
                disabledControls={disabledControls}
                setAppearance={setAppearance}
                appearance={payloadModelAppearance.appearance}
                isPast={true}
            />
        );
    });

    // the group is solo'd if everything in the group is solo'd
    const isGroupSolo = items.length > 0 && items.every(entry => appearanceSettings.solo?.includes(entry.payloadModel));

    const handleGroupSoloChange = React.useCallback(
        isSolo => {
            onAppearanceChange(current => ({
                ...current,
                solo: isSolo ? items.map(entry => entry.payloadModel) : [],
            }));
        },
        [onAppearanceChange, items],
    );

    const handleGroupAppearanceChange = React.useCallback(
        (childrenSettings: PayloadModelAppearance[]) => {
            onAppearanceChange(state => {
                return {
                    ...state,
                    pastRestoratives: {
                        ...state.pastRestoratives,
                        [state.restorativeView]: childrenSettings,
                    },
                };
            });
        },
        [onAppearanceChange],
    );

    // If there are no past restoratives, don't display anything.
    return items.length ? (
        <GroupAppearanceController
            text={'Past Designs'}
            disabledControls={disabledControls}
            solo={isGroupSolo}
            onSoloChange={handleGroupSoloChange}
            appearanceArray={items}
            onAppearanceChange={handleGroupAppearanceChange}
            isPast={true}
        >
            {itemElements}
        </GroupAppearanceController>
    ) : null;
};
