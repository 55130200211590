/* eslint-disable max-lines, max-lines-per-function */
import { useCommonStyles } from '../../hooks';
import type { QcHeatmapRange } from '../ColorRamp';
import { QCColorLegend, QcHeatmapOptions } from '../ColorRamp';
import { LoadPreviousDesignButton } from '../DesignComparison';
import { useRegisterHotKeys } from '../HotKeyCheatSheet';
import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { RestorativeView } from '../ModelAppearance/ModelAppearanceTypes';
import { getToggledOpacity } from '../ModelAppearance/Transparent.util';
import { unifyRestorativesView } from '../PortalDesignEditor/SculptingApp.utils';
import { AnatomyLayerSelectionBar } from './AnatomyLayerSelectionBar';
import { useQcToolbarStyles } from './QCToolbar.hooks';
import { HeatMapTypeToActionTypeMap } from './QCToolbar.utils';
import { useQcToolbarTrackToggle } from './QcToolbarTrackToogle.hooks';
import { OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import { HeatMapType } from '@orthly/forceps';
import {
    CollisionsIcon,
    CollisionsUndercutIcon,
    IconToggleButton,
    LayersIcon,
    ModelDoctorMarginIcon,
    ModelMarginIcon,
    OcclusionMapIcon,
    ProximalMapIcon,
    ProximalUndercutMapIcon,
    QCHeatmapIcon,
    ThicknessMapIcon,
    TissuePressureIcon,
    ToothMarkingsIcon,
} from '@orthly/ui';
import { Card, FlossPalette, Grid, Menu, styled, Text, useScreenIsLgOrUp } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';

type HeatMapSelectionBarProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    enableSculptReviewHeatmaps: boolean;
    enableUndercutHeatmap: boolean;
    enableTissuePressureHeatmap: boolean;
    dynamicHeatmaps: boolean;
    enableProximalHeatmap?: boolean;
    enableGuidedWaxupToolbar?: boolean;
    showBasicHeatmaps?: boolean;
    anchorEl?: HTMLElement | null;
    onClose?: () => void;
};

const ShowHeatmapText: React.VFC<{ heatmapType: string }> = ({ heatmapType }) => {
    return (
        <Text variant={'caption'} color={'PRIMARY_FOREGROUND'} medium style={{ marginLeft: 8 }}>
            {`Show ${heatmapType} Heatmap`}
        </Text>
    );
};

interface WrapperProps {
    enableGuidedWaxupToolbar: boolean | undefined;
    onClose?: () => void;
    anchorEl?: HTMLElement | null;
}

const DesktopMenuWrapper: React.FC<Omit<WrapperProps, 'enableGuidedWaxupToolbar'>> = ({
    anchorEl,
    onClose,
    children,
}) => {
    return (
        <Menu
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={onClose}
            sx={{ '& .MuiMenu-list': { padding: '0 !important' } }}
        >
            {children}
        </Menu>
    );
};

const DesktopCardWrapper: React.FC = ({ children }) => {
    const styles = useQcToolbarStyles();
    return (
        <Card raised={true} className={styles.panel} style={{ width: 'auto' }}>
            {children}
        </Card>
    );
};

const StyleCard = styled(Card)({
    marginTop: 16,
    paddingTop: 8,
    paddingBottom: 8,
});

const MobileCardWrapper: React.FC = ({ children }) => {
    return (
        <StyleCard variant={'outlined'} style={{ alignContent: 'flex-start', padding: 0 }}>
            {children}
        </StyleCard>
    );
};

const DesktopWrapperEl: React.FC<WrapperProps> = ({ enableGuidedWaxupToolbar, anchorEl, onClose, children }) =>
    enableGuidedWaxupToolbar ? (
        <DesktopMenuWrapper onClose={onClose} anchorEl={anchorEl}>
            {children}
        </DesktopMenuWrapper>
    ) : (
        <DesktopCardWrapper>{children}</DesktopCardWrapper>
    );

const WrapperEl: React.FC<WrapperProps> = ({ enableGuidedWaxupToolbar, children, onClose, anchorEl }) => {
    const isScreenLgOrUp = useScreenIsLgOrUp();
    const mobileGuidedWaxups = enableGuidedWaxupToolbar && !isScreenLgOrUp;
    return mobileGuidedWaxups ? (
        <MobileCardWrapper>{children}</MobileCardWrapper>
    ) : (
        <DesktopWrapperEl enableGuidedWaxupToolbar={enableGuidedWaxupToolbar} onClose={onClose} anchorEl={anchorEl}>
            {children}
        </DesktopWrapperEl>
    );
};

/**
 * This sub-toolbar selects between multiple restorative heatmap views.
 *
 * @component
 */
export const HeatMapSelectionBar: React.FC<HeatMapSelectionBarProps> = ({
    appearanceSettings,
    onAppearanceChange,
    showBasicHeatmaps,
    enableSculptReviewHeatmaps,
    enableUndercutHeatmap,
    enableTissuePressureHeatmap,
    enableProximalHeatmap = true,
    enableGuidedWaxupToolbar,
    anchorEl,
    onClose,
    dynamicHeatmaps,
}) => {
    const styles = useQcToolbarStyles();
    const trackToggle = useQcToolbarTrackToggle();

    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const activeHeatMap =
        appearanceSettings.restorativeView === RestorativeView.HeatMap ? appearanceSettings.activeHeatMap : undefined;

    const hasPastRestoratives = !!appearanceSettings.pastRestoratives.CAD.length;

    const setOcclusionHeatmap = React.useCallback(() => {
        trackToggle(HeatMapTypeToActionTypeMap[HeatMapType.Occlusal], false, true);
        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.Occlusal,
            restorativeView: RestorativeView.HeatMap,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.Occlusal].defaultRange : undefined,
        }));
        onClose?.();
    }, [onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    const setProximalHeatmap = React.useCallback(() => {
        trackToggle(HeatMapTypeToActionTypeMap[HeatMapType.Proximal], false, true);

        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.Proximal,
            restorativeView: RestorativeView.HeatMap,
            showCurtainsHeatmap: false,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.Proximal].defaultRange : undefined,
        }));
        onClose?.();
    }, [onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    const showCurtainsHeatmap = appearanceSettings.showCurtainsHeatmap;
    const setProximalCurtainHeatmap = React.useCallback(() => {
        trackToggle('heatmap_proximal_curtain', false, true);

        unifyRestorativesView(orderId, appearanceSettings, onAppearanceChange);

        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.Proximal,
            restorativeView: RestorativeView.HeatMap,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.Proximal].defaultRange : undefined,
            showCurtainsHeatmap: true,
        }));
        onClose?.();
    }, [orderId, appearanceSettings, onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    const setThicknessHeatmap = React.useCallback(() => {
        trackToggle(HeatMapTypeToActionTypeMap[HeatMapType.Thickness], false, true);
        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.Thickness,
            restorativeView: RestorativeView.HeatMap,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.Thickness].defaultRange : undefined,
        }));
        onClose?.();
    }, [onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    const setTissuePressureHeatmap = React.useCallback(() => {
        trackToggle(HeatMapTypeToActionTypeMap[HeatMapType.TissuePressure], false, true);

        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.TissuePressure,
            restorativeView: RestorativeView.HeatMap,
            showCurtainsHeatmap: false,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.TissuePressure].defaultRange : undefined,
        }));
        onClose?.();
    }, [onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    const setVertexDisplacementHeatmap = React.useCallback(() => {
        trackToggle(HeatMapTypeToActionTypeMap[HeatMapType.VertexDisplacement], false, true);

        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.VertexDisplacement,
            restorativeView: RestorativeView.HeatMap,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.VertexDisplacement].defaultRange : undefined,
        }));
        onClose?.();
    }, [onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    const setSurfaceDisplacementHeatmap = React.useCallback(() => {
        trackToggle(HeatMapTypeToActionTypeMap[HeatMapType.SurfaceDisplacement], false, true);

        onAppearanceChange(current => ({
            ...current,
            activeHeatMap: HeatMapType.SurfaceDisplacement,
            restorativeView: RestorativeView.HeatMap,
            heatMapRange: dynamicHeatmaps ? QcHeatmapOptions[HeatMapType.SurfaceDisplacement].defaultRange : undefined,
        }));
        onClose?.();
    }, [onAppearanceChange, trackToggle, onClose, dynamicHeatmaps]);

    return (
        <WrapperEl enableGuidedWaxupToolbar={enableGuidedWaxupToolbar} onClose={onClose} anchorEl={anchorEl}>
            <div className={styles.flexColumn} aria-label={'Heatmap Tools'}>
                <IconToggleButton
                    tooltip={enableGuidedWaxupToolbar ? '' : 'Show Occlusal Heatmap'}
                    className={styles.iconButton}
                    active={activeHeatMap === HeatMapType.Occlusal}
                    onClick={setOcclusionHeatmap}
                >
                    <OcclusionMapIcon
                        style={{
                            color: enableGuidedWaxupToolbar ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                        }}
                    />
                    {enableGuidedWaxupToolbar && <ShowHeatmapText heatmapType={'Occlusal'} />}
                </IconToggleButton>

                {showBasicHeatmaps && (
                    <>
                        {enableProximalHeatmap && (
                            <IconToggleButton
                                tooltip={enableGuidedWaxupToolbar ? '' : 'Show Proximal Heatmap'}
                                className={styles.iconButton}
                                active={activeHeatMap === HeatMapType.Proximal && !showCurtainsHeatmap}
                                onClick={setProximalHeatmap}
                            >
                                <ProximalMapIcon
                                    style={{
                                        color: enableGuidedWaxupToolbar
                                            ? FlossPalette.PRIMARY_FOREGROUND
                                            : FlossPalette.GRAY,
                                    }}
                                />
                                {enableGuidedWaxupToolbar && <ShowHeatmapText heatmapType={'Proximal'} />}
                            </IconToggleButton>
                        )}

                        {enableUndercutHeatmap && (
                            <IconToggleButton
                                tooltip={'Show Proximal Heatmap With Undercuts'}
                                className={styles.iconButton}
                                active={activeHeatMap === HeatMapType.Proximal && showCurtainsHeatmap}
                                onClick={setProximalCurtainHeatmap}
                            >
                                <ProximalUndercutMapIcon />
                            </IconToggleButton>
                        )}

                        <IconToggleButton
                            tooltip={enableGuidedWaxupToolbar ? '' : 'Show Thickness Heatmap'}
                            className={styles.iconButton}
                            active={activeHeatMap === HeatMapType.Thickness}
                            onClick={setThicknessHeatmap}
                            data-test={'thickness-heatmap-button'}
                        >
                            <ThicknessMapIcon
                                style={{
                                    color: enableGuidedWaxupToolbar
                                        ? FlossPalette.PRIMARY_FOREGROUND
                                        : FlossPalette.GRAY,
                                }}
                            />
                            {enableGuidedWaxupToolbar && <ShowHeatmapText heatmapType={'Thickness'} />}
                        </IconToggleButton>
                    </>
                )}
                {enableTissuePressureHeatmap && (
                    <IconToggleButton
                        tooltip={enableGuidedWaxupToolbar ? '' : 'Show Tissue Pressure Heatmap'}
                        className={styles.iconButton}
                        active={activeHeatMap === HeatMapType.TissuePressure && !showCurtainsHeatmap}
                        onClick={setTissuePressureHeatmap}
                    >
                        <TissuePressureIcon
                            style={{
                                color: enableGuidedWaxupToolbar ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.GRAY,
                            }}
                        />
                        {enableGuidedWaxupToolbar && <ShowHeatmapText heatmapType={'Proximal'} />}
                    </IconToggleButton>
                )}

                {enableSculptReviewHeatmaps && (
                    <>
                        <IconToggleButton
                            tooltip={'Show Modified Regions'}
                            className={styles.iconButton}
                            active={activeHeatMap === HeatMapType.VertexDisplacement}
                            onClick={setVertexDisplacementHeatmap}
                        >
                            <Text variant={'body1'}> M </Text>
                        </IconToggleButton>

                        <IconToggleButton
                            tooltip={'Show Surface Displacement Heatmap'}
                            className={styles.iconButton}
                            active={activeHeatMap === HeatMapType.SurfaceDisplacement}
                            onClick={setSurfaceDisplacementHeatmap}
                        >
                            <Text variant={'body1'}> S </Text>
                        </IconToggleButton>
                    </>
                )}

                {hasPastRestoratives && (
                    <IconToggleButton
                        tooltip={'Show Changes Heatmap'}
                        className={styles.iconButton}
                        active={activeHeatMap === HeatMapType.SurfaceDisplacement}
                        onClick={setSurfaceDisplacementHeatmap}
                    >
                        <Text variant={'body1'}> C </Text>
                    </IconToggleButton>
                )}
            </div>
        </WrapperEl>
    );
};

export interface EnableQcTools {
    enableAnatomyLayers: boolean;
    enableCollisions: boolean;
    enableHeatmaps: boolean;
    enableUndercutHeatmap: boolean;
    enableTissuePressureHeatmap: boolean;
    enableMarginLines: boolean;
    enableDoctorMarginLines: boolean;
    enableDoctorToothMarkings: boolean;
    editMarginLines: boolean;
    enableUndercutView: boolean;
    enableSelectForEditing?: boolean;
    enableSculptReviewHeatmaps?: boolean;
}

// functions to pipe out to parent if needed
interface QcToolbarFns {
    toggleRestorativesTransparent: (usedKeyboardShortcut: boolean) => void;
    toggleAnatomyActive: (usedKeyboardShortcut: boolean) => void;
    toggleMarginLines: (usedKeyboardShortcut: boolean) => void;
    toggleCollisionLines: (usedKeyboardShortcut: boolean) => void;
    toggleHeatmapActive: (usedKeyboardShortcut: boolean) => void;
    toggleHeatmapWithHotKey: (heatmap: HeatMapType) => void;
    toggleDoctorMarginLines: (usedKeyboardShortcut: boolean) => void;
    toggleDoctorToothMarkings: (usedKeyboardShortcut: boolean) => void;
}

type QCToolbarProps = {
    appearanceSettings: ModelAppearance;
    topChildren?: React.ReactNode;
    children?: React.ReactNode;
    dynamicHeatmaps: boolean;
    enableQcTools: EnableQcTools;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    disableHotkeys?: boolean;
    fnCallbacksRef?: React.MutableRefObject<QcToolbarFns>;
    ProximalContactsSubmenu?: JSX.Element;
    setDesiredPreviousDesignFiles?: React.Dispatch<React.SetStateAction<string[]>>;
    showBasicHeatmaps?: boolean;
};

/**
 * This toolbar selects between major view elements and mode.
 *
 * @component
 */
export const QCToolbar: React.FC<QCToolbarProps> = props => {
    const {
        appearanceSettings,
        dynamicHeatmaps,
        enableQcTools,
        onAppearanceChange,
        topChildren,
        disableHotkeys,
        children,
        fnCallbacksRef,
        ProximalContactsSubmenu,
        setDesiredPreviousDesignFiles,
        showBasicHeatmaps,
    } = props;

    const {
        enableAnatomyLayers,
        enableCollisions,
        enableHeatmaps,
        enableUndercutHeatmap,
        enableTissuePressureHeatmap,
        enableMarginLines,
        enableDoctorMarginLines,
        enableDoctorToothMarkings,
        enableSculptReviewHeatmaps,
    } = enableQcTools;

    const commonStyles = useCommonStyles();
    const styles = useQcToolbarStyles();
    const trackToggle = useQcToolbarTrackToggle();

    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    // restorative heat maps
    const heatmapActive = appearanceSettings.restorativeView === RestorativeView.HeatMap;
    const toggleHeatmapActive = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('heatmap', usedKeyboardShortcut, !heatmapActive);

            onAppearanceChange(current => ({
                ...current,
                // when active: show heat maps
                restorativeView: heatmapActive ? RestorativeView.CAD : RestorativeView.HeatMap,
                // and disable anatomy layers, which show CADs
                showAnatomyLayers: false,
            }));
        },
        [onAppearanceChange, heatmapActive, trackToggle],
    );

    const toggleHeatmapWithHotKey = React.useCallback(
        (heatmap: HeatMapType) => {
            if (!heatmapActive || appearanceSettings.activeHeatMap === heatmap) {
                toggleHeatmapActive(true);
            }

            trackToggle(HeatMapTypeToActionTypeMap[heatmap ?? appearanceSettings.activeHeatMap], true, true);

            onAppearanceChange(current => ({
                ...current,
                activeHeatMap: heatmap ?? appearanceSettings.activeHeatMap,
            }));
        },
        [appearanceSettings.activeHeatMap, heatmapActive, onAppearanceChange, toggleHeatmapActive, trackToggle],
    );

    // anatomy layers
    const anatomyActive = appearanceSettings.showAnatomyLayers;
    const toggleAnatomyActive = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('anatomy_layers', usedKeyboardShortcut, !anatomyActive);

            onAppearanceChange(current => ({
                ...current,
                // show CADs
                restorativeView: RestorativeView.CAD,
                // and show anatomy layers by showing translucent Anatomy alongside CADs
                showAnatomyLayers: !anatomyActive,
            }));
        },
        [anatomyActive, onAppearanceChange, trackToggle],
    );

    // collision lines
    const collisionsActive = appearanceSettings.showCollisions;
    const toggleCollisionLines = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('collisions', usedKeyboardShortcut, !collisionsActive);

            onAppearanceChange(current => ({
                ...current,
                showCollisions: !collisionsActive,
            }));
        },
        [onAppearanceChange, collisionsActive, trackToggle],
    );

    const showCurtainsCollisions = appearanceSettings.showCurtainsCollisions;
    const toggleCurtainsCollisions = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('curtain_collisions', usedKeyboardShortcut, !showCurtainsCollisions);

            if (!showCurtainsCollisions) {
                unifyRestorativesView(orderId, appearanceSettings, onAppearanceChange);
            }

            onAppearanceChange(current => ({
                ...current,
                showCurtainsCollisions: !showCurtainsCollisions,
            }));
        },
        [orderId, appearanceSettings, onAppearanceChange, showCurtainsCollisions, trackToggle],
    );

    // margin lines
    const designMarginLinesActive = appearanceSettings.showMarginLines;
    const toggleMarginLines = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('design_margin_lines', usedKeyboardShortcut, !designMarginLinesActive);

            onAppearanceChange(current => ({
                ...current,
                showMarginLines: !designMarginLinesActive,
                showDoctorMarginLines: !designMarginLinesActive,
            }));
        },
        [onAppearanceChange, designMarginLinesActive, trackToggle],
    );

    // doctor margin lines
    const doctorMarginLinesActive = appearanceSettings.showDoctorMarginLines;
    const toggleDoctorMarginLines = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('doctor_margin_lines', usedKeyboardShortcut, !doctorMarginLinesActive);

            onAppearanceChange(current => ({
                ...current,
                showDoctorMarginLines: !doctorMarginLinesActive,
            }));
        },
        [onAppearanceChange, doctorMarginLinesActive, trackToggle],
    );

    // doctor tooth markings
    const doctorToothMarkingsActive = appearanceSettings.showDoctorToothMarkings;
    const toggleDoctorToothMarkings = React.useCallback(
        (usedKeyboardShortcut: boolean) => {
            trackToggle('doctor_tooth_markings', usedKeyboardShortcut, !doctorToothMarkingsActive);

            onAppearanceChange(current => ({
                ...current,
                showDoctorToothMarkings: !current.showDoctorToothMarkings,
            }));
        },
        [onAppearanceChange, doctorToothMarkingsActive, trackToggle],
    );

    React.useEffect(() => {
        const range =
            heatmapActive && dynamicHeatmaps
                ? QcHeatmapOptions[appearanceSettings.activeHeatMap].defaultRange
                : undefined;

        onAppearanceChange(current => ({
            ...current,
            heatMapRange: range,
        }));
    }, [heatmapActive, onAppearanceChange, appearanceSettings.activeHeatMap, dynamicHeatmaps]);

    const handleHeatMapRangeChange = React.useCallback(
        (newValues: QcHeatmapRange): void => {
            onAppearanceChange(current => ({
                ...current,
                heatMapRange: dynamicHeatmaps ? newValues : undefined,
            }));
        },
        [onAppearanceChange, dynamicHeatmaps],
    );

    const toggleRestorativesTransparent = React.useCallback(() => {
        onAppearanceChange(currentAppearance => {
            const anchorCad = currentAppearance.restoratives['CAD'][0];
            if (!anchorCad) {
                return { ...currentAppearance };
            }

            return {
                ...currentAppearance,
                restoratives: {
                    HeatMap: currentAppearance.restoratives.HeatMap,
                    CAD: currentAppearance.restoratives['CAD'].map(item => ({
                        ...item,
                        appearance: { ...item.appearance, opacity: getToggledOpacity(anchorCad.appearance) },
                    })),
                },
            };
        });
    }, [onAppearanceChange]);

    // Hot keys
    useRegisterHotKeys({
        key: 'Q',
        description: 'Occlusal Heatmap',
        category: 'Tools',
        action: () => toggleHeatmapWithHotKey(HeatMapType.Occlusal),
    });

    useRegisterHotKeys({
        key: 'W',
        description: 'Proximal Heatmap',
        category: 'Tools',
        action: () => toggleHeatmapWithHotKey(HeatMapType.Proximal),
    });

    useRegisterHotKeys({
        key: 'E',
        description: 'Thickness Heatmap',
        category: 'Tools',
        action: () => toggleHeatmapWithHotKey(HeatMapType.Thickness),
    });

    useRegisterHotKeys({
        key: 'A',
        description: 'Anatomy Layers',
        category: 'Tools',
        action: () => toggleAnatomyActive(true),
        disabled: disableHotkeys ?? false,
    });

    useRegisterHotKeys({
        key: 'C',
        description: 'Collisions',
        category: 'Tools',
        action: () => toggleCollisionLines(true),
        disabled: disableHotkeys ?? false,
    });

    useRegisterHotKeys({
        key: 'M',
        description: 'Margin Lines',
        category: 'Tools',
        action: () => toggleMarginLines(true),
        disabled: disableHotkeys ?? false,
    });

    // Pipe all these useful callbacks out to a ref
    React.useEffect(() => {
        if (!fnCallbacksRef) {
            return;
        }
        fnCallbacksRef.current = {
            toggleRestorativesTransparent,
            toggleAnatomyActive,
            toggleMarginLines,
            toggleCollisionLines,
            toggleHeatmapActive,
            toggleHeatmapWithHotKey,
            toggleDoctorMarginLines,
            toggleDoctorToothMarkings,
        };
    }, [
        fnCallbacksRef,
        toggleRestorativesTransparent,
        toggleAnatomyActive,
        toggleMarginLines,
        toggleCollisionLines,
        toggleHeatmapActive,
        toggleHeatmapWithHotKey,
        toggleDoctorMarginLines,
        toggleDoctorToothMarkings,
    ]);

    return (
        <div className={commonStyles.noPointerEvents}>
            <Grid container className={commonStyles.noPointerEvents} direction={'row'} spacing={2}>
                <Grid
                    item
                    role={'toolbar'}
                    aria-label={'Quality Control Tools'}
                    className={commonStyles.noPointerEvents}
                >
                    <Card raised={true} className={clsx(styles.panel, styles.mainMenu, commonStyles.noPointerEvents)}>
                        <div className={styles.flexColumn}>
                            {topChildren}
                            {enableHeatmaps && (
                                <IconToggleButton
                                    tooltip={heatmapActive ? 'Hide Heatmaps' : 'Show Heatmaps'}
                                    className={styles.iconButton}
                                    active={heatmapActive}
                                    onClick={() => toggleHeatmapActive(false)}
                                    data-test={'heatmaps-button'}
                                >
                                    <QCHeatmapIcon />
                                </IconToggleButton>
                            )}

                            {enableAnatomyLayers && (
                                <IconToggleButton
                                    tooltip={anatomyActive ? 'Hide Anatomy Layers' : 'Show Anatomy Layers'}
                                    active={anatomyActive}
                                    className={styles.iconButton}
                                    onClick={() => toggleAnatomyActive(false)}
                                >
                                    <LayersIcon />
                                </IconToggleButton>
                            )}

                            {enableCollisions && (
                                <IconToggleButton
                                    tooltip={appearanceSettings.showCollisions ? 'Hide Collisions' : 'Show Collisions'}
                                    active={appearanceSettings.showCollisions}
                                    className={styles.iconButton}
                                    onClick={() => toggleCollisionLines(false)}
                                >
                                    <CollisionsIcon />
                                </IconToggleButton>
                            )}

                            {enableCollisions && enableUndercutHeatmap && appearanceSettings.showCollisions && (
                                <IconToggleButton
                                    tooltip={
                                        appearanceSettings.showCurtainsCollisions
                                            ? 'Hide Undercut Collisions'
                                            : 'Show Undercut Collisions'
                                    }
                                    active={appearanceSettings.showCurtainsCollisions}
                                    className={styles.iconButton}
                                    onClick={() => toggleCurtainsCollisions(false)}
                                >
                                    <CollisionsUndercutIcon />
                                </IconToggleButton>
                            )}

                            {enableMarginLines && (
                                <>
                                    <IconToggleButton
                                        tooltip={
                                            appearanceSettings.showMarginLines
                                                ? 'Hide Margin Lines'
                                                : 'Show Margin Lines'
                                        }
                                        active={appearanceSettings.showMarginLines}
                                        className={styles.iconButton}
                                        onClick={() => toggleMarginLines(false)}
                                    >
                                        <ModelMarginIcon />
                                    </IconToggleButton>
                                    {appearanceSettings.showMarginLines && enableDoctorMarginLines && (
                                        <IconToggleButton
                                            tooltip={
                                                appearanceSettings.showDoctorMarginLines
                                                    ? 'Hide Doctor Margin Lines'
                                                    : 'Show Doctor Margin Lines'
                                            }
                                            active={appearanceSettings.showDoctorMarginLines}
                                            className={styles.iconButton}
                                            onClick={() => toggleDoctorMarginLines(false)}
                                        >
                                            <ModelDoctorMarginIcon />
                                        </IconToggleButton>
                                    )}
                                </>
                            )}

                            {enableDoctorToothMarkings && (
                                <IconToggleButton
                                    tooltip={
                                        appearanceSettings.showDoctorToothMarkings
                                            ? 'Hide Tooth Markings'
                                            : 'Show Tooth Markings'
                                    }
                                    active={appearanceSettings.showDoctorToothMarkings}
                                    className={styles.iconButton}
                                    onClick={() => toggleDoctorToothMarkings(false)}
                                >
                                    <ToothMarkingsIcon />
                                </IconToggleButton>
                            )}

                            <LoadPreviousDesignButton
                                appearance={appearanceSettings}
                                setDesiredPreviousDesignFiles={setDesiredPreviousDesignFiles}
                            />

                            {children}
                        </div>
                    </Card>
                </Grid>

                {!!ProximalContactsSubmenu && (
                    <Grid item className={commonStyles.noPointerEvents}>
                        {ProximalContactsSubmenu}
                    </Grid>
                )}

                {enableHeatmaps && heatmapActive && (
                    <Grid item className={commonStyles.noPointerEvents}>
                        <HeatMapSelectionBar
                            appearanceSettings={appearanceSettings}
                            onAppearanceChange={onAppearanceChange}
                            enableSculptReviewHeatmaps={!!enableSculptReviewHeatmaps}
                            enableUndercutHeatmap={enableUndercutHeatmap}
                            enableTissuePressureHeatmap={enableTissuePressureHeatmap}
                            showBasicHeatmaps={showBasicHeatmaps ?? true}
                            dynamicHeatmaps={dynamicHeatmaps}
                        />
                    </Grid>
                )}

                {enableAnatomyLayers && anatomyActive && (
                    <Grid item className={commonStyles.noPointerEvents}>
                        <AnatomyLayerSelectionBar
                            appearanceSettings={appearanceSettings}
                            onAppearanceChange={onAppearanceChange}
                        />
                    </Grid>
                )}

                {appearanceSettings.restorativeView === RestorativeView.HeatMap &&
                    appearanceSettings.activeHeatMap !== HeatMapType.VertexDisplacement && (
                        <Grid item className={commonStyles.noPointerEvents}>
                            <QCColorLegend
                                dynamicHeatmaps={dynamicHeatmaps}
                                heatMapType={appearanceSettings.activeHeatMap}
                                heatMapRange={appearanceSettings.heatMapRange}
                                setHeatMapRange={handleHeatMapRangeChange}
                            />
                        </Grid>
                    )}
            </Grid>
        </div>
    );
};
