import type { CrossSectionEntry } from './CrossSectionData';
import type {} from '@mui/styles';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { FlossPalette, createStyles, makeStyles } from '@orthly/ui-primitives';
import _ from 'lodash';

export const useStyles = makeStyles(() =>
    createStyles({
        crossSectionPane: {
            position: 'absolute',
            zIndex: 10,

            display: 'flex',
            flexDirection: 'column',

            borderRadius: '3px',
            boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',

            width: `30%`,
            height: `55%`,
            right: '10px',
            top: '10px',

            opacity: 0.75,
        },
        header: {
            height: '40px',
            width: '100%',
            display: 'flex',
            flexGrow: 0,
            backgroundColor: 'white',
            verticalAlign: 'middle',
            userSelect: 'none',
            cursor: 'auto',
            '&:hover': {
                // I would prefer grab but it's not working
                cursor: 'move',
            },
        },
        headerButton: {
            flexGrow: 0,
            width: '40px',
            height: '40px',

            borderRadius: '0 !important',

            '&:hover': {
                cursor: 'pointer',
            },
        },
        iconButton: {
            borderRadius: '0 !important',
            '&.active': {
                backgroundColor: '#c4f6eb',
            },
        },
        csCanvas: {
            flexGrow: 1,
            height: '1px',
            width: '100%',
            backgroundColor: '#ddd',
        },
        headerSpacer: {
            flexGrow: 1,
        },
        toolbar: {
            height: '75px',
            width: '1px',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'row',

            '& button': {
                width: '40px',
                height: '40px',
                borderRadius: '0 !important',
            },
        },
        error: {
            color: '#BA0000',
            '&> span:not(last-child)': {
                marginRight: '.4em',
            },
        },
    }),
);

const DEFAULT_LINE_STYLE = {
    color: '#333',
    linewidth: 2,
};

const LINE_STYLES = {
    // we show restoratives (only CAD models) in a special color
    [LabsGqlOrderDesignScanType.Cad]: {
        color: '#555',
        linewidth: 1,
    },
    [LabsGqlOrderDesignScanType.Scans]: {
        color: '#333',
        linewidth: 2,
    },
    [LabsGqlOrderDesignScanType.QcExtras]: {
        color: '#FF081F',
        linewidth: 1,
    },
    MarginLine: {
        color: 'red',
        linewidth: 1,
    },
    UpdatedMarginLine: {
        color: 'magenta',
        linewidth: 1,
    },
};

// Line styles for cross section entities derived from past designs.
const PAST_LINE_STYLES = {
    [LabsGqlOrderDesignScanType.Cad]: {
        color: FlossPalette.GREEN,
        linewidth: 1,
    },
    MarginLine: {
        color: 'blue',
        linewidth: 1,
    },
};

export function getLineStyle(entry: Pick<CrossSectionEntry, 'isPast' | 'type'>) {
    return _.get(entry.isPast ? PAST_LINE_STYLES : LINE_STYLES, entry.type, DEFAULT_LINE_STYLE);
}
