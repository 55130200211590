import { AttributeName } from '../Utils3D/BufferAttributeConstants';
import { findSelfIntersections } from '../Utils3D/Intersection';
import {
    getCementGapPercentile,
    getSealZoneGapPercentile,
    hasEdgeLongerThan,
    hasSurfaceDisplacementGreaterThan,
    hasThicknessLessThan,
} from '../Utils3D/Mesh3d.util';
import { buildMeshAdjacencyAndVerticesFaces, type MeshConnectivityGraph } from '../Utils3D/MeshConnectivityGraph';
import { ensureMeshIndex } from '../Utils3D/MeshIndex';
import { getIntaglioGeometry } from '../Utils3D/Subgeometry.util';
import { computeUndercutInterference } from '../Utils3D/Undercut';
import type { IntaglioSettings } from '@orthly/shared-types';
import _ from 'lodash';
import type * as THREE from 'three';

const MAX_EDGE_LENGTH_MM = 1;
const MAX_SURFACE_DISPLACEMENT_MM = 1;
const MINIMUM_THICKNESS_MM = 0.55;
const CEMENT_GAP_TOLERANCE = 0.2;
const MIN_CEMENT_GAP_PERCENTILE = 0.5;
const MIN_SEAL_ZONE_GAP_PERCENTILE = 0.6;

interface SimpleCheckResult {
    // Whether the check passed. Undefined indicates an error occured while running the test.
    passed?: boolean;
    // The time, in milliseconds, it took to run the check
    runTimeMs: number;
}

export enum IntaglioCheckType {
    EDGE_LENGTH = 'EDGE_LENGTH',
    SURFACE_DISPLACEMENT = 'SURFACE_DISPLACEMENT',
    SELF_INTERSECTION = 'SELF_INTERSECTION',
    MINIMUM_THICKNESS = 'MINIMUM_THICKNESS',
    UNDERCUT = 'UNDERCUT',
    CEMENT_GAP = 'CEMENT_GAP', // cement gap over the entire intaglio
    SEAL_ZONE_GAP = 'SEAL_ZONE_GAP', // cement gap over the seal zone (subset of intaglio)
}

const INTAGLIO_CHECK_TYPE_TO_LABEL: Readonly<Record<IntaglioCheckType, string>> = {
    [IntaglioCheckType.EDGE_LENGTH]: 'Intaglio edge length',
    [IntaglioCheckType.SURFACE_DISPLACEMENT]: 'Intaglio surface displacement',
    [IntaglioCheckType.SELF_INTERSECTION]: 'Intaglio self intersection',
    [IntaglioCheckType.MINIMUM_THICKNESS]: 'Minimum thickness',
    [IntaglioCheckType.UNDERCUT]: 'Intaglio undercut',
    [IntaglioCheckType.CEMENT_GAP]: 'Intaglio cement gap',
    [IntaglioCheckType.SEAL_ZONE_GAP]: 'Seal zone cement gap',
};

export class IntaglioCheckResult {
    private results: Record<IntaglioCheckType, SimpleCheckResult>;

    constructor() {
        this.results = {
            [IntaglioCheckType.EDGE_LENGTH]: { runTimeMs: 0 },
            [IntaglioCheckType.SURFACE_DISPLACEMENT]: { runTimeMs: 0 },
            [IntaglioCheckType.SELF_INTERSECTION]: { runTimeMs: 0 },
            [IntaglioCheckType.MINIMUM_THICKNESS]: { runTimeMs: 0 },
            [IntaglioCheckType.UNDERCUT]: { runTimeMs: 0 },
            [IntaglioCheckType.CEMENT_GAP]: { runTimeMs: 0 },
            [IntaglioCheckType.SEAL_ZONE_GAP]: { runTimeMs: 0 },
        };
    }

    get passed(): boolean {
        return Object.values(this.results).every(result => result.passed);
    }

    get errorsArray(): string[] {
        return _.compact(
            Object.values(IntaglioCheckType).map(checkType => {
                return this.results[checkType].passed ? undefined : INTAGLIO_CHECK_TYPE_TO_LABEL[checkType];
            }),
        );
    }

    setResult(check: IntaglioCheckType, passed: boolean | undefined, runTimeMs: number) {
        this.results[check] = { passed, runTimeMs };
    }
}

export type CementGaps = Pick<IntaglioSettings, 'cementGap' | 'sealZoneGap'>;

/**
 * Runs checks on the restorative intaglio surface to ensure it is manufacturable
 * @param restorativeGeometry The restorative geometry to check
 * @param graph The connectivity graphs of `restorativeGeometry`
 * @param prepSiteGeometry Geometry of the prep site
 * @param insertionAxis The insertion axis of the restorative
 * @returns Post edit check results
 */
export function runIntaglioChecks(
    restorativeGeometry: THREE.BufferGeometry,
    graph: MeshConnectivityGraph,
    prepSiteGeometry: THREE.BufferGeometry,
    insertionAxis: THREE.Vector3,
    cementGaps: CementGaps,
): IntaglioCheckResult {
    const result = new IntaglioCheckResult();

    const attributesToCopy = [
        AttributeName.SurfaceDisplacement,
        AttributeName.IsSealZone,
        AttributeName.CementGapDistance,
    ];
    const subgeometryData = getIntaglioGeometry(restorativeGeometry, graph, attributesToCopy);
    if (!subgeometryData) {
        return result;
    }

    const { geometry: intaglioGeometry } = subgeometryData;

    // NB: We must calculate the BVH before the adjacency graph because `ensureMeshIndex` potentially rearranges the
    // index of the geometry, which would invalidate any adjacency graphs that were calculated previously.
    ensureMeshIndex(intaglioGeometry);
    const intaglioGraph = buildMeshAdjacencyAndVerticesFaces(intaglioGeometry, false);

    // Check intaglio edge lengths.
    const { result: hasLongEdge, durationMs: edgeLengthDurationMs } = withTiming(() => {
        return hasEdgeLongerThan(intaglioGeometry, intaglioGraph.adjacencyVertexToVertex, MAX_EDGE_LENGTH_MM);
    });
    result.setResult(IntaglioCheckType.EDGE_LENGTH, flipBool(hasLongEdge), edgeLengthDurationMs);

    // Check intaglio surface displacements.
    const { result: hasLargeSurfaceDisplacement, durationMs: displacementDurationMs } = withTiming(() => {
        return hasSurfaceDisplacementGreaterThan(intaglioGeometry, MAX_SURFACE_DISPLACEMENT_MM);
    });
    result.setResult(
        IntaglioCheckType.SURFACE_DISPLACEMENT,
        flipBool(hasLargeSurfaceDisplacement),
        displacementDurationMs,
    );

    // Check for self collisions in the intaglio.
    const { result: selfIntersections, durationMs: selfIntersectionsDurationMs } = withTiming(() => {
        return findSelfIntersections(intaglioGeometry, intaglioGraph);
    });
    result.setResult(
        IntaglioCheckType.SELF_INTERSECTION,
        selfIntersections ? selfIntersections.length === 0 : undefined,
        selfIntersectionsDurationMs,
    );

    // Check for minimum thickness
    const { result: isTooThin, durationMs: minimumThicknessDurationMs } = withTiming(() => {
        return hasThicknessLessThan(restorativeGeometry, MINIMUM_THICKNESS_MM);
    });
    result.setResult(IntaglioCheckType.MINIMUM_THICKNESS, flipBool(isTooThin), minimumThicknessDurationMs);

    // Check for undercut with respect to the prep site.
    const { result: undercutInterferences, durationMs: undercutDurationMs } = withTiming(() => {
        return computeUndercutInterference(intaglioGeometry, [prepSiteGeometry], insertionAxis, { taperDegrees: 0.8 });
    });
    result.setResult(IntaglioCheckType.UNDERCUT, !undercutInterferences.length, undercutDurationMs);

    // Check the cement gap over the entire intaglio.
    const { result: cementGapPercentile, durationMs: cementGapDurationMs } = withTiming(() => {
        return getCementGapPercentile(intaglioGeometry, cementGaps.cementGap * (1 + CEMENT_GAP_TOLERANCE));
    });
    result.setResult(
        IntaglioCheckType.CEMENT_GAP,
        cementGapPercentile >= MIN_CEMENT_GAP_PERCENTILE,
        cementGapDurationMs,
    );

    // Check the cement gap over the seal zone.
    const { result: sealZoneGapPercentile, durationMs: sealZoneGapDurationMs } = withTiming(() => {
        return getSealZoneGapPercentile(intaglioGeometry, cementGaps.sealZoneGap * (1 + CEMENT_GAP_TOLERANCE));
    });
    result.setResult(
        IntaglioCheckType.SEAL_ZONE_GAP,
        sealZoneGapPercentile >= MIN_SEAL_ZONE_GAP_PERCENTILE,
        sealZoneGapDurationMs,
    );

    return result;
}

// Flips a boolean but passes through an undefined
function flipBool(value?: boolean): boolean | undefined {
    return value === undefined ? undefined : !value;
}

function withTiming<T>(fn: () => T): { result: T; durationMs: number } {
    const startMs = performance.now();
    const result = fn();
    const endMs = performance.now();
    return { result, durationMs: endMs - startMs };
}
