import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import type { CrossSectionPlaneSetter } from './CrossSectionData';
import { useThree } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';
import type { Raycaster } from 'three';
import { Plane, Quaternion, Vector3 } from 'three';

const CROSS_SECTION_PLANE_LINE_COLOR = '#127094';

type DrawNewCrossSectionPlaneToolType = React.FC<{
    setCSPlane: CrossSectionPlaneSetter;
    cameraControlsRef: MainViewCameraControlsRef;
    newCrossSectionPlaneActive: boolean;
    onNewCrossSectionPlane: () => void;
}>;

/***
 * This component is a tool which allows to create a plane
 * in a 3d view, by dragging a line.
 *
 * Created plane will be oriented:
 * 1. to be perpendicular to view plane
 *    (in other words to have camera view vector laying inside plane)
 * 2. to have drawn line laying in plane
 *
 * @component
 */
export const DrawNewCrossSectionPlaneTool: DrawNewCrossSectionPlaneToolType = ({
    setCSPlane,
    cameraControlsRef,
    newCrossSectionPlaneActive,
    onNewCrossSectionPlane,
}) => {
    const { camera, gl, raycaster } = useThree();

    const planeLineGeometry = React.useRef(new THREE.BufferGeometry().setFromPoints([new Vector3(), new Vector3()]));

    // This effect is responsible for drawing a line in a main 3D view
    React.useEffect(() => {
        // Disable main camera controls
        // We don't want to rotate camera when user drags a line
        cameraControlsRef.current && (cameraControlsRef.current.enabled = !newCrossSectionPlaneActive);

        if (newCrossSectionPlaneActive) {
            // If tool is active, register three pointer event handlers
            // * down - initialize this tool
            //          (saves position where user starts to drag a line)
            // * move - if tool is initialized (see down),
            //          make line visible and adjust it's geometry.
            //          Line is drawn between current pointer position and
            //          point where user started to drag
            // * up   - saves the results, sets the plane, and hide line

            // Initial drag position
            let pointerDown: Vector3 | null = null;
            // Current pointer position
            let pointerB: Vector3 | null = null;

            // Initialize the tool
            const down = () => {
                pointerDown = getPointFromRay(raycaster);
            };

            const move = (evt: MouseEvent) => {
                if (pointerDown) {
                    pointerB = getPointFromRay(raycaster);

                    if (evt.shiftKey) {
                        // If user holds down shift key
                        // align Cross Section Plane line with model local Vertical line

                        const verticalLine = new Vector3(0, 1, 0);
                        const abVector = pointerB.sub(pointerDown);
                        const abLength = abVector.dot(verticalLine);
                        pointerB = pointerDown.clone().add(verticalLine.multiplyScalar(abLength));
                    }

                    // Update line geometry
                    planeLineGeometry.current.setFromPoints([pointerDown, pointerB]);
                    planeLineGeometry.current.attributes.position &&
                        (planeLineGeometry.current.attributes.position.needsUpdate = true);
                }
            };

            const up = () => {
                if (pointerDown && pointerB) {
                    let a = pointerDown;
                    let b = pointerB;

                    // Order line points by y coordinate
                    // to have consistent Cross Section Plane normal
                    // vector orientation
                    if (a.y < b.y) {
                        a = b;
                        b = pointerDown;
                    }

                    // Create Plane by 3 points, we already have 2 points for the line
                    // find third point by having camera looking direction vector
                    // add to one of the line points
                    const camLookDirection = new Vector3(0, 0, -1).applyQuaternion(camera.quaternion);
                    const c = b.clone().add(camLookDirection);

                    const plane = new Plane().setFromCoplanarPoints(a, b, c);

                    const planePosition = plane.normal.clone().multiplyScalar(plane.constant * -1);
                    const planeNormal = plane.normal.clone();

                    // Orient plane along the normal
                    const q1 = new Quaternion().setFromUnitVectors(new Vector3(0, 0, 1), planeNormal);
                    const minorAxisFrom = new Vector3(1, 0, 0).applyQuaternion(q1);

                    // Orient plane (rotate mesh around the normal) to align
                    // plane representing oval minor axis with camera direction
                    const q2 = new Quaternion().setFromUnitVectors(minorAxisFrom, camLookDirection);
                    const planeOrientation = q2.multiply(q1);

                    setCSPlane({
                        position: planePosition,
                        orientation: planeOrientation,
                        cameraUp: camera.up,
                    });

                    pointerDown = null;
                }

                // reset the line
                planeLineGeometry.current.setFromPoints([new Vector3(), new Vector3()]);

                onNewCrossSectionPlane && onNewCrossSectionPlane();
            };

            gl.domElement.addEventListener('pointerup', up);
            gl.domElement.addEventListener('pointerdown', down);
            gl.domElement.addEventListener('pointermove', move);

            return () => {
                gl.domElement.removeEventListener('pointerup', up);
                gl.domElement.removeEventListener('pointerdown', down);
                gl.domElement.removeEventListener('pointermove', move);
            };
        }
    }, [
        newCrossSectionPlaneActive,
        camera,
        cameraControlsRef,
        gl.domElement,
        onNewCrossSectionPlane,
        raycaster,
        setCSPlane,
    ]);

    return (
        <lineSegments geometry={planeLineGeometry.current} renderOrder={5}>
            <lineBasicMaterial
                attach={'material'}
                color={CROSS_SECTION_PLANE_LINE_COLOR}
                linewidth={2}
                depthTest={false}
                depthWrite={false}
            />
        </lineSegments>
    );
};

function getPointFromRay(raycaster: Raycaster) {
    // Ideally would be write line on top, without changing line offset
    return raycaster.ray.origin.clone().add(raycaster.ray.direction.multiplyScalar(50));
}
