import { getAskDoctorV2Configuration } from '../../screens/labs/order-detail-v2/components/AskDoctorResponse/utils';
import { ResolveFlaggedScanDialog } from './ResolvedFlaggedScanDialog';
import { ResolveFlaggedScanScreen } from './types';
import { isOrderScanRejectedAndVisibleToPractice } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { ActionCard } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import { AttachmentPreview } from '@orthly/veneer';
import React from 'react';

export const ScanFlaggedWarning: React.FC<{
    order: LabsGqlLabOrderFragment;
    orderDetail?: boolean;
    refetch: () => Promise<any>;
}> = props => {
    const { order, refetch } = props;
    const [resolveDialogOpen, setResolveDialogOpen] = React.useState<boolean>(false);
    const [attachmentsOpen, setAttachmentsOpen] = React.useState(false);
    const [screen, setScreen] = React.useState<ResolveFlaggedScanScreen>(ResolveFlaggedScanScreen.DOCTOR_CHOICE);
    const { canUseAskDoctorV2Flow } = getAskDoctorV2Configuration(order);

    // In Ask Doctor V2, Scans rejections are resolved in the same banner as the regular holds, so we do not display this scan warning when the doctor can use the new v2 flow from the resolve hold path instead.
    if (!isOrderScanRejectedAndVisibleToPractice(order) || canUseAskDoctorV2Flow) {
        return null;
    }

    const { active_task: activeTask } = order.fulfillment_workflow;
    const { category, file_urls, notes_for_doctor } = activeTask.configuration;

    return (
        <>
            <ActionCard
                title={
                    props.orderDetail
                        ? 'There is an issue with the scan for this order'
                        : `We found an issue with ${order.patient.first_name} ${order.patient.last_name}'s scan`
                }
                variant={'alert'}
                primaryAction={{
                    onClick: () => setResolveDialogOpen(true),
                    text: 'Resolve',
                    endIcon: 'ChevronRight',
                }}
                secondaryAction={
                    file_urls?.length ? (
                        <AttachmentPreview
                            title={'Attachments'}
                            buttonText={''}
                            CustomButton={props => (
                                <Button onClick={props.onClick} variant={'ghost'} fullWidth={false}>
                                    View Attachments
                                </Button>
                            )}
                            sources={file_urls.map((url, idx) => ({
                                source: url,
                                name: `Attachment ${idx + 1}`,
                            }))}
                            open={attachmentsOpen}
                            setOpen={setAttachmentsOpen}
                        />
                    ) : undefined
                }
                subtitle={
                    <Grid container>
                        <Grid item xs={12}>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                {props.orderDetail
                                    ? 'Please let Dandy Support know how you would like to proceed'
                                    : 'We need your help resolving this, the delivery date might be affected.'}
                            </Text>
                        </Grid>
                        {props.orderDetail && (
                            <>
                                <Grid item xs={12}>
                                    <Text variant={'body2'} color={'DARK_GRAY'}>
                                        <b>Reason: </b> &nbsp; {category ?? 'Other'}
                                    </Text>
                                </Grid>
                                <Grid item xs={12}>
                                    <Text variant={'body2'} color={'DARK_GRAY'}>
                                        <b>Notes: </b> &nbsp; {notes_for_doctor ?? '(no notes available)'}
                                    </Text>
                                </Grid>
                            </>
                        )}
                    </Grid>
                }
            />
            <ResolveFlaggedScanDialog
                order={order}
                open={resolveDialogOpen}
                setOpen={setResolveDialogOpen}
                refetch={refetch}
                activeTask={activeTask}
                screen={screen}
                setScreen={setScreen}
            />
        </>
    );
};
