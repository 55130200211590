import { QRCode } from '../QRCode';
import { SimpleDropzone } from './FileUploaderDropzone';
import { ValidationUtils } from '@orthly/runtime-utils';
import { AttachIcon, LoadBlocker, SimpleDialog, SimpleInput } from '@orthly/ui';
import {
    Button,
    FlossPalette,
    Text,
    stylesFactory,
    Grid,
    IconButton,
    Menu,
    MenuItem,
    RefreshIcon,
} from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    subtitle: { marginTop: 8 },
    sendCTA: { marginTop: 16 },
}));

interface SendUploadLinkProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    sendText: (phoneNumber: string) => Promise<void>;
    placeholderPhoneNumber?: string;
    submitting?: boolean;
}

const SendUploadLink: React.VFC<SendUploadLinkProps> = ({
    sendText,
    placeholderPhoneNumber,
    submitting,
    open,
    setOpen,
}) => {
    const classes = useStyles();
    const [phoneNumber, setPhoneNumber] = React.useState<string | undefined>();

    React.useEffect(() => {
        if (!phoneNumber && placeholderPhoneNumber) {
            setPhoneNumber(placeholderPhoneNumber);
        }
    }, [placeholderPhoneNumber, phoneNumber]);

    const isNumber = ValidationUtils.isPhone(phoneNumber);

    return (
        <SimpleDialog
            title={'Enter your phone number to receive a mobile upload link'}
            open={open}
            onClose={() => setOpen(false)}
            actions={
                <>
                    <Button variant={'secondary'} onClick={() => setOpen(false)} style={{ marginRight: 8 }}>
                        Cancel
                    </Button>
                    <Button
                        disabled={!isNumber}
                        variant={'primary'}
                        onClick={() => phoneNumber && sendText(phoneNumber).then(() => setOpen(false))}
                    >
                        Send
                    </Button>
                </>
            }
        >
            <LoadBlocker blocking={!!submitting} ContainerProps={{ className: classes.sendCTA }}>
                <SimpleInput
                    onChange={val => setPhoneNumber(val ?? undefined)}
                    value={phoneNumber}
                    label={'Phone Number'}
                    fullWidth
                    TextFieldProps={{
                        autoFocus: true,
                        type: 'tel',
                        error: !isNumber,
                        helperText: !isNumber ? 'Please enter a valid phone number' : undefined,
                    }}
                />
            </LoadBlocker>
        </SimpleDialog>
    );
};

export interface FileUploadProps {
    title?: string;
    onDropAccepted: (files: File[]) => void;
    allowMultipleFiles?: boolean;
    qrCode: {
        qrData?: string;
        error?: Error;
        refetch?: () => void;
    };
    textUploadLink: Pick<SendUploadLinkProps, 'sendText' | 'submitting' | 'placeholderPhoneNumber'>;
    // by default accepting only images
    acceptAllFileTypes?: boolean;
    isMobile?: boolean;
}

export const FileUpload: React.VFC<FileUploadProps> = ({
    title,
    onDropAccepted,
    qrCode,
    textUploadLink,
    allowMultipleFiles,
    acceptAllFileTypes,
    isMobile,
}) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [showTextInput, setShowTextInput] = React.useState(false);

    const handleClose = () => setAnchorEl(null);
    const generateQRCode = () => {
        qrCode.refetch?.();
        handleClose();
    };
    const showTextMessage = () => {
        setShowTextInput(true);
        handleClose();
    };

    return (
        <>
            {!isMobile && (
                <>
                    <Text variant={'body2'} bold>
                        {title}
                    </Text>
                    <Text variant={'body2'} color={'GRAY'} className={classes.subtitle}>
                        From your phone via QR code{' '}
                        <IconButton
                            onClick={e => setAnchorEl(e.currentTarget)}
                            style={{ cursor: 'pointer', padding: 0 }}
                        >
                            <RefreshIcon htmlColor={FlossPalette.GREEN} />
                        </IconButton>
                    </Text>
                    <Menu
                        anchorEl={anchorEl}
                        open={!!anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <MenuItem onClick={generateQRCode}>Generate a new QR code</MenuItem>
                        <MenuItem onClick={showTextMessage}>Get a text message with the link</MenuItem>
                    </Menu>

                    <QRCode {...qrCode} errorText={'Please try using our texting service, or the desktop uploader.'} />

                    <SendUploadLink open={showTextInput} setOpen={setShowTextInput} {...textUploadLink} />

                    <Text variant={'body2'} color={'GRAY'} className={classes.subtitle}>
                        Or from your laptop
                    </Text>
                </>
            )}
            <SimpleDropzone
                options={{
                    ...(!acceptAllFileTypes && { accept: { 'image/*': ['.jpg', '.jpeg', '.png', '.gif', '.heic'] } }),
                    multiple: allowMultipleFiles ?? true,
                    onDropAccepted,
                }}
                wrapperStyle={{ border: 'none', padding: 0, minHeight: 'auto', marginTop: 8, background: 'none' }}
                dropzoneContent={
                    <Grid
                        container
                        direction={'column'}
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        data-test={'dropzone'}
                    >
                        <Button variant={'secondary'}>
                            Select files <AttachIcon />
                        </Button>
                    </Grid>
                }
            />
        </>
    );
};
