import { makeLegendTools } from './ColorLegendTools';
import { QcHeatmapOptions } from './ColorRamp.constants';
import type { QCLegendProps } from './ColorRamp.types';
import type { ColorRampData } from './ColorRamp.util';
import { generateColorRamps, getLabelFormatter } from './ColorRamp.util';
import _ from 'lodash';
import React from 'react';

export function useColorRamps() {
    const [colorRamps, setColorRamps] = React.useState<ColorRampData>();
    React.useEffect(() => {
        generateColorRamps().then(setColorRamps, e => console.warn('Failed to generate color ramps', e));
    }, []);
    return colorRamps;
}

export function useHeatMap({
    heatMapType,
    heatMapRange,
    setHeatMapRange,
    heatMapStep,
}: Omit<QCLegendProps, 'dynamicHeatmaps'>) {
    const heatMap = QcHeatmapOptions[heatMapType];
    const range = heatMapRange ?? heatMap.defaultRange;
    const step = heatMapStep ?? heatMap.defaultStep;

    const { min: vMin, max: vMax } = range;
    const step_size = (vMax - vMin) / (heatMap.numSteps - 1);
    const points = _.range(heatMap.numSteps).map(step => vMin + step * step_size);

    const label = getLabelFormatter(range, points);

    // create legend tools even if we're not showing them because react hooks force us to
    const legendTools = React.useMemo(() => {
        return makeLegendTools({
            heatMap,
            range,
            step,
            onChange: setHeatMapRange ?? (() => {}),
        });
    }, [heatMap, range, setHeatMapRange, step]);

    return { points, label, legendTools, vMin, vMax, heatMap };
}
