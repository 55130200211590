/* eslint-disable max-lines, max-lines-per-function */
import { useRegisterHotKeys } from '../HotKeyCheatSheet';
import { isRemovableItem } from '../ModelViewer';
import { Jaw } from '../ModelViewer/ModelViewerTypes';
import { useUndercutShader } from '../ModelViewer/UndercutShader';
import { GroupAppearanceController, getCurrentGroupAppearance } from './GroupAppearanceController';
import { ModelAppearanceController } from './ModelAppearanceController';
import type { DisabledControls, ItemAppearance, ModelAppearance, PayloadModelAppearance } from './ModelAppearanceTypes';
import { RestorativeView } from './ModelAppearanceTypes';
import _ from 'lodash';
import React from 'react';
import { Box3, Vector3 } from 'three';

type RestorativesAppearanceControllerProps = {
    appearanceSettings: ModelAppearance;
    onAppearanceChange: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    disableHotkeys?: boolean;
};

/**
 * Controls the appearance of restorative models, including the individual restoratives and the group as a whole.
 * Notably, we have multiple restorative views, stored in separate models, so there is handling for these here.
 *
 * @component
 */
export const RestorativesAppearanceController: React.FC<RestorativesAppearanceControllerProps> = ({
    appearanceSettings,
    onAppearanceChange,
    disableHotkeys,
}) => {
    // appearance settings for all restoratives views
    const restorativeSettings = appearanceSettings.restoratives;
    // the current restoratives view
    const restorativeView = appearanceSettings.restorativeView;
    // appearance settings for the *current* restorative view
    const restorativeItems: PayloadModelAppearance[] = restorativeSettings[restorativeView];

    const disabledControls: DisabledControls = {
        colorize: true,
        insertionAxis: true,
        undercutArrowAndShadow: false,
        undercutCurtains: false,
        // we disable transparency for heatmaps because we're unable to make them tranparent
        transparency: restorativeView === RestorativeView.HeatMap,
        solo: false,
        selectedForEdit: true,
    };

    const groupDisabledControls: DisabledControls = {
        ...disabledControls,
        insertionAxis: false,
        undercutArrowAndShadow: true,
        undercutCurtains: true,
    };

    // Shader instance doesn't rely on appearance and payload itself.
    // All the actual dependencies are passed via uniforms
    const shader = useUndercutShader();

    const restorativeElements = restorativeItems.map((payloadModelAppearance, index) => {
        const setAppearance = (update: (current: ItemAppearance) => ItemAppearance) => {
            onAppearanceChange(current => {
                // In this method:
                // 1 merge Appearance settings changes for the
                //   particular restorative
                // 2 Change dependencies appearance:
                //   a) only one restorative may have showUndercutShadow at a time
                //   b) if undercut shadow is on, only the corresponding restorative's
                //      insertionAxis may be displayed
                //   c) for restorative with showUndercutShadow apply undercut shader
                //      onto corresponding jaw's appearance
                const items = current.restoratives[current.restorativeView];
                const item = items[index];
                if (!item) {
                    return current;
                }
                const newAppearance = update(item.appearance);

                const newRestorativeItems = items.map((item, i) => {
                    if (i === index) {
                        return {
                            ...item,
                            appearance: newAppearance,
                        };
                    }

                    // Only one restorative can have showUndercutShadow active at the time.
                    // Set showUndercutShadow to false for all other items.
                    if (newAppearance.showUndercutShadow) {
                        return {
                            ...item,
                            appearance: {
                                ...item.appearance,
                                showInsertionAxis: false,
                                showUndercutShadow: false,
                            },
                        };
                    }
                    return item;
                });

                const newRestorativeSettings = {
                    ...current.restoratives,
                    [restorativeView]: newRestorativeItems,
                };

                return {
                    ...current,
                    restoratives: newRestorativeSettings,
                };
            });
        };

        const handleSoloChange = (isSolo: boolean) => {
            onAppearanceChange(current => ({
                ...current,
                solo: isSolo ? [payloadModelAppearance.payloadModel] : [],
            }));
        };

        const isSolo = appearanceSettings.solo?.includes(payloadModelAppearance.payloadModel);
        const insertionAxisExists = payloadModelAppearance.payloadModel.insertionAxis !== undefined;
        const disableUndercutControls = insertionAxisExists
            ? {}
            : {
                  undercutArrowAndShadow: true,
                  undercutCurtains: true,
              };

        return (
            <ModelAppearanceController
                key={`${appearanceSettings.restorativeView}_${index}`}
                solo={isSolo}
                onSoloChange={handleSoloChange}
                text={payloadModelAppearance.payloadModel.name}
                disabledControls={{
                    ...disabledControls,
                    ...disableUndercutControls,
                    insertionAxis: !insertionAxisExists,
                }}
                setAppearance={setAppearance}
                appearance={payloadModelAppearance.appearance}
            />
        );
    });

    React.useEffect(() => {
        if (!appearanceSettings.restoratives[appearanceSettings.restorativeView].length) {
            // If there are no restoratives, do not alter the custom shader so that we do not conflict with other
            // effects that apply custom shaders.
            return;
        }

        // Now set the undercut shader to jaw associated with the
        // restorative or remove it for all the jaws
        const jawsSettings = {
            [Jaw.Upper]: appearanceSettings.upperJaw,
            [Jaw.Lower]: appearanceSettings.lowerJaw,
        };

        const anyJawHasCustomShader = [Jaw.Upper, Jaw.Lower].some(j =>
            jawsSettings[j].some(settings => settings.appearance.customShader !== undefined),
        );

        if (anyJawHasCustomShader) {
            // Remove shader for both arches
            // we reapply it later based on if new settings for restoratives
            // have undercut turned on
            [Jaw.Upper, Jaw.Lower].forEach(j => {
                jawsSettings[j] = jawsSettings[j].map(settings => ({
                    ...settings,
                    appearance: {
                        ...settings.appearance,
                        customShader: undefined,
                    },
                }));
            });
        }

        // Apply shader to jaw corresponding to restorative
        const undercutRestorative = appearanceSettings.restoratives[appearanceSettings.restorativeView].find(
            item => item.appearance.showUndercutShadow,
        );
        const jaw = undercutRestorative?.payloadModel?.jaw;
        if (jaw) {
            jawsSettings[jaw] = jawsSettings[jaw].map(settings => ({
                ...settings,
                appearance: {
                    ...settings.appearance,
                    customShader: shader,
                },
            }));
        } else {
            console.warn("Can't determine arch to apply undercut shader");
        }
        const newSettings: ModelAppearance = {
            ...appearanceSettings,
            upperJaw: jawsSettings[Jaw.Upper],
            lowerJaw: jawsSettings[Jaw.Lower],
        };

        if (!_.isEqual(newSettings, appearanceSettings)) {
            onAppearanceChange(newSettings);
        }
    }, [appearanceSettings, onAppearanceChange, shader]);

    // the group is solo'd if everything in the group is solo'd
    const isGroupSolo =
        restorativeItems.length > 0 &&
        restorativeItems.every(entry => appearanceSettings.solo?.includes(entry.payloadModel));

    const handleRestorativesSoloChange = React.useCallback(
        isSolo => {
            onAppearanceChange(current => ({
                ...current,
                solo: isSolo ? restorativeItems.map(entry => entry.payloadModel) : [],
            }));
        },
        [onAppearanceChange, restorativeItems],
    );

    const toggleRestorativeSolo = React.useCallback(
        () => handleRestorativesSoloChange(!isGroupSolo),
        [handleRestorativesSoloChange, isGroupSolo],
    );

    const handleRestorativesGroupAppearanceChange = React.useCallback(
        (childrenSettings: PayloadModelAppearance[]) => {
            onAppearanceChange(current => {
                const newRestorativeSettings = { ...current.restoratives, [restorativeView]: childrenSettings };
                return {
                    ...current,
                    restoratives: newRestorativeSettings,
                };
            });
        },
        [onAppearanceChange, restorativeView],
    );

    const currentGroupAppearance = getCurrentGroupAppearance(restorativeItems, disabledControls);

    const toggleRestorativesVisible = React.useCallback(() => {
        const newSettings = restorativeItems.map(oldPayloadModel => {
            return {
                payloadModel: oldPayloadModel.payloadModel,
                appearance: {
                    ...oldPayloadModel.appearance,
                    visible: !currentGroupAppearance.visible,
                },
            };
        });
        handleRestorativesGroupAppearanceChange(newSettings);
    }, [handleRestorativesGroupAppearanceChange, restorativeItems, currentGroupAppearance]);

    useRegisterHotKeys({
        key: 'R',
        description: 'Show/Hide All Restoratives',
        category: 'Restoratives',
        action: toggleRestorativesVisible,
        disabled: disableHotkeys ?? false,
    });
    useRegisterHotKeys({
        key: 'Shift+R',
        description: 'Solo Restoratives',
        category: 'Restoratives',
        action: toggleRestorativeSolo,
        disabled: disableHotkeys ?? false,
    });

    const restorativeWithUndercut = restorativeItems.filter(itm => itm.appearance.showUndercutShadow);
    const hasRemovables = restorativeItems.filter(itm => isRemovableItem(itm.payloadModel)).length > 0;

    const insertionAxis = restorativeWithUndercut[0]?.payloadModel?.insertionAxis;

    // In short, this shader colors jaw mesh faces
    // which are aligned* with insertion axis within
    // some bbox around restorative.
    //
    // * face normal is aligned with insertion axis,
    //   ie dot product between face norm and insertion
    //   axis is positive (greater than 0.01 threshold).

    // Update undercut shader uniforms

    // Update insertionAxis
    React.useEffect(() => {
        const uniform = shader.uniforms.insertionAxis;
        const value = insertionAxis?.map(ele => -1 * ele);
        if (value) {
            uniform.value = value;
        }
    }, [shader, insertionAxis]);

    // Coloring is applied within bounding box around
    // restoratives.

    // Update bounding box to include restoratives
    // and pass it to shader via uniforms.
    const bbox = new Box3();
    restorativeWithUndercut.forEach(p => {
        const geom = p.payloadModel.model.geometry;
        if (!geom.boundingBox) {
            geom.computeBoundingBox();
        }

        if (geom.boundingBox) {
            bbox.expandByPoint(geom.boundingBox.min);
            bbox.expandByPoint(geom.boundingBox.max);
        }
    });

    React.useEffect(() => {
        const uniformMin = shader.uniforms.bboxMin;
        uniformMin.value = new Vector3(bbox.min.x - 3.0, bbox.min.y - 3.0, bbox.min.z - 1.0);

        const uniformMax = shader.uniforms.bboxMax;
        uniformMax.value = new Vector3(bbox.max.x + 3.0, bbox.max.y + 3.0, bbox.max.z + 1.0);
    }, [shader, bbox.min.x, bbox.min.y, bbox.min.z, bbox.max.x, bbox.max.y, bbox.max.z]);

    // If jaw is desaturated, desaturate it in shader material as well.
    const lowerJawColors = appearanceSettings.lowerJaw[0]?.appearance?.colorize;
    const upperJawColors = appearanceSettings.upperJaw[0]?.appearance?.colorize;

    const undercutOnUpperJaw = restorativeWithUndercut[0]?.payloadModel?.jaw === Jaw.Upper;
    const undercutOnLowerJaw = restorativeWithUndercut[0]?.payloadModel?.jaw === Jaw.Lower;

    const desaturateUndercutModel = (undercutOnUpperJaw && !upperJawColors) || (undercutOnLowerJaw && !lowerJawColors);

    React.useEffect(() => {
        const uniform = shader.uniforms.desaturate;
        uniform.value = desaturateUndercutModel;
    }, [shader, desaturateUndercutModel]);

    // if there are no restoratives don't display anything
    if (restorativeItems.length === 0) {
        return null;
    }
    const groupName = hasRemovables ? 'Items' : 'Restoratives';
    return (
        <GroupAppearanceController
            text={groupName}
            disabledControls={groupDisabledControls}
            solo={isGroupSolo}
            onSoloChange={handleRestorativesSoloChange}
            appearanceArray={restorativeItems}
            onAppearanceChange={handleRestorativesGroupAppearanceChange}
        >
            {restorativeElements}
        </GroupAppearanceController>
    );
};
