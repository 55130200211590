import type { ModelElement, ModelInfoElement, ToothElement } from './DesignCaseFileParser';
import { IMPLANT_TOOTH_TYPES } from './DesignCaseFileParser';
import { ToothUtils } from '@orthly/items';
import _ from 'lodash';

export enum ToothElementType {
    Crown = 'teCrown',
    Veneer = 'teVeneer',
    Inlay = 'teInlay',
    TemporaryVPrepCrown = 'teTemporaryVPrepCrown',
    TemporaryVPrepCrownPontic = 'teTemporaryVPrepCrownPontic',
    Coping = 'teCoping',
    PostAndCoreStandard = 'tePostAndCoreStandard',
    AbutmentScrewRetainedCrown = 'teAbutmentScrewRetainedCrown',
    ArtificialTooth = 'teArtificialTooth',
    Gingiva = 'teGingiva',
    GingivaDenture = 'teGingivaFD',
    Pontic = 'tePontic',
    CrownPontic = 'teCrownPontic',
    TemporaryCrownPontic = 'teTemporaryCrownPontic',
    RemovablePartialDenture = 'teRPD',
    RpdClasp = 'teClasp',
}

export const CrownAndBridgeMarginToothTypes: string[] = [
    ToothElementType.Crown,
    ToothElementType.Veneer,
    ToothElementType.Inlay,
    ToothElementType.TemporaryVPrepCrown,
    ToothElementType.TemporaryVPrepCrownPontic,
    ToothElementType.Coping,
    ToothElementType.PostAndCoreStandard,
];

export const EligibleForHeatmapToothTypes: string[] = [
    ToothElementType.Crown,
    ToothElementType.Veneer,
    ToothElementType.Inlay,
    ToothElementType.TemporaryVPrepCrown,
    ToothElementType.Coping,
    ToothElementType.PostAndCoreStandard,
    ToothElementType.AbutmentScrewRetainedCrown,
    ToothElementType.ArtificialTooth,
    ToothElementType.Pontic,
    ToothElementType.CrownPontic,
    ToothElementType.TemporaryCrownPontic,
    ToothElementType.RemovablePartialDenture,
];

export enum CadType {
    Gingiva = 'Gingiva',
    Splint = 'Splint',
}

function isSupportedModelType(modelElement: Pick<ModelElement, 'modelType' | 'modelElementID'>) {
    return ['meIndicationRegular', 'meSplint'].includes(modelElement.modelType);
}

function getGingivaName(toothNumbers: number[]) {
    return `Gingiva ${
        toothNumbers.some(unn => ToothUtils.isToothNumber(unn) && ToothUtils.toothIsUpper(unn)) ? 'Upper' : 'Lower'
    }`;
}
export class DesignCaseFileUtil {
    static deSuffixFilename(fileName: string): string {
        return fileName.substring(0, fileName.lastIndexOf('.')) || fileName;
    }

    static cleanXmlNameForComparison(fileName: string): string {
        // Take care of backslashes, and double slashes
        const cleanedSlashes = fileName.replaceAll('\\', '/').replaceAll('//', '/');
        const folderParts = cleanedSlashes.split('/');
        if (folderParts.length < 3) {
            return DesignCaseFileUtil.deSuffixFilename(cleanedSlashes);
        }
        const leadingSlashesRemoved = folderParts.slice(-2).join('/');
        return DesignCaseFileUtil.deSuffixFilename(leadingSlashesRemoved);
    }

    // On server, we get orthly-labs/designs/order_id/design_id/CAD/fileName.stl
    // In XML, we get /CAD/fileName.dcm or sometimes double backslashes etc
    static cleanBlobStorageNameForComparison(cloudPath: string): string {
        const folderParts = cloudPath.split('/');
        if (folderParts.length < 3) {
            return DesignCaseFileUtil.deSuffixFilename(cloudPath);
        }
        const cloudDirsRemoved = cloudPath.split('/').slice(-2).join('/');
        return DesignCaseFileUtil.deSuffixFilename(cloudDirsRemoved);
    }

    static getModelElementFromFileName<
        MinimalModelElement extends Pick<ModelElement, 'modelElementID' | 'modelFilePath' | 'modelType' | 'modelJobID'>,
    >(modelElements: MinimalModelElement[], scanName: string): MinimalModelElement | undefined {
        const targetModelFileName = DesignCaseFileUtil.cleanBlobStorageNameForComparison(scanName);
        return modelElements.find(item => {
            return DesignCaseFileUtil.cleanXmlNameForComparison(item.modelFilePath) === targetModelFileName;
        });
    }

    static isPrintedModel<MinimalModelElement extends Pick<ModelElement, 'modelType'>>(
        modelElement: MinimalModelElement,
    ): boolean {
        return modelElement.modelType.includes('meDigitalModel');
    }
    static getTeethByModelID<MinimalToothElement extends Pick<ToothElement, 'modelElementID'>>(
        toothElements: MinimalToothElement[],
        modelElementID: string,
    ): MinimalToothElement[] {
        return toothElements.filter(item => item.modelElementID === modelElementID);
    }

    /*
     * Informs us any unn location with an abutment
     * or direct to implant (screw retained) connection
     * pontics will be omitted
     * */
    static getImplantUnns(toothElements: Pick<ToothElement, 'toothNumber' | 'cacheToothTypeClass'>[]): number[] {
        return toothElements
            .filter(el => IMPLANT_TOOTH_TYPES.includes(el.cacheToothTypeClass))
            .map(el => el.toothNumber);
    }

    static isNonImplantCrownItem<MinimalModelElement extends Pick<ModelElement, 'modelElementID' | 'modelType'>>(
        modelElement: MinimalModelElement,
        toothElements: Pick<ToothElement, 'toothNumber' | 'cacheToothTypeClass' | 'modelElementID'>[],
    ): boolean {
        const implantUnns = this.getImplantUnns(toothElements);

        const teeth = this.getTeethByModelID(toothElements, modelElement.modelElementID);
        if (teeth.some((tE: ToothElement) => implantUnns.includes(tE.toothNumber))) {
            return false;
        }

        // now, make sure that we have a margin item in the mE
        // because dentures can have meIndicationRegular but no crowns
        return teeth.some(tE => CrownAndBridgeMarginToothTypes.includes(tE.cacheToothTypeClass));
    }

    static getNonImplantCrownItems<MinimalModelElement extends Pick<ModelElement, 'modelElementID' | 'modelType'>>(
        toothElements: Pick<ToothElement, 'toothNumber' | 'cacheToothTypeClass' | 'modelElementID'>[],
        modelElements: MinimalModelElement[],
    ): MinimalModelElement[] {
        return modelElements.filter(mE => DesignCaseFileUtil.isNonImplantCrownItem(mE, toothElements));
    }

    // Crowns on Abutments or Crowns Direct to Implants (not sure about tiBase!)
    static getImplantCrownItems(toothElements: ToothElement[], modelElements: ModelElement[]): ModelElement[] {
        const implantUnns = this.getImplantUnns(toothElements);
        return modelElements.filter(mE => {
            if (mE.modelType !== 'meIndicationRegular') {
                return false;
            }
            const associatedTeeth = this.getTeethByModelID(toothElements, mE.modelElementID);
            if (associatedTeeth.some((tE: ToothElement) => tE.cacheToothTypeClass === 'teAbutment')) {
                return false;
            }
            return associatedTeeth.some((tE: ToothElement) => implantUnns.includes(tE.toothNumber));
        });
    }

    // This is currently only used by the team 3d version of the web design loader.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    static getNameForModelElement(
        modelElement: Pick<ModelElement, 'modelType' | 'modelElementID'>,
        toothElements: Pick<ToothElement, 'modelElementID' | 'toothNumber' | 'cacheToothTypeClass'>[],
        modelInfoElements: Pick<ModelInfoElement, 'modelElementID' | 'toothNumber'>[] | undefined,
    ): string | undefined {
        if (modelElement.modelType === 'meSurgicalGuide') {
            // TODO, some day figure out tooth numbers
            return 'Surgical Guide ';
        }

        if (modelElement.modelType === 'meSplint') {
            // TODO, some day figure out upper or lower
            return 'Splint';
        }

        // Figure out dies and upper etc
        if (modelElement.modelType.startsWith('meDigitalModel')) {
            // Maybe Theres a tooth number
            const meInfoToothNumber = modelInfoElements?.find(
                meInfo => meInfo.modelElementID === modelElement.modelElementID,
            )?.toothNumber;

            if (modelElement.modelType === 'meDigitalModelDie') {
                return `Die ${meInfoToothNumber}`;
            }

            if (modelElement.modelType === 'meDigitalModelTissue') {
                return `Soft Tissue ${meInfoToothNumber}`;
            }

            if (
                ['meDigitalModelPrepSectioned', 'meDigitalModelPrepUnsectioned', 'meDigitalModelAntagonist'].includes(
                    modelElement.modelType,
                )
            ) {
                return modelElement.modelType.replace('meDigitalModel', '').replace('Prep', '').concat(' Model');
            }
        }

        // meIndicationRegular
        const teeth = DesignCaseFileUtil.getTeethByModelID(toothElements, modelElement.modelElementID);
        const toothNumbers = teeth.map(tE => tE.toothNumber);
        const toothElement = teeth[0];

        if (teeth.length === 0) {
            return undefined;
        }

        if (modelElement.modelType === 'meTransferGuide') {
            return `Insertion Guide ${Math.min(...toothNumbers)}}`.concat(
                toothNumbers.length > 1 ? `x${Math.max(...toothNumbers)}}` : '',
            );
        }

        // meIndication Regular
        if (teeth.length === 1 && toothElement) {
            const cacheToothType = toothElement.cacheToothTypeClass;
            switch (cacheToothType) {
                case 'teCrown':
                case 'teTemporaryVPrepCrown':
                    return `Crown ${toothElement.toothNumber}`;
                case 'teCoping':
                    return `Coping ${toothElement.toothNumber}`;
                case 'teInlay':
                    return `Inlay/Onlay ${toothElement.toothNumber}`;
                case 'teAbutment':
                    return `Abutment ${toothElement.toothNumber}`;
                case 'teArtificialTooth':
                    return `Tooth ${toothElement.toothNumber}`;
                case 'teGingivaFD':
                case 'teGingiva':
                    return getGingivaName(toothNumbers);
            }
        } else {
            // Bridges
            if (
                teeth.some(tE =>
                    ['teTemporaryCrownPontic', 'teTemporaryVPrepCrownPontic', 'tePontic', 'teCrownPontic'].includes(
                        tE.cacheToothTypeClass,
                    ),
                )
            ) {
                return `Bridge ${Math.min(...toothNumbers)}x${Math.max(...toothNumbers)}`;
            }

            // Denture Teeth
            if (teeth.some(tE => tE.cacheToothTypeClass === 'teArtificialTooth')) {
                const hasAnterior = ToothUtils.isAnterior(toothNumbers);
                // careful, isPosterior means isPosteriorOnly
                const hasPosterior = toothNumbers.some(unn => ToothUtils.toothIsPosterior(unn));

                const anatomicRegion =
                    // eslint-disable-next-line no-nested-ternary
                    hasAnterior && !hasPosterior ? ' Anterior' : hasPosterior && !hasAnterior ? ' Posterior' : '';
                const jaw = toothNumbers.some(unn => ToothUtils.isToothNumber(unn) && ToothUtils.toothIsUpper(unn))
                    ? 'Upper'
                    : 'Lower';
                return `DentureTeeth ${jaw}${anatomicRegion}`;
            }
            // Gingiva
            if (teeth.some(tE => ['teGingivaFD', 'teGingiva'].includes(tE.cacheToothTypeClass))) {
                return getGingivaName(toothNumbers);
            }
            // RPD Framework
            if (teeth.some(tE => ['teRPD', 'teClasp'].includes(tE.cacheToothTypeClass))) {
                return `RPD Framework ${
                    toothNumbers.some(unn => ToothUtils.isToothNumber(unn) && ToothUtils.toothIsUpper(unn))
                        ? 'Upper'
                        : 'Lower'
                }`;
            }
        }

        return undefined;
    }

    /*
     * For objects that are meIndicationRegular and potentially
     * multiunit, determine what the most common toothCacheType is
     * */
    static getPredominantToothElementType(
        modelElement: Pick<ModelElement, 'modelType' | 'modelElementID'>,
        toothElements: Pick<ToothElement, 'modelElementID' | 'toothNumber' | 'cacheToothTypeClass'>[],
    ): string | undefined {
        if (!modelElement || !isSupportedModelType(modelElement)) {
            return undefined;
        }
        const teeth = DesignCaseFileUtil.getTeethByModelID(toothElements, modelElement.modelElementID);
        if (teeth.length === 0) {
            return undefined;
        }

        const typeCounts: Map<string, number> = new Map<string, number>();

        teeth.forEach(tE => {
            typeCounts.set(tE.cacheToothTypeClass, typeCounts.get(tE.cacheToothTypeClass) ?? 0 + 1);
        });

        let bestCount = 0;
        let bestType: string | undefined;
        for (const entry of typeCounts) {
            const toothType = entry[0];
            const typeCount = entry[1];
            if (typeCount > bestCount) {
                bestType = toothType;
                bestCount = typeCount;
            }
        }

        return bestType;
    }

    static getReasonableCadNameFromModelElement(
        toothElements: Pick<ToothElement, 'modelElementID' | 'toothNumber' | 'cacheToothTypeClass'>[],
        modelElement: Pick<ModelElement, 'modelType' | 'modelElementID'> | undefined,
    ): { unns: number[]; name: string | undefined } {
        if (!modelElement || !isSupportedModelType(modelElement)) {
            return { unns: [], name: undefined };
        }
        const teeth = DesignCaseFileUtil.getTeethByModelID(toothElements, modelElement.modelElementID);
        const unns = _.uniq(teeth.map(tooth => tooth.toothNumber));

        return {
            unns,
            name: this.getNameForModelElement(modelElement, toothElements, undefined),
        };
    }

    static getCadType(
        toothElements: Pick<ToothElement, 'modelElementID' | 'toothNumber' | 'cacheToothTypeClass'>[],
        modelElement: Pick<ModelElement, 'modelType' | 'modelElementID'> | undefined,
    ): CadType | undefined {
        if (!modelElement || !isSupportedModelType(modelElement)) {
            return undefined;
        }
        const teeth = DesignCaseFileUtil.getTeethByModelID(toothElements, modelElement.modelElementID);

        if (teeth.some(tE => ['teGingivaFD', 'teGingiva'].includes(tE.cacheToothTypeClass))) {
            return CadType.Gingiva;
        }
        if (teeth.some(tE => tE.cacheToothTypeClass === 'teSplint')) {
            return CadType.Splint;
        }
        return undefined;
    }
}
