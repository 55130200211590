import { FileList } from '../PhotoUpload/FileList';
import type { UploadedFile } from './FileUploader.utils';
import { Text, Grid } from '@orthly/ui-primitives';
import React from 'react';

export interface UploadedFilesProps {
    files: UploadedFile[];
    onRemoveFile: (name: string) => void;
    maxHeight?: number;
    maxThumbnailSize?: number;
}

export const UploadedFiles: React.VFC<UploadedFilesProps> = ({ files, onRemoveFile, maxHeight, maxThumbnailSize }) => {
    return (
        <>
            <Grid container justifyContent={'space-between'} style={{ marginBottom: 16 }}>
                <Text variant={'body2'} bold>
                    Your files
                </Text>
                {!!files.length && (
                    <Text variant={'body2'} color={'GRAY'}>
                        Click to view or remove
                    </Text>
                )}
            </Grid>
            {!files.length && (
                <Text variant={'body2'} color={'GRAY'}>
                    You can view all of the files you upload here.
                </Text>
            )}
            <FileList
                files={files}
                onRemove={onRemoveFile}
                maxHeight={maxHeight ?? 280}
                maxThumbnailSize={maxThumbnailSize}
            />
        </>
    );
};
