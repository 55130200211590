/* eslint-disable max-lines */
import { useFirebaseStorage } from '../../context';
import { getFirebaseDownloadUrl } from '../../hooks';
import type { CacheableModelPayload } from './ModelAssetCacheContext';
import { ModelAssetCacheContext } from './ModelAssetCacheContext';
import type { FirebasePreviewFileMultiWithType } from './OrderDesignPreview.types';
import { getDesignPayloadItems, loadColorMapFromImage, loadModelFromBuffer } from './OrderDesignPreview.util';
import type { DesignModelPathsOpts } from './getDesignModelPaths';
import { getDesignModelPaths } from './getDesignModelPaths';
import { getScanPaths } from './getScanPaths';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { DesignCaseParserOrder, Model, ModelAppearance, ModelPayload, ModelPayloadItem } from '@orthly/dentin';
import {
    Jaw,
    isPrimaryDynamicHeatmapsDataAvailable,
    setAppearanceForCadHeatmaps,
    stlFileUriToLabel,
} from '@orthly/dentin';
import type { Point3DArray } from '@orthly/forceps';
import { AttributeName, ComputeVertexNormalsByAngle, DesignCaseFileUtil } from '@orthly/forceps';
import type { DesignModelPayloadsDesignRevision_FragmentFragment, FragmentType } from '@orthly/graphql-inline-react';
import { getFragmentData, graphql } from '@orthly/graphql-inline-react';
import { useScanWithAssetsQuery } from '@orthly/graphql-react';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import { isArrayMin3 } from '@orthly/runtime-utils';
import type { DesignAccelerationData, InternalDesignMetadata } from '@orthly/shared-types';
import {
    instanceOfDesignAccelerationData,
    instanceOfDesignMetadata,
    instanceOfInternalDesignMetadata,
} from '@orthly/shared-types';
import { OrthlyBrowserConfig, useMemoizedValue } from '@orthly/ui';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import type Firebase from 'firebase/compat/app';
import _ from 'lodash';
import React from 'react';
import type { AsyncState } from 'react-async-hook';
import { useAsync, useAsyncCallback } from 'react-async-hook';

const MAX_POLLING_ATTEMPTS = 6;

export function usePollForDesigns(args: { refetch?: () => Promise<any> | void; assets?: { file_path: string }[] }) {
    const { refetch, assets } = args;
    const [pollingAttempts, setPollingAttempts] = React.useState<number>();

    const retriggerAndPoll = React.useCallback(() => {
        if (pollingAttempts !== undefined) {
            return;
        }
        void refetch?.();
        setPollingAttempts(1);
    }, [refetch, pollingAttempts]);

    React.useEffect(() => {
        let cancelPoll = false; // To prevent issues when unmounting
        if (assets?.length || (pollingAttempts !== undefined && pollingAttempts >= MAX_POLLING_ATTEMPTS)) {
            setPollingAttempts(undefined);
        }
        if (pollingAttempts !== undefined && pollingAttempts < MAX_POLLING_ATTEMPTS) {
            setTimeout(() => {
                if (cancelPoll) {
                    return;
                }
                void refetch?.();
                setPollingAttempts(curr => (curr ?? 0) + 1);
            }, 5000);
        }
        return () => {
            cancelPoll = true;
        };
    }, [pollingAttempts, assets, refetch]);

    return { retriggerAndPoll, pollingDesigns: pollingAttempts !== undefined };
}

interface ScanData {
    image?: string | undefined;
    name: string;
    type: LabsGqlOrderDesignScanType;
    source: string;
}

async function fetchAndLoadModel(scan: ScanData): Promise<Model> {
    // get the array buffer form the resolved firebase url scan.source
    const res = await axios.get<ArrayBuffer>(scan.source, { responseType: 'arraybuffer' });
    // use convenience util to actually load it into a Model (our Model type)
    // We need the "experimental ply loader" only for QC Proxies
    return loadModelFromBuffer({
        buffer: res.data,
        filePath: scan.name,
        useExperimentalPlyLoader:
            scan.type === LabsGqlOrderDesignScanType.Cad ||
            scan.type === LabsGqlOrderDesignScanType.QcExtras ||
            (scan.type === LabsGqlOrderDesignScanType.Scans && !!scan.image),
        shouldCalculateUVs: !!scan.image,
    });
}

async function loadModelAndColorMap(
    path: FirebasePreviewFileMultiWithType,
    firebase: Firebase.storage.Storage,
    cache: Record<string, CacheableModelPayload>,
): Promise<CacheableModelPayload | undefined> {
    try {
        const cached = cache[path.name];
        if (cached) {
            return cached;
        }
        const [source, image] = await Promise.all([
            getFirebaseDownloadUrl(firebase, path.source),
            path.image && getFirebaseDownloadUrl(firebase, path.image),
        ]);

        const scan = {
            name: path.name,
            type: path.type,
            source,
            image,
        };

        const [model, colorMap] = await Promise.all([fetchAndLoadModel(scan), loadColorMapFromImage(image)]);
        if (colorMap && ['ply', 'drc'].includes(model.modelType)) {
            // The y-coordinate of the texture files is flipped relative to our encoding in PLY and Draco files.
            colorMap.flipY = false;
        }
        const result = {
            path: path.name,
            type: path.type,
            model,
            colorMap,
        };
        cache[path.name] = result;
        return result;
    } catch (err) {
        console.warn(err);
        return undefined;
    }
}

function getJawType(unns: number[]) {
    if (unns.some(ele => ToothUtils.toothIsUpper(ele))) {
        return Jaw.Upper;
    } else if (unns.some(ele => ToothUtils.toothIsLower(ele))) {
        return Jaw.Lower;
    }
    return undefined;
}

/**
 * Returns metadata fields for a model asset.
 *
 * @param design The design revision
 * @param file Object containing the storage paths for the model and texture assets, as
 *             well as the asset type.
 * @param nameCounter An in-out counter value used to produce unique names for models.
 * @returns Metadata fields for a `ModelPayload` item for the model asset.
 */
export function getModelMetadata(
    design: DesignCaseFile | null | undefined,
    file: FirebasePreviewFileMultiWithType,
    nameCounter: { value: number },
): Omit<ModelPayload, 'model' | 'colorMap'> & { imagePath?: string; type: LabsGqlOrderDesignScanType } {
    const { image, source: path, type } = file;
    const modelInfo = design
        ? DesignCaseFileUtil.getModelElementFromFileName(
              design.case_file_model_elements.map(ele => ({
                  modelElementID: ele.model_element_id,
                  modelFilePath: ele.model_file_name,
                  modelJobID: ele.model_job_id ?? '',
                  modelType: ele.model_element_type ?? 'meIndicationRegular',
                  insertionAxis: ele.insertion_axis,
              })),
              path,
          )
        : undefined;

    const { unns, name: cadName } = design
        ? DesignCaseFileUtil.getReasonableCadNameFromModelElement(
              design.case_file_tooth_elements.map(ele => ({
                  cacheToothTypeClass: ele.tooth_type_class ?? '',
                  modelElementID: ele.model_element_id,
                  toothNumber: ele.tooth_number,
              })),
              modelInfo,
          )
        : { unns: [], name: undefined };

    let name: string;
    if (modelInfo?.modelType === 'meDigitalModelDie') {
        name = `Die Item (${String(nameCounter.value++).padStart(3, '0')})`;
    } else if (type === LabsGqlOrderDesignScanType.Cad) {
        name = cadName ?? `CAD Item (${String(nameCounter.value++).padStart(3, '0')})`;
    } else {
        name = stlFileUriToLabel(path);
    }

    const insertionAxis: Point3DArray | undefined =
        modelInfo?.insertionAxis && isArrayMin3(modelInfo?.insertionAxis ?? [])
            ? [modelInfo.insertionAxis[0] ?? 0, modelInfo.insertionAxis[1] ?? 0, modelInfo.insertionAxis[2] ?? 0]
            : undefined;
    const cadType = design
        ? DesignCaseFileUtil.getCadType(
              design.case_file_tooth_elements.map(ele => ({
                  cacheToothTypeClass: ele.tooth_type_class ?? '',
                  modelElementID: ele.model_element_id,
                  toothNumber: ele.tooth_number,
              })),
              modelInfo,
          )
        : undefined;
    return {
        path,
        name,
        imagePath: image,
        type,
        unns,
        insertionAxis,
        jaw: getJawType(unns),
        modelElementID: modelInfo?.modelElementID,
        cadType,
    };
}

/**
 * Fetches 3D file payloads for the design.
 *
 * @param files Model and image (texture) paths to load
 * @param context Provides location for analytics
 * @returns A UseAsyncReturn of (CacheableModelPayload | undefined)[]. Entries in the returned array are in
 *      the same order as `paths`, and will be `undefined` if there was a failure to fetch the data.
 */
function useDesignAssets(files: FirebasePreviewFileMultiWithType[], context: { location: string }) {
    // Use cache from ModelAssetCacheContext, falling back to local cache.
    const [localCache] = React.useState({});
    const { modelAssetCache = localCache } = React.useContext(ModelAssetCacheContext) ?? {};
    const firebase = useFirebaseStorage();

    return useAsync<(CacheableModelPayload | undefined)[] | undefined>(async () => {
        if (!files.length) {
            return [];
        }
        const loadStartTime = Date.now();
        const cacheablePayloads = await Promise.all(
            files.map(file => loadModelAndColorMap(file, firebase, modelAssetCache)),
        );
        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Model Asset Loading', {
            assetDownloadTimeMs: Date.now() - loadStartTime,
            location: context.location,
        });
        return cacheablePayloads;
    }, [files, firebase, modelAssetCache]);
}

/**
 * Fetches 3D file payloads for the design.
 *
 * @param paths Paths to load
 * @param design The corresponding design data
 * @param context Extra context about how to load assets
 * @returns Loaded ModelPayloads
 */
export function useDesignModelPayloadsInner(
    paths: FirebasePreviewFileMultiWithType[],
    design: DesignCaseFile | null | undefined,
    context: {
        location: string;
    },
): AsyncState<ModelPayload[]> {
    const assetsState = useDesignAssets(paths, context);

    const payloads: ModelPayload[] | undefined = React.useMemo(() => {
        if (!assetsState.result) {
            return undefined;
        }
        const nameCounter = { value: 1 };
        const zipped = _.zipWith(
            assetsState.result,
            paths,
            (asset, path) =>
                asset &&
                path && {
                    ...getModelMetadata(design, path, nameCounter),
                    model: asset.model,
                    colorMap: asset.colorMap,
                },
        );
        return _.compact(zipped);
    }, [design, paths, assetsState.result]);

    return {
        ...assetsState,
        result: payloads,
    };
}

/**
 * Returns model payloads for the scan file specified by `scanExportId`.
 * Pass `null` to skip loading.
 */
export function useDesignModelPayloadsForScan(scanExportId: string | null, design?: DesignCaseFile) {
    const { data } = useScanWithAssetsQuery({
        variables: {
            scanId: scanExportId ?? '',
        },
        skip: !scanExportId,
    });
    const paths = React.useMemo(() => {
        return data ? getScanPaths(data.scan_file, { enableDraco: true }) : [];
    }, [data]);
    return useDesignModelPayloadsInner(paths, design, { location: 'Scan Viewer' });
}

const DesignModelPayloadsDesignRevision_Fragment = graphql(`
    fragment DesignModelPayloadsDesignRevision_Fragment on DesignOrderDesignRevisionDTO {
        assets {
            file_path
            file_type
            mesh_type
            image_path
        }

        case_file_model_elements {
            model_element_id
            model_file_name
            insertion_axis
            model_job_id
            model_element_type
        }

        case_file_tooth_elements {
            model_element_id
            tooth_element_id
            tooth_number
            tooth_type_class
        }
    }
`);

export type DesignCaseFile = Pick<
    DesignModelPayloadsDesignRevision_FragmentFragment,
    'case_file_model_elements' | 'case_file_tooth_elements'
>;

/** The metadata of a ModelPayloadItem; everything but the model (and colorMap) payload. */
export type ModelMetadataItem = Omit<ModelPayloadItem, 'model' | 'colorMap'> & {
    imagePath?: string;
    // `type` is optional in ModelPayloadItem but required here.
    type: LabsGqlOrderDesignScanType;
};

export interface UseDesignModelPayloadsOpts extends DesignModelPathsOpts {
    filterAndMap?: (modelMetadata: ModelMetadataItem[]) => ModelMetadataItem[];
    isImmediateDenture?: boolean;
}

export function useDesignModelPayloads(
    designFragment: FragmentType<typeof DesignModelPayloadsDesignRevision_Fragment> | null | undefined,
    order: DesignCaseParserOrder,
    opts: UseDesignModelPayloadsOpts = {},
): AsyncState<ModelPayloadItem[]> {
    const design = getFragmentData(DesignModelPayloadsDesignRevision_Fragment, designFragment);

    const memoizedOpts = useMemoizedValue(opts);
    const filteredMetadataItems = React.useMemo(() => {
        if (!design?.assets) {
            return [];
        }

        const paths = getDesignModelPaths(design.assets, memoizedOpts);
        const nameCounter = { value: 1 };
        const modelMetadata = paths.map(path => getModelMetadata(design, path, nameCounter));
        const metadataItems: ModelMetadataItem[] = getDesignPayloadItems(
            order,
            modelMetadata,
            design,
            memoizedOpts.isImmediateDenture,
        );
        const { filterAndMap } = memoizedOpts;
        return filterAndMap ? filterAndMap(metadataItems) : metadataItems;
    }, [design, order, memoizedOpts]);

    const pathsToFetch = React.useMemo(
        () =>
            filteredMetadataItems.map(item => ({
                type: item.type,
                name: item.path,
                source: item.path,
                image: item.imagePath,
            })),
        [filteredMetadataItems],
    );

    const assetsState = useDesignAssets(pathsToFetch, { location: 'Design Viewer' });

    const completePayloads: ModelPayloadItem[] | undefined = React.useMemo(() => {
        if (!assetsState.result) {
            return undefined;
        }
        const zipped: (ModelPayloadItem | undefined)[] = _.zipWith(
            filteredMetadataItems,
            assetsState.result,
            (partialPayload, asset) => {
                return (
                    asset &&
                    partialPayload && {
                        ...partialPayload,
                        model: asset.model,
                        colorMap: asset.colorMap,
                    }
                );
            },
        );
        return _.compact(zipped);
    }, [assetsState.result, filteredMetadataItems]);

    return {
        ...assetsState,
        result: completePayloads,
    };
}

export function useDesignXmlPayload(path?: string) {
    const [previous, setPrevious] = React.useState<string>();
    const firebase = useFirebaseStorage();
    const cb = useAsyncCallback(async (path?: string) => {
        if (!path) {
            return undefined;
        }
        const source = await getFirebaseDownloadUrl(firebase, path);
        try {
            const res = await axios.get<string | unknown>(source, { responseType: 'text' });
            if (typeof res.data !== 'string') {
                return JSON.stringify(res.data);
            }

            return res.data;
        } catch (_err: any) {
            return undefined;
        }
    });

    React.useEffect(() => {
        if (path && !_.isEqual(path, previous)) {
            setPrevious(path);
            void cb.execute(path).then();
        }
    }, [cb, path, previous]);

    return cb;
}

const EMPTY_METADATA: InternalDesignMetadata = {
    unnForFilePath: {},
    metadata_missing: true,
    feature_extraction_version: '',
    qc_analysis_version: '',
    transforms_applied: false,
    internal_design_processing: 'NOTUSED',
    total_mb_scans: 0,
};

export function useDesignMetadata(metadata_path?: string | null): InternalDesignMetadata {
    const { result: metadataFile } = useDesignXmlPayload(metadata_path ?? undefined);

    return React.useMemo<InternalDesignMetadata>(() => {
        try {
            //  We occasionally have Infinity in metadata values which fails to parse in JSON
            //  This breaks margin visibility in portal because too many things are packed into metadata.json
            //  There are no other uses impacted by changing Infinity to a large number
            //  TODO DND-2884
            const cleanedMetadata = metadataFile?.replace(' Infinity', '1000000');
            const metadata = JSON.parse(cleanedMetadata ?? '{}');

            // has none of the required fields
            if (!instanceOfDesignMetadata(metadata)) {
                return EMPTY_METADATA;
            }
            // has only the old required fields
            if (!instanceOfInternalDesignMetadata(metadata)) {
                return {
                    ...EMPTY_METADATA,
                    unnForFilePath: metadata['unnForFilePath'],
                };
            }
            // has it all
            return metadata;
        } catch (_error: any) {
            // This error gets thrown in QA when the design file is not found, which happens a lot.
            // Since this is expected, and the resulting errors reported to Sentry
            // are not actionable, we only want to log this error in production.
            if (OrthlyBrowserConfig.isProduction) {
                Sentry.captureException(new Error('Bad 3D Design metadata'), {
                    extra: { metadata_path },
                });
            }
            return EMPTY_METADATA;
        }
    }, [metadataFile, metadata_path]);
}

export function useDesignAccelerationdata(path?: string | null): DesignAccelerationData {
    const { result: metadataFile } = useDesignXmlPayload(path ?? undefined);

    return React.useMemo<DesignAccelerationData>(() => {
        try {
            const metadata = JSON.parse(metadataFile ?? '[]');
            if (!instanceOfDesignAccelerationData(metadata)) {
                Sentry.captureException(new Error('Bad 3D Design acceleration data'), {
                    extra: { path },
                });
                return [];
            }
            return metadata;
        } catch (_error: any) {
            // This error gets thrown in QA when the design file is not found, which happens a lot.
            // Since this is expected, and the resulting errors reported to Sentry
            // are not actionable, we only want to log this error in production.
            if (OrthlyBrowserConfig.isProduction) {
                Sentry.captureException(new Error('Bad 3D Design acceleration data'), {
                    extra: { path },
                });
            }
            return [];
        }
    }, [metadataFile, path]);
}

export const useDynamicShadersForHeatmaps = (
    model_payload_items: ModelPayloadItem[],
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>,
) => {
    const [shadersEnabled, setShadersEnabled] = React.useState<boolean>(false);
    // If we have our existing float attributes, enable the shaders
    React.useEffect(() => {
        const cadHasAttrs = isPrimaryDynamicHeatmapsDataAvailable(
            model_payload_items.filter(pi => pi.type === LabsGqlOrderDesignScanType.Cad),
        );

        if (!cadHasAttrs || shadersEnabled) {
            return;
        }

        model_payload_items
            .filter(pi => pi.type === LabsGqlOrderDesignScanType.Cad)
            .forEach(pi => {
                if (!pi.model.geometry.hasAttribute(AttributeName.Normal)) {
                    // this is to ensure consistent behavior with 3Shape's normal method
                    ComputeVertexNormalsByAngle(pi.model.geometry);
                }
            });

        setAppearance && setAppearanceForCadHeatmaps(setAppearance);
        setShadersEnabled(true);
    }, [model_payload_items, setAppearance, shadersEnabled, setShadersEnabled]);

    return shadersEnabled;
};
