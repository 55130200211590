import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import type { ModelPayloadItem } from './ModelViewerTypes';
import type { ToothPrepDataMap } from './PrepDesign.hooks';
import { getCameraPoseForOcclusal } from './ProximalContactsUI';
import { updateCameraFromPose } from './utils3d';
import type { ToothNumber } from '@orthly/items';
import { IconToggleButton, PoiIcon } from '@orthly/ui';
import { Menu, MenuItem } from '@orthly/ui-primitives';
import React from 'react';
import * as THREE from 'three';

function focusOnPathOfInsertion(controlRef: MainViewCameraControlsRef, item: ModelPayloadItem) {
    const camera = controlRef.current?.object;
    if (!camera) {
        return;
    }

    const cameraPose = getCameraPoseForOcclusal(item);
    updateCameraFromPose(controlRef, cameraPose, 75);
}

// When there are multiple items with insertion axes, this menu allows selection of which one to focus on.
const PoiSelectMenu: React.VFC<{
    controlRef: MainViewCameraControlsRef;
    items: ModelPayloadItem[];
    anchorEl?: Element | null;
    onClose: () => void;
}> = ({ controlRef, items, anchorEl, onClose }) => {
    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            onClose={onClose}
            anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
            transformOrigin={{ vertical: 'center', horizontal: 'left' }}
            // Workaround for bug where otherwise the <body> gets 15px right padding when the menu is open.
            disableScrollLock={true}
        >
            {items.map(item => {
                return (
                    <MenuItem
                        key={item.name}
                        onClick={() => {
                            focusOnPathOfInsertion(controlRef, item);
                            onClose();
                        }}
                    >
                        {item.name}
                    </MenuItem>
                );
            })}
        </Menu>
    );
};

// Renders an "IconToggleButton" for the POI button and a menu to select which
// restorative item to focus on when there are multiple.
export const SnapToPoiButton: React.VFC<{
    controlRef: MainViewCameraControlsRef;
    payloadItems: ModelPayloadItem[];
    prepData: ToothPrepDataMap;
    className?: string;
}> = ({ controlRef, payloadItems, prepData, className }) => {
    const [poiItemSelectMenuAnchorEl, setPoiItemSelectMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const restorativeItems = useRestorativeItems(payloadItems, prepData);

    const onPoiButtonClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (restorativeItems.length === 1) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            focusOnPathOfInsertion(controlRef, restorativeItems[0]!);
        } else {
            setPoiItemSelectMenuAnchorEl(event.currentTarget);
        }
    };

    return (
        <>
            <IconToggleButton
                tooltip={'Snap to POI'}
                active={false}
                onClick={evt => onPoiButtonClick(evt)}
                className={className}
            >
                <PoiIcon />
            </IconToggleButton>
            <PoiSelectMenu
                controlRef={controlRef}
                items={restorativeItems}
                anchorEl={poiItemSelectMenuAnchorEl}
                onClose={() => setPoiItemSelectMenuAnchorEl(null)}
            />
        </>
    );
};

/**
 * Returns the restorative model payload items, or if there are none, creates stub items for each tooth with prep data.
 */
function useRestorativeItems(payloadItems: ModelPayloadItem[], prepData: ToothPrepDataMap): ModelPayloadItem[] {
    return React.useMemo(() => {
        const restorativeItems = payloadItems.filter(i => i.isRestorative);
        if (restorativeItems.length) {
            return restorativeItems;
        }

        return Array.from(prepData.entries()).map(([toothNumber, prepData]) =>
            createStubModelPayloadItem(toothNumber, prepData.insertionAxis, prepData.marginCentroid),
        );
    }, [payloadItems, prepData]);
}

/**
 * Creates a stub model payload item with enough data for `getCameraPoseForOcclusal` to work.
 */
function createStubModelPayloadItem(
    toothNumber: ToothNumber,
    insertionAxis: THREE.Vector3,
    marginCentroid: THREE.Vector3,
): ModelPayloadItem {
    const stubRestorativeGeometry = new THREE.BoxBufferGeometry();
    const center = marginCentroid.clone().addScaledVector(insertionAxis, 2);
    stubRestorativeGeometry.translate(...center.toArray());

    return {
        name: `Crown ${toothNumber}`,
        path: '',
        isRestorative: true,
        isPrintedModel: false,
        insertionAxis: insertionAxis.toArray(),
        model: {
            modelType: 'stl',
            geometry: stubRestorativeGeometry,
        },
    };
}
