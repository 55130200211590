import type { CrossSectionAppState, CrossSectionHooksApp } from './CrossSection.hooks';
import { CrossSectionView } from './CrossSectionView';
import { AddCrossSectionIcon, XYZArrowsIcon } from '@orthly/ui';
import { FlossPalette, Text, IconButton, Tooltip } from '@orthly/ui-primitives';
import React from 'react';

type CrossSectionViewPanelType = React.FC<{
    enableIdenticalPanningBehaviour?: boolean;
    crossSectionApp: CrossSectionHooksApp;
    crossSectionState: CrossSectionAppState;
}>;

const styleActive = { color: FlossPalette.PRIMARY_FOREGROUND };
const styleInactive = { color: FlossPalette.GRAY };

export const CrossSectionViewPanel: CrossSectionViewPanelType = ({
    enableIdenticalPanningBehaviour,
    crossSectionApp,
    crossSectionState,
}) => {
    if (!crossSectionState.appActive.open) {
        return null;
    }
    return (
        <CrossSectionView
            enableIdenticalPanningBehaviour={enableIdenticalPanningBehaviour}
            onClose={() => crossSectionState.appActive.toggle(false, false)}
            crossSection={crossSectionApp.crossSectionData}
            crossSectionUpToDate={crossSectionApp.crossSectionPlaneActive}
        >
            <Tooltip title={'New Cross Section'} tabIndex={1}>
                <IconButton
                    aria-label={'New Cross Section'}
                    disabled={!crossSectionApp.crossSectionPlaneActive}
                    onClick={() => {
                        crossSectionApp.clearCrossSectionPlane();
                    }}
                >
                    <AddCrossSectionIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title={'Restore Camera View'} tabIndex={3}>
                <IconButton
                    aria-label={'Restore Camera View'}
                    onClick={() => {
                        crossSectionApp.restoreCameraView(crossSectionApp.crossSectionCameraView);
                    }}
                    disabled={!crossSectionApp.crossSectionCameraView}
                >
                    <XYZArrowsIcon />
                </IconButton>
            </Tooltip>
            <Tooltip
                title={crossSectionState.clippingPlane.open ? 'Disable Clipping Plane' : 'Enable Clipping Plane'}
                tabIndex={4}
            >
                <IconButton
                    aria-label={
                        crossSectionState.clippingPlane.open ? 'Disable Clipping Plane' : 'Enable Clipping Plane'
                    }
                    style={crossSectionState.clippingPlane.open ? styleInactive : styleActive}
                    onClick={() => crossSectionState.clippingPlane.toggle(false)}
                >
                    <Text style={crossSectionState.clippingPlane.open ? styleInactive : styleActive} variant={'body1'}>
                        C
                    </Text>
                </IconButton>
            </Tooltip>
            <Tooltip
                title={crossSectionState.reversePlane.open ? 'Disable Reverse Plane' : 'Enable Reverse Plane'}
                tabIndex={5}
            >
                <IconButton
                    aria-label={crossSectionState.reversePlane.open ? 'Disable Reverse Plane' : 'Enable Reverse Plane'}
                    style={crossSectionState.reversePlane.open ? styleInactive : styleActive}
                    onClick={() => crossSectionState.reversePlane.toggle(false)}
                >
                    <Text style={crossSectionState.reversePlane.open ? styleInactive : styleActive} variant={'body1'}>
                        R
                    </Text>
                </IconButton>
            </Tooltip>
        </CrossSectionView>
    );
};
