import type { ModelAppearanceControllerProps } from './ModelAppearanceController';
import { ModelAppearanceController } from './ModelAppearanceController';
import type {
    DisabledControls,
    ItemAppearance,
    ItemAppearanceFlags,
    PayloadModelAppearance,
} from './ModelAppearanceTypes';
import { DEFAULT_TRANSPARENT_OPACITY } from './Transparent.util';
import { Collapse } from '@orthly/ui-primitives';
import React from 'react';

/**
 * Helper to compute the group value for a given key across a set of payload sub-values.
 * The group value will stay the same as the default value until all the sub-values have been clicked.
 *
 * @param key The key to check on each payload.
 * @param subjects The payloads to check.
 * @param defaultValue The value's default.
 * @returns
 */
function groupValue<K extends keyof ItemAppearance>(
    key: K,
    subjects: PayloadModelAppearance[],
    defaultValue: ItemAppearance[K],
    nonDefaultValue: ItemAppearance[K],
): ItemAppearance[K] {
    const allClicked = subjects.map(s => s.appearance[key]).every(v => v !== defaultValue);
    return allClicked ? nonDefaultValue : defaultValue;
}

function groupValueBool(
    key: keyof ItemAppearanceFlags,
    subjects: PayloadModelAppearance[],
    defaultValue: boolean,
): boolean {
    return groupValue(key, subjects, defaultValue, !defaultValue);
}

export function getCurrentGroupAppearance(
    appearanceArray: PayloadModelAppearance[],
    disabledControls: Partial<DisabledControls>,
): ItemAppearance {
    return {
        colorize: !disabledControls.colorize && groupValueBool('colorize', appearanceArray, false),
        showInsertionAxis:
            !disabledControls.insertionAxis && groupValueBool('showInsertionAxis', appearanceArray, false),
        showUndercutShadow:
            !disabledControls.undercutArrowAndShadow && groupValueBool('showUndercutShadow', appearanceArray, false),
        showUndercutCurtains:
            !disabledControls.undercutCurtains && groupValueBool('showUndercutCurtains', appearanceArray, false),
        visible: groupValueBool('visible', appearanceArray, true),
        opacity: disabledControls.transparency
            ? 1
            : groupValue('opacity', appearanceArray, 1, DEFAULT_TRANSPARENT_OPACITY),
    };
}

type GroupAppearanceControllerProps = Pick<
    ModelAppearanceControllerProps,
    'text' | 'disabledControls' | 'solo' | 'onSoloChange' | 'isPast' | 'variant' | 'className'
> & {
    appearanceArray: PayloadModelAppearance[];
    onAppearanceChange: (newAppearance: PayloadModelAppearance[]) => any;

    children: React.ReactNode;

    alwaysOpen?: boolean;
};

/**
 * Controls the appearance of a group of related models (e.g. transparent/solo/hide the entire group).
 *
 * @component
 */
export const GroupAppearanceController: React.FC<GroupAppearanceControllerProps> = props => {
    const {
        text,
        solo,
        onSoloChange,
        disabledControls,
        appearanceArray,
        onAppearanceChange,
        children,
        isPast,
        alwaysOpen,
        variant,
        className,
    } = props;
    const [headerExpanded, setHeaderExpanded] = React.useState(false);
    const setAppearance = (update: (current: ItemAppearance) => ItemAppearance) => {
        const newSettings = appearanceArray.map(oldPayloadModel => {
            return {
                payloadModel: oldPayloadModel.payloadModel,
                appearance: update(oldPayloadModel.appearance),
            };
        });

        onAppearanceChange(newSettings);
    };

    // NOTE: a group field is enabled when all the subvalues have been clicked
    const currentGroupAppearance = getCurrentGroupAppearance(appearanceArray, disabledControls);

    const onCollapseChange = () => {
        setHeaderExpanded(current => !current);
    };
    const headerController = (
        <ModelAppearanceController
            key={'group_header'}
            text={text}
            header={true}
            solo={solo}
            onSoloChange={onSoloChange}
            disabledControls={disabledControls}
            setAppearance={setAppearance}
            appearance={currentGroupAppearance}
            onCollapseChange={alwaysOpen ? undefined : onCollapseChange}
            headerExpanded={alwaysOpen || headerExpanded}
            isPast={isPast}
            variant={variant}
            className={className}
        />
    );

    return (
        <>
            {headerController}
            {alwaysOpen ? (
                children
            ) : (
                <Collapse in={headerExpanded} timeout={'auto'} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
};
