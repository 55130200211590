import type { AttributeName } from './BufferAttributeConstants';
import type * as THREE from 'three';

export type IndexedBufferGeometry = Omit<THREE.BufferGeometry, 'getIndex' | 'clone' | 'index' | 'setIndex'> & {
    index: THREE.BufferAttribute;
    getIndex(): THREE.BufferAttribute;
    setIndex(index: THREE.BufferAttribute | number[]): IndexedBufferGeometry;
    clone(): IndexedBufferGeometry;
};

/**
 * A type that represents a buffer geometry that's guaranteed to have particular attributes.
 */
export type GeometryWithAttributes<A extends AttributeName> = Omit<
    THREE.BufferGeometry,
    'attributes' | 'hasAttribute' | 'deleteAttribute' | 'getAttribute'
> & {
    hasAttribute<Attr extends A>(attr: Attr): true;
    hasAttribute(attr: string): boolean;
    deleteAttribute<Attr extends A>(attr: Attr): never;
    deleteAttribute(attr: string): GeometryWithAttributes<A>;
    getAttribute<Attr extends A>(attr: Attr): THREE.BufferAttribute | THREE.InterleavedBufferAttribute;
    getAttribute(attr: string): THREE.BufferAttribute | THREE.InterleavedBufferAttribute | undefined;
    attributes: {
        [K in A]: THREE.BufferAttribute | THREE.InterleavedBufferAttribute;
    } & THREE.BufferGeometry['attributes'];
};

export type GeometryWithStandardAttributes = GeometryWithAttributes<
    AttributeName.Position | AttributeName.Normal | AttributeName.TexCoord
>;
