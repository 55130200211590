import { ThreeOxzUtil } from './ThreeOxz.util';
import type { Vector3, MarginLine } from './ThreeOxzGenerator.types';
import { encode } from 'base64-arraybuffer';
import _ from 'lodash';
import { create as createXML } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';

// This process is heavily based on the research done by Patrick Moore.
// https://www.notion.so/orthly/3Shape-Margin-Injection-into-DCM-71210a049a2e4ce683ac9a5847fc4408
// This is a rough Typescript port of his Python code.
export class DcmMarginInjector {
    static readonly PADDING_VALUE: number = 16711680;

    static pointsToPaddedArray(points: Vector3[]): Float32Array {
        const paddedValues = points.map<[number, number, number, number]>(point => [
            point.x,
            point.y,
            point.z,
            DcmMarginInjector.PADDING_VALUE,
        ]);
        return new Float32Array(_.flatten(paddedValues));
    }

    static pointsToBase64(points: Vector3[]): string {
        const arr = DcmMarginInjector.pointsToPaddedArray(points);
        return encode(arr.buffer);
    }

    static createMarginLineNode(marginLine: MarginLine): XMLBuilder {
        const base64Points = DcmMarginInjector.pointsToBase64(marginLine.refinedPoints);
        const node = createXML();
        const object = node.ele('Object', { name: 'Spline', type: 'TSysSpline' });
        ThreeOxzUtil.addPropertyFields(object, {
            iMisc1: `1`,
            SplineType: '0',
            Closed: 'True',
            Radius: '0.07',
            DrawPixels: 'False',
            RoundEnds: 'False',
            SplineParameter: '0',
            Color: '0',
            Precision: '0.00',
            Name: `MarginLine_${marginLine.unn}`,
            PointColorHigh: '0',
            DragParameter: '0',
            DragScaleFactor: '0',
            DragStartPointFixed: 'False',
            DragEndPointFixed: 'False',
            ImplicitSurface: 'False',
            PointSize: '0.07',
            UseVertexColor: 'False',
            PointColor: '0',
            Visible: 'True',
            PointsVisible: 'False',
            AllSet: 'False',
            HandleAsClosedSpline: 'False',
            HighlightFirstControlPoint: 'False',
            HighlightLastControlPoint: 'False',
            TraceMethod: 'stmProjection',
            VisualSamplingFactor: '0',
            JumpBoundary: 'False',
        });
        object.ele('ControlPointsPacked').txt(base64Points);

        return object;
    }

    static findOrCreateSplines(root: XMLBuilder): XMLBuilder {
        const hps = root.find(e => e.node.nodeName === 'HPS');

        if (!hps) {
            throw new Error('Failed to find HPS');
        }

        const splines = hps.find(e => e.node.nodeName === 'Splines');
        if (splines) {
            // Remove all existing splines
            splines.each(spline => spline.remove());
            return splines;
        }

        return hps.ele('Splines');
    }

    private static injectMarginLine(root: XMLBuilder, marginLine: MarginLine) {
        root.import(DcmMarginInjector.createMarginLineNode(marginLine));
    }

    // Internal function for generating an XMLBuilder representation of the DCM with the margins injected.
    // Exposed publicly for testing only -- you should certainly be calling `injectMarginsToString`.
    static async injectMargins(dcmBuffer: Buffer, marginLines: MarginLine[]): Promise<XMLBuilder> {
        const root = createXML(dcmBuffer.toString());
        const splines = DcmMarginInjector.findOrCreateSplines(root);
        marginLines.forEach(marginLine => DcmMarginInjector.injectMarginLine(splines, marginLine));
        return root;
    }

    // Similar to `injectMargins`, but will overwrite the provided file after injection.
    static async injectMarginsToString(dcmBuffer: Buffer, marginLines: MarginLine[]): Promise<string> {
        const root = await DcmMarginInjector.injectMargins(dcmBuffer, marginLines);

        // Headless printing is important to avoid emitting the <xml> header.
        return root.end({ prettyPrint: true, headless: true });
    }
}
