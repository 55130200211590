import { SignUpPageContainer } from '../SignUpPageContainer';
import React from 'react';

export const SignUpBillingRoot: React.FC = () => {
    return (
        <SignUpPageContainer
            header={'Enter your billing details'}
            subheader={'These details will be saved to your account. You will not be charged at this time.'}
        >
            <p>Billing form</p>
        </SignUpPageContainer>
    );
};
