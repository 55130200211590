/* eslint-disable max-lines */
import { useCheckoutPropSelector, useCheckoutSelector } from '../../../redux/selectors';
import { SelectExtrasSection } from '../screens/CheckoutExtrasScreen';
import { CheckoutItemDataFieldsSection } from '../screens/CheckoutItemsStep/fields/CheckoutItemDataFields';
import { CheckoutItemMetafieldsSection } from '../screens/CheckoutItemsStep/fields/CheckoutItemMetafields';
import { CheckoutItemMultiToothField } from '../screens/CheckoutItemsStep/fields/CheckoutItemMultiToothField';
import { CheckoutItemSelectField } from '../screens/CheckoutItemsStep/fields/CheckoutItemSelectField';
import { CheckoutShadePicker } from '../screens/CheckoutItemsStep/fields/CheckoutShadePicker';
import { CheckoutSuggestedOrder } from '../screens/CheckoutItemsStep/fields/CheckoutSuggestedOrder';
import { CheckoutSurgicalGuideImplantMetadataFields } from '../screens/CheckoutItemsStep/fields/CheckoutSurgicalGuideImplantMetadataFields';
import { ImplantRelationshipFields } from '../screens/CheckoutItemsStep/fields/ImplantRelationshipFields';
import { ItemNotesV2 } from '../screens/CheckoutItemsStep/fields/ItemNotesV2';
import { MaterialFields } from '../screens/CheckoutItemsStep/fields/MaterialFields';
import type { CheckoutScreen } from '../screens/CheckoutScreen';
import { CheckoutItemV2Manager } from './CheckoutItemV2Manager';
import { useCheckoutAction } from './checkout.actions';
import type { CheckoutFeatureFlags, CheckoutItemV2, CheckoutState } from './checkout.state';
import { isCheckoutItemV2WithImplant } from './reducers/checkout.state';
import { screensForImplantItem } from './reducers/implantScreens';
import { bulkItemsSelector } from './selectors/bulk-items.selectors';
import { ExtraCartItemV2DisplayUtils, CheckoutTextButton, CheckoutBodyWrapper } from '@orthly/dentin';
import {
    CartItemV2Utils,
    LabOrderItemSKUType,
    ExtraCartItemV2Utils,
    CheckoutUnknownItemExtraOptions,
    ExtraCartItems,
} from '@orthly/items';
import {
    FlossPalette,
    Collapse,
    Fade,
    Grid,
    Tooltip,
    Text,
    Typography,
    createStyles,
    makeStyles,
    CheckIcon,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useUnitTypeFieldStyles = makeStyles({ imgWrap: { paddingLeft: `1.5rem`, paddingRight: '1.5rem' } });

type DisplayState = {
    needsSelection: boolean;
    isComplete: boolean;
    isActiveItem: boolean;
};

function useUnitTypeFieldDisplayState(item: CheckoutItemV2): DisplayState {
    const { step } = useCheckoutPropSelector(['step']);
    const [activeItemIndex, lastVisitedItemIndex] = useCheckoutSelector(state => [
        getActiveItemIndex(state),
        getLastVisitedItemIndex(state),
    ]);
    const needsSelection = CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Unknown);
    const isComplete = step > 1 || (lastVisitedItemIndex >= item.item_index && !needsSelection);
    const isActiveItem = activeItemIndex === item.item_index && step === 1;
    return { needsSelection, isComplete, isActiveItem };
}

const ResetUnknownUnit: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const changeItemType = useCheckoutAction('CHANGE_ITEM_TYPE');
    const { isActiveItem, needsSelection } = useUnitTypeFieldDisplayState(item);

    // only allow for items that came from unknown items in the scan export
    const maybeExtraUnitType = ExtraCartItemV2Utils.getExtraItemUnitType(item);
    if (
        !item.from_scan_export ||
        !CheckoutUnknownItemExtraOptions.some(extra => extra.unit_type === maybeExtraUnitType)
    ) {
        return null;
    }

    return (
        <Fade in={isActiveItem && !needsSelection}>
            <Grid container justifyContent={'flex-end'}>
                <CheckoutTextButton
                    style={{ padding: 0 }}
                    onClick={() =>
                        changeItemType({
                            item_index: item.item_index,
                            unit_type: 'Unknown',
                        })
                    }
                >
                    Change Item Type
                </CheckoutTextButton>
            </Grid>
        </Fade>
    );
};

const UnitTypeField: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const classes = useUnitTypeFieldStyles();
    const changeItemType = useCheckoutAction('CHANGE_ITEM_TYPE');
    const { isComplete, isActiveItem, needsSelection } = useUnitTypeFieldDisplayState(item);
    const options = React.useMemo(
        () =>
            CheckoutUnknownItemExtraOptions.map(item => ({
                value: item.unit_type,
                label: item.label ?? item.unit_type,
                image_url: ExtraCartItemV2DisplayUtils.getImageForUnitType(item.unit_type),
            })),
        [],
    );

    const selectVisible = isActiveItem && needsSelection;

    return (
        <CheckoutBodyWrapper isComplete={isComplete} visible={selectVisible}>
            <CheckoutItemSelectField
                required={true}
                options={options}
                onChange={value => {
                    const extraItem =
                        value && ExtraCartItemV2Utils.isExtraUnitType(value) ? ExtraCartItems[value] : undefined;

                    if (extraItem) {
                        changeItemType({
                            item_index: item.item_index,
                            unit_type: extraItem.unit_type,
                        });
                    }
                }}
                label={'We had an issue with this item. Can you tell us what you wanted to order?'}
                buttonSelectClasses={{ imgWrap: classes.imgWrap }}
            />
        </CheckoutBodyWrapper>
    );
};

const useTitleStyles = makeStyles(() =>
    createStyles({
        subtitle: {
            color: FlossPalette.HIGHLIGHT_BLUE,
            display: 'flex',
            alignItems: 'center',
        },
        implantSystemKey: {
            color: 'gray',
            marginLeft: '1rem',
            marginRight: '0.5rem',
        },
        implantSystemValue: {
            color: FlossPalette.BLACK,
        },
        groupingButton: {
            textTransform: 'unset',
            paddingLeft: 0,
            paddingRight: 0,
            marginLeft: 10,
            marginBottom: -4,
        },
        icon: {
            color: FlossPalette.STAR_GRASS_HIGHLIGHTED,
            marginRight: 4,
        },
    }),
);

const ToggleBulkEdit: React.FC<{ bulkEditId: string }> = ({ bulkEditId }) => {
    const classes = useTitleStyles();
    const itemCount = useCheckoutSelector(s => s.items.filter(i => i.bulk_edit_id === bulkEditId)).length;
    const canRegroup = useCheckoutSelector(s => s.canRegroupBulkIds.includes(bulkEditId) && itemCount > 1);
    const canUngroup = useCheckoutSelector(s => s.bulkEditIds.includes(bulkEditId));
    const [showSuccess, setShowSuccess] = React.useState<boolean>(false);
    const enableBulkEdit = useCheckoutAction('ENABLE_BULK_EDIT');
    const disableBulkEdit = useCheckoutAction('DISABLE_BULK_EDIT');

    const onClick = React.useCallback(() => {
        canRegroup ? enableBulkEdit(bulkEditId) : disableBulkEdit(bulkEditId);
        setShowSuccess(true);
        setTimeout(() => {
            setShowSuccess(false);
        }, 2000);
    }, [bulkEditId, canRegroup, disableBulkEdit, enableBulkEdit]);

    if (!canRegroup && !canUngroup) {
        return null;
    }
    return (
        <Grid
            container
            style={{ width: 'auto', marginLeft: 5 }}
            alignItems={'flex-start'}
            direction={'column'}
            justifyContent={'flex-end'}
        >
            <Collapse in={showSuccess} collapsedSize={0}>
                <Grid container>
                    <CheckIcon className={classes.icon} />
                    <Typography color={'textSecondary'} variant={'body2'}>
                        {canRegroup ? 'Ungrouped ' : 'Regrouped '} into {itemCount} items
                    </Typography>
                </Grid>
            </Collapse>
            <Collapse in={!showSuccess} collapsedSize={0}>
                <CheckoutTextButton classes={{ root: classes.groupingButton }} onClick={onClick}>
                    Edit teeth {canRegroup ? 'in bulk' : 'individually'}
                </CheckoutTextButton>
            </Collapse>
        </Grid>
    );
};

const CheckoutLineItemRemoveV2: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const itemsCount = useCheckoutSelector(s => s.items.length);
    const removeItem = useCheckoutAction('REMOVE_ITEM');
    // cannot remove an item if there is only 1 in cart
    const removeDisabled = itemsCount <= 1;
    return (
        <Tooltip
            arrow
            title={'Cannot delete only item in scan'}
            disableTouchListener={!removeDisabled}
            disableHoverListener={!removeDisabled}
            disableFocusListener={!removeDisabled}
        >
            <span style={{ whiteSpace: 'nowrap' }}>
                <CheckoutTextButton
                    disabled={removeDisabled}
                    style={{ padding: 0 }}
                    onClick={() => removeItem(item.item_index)}
                >
                    Delete item
                </CheckoutTextButton>
            </span>
        </Tooltip>
    );
};

const CheckoutItemTitleImplantSectionItem: React.FC<{ title: string; value?: string | null }> = ({ title, value }) => {
    const classes = useTitleStyles();

    if (!value) {
        return null;
    }

    return (
        <span>
            <span className={classes.implantSystemKey}>{title}</span>
            <span className={classes.implantSystemValue}>{_.startCase(value)}</span>
        </span>
    );
};

const CheckoutItemTitleImplantSection: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const classes = useTitleStyles();
    const implantMetadata = CartItemV2Utils.itemIsType(item, [
        LabOrderItemSKUType.Implant,
        LabOrderItemSKUType.ImplantBridge,
    ])
        ? CartItemV2Utils.getImplantMetadata(item)
        : undefined;

    if (!implantMetadata) {
        return null;
    }

    const { relationship, manufacturer, system, connection_size } = implantMetadata;

    return (
        <Text variant={'body1'} className={classes.subtitle}>
            <CheckoutItemTitleImplantSectionItem title={'Relationship'} value={relationship} />
            <CheckoutItemTitleImplantSectionItem title={'Manufacturer'} value={manufacturer} />
            <CheckoutItemTitleImplantSectionItem title={'System'} value={system} />
            {/* Title updated from 'Connection Size' */}
            <CheckoutItemTitleImplantSectionItem title={'Platform Size'} value={connection_size} />
        </Text>
    );
};

const CheckoutItemTitle: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const classes = useTitleStyles();
    const { bulkEditIds } = useCheckoutPropSelector(['bulkEditIds']);
    const isBulkMode = bulkEditIds.includes(item.bulk_edit_id);
    const allItems = useCheckoutSelector(bulkItemsSelector(item.bulk_edit_id));

    const teethDisplay = React.useMemo(() => {
        const teeth = isBulkMode
            ? _.flatMap(allItems, CartItemV2Utils.getSingleToothUnits)
            : CartItemV2Utils.getSingleToothUnits(item);
        const implantTeeth = _.uniq(teeth.filter(t => t.unit_type === 'Abutment').map(t => t.unn));
        const ponticTeeth = _.uniq(teeth.filter(t => t.unit_type === 'CrownPontic').map(t => t.unn));
        const uniqueTeeth = _.uniq(teeth.map(t => t.unn))
            .sort((a, b) => a - b)
            .map(unn => {
                if (implantTeeth.includes(unn)) {
                    return `${unn} (Implant)`;
                }

                if (ponticTeeth.includes(unn)) {
                    return `${unn} (Pontic)`;
                }

                return `${unn}`;
            });
        return uniqueTeeth.length > 0
            ? `${uniqueTeeth.length > 1 ? 'Teeth' : 'Tooth'}: ${uniqueTeeth.join(', ')}`
            : undefined;
    }, [allItems, isBulkMode, item]);

    return (
        <>
            <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
                <Grid container style={{ width: 'auto', maxWidth: '80%' }}>
                    <Text variant={'h6'}>
                        {CheckoutItemV2Manager.getFormattedTitle(item)} {isBulkMode && `(x${allItems.length})`}
                    </Text>
                </Grid>
                <Grid container style={{ width: 'auto' }}>
                    {!isBulkMode && <ResetUnknownUnit item={item} />}
                </Grid>
            </Grid>
            <Grid container wrap={'nowrap'} justifyContent={'space-between'}>
                <Grid container style={{ width: 'auto', maxWidth: !isBulkMode ? '80%' : undefined }}>
                    {teethDisplay && (
                        <Text variant={'body1'} className={classes.subtitle}>
                            <span style={{ marginRight: 4 }}>&#x25cf;</span>
                            {teethDisplay}
                        </Text>
                    )}
                    <CheckoutItemTitleImplantSection item={item} />
                    <ToggleBulkEdit bulkEditId={item.bulk_edit_id} />
                </Grid>
                {!isBulkMode && (
                    <Grid container style={{ width: 'auto' }}>
                        <CheckoutLineItemRemoveV2 item={item} />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

const CheckoutV2Item: React.FC<{ item: CheckoutItemV2 }> = ({ item }) => {
    const needsUnitSelect = CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Unknown);

    return (
        <>
            {/* This shows the full-screen unit-type select if its an unknown item */}
            {needsUnitSelect ? (
                <UnitTypeField item={item} />
            ) : (
                <Grid container style={{ padding: '32px 0' }}>
                    <CheckoutItemTitle item={item} />
                    {CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Removeable) &&
                        item.unit.unit_type === 'Night Guard' && <CheckoutSuggestedOrder item={item} />}
                    {CheckoutItemV2Manager.isMultiToothSelectItem(item) && <CheckoutItemMultiToothField item={item} />}
                    <ImplantRelationshipFields item={item} />
                    <MaterialFields item={item} />
                    <CheckoutShadePicker item={item} />
                    {CheckoutItemV2Manager.isUnspecifiedOtherItem(item) && <ItemNotesV2 item={item} />}
                    {CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.SurgicalGuide) && (
                        <CheckoutSurgicalGuideImplantMetadataFields item={item} />
                    )}
                    <CheckoutItemDataFieldsSection item={item} />
                    <CheckoutItemMetafieldsSection item={item} />
                </Grid>
            )}
        </>
    );
};

const defaultCompletionStateCheckerForItem = (item: CheckoutItemV2): CheckoutScreen['completionState'] => {
    return () => {
        const fieldsRemaining = CheckoutItemV2Manager.fieldsRemaining(item);
        return fieldsRemaining === 0
            ? { complete: true, fieldsRemaining: 0 }
            : { fieldsRemaining, complete: false, reason: `${fieldsRemaining} remaining` };
    };
};

function screenForRegularItem(item: CheckoutItemV2): CheckoutScreen {
    return {
        title: 'Reviewing items',
        contents: <CheckoutV2Item item={item} />,
        completionState: defaultCompletionStateCheckerForItem(item),
        itemIndex: item.item_index,
        identifier: `item:${item.item_index}`,
    };
}

const EXTRAS_SCREEN_IDENTIFIER = 'extras';

const CheckoutExtrasScreen: React.VFC = () => {
    const [entered, setEntered] = React.useState(false);
    const isCurrentScreen = useCheckoutSelector(isActiveScreen(EXTRAS_SCREEN_IDENTIFIER));

    React.useEffect(() => {
        if (isCurrentScreen) {
            // Hacky: Use a delay to wait for the animation to finish.
            setTimeout(() => setEntered(true), 300);
        }
        setEntered(false);
    }, [isCurrentScreen]);

    return <SelectExtrasSection entered={entered && isCurrentScreen} />;
};

const extrasScreen: CheckoutScreen = {
    title: 'Did you want to add anything extra?',
    contents: <CheckoutExtrasScreen />,
    completionState: () => {
        return { complete: true };
    },
    identifier: EXTRAS_SCREEN_IDENTIFIER,
};

function screensForSingleItem(item: CheckoutItemV2, checkoutFeatureFlags: CheckoutFeatureFlags): CheckoutScreen[] {
    if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Model) && item.unit_type === 'Implant Model') {
        return [];
    }
    if (isCheckoutItemV2WithImplant(item)) {
        return screensForImplantItem(item, checkoutFeatureFlags);
    }
    return [screenForRegularItem(item)];
}

// Screens for items in the order
function screensForItems(
    items: CheckoutItemV2[],
    bulkEditIds: string[],
    checkoutFeatureFlags: CheckoutFeatureFlags,
): CheckoutScreen[] {
    const itemsByBulkId = CheckoutItemV2Manager.getBulkGroups(items);
    // If bulk editing, collect the representative item from each bulk group.
    const representativeItems = Object.entries(itemsByBulkId).flatMap(([bulkId, matchingItems]) => {
        const firstItem = matchingItems[0];
        const isBulkMode = bulkEditIds.includes(bulkId);
        if (firstItem && isBulkMode) {
            return firstItem;
        }
        return matchingItems;
    });
    // Sort items and map into screens.
    return CheckoutItemV2Manager.sortItems(representativeItems).flatMap(item =>
        screensForSingleItem(item, checkoutFeatureFlags),
    );
}

export function getItemScreens(state: CheckoutState): CheckoutScreen[] {
    const items = state.items;
    const bulkEditIds = state.bulkEditIds;

    const scanItems = items.filter(i => i.from_scan_export);
    const extraItems = items.filter(i => !i.from_scan_export);

    return [
        ...screensForItems(scanItems, bulkEditIds, state.featureFlags),
        extrasScreen,
        ...screensForItems(extraItems, bulkEditIds, state.featureFlags),
    ];
}

export function getCurrentScreen(state: CheckoutState): CheckoutScreen | undefined {
    return getItemScreens(state)[state.itemScreenIndex];
}

export function getActiveItemIndex(state: CheckoutState): number | undefined {
    return getCurrentScreen(state)?.itemIndex;
}

function isActiveScreen(identifier: string): (state: CheckoutState) => boolean {
    return state => state.step === 1 && getCurrentScreen(state)?.identifier === identifier;
}

// Returns the index of the last visited item, including the current item.
export function getLastVisitedItemIndex(state: CheckoutState): number {
    const itemScreens = getItemScreens(state).slice(0, state.itemScreenIndex + 1);
    return _.findLast(itemScreens, screen => screen.itemIndex !== undefined)?.itemIndex ?? 0;
}
