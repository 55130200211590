import { useRetainerToken } from '../context/RetainerTokenProvider';
import { NATIVE_TOKEN, IMPERSONATE_TOKEN } from './constants';
import type { LabsGqlExchangeMultiAuthLegacyTokenQuery } from '@orthly/graphql-operations';
import { clearApolloCache, useExchangeMultiAuthLegacyTokenQuery } from '@orthly/graphql-react';
import { RetainerTokenStorage } from '@orthly/retainer-common';
import React from 'react';

type ShowNotification = (message: string, error: boolean) => void;

function getImpersonateToken(allowImpersonate: boolean): string | undefined {
    if (!allowImpersonate) {
        return undefined;
    }
    const search = new URLSearchParams(typeof window === 'undefined' ? '' : window.location.search);
    return search.get(IMPERSONATE_TOKEN) || search.get(NATIVE_TOKEN) || undefined;
}

function useInitialImpersonateToken(allowImpersonate: boolean) {
    const [initialImpersonateToken] = React.useState(getImpersonateToken(allowImpersonate));
    React.useEffect(() => {
        if (initialImpersonateToken) {
            RetainerTokenStorage.removeToken();
            clearApolloCache().catch(console.error);
            const url = new URL(window.location.href);
            url.searchParams.delete(NATIVE_TOKEN);
            url.searchParams.delete(IMPERSONATE_TOKEN);
            window.history.replaceState({}, window.document.title, url.toString());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return initialImpersonateToken;
}

function getLegacyExchangeError(error: any) {
    const errorMessage =
        error instanceof Error && `${error.message}`.includes('expired')
            ? 'Login link expired.'
            : 'Direct login failed.';
    return `${errorMessage} Please login using email and password.`;
}

export function useLegacyTokenExchanger(allowImpersonate: boolean, showNotification: ShowNotification) {
    const { setRetainerToken, deleteRetainerToken } = useRetainerToken();
    const initialImpersonateToken = useInitialImpersonateToken(allowImpersonate);
    const [legacyNativeIdToken] = React.useState<string | undefined>(initialImpersonateToken);
    const onError = React.useCallback(
        (error: any) => {
            console.error(error);
            showNotification(getLegacyExchangeError(error), true);
            deleteRetainerToken();
            RetainerTokenStorage.removeToken();
        },
        [deleteRetainerToken, showNotification],
    );
    const onCompleted = React.useCallback(
        (data: LabsGqlExchangeMultiAuthLegacyTokenQuery) => {
            data?.exchangeMultiAuthLegacyToken.accessToken &&
                setRetainerToken(data.exchangeMultiAuthLegacyToken.accessToken, data.exchangeMultiAuthLegacyToken.id);
        },
        [setRetainerToken],
    );
    const { loading, called, refetch } = useExchangeMultiAuthLegacyTokenQuery({
        onError,
        onCompleted,
        variables: legacyNativeIdToken ? { nativeIdToken: legacyNativeIdToken } : undefined,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        skip: !legacyNativeIdToken,
    });
    const [refetching, setRefetching] = React.useState<boolean>(false);
    const loadSessionFromLegacyToken = React.useCallback(
        async (legacyNativeIdToken: string, onErrorCb?: (err: any) => void): Promise<void> => {
            try {
                setRefetching(true);
                const { error, data } = await refetch({ nativeIdToken: legacyNativeIdToken });
                console.debug('Finished loadSessionFromLegacyToken');
                if (error) {
                    onError(error);
                }
                data && onCompleted(data);
            } catch (e: any) {
                onError(e);
                onErrorCb?.(e);
            } finally {
                setRefetching(false);
            }
        },
        [onCompleted, onError, refetch],
    );
    const sessionLoadStarting = !!legacyNativeIdToken && !called;
    return {
        loadSessionFromLegacyToken,
        legacyNativeIdToken,
        tokenExchangeLoading: loading || sessionLoadStarting || refetching,
    };
}
