/*
 *  These schemas are used to validate the parts of 3Shape xml which appear in the
 *  DentalDesignerModellingTree and dcm files
 *
 *  Meant to be used on the TS objects which come out of xmlBuilder.toObject()
 *  where attributes have @ prefixes etc.  See xmlbuilder2 documentation
 * */
import { z } from 'zod';

export const UnlabelledVector3Schema = z.object({
    x: z.preprocess(x => Number(x), z.number()),
    y: z.preprocess(x => Number(x), z.number()),
    z: z.preprocess(x => Number(x), z.number()),
});

export const NamedVector3Schema = z.object({
    '@name': z.string(),
    '@x': z.preprocess(x => Number(x), z.number()),
    '@y': z.preprocess(x => Number(x), z.number()),
    '@z': z.preprocess(x => Number(x), z.number()),
});

export const Matrix4Schema = z.object({
    '@name': z.string(),
    '@m00': z.preprocess(x => Number(x), z.number()),
    '@m01': z.preprocess(x => Number(x), z.number()),
    '@m02': z.preprocess(x => Number(x), z.number()),
    '@m03': z.preprocess(x => Number(x), z.number()),
    '@m10': z.preprocess(x => Number(x), z.number()),
    '@m11': z.preprocess(x => Number(x), z.number()),
    '@m12': z.preprocess(x => Number(x), z.number()),
    '@m13': z.preprocess(x => Number(x), z.number()),
    '@m20': z.preprocess(x => Number(x), z.number()),
    '@m21': z.preprocess(x => Number(x), z.number()),
    '@m22': z.preprocess(x => Number(x), z.number()),
    '@m23': z.preprocess(x => Number(x), z.number()),
    '@m30': z.preprocess(x => Number(x), z.number()),
    '@m31': z.preprocess(x => Number(x), z.number()),
    '@m32': z.preprocess(x => Number(x), z.number()),
    '@m33': z.preprocess(x => Number(x), z.number()),
});
