import { LogoutButton } from '../../../../components/LogoutButton';
import { useGetUserFromDrPrefId } from '../../../../graphql/useGetUserFromDrPrefId.graphql';
import { usePracticeLogout } from '../../../../redux/auth';
import { PracticeUtils } from '../../../../utils/utils';
import { isPlaceholderEmail } from '../../../account/profile-settings/account/utils';
import { LabeledTextField } from '../../components/labeled-field/LabeledFields';
import { useRevealPassword } from '../../components/labeled-field/useRevealPassword';
import { PageLayout } from '../../components/page-layout/PageLayout';
import { CenteredFooterText, FooterLink } from '../../components/page-layout/components/PanelFooterLink';
import { useDoctorId } from '../../state/useDoctorId';
import { useCreateLoginCredentialsValidation } from './useCreateLoginCredentialsValidation';
import { useCreatePassword } from './useCreatePassword.graphql';
import { useNextStep } from './useNextStep';
import { Button } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useRemoveImpersonateToken = () => {
    const history = useHistory();

    const searchParams = new URLSearchParams(history.location.search);

    if (searchParams.has('impersonateToken')) {
        searchParams.delete('impersonateToken');
        history.replace({ search: searchParams.toString() });
    }
};

const useRedirect = (isAccountActivated?: boolean, loading?: boolean) => {
    const goToNextStep = useNextStep();

    React.useEffect(() => {
        if (!loading && isAccountActivated) {
            goToNextStep();
        }
    }, [isAccountActivated, goToNextStep, loading]);
};

export const CreateLoginCredentials: React.FC = () => {
    const { value: enableForceUserCredentials = false, loading } = useFeatureFlag('enableForceUserCredentials');
    useRemoveImpersonateToken();
    const logout = usePracticeLogout();
    const preferencesId = useDoctorId();
    const { user, loading: loadingUser } = useGetUserFromDrPrefId(preferencesId);
    useRedirect(user?.is_account_activated, loadingUser);
    const userEmail = user?.email;
    const { action, type } = useRevealPassword();
    const [password, setPassword] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [confirmedPassword, setConfirmedPassword] = React.useState('');
    const placeholderEmail = userEmail && isPlaceholderEmail(userEmail);
    const isMigration = PracticeUtils.getParsedQueryProperty(`migrate`, location.search) ?? '';

    const {
        isFormValid,
        passwordError,
        confirmedPasswordError,
        emailError,
        setShowPasswordError,
        setShowConfirmedPasswordError,
        setShowEmailError,
    } = useCreateLoginCredentialsValidation({
        password,
        confirmedPassword,
        email,
    });

    const { submit: submitCreatePassword, submitting: submittingCreatePassword } = useCreatePassword();
    const shouldContinue = isFormValid || submittingCreatePassword;
    const hasPlaceholderEmail = Boolean(placeholderEmail && enableForceUserCredentials && !!userEmail);

    React.useEffect(() => {
        if (!placeholderEmail && userEmail) {
            setEmail(userEmail);
        }
    }, [placeholderEmail, userEmail]);

    if (loading) {
        return null;
    }

    return (
        <PageLayout
            pageMessage={'Create profile'}
            panelTitle={'Create your login credentials'}
            onFormSubmit={async e => {
                e.preventDefault();
                await submitCreatePassword({
                    args: {
                        password: password,
                        preferences_id: preferencesId,
                        email,
                    },
                });
            }}
        >
            <LabeledTextField
                isRequired
                label={'Email'}
                placeholder={'Enter your email address'}
                disabled={!hasPlaceholderEmail}
                value={hasPlaceholderEmail ? email : userEmail}
                onChange={(value: string | undefined) => {
                    if (hasPlaceholderEmail && value) {
                        setEmail(value);
                    }
                }}
                TextFieldProps={{
                    type: 'email',
                    onBlur: () => {
                        setShowEmailError(email !== '');
                    },
                    error: !!emailError,
                    helperText: emailError,
                }}
            />
            <LabeledTextField
                isRequired
                label={'Password'}
                placeholder={'Enter your password'}
                value={password}
                onChange={(next = '') => {
                    setPassword(next);
                }}
                TextFieldProps={{
                    type,
                    onBlur: () => {
                        setShowPasswordError(password !== '');
                    },
                    error: !!passwordError,
                    helperText: passwordError,
                }}
                action={action}
            />
            <LabeledTextField
                isRequired
                label={'Repeat Password'}
                placeholder={'Re-enter your password'}
                value={confirmedPassword}
                onChange={(next = '') => {
                    setConfirmedPassword(next);
                }}
                TextFieldProps={{
                    type,
                    onBlur: () => {
                        setShowConfirmedPasswordError(confirmedPassword !== '');
                    },
                    error: !!confirmedPasswordError,
                    helperText: confirmedPasswordError,
                }}
                action={action}
            />
            <Button variant={'primary'} fullWidth type={'submit'} disabled={!shouldContinue}>
                Continue
            </Button>
            <CenteredFooterText>
                {isMigration ? (
                    <LogoutButton variant={'ghost'} />
                ) : (
                    <FooterLink onClick={logout}>Already an existing user? Login instead</FooterLink>
                )}
            </CenteredFooterText>
        </PageLayout>
    );
};
