import { OrderDetailRightPanel } from './OrderDetailRightPanel';
import { OrderDetailTopBarActionsV2 } from './components/OrderDetailMobileTopBarActionsV2';
import { OrderDetailTopBarV2 } from './components/OrderDetailTopBarV2';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import React from 'react';

export const TopBarActions: React.FC<{
    order?: LabsGqlSingleLabOrderFragment;
}> = ({ order }) => {
    return order ? <OrderDetailTopBarActionsV2 order={order} /> : null;
};

export const TopBar: React.FC<{
    order?: LabsGqlSingleLabOrderFragment;
}> = ({ order }) => {
    return order ? <OrderDetailTopBarV2 order={order} /> : null;
};

export const SidePanel: React.FC<{
    order?: LabsGqlSingleLabOrderFragment;
}> = ({ order }) => {
    return order ? <OrderDetailRightPanel order={order} /> : null;
};
