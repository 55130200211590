// We generate zip file names in Veneer and Zipstream.
// We may likely change the generated filename convention when we
// extend to 3rd party labs.

// These interfaces exist because we cannot import from graphql-operations
// Ideally, this is a Pick<LabsGqlOrderLabPreviewFragment, 'patient', 'order_number'>
// and we can extend if our naming convention changes
interface PatientForCamFiles {
    first_name: string;
    last_name: string;
}
interface OrderForCamFiles {
    order_number: string;
    patient: PatientForCamFiles;
}

export function camZipNameFromOrder(order: OrderForCamFiles): string {
    const patientName = `${order.patient.first_name} ${order.patient.last_name}`.trim();
    return `${patientName}_${order.order_number}_DandyCAM.zip`;
}
