import type { MainViewCameraControlsRef } from './ModelViewerTHREETypes';
import type { ModelPayloadView } from './ModelViewerTypes';
import { ModelPayloadViewKind, Jaw } from './ModelViewerTypes';
import { updateRaycaster } from './utils3d/raycast-bvh.util';
import type { AdjacencyMatrix } from '@orthly/forceps';
import { buildMeshAdjacency, getNeighbors, ensureMeshIndex, AttributeName } from '@orthly/forceps';
import { FlossPalette } from '@orthly/ui-primitives';
import { useThree } from '@react-three/fiber';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';
import type { Vector3, OrthographicCamera } from 'three';
import type { MeshBVH } from 'three-mesh-bvh';

export const ShadeMatch: React.FC<{
    modelPayload: ModelPayloadView;
    cameraControlsRef: MainViewCameraControlsRef;
    onShadePicked: (color: [number, number, number]) => void;
    maxRadiusMm: number;
    jaw: Jaw;
}> = ({ cameraControlsRef, modelPayload, maxRadiusMm, onShadePicked, jaw }) => {
    const marginLinesGroupRef = React.useRef<THREE.Group>();

    const { gl } = useThree();

    const associatedGeometry = React.useMemo(() => {
        switch (modelPayload.kind) {
            case ModelPayloadViewKind.SingletonPayloadView:
                return modelPayload.payload.model.geometry;
            case ModelPayloadViewKind.AnteriorAndPrepCombined: {
                const candidate = jaw === Jaw.Upper ? modelPayload.prepScan : modelPayload.anteriorScan;
                return candidate?.model.geometry;
            }
            case ModelPayloadViewKind.PrepCombined:
                return modelPayload.prepScan?.model.geometry;
            case ModelPayloadViewKind.FullJaw: {
                const candidate = jaw === Jaw.Upper ? modelPayload.upper : modelPayload.lower;
                return candidate.model.geometry;
            }
        }

        return undefined;
    }, [modelPayload, jaw]);

    // Geometry and Materials for reuse for Control Points
    // Geometry and Materials will accumulate if not disposed of
    // and can cause performance degradation as they pile up
    const { sphereGeometry, sphereMaterial } = React.useMemo(() => {
        return {
            sphereGeometry: new THREE.SphereBufferGeometry(maxRadiusMm, 32, 32),
            sphereMaterial: new THREE.MeshBasicMaterial({ color: FlossPalette.STAR_GRASS }),
        };
    }, [maxRadiusMm]);

    const adjacencyMatrix = React.useMemo<AdjacencyMatrix>(() => {
        if (!associatedGeometry) {
            return [];
        }
        return buildMeshAdjacency(associatedGeometry);
    }, [associatedGeometry]);

    // needed for raycasting
    const indexBVH = React.useMemo<MeshBVH | undefined>(
        () => {
            return associatedGeometry ? ensureMeshIndex(associatedGeometry) : undefined;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [associatedGeometry, associatedGeometry?.uuid],
    );

    React.useEffect(
        // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
        // eslint-disable-next-line sonarjs/cognitive-complexity
        () => {
            const controls = cameraControlsRef.current;
            const camera = cameraControlsRef.current?.object as OrthographicCamera;
            const g = marginLinesGroupRef.current;

            if (!controls) {
                return;
            }

            if (g) {
                g.renderOrder = 1000;
            }

            let spherePos: Vector3 | undefined = undefined;
            let sphere: THREE.Mesh | undefined = undefined;
            let mouseState: 'dragging' | 'pressed' | 'none' = 'none';

            const update_live_visual_objects = () => {
                if (g && spherePos) {
                    if (!sphere) {
                        sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
                        g.add(sphere);
                    }

                    sphere.position.copy(spherePos);
                }
            };

            // put sphere where it belongs
            update_live_visual_objects();

            const raycaster = new THREE.Raycaster();
            const canvas = gl.domElement;

            const recomputeSelectedColor = () => {
                if (!indexBVH || !associatedGeometry || mouseState !== 'pressed') {
                    return;
                }

                const intersects = indexBVH.raycast(raycaster.ray, THREE.FrontSide);
                const intersection = _.minBy(intersects, intersect => intersect.distance);

                // we did not click on the mesh
                if (!intersection || !intersection.face) {
                    mouseState = 'dragging';
                    return;
                }

                // Disable the user from being able to move the model while they are moving the color picker.
                controls.enabled = false;
                spherePos = intersection.point;

                const numVertices = associatedGeometry.attributes.position?.count ?? 0;
                const neighbors = getNeighbors({
                    numVertices,
                    adjacencyMatrix,
                    mainHandle: intersection.face.a,
                    maxRadiusMm: maxRadiusMm || 1,
                    geometry: associatedGeometry,
                });

                // Find the sum of each of the red, green, and blue channels.
                // These can then be averaged to find the average color within the selected region.
                // We don't do any multiplication or floor until the end to avoid floating point math errors.
                // We intentionally square the r, g, and b values before adding them to the sum.
                // This is to approximately reverse the compression done to store rgb.
                // For more information, see: https://graphicdesign.stackexchange.com/questions/113884/calculating-average-of-two-rgb-values
                const rgbSums = neighbors.reduce<[number, number, number]>(
                    (sums, vert) => {
                        const r = associatedGeometry?.getAttribute(AttributeName.Color).getX(vert) ?? 0;
                        const g = associatedGeometry?.getAttribute(AttributeName.Color).getY(vert) ?? 0;
                        const b = associatedGeometry?.getAttribute(AttributeName.Color).getZ(vert) ?? 0;

                        return [sums[0] + r * r, sums[1] + g * g, sums[2] + b * b];
                    },
                    [0, 0, 0],
                );
                const rgb: [number, number, number] = [
                    Math.floor(Math.sqrt(rgbSums[0] / neighbors.length) * 255),
                    Math.floor(Math.sqrt(rgbSums[1] / neighbors.length) * 255),
                    Math.floor(Math.sqrt(rgbSums[2] / neighbors.length) * 255),
                ];

                onShadePicked?.(rgb);

                // create or update the spheres and their indexes
                update_live_visual_objects();
            };

            const pointerMove = (evt: MouseEvent) => {
                // communicate mouse position to scene via camera etc
                updateRaycaster(raycaster, gl.domElement, camera, evt);

                if (mouseState !== 'pressed') {
                    mouseState = 'dragging';
                }

                recomputeSelectedColor();
            };

            const pointerDown = (evt: MouseEvent) => {
                if (evt.button !== 0 || !indexBVH || !associatedGeometry) {
                    return;
                }

                mouseState = 'pressed';
                recomputeSelectedColor();
            };

            const pointerUp = (evt: MouseEvent) => {
                if (evt.button !== 0 || !indexBVH || !associatedGeometry) {
                    return;
                }

                recomputeSelectedColor();
                mouseState = 'none';
                controls.enabled = true;
            };

            canvas?.addEventListener('pointermove', pointerMove);
            canvas?.addEventListener('pointerdown', pointerDown);
            canvas?.addEventListener('pointerup', pointerUp);

            return () => {
                canvas?.removeEventListener('pointerdown', pointerDown);
                canvas?.removeEventListener('pointermove', pointerMove);
                canvas?.removeEventListener('pointerup', pointerUp);
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [
            cameraControlsRef,
            cameraControlsRef.current,
            associatedGeometry,
            associatedGeometry?.uuid,
            sphereMaterial,
            sphereGeometry,
            gl,
            onShadePicked,
            maxRadiusMm,
            adjacencyMatrix,
            indexBVH,
        ],
    );

    return <group ref={marginLinesGroupRef} />;
};
