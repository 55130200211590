import xmlParser from 'fast-xml-parser';
import _ from 'lodash';

// All of the ModelElement types that we care about. This list isn't exhaustive.
export const ModelElementTypes = [
    'meIndicationRegular',
    'meDigitalModelDie',
    'meDigitalModelPrepSectioned',
    'meDigitalModelPrepUnsectioned',
    'meDigitalModelAntagonist',
    'meDigitalModelNeighbor',
    'meDigitalModelTissue',
    'meImplantPlanning',
    'meSurgicalGuide',
    'meTransferGuide',
    'meSplint',
] as const;
export type ModelElementType = (typeof ModelElementTypes)[number];

export function isModelElementType(a: string | null | undefined): a is ModelElementType {
    if (!a) {
        return false;
    }
    return ModelElementTypes.includes(a as ModelElementType);
}

// All the ToothElement types that we know
export const CacheToothClassTypes = [
    'teNone',
    'teCrown',
    'teCrownPontic',
    'teInlay',
    'teVeneer',
    'teTemporaryVPrepCrown',
    'teTemporaryCrownPontic',
    'teTemporaryVPrepCrownPontic',
    'teTabletop',
    'tePontic',
    'teCoping',
    'teWaxup',
    'teTelescopeCrown',
    'teAbutment',
    'teAbutmentWaxup',
    'tePositionMarker',
    'teArtificialTooth',
    'teClasp',
    'teAbutmentAnatomical',
    'teAbutmentScrewRetainedCrown',
    'teGingiva',
    'teGingivaFD',
    'teGingivaFullDenture',
    'teGingivaAnatomical',
    'teRPD3DBitePlate',
    'tePostAndCoreStandard',
    'tePostAndCoreRetainedCrown',
    'tePostAndCoreAnatomical',
    'tePostAndCoreTelescope',
    'teSplint',
    'teCustomImpressionTray',
    'teCopyDentureTray',
    'teImplantPlanning',
    'teSurgicalGuide',
    'teTransferGuide',
    'teDigitalModelDie',
    'teDigitalModelPrepSectioned',
    'teDigitalModelPrepUnsectioned',
    'teDigitalModelAntagonist',
    'teDigitalModelPrepSectionedCut',
    'teRPD',
];
export type CacheToothClassType = (typeof CacheToothClassTypes)[number];

export function isCacheToothClassType(a: string | null | undefined): a is CacheToothClassType {
    if (!a) {
        return false;
    }
    return CacheToothClassTypes.includes(a);
}

export const IMPLANT_TOOTH_TYPES = ['teAbutment', 'teAbutmentAnatomical', 'teAbutmentScrewRetainedCrown'];
export const DENTURE_TOOTH_TYPES = ['teGingivaFD', 'teGingivaFullDenture', 'teArtificialTooth', 'teRPD', 'teClasp'];

type Properties = {
    [key: string]: string;
};

/**
 * ToothElementList is a a field
 *   TDM_Item_ToothElement
 */
export interface ModelElement {
    modelFilePath: string;
    modelType: ModelElementType;
    modelElementID: string;
    modelJobID: string;
    properties: Properties;
    itemsDesc: string;
    insertionAxis?: InsertionAxis;
}

/**
 * ToothElementList
 *   - TDM_Item_ToothElement
 * These represent every item that maps to a tooth unit
 *
 * Each unit in a bridge will have a ToothElement
 * with property modelElementID mapping to the CAD ModelElement
 *
 * Each Abutment,  Implant Crown and PostAndCore will also have a ToothElement
 *
 * Properties
 *   -Anatomical: boolean  if a coping, it means it's a cutback coping vs a thimbly coping, otherwise its False
 *   -PostAndCore:  boolean  whether or not it's a post and core.  Redundant with and sometimes in conflict with
 *   -CacheToothTypeClass:
 */
export interface ToothElement {
    toothElementID: string;
    modelElementID: string;
    toothNumber: number;
    cacheToothTypeClass: CacheToothClassType;
    properties: Properties;
}

/**
 * LinkList
 *   - TDM_Item_Link
 * These items specify a connection (relationship) or physical
 * within a CAD object.  Most often the connectors in bridges
 * There wil be 2 LinkToothElements in the LinkToothElement list
 * which key to the linkID of an item in the LinkList
 */
interface LinkElement {
    linkID: string;
    modelElementID: string;
    cacheLinkTypeClass: string;
    linkTypeID: string;
}

/**
 * LinkToothElementList
 *   - TDM_Item_LinkToothElement
 * These items specify a relationshup connection between two tooth elements
 * Eg, in bridge 9x11 we would expect
 * but the relationship connection
 */
interface LinkToothElement {
    linkID: string;
    toothElementID: string;
    linkToothElementID: string;
}

export interface ModelInfoElement {
    modelElementID: string;
    digitalModelElementInfoID: string;
    toothNumber: number;
    properties: Properties;
}

interface InsertionAxis {
    x: number;
    y: number;
    z: number;
}

interface InsertionAxesByModelId {
    [key: string]: InsertionAxis;
}

// Our parser representation of 3Shape Dental Designer design case structure
export interface ParsedCaseResult {
    modelElements: ModelElement[];
    toothElements: ToothElement[];
    linkElements?: LinkElement[];
    linkToothElements?: LinkToothElement[];
    modelInfoElements?: ModelInfoElement[];
    orderName?: string;
}

export class DesignCaseFileParser {
    private static reducePropertyValue(a?: string): string | number | boolean | undefined {
        if (!a) {
            return a;
        }

        if (!_.isNaN(_.toNumber(a))) {
            return _.toNumber(a);
        }

        if (a.toLowerCase() === 'true') {
            return true;
        }

        if (a.toLowerCase() === 'false') {
            return false;
        }

        return a;
    }

    private static findObjectByName(objects: any[], obName: string): any[] {
        let caseElements = objects.find(obj => obj['@_name'] === obName)?.List?.Object;

        if (!caseElements) {
            return [];
        }

        if (caseElements.Property) {
            caseElements = [caseElements];
        }

        if (!Array.isArray(caseElements)) {
            return [caseElements];
        }

        return caseElements;
    }

    private static formatModelPath = (path: string) => {
        return path.replace(/\\/g, '/');
    };
    // Parses a properties data structure, which could be either 1 value or an array, and returns a map of all of the named values.
    // This is kinda gross and hacky, but the named properties are arbitrary and we may want to use more of them in the future.
    private static parseProperty(property?: any | any[], morphValue: boolean = true): Properties {
        if (!property) {
            return {};
        }

        const properties = Array.isArray(property) ? property : [property];

        return properties.reduce(
            (state, curr) => ({
                ...state,
                [curr['@_name']]: morphValue
                    ? DesignCaseFileParser.reducePropertyValue(curr['@_value'])
                    : curr['@_value'],
            }),
            {},
        );
    }

    // Parses the model elements list
    // This list is the list of all models within the design, including what kind they are and other metadata.
    // Will only return those that have well formed names and types.
    private static parseModelElements(elements: any[], insertionAxes?: InsertionAxesByModelId): ModelElement[] {
        return _.compact(
            elements.map<ModelElement | undefined>(element => {
                if (element['@_type'] !== 'TDM_Item_ModelElement') {
                    return undefined;
                }

                const properties = DesignCaseFileParser.parseProperty(element.Property);

                const { ModelFilename, ModelElementType: elementType, ModelElementID, ModelJobID, Items } = properties;

                // TODO, allow ModelFileName correction here
                if (
                    !elementType ||
                    !ModelElementTypes.includes(elementType as ModelElementType) ||
                    !ModelElementID ||
                    !ModelJobID
                ) {
                    return undefined;
                }

                return {
                    properties,
                    modelElementID: ModelElementID,
                    modelJobID: ModelJobID,
                    modelFilePath: ModelFilename ? DesignCaseFileParser.formatModelPath(ModelFilename) : '',
                    modelType: elementType as ModelElementType,
                    itemsDesc: Items ?? '',
                    insertionAxis: insertionAxes?.[ModelElementID],
                };
            }),
        );
    }

    private static parseLinkToothElements(elements: any[]): LinkToothElement[] {
        return _.compact(
            elements.map<LinkToothElement | undefined>(element => {
                if (element['@_type'] !== 'TDM_Item_LinkToothElement') {
                    return undefined;
                }

                const properties = DesignCaseFileParser.parseProperty(element.Property);

                const { LinkToothElementID, ToothElementID, LinkID } = properties;

                if (!LinkToothElementID || !ToothElementID || !LinkID) {
                    return undefined;
                }

                return {
                    linkID: LinkID,
                    toothElementID: ToothElementID,
                    linkToothElementID: LinkToothElementID,
                };
            }),
        );
    }

    private static parseLinkElements(elements: any[]): LinkElement[] {
        return _.compact(
            elements.map<LinkElement | undefined>(element => {
                if (element['@_type'] !== 'TDM_Item_Link') {
                    return undefined;
                }

                const properties = DesignCaseFileParser.parseProperty(element.Property);

                const { LinkID, LinkTypeID, ModelElementID, CacheLinkTypeClass } = properties;

                if (!LinkID || !LinkTypeID || !ModelElementID || !CacheLinkTypeClass) {
                    return undefined;
                }

                return {
                    linkID: LinkID,
                    linkTypeID: LinkTypeID,
                    modelElementID: ModelElementID,
                    cacheLinkTypeClass: CacheLinkTypeClass,
                };
            }),
        );
    }

    private static parseModelInfoElements(elements: any[]): ModelInfoElement[] {
        return _.compact(
            elements.map<ModelInfoElement | undefined>(element => {
                if (element['@_type'] !== 'TDM_Item_DigitalModelElementInfo') {
                    return undefined;
                }

                const properties = DesignCaseFileParser.parseProperty(element.Property);

                const { ModelElementID, DigitalModelElementInfoID, ToothNumber } = properties;

                if (!ModelElementID || !DigitalModelElementInfoID || !ToothNumber) {
                    return undefined;
                }

                return {
                    properties,
                    modelElementID: ModelElementID,
                    toothNumber: parseInt(ToothNumber),
                    digitalModelElementInfoID: DigitalModelElementInfoID,
                };
            }),
        );
    }

    private static parseToothElements(elements: any[]): ToothElement[] {
        return _.compact(
            elements.map<ToothElement | undefined>(element => {
                if (element['@_type'] !== 'TDM_Item_ToothElement') {
                    return undefined;
                }

                const properties = DesignCaseFileParser.parseProperty(element.Property);

                const { ToothElementID, ModelElementID, ToothNumber, CacheToothTypeClass } = properties;

                if (!ToothElementID || !ModelElementID || !ToothNumber || !ModelElementID || !CacheToothTypeClass) {
                    return undefined;
                }

                return {
                    properties,
                    modelElementID: ModelElementID,
                    toothNumber: parseInt(ToothNumber),
                    toothElementID: ToothElementID,
                    cacheToothTypeClass: CacheToothTypeClass,
                };
            }),
        );
    }

    private static parseOrderName(orderElements: any[]): string | undefined {
        const firstOrderElement = orderElements[0];
        const properties = firstOrderElement
            ? DesignCaseFileParser.parseProperty(firstOrderElement.Property)
            : undefined;

        return properties?.['IntOrderID'];
    }

    // Parses a design case as an xml string, and returns the structure representing this case.
    static parseDesignCase(data?: string, insertionAxes?: InsertionAxesByModelId): ParsedCaseResult {
        if (!data) {
            throw new Error('No data provided');
        }
        // This data is largely unstructured, so wrapped in a try catch just to CYA.
        const xml = xmlParser.parse(data, {
            attributeNamePrefix: '@_',
            ignoreAttributes: false,
        });

        // xml structure is DentalContainer > Object (MainObject) > Object (... many types)
        // One of these objects in here will be the ModelElementList object.
        // Unless there's only one child, this should always be an array.
        const objects = xml?.DentalContainer?.Object?.Object;
        // Should never happen, but a safety check for if the objects list isn't in the right place.
        if (!objects || !Array.isArray(objects)) {
            throw new Error('No objects found in DentalContainer');
        }

        // xml structure here is Object (ModelElementList) > List > Object (TDM_Item_ModelElement, among others)
        const modelElements = DesignCaseFileParser.findObjectByName(objects, 'ModelElementList');
        // xml structure here is Object (ToothElements) > List > Object (TDM_Item_ToothElement, among others)
        const toothElements = DesignCaseFileParser.findObjectByName(objects, 'ToothElementList');
        const modelElementInfos = DesignCaseFileParser.findObjectByName(objects, 'DigitalModelElementInfoList');
        const orderElements = DesignCaseFileParser.findObjectByName(objects, 'OrderList');
        const linkToothElements = DesignCaseFileParser.findObjectByName(objects, 'LinkToothElementList');
        const linkListElements = DesignCaseFileParser.findObjectByName(objects, 'LinkList');

        // No elements defined
        if (!modelElements || !Array.isArray(modelElements)) {
            throw new Error('No model elements found');
        }

        const hasToothElements = Array.isArray(toothElements) && toothElements.length > 0;

        const parsedModelElements = DesignCaseFileParser.parseModelElements(modelElements, insertionAxes);
        const parsedToothElements = hasToothElements ? DesignCaseFileParser.parseToothElements(toothElements) : [];
        const parsedModelInfoElements = DesignCaseFileParser.parseModelInfoElements(modelElementInfos);
        const parsedLinkToothElements = DesignCaseFileParser.parseLinkToothElements(linkToothElements);
        const parsedLinkElements = DesignCaseFileParser.parseLinkElements(linkListElements);

        // For now, we only return if we find any elements
        // as they're the only thing we care about
        if (!parsedModelElements.length) {
            throw new Error('No parsed model elements found');
        }
        if (hasToothElements && !parsedToothElements.length) {
            throw new Error('No parsed tooth elements found');
        }

        return {
            modelElements: parsedModelElements,
            toothElements: parsedToothElements,
            modelInfoElements: parsedModelInfoElements,
            orderName: DesignCaseFileParser.parseOrderName(orderElements),
            linkToothElements: parsedLinkToothElements,
            linkElements: parsedLinkElements,
        };
    }
}
