import PhotoFrameOverlay from '../../../assets/images/webcam-capture/photo-frame-overlay.svg';
import { PhotoFrameIcon, Spotlight } from '@orthly/ui';
import { Button, stylesFactory, Text, useScreenIsMd, Grid } from '@orthly/ui-primitives';
import clsx from 'clsx';
import React from 'react';
import useMeasure from 'react-use-measure';

const useStyles = stylesFactory(() => ({
    cameraOverlay: {
        alignSelf: 'stretch',
        flexWrap: 'nowrap',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
    },
    cameraAnchor: {
        position: 'relative',
        display: 'flex',
        '&>video': {
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
            borderRadius: 16,
        },
    },
    flipX: {
        transform: 'scaleX(-1)',
    },
}));

function useCountDownTimer(seconds: number, started: boolean = true): number {
    const [count, setCount] = React.useState(seconds);
    const timer = React.useRef<number>();
    React.useEffect(() => {
        if (count > 0 && started) {
            timer.current = window.setTimeout(() => setCount(count - 1), 1000);
            return () => window.clearTimeout(timer.current);
        }
    }, [count, started]);
    return count;
}

function getImageFromVideo(video: HTMLVideoElement, onBlob: (blob: Blob) => void, flipX: boolean = false) {
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx = canvas.getContext('2d');
    if (!ctx) {
        return;
    }
    if (flipX) {
        ctx.scale(-1, 1);
        ctx.drawImage(video, -canvas.width, 0);
    } else {
        ctx.drawImage(video, 0, 0);
    }
    canvas.toBlob(blob => blob && onBlob(blob), 'image/jpg');
}

const photoCrop = {
    marginTop: 0.15,
    height: 0.6,
};

/** crop the photo to a square */

export function cropWebcamCaptureFocusFrame(photoFile: Blob, onBlob: (blob: Blob) => void, size: number = 256) {
    // load the blob
    const img = new Image();
    img.src = URL.createObjectURL(photoFile);
    img.onload = () => {
        URL.revokeObjectURL(img.src);
        // create a canvas of size*size
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        // get the context
        const ctx = canvas.getContext('2d');
        if (!ctx) {
            return;
        }
        // # of pixels to capture
        const width = img.height * photoCrop.height;
        const height = width;
        // # of pixels to skip
        const marginTop = img.height * photoCrop.marginTop;
        const marginLeft = (img.width - width) / 2;
        ctx.drawImage(img, marginLeft, marginTop, width, height, 0, 0, canvas.width, canvas.height);
        // convert to image/jpg
        canvas.toBlob(blob => blob && onBlob(blob), 'image/jpg');
    };
}

function useBestCameraStyleInContainer(
    srcObject: MediaStream,
): [ReturnType<typeof useMeasure>[0], Pick<React.CSSProperties, 'width' | 'height'>] {
    const isMobile = useScreenIsMd();
    const [containerRef, { width, height }] = useMeasure();
    const aspectRatio = srcObject.getVideoTracks()[0]?.getSettings().aspectRatio;
    if (aspectRatio) {
        if (isMobile || !height || (width && height * aspectRatio > width)) {
            return [containerRef, { width, height: width / aspectRatio }];
        }
        return [containerRef, { height, width: height * aspectRatio }];
    }
    return [containerRef, { width, height }];
}

const VideoPlayer = React.forwardRef<
    HTMLVideoElement,
    React.ComponentProps<'video'> & { srcObject: HTMLVideoElement['srcObject']; flipX?: boolean }
>(({ srcObject, flipX, ...props }, outterRef) => {
    const classes = useStyles();
    const innerRef = React.useCallback(
        video => {
            if (video) {
                video.srcObject = srcObject;
                // safari wants this even with autoPlay
                video.play();
            }
            if (typeof outterRef === 'function') {
                outterRef(video);
            } else if (outterRef) {
                outterRef.current = video;
            }
        },
        [outterRef, srcObject],
    );
    return <video ref={innerRef} {...props} className={clsx(classes.flipX, props.className)} />;
});

export const WebcamCaptureCountdown: React.VFC<{
    srcObject: MediaStream;
    onCaptured: (blob: Blob) => void;
    onCancel: () => void;
    facingUser?: boolean;
}> = ({ srcObject, onCaptured, onCancel, facingUser }) => {
    const classes = useStyles();
    const [started, setStarted] = React.useState(false);
    const count = useCountDownTimer(5, started);
    const videoRef = React.useRef<HTMLVideoElement>(null);
    const [containerRef, cameraStyle] = useBestCameraStyleInContainer(srcObject);
    const onCapturedRef = React.useRef(onCaptured);
    onCapturedRef.current = onCaptured;
    React.useEffect(() => {
        if (count <= 0 && videoRef.current) {
            getImageFromVideo(videoRef.current, onCapturedRef.current, facingUser);
        }
    }, [count, facingUser]);
    return (
        <Grid container alignItems={'center'} ref={containerRef} style={{ alignSelf: 'stretch' }}>
            {!started ? (
                <div style={cameraStyle} className={classes.cameraAnchor}>
                    <VideoPlayer autoPlay playsInline muted flipX={facingUser} srcObject={srcObject} />
                    <Spotlight container className={classes.cameraOverlay}>
                        <Text variant={'h6'} color={'WHITE'} align={'center'} medium>
                            Clicking the button below will start a 5-second timer.
                        </Text>
                        <Text variant={'h6'} color={'WHITE'} align={'center'} medium>
                            Big smiles for bonus points!
                        </Text>
                        <Button variant={'primary'} onClick={() => setStarted(true)} style={{ marginTop: 16 }}>
                            Take photo
                        </Button>
                        <Button variant={'secondary'} style={{ backgroundColor: 'unset' }} onClick={onCancel}>
                            Cancel
                        </Button>
                    </Spotlight>
                </div>
            ) : (
                <Spotlight className={classes.cameraAnchor} style={cameraStyle}>
                    <VideoPlayer autoPlay playsInline muted ref={videoRef} flipX={facingUser} srcObject={srcObject} />
                    <Grid container className={classes.cameraOverlay} style={{ justifyContent: 'flex-start' }}>
                        <div style={{ height: `${photoCrop.marginTop * 100}%`, flexShrink: 0 }} />
                        <img src={PhotoFrameOverlay} alt={''} style={{ height: `${photoCrop.height * 100}%` }} />
                        <PhotoFrameIcon style={{ marginTop: 32 }} />
                        <Text variant={'h6'} color={'WHITE'} medium style={{ marginTop: 8 }}>
                            Place your face in the center for the best result
                        </Text>
                    </Grid>
                    <Text variant={'h2'} color={'WHITE'} style={{ position: 'absolute', right: 32, bottom: 16 }}>
                        {count}
                    </Text>
                </Spotlight>
            )}
        </Grid>
    );
};
