import * as THREE from 'three';

interface BoxProps {
    length: number;
    width: number;
    height: number;
    x: number;
    y: number;
    z: number;
}

/**
 * Unlike THREE.BoxBufferGeometry, this creates a geometry with indexed faces (i.e. vertices are shared between the triangles).
 */
export function createBox(props?: Partial<BoxProps>): THREE.BufferGeometry {
    const length = props?.length ?? 1;
    const width = props?.width ?? 1;
    const height = props?.height ?? 1;
    const x = props?.x ?? 0;
    const y = props?.y ?? 0;
    const z = props?.z ?? 0;

    if (length <= 0 || width <= 0 || height <= 0) {
        throw new Error('length, width, and height must be positive');
    }

    const geometry = new THREE.BufferGeometry();

    const positions = [
        [-0.5, -0.5, -0.5],
        [0.5, -0.5, -0.5],
        [0.5, 0.5, -0.5],
        [-0.5, 0.5, -0.5],
        [-0.5, -0.5, 0.5],
        [0.5, -0.5, 0.5],
        [0.5, 0.5, 0.5],
        [-0.5, 0.5, 0.5],
    ];
    geometry.setFromPoints(positions.map(p => new THREE.Vector3(...p)));

    const indices = [
        [0, 2, 1],
        [2, 0, 3],
        [0, 1, 5],
        [5, 4, 0],
        [1, 2, 6],
        [6, 5, 1],
        [2, 3, 7],
        [7, 6, 2],
        [3, 0, 4],
        [4, 7, 3],
        [4, 5, 6],
        [6, 7, 4],
    ];
    geometry.setIndex(indices.flat());

    geometry.scale(length, width, height);
    geometry.translate(x, y, z);

    return geometry;
}

export function createCube(
    center: THREE.Vector3 = new THREE.Vector3(0, 0, 0),
    sideLength: number = 1,
): THREE.BufferGeometry {
    return createBox({
        length: sideLength,
        width: sideLength,
        height: sideLength,
        x: center.x,
        y: center.y,
        z: center.z,
    });
}

interface PlaneOptions {
    // Number of cells in the x direction
    numDivisionsX?: number;
    // Number of cells in the y direction
    numDivisionsY?: number;
    // Length of the plane in the x direction
    lengthX?: number;
    // Length of the plane in the y direction
    lengthY?: number;
    // x-position of the first vertex of the plane
    x?: number;
    // y-position of the first vertex of the plane
    y?: number;
    // z-position of the first vertex of the plane
    z?: number;
}

/**
 * Creates a planar geometry with indexed faces. The plane is divided into rectangular grid cells, each of which
 * consists of two triagular faces.
 */
export function createPlane(options: PlaneOptions = {}) {
    const { numDivisionsX, numDivisionsY, lengthX, lengthY, x, y, z } = {
        numDivisionsX: 1,
        numDivisionsY: 1,
        lengthX: 1,
        lengthY: 1,
        x: 0,
        y: 0,
        z: 0,
        ...options,
    };

    const cellLengthX = lengthX / numDivisionsX;
    const cellLengthY = lengthY / numDivisionsY;

    const positions: THREE.Vector3[] = [];
    const indices: number[] = [];

    for (let j = 0; j <= numDivisionsY; ++j) {
        for (let i = 0; i <= numDivisionsX; ++i) {
            positions.push(new THREE.Vector3(i * cellLengthX + x, j * cellLengthY + y, z));

            if (i < numDivisionsX && j < numDivisionsY) {
                const indLowerLeft = i + j * (numDivisionsX + 1);
                const indLowerRight = indLowerLeft + 1;
                const indUpperLeft = indLowerLeft + numDivisionsX + 1;
                const indUpperRight = indUpperLeft + 1;

                indices.push(indLowerLeft, indLowerRight, indUpperRight);
                indices.push(indUpperRight, indUpperLeft, indLowerLeft);
            }
        }
    }

    const geometry = new THREE.BufferGeometry();
    geometry.setFromPoints(positions);
    geometry.setIndex(indices);

    return geometry;
}
