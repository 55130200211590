// when we update THREE.js we will need to rework this
import * as THREE from 'three';

export function createCircleLineGeometry(radius: number): THREE.Vector3[] {
    const points = new THREE.CircleGeometry(radius, 16).vertices;
    // remove the center point
    points.shift();
    const pts_pairs: THREE.Vector3[] = [];
    for (let i = 0; i < points.length; i++) {
        const pt = points[i];
        const pt_next = points[(i + 1) % points.length];
        if (pt && pt_next) {
            pts_pairs.push(pt.clone());
            pts_pairs.push(pt_next.clone());
        }
    }
    return pts_pairs;
}
