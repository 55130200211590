import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { ItemCheckerUtils, OrderItemV2Utils, ToothUtils } from '@orthly/items';
import type { IOrderItemV2DTO } from '@orthly/items';

interface MinimalFulfillmentWorkflow {
    configuration: {
        waxup_required: boolean;
    };
}

interface MinimalOrder {
    items_v2: IOrderItemV2DTO[] | LabsGqlLabOrderFragment['items_v2'];
    fulfillment_workflow: MinimalFulfillmentWorkflow;
    original_order_id: string | null;
}

/**
 * If the order should be excluded from the Dandy Verified Design workflow, returns a string explaining why.
 */
export function getDandyVerifiedDesignDisallowedReason(order: MinimalOrder): string | null {
    const { items_v2, fulfillment_workflow, original_order_id } = order;

    if (original_order_id) {
        return 'Order is a refab.';
    }

    const [firstItem, ...restItems] = items_v2;

    if (!firstItem || restItems.length) {
        // We must make sure that the order will be sent to Automate after prep. Furthermore, it's not clear at this
        // point how the model design step will fit in with the direct-to-lab workflow.
        return 'Order does not contain exactly one item.';
    }

    const parsedItem = OrderItemV2Utils.parseItem(firstItem);
    if (!(ItemCheckerUtils.isCrownItem(parsedItem) && ToothUtils.toothIsPosterior(parsedItem.unit.unn))) {
        // We must make sure that the order will be sent to Automate after prep.
        return 'Item is not a posterior crown.';
    }

    const crownUnderPartialPref = parsedItem.preference_fields.find(el => el.field_id === 'crown-under-partial');
    if (crownUnderPartialPref?.value === 'Yes') {
        return 'Item is a crown under partial.';
    }

    if (fulfillment_workflow.configuration.waxup_required) {
        // It's not clear at this point how the doctor design preview will fit in with the direct-to-lab workflow.
        return 'Doctor design preview is required.';
    }

    return null;
}
