import { ReviewThisOrderEntrypoint } from './Reviews/ReviewThisOrder';
import { ShowGivenOrderFeedback } from './Reviews/ShowGivenOrderFeedback';
import { OrderListItemTracker, resolveOrderEquivalentForFulfillment } from '@orthly/dentin';
import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { LabsGqlWorkflowStateEnum } from '@orthly/graphql-schema';
import { useSession } from '@orthly/session-client';
import { FlossPalette, CircularProgress, Icon, Zoom } from '@orthly/ui-primitives';
import React from 'react';

interface InboxOrderStepperProps {
    order: LabsGqlOrderPreviewFragment;
    hasIssue: boolean;
}

export type StarRatingType = 0 | 1 | 2 | 3 | 4 | 5;
export const StarRatingInput: React.VFC<{
    onChange?: (newRating: StarRatingType) => void;
    rating: StarRatingType;
    loading?: boolean;
    readonly?: boolean;
    disabled?: boolean;
}> = ({ onChange, rating, loading, readonly, disabled }) => {
    const options = [1, 2, 3, 4, 5] as const;
    const [liveRating, setLiveRating] = React.useState(rating);
    const [animate, setAnimate] = React.useState(false);
    // eslint-disable-next-line no-nested-ternary
    const color = FlossPalette[disabled ? 'LIGHT_GRAY' : !!liveRating ? 'WARNING' : 'LIGHT_GRAY'];
    const [hoverTo, setHoverTo] = React.useState(0);
    return (
        <span>
            {loading && <CircularProgress size={20} />}{' '}
            {options.map(optValue => {
                const filled = optValue <= (liveRating ?? 0);

                const isHoveredTo = !disabled && !readonly && !liveRating && hoverTo >= optValue;
                const animationDelay = 100 + 80 * optValue;
                const content = (
                    <Icon
                        icon={filled ? 'Star' : 'StarBorder'}
                        onMouseOver={() => setHoverTo(optValue)}
                        onMouseOut={() => setHoverTo(0)}
                        style={{
                            color: isHoveredTo ? FlossPalette.WARNING : color,
                            cursor: disabled || readonly ? 'not-allowed' : 'pointer',
                            fontSize: `1.8rem`,
                            marginBottom: `-0.5rem`,
                        }}
                        data-test={`feedback-star-rating-${optValue}`}
                        onClick={ev => {
                            ev.stopPropagation();
                            if (disabled || readonly) {
                                return;
                            }
                            setLiveRating(optValue);
                            setAnimate(true);
                            setTimeout(() => {
                                setAnimate(false);
                                onChange?.(optValue);
                            }, animationDelay);
                        }}
                    />
                );
                return animate && filled ? (
                    <Zoom key={optValue} in={true} timeout={animationDelay} style={{ display: 'inline' }}>
                        {content}
                    </Zoom>
                ) : (
                    <React.Fragment>{content}</React.Fragment>
                );
            })}
        </span>
    );
};

export const OrderSummaryStepper: React.FC<InboxOrderStepperProps> = props => {
    const { order, hasIssue } = props;
    const session = useSession();
    const { delivery_date, ship_date, created_at, ship_status } = resolveOrderEquivalentForFulfillment(
        order,
        order.fulfillment.current,
    );
    const { workflow_state, can_refabricate, can_submit_feedback, can_submit_tryin_feedback } = order;

    if (workflow_state.state === LabsGqlWorkflowStateEnum.Delivered) {
        if (order.review_submission) {
            return <ShowGivenOrderFeedback order={order} review_submission={order.review_submission} />;
        }

        return (
            <ReviewThisOrderEntrypoint
                orderId={order.id}
                canRefabricate={can_refabricate}
                canSubmitFeedback={can_submit_feedback}
                canSubmitTryInFeedback={can_submit_tryin_feedback}
            />
        );
    }

    return (
        <OrderListItemTracker
            hasIssue={hasIssue}
            isManufacturer={session?.organization_type === 'lab'}
            // This field is only utilized by labs.
            // It is in the shared component because the two portals used to be conjoined.
            // Even if unused, I'd rather we _didn't_ proliferate usage of this field in the practice portal,
            // so I am intentionally utilizing an empty string.
            due_date={''}
            estimated_delivery_date={order.practice_dates.estimated_delivery_date}
            tracking_status={order.fulfillment?.current?.shipment?.tracking_status ?? ''}
            tracking_substatus={order.fulfillment?.current?.shipment?.tracking_substatus ?? ''}
            package_accepted_date={order.fulfillment?.current?.shipment?.package_accepted_date ?? ''}
            created_at={created_at}
            ship_date={ship_date}
            ship_status={ship_status}
            delivery_date={delivery_date}
            state={workflow_state.state}
        />
    );
};
