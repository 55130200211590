/* eslint-disable sonarjs/no-identical-functions */
import { I16, Struct, U16, U32, U64, U8 } from '../Utility/Util';
import { DosDateTime } from './DosTime';

// prettier-ignore
const FileRecord_Descriptor = [Struct.magic32(0x04034b50),Struct.field('version', U16),Struct.field('flags', U16),Struct.field('compression', I16),Struct.field('fileTime', DosDateTime),Struct.field('crc', U32),Struct.field('compressedSize', U32),Struct.field('uncompressedSize', U32),Struct.field('fileNameLength', U16),Struct.field('extraFieldLength', U16),Struct.string('fileName', 'fileNameLength'),Struct.array('extraField', U8, 'extraFieldLength'),] as const;
export class FileRecord extends Struct.define<FileRecord>(FileRecord_Descriptor) {
    static readonly MAGIC = 0x04034b50;
    version: number = 0;
    flags: number = 0;
    compression: number = 0;
    fileTime: Date = new Date();
    crc: number = 0;
    compressedSize: number = 0;
    uncompressedSize: number = 0;
    fileName: string = '';
    extraField: (typeof U8)['Empty'] = U8.Empty;
    constructor(init?: Partial<FileRecord>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const DirEntry_Descriptor = [Struct.magic32(0x02014b50),Struct.field('versionMadeBy', U16),Struct.field('versionToExtract', U16),Struct.field('flags', U16),Struct.field('compression', I16),Struct.field('fileTime', DosDateTime),Struct.field('crc', U32),Struct.field('compressedSize', U32),Struct.field('uncompressedSize', U32),Struct.field('fileNameLength', U16),Struct.field('extraFieldLength', U16),Struct.field('commentLength', U16),Struct.field('diskNumberStart', U16),Struct.field('internalAttributes', U16),Struct.field('externalAttributes', U32),Struct.field('headerOffset', U32),Struct.string('fileName', 'fileNameLength'),Struct.array('extraField', U8, 'extraFieldLength'),Struct.string('comment', 'commentLength'),] as const;
export class DirEntry extends Struct.define<DirEntry>(DirEntry_Descriptor) {
    static readonly MAGIC = 0x02014b50;
    versionMadeBy: number = 0;
    versionToExtract: number = 0;
    flags: number = 0;
    compression: number = 0;
    fileTime: Date = new Date();
    crc: number = 0;
    compressedSize: number = 0;
    uncompressedSize: number = 0;
    diskNumberStart: number = 0;
    internalAttributes: number = 0;
    externalAttributes: number = 0;
    headerOffset: number = 0;
    fileName: string = '';
    extraField: (typeof U8)['Empty'] = U8.Empty;
    comment: string = '';
    constructor(init?: Partial<DirEntry>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const DataDescriptor_Descriptor = [Struct.magic32(0x08074b50),Struct.field('crc', U32),Struct.field('compressedSize', U32),Struct.field('uncompressedSize', U32),] as const;
export class DataDescriptor extends Struct.define<DataDescriptor>(DataDescriptor_Descriptor) {
    static readonly MAGIC = 0x08074b50;
    crc: number = 0;
    compressedSize: number = 0;
    uncompressedSize: number = 0;
    constructor(init?: Partial<DataDescriptor>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const DigialSig_Descriptor = [Struct.magic32(0x05054b50),Struct.field('dataLength', U16),Struct.array('data', U8, 'dataLength'),] as const;
export class DigialSig extends Struct.define<DigialSig>(DigialSig_Descriptor) {
    static readonly MAGIC = 0x05054b50;
    data: (typeof U8)['Empty'] = U8.Empty;
    constructor(init?: Partial<DigialSig>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const EndLocator_Descriptor = [Struct.magic32(0x06054b50),Struct.field('diskNumber', U16),Struct.field('startDiskNumber', U16),Struct.field('entriesOnDisk', U16),Struct.field('entriesInDirectory', U16),Struct.field('directorySize', U32),Struct.field('directoryOffset', U32),Struct.field('commentLength', U16),Struct.string('comment', 'commentLength'),] as const;
export class EndLocator extends Struct.define<EndLocator>(EndLocator_Descriptor) {
    static readonly MAGIC = 0x06054b50;
    diskNumber: number = 0;
    startDiskNumber: number = 0;
    entriesOnDisk: number = 0;
    entriesInDirectory: number = 0;
    directorySize: number = 0;
    directoryOffset: number = 0;
    comment: string = '';
    constructor(init?: Partial<EndLocator>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const EndLocator64_Descriptor = [Struct.magic32(0x07064b50),Struct.field('startDiskNumber', U32),Struct.field('directoryOffset', U64),Struct.field('entriesInDirectory', U32),] as const;
export class EndLocator64 extends Struct.define<EndLocator64>(EndLocator64_Descriptor) {
    static readonly MAGIC = 0x07064b50;
    startDiskNumber: number = 0;
    directoryOffset: bigint = BigInt(0);
    entriesInDirectory: number = 0;
    constructor(init?: Partial<EndLocator64>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const EndLocatorRecord64_Descriptor = [Struct.magic32(0x06064b50),Struct.field('startDiskNumber', U32),Struct.field('rDirectoryRecordSize', U64),Struct.array('rData', U8, 'dataLength'),] as const;
export class EndLocatorRecord64 extends Struct.define<EndLocatorRecord64>(EndLocatorRecord64_Descriptor) {
    static readonly MAGIC = 0x06064b50;
    startDiskNumber: number = 0;
    rDirectoryRecordSize: bigint = BigInt(0);
    rData: (typeof U8)['Empty'] = U8.Empty;
    constructor(init?: Partial<EndLocatorRecord64>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const ExtraData_Descriptor = [Struct.magic32(0x08064b50),Struct.field('length', U32),Struct.array('data', U8, 'length'),] as const;
export class ExtraData extends Struct.define<ExtraData>(ExtraData_Descriptor) {
    static readonly MAGIC = 0x08064b50;
    data: (typeof U8)['Empty'] = U8.Empty;
    constructor(init?: Partial<ExtraData>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const ExtraField_Descriptor = [Struct.field('headerId', U16),Struct.field('dataSize', U16),Struct.array('data', U8, 'dataSize'),] as const;
export class ExtraField extends Struct.define<ExtraField>(ExtraField_Descriptor) {
    headerId: number = 0;
    data: (typeof U8)['Empty'] = U8.Empty;
    constructor(init?: Partial<ExtraField>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}

// prettier-ignore
const WzAESMode_Descriptor = [Struct.field('version', U16),Struct.field('vendor', U16),Struct.field('strength', U8),Struct.field('compression', U16),] as const;
export class WzAESMode extends Struct.define<WzAESMode>(WzAESMode_Descriptor) {
    version: number = 0;
    vendor: number = 0;
    strength: number = 0;
    compression: number = 0;
    constructor(init?: Partial<WzAESMode>) {
        super();
        if (init) {
            Object.assign(this, init);
        }
    }
}
