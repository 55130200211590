import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import React from 'react';
import { Box3, Color, Uniform, Vector3 } from 'three';
import * as THREE from 'three';

// Note: consider to change to phong material shader
// http://www.cs.toronto.edu/~jacobson/phong-demo/

const vertexShader = `
#include <uv_pars_vertex>

varying vec3 norm;
varying vec3 vColor;
varying float inside;

// undercut
uniform vec3 bboxMin;
uniform vec3 bboxMax;

void main() {
    #include <uv_vertex>

    norm = normal;

    bool xInside = position.x >= bboxMin.x && position.x <= bboxMax.x;
    bool yInside = position.y >= bboxMin.y && position.y <= bboxMax.y;
    bool zInside = position.z >= bboxMin.z && position.z <= bboxMax.z;
    
    inside = (xInside && yInside && zInside) ? 1.0 : 0.0;

    vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
    gl_Position = projectionMatrix * modelViewPosition;
#ifndef USE_MAP
    vColor= color;
#endif // !USE_MAP
}
`;

const fragmentShader = `
    #include <uv_pars_fragment>
    #include <map_pars_fragment>

    varying vec3 norm;
    varying vec3 vColor;
    varying float inside;

    uniform vec3 insertionAxis;
    uniform vec3 shadeColor;
    uniform bool desaturate; 

    void main() {
    #ifdef USE_MAP
        vec4 diffuseColor = vec4(1.0, 1.0, 1.0, 1.0);
        #include <map_fragment>
        vec3 color = diffuseColor.xyz;
    #else
        vec3 color = vColor;
    #endif // USE_MAP

        if (desaturate) {
            // relative luminance desaturation
            color = vec3(dot(color, vec3(0.2126, 0.7152, 0.0722)));
        }

        float dotProduct = max(dot(norm, insertionAxis), 0.0);
        float factor = (dotProduct > 0.01 && inside > 0.5) ? 0.5 : 0.0;

        gl_FragColor = vec4(mix(color, shadeColor, factor), 1.0);
    }
`;

export function calculateBBOX(appearanceSettings: ModelAppearance) {
    const { restoratives, restorativeView } = appearanceSettings;
    const restorativesPayload = restoratives[restorativeView];

    const bbox = new Box3();

    restorativesPayload
        .filter(pl => pl.appearance.showUndercutShadow)
        .map(pl => pl.payloadModel.model.geometry)
        .forEach(g => {
            if (!g.boundingBox) {
                g.computeBoundingBox();
            }

            if (g.boundingBox) {
                bbox.expandByPoint(g.boundingBox.min);
                bbox.expandByPoint(g.boundingBox.max);
            }
        });

    return bbox;
}

export type UndercutShader = {
    fragmentShader: string;
    vertexShader: string;
    uniforms: {
        insertionAxis: Uniform;
        shadeColor: Uniform;
        desaturate: Uniform;
        bboxMin: Uniform;
        bboxMax: Uniform;
    };
};

export function useUndercutShader(): UndercutShader {
    return React.useMemo(() => createUndercutShader(), []);
}

/**
 * Create undercut shader.
 */
export function createUndercutShader(): UndercutShader {
    return {
        fragmentShader,
        vertexShader,
        uniforms: {
            ...THREE.UniformsUtils.clone(THREE.ShaderLib.physical.uniforms),
            insertionAxis: new Uniform(new Vector3(0.0, 1.0, 0.0)),
            shadeColor: new Uniform(new Color(0x213db0)),
            desaturate: new Uniform(false),
            bboxMin: new Uniform(new Vector3(-1.0, -1.0, -1.0)),
            bboxMax: new Uniform(new Vector3(1.0, 1.0, 1.0)),
        },
    };
}
