import type { Maybe } from '@orthly/graphql-schema';
import { FlossPalette, stylesFactory, Text } from '@orthly/ui-primitives';
import classNames from 'classnames';
import ReactMarkdown from 'react-markdown';

const useStyles = stylesFactory(() => ({
    markdown: {
        '& p': {
            margin: 0,
            '&+p': {
                marginTop: 12,
            },
        },
        '& ul': {
            listStyle: 'none',
            paddingInlineStart: 16,
            margin: 0,
            '&>li+li': {
                marginTop: 6,
            },
        },
        '& ol': {
            paddingInlineStart: 16,
            '&>li+li': {
                marginTop: 6,
            },
            '& ul': {
                marginTop: 6,
            },
        },
        '& blockquote': {
            borderLeft: `3px solid ${FlossPalette.STROKE_LIGHT}`,
            margin: 0,
            paddingLeft: 12,
            '&:not(:last-child)': {
                marginBottom: 12,
            },
            '& ul': {
                listStyle: 'initial',
                '&:not(:last-child)': {
                    marginBottom: 12,
                },
            },
        },
    },
}));

const Strong: React.FC = ({ children }) => {
    return (
        <Text variant={'caption'} medium>
            {children}
        </Text>
    );
};

export const OrderTimelineMarkdown: React.FC<{ children: Maybe<string>; className?: string }> = ({
    children,
    className,
}) => {
    const classes = useStyles();

    if (!children) {
        return null;
    }

    return (
        <ReactMarkdown className={classNames(classes.markdown, className)} components={{ strong: Strong }}>
            {children}
        </ReactMarkdown>
    );
};
