import { DEFAULT_MODEL_RGB_U8_COLOR, modelClearanceColorMap } from '../ColorRamp/colorMappingFunctions';
import { setMeshColor } from './ModelMeshes';
import { AttributeName, ensureMeshIndex } from '@orthly/forceps';
import * as THREE from 'three';
import type { MeshBVH, HitPointInfo } from 'three-mesh-bvh';

const OPPOSING_JAW_DISTANCE_ATTRIBUTE_NAME: string = 'prepDistance';

export function getOpposingJawDistance(
    geometry: THREE.BufferGeometry | undefined,
): THREE.Float32BufferAttribute | undefined {
    return geometry?.getAttribute(OPPOSING_JAW_DISTANCE_ATTRIBUTE_NAME) as THREE.Float32BufferAttribute | undefined;
}

/**
 * For every point in the canvas model, find the nearest distance to the source
 * model, storing this value for later use
 *
 * Currently this function need only be executed once per model, as the distances
 * between two models will never change
 */
export function computeDistanceToOpposingModel(
    modelSource: THREE.BufferGeometry,
    modelCanvas: THREE.BufferGeometry,
): void {
    // ensure this geometry has an index
    const sourceIndex: MeshBVH = ensureMeshIndex(modelSource);

    if (getOpposingJawDistance(modelCanvas)) {
        return;
    }

    const dists: number[] = [];
    const positions = modelCanvas.attributes[AttributeName.Position]?.array ?? new Float32Array();

    const p = new THREE.Vector3();

    for (let i = 0; i < positions.length; i += 3) {
        const target = {} as HitPointInfo;

        const x = positions[i];
        const y = positions[i + 1];
        const z = positions[i + 2];

        if (x === undefined || y === undefined || z === undefined) {
            continue;
        }

        // find the closest location to this point on `modelSource` and write
        // the distance to `target` by reference
        sourceIndex.closestPointToPoint(p.set(x, y, z), target, 0, 3);

        dists.push(target.distance ?? Infinity);
    }

    modelCanvas.setAttribute(OPPOSING_JAW_DISTANCE_ATTRIBUTE_NAME, new THREE.Float32BufferAttribute(dists, 1));
}

/**
 * Modify the color attribute of this model based on distance to another model
 *
 * This function is destructive and will remove the existing color from the
 * model. It is the responsibility of the caller to ensure the old colors are
 * stored somewhere, if they will require the original colors later
 */
export function applyColorFromDistance(geometry: THREE.BufferGeometry, min: number, max: number): void {
    const distances = getOpposingJawDistance(geometry);

    if (!distances) {
        return;
    }

    const colors = [];

    for (const colorValue of Array.from(distances.array)) {
        const color = modelClearanceColorMap(min, max, colorValue ?? 0.0) ?? DEFAULT_MODEL_RGB_U8_COLOR;

        colors.push(color.r / 255.0, color.g / 255.0, color.b / 255.0);
    }

    setMeshColor(geometry, new THREE.Float32BufferAttribute(colors, 3));
}
