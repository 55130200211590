import { useGuidedWaxupTabs, usePresetTabClick } from '../GuidedWaxup.util';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupSelector } from '../state/GuidedWaxupState';
import { FlossPalette, Text, stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    tabsContainer: {
        justifyContent: 'space-between',
    },
    selectedTab: {
        borderBottom: `2px solid ${FlossPalette.PRIMARY_FOREGROUND}`,
    },
    unselectedTab: {
        borderBottom: 'none',
    },
}));

export const PresetTabTopbar: React.VFC = () => {
    const classes = useStyles();
    const { selectedTab } = useGuidedWaxupContext();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const tabs = useGuidedWaxupTabs(presets);
    const presetTabClick = usePresetTabClick();
    return (
        <Grid container className={classes.tabsContainer}>
            {tabs.map(tab => {
                const selected = selectedTab === tab.value;
                return (
                    <Grid
                        item
                        onClick={() => presetTabClick(tab)}
                        key={tab.value}
                        className={selected ? classes.selectedTab : classes.unselectedTab}
                    >
                        <Text variant={'body2'} medium color={selected ? 'PRIMARY_FOREGROUND' : 'GRAY'}>
                            {tab.label}
                        </Text>
                    </Grid>
                );
            })}
        </Grid>
    );
};
