import { OrderAction, OrderActionTrackerIdMap } from './OrderActionsUtils';
import { AttachScansAction } from './practitioners/AttachScans/AttachScansAction';
import { SelectScansAction } from './practitioners/AttachScans/SelectScansAction';
import { useRepackageScansAction } from './practitioners/AttachScans/utils/Reloader.util';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { OrderToolbar, PracticeFullScreenDialog, PracticeScreen } from '@orthly/dentin';
import { FullScreenWorkflowContainer } from '@orthly/dentin';
import type { ScanEntry } from '@orthly/forceps';
import type { LabsGqlLabOrderFragment, LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import { OrderItemV2Utils } from '@orthly/items';
import { Format } from '@orthly/runtime-utils';
import { LoadBlocker } from '@orthly/ui';
import type { ButtonVariant, SlideProps } from '@orthly/ui-primitives';
import { stylesFactory, FlossPalette, Tooltip, Button, Text } from '@orthly/ui-primitives';
import { getOrderEditMode, OrderEditMode, OrderEditDeliveryDate } from '@orthly/veneer';
import { NewOrderBanner } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = stylesFactory(() => ({
    badge: {
        backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
        color: FlossPalette.SECONDARY_FOREGROUND,
        borderRadius: 2,
        fontSize: 12,
        marginLeft: 8,
        padding: '0 8px',
    },
}));

enum AttachActionType {
    AttachScans = 'AttachScans',
    SelectScans = 'SelectScans',
}

const getActionTitles = (attachAction: AttachActionType) => {
    switch (attachAction) {
        case AttachActionType.AttachScans:
            return { title: 'Additional scans', subtitle: 'What case do you want to pull from?' };
        case AttachActionType.SelectScans:
            return { title: 'Additional scans', subtitle: 'What scan do you want to upload to this order?' };
    }
};

const getOnBackAction = (
    attachAction: AttachActionType,
    setAttachAction: (action: AttachActionType) => void,
    setSelectedScanFiles: (files: string[]) => void,
    setOpen: (value: boolean) => void,
) => {
    switch (attachAction) {
        case AttachActionType.AttachScans:
            return () => setOpen(false);
        case AttachActionType.SelectScans:
            return () => {
                setSelectedScanFiles([]);
                setAttachAction(AttachActionType.AttachScans);
            };
    }
};

const getNextButtonAdditionalContent = (
    attachAction: AttachActionType,
    numberOfSelectedScans: number,
): React.ReactNode | undefined => {
    if (attachAction !== AttachActionType.SelectScans) {
        return undefined;
    }

    return (
        <Text variant={'body2'} color={numberOfSelectedScans > 0 ? 'BLACK' : 'GRAY'} style={{ marginTop: 4 }}>
            {numberOfSelectedScans > 0
                ? `${Format.pluralize('scan', numberOfSelectedScans)} selected`
                : 'Select a scan to continue'}
        </Text>
    );
};

const AttachFilesOrScansTitle: React.VFC<{
    title: string;
    subtitle: string;
}> = ({ title, subtitle }) => {
    return (
        <>
            <Text variant={'h4'} medium color={'LIGHT_GRAY'} style={{ marginBottom: -4 }}>
                {title}
            </Text>
            <Text variant={'h3'} medium style={{ marginBottom: 20 }}>
                {subtitle}
            </Text>
        </>
    );
};

const onOpenAttachScansAction = (orderId: string, setOpen: (value: boolean) => void) => {
    BrowserAnalyticsClientFactory.Instance?.track('Practice - Attach Scans - Opened Attach Scans', {
        $groups: { order: orderId },
    });
    setOpen(true);
};

export const AddExistingScanForm: React.FC<{
    order: LabsGqlLabOrderFragment;
    setOpen: (value: boolean) => void;
    setScansToastOpen?: (open: boolean) => void;
    setErrorToastOpen?: (open: boolean) => void;
    onUploadAction?: () => void;
}> = ({ order, setOpen, setScansToastOpen, setErrorToastOpen, onUploadAction }) => {
    const history = useHistory();
    const [loading, setLoading] = React.useState(false);
    const [loaderText, setLoaderText] = React.useState('');
    const [animationDirection, setAnimationDirection] = React.useState<SlideProps['direction'] | undefined>(undefined);
    const [triggerAnimation, setTriggerAnimation] = React.useState(false);
    const [attachAction, setAttachAction] = React.useState(AttachActionType.AttachScans);
    const [orderEtaValidation, setOrderEtaValidation] = React.useState(false);
    const previousAttachAction = React.useRef<AttachActionType | null>(null);
    const { editMode } = getOrderEditMode({ order, newScan: true });
    const orderIsCancelResubmit = editMode === OrderEditMode.CancelAndResubmit;
    /*
     * Tracks the patient scan selected on the AttachScansAction screen.
     */
    const [selectedScan, setSelectedScan] = React.useState<LabsGqlPatientScanFileFragment | null>(null);
    /*
     * Tracks the scan files that are downloaded when the doctor selects a patient scan.
     */
    const [scans, setScans] = React.useState<ScanEntry[]>([]);
    /*
     * Tracks the scan file names the doctor wishes to attach to the order.
     */
    const [selectedScanFiles, setSelectedScanFiles] = React.useState<string[]>([]);

    const onScansUploadSuccess = (orderId: string) => {
        if (orderId !== order.id) {
            history.push(`/${PracticeScreen.orders}/${orderId}`);
        }

        onUploadAction?.();
        setScansToastOpen?.(true);
        setOpen(false);

        /*
         * This is impossible, but I'd rather not force cast selectedScan.
         */
        if (!selectedScan) {
            return;
        }

        const selectedScanTypes = scans
            .filter(scan => selectedScanFiles.includes(scan.file_name ?? ''))
            .map(scan => scan.definition.type);

        if (orderIsCancelResubmit) {
            BrowserAnalyticsClientFactory.Instance?.track(
                'Practice - Attach Scans - Order Cancelled and Resubmitted With New Scans',
                {
                    scanFileCreationDate: new Date(selectedScan.created_at),
                    scanFileTypes: selectedScanTypes,
                    $groups: { order: orderId },
                },
            );
        } else {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Attach Scans - Order Edited With New Scans', {
                scanFileCreationDate: new Date(selectedScan.created_at),
                scanFileTypes: selectedScanTypes,
                $groups: { order: orderId },
            });
        }
    };

    const selectedScans = scans.filter(scan => selectedScanFiles.includes(scan.file_name ?? ''));
    const repackageScanAction = useRepackageScansAction(
        order,
        selectedScans,
        setLoading,
        setLoaderText,
        onScansUploadSuccess,
        () => setErrorToastOpen?.(true),
    );
    const onBackAction = getOnBackAction(attachAction, setAttachAction, setSelectedScanFiles, setOpen);

    const orderItems = OrderItemV2Utils.parseItems(order.items_v2);

    const disableNext = React.useMemo(() => {
        switch (editMode) {
            case OrderEditMode.OrderEdit:
                return attachAction === AttachActionType.SelectScans && selectedScanFiles.length === 0;
            case OrderEditMode.CancelAndResubmit:
                return (
                    attachAction === AttachActionType.SelectScans &&
                    (selectedScanFiles.length === 0 || !orderEtaValidation)
                );
            default:
                return false;
        }
    }, [editMode, attachAction, selectedScanFiles.length, orderEtaValidation]);

    React.useEffect(() => {
        /*
         * We don't want to animate if the doctor is moving from the AttachScans screen to the AttachFiles screen,
         * since the only way to navigate back from AttachScans is by clicking the 'Back' button in the bottom nav
         * which controls its animation independently. Otherwise we'd get a double animation.
         *
         * If the doctor is moving from the AttachScansAction screen to the SelectScansAction screen,
         * we know it is a 'back' operation, so we'll animate the screen up. Otherwise, animate the screen down.
         */
        if (previousAttachAction.current !== AttachActionType.AttachScans) {
            setAnimationDirection(
                previousAttachAction.current === AttachActionType.SelectScans &&
                    attachAction === AttachActionType.AttachScans
                    ? 'up'
                    : 'down',
            );

            setTriggerAnimation(t => !t);
        }

        previousAttachAction.current = attachAction;
    }, [attachAction]);

    const orderEditMode = editMode ?? undefined;
    const orderStatus = order.status;
    const orderActiveTaskType = order.fulfillment_workflow.active_task?.type;

    return (
        <FullScreenWorkflowContainer
            title={<AttachFilesOrScansTitle {...getActionTitles(attachAction)} />}
            onBack={onBackAction}
            onNext={attachAction === AttachActionType.SelectScans ? repackageScanAction : undefined}
            disableNext={disableNext}
            AdditionalContent={
                orderIsCancelResubmit && attachAction === AttachActionType.SelectScans ? (
                    <OrderEditDeliveryDate
                        isLastPage
                        originalDeliveryEta={order.practice_dates.estimated_delivery_date}
                        originalDesignPreviewEta={order.practice_dates.digital_design_preview_estimated_completion_date}
                        // We set changedItems to the default list of items because no item editing occurs in the add attachments flow.
                        changedItems={orderItems}
                        hasPassedEtaValidation={orderEtaValidation}
                        setHasPassedEtaValidation={setOrderEtaValidation}
                        doctorId={order.doctor_preferences_id}
                        hasWaxup={order.fulfillment_workflow.configuration.waxup_required}
                        orderItems={orderItems}
                    />
                ) : (
                    <></>
                )
            }
            navAreaWrapperStyle={{ alignItems: 'baseline' }}
            NextButtonAdditionalContent={getNextButtonAdditionalContent(attachAction, selectedScanFiles.length)}
            nextTitle={orderIsCancelResubmit ? 'Submit Changes as New Order' : 'Upload Scans'}
            backTitle={attachAction === AttachActionType.AttachScans ? 'Cancel' : 'Back'}
            nextButtonProps={orderIsCancelResubmit ? { variant: 'alert' } : undefined}
            animationDirection={animationDirection}
            triggerAnimation={triggerAnimation}
            BannerComponent={
                orderStatus &&
                orderActiveTaskType &&
                orderEditMode && (
                    <NewOrderBanner
                        orderStatus={orderStatus}
                        orderActiveTaskType={orderActiveTaskType}
                        orderEditMode={orderEditMode}
                    />
                )
            }
        >
            <LoadBlocker blocking={loading} loaderText={loaderText}>
                {attachAction === AttachActionType.AttachScans && (
                    <AttachScansAction
                        order={order}
                        setScans={setScans}
                        setLoading={setLoading}
                        setSelectedScan={setSelectedScan}
                        onSelectScanAction={() => setAttachAction(AttachActionType.SelectScans)}
                    />
                )}
                {attachAction === AttachActionType.SelectScans && !!selectedScan && (
                    <SelectScansAction
                        order={order}
                        scans={scans}
                        selectedScan={selectedScan}
                        selectedScanFiles={selectedScanFiles}
                        setSelectedScanFiles={setSelectedScanFiles}
                        setLoading={setLoading}
                        setLoaderText={setLoaderText}
                        onBackAction={onBackAction}
                    />
                )}
            </LoadBlocker>
        </FullScreenWorkflowContainer>
    );
};

export const AddExistingScanAction: React.FC<{
    order: LabsGqlLabOrderFragment;
    style?: React.CSSProperties;
    fullWidth?: boolean;
    buttonVariant?: ButtonVariant;
    leftAlign?: boolean;
    setScansToastOpen?: (open: boolean) => void;
    setErrorToastOpen?: (open: boolean) => void;
}> = ({
    order,
    style,
    buttonVariant = 'ghost',
    leftAlign,
    setScansToastOpen,
    setErrorToastOpen,
    fullWidth = false,
}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { editMode } = getOrderEditMode({ order, newScan: true });
    const disableAttachScans = editMode === OrderEditMode.Disabled || !order.can_attach_scans;

    if (!order.can_attach_scans) {
        return null;
    }

    return (
        <>
            <PracticeFullScreenDialog open={open}>
                <OrderToolbar order={order} onClose={() => setOpen(false)} />
                <AddExistingScanForm
                    order={order}
                    setOpen={setOpen}
                    setScansToastOpen={setScansToastOpen}
                    setErrorToastOpen={setErrorToastOpen}
                />
            </PracticeFullScreenDialog>
            <Tooltip
                title={disableAttachScans ? 'You cannot add a scan to an order that’s already been fabricated.' : ''}
                arrow
            >
                <span>
                    <Button
                        leftAlign={leftAlign}
                        fullWidth={fullWidth}
                        data-tracker-id={OrderActionTrackerIdMap[OrderAction.AttachExistingScan]}
                        variant={buttonVariant}
                        style={style}
                        disabled={disableAttachScans}
                        startIcon={'CloudUploadIcon'}
                        onClick={() => onOpenAttachScansAction(order.id, setOpen)}
                        data-test={'attach-existing-scans-action'}
                    >
                        Attach existing scan {<span className={classes.badge}>New</span>}
                    </Button>
                </span>
            </Tooltip>
        </>
    );
};
