import { OrderTimelineV2 } from '../components/PracticeOrderChatV2/components/OrderTimeline/OrderTimeline';
import { refetchTimelineItemsAtom } from '../components/atoms/Timeline.atoms';
import type { LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { useTimelineV2ItemsQuery } from '@orthly/graphql-react';
import { FlossPalette, Text, styled } from '@orthly/ui-primitives';
import { useSetAtom } from 'jotai';
import React from 'react';

const OrderDetailBlock = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: 12,
    borderRadius: 16,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    overflow: 'hidden',
    maxHeight: 430,
});

const HeaderText = styled('div')({
    display: 'flex',
    height: '40px',
    backgroundColor: FlossPalette.DARK_TAN,
    width: '100%',
    alignItems: 'center',
    paddingLeft: '24px',
});

interface OrderDetailTimelineBlockProps {
    order: LabsGqlSingleLabOrderFragment;
}

export const OrderDetailTimelineBlock: React.FC<OrderDetailTimelineBlockProps> = ({ order }) => {
    const {
        data,
        refetch,
        loading: isLoadingItems,
    } = useTimelineV2ItemsQuery({
        variables: { entityId: order?.id ?? '' },
        skip: !order?.id,
    });

    const setRefetchTimelineItemsAtom = useSetAtom(refetchTimelineItemsAtom);

    React.useEffect(() => {
        setRefetchTimelineItemsAtom(() => refetch);
        return () => setRefetchTimelineItemsAtom(null);
    }, [refetch, setRefetchTimelineItemsAtom]);

    return (
        <OrderDetailBlock data-pendo-id={'order-detail-timeline'}>
            <HeaderText>
                <Text variant={'body2'} bold color={'DARK_GRAY'}>
                    Timeline
                </Text>
            </HeaderText>
            <OrderTimelineV2
                events={data?.timelineV2Items ?? []}
                isLoadingItems={isLoadingItems}
                order={order}
                showScrollToTop
            />
        </OrderDetailBlock>
    );
};
