import type { ListNode } from '../../Utils/CircularLinkedList';
import { CircularLinkedList } from '../../Utils/CircularLinkedList';

class FacetParser {
    private _stream: DataView;

    private _streamOffset: number;

    private _facets: [number, number, number][];

    private _sequenceIndex: number;

    private _activeBoundary: CircularLinkedList<number>;

    private _activeVertex?: ListNode<number>;

    private _commandTable: readonly (() => void)[];

    constructor(data: ArrayBuffer) {
        this._stream = new DataView(data);
        this._streamOffset = 0;
        this._facets = [];
        this._sequenceIndex = 0;
        this._activeBoundary = new CircularLinkedList<number>();
        this._commandTable = [
            this.addToBoundarySeq.bind(this),
            this.stitchBoundary.bind(this),
            this.stitchBoundaryAhead.bind(this),
            this.advanceActiveVertex.bind(this),
            this.newMeshSeq.bind(this),
            this.newMesh.bind(this),
            this.newMesh.bind(this),
            this.addToBoundary.bind(this),
            this.addToBoundary.bind(this),
            this.removeActiveFromBoundary.bind(this),
            this.incrementSeq.bind(this),
        ] as const;
    }

    parse(): [number, number, number][] {
        while (this._streamOffset < this._stream.byteLength) {
            const commandId = this.readByte();
            const command = this._commandTable[commandId];

            if (command === undefined) {
                throw new Error(`Unsupported facet parser command: ${commandId}`);
            }

            command();
        }
        return this._facets;
    }

    private createFacet(v0: number, v1: number, v2: number) {
        this._facets.push([v0, v1, v2]);
    }

    private addToBoundarySeq() {
        if (!this._activeVertex) {
            throw new Error(`addToBoundarySeq requires an active boundary.`);
        }
        this.addToBoundaryCommon(this._sequenceIndex);
        this._sequenceIndex += 1;
    }

    private stitchBoundary() {
        if (!this._activeVertex) {
            throw new Error(`stitchBoundary requires an active boundary.`);
        }
        const node = this._activeVertex.next;
        this.createFacet(this._activeVertex.value, this._activeVertex.prev.value, node.value);
        this._activeBoundary.remove(this._activeVertex);
        this._activeVertex = node;
    }

    private stitchBoundaryAhead() {
        if (!this._activeVertex) {
            throw new Error(`stitchBoundaryAhead requires an active boundary.`);
        }
        const node = this._activeVertex.next.next;
        this.createFacet(this._activeVertex.value, node.value, this._activeVertex.next.value);
        this._activeBoundary.remove(this._activeVertex.next);
        this._activeVertex = node;
    }

    private advanceActiveVertex() {
        if (this._activeVertex) {
            this._activeVertex = this._activeVertex.next;
        }
    }

    private newMeshSeq() {
        const n = this._sequenceIndex;
        this.newMeshCommon(n, n + 1, n + 2);
        this._sequenceIndex += 3;
    }

    private newMesh() {
        this.newMeshCommon(this.readInt32(), this.readInt32(), this.readInt32());
    }

    private newMeshCommon(v0: number, v1: number, v2: number) {
        this.createFacet(v0, v1, v2);
        this._activeBoundary.clear();
        this._activeBoundary.insertLast(v0);
        this._activeBoundary.insertLast(v1);
        this._activeBoundary.insertLast(v2);
        this._activeVertex = this._activeBoundary.first;
    }

    private addToBoundary() {
        this.addToBoundaryCommon(this.readInt32());
    }

    private addToBoundaryCommon(n: number) {
        if (!this._activeVertex) {
            throw new Error('addToBoundary requires an active boundary.');
        }
        const node = this._activeVertex.next;
        this.createFacet(n, node.value, this._activeVertex.value);
        this._activeBoundary.insertAfter(this._activeVertex, n);
        this._activeVertex = node;
    }

    private removeActiveFromBoundary() {
        if (!this._activeVertex) {
            throw new Error('removeActiveFromBoundary requires an active boundary.');
        }
        const { prev, next } = this._activeVertex;
        if (prev.value === next.value) {
            this._activeBoundary.remove(prev);
        }
        this._activeBoundary.remove(this._activeVertex);
        this._activeVertex = next;
    }

    private incrementSeq() {
        this._sequenceIndex += 1;
    }

    private readByte() {
        const byte = this._stream.getUint8(this._streamOffset);
        this._streamOffset += Uint8Array.BYTES_PER_ELEMENT;
        return byte;
    }

    private readInt32() {
        const byte = this._stream.getInt32(this._streamOffset, true);
        this._streamOffset += Int32Array.BYTES_PER_ELEMENT;
        return byte;
    }
}

export function parseFacets(data: ArrayBuffer): [number, number, number][] {
    const parser = new FacetParser(data);
    return parser.parse();
}
