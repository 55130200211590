import { toggleAction } from '../GuidedWaxup.util';
import { GUIDED_WAXUP_COMMON_STYLES } from '../VisualConstants.util';
import { VisibilityToggleDesktop } from './VisibilityToggleDesktop';
import type { AggregateToggle, ExternalAppearanceApp, ModelAppearance } from '@orthly/dentin';
import { FlossPalette, Grid, Text, stylesFactory } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    modelVisibility: {
        padding: '15px 15px 15px 30px',
        ...GUIDED_WAXUP_COMMON_STYLES,
        borderRight: 'none',
        borderTop: 'none',
    },
    showAll: {
        color: FlossPalette.PRIMARY_FOREGROUND,
        cursor: 'pointer',
        opacity: 1,
        paddingLeft: 5,

        // class is used on non-button element so select on aria-disabled instead
        ['&[aria-disabled="true"]']: {
            cursor: 'default',
            opacity: 0.5,
        },
    },
}));

export const GeneralReviewVisibilityControls: React.VFC<{
    toggles: AggregateToggle[];
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    externalAppearanceApp: ExternalAppearanceApp;
}> = ({ toggles, appearance, setAppearance }) => {
    const items = appearance.scans.length
        ? [...appearance.restoratives.CAD, ...appearance.preExtractionScans]
        : appearance.restoratives.CAD;
    const classes = useStyles();
    const showAllEnabled = toggles.some(toggle => !toggle.state.checked);
    return (
        <>
            <Grid item className={classes.modelVisibility}>
                <Grid container justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Text variant={'caption'} medium color={'DARK_GRAY'}>
                        MODEL VISIBILITY
                    </Text>
                    <Text
                        role={'button'}
                        variant={'caption'}
                        aria-disabled={!showAllEnabled}
                        medium
                        onClick={() => {
                            if (showAllEnabled) {
                                toggles.forEach(toggle => {
                                    toggle.setChecked(true);
                                });
                                items.forEach(pma => {
                                    toggleAction(setAppearance, pma);
                                });
                            }
                        }}
                        className={classes.showAll}
                    >
                        Show all
                    </Text>
                </Grid>
            </Grid>
            {toggles.map(toggle => (
                <VisibilityToggleDesktop
                    key={toggle.label}
                    toggleLabel={toggle.label}
                    toggleAction={toggle.setChecked}
                    toggled={toggle.state.checked}
                />
            ))}
            {_.sortBy(items, pma => pma.payloadModel.name).map(pma => (
                <VisibilityToggleDesktop
                    key={pma.payloadModel.name}
                    toggleLabel={pma.payloadModel.name}
                    toggleAction={toggleAction(setAppearance, pma)}
                    toggled={pma.appearance.visible}
                />
            ))}
        </>
    );
};
