import { findClosestPointToPolyline } from '../MarginLines';
import { logger } from '../Utils/Logger';
import { AttributeName } from './BufferAttributeConstants';
import { getTriangleByIndex, getVertex, getVertexNormal } from './Mesh3d.util';
import type { AdjacencyMatrix } from './MeshConnectivityGraph';
import { ensureMeshIndex } from './MeshIndex';
import type { CementGapSettings } from './PrepSite.util';
import { DEFAULT_CEMENT_GAP_SETTINGS, idealGapDistanceFunction } from './PrepSite.util';
import * as THREE from 'three';

export function projectIntaglioToIdealSurface(
    geometry: THREE.BufferGeometry,
    idealSurface: THREE.BufferGeometry,
    meshAdj: AdjacencyMatrix,
) {
    const positions = geometry.getAttribute(AttributeName.Position);
    const normals = geometry.getAttribute(AttributeName.Normal);
    const isIntaglio = geometry.getAttribute(AttributeName.IsIntaglio);
    if (!(positions && normals && isIntaglio)) {
        return;
    }
    const idealSurfaceIndex = ensureMeshIndex(idealSurface);

    // loop through the vertices of the geometry and project them to the ideal surface through the normal
    const vertex = new THREE.Vector3();
    const normal = new THREE.Vector3();
    for (let i = 0; i < positions.count; i++) {
        // check if the vertex is in intaglio region
        if (!isIntaglio.getX(i) || meshAdj[i]?.some(v => !isIntaglio.getX(v))) {
            continue;
        }

        vertex.fromBufferAttribute(positions, i);
        normal.fromBufferAttribute(normals, i);

        const closestPoint = idealSurfaceIndex.closestPointToPoint(vertex);
        if (closestPoint) {
            vertex.copy(closestPoint.point);
        }

        positions.setXYZ(i, vertex.x, vertex.y, vertex.z);
    }
    positions.needsUpdate = true;
}

const MAX_ITERATIONS = 20;
const MAX_STEP_SIZE = 0.01;
const SMOOTHING_FACTOR = 10000;

export function conformIntaglioToIdealizedPrepSite(
    intaglio: THREE.BufferGeometry,
    intaglioAdj: AdjacencyMatrix,
    prepSite: THREE.BufferGeometry,
    margin: THREE.Vector3[],
    idealGapParameters: CementGapSettings = DEFAULT_CEMENT_GAP_SETTINGS,
) {
    const prepBvh = ensureMeshIndex(prepSite);
    const posAttr = intaglio.getAttribute(AttributeName.Position);
    const isIntaglioAttr = intaglio.getAttribute(AttributeName.IsIntaglio);

    let maxStepSize = Infinity;
    const vertex = new THREE.Vector3();
    const normal = new THREE.Vector3();
    const vec = new THREE.Vector3();
    const triangle = new THREE.Triangle();
    let iteration = 0;
    while (maxStepSize > MAX_STEP_SIZE && iteration < MAX_ITERATIONS) {
        maxStepSize = 0;
        const affectedVertices = [];
        let d = 0;
        for (let i = 0; i < posAttr.count; i++) {
            const neighbors = intaglioAdj[i];

            // check if the vertex is in intaglio region
            if (!neighbors || isIntaglioAttr.getX(i) === 0 || neighbors.some(v => isIntaglioAttr.getX(v) === 0)) {
                continue;
            }
            getVertex(i, intaglio, vertex);
            getVertexNormal(i, intaglio, normal);
            const closest = prepBvh.closestPointToPoint(vertex);
            if (!closest) {
                continue;
            }
            getTriangleByIndex(prepSite, closest.faceIndex, triangle);
            triangle.getNormal(vec);
            if (vec.dot(normal) > 0) {
                normal.negate();
            }
            const { closestDistance } = findClosestPointToPolyline(margin, vertex, true, false);
            const distance =
                vec.dot(vertex) -
                vec.dot(closest.point) -
                idealGapDistanceFunction(closestDistance, idealGapParameters);
            const offset = new THREE.Vector3();
            let l = 0;
            for (const vj of neighbors) {
                getVertex(vj, intaglio, vec);
                vec.sub(vertex);
                l += 1;
                offset.add(vec);
            }
            offset
                .multiplyScalar(Math.min(0.5, SMOOTHING_FACTOR * distance * distance) / l)
                .addScaledVector(normal, distance);
            affectedVertices.push({ i, offset });
            maxStepSize = Math.max(maxStepSize, offset.length());
            d = Math.max(d, Math.abs(distance));
        }

        for (const { i, offset } of affectedVertices) {
            getVertex(i, intaglio, vertex);
            vertex.add(offset);
            posAttr.setXYZ(i, vertex.x, vertex.y, vertex.z);
        }

        intaglio.computeVertexNormals();
        iteration += 1;
        logger.info('conformIntaglioToIdealizedPrepSite', { iteration, maxStepSize, d });
    }
}
