import { OrdersToolbarDoctorFilter } from '../../../components/OrdersToolbar/OrdersToolbarDoctorFilter';
import { OrdersToolbarFiltersContainer } from '../../../components/OrdersToolbar/OrdersToolbarFiltersContainer';
import { OrdersToolbarMobileScreenSelect } from '../../../components/OrdersToolbar/OrdersToolbarMobileScreenSelect';
import { OrdersToolbarRoot } from '../../../components/OrdersToolbar/OrdersToolbarRoot';
import { OrdersToolbarSearch } from '../../../components/OrdersToolbar/OrdersToolbarSearch';
import { OrdersToolbarSort } from '../../../components/OrdersToolbar/OrdersToolbarSort';
import { useOrdersToolbarSelectProps } from '../../../components/OrdersToolbar/useOrdersToolbarSelectProps';
import { usePracticeAppSelector } from '../../../redux';
import { useOrderAction } from '../../../redux/orders/orders.actions';
import { useOrdersPropSelector, useOrdersSelector } from '../../../redux/orders/orders.selectors';
import type { LabOrderRootStatus } from '../LabsUtils';
import { useOpenOrderDetailPage } from '../LabsUtils';
import { useOrdersOverviewSidebarItems } from './useOrdersOverviewItems';
import { useOrderSearchResults } from '@orthly/graphql-react';
import { LabsGqlProductLine as ProductLine } from '@orthly/graphql-schema';
import { SimpleMultiSelect } from '@orthly/ui';
import { FlossPalette } from '@orthly/ui-primitives';
import React from 'react';

type ProductLineOptValue = Exclude<ProductLine, ProductLine.HighComplexityCrownOrBridge>;

// User readable labels for product lines
const labelMap: Record<ProductLineOptValue, string> = {
    [ProductLine.SimpleCrownOrBridge]: 'Crown & Bridge',
    [ProductLine.Implant]: 'Implants',
    [ProductLine.ImplantSurgery]: 'Surgical Guides',
    [ProductLine.Partial]: 'Partial Dentures',
    [ProductLine.Denture]: 'Full Dentures',
    [ProductLine.Removable]: 'Splints & Guards',
    [ProductLine.Aligner]: 'Aligners',
    [ProductLine.Sleep]: 'Sleep Apnea',
    [ProductLine.Other]: 'Models & Misc',
};

const sortedOptions: ProductLineOptValue[] = [
    ProductLine.SimpleCrownOrBridge,
    ProductLine.Implant,
    ProductLine.ImplantSurgery,
    ProductLine.Partial,
    ProductLine.Denture,
    ProductLine.Removable,
    ProductLine.Aligner,
    ProductLine.Sleep,
    ProductLine.Other,
];
const productLineOptions = sortedOptions.map(value => ({ value, label: labelMap[value] }));

const ProductLineFilter: React.FC = () => {
    const { product_line_filter } = useOrdersPropSelector(['product_line_filter']);
    const setProductFilter = useOrderAction('SET_PRODUCT_FILTER');
    const selectProps = useOrdersToolbarSelectProps();
    return (
        <SimpleMultiSelect
            {...selectProps}
            options={productLineOptions}
            onChange={val => {
                const productLines = (val ?? []).flatMap<ProductLine>(val => {
                    // sanity check its a valid product line
                    if (!Object.values(ProductLine).includes(val as any)) {
                        return [];
                    }
                    // slightly unintuitive - we only show Crown & Bridge once in the list but it's split across 2 product
                    // lines, so we need to add simple to the filter value stored in redux
                    if (val === ProductLine.SimpleCrownOrBridge) {
                        return [ProductLine.SimpleCrownOrBridge, ProductLine.HighComplexityCrownOrBridge];
                    }
                    return val as ProductLine;
                });
                setProductFilter(productLines);
            }}
            label={'Product'}
            // filter out the high complexity crown or bridge option, it's grouped into Simple
            value={product_line_filter.filter(v => v !== ProductLine.HighComplexityCrownOrBridge)}
            placeholder={'All products'}
            variant={'filled'}
            backgroundColor={FlossPalette.WHITE}
            style={{ borderRadius: 0, border: 'none', boxShadow: 'none' }}
        />
    );
};

const FiltersMobile: React.FC = () => {
    const status = usePracticeAppSelector(s => s.orders.status);
    const setStatus = useOrderAction('CHANGE_STATUS');
    const sections = useOrdersOverviewSidebarItems();
    return (
        <OrdersToolbarMobileScreenSelect<LabOrderRootStatus>
            screen={status}
            screenItems={sections}
            setScreen={setStatus}
        />
    );
};

const FiltersDesktop: React.FC = () => {
    return (
        <OrdersToolbarFiltersContainer
            filterOne={
                <OrdersToolbarDoctorFilter
                    selectedDoctorIds={useOrdersSelector(s => s.doctor_id_filter)}
                    setSelectedDoctorIds={useOrderAction('SET_DOCTOR_FILTER')}
                />
            }
            filterTwo={<ProductLineFilter />}
            filterThree={
                <OrdersToolbarSort
                    toggleDirection={useOrderAction('TOGGLE_SORT_DIR')}
                    isAscending={useOrdersSelector(s => s.sort_asc)}
                    setSortColumn={useOrderAction('SET_SORT_COL')}
                    sortColumn={useOrdersSelector(s => s.sort_column)}
                />
            }
        />
    );
};

export const OrdersOverviewToolbar: React.FC = () => {
    const openOrder = useOpenOrderDetailPage();
    return (
        <OrdersToolbarRoot
            DesktopFilters={FiltersDesktop}
            MobileFilters={FiltersMobile}
            searchComponent={
                <OrdersToolbarSearch onPickOrder={openOrder} useOrderSearchResults={useOrderSearchResults} />
            }
        />
    );
};
