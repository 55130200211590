import { AttributeName } from './BufferAttributeConstants';
import type { BufferAttribute, InterleavedBufferAttribute, Object3D } from 'three';
import { Mesh, Vector3 } from 'three';

/**
 * Usage:
 *  const exporter = new STLExporter();
 *
 *  // second argument is a list of options
 *  const data = exporter.parse( mesh, { binary: true } );
 *
 */

export interface STLExporterOptions {
    binary?: boolean;
}

export class STLExporter {
    output: DataView | string | undefined = undefined;
    offset: number = 80; // skip header
    binary: boolean = false;

    vA: THREE.Vector3 = new Vector3();
    vB: THREE.Vector3 = new Vector3();
    vC: THREE.Vector3 = new Vector3();
    cb: THREE.Vector3 = new Vector3();
    ab: THREE.Vector3 = new Vector3();
    normal: THREE.Vector3 = new Vector3();

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    parse(scene: Object3D, options: STLExporterOptions) {
        this.binary = options.binary ?? false;

        const objects: { object3d: Object3D; geometry: THREE.BufferGeometry }[] = [];
        let triangles = 0;

        scene.traverse(function (object: Object3D) {
            if (object instanceof Mesh) {
                const geometry = object.geometry;

                const index = geometry.index;
                const positionAttribute = geometry.getAttribute(AttributeName.Position);

                triangles += index !== null ? index.count / 3 : positionAttribute.count / 3;

                objects.push({
                    object3d: object,
                    geometry,
                });
            }
        });

        if (this.binary === true) {
            const bufferLength = triangles * 2 + triangles * 3 * 4 * 4 + 80 + 4;
            const arrayBuffer = new ArrayBuffer(bufferLength);
            this.output = new DataView(arrayBuffer);

            // 3SHAPE header, literally "3Shape"
            const threeshapeHeader = [51, 83, 104, 97, 112, 101];
            for (let idx = 0; idx < threeshapeHeader.length; idx++) {
                this.output.setUint8(idx, threeshapeHeader[idx] ?? 0);
            }

            this.output.setUint32(this.offset, triangles, true);
            this.offset += 4;
        } else {
            this.output = '';
            this.output += 'solid exported\n';
        }

        this.vA = new Vector3();
        this.vB = new Vector3();
        this.vC = new Vector3();
        this.cb = new Vector3();
        this.ab = new Vector3();
        this.normal = new Vector3();

        for (let i = 0, il = objects.length; i < il; i++) {
            if (!objects[i]) {
                continue;
            }
            const object = objects[i]?.object3d;
            const geometry = objects[i]?.geometry;

            const index = geometry?.index;
            const positionAttribute = geometry?.getAttribute(AttributeName.Position);

            if (positionAttribute === undefined) {
                continue;
            }

            if (index !== null && index !== undefined && positionAttribute !== undefined) {
                // indexed geometry

                for (let j = 0; j < index.count; j += 3) {
                    const a = index.getX(j + 0);
                    const b = index.getX(j + 1);
                    const c = index.getX(j + 2);

                    this.writeFace(a, b, c, positionAttribute, object);
                }
            } else {
                // non-indexed geometry

                for (let j = 0; j < positionAttribute.count; j += 3) {
                    const a = j + 0;
                    const b = j + 1;
                    const c = j + 2;

                    this.writeFace(a, b, c, positionAttribute, object);
                }
            }
        }

        if (this.binary === false) {
            this.output += 'endsolid exported\n';
        }

        return this.output;
    }

    private writeFace(
        a: number,
        b: number,
        c: number,
        positionAttribute: BufferAttribute | InterleavedBufferAttribute,
        object: Object3D | undefined,
    ) {
        if (!object) {
            return;
        }

        this.vA.fromBufferAttribute(positionAttribute, a);
        this.vB.fromBufferAttribute(positionAttribute, b);
        this.vC.fromBufferAttribute(positionAttribute, c);

        this.vA.applyMatrix4(object.matrixWorld);
        this.vB.applyMatrix4(object.matrixWorld);
        this.vC.applyMatrix4(object.matrixWorld);

        this.writeNormal(this.vA, this.vB, this.vC);

        this.writeVertex(this.vA);
        this.writeVertex(this.vB);
        this.writeVertex(this.vC);

        if (this.binary === true && this.output instanceof DataView) {
            this.output.setUint16(this.offset, 0, true);
            this.offset += 2;
        } else {
            this.output += '\t\tendloop\n';
            this.output += '\tendfacet\n';
        }
    }

    private writeNormal(vA: THREE.Vector3, vB: THREE.Vector3, vC: THREE.Vector3) {
        this.cb.subVectors(vC, vB);
        this.ab.subVectors(vA, vB);
        this.cb.cross(this.ab).normalize();

        this.normal.copy(this.cb).normalize();

        if (this.binary === true && this.output instanceof DataView) {
            this.output.setFloat32(this.offset, this.normal.x, true);
            this.offset += 4;
            this.output.setFloat32(this.offset, this.normal.y, true);
            this.offset += 4;
            this.output.setFloat32(this.offset, this.normal.z, true);
            this.offset += 4;
        } else {
            this.output += `\tfacet normal ${this.normal.x} ${this.normal.y} ${this.normal.z}\n`;
            this.output += '\t\touter loop\n';
        }
    }

    private writeVertex(vertex: THREE.Vector3) {
        if (this.binary === true && this.output instanceof DataView) {
            this.output.setFloat32(this.offset, vertex.x, true);
            this.offset += 4;
            this.output.setFloat32(this.offset, vertex.y, true);
            this.offset += 4;
            this.output.setFloat32(this.offset, vertex.z, true);
            this.offset += 4;
        } else {
            this.output += `\t\t\tvertex ${vertex.x} ${vertex.y} ${vertex.z}\n`;
        }
    }
}
