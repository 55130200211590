import { ColorRamp } from './ColorRamp';
import { useHeatMap } from './ColorRamp.hooks';
import type { QCLegendProps } from './ColorRamp.types';
import { Button } from '@mui/material';
import { MagnifierZoomInIcon, MagnifierZoomOutIcon } from '@orthly/ui';
import { FlossPalette, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

// Layout parameters:
// 180 is nicely divisible by many numbers
// the Figma design gave it as 185px tall, but being not perfectly divisible by eg. 5 meant there were
// subpixel rounding issues, and the labels were slightly unevenly spaced
const RAMP_LENGTH = 180;

const StyledIconButton = styled(Button)({
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    padding: '0',
    minWidth: '27px',
    height: '40px',
    color: FlossPalette.GRAY,
    border: 'none',
    backgroundColor: FlossPalette.WHITE,
    '&:hover': {
        backgroundColor: FlossPalette.TAN,
    },
    '&:only-child': {
        width: '100%',
    },
});

const StyledTopIconButton = styled(StyledIconButton)({
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    '&:first-of-type': {
        borderRadius: '8px 0px 0px 0px',
    },
    '&:only-child': {
        borderRadius: '8px 8px 0px 0px',
    },
    '&:last-of-type:not(:first-child)': {
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: '0px 8px 0px 0px',
    },
});

const StyledBottomIconButton = styled(StyledIconButton)({
    borderTop: `1px solid ${FlossPalette.DARK_TAN}`,
    '&:first-of-type': {
        borderRadius: '0px 0px 0px 8px',
    },
    '&:only-child': {
        borderRadius: '0px 0px 8px 8px',
    },
    '&:last-of-type:not(:first-child)': {
        borderLeft: `1px solid ${FlossPalette.DARK_TAN}`,
        borderRadius: '0px 0px 8px 0px',
    },
});

const StyledToolRow = styled('div')({
    display: 'flex',
    flexDirection: 'row',
});

const ColorLegendCard = styled('div')({
    float: 'left',
    zIndex: 21,
    marginLeft: 15,
    borderRadius: 3,
    background: '#FFFFFF',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
    pointerEvents: 'auto',
});

const ColorLegendContainer = styled('div')({
    width: 79,
    paddingTop: 8,
    paddingBottom: 8,
    pointerEvents: 'none',
});

const ColorLegendBar = styled('div')({
    height: '100%',
    marginLeft: 8,
    width: 16,
});
const ColorLegendPoint = styled('div')({
    color: FlossPalette.GRAY,
    fontSize: 12,
    position: 'absolute',
    left: 28,
    height: 0,
    display: 'flex',
    alignItems: 'center',
    pointerEvents: 'none',
});

export const QCColorLegendVertical: React.VFC<QCLegendProps> = props => {
    const { dynamicHeatmaps, hideZoom = false, ...otherProps } = props;
    const { points, label, legendTools, vMin, vMax, heatMap } = useHeatMap(otherProps);
    const showZoomButton = !hideZoom;

    return (
        <ColorLegendCard data-test={'color-legend-card'} aria-label={'Heatmap Color Legend'}>
            {dynamicHeatmaps && (
                <StyledToolRow>
                    <StyledTopIconButton onClick={legendTools.moveWindowUp} aria-label={'Move Up'}>
                        <Icon icon={'ArrowUpIcon'} />
                    </StyledTopIconButton>
                    {showZoomButton && (
                        <StyledTopIconButton onClick={legendTools.zoomIntoWindow} aria-label={'Zoom In'}>
                            <MagnifierZoomInIcon />
                        </StyledTopIconButton>
                    )}
                </StyledToolRow>
            )}
            <ColorLegendContainer>
                <div
                    style={{
                        position: 'relative',
                    }}
                >
                    <ColorLegendBar>
                        <ColorRamp
                            vMin={vMin}
                            vMax={vMax}
                            colorMap={heatMap.reverse ? (a, b, v) => heatMap.fn(0, b - a, b - v) : heatMap.fn}
                            width={16}
                            height={RAMP_LENGTH}
                        />
                    </ColorLegendBar>

                    {points.map(value => {
                        const pct = (value - vMin) / (vMax - vMin);
                        return (
                            <ColorLegendPoint
                                key={value}
                                style={{
                                    bottom: `min(max(calc(1ch + ${pct * RAMP_LENGTH}px), 10px), 180px)`,
                                }}
                            >
                                {label(value)}
                            </ColorLegendPoint>
                        );
                    })}
                </div>
            </ColorLegendContainer>
            {dynamicHeatmaps && (
                <StyledToolRow>
                    <StyledBottomIconButton onClick={legendTools.moveWindowDown} aria-label={'Move Down'}>
                        <Icon icon={'ArrowDownIcon'} />
                    </StyledBottomIconButton>
                    {showZoomButton && (
                        <StyledBottomIconButton onClick={legendTools.zoomOutOfWindow} aria-label={'Zoom Out'}>
                            <MagnifierZoomOutIcon />
                        </StyledBottomIconButton>
                    )}
                </StyledToolRow>
            )}
        </ColorLegendCard>
    );
};
