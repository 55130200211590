import { SettingsSection } from '../account/components/SettingsSection';
import { DeliveryAddresses } from '../account/practice-settings/addresses/DeliveryAddresses';
import { EditDeliveryAddressModal } from '../account/practice-settings/addresses/EditDeliveryAddressModal';
import { PaymentMethodsTitleAction } from '../account/practice-settings/payment/PaymentMethodsTitleAction';
import type { LabsGqlDoctorDeliveryAddressFragment, LabsGqlPaymentMethodFragment } from '@orthly/graphql-operations';
import { FlossPalette, stylesFactory, useScreenIsMobileOrVerticalTablet, Grid, Text } from '@orthly/ui-primitives';
import { PaymentMethods } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory<{ isMobile: boolean }>(() => ({
    addressAndPaymentContainer: {
        border: ({ isMobile }) => (isMobile ? 'none' : `1px solid ${FlossPalette.DARK_TAN}`),
        overflow: 'hidden',
        borderRadius: ({ isMobile }) => (isMobile ? 0 : 16),
    },
}));

interface PaymentMethodAndAddressSectionProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    partnerId?: string;
    loadingAddresses: boolean;
    activeAddresses: LabsGqlDoctorDeliveryAddressFragment[];
    loadingMethods: boolean;
    methods: LabsGqlPaymentMethodFragment[];
    removeMethod: (props: { partnerId: string; cardId: string }) => Promise<void>;
    setDefaultMethod: (props: { partnerId: string; cardId: string }) => Promise<void>;
}

export const PaymentMethodAndAddressSection: React.VFC<PaymentMethodAndAddressSectionProps> = ({
    open,
    setOpen,
    partnerId,
    loadingAddresses,
    activeAddresses,
    loadingMethods,
    methods,
    removeMethod,
    setDefaultMethod,
}) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const classes = useStyles({ isMobile });
    return (
        <Grid item container className={classes.addressAndPaymentContainer}>
            <SettingsSection
                title={'Delivery address'}
                subtitle={'These addresses are available during checkout'}
                longSection
                action={
                    <EditDeliveryAddressModal
                        open={open}
                        setOpen={setOpen}
                        partnerId={partnerId ?? ''}
                        buttonVariant={'ghost'}
                        buttonStyles={{ padding: 0, height: 'unset', marginTop: 4 }}
                    />
                }
            >
                {loadingAddresses && activeAddresses?.length === 0 && (
                    <Text variant={'body2'} color={'GRAY'}>
                        You have not added any delivery addresses yet.
                    </Text>
                )}
                <DeliveryAddresses partnerId={partnerId ?? ''} />
            </SettingsSection>
            <SettingsSection
                title={'Payment methods'}
                subtitle={'Used to pay your invoices'}
                longSection
                settingsRowStyle={{ border: 'none' }}
                action={<PaymentMethodsTitleAction />}
            >
                {loadingMethods && methods.length === 0 && (
                    <Text variant={'body2'} color={'GRAY'}>
                        You have not added any payment methods yet.
                    </Text>
                )}
                <PaymentMethods
                    partnerId={partnerId ?? ''}
                    loadedMethods={methods}
                    removeMethod={removeMethod}
                    setDefaultMethod={setDefaultMethod}
                />
            </SettingsSection>
        </Grid>
    );
};
