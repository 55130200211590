import type { WaxupOrder } from '../../Waxups.types';
import type { LabsGqlGuidedWaxupPresetStatus } from '@orthly/graphql-schema';
import { LabsGqlGuidedWaxupPresetType, LabsGqlOrderItemSkuType } from '@orthly/graphql-schema';
import { ToothUtils } from '@orthly/items';
import _ from 'lodash';

export const getGuidedWaxupPresetTypesForOrder = (order: WaxupOrder, teeth: number[]) => {
    const implantOnly = order.items_v2.every(item => item.sku === LabsGqlOrderItemSkuType.Implant);
    const presetTypes = ToothUtils.isAnterior(teeth)
        ? [
              LabsGqlGuidedWaxupPresetType.ToothDesign,
              LabsGqlGuidedWaxupPresetType.MarginView,
              LabsGqlGuidedWaxupPresetType.ContourView,
              LabsGqlGuidedWaxupPresetType.GeneralView,
          ]
        : [
              LabsGqlGuidedWaxupPresetType.ContactDesign,
              LabsGqlGuidedWaxupPresetType.OcclusalAnatomy,
              LabsGqlGuidedWaxupPresetType.MarginView,
              LabsGqlGuidedWaxupPresetType.GeneralView,
          ];
    // remove margin view for implant-only orders
    return presetTypes.filter(pt => !(implantOnly && pt === LabsGqlGuidedWaxupPresetType.MarginView));
};

export interface GuidedWaxupPreset {
    name: string;
}

export interface PresetInfo {
    status?: LabsGqlGuidedWaxupPresetStatus;
    notes?: string;
    annotatedImageUrls?: string[];
}

export interface GuidedWaxupState {
    presets: Partial<Record<LabsGqlGuidedWaxupPresetType, PresetInfo>>;
}
export const INITIAL_GUIDED_WAXUP_STATE: GuidedWaxupState = {
    presets: {},
};

export const getStartingGuidedWaxupStateForOrder = (order: WaxupOrder, teeth: number[]): GuidedWaxupState => {
    const presetTypes = getGuidedWaxupPresetTypesForOrder(order, teeth);
    return { presets: _.fromPairs(presetTypes.map(pt => [pt, {}])) };
};
