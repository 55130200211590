import { lib } from 'crypto-js';
import SHA1 from 'crypto-js/sha1';
import type { PADDING } from 'egoroof-blowfish';
import Blowfish from 'egoroof-blowfish';

const FLIP_BLOCK_SIZE = 4;

export function flipBlockEndianness(data: Uint8Array) {
    for (let i = 0; i < data.byteLength; i += FLIP_BLOCK_SIZE) {
        const block = data.slice(i, i + FLIP_BLOCK_SIZE);
        data.set(block.reverse(), i);
    }
}

const PAD_BLOCK_SIZE = 8;

export function getPadded(data: Uint8Array, padding: PADDING = Blowfish.PADDING.PKCS5) {
    const n = PAD_BLOCK_SIZE * (1 + Math.trunc(data.byteLength / PAD_BLOCK_SIZE));
    const rem = n - data.byteLength;
    if (rem === 8 && padding !== Blowfish.PADDING.PKCS5) {
        return data;
    }
    const padded = new Uint8Array(n);
    padded.set(data);
    switch (padding) {
        case Blowfish.PADDING.PKCS5:
            padded.fill(rem, data.byteLength);
            break;
        case Blowfish.PADDING.ONE_AND_ZEROS:
            padded.fill(0, data.byteLength);
            padded[data.byteLength] = 0x80;
            break;
        case Blowfish.PADDING.LAST_BYTE:
            padded.fill(0, data.byteLength);
            padded[n - 1] = rem;
            break;
        case Blowfish.PADDING.NULL:
            padded.fill(0, data.byteLength);
            break;
        case Blowfish.PADDING.SPACES:
            padded.fill(0x20, data.byteLength);
            break;
    }
    return padded;
}

export function getDefaultKey(): Uint8Array {
    return new Uint8Array([
        0x34, 0x90, 0x02, 0x93, 0x58, 0x2f, 0x49, 0x94, 0x76, 0x02, 0x19, 0xdf, 0x3b, 0x56, 0x44, 0x1c,
    ]);
}

export function getReversedKey(key: Uint8Array): Uint8Array {
    return key.reverse().map(b => b ^ 123);
}

export function getSecondaryKey(): Uint8Array {
    const shuffleScale = 2147483543;
    const shuffleOffset = 83;
    const shuffle = (x: number) => (Math.imul(shuffleScale, x) + shuffleOffset) % shuffleScale;
    const key = new Uint8Array(64);
    let seed = 876546547;
    for (let i = 0; i < key.length; i += 1) {
        seed = shuffle(seed);
        key[i] = (seed >> 8) & 0xff;
    }
    return key;
}

export function getSalt(seed: string | Uint8Array): Uint8Array {
    // Since we have to do the padding manually so the block flipping works correctly, set it to null here.
    const bf = new Blowfish(getSecondaryKey(), Blowfish.MODE.ECB, Blowfish.PADDING.NULL);
    const seedBytes = getPadded(typeof seed === 'string' ? new TextEncoder().encode(seed) : seed.slice());
    flipBlockEndianness(seedBytes);
    const e = bf.encode(seedBytes);
    flipBlockEndianness(e);
    // The create function technically can work on typed arrays but the type definitions don't reflect this.
    const array = lib.WordArray.create(e as any);
    const digest = SHA1(array);
    const digestArray = new Int32Array(digest.words);
    const result = new Uint8Array(digestArray.buffer, 0, digest.sigBytes);
    // crypto-js flips the endianness again. This is getting kind of old.
    flipBlockEndianness(result);
    return result;
}

export function concatKeys(...keys: Uint8Array[]) {
    const length = keys.reduce((l, a) => l + a.length, 0);
    const result = new Uint8Array(length);
    let offset = 0;
    for (const key of keys) {
        result.set(key, offset);
        offset += key.length;
    }
    return result;
}

export function getChecksum(buffer: Uint8Array, byteCount: number) {
    const wrapThreshold = 65521;
    let lo = 1;
    let hi = 0;
    for (let i = 0; i < byteCount; i += 1) {
        lo += buffer[i] as number;
        if (lo >= wrapThreshold) {
            lo -= wrapThreshold;
        }
        hi += lo;
        if (hi >= wrapThreshold) {
            hi -= wrapThreshold;
        }
    }
    const l0 = lo & 0xff;
    const l1 = lo >> 8;
    const h0 = hi & 0xff;
    const h1 = hi >> 8;
    return ((l0 << 24) | (l1 << 16) | (h0 << 8) | h1) >>> 0;
}
