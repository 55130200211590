import { unnFromFacetMark } from './Dcm.utils';
import type { DcmGeometryInjector, BufferGeometryOptions } from './DcmGeometryInjector';
import { ToothUtils } from '@orthly/items';
import { isArrayMin1 } from '@orthly/runtime-utils';
import { assertNever } from '@orthly/shared-types';
import * as THREE from 'three';

function replaceIn<T>(arr: T[] | undefined, needle: T, value: T) {
    arr?.forEach((v, i) => {
        if (v === needle) {
            arr[i] = value;
        }
    });
}

function vecEquals(a: THREE.Vec2 | undefined, b: THREE.Vec2 | undefined) {
    return a === b || (a?.x === b?.x && a?.y === b?.y);
}

interface BuildGeometryArgs {
    facets: number[];
    verts: number[];
    uvs?: number[];
    colors?: number[];
    normals?: number[];
}
function buildGeometry(args: BuildGeometryArgs) {
    const geometry = new THREE.BufferGeometry();
    geometry.setIndex(args.facets);
    geometry.setAttribute('position', new THREE.Float32BufferAttribute(args.verts, 3));

    if (args.uvs && args.uvs.length > 0) {
        geometry.setAttribute('uv', new THREE.Float32BufferAttribute(args.uvs, 2));
    }
    if (args.colors && args.colors.length > 0) {
        geometry.setAttribute('color', new THREE.Float32BufferAttribute(args.colors, 3));
    }
    if (args.normals && args.normals.length > 0) {
        geometry.setAttribute('normal', new THREE.Float32BufferAttribute(args.normals, 3));
    } else {
        geometry.computeVertexNormals();
    }

    geometry.computeBoundingSphere();
    return geometry;
}

/**
 * Used by the mesh builder to duplicate vertices that have differing per-facet properties.
 */
class VertexEmitter {
    /**
     * Each initial vertex can be potentially split into multiple vertices depending on facet
     * properties, this maps each vertex to a list of vertex indices that it has been split into.
     */
    public uvs: THREE.Vec2[] = [];
    public colors: THREE.Color[] = [];
    private splitMap: number[][];
    private vertCount: number;

    /**
     * Constructs a VertexEmitter instance.
     * @param facets array of number triplets which represents the face connectivity
     * @param verts array of Vector3s representing the location at each vertex
     */
    constructor(
        public facets: [number, number, number][],
        public verts: THREE.Vector3[],
        public normals: THREE.Vector3[],
    ) {
        // To start with, each vertex has itself in its split set, since none of them have been split.
        this.splitMap = verts.map((_, i) => [i]);
        this.vertCount = verts.length;
    }

    /**
     * Emits a vertex and associated properties, reusing existing vertices if possible.
     * @param vIdx The index of the vertex to duplicate if necessary.
     * @param fIdx The index of the incident facet to emit a vertex for.
     * @param props The properties of the specified facet at the specified vertex.
     */
    emit(vIdx: number, fIdx: number, props: { uv?: THREE.Vec2; color?: THREE.Color }) {
        const group = this.splitMap[vIdx] as number[];
        // Attempt to find an existing vertex with the same properties as the one we're emitting.
        // We only need to check the vertices in the split group.
        const gIdx = group.find(
            v =>
                (!props.uv || !this.uvs[v] || vecEquals(props.uv, this.uvs[v])) &&
                (!props.color || !this.colors[v] || props.color.getHex() === this.colors[v]?.getHex()),
        );

        // If we couldn't find a matching vertex a new one must be created, the index of this new
        // vertex will be the current count.
        const idx = gIdx ?? this.vertCount;
        if (gIdx === undefined) {
            group.push(this.vertCount);
            this.vertCount += 1;
        }

        if (idx !== vIdx) {
            // Replace the old vertex index with the new index.
            replaceIn(this.facets[fIdx], vIdx, idx);

            this.verts[idx] = this.verts[vIdx] as THREE.Vector3;
            this.normals[idx] = this.normals[vIdx] as THREE.Vector3;
        }
        // The uvs and colors arrays start out empty, so we always need to set them even if a new
        // vertex wasn't created.
        if (props.uv) {
            this.uvs[idx] = props.uv;
        }
        if (props.color) {
            this.colors[idx] = props.color;
        }
    }
}

class MeshBuilder {
    private injector: DcmGeometryInjector;
    private rawFacets: [number, number, number][];
    private rawVerts: THREE.Vector3[];

    /**
     * Array with the same length as _rawVerts that records per-facet properties for each incident
     * facet. The uv and color arrays will have the same length as the number of incident facets in
     * order of ascending facet index. E.g. a vertex incident to facets f1 < f2 < ... < f6 would
     * store the uv and color for facet f1 in position 0 of both arrays, f2 in position 1, etc.
     */
    private vertFacetProps: { uv?: THREE.Vec2[]; color?: THREE.Color[] }[];

    /**
     * Array with the same length as _rawVerts that keeps track of the indices of incident facets
     * for that vertex in ascending order.
     */
    private vertToFacets: number[][];

    constructor(injector: DcmGeometryInjector) {
        this.injector = injector;
        this.rawFacets = injector.parseFacets();
        this.rawVerts = injector.parseVertices();

        // By default verts have no per-facet properties since they are optional
        this.vertFacetProps = this.rawVerts.map(_ => ({}));

        // Build the vert to facet map by starting with each vertex incident to no facets
        this.vertToFacets = this.rawVerts.map(_ => []);
        // And then populate the facet list by iterating over each facet and adding it to
        // each of its vertices facet list
        this.rawFacets.forEach((facet, i) => {
            facet.forEach(v => this.vertToFacets[v]?.push(i));
        });
    }

    /**
     * Try to parse texture coords and add them to the generated geometry.
     */
    addTexCoords() {
        const rawTexCoords = this.injector.parseTextureCoords();
        if (!rawTexCoords) {
            return;
        }

        if (rawTexCoords.length !== this.vertFacetProps.length) {
            throw new Error(
                `Error: expected ${this.vertFacetProps.length} texture coordinate entries, received ${rawTexCoords.length}.`,
            );
        }

        // Populate the per-facet properties for each vertex
        this.vertFacetProps.forEach((props, i) => {
            const uvs = rawTexCoords[i] as THREE.Vec2[];
            const incident = this.vertToFacets[i]?.length as number;
            if (uvs.length !== 1 && uvs.length !== incident) {
                throw new Error(
                    `Error: expected texture coordinate entry for vertex ${i} to have 1 or ${incident} elements, received ${uvs.length}`,
                );
            }
            // If only one texture coordinate exists, repeat it for every incident facet
            props.uv = isArrayMin1(uvs) ? Array.from<THREE.Vec2>({ length: incident }).fill(uvs[0]) : uvs;
        });
    }

    /**
     * Try to parse facet marks and add them as colors to the generated geometry.
     * @param palette A function mapping a facet mark value to a color.
     */
    addFacetMarks(palette: (mark: number) => THREE.Color) {
        const rawMarks = this.injector.parseFacetMarks();
        if (!rawMarks) {
            return;
        }

        if (rawMarks.length !== this.rawFacets.length) {
            throw new Error(`Error: expected ${this.rawFacets.length} facet marks, received ${rawMarks.length}`);
        }

        this.rawFacets.forEach((facet, i) => {
            for (const vIdx of facet) {
                const props = this.vertFacetProps[vIdx];
                if (props) {
                    const marks = props.color ?? [];
                    marks.push(palette(rawMarks[i] as number));
                    props.color = marks;
                }
            }
        });
    }

    /**
     * Generates the buffer geometry
     * @returns An indexed BufferGeometry
     */
    generate() {
        const hasUVs = this.vertFacetProps.some(props => props.uv);
        const hasColors = this.vertFacetProps.some(props => props.color);
        if (!hasUVs && !hasColors) {
            return buildGeometry({
                facets: this.rawFacets.flat(),
                verts: this.rawVerts.flatMap(({ x, y, z }) => [x, y, z]),
            });
        }

        // Need to make appropriate copies of raw facets and verts to avoid modifying them in-place.
        // This could probably be avoided and allow the change to be destructive since the intent
        // is to only call generate() once and then discard the builder instance.
        const emitter = new VertexEmitter(
            this.rawFacets.map(([i, j, k]) => [i, j, k] as [number, number, number]),
            this.rawVerts.slice(),
            this._computeNormals(),
        );

        this.vertFacetProps.forEach((props, vIdx) => {
            this.vertToFacets[vIdx]?.forEach((fIdx, i) => {
                const propVal: { uv?: THREE.Vec2; color?: THREE.Color } = {};
                if (props.uv) {
                    propVal.uv = props.uv[i];
                }
                if (props.color) {
                    propVal.color = props.color[i];
                }
                emitter.emit(vIdx, fIdx, propVal);
            });
        });

        return buildGeometry({
            facets: emitter.facets.flat(),
            verts: emitter.verts.flatMap(({ x, y, z }) => [x, y, z]),
            uvs: emitter.uvs.flatMap(({ x, y }) => [x, y]),
            colors: emitter.colors.flatMap(({ r, g, b }) => [r, g, b]),
            normals: emitter.normals.flatMap(({ x, y, z }) => [x, y, z]),
        });
    }

    private _computeNormals() {
        // Do not normalize these, we want to average them in an area-weighted way so that
        // thin incident triangles don't influence the average more than wider triangles.
        const facetNormals = this.rawFacets.map(([vi, vj, vk]) => {
            const ri = this.rawVerts[vi] as THREE.Vector3;
            const rj = this.rawVerts[vj] as THREE.Vector3;
            const rk = this.rawVerts[vk] as THREE.Vector3;
            // Save a few extra allocations and just compute the differences and cross product
            // directly so we don't need to create two temporaries.
            // n = (rj - ri) x (rk - ri)
            return new THREE.Vector3(
                (rj.y - ri.y) * (rk.z - ri.z) - (rj.z - ri.z) * (rk.y - ri.y),
                (rj.z - ri.z) * (rk.x - ri.x) - (rj.x - ri.x) * (rk.z - ri.z),
                (rj.x - ri.x) * (rk.y - ri.y) - (rj.y - ri.y) * (rk.x - ri.x),
            );
        });

        return this.vertToFacets.map(facets => {
            const n = facets.reduce((n, fi) => n.add(facetNormals[fi] as THREE.Vector3), new THREE.Vector3());
            return n.normalize();
        });
    }
}

function hueMap(p: number, q: number, t: number) {
    const tw = (t + 1) % 1;
    if (tw < 1 / 6) {
        return p + (q - p) * 6 * tw;
    }
    if (tw < 1 / 2) {
        return q;
    }
    if (tw < 2 / 3) {
        return p + (q - p) * (2 / 3 - tw) * 6;
    }
    return p;
}

function hslToRgb(h: number, s: number, l: number): [number, number, number] {
    if (s === 0) {
        return [l, l, l];
    }

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    return [hueMap(p, q, h + 1 / 3), hueMap(p, q, h), hueMap(p, q, h - 1 / 3)];
}

const INTERESTING_COLOR = new THREE.Color(0.5, 0.5, 1);
const UNINTERESTING_COLOR = new THREE.Color(0.2, 0.85, 0.25);

export enum FacetMarksPalette {
    DEFAULT = 'DEFAULT',
    TOOTH_UNN = 'TOOTH_UNN',
    TARGET_BIT = 'TARGET_BIT',
}
export type FacetPaletteType = 'TOOTH_UNN' | 'DEFAULT' | 'TARGET_BIT';

class DefaultPalette {
    private interestingMask: number;
    readonly colorMap: Map<number, THREE.Color> = new Map();

    constructor(
        private paletteType: FacetPaletteType = 'TOOTH_UNN',
        targetBit: number = 0,
    ) {
        this.interestingMask = 1 << targetBit;
    }

    private targetBitPalette() {
        return (x: number) => {
            if ((x & this.interestingMask) !== 0) {
                return INTERESTING_COLOR;
            }
            return UNINTERESTING_COLOR;
        };
    }

    private defaultPalette() {
        return (x: number) => {
            // otherwise show everything else best we can
            let color = this.colorMap.get(x);
            if (!color) {
                color = this._generateColor();
                this.colorMap.set(x, color);
            }
            return color;
        };
    }

    private toothPalette() {
        return (x: number) => {
            const maybeToothNumber = unnFromFacetMark(x);

            if (ToothUtils.isToothNumber(maybeToothNumber)) {
                let color = this.colorMap.get(maybeToothNumber);
                if (!color) {
                    color = this._generateColor();
                    this.colorMap.set(maybeToothNumber, color);
                }
                return color;
            }

            return UNINTERESTING_COLOR;
        };
    }

    get palette() {
        if (this.paletteType === 'DEFAULT') {
            return this.defaultPalette();
        } else if (this.paletteType === 'TOOTH_UNN') {
            return this.toothPalette();
        } else if (this.paletteType === 'TARGET_BIT') {
            return this.targetBitPalette();
        }
        assertNever(this.paletteType);
    }

    private _generateColor() {
        const t = this.colorMap.size / (2 * Math.PI - 4 / 5);
        const h = t % 1;
        const s = (Math.cos(Math.floor(t) * 1.516575) + 3) / 7;
        const l = (Math.sin(Math.floor(t) * 1.4) + 2) / 4;
        const [r, g, b] = hslToRgb(h, s, l);

        return new THREE.Color(r, g, b);
    }
}

export function createBufferGeometryForFacetMarks(
    injector: DcmGeometryInjector,
    markPalette: FacetPaletteType | undefined,
    targetMark: number | undefined,
    opts?: BufferGeometryOptions,
): THREE.BufferGeometry {
    const builder = new MeshBuilder(injector);

    if (opts?.applyTextureCoords ?? true) {
        builder.addTexCoords();
    }
    if (markPalette) {
        const paletteObj = new DefaultPalette(markPalette, targetMark ?? 0);
        builder.addFacetMarks(paletteObj.palette);
    }

    return builder.generate();
}
