import type { HTMLOverlayAnchorRef } from '../misc/HTMLOverlayAnchor';
import { createStyles, makeStyles } from '@orthly/ui-primitives';
import type { OrbitControlsProps } from '@react-three/drei';
import { useThree } from '@react-three/fiber';
import React from 'react';
import type { Vector3 } from 'three';
import { Vector2 } from 'three';

const useStyles = makeStyles(() =>
    createStyles({
        htmlOverlayTarget: {
            '&.offdisplay': {
                display: 'none !important',
            },
        },
    }),
);

const DISPLAY_MARGINS = {
    top: 25,
    right: 30,
    bottom: -10,
    left: 0,
};

const DISPLAY_OFFSET = [4, 8];

type HTMLOverlaySyncProps = {
    controlVisibility?: boolean;
    cameraControlsRef: React.MutableRefObject<OrbitControlsProps | null>;
    anchorRef: HTMLOverlayAnchorRef;
    targetElementRef: React.MutableRefObject<HTMLDivElement | undefined>;
};

type HTMLOverlaySyncType = React.FC<HTMLOverlaySyncProps>;

/**
 * Pseudo component to synchronize position of HTML absolutely positioned
 * element @param targetElementRef with @param anchorRef canvas position.
 *
 * @component
 */
export const HTMLOverlaySync: HTMLOverlaySyncType = ({
    controlVisibility,
    cameraControlsRef,
    anchorRef,
    targetElementRef,
}) => {
    const { gl, camera } = useThree();
    const cssClasses = useStyles();

    React.useEffect(() => {
        /**
         * Calculates canvas x,y coordinates in pixels for
         * given Vector3 in world space
         */
        function getCanvasCoordinates(vec3: Vector3): Vector2 {
            // camera's normalized device coordinate (NDC) space
            const ndc = vec3.project(camera);

            const rect = gl.domElement.getClientRects()[0];
            const halfWidth = (rect?.width ?? 1920) / 2;
            const halfHeight = (rect?.height ?? 1080) / 2;

            const x = Math.floor((ndc.x + 1) * halfWidth);
            const y = Math.floor((1 - ndc.y) * halfHeight);

            return new Vector2(x, y);
        }

        const update = () => {
            if (anchorRef.current && targetElementRef.current) {
                const canvasRect = gl.domElement.getClientRects()[0] || {
                    left: 0,
                    top: 0,
                    right: 1920,
                    bottom: 1080,
                };

                let { x, y } = getCanvasCoordinates(anchorRef.current.position.clone());

                x += DISPLAY_OFFSET[0] || 0.0;
                y += DISPLAY_OFFSET[1] || 0.0;

                targetElementRef.current.style.left = `${x}px`;
                targetElementRef.current.style.top = `${y}px`;

                if (controlVisibility) {
                    x += canvasRect?.left || 0.0;
                    y += canvasRect?.top || 0.0;

                    const outsideDisplay =
                        x - DISPLAY_MARGINS.left < canvasRect.left ||
                        x + DISPLAY_MARGINS.right > canvasRect.right ||
                        y - DISPLAY_MARGINS.top < canvasRect.top ||
                        y + DISPLAY_MARGINS.bottom > canvasRect.bottom;

                    targetElementRef.current.classList.add(cssClasses.htmlOverlayTarget);

                    if (outsideDisplay) {
                        targetElementRef.current.classList.add('offdisplay', 'hidden');
                    } else {
                        targetElementRef.current.classList.remove('offdisplay', 'hidden');
                    }
                }
            }
        };

        cameraControlsRef?.current?.addEventListener?.('change', update);
        anchorRef?.current?.addEventListener('update', update);
    }, [cameraControlsRef, anchorRef, camera, controlVisibility, cssClasses, gl.domElement, targetElementRef]);

    return <></>;
};
