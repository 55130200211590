import type { ToothNumber } from '@orthly/items';

export const FACET_TYPES = ['margin', 'near-margin', 'cameo', 'seal-zone', 'intaglio'] as const;
export type FacetType = (typeof FACET_TYPES)[number];

export interface RestorativeFacetProperties {
    toothNumber: ToothNumber;
    facetType: FacetType | undefined;
}

const MARGIN_MASK = 1 << 19;
const NEAR_MARGIN_MASK = 1 << 24;
const CAMEO_MASK = 1 << 20;
const SEAL_ZONE_MASK = 1 << 17;
const INTAGLIO_MASK = 1 << 18;

function parseFacetType(mark: number): FacetType | undefined {
    if ((mark & NEAR_MARGIN_MASK) !== 0) {
        return 'near-margin';
    }
    if ((mark & MARGIN_MASK) !== 0) {
        return 'margin';
    }
    if ((mark & CAMEO_MASK) !== 0) {
        return 'cameo';
    }
    if ((mark & SEAL_ZONE_MASK) !== 0) {
        return 'seal-zone';
    }
    if ((mark & INTAGLIO_MASK) !== 0) {
        return 'intaglio';
    }
    return undefined;
}

export function isFacetType(val: string): val is FacetType {
    return (FACET_TYPES as readonly string[]).includes(val);
}

export function parseRestorativeFacetMark(mark: number): RestorativeFacetProperties {
    const toothNumber = ((mark >> 27) + 1) as ToothNumber;
    const facetType = parseFacetType(mark);
    return { toothNumber, facetType };
}
