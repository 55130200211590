/* eslint-disable max-lines, max-lines-per-function */
import { useCommonStyles } from '../../hooks';
import { CrossSectionPlaneTool, useCrossSectionApp } from '../CrossSection';
import { CrossSectionViewPanel } from '../CrossSection/CrossSectionPanel';
import { HotKeyCheatSheetButton } from '../HotKeyCheatSheet';
import { createDefaultAppearanceSettings } from '../ModelAppearance';
import { HeatmapHighlight } from './HeatmapHighlight';
import { useHeatmapHighlight, useModelsForHeatmapHighlight } from './HeatmapHighlight.hooks';
import { InsertionAxisMesh } from './InsertionAxisMesh';
import { IDENTITY_16ARRAY } from './MarginMesh';
import { MESH_DEFAULT_POSITION } from './ModelMeshes';
import {
    DEFAULT_CAMERA_POSITION,
    ModelViewerControls,
    ModelViewerLayoutContainer,
    addKeyListener,
    getMarginLines,
    useDefaultModelRotation,
} from './ModelViewer';
import { isDynamicHeatmapsDataAvailable } from './ModelViewer.util';
import { ModelViewerCanvas } from './ModelViewerCanvas';
import { ModelViewerFallbackComponent } from './ModelViewerFallbackComponent';
import { isPlyOrCtmModel } from './ModelViewerPayloads';
import type { ModelPayloadItem } from './ModelViewerTypes';
import { MouseLabel } from './MouseLabel';
import {
    useInitializeOcclusalDistance,
    useInitializeSurfaceDisplacement,
    useNewModelViewerStyles,
    useResetUninitializedDistanceField,
} from './NewModelViewer.hooks';
import type { NewModelViewerProps } from './NewModelViewer.types';
import { isLowerMBItem, isPastDesignItem, isUpperMBItem } from './NewModelViewer.utils';
import { useNewModelViewerToolsOverlay } from './NewModelViewerOverlay';
import { AppearancePayloadMesh } from './NewModelViewerPayloads';
import { usePrepDesignData } from './PrepDesign.hooks';
import { Snapshotter } from './ScreenShotCore';
import { useGetToothMarkings } from './ToothMarkings.hooks';
import { BadGPUPerformance, useOrthlyDetectGPU } from './utils3d/useOrthlyDetectGPU';
import { AttributeName, ensureDisplacementAttributesInitialized, getMaxMarginDistance } from '@orthly/forceps';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import type { MarginLine } from '@orthly/shared-types';
import { withErrorBoundary } from '@orthly/ui';
import { Grid } from '@orthly/ui-primitives';
import { OrthographicCamera } from '@react-three/drei';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';

/**
 * This is the new Model Viewer, capable of rendering an entire scene.
 * It sports a new UI and features internal appearance controls and QC tools.
 *
 * @component
 */
const NewModelViewerInner: React.FC<NewModelViewerProps> = props => {
    const {
        style,
        variant,
        modelling_tree_buffer,
        design_metadata,
        enableIdenticalPanningBehaviour,
        full_screen,
        aligner_plan_type,
        model_payload_items,
        enable_qc_tools,
        hide_toolbar,
        designQcConfig: { appearance, setAppearance, modelRef, controlRef, takeSnapshotRef },
        min_zoom_distance,
        max_zoom_distance,
        showOrderScans,
        showPreExtractionScans,
        enableNewScanMeshMaterial,
        marginLineEditing,
        useShaderHeatmaps,
        enableHeatmapHighlight,
        undercutApp,
        disableHotKeys,
        children,
        portalMargins,
        doctorMarginLines,
        oxzMarginInfo,
        updatedMarginLine,
        showUpdatedMarginLines,
        use3ShapeViewerControls,
        previousDesignMetadata,
        previousModellingTreeBuffer,
        setMaxMarginDistance,
        displayLocation,
        allowPortalEdits,
        setAllowPortalEdits,
        checkMinimumSystemRequirements,
        hideOverlayTools,
        camConfig,
        postEditCheckResult,
        scanTrimmedSections,
        showScansHeatmap,
        precomputedToothMarkings,
        insertionAxes,
        checkCanvasSize,
        enableTubeMarginLine,
        enablePreferredScansIfNoDesigns,
        enableWireframes,
        showWireframes,
    } = props;

    const commonStyles = useCommonStyles();
    const styles = useNewModelViewerStyles();

    const { enableCrossSections, enableDynamicHeatmaps } = enable_qc_tools;

    React.useEffect(() => {
        setAppearance(
            createDefaultAppearanceSettings(model_payload_items, {
                showOrderScans,
                showPreExtractionScans,
                enablePreferredScansIfNoDesigns,
            }),
        );
    }, [setAppearance, model_payload_items, showOrderScans, showPreExtractionScans, enablePreferredScansIfNoDesigns]);

    useInitializeSurfaceDisplacement(appearance.restoratives.HeatMap, appearance.pastRestoratives.CAD);
    useInitializeSurfaceDisplacement(appearance.pastRestoratives.HeatMap, appearance.restoratives.CAD);

    // Initialize occlusal distances if not already for default upper and lower arches
    const lowerRefs = showScansHeatmap ? appearance.lowerJaw.slice(0, 1) : [];
    const upperRefs = showScansHeatmap ? appearance.upperJaw.slice(0, 1) : [];
    useInitializeOcclusalDistance(appearance.upperJaw[0], lowerRefs);
    useInitializeOcclusalDistance(appearance.lowerJaw[0], upperRefs);

    // Make sure other scans have invalid occlusal distances if the attribute doesn't exist
    useResetUninitializedDistanceField(showScansHeatmap ? appearance.scans : [], AttributeName.OcclusalDistance);

    const dynamicHeatmaps = enableDynamicHeatmaps && isDynamicHeatmapsDataAvailable(model_payload_items);

    React.useEffect(() => {
        if (dynamicHeatmaps) {
            model_payload_items
                .filter(
                    pi => pi.type === LabsGqlOrderDesignScanType.QcExtras || pi.type === LabsGqlOrderDesignScanType.Cad,
                )
                .forEach(pi => {
                    ensureDisplacementAttributesInitialized(pi.model.geometry);
                });
        }
    }, [dynamicHeatmaps, model_payload_items]);

    const toothMarkings = useGetToothMarkings(precomputedToothMarkings, design_metadata, oxzMarginInfo);

    // margin lines
    const marginLines = React.useMemo(() => {
        // If we edited in the portal, use these margins
        if (portalMargins && portalMargins.length) {
            return portalMargins.map<MarginLine>(datum => ({
                tooth: datum.unn,
                transformationMatrix: IDENTITY_16ARRAY,
                coords: _.chunk(datum.coords, 3).filter(chunk => chunk.length === 3) as [number, number, number][],
                isDoctorMargin: false,
            }));
        }

        const prepItems: ModelPayloadItem[] = model_payload_items.filter(
            pl => !isPastDesignItem(pl) && (isUpperMBItem(pl) || isLowerMBItem(pl)),
        );

        const prepModels = _.compact(prepItems.map(i => isPlyOrCtmModel(i.model)));

        return getMarginLines(prepModels, design_metadata, modelling_tree_buffer);
    }, [portalMargins, modelling_tree_buffer, model_payload_items, design_metadata]);

    // Margin lines from the previous design
    const previousMarginLines = React.useMemo(() => {
        const prepItems: ModelPayloadItem[] = model_payload_items.filter(
            pl => isPastDesignItem(pl) && (isUpperMBItem(pl) || isLowerMBItem(pl)),
        );

        const prepModels = _.compact(prepItems.map(i => isPlyOrCtmModel(i.model)));

        return getMarginLines(prepModels, previousDesignMetadata, previousModellingTreeBuffer);
    }, [model_payload_items, previousDesignMetadata, previousModellingTreeBuffer]);

    const hasRestoratives = model_payload_items.some(item => item.isRestorative);
    const prepData = usePrepDesignData(hasRestoratives, insertionAxes, marginLines);

    React.useEffect(() => {
        setMaxMarginDistance?.(getMaxMarginDistance(marginLines, previousMarginLines));
    }, [marginLines, previousMarginLines, setMaxMarginDistance]);

    // controls hot-keys
    React.useEffect(() => {
        return addKeyListener(controlRef.current);
    }, [controlRef]);

    const {
        state: { clippingPlane, crossSection, scanAppearance, reversePlane },
        OverlayTools,
        selectedInsertionAxis,
    } = useNewModelViewerToolsOverlay(props, dynamicHeatmaps, prepData);

    const crossSectionApp = useCrossSectionApp(controlRef, { clippingPlane, reversePlane, appActive: crossSection });

    // TODO, EPDCAD-20 we should not need this
    const defaultRotation = useDefaultModelRotation(aligner_plan_type);

    const cameraProps = {
        zoom: 4,
        position: DEFAULT_CAMERA_POSITION,
        ...(camConfig ?? {}),
    };

    const hasPastRestoratives = model_payload_items.some(item => item.isPastDesign);

    const { isBadGpu, loading, isMobile } = useOrthlyDetectGPU(checkMinimumSystemRequirements);

    React.useEffect(() => {
        if ((isMobile || isBadGpu) && allowPortalEdits && setAllowPortalEdits) {
            setAllowPortalEdits(false);
        }
    }, [isMobile, isBadGpu, allowPortalEdits, setAllowPortalEdits]);

    const { updateTextRef, setHeatmapHighlight } = useHeatmapHighlight();
    const heatmapHighlightModels = useModelsForHeatmapHighlight(appearance, enableHeatmapHighlight, showScansHeatmap);

    if (loading) {
        return null;
    }
    if (isBadGpu) {
        return <BadGPUPerformance />;
    }

    return (
        <ModelViewerLayoutContainer
            style={style}
            variant={variant}
            full_screen={full_screen}
            hide_toolbar={hide_toolbar}
            hasPastRestoratives={hasPastRestoratives}
            displayLocation={displayLocation}
        >
            {enableHeatmapHighlight && appearance.restorativeView !== 'CAD' && (
                <MouseLabel forceUpdateRef={updateTextRef} />
            )}
            {!disableHotKeys && <HotKeyCheatSheetButton sheetAnchor={'bottom-right'} />}
            {!hideOverlayTools && (
                <div
                    className={clsx(styles.uiPanelsContainer, commonStyles.noPointerEvents)}
                    style={{ display: scanAppearance.open ? 'flex' : 'block' }}
                >
                    {OverlayTools}
                </div>
            )}
            {/*
             * Some notes: colorManagement is set to true by default to fix threejs' colors being linear and wrong
             * This, however, causes models that look very off with the textures we have (e.g. not close to 3shape)
             * Concurrent enables support for React's concurrent mode
             */}
            <Grid style={{ height: '100%', width: '100%', overflow: 'hidden' }}>
                {enableCrossSections && (
                    <CrossSectionViewPanel
                        enableIdenticalPanningBehaviour={enableIdenticalPanningBehaviour}
                        crossSectionApp={crossSectionApp}
                        crossSectionState={{ appActive: crossSection, clippingPlane, reversePlane }}
                    />
                )}
                <ModelViewerCanvas useSRGB={false} checkCanvasSize={checkCanvasSize}>
                    <OrthographicCamera makeDefault {...cameraProps}>
                        <pointLight intensity={0.8} color={0xffffff} />
                    </OrthographicCamera>
                    <ambientLight intensity={0.05} color={0xffffff} />
                    <group ref={modelRef} rotation={defaultRotation}>
                        <AppearancePayloadMesh
                            marginLines={marginLines}
                            doctorMarginLines={doctorMarginLines}
                            previousMarginLines={previousMarginLines}
                            toothMarkings={toothMarkings}
                            appearanceSettings={appearance}
                            cameraControlsRef={controlRef}
                            enableNewScanMeshMaterial={enableNewScanMeshMaterial}
                            marginLineEditing={marginLineEditing}
                            useShaderHeatmaps={useShaderHeatmaps}
                            postEditCheckResult={postEditCheckResult}
                            scanTrimmedSections={scanTrimmedSections}
                            showScansHeatmap={showScansHeatmap}
                            prepData={prepData}
                            selectedInsertionAxis={selectedInsertionAxis}
                            enableTubeMarginLine={enableTubeMarginLine}
                            showWireframe={enableWireframes && showWireframes}
                        />
                        {enableCrossSections && (
                            <CrossSectionPlaneTool
                                payload={appearance}
                                marginLines={marginLines}
                                updatedMarginLine={updatedMarginLine}
                                previousMarginLines={previousMarginLines}
                                showMarginLines={appearance.showMarginLines}
                                showUpdatedMarginLines={showUpdatedMarginLines}
                                showPreviousMarginLines={appearance.showPreviousMarginLines}
                                csPlane={crossSectionApp.csPlane}
                                setCSPlane={crossSectionApp.setCSPlane}
                                minorAxis={crossSectionApp.crossSectionMinorAxis}
                                cameraControlsRef={controlRef}
                                newCrossSectionPlaneActive={
                                    crossSection.open && !crossSectionApp.crossSectionPlaneActive
                                }
                                onUpdateCrossSection={crossSectionApp.updateCrossSectionData}
                                onNewCrossSectionPlane={crossSectionApp.handleNewCrossSectionPlane}
                                clippingPlane={clippingPlane.open}
                                reversePlane={reversePlane.open}
                            />
                        )}
                        {undercutApp?.undercutActive && (
                            <InsertionAxisMesh
                                visible={true}
                                highlighted={true}
                                insertionAxis={undercutApp.insertionAxis}
                                position={MESH_DEFAULT_POSITION}
                            />
                        )}
                        {
                            // If heatmaps are active, render the heatmap value under the mouse
                            <HeatmapHighlight
                                key={`heatmap_highlight`}
                                geometries={heatmapHighlightModels.map(m => m.payloadModel.model.geometry)}
                                cameraControlsRef={controlRef}
                                activeHeatMap={appearance.activeHeatMap}
                                showCurtainsHeatmap={appearance.showCurtainsHeatmap}
                                maxRadiusMm={0.1}
                                onHeatmapHighlighted={setHeatmapHighlight}
                            />
                        }
                    </group>
                    <ModelViewerControls
                        enableIdenticalPanningBehaviour={enableIdenticalPanningBehaviour}
                        ref={controlRef}
                        min_zoom_distance={min_zoom_distance}
                        max_zoom_distance={max_zoom_distance}
                        use3ShapeViewerControls={use3ShapeViewerControls}
                    />
                    {takeSnapshotRef && <Snapshotter takeSnapshotRef={takeSnapshotRef} />}
                    {children}
                </ModelViewerCanvas>
            </Grid>
        </ModelViewerLayoutContainer>
    );
};

export const NewModelViewer = withErrorBoundary(NewModelViewerInner, {
    FallbackComponent: ModelViewerFallbackComponent,
});
