import type { ModelAppearance } from './ModelAppearanceTypes';
import { ModelVisibilityToggler, useAggregateToggles } from './ModelVisibilityToggler';
import MUIButton from '@mui/material/Button';
import { FlossPalette, Popover, ButtonGroup, Icon, styled } from '@orthly/ui-primitives';
import React from 'react';

// Use a completely custom button, since we need both a green-on-white inactive and
// white-on-green active appearance.
const GroupButton = styled(MUIButton)(({ active }: { active: boolean }) => ({
    backgroundColor: active ? FlossPalette.PRIMARY_FOREGROUND : FlossPalette.WHITE,
    color: active ? FlossPalette.WHITE : FlossPalette.PRIMARY_FOREGROUND,
    borderColor: `${FlossPalette.STROKE_LIGHT} !important`,
    '&:hover': {
        zIndex: 1,
        backgroundColor: active ? FlossPalette.STAR_GRASS_HOVER : FlossPalette.WHITE,
        borderColor: `${FlossPalette.STROKE_DARK} !important`,
    },
}));

export const ModelAppearanceFilters: React.FC<{
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
}> = props => {
    const { appearance, setAppearance } = props;
    const toggles = useAggregateToggles(appearance, setAppearance);

    const [restoPopoverAnchor, setRestoPopoverAnchor] = React.useState<HTMLElement | null>(null);

    return (
        <>
            <ButtonGroup variant={'outlined'} style={{ position: 'absolute', bottom: 20, left: 20 }}>
                {toggles.map(toggle => (
                    <GroupButton
                        active={toggle.state.checked}
                        key={toggle.label}
                        variant={'outlined'}
                        onClick={() => toggle.setChecked(!toggle.state.checked)}
                    >
                        {toggle.label}
                    </GroupButton>
                ))}
                <GroupButton active={false} variant={'outlined'} onClick={e => setRestoPopoverAnchor(e.currentTarget)}>
                    <Icon icon={'MenuRounded'} />
                </GroupButton>
            </ButtonGroup>

            <Popover
                open={!!restoPopoverAnchor}
                anchorEl={restoPopoverAnchor}
                onClose={() => {
                    setRestoPopoverAnchor(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <ModelVisibilityToggler {...props} hideAggregateControls />
            </Popover>
        </>
    );
};
