import { toggleAction } from '../GuidedWaxup.util';
import { VisibilityToggleMobile } from './VisibilityToggleMobile';
import type { AggregateToggle, ModelAppearance } from '@orthly/dentin';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

const useStyles = stylesFactory(() => ({
    toggleContainer: {
        padding: '5px 10px 10px 10px',
        overflow: 'auto',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
    },
}));

interface GeneralReviewVisibilityControlsMobileProps {
    toggles: AggregateToggle[];
    appearance: ModelAppearance;
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
}

export const GeneralReviewVisibilityControlsMobile: React.VFC<GeneralReviewVisibilityControlsMobileProps> = ({
    toggles,
    appearance,
    setAppearance,
}) => {
    const classes = useStyles();
    return (
        <>
            <Grid item container className={classes.toggleContainer}>
                {toggles.map(toggle => (
                    <VisibilityToggleMobile
                        key={toggle.label}
                        toggleLabel={toggle.label}
                        toggleAction={toggle.setChecked}
                    />
                ))}
                {_.sortBy(appearance.restoratives.CAD, pma => pma.payloadModel.name).map(pma => (
                    <VisibilityToggleMobile
                        key={pma.payloadModel.name}
                        toggleLabel={pma.payloadModel.name}
                        toggleAction={toggleAction(setAppearance, pma)}
                    />
                ))}
            </Grid>
        </>
    );
};
