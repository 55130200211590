export interface ListNode<T> {
    list: CircularLinkedList<T>;
    value: T;
    next: ListNode<T>;
    prev: ListNode<T>;
}

export class CircularLinkedList<T> {
    private _first?: ListNode<T>;

    private _length: number = 0;

    get first() {
        return this._first;
    }

    get length() {
        return this._length;
    }

    clear() {
        this._first = undefined;
        this._length = 0;
    }

    insertFirst(value: T) {
        if (this._first) {
            return this.insertAfter(this._first.prev, value);
        } else {
            const node = { list: this, value } as unknown as ListNode<T>;
            node.prev = node;
            node.next = node;
            this._first = node;
            this._length = 1;
            return node;
        }
    }

    insertLast(value: T) {
        if (this._first) {
            return this.insertAfter(this._first.prev, value);
        } else {
            return this.insertFirst(value);
        }
    }

    insertAfter(node: ListNode<T>, value: T) {
        if (node.list !== this) {
            throw new Error('Cannot insert after node from different list.');
        }
        const newNode: ListNode<T> = { list: this, value, prev: node, next: node.next };

        if (node.next) {
            node.next.prev = newNode;
        }
        node.next = newNode;
        this._length += 1;
        return newNode;
    }

    remove(node: ListNode<T>) {
        if (node.list !== this) {
            throw new Error('Cannot remove node from different list.');
        }
        const { prev, next } = node;
        if (prev !== next) {
            if (node === this._first) {
                this._first = next;
            }
            prev.next = next;
            next.prev = prev;
            this._length -= 1;
            return next;
        } else if (prev !== node) {
            if (node === this._first) {
                this._first = next;
            }
            next.prev = next;
            next.next = next;
            this._length -= 1;
            return next;
        } else {
            this._first = undefined;
            this._length = 0;
            return undefined;
        }
    }
}
