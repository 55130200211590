import type { StaffMemberSession } from './StaffMemberSession.types';
import { RetainerTokenStorage, RetainerDTOValidation } from '@orthly/retainer-common';

export class RetainerSessionManager {
    static tokenFromLocalStorage() {
        return RetainerTokenStorage.getActiveToken();
    }

    static sessionFromToken(retainerToken: string | null): StaffMemberSession | null {
        try {
            if (!retainerToken) {
                return null;
            }
            const { user, member, isImpersonation = false } = RetainerDTOValidation.tokenStringToBody(retainerToken);
            return { ...member, user, isImpersonation };
        } catch (e: any) {
            console.error(e);
            return null;
        }
    }
}
