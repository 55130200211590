import type { LabsGqlLabOrderFragment, LabsGqlScanExportItemForOrderFragment } from '@orthly/graphql-operations';
import { DateUtils } from '@orthly/shared-types';
import type { SimpleAutocompleteOption } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';

// This minimal version of the LabsGqlLabOrderFragment is needed to maintain compatibility with Chairside.
export interface SearchAutocompleteOrder
    extends Pick<LabsGqlLabOrderFragment, 'id' | 'created_at' | 'patient' | 'status' | 'doctor_name'> {
    scan_export: { elements: Pick<LabsGqlScanExportItemForOrderFragment, 'LinkTypeClass' | 'TypeIDs'>[] };
}

export interface ItemSearchControls {
    search: string | undefined;
    setSearch: (search?: string) => any;
}

export interface ItemSearchResults {
    search: string | undefined;
    setSearch: (search?: string) => any;
    items: SearchAutocompleteOrder[];
    skipQuery: boolean;
    loading: boolean;
}

function useAutocompleteOptionsForOrders(orders: SearchAutocompleteOrder[]): SimpleAutocompleteOption[] {
    return React.useMemo(() => {
        return _.sortBy(orders, o => -new Date(o.created_at).valueOf()).map<SimpleAutocompleteOption>(o => {
            const patientName = `${o.patient.first_name} ${o.patient.last_name}`;
            const dateLabel = `Created ${DateUtils.format(o.created_at, 'MM/DD/YY')}`;
            return { value: o.id, label: `${patientName} (${_.startCase(o.status)}. ${dateLabel})` };
        });
    }, [orders]);
}

export function useSearchAutocomplete(
    onSelectItemId: (itemId: string) => void,
    useItemSearchResults: (controls?: ItemSearchControls) => ItemSearchResults,
    controls?: ItemSearchControls,
) {
    const { items, search, loading, setSearch, skipQuery: searchDisabled } = useItemSearchResults(controls);
    const options = useAutocompleteOptionsForOrders(items);
    const onInputChange = React.useCallback(
        (value: string | null) => {
            setSearch(value ?? undefined);
        },
        [setSearch],
    );
    const onSelectOption = React.useCallback(
        (value: string | null) => {
            if (value) {
                setSearch(undefined);
                onSelectItemId(value);
            }
        },
        [onSelectItemId, setSearch],
    );
    const onBlurOrFocus = React.useCallback(
        (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const newValue = e.target?.value ? e.target?.value.trim() : undefined;
            if (newValue !== search) {
                setSearch(newValue);
            }
        },
        [search, setSearch],
    );
    const noOptionsText = searchDisabled ? 'Search for patients' : 'No options found';
    return {
        search,
        options,
        onInputChange,
        onSelectOption,
        onBlurOrFocus,
        noOptionsText,
        items,
        loading: loading && !searchDisabled,
    };
}
