/* eslint-disable @orthly/no-console-log */
import type { ILogger, LogLevel } from '@orthly/shared-types';
import { compact } from 'lodash';

/**
 * A simple implementation of ILogger that just logs to `console`.
 *
 * This class should never be used in production - use OrthlyLogger instead. This class is useful for debugging
 * forceps functions that accept an ILogger parameter, when calling those functions from our test scripts.
 */
export class ConsoleLogger implements ILogger {
    private static instance: ConsoleLogger | null = null;

    private constructor() {}

    static getInstance(): ConsoleLogger {
        if (!ConsoleLogger.instance) {
            ConsoleLogger.instance = new ConsoleLogger();
        }

        return ConsoleLogger.instance;
    }

    createChild(_namespaceSuffix: string, _context: Record<string, any>): ILogger {
        throw new Error('ConsoleLogger.createChild is not implemented');
    }

    audit(): ILogger {
        throw new Error('ConsoleLogger.audit is not implemented');
    }

    fatal(message: string, data?: Record<string, any>): void {
        console.error(...compact([message, data]));
    }

    error(obj: string | Error, data?: Record<string, any>): void {
        console.error(...compact([obj, data]));
    }

    warn(message: string, data?: Record<string, any>): void {
        console.warn(...compact([message, data]));
    }

    info(message: string, data?: Record<string, any>): void {
        console.info(...compact([message, data]));
    }

    debug(message: string, data?: Record<string, any>): void {
        console.debug(...compact([message, data]));
    }

    trace(message: string, data?: Record<string, any>): void {
        console.trace(...compact([message, data]));
    }

    time(_level: LogLevel, _message: string, _startData?: Record<string, any>): () => void {
        throw new Error('ConsoleLogger.time is not implemented');
    }
}
