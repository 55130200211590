import { ProductUnitTypeImages } from '../utils/ProductUnitTypeImages';
import type { ProductUnitType } from '@orthly/items';
import { FlossPalette, stylesFactory, Text, Box } from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';

type ProductImageWrapperSize = 48 | 32 | 24;

interface ProductImageWrapperStyleProps {
    backgroundColor?: string;
    size: ProductImageWrapperSize;
}

const DEFAULT_SIZE = 32;

const useStyles = stylesFactory(() => ({
    iconFrame: {
        backgroundColor: FlossPalette.TAN,
        borderRadius: 40,
        position: 'relative' as const,
    },
    icon: {
        position: 'absolute' as const,
        top: 0,
        left: 0,
    },
}));

interface ProductImageWrapperProps extends Pick<ProductImageWrapperStyleProps, 'backgroundColor'> {
    product: ProductUnitType;
    wrapperStyle?: React.CSSProperties;
    imgStyle?: React.CSSProperties;
    size?: ProductImageWrapperSize;
}

// Figma link: See "Product image wrapper" in https://www.figma.com/file/HuCVKGaat3yxkP33weyVnz/Design-System?node-id=9062%3A30392
/**
 * The original ProductImageWrapper. The extra nesting 'div' should be unnecessary if the consumer is properly styled.
 * @deprecated - use UnwrappedProductImageWrapper instead
 * @param props
 * @constructor
 */
export const ProductImageWrapper: React.VFC<ProductImageWrapperProps> = props => {
    const { product, wrapperStyle, imgStyle, backgroundColor, size = DEFAULT_SIZE } = props;
    const classes = useStyles();

    const src = React.useMemo(() => {
        return size > 32 ? ProductUnitTypeImages.Large[product] : ProductUnitTypeImages.Small[product];
    }, [product, size]);

    return (
        <div className={classes.iconFrame} style={{ ...wrapperStyle, backgroundColor, width: size, height: size }}>
            <img src={src} alt={product} className={classes.icon} style={{ ...imgStyle, width: size, height: size }} />
        </div>
    );
};

// Figma link: See "Product image wrapper" in https://www.figma.com/file/HuCVKGaat3yxkP33weyVnz/Design-System?node-id=9062%3A30392
export const UnwrappedProductImageWrapper: React.VFC<ProductImageWrapperProps> = props => {
    const { product, wrapperStyle, imgStyle, backgroundColor, size = DEFAULT_SIZE } = props;
    const classes = useStyles();

    const src = React.useMemo(() => {
        return size > 32 ? ProductUnitTypeImages.Large[product] : ProductUnitTypeImages.Small[product];
    }, [product, size]);

    return (
        <img
            src={src}
            alt={product}
            className={classes.iconFrame}
            style={{ ...wrapperStyle, backgroundColor, ...imgStyle, width: size, height: size }}
        />
    );
};

interface ProductCountWrapperProps extends Pick<ProductImageWrapperStyleProps, 'backgroundColor'> {
    size?: ProductImageWrapperSize;
    count: number;
}

export const ProductCountWrapper: React.VFC<ProductCountWrapperProps> = props => {
    const { size = DEFAULT_SIZE } = props;
    const classes = useStyles();

    return (
        <div
            className={classes.iconFrame}
            style={{
                // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                // eslint-disable-next-line no-nested-ternary
                padding: size === 48 ? 10 : size === 32 ? 5 : 0,
                textAlign: 'center',
                backgroundColor: props.backgroundColor,
                width: size,
                height: size,
            }}
        >
            <Text variant={'caption'} medium color={'DARK_GRAY'}>
                +{props.count}
            </Text>
        </div>
    );
};

export interface MultiProductImageWrapperProps extends Pick<ProductImageWrapperStyleProps, 'backgroundColor'> {
    products: ProductUnitType[];
    maxNumOrbs?: number;
    wrapperStyle?: React.CSSProperties;
    size?: ProductImageWrapperSize;
    direction?: 'row' | 'column';
}

export const MultiProductImageWrapper: React.VFC<MultiProductImageWrapperProps> = ({
    backgroundColor,
    products,
    maxNumOrbs,
    size,
    wrapperStyle,
    direction = 'row',
}) => {
    const data = React.useMemo<(number | ProductUnitType)[]>(() => {
        // If no max is provided, we will use all of the provided products
        const uniqProducts = _.uniq(products);
        if (!maxNumOrbs) {
            return uniqProducts;
        }

        // if we have the same or a lower number of products as our max, then we show them all
        if (uniqProducts.length <= maxNumOrbs) {
            return uniqProducts;
        }

        // otherwise, we slice down and append the number of elements that weren't included
        const firstNProducts = _.slice(uniqProducts, 0, maxNumOrbs - 1);
        const numberOfRemainingSkus = uniqProducts.length - maxNumOrbs + 1;
        return [...firstNProducts, numberOfRemainingSkus];
    }, [products, maxNumOrbs]);

    const isVertical = direction !== 'row';

    return (
        <Box
            sx={{
                display: 'grid',
                gridAutoFlow: isVertical ? 'row' : 'column',
                '& > * ': {
                    marginLeft: isVertical ? 0 : '-8px',
                    marginTop: isVertical ? '-8px' : 0,
                },
                '& > *:first-child': {
                    marginTop: 0,
                },
            }}
        >
            {data.map((item, idx) => {
                if (typeof item === 'number') {
                    return <ProductCountWrapper key={idx} backgroundColor={backgroundColor} count={item} size={size} />;
                } else {
                    return (
                        <UnwrappedProductImageWrapper
                            key={idx}
                            product={item}
                            backgroundColor={backgroundColor}
                            size={size}
                            wrapperStyle={wrapperStyle}
                        />
                    );
                }
            })}
        </Box>
    );
};
