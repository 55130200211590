import { PATIENT_PORTAL_ROUTE } from './routes';
import { reduxHistory, store } from './store';
import { ConfigService } from './utils';
import { PracticeGqlProvider } from './utils/PracticeGqlProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PracticeScreen } from '@orthly/dentin';
import { RetainerSessionProvider } from '@orthly/session-client';
import { OrthlyEnvironment } from '@orthly/shared-types';
import type { OrthlyBrowserConfig } from '@orthly/ui';
import {
    OrthlyFrontendApps,
    OrthlyErrorBoundary,
    LoadBlocker,
    StyledSnackbarProvider,
    OrthlyConfigProvider,
    ReloadStalePages,
} from '@orthly/ui';
import { DandyThemeProvider, Grid } from '@orthly/ui-primitives';
import { ConnectedFirebaseProvider, LaunchDarklyProvider, useLaunchDarklyUserContext } from '@orthly/veneer';
import { ConnectedRouter } from 'connected-react-router';
import { useSnackbar } from 'notistack';
import React from 'react';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';

const ImpersonateLoadingFallback: React.FC = () => {
    return (
        <LoadBlocker blocking={true} loader={'dandy'}>
            <Grid container style={{ height: '100vh', width: '100vw' }} />
        </LoadBlocker>
    );
};

const PracticeSession: React.FC = props => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <RetainerSessionProvider
            allowedOrgTypes={['practice', 'lab']}
            allowImpersonate
            showNotification={(msg, isError) => {
                enqueueSnackbar(msg, { variant: isError ? 'error' : undefined });
            }}
            TokenExchangeLoadingFallback={ImpersonateLoadingFallback}
        >
            <ConnectedFirebaseProvider
                appName={OrthlyFrontendApps.practice}
                envOverride={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : undefined}
            >
                <QueryParamProvider ReactRouterRoute={Route}>{props.children}</QueryParamProvider>
            </ConnectedFirebaseProvider>
        </RetainerSessionProvider>
    );
};

export const PracticeRootProvider: React.FC<{ config: OrthlyBrowserConfig }> = props => {
    const ldContext = useLaunchDarklyUserContext();

    return (
        <DandyThemeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <OrthlyErrorBoundary componentName={'PracticeAppRoot'}>
                    <OrthlyConfigProvider config={props.config}>
                        <StyledSnackbarProvider
                            maxSnack={1}
                            autoHideDuration={3500}
                            // Parker: this is lazy but i cant figure out what has an obscene z-index so gotta beat it
                            style={{ zIndex: 100000 }}
                        >
                            <Provider store={store as any}>
                                <ConnectedRouter history={reduxHistory}>
                                    <ReloadStalePages
                                        safeToReloadRoutes={[
                                            PracticeScreen.login,
                                            PATIENT_PORTAL_ROUTE,
                                            PracticeScreen.inbox,
                                            PracticeScreen.my_practice,
                                            PracticeScreen.lab,
                                            PracticeScreen.orders,
                                        ]}
                                        maxAgeInDays={3}
                                    >
                                        <PracticeGqlProvider>
                                            <PracticeSession>
                                                <LaunchDarklyProvider config={ConfigService} context={ldContext}>
                                                    {props.children}
                                                </LaunchDarklyProvider>
                                            </PracticeSession>
                                        </PracticeGqlProvider>
                                    </ReloadStalePages>
                                </ConnectedRouter>
                            </Provider>
                        </StyledSnackbarProvider>
                    </OrthlyConfigProvider>
                </OrthlyErrorBoundary>
            </LocalizationProvider>
        </DandyThemeProvider>
    );
};
