import type { DcmGeometryInjector } from './DcmFiles';
import { getInsertionAxis } from './DcmFiles/InsertionAxis';
import type { ModellingTree } from './DentalDesignerModellingTree/ModellingTree';
import type { ParsedCaseResult, ModelElement } from './DesignCaseFile/DesignCaseFileParser';
import { IMPLANT_TOOTH_TYPES } from './DesignCaseFile/DesignCaseFileParser';
import type { ThreeShapeDesignTransformations } from './DesignZip/DesignZipTypes';
import { ToothUtils } from '@orthly/items';
import * as THREE from 'three';
import { create as createXml } from 'xmlbuilder2';

// Map from restorative DCM file path (relative to the root design directory, e.g. "CAD/CAD1.dcm") to DCM file contents
type ModelMap = Map<string, string>;

const IDENTITY = new THREE.Matrix4().identity();

/**
 * Gets the insertion axes from a design
 * @param parsedCase Parsed case file contents
 * @param modellingTree Dental designer modelling tree
 * @param transformations Parsed arch transformations
 * @param cadModels Map of design CAD/ directory DCM files
 * @returns A map from model element ID to insertion axis, expressed in world space
 */
export function getInsertionAxes(
    parsedCase: Pick<ParsedCaseResult, 'toothElements' | 'modelElements'>,
    modellingTree: Pick<ModellingTree, 'insertionAxes'>,
    transformations: ThreeShapeDesignTransformations,
    cadModels: ModelMap,
): Map<string, THREE.Vector3> {
    const upperTransformation = transformations.upperAlignToBite ?? transformations.upperJawToLowerJaw;
    const lowerTransformation = transformations.lowerAlignToBite ?? IDENTITY;
    if (!upperTransformation) {
        return new Map();
    }

    const insertionAxesDdmt = modellingTree.insertionAxes;

    const { toothElements, modelElements } = parsedCase;

    // Find all implant UNNs so we can associate crowns with them.
    const implantUnns = toothElements
        .filter(el => IMPLANT_TOOTH_TYPES.includes(el.cacheToothTypeClass))
        .map(el => el.toothNumber);

    // Walk through the CAD DCMs and check for insertion axes.
    const insertionAxes = new Map<string, THREE.Vector3>();
    for (const [dcmFilePath, dcmText] of cadModels.entries()) {
        const modelElement = modelElements.find(el => el.modelFilePath === dcmFilePath);
        if (!modelElement) {
            continue;
        }

        const insertionAxisDcm = getInsertionAxis(createXml(dcmText));
        const insertionAxisDataDdmt = insertionAxesDdmt.get(modelElement.modelJobID);

        const modelElementId = modelElement.modelElementID;
        const matchedToothElements = toothElements.filter(el => el.modelElementID === modelElementId);
        const matchedUnns = matchedToothElements.map(el => el.toothNumber);
        const isDirectToImplant = matchedToothElements.some(el => IMPLANT_TOOTH_TYPES.includes(el.cacheToothTypeClass));

        // We use the DCM value in the following situations:
        //   A valid value was found and it is not a direct to implant component and...
        //   1. Crown or Bridge on an abutment or...
        //   2. Crown or Bridge on a tooth and changedByHand
        const isDcmInsertionAxisValid = !!insertionAxisDcm && !isDirectToImplant;

        // This function replicates logic in root-canal. I could not think of a better name for this variable and I do
        // not understand the logic behind it. Perhaps we can refactor it in the future.
        const other = matchedUnns.some(el => implantUnns.includes(el)) || !!insertionAxisDataDdmt?.changedByHand;

        // The drill direction from the modelling tree is the negative of the insertion axis from the DCMs.
        const insertionAxis =
            isDcmInsertionAxisValid && other
                ? insertionAxisDcm
                : insertionAxisDataDdmt?.drillDirection.clone().negate();
        if (!insertionAxis) {
            continue;
        }

        // No matter the source, the insertion direction is expressed in local space and must be transformed to the
        // world space.
        const transform = matchedUnns.some(ToothUtils.toothIsUpper) ? upperTransformation : lowerTransformation;
        insertionAxis.transformDirection(transform);
        insertionAxes.set(modelElementId, insertionAxis);
    }

    return insertionAxes;
}

// This just takes a different shape for cadModels with modelElement and the dcm
// object already parsed.  TODO, unify or enhance
export function getInsertionAxes2(
    parsedCase: Pick<ParsedCaseResult, 'toothElements' | 'modelElements'>,
    modellingTree: Pick<ModellingTree, 'insertionAxes'>,
    transformations: ThreeShapeDesignTransformations,
    cadModels: { fileName: string; dcm: DcmGeometryInjector; modelElement?: ModelElement }[],
): Map<string, THREE.Vector3> {
    const upperTransformation = transformations.upperAlignToBite ?? transformations.upperJawToLowerJaw;
    const lowerTransformation = transformations.lowerAlignToBite ?? IDENTITY;
    if (!upperTransformation) {
        return new Map();
    }

    const insertionAxesDdmt = modellingTree.insertionAxes;

    const { toothElements, modelElements } = parsedCase;

    // Find all implant UNNs so we can associate crowns with them.
    const implantUnns = toothElements
        .filter(el => IMPLANT_TOOTH_TYPES.includes(el.cacheToothTypeClass))
        .map(el => el.toothNumber);

    // Walk through the CAD DCMs and check for insertion axes.
    const insertionAxes = new Map<string, THREE.Vector3>();
    for (const modelElement of modelElements) {
        // in a prep case, cadModel might not exist yet
        const cadModel = cadModels.find(cM => cM.modelElement?.modelElementID === modelElement.modelElementID);

        const insertionAxisDcm = cadModel ? getInsertionAxis(cadModel.dcm.processedXML) : undefined;

        const insertionAxisDataDdmt = insertionAxesDdmt.get(modelElement.modelJobID);

        const matchedToothElements = toothElements.filter(el => el.modelElementID === modelElement.modelElementID);
        const matchedUnns = matchedToothElements.map(el => el.toothNumber);
        const isDirectToImplant = matchedToothElements.some(el => IMPLANT_TOOTH_TYPES.includes(el.cacheToothTypeClass));

        // We use the DCM value in the following situations:
        //   A valid value was found and it is not a direct to implant component and...
        //   1. Crown or Bridge on an abutment or...
        //   2. Crown or Bridge on a tooth and changedByHand
        const isDcmInsertionAxisValid = !!insertionAxisDcm && !isDirectToImplant;

        // This function replicates logic in root-canal. I could not think of a better name for this variable and I do
        // not understand the logic behind it. Perhaps we can refactor it in the future.
        const other = matchedUnns.some(el => implantUnns.includes(el)) || !!insertionAxisDataDdmt?.changedByHand;

        // The drill direction from the modelling tree is the negative of the insertion axis from the DCMs.
        const insertionAxis =
            isDcmInsertionAxisValid && other
                ? insertionAxisDcm
                : insertionAxisDataDdmt?.drillDirection.clone().negate();
        if (!insertionAxis) {
            continue;
        }

        // No matter the source, the insertion direction is expressed in local space and must be transformed to the
        // world space.
        const transform = matchedUnns.some(ToothUtils.toothIsUpper) ? upperTransformation : lowerTransformation;
        insertionAxis.transformDirection(transform);
        insertionAxes.set(modelElement.modelElementID, insertionAxis);
    }

    return insertionAxes;
}
