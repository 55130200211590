import { PaddedGridLayout } from './TrainingDataIntake.styles';
import { TrainingDialogStage } from './TrainingDataIntake.types';
import { AddTraineeButton } from './components/AddTraineeButton';
import { DentistFormFields } from './components/DentistFormFields';
import { TraineeFormFields } from './components/TraineeFormFields';
import { useTrainees } from './hooks/useTrainees';
import { Format } from '@orthly/runtime-utils';
import { styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const AddPersonLayout = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
        alignItems: 'stretch',
        gap: theme.spacing(1.5),
    },
}));

const PaddedGridLayoutWithOverflow = styled(PaddedGridLayout)(({ theme }) => ({
    maxHeight: 464,
    overflowY: 'auto',

    [theme.breakpoints.down('sm')]: {
        maxHeight: 'unset',
    },
}));

const useTrainingAttendeesLabel = () => {
    const { trainees } = useTrainees();
    return `${Format.pluralizeNoun('Person', trainees.length)} attending training (${trainees.length})`;
};

const TraineeDataIntakeFormFirstStage: React.VFC = () => {
    const { traineeAtoms } = useTrainees();
    const trainingAttendeesLabel = useTrainingAttendeesLabel();

    return (
        <PaddedGridLayout>
            <AddPersonLayout>
                <Text variant={'body2'} medium>
                    {trainingAttendeesLabel}
                </Text>
                <AddTraineeButton />
            </AddPersonLayout>

            <PaddedGridLayoutWithOverflow>
                {traineeAtoms.map((traineeAtom, index) => (
                    <TraineeFormFields key={`${traineeAtom}`} listIndex={index} traineeAtom={traineeAtom} />
                ))}
            </PaddedGridLayoutWithOverflow>
        </PaddedGridLayout>
    );
};

const TraineeDataIntakeFormSecondStage: React.VFC = () => {
    const { traineeAtoms } = useTrainees();

    return (
        <PaddedGridLayoutWithOverflow>
            {traineeAtoms.map(traineeAtom => (
                <DentistFormFields key={`${traineeAtom}`} traineeAtom={traineeAtom} />
            ))}
        </PaddedGridLayoutWithOverflow>
    );
};

export const TrainingDataIntakeForm: React.VFC<{ stage: TrainingDialogStage }> = ({ stage }) => {
    if (stage === TrainingDialogStage.FIRST) {
        return <TraineeDataIntakeFormFirstStage />;
    }

    return <TraineeDataIntakeFormSecondStage />;
};
