import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { RestorativeView } from '../ModelAppearance/ModelAppearanceTypes';
import { getOpacities } from './TransparencySlider.util';
import type { DandyAnalyticsEventSchemaType } from '@orthly/analytics/dist/browser';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import { FlossPalette, stylesFactory, Text, Button, Grid, Slider, withStyles } from '@orthly/ui-primitives';
import React from 'react';

const FlossSlider = withStyles(() => ({
    track: {
        height: 4,
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
    rail: {
        height: 4,
        opacity: 1,
        color: FlossPalette.DARK_TAN,
    },
    thumb: {
        width: 16,
        height: 16,
        marginTop: -6,
        marginLeft: -8,
        color: FlossPalette.PRIMARY_FOREGROUND,
    },
}))(Slider);

const useStyles = stylesFactory(() => ({
    slider: {
        width: 160,
        margin: '0px 26px',
    },
}));

type DisplayLocationT =
    DandyAnalyticsEventSchemaType['All - Portal - Design Comparison - Transparency Slider Used']['displayLocation'];

function useTrackSliderUsed(displayLocation: DisplayLocationT) {
    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    return React.useCallback(
        (currentRestorativeOpacity: number, pastRestorativeOpacity: number) => {
            if (!orderId) {
                return;
            }

            BrowserAnalyticsClientFactory.Instance?.track(
                'All - Portal - Design Comparison - Transparency Slider Used',
                {
                    currentRestorativeOpacity,
                    pastRestorativeOpacity,
                    displayLocation,
                    $groups: { order: orderId },
                },
            );
        },
        [orderId, displayLocation],
    );
}

interface TransparencySliderProps {
    setAppearance: React.Dispatch<React.SetStateAction<ModelAppearance>>;
    displayLocation: DisplayLocationT;
    trackResetUsed: () => void;
}

export const TransparencySlider: React.FC<TransparencySliderProps> = ({
    setAppearance,
    displayLocation,
    trackResetUsed,
}) => {
    const classes = useStyles();

    const trackSliderUsed = useTrackSliderUsed(displayLocation);

    const [value, setValue] = React.useState<number>(0.5);

    const handleChange = React.useCallback(
        (_event: any, newValue: number | number[]) => {
            if (Array.isArray(newValue)) {
                return;
            }

            setValue(newValue);

            const opacities = getOpacities(newValue);
            if (!opacities) {
                return;
            }

            setAppearance(current => {
                const cadRestoratives = current.restoratives.CAD.map(pma => ({
                    ...pma,
                    appearance: { ...pma.appearance, opacity: opacities.current },
                }));

                const pastCadRestoratives = current.pastRestoratives.CAD.map(pma => ({
                    ...pma,
                    appearance: { ...pma.appearance, opacity: opacities.past },
                }));

                return {
                    ...current,
                    restoratives: {
                        ...current.restoratives,
                        [RestorativeView.CAD]: cadRestoratives,
                    },
                    pastRestoratives: {
                        ...current.pastRestoratives,
                        [RestorativeView.CAD]: pastCadRestoratives,
                    },
                };
            });
        },
        [setValue, setAppearance],
    );

    const handleChangeCommitted = React.useCallback(
        (_event: any, newValue: number | number[]) => {
            if (Array.isArray(newValue)) {
                return;
            }

            const opacities = getOpacities(newValue);
            if (!opacities) {
                return;
            }

            trackSliderUsed(opacities.current, opacities.past);
        },
        [trackSliderUsed],
    );

    const resetTransparency = React.useCallback(() => {
        trackResetUsed();
        handleChange(null, 0.5);
    }, [handleChange, trackResetUsed]);

    return (
        <Grid container direction={'column'}>
            <Grid container item direction={'row'} justifyContent={'space-between'} wrap={'nowrap'}>
                <Grid item>
                    <Text variant={'body2'} medium color={'DARK_GRAY'}>
                        Transparency
                    </Text>
                </Grid>
                <Grid item>
                    <Button variant={'secondary'} onClick={resetTransparency} style={{ height: '2em' }}>
                        Reset
                    </Button>
                </Grid>
            </Grid>
            <Grid container item direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Grid item>
                    <Text variant={'body2'} color={'GRAY'} style={{ lineHeight: '1.2em' }}>
                        Past
                    </Text>
                </Grid>
                <Grid item>
                    <FlossSlider
                        className={classes.slider}
                        value={value}
                        onChange={handleChange}
                        onChangeCommitted={handleChangeCommitted}
                        min={0}
                        max={1}
                        step={0.05}
                        size={'small'}
                    />
                </Grid>
                <Grid item>
                    <Text variant={'body2'} color={'GRAY'} style={{ lineHeight: '1.2em' }}>
                        Current
                    </Text>
                </Grid>
            </Grid>
        </Grid>
    );
};
