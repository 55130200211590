import { DisabledFieldOverlay } from './DisabledFieldOverlay';
import { SuggestedValueWarning } from './SuggestedValueWarning';
import { buttonClasses, buttonGroupClasses } from '@mui/material';
import type { SimpleSelectOption, SimpleSelectProps } from '@orthly/ui';
import { useMergedMuiClasses, Typography as FlossTypography } from '@orthly/ui';
import type { Theme, ButtonGroupProps, TooltipProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    makeStyles,
    Button,
    ButtonGroup,
    FormHelperText,
    Grid,
    Tooltip,
    Text,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import _ from 'lodash';
import React from 'react';

export type CheckoutButtonSelectItemClassKey =
    | 'button'
    | 'buttonDisabled'
    | 'buttonInactive'
    | 'buttonSelected'
    | 'buttonLabel'
    | 'buttonHidden'
    | 'optionImg'
    | 'imgWrap';

export type CheckoutButtonSelectClassKey =
    | CheckoutButtonSelectItemClassKey
    | 'root'
    | 'titleContainer'
    | 'buttonGroup'
    | 'buttonGroupImgs'
    | 'title'
    | 'titleError';

export const useCheckoutButtonSelectClasses = makeStyles((theme: Theme) => {
    const buttonTransition = theme.transitions.create(['padding', 'width', 'min-width'], {
        easing: theme.transitions.easing.easeIn,
        duration: 200,
    });
    return createStyles<CheckoutButtonSelectClassKey, {}>({
        root: {
            padding: `16px 0`,
            position: 'relative',
        },
        titleContainer: {
            paddingBottom: 12,
            alignItems: 'center',
        },
        buttonGroup: {
            '& *': {
                position: 'relative',
            },
        },
        buttonGroupImgs: {
            [`& .${buttonGroupClasses.disabled}`]: {
                '& img': {
                    opacity: 0.3,
                    transition: theme.transitions.create('opacity', {
                        easing: theme.transitions.easing.easeInOut,
                        duration: 100,
                    }),
                },
                '&:hover img': {
                    opacity: 1,
                },
            },
            '&': {
                justifyContent: 'space-between',
            },
            [theme.breakpoints.down('md')]: {
                flexWrap: 'wrap',
                [`& .${buttonClasses.root}`]: {
                    borderRightColor: FlossPalette.DARK_TAN,
                },
            },
        },
        button: {
            textTransform: 'unset',
            border: `2px solid ${FlossPalette.DARK_TAN}`,
            padding: '5px 15px 7px',
            transition: buttonTransition,
            width: '100%',
            // This increases specificity to ensure we have a zero borderRadius.
            '&&': {
                borderRadius: 0,
                height: 'auto',
            },
            '&&:hover': {
                color: FlossPalette.BLACK,
                borderColor: FlossPalette.DARK_TAN,
            },
            [`&.${buttonClasses.disabled}`]: {
                border: `2px solid ${FlossPalette.DARK_TAN}`,
                opacity: 0.7,
            },
            // TODO: What should this connect to?
            '&$buttonHidden': {
                width: '0 !important',
                minWidth: 0,
                padding: 0,
                height: 0,
                borderLeft: 'none',
                borderRight: 'none',
                overflow: 'hidden',
            },
        },
        buttonDisabled: {},
        buttonInactive: {},
        buttonHidden: {},
        buttonSelected: {
            borderColor: `${FlossPalette.BLACK} !important`,
            background: FlossPalette.TAN,
            paddingBottom: 5,
            borderBottomWidth: 4,
            zIndex: 1,
            [`& + .${buttonClasses.root}`]: {
                borderLeftColor: 'transparent',
            },
        },
        buttonLabel: {
            display: 'flex',
            flexWrap: 'nowrap',
            flexDirection: 'column',
            fontFamily: 'Inter Regular, Verdana, sans-serif',
            height: '100%',
            flexGrow: 1,
            width: '100%',
            justifyContent: 'center',
            // fade-in effect
            transition: 'opacity 50ms linear 200ms, width 50ms linear 200ms',
            visibility: 'visible',
            opacity: 1,
            [theme.breakpoints.down('md')]: {
                minHeight: 'unset',
            },
        },
        optionImg: {
            objectFit: 'contain',
            width: 'auto',
            maxWidth: '100%',
            position: 'relative',
        },
        imgWrap: {
            padding: `${theme.typography.pxToRem(48)} ${theme.typography.pxToRem(32)}`,
            height: '100%',
            flexGrow: 1,
        },
        title: {
            fontWeight: 500,
            '&$titleError': {
                color: theme.palette.error.main,
            },
        },
        titleError: {},
    });
});

export type CheckoutButtonSelectOption = Omit<SimpleSelectOption, 'label'> & {
    image_url?: string;
    label?: React.ReactNode;
    hidden?: boolean;
    TooltipProps?: Partial<TooltipProps> & Pick<TooltipProps, 'title'>;
    badge?: number;
};

interface CheckoutButtonSelectPropsBase extends Pick<SimpleSelectProps, 'helperText' | 'disabled' | 'label'> {
    classes?: Partial<Record<CheckoutButtonSelectClassKey, string>>;
    buttonGroupClasses?: ButtonGroupProps['classes'];
    options: CheckoutButtonSelectOption[];
    containerStyle?: React.CSSProperties;
    suggestedValue?: string;
}

interface CheckoutButtonSelectPropsMulti extends CheckoutButtonSelectPropsBase {
    mode: 'multiple';
    value: string[];
    onChange: (newValue: string[]) => void;
}

interface CheckoutButtonSelectPropsSingle extends CheckoutButtonSelectPropsBase {
    mode: 'single';
    value?: string;
    onChange: (newValue?: string) => void;
}

export type CheckoutButtonSelectProps = CheckoutButtonSelectPropsMulti | CheckoutButtonSelectPropsSingle;

export const CheckoutButtonSelect: React.FC<CheckoutButtonSelectProps> = props => {
    const baseClasses = useCheckoutButtonSelectClasses();
    const classes = useMergedMuiClasses(baseClasses, props.classes);
    const { options, value: currentValue, suggestedValue } = props;
    const imagesEnabled = React.useMemo(() => _.every(options, o => !!o.image_url), [options]);
    const optionIsSelected = React.useCallback(
        (optionValue: string) => {
            if (props.mode === 'multiple') {
                return props.value.includes(optionValue);
            }
            return optionValue === props.value;
        },
        [props.mode, props.value],
    );
    const onOptionClicked = (optionValue: string) => () => {
        const optCurrentlySelected = optionIsSelected(optionValue);
        if (props.mode === 'multiple') {
            const newValue = optCurrentlySelected
                ? props.value.filter(v => v !== optionValue)
                : [...props.value, optionValue];
            return props.onChange(newValue);
        }
        props.onChange(optCurrentlySelected ? undefined : optionValue);
    };
    const helperText = React.useMemo(() => {
        if (typeof props.helperText === 'string') {
            return props.helperText.trim().length > 0 ? props.helperText : null;
        }
        return props.helperText;
    }, [props.helperText]);
    return (
        <Grid container style={props.containerStyle} className={classes.root}>
            <Grid container className={classes.titleContainer}>
                <Text className={classes.title}>{props.label}</Text>
                {helperText && (
                    <Grid container>
                        <FormHelperText>{helperText}</FormHelperText>
                    </Grid>
                )}
            </Grid>
            <ButtonGroup
                fullWidth
                className={cx(classes.buttonGroup, imagesEnabled && classes.buttonGroupImgs)}
                classes={props.buttonGroupClasses}
            >
                {props.options.map(option => {
                    const imageSrc = imagesEnabled ? option.image_url : undefined;
                    const selected = optionIsSelected(option.value);
                    const label = option.label ?? _.startCase(option.value);
                    const tooltipProps = option.TooltipProps;
                    const button = (
                        <Button
                            variant={'contained'}
                            disabled={option.disabled}
                            key={option.value}
                            classes={{
                                root: cx(
                                    classes.button,
                                    classes.buttonLabel,
                                    selected && classes.buttonSelected,
                                    !selected && !!currentValue && props.mode !== 'multiple' && classes.buttonInactive,
                                    option.hidden && classes.buttonHidden,
                                ),
                                disabled: classes.buttonDisabled,
                            }}
                            onClick={onOptionClicked(option.value)}
                        >
                            {!!imageSrc && (
                                <Grid container className={classes.imgWrap} justifyContent={'center'}>
                                    <img src={imageSrc} className={classes.optionImg} alt={option.value} />
                                </Grid>
                            )}
                            <Grid container justifyContent={'center'} style={{ whiteSpace: 'normal' }}>
                                {label}
                            </Grid>
                            {option.badge !== undefined && (
                                <div
                                    style={{
                                        width: 24,
                                        height: 24,
                                        position: 'absolute',
                                        top: 16,
                                        right: 8,
                                        borderRadius: 8,
                                        backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
                                    }}
                                >
                                    <FlossTypography variant={'body2'} medium color={'WHITE'}>
                                        {option.badge}
                                    </FlossTypography>
                                </div>
                            )}
                        </Button>
                    );
                    return tooltipProps ? <Tooltip {...tooltipProps}>{button}</Tooltip> : button;
                })}
                {props.children}
            </ButtonGroup>
            <DisabledFieldOverlay disabled={!!props.disabled} />
            {props.mode !== 'multiple' && !!suggestedValue && (
                <SuggestedValueWarning
                    currentValue={props.value}
                    suggestedValue={suggestedValue}
                    customStyles={{ marginTop: '8px' }}
                    onAccept={onOptionClicked(suggestedValue)}
                />
            )}
        </Grid>
    );
};
