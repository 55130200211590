import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { OrthlyBrowserConfig, ErrorPage } from '@orthly/ui';
import * as Sentry from '@sentry/react';
import type { TierResult } from 'detect-gpu';
import { getGPUTier } from 'detect-gpu';
import React from 'react';

// cache our GPU Tier detection for reporting
let globalGpuCachedTier: undefined | TierResult = undefined;

export const BadGPUPerformance: React.VFC = () => (
    <ErrorPage
        message={'Minimum System Requirements Not Met'}
        text={'This browser or PC does not have adequate graphics performance.'}
    />
);

export function useOrthlyDetectGPU(checkMinimumSystemRequirements?: boolean) {
    const [gpuTier, setGpuTier] = React.useState<TierResult | undefined>(undefined);
    const [gpuTierCalled, setGpuTierCalled] = React.useState(false);
    const [gpuTierError, setGpuTierError] = React.useState<string>('');

    React.useEffect(() => {
        if (checkMinimumSystemRequirements && gpuTier === undefined && !gpuTierCalled) {
            setGpuTierCalled(true);
            getGPUTier()
                .then(result => {
                    setGpuTier(result);
                })
                .catch(error => {
                    setGpuTierError(JSON.stringify(error));
                });
        }
        const gpuTierIsBad =
            gpuTier?.type === 'WEBGL_UNSUPPORTED' ||
            gpuTier?.type === 'BLOCKLISTED' ||
            gpuTier?.gpu?.includes('swiftshader');

        if (gpuTier && gpuTierIsBad && !OrthlyBrowserConfig.isDevelopment) {
            Sentry.captureException(new Error('Bad GPU Found'), {
                extra: { gpuTier },
            });
        }

        if (gpuTier !== undefined) {
            // if we have already cached, and the value changed (this effect tracks gpuInfo)
            BrowserAnalyticsClientFactory.Instance?.track('Global - GPU Tier Detected', {
                tier: gpuTier?.tier,
                type: gpuTier?.type,
                fps: gpuTier?.fps,
                isMobile: gpuTier?.isMobile,
                gpu: gpuTier?.gpu,
                isChanged: !!globalGpuCachedTier,
            });
            globalGpuCachedTier = gpuTier;
        }
    }, [gpuTier, setGpuTier, checkMinimumSystemRequirements, gpuTierCalled]);

    return {
        loading: checkMinimumSystemRequirements && gpuTier === undefined,
        error: gpuTierError,
        isBadGpu:
            gpuTier?.type === 'WEBGL_UNSUPPORTED' ||
            gpuTier?.type === 'BLOCKLISTED' ||
            gpuTier?.gpu?.includes('swiftshader'),
        gpuInfo: gpuTier,
        isMobile: gpuTier?.isMobile,
    };
}
