import { FileSelectLoader } from './FileSelectList';
import type { FileUploaderDropzoneProps } from './FileUploaderDropzone';
import { FileUploaderDropzone } from './FileUploaderDropzone';
import { FirebaseFileListItem } from './FirebaseUploadFileList';
import type { FileUploaderField, FileUploaderPreviewComponentProps } from './file-uploader-types';
import { OrthlyErrorBoundary } from '@orthly/ui';
import { Grid, List, Text } from '@orthly/ui-primitives';
import React from 'react';

interface SingleFileFieldProps<K extends string = string> {
    field: FileUploaderField<K>;
    selectedFile?: File;
    progress: number;
    onRemoveFile: (fileName: string) => void;
    dropzoneContent?: React.ReactNode;
    loading: boolean;
    PreviewComponent?: React.FC<FileUploaderPreviewComponentProps>;
    dropzoneProps: Omit<FileUploaderDropzoneProps<K>, 'fileFields'>;
    disableImgPreview?: boolean;
    wrapperStyle?: React.CSSProperties;
}

export function FileUploaderSingleFileField<K extends string>(props: SingleFileFieldProps<K>) {
    const { field, selectedFile, progress, onRemoveFile, loading, PreviewComponent } = props;
    return (
        <Grid container key={field.fieldKey} style={{ padding: '10px 0' }}>
            {field.displayName && (
                <Text variant={'h6'} style={{ width: '100%', textAlign: 'left', marginBottom: 5 }}>
                    Select {field.displayName}
                </Text>
            )}
            {PreviewComponent && (
                <Grid container>
                    <OrthlyErrorBoundary componentName={'PreviewComponent'} FallbackComponent={__props => null}>
                        <PreviewComponent field={field} selectedFile={selectedFile} />
                    </OrthlyErrorBoundary>
                </Grid>
            )}
            <Grid container style={{ position: 'relative', display: !selectedFile ? 'none' : undefined }}>
                <Grid item xs={12}>
                    <FileSelectLoader loading={loading} progress={progress * 100} />
                    <List dense style={{ padding: 0, borderBottom: '1px solid #eee' }}>
                        {selectedFile && (
                            <FirebaseFileListItem
                                disableImgPreview={props.disableImgPreview}
                                file={selectedFile}
                                onRemove={() => onRemoveFile(selectedFile.name)}
                            />
                        )}
                    </List>
                </Grid>
            </Grid>
            <Grid container style={{ paddingTop: 10, display: selectedFile ? 'none' : undefined }}>
                <FileUploaderDropzone
                    fileFields={[field]}
                    dropzoneContent={props.dropzoneContent}
                    wrapperStyle={props.wrapperStyle}
                    {...props.dropzoneProps}
                />
            </Grid>
        </Grid>
    );
}
