import { createFocusContactAppearanceSettings } from '../ModelAppearance';
import type { ModelPayloadItem } from './ModelViewerTypes';
import type { NewModelViewerProps } from './NewModelViewer.types';
import { isCadItem } from './NewModelViewer.utils';
import type { CameraPose } from './utils3d/camera-controls.util';
import { updateCameraFromPose } from './utils3d/camera-controls.util';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import { HeatMapType } from '@orthly/forceps';
import type { OcclusalContact, OcclusalContactData, ProximalContactPatch } from '@orthly/shared-types';
import { instanceOfProxContact, instanceOfQCMetadata } from '@orthly/shared-types';
import { IconToggleButton, LookAtContoursIcon } from '@orthly/ui';
import { FlossPalette, Grid, List, ListItem, Text, createStyles, makeStyles } from '@orthly/ui-primitives';
import clsx from 'clsx';
import _ from 'lodash';
import React from 'react';
import * as THREE from 'three';

const useStyles = makeStyles(() =>
    createStyles({
        submenuRoot: {
            width: 192,
            maxHeight: 300,
            height: '100%',
            borderRadius: 12,
            backgroundColor: FlossPalette.WHITE,
            border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
            padding: `16px 12px`,
        },
        submenuSectionButton: {
            height: 24,
            marginRight: 8,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        submenuSection: {
            paddingBottom: 8,
            overflowX: 'hidden',
            borderBottom: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        },
        submenuToothRow: {
            height: 28,
            '&:hover': {
                cursor: 'pointer',
                backgroundColor: FlossPalette.TAN,
            },
        },
        submenuToothRowSelected: {
            backgroundColor: FlossPalette.PRIMARY_BACKGROUND,
            borderRadius: 4,
        },
        toggleButton: {
            backgroundColor: FlossPalette.TAN,
            borderRadius: 0,
            '&.active': {
                backgroundColor: FlossPalette.DARK_TAN,
            },
            '@media (hover: none)': {
                '&:hover': {
                    // Match the non-hover border.
                    border: '1px solid transparent',
                },
            },
        },
        submenuListWrapper: {
            paddingTop: 8,
            overflowY: 'auto',
            maxHeight: 232,
        },
    }),
);

// Occlusal contact data was poorly thought out by @PatrickMoore
// It actually does not include the occlusal contact direction
// so we just reduce it to a list of UNNs and write more bad
// code later to handle it and use the insertion axis later
export function reduceOcclusalContactData(
    occlContactData: OcclusalContactData,
): { unn: number; relationship: string }[] {
    const foundContacts = _.compact(
        occlContactData.occlusal_contacts.map((oc: OcclusalContact) => {
            return oc.localMinima[0]?.unn;
        }),
    );
    return [...occlContactData.missing_contacts, ...foundContacts].map(ele => {
        return { unn: ele, relationship: 'Occlusal' };
    });
}

export function getCameraPoseForProximalContact(
    pc: Pick<ProximalContactPatch, 'directionContact' | 'directionOcclusal' | 'location'>,
): CameraPose {
    const contactVector = new THREE.Vector3(pc.directionContact.x, pc.directionContact.y, pc.directionContact.z);
    const insertionAxis = new THREE.Vector3(pc.directionOcclusal.x, pc.directionOcclusal.y, pc.directionOcclusal.z);
    const objectCenter = new THREE.Vector3(pc.location.x, pc.location.y, pc.location.z);

    const camZ = contactVector.clone().multiplyScalar(-1);
    camZ.normalize();
    const camY = insertionAxis.clone().normalize();
    const camX = camY.clone().cross(camZ);
    camX.normalize();
    const matrixPose = new THREE.Matrix4().makeBasis(camX, camY, camZ);
    const camLocation = objectCenter.clone().add(camZ.multiplyScalar(-100));

    return { targetVector: objectCenter, locVector: camLocation, rotMatrix: matrixPose };
}

export function getCameraPoseForOcclusal(mpl: ModelPayloadItem): CameraPose {
    const geom = mpl.model.geometry;
    if (!geom.boundingSphere) {
        geom.computeBoundingSphere();
    }
    if (!geom.boundingSphere || !mpl.insertionAxis) {
        return {
            targetVector: new THREE.Vector3(0, 0, 0),
            locVector: new THREE.Vector3(0, 0, -100),
            rotMatrix: new THREE.Matrix4(),
        };
    }
    // take the mean of the min and max of the bound box corners
    const objectCenter = geom.boundingSphere.center.clone();

    const camZ = new THREE.Vector3(mpl.insertionAxis[0], mpl.insertionAxis[1], mpl.insertionAxis[2]);
    camZ.normalize().multiplyScalar(-1);

    const vectorX = new THREE.Vector3(1, 0, 0);
    const vectorY = new THREE.Vector3(0, 1, 0);
    const forwardAxis = camZ.dot(vectorX) < 0.7 ? vectorX : vectorY;

    const camY = forwardAxis.clone().add(camZ.clone().multiplyScalar(-1 * forwardAxis.dot(camZ)));
    camY.normalize();
    const camX = camY.clone().cross(camZ);

    const matrixPose = new THREE.Matrix4().makeBasis(camX, camY, camZ);
    const camLocation = objectCenter.clone().add(camZ.multiplyScalar(-100));

    return { targetVector: objectCenter, locVector: camLocation, rotMatrix: matrixPose };
}

type MinimalModelViewerProps = Pick<
    NewModelViewerProps,
    'design_metadata' | 'model_payload_items' | 'showOrderScans' | 'useProxyModelsForHeatmaps' | 'designQcConfig'
>;

interface ProximalContactsSubmenuProps {
    orderId: string;
    unns: _.Dictionary<{ unn: number; relationship: string }[]>;
    modelViewerProps: MinimalModelViewerProps;
}

const ProximalContactsSubmenu: React.VFC<ProximalContactsSubmenuProps> = ({ orderId, unns, modelViewerProps }) => {
    const classes = useStyles();
    const {
        design_metadata,
        model_payload_items,
        showOrderScans,
        useProxyModelsForHeatmaps,
        designQcConfig: { setAppearance, controlRef },
    } = modelViewerProps;

    const [visibleSection, setVisibleSection] = React.useState<'distal' | 'mesial' | 'occlusal'>('distal');
    const [selectedUnn, setSelectedUnn] = React.useState<string | undefined>(undefined);

    const focusProximal = React.useCallback(
        (pc: ProximalContactPatch | { unn: number }) => {
            const camera = controlRef.current?.object;
            if (!camera || !design_metadata) {
                return;
            }
            const focused_restorative = model_payload_items.filter(isCadItem).find((mpl: ModelPayloadItem) => {
                return mpl.unns?.includes(pc.unn);
            });

            if (!focused_restorative) {
                return;
            }

            // This custom type guard helps us determine if this is a prox contact with specific directions
            // or if we need to just focus on the center of the restorative down its insertion axis
            const cameraPose = instanceOfProxContact(pc)
                ? getCameraPoseForProximalContact(pc)
                : getCameraPoseForOcclusal(focused_restorative);

            // all the math wrapped up for convenience
            updateCameraFromPose(controlRef, cameraPose, 75);

            const contactType = instanceOfProxContact(pc) ? HeatMapType.Proximal : HeatMapType.Occlusal;
            setAppearance(
                createFocusContactAppearanceSettings(
                    model_payload_items,
                    showOrderScans,
                    pc.unn,
                    contactType,
                    useProxyModelsForHeatmaps,
                ),
            );
        },
        [controlRef, model_payload_items, setAppearance, design_metadata, showOrderScans, useProxyModelsForHeatmaps],
    );

    React.useEffect(() => {
        const contact = selectedUnn
            ? unns[selectedUnn]?.find(c => c.relationship.toLowerCase() === visibleSection.toLowerCase())
            : undefined;

        if (!contact || !selectedUnn) {
            return;
        }

        BrowserAnalyticsClientFactory.Instance?.track(`Ops - Portal - QC Zoom Tool Used`, {
            $groups: {
                order: orderId,
            },
            unn: selectedUnn,
            relationship: contact.relationship,
        });
        focusProximal(contact);
    }, [focusProximal, orderId, selectedUnn, visibleSection, unns]);

    return (
        <Grid container direction={'column'} className={classes.submenuRoot}>
            <Grid item container direction={'row'} className={classes.submenuSection}>
                <Grid item>
                    <Text
                        variant={'body2'}
                        medium
                        className={classes.submenuSectionButton}
                        color={visibleSection === 'distal' ? 'PRIMARY_FOREGROUND' : 'LIGHT_GRAY'}
                        onClick={() => setVisibleSection('distal')}
                    >
                        Distal
                    </Text>
                </Grid>
                <Grid item>
                    <Text
                        variant={'body2'}
                        medium
                        className={classes.submenuSectionButton}
                        color={visibleSection === 'mesial' ? 'PRIMARY_FOREGROUND' : 'LIGHT_GRAY'}
                        onClick={() => setVisibleSection('mesial')}
                    >
                        Mesial
                    </Text>
                </Grid>
                <Grid item>
                    <Text
                        variant={'body2'}
                        medium
                        className={classes.submenuSectionButton}
                        color={visibleSection === 'occlusal' ? 'PRIMARY_FOREGROUND' : 'LIGHT_GRAY'}
                        onClick={() => setVisibleSection('occlusal')}
                    >
                        Occlusal
                    </Text>
                </Grid>
            </Grid>
            <Grid item className={classes.submenuListWrapper}>
                <List style={{ padding: 0, height: '100%' }}>
                    {Object.keys(unns)
                        .filter(unn =>
                            unns[unn]?.some(c => c.relationship.toLowerCase() === visibleSection.toLowerCase()),
                        )
                        .map(unn => (
                            <ListItem
                                className={clsx(
                                    classes.submenuToothRow,
                                    selectedUnn === unn ? classes.submenuToothRowSelected : undefined,
                                )}
                                key={unn}
                                onClick={() => setSelectedUnn(unn)}
                            >
                                <Text variant={'body2'} medium color={selectedUnn === unn ? 'BLACK' : 'GRAY'}>
                                    #{unn} {_.upperFirst(visibleSection)}
                                </Text>
                            </ListItem>
                        ))}
                </List>
            </Grid>
        </Grid>
    );
};

export function useProximalContactsViewUi(props: MinimalModelViewerProps) {
    const { design_metadata } = props;
    const classes = useStyles();

    const [isProximalContactsSubmenuVisible, setIsProximalContactsSubmenuVisible] = React.useState<boolean>(false);

    const proximalContactsUnns = React.useMemo(() => {
        if (
            !instanceOfQCMetadata(design_metadata) ||
            (design_metadata.prox_contact_data?.matchedContacts === undefined &&
                design_metadata.occlusal_contact_data === undefined)
        ) {
            return null;
        }

        const reducedOcclusalContacts = design_metadata?.occlusal_contact_data
            ? reduceOcclusalContactData(design_metadata.occlusal_contact_data)
            : [];
        const definedProxContacts = design_metadata?.prox_contact_data?.matchedContacts ?? [];
        const unns = _.groupBy([...definedProxContacts, ...reducedOcclusalContacts], pc => pc.unn);

        // Ensure we don't show the button to enable if there aren't any in our list.
        if (!Object.keys(unns).length) {
            return null;
        }

        return unns;
    }, [design_metadata]);

    const ProximalContactsSubmenuToggleButton = React.useMemo(() => {
        if (!proximalContactsUnns) {
            return null;
        }

        return (
            <IconToggleButton
                active={isProximalContactsSubmenuVisible}
                className={classes.toggleButton}
                tooltip={isProximalContactsSubmenuVisible ? `Hide Proximal Contacts` : `Show Proximal Contacts`}
                onClick={() => setIsProximalContactsSubmenuVisible(open => !open)}
            >
                <LookAtContoursIcon />
            </IconToggleButton>
        );
    }, [isProximalContactsSubmenuVisible, setIsProximalContactsSubmenuVisible, proximalContactsUnns, classes]);

    const Submenu = React.useMemo(() => {
        if (!isProximalContactsSubmenuVisible || !proximalContactsUnns) {
            return null;
        }

        return (
            <OrderAnalyticsContext.Consumer>
                {context => (
                    <ProximalContactsSubmenu
                        unns={proximalContactsUnns}
                        modelViewerProps={props}
                        orderId={context?.orderId ?? ''}
                    />
                )}
            </OrderAnalyticsContext.Consumer>
        );
    }, [proximalContactsUnns, props, isProximalContactsSubmenuVisible]);

    return { ProximalContactsSubmenuToggleButton, isProximalContactsSubmenuVisible, ProximalContactsSubmenu: Submenu };
}
