import { trackGuidedWaxupButtonClick } from '../../DesignViewerLiteDetachedContainer';
import { useDesignViewerButtonStyles } from '../VisualConstants.util';
import type { ExternalAppearanceApp } from '@orthly/dentin';
import { ModelTextureIcon } from '@orthly/ui';
import {
    Button,
    Grid,
    Switch,
    Text,
    useScreenIsLgOrUp,
    useScreenIsMobileOrVerticalTablet,
} from '@orthly/ui-primitives';
import cx from 'classnames';
import React from 'react';

interface ColorToggleButtonProps {
    externalAppearanceApp: ExternalAppearanceApp;
}

export const ColorToggleButton: React.VFC<ColorToggleButtonProps> = ({ externalAppearanceApp }) => {
    const classes = useDesignViewerButtonStyles();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const isScreenLgOrUp = useScreenIsLgOrUp();
    return (
        <Button
            variant={'secondary'}
            size={'small'}
            onClick={() => {
                trackGuidedWaxupButtonClick('Toggle Color');
                externalAppearanceApp.toggleColor();
            }}
            className={cx(
                classes.buttonBorder,
                isMobile &&
                    externalAppearanceApp.appearanceFilterValues.showColor &&
                    classes.selectedMobileDesignButton,
            )}
            style={isScreenLgOrUp ? {} : { height: '100%' }}
        >
            <Grid container className={classes.buttonsContainer}>
                <ModelTextureIcon />
                {!isScreenLgOrUp ? (
                    <Text variant={'caption'} medium color={'PRIMARY_FOREGROUND'}>
                        Color
                    </Text>
                ) : (
                    <>
                        <Switch
                            color={'secondary'}
                            checked={externalAppearanceApp.appearanceFilterValues.showColor}
                            key={+externalAppearanceApp.appearanceFilterValues.showColor}
                        />
                        Color
                    </>
                )}
            </Grid>
        </Button>
    );
};
