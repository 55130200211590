import { FlossPalette, stylesFactory, Tooltip, InfoIcon, WarningIcon } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    tooltip: {
        whiteSpace: 'pre-line',
    },
}));

interface OrderSummaryRowWarningProps {
    warningMessage?: string;
    warningTooltipContent?: string;
}

export const OrderSummaryRowWarning: React.VFC<OrderSummaryRowWarningProps> = ({
    warningMessage,
    warningTooltipContent,
}) => {
    const classes = useStyles();

    if (!warningMessage || warningMessage.length === 0) {
        return null;
    }

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <WarningIcon color={'inherit'} style={{ color: FlossPalette.ATTENTION, fontSize: 14 }} />
            <span style={{ marginLeft: 4, paddingRight: 4, display: 'flex' }}>
                <span style={{ color: FlossPalette.ATTENTION }}>{warningMessage}</span>
                {!!warningTooltipContent && (
                    <Tooltip key={'key'} title={warningTooltipContent} arrow classes={{ tooltip: classes.tooltip }}>
                        <InfoIcon style={{ fontSize: 'medium', margin: 'auto 8px', color: FlossPalette.GRAY }} />
                    </Tooltip>
                )}
            </span>
        </div>
    );
};
