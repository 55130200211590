import { getFirstAndLastNameFromFullName, getStaffTitleFromPracticeRoles } from '@orthly/dentin';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import {
    useGetMembershipLoginLazyQuery,
    useMigratePreferencesToStaffMutation,
    useOneTimeCodeVerificationMutation,
} from '@orthly/graphql-react';
import type { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';

export function useVerifyOneTimeCode(onSuccess?: () => void, onError?: () => void) {
    const [oneTimeCodeVerificationMtn, { error }] = useOneTimeCodeVerificationMutation();
    const verificationMtnSubmitter = (data: { code: string; mobile_phone_number: string }) =>
        oneTimeCodeVerificationMtn({
            variables: {
                code: data.code,
                mobile_phone_number: data.mobile_phone_number,
            },
        });
    const { submit, submitting } = useChangeSubmissionFn(verificationMtnSubmitter, {
        closeOnComplete: true,
        disableDefaultErrorMessage: true,
        onError: () => {
            onError?.();
        },
        onSuccess: data => {
            if (data?.data?.verified) {
                onSuccess?.();
            } else {
                onError?.();
            }
        },
    });
    return { submit, submitting, error };
}

export function useMigratePref(
    proceedToNextStep: () => void,
    onSelectToken: (token: string, key: string) => void,
    phoneNumber: string,
    email: string,
    employeeName: string,
    roles?: LabsGqlStaffRolePractice[],
    setSelectedEmployee?: (selectedEmployee: LabsGqlDoctorPreferencesFragment) => void,
) {
    const [migrateSubmitMtn] = useMigratePreferencesToStaffMutation();
    const migrateMtnSubmitter = (employee: LabsGqlDoctorPreferencesFragment) => {
        const { firstName, lastName } = getFirstAndLastNameFromFullName(employeeName);
        return migrateSubmitMtn({
            variables: {
                args: {
                    email,
                    id: employee.id,
                    first_name: firstName || getStaffTitleFromPracticeRoles(roles),
                    last_name: lastName,
                    mobile_phone_number: phoneNumber,
                    roles: roles ?? [],
                    is_mobile_verified: true,
                },
            },
        });
    };
    const [triggerQuery] = useGetMembershipLoginLazyQuery({
        onCompleted: async ({ getMembershipLogin }) => {
            if (getMembershipLogin) {
                onSelectToken(getMembershipLogin.access_token, getMembershipLogin.id);
                proceedToNextStep();
            }
        },
    });
    return useChangeSubmissionFn(migrateMtnSubmitter, {
        closeOnComplete: true,
        onSuccess: result => {
            if (result?.data?.migratePreferencesToStaff.preferences) {
                setSelectedEmployee?.(result.data.migratePreferencesToStaff.preferences);
            }
            triggerQuery({
                variables: { mobile_phone_number: phoneNumber },
            });
        },
    });
}
