import { ScanFlaggedWarning } from '../../../components/WarningActionCards/ScanFlaggedWarning';
import { usePartnerUiSelector } from '../../../redux/ui';
import { useLabsContext } from '../LabsContext';
import { useOpenOrderDetailPage } from '../LabsUtils';
import { MarkNeedsRefabricationAction } from '../actions/practitioners/MarkNeedsRefabrication';
import { InfoBlock } from './OrderDetailBodyHelperComponents';
import { OrderDetailAlignersAlert } from './components/OrderDetailAlignersAlert';
import { OrderDetailsCbctScanManagement } from './components/OrderDetailCbctScanManagement.graphql';
import { OrderDetailDenturesWaxRimDeliveredAlert } from './components/OrderDetailDenturesWaxRimDeliveredAlert';
import { OrderDetailTracking } from './components/OrderDetailTracking';
import { OrderDetailTryInFeedbackAlert } from './components/OrderDetailTryInFeedbackAlert';
import { OrderDetailTryInFeedbackNeedsScanAlert } from './components/OrderDetailTryInFeedbackNeedsScanAlert';
import { OrderDetailsRefabs } from './components/OrderDetailsRefabs';
import type { LabsGqlOrder, LabsGqlSingleLabOrderFragment } from '@orthly/graphql-operations';
import { LabOrderItemSKUType, OrderItemV2Utils } from '@orthly/items';
import { LoadBlocker } from '@orthly/ui';
import { stylesFactory, useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import {
    GuidedWaxupAlertBanner,
    OrderDetailAligners,
    OrderDetailBlock,
    OrderDetailDentures,
    OrderDetailDoctorPhotosBlock,
    OrderDetailMouth,
    OrderDetailOriginalPhotosBlock,
    OrderDetailPartials,
    OrderDetailPreferencesBlock,
    OrderDetailShadeMatchBlock,
    OrderItemsTable,
    OrderRefabAlertBanner,
    OrderMultiFulfillmentAlertBanner,
    OrderWaxup,
    ScanExportModelViewerBlock,
    shouldShowWaxup,
    useShouldShowGuidedWaxupFlow,
    OrderDetailTryInFeedback,
} from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    bodyRoot: {
        padding: '12px 28px',
        [theme.breakpoints.down('sm')]: { padding: 8 },
    },
}));

const OrderDetailNotes: React.FC<{ doctor_notes: string }> = ({ doctor_notes }) => {
    if (doctor_notes.trim().length === 0) {
        return null;
    }
    return (
        <OrderDetailBlock title={'Notes'} variant={'full'} fitHeightToContent rootStyle={{ flexBasis: 'auto' }}>
            <Text variant={'body1'}>{doctor_notes}</Text>
        </OrderDetailBlock>
    );
};

interface OrderDetailSharedProps {
    order?: LabsGqlSingleLabOrderFragment;
    refetch: () => Promise<any>;
    refetchMessages?: () => Promise<any>;
}

const OrderDetailMouthAndItems: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch } = props;
    return (
        <Grid container>
            <Grid container item xs={12} md={4} alignContent={'flex-start'} direction={'column'}>
                <OrderDetailMouth order={order} />
            </Grid>
            <Grid container item xs={12} md={8} alignContent={'flex-start'} direction={'column'} wrap={'nowrap'}>
                <OrderItemsTable
                    items={OrderItemV2Utils.parseItems(order?.items_v2 ?? [])}
                    titleAction={
                        order && (
                            <MarkNeedsRefabricationAction order={order} refetch={refetch} analyticsLocation={'body'} />
                        )
                    }
                    variant={'full'}
                />
                <OrderDetailNotes doctor_notes={order?.doctor_notes ?? ''} />
            </Grid>
        </Grid>
    );
};

const OrderDetailDesignViewerBlock: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch } = props;
    const { userRole } = useLabsContext();
    const isMobile = useScreenIsMobile();
    if (order && shouldShowWaxup(order)) {
        return <OrderWaxup order={order} userRole={userRole} refetchOrder={refetch} clickToLoad={isMobile} />;
    }
    return null;
};

const OrderDetailBodyTopSection: React.FC<OrderDetailSharedProps> = props => {
    const { order, refetch, refetchMessages } = props;

    return (
        <Grid container>
            {!!order && (
                <>
                    <ScanFlaggedWarning orderDetail order={order} refetch={refetch} />

                    <OrderDetailTryInFeedbackAlert order={order} />

                    {!!order.previous_fulfillment_order_id && (
                        <OrderDetailTryInFeedbackNeedsScanAlert
                            order={{ ...order, previous_fulfillment_order_id: order.previous_fulfillment_order_id }}
                        />
                    )}

                    <OrderDetailDenturesWaxRimDeliveredAlert order={order} />

                    {order.aligner_case && (
                        <OrderDetailAlignersAlert
                            patient_first_name={order.patient.first_name}
                            order={order}
                            status={order.status}
                        />
                    )}
                </>
            )}
            <InfoBlock order={order} />
            <OrderDetailTracking order={order} refetch={refetch} />
            {order &&
                OrderItemV2Utils.parseItems(order.items_v2).some(
                    OrderItemV2Utils.itemIsType(LabOrderItemSKUType.SurgicalGuide),
                ) && (
                    <OrderDetailsCbctScanManagement order={order} refetch={refetch} refetchMessages={refetchMessages} />
                )}
        </Grid>
    );
};

const OrderDetailBodyRefabsSplits: React.FC<{ order?: LabsGqlOrder }> = ({ order }) => {
    if (!order) {
        return null;
    }
    return (
        <>
            <OrderDetailsRefabs order={order} />
        </>
    );
};

interface OrderDetailBodyProps extends OrderDetailSharedProps {
    loading: boolean;
}

export const OrderDetailBody: React.FC<OrderDetailBodyProps> = props => {
    const { order, refetch, refetchMessages } = props;
    const { userRole } = useLabsContext();
    const isNativeApp = usePartnerUiSelector(ui => ui.isNativeApp);
    const isMobile = useScreenIsMobile();
    const classes = useStyles();
    const openOrder = useOpenOrderDetailPage();
    const enableNewGuidedWaxupFlow = useShouldShowGuidedWaxupFlow(order);
    const showGuidedWaxupBanner = order && enableNewGuidedWaxupFlow;

    return (
        <Grid container>
            {order && <OrderMultiFulfillmentAlertBanner order={order} openOrder={openOrder} />}
            {order && <OrderRefabAlertBanner order={order} openOrder={openOrder} />}
            {showGuidedWaxupBanner && <GuidedWaxupAlertBanner order={order} />}
            <Grid container className={classes.bodyRoot} alignItems={'flex-start'}>
                <LoadBlocker blocking={props.loading}>
                    <OrderDetailBodyTopSection refetch={refetch} refetchMessages={refetchMessages} order={order} />
                    <OrderDetailBodyRefabsSplits order={order} />
                    <OrderDetailDesignViewerBlock refetch={refetch} order={order} />
                    {order && (
                        <>
                            <OrderDetailDoctorPhotosBlock
                                order={order}
                                refetchOrder={refetch}
                                isPSR={false}
                                disablePhotoDownload={isNativeApp || isMobile}
                            />
                            <OrderDetailOriginalPhotosBlock
                                order={order}
                                refetchOrder={refetch}
                                isPSR={false}
                                disablePhotoDownload={isNativeApp || isMobile}
                            />
                        </>
                    )}
                    {order?.scan_export.image_urls && (
                        <OrderDetailShadeMatchBlock orderId={order.id} imageUrls={order?.scan_export.image_urls} />
                    )}
                    {order?.previous_fulfillment_order_id && (
                        <OrderDetailTryInFeedback
                            orderId={order.previous_fulfillment_order_id}
                            isPreviousOrder={true}
                        />
                    )}
                    {order?.dentures_fulfillment && <OrderDetailDentures order={order} />}
                    {order?.partials_fulfillment && <OrderDetailPartials order={order} />}
                    {order?.aligner_case ? (
                        <OrderDetailAligners
                            lab_order={order}
                            aligner_case={order.aligner_case}
                            refetch={refetch}
                            user_role={userRole}
                            disablePhotoDownload={isNativeApp || isMobile}
                        />
                    ) : (
                        <OrderDetailMouthAndItems refetch={refetch} order={order} />
                    )}
                    {order && <ScanExportModelViewerBlock order={order} />}
                    {order && <OrderDetailPreferencesBlock order={order} />}
                </LoadBlocker>
            </Grid>
        </Grid>
    );
};
