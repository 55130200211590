export class DownloadUtils {
    static downloadUrl(url: string, fileName?: string) {
        const link = document.createElement('a');
        link.href = url;
        if (fileName) {
            link.download = fileName;
        }
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static downloadBlob(blob: Blob, fileName: string) {
        const url = URL.createObjectURL(blob);
        DownloadUtils.downloadUrl(url, fileName);
        setTimeout(() => URL.revokeObjectURL(url), 30_000);
    }
}
