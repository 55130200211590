import type { ICartItemV2DTO, ICustomFieldSubmission } from '@orthly/items';
import { AllItemMetafields, ItemMetafieldV2Utils } from '@orthly/items';
import { useSession } from '@orthly/session-client';
import _ from 'lodash';
import React from 'react';

export type OrderDetailFieldSubmissions = {
    // matches the doctors defaults
    commonPreferences: ICustomFieldSubmission[];
    // fields that are different by item
    differentByItemFieldIds: string[];
};

const defaultOrderPrefs: OrderDetailFieldSubmissions = { differentByItemFieldIds: [], commonPreferences: [] };

function getOrderDetailItemFieldPreferences(
    items: Pick<ICartItemV2DTO, 'preference_fields'>[],
): OrderDetailFieldSubmissions {
    const fieldsById = _.groupBy(
        items.flatMap(({ preference_fields }) => preference_fields),
        f => f.field_id,
    );
    return _.values(fieldsById).reduce<OrderDetailFieldSubmissions>((result, submissionsSameId) => {
        const firstSubmission = submissionsSameId[0];
        if (!firstSubmission) {
            return result;
        }
        const isSameForAllItems =
            submissionsSameId.length === 1 || _.every(submissionsSameId, s => s.value === firstSubmission.value);
        if (isSameForAllItems) {
            return { ...result, commonPreferences: [...result.commonPreferences, firstSubmission] };
        }
        return {
            ...result,
            differentByItemFieldIds: [...result.differentByItemFieldIds, firstSubmission.field_id],
        };
    }, defaultOrderPrefs);
}

export function useOrderDetailPreferences(
    items: Pick<ICartItemV2DTO, 'preference_fields'>[],
    { isOps, showNumericValues: overrideShowNumericValues }: { isOps?: boolean; showNumericValues?: boolean } = {},
): { name: string; value: string }[] {
    const isPSR = useSession()?.organization_type === 'internal';
    const showNumericValues = isPSR || overrideShowNumericValues;
    const orderPrefFields = React.useMemo(() => getOrderDetailItemFieldPreferences(items), [items]);

    return React.useMemo(() => {
        return orderPrefFields.commonPreferences.flatMap(prefSubmission => {
            const metafield = AllItemMetafields.find(field => field.id === prefSubmission.field_id);
            const optionLabel = metafield?.options?.find(opt => opt.value === prefSubmission.value)?.label;

            if (!isOps && metafield && ItemMetafieldV2Utils.isDeprecatedSelectField(metafield, prefSubmission.value)) {
                return [];
            }

            const numericValue = metafield?.options
                ? metafield.options.find(o => o.value === prefSubmission.value)?.internal_display_label
                : undefined;
            return [
                {
                    name: metafield?.name ?? prefSubmission.display_name,
                    id: prefSubmission.field_id,
                    value:
                        // Nested ternaries are harder to read and should be avoided. Consider using an if/else statement instead.
                        // eslint-disable-next-line no-nested-ternary
                        typeof prefSubmission.value === 'boolean'
                            ? prefSubmission.value
                                ? 'Yes'
                                : 'No'
                            : showNumericValues && !!numericValue
                              ? numericValue
                              : optionLabel ?? `${prefSubmission.value}`,
                },
            ];
        });
    }, [orderPrefFields.commonPreferences, showNumericValues, isOps]);
}
