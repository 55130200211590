/* eslint-disable max-lines */
import { logger } from '../../Utils/Logger';
import { DcmMarginInjector } from './DcmMarginInjector';
import { ThreeOxzUtil } from './ThreeOxz.util';
import { ThreeOxzCaseBuilder } from './ThreeOxzCaseBuilder';
import type { FormatGeneratorCaseData } from './ThreeOxzGenerator.types';
import type { ICartItemV2DTO } from '@orthly/items';
import { CartItemV2Utils, LabOrderItemSKUType, OrderItemLinkRelationship, OrderItemArch } from '@orthly/items';
import JSZip from 'jszip';
import _ from 'lodash';
import { create as createXML } from 'xmlbuilder2';
import type { XMLBuilder } from 'xmlbuilder2/lib/interfaces';

// only temporary for 3oxz gen (don't export or use elsewhere)
interface SingleSimpleToothUnit {
    type: 'single';
    unit_type: string;
    unn?: number;
    material: string;
    implant_id?: string;
}

interface BridgeSimpleToothUnit {
    type: 'bridge';
    sub_units: SingleSimpleToothUnit[];
}

type SimpleToothUnit = SingleSimpleToothUnit | BridgeSimpleToothUnit;

export class ThreeOxzGenerator {
    constructor(private readonly caseData: FormatGeneratorCaseData) {}

    // Exposed publicly for testing purposes only.
    async generateInternal(zip: JSZip) {
        const scanFilePaths = ThreeOxzUtil.getModelPathRenamings(this.caseData);
        await Promise.all(
            Object.entries(scanFilePaths).map(async ([source, destStub]) => {
                try {
                    logger.info(`Evaluating a 3oxz copy of ${source} => ${destStub}`);

                    const scan = this.caseData.scans.find(s => s.file_name === source);

                    if (!scan || !scan.buffer) {
                        logger.warn(`Failed to find scan`, {
                            source,
                            destStub,
                        });
                        return;
                    }

                    const marginLines = this.caseData.margin_lines?.filter(line => line.scan_id === scan.id);
                    logger.info('Writing DCM', {
                        source,
                        destStub,
                        scan_id: scan.id,
                        margin_line_count: marginLines.length,
                    });

                    const finalContents = marginLines?.length
                        ? await DcmMarginInjector.injectMarginsToString(scan.buffer, marginLines)
                        : scan.buffer;

                    zip.file(destStub, finalContents);
                } catch (err) {
                    logger.error(`Failed to copy model`, err);
                }
            }),
        );

        const caseFileData = this.generateCaseFile();
        const threeOxFileData = this.generateThreeOxFile();

        zip.file(`${this.caseData.id}_case.xml`, caseFileData);
        zip.file(`${this.caseData.id}.3ox`, `\uFEFF${threeOxFileData}`);

        return zip;
    }

    async generate() {
        const zip = new JSZip();

        return this.generateInternal(zip);
    }

    private get clinicId() {
        return this.caseData.scanner_id.replace(/-/g, '').substring(0, 10);
    }

    private static getMaterialValue(material: string) {
        const base = material.toLowerCase().trim().replace(/ /g, '_').replace(/-/g, '_');
        return _.capitalize(base);
    }

    private get3ShapeUnitType(unit_type: string): string {
        switch (unit_type) {
            case 'Onlay':
                return 'Inlay';
            case 'Partial':
            case 'Partial Denture':
                return 'Clasp';
            case 'Denture':
                return 'FullDenture';
            case 'Bleaching Tray':
            case 'Night Guard':
            case 'Retainer':
            case 'Sportsguard':
                return 'Splints';
        }
        // otherwise it's probably right or has no equivalent, good enough
        return unit_type;
    }

    private static splitBridgeSimpleToothUnitsIntoJaws(unit: BridgeSimpleToothUnit): SimpleToothUnit[] {
        // And now we find the islands, defined as a contiguous block of UNNs.
        // For instance, [5,6,7,10,11,12,16] has three islands -- [5,6,7], [10,11,12], and [16].
        // We work with sorted teeth so that we are able to identify if the current UNN can be added to the last island inserted.
        // If it cannot, we must produce a new island.
        // For efficiency, we always put the latest island at the front of the array.
        const sortedUnits = _.sortBy(unit.sub_units, unit => unit.unn ?? 0);
        const islands = sortedUnits.reduce<SingleSimpleToothUnit[][]>((islands, subUnit) => {
            const [latestIsland, ...otherIslands] = islands;

            // If there is no island at the front of the list, we haven't found any yet, and thus insert this unn as its own island.
            if (!latestIsland) {
                return [[subUnit]];
            }

            const unn = subUnit.unn ?? 0;
            const lastNumberInIsland = _.last(latestIsland)?.unn ?? 0;
            // To be a contiguous island, this tooth must be one more than the previous highest, AND be on the same arch.
            const unnInIsland = lastNumberInIsland <= 16 === unn <= 16 && lastNumberInIsland + 1 === unn;
            return unnInIsland ? [[...latestIsland, subUnit], ...otherIslands] : [[subUnit], ...islands];
        }, []);

        return Object.values(islands).flatMap<SimpleToothUnit>(unitsInIsland => {
            const uniqUnns = _.uniq(unitsInIsland.map(unitOnJaw => unitOnJaw.unn ?? 1));
            // This is an instance where the user has an implant bridge or a regular bridge with UNNs [1,2,3,23].
            // This jaw is just [23]
            if (uniqUnns.length === 1) {
                return unitsInIsland;
            }

            // This is a bridge/implant bridge with at least 1 unique unn, so it should be printed as a full connector.
            return [
                {
                    type: 'bridge',
                    sub_units: unitsInIsland,
                },
            ];
        });
    }

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    private getSimpleToothUnits(item: ICartItemV2DTO): SimpleToothUnit[] {
        if (
            CartItemV2Utils.itemIsType(item, [
                LabOrderItemSKUType.Crown,
                LabOrderItemSKUType.Inlay,
                LabOrderItemSKUType.Veneer,
            ])
        ) {
            return [
                {
                    type: 'single',
                    unit_type: item.sku,
                    unn: item.unit.unn,
                    material: item.unit.material ?? '',
                },
            ];
        }

        if (
            CartItemV2Utils.itemIsType(item, [
                LabOrderItemSKUType.Aligners,
                LabOrderItemSKUType.Denture,
                LabOrderItemSKUType.Removeable,
            ])
        ) {
            return [
                {
                    type: 'single',
                    unit_type: this.get3ShapeUnitType(item.unit.unit_type),
                    material: item.unit.material ?? '',
                },
            ];
        }

        if (CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Bridge])) {
            return ThreeOxzGenerator.splitBridgeSimpleToothUnitsIntoJaws({
                type: 'bridge',
                sub_units: item.units.map(u => ({
                    type: 'single',
                    unit_type: this.get3ShapeUnitType(u.unit_type),
                    unn: u.unn,
                    material: u.material ?? '',
                })),
            });
        }

        if (CartItemV2Utils.itemTypeHasImplant(item)) {
            // TODO: need better order item utils, shouldn't be using cart utils
            const implant_metadata = CartItemV2Utils.getImplantMetadata(item);
            const implants = CartItemV2Utils.getImplantGroups(item);
            // ImplantBridges can contain teeth that aren't just implants, whereas Implant items is just a single implant unit with no restoratives.
            const nonImplants = item.sku === LabOrderItemSKUType.ImplantBridge ? item.restoratives : [];

            const nonImplantUnits = nonImplants.flatMap<SingleSimpleToothUnit>(u => ({
                type: 'single',
                unit_type: this.get3ShapeUnitType(u.unit_type),
                unn: u.unn,
                material: u.material ?? '',
            }));

            const isScrewRetained = implant_metadata?.relationship === OrderItemLinkRelationship.ScrewRetained;

            const implantUnits = implants.flatMap<SingleSimpleToothUnit>(u => {
                const toothUnits: SingleSimpleToothUnit[] = [];

                if (!isScrewRetained) {
                    // CementRetained implants have both a Crown element and an Implant element in 3shape generated files.
                    // We are adopting this convention for Screwmentable implants.
                    toothUnits.push({
                        type: 'single',
                        unn: u.unn,
                        material: u.crown.material ?? '',
                        unit_type: 'Crown',
                    });
                }
                toothUnits.push({
                    type: 'single',
                    unn: u.unn,
                    material: u.abutment.material ?? '',
                    unit_type: this.getAbutmentUnitType(implant_metadata?.relationship),
                    implant_id: [
                        implant_metadata?.manufacturer,
                        implant_metadata?.system,
                        implant_metadata?.connection_size,
                    ].join('_'),
                });

                return toothUnits;
            });

            const units = [...implantUnits, ...nonImplantUnits];

            if (item.sku === LabOrderItemSKUType.ImplantBridge) {
                const [abutments, nonAbutments] = _.partition(units, u => u.unit_type === 'Abutment');
                const bridgeSplitIntoJaws = ThreeOxzGenerator.splitBridgeSimpleToothUnitsIntoJaws({
                    type: 'bridge',
                    sub_units: nonAbutments,
                });
                return [...abutments, ...bridgeSplitIntoJaws];
            }

            // Simple implant -- flatten the units and return them.
            return units;
        }

        if (CartItemV2Utils.itemIsType(item, [LabOrderItemSKUType.Partial])) {
            return item.unit.unns.map(unn => ({
                unn,
                type: 'single',
                unit_type: this.get3ShapeUnitType(item.unit.unit_type),
                material: item.unit.material ?? '',
            }));
        }

        if (CartItemV2Utils.itemIsType(item, LabOrderItemSKUType.Other)) {
            return [
                {
                    type: 'single',
                    unit_type: this.get3ShapeUnitType(item.unit.unit_type),
                    material: item.unit.material ?? '',
                },
            ];
        }

        // If we can't find any specific ModelElement to return, we'll just use Splint.
        // Splint is an acceptable full-arch ModelElement that will allow 3shape to import the case,
        // and the designer can tweak as needed.
        // This used to return `Unknown`, which would cause 3oxz imports to fail entirely.
        return [
            {
                type: 'single',
                unit_type: 'Splints',
                unn: 32,
                material: '',
            },
        ];
    }

    private getAbutmentUnitType(relationship: OrderItemLinkRelationship | undefined | null) {
        if (relationship === OrderItemLinkRelationship.ScrewRetained) {
            return 'AbutmentScrewRetainedCrown';
        }
        if (relationship === OrderItemLinkRelationship.Screwmentable) {
            return 'AbutmentScrewmentableCrown';
        }
        return 'Abutment';
    }

    private generateItemTypeIdsForSingleSimpleToothUnit(
        root: XMLBuilder,
        item: ICartItemV2DTO,
        tooth: SingleSimpleToothUnit,
    ) {
        switch (item.sku) {
            case LabOrderItemSKUType.Aligners:
            case LabOrderItemSKUType.Removeable:
                return root.ele('TypeID', {
                    typeID: tooth.unit_type === 'Aligner' ? 'ClearAligner' : tooth.unit_type,
                    uNN: item.unit.arch === OrderItemArch.Upper ? '1' : '17',
                    typeClass: tooth.unit_type,
                });
            case LabOrderItemSKUType.Denture:
                return root.ele('TypeID', {
                    typeID: `FullDenture2`,
                    uNN: item.unit.arch === OrderItemArch.Upper ? '1' : '17',
                    typeClass: `FullDenture`,
                });
            default: {
                const resolvedTypeId = tooth.unit_type === 'CrownPontic' ? 'Pontic' : tooth.unit_type;
                return root.ele('TypeID', {
                    typeID: `${resolvedTypeId} ${ThreeOxzGenerator.getMaterialValue(tooth.material)}`,
                    uNN: tooth.unn ?? '',
                    typeClass: tooth.unit_type,
                    globalImplantConnectionID: tooth.implant_id,
                });
            }
        }
    }

    private generateItemTypeIds(root: XMLBuilder, item: ICartItemV2DTO, tooth: SimpleToothUnit) {
        if (tooth.type === 'single') {
            this.generateItemTypeIdsForSingleSimpleToothUnit(root, item, tooth);
            return;
        }

        tooth.sub_units.forEach(innerTooth => this.generateItemTypeIdsForSingleSimpleToothUnit(root, item, innerTooth));
    }

    generateThreeOxFile() {
        const creationDateUTC = this.caseData.creation_date.toISOString();

        const root = createXML({ version: '1.0' });
        const orderExchangeMessage = root.ele('OrderExchangeMessage', {
            'xmlns:xsd': 'http://www.w3.org/2001/XMLSchema',
            'xmlns:xsi': 'http://www.w3.org/2001/XMLSchema-instance',
            type: 'AddOrder',
            version: '6.0.6.6',
            xmlns: 'http://schemas.3shape.com/3OX/OrderInterface/2011/01',
        });
        const order = orderExchangeMessage.ele('Command').ele('AddOrderRequest').ele('Order');
        ThreeOxzUtil.addXMLFields(order, {
            OrderID: { value: this.caseData.id },
            PatientId: { value: this.caseData.patient.id },
            Previous: { value: '00000000-0000-0000-0000-000000000000' },
        });
        order.ele('CommunicateOrderState').ele('State').txt('Scanned').up().ele('Flag').txt('Sent');
        const innerOrder = order.ele('InnerOrder');
        const restorationOrder = innerOrder.ele('RestorationOrder');
        const orderInfo = restorationOrder.ele('OrderInfo');
        ThreeOxzUtil.addXMLFields(orderInfo, {
            ThreeShapeOrderNo: this.caseData.id.replace(/-/g, '_'),
            Patient_LastName: this.caseData.patient.last_name,
            Patient_FirstName: this.caseData.patient.first_name,
            LabName: 'Dandy Labs',
            ClinicId: this.clinicId,
            Comments: this.caseData.comments,
            OperatorId: this.caseData.doctor_preferences_id,
            LabOperator: this.caseData.doctor_preferences_id,
            OrderImportance: 'Normal',
            RelativePos: 'False',
            OrderRelativePositionClass: 'Unsectioned',
            ScanSource: 'TRIOS',
            DeliveryDate: creationDateUTC,
        });
        restorationOrder.ele('AdditionalOrderInfo');

        const modelElements = restorationOrder.ele('ModelElements');
        const itemsExcludingNonThreeoxzItems = this.caseData.items.filter(item =>
            ThreeOxzUtil.shouldIncludeItemInThreeoxz(item, this.caseData.ignoreSkus),
        );
        // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
        // eslint-disable-next-line sonarjs/cognitive-complexity
        itemsExcludingNonThreeoxzItems.forEach(item => {
            const teeth = this.getSimpleToothUnits(item);

            teeth.forEach(tooth => {
                const modelElement = modelElements.ele('ModelElement');
                this.generateItemTypeIds(modelElement.ele('TypeIDs'), item, tooth);

                const baseShade = item.shades.find(s => s.name === 'base')?.value;
                if (baseShade) {
                    modelElement.ele('Color', { displayName: baseShade }).txt('Color3');
                }

                const resolvedMaterial = tooth.type === 'single' ? tooth.material : tooth.sub_units[0]?.material ?? '';
                ThreeOxzUtil.addXMLFields(modelElement, {
                    ModelElementIndex: '0',
                    Material: {
                        value: ThreeOxzGenerator.getMaterialValue(resolvedMaterial),
                        data: { displayName: resolvedMaterial },
                    },
                    NoOfUnits: tooth.type === 'single' ? '1' : `${tooth.sub_units.length}`,
                    OrderDate: creationDateUTC,
                    ShipmentDate: creationDateUTC,
                    DeliveryDate: creationDateUTC,
                    ScanModule: 'DigitalImpression',
                    DesignModule: 'DentalDesigner',
                    ManufacturerID: 'deadbeef-cafe-4004-abba-facadefacade',
                    ProcessStatusID: 'Scanned',
                    LinkID: tooth.type === 'bridge' ? 'LinkTypeConnector6' : undefined,
                    LinkTypeClass: tooth.type === 'bridge' ? 'Connector' : undefined,
                });
                const scanFiles = modelElement.ele('ScanFiles');
                const scanPathNames = ThreeOxzUtil.getModelPathRenamings(this.caseData);
                const scansOnArch = this.caseData.scans.filter(scan => {
                    const itemArch = ThreeOxzUtil.isUpperItem(item) ? 'upper' : 'lower';
                    const isAlwaysIncluded =
                        scan.definition.type !== 'jaw' && scan.definition.type !== 'emergence_profile';
                    const isOnArch =
                        (scan.definition.type === 'jaw' || scan.definition.type === 'emergence_profile') &&
                        scan.definition.variant === itemArch;
                    const areAllItemsOnSameArch =
                        itemsExcludingNonThreeoxzItems.every(i => ThreeOxzUtil.isUpperItem(i)) ||
                        itemsExcludingNonThreeoxzItems.every(i => !ThreeOxzUtil.isUpperItem(i));

                    return isOnArch || isAlwaysIncluded || areAllItemsOnSameArch;
                });

                scansOnArch.forEach(scan => {
                    const scanType = ThreeOxzUtil.getScanFileType(this.caseData.items, this.caseData.scans, scan);

                    const dcmFile = scan.file_name;
                    const scanOrdinal = dcmFile
                        ? dcmFile
                              .split(/[\\/]/)
                              .pop()
                              ?.match(/\d\.dcm/g)?.[0]
                              .replace('.dcm', '')
                        : undefined;

                    const outputPathName = scanPathNames[dcmFile ?? ''];
                    const outputPathNameWithOrdinal = outputPathName
                        ? `${outputPathName.replace('.dcm', '')}${scanOrdinal ?? ''}.dcm`
                        : undefined;

                    if (!outputPathNameWithOrdinal || !scanType) {
                        return;
                    }

                    scanFiles.ele('ScanFile', {
                        scanProcessState: 'Unprepared',
                        fileType: scanType,
                        path: outputPathNameWithOrdinal,
                    });
                });
            });
        });
        order.ele('Comments');
        order.ele('Attachments');
        const approvalRequirements = order.ele('ApprovalRequirements');
        ThreeOxzUtil.addXMLFields(approvalRequirements, {
            RequireScanApproval: 'true',
            RequireMarginLineApproval: 'false',
            RequireDesignApproval: 'false',
        });

        return root.end({ prettyPrint: true });
    }

    generateCaseFile() {
        const builder = new ThreeOxzCaseBuilder(this.caseData);
        return builder.buildCase().end({ prettyPrint: true });
    }

    private getCaseName(): string {
        return `${this.caseData.patient.first_name}_${this.caseData.patient.last_name}`;
    }
}
