import type { FirebasePreviewFileMultiWithType } from './OrderDesignPreview.types';
import type { LabsGqlScanExportAssetFragment, LabsGqlScanWithAssetsFragment } from '@orthly/graphql-operations';
import { LabsGqlOrderDesignScanType } from '@orthly/graphql-schema';
import _ from 'lodash';

function filesFromAssets(
    assets: LabsGqlScanExportAssetFragment[],
    enableDraco: boolean,
): FirebasePreviewFileMultiWithType[] {
    return _.compact(
        assets.map((asset): FirebasePreviewFileMultiWithType | null => {
            let source: string | null;
            if (enableDraco) {
                source = asset.drc_path ?? asset.ply_path ?? asset.stl_path;
            } else {
                source = asset.ply_path ?? asset.stl_path;
            }
            if (!source) {
                return null;
            }

            return {
                source,
                name: source,
                type: LabsGqlOrderDesignScanType.Scans,
                image: asset.texture_path ?? undefined,
            };
        }),
    );
}

export function getScanPaths(
    scan: LabsGqlScanWithAssetsFragment,
    flags: { enableDraco: boolean },
): FirebasePreviewFileMultiWithType[] {
    const { enableDraco } = flags;
    return filesFromAssets(scan.assets ?? [], enableDraco);
}
