import type { ModelAppearance } from '../ModelAppearance/ModelAppearanceTypes';
import { useNewModelViewerStyles } from '../ModelViewer/NewModelViewer.hooks';
import { DesignComparisonContext } from './DesignComparisonContext';
import { BrowserAnalyticsClientFactory, OrderAnalyticsContext } from '@orthly/analytics/dist/browser';
import { IconToggleButton, DesignRestorativeIcon } from '@orthly/ui';
import path from 'path-browserify';
import React from 'react';

interface LoadPreviousDesignButtonProps {
    appearance: ModelAppearance;
    setDesiredPreviousDesignFiles?: React.Dispatch<React.SetStateAction<string[]>>;
}

export const LoadPreviousDesignButton: React.FC<LoadPreviousDesignButtonProps> = ({
    appearance,
    setDesiredPreviousDesignFiles,
}) => {
    const styles = useNewModelViewerStyles();

    const analyticsContext = React.useContext(OrderAnalyticsContext);
    const orderId = analyticsContext?.orderId;

    const designComparisonContext = React.useContext(DesignComparisonContext);
    const displayLocation = designComparisonContext?.displayLocation ?? undefined;

    const hasPastRestoratives = !!appearance.pastRestoratives.CAD.length;

    const handleClick = React.useCallback(() => {
        if (hasPastRestoratives) {
            setDesiredPreviousDesignFiles?.([]);
            return;
        }

        const desiredFiles = [...appearance.restoratives.CAD, ...appearance.restoratives.HeatMap].map(pma =>
            path.basename(pma.payloadModel.path),
        );
        setDesiredPreviousDesignFiles?.(desiredFiles);

        if (!orderId) {
            return;
        }

        BrowserAnalyticsClientFactory.Instance?.track('All - Portal - Design Comparison - Past Design Loaded', {
            $groups: { order: orderId },
            displayLocation,
        });
    }, [appearance.restoratives, setDesiredPreviousDesignFiles, orderId, displayLocation, hasPastRestoratives]);

    return setDesiredPreviousDesignFiles ? (
        <IconToggleButton
            tooltip={`${hasPastRestoratives ? 'Unload' : 'Load'} Previous Restorative Models`}
            active={hasPastRestoratives}
            className={styles.qcToolbarButton}
            onClick={handleClick}
        >
            <DesignRestorativeIcon />
        </IconToggleButton>
    ) : null;
};
