import { useChatAction } from '../../chat/chat-state/chat.actions';
import { useGoToPracticeScreen } from '../../labs/LabsUtils';
import { usePracticeInboxData } from '../state/PracticeInboxProvider';
import { InboxBodySection } from './InboxBodySection';
import { PracticeScreen } from '@orthly/dentin';
import type { IconName, Theme } from '@orthly/ui-primitives';
import { FlossPalette, createStyles, makeStyles, Grid, Button, Text } from '@orthly/ui-primitives';
import { useFeatureFlag } from '@orthly/veneer';
import React from 'react';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        itemWrap: {
            padding: 24,
            minHeight: '10vh',
        },
        item: {
            borderRadius: 16,
            background: FlossPalette.TAN,
            padding: '20px 24px',
        },
        button: {
            background: '#fff',
            minWidth: '40%',
            [theme.breakpoints.down('lg')]: {
                width: '100%',
            },
        },
        textSecondary: {
            color: theme.palette.text.secondary,
        },
    }),
);

interface InboxEmptySectionItemProps {
    onClick: () => void;
    title: string;
    buttonText: string;
    ButtonEndIcon?: IconName;
}

const InboxEmptySectionItem: React.FC<InboxEmptySectionItemProps> = props => {
    const { title, onClick, ButtonEndIcon, buttonText } = props;
    const classes = useStyles();
    return (
        <Grid item xs={12} md={6}>
            <Grid container className={classes.item}>
                <Grid container>
                    <Text variant={'body2'} className={classes.textSecondary}>
                        {title}
                    </Text>
                </Grid>
                <Grid container style={{ paddingTop: 32 }}>
                    <Button variant={'ghost'} endIcon={ButtonEndIcon} onClick={onClick} className={classes.button}>
                        {buttonText}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};

const InboxEmptySectionContent: React.FC = () => {
    const openZDAction = useChatAction('OPEN_ZD_CHAT');
    const { value: chatAvailability } = useFeatureFlag('chatAvailability');

    const goToOrders = useGoToPracticeScreen(PracticeScreen.orders);
    return (
        <InboxBodySection title={'Nothing to do today, just kick back and relax️ 😎'}>
            <Grid container spacing={4}>
                <InboxEmptySectionItem
                    title={'Looking for older orders?'}
                    onClick={() => goToOrders()}
                    buttonText={'All orders'}
                    ButtonEndIcon={'ChevronRight'}
                />
                <InboxEmptySectionItem
                    title={'Need a hand?'}
                    onClick={() => {
                        openZDAction(chatAvailability);
                    }}
                    buttonText={'Chat with us'}
                    ButtonEndIcon={'ChatIcon'}
                />
            </Grid>
        </InboxBodySection>
    );
};

export const InboxEmptySection: React.FC<{ activeOrderCount: number }> = props => {
    const { activeOrderCount } = props;
    const { totalItemsCount } = usePracticeInboxData();
    // If any inbox items or active tracking orders this should NOT show
    if (activeOrderCount + totalItemsCount > 0) {
        return null;
    }
    return <InboxEmptySectionContent />;
};
