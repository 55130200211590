import { getFlagVariantValueChecker } from '../../../../utils/utils';
import type { InboxUpsellItemConfigProps } from './InboxUpsellComponents';
import DandyRecommended from '@orthly/dentin/assets/images/hd-skus/Dandy_Recommended_Upsell.png';

export const HolidayRushUpsellConfig: InboxUpsellItemConfigProps = {
    assetName: '2023 Holiday Rush Orders',
    title: 'Holiday Closures & Recommended Order Dates',
    subtitle:
        'During the holiday season, we want to make sure orders are delivered in a timely manner when your practice is open.',
    ctaText: 'Learn More',
    destination: {
        url: 'https://help.meetdandy.com/hc/en-us/articles/20887942731277-When-do-I-need-to-place-my-order-to-have-it-by-the-holidays-',
    },
    imgSrc: DandyRecommended,
    imgStyle: { maxHeight: 100, bottom: 30, left: -16, position: 'relative' },
    skuType: 'holiday-rush',
    filterCriteria: null,
    featureFlagKey: 'upsellHolidayRush-202310',
    backgroundColor: 'PRIMARY_BACKGROUND',
    ctaButtonColor: 'STAR_GRASS',
    checkFeatureEnabled: getFlagVariantValueChecker('show_a'),
};
