import { AttachScansUtils } from './utils/AttachScans.util';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type {
    ModelPayloadItem,
    MainViewModelRef,
    MainViewCameraControlsRef,
    ModelAppearance,
    PayloadModelAppearance,
    DesignQcViewerProps,
} from '@orthly/dentin';
import { orderMaterialCanHaveLayers, NewModelViewer, MODEL_VIEWER_INITIAL_APPEARANCE } from '@orthly/dentin';
import type { ScanEntry } from '@orthly/forceps';
import { AttributeName, DcmGeometryInjector, deSuffixFilename } from '@orthly/forceps';
import type { LabsGqlLabOrderFragment, LabsGqlPatientScanFileFragment } from '@orthly/graphql-operations';
import { SimpleCheckbox } from '@orthly/ui';
import { FlossPalette, styled, stylesFactory, useScreenIsMobile, Tooltip, Button, Text } from '@orthly/ui-primitives';
import { getScanPayloadItems, useDesignModelPayloadsForScan } from '@orthly/veneer';
import _ from 'lodash';
import { basename } from 'path-browserify';
import React from 'react';

const useStyles = stylesFactory(() => ({
    label: {
        marginLeft: 0,
    },
}));

const Layout = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    gap: 24,
    [theme.breakpoints.down('lg')]: {
        flexDirection: 'column',
        gap: 20,
    },
}));

const ScanListLayout = styled('div')(({ theme }) => ({
    background: FlossPalette.TAN,
    padding: 24,
    borderRadius: 8,
    height: 'fit-content',
    width: '40%',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

const ScanTitleLayout = styled('div')({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 12,
    '&:not(:last-child)': {
        borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
        padding: '12px 0',
    },
});

const ScanTitleButtonsLayout = styled('div')({
    display: 'flex',
    alignItems: 'center',
});

const ScanPreviewLayout = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    width: 'calc(60% - 24px)',
    [theme.breakpoints.down('lg')]: {
        width: '100%',
    },
}));

const PreviewPromptLayout = styled('div')({
    display: 'flex',
    height: 600,
    alignItems: 'center',
    justifyContent: 'center',
});

const ScanPreviewWrapper = styled('div')({
    background: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: 8,
    width: '100%',
});

interface SelectScansActionProps {
    order: LabsGqlLabOrderFragment;
    scans: ScanEntry[];
    selectedScanFiles: string[];
    setSelectedScanFiles: React.Dispatch<React.SetStateAction<string[]>>;
    selectedScan: LabsGqlPatientScanFileFragment;
    setLoading: (loading: boolean) => void;
    setLoaderText: (text: string) => void;
    onBackAction?: () => void;
    onSelectPreviewFile?: (fileName: string) => void;
    previewingScan?: boolean;
    confirmNewOrder?: boolean;
}

const fileDoesMatchSTL = (file_name: string, expected_name: string, stlNameLookup: Map<string, string>) =>
    deSuffixFilename(file_name).toLowerCase() === stlNameLookup.get(expected_name);

const fileDoesMatchPLY = (file_name: string, expected_name: string) =>
    deSuffixFilename(file_name) === expected_name.split('_')[0];

const useScansAssetsMap = (
    orderId: string,
    selectedScanExport: string,
    scans: ScanEntry[],
    model_payload_items: ModelPayloadItem[],
) => {
    return React.useMemo(() => {
        const stlNameLookup = AttachScansUtils.getSTLNameLookupMap(scans);

        const trackScanValidationFailure = (reason: string) => {
            BrowserAnalyticsClientFactory.Instance?.track('Practice - Attach Scans - Selected Scan Failed Validation', {
                reason,
                scanExportId: selectedScanExport,
                $groups: { order: orderId },
            });
        };

        return scans.reduce<Map<string, { matchedItem: ModelPayloadItem | null; vertexMatch: boolean }>>(
            (acc: Map<string, { matchedItem: ModelPayloadItem | null; vertexMatch: boolean }>, scan: ScanEntry) => {
                if (!scan.file_name) {
                    return acc;
                }

                const matchedItem = model_payload_items.find((mpi: ModelPayloadItem) => {
                    const expectedLowercaseName = deSuffixFilename(basename(mpi.path));
                    const isSTL = mpi.path.endsWith('.stl');
                    return isSTL
                        ? fileDoesMatchSTL(scan.file_name ?? '', expectedLowercaseName, stlNameLookup)
                        : fileDoesMatchPLY(scan.file_name ?? '', expectedLowercaseName);
                });

                if (!matchedItem) {
                    trackScanValidationFailure('No matching model payload item found.');
                    acc.set(scan.file_name, { matchedItem: null, vertexMatch: false });
                    return acc;
                }

                const modelVertCount = matchedItem.model.geometry.getAttribute(AttributeName.Position).count;
                const dcm = DcmGeometryInjector.tryBuildDCM(scan.buffer?.toString() ?? '');

                if (!dcm) {
                    trackScanValidationFailure('Could not build a DCM from the selected scan entry.');
                    acc.set(scan.file_name, { matchedItem, vertexMatch: false });
                    return acc;
                }

                const binData = dcm.dcmObject.HPS.Packed_geometry.Binary_data;
                const dcmVertCount = (binData.CA ?? binData.CE)?.Vertices['@vertex_count'];

                if (dcmVertCount !== modelVertCount) {
                    trackScanValidationFailure('Vertex count mismatch.');
                    acc.set(scan.file_name, { matchedItem, vertexMatch: false });
                    return acc;
                }

                acc.set(scan.file_name, { matchedItem, vertexMatch: true });
                return acc;
            },
            new Map(),
        );
    }, [scans, model_payload_items, selectedScanExport, orderId]);
};

interface SelectScansModelViewerProps {
    previewedFile: string;
    order: LabsGqlLabOrderFragment;
    designQcConfig: DesignQcViewerProps;
    modelPayloadItems: ModelPayloadItem[];
}

export const SelectScansModelViewer: React.VFC<SelectScansModelViewerProps> = props => {
    const { previewedFile, order, designQcConfig, modelPayloadItems } = props;

    return (
        <>
            <ScanPreviewWrapper>
                {!previewedFile && (
                    <PreviewPromptLayout>
                        <Text variant={'body1'} color={'GRAY'}>
                            Select ‘Preview’ to view a scan from your intraoral scanning device
                        </Text>
                    </PreviewPromptLayout>
                )}
                {previewedFile && (
                    <NewModelViewer
                        orderMaterialsHaveLayers={orderMaterialCanHaveLayers(order)}
                        designQcConfig={designQcConfig}
                        model_payload_items={modelPayloadItems}
                        hideUi
                        disableHotKeys
                        showOrderScans
                        enableNewScanMeshMaterial
                        enable_qc_tools={{
                            enableAnatomyLayers: false,
                            enableCollisions: false,
                            enableCrossSections: false,
                            enableDynamicHeatmaps: false,
                            enableHeatmaps: false,
                            enableUndercutHeatmap: false,
                            enableTissuePressureHeatmap: false,
                            enableMarginLines: false,
                            enableDoctorMarginLines: false,
                            enableDoctorToothMarkings: false,
                            editMarginLines: false,
                            enableUndercutView: false,
                        }}
                    />
                )}
            </ScanPreviewWrapper>
            {previewedFile && (
                <Text variant={'caption'} color={'GRAY'} style={{ marginTop: 4 }}>
                    <Text variant={'caption'} medium color={'GRAY'}>
                        Previewing:
                    </Text>{' '}
                    {previewedFile}
                </Text>
            )}
        </>
    );
};

export const SelectScansAction: React.VFC<SelectScansActionProps> = props => {
    const {
        order,
        scans,
        selectedScan,
        selectedScanFiles,
        setSelectedScanFiles,
        setLoading,
        setLoaderText,
        onBackAction,
        onSelectPreviewFile,
        previewingScan,
        confirmNewOrder,
    } = props;
    const classes = useStyles();
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const [previewedFile, setPreviewedFile] = React.useState('');

    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);

    const { result: designPayloads, loading: designPayloadsLoading } = useDesignModelPayloadsForScan(selectedScan.id);

    const model_payload_items = React.useMemo<ModelPayloadItem[]>(() => {
        return getScanPayloadItems(_.compact(designPayloads) ?? []);
    }, [designPayloads]);

    const scanAssetsMap = useScansAssetsMap(order.id, selectedScan.id, scans, model_payload_items);
    const isMobile = useScreenIsMobile();

    React.useEffect(() => {
        setLoaderText(designPayloadsLoading ? 'One moment while we parse your scan files...' : '');
        setLoading(designPayloadsLoading);
    }, [setLoading, setLoaderText, designPayloadsLoading]);

    React.useEffect(() => {
        const stlNameLookup = AttachScansUtils.getSTLNameLookupMap(scans);

        setAppearance(currentAppearance => {
            const scanPayloads = currentAppearance.scans.map((plma: PayloadModelAppearance) => {
                const expectedName = deSuffixFilename(basename(plma.payloadModel.path));

                if (!expectedName) {
                    return plma;
                }

                const isPreviewedSTL = fileDoesMatchSTL(previewedFile, expectedName, stlNameLookup);
                const isPreviewedPLY = fileDoesMatchPLY(previewedFile, expectedName);

                return { ...plma, appearance: { ...plma.appearance, visible: isPreviewedSTL || isPreviewedPLY } };
            });

            return { ...currentAppearance, scans: scanPayloads };
        });
    }, [scans, previewedFile, selectedScanFiles]);

    const showScanList = React.useMemo(() => {
        return !isMobile || (!previewingScan && !confirmNewOrder);
    }, [isMobile, previewingScan, confirmNewOrder]);

    const showScanPreview = React.useMemo(() => {
        return !isMobile || (previewingScan && !confirmNewOrder);
    }, [isMobile, previewingScan, confirmNewOrder]);

    React.useEffect(() => {
        if (isMobile && !previewingScan) {
            setPreviewedFile('');
        }
    }, [isMobile, previewingScan]);

    return (
        <Layout>
            <ScanListLayout style={{ display: showScanList ? 'block' : 'none' }}>
                {onBackAction && (
                    <Button
                        variant={'ghost'}
                        onClick={props.onBackAction}
                        startIcon={'ChevronLeft'}
                        style={{ padding: 0, height: 'unset', marginBottom: 16 }}
                    >
                        Select a different case
                    </Button>
                )}
                <Text variant={'h6'} medium>
                    {AttachScansUtils.getScanTitle(selectedScan)}
                </Text>
                <Text variant={'body2'} color={'GRAY'} style={{ margin: '4px 0 8px 0' }}>
                    Created {AttachScansUtils.formatScanDate(selectedScan.created_at)}
                </Text>
                {scans.map(scan => {
                    if (!scan.file_name) {
                        return null;
                    }

                    const scanMatchObject = scanAssetsMap.get(scan.file_name);
                    const scanIsMatched = !!scanMatchObject?.matchedItem && scanMatchObject.vertexMatch;
                    const scanIsSelected = selectedScanFiles.includes(scan.file_name);
                    const scanIsPreviewed = scan.file_name === previewedFile;
                    const disableSelect = !scanIsMatched && !designPayloadsLoading;

                    return (
                        <Tooltip
                            key={scan.file_name}
                            title={disableSelect ? "Hmm, we're having a problem with this scan. Sorry about that." : ''}
                        >
                            <ScanTitleLayout>
                                <ScanTitleButtonsLayout>
                                    <SimpleCheckbox
                                        checked={scanIsSelected}
                                        setChecked={() => {
                                            const updateScans = scanIsSelected
                                                ? selectedScanFiles.filter(f => f !== scan.file_name)
                                                : selectedScanFiles.concat(scan.file_name ?? '');
                                            setSelectedScanFiles(updateScans);
                                        }}
                                        label={
                                            <Text
                                                variant={'body2'}
                                                medium
                                                color={disableSelect ? 'LIGHT_GRAY' : undefined}
                                            >
                                                {AttachScansUtils.formatScanFileName(scan.file_name)}
                                            </Text>
                                        }
                                        FormControlLabelProps={{
                                            style: { marginRight: 0 },
                                            classes: { label: classes.label },
                                        }}
                                        CheckboxProps={{
                                            disabled: disableSelect,
                                            style: { marginLeft: 0 },
                                            color: 'secondary',
                                        }}
                                    />
                                </ScanTitleButtonsLayout>
                                <ScanTitleButtonsLayout>
                                    <Button
                                        variant={'ghost'}
                                        onClick={() => {
                                            const file = previewedFile === scan.file_name ? '' : scan.file_name ?? '';
                                            setPreviewedFile(file);
                                            onSelectPreviewFile?.(file);
                                        }}
                                        disabled={disableSelect}
                                        style={{
                                            padding: 0,
                                            height: 'fit-content',
                                            marginLeft: 16,
                                            background: scanIsPreviewed
                                                ? FlossPalette.PRIMARY_BACKGROUND
                                                : 'transparent',
                                            color: scanIsPreviewed ? FlossPalette.STAR_GRASS_ACTIVE : undefined,
                                        }}
                                    >
                                        {scanIsPreviewed ? 'Previewing' : 'Preview'}
                                    </Button>
                                </ScanTitleButtonsLayout>
                            </ScanTitleLayout>
                        </Tooltip>
                    );
                })}
            </ScanListLayout>
            {showScanPreview && (
                <ScanPreviewLayout>
                    <SelectScansModelViewer
                        previewedFile={AttachScansUtils.formatScanFileName(previewedFile)}
                        order={order}
                        designQcConfig={{ appearance, setAppearance, modelRef, controlRef }}
                        modelPayloadItems={model_payload_items}
                    />
                </ScanPreviewLayout>
            )}
        </Layout>
    );
};
