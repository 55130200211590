import _ from 'lodash';

export function getCleanedModelPayloadLabel(modelName: string): string {
    // Some model names come in as LowerJawScan_lower, which is redundant and not needed.
    // Bitescans will return as Bitescan.
    // We really only care about "Lower Jaw" or "Bite" for these two examples.
    const modelNameWithoutVariant = modelName
        .replace(/(_upper|_lower|scan|Scan)/g, '')
        .replace(/prepreparation/gi, 'Pre-P');
    // removes underscores and dashes
    return _.startCase(modelNameWithoutVariant);
}
