import * as compressor from './Utility/Compressors';
import { crc32 } from './Utility/Crc32';
import type { BufferView } from './Utility/Util';
import { Vec } from './Utility/Vec';
import { WzAES } from './Utility/WzAES';
import { ZipCrypto } from './Utility/ZipCrypto';
import * as zip from './ZipFormat';

export interface IArchiveFile {
    name: string;
    body: string | BufferView | (() => Promise<BufferView>);
    timestamp?: Date;
    compression?: zip.CompressionType;
    password?: string;
}

// Common file chunk.
//
export abstract class ArchiveFile implements IArchiveFile {
    // Record and the associated password.
    //
    protected record: zip.FileRecord;
    protected currentPassword?: string;

    protected constructor(record: zip.FileRecord = new zip.FileRecord()) {
        this.record = record;
    }

    // Reduces to the basic descriptor.
    //
    async fetch(pw?: string) {
        return {
            name: this.name,
            body: await this.body(pw),
            timestamp: this.timestamp,
            compression: this.compression,
            ...(this.isCompressed && { password: this.password }),
        };
    }

    // Data wrappers.
    //
    abstract digest(): Promise<Vec>;
    abstract body(pw?: string): Promise<Vec>;

    // File traits.
    //
    get fileRecord(): Readonly<zip.FileRecord> {
        return this.record;
    }
    get name() {
        return this.record.fileName;
    }
    get isEncrypted() {
        return !!(this.record.flags & zip.Flag.Encrypted);
    }
    get isCompressed() {
        return this.record.compression !== zip.CompressionType.STORED;
    }
    get byteLength() {
        return this.record.uncompressedSize;
    }
    get timestamp() {
        return this.record.fileTime;
    }
    get checksum() {
        return this.record.crc;
    }
    get password() {
        return this.currentPassword;
    }
    get compression() {
        return this.record.compression;
    }

    // Export algorithms used by this chunk, all functions are pure.
    //
    calculateChecksum(data: Vec) {
        return crc32(data);
    }
    decryptDigest(data: Vec, password: string, crc: number) {
        if (this.isEncrypted) {
            if (this.record.compression === zip.CompressionType.WzAES) {
                return new WzAES(password).decryptRecord(data, crc, this.record);
            } else {
                return new ZipCrypto(password).decryptRecord(data, crc, this.record);
            }
        }
        return data;
    }
    encryptDigest(data: Vec, password: string, crc: number) {
        if (this.isEncrypted) {
            if (this.record.compression === zip.CompressionType.WzAES) {
                return new WzAES(password).encryptRecord(data, crc, this.record);
            } else {
                return new ZipCrypto(password).encryptRecord(data, crc);
            }
        }
        return data;
    }
    async decompressBody(data: Vec): Promise<Vec> {
        const cmp = compressor.find(this.record.compression);
        return this.isCompressed ? new Vec(await cmp.decompress(data.raw)) : data.clone();
    }
    async compressBody(data: Vec): Promise<Vec> {
        const cmp = compressor.find(this.record.compression);
        return this.isCompressed ? new Vec(await cmp.compress(data.raw)) : data.clone();
    }
}
