import { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { Assert } from '@orthly/runtime-utils';

export const getFirstAndLastNameFromFullName = (fullName: string) => {
    const trimmedFullName = fullName.trim();
    const [firstName, ...otherNameParts] = trimmedFullName.split(/\s+/);
    // result of split can only be an empty array if string and separator are both empty strings
    // thus it'll always have at least one element, inform TS of this
    Assert.assert(firstName !== undefined);
    return {
        firstName: firstName,
        lastName: otherNameParts.join(' ') || trimmedFullName,
    };
};

export const getStaffTitleFromPracticeRoles = (roles?: LabsGqlStaffRolePractice[] | null) => {
    if (roles?.includes(LabsGqlStaffRolePractice.PracticeDoctor)) {
        return 'Dr.';
    }
    return 'Staff';
};
