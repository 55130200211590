import { ArchiveFile } from './File';
import { U16, U32, U64, type BufferView } from './Utility/Util';
import { Vec } from './Utility/Vec';
import * as zip from './ZipFormat';

// Known passwords.
//
const KNOWN_PASSWORDS = [
    'G6H7fgThb6n458HazvpQ',
    'D5T/LQ(-JE6-T5.M*A03',
    '@KBIP9DM4E.5.-VM4ELG',
    '3sUpDaTeRconfiG',
    'antonnsi',
    'udglqivn2',
    'D44Mi\u{435}}',
    '@ScbZj/5Bf',
    'nye1PKrM',
] as const;

// Archive reader options.
//
export interface ReadOptions {
    // Gets the possible passwords given the file name.
    //
    getPassword?(fileName: string): ReadonlyArray<string>;
}

// Implement ArchiveFile given the read entry.
//
export class ArchiveReadable extends ArchiveFile {
    #digest: Vec;
    #options: ReadOptions;
    #bodyCache?: Vec;

    constructor(record: zip.FileRecord, digest: Vec, options: ReadOptions) {
        super(record);
        this.#digest = digest;
        this.#options = options;
    }

    async digest() {
        return this.#digest.clone();
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async body(pw?: string): Promise<Vec> {
        if (pw === undefined) {
            if (this.#bodyCache) {
                return this.#bodyCache;
            }
            let result: Vec | undefined;
            if (!this.isEncrypted || this.currentPassword !== undefined) {
                result = await this.body(this.currentPassword ?? '');
            } else {
                let lastErr: any = null;
                for (const pw of this.#options.getPassword?.(this.name) ?? KNOWN_PASSWORDS) {
                    try {
                        result = await this.body(pw);
                        break;
                    } catch (e) {
                        lastErr = e;
                    }
                }
                if (!result) {
                    throw lastErr ?? Error('Invalid password.');
                }
            }
            this.#bodyCache = result;
            return result;
        } else {
            const compressedBody = this.decryptDigest(this.#digest, pw, this.checksum);
            const body = await this.decompressBody(compressedBody);
            // eslint-disable-next-line sonarjs/no-collapsible-if
            if ((this.record.flags & zip.Flag.DescriptorUsedMask) !== 0 || this.checksum !== 0) {
                if (this.calculateChecksum(body) !== this.checksum) {
                    throw Error('Corrupt archive.');
                }
            }
            this.currentPassword = pw;
            return body;
        }
    }
}

// Reads extra fields structure given the raw bytes.
//
function* parseExtraFields(arr: Vec) {
    while (!arr.empty) {
        const id = arr.shift(U16);
        const len = arr.shift(U16);
        yield { id: id as zip.ExtensionId, data: arr.slice(0, len) };
        arr.erase(0, len);
    }
}

function readBodySync(vec: Vec, record: zip.FileRecord) {
    if (record.flags & zip.Flag.DescriptorUsedMask) {
        const offset = vec.findIndex(U32, (v, off) => {
            if (v === zip.DataDescriptor.MAGIC) {
                try {
                    if (vec.get(zip.DataDescriptor, off).compressedSize === off) {
                        return true;
                    }
                } catch {}
            }
            return false;
        });
        if (offset < 0) {
            throw Error('Data descriptor used mask is set, no valid entry found.');
        }
        const body = vec.subarray(0, offset);
        vec.erase(0, offset);
        const desc = vec.shift(zip.DataDescriptor);
        record.compressedSize = desc.compressedSize;
        record.crc = desc.crc;
        record.uncompressedSize = desc.uncompressedSize;
        return body;
    } else {
        return vec.subarray(0, record.compressedSize);
    }
}

// Reads each file in the zip.
//
function* readSync(vec: Vec, options: ReadOptions = {}) {
    while (vec.length > 4 && vec.get(U32, 0) === zip.FileRecord.MAGIC) {
        const record = vec.shift(zip.FileRecord);
        for (const f of parseExtraFields(new Vec(record.extraField))) {
            if (f.id === zip.ExtensionId.Zip64) {
                record.uncompressedSize = Number(f.data.shift(U64));
                record.compressedSize = Number(f.data.shift(U64));
            }
        }

        const body = readBodySync(vec, record);
        yield new ArchiveReadable(record, body, options);
    }
}

export function readArchive<T extends BufferView | Blob | AsyncIterable<BufferView>>(data: T, options?: ReadOptions) {
    let result;
    if ('byteOffset' in data) {
        result = readSync(new Vec(data), options);
    } else {
        result = Vec.consume(data).then(data => readSync(data, options));
    }
    return result as T extends BufferView ? Generator<ArchiveReadable> : Promise<Generator<ArchiveReadable>>;
}
