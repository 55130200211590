import { AttributeName } from './BufferAttributeConstants';
import type { AdjacencyMatrix, MeshConnectivityGraph } from './MeshConnectivityGraph';
import { ensureMeshIndex } from './MeshIndex';
import { THREE_CACHE } from './ThreeObjectCache';
import _ from 'lodash';
import * as THREE from 'three';
import type { MeshBVH } from 'three-mesh-bvh';

export type ThreeBufferAttribute = THREE.BufferAttribute | THREE.InterleavedBufferAttribute | undefined;

// Epsilon, the numerical tolerance
const EPS = 1e-6;

/**
 * Checks for intersections between two geometries
 * @param geometry One geometry to check. This geometry will have its BVH calculated if it is not already cached.
 * @param otherGeometry The other geometry to check for intersection(s) with `geometry`
 * @param ensureOtherBVH If true, the BVH of `otherGeometry` will be calculated if it is not already cached. It is not necessary for `otherGeometry`
 * to have a BVH, but it will speed up the intersection check if present.
 * @returns True if `geometry` and `otherGeometry` intersect; false otherwise
 */
export function intersects(
    geometry: THREE.BufferGeometry,
    otherGeometry: THREE.BufferGeometry,
    ensureOtherBVH: boolean = false,
): boolean {
    const index = ensureMeshIndex(geometry);

    ensureOtherBVH && ensureMeshIndex(otherGeometry);

    const identity = new THREE.Matrix4();
    return index.intersectsGeometry(otherGeometry, identity);
}

export interface Intersection {
    faceIndex1: number;
    faceIndex2: number;
    position: THREE.Vector3;
}

/**
 * Finds self intersections within a geometry
 *
 * This function checks for self intersections by raycasting along each edge in the geometry against the geometry
 * itself, ignoring hits with the selfsame edge.
 *
 * @param geometry The geometry to inspect
 * @param graph Specifies the vertex and face connectivity in `geometry`
 * @returns A list of the intersecting faces. An empty list is returned if the geometry does not intersect itself.
 */
export function findSelfIntersections(
    geometry: THREE.BufferGeometry,
    graph: MeshConnectivityGraph,
): Intersection[] | undefined {
    const inputs = prereqFindSelfIntersections(geometry);
    if (!inputs) {
        return;
    }

    const { positions, boundsTree, numFaces } = inputs;
    const { adjacencyVertexToVertex: v2V, adjacencyFacesToVertex: v2F } = graph;

    const posA = new THREE.Vector3();
    const posB = new THREE.Vector3();
    const vecAB = new THREE.Vector3();
    const ray = new THREE.Ray();
    const hashedFacesToPosition: Map<number, THREE.Vector3> = new Map();

    for (const indA of _.range(v2V.length)) {
        const neighborVertices = v2V[indA];
        if (!neighborVertices) {
            return;
        }

        for (const indB of neighborVertices) {
            if (indB <= indA) {
                // Only need to check each edge once.
                continue;
            }

            posA.fromBufferAttribute(positions, indA);
            posB.fromBufferAttribute(positions, indB);
            vecAB.subVectors(posB, posA);

            const lengthAB = vecAB.length();
            vecAB.normalize();

            ray.origin = posA;
            ray.direction = vecAB;

            const hits = boundsTree.raycast(ray, THREE.DoubleSide);
            const intersections = filterSelfIntersections(indA, indB, lengthAB, hits, v2F);
            if (!intersections) {
                return;
            }

            // Because we check each edge, it is possible for a face-face intersection to be reported twice (i.e. if two
            // edges of a face intersect another face). We use a recoverable hashing function to transform the index
            // pair into an integer and use a Set to remove duplicates.
            intersections.forEach(({ faceIndex1, faceIndex2, position }) => {
                const hashedFaces = hashIndexPair([faceIndex1, faceIndex2], numFaces);
                hashedFacesToPosition.set(hashedFaces, position);
            });
        }
    }

    return [...hashedFacesToPosition.entries()].map(([hash, position]) => {
        const [faceIndex1, faceIndex2] = recoverIndexPair(hash, numFaces);
        return { faceIndex1, faceIndex2, position };
    });
}

type NewType = {
    positions: NonNullable<ThreeBufferAttribute>;
    boundsTree: MeshBVH;
    numFaces: number;
};

/**
 * Helper for `findSelfIntersection` that checks prerequisites on the input
 * @param geometry The geometry to inspect
 * @returns Parsed inputs, if they are as expected
 */
function prereqFindSelfIntersections(geometry: THREE.BufferGeometry): NewType | undefined {
    const boundsTree = geometry.boundsTree;
    if (!boundsTree) {
        return;
    }

    const positions = geometry.getAttribute(AttributeName.Position);
    if (!positions) {
        return;
    }

    const index = geometry.getIndex();
    if (!index) {
        return;
    }

    const numFaces = index.array.length / 3;
    if (numFaces * numFaces > Number.MAX_SAFE_INTEGER) {
        // The index pair hashing will not work correctly in this case. This will only trip if the geometry has > ~95
        // million faces.
        return;
    }

    return { positions, boundsTree, numFaces };
}

/**
 * Helper for `findSelfIntersections` that filters raycast hits for true self intersections
 * @param indA Index of the first vertex of the edge being checked for intersection with the geometry
 * @param indB Index of the second vertex of the edge being checked for intersection with the geometry
 * @param lengthAB Length of the edge from vertex A to vertex B
 * @param hits Raycast hits with the geometry from the ray emanating from vertex A in the direction of vertex B
 * @param v2F Map from vertex to neighboring faces in the geometry
 * @returns A list of self-intersecting face pairs
 */
function filterSelfIntersections(
    indA: number,
    indB: number,
    lengthAB: number,
    hits: THREE.Intersection[],
    v2F: AdjacencyMatrix,
): Intersection[] | undefined {
    if (!hits.length) {
        return [];
    }

    const neighborFacesA = v2F[indA];
    const neighborFacesB = v2F[indB];
    if (!(neighborFacesA && neighborFacesB)) {
        return;
    }

    const neighborFacesAB = _.intersection(neighborFacesA, neighborFacesB);
    if (neighborFacesAB.length < 1 || neighborFacesAB.length > 2) {
        // It should not be possible for the edge AB to neighbor less than one face, because an edge, by definition, is
        // the line segment between two indices of a face. If the edge neighbors more than two faces, then we have a
        // non-manifold geometry.
        return;
    }

    const intersections: Intersection[] = [];
    for (const hit of hits) {
        const hitFaceIndex = hit.faceIndex;
        if (
            hitFaceIndex === undefined ||
            // Ignore false positive hits from the neighboring face(s)
            neighborFacesAB.includes(hitFaceIndex) ||
            // Ignore hits that coincide with vertex A - this could be with a face that includes vertex A
            hit.distance < EPS ||
            // Similarly, ignore hits that coincide with vertex B
            hit.distance > lengthAB - EPS
        ) {
            continue;
        }

        neighborFacesAB.forEach(faceInd =>
            intersections.push({ faceIndex1: faceInd, faceIndex2: hitFaceIndex, position: hit.point.clone() }),
        );
    }

    return intersections;
}

/**
 * Computes a number from which the original pair of indices can be recovered. This function is commutative, implying
 * that the order of the indices can not be recovered.
 * @param indexPair The pair of indices to hash
 * @param maxInd The maximum expected value of each element of `indexPair`
 * @returns The hash value
 */
function hashIndexPair(indexPair: [number, number], maxInd: number): number {
    const [ind1, ind2] = indexPair;
    return maxInd * Math.min(ind1, ind2) + Math.max(ind1, ind2);
}

/**
 * Recovers the index pair (without order preserved) from the hash
 * @param hash The value from which to extract the original indices
 * @param maxInd The maximum expected value of each of the two original indices
 * @returns The original index pair
 */
function recoverIndexPair(hash: number, maxInd: number): [number, number] {
    return [Math.floor(hash / maxInd), hash % maxInd];
}

export function lineIntersectPlaneAt(line: THREE.Line3, plane: { normal: THREE.Vector; constant: number }): number {
    const ns = plane.normal.dot(line.start);
    return (-plane.constant - ns) / (plane.normal.dot(line.end) - ns);
}

export function lineIntersectsTriangle(line: THREE.Line3, triangle: THREE.Triangle): boolean {
    return THREE_CACHE.autoAcquire(
        'plane',
        'vector3',
    )((plane, vec) => {
        triangle.getPlane(plane);
        const t = lineIntersectPlaneAt(line, plane);
        if (t < 0 || 1 < t || isNaN(t)) {
            return false;
        }

        return triangle.containsPoint(line.at(t, vec));
    });
}

export function planesIntersection(a: THREE.Plane, b: THREE.Plane, c: THREE.Plane, target: THREE.Vector3) {
    return THREE_CACHE.autoAcquire('matrix3')(mat => {
        // The intersection of the 3 planes is a point, `p`, such that:
        // abN·(p - a) = 0
        // bcN·(p - b) = 0
        // caN·(p - c) = 0
        // We can form a matrix with our normals as rows
        mat.set(
            a.normal.x,
            a.normal.y,
            a.normal.z,
            b.normal.x,
            b.normal.y,
            b.normal.z,
            c.normal.x,
            c.normal.y,
            c.normal.z,
        );
        // Now we have the system which we can solve:
        //       / abN·a \
        // M p = | bcN·b |
        //       \ caN·c /
        mat.invert();

        return target.set(-a.constant, -b.constant, -c.constant).applyMatrix3(mat);
    });
}
