import { isGuidedPreset, useGetSelectedWaxup } from '../GuidedWaxup.util';
import {
    DESKTOP_SCREEN_CONTAINER_STYLES,
    GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    PRESET_SIDEBAR_WIDTH,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED,
    TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED,
} from '../VisualConstants.util';
import { GuidedWaxupTimelineAndImagesSidebar } from '../components';
import type { CurrentWaxupModelViewerProps } from '../components/CurrentWaxupModelViewerControls';
import { GuidedWaxupNavArea } from '../components/GuidedWaxupNavArea';
import { HistoricalWaxupModelViewerAndControls } from '../components/HistoricalWaxupModelViewerControls';
import { HistoricalWaxupPresetInfo } from '../components/HistoricalWaxupPresetInfo';
import { PresetTabsSidebar } from '../components/PresetTabSidebar';
import { RevisionHistoryTabs } from '../components/RevisionHistoryTabs';
import { useGuidedWaxupContext } from '../state/GuidedWaxupContext';
import { useGuidedWaxupAction, useScrollToContainer } from '../state/GuidedWaxupState';
import type { AggregateToggle, PresetViewControllerFns } from '@orthly/dentin';
import { LabsGqlGuidedWaxupPresetType } from '@orthly/graphql-schema';
import { stylesFactory, Grid } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory<{ doesPreviousWaxupHavePresets: boolean; isSidebarExpanded?: boolean }>(() => ({
    root: {
        flexGrow: 1,
        position: 'relative',
        height: GUIDED_WAXUP_DESKTOP_SCREEN_HEIGHT,
    },
    bodyContainer: {
        width: ({ isSidebarExpanded }) =>
            `calc(100dvw - ${
                isSidebarExpanded
                    ? TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_EXPANDED
                    : TIMELINE_AND_IMAGES_SIDEBAR_WIDTH_COLLAPSED
            }px - 230px)`,
        // We add left padding to the model viewer container itself
        // so the margin here is just the width of the sidebar. If the
        // previous waxup doesn't have presets, there's no sidebar
        marginLeft: ({ doesPreviousWaxupHavePresets }) => (doesPreviousWaxupHavePresets ? PRESET_SIDEBAR_WIDTH : 0),
        ...DESKTOP_SCREEN_CONTAINER_STYLES,
    },
    revisionTabsContainer: {
        margin: '20px 20px 5px 20px',
        width: 'fit-content',
    },
}));

export interface HistoricalWaxupScreensProps extends CurrentWaxupModelViewerProps {
    setZoom: React.Dispatch<React.SetStateAction<number>>;
    selectedDesignRevisionNotes?: string;
    presetViewControls: PresetViewControllerFns;
    toggles: AggregateToggle[];
    isApprovedWaxup?: boolean;
}

export const HistoricalWaxupDesktopScreen: React.VFC<HistoricalWaxupScreensProps> = ({
    appearance,
    setAppearance,
    externalAppearanceApp,
    modelRef,
    controlRef,
    zoom,
    selectedDesignRevisionNotes,
    toggles,
}) => {
    const selectedWaxup = useGetSelectedWaxup();
    // If a previously rejected waxup was submitted through either the legacy flow,
    // or through the ops portal, then there should be no presets on the rejection
    // If a previous waxup was approved, then we want to show the presets
    const doesPreviousWaxupHavePresets = !!selectedWaxup?.annotations;
    const { selectedDesignRevisionId, selectedTab, setSelectedTab, isSidebarExpanded } = useGuidedWaxupContext();
    const classes = useStyles({ doesPreviousWaxupHavePresets, isSidebarExpanded });
    const setPresetAnnotation = useGuidedWaxupAction('SET_PRESET_ANNOTATION');
    const setAnnotation = React.useCallback(
        (annotatedImageUrls: string[]) => {
            setPresetAnnotation({ presetName: selectedTab, annotatedImageUrls });
        },
        [selectedTab, setPresetAnnotation],
    );
    const guidedPreset = isGuidedPreset(selectedTab);

    const scrollableRef = React.useRef<HTMLDivElement | null>(null);
    useScrollToContainer(scrollableRef);

    // If we navigate to a historical screen for a rejection that wasn't submitted with presets, then
    // we want to only show them the general view to not confuse the doctor with the preset views since
    // they didn't submit the rejection with those in mind originally. We also hide the preset sidebar below
    // in this scenario
    React.useEffect(() => {
        if (!doesPreviousWaxupHavePresets) {
            setSelectedTab(LabsGqlGuidedWaxupPresetType.GeneralView);
        }
    }, [doesPreviousWaxupHavePresets, setSelectedTab]);

    return (
        <Grid container className={classes.root}>
            {doesPreviousWaxupHavePresets && (
                <PresetTabsSidebar
                    toggles={toggles}
                    appearance={appearance}
                    setAppearance={setAppearance}
                    externalAppearanceApp={externalAppearanceApp}
                />
            )}
            <div className={classes.bodyContainer} key={selectedDesignRevisionId} ref={scrollableRef}>
                <RevisionHistoryTabs className={classes.revisionTabsContainer} />
                <HistoricalWaxupModelViewerAndControls
                    appearance={appearance}
                    setAppearance={setAppearance}
                    externalAppearanceApp={externalAppearanceApp}
                    modelRef={modelRef}
                    controlRef={controlRef}
                    zoom={zoom}
                    setAnnotation={setAnnotation}
                    selectedDesignRevisionNotes={selectedDesignRevisionNotes}
                    style={{ height: '500px', width: 'auto' }}
                />
                {guidedPreset && <HistoricalWaxupPresetInfo />}
                <div style={{ flexGrow: 1 }} />
                {guidedPreset && <GuidedWaxupNavArea />}
            </div>
            <GuidedWaxupTimelineAndImagesSidebar />
        </Grid>
    );
};
