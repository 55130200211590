import { areEqualOrSuperset, areEqualOrSupersetFields } from './item.util';
import { DenturesProductionType, DENTURES_ARCH_CHOICE_METAFIELD_ID } from '@orthly/items';
import type { IDentureItem } from '@orthly/items';
import _ from 'lodash';

// denture preference fields that should be ignored as they're handled by dedicated properties or not relevant to lab slip
export const DENTURES_PREFERENCE_FIELDS = [
    DENTURES_ARCH_CHOICE_METAFIELD_ID,
    'denture-production-type-order-item-meta',
    'denture-fabrication-method-order-item-meta',
    'denture-quantity-order-item-meta',
    'smile-style-order-item-meta',
    'denture-festooning-level-order-item-meta',
    'denture-handle-diastema-order-item-meta',
    'denture-correct-occlusal-scheme-order-item-meta',
    'denture-add-stippling-order-item-meta',
    'denture-add-post-dam-order-item-meta',
    'denture-bite-adjustment-order-item-meta',
    'denture-midline-correction-order-item-meta',
    'denture-implant-support-order-item-meta',
    'denture-collar-heights-order-item-meta',
    'denture-horseshoe-order-item-meta',
    'denture-exact-copy-order-item-meta',
    'denture-copy-change-notes-order-item-meta',
    'denture-add-metal-framework-order-item-meta',
    'denture-add-metal-mesh-order-item-meta',
    'denture-add-softliner-order-item-meta',
    'denture-cusil-gaskets-enabled-order-item-meta',
    'denture-cusil-gaskets-teeth-order-item-meta',
];

const TRY_IN_DENTURE_NAME = 'Monolithic Try In (Full Dentures)';
const WAX_RIM_DENTURE_NAME = 'Wax Bite Rim (Full Dentures)';
const FINAL_DENTURE_NAME = 'Full Dentures (Process to Final)';

/**
 * Determine if two Denture order items are similar enough that they can
 * be "merged" into a single item
 * From: mergedOrderItem.ts
 * @link https://github.com/orthly/orthlyweb/blob/d104eb2f0ade72629cfeb80b8fe74336044cfdc5/packages/veneer/src/components/OrderSummary/util/mergeOrderItems.ts#L82
 * Two items are duplicates if they are the same link/unit/material types throughout, with same preferences, just for different tooth numbers
 * In these cases we merge them together for display
 * @param itemA Denture order item to attempt to merge
 * @param itemB 2nd Denture item to attempt to merge with itemA
 * @returns Whether or not the two items are similar enough to be merged
 */
export function dentureItemsAreSimilar(itemA: IDentureItem, itemB: IDentureItem): boolean {
    const propertiesToCompare = ['denture_type', 'unit'];
    if (!_.isEqual(_.pick(itemA, propertiesToCompare), _.pick(itemB, propertiesToCompare))) {
        return false;
    }
    /*
     Preferences/Shades are "equal" if 1 item is an equal or superset of the other
     example: both have the same "base" shade, but item1 also has tissue shade
     itemAShade = [
     {name: 'base', value: 'B1', __typename: 'LabOrderItemShade'},
     {name: 'tissue', value: 'Original', __typename: 'LabOrderItemShade'}
     ]
     itemBShade = [{name: 'base', value: 'B1', __typename: 'LabOrderItemShade'}]
     */

    // Shades are different
    if (!areEqualOrSuperset(itemA.shades, itemB.shades)) {
        return false;
    }

    // Preferences are different
    if (!areEqualOrSupersetFields(itemA.preference_fields, itemB.preference_fields)) {
        return false;
    }
    return true;
}

/**
 * Get the display name of the Denture item
 * based on the production type, falling back to material if undefined
 * @param item the Denture item whose description to format
 * @returns Formatted name of the Denture item
 */
export function getDentureDescription(item: IDentureItem): string {
    switch (item.denture_production_type) {
        case DenturesProductionType.Final:
            return FINAL_DENTURE_NAME;
        case DenturesProductionType.TryIn:
            return TRY_IN_DENTURE_NAME;
        case DenturesProductionType.WaxRim:
            return WAX_RIM_DENTURE_NAME;
    }

    const material = item.unit.material;
    switch (material) {
        case 'Monolithic Try-in':
            return TRY_IN_DENTURE_NAME;
        case 'Wax Rim':
            return WAX_RIM_DENTURE_NAME;
        default:
            return FINAL_DENTURE_NAME;
    }
}
