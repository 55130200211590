import type { ExpandedItem } from './Submission.types';
import type { DandyFinishingSubmission } from '@orthly/shared-types';
import * as b64 from 'base64-arraybuffer';
import _ from 'lodash';
import * as THREE from 'three';

/**
 * Functions for converting between Dandy Finishing submission DTO and expanded (deserialized, with three.js objects
 * where applicable) types
 */

/**
 * Creates expanded item data (deserialized, with three.js objects where applicable)
 */
export function toExpandedItems(submission: DandyFinishingSubmission): ExpandedItem[] {
    return submission.items.map(item => ({
        modelElementId: item.modelElementId,
        vertexPositions: toVector3Array(item.vertexPositionsData),
        morphPoints: item.morphPoints.map(morphPoint => ({
            name: morphPoint.name,
            points: toVector3Array(morphPoint.pointsData),
        })),
        updatedSplines: item.updatedSplines.map(updatedSpline => ({
            name: updatedSpline.name,
            points: toVector3Array(updatedSpline.pointsData),
            pointsHash: updatedSpline.pointsHash,
        })),
        insertionAxis: new THREE.Vector3(item.insertionAxis.x, item.insertionAxis.y, item.insertionAxis.z),
    }));
}

/**
 * Creates a Dandy Finishing submission DTO
 */
export function toSerializedSubmission(items: ExpandedItem[]): DandyFinishingSubmission {
    return {
        items: items.map(item => {
            return {
                modelElementId: item.modelElementId,
                vertexPositionsData: encodeVector3Array(item.vertexPositions),
                morphPoints: item.morphPoints.map(morphPoint => ({
                    name: morphPoint.name,
                    pointsData: encodeVector3Array(morphPoint.points),
                })),
                updatedSplines: item.updatedSplines.map(updatedSpline => ({
                    name: updatedSpline.name,
                    pointsData: encodeVector3Array(updatedSpline.points),
                    pointsHash: updatedSpline.pointsHash,
                })),
                insertionAxis: _.pick(item.insertionAxis, ['x', 'y', 'z']),
            };
        }),
    };
}

function toFloat32Array(b64Data: string): Float32Array {
    return new Float32Array(b64.decode(b64Data));
}

function toVector3Array(b64Data: string): THREE.Vector3[] {
    const numbers = toFloat32Array(b64Data);
    const vectors: THREE.Vector3[] = [];
    for (let i = 0; i < numbers.length; i += 3) {
        vectors.push(new THREE.Vector3(numbers[i] ?? NaN, numbers[i + 1] ?? NaN, numbers[i + 2] ?? NaN));
    }
    return vectors;
}

function encodeVector3Array(vectors: THREE.Vector3[]): string {
    const numbers = new Float32Array(vectors.length * 3);
    vectors.forEach((v, i) => {
        const offset = i * 3;
        numbers[offset] = v.x;
        numbers[offset + 1] = v.y;
        numbers[offset + 2] = v.z;
    });
    return b64.encode(numbers.buffer);
}
