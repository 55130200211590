import type { LabsGqlOrderPreviewFragment } from '@orthly/graphql-operations';
import { useGetReviewQuery, useOrder } from '@orthly/graphql-react';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import { FlossPalette, stylesFactory, Grid, Tooltip, InfoIcon, Icon, Text } from '@orthly/ui-primitives';
import React from 'react';

const useStyles = stylesFactory(() => ({
    tooltipTextBox: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-star',
        paddingLeft: '10px',
        paddingRight: '10px',
    },
    tooltip: {
        maxWidth: '220px',
        borderRadius: 5,
        backgroundColor: FlossPalette.GRAY,
    },
    tooltipIcon: {
        fontSize: 15,
        width: 16,
        height: 16,
        color: FlossPalette.GRAY,
        cursor: 'help',
        marginBottom: -3,
    },
}));

export const ProductsReviewedTooltip: React.VFC<{ order: LabsGqlOrderPreviewFragment }> = ({ order }) => {
    const classes = useStyles();
    const { order: lab_order } = useOrder(order.id);
    const orderItems = OrderItemV2Utils.parseItems(lab_order?.items_v2 ?? []);
    const { data } = useGetReviewQuery({ variables: { order_id: order?.id ?? '' }, skip: !order?.id });
    const itemsReviewed = data?.getReview?.items.map(review_item => {
        const itemAttributes = [];
        for (const order_item of orderItems) {
            if (review_item.item_id === order_item.id) {
                const shortName = CartItemV2Utils.getFullDisplayName(order_item);
                itemAttributes.push(shortName, review_item.star_rating);
            }
        }
        return itemAttributes;
    });
    const unreviewedCount = order.items_v2.length - (data?.getReview?.items.length ?? 0);

    return (
        <Tooltip
            title={
                <Grid container className={classes.tooltipTextBox}>
                    <Grid item>
                        <Text variant={'body2'} medium color={`WHITE`}>
                            Thank you for reviewing:
                        </Text>
                    </Grid>
                    {itemsReviewed?.map(
                        (item, idx) =>
                            item && (
                                <Grid key={`${item}-${idx}`} item>
                                    <Text variant={'caption'} color={`WHITE`}>
                                        {typeof item[0] === 'string' && item[0].toUpperCase()}
                                        {item[1] !== null && (
                                            <Text variant={'caption'} medium color={`WHITE`}>
                                                : {item[1]}
                                                {
                                                    <Icon
                                                        icon={'Star'}
                                                        style={{ width: 15, height: 15, marginBottom: -3 }}
                                                    />
                                                }
                                            </Text>
                                        )}
                                    </Text>
                                </Grid>
                            ),
                    )}
                    {!!unreviewedCount && (
                        <Grid item style={{ marginTop: 15 }}>
                            <Text variant={'caption'} color={`WHITE`}>
                                You can contact us via chat to submit further feedback
                            </Text>
                        </Grid>
                    )}
                </Grid>
            }
            arrow
            placement={'left'}
            classes={{ tooltip: classes.tooltip }}
        >
            <InfoIcon className={classes.tooltipIcon} />
        </Tooltip>
    );
};
