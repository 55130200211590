import { SignUpFormContainer } from '../sign-up/SignUpFormContainer';
import { SignUpPageContainer } from '../sign-up/SignUpPageContainer';
import { SignUpPageLayout } from '../sign-up/SignUpPageLayout';
import { PracticeScreen } from '@orthly/dentin';
import { usePracticeEmailHasNotStartedOrCompletedSignUpLazyQuery } from '@orthly/graphql-react';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import { SimpleInput } from '@orthly/ui';
import { useScreenIsMobile, Grid, Text } from '@orthly/ui-primitives';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { useHistory } from 'react-router-dom';

function usePracticeEmailHasNotStartedOrCompletedSignUp(
    practiceHasNotStartedOrHasCompletedSignUp: () => void,
    practiceHasStartedAndNotCompletedSignUp: () => void,
) {
    return usePracticeEmailHasNotStartedOrCompletedSignUpLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
        onCompleted: data => {
            data?.practiceEmailHasNotStartedOrCompletedSignUp
                ? practiceHasNotStartedOrHasCompletedSignUp()
                : practiceHasStartedAndNotCompletedSignUp();
        },
    });
}

interface VerifyCodeInputProps {
    error?: string;
    code: string;
    onChangeAction: (value?: string) => void;
}

const SignUpLoggedOutVerifyCodeField: React.VFC<VerifyCodeInputProps> = props => {
    const { code, error, onChangeAction } = props;
    const isMobile = useScreenIsMobile();

    return (
        <Grid item>
            <Text variant={'body1'} medium>
                Dandy verification code
            </Text>
            <SimpleInput
                placeholder={'Enter your 10-character verification code'}
                label={''}
                flossInputConfig={{ backgroundColor: 'white', bordered: true }}
                onChange={onChangeAction}
                value={code}
                TextFieldProps={{
                    autoFocus: true,
                    helperText: error,
                    error: !!error,
                    style: { width: isMobile ? '100%' : '80%' },
                    InputProps: { style: { marginBottom: !!error ? 4 : 0 } },
                    inputProps: { 'data-test': 'verify-code-input' },
                    onBlur: () => onChangeAction(code),
                }}
            />
        </Grid>
    );
};

interface SignUpLoggedOutRootProps {
    originalLocation: LocationDescriptorObject;
}

export const SignUpLoggedOutRoot: React.VFC<SignUpLoggedOutRootProps> = ({ originalLocation }) => {
    const { onSubmitLogin, loading, loginError } = useStaffMemberLoginProps();
    const [showError, setShowError] = React.useState(true);
    const [code, setCode] = React.useState('');
    const [email, setEmail] = React.useState('');
    const calledOnce = React.useRef(false);
    const session = useSession();
    const history = useHistory();

    const onChangeAction = (value?: string) => {
        setShowError(false);
        setCode(value ?? '');
    };

    const onContinueAction = () => {
        setShowError(true);
        if (!loading && !!email && !!code) {
            onSubmitLogin({ email, password: code });
        }
    };

    const [triggerQuery] = usePracticeEmailHasNotStartedOrCompletedSignUp(
        () => history.push(`/${PracticeScreen.inbox}`),
        () => history.push(`/${PracticeScreen.sign_up_account}`),
    );

    React.useEffect(() => {
        /*
         * If the user is already logged in and the component has just mounted, redirect to inbox.
         */
        if (session?.organization_type && session?.user && !calledOnce.current) {
            return history.push(`/${PracticeScreen.inbox}`);
        }

        calledOnce.current = true;

        /*
         * If the user has logged in, continue to the next step.
         */
        if (session?.organization_type && session?.user) {
            return triggerQuery({ variables: { email: session.user.email } });
        }

        /*
         * If the user is not logged in and an email is provided, strip the email out from the search query.
         */
        const query = new URLSearchParams(history.location.search);
        if (query.has('email')) {
            setEmail(query.get('email') ?? '');
            return history.replace({ search: '' });
        }

        /*
         * If the user is not logged in and there is no email provided, redirect to the login page.
         */
        history.push('/login');
    }, [triggerQuery, history, originalLocation, session?.organization_type, session?.user]);

    return (
        <SignUpPageLayout>
            <SignUpPageContainer
                header={'Enter your verification code'}
                subheader={'Please enter the code in your welcome email to get started'}
            >
                <SignUpFormContainer buttonText={'Continue'} isSubmittable={!!code} onSubmitAction={onContinueAction}>
                    <SignUpLoggedOutVerifyCodeField
                        code={code}
                        onChangeAction={onChangeAction}
                        error={showError && !!loginError ? loginError : undefined}
                    />
                </SignUpFormContainer>
            </SignUpPageContainer>
        </SignUpPageLayout>
    );
};
