import { useCurrentProfile } from '../../../redux';
import { usePracticeInboxActiveOrders } from '@orthly/graphql-react';

export function useInboxActiveOrders() {
    const currentProfile = useCurrentProfile();
    const { orders, priority } = usePracticeInboxActiveOrders(currentProfile?.id);

    return {
        priority,
        orders,
    };
}
