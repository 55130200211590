import type { LabsGqlCartDeliveryOption } from '@orthly/graphql-schema';
import { FlossPalette, TruckIcon, Text, styled } from '@orthly/ui-primitives';
import moment from 'moment';
import React from 'react';

interface DateDisplayProps {
    option: LabsGqlCartDeliveryOption;
}

const Container = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr auto',
});

const DeliveryDetailDateRow = styled('div')({
    padding: 16,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
});

const DesignPreviewRow = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    backgroundColor: FlossPalette.DARK_TAN,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingTop: 8,
    height: 40,
    width: 'auto',
});

const IconWrapper = styled('div')({
    paddingLeft: 12,
    paddingTop: 3,
});

const StyledTruckIcon = styled(TruckIcon)({
    color: FlossPalette.GRAY,
    height: 18,
    width: 18,
});

const ApprovedDeliveryDateSpan = styled('span')({
    color: FlossPalette.SECONDARY_FOREGROUND,
    fontWeight: 900,
});

export const EstimatedDeliveryDateDisplay: React.VFC<DateDisplayProps> = ({ option }) => {
    const hasDesignPreview = option.design_preview_eta;

    return (
        <Container>
            <DeliveryDetailDateRow>
                <Text variant={'body2'} color={'BLACK'}>
                    {hasDesignPreview ? 'A design preview will be ready for approval by' : 'Estimated delivery'}
                </Text>
                <Text variant={'h6'} color={'SECONDARY_FOREGROUND'}>
                    {hasDesignPreview
                        ? moment(option.design_preview_eta).format('MMMM D')
                        : moment(option.delivery_date).format('dddd, MMMM D')}
                </Text>
            </DeliveryDetailDateRow>
            {hasDesignPreview && (
                <DesignPreviewRow>
                    <IconWrapper>
                        <StyledTruckIcon />
                    </IconWrapper>
                    <Text variant={'body2'} color={'GRAY'}>
                        {'Expect delivery by '}
                        <ApprovedDeliveryDateSpan data-test={'approved-delivery-date'}>
                            {moment(option.delivery_date).format('MMMM D')}
                        </ApprovedDeliveryDateSpan>
                        {' if design approved within 24h.'}
                    </Text>
                </DesignPreviewRow>
            )}
        </Container>
    );
};
