// We support several types of heat maps
export enum HeatMapType {
    Thickness = 1,
    Proximal = 2,
    Occlusal = 3,
    Clearance = 4,
    VertexDisplacement = 5,
    SurfaceDisplacement = 6,
    TissuePressure = 7,
    AlignmentDistance = 8,
    Undercut = 9,
    CementGap = 10,
}

export const ALL_SCULPT_HEATMAPS = [
    HeatMapType.Thickness,
    HeatMapType.Occlusal,
    HeatMapType.Proximal,
    HeatMapType.VertexDisplacement,
    HeatMapType.SurfaceDisplacement,
];
