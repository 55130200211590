import type { DcmGeometryInjector, TextureImage } from '../DcmFiles';
import type { ModelElement } from '../DesignCaseFile';
import type { ToothNumber } from '@orthly/items';
import type JsZip from 'jszip';
import type * as THREE from 'three';

export interface DesignProjectAsset {
    geom: THREE.BufferGeometry;
    imgTexture?: TextureImage;
    roleType: string;
    sourceFile: string;
    injector: DcmGeometryInjector;
    stub: string;
}

export interface DesignProjectCadAsset extends DesignProjectAsset {
    modelElementId: string | undefined;
    toothElements: { unn: ToothNumber; id: string }[];
}

// This mimics our expected file structure
// populated with what exists within them
export interface DesignZipContents<T> {
    cadFiles: [string, T][];
    anatomyFiles: [string, T][];
    scanFiles: [string, T][];
    extraData: [string, T][];
    upperMbScanFile?: [string, T];
    lowerMbScanFile?: [string, T];
    caseXmlFile: T;
    ddmtFile?: T;
    materialsFile?: T;
}

export interface DcmProcessingIntermediary {
    fileName: string;
    dcm: DcmGeometryInjector;
    modelElement?: ModelElement;
}

export interface AnatomyDcmProcessingIntermediary {
    fileName: string;
    dcm: DcmGeometryInjector;
    toothNumber?: ToothNumber;
}

export type ExistingFiles = DesignZipContents<JsZip.JSZipObject>;
