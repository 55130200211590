import { useMountingMatrix } from '../../../hooks/useMountingMatrix';
import { isGuidedPreset, usePresetSetup, useSubmitGuidedWaxupReview } from './GuidedWaxup.util';
import { GuidedWaxupNavArea } from './components/GuidedWaxupNavArea';
import type { CurrentWaxupScreenProps } from './screens/CurrentWaxupDesktopScreen';
import { CurrentWaxupDesktopScreen } from './screens/CurrentWaxupDesktopScreen';
import { CurrentWaxupMobileScreen } from './screens/CurrentWaxupMobileScreen';
import type { HistoricalWaxupScreensProps } from './screens/HistoricalWaxupDesktopScreen';
import { HistoricalWaxupDesktopScreen } from './screens/HistoricalWaxupDesktopScreen';
import { HistoricalWaxupMobileScreen } from './screens/HistoricalWaxupMobileScreen';
import { useGuidedWaxupContext } from './state/GuidedWaxupContext';
import { useGuidedWaxupAction, useGuidedWaxupSelector, useWaxupIsRejected } from './state/GuidedWaxupState';
import { getStartingGuidedWaxupStateForOrder } from './state/GuidedWaxupTypes';
import type { MainViewCameraControlsRef, MainViewModelRef, ModelAppearance } from '@orthly/dentin';
import {
    AGGREGATE_CONTROL_LABELS,
    MODEL_VIEWER_INITIAL_APPEARANCE,
    PracticeScreen,
    useAggregateToggles,
    useExternalAppearanceFilters,
    usePresetViewSetter,
} from '@orthly/dentin';
import { OrderDesignPreviewDesign_FragmentFragmentDoc, getFragmentData } from '@orthly/graphql-inline-react';
import type { LabsGqlSubmitWaxupReviewMutationVariables } from '@orthly/graphql-operations';
import {
    LabsGqlDesignOrderDoctorReviewStatus,
    LabsGqlGuidedWaxupPresetStatus,
    LabsGqlGuidedWaxupPresetType,
} from '@orthly/graphql-schema';
import { useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory } from 'react-router-dom';

const HistoricalWaxupsScreens: React.VFC<HistoricalWaxupScreensProps> = ({
    appearance,
    setAppearance,
    externalAppearanceApp,
    modelRef,
    controlRef,
    zoom,
    setZoom,
    presetViewControls,
    toggles,
}) => {
    const { selectedDesignFragment, selectedTab } = useGuidedWaxupContext();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const guidedPreset = isGuidedPreset(selectedTab);

    const selectedDesign = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment);
    const isApprovedWaxup = selectedDesign?.doctor_review?.status === LabsGqlDesignOrderDoctorReviewStatus.Approved;

    // Always show the nav area if the selected revision was a rejection, but if vieweing an approved waxup,
    // we hide the nav area when viewing the general view preset since there's no rejection note to view
    const showNavArea = !isApprovedWaxup || guidedPreset;

    usePresetSetup({ appearance, controlRef, presetViewControls, setAppearance, setZoom });

    return isMobile ? (
        <>
            <HistoricalWaxupMobileScreen
                appearance={appearance}
                setAppearance={setAppearance}
                externalAppearanceApp={externalAppearanceApp}
                modelRef={modelRef}
                controlRef={controlRef}
                zoom={zoom}
                setZoom={setZoom}
                selectedDesignRevisionNotes={selectedDesign?.doctor_review?.notes ?? undefined}
                presetViewControls={presetViewControls}
                toggles={toggles}
                isApprovedWaxup={isApprovedWaxup}
            />
            {showNavArea && <GuidedWaxupNavArea />}
        </>
    ) : (
        <HistoricalWaxupDesktopScreen
            appearance={appearance}
            setAppearance={setAppearance}
            externalAppearanceApp={externalAppearanceApp}
            modelRef={modelRef}
            controlRef={controlRef}
            zoom={zoom}
            setZoom={setZoom}
            presetViewControls={presetViewControls}
            selectedDesignRevisionNotes={selectedDesign?.doctor_review?.notes ?? undefined}
            toggles={toggles}
            isApprovedWaxup={isApprovedWaxup}
        />
    );
};

const CurrentWaxupScreens: React.VFC<CurrentWaxupScreenProps> = ({
    submit,
    appearance,
    setAppearance,
    externalAppearanceApp,
    modelRef,
    controlRef,
    zoom,
    setZoom,
    presetViewControls,
    setAnnotation,
    toggles,
}) => {
    const isMobile = useScreenIsMobileOrVerticalTablet();
    usePresetSetup({ appearance, controlRef, presetViewControls, setAppearance, setZoom });

    return isMobile ? (
        <>
            <CurrentWaxupMobileScreen
                appearance={appearance}
                submit={submit}
                setAppearance={setAppearance}
                externalAppearanceApp={externalAppearanceApp}
                modelRef={modelRef}
                controlRef={controlRef}
                zoom={zoom}
                setZoom={setZoom}
                presetViewControls={presetViewControls}
                setAnnotation={setAnnotation}
                toggles={toggles}
            />
            <GuidedWaxupNavArea submit={submit} />
        </>
    ) : (
        <CurrentWaxupDesktopScreen
            submit={submit}
            appearance={appearance}
            setAppearance={setAppearance}
            externalAppearanceApp={externalAppearanceApp}
            modelRef={modelRef}
            controlRef={controlRef}
            zoom={zoom}
            setZoom={setZoom}
            presetViewControls={presetViewControls}
            setAnnotation={setAnnotation}
            toggles={toggles}
        />
    );
};

export const GuidedWaxupScreenWrapper: React.VFC = () => {
    const { order, selectedDesignRevisionAlreadyReviewed, teeth, refetch, selectedTab, selectedDesignFragment } =
        useGuidedWaxupContext();
    const history = useHistory();
    const isMobile = useScreenIsMobileOrVerticalTablet();
    const patchState = useGuidedWaxupAction('PATCH_STATE');
    const setPresetAnnotation = useGuidedWaxupAction('SET_PRESET_ANNOTATION');
    const waxupRejected = useWaxupIsRejected();
    const presets = useGuidedWaxupSelector(s => s.presets);
    const generalRejectionNote =
        useGuidedWaxupSelector(s => s.presets[LabsGqlGuidedWaxupPresetType.GeneralView]?.notes) ?? '';
    const design = getFragmentData(OrderDesignPreviewDesign_FragmentFragmentDoc, selectedDesignFragment);

    const setAnnotation = React.useCallback(
        (annotatedImageUrls: string[]) => {
            setPresetAnnotation({ presetName: selectedTab, annotatedImageUrls });
        },
        [selectedTab, setPresetAnnotation],
    );

    // this state manages all the visibility, color, heatmaps and other stuff
    // the setAppearance hook in the (prevAppearance => newAppearance) form is how you want to use it
    const [appearance, setAppearance] = React.useState<ModelAppearance>(MODEL_VIEWER_INITIAL_APPEARANCE);
    const externalAppearanceApp = useExternalAppearanceFilters(setAppearance);
    const modelRef: MainViewModelRef = React.useRef(undefined);
    const controlRef: MainViewCameraControlsRef = React.useRef(null);
    const [zoom, setZoom] = React.useState<number>(isMobile ? 4 : 6);
    // to avoid having to change existing logic within the useAggregateToggles hook being used here, just exclude Scans and Restorations
    // toggles
    const unfilteredToggles = useAggregateToggles(appearance, setAppearance);

    const toggles = unfilteredToggles.filter(
        toggle =>
            toggle.label !== AGGREGATE_CONTROL_LABELS.SCANS && toggle.label !== AGGREGATE_CONTROL_LABELS.RESTORATIONS,
    );

    const mountingMatrix = useMountingMatrix(design);

    const presetViewControls = usePresetViewSetter(mountingMatrix, controlRef, appearance);
    const { submit } = useSubmitGuidedWaxupReview(async () => {
        await refetch();
        history.replace(`/${PracticeScreen.orders}/${order.id}`);
    });

    const dataToSave: LabsGqlSubmitWaxupReviewMutationVariables = {
        order_id: order.id,
        rejection: waxupRejected
            ? {
                  notes: generalRejectionNote,
                  presets: Object.entries(presets).map(([type, info]) => ({
                      preset_type: type as LabsGqlGuidedWaxupPresetType,
                      preset_status:
                          type === LabsGqlGuidedWaxupPresetType.GeneralView
                              ? info.status ?? LabsGqlGuidedWaxupPresetStatus.Rejected
                              : info.status ?? LabsGqlGuidedWaxupPresetStatus.Skipped,
                      annotated_image_urls: info.annotatedImageUrls ?? [],
                      notes: info.notes ?? '',
                  })),
              }
            : null,
    };

    React.useEffect(() => {
        patchState(getStartingGuidedWaxupStateForOrder(order, teeth));
    }, [order, teeth, patchState]);

    if (!selectedDesignFragment) {
        return null;
    }

    return selectedDesignRevisionAlreadyReviewed ? (
        <HistoricalWaxupsScreens
            appearance={appearance}
            setAppearance={setAppearance}
            externalAppearanceApp={externalAppearanceApp}
            modelRef={modelRef}
            controlRef={controlRef}
            zoom={zoom}
            setZoom={setZoom}
            presetViewControls={presetViewControls}
            toggles={toggles}
        />
    ) : (
        <CurrentWaxupScreens
            submit={() => submit(dataToSave)}
            appearance={appearance}
            setAppearance={setAppearance}
            externalAppearanceApp={externalAppearanceApp}
            modelRef={modelRef}
            controlRef={controlRef}
            zoom={zoom}
            setZoom={setZoom}
            presetViewControls={presetViewControls}
            setAnnotation={setAnnotation}
            toggles={toggles}
        />
    );
};
