import type { QcHeatmapRange } from '../ColorRamp';
import { MODEL_GRAY_COLOR } from './Colors';
import { createOcclusalNoColorHeatmapShader } from './OcclusalNoColorShader';
import { createOcclusalHeatmapShader } from './OcclusalShader';
import type { DandyShaderMaterialParameters } from './dandyShaderMaterial';
import { DandyShaderMaterial } from './dandyShaderMaterial';
import { extend } from '@react-three/fiber';
import React from 'react';
import * as THREE from 'three';

extend({ DandyShaderMaterial });

export type ScanMeshShaderMaterialPrepProps = {
    heatMapRange: QcHeatmapRange;
    showHeatmap?: boolean;
    enableVertexColors?: boolean;
    enableColoring?: boolean;
    opacity?: number;
    colorMap?: THREE.Texture;
    // Diffuse color to use when vertex colors are disabled
    color?: THREE.Color;
};

const _DEFAULT_PREP_SCAN_MESH_SHADER_PROPS = {
    showHeatmap: false,
    opacity: 1.0,
    color: MODEL_GRAY_COLOR,
};

export const ScanMeshShaderMaterialPrep: React.FC<ScanMeshShaderMaterialPrepProps> = props => {
    const { heatMapRange, showHeatmap, enableVertexColors, enableColoring, opacity, colorMap, color } = {
        ..._DEFAULT_PREP_SCAN_MESH_SHADER_PROPS,
        ...props,
    };

    // Choose imageTexture vs vertexColor
    const visProps = {
        transparent: opacity < 1.0,
        opacity: opacity,
    };

    let shader: THREE.ShaderMaterialParameters | undefined = undefined;
    if (enableColoring) {
        shader = createOcclusalHeatmapShader({
            showHeatmap: showHeatmap,
            heatMapRange,
            opacity: opacity,
            map: colorMap,
        });
    } else {
        shader = createOcclusalNoColorHeatmapShader({
            showHeatmap: showHeatmap,
            heatMapRange,
            opacity: opacity,
            color,
        });
    }

    const shaderParams: DandyShaderMaterialParameters = {
        side: THREE.DoubleSide,
        clipping: true,
        ...shader,
        ...visProps,
        vertexColors: enableVertexColors,
    };

    return <dandyShaderMaterial args={[shaderParams]} />;
};
