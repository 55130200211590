import { useStaffMemberMatcher } from '../../../../redux';
import { useOpenOrderDetailPage } from '../../../../screens/labs/LabsUtils';
import { usePracticeInboxData } from '../../../inbox/state/PracticeInboxProvider';
import type { CtaAnalyticsEventData } from '@orthly/analytics/dist/browser';
import { useAnalyticsOnClick } from '@orthly/analytics/dist/browser';
import { PracticeScreen } from '@orthly/dentin';
import type { LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import { SidebarChatIcon, TrackIcon, WarningIcon } from '@orthly/ui';
import type { SvgIconProps } from '@orthly/ui-primitives';
import {
    FlossPalette,
    createStyles,
    Grid,
    makeStyles,
    Divider,
    ChevronRight,
    RefreshIcon,
    Button,
    Text,
} from '@orthly/ui-primitives';
import _ from 'lodash';
import React from 'react';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(() =>
    createStyles({
        attentionArea: {
            marginTop: 32,
            marginBottom: 32,
            padding: 32,
            backgroundColor: FlossPalette.WHITE,
            borderRadius: 16,
            border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        },
        warningIconWrapper: {
            height: 48,
            width: 48,
            padding: 4,
            borderRadius: '50%',
            backgroundColor: FlossPalette.ATTENTION,
            color: FlossPalette.WHITE,
            textAlign: 'center',
        },
    }),
);

interface AlertItem {
    title: string;
    subtitle: string;
    buttonText: string;
    createdAt: string;
    onClick: () => void;
}

const getTitleForActionableHoldOrders = (actionableHoldOrder: LabsGqlLabOrderFragment): string => {
    const patient = `${actionableHoldOrder.patient.first_name} ${actionableHoldOrder.patient.last_name}`;
    const defaultTitle = `An order for ${patient} needs your attention`;
    const activeTask = actionableHoldOrder.fulfillment_workflow.active_task;
    if (!activeTask) {
        return defaultTitle;
    }
    switch (activeTask.__typename) {
        case 'DrReviewHoldWorkflowTask':
            return `We need your input to clear a hold on ${patient}'s order`;
        case 'ResolveScanRejectionWorkflowTask':
            return `We found an issue with ${patient}'s scan`;
        default:
            return defaultTitle;
    }
};

const viewWaxupEventData: CtaAnalyticsEventData = {
    AssetLocation: 'Post checkout',
    AssetCTAText: 'View Waxup',
    AssetName: 'Waxup notification',
    AssetType: 'banner',
    AssetVersion: 'v1.0',
};
const resolveIssueEventData: CtaAnalyticsEventData = {
    AssetLocation: 'Post checkout',
    AssetCTAText: 'Resolve issue',
    AssetName: 'Order issue notification',
    AssetType: 'banner',
    AssetVersion: 'v1.0',
};

const useAlertItems = (): AlertItem[] => {
    const { pendingWaxupApprovals, actionableHoldOrders } = usePracticeInboxData();
    const shouldDisplay = useStaffMemberMatcher();
    const openOrderPage = useOpenOrderDetailPage();
    const onClickWaxup = useAnalyticsOnClick('Button Clicked', viewWaxupEventData, openOrderPage);
    const onClickResolveIssue = useAnalyticsOnClick('Button Clicked', resolveIssueEventData, openOrderPage);
    const heldOrderItems = React.useMemo(() => {
        return actionableHoldOrders
            .filter((order): order is LabsGqlLabOrderFragment => !!order && shouldDisplay(order.doctor_preferences_id))
            .map(aho => ({
                title: getTitleForActionableHoldOrders(aho),
                subtitle: '',
                buttonText: resolveIssueEventData.AssetCTAText,
                createdAt: aho.created_at,
                onClick: () => {
                    onClickWaxup(aho.id);
                },
            }));
    }, [actionableHoldOrders, shouldDisplay, onClickWaxup]);
    const waxupApprovalItems = React.useMemo(() => {
        return pendingWaxupApprovals
            .filter(pwa => shouldDisplay(pwa.doctor_preferences_id))
            .map(pwa => ({
                title: `A waxup for ${pwa.patient.first_name} ${pwa.patient.last_name} needs your approval'`,
                subtitle: '',
                buttonText: viewWaxupEventData.AssetCTAText,
                createdAt: pwa.created_at,
                onClick: () => {
                    onClickResolveIssue(pwa.id);
                },
            }));
    }, [pendingWaxupApprovals, shouldDisplay, onClickResolveIssue]);
    return React.useMemo(
        () => _.sortBy([...waxupApprovalItems, ...heldOrderItems], a => a.createdAt),
        [waxupApprovalItems, heldOrderItems],
    );
};

interface EmptyAlertBlockItemProps {
    Icon: React.ComponentType<SvgIconProps>;
    title: string;
    description: string;
}

const EmptyAlertBlockItem: React.FC<EmptyAlertBlockItemProps> = props => {
    const { title, description, Icon } = props;
    return (
        <Grid item xs={3} style={{ textAlign: 'center' }}>
            <Icon style={{ color: FlossPalette.GRAY }} />
            <Text medium variant={'body2'}>
                {title}
            </Text>
            <Text variant={'body2'} style={{ color: FlossPalette.GRAY }}>
                {description}
            </Text>
        </Grid>
    );
};

const goToPortalEventData: CtaAnalyticsEventData = {
    AssetCTAText: 'Return to the Portal',
    AssetVersion: 'v1.0',
    AssetType: 'button',
    AssetName: 'Return to Portal',
    AssetLocation: 'Post checkout',
};

const EmptyAlertsPostCheckoutBlock: React.VFC = () => {
    const classes = useStyles();
    const history = useHistory();
    const emptyAlertBlockItemProps: EmptyAlertBlockItemProps[] = [
        {
            Icon: TrackIcon,
            title: 'Track your orders',
            description: 'View order status and get the latest track & trace information',
        },
        {
            Icon: RefreshIcon,
            title: 'Start a refabrication',
            description: 'No need to give us a call. Initiate a refabrication in seconds',
        },
        {
            Icon: SidebarChatIcon,
            title: 'Chat with us',
            description: "Have questions about this order or anything else? We're just one click away",
        },
    ];
    const goToPortal = useAnalyticsOnClick('Button Clicked', goToPortalEventData, () =>
        history.push(`/${PracticeScreen.orders}`),
    );
    return (
        <div className={classes.attentionArea}>
            <Text variant={'h5'} style={{ fontWeight: 'bold', marginBottom: 16 }}>
                Did you know you can use the portal to do the following:
            </Text>
            <Grid container spacing={1} justifyContent={'space-between'} alignItems={'stretch'}>
                {emptyAlertBlockItemProps.map((props, index) => (
                    <React.Fragment key={index}>
                        <EmptyAlertBlockItem {...props} key={index} />
                        {index !== emptyAlertBlockItemProps.length - 1 && (
                            <Divider orientation={'vertical'} style={{ height: 'inherit', margin: '16px 0' }} />
                        )}
                    </React.Fragment>
                ))}
            </Grid>
            <Button variant={'secondary'} fullWidth style={{ marginTop: 16 }} onClick={goToPortal}>
                {goToPortalEventData.AssetCTAText}
            </Button>
        </div>
    );
};

const viewMoreEventData: CtaAnalyticsEventData = {
    AssetCTAText: 'View more notifications',
    AssetVersion: 'v1.0',
    AssetType: 'button',
    AssetName: 'View Notifications',
    AssetLocation: 'Post checkout',
};

export const PostCheckoutAlertArea: React.FC = () => {
    const history = useHistory();
    const classes = useStyles();
    const alertItems = useAlertItems();
    const openInbox = useAnalyticsOnClick('Button Clicked', viewMoreEventData, () => history.push(`/inbox`));
    if (alertItems.length === 0) {
        return <EmptyAlertsPostCheckoutBlock />;
    }
    const topThreeItems = alertItems.slice(0, 3);
    return (
        <div className={classes.attentionArea}>
            <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                    <div className={classes.warningIconWrapper}>
                        <WarningIcon fontSize={'large'} />
                    </div>
                </Grid>
                <Grid item>
                    <Text variant={'h5'}>{alertItems.length} items need your attention</Text>
                    <Text variant={'body2'}>Please take action as soon as possible to prevent delays.</Text>
                </Grid>
            </Grid>
            {topThreeItems.map((x, i) => (
                <Grid container spacing={2} alignItems={'center'} justifyContent={'space-between'} key={i}>
                    <Grid item xs={9}>
                        <Text variant={'body2'} style={{ fontWeight: 'bold' }}>
                            {x.title}
                        </Text>
                        <Text variant={'body2'} style={{ color: FlossPalette.GRAY }}>
                            {x.subtitle}
                        </Text>
                    </Grid>
                    <Grid item xs={3}>
                        <Button variant={'ghost'} onClick={x.onClick} endIcon={'ChevronRight'}>
                            {x.buttonText}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <div
                            style={{
                                margin: '0 2px',
                                borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                display: i === topThreeItems.length - 1 ? 'none' : 'block',
                            }}
                        />
                    </Grid>
                </Grid>
            ))}
            {alertItems.length > 3 && (
                <Button variant={'secondary'} fullWidth={true} onClick={openInbox}>
                    View {alertItems.length - 3} more notifications <ChevronRight />
                </Button>
            )}
        </div>
    );
};
