/* eslint-disable max-lines, max-lines-per-function */
import type { MainViewCameraControlsRef } from '../ModelViewer/ModelViewerTHREETypes';
import { ArrowMesh } from '../misc/ArrowMesh';
import type { CrossSectionPlane, CrossSectionPlaneSetter } from './CrossSectionData';
import { CrossSectionMathUtils } from './CrossSectionData';
import { CrossSectionRotationControl, CrossSectionRotationGizmoPlacement } from './CrossSectionRotationControl';
import { useThree } from '@react-three/fiber';
import React from 'react';
import type { Group } from 'three';
import { Line3, Plane, Quaternion, Vector3, Vector2 } from 'three';

export enum CONTROL_TOOL {
    DRAG_NORMAL = 'drag_normal',
    DRAG_FREE = 'drag_free',

    ROTATE_TOP = 'rotate_top',
    ROTATE_BOTTOM = 'rotate_bottom',
    ROTATE_FRONT = 'rotate_front',
    ROTATE_BACK = 'rotate_back',
}

/**
 * Lower the number - higher the priority
 * @see setToolStateWithPriority
 */
const TOOL_PRIORITIES: Record<CONTROL_TOOL, number> = {
    drag_free: 1,
    drag_normal: 2,
    rotate_top: 3,
    rotate_bottom: 3,
    rotate_front: 3,
    rotate_back: 3,
};

function useMouseEventsCallbacks(
    tool: CONTROL_TOOL,
    onActiveChange: (tool: CONTROL_TOOL, active: boolean) => void,
    onHoverChange: (tool: CONTROL_TOOL, isHover: boolean) => void,
) {
    return {
        active: React.useCallback(a => onActiveChange(tool, a), [onActiveChange, tool]),
        hover: React.useCallback(h => onHoverChange(tool, h), [onHoverChange, tool]),
    };
}

/**
 * There should be only one or none highlighted tool
 * (tool which we have mouse hovered) and active tool
 *
 * This function manages the state for hovered and active tools.
 *
 * First, it resets (sets to undefined) state if previously active tool
 * gets deactivated.
 *
 * And second, for *active* or hovered tool it sets the state to the tool
 * with highest priority.
 */
function setToolStateWithPriority(tool: CONTROL_TOOL, newState: boolean, currentState: CONTROL_TOOL | undefined) {
    // Deactivate active tool
    if (currentState === tool && !newState) {
        return undefined;
    }

    // Activate tool according to tools priority
    if (newState) {
        // Simple case if nothing is active
        if (currentState === undefined) {
            return tool;
        }

        // Check tool priority
        const activeToolPriority = TOOL_PRIORITIES[currentState];
        const newToolPriority = TOOL_PRIORITIES[tool];

        if (newToolPriority < activeToolPriority) {
            return tool;
        }
    }

    return currentState;
}

interface CrossSectionDragNormalControlProps {
    visible: boolean;
    highlighted?: boolean;
    orientation: Quaternion;
    onHoverChange: (mouseIsHover: boolean) => void;
}
/**
 * This component draws an arrow gizmo
 *
 * @component
 */
const DragNormalGizmo: React.VFC<CrossSectionDragNormalControlProps> = props => {
    const { visible, highlighted, orientation, onHoverChange } = props;

    return (
        <ArrowMesh
            renderOrder={3}
            visible={visible}
            quaternion={orientation}
            onPointerOver={() => {
                onHoverChange(true);
            }}
            onPointerOut={() => {
                onHoverChange(false);
            }}
        >
            <meshBasicMaterial
                depthTest={false}
                depthWrite={false}
                attach={'material'}
                color={highlighted ? '#eee' : '#999'}
            />
        </ArrowMesh>
    );
};

interface CrossSectionDragFreeControlProps {
    visible: boolean;
    highlighted?: boolean;
    onHoverChange: (mouseIsHover: boolean) => void;
}
const DragFreeGizmo: React.VFC<CrossSectionDragFreeControlProps> = props => {
    const { visible, highlighted, onHoverChange } = props;

    return (
        <mesh
            renderOrder={3}
            visible={visible}
            onPointerOver={() => {
                onHoverChange(true);
            }}
            onPointerOut={() => {
                onHoverChange(false);
            }}
        >
            <sphereBufferGeometry attach={'geometry'} args={[1, 16, 16]} />
            <meshBasicMaterial
                depthTest={false}
                depthWrite={false}
                attach={'material'}
                color={highlighted ? '#FF0000' : '#990000'}
            />
        </mesh>
    );
};

export interface CrossSectionPlaneControlsProps {
    visible: boolean;
    csPlane: CrossSectionPlane;
    setCSPlane: CrossSectionPlaneSetter;
    cameraControlsRef: MainViewCameraControlsRef;
}

/**
 * This component allows to use mouse drag and drop
 * to move and change the orientation of the
 * cross section plane.
 *
 * Displays some gizmos and drag and drop handlers
 * attached to it.
 *
 * @component
 */
export const CrossSectionPlaneControls: React.VFC<CrossSectionPlaneControlsProps> = ({
    visible,
    csPlane,
    setCSPlane,
    cameraControlsRef,
}) => {
    const { gl, raycaster, camera } = useThree();

    const gizmosRef = React.useRef<Group>();

    const [hoverTool, setHoverTool] = React.useState<CONTROL_TOOL | undefined>(undefined);
    const hoverToolRef = React.useRef(hoverTool);
    React.useEffect(() => {
        hoverToolRef.current = hoverTool;
    }, [hoverTool]);

    const [activeTool, setActiveTool] = React.useState<CONTROL_TOOL | undefined>(undefined);
    const activeToolRef = React.useRef(activeTool);
    React.useEffect(() => {
        activeToolRef.current = activeTool;
    }, [activeTool]);

    const onActiveChange = React.useCallback(
        (tool: CONTROL_TOOL, active: boolean) => {
            const newActiveState = setToolStateWithPriority(tool, active, activeToolRef.current);
            setActiveTool(newActiveState);
        },
        [activeToolRef],
    );

    const onHoverChange = React.useCallback(
        (tool: CONTROL_TOOL, state: boolean) => {
            const newHoverState = setToolStateWithPriority(tool, state, hoverToolRef.current);
            setHoverTool(newHoverState);
        },
        [hoverToolRef],
    );

    const onDragNormalHoverChange = React.useCallback(
        (isHover: boolean) => {
            onHoverChange(CONTROL_TOOL.DRAG_NORMAL, isHover);
        },
        [onHoverChange],
    );

    const onDragFreeHoverChange = React.useCallback(
        (isHover: boolean) => {
            onHoverChange(CONTROL_TOOL.DRAG_FREE, isHover);
        },
        [onHoverChange],
    );

    const rotateTopMouseCB = useMouseEventsCallbacks(CONTROL_TOOL.ROTATE_TOP, onActiveChange, onHoverChange);
    const rotateBottomMouseCB = useMouseEventsCallbacks(CONTROL_TOOL.ROTATE_BOTTOM, onActiveChange, onHoverChange);
    const rotateFrontMouseCB = useMouseEventsCallbacks(CONTROL_TOOL.ROTATE_FRONT, onActiveChange, onHoverChange);
    const rotateBackMouseCB = useMouseEventsCallbacks(CONTROL_TOOL.ROTATE_BACK, onActiveChange, onHoverChange);

    const dragFreeVisible =
        activeTool === undefined || activeTool === CONTROL_TOOL.DRAG_FREE || activeTool === CONTROL_TOOL.DRAG_NORMAL;

    const dragNormalVisible = activeTool === undefined || activeTool === CONTROL_TOOL.DRAG_NORMAL;

    const rotateTopVisible = activeTool === undefined || activeTool === CONTROL_TOOL.ROTATE_TOP;
    const rotateBottomVisible = activeTool === undefined || activeTool === CONTROL_TOOL.ROTATE_BOTTOM;
    const rotateFrontVisible = activeTool === undefined || activeTool === CONTROL_TOOL.ROTATE_FRONT;
    const rotateBackVisible = activeTool === undefined || activeTool === CONTROL_TOOL.ROTATE_BACK;

    // NOTE: We want the gizmos of this component to be rerendered on each csPlane update,
    // but not the effect (which updates csPlane).
    // Since the effect only requires current csPlane state at the time of down click, we create
    // a copy in csPlaneRef and update it from csPlane on every update (so that it is fresh) but
    // the effect uses only this and never csPlane.
    // See notes inside the effect.
    const csPlaneRef = React.useRef<CrossSectionPlane>();
    React.useEffect(() => {
        csPlaneRef.current = csPlane;
    }, [csPlane]);

    // EPDPLT-3246 High cognitive complexity. Consider refactoring to make this function easier to test and maintain.
    // eslint-disable-next-line sonarjs/cognitive-complexity
    React.useEffect(() => {
        const canvas = gl.domElement;

        // We change Cross Section Plane position by dragging
        // it along it's normal.

        // This implemented in three mouse events handlers
        // * down - initialize this tool:
        //          disable main camera controls
        //          save dragOffset - this is a vector,
        //          from cross section original position, to mouse pointer
        //          down position projected onto the plane normal

        // * move - if tool is active,
        //          determine current pointer position, and set
        //          cross section plane position to mouse position
        //          minus dragOffset

        // * up   - resets this tool

        // This effect is intended to bind mouse handlers which persist as long as
        // whole element exists, rather than frequently updated.
        // It is particularly important that this effect doesn't use csPlane because
        // it calls setCSPlane(), and the update loop would cause continual self-invoked
        // resets.
        // Since this effect only requires the *initial* csPlane position and normal at
        // the time of down-click, the down mouse handler will get those from csPlaneRef
        // and keep them in local state.

        // flag that dragging is active
        let dragging = false;

        // vector between pointer down and original plane position
        // without it plane would jump to the point where user starts
        // to drag instead of keeping some offset.
        let dragOffset = new Vector3();

        // Plane orthogonal to Cross Section plane, used to calculate
        // mouse point position in 3d space.
        const workPlane = new Plane();

        // This tool doesn't change the normal. But it might be updated
        // outside, we update this value on a pointer down
        let csPlaneNormal = new Vector3();

        // Move plane free of constraints, or along the plane normal
        let clampToNormal = false;

        // Plane position on mouse down
        let csPlanePosition = new Vector3();

        // Returns coordinates in world space, where raycaster ray
        // intersects workPlane
        const workPlaneRayCoordinates = (): Vector3 | undefined => {
            const pointA = raycaster.ray.origin;
            const pointB = raycaster.ray.origin.clone().add(raycaster.ray.direction.clone().multiplyScalar(1000));

            const line = new Line3(pointA, pointB);

            return workPlane.intersectLine(line, new Vector3());
        };

        const down = () => {
            // We only update local state in down()
            if (gizmosRef.current?.visible && csPlaneRef.current) {
                // We use hoverToolRef here not activeTool,
                // because active tool have not been set yet.
                clampToNormal = hoverToolRef.current === CONTROL_TOOL.DRAG_NORMAL;

                // grab the inital normal and position from csPlaneRef
                csPlaneNormal = CrossSectionMathUtils.getPlaneNormal(csPlaneRef.current);
                const planePosition = csPlaneRef.current.position;

                const up = raycaster.camera.up.clone();
                csPlanePosition = planePosition.clone().projectOnPlane(csPlaneNormal);

                // Update work plane
                workPlane.setFromCoplanarPoints(
                    planePosition,
                    up.add(planePosition),
                    csPlaneNormal.clone().add(planePosition),
                );

                // Check that user starts to drag on one of the gizmos
                const intersection = raycaster.intersectObject(gizmosRef.current, true);

                if (intersection.length > 0) {
                    // Disable main camera controls
                    cameraControlsRef.current && (cameraControlsRef.current.enabled = false);

                    const downPosition = workPlaneRayCoordinates();
                    if (downPosition) {
                        dragOffset = planePosition.sub(downPosition);
                    }

                    dragging = true;
                    onActiveChange?.(clampToNormal ? CONTROL_TOOL.DRAG_NORMAL : CONTROL_TOOL.DRAG_FREE, true);
                } else {
                    onActiveChange?.(clampToNormal ? CONTROL_TOOL.DRAG_NORMAL : CONTROL_TOOL.DRAG_FREE, false);
                    dragging = false;
                }
            }
        };

        const move = (event: MouseEvent) => {
            if (dragging) {
                // we need to manually update the raycaster since R3F doesn't update it if the mouse goes over other html elements in the page
                // like the cross-section panel
                const canvasRect = canvas.getClientRects()[0];
                const canvasTopOffset = canvasRect?.top ?? 0;
                const canvasLeftOffset = canvasRect?.left ?? 0;
                const mouse = new Vector2();

                // we want to compute the normalized device coordinates for the mouse position (requried for WebGL)
                // x goes from -1 to +1 from left to right
                // y goes from -1 to +1 from bottom to top, notice that y is flipped in NDC compared to event cooridnates
                // https://threejs.org/manual/?q=mou#en/picking
                mouse.x = ((event.clientX - canvasLeftOffset) / canvas.width) * 2 - 1;
                mouse.y = -((event.clientY - canvasTopOffset) / canvas.height) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);

                const dragVector = workPlaneRayCoordinates();
                const currentCSPlane = csPlaneRef.current;

                if (currentCSPlane && dragVector) {
                    if (clampToNormal) {
                        const csPlaneNormal = CrossSectionMathUtils.getPlaneNormal(currentCSPlane);
                        dragVector.projectOnVector(csPlaneNormal);
                        dragVector.add(csPlanePosition);
                    }
                    const csPlaneNewOrigin = dragVector.add(dragOffset);

                    setCSPlane({
                        ...currentCSPlane,
                        position: csPlaneNewOrigin,
                    });
                }
            }
        };

        const up = () => {
            dragging = false;
            onActiveChange?.(clampToNormal ? CONTROL_TOOL.DRAG_NORMAL : CONTROL_TOOL.DRAG_FREE, false);
            clampToNormal = false;
            dragOffset = new Vector3();
            csPlanePosition = new Vector3();
            cameraControlsRef.current && (cameraControlsRef.current.enabled = true);
        };

        // add the events to ownerDocument so that it works on the whole page (not only inside the canvas), this allows the user to drag
        // the cross section control outside of the canvas or over other panels that covers the canvas like
        // the cross-section view panel
        canvas.ownerDocument.addEventListener('pointerup', up);
        canvas.ownerDocument.addEventListener('pointerdown', down);
        canvas.ownerDocument.addEventListener('pointermove', move);

        // grab the current cameraControls, in case it changes before cleanup runs
        const cameraControls = cameraControlsRef.current;
        return () => {
            canvas.ownerDocument.removeEventListener('pointerup', up);
            canvas.ownerDocument.removeEventListener('pointerdown', down);
            canvas.ownerDocument.removeEventListener('pointermove', move);

            if (dragging && cameraControls) {
                cameraControls.enabled = true;
            }
        };

        // See notes above. This effect cannot depend on csPlane
    }, [cameraControlsRef, camera, gl.domElement, raycaster, setCSPlane, onActiveChange]);

    // grab the current normal from csPlane
    const norm = CrossSectionMathUtils.getPlaneNormal(csPlane);

    const qa = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), norm);
    const qb = new Quaternion().setFromUnitVectors(new Vector3(0, 1, 0), norm.clone().multiplyScalar(-1));

    return (
        <group>
            <group visible={visible} position={csPlane.position} ref={gizmosRef}>
                <DragNormalGizmo
                    orientation={qa}
                    visible={dragNormalVisible}
                    highlighted={hoverTool === CONTROL_TOOL.DRAG_NORMAL}
                    onHoverChange={onDragNormalHoverChange}
                />
                <DragNormalGizmo
                    orientation={qb}
                    visible={dragNormalVisible}
                    highlighted={hoverTool === CONTROL_TOOL.DRAG_NORMAL}
                    onHoverChange={onDragNormalHoverChange}
                />
                <DragFreeGizmo
                    visible={dragFreeVisible}
                    highlighted={hoverTool === CONTROL_TOOL.DRAG_FREE}
                    onHoverChange={onDragFreeHoverChange}
                />
            </group>
            <group>
                <CrossSectionRotationControl
                    visible={rotateTopVisible}
                    highlighted={hoverTool === CONTROL_TOOL.ROTATE_TOP}
                    onActive={rotateTopMouseCB.active}
                    onHoverChange={rotateTopMouseCB.hover}
                    placement={CrossSectionRotationGizmoPlacement.TOP}
                    csPlane={csPlane}
                    setCSPlane={setCSPlane}
                    cameraControlsRef={cameraControlsRef}
                />
                <CrossSectionRotationControl
                    visible={rotateBottomVisible}
                    highlighted={hoverTool === CONTROL_TOOL.ROTATE_BOTTOM}
                    onActive={rotateBottomMouseCB.active}
                    onHoverChange={rotateBottomMouseCB.hover}
                    placement={CrossSectionRotationGizmoPlacement.BOTTOM}
                    csPlane={csPlane}
                    setCSPlane={setCSPlane}
                    cameraControlsRef={cameraControlsRef}
                />
                <CrossSectionRotationControl
                    visible={rotateFrontVisible}
                    highlighted={hoverTool === CONTROL_TOOL.ROTATE_FRONT}
                    onActive={rotateFrontMouseCB.active}
                    onHoverChange={rotateFrontMouseCB.hover}
                    placement={CrossSectionRotationGizmoPlacement.FRONT}
                    csPlane={csPlane}
                    setCSPlane={setCSPlane}
                    cameraControlsRef={cameraControlsRef}
                />
                <CrossSectionRotationControl
                    visible={rotateBackVisible}
                    highlighted={hoverTool === CONTROL_TOOL.ROTATE_BACK}
                    onActive={rotateBackMouseCB.active}
                    onHoverChange={rotateBackMouseCB.hover}
                    placement={CrossSectionRotationGizmoPlacement.BACK}
                    csPlane={csPlane}
                    setCSPlane={setCSPlane}
                    cameraControlsRef={cameraControlsRef}
                />
            </group>
        </group>
    );
};
