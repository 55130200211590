import type { LabsGqlDoctorPreferencesFragment, LabsGqlLabOrderFragment } from '@orthly/graphql-operations';
import type { LabsGqlReasonCodeFault, LabsGqlReasonCodeGroup, Maybe } from '@orthly/graphql-schema';
import type { ValidShadeType } from '@orthly/items';

export type RefabFlowScreen =
    | 'selection'
    | 'reasonCodes'
    | 'shade_attachments'
    | 'shade_change_requests'
    | 'supplemental_info_step_one'
    | 'supplemental_info_step_two'
    | 'additional_notes'
    | 'info'
    | 'confirmation';

export type RefabFlowLabOrder = Pick<
    LabsGqlLabOrderFragment,
    | 'id'
    | 'items_v2'
    | 'doctor_name'
    | 'created_at'
    | 'delivery_date'
    | 'patient'
    | 'refabrication_order_id'
    | 'scan_export'
    | 'doctor_preferences_id'
    | 'original_order_id'
>;

export type RefabFlowDoctorPreferences = Pick<LabsGqlDoctorPreferencesFragment, 'custom_field_preferences'>;

export interface RefabAttachment {
    firebaseUrl: string;
}

export type RefabAnnotation = {
    imageUrl: string;
    comment?: string;
};

export type RefabShadeChangeRequest = {
    shadeName: ValidShadeType;
    previousValue: string | undefined;
    newValue: string | undefined;
};

export type ShadeUpdate = {
    changeRequests: RefabShadeChangeRequest[];
};

export interface RefabFlowItemReasonCode {
    itemId: string;
    group: LabsGqlReasonCodeGroup; // Aesthetic, Fit or, Other
    category: string; // eg. Shade, Anatomy, Occlusion, etc.
    title: string; // eg. Too light, Too dark, Too tight, etc.
    codeId: string; // Identifier for the group+category+title trio. (The .id of the reasonCode object.)
    requiresReturn: boolean;
    otherNotes: string; // Applies when title === 'Other'
    additionalInfoRequest: string[]; // List of text to display in supplemental screen based on reason code selected
    // TODO: say 'fault' instead of 'defaultFault' (as ops can edit it), and make corresponding change in chairside.
    defaultFault: Maybe<LabsGqlReasonCodeFault>; // e.g. Carrier, Dandy, Lab, etc.
}

export interface RefabFlowState {
    screen: RefabFlowScreen;
    screenItemId: string;
    selectedItemIds: string[];
    // Itemwise mapping, of the form: { itemId: { codeId: RefabFlowItemReasonCode, ... }, ... }
    itemwiseReasonCodes: Record<string, Record<string, RefabFlowItemReasonCode>>;
    // TODO: Remove supplementaryNotes from refab state.
    // Added general notes back to refab flow as a short-term solution for doctors giving additional context on issues with refab.
    supplementaryNotes: string;
    returnLabelUrl?: string;
    annotations: RefabAnnotation[];
    attachments: RefabAttachment[];
    shadeUpdatesByItemId: Record<string, ShadeUpdate>;
    isSettingShadesForAllItems: boolean | undefined;
}
export const INITIAL_REFAB_FLOW_STATE: RefabFlowState = {
    screen: 'selection',
    screenItemId: '',
    selectedItemIds: [],
    itemwiseReasonCodes: {},
    supplementaryNotes: '',
    annotations: [],
    attachments: [],
    shadeUpdatesByItemId: {},
    isSettingShadesForAllItems: undefined,
};
