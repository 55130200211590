import type { LabsGqlPartialsFulfillmentFragment } from '@orthly/graphql-operations';
import React from 'react';

const DEFAULT_OPEN_PDF_ACTION = (url: string) => window.open(url, '_blank');

export const printPartialsFeedbackLabel = (
    partials_fulfillment_fragment?: Pick<LabsGqlPartialsFulfillmentFragment, 'current'>,
    openPdfAction: (url: string) => void = DEFAULT_OPEN_PDF_ACTION,
) => {
    const current = partials_fulfillment_fragment?.current;
    const current_return = current && `return` in current ? current.return : undefined;

    const labelUrl = current_return?.shipment.label_url;

    // There is no shipping label generated, die on the hill.
    if (!labelUrl) {
        console.error({
            err: `No shipping label URL in partials fulfillment: ${partials_fulfillment_fragment}`,
        });
        return;
    }

    openPdfAction(labelUrl);
};

export const usePartialsFeedbackPrintLabel = (
    partials_fulfillment_fragment?: Pick<LabsGqlPartialsFulfillmentFragment, 'current'>,
    openPdfAction: (url: string) => void = DEFAULT_OPEN_PDF_ACTION,
) => {
    return React.useCallback(
        () => printPartialsFeedbackLabel(partials_fulfillment_fragment, openPdfAction),
        [partials_fulfillment_fragment, openPdfAction],
    );
};
