import type { ModelPayloadItem } from '../ModelViewer';
import type { Pose } from '@orthly/forceps';
import { computeAllCollisionLines, collissionGroupsToGeometry, AttributeName } from '@orthly/forceps';
import * as THREE from 'three';

/**
 * Recomputes all collision of a geometry based on the proximal and occlusal distance fields stored as an attribute
 * inside this geometry it will return one geometry object to visualize the collisions lines as a mesh geometry the
 * geometry object will be wrapped inside a ModelPayloadItem data structure to easily replace the old collisions object
 * the returned geometry also stores the face-to-lines maps of the the proximal and occlusal collisions to be easily
 * updated later.
 * @param geometry The geometry from whose distance attributes the collisions will be computed
 * @param pose The pose of `geometry` in the scene. If not provided, it is assumed that `geometry` is expressed in the
 *   world frame
 * @param occlusalDistanceAttributeName The name of the occlusal distance attribute from which to calculate the occlusal
 *   collisions
 * @returns A ModelPayloadItem containing the geometry of the collisions lines
 */
export function recomputeCollisionsObject(
    geometry: THREE.BufferGeometry,
    pose?: Pose,
    occlusalDistanceAttributeName: string = AttributeName.OcclusalDistance,
): ModelPayloadItem {
    const faceToLineMapProximal: Map<number, THREE.Vector3[]> = computeAllCollisionLines(
        geometry,
        AttributeName.ProximalDistance,
    );
    const faceToLineMapProximalWithCurtains: Map<number, THREE.Vector3[]> = computeAllCollisionLines(
        geometry,
        AttributeName.CurtainsDistance,
    );
    const faceToLineMapOcclusal: Map<number, THREE.Vector3[]> = computeAllCollisionLines(
        geometry,
        occlusalDistanceAttributeName,
    );

    // Set this for rapid updating of collision object
    const nFaces = geometry.index ? geometry.index?.count / 3 : 0;
    const maxNumberOfPoints = 3 * (nFaces * 3); // each face adds 3 vertices, and possibly each of the map adds a face

    const resultGeometry = collissionGroupsToGeometry(
        [
            { name: 'faceToLineMapProximal', data: faceToLineMapProximal },
            { name: 'faceToLineMapProximalWithCurtains', data: faceToLineMapProximalWithCurtains },
            { name: 'faceToLineMapOcclusal', data: faceToLineMapOcclusal },
        ],
        maxNumberOfPoints,
    );

    let mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material> | undefined = undefined;
    if (pose) {
        mesh = new THREE.Mesh(resultGeometry);
        mesh.position.copy(pose.position);
        mesh.quaternion.copy(pose.orientation);
    }

    return {
        name: 'updated_collisions',
        path: '',
        model: {
            geometry: resultGeometry,
            modelType: 'stl',
        },
        isRestorative: false,
        isPrintedModel: false,
        mesh,
    };
}
