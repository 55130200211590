import { AnalyticsClient } from '../../../analytics/analyticsClient';
import type { PartnerAppState } from '../../../redux/types';
import { usePracticeInboxData } from '../../inbox/state/PracticeInboxProvider';
import { cleanStarRating, useFeedbackCurrentLabOrder } from '../Feedback.util';
import { FeedbackActions } from './feedback.actions';
import { useSubmitFeedbackV3Mutation } from '@orthly/graphql-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import _ from 'lodash';
import React from 'react';
import { useDispatch, useStore } from 'react-redux';

export type StarRating = 1 | 2 | 3 | 4 | 5;

export const useSubmitFeedbackV3 = () => {
    const { lab_order } = useFeedbackCurrentLabOrder();
    const { id: lab_order_id } = lab_order ?? {};

    const dispatch = useDispatch();
    const store = useStore<PartnerAppState>();

    const { refetchPendingReviews } = usePracticeInboxData();
    const [raw_submit] = useSubmitFeedbackV3Mutation();
    const { submit, submitting } = useChangeSubmissionFn<any, any>(raw_submit, {
        closeOnComplete: true,
        onSuccess: () => {
            dispatch(FeedbackActions.GO_TO({ id: `post_submit` }));
            void refetchPendingReviews();
        },
    });

    const maybe_submit = React.useCallback(() => {
        const raw_items = store.getState().feedback.items;

        if (!lab_order_id) {
            return;
        }
        const items = Object.entries(raw_items).map(
            ([item_id, { tags: raw_tags, notes, chairside_adjustment_minutes, star_rating }]) => {
                const tags = Object.values(raw_tags).map(reasonCode => ({
                    category: _.startCase(reasonCode.category),
                    title: _.startCase(reasonCode.title),
                    other_notes: reasonCode.otherNotes,
                    reason_code_id: reasonCode.codeId,
                }));
                return {
                    item_id,
                    tags,
                    notes,
                    chairside_adjustment_minutes,
                    star_rating: star_rating === null ? 0 : star_rating,
                };
            },
        );

        const raw_min_star_rating = Math.min(5, ...items.map(({ star_rating }) => star_rating));
        const cleaned_min_star_rating = cleanStarRating(raw_min_star_rating);

        AnalyticsClient.track('Practice - Order Feedback Submitted', {
            rating: cleaned_min_star_rating <= 4 ? 'unhappy' : 'happy',
            minStarRating: raw_min_star_rating === 0 ? 0 : cleaned_min_star_rating,
            avgStarRating: _.sum(items.map(item => item.star_rating)) / items.length,
            submitMethod: 'regular',
            $groups: {
                order: lab_order_id,
            },
        });

        // annotations and attachments are set as empty array since this hook is only for reviews created through feedback flow,
        // scan annotations and attachments are only present when a review is created through the refabrication flow
        void submit({ variables: { data: { lab_order_id, items, annotations: [], attachments: [] } } });
    }, [submit, store, lab_order_id]);

    return { submit: maybe_submit, loading: submitting };
};
